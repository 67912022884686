import HttpService from '../../system/services/HttpService'

export default {
    getProspect: (userId, universityIds) => {
        return HttpService.get(`/dashboard/users/${userId}/prospectInfo`, { universityIds })
    },
    getSaProfileTypes: (data) => {
        return HttpService.get('/common/data/ambassador/profileTypes', data)
    },
    getSubjects: (data) => {
        return HttpService.get('/common/data/subjects', data)
    },
    getStudentTypes: (data) => {
        return HttpService.get('/common/data/studentTypes', data)
    },
    getStaffTypes: (data) => {
        return HttpService.get('/common/data/staffTypes', data)
    },
    getYearOfStudies: (data) => {
        return HttpService.get('/common/data/yearOfStudies', data)
    },
    getCourseTypes: (data) => {
        return HttpService.get('/common/data/courseTypes', data)
    },
    getIndustries: (data) => {
        return HttpService.get('/common/data/industries', data)
    },
    changeAmbassadorInfo: ({ data, userId }) => {
        return HttpService.put(`/dashboard/users/${userId}/profileType`, data)
    },
    deleteAmbassador: ({ userId }) => {
        return HttpService.delete(`/dashboard/universities/ambassadors/${userId}`)
    },
    /**
     * Request career reference
     * @param {number} userId - User id
     * @returns {Promise<{success: boolen}>}
     */
    downloadCareerReference: (userId) => {
        return HttpService.get(`/common/account/careerReference/pdf?type=link&user_id=${userId}`)
    },
}
