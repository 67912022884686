import React from 'react'
import { useLocation } from 'react-router-dom'
import { Pagination, Row } from 'antd'

import Loader from '../../../content/components/Loader'

import EventCard from './event-card'

const LiveStreamsList = ({ loading, items, total, onPaginationchange, currentPage }) => {
    const { pathname } = useLocation()

    if (loading) {
        return (
            <div className="live-events-loader">
                <Loader />
            </div>
        )
    }

    if (items.length === 0) {
        return (
            <div
                style={{
                    fontSize: '3rem',
                    textAlign: 'center',
                    margin: '30vh 30vh 0 30vh',
                }}
            >
                {`You don't have any streams yet. Create a new one or read the Start Guide to learn more about the feature.`}
            </div>
        )
    }

    return (
        <>
            <div className="events-list">
                {items.map((event) => {
                    const { id } = event
                    return <EventCard key={id} to={`${pathname}/${id}`} event={event} />
                })}
            </div>
            {total > 20 && (
                <Row justify="center" style={{ paddingTop: '1.6rem', paddingBottom: '3rem' }}>
                    <Pagination
                        current={currentPage}
                        total={total}
                        showSizeChanger={false}
                        size={'small'}
                        pageSize={20}
                        onChange={onPaginationchange}
                    />
                </Row>
            )}
        </>
    )
}

export default LiveStreamsList
