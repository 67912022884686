import { HttpService } from 'src/app/system/services/HttpService';
export default {
    getUserCountry: function () {
        return HttpService.get('/common/data/country');
    },
    getCountriesList: function (data) {
        return HttpService.get('/common/data/countries', data);
    },
    getNationalities: function () {
        return HttpService.get('/common/data/nationalities');
    },
};
