import {
    UNIVERSITY_WIDGET_INFO_SUCCESS,
    UNIVERSITY_WIDGET_INFO_REQUESTED,
} from '../../../actions/settings/custom-styles'

import { UNIVERSITY_WIDGETS } from '../../../store/initialState'

const initialState = UNIVERSITY_WIDGETS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case UNIVERSITY_WIDGET_INFO_SUCCESS: {
            const newState = {
                primary_color:
                    payload.settings && payload.settings.primary_color ? payload.settings.primary_color : '#000000',
                secondary_color:
                    payload.settings && payload.settings.secondary_color ? payload.settings.secondary_color : '#000000',
                typeface: payload.settings && payload.settings.typeface ? payload.settings.typeface : 'Helvetica',
                custom_css: payload.settings && payload.settings.custom_css ? payload.settings.custom_css : '',
                isLoading: false,
            }

            return newState
        }

        case UNIVERSITY_WIDGET_INFO_REQUESTED: {
            const newState = {
                ...state,
                isLoading: true,
            }
            return newState
        }

        default: {
            return state
        }
    }
}
