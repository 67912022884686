import React, { PureComponent } from 'react'
import ReactPlayer from 'react-player'

class SharedPostPreviewPopup extends PureComponent {
    render() {
        const { post } = this.props
        let contentView = ''
        if (post.type === 'image') {
            contentView = (
                <div className="post-galery-item">
                    <img
                        src={
                            post.images[0].sizes['750x1400']
                                ? post.images[0].sizes['750x1400']
                                : post.images[0].original
                        }
                        alt="Post gallery image"
                    />
                </div>
            )
        } else if (post.type === 'video') {
            contentView = (
                <div className="video-player">
                    <ReactPlayer
                        className="video-element"
                        url={post.videos[0].original}
                        aria-label=""
                        playing={false}
                        muted
                        pip
                        playsinline
                        loop
                        controls
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload',
                                    pip: 'true',
                                },
                            },
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                    />
                </div>
            )
        }

        return <div>{contentView}</div>
    }
}

export default SharedPostPreviewPopup
