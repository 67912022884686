import {
    REPORT_QUESTIONS_INFO,
    REPORT_QUESTIONS_INFO_SUCCESS,
    REPORT_QUESTIONS_INFO_FAILED,
} from '../../actions/reporting/faqActions'

import { FAQ_REPORT } from '../../store/initialState'

const initialState = FAQ_REPORT

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case REPORT_QUESTIONS_INFO: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case REPORT_QUESTIONS_INFO_SUCCESS: {
            return {
                items: payload.items,
                total: payload.total,
                all_likes: payload.all_likes ? payload.all_likes : 0,
                all_views: payload.all_views ? payload.all_views : 0,
                isLoading: false,
            }
        }

        case REPORT_QUESTIONS_INFO_FAILED: {
            return {
                ...state,
                items: [],
                isLoading: false,
            }
        }

        default: {
            return state
        }
    }
}
