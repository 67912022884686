import { Button, Col, Form, Modal, Row } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg'
import { closeUsersModal, updateLiveStream } from '../../../../redux/actions/live-streams/live-streams-actions'
import { getLiveEventsUsersModal } from '../../../../redux/selectors'

import { UsersForm, UsersRequestModel } from './users-form'

const UsersFormModal = () => {
    const dispatch = useDispatch()
    const { open, data, submitting } = useSelector(getLiveEventsUsersModal)
    const [form] = Form.useForm()

    const handleModalClose = useCallback(() => {
        dispatch(closeUsersModal())
    }, [dispatch])

    const handleSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            const requestPayload = UsersRequestModel(values, data)
            dispatch(updateLiveStream(requestPayload))
        })
    }, [data, dispatch, form])

    const footer = useMemo(() => {
        return (
            <Row justify="center">
                <Col flex="162px">
                    <Button
                        type="primary"
                        block
                        size="large"
                        disabled={submitting}
                        loading={submitting}
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        )
    }, [handleSubmit, submitting])

    return (
        <Modal
            title="Manage ambassadors"
            footer={footer}
            open={open}
            closeIcon={<CloseIcon />}
            onCancel={handleModalClose}
            width={600}
            className="tap-event-modal"
            destroyOnClose
        >
            <UsersForm form={form} data={data} />
        </Modal>
    )
}

export default UsersFormModal
