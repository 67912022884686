export const prepareStreamHostUrl = ({ accountInfo, streamData }) => {
    const { avatar, name } = accountInfo
    return `${streamData?.hostRoomUrl}&people=off${
        avatar
            ? `&avatarUrl=${avatar.sizes && avatar.sizes['370x370'] ? avatar.sizes['370x370'] : avatar.original}`
            : ''
    }&displayName=${name}&leaveButton=off`
}

export const prepareStreamUrl = ({ accountInfo, streamData }) => {
    const { avatar, name } = accountInfo
    return `${streamData?.roomUrl}&people=off${
        avatar
            ? `&avatarUrl=${avatar.sizes && avatar.sizes['370x370'] ? avatar.sizes['370x370'] : avatar.original}`
            : ''
    }&topToolbar=off&displayName=${name}&leaveButton=off`
}
