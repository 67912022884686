export const CLOSE_ACTION_MODAL = 'CLOSE_ACTION_MODAL'
export const OPEN_ACTION_MODAL = 'OPEN_ACTION_MODAL'

export const OPEN_USER_INFO_MODAL = 'OPEN_USER_INFO_MODAL'
export const CLOSE_USER_INFO_MODAL = 'CLOSE_USER_INFO_MODAL'
export const SET_PROSPECT_BLOCK = 'SET_PROSPECT_BLOCK'

export const NEW_NOTIFICATION = 'NEW_NOTIFICATION'
export const REMOVE_LAST_NOTIFICATION = 'REMOVE_LAST_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export const NEW_MESSAGES_RECEIVED = 'NEW_MESSAGES_RECEIVED'
export const HIDE_NEW_MESSAGES = 'HIDE_NEW_MESSAGES'

export const OPEN_SETTINGS_MESSAGING_POPUP = 'OPEN_SETTINGS_MESSAGING_POPUP'
export const CLOSE_SETTINGS_MESSAGING_POPUP = 'CLOSE_SETTINGS_MESSAGING_POPUP'
export const REPLY_MESSAGE = 'REPLY_MESSAGE'
