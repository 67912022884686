import React, { PureComponent } from 'react'

import { connect } from 'react-redux'
import store from '../../redux/store'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../redux/actions/content/componentsActions'
import Modal from 'antd/es/modal'

class LiveEventsFeature extends PureComponent {
    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'live-events-feature',
            },
        })
    }

    render() {
        return (
            <div className="live-events-feature-page">
                <div id="modal-live-events-area" className="modal-live-events-area" />
                <Modal
                    open={true}
                    bodyStyle={{ padding: 0 }}
                    width={'60rem'}
                    zIndex={9999}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                    destroyOnClose
                    getContainer="#modal-live-events-area"
                >
                    <div className="live-events-modal-body">
                        <p className="live-events-modal-header">Live Events. New feature available</p>
                        <p className="live-events-modal-text live-events-modal-text-start">
                            Connect your ambassadors, prospects and wider community through Live Events held on TAP -
                            bring everyone together in one place.
                        </p>
                        <div className="video-wrapper video-live-events-wrapper">
                            <iframe
                                src="https://player.vimeo.com/video/637537136?h=b3f6240abf&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                width="640"
                                height="373"
                                frameBorder="0"
                                allow="autoplay; fullscreen"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <p className="live-events-modal-text">
                            Our Live Events feature helps institutions run a whole host of online events such as:
                            <div className="events-items-example">
                                <p>Webinars</p>
                                <p>Taster Sessions</p>
                                <p>Information Sessions</p>
                                <p>Q&As</p>
                            </div>
                        </p>
                        <p className="live-events-modal-text live-events-modal-text-center">
                            It&apos;s a fantastic way of reaching a large volume of prospects at any one time, whilst
                            keeping them connected with you and your ambassadors at all times, even before and after an
                            event.
                        </p>
                        <p className="live-events-modal-text">
                            <span>Contact the support team to book a demo and find out more.</span>
                        </p>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(LiveEventsFeature)
