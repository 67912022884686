import { Checkbox, Form, Input } from 'antd';
import React from 'react';
import { passwordShapeRegex } from 'src/app/common/utils/password-shape-regex';
export var LastStepFields = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(Form.Item, { name: "password", label: "Password*", rules: [
                { required: true, message: 'Type password' },
                function () { return ({
                    validator: function (_, value) {
                        if (passwordShapeRegex(value) && value) {
                            return Promise.reject(new Error('Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol'));
                        }
                        return Promise.resolve();
                    },
                }); },
            ] },
            React.createElement(Input.Password, { placeholder: "Password" })),
        React.createElement(Form.Item, { name: "confirmPassword", label: "Confirm password*", dependencies: ['password'], rules: [
                { required: true, message: 'Type password' },
                function (_a) {
                    var getFieldValue = _a.getFieldValue;
                    return ({
                        validator: function (_, value) {
                            var passwordValue = getFieldValue('password');
                            if (value !== passwordValue && value) {
                                return Promise.reject(new Error('This password does not match'));
                            }
                            return Promise.resolve();
                        },
                    });
                },
            ] },
            React.createElement(Input.Password, { placeholder: "Confirm password" })),
        React.createElement(Form.Item, { name: "terms", valuePropName: "checked", rules: [
                function (_a) {
                    var getFieldValue = _a.getFieldValue;
                    return ({
                        validator: function () {
                            var tetmValue = getFieldValue('terms');
                            if (!tetmValue) {
                                return Promise.reject(new Error('Please agree to the terms to be able to use this service'));
                            }
                            return Promise.resolve();
                        },
                    });
                },
            ] },
            React.createElement(Checkbox, { className: "!text-xs" },
                "I am over the age of 18 and consent to my personal data being processed by my institution, according to its Privacy Policy, and being shared with The Ambassador Platform according to their\u00A0",
                React.createElement("a", { className: "privacy-link", target: "_blank", rel: "noopener noreferrer", href: "https://tap.st/privacy" }, "Privacy Notice,"),
                "\u00A0",
                React.createElement("a", { className: "privacy-link", target: "_blank", rel: "noopener noreferrer", href: "https://legal.theambassadorplatform.com/admin-user-terms" }, "Admin User Terms"),
                "\u00A0and\u00A0",
                React.createElement("a", { className: "privacy-link", target: "_blank", rel: "noopener noreferrer", href: "https://legal.theambassadorplatform.com/customer-terms" }, "Customer Terms"),
                "\u00A0 so that they can facilitate the management of content generation from representatives of my institution and messaging interactions with prospects."))));
};
