import React, { Suspense } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import NotificationsWrapper from '../../NotificationsWrapper.jsx'
import Notifications from '../../components/Notifications'
import SAList from '../SA list/index'
import IsAllowed from '../_layout/IsAllowed.jsx'
import IsAuthorized from '../_layout/IsAuthorized.jsx'
import RedirectIfGuest from '../_layout/RedirectIfGuest.jsx'
import SimpleAdmin from '../_layout/SimpleAdmin.jsx'
import Forgot from '../account/forgot'
import LogIn from '../account/login/index'
import Registration from '../account/registration/index'
import RegistrationGroup from '../account/registrationGroup'
import SsoRedirectionPage from '../account/ssoRedirectionPage/sso-redirection-page'
import ArchiveChats from '../chat/archive/index'
import GroupChatsMessaging from '../chat/group-chats/index'
import ChatMessaging from '../chat/messaging/index'
import ChatStartGuide from '../chat/start-guide/index'
import CommunityContainer from '../community-container/community-container'
import Dashboard from '../content/dashboard/index'
import Messaging from '../content/messaging/index'
import Schedule from '../content/schedule/index'
import Sharing from '../content/sharing/index'
import ContentStartGuide from '../content/start-guide/index'
import Library from '../faq/library'
import NewQuestions from '../faq/new_questions'
import FaqStartGuide from '../faq/start-guide/index'
import Home from '../home/index'
import LiveEventsScreen from '../live-events-container/live-events-screen'
import LiveEventsFeature from '../live-events-feature'
import LiveStreamsScreen from '../live-streams-container/live-streams-screen'
import TrackedButton from '../promotion/button/index'
import Carousel from '../promotion/carousel/index'
import TrackedLink from '../promotion/link/index'
import PopCard from '../promotion/pop-card/index'
import ReportingActivity from '../reporting/activity'
import ReportingAmbassadors from '../reporting/ambassadors'
import ReportingContent from '../reporting/content'
import ReportingFaq from '../reporting/faq'
import ReportingOverview from '../reporting/overview'
import ReportingProspects from '../reporting/prospects'
import ReportingTime from '../reporting/time'
import AnalyticsTrendAnalyzer from '../reporting/trend'
import IdpConversionReport from '../reporting/idp-conversion'
import Account from '../settings/account'
import AutoDeletion from '../settings/auto-deletion/index'
import Crm from '../settings/crm/index'
import CustomCss from '../settings/custom-css/index'
import CustomStyles from '../settings/custom-styles/index'
import ExperienceBasedQuestions from '../settings/experience-based-questions'
import ExportData from '../settings/export-data/index'
import InviteAdmins from '../settings/invite-admins/index'
import Invite from '../settings/invite/index'
import Legal from '../settings/legal/index'
import NotificationsControl from '../settings/notifications-control/index'
import OptionalQuestions from '../settings/optional-questions/index'
import Safeguarding from '../settings/safeguarding/index'
import SettingsSso from '../settings/sso/settings-sso'
import TapPage from '../settings/tap-page/index'
import Timezone from '../settings/timezone/index'
import SetupBranding from '../setup/branding/index'
import SetupOptionalData from '../setup/optional-data/index'

import MaintenanceScreen from '../account/maintenance/index'
import { PlsOnboarding } from '../account/pls-onboarding'
import { IdpActivation } from '../idp-activation'
import CampaignManagementScreen from '../campaign-management-container/campaign-management-screen'
import PartnersIntegrations from '../partners-integrations/index'
import AmbassadorSelfInviteScreen from '../setup/ambassador-self-invite-screen/ambassador-self-invite-screen'
import BookDemoScreen from '../setup/book-a-demo-call-screen/book-a-demo-call-screen'
import OrganisationDetailsScreen from '../setup/organisation-details-screen/organisation-details-screen'
import ParticipantFirstMessageScreen from '../setup/participant-first-message-screen/participant-first-message-screen'
import SetupPrivacyPolicy from '../setup/policy/index'
import SetupTapPage from '../setup/tap-page/index'
import SetupTrainingCentre from '../setup/training-centre/index'

// const LiveEventsUpcoming = lazy(() => import(/* webpackChunkName: "live-events" */ '../live-events/upcoming'))
// const LiveEventsInProgress = lazy(() => import(/* webpackChunkName: "live-events" */ '../live-events/in-progress'))
// const LiveEventsPrevious = lazy(() => import(/* webpackChunkName: "live-events" */ '../live-events/previous'))

const unAuthorizedRoutes = () => {
    return (
        <Switch>
            <RedirectIfGuest>
                <Route exact path="/account/login" component={LogIn} />
                <Route exact path="/account/forgot" component={Forgot} />
                <Route exact path="/account/forgot/confirm/:user_id(\d+)/:hex" component={Forgot} />
                <Route exact path="/account/registration/d39dde9d1de1f56a" component={Registration} />
                <Route exact path="/account/group/registration/d39dde9d1de1f56a" component={RegistrationGroup} />
                <Route exact path="/account/registration/university/d39dde9d1de1f56a" component={Registration} />
                <Route exact path="/account/sign-in-redirect" component={SsoRedirectionPage} />
            </RedirectIfGuest>
        </Switch>
    )
}

const authorizedRoutes = () => {
    return (
        <IsAuthorized>
            <Switch>
                <SimpleAdmin
                    exact
                    path={['/setup/organization-details', '/:university_id/setup/organization-details']}
                    component={OrganisationDetailsScreen}
                    setupWizard={true}
                />
                <SimpleAdmin
                    exact
                    path={['/setup/ambassador-self-invite', '/:university_id/setup/ambassador-self-invite']}
                    component={AmbassadorSelfInviteScreen}
                    setupWizard={true}
                />
                <SimpleAdmin
                    exact
                    path={['/setup/participant-first-message', '/:university_id/setup/participant-first-message']}
                    component={ParticipantFirstMessageScreen}
                    setupWizard={true}
                />
                <SimpleAdmin
                    exact
                    path={['/setup/book-a-demo-call', '/:university_id/setup/book-a-demo-call']}
                    component={BookDemoScreen}
                    setupWizard={true}
                />
                <SimpleAdmin
                    exact
                    path={['/setup/privacy-policy', '/:university_id/setup/privacy-policy']}
                    component={SetupPrivacyPolicy}
                    setupWizard={true}
                />
                <SimpleAdmin
                    exact
                    path={['/setup/training-centre', '/:university_id/setup/training-centre']}
                    component={SetupTrainingCentre}
                    setupWizard={true}
                />
                <SimpleAdmin
                    exact
                    path={['/setup/branding', '/:university_id/setup/branding']}
                    component={SetupBranding}
                    setupWizard={true}
                />
                <SimpleAdmin
                    exact
                    path={['/setup/tap-page', '/:university_id/setup/tap-page']}
                    component={SetupTapPage}
                    setupWizard={true}
                />
                <SimpleAdmin
                    exact
                    path={['/setup/optional-data', '/:university_id/setup/optional-data']}
                    component={SetupOptionalData}
                    setupWizard={true}
                />
                <IsAllowed
                    exact
                    path={['/content/dashboard', '/:university_id/content/dashboard']}
                    component={Dashboard}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/content/messaging', '/:university_id/content/messaging']}
                    component={Messaging}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/content/schedule', '/:university_id/content/schedule']}
                    component={Schedule}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/content/sharing', '/:university_id/content/sharing']}
                    component={Sharing}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/content/start', '/:university_id/content/start']}
                    component={ContentStartGuide}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/content-group/:content_group_id', '/:university_id/content-group/:content_group_id']}
                    component={Dashboard}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/tag/:tag_id(d+)', '/:university_id/tag/:tag_id(d+)']}
                    component={Dashboard}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={[
                        '/content-responses/:content_request_id(d+)',
                        '/:university_id/content-responses/:content_request_id(d+)',
                    ]}
                    component={Dashboard}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/user-posts/:user_id(d+)', '/:university_id/user-posts/:user_id(d+)']}
                    component={Dashboard}
                    testIfUniIsActivated={true}
                />
                <Route exact path={['/settings/account', '/:university_id/settings/account']} component={Account} />
                <IsAllowed
                    exact
                    path={['/settings/invite', '/:university_id/settings/invite']}
                    component={Invite}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/settings/invite-admins', '/:university_id/settings/invite-admins']}
                    component={InviteAdmins}
                    checkGroupAdminPermissions
                />
                <Route
                    exact
                    path={['/settings/notifications-control', '/:university_id/settings/notifications-control']}
                    component={NotificationsControl}
                />
                <Route
                    exact
                    path={['/settings/custom-styles', '/:university_id/settings/custom-styles']}
                    component={CustomStyles}
                />
                <Route
                    exact
                    path={['/settings/custom-css', '/:university_id/settings/custom-css']}
                    component={CustomCss}
                />
                <Route
                    exact
                    path={['/settings/legal', '/:university_id/settings/legal']}
                    component={Legal}
                    testIfUniIsActivated={true}
                />
                <Route
                    exact
                    path={['/settings/safeguarding', '/:university_id/settings/safeguarding']}
                    component={Safeguarding}
                />
                <Route
                    exact
                    path={['/settings/auto-deletion/', '/:university_id/settings/auto-deletion/']}
                    component={AutoDeletion}
                />
                <IsAllowed
                    exact
                    path={['/settings/export-data/', '/:university_id/settings/export-data/']}
                    component={ExportData}
                    testIfUniIsActivated={true}
                />
                <Route exact path={['/settings/tap-page', '/:university_id/settings/tap-page']} component={TapPage} />
                <Route exact path={['/settings/crm', '/:university_id/settings/crm']} component={Crm} />
                <Route exact path={['/settings/timezone', '/:university_id/settings/timezone']} component={Timezone} />
                <IsAllowed
                    exact
                    path={['/settings/sso', '/:university_id/settings/sso']}
                    component={SettingsSso}
                    isSsoAllowed={true}
                />
                <IsAllowed
                    exact
                    path={['/settings/optional-questions', '/:university_id/settings/optional-questions']}
                    component={OptionalQuestions}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={[
                        '/settings/experience-based-questions',
                        '/:university_id/settings/experience-based-questions',
                    ]}
                    component={ExperienceBasedQuestions}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/chat/messaging', '/:university_id/chat/messaging']}
                    component={ChatMessaging}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/chat/group-chats', '/:university_id/chat/group-chats']}
                    component={GroupChatsMessaging}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/chat/archive', '/:university_id/chat/archive']}
                    component={ArchiveChats}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/faq/new-questions', '/:university_id/faq/new-questions']}
                    component={NewQuestions}
                    testIfUniIsActivated={true}
                />
                <Route
                    exact
                    path={['/faq/new-answers', '/:university_id/faq/new-answers']}
                    render={(props) => <Library {...props} isPublished={false} />}
                />
                <Route
                    exact
                    path={['/faq/library', '/:university_id/faq/library']}
                    render={(props) => <Library {...props} isPublished={true} />}
                />
                <IsAllowed
                    exact
                    path={['/sa-list', '/:university_id/sa-list']}
                    component={SAList}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/chat/start', '/:university_id/chat/start']}
                    component={ChatStartGuide}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/faq/start', '/:university_id/faq/start']}
                    component={FaqStartGuide}
                    testIfUniIsActivated={true}
                />
                <IsAllowed
                    exact
                    path={['/reporting/overview', '/:university_id/reporting/overview']}
                    component={ReportingOverview}
                    checkGroupAdminPermissions
                />
                <IsAllowed
                    exact
                    path={['/reporting/activity', '/:university_id/reporting/activity']}
                    component={ReportingActivity}
                    checkGroupAdminPermissions
                />
                <IsAllowed
                    exact
                    path={['/reporting/content', '/:university_id/reporting/content']}
                    component={ReportingContent}
                    checkGroupAdminPermissions
                />
                <IsAllowed
                    exact
                    path={['/reporting/faq', '/:university_id/reporting/faq']}
                    component={ReportingFaq}
                    checkGroupAdminPermissions
                />
                <IsAllowed
                    exact
                    path={['/reporting/ambassadors', '/:university_id/reporting/ambassadors']}
                    component={ReportingAmbassadors}
                    checkGroupAdminPermissions
                />
                <IsAllowed
                    exact
                    path={['/reporting/prospects', '/:university_id/reporting/prospects']}
                    component={ReportingProspects}
                    checkGroupAdminPermissions
                />
                <IsAllowed
                    exact
                    path={['/reporting/time', '/:university_id/reporting/time']}
                    component={ReportingTime}
                    checkGroupAdminPermissions
                />
                <IsAllowed
                    exact
                    path={['/reporting/trend', '/:university_id/reporting/trend']}
                    component={AnalyticsTrendAnalyzer}
                    checkGroupAdminPermissions
                />
                <Route
                    exact
                    path={['/reporting/idp-conversion', '/:university_id/reporting/idp-conversion']}
                    component={IdpConversionReport}
                    checkGroupAdminPermissions
                />
                <SimpleAdmin
                    exact
                    path={['/partners-integrations', '/:university_id/partners-integrations']}
                    component={PartnersIntegrations}
                />
                <Route exact path={['/promotion/pop-card', '/:university_id/promotion/pop-card']} component={PopCard} />
                <Route
                    exact
                    path={['/promotion/carousel', '/:university_id/promotion/carousel']}
                    component={Carousel}
                />
                <Route exact path={['/promotion/link', '/:university_id/promotion/link']} component={TrackedLink} />
                <Route
                    exact
                    path={['/promotion/button', '/:university_id/promotion/button']}
                    component={TrackedButton}
                />
                {/*
                <CurrentUniversityIsActivated exact path="/live-events/upcoming" component={LiveEventsUpcoming} />
                <CurrentUniversityIsActivated exact path="/live-events/in-progress" component={LiveEventsInProgress} />
                <CurrentUniversityIsActivated exact path="/live-events/previous" component={LiveEventsPrevious} />
*/}
                <IsAllowed
                    path={['/live-streams', '/:university_id/live-streams']}
                    component={LiveStreamsScreen}
                    testIfUniIsActivated={true}
                    testIfLiveStreamsIsActivated={true}
                />
                <Route
                    path={['/community', '/:university_id/community']}
                    component={CommunityContainer}
                    testIfUniIsActivated={true}
                    testIfLiveStreamsIsActivated={true}
                />
                <IsAllowed
                    path={['/live-events', '/:university_id/live-events']}
                    component={LiveEventsScreen}
                    testIfUniIsActivated={true}
                    testIfLiveEventIsActivated={true}
                />
                <IsAllowed
                    path={['/campaign-management', '/:university_id/campaign-management']}
                    component={CampaignManagementScreen}
                    testIfUniIsActivated={true}
                    testIfCampaignManagementIsActivated={true}
                />
                <Route
                    exact
                    path={['/live-events-feature', '/:university_id/live-events-feature']}
                    component={LiveEventsFeature}
                />
                <Route exact path={['/', '/home', '/:university_id', '/:university_id/home']} component={Home} />
            </Switch>
        </IsAuthorized>
    )
}

const Router = () => (
    <Suspense fallback={<></>}>
        <Switch>
            <NotificationsWrapper>
                <Notifications />
                <Route path={['/account']} component={unAuthorizedRoutes} />
                <Route path={['/', '/:university_id']} component={authorizedRoutes} />
                <Route path={['/:university_id/maintenance', '/maintenance']} component={MaintenanceScreen} />
                <Route path={['/pls-onboarding', '/:university_id/pls-onboarding']} component={PlsOnboarding} />
                <Route path={['/:university_id/idp-activation', '/idp-activation']} component={IdpActivation} />
            </NotificationsWrapper>
        </Switch>
    </Suspense>
)

export default withRouter(Router)
