import React, { PureComponent } from 'react'

class Topics extends PureComponent {
    render() {
        const { user } = this.props

        return (
            <div className="topics-data-block">
                {user.topics && user.topics.length > 0 ? (
                    <>
                        <p className="topics-title">Topic</p>
                        <div className="topics-list scroll-8">
                            {user.topics.map((topic, index) => {
                                return (
                                    <p key={index} className="topics-text">
                                        {topic}
                                    </p>
                                )
                            })}
                        </div>
                    </>
                ) : (
                    <span className="no-prospect-info-data">No topics detected</span>
                )}
            </div>
        )
    }
}

export default Topics
