import React, { useCallback } from 'react'

import { Form, Input, Button, Row } from 'antd'

import { useDispatch } from 'react-redux'

import { getAppDownloadLink } from '../../../../../../../../../redux/actions/pls-sign-up/pls-sign-up-actions'

const EmailInvitationForm = () => {
    const dispatch = useDispatch()

    const onFinish = useCallback(
        (values) => {
            const data = {
                method: 'email',
                email: values.emailAddress,
            }
            dispatch(getAppDownloadLink(data))
        },
        [dispatch]
    )

    return (
        <Form
            layout="vertical"
            initialValues={{
                emailAddress: '',
            }}
            onFinish={onFinish}
            autoComplete="off"
            hideRequiredMark
        >
            <Form.Item
                name="emailAddress"
                label="Email address"
                className="form-item email"
                rules={[
                    { required: true, message: 'Add email address' },
                    { type: 'email', message: 'The input is not valid email' },
                ]}
            >
                <Row>
                    <Input placeholder="f.e. jamessmith@institution.com" className="form-item-input" maxLength={50} />
                    <Button type="link" htmlType="submit">
                        Get link
                    </Button>
                </Row>
            </Form.Item>
        </Form>
    )
}

export default EmailInvitationForm
