var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Form } from 'antd';
import { uniq } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import API from 'src/app/api';
import { pluckIds } from 'src/app/common/utils/array-utils';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { CREATE_CONTENT_GROUP } from 'src/app/redux/actions/content/messagingActions';
import { ContentGroupTypeEnum } from 'src/types/general';
var prepareFilters = function (filters) {
    var filtersData = {};
    for (var key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
            var value = filters[key];
            if (Array.isArray(value) && value.length > 0) {
                filtersData[key] = value;
            }
            else if (typeof value === 'string' && key === 'type') {
                filtersData.isDomestic = value === 'domestic';
            }
        }
    }
    return filtersData;
};
export var useIdpContentGroup = function () {
    var form = Form.useForm()[0];
    var _a = useState([]), ambassadors = _a[0], setAmbassadors = _a[1];
    var _b = useState([]), ambassadorsIds = _b[0], setAmbassadorsIds = _b[1];
    var _c = useState(false), isAmbassadorsListLoading = _c[0], setIsAmbassadorsListLoading = _c[1];
    var _d = useState(false), isCreateGroupDisabled = _d[0], setIsCreateGroupDisabled = _d[1];
    var _e = useState({
        jurneyStage: [],
        jurneyStep: [],
        destination: [],
        topicsTheme: [],
        topics: [],
        channel: [],
    }), tags = _e[0], setTags = _e[1];
    var _f = useState({
        type: undefined,
        nationalities: [],
        universities: [],
        subjects: [],
    }), filters = _f[0], setFilters = _f[1];
    var groupType = useAppSelector(function (state) { return state.createContentGroupModal; }).groupType;
    var isBroadcast = groupType === ContentGroupTypeEnum.Broadcast;
    var dispatch = useAppDispatch();
    useEffect(function () {
        var filtersData = prepareFilters(filters);
        setIsAmbassadorsListLoading(true);
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var resp, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, API.dashboardGroup.getIdpContentGroupAmbassadors({
                                filter: filtersData,
                            })];
                    case 1:
                        resp = _a.sent();
                        setIsAmbassadorsListLoading(false);
                        setAmbassadors(resp.data);
                        setIsCreateGroupDisabled(resp.data.length === 0);
                        setAmbassadorsIds(pluckIds(resp.data));
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching data:', error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [form, filters]);
    useEffect(function () {
        form.setFieldValue('tags', uniq(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], tags.jurneyStage, true), tags.destination, true), tags.jurneyStep, true), tags.topicsTheme, true), tags.topics, true), tags.channel, true)));
    }, [form, tags]);
    var onFinish = useCallback(function () {
        form.validateFields().then(function (values) {
            var requestData = isBroadcast
                ? {
                    name: values.name,
                    description: values.description,
                    idpContentGroupType: ContentGroupTypeEnum.Broadcast,
                    ambassadorsIds: ambassadorsIds,
                }
                : {
                    name: values.name,
                    description: values.description,
                    tags: values.tags,
                    type: values.contentType,
                    idpContentGroupType: ContentGroupTypeEnum.Content,
                    ambassadorsIds: ambassadorsIds,
                };
            dispatch({
                type: CREATE_CONTENT_GROUP,
                payload: requestData,
            });
        });
    }, [form, dispatch, isBroadcast, ambassadorsIds]);
    var handleJurneyStepChange = useCallback(function (val, options) {
        setTags(function (prevTags) { return (__assign(__assign({}, prevTags), { jurneyStep: uniq(__spreadArray(__spreadArray([], [], false), val.map(function (item) { return item.trim(); }), true)), jurneyStage: uniq(__spreadArray(__spreadArray([], [], false), options.map(function (item) { return item.title; }), true)) })); });
    }, []);
    var handleTopicsOfInterestChange = useCallback(function (val, options) {
        setTags(function (prevTags) { return (__assign(__assign({}, prevTags), { topics: val, topicsTheme: uniq(__spreadArray(__spreadArray([], [], false), options.map(function (item) { return item.title; }), true)) })); });
    }, []);
    var handleChannelChange = useCallback(function (val) {
        setTags(function (prevTags) { return (__assign(__assign({}, prevTags), { channel: val })); });
    }, []);
    var handleDestinationChange = useCallback(function (val) {
        setTags(function (prevTags) { return (__assign(__assign({}, prevTags), { destination: val.map(function (item) { return item.split('_')[0]; }) })); });
    }, []);
    var handleAmbassadorsSelect = useCallback(function (ids) {
        setAmbassadorsIds(ids);
        setIsCreateGroupDisabled(ids.length === 0);
    }, []);
    return {
        form: form,
        ambassadors: ambassadors,
        isAmbassadorsListLoading: isAmbassadorsListLoading,
        isCreateGroupDisabled: isCreateGroupDisabled,
        onFinish: onFinish,
        handleJurneyStepChange: handleJurneyStepChange,
        handleTopicsOfInterestChange: handleTopicsOfInterestChange,
        handleChannelChange: handleChannelChange,
        handleDestinationChange: handleDestinationChange,
        setFilters: setFilters,
        handleAmbassadorsSelect: handleAmbassadorsSelect,
        isBroadcast: isBroadcast,
    };
};
