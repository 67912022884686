var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'src/app/api';
export var USERS_MESSAGING_INFO = 'USERS_MESSAGING_INFO';
export var USERS_MESSAGING_INFO_SUCCESS = 'USERS_MESSAGING_INFO_SUCCESS';
export var USERS_MESSAGING_INFO_FAILED = 'USERS_MESSAGING_INFO_FAILED';
export var AMBASSADORS_INFO = 'AMBASSADORS_INFO';
export var AMBASSADORS_INFO_SUCCESS = 'AMBASSADORS_INFO_SUCCESS';
export var AMBASSADORS_INFO_FAILED = 'AMBASSADORS_INFO_FAILED';
export var OPEN_CONTENT_GROUP_POPUP = 'OPEN_CONTENT_GROUP_POPUP';
export var CLOSE_CONTENT_GROUP_POPUP = 'CLOSE_CONTENT_GROUP_POPUP';
export var CREATE_CONTENT_GROUP = 'CREATE_CONTENT_GROUP';
export var CREATE_CONTENT_GROUP_SUCCESS = 'CREATE_CONTENT_GROUP_SUCCESS';
export var CREATE_CONTENT_GROUP_FAILED = 'CREATE_CONTENT_GROUP_FAILED';
export var CONTENT_GROUPS_INFO = 'CONTENT_GROUPS_INFO';
export var CONTENT_GROUPS_INFO_SUCCESS = 'CONTENT_GROUPS_INFO_SUCCESS';
export var CONTENT_GROUPS_INFO_FAILED = 'CONTENT_GROUPS_INFO_FAILED';
export var DIALOG_MESSAGES_INFO = 'DIALOG_MESSAGES_INFO';
export var DIALOG_MESSAGES_INFO_SUCCESS = 'DIALOG_MESSAGES_INFO_SUCCESS';
export var DIALOG_MESSAGES_INFO_FAILED = 'DIALOG_MESSAGES_INFO_FAILED';
export var VIDEO_TRANSCODE = 'VIDEO_TRANSCODE';
export var VIDEO_TRANSCODE_SUCCESS = 'VIDEO_TRANSCODE_SUCCESS';
export var VIDEO_TRANSCODE_FAILED = 'VIDEO_TRANSCODE_FAILED';
export var SEND_MESSAGE = 'SEND_MESSAGE';
export var SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export var SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';
export var EDIT_CONTENT_GROUP = 'EDIT_CONTENT_GROUP';
export var EDIT_CONTENT_GROUP_SUCCESS = 'EDIT_CONTENT_GROUP_SUCCESS';
export var EDIT_CONTENT_GROUP_FAILED = 'EDIT_CONTENT_GROUP_FAILED';
export var CLOSE_CONTENT_GROUP = 'CLOSE_CONTENT_GROUP';
export var CLOSE_CONTENT_GROUP_SUCCESS = 'CLOSE_CONTENT_GROUP_SUCCESS';
export var CLOSE_CONTENT_GROUP_FAILED = 'CLOSE_CONTENT_GROUP_FAILED';
export var CLEAR_CONTENT_GROUP = 'CLEAR_CONTENT_GROUP';
export var OPEN_UPLOAD_FILE_POPUP = 'OPEN_UPLOAD_FILE_POPUP';
export var SET_ACTIVE_CONTENT_GROUP = 'SET_ACTIVE_CONTENT_GROUP';
export var CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export var RELOAD_CONTENT_GROUP = 'RELOAD_DIALOG';
export var RELOAD_CONTENT_GROUP_SUCCESS = 'RELOAD_DIALOG_SUCCESS';
export var RELOAD_CONTENT_GROUP_FAILED = 'RELOAD_DIALOG_FAILED';
// export const VIEW_DIALOGS = "VIEW_DIALOGS";
export var EDIT_MESSAGE_POST_GLOBAL_TAG = 'EDIT_MESSAGE_POST_GLOBAL_TAG';
export var EDIT_MESSAGE_POST_GLOBAL_TAG_FINISH = 'EDIT_MESSAGE_POST_GLOBAL_TAG_FINISH';
export var RESET_DIALOG_MESSAGES_COUNTER = 'RESET_DIALOG_MESSAGES_COUNTER';
export var EDIT_MESSAGE_POST_CAPTION_SUCCESS = 'EDIT_MESSAGE_POST_CAPTION_SUCCESS';
export var DELETE_CONTENT_MESSAGE = 'DELETE_CONTENT_MESSAGE';
export var LOAD_OLD_MESSAGES_SUCCESS = 'LOAD_OLD_MESSAGES_SUCCESS';
export var getAmbassadorsInfo = createAction(AMBASSADORS_INFO);
export var getNewAmbassadorsInfoAsync = createAsyncThunk('AMBASSADORS_INFO_REQUESTED_NEW', function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, universityIds;
        return __generator(this, function (_b) {
            state = getState();
            universityIds = state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null;
            return [2 /*return*/, API.dashboardNew.universityAmbassadors(__assign(__assign({}, payload), { universityIds: universityIds }))];
        });
    });
});
