import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { ACCOUNT } from '../../../redux/actions/account/account-types'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import store from '../../../redux/store'

import EditPrivacyPolicyForm from './components/forms/EditPrivacyPolicyForm'
import { trackAddedPrivacyPolicy } from '../../../helpers/MixpanelHelper'
import { MarketingConsent } from './components'

class Legal extends PureComponent {
    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
    }

    handlePrivacyPolicySubmit = (values) => {
        const onFinish = (added) => {
            if (added) {
                trackAddedPrivacyPolicy(this.props.account.account_info.university.id)
            }
        }
        store.dispatch({
            type: ACCOUNT.EDIT_PRIVACY_POLICY,
            payload: {
                privacy_policy: values.privacyPolicy,
            },
            meta: {
                onFinish,
            },
        })
    }

    render() {
        const { account_info: accountInfo, currentGroupUniversityId } = this.props.account
        return (
            <section className="content settings">
                <h3 className="settings-title">{`Legal ${
                    !accountInfo.isGroupAdmin || (accountInfo.isGroupAdmin && currentGroupUniversityId) ? 'set-up' : ''
                }`}</h3>

                <div className="settings-grid has-tutorial">
                    <div className="column">
                        <div className="tutorial-intro">
                            <p>
                                The Ambassador Platform is a Data Processor (as understood in the context of the GDPR).
                                You are the Data Controller and are responsible for the data we process on your behalf.
                            </p>
                            <p>
                                Read more about how terms and conditions work on The Ambassador Platform in our{' '}
                                <a
                                    href="https://legal.theambassadorplatform.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Legal Center
                                </a>{' '}
                                or get in touch with our Customer Success Team.
                            </p>
                        </div>

                        <hr className="settings-hr"></hr>
                        {(!accountInfo.isGroupAdmin || (accountInfo.isGroupAdmin && currentGroupUniversityId)) && (
                            <>
                                <div className="settings-item">
                                    <h4 className="settings-name">1. Privacy policy link</h4>
                                    <p>
                                        The Ambassador Platform is a Data Processor (as understood in the context of the
                                        GDPR). You are the Data Controller and are responsible for the data we process
                                        on your behalf. Please link your privacy policy below:
                                    </p>
                                    <EditPrivacyPolicyForm handleSubmit={this.handlePrivacyPolicySubmit} />
                                    <br />
                                    <p>
                                        We will display the privacy policy you have linked above to all users of The
                                        Ambassador Platform (students, staff and prospects) when they create an account.
                                    </p>
                                    <p>
                                        You can share{' '}
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/legal-setup-on-the-ambassador-platform"
                                            target="_blank"
                                            className="settings-content-link"
                                            rel="noopener noreferrer"
                                        >
                                            this article
                                        </a>{' '}
                                        with your legal colleagues to help them understand our service and our data
                                        protection processes.
                                    </p>
                                </div>
                                {!accountInfo.isGroupAdmin && <MarketingConsent accountInfo={accountInfo} />}
                            </>
                        )}
                    </div>
                    {(!accountInfo.isGroupAdmin || (accountInfo.isGroupAdmin && currentGroupUniversityId)) && (
                        <div className="column resources">
                            <h3 className="video-title">Video tutorial: Legal set-up</h3>
                            <div className="video-wrapper">
                                <iframe
                                    src="https://player.vimeo.com/video/340188099"
                                    width="640"
                                    height="360"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen
                                ></iframe>
                            </div>

                            <div className="resources-box">
                                <h3>Helpful resources</h3>
                                <ul>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/legal-setup-on-the-ambassador-platform"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Legal set-up on The Ambassador Platform
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/how-terms-conditions-work-on-the-ambassador-platform"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            How terms and conditions work on The Ambassador Platform
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(Legal)
