import { Select, Form } from 'antd'
import { parseISO, format, add } from 'date-fns'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GET_TIMEZONES } from '../../redux/actions/settings/timezonesActions'
import { getTimezonesSlice } from '../../redux/selectors'

const { Option } = Select

const filterOption = (input, option) => option.children.toLowerCase().includes(input.toLowerCase())

const TimezonesSelect = () => {
    const dispatch = useDispatch()
    const { items, isLoading } = useSelector(getTimezonesSlice)

    useEffect(() => {
        dispatch({
            type: GET_TIMEZONES,
        })
    }, [])

    const options = useMemo(
        () =>
            items.map((timezone) => {
                const date = parseISO(timezone.current_time)
                const formattedTime = format(add(date, { minutes: date.getTimezoneOffset() }), 'HH:mm')
                const text = `(${formattedTime}) ${timezone.name}`

                return (
                    <Option key={timezone.id} value={timezone.id} label={timezone.name}>
                        {text}
                    </Option>
                )
            }),
        [items]
    )

    return (
        <Form.Item name="timezoneId" label="Timezone">
            <Select
                loading={isLoading}
                showSearch
                optionFilterProp="children"
                optionLabelProp="label"
                filterOption={filterOption}
            >
                {options}
            </Select>
        </Form.Item>
    )
}

export default TimezonesSelect
