import { all, call, takeEvery, select, put, take } from 'redux-saga/effects'

import API from '../../../api'
import { LIVE_EVENTS } from '../../actions/live-event/live-event-action-types'
import {
    getIsGroupAdmin,
    getCurrentGroupUniversityId,
    getAccountSlice,
    getLiveEvents,
    getActiveLiveEventId,
} from '../../selectors'

function* fetchLiveEventsSaga({ payload }) {
    const isGroupAdmin = yield select(getIsGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        payload.universityId = currentGroupUniversityId || payload.university_id
    }

    const response = yield call(() => {
        return API.liveEvent.searchEvents(payload)
    })

    if (response.success) {
        const { data: { events = [] } = {} } = response

        yield put({
            type: LIVE_EVENTS.FETCH_SUCCESS,
            payload: {
                items: events,
            },
        })

        const currentEventId = yield select(getActiveLiveEventId)

        // Quick workaround to fetch event which is not in the first 20 items
        if (currentEventId && !events.some(({ id }) => id === +currentEventId)) {
            const fetchOne = yield call(() => {
                return API.liveEvent.searchEvents({ liveEventId: currentEventId })
            })

            if (fetchOne.success) {
                const { data: { events: otherEvents = [] } = {} } = fetchOne

                const itemStatus = otherEvents[0].status
                const status = payload.status

                if (itemStatus === status) {
                    yield put({
                        type: LIVE_EVENTS.FETCH_SUCCESS,
                        payload: {
                            items: otherEvents,
                        },
                        meta: {
                            addToExisting: true,
                        },
                    })
                }
            }
        }
    }
}

function* handleSetActiveLiveEventSaga({ payload }) {
    const eventId = payload

    const account = yield select(getAccountSlice)

    yield put({
        type: LIVE_EVENTS.FETCH_PROSPECTS,
        payload: {
            liveEventId: eventId,
            universityId: account.currentGroupUniversityId || account.account_info.university_id, // temporarily
            limit: 100,
            offset: 0,
        },
    })
}

function* handleFetchLiveEventProspectsSaga({ payload }) {
    const { liveEventId } = payload

    try {
        const response = yield call(() => {
            return API.liveEvent.getProspects(payload)
        })

        if (response.success) {
            const events = yield select(getLiveEvents)

            if (events.length === 0) {
                yield take(LIVE_EVENTS.FETCH_SUCCESS)

                yield put({
                    type: LIVE_EVENTS.FETCH_PROSPECTS_SUCCESS,
                    payload: {
                        liveEventId,
                        items: response.data ? response.data.users : [],
                        total: response.data ? response.data.total : 0,
                    },
                })
            } else {
                yield put({
                    type: LIVE_EVENTS.FETCH_PROSPECTS_SUCCESS,
                    payload: {
                        liveEventId,
                        items: response.data ? response.data.users : [],
                        total: response.data ? response.data.total : 0,
                    },
                })
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* watchLiveEventsFetchSaga() {
    yield takeEvery(LIVE_EVENTS.FETCH, fetchLiveEventsSaga)
}

function* watchSetActiveLiveEventSaga() {
    yield takeEvery(LIVE_EVENTS.SET_ACTIVE, handleSetActiveLiveEventSaga)
}

function* watchFetchLiveEventProspects() {
    yield takeEvery(LIVE_EVENTS.FETCH_PROSPECTS, handleFetchLiveEventProspectsSaga)
}

function* liveEventsFetchSaga() {
    yield all([call(watchLiveEventsFetchSaga), call(watchSetActiveLiveEventSaga), call(watchFetchLiveEventProspects)])
}

export default liveEventsFetchSaga
