import {
    SCHEDULE_DATA_SUCCESS,
    SCHEDULE_EDIT_EVENT_SUCCESS,
    SCHEDULE_DEACTIVATE_EVENT_SUCCESS,
    SCHEDULE_UPDATE_STATUSES_SUCCESS,
    SCHEDULE_ITEM_SUCCESS,
} from '../../actions/content/scheduleActions'

const initialState = {
    data: [],
    activeSсheduleItem: null,
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case SCHEDULE_DATA_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.data = payload
            // TODO: temporary fix to cut timezone:
            for (const item of newState.data) {
                item.date = item.date.slice(0, Math.max(0, item.date.length - 5))
            }
            // console.log(newState.data);
            return newState
        }

        case SCHEDULE_EDIT_EVENT_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.data = [...state.data]
            for (const item of newState.data) {
                if (item.id === payload.id) {
                    item.name = payload.name
                    item.description = payload.description
                    item.date = payload.date
                    break
                }
            }
            // console.log(newState.data);
            return newState
        }

        case SCHEDULE_DEACTIVATE_EVENT_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.data = [...state.data]
            for (let i = 0; i < newState.data.length; ++i) {
                if (newState.data[i].id === payload.eventId) {
                    newState.data[i].closed = true
                    break
                }
            }
            return newState
        }

        case SCHEDULE_UPDATE_STATUSES_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.data = [...state.data]
            const updatedIds = new Set(payload.ids)
            for (const item of newState.data) {
                if (updatedIds.has(item.id)) {
                    item.is_active = payload.isActive
                }
            }
            // console.log(newState.data);
            return newState
        }
        case SCHEDULE_ITEM_SUCCESS: {
            return {
                ...state,
                activeSсheduleItem: payload,
            }
        }
    }

    return state
}
