import React, { useState, useEffect } from 'react'
import { Select, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const TapTag = (props) => {
    const { label, closable, onClose } = props

    return (
        <span className="search-term" style={{ margin: '3px 3px 3px 0' }}>
            {label}
            {closable && <CloseOutlined style={{ marginLeft: '6px' }} onClick={onClose} />}
        </span>
    )
}

const DomainsSelector = (props) => {
    return (
        <>
            <Select
                dropdownStyle={{ display: 'none' }}
                mode="tags"
                tagRender={TapTag}
                className="domains-selector"
                placeholder="Enter Domain name"
                onChange={props.setDomains}
                value={props.domains}
            />
        </>
    )
}

export default DomainsSelector
