import React, { PureComponent } from 'react'
import HighCharts from 'highcharts'
import HighChartsReact from 'highcharts-react-official'

class LineChart extends PureComponent {
    prepareOptions() {
        return {
            chart: {
                type: 'spline',
                height: 250,
            },
            plotOptions: {
                spline: {
                    marker: {
                        enabled: false,
                        fillColor: '#fff',
                        lineWidth: 2,
                        lineColor: null,
                    },
                },
            },
            colors: ['#FF5100'],
            title: {
                text: null,
            },
            xAxis: {
                type: 'datetime',
                gridLineWidth: 1,
                gridLineColor: '#ffddbb',
                gridLineDashStyle: 'longdash',
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    enabled: true,
                    formatter: this.props.yAxisFormatter,
                },
                min: 0,
                allowDecimals: false,
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                formatter: this.props.tooltipFormatter,
                backgroundColor: '#646a7a',
                borderColor: 'none',
                useHTML: true,
                shape: 'square',
                shadow: false,
            },
            series: [
                {
                    data: this.props.data,
                },
            ],
        }
    }

    render() {
        return (
            <HighChartsReact
                highcharts={HighCharts}
                options={this.prepareOptions()}
                containerProps={{ className: 'home-graph' }}
            />
        )
    }
}

export default LineChart
