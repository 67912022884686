import Header from '../../_layout/header'
import NavigationSetupWizard from '../../_layout/navSetupWizard'
import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import { connect } from 'react-redux'
import Modal from 'antd/es/modal'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import Slider from 'react-slick'
import Sprite from '../../../../assets/img/symbol/sprite.svg'
import ExplanationModal from './components/ExplanationModal'
import { ReactComponent as SettingsIcon } from '../../../../assets/svg/icons/settings.svg'
import {
    CHANGE_UNIVERSITY_NAME_REQUESTED,
    UNIVERSITY_CHANGE_AVATAR,
} from '../../../redux/actions/account/accountActions'
import universityDefaultAvatar from '../../../../assets/img/university_default_avatar.jpeg'
import tipIcon from '../../../../assets/img/setup/tip.png'
import { SET_WIDGET_STYLES, UNIVERSITY_WIDGET_INFO_REQUESTED } from '../../../redux/actions/settings/custom-styles'
import SlideCard from './components/SlideCard'
import SlideCards from './components/SlideCards'
import SlideFaqCards from './components/SlideFaqCards'
import SlideApp from './components/SlideApp'
import SlideCardSecondary from './components/SlideCardSecondary'

function PrevArrow(props) {
    const { onClick } = props
    return (
        <button type="button" onClick={onClick} className="slick-prev">
            <svg className="icon icon-slider-arrow">
                <use xlinkHref={Sprite + '#slider-arrow'}></use>
            </svg>
        </button>
    )
}

function NextArrow(props) {
    const { onClick } = props
    return (
        <button type="button" onClick={onClick} className="slick-next">
            <svg className="icon icon-slider-arrow">
                <use xlinkHref={Sprite + '#slider-arrow'}></use>
            </svg>
        </button>
    )
}

class BrandingPage extends PureComponent {
    constructor(props) {
        super()
        this.state = {
            universityName:
                props.account && props.account.account_info && props.account.account_info.university
                    ? props.account.account_info.university.name
                    : '',
            initialUniversityName:
                props.account && props.account.account_info && props.account.account_info.university
                    ? props.account.account_info.university.name
                    : '',
            primaryColor: props.universityWidgets.primary_color,
            secondaryColor: props.universityWidgets.secondary_color,
            initialPrimaryColor: props.universityWidgets.primary_color,
            initialSecondaryColor: props.universityWidgets.secondary_color,
            primaryColorError: false,
            secondaryColorError: false,
            initialSlide: 0,
            activeSlide: 0,
            showPrevArrow: false,
            showNextArrow: true,
            showModal: false,
            nameError: false,
            symbolsLeft:
                props.account &&
                props.account.account_info &&
                props.account.account_info.university &&
                props.account.account_info.university.name
                    ? props.account.account_info.university.name.length
                    : 50,
        }

        this.onNameChange = this.onNameChange.bind(this)
        this.onPrimaryBrandChange = this.onPrimaryBrandChange.bind(this)
        this.onSecondaryBrandChange = this.onSecondaryBrandChange.bind(this)
        this.handleChangeSlide = this.handleChangeSlide.bind(this)
        this.showModal = this.showModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.nextStep = this.nextStep.bind(this)
        this.prevStep = this.prevStep.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: UNIVERSITY_WIDGET_INFO_REQUESTED,
            payload: {
                type: 'common',
            },
        })
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'setup-branding',
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.universityWidgets.primary_color !== this.props.universityWidgets.primary_color ||
            prevProps.universityWidgets.secondary_color !== this.props.universityWidgets.secondary_color
        ) {
            this.setState({
                primaryColor: this.props.universityWidgets.primary_color,
                secondaryColor: this.props.universityWidgets.secondary_color,
                primaryColorError: false,
                secondaryColorError: false,
            })
        }
    }

    handleChangeSlide(number) {
        if (number > 0) {
            this.setState({
                showPrevArrow: true,
                showNextArrow: number < 4,
                activeSlide: number,
            })
        } else {
            this.setState({
                showPrevArrow: false,
                activeSlide: number,
            })
        }
    }

    nextStep() {
        const {
            universityName,
            initialUniversityName,
            primaryColor,
            secondaryColor,
            initialPrimaryColor,
            initialSecondaryColor,
        } = this.state
        if (primaryColor[0] !== '#' || primaryColor.length !== 7) {
            this.setState({ primaryColorError: true })
            return
        }
        if (secondaryColor[0] !== '#' || secondaryColor.length !== 7) {
            this.setState({ secondaryColorError: true })
            return
        }
        const afterAllRequests = () => {
            if (
                !window.localStorage.getItem('setupWizardStep') ||
                +window.localStorage.getItem('setupWizardStep') < 4
            ) {
                window.localStorage.setItem('setupWizardStep', '4')
            }
            this.props.history.push('/setup/tap-page')
        }
        const onFinish = () => {
            // only when server error or success
            if (initialPrimaryColor !== primaryColor || initialSecondaryColor !== secondaryColor) {
                store.dispatch({
                    type: SET_WIDGET_STYLES,
                    payload: {
                        type: 'common',
                        settings: {
                            primary_color: primaryColor,
                            secondary_color: secondaryColor,
                        },
                    },
                    onFinish: afterAllRequests,
                })
            } else {
                afterAllRequests()
            }
        }
        const onSuccess = () => {
            this.setState({ initialUniversityName: uniName })
        }
        const uniName = universityName.trim()
        if (uniName.length > 0 && uniName === initialUniversityName) {
            onFinish(true)
        } else {
            store.dispatch({
                type: CHANGE_UNIVERSITY_NAME_REQUESTED,
                payload: {
                    university_name: uniName,
                },
                onFinish,
                onSuccess,
            })
        }
    }

    prevStep() {
        this.props.history.push('/setup/training-centre')
    }

    showModal() {
        this.setState({
            showModal: true,
        })
    }

    handleCloseModal() {
        this.setState({
            showModal: false,
        })
    }

    onNameChange({ target }) {
        const name = target.value
        if (name.length < 51) {
            this.setState({
                universityName: name,
                symbolsLeft: 50 - name.length,
                nameError: name.length === 0,
            })
        }
    }

    onPrimaryBrandChange({ target }) {
        const color = target.value.trim()
        this.setState({
            primaryColor: color,
            primaryColorError: (color.length > 6 && color[0] !== '#') || color.length > 7,
        })
    }

    onSecondaryBrandChange({ target }) {
        const color = target.value.trim()
        this.setState({
            secondaryColor: color,
            secondaryColorError: (color.length > 6 && color[0] !== '#') || color.length > 7,
        })
    }

    handleChangeAvatarInput(event) {
        const reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.addEventListener('load', function () {
            store.dispatch({
                type: UNIVERSITY_CHANGE_AVATAR,
                payload: {
                    avatar: reader.result.slice(reader.result.indexOf(',') + 1),
                },
            })
        })
    }

    render() {
        const {
            universityName,
            initialSlide,
            showPrevArrow,
            showNextArrow,
            activeSlide,
            showModal,
            primaryColor,
            secondaryColor,
            symbolsLeft,
            nameError,
            primaryColorError,
            secondaryColorError,
        } = this.state
        const { account } = this.props

        let logo = null
        let avatar = null

        if (
            account &&
            account.account_info &&
            account.account_info.university &&
            account.account_info.university.avatar &&
            account.account_info.university.avatar.original
        ) {
            avatar =
                account.account_info.university.avatar.sizes['160x160'] ||
                account.account_info.university.avatar.original
            logo = <img className="setup-university-logo-img" src={avatar || universityDefaultAvatar} alt="Logo" />
        } else {
            logo = <span className="setup-university-logo-img setup-university-logo-img-empty">+ Add logo</span>
        }
        const settings = {
            infinite: false,
            arrows: true,
            nextArrow: showNextArrow ? <NextArrow /> : <></>,
            prevArrow: showPrevArrow ? <PrevArrow /> : <></>,
            draggable: false,
            initialSlide: Number(initialSlide),
            afterChange: this.handleChangeSlide,
        }

        let previewTitle = 'Primary Color'
        let floorText = (
            <>
                <span>Primary color</span> is used for titles, course name background, and 'Chat with me' button on the
                Ambassador's profile card
            </>
        )
        switch (activeSlide) {
        case 2: {
            previewTitle = 'Secondary Color'
            floorText = (
                <>
                    <span>Secondary color</span> is used for Ambassadors' names on profile cards and on the hover of
                    buttons on the TAP Feed, for example, 'Chat with me' and 'See more'
                </>
            )
        
        break;
        }
        case 3: {
            previewTitle = 'your logo and institution name'
            floorText = (
                <>
                    <span>Institution name and logo</span> are used on the TAP Feed, if, as admins, you add content and
                    FAQs on behalf of the institution'
                </>
            )
        
        break;
        }
        case 4: {
            previewTitle = 'your logo and institution name'
            floorText = (
                <>
                    <span>University name and logo</span> are used in the Ambassadors' app, in the Career Reference
                    section
                </>
            )
        
        break;
        }
        // No default
        }
        return (
            <div className="setup-branding-page">
                <Header submenuUnActive={true} />
                <div id="page-wrapper" className="page-wrapper">
                    <NavigationSetupWizard />

                    <section className="content settings no-submenu home-start-guide setup-wizard">
                        <h1 className="settings-title">Admin Dashboard Setup Guide</h1>
                        <div className="setup-start-intro">
                            Welcome to Setup Guide of The Ambassador Platform’s Admin Dashboard! This section is
                            designed to guide you through set up.
                        </div>

                        <div className="start-guide-wizard-grid">
                            <div className="column">
                                <div className="setup-start-block">
                                    <div className="policy-title">3. Customize your branding</div>
                                    <p className="video-privacy-link">
                                        Watch this <span onClick={this.showModal}>video explanation</span> to see how it
                                        works
                                    </p>

                                    <div className="settings-item">
                                        <h4 className="settings-name">Institution Name</h4>

                                        <div className="setup-university-input-block">
                                            <input
                                                name="university_name"
                                                value={universityName}
                                                className="setup-university-input"
                                                placeholder="Institution Name"
                                                type="text"
                                                onChange={this.onNameChange}
                                            />
                                            <span>{symbolsLeft}/50</span>
                                        </div>
                                        {nameError && (
                                            <div className="input-error-message w-450">
                                                Institution Name is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="settings-item">
                                        <h4 className="settings-name">Institution logo</h4>
                                        <div className="tip-block branding-tip">
                                            <img src={tipIcon} alt="tip" />
                                            <span>
                                                Preferably in a square format, minimum 300x300 pixels, maximum 1000x1000
                                                pixels.
                                            </span>
                                        </div>
                                        <div className="setup-university-logo-block with-avatar">
                                            <div className="setup-university-logo-preview">
                                                <div
                                                    className="edit-university-block"
                                                    onChange={this.handleChangeAvatarInput}
                                                >
                                                    <label
                                                        className={
                                                            'edit-university-label' + (avatar ? ' with-avatar' : '')
                                                        }
                                                    >
                                                        <input
                                                            type="file"
                                                            className="edit-university-input"
                                                            accept="image/x-png,image/jpeg"
                                                        />
                                                        {avatar && <span className="edit-university-text">Edit</span>}
                                                    </label>
                                                    {logo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="settings-item">
                                        <h4 className="settings-name">Brand colors</h4>
                                        <div className="tip-block mb-0">
                                            <img src={tipIcon} alt="tip" />
                                            <span>
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="https://webaim.org/resources/contrastchecker"
                                                    target="_blank"
                                                >
                                                    Click here
                                                </a>{' '}
                                                to check that your colors meet accessibility requirements.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="settings-item">
                                        <div className="color-row">
                                            <span className="color-row-text">Primary color</span>
                                            <label className="color-input-label">
                                                <input
                                                    name="primaryColor"
                                                    className="signin-input privacy-input branding-input"
                                                    value={primaryColor}
                                                    type="text"
                                                    onChange={this.onPrimaryBrandChange}
                                                    style={{
                                                        borderColor: primaryColorError ? '#b30000' : primaryColor,
                                                    }}
                                                />
                                            </label>
                                            {primaryColorError && (
                                                <span className="input-error-message color-error-message">
                                                    Color code should contain '#' symbol and 6 numbers (f.e. #000000)
                                                </span>
                                            )}
                                        </div>
                                        <div className="color-row">
                                            <span className="color-row-text">Secondary color</span>
                                            <label className="color-input-label">
                                                <input
                                                    name="secondaryColor"
                                                    className="signin-input privacy-input branding-input"
                                                    value={secondaryColor}
                                                    type="text"
                                                    onChange={this.onSecondaryBrandChange}
                                                    style={{
                                                        borderColor: secondaryColorError ? '#b30000' : secondaryColor,
                                                    }}
                                                />
                                            </label>
                                            {secondaryColorError && (
                                                <span className="input-error-message color-error-message">
                                                    Color code should contain '#' symbol and 6 numbers (f.e. #000000)
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="setup-steps-buttons">
                                        <button className="previous-step-button" onClick={this.prevStep}>
                                            Previous
                                        </button>
                                        <button onClick={this.nextStep}>Next</button>
                                    </div>
                                    <div className="setup-skip-text">
                                        If you skip this step, you'll find it later in <SettingsIcon /> Settings
                                    </div>
                                </div>
                            </div>

                            <div className={'preview column preview-branding active-slide-' + activeSlide}>
                                <div className="preview-title">
                                    <span>Preview </span>
                                    <span>{previewTitle}</span>
                                </div>
                                <div className="preview-body">
                                    <Slider
                                        ref={(slider) => (this.slider = slider)}
                                        className="svg-wizard-slider"
                                        {...settings}
                                    >
                                        <SlideCard primaryColor={primaryColor} />
                                        <SlideCards primaryColor={primaryColor} />
                                        <SlideCardSecondary
                                            primaryColor={primaryColor}
                                            secondaryColor={secondaryColor}
                                        />
                                        <SlideFaqCards avatar={avatar} name={universityName} />
                                        <SlideApp avatar={avatar} name={universityName} />
                                    </Slider>
                                </div>
                                <div className="preview-floor">{floorText}</div>
                            </div>
                        </div>
                    </section>
                </div>
                <Modal
                    open={showModal}
                    bodyStyles={{ padding: 0 }}
                    centered={true}
                    width={800}
                    title={null}
                    footer={null}
                    destroyOnClose={true}
                    keyboard={false}
                    onCancel={this.handleCloseModal}
                    className="setup-modal"
                >
                    <ExplanationModal handleCloseModal={this.handleCloseModal} />
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        universityWidgets: state.universityWidgets,
    }
})(BrandingPage)
