import { GET_CSP_DOMAINS_SUCCESS } from '../../actions/settings/cspDomainsActions'

const initialState = []

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_CSP_DOMAINS_SUCCESS: {
            // console.log(payload);
            return payload
        }

        default: {
            return state
        }
    }
}
