import { Space } from 'antd'
import React, { useMemo } from 'react'

// import { ReactComponent as CameraIconOff } from '../../../../../../../../../../assets/svg/icons/camera-off.svg'
// import { ReactComponent as CameraIcon } from '../../../../../../../../../../assets/svg/icons/camera.svg'
import { ReactComponent as MicIconOff } from '../../../../../../../../../../assets/svg/icons/mic-off.svg'
import { ReactComponent as MicIcon } from '../../../../../../../../../../assets/svg/icons/mic.svg'

const ParticipantInfo = ({ participant }) => {
    const {
        user: { name },
        // videoMuted,
        audioMuted,
    } = participant

    // const videoIcon = useMemo(() => (videoMuted ? <CameraIconOff /> : <CameraIcon />), [videoMuted])
    const audioIcon = useMemo(() => (audioMuted ? <MicIconOff /> : <MicIcon />), [audioMuted])

    return (
        <div className="participant-info">
            <Space>
                <div className="control-wrapper">{audioIcon}</div>
                {/* <div className="control-wrapper"> {videoIcon}</div> */}
                {name}
            </Space>
        </div>
    )
}

export default ParticipantInfo
