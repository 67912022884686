import React, { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { getAmbassadorAdditionalData } from '../../../../../../redux/actions/content/ambassadorsActions'

const ViewCell = ({ record }) => {
    const dispatch = useDispatch()

    const getAmbassadorData = useCallback(() => {
        dispatch(
            getAmbassadorAdditionalData({
                userId: record.id,
                avatar: record.avatar,
                fullName: record.full_name,
            })
        )
    }, [record, dispatch])
    return (
        <button className="view-profile-info-btn" onClick={getAmbassadorData}>
            View
        </button>
    )
}

const viewdCellRenderer = (text, record) => <ViewCell record={record} />

export default viewdCellRenderer
