import React, { useCallback } from 'react'
import { Button, Popover } from 'antd'
import { useDispatch } from 'react-redux'
import { ACCOUNT_LOGOUT } from '../../redux/actions/account/accountActions'

const LogOutPopover = () => {
    return (
        <div style={{ alignSelf: 'flex-start', position: 'absolute', top: 0, right: 0 }}>
            <Popover content={<LogOutButton />} trigger="click" placement="bottomRight">
                <button type="button" style={{ width: 30, height: 30 }}></button>
            </Popover>
        </div>
    )
}

const LogOutButton = () => {
    const dispatch = useDispatch()
    const onLogOut = useCallback(() => {
        dispatch({
            type: ACCOUNT_LOGOUT,
        })
    }, [dispatch])

    return (
        <>
            <Button onClick={onLogOut} type="link" style={{ fontSize: 16 }}>
                Log Out
            </Button>
        </>
    )
}

export default React.memo(LogOutPopover)
