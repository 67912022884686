import { Button, Col, Modal, Row } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as CloseIcon } from '../../../../../../../../../assets/svg/icons/close.svg'
import { getEventStreamSlice } from '../../../../../../../../redux/selectors/common-selectors'

import LocalMediaPreview from './local-media-preview'

const JoinModal = ({ localMedia, onClose, onJoinClick }) => {
    const { joinModalOpen, joinInProgress } = useSelector(getEventStreamSlice)

    const [joinDisabled, setJoinDisabled] = useState(true)

    const footer = useMemo(
        () => (
            <Row justify="center">
                <Col flex="162px">
                    <Button
                        type="primary"
                        block
                        size="large"
                        disabled={joinDisabled || joinInProgress}
                        loading={joinInProgress}
                        onClick={onJoinClick}
                    >
                        Join room
                    </Button>
                </Col>
            </Row>
        ),
        [joinDisabled, joinInProgress, onJoinClick]
    )

    const afterClose = useCallback(() => {
        setJoinDisabled(true)
    }, [])

    const handleLocalMediaStarted = useCallback((disabled) => {
        setJoinDisabled(disabled)
    }, [])

    const handleCancel = useCallback(() => {
        if (!joinInProgress) {
            onClose()
        }
    }, [joinInProgress, onClose])

    return (
        <Modal
            maskClosable={joinInProgress}
            afterClose={afterClose}
            open={joinModalOpen}
            onCancel={handleCancel}
            title="Join the event room"
            closeIcon={<CloseIcon />}
            footer={footer}
            width={600}
            className="tap-event-modal"
            destroyOnClose
        >
            <LocalMediaPreview localMedia={localMedia} onLocalMediaStarted={handleLocalMediaStarted} />
        </Modal>
    )
}

export default JoinModal
