import React, { useCallback, useState, useMemo } from 'react'
import { Button, Modal, Row, Col, Form, Typography, Input } from 'antd'

import { DeleteOutlined } from '@ant-design/icons'

import { useDispatch } from 'react-redux'

import { handleDeleteAmbassador } from '../../../../../redux/actions/content/usersActions'

import { ReactComponent as CloseIcon } from '../../../../../../assets/svg/icons/close.svg'

const { Text } = Typography

const DeleteUserCell = ({ record }) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [modalOpen, setModalOpen] = useState(false)
    const [step, setStep] = useState(1)

    const handleDeleteUser = useCallback(() => {
        setModalOpen(true)
    }, [])

    const handleCloseUserPopup = useCallback(() => {
        setModalOpen(false)
        setStep(1)
        form.resetFields()
    }, [form])

    const handleFirstStepComplete = useCallback(() => {
        form.validateFields().then(() => {
            setStep(2)
        })
    }, [form])

    const handleDeleteUserConfirm = useCallback(() => {
        dispatch(
            handleDeleteAmbassador({
                userId: record.id,
                onSuccess: handleCloseUserPopup,
            })
        )
    }, [record, dispatch, handleCloseUserPopup])

    const footer = useMemo(
        () => (
            <Col style={{ marginTop: '5rem' }}>
                {step === 1 ? (
                    <Row justify={'center'}>
                        <Button
                            type="primary"
                            block
                            size="large"
                            style={{ width: '30%' }}
                            onClick={handleCloseUserPopup}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            block
                            size="large"
                            style={{ width: '30%', marginLeft: '0.8rem' }}
                            onClick={handleFirstStepComplete}
                        >
                            Confirm
                        </Button>
                    </Row>
                ) : (
                    <Row justify={'center'}>
                        <Button
                            type="primary"
                            block
                            size="large"
                            style={{ width: '30%' }}
                            onClick={handleCloseUserPopup}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            block
                            size="large"
                            style={{ width: '30%', marginLeft: '0.8rem' }}
                            onClick={handleDeleteUserConfirm}
                        >
                            Delete
                        </Button>
                    </Row>
                )}
            </Col>
        ),
        [step, handleCloseUserPopup, handleDeleteUserConfirm, handleFirstStepComplete]
    )

    return (
        <>
            <Button type="button" onClick={handleDeleteUser}>
                <DeleteOutlined />
            </Button>

            <Modal
                title="Are you sure?"
                open={modalOpen}
                centered={true}
                footer={footer}
                closeIcon={<CloseIcon />}
                closable={false}
                onCancel={handleCloseUserPopup}
                className="tap-event-modal"
                destroyOnClose
            >
                {step === 1 ? (
                    <>
                        <Form form={form} autoComplete="off" hideRequiredMark>
                            <Row>
                                <Text>
                                    When you delete ambassadors their name is changed to ‘Deleted User’ in chats, they
                                    disappear from TAP feed and reporting.
                                </Text>
                            </Row>
                            <Form.Item
                                name="confirmDelete"
                                label={null}
                                style={{ marginTop: 30 }}
                                rules={[
                                    { required: true, message: 'Type ambassador first name' },
                                    () => ({
                                        validator(_, value) {
                                            if (value && value.toLowerCase() !== record.name.toLowerCase()) {
                                                return Promise.reject(new Error(`Ambassador's name doesn't match`))
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    placeholder="Type an ambassador’s first name in the input below to confirm"
                                    maxLength={50}
                                />
                            </Form.Item>
                        </Form>
                    </>
                ) : (
                    <Row>
                        <Text>We will not be able to recover a deleted user. Click Delete button to proceed.</Text>
                    </Row>
                )}
            </Modal>
        </>
    )
}

const deleteUserCellCellRenderer = (text, record) => <DeleteUserCell text={text} record={record} />

export default deleteUserCellCellRenderer
