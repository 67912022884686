import { Form } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { roles } from '../../../../../helpers/helpers'
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions'
import { UsersSelect } from '../../index'

import UsersFormModel from './users-form-model'

const UsersForm = ({ form, data }) => {
    const dispatch = useDispatch()
    const initialValues = useMemo(() => UsersFormModel(data), [data])

    const { userIds } = initialValues

    useEffect(() => {
        form.resetFields()
    }, [initialValues, form])

    useEffect(() => {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
                roles: [roles.student],
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Form form={form} initialValues={initialValues} layout="vertical">
            <UsersSelect
                id="userIds"
                label="Add chat participants"
                roles={[roles.student]}
                form={form}
                showRoles
                selectedUsers={userIds}
            />
        </Form>
    )
}

export default UsersForm
