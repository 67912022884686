import { useCallback, useEffect, useState } from 'react';
export function useModalVisibility(visible) {
    var _a = useState(visible), isVisible = _a[0], setIsVisible = _a[1];
    useEffect(function () {
        if (visible) {
            setIsVisible(visible);
        }
    }, [visible]);
    var setClose = useCallback(function () {
        setIsVisible(false);
    }, []);
    return [isVisible, setClose];
}
