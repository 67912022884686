import { Card, Col, Row } from 'antd'
import Modal from 'antd/es/modal'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {
    getCurrentDay,
    getCurrentMonthDateRange,
    getDateRangeForMonth,
    isSameDay,
    normalizeDate,
} from '../../../helpers/time'
import {
    CLOSE_ACTION_MODAL,
    CLOSE_SETTINGS_MESSAGING_POPUP,
    OPEN_ACTION_MODAL,
} from '../../../redux/actions/components/componentsActions'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { CLOSE_CONTENT_GROUP_POPUP, OPEN_CONTENT_GROUP_POPUP } from '../../../redux/actions/content/messagingActions'
import {
    deactivateScheduleEvent,
    editScheduleEvent,
    fetchScheduleData,
    fetchScheduleItem,
    updateScheduleEventStatuses,
} from '../../../redux/actions/content/scheduleActions'
import store from '../../../redux/store'
import { CreateContentGroupConnected } from '../messaging/components/popups/CreateContentGroup'
import SettingsContentGroup from '../messaging/components/popups/SettingsContentGroup'

import DayPanel from './DayPanel'
import EventsCalendar from './EventsCalendar'

const getEventForDate = (data, date) => {
    for (const item of data) {
        if (isSameDay(date, item.date)) {
            return item
        }
    }

    return { name: '', description: '' }
}

class Schedule extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedDate: getCurrentDay(),
            activeDateRange: getCurrentMonthDateRange(),
        }

        this.state.selectedEventData = getEventForDate(this.props.data, this.state.selectedDate)

        this.handleDateSelection = this.handleDateSelection.bind(this)
        this.handleMonthChange = this.handleMonthChange.bind(this)
        this.handleSelectEvents = this.handleSelectEvents.bind(this)
        this.handleEditEvent = this.handleEditEvent.bind(this)
        this.handleDeactivateEvent = this.handleDeactivateEvent.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'content',
            },
        })

        this.props.fetchScheduleData(this.state.activeDateRange)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.props.fetchScheduleData(this.state.activeDateRange)
        }
    }

    static getDerivedStateFromProps(props, state) {
        const selectedEventData = getEventForDate(props.data, state.selectedDate)
        if (selectedEventData !== state.selectedEventData) {
            state.selectedEventData = selectedEventData

            return state
        }

        return null
    }

    showStatusChangeConfirmationPopup(eventId) {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button
                                type="submit"
                                className="el-btn confirm-delete-button"
                                onClick={() => this.props.deactivateScheduleEvent(eventId)}
                            >
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={this.closeConfirmationPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    closeConfirmationPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    handleDateSelection(newDate) {
        this.setState({ selectedDate: newDate })
    }

    handleMonthChange(newDate) {
        const activeDateRange = getDateRangeForMonth(newDate)

        this.props.fetchScheduleData(activeDateRange)

        this.setState({ activeDateRange, selectedDate: newDate })
    }

    handleSelectEvents(eventIds, value) {
        this.props.updateScheduleEventStatuses(eventIds, value)
    }

    handleEditEvent(eventId) {
        this.props.fetchScheduleItem(eventId)
    }

    handleDeactivateEvent(eventId) {
        this.showStatusChangeConfirmationPopup(eventId)
    }

    handleContentGroupCreate() {
        store.dispatch({
            type: OPEN_CONTENT_GROUP_POPUP,
        })
    }

    handleCloseContentGroupPopup() {
        store.dispatch({
            type: CLOSE_CONTENT_GROUP_POPUP,
        })
    }

    handleCloseContentGroupSettingsPopup() {
        store.dispatch({
            type: CLOSE_SETTINGS_MESSAGING_POPUP,
        })
    }

    render() {
        return (
            <>
                <section className="content reporting">
                    <div className="content-schedule-title">Schedule</div>
                    <div className="content-schedule-intro">
                        Use the schedule to set automated content requests for your ambassadors.
                    </div>
                    <Card className="reporting-block">
                        <Row>
                            <Col span={15}>
                                <EventsCalendar
                                    date={this.state.selectedDate}
                                    data={this.props.data}
                                    onDateSelection={this.handleDateSelection}
                                    onMonthChange={this.handleMonthChange}
                                    onSelect={this.handleSelectEvents}
                                />
                            </Col>
                            <Col span={1}>
                                <div className="schedule-divider" />
                            </Col>
                            <Col span={8}>
                                <DayPanel
                                    date={this.state.selectedDate}
                                    id={this.state.selectedEventData.id}
                                    isDefault={this.state.selectedEventData.is_default}
                                    name={this.state.selectedEventData.name}
                                    description={this.state.selectedEventData.description}
                                    archived={this.state.selectedEventData.closed}
                                    onEdit={this.handleEditEvent}
                                    onDeactivate={this.handleDeactivateEvent}
                                    onGroupCreate={this.handleContentGroupCreate}
                                />
                            </Col>
                        </Row>
                    </Card>
                </section>
                <Modal
                    open={this.props.createContentGroupModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseContentGroupPopup}
                    destroyOnClose
                    className="react-create-content-group"
                >
                    <CreateContentGroupConnected
                        onClosePopup={this.handleCloseContentGroupPopup}
                        type="scheduled-content-group"
                        scheduledDay={normalizeDate(this.state.selectedDate)}
                        isSсheduledContentGroup={true}
                    />
                </Modal>

                <Modal
                    open={this.props.settingsMessagingModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseContentGroupSettingsPopup}
                    destroyOnClose
                    className="react-create-content-group"
                >
                    <SettingsContentGroup
                        activeContentGroup={this.props.activeSсheduleItem}
                        onClosePopup={this.handleCloseContentGroupSettingsPopup}
                        isSсheduledContentGroup={true}
                        editScheduleEvent={this.props.editScheduleEvent}
                        scheduledDay={normalizeDate(this.state.selectedDate)}
                    />
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    data: state.schedule.data,
    activeSсheduleItem: state.schedule.activeSсheduleItem,
    createContentGroupModal: state.createContentGroupModal,
    settingsMessagingModal: state.settingsMessagingModal,
})

export default connect(mapStateToProps, {
    fetchScheduleData,
    editScheduleEvent,
    deactivateScheduleEvent,
    updateScheduleEventStatuses,
    fetchScheduleItem,
})(Schedule)
