var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable camelcase */
import { Form, Input, Modal, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
export var AdditionalParameters = function (_a) {
    var form = _a.form, initialValues = _a.initialValues, setTouched = _a.setTouched;
    var fieldsNames = ['custom_1', 'custom_2', 'custom_3'];
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var _c = useState({
        custom_1: false,
        custom_2: false,
        custom_3: false,
    }), switchStates = _c[0], setSwitchStates = _c[1];
    var _d = useState({
        fieldName: '',
        checked: false,
    }), activeSwitchState = _d[0], setActiveSwitchState = _d[1];
    useEffect(function () {
        setSwitchStates(function (prevStates) { return (__assign(__assign({}, prevStates), { custom_1: initialValues === null || initialValues === void 0 ? void 0 : initialValues.custom_1_unique, custom_2: initialValues === null || initialValues === void 0 ? void 0 : initialValues.custom_2_unique, custom_3: initialValues === null || initialValues === void 0 ? void 0 : initialValues.custom_3_unique })); });
    }, [initialValues]);
    var onChange = function (fieldName, checked) {
        setIsModalOpen(true);
        setActiveSwitchState(function (prevStates) { return (__assign(__assign({}, prevStates), { fieldName: fieldName, checked: checked })); });
    };
    var onConfirm = function () {
        fieldsNames.forEach(function (switchName) {
            setSwitchStates(function (prevStates) {
                var _a;
                return (__assign(__assign({}, prevStates), (_a = {}, _a[switchName] = switchName !== activeSwitchState.fieldName ? false : activeSwitchState.checked, _a)));
            });
            form.setFieldValue("".concat(switchName, "_unique"), switchName !== activeSwitchState.fieldName ? false : activeSwitchState.checked);
        });
        setIsModalOpen(false);
        setTouched(true);
    };
    var handleCloseConfirmPopup = function () {
        setIsModalOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "mb-2.5 text-reading-lg font-interface-bold" }, "Additional custom fields"),
        React.createElement("p", { className: "text-reading-base mb-2.5" }, "Use the fields below if you need to pass some additional parameters for enabling SSO-powered authorisation."),
        React.createElement("div", { className: "sso-custom-field-row mb-2.5" },
            React.createElement("p", null, "This is the name of the parameter in your SSO App / provider"),
            React.createElement("p", { className: "col-span-2" },
                "This is how your custom field will be returned to our\u00A0",
                React.createElement("a", { target: "_blank", href: "https://public-api.theambassadorplatform.com/docs#tag/Users/operation/UsersController_getProspects", className: "text-brand-orange font-interface-bold", rel: "noreferrer" }, "public API"),
                "\u00A0 so that you can pull it from there")),
        fieldsNames.map(function (field, index) {
            return (React.createElement("div", { key: index, className: "sso-custom-field-row" },
                React.createElement(Form.Item, { name: "".concat(field, "_name"), className: "sso-field-item" },
                    React.createElement(Input, { placeholder: "e.g. field_of_study" })),
                React.createElement(Form.Item, { name: "".concat(field, "_reference"), className: "sso-field-item" },
                    React.createElement(Input, { placeholder: "e.g. studyField" })),
                React.createElement(Form.Item, { className: "sso-field-item" },
                    React.createElement(Switch, { onChange: function (checked) { return onChange("".concat(field), checked); }, checked: switchStates["".concat(field)] }),
                    React.createElement("span", { className: "ml-1" }, "This is a unique Identifier")),
                React.createElement(Form.Item, { name: "".concat(field, "_unique"), noStyle: true, valuePropName: "checked" },
                    React.createElement(Switch, { style: { display: 'none' } }))));
        }),
        React.createElement(Modal, { centered: true, destroyOnClose: true, open: isModalOpen, footer: null, closeIcon: null },
            React.createElement("div", { className: "p-8 text-center" },
                React.createElement("h3", { className: "text-header-sm" }, "Are you sure?"),
                React.createElement("p", { className: "text-reading-lg my-6" },
                    "This parameter is a unique identifier. There can be\u00A0",
                    React.createElement("span", { className: "font-interface-bold" }, "only one unique identifier"),
                    " from the custom parameters. If you turn it off some information that is stored about your users in TAP may not be provided back to your SSO app in case of any changes"),
                React.createElement("div", { className: "btn-wrap confirm-ban-buttons" },
                    React.createElement("button", { type: "submit", className: "el-btn confirm-delete-button", onClick: onConfirm }, "Yes, I am sure"),
                    React.createElement("button", { type: "button", className: "el-btn cancel-delete-button", onClick: handleCloseConfirmPopup }, "Cancel"))))));
};
