var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button } from 'antd';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
var validate = function (values) {
    var errors = {};
    if (!values.old_password) {
        errors.old_password = 'Old Please enter your password';
    }
    if (!values.new_password) {
        errors.new_password = 'Please enter a password';
    }
    else if (!/^(?=.*?[\p{Ll}])(?=.*?[\p{Lu}])(?=.*\d)(?=.*?[\W_]).{8,}$/gu.test(values.new_password)) {
        errors.new_password =
            'Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol';
    }
    if (!values.confirm_password) {
        errors.confirm_password = 'Please enter your password';
    }
    else if (!/^(?=.*?[\p{Ll}])(?=.*?[\p{Lu}])(?=.*\d)(?=.*?[\W_]).{8,}$/gu.test(values.confirm_password)) {
        errors.confirm_password =
            'Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol';
    }
    if (values.new_password != values.confirm_password) {
        errors.confirm_password = 'Passwords don’t match';
    }
    return errors;
};
var renderField = function (_a) {
    var input = _a.input, label = _a.label, placeholder = _a.placeholder, type = _a.type, className = _a.className, _b = _a.meta, touched = _b.touched, error = _b.error, warning = _b.warning;
    return (React.createElement("div", null,
        React.createElement("input", __assign({}, input, { className: className, placeholder: placeholder, type: type })),
        touched && ((error && React.createElement("span", { className: "error" }, error)) || (warning && React.createElement("span", null, warning)))));
};
/**
 * @deprecated
 * Have to be refactored. Redux forn is not used anymore
 */
function PasswordEditForm(_a) {
    var handleSubmit = _a.handleSubmit, onCancel = _a.onCancel;
    return (React.createElement("form", { action: "#", method: "POST", className: "hidden-form", onSubmit: handleSubmit },
        React.createElement("label", { className: "signin-label" },
            React.createElement(Field, { name: "old_password", component: renderField, type: "password", placeholder: "Old Password", className: "signin-input mod-hidden settings-input" })),
        React.createElement("label", { className: "signin-label" },
            React.createElement(Field, { name: "new_password", component: renderField, type: "password", placeholder: "New Password", className: "signin-input mod-hidden settings-input" })),
        React.createElement("label", { className: "signin-label" },
            React.createElement(Field, { name: "confirm_password", component: renderField, type: "password", placeholder: "Confirm Password", className: "signin-input mod-hidden settings-input" })),
        React.createElement("div", { className: "hidden-buttons-block" },
            React.createElement(Button, { htmlType: "submit", className: "settings-btn", type: "link", style: { paddingLeft: 0 }, "data-automation-id": "submit-password" }, "Submit"),
            React.createElement(Button, { className: "settings-btn mod-cancel js-hide-form", type: "link", onClick: onCancel, "data-automation-id": "cancel-password-change" }, "Cancel"))));
}
export default reduxForm({
    form: 'password_edit_form',
    validate: validate,
})(PasswordEditForm);
