import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Typography } from 'antd'

import { openCampaignTypeModal } from '../../../../redux/actions/campaign/campaign-actions'

const { Title } = Typography

const CampaignsListHeader = () => {
    const dispatch = useDispatch()

    const handleCreateCampaignClick = useCallback(() => {
        dispatch(openCampaignTypeModal())
    }, [dispatch])

    return (
        <Row align="space-between" style={{ alignItems: 'center' }}>
            <Title>Campaigns</Title>
            <a onClick={handleCreateCampaignClick} className="el-btn create-event-popup-btn">
                Create a new campaign
            </a>
        </Row>
    )
}

export default CampaignsListHeader
