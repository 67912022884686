import API from '../../../api'
import { call, put, select } from 'redux-saga/effects'
import {
    CREATE_QUESTION_SUCCESS,
    CREATE_QUESTION_FAILED,
    QUESTIONS_INFO_SUCCESS,
    QUESTIONS_INFO_FAILED,
    QUESTIONS_ANSWERS_INFO_FAILED,
    QUESTIONS_ANSWERS_INFO_SUCCESS,
    EDIT_ANSWER_FAILED,
    EDIT_ANSWER_SUCCESS,
    QUESTIONS_ANSWERS_INFO,
    ANSWER_TOGGLE_PUBLISH_FAILED,
    ANSWER_TOGGLE_PUBLISH_SUCCESS,
    QUESTIONS_INFO,
    DELETE_QUESTION_SUCCESS,
    EDIT_FAQ_SUCCESS,
    EDIT_FAQ_FAILED,
    DELETE_QUESTION_FAILED,
    DELETE_ANSWER_SUCCESS,
    DELETE_ANSWER_FAILED,
    EDIT_FAQ_GLOBAL_TAG_FINISH,
} from '../../actions/faq/faqActions'

import { CLOSE_CREATE_QUESTION_POPUP } from '../../actions/faq/componentsActions'

import { OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* createQuestion(action) {
    const payload = action.payload

    const isGroupAdmin = yield select(getGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

    let universityId = null
    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        universityId = currentGroupUniversityId || payload.university_id
    }
    try {
        const response = yield call(() => {
            return API.faq.create(payload, universityId)
        })

        if (response.success) {
            yield put({
                type: CREATE_QUESTION_SUCCESS,
            })

            if (window.location.pathname.includes('/faq/library')) {
                yield put({
                    type: QUESTIONS_ANSWERS_INFO,
                    payload: {
                        filter: {
                            published: true,
                        },
                    },
                })
            } else if (window.location.pathname.includes('/faq/new-answers')) {
                yield put({
                    type: QUESTIONS_ANSWERS_INFO,
                    payload: {
                        filter: {
                            published: false,
                        },
                    },
                })
            } else {
                yield put({
                    type: QUESTIONS_INFO,
                    payload: {
                        filter: {
                            no_answers: true,
                        },
                    },
                })
            }

            yield put({
                type: CLOSE_CREATE_QUESTION_POPUP,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your question has been created',
                },
            })

            if (payload.funcFinish) payload.funcFinish()
        } else {
            yield put({
                type: CREATE_QUESTION_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CREATE_QUESTION_FAILED,
        })
    }
}

export function* deleteQuestion(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.faq.deleteQuestion(payload)
        })

        if (response.success) {
            yield put({
                type: DELETE_QUESTION_SUCCESS,
            })

            if (window.location.pathname.includes('/faq/library')) {
                yield put({
                    type: QUESTIONS_ANSWERS_INFO,
                    payload: {
                        filter: {
                            published: true,
                        },
                    },
                })
            } else if (window.location.pathname.includes('/faq/new-answers')) {
                yield put({
                    type: QUESTIONS_ANSWERS_INFO,
                    payload: {
                        filter: {
                            published: false,
                        },
                    },
                })
            } else {
                yield put({
                    type: QUESTIONS_INFO,
                    payload: {
                        filter: {
                            no_answers: true,
                        },
                    },
                })
            }

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Question was deleted',
                },
            })

            if (payload.funcFinish) payload.funcFinish()
        } else {
            yield put({
                type: DELETE_QUESTION_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: DELETE_QUESTION_FAILED,
        })
    }
}

export function* editFAQ(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.faq.editFAQ(payload.faqID, payload.options)
        })

        if (response.success) {
            yield put({
                type: EDIT_FAQ_SUCCESS,
            })

            yield put({
                type: CLOSE_CREATE_QUESTION_POPUP,
            })

            yield payload.isAnsweredQuestions
                ? put({
                      type: QUESTIONS_ANSWERS_INFO,
                      payload: {
                          filter: {
                              published: payload.isPublished,
                              globalTagsIds: [],
                          },
                      },
                  })
                : put({
                      type: QUESTIONS_INFO,
                      payload: {
                          filter: {
                              no_answers: true,
                          },
                      },
                  })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Question edited',
                },
            })
        } else {
            yield put({
                type: EDIT_FAQ_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: EDIT_FAQ_FAILED,
        })
    }
}

export function* questions(action) {
    const payload = action.payload

    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        const response = yield call(() => {
            return API.faq.info(payload.filter, universityIds)
        })
        yield response.success
            ? put({
                  type: QUESTIONS_INFO_SUCCESS,
                  payload: {
                      items: response.data.faqs,
                      total: response.data.total,
                  },
              })
            : put({
                  type: QUESTIONS_INFO_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: QUESTIONS_INFO_FAILED,
        })
    }
}

export function* questionsAnswers(action) {
    const payload = action.payload

    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        const response = yield call(() => {
            return API.faq.info(payload.filter, universityIds)
        })

        yield response.success
            ? put({
                  type: QUESTIONS_ANSWERS_INFO_SUCCESS,
                  payload: {
                      items: response.data.faqs,
                      total: response.data.total,
                      filter: payload.filter,
                  },
              })
            : put({
                  type: QUESTIONS_ANSWERS_INFO_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: QUESTIONS_ANSWERS_INFO_FAILED,
        })
    }
}

export function* editAnswer(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.faq.editAnswer(payload.answer_id, payload.answer)
        })

        if (response.success) {
            yield put({
                type: EDIT_ANSWER_SUCCESS,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Answer edited',
                },
            })

            yield put({
                type: QUESTIONS_ANSWERS_INFO,
                payload: {
                    filter: payload.filter,
                },
            })
        } else {
            yield put({
                type: EDIT_ANSWER_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: EDIT_ANSWER_FAILED,
        })
    }
}

export function* deleteAnswer(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.faq.deleteAnswer(payload)
        })

        if (response.success) {
            yield put({
                type: DELETE_ANSWER_SUCCESS,
            })

            yield window.location.pathname.includes('/faq/library')
                ? put({
                      type: QUESTIONS_ANSWERS_INFO,
                      payload: {
                          filter: {
                              published: true,
                          },
                      },
                  })
                : put({
                      type: QUESTIONS_ANSWERS_INFO,
                      payload: {
                          filter: {
                              published: false,
                          },
                      },
                  })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Answer was deleted',
                },
            })
        } else {
            yield put({
                type: DELETE_ANSWER_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: DELETE_ANSWER_FAILED,
        })
    }
}

export function* togglePublished(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.faq.togglePublished(payload.answer_id)
        })

        if (response.success) {
            yield put({
                type: ANSWER_TOGGLE_PUBLISH_SUCCESS,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: payload.published ? 'Answer unpublished' : 'Answer published',
                },
            })

            yield put({
                type: QUESTIONS_ANSWERS_INFO,
                payload: {
                    filter: {
                        published: payload.published,
                    },
                },
            })
        } else {
            yield put({
                type: ANSWER_TOGGLE_PUBLISH_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ANSWER_TOGGLE_PUBLISH_FAILED,
        })
    }
}

export function* changeFaqGlobalTags(action) {
    yield put({
        type: EDIT_FAQ_GLOBAL_TAG_FINISH,
        payload: action.payload,
    })
}
