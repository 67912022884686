import API from '../../../../api'
import { call, put, select } from 'redux-saga/effects'

import {
    UPLOAD_COMPANIES_CSV_SUCCESS,
    GET_COMPANIES_DATA_SUCCESS,
    ADD_COMPANY_SUCCESS,
    DELETE_COMPANY_SUCCESS,
    EDIT_COMPANY_SUCCESS,
} from '../../../actions/settings/companies-control'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* uploadCompanies(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
        if (isGroupAdmin && currentGroupUniversityId) {
            payload.append('universityId', currentGroupUniversityId)
        }
        const response = yield call(() => API.settings.uploadCompanies(payload))

        if (response.success) {
            yield put({
                type: UPLOAD_COMPANIES_CSV_SUCCESS,
                payload: response.data,
            })
        }
    } catch (err) {}
}

export function* getCompanies(action) {
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            if (isGroupAdmin && !universityId) {
                return API.settings.getCompanies()
            } else {
                return API.settings.getCompanies(universityId)
            }
        })

        if (response.success) {
            yield put({
                type: GET_COMPANIES_DATA_SUCCESS,
                payload: response.data,
            })
        }
    } catch (e) {
        console.log(e)
    }
}

export function* addCompany(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            const data = payload

            if (isGroupAdmin && !universityId) {
                return API.settings.addCompany(data)
            } else {
                data.universityId = universityId
                return API.settings.addCompany(data)
            }
        })

        if (response.success) {
            yield put({
                type: ADD_COMPANY_SUCCESS,
                payload: response.data,
            })
        }
    } catch (e) {
        console.log(e)
    }
}

export function* editCompany(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            const data = payload

            if (isGroupAdmin && !universityId) {
                return API.settings.editCompany(data)
            } else {
                data.universityId = universityId
                return API.settings.editCompany(data)
            }
        })

        if (response.success) {
            if (payload.isEdit) {
                yield put({
                    type: EDIT_COMPANY_SUCCESS,
                    payload: response.data[0],
                })
            } else {
                yield put({
                    type: DELETE_COMPANY_SUCCESS,
                    payload: response.data[0],
                })
            }
        }
    } catch (e) {
        console.log(e)
    }
}
