import { Modal, Typography, Row, Col, Button } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LIVE_EVENTS } from '../../redux/actions/live-event/live-event-action-types'
import { LIVE_STREAMS } from '../../redux/actions/live-streams/live-streams-action-types'
import { cancelLiveEvent, startLiveEvent, stopLiveEvent } from '../../redux/actions/live-event/live-events-actions'
import { unpinMessage } from '../../redux/actions/chat/messagingActions'

import { updateLiveStream } from '../../redux/actions/live-streams/live-streams-actions'
import { closeNotificationModal } from '../../redux/actions/notification-modal/notification-modal-actions'
import { getNotificationModalSlice } from '../../redux/selectors'

const { Title } = Typography

const NotificationModalWrapper = () => {
    const dispatch = useDispatch()
    const { open, type, data } = useSelector(getNotificationModalSlice)

    const handleCancel = useCallback(() => {
        dispatch(closeNotificationModal())
    }, [dispatch])

    /* const handleAddToCalendar = useCallback(() => {
        dispatch(addToCalendar())
    }, [dispatch]) */

    const handleStartEventConfirm = useCallback(() => {
        const { id } = data
        dispatch(startLiveEvent({ id }))
    }, [dispatch, data])

    const handleStopEventConfirm = useCallback(() => {
        const { id } = data
        dispatch(
            stopLiveEvent({
                id,
            })
        )
    }, [dispatch, data])

    const handleCancelEventConfirm = useCallback(() => {
        const { id } = data
        dispatch(
            cancelLiveEvent({
                id,
            })
        )
    }, [dispatch, data])

    const handleUpdateStatusConfirm = useCallback(() => {
        const { liveStreamId, status } = data
        dispatch(
            updateLiveStream({
                liveStreamId,
                status,
                editType: 'status',
            })
        )
    }, [dispatch, data])

    const handleArchiveConfirm = useCallback(() => {
        const { liveStreamId, state } = data
        dispatch(
            updateLiveStream({
                liveStreamId,
                isArchived: state !== 'public',
                editType: 'archive',
            })
        )
    }, [dispatch, data])

    const handleUnpinMessage = useCallback(() => {
        const { messageId, dialogId } = data
        dispatch(unpinMessage({ messageId, dialogId }))
    }, [dispatch, data])

    const modalBody = useMemo(() => {
        if (!type) return <></>

        if (type === LIVE_EVENTS.CREATE_SUCCESS) {
            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>Live event was created</Title>
                            <p style={{ textAlign: 'center' }}>
                                Notifications for speakers and chat participants were sent.
                                {/*
                                <br />
                                Do you want to add this event to your calendar?
                                */}
                            </p>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col>
                            <Button type="primary" size="large" onClick={handleCancel}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_EVENTS.UPDATE_SUCCESS || type === LIVE_STREAMS.UPDATE_SUCCESS) {
            const { status } = data
            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>
                                {`Your stream has been ${
                                    status === 'inProgress' ? 'started' : status === 'completed' ? 'finished' : 'edited'
                                }`}
                            </Title>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col>
                            <Button onClick={handleCancel} type="primary" size="large">
                                Close
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_EVENTS.START_EVENT_CONFIRM) {
            const { name } = data
            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>
                                Do you want to start the event <span className="tap-color-primary">{`'${name}'`}</span>?
                            </Title>
                        </Col>
                    </Row>

                    <Row justify="center" gutter={20}>
                        <Col flex="11rem">
                            <Button onClick={handleStartEventConfirm} block type="primary" size="large">
                                Start
                            </Button>
                        </Col>
                        <Col flex="11rem">
                            <Button onClick={handleCancel} block type="default" size="large">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_EVENTS.STOP_EVENT_CONFIRM) {
            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>Are you sure you want to stop the stream?</Title>
                        </Col>
                    </Row>

                    <Row justify="center" gutter={20}>
                        <Col flex="11rem">
                            <Button onClick={handleStopEventConfirm} block type="primary" size="large">
                                Stop
                            </Button>
                        </Col>
                        <Col flex="11rem">
                            <Button onClick={handleCancel} block type="default" size="large">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_EVENTS.CANCEL_EVENT_CONFIRM) {
            const { name } = data

            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>
                                Do you want to cancel the event <span className="tap-color-primary">{`'${name}'`}</span>
                                ?
                            </Title>
                        </Col>
                    </Row>

                    <Row justify="center" gutter={20}>
                        <Col flex="11rem">
                            <Button onClick={handleCancelEventConfirm} block type="primary" size="large">
                                Yes
                            </Button>
                        </Col>
                        <Col flex="11rem">
                            <Button onClick={handleCancel} block type="default" size="large">
                                No
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_STREAMS.CREATE_SUCCESS) {
            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>Live stream was created</Title>
                            <p style={{ textAlign: 'center' }}>
                                Notifications for participants were sent.
                                {/*
                                <br />
                                Do you want to add this event to your calendar?
                                */}
                            </p>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col>
                            <Button type="primary" size="large" onClick={handleCancel}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_STREAMS.ARCHIVE_STREAM) {
            const { name, state } = data

            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>
                                Do you want to {`${state !== 'public' ? 'archive' : 'publish'}`} the stream{' '}
                                <span className="tap-color-primary">{` '${name}' `}</span>?
                            </Title>
                        </Col>
                    </Row>

                    <Row justify="center" gutter={20}>
                        <Col flex="11rem">
                            <Button onClick={handleArchiveConfirm} block type="primary" size="large">
                                Yes
                            </Button>
                        </Col>
                        <Col flex="11rem">
                            <Button onClick={handleCancel} block type="default" size="large">
                                No
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_STREAMS.START_STREAM_CONFIRM) {
            const { name } = data
            return (
                <div style={{ padding: '2rem 2rem' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>
                                Do you want to start the stream <span className="tap-color-primary">{`'${name}'`}</span>
                                &nbsp;?
                            </Title>
                        </Col>
                    </Row>

                    <Row justify="center" gutter={20}>
                        <Col flex="11rem">
                            <Button onClick={handleUpdateStatusConfirm} block type="primary" size="large">
                                Start
                            </Button>
                        </Col>
                        <Col flex="11rem">
                            <Button onClick={handleCancel} block type="default" size="large">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_STREAMS.CANCEL_STREAM_CONFIRM) {
            const { name } = data

            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>
                                Do you want to cancel the stream{' '}
                                <span className="tap-color-primary">{`'${name}'`}</span>?
                            </Title>
                        </Col>
                    </Row>

                    <Row justify="center" gutter={20}>
                        <Col flex="11rem">
                            <Button onClick={handleUpdateStatusConfirm} block type="primary" size="large">
                                Yes
                            </Button>
                        </Col>
                        <Col flex="11rem">
                            <Button onClick={handleCancel} block type="default" size="large">
                                No
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_STREAMS.SHARE_STREAM_RECORD_SUCCESS) {
            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>Success</Title>
                            <p style={{ textAlign: 'center' }}>You have shared the video recording.</p>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col>
                            <Button type="primary" size="large" onClick={handleCancel}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_STREAMS.STOP_STREAM_CONFIRM) {
            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>Are you sure you want to stop the stream?</Title>
                        </Col>
                    </Row>

                    <Row justify="center" gutter={20}>
                        <Col flex="11rem">
                            <Button onClick={handleUpdateStatusConfirm} block type="primary" size="large">
                                Stop
                            </Button>
                        </Col>
                        <Col flex="11rem">
                            <Button onClick={handleCancel} block type="default" size="large">
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === LIVE_STREAMS.JOIN_STREAM_LOBBY) {
            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>The Live Stream is not started yet. You are in the lobby.</Title>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col>
                            <Button type="primary" size="large" onClick={handleCancel}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }
        if (type === 'UPLOAD_FILE_INFO_SUCCESS') {
            return (
                <div style={{ padding: '2rem 0' }}>
                    <Row justify="center" style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Title level={3}>Success</Title>
                            <p style={{ textAlign: 'center' }}>
                                Your content was uploaded. You will see it now in the gallery.
                            </p>
                        </Col>
                    </Row>

                    <Row justify="center">
                        <Col>
                            <Button type="primary" size="large" onClick={handleCancel}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        if (type === 'UNPIN_MESSAGE') {
            return (
                <Col className="general-popup">
                    <Title level={2} className="modal-title">
                        Are you sure you want to unpin the message?
                    </Title>
                    <Row justify={'center'}>
                        <button className="el-btn confirm-delete-button" onClick={handleUnpinMessage}>
                            Confirm
                        </button>
                        <button className="el-btn cancel-delete-button" onClick={handleCancel}>
                            Cancel
                        </button>
                    </Row>
                </Col>
            )
        }

        return <></>
    }, [
        type,
        data,
        handleStartEventConfirm,
        handleStopEventConfirm,
        handleCancelEventConfirm,
        handleCancel,
        handleUpdateStatusConfirm,
        handleArchiveConfirm,
        handleUnpinMessage,
    ])

    return (
        <Modal
            centered
            open={open}
            destroyOnClose
            closeIcon={<></>}
            className="tap-notification-modal"
            onCancel={handleCancel}
            footer={false}
            width={500}
        >
            {modalBody}
        </Modal>
    )
}

export default NotificationModalWrapper
