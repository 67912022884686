import { Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatType } from '../../../../../common/constants';
import { CHATS_INFO_SEARCH } from '../../../../../redux/actions/chat/messagingActions';
import { getChatsSlice } from '../../../../../redux/selectors';
var Option = Select.Option;
var GroupChatSelect = function (_a) {
    var onGroupChatSelect = _a.onGroupChatSelect;
    var dispatch = useDispatch();
    var groupChats = useSelector(getChatsSlice).items;
    var options = useMemo(function () {
        return groupChats.map(function (chats) {
            return (React.createElement(Option, { key: chats.id, value: chats.id, label: chats.name, privateCode: chats.is_private ? chats.private_code : null }, "".concat(chats.name)));
        });
    }, [groupChats]);
    useEffect(function () {
        dispatch({
            type: CHATS_INFO_SEARCH,
            payload: {
                withGlobalTags: true,
                type_chat: ChatType.GROUP_CHAT,
                archived: false,
            },
        });
    }, [dispatch]);
    return (React.createElement(Select, { size: "large", style: { width: 'calc(80% + 2rem + 2px)' }, onChange: onGroupChatSelect, filterOption: false, placeholder: "Select group chat" }, options));
};
export default GroupChatSelect;
