import { Button } from 'antd'
import liveswitch from 'fm.liveswitch'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    closeJoinModal,
    joinStream,
    leaveStream,
    openJoinModal,
} from '../../../../../../redux/actions/live-event/live-events-actions'
import { getLiveEventJoinModal, getLiveEventsSlice } from '../../../../../../redux/selectors'
import config from '../../../../../../system/config'

import { StreamControls } from './components'
import JoinEventModal from './components/join-event-modal'
import { pluckIds } from '../../../../../../common/utils/array-utils'
import API from '../../../../../../api'
import { roles } from '../../../../../../helpers/helpers'

const gatewayUrl = config.liveSwitch
const applicationId = config.liveSwitchAppId

const VIDEO_CONFIG = {
    WIDTH: 640,
    HEIGHT: 480,
    FPS: 30,
}

const maxBitRate = 2000

const deviceId = liveswitch.Guid.newGuid().toString().replace(/-/g, '')

const adjustSfuConnectionBitrate = (maxBitrate, sfuConnection) => {
    console.log('Adjusting max bitratsetSimulcastModee to: ' + maxBitrate)
    // This is the magic, changes the video send bitrate.
    const videoStream = sfuConnection.getVideoStream()
    videoStream.setMaxSendBitrate(maxBitrate)
}

const adjustBitrateBasedOnChannel = (channel, sfuConnection) => {
    const numberOfParticipants = channel.getRemoteClientInfos().length + 1
    adjustSfuConnectionBitrate(maxBitRate / numberOfParticipants, sfuConnection)
}

const EventVideoStream = ({ account, activeEvent }) => {
    const dispatch = useDispatch()

    const { open: joinModalOpen } = useSelector(getLiveEventJoinModal)
    const { joinedStream } = useSelector(getLiveEventsSlice)
    const { account_info: accountInfo } = account
    const { id, name, last_name: lastName } = accountInfo

    const { slug, speakers } = activeEvent

    const eventVideoStreamRef = useRef(null)

    const [isOnline, setIsOnline] = useState(false)
    const [joinInProgress, setJoinInProgress] = useState(false)
    const [leaveInProgress, setLeaveInProgress] = useState(false)

    const [videoDevices, setVideoDevices] = useState([])
    const [audioDevices, setAudioDevices] = useState([])

    const [localAudioMuted, setLocalAudioMuted] = useState(false)
    const [localVideoMuted, setLocalVideoMuted] = useState(false)

    const client = useRef(null)
    const channel = useRef(null)
    const localMedia = useRef(null)
    const layoutManager = useRef(null)
    const upstreamConnection = useRef(null)
    const downstreamConnections = useRef({})
    const localScreenMedia = useRef(new liveswitch.LocalMedia(false, true, true))
    const screenSharingUpstreamConnection = useRef(null)

    const isSpeaker = useMemo(() => pluckIds(speakers).includes(id), [speakers, id])

    const isAdminSpeaker = useMemo(
        () => speakers.some(({ id: roleId, role_key: role }) => roleId === id && role === roles.admin),
        [accountInfo, speakers]
    )

    const { userId, userName } = useMemo(() => {
        return {
            userId: id + '',
            userName: `${name} ${lastName}`,
        }
    }, [id, name, lastName])

    useEffect(() => {
        localScreenMedia.current.addOnVideoStarted(() => {
            localScreenMedia.current.getVideoTrack().addOnStopped(() => {
                toggleScreenSharing()
            })
        })

        if (localMedia.current === null) {
            const audioEnabled = true
            const videoEnabled = new liveswitch.VideoConfig(VIDEO_CONFIG.WIDTH, VIDEO_CONFIG.HEIGHT, VIDEO_CONFIG.FPS)
            localMedia.current = new liveswitch.LocalMedia(audioEnabled, videoEnabled)
            layoutManager.current = new liveswitch.DomLayoutManager(eventVideoStreamRef.current)

            localMedia.current.getVideoSourceInputs().then((inputs) => {
                setVideoDevices(inputs)
            })
            localMedia.current.getAudioSourceInputs().then((inputs) => {
                setAudioDevices(inputs)
            })
        }

        if (!isSpeaker) {
            joinAsync().then(() => {
                setIsOnline(true)
                setJoinInProgress(false)
                dispatch(joinStream())
                if (isSpeaker) {
                    dispatch(closeJoinModal())
                    layoutManager.current.setLocalMedia(localMedia.current)
                }
            })
        }

        return () => {
            if (client.current !== null) {
                client.current.unregister()
            }
            if (localMedia.current !== null) {
                localMedia.current.stop()
            }
        }
    }, [])

    const openSfuUpstreamConnection = (localMedia) => {
        // Create audio and video streams from local media.
        const audioStream = localMedia.getAudioTrack() !== null ? new liveswitch.AudioStream(localMedia) : null
        const videoStream = localMedia.getVideoTrack() !== null ? new liveswitch.VideoStream(localMedia) : null
        videoStream.setSimulcastMode(liveswitch.SimulcastMode.RtpStreamId)

        // Create a SFU upstream connection with local audio and video.
        const connection = channel.current.createSfuUpstreamConnection(audioStream, videoStream)

        connection.addOnStateChange((conn) => {
            console.log(`Upstream connection is ${new liveswitch.ConnectionStateWrapper(conn.getState()).toString()}.`)

            if (
                conn.getState() === liveswitch.ConnectionState.Closing ||
                conn.getState() === liveswitch.ConnectionState.Failing
            ) {
                if (conn.getRemoteClosed()) {
                    liveswitch.Log.info(`Upstream connection ${conn.getId()} was closed`)
                }
            } else if (conn.getState() === liveswitch.ConnectionState.Failed) {
                openSfuUpstreamConnection(localMedia)
            }
        })

        connection.open()
        return connection
    }

    const onClientRegistered = useCallback(
        (channels) => {
            channel.current = channels[0]

            channel.current.addOnRemoteClientJoin((remoteConnectionInfo) => {
                console.log('addOnRemoteClientJoin remoteConnectionInfo', remoteConnectionInfo)
                console.log('channel.current.getRemoteClientInfos()', channel.current.getRemoteClientInfos())
                const userRemoteId = remoteConnectionInfo.getUserId()

                for (const speaker of speakers) {
                    if (+userRemoteId === +speaker.id) {
                        adjustBitrateBasedOnChannel(channel.current, upstreamConnection.current)
                    }
                }
            })

            channel.current.addOnRemoteUpstreamConnectionOpen((remoteConnectionInfo) => {
                openSfuDownstreamConnection(remoteConnectionInfo, layoutManager.current, channel.current)
            })

            if (isSpeaker) {
                upstreamConnection.current = openSfuUpstreamConnection(localMedia.current)
            }
        },
        [speakers, isSpeaker]
    )

    const openSfuDownstreamConnection = (remoteConnectionInfo, layoutManager, channel) => {
        // Create remote media.
        const remoteMedia = new liveswitch.RemoteMedia()
        const audioStream = new liveswitch.AudioStream(remoteMedia)
        const videoStream = new liveswitch.VideoStream(remoteMedia)

        // Add remote media to the layout.
        layoutManager.addRemoteMedia(remoteMedia)

        // Create a SFU downstream connection with remote audio and video.
        const connection = channel.createSfuDownstreamConnection(remoteConnectionInfo, audioStream, videoStream)

        // Store the downstream connection.
        downstreamConnections.current[connection.getId()] = connection

        connection.addOnStateChange((conn) => {
            console.log(
                `Downstream connection is ${new liveswitch.ConnectionStateWrapper(conn.getState()).toString()}.`
            )

            // Remove the remote media from the layout and destroy it if the remote is closed.
            if (conn.getRemoteClosed()) {
                delete downstreamConnections.current[connection.getId()]
                layoutManager.removeRemoteMedia(remoteMedia)
                remoteMedia.destroy()
            }
        })

        connection.open()
        return connection
    }

    const joinAsync = async () => {
        const promise = new liveswitch.Promise()

        // Create a client.
        if (client.current === null) {
            // client.current = new liveswitch.Client(gatewayUrl, applicationId, userId)
            client.current = new liveswitch.Client(gatewayUrl, applicationId, userId, deviceId)
            client.current.setUserAlias(userId)
        }

        // Generate a token (do this on the server to avoid exposing your shared secret).

        const channelClaim = new liveswitch.ChannelClaim(slug)

        const tokenResponse =
            (await API.liveEvent.getLiveSwitchRegistrationToken({
                deviceId: client.current.getDeviceId(),
                clientId: client.current.getId(),
                channelClaim: {
                    id: channelClaim._id,
                    action: channelClaim._action,
                },
            })) || {}

        if (!tokenResponse.success) {
            setIsOnline(false)
            setJoinInProgress(false)
            promise.reject()
            return
        }

        const { data: token } = tokenResponse

        // setUnregistering(false)

        client.current.addOnStateChange(() => {
            if (client.current.getState() === liveswitch.ClientState.Registering) {
                console.log('client is registering')
            } else if (client.current.getState() === liveswitch.ClientState.Registered) {
                console.log('client is registered')
            } else if (client.current.getState() === liveswitch.ClientState.Unregistering) {
                console.log('client is unregistering')
            }
            console.debug(`Client is ${new liveswitch.ClientStateWrapper(client.current.getState())}.`)
        })

        // Register client with token.
        client.current
            .register(token)
            .then((channels) => {
                onClientRegistered(channels)
                promise.resolve(null)
            })
            .fail((ex) => {
                console.error('Failed to register with Gateway.')
                setIsOnline(false)
                setJoinInProgress(false)
                promise.reject(ex)
            })

        return promise
    }

    const handleJoinClick = useCallback(
        (isSpeaker) => {
            setJoinInProgress(true)

            joinAsync().then(() => {
                setIsOnline(true)
                setJoinInProgress(false)
                dispatch(joinStream())
                if (isSpeaker) {
                    dispatch(closeJoinModal())
                    layoutManager.current.setLocalMedia(localMedia.current)
                }
            })
        },
        [dispatch]
    )

    const stopLocalMedia = useCallback(async () => {
        const promise = new liveswitch.Promise()

        // Stop capturing local media.
        localMedia.current
            .stop()
            .then(() => {
                layoutManager.current.removeRemoteViews()
                layoutManager.current.unsetLocalView()

                if (localMedia.current != null) {
                    localMedia.current.destroy()
                    localMedia.current = null

                    const audioEnabled = true
                    const videoEnabled = new liveswitch.VideoConfig(
                        VIDEO_CONFIG.WIDTH,
                        VIDEO_CONFIG.HEIGHT,
                        VIDEO_CONFIG.FPS
                    )
                    localMedia.current = new liveswitch.LocalMedia(audioEnabled, videoEnabled)
                }

                promise.resolve(null)
            })
            .fail((ex) => {
                liveswitch.Log.error(ex.message)
                promise.reject(ex)
            })

        return promise
    }, [])

    const startScreenSharing = useCallback(() => {
        // Start screen capturing.
        localScreenMedia.current
            .start()
            .then(() => {
                // Open a SFU upstream connection for screen sharing.
                screenSharingUpstreamConnection.current = openSfuUpstreamConnection(localScreenMedia.current)
            })
            .fail(() => {
                console.error('Screen capture could not be started.')
            })

        // Add the screen sharing media to the layout.
        layoutManager.current.addRemoteMedia(localScreenMedia.current)
    }, [])

    const stopScreenSharing = useCallback(() => {
        // Close the screen sharing upstream connection.
        if (screenSharingUpstreamConnection.current !== null) {
            screenSharingUpstreamConnection.current.close().then(() => {
                // Stop the screen capturing and remove it from the layout.
                localScreenMedia.current.stop()
                layoutManager.current.removeRemoteMedia(localScreenMedia.current)
            })
        }
    }, [])

    const toggleScreenSharing = useCallback(() => {
        if (
            localScreenMedia.current.getState() === liveswitch.LocalMediaState.New ||
            localScreenMedia.current.getState() === liveswitch.LocalMediaState.Stopped
        ) {
            startScreenSharing()
        } else {
            stopScreenSharing()
        }
    }, [startScreenSharing, stopScreenSharing])

    const handleShareScreenClick = useCallback(() => {
        toggleScreenSharing()
    }, [toggleScreenSharing])

    const leaveAsync = useCallback(async () => {
        if (client.current !== null) {
            // setUnregistering(true)
            return client.current
                .unregister()
                .then(() =>
                    liveswitch.Log.debug(
                        `Client ${client.current.getId()} has successfully unregistered from channel ${channel.current.getId()}.`
                    )
                )
                .fail(() => liveswitch.Log.error('Unregistration failed.'))
        }
    }, [])

    const clearStreamState = useCallback(() => {
        setIsOnline(false)
        setLeaveInProgress(false)
        setJoinInProgress(false)
        setLocalAudioMuted(false)
        setLocalVideoMuted(false)
        dispatch(leaveStream())
    }, [dispatch])

    const handleLeaveClick = useCallback(() => {
        setLeaveInProgress(true)

        if (isSpeaker) {
            stopLocalMedia().then(() => {
                leaveAsync().then(() => {
                    clearStreamState()
                })
            })
        } else {
            leaveAsync().then(() => {
                clearStreamState()
            })
        }
    }, [stopLocalMedia, leaveAsync, clearStreamState, isSpeaker])

    const handleMuteAudioClick = useCallback(() => {
        if (upstreamConnection.current !== null) {
            const config = upstreamConnection.current.getConfig()
            const newValue = !config.getLocalAudioMuted()

            config.setLocalAudioMuted(newValue)
            setLocalAudioMuted(newValue)

            upstreamConnection.current
                .update(config)
                .then(() => {})
                .fail(() => {
                    setLocalAudioMuted(!newValue)
                })

            localMedia.current.changeAudioSourceInput(newValue ? {} : audioDevices[0])
        } else {
            localMedia.current.changeAudioSourceInput(!localAudioMuted ? {} : audioDevices[0])
            setLocalAudioMuted((prevState) => !prevState)
        }
    }, [localAudioMuted, audioDevices])

    const handleMuteVideoClick = useCallback(() => {
        if (upstreamConnection.current !== null) {
            const config = upstreamConnection.current.getConfig()
            // @todo fix when not synced with current state
            const newValue = !config.getLocalVideoMuted()

            config.setLocalVideoMuted(newValue)
            setLocalVideoMuted(newValue)

            upstreamConnection.current
                .update(config)
                .then(() => {})
                .fail(() => {
                    setLocalVideoMuted(!newValue)
                })

            localMedia.current.changeVideoSourceInput(newValue ? {} : videoDevices[0])
        } else {
            localMedia.current.changeVideoSourceInput(!localVideoMuted ? {} : videoDevices[0])
            setLocalVideoMuted((prevState) => !prevState)
        }
    }, [localVideoMuted, videoDevices])

    const onJoinModalClose = useCallback(() => {
        dispatch(closeJoinModal())

        localMedia.current.stop().then(() => {
            localMedia.current.changeVideoSourceInput(videoDevices[0])
            localMedia.current.changeAudioSourceInput(audioDevices[0])
            setLocalVideoMuted(false)
            setLocalAudioMuted(false)
        })
    }, [dispatch, videoDevices, audioDevices])

    const handleJoinRoomClick = useCallback(() => {
        dispatch(openJoinModal())
    }, [dispatch])

    return (
        <>
            <div className="tap-livestream">
                <div className="tap-livestream-video-wrapper" ref={eventVideoStreamRef} />
                {isAdminSpeaker && !joinedStream && (
                    <div className="tap-livestream-join-layer">
                        <div>
                            <div>Event is in progress</div>
                            <br />
                            <Button type="primary" size="large" onClick={handleJoinRoomClick}>
                                Join the room
                            </Button>
                        </div>
                    </div>
                )}

                <div className="tap-livestream-controls">
                    <StreamControls
                        userName={userName}
                        isOnline={isOnline}
                        onJoinClick={handleJoinClick}
                        joinInProgress={joinInProgress}
                        onLeaveClick={handleLeaveClick}
                        leaveInProgress={leaveInProgress}
                        onMuteAudioClick={handleMuteAudioClick}
                        audioOn={!localAudioMuted}
                        onMuteVideoClick={handleMuteVideoClick}
                        videoOn={!localVideoMuted}
                        onShareScreenClick={handleShareScreenClick}
                        isSpeaker={isSpeaker}
                    />
                </div>
            </div>
            {localMedia.current !== null && (
                <JoinEventModal
                    open={joinModalOpen}
                    localMedia={localMedia.current}
                    onMuteAudioClick={handleMuteAudioClick}
                    onMuteVideoClick={handleMuteVideoClick}
                    joinInProgress={joinInProgress}
                    onJoinClick={handleJoinClick}
                    audioOn={!localAudioMuted}
                    videoOn={!localVideoMuted}
                    onJoinModalClose={onJoinModalClose}
                    isSpeaker={isSpeaker}
                />
            )}
        </>
    )
}

export default EventVideoStream
