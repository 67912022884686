import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Modal, Row, Spin } from 'antd'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import store from '../../../redux/store'

import { CreateContentGroupConnected } from '../../content/messaging/components/popups/CreateContentGroup'
import CreateFaq from '../../faq/new_questions/components/popups/CreateQuestionPopup'
import ChartHeader from '../../reporting/components/ChartHeader'
import DatesIntervalSelector from '../../reporting/components/DatesIntervalSelector'
import DonutChart from '../../reporting/components/DonutChart'
import ExportButtons from '../../reporting/components/ExportButtons'
import FilterDropdown from '../../reporting/components/SearchCriteria'
import Search from './Search'
import TopicsTable from './TopicsTable'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

import {
    fetchTrendAnalyzerChartsData,
    fetchTrendAnalyzerCsvData,
    fetchTrendAnalyzerTableData,
} from '../../../redux/actions/analytics/trendAnalyzerActions'

import { getDateRange } from '../../../helpers/time'

import { isIdpRedirect } from 'src/app/helpers/idpRedirect'
import { HomeRedirect } from 'src/app/containers/_layout/nav/components/HomeRedirect'

class AnalyticsTrendAnalyzer extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedButton: '30Days',
            selectedDateRange: getDateRange(30),
            selectedNationality: null,
            searchString: '',
            selectedTopicIds: [],
            showCreateFaqPopup: false,
            showCreateContentPopup: false,
            activeTagInfo: { id: null, name: '' },
        }

        this.handleNationalitySelection = this.handleNationalitySelection.bind(this)
        this.handleDatesIntervalSelectorUpdate = this.handleDatesIntervalSelectorUpdate.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleClickExportSelected = this.handleClickExportSelected.bind(this)
        this.handleClickExportAll = this.handleClickExportAll.bind(this)
        this.handleTopicsSelectionChange = this.handleTopicsSelectionChange.bind(this)
        this.handleCreateFaq = this.handleCreateFaq.bind(this)
        this.handleCloseCreateFaqPopup = this.handleCloseCreateFaqPopup.bind(this)
        this.handleCreateContent = this.handleCreateContent.bind(this)
        this.handleCloseCreateContentPopup = this.handleCloseCreateContentPopup.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'reporting',
            },
        })

        this.props.fetchTrendAnalyzerChartsData(this.state.selectedDateRange)
        this.props.fetchTrendAnalyzerTableData(this.state.selectedDateRange)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.props.fetchTrendAnalyzerChartsData(this.state.selectedDateRange)
            this.props.fetchTrendAnalyzerTableData(this.state.selectedDateRange)
        }
    }

    generateDatesSelector() {
        return (
            <DatesIntervalSelector
                selectedButton={this.state.selectedButton}
                fromDate={this.state.selectedDateRange.fromDate}
                toDate={this.state.selectedDateRange.toDate}
                onChange={this.handleDatesIntervalSelectorUpdate}
            />
        )
    }

    handleNationalitySelection(selectedNationality) {
        this.setState({ selectedNationality })

        this.props.fetchTrendAnalyzerChartsData({ ...this.state.selectedDateRange, nationalityId: selectedNationality })
    }

    handleDatesIntervalSelectorUpdate(selectedButton, selectedDateRange) {
        this.setState({ selectedButton, selectedDateRange, selectedNationality: null })

        this.props.fetchTrendAnalyzerChartsData(selectedDateRange)
        this.props.fetchTrendAnalyzerTableData(selectedDateRange)
    }

    handleSearch(searchString) {
        this.setState({ searchString })
    }

    handleClickExportSelected() {
        this.props.fetchTrendAnalyzerCsvData(this.state.selectedDateRange, this.state.selectedTopicIds)
    }

    handleClickExportAll() {
        this.props.fetchTrendAnalyzerCsvData(this.state.selectedDateRange)
    }

    handleCreateFaq(topic, tagId) {
        this.setState({ showCreateFaqPopup: true, activeTagInfo: { id: tagId, name: topic } })
    }

    handleCloseCreateFaqPopup() {
        this.setState({ showCreateFaqPopup: false })
    }

    handleCreateContent(topic, tagId) {
        this.setState({ showCreateContentPopup: true, activeTagInfo: { id: tagId, name: topic } })
    }

    handleCloseCreateContentPopup() {
        this.setState({ showCreateContentPopup: false })
    }

    handleTopicsSelectionChange(selectedTopicIds) {
        this.setState({ selectedTopicIds })
    }

    generateNationalityOptions() {
        const result = [{ key: 0, name: 'All' }]
        if (!this.props.charts || !this.props.charts.nationalities) {
            return result
        }

        for (const item of this.props.charts.nationalities) {
            result.push({ key: item.id, name: item.name })
        }

        return result
    }

    prepareTopicsChartData(topicsData) {
        if (!topicsData || topicsData.length === 0) {
            return { data: [] }
        }

        const chartData = []
        let otherPercentage = 100

        for (const item of topicsData) {
            chartData.push({ name: item.name, y: item.percentage })

            otherPercentage -= item.percentage
        }

        if (otherPercentage > 0) {
            chartData.push({ name: 'Other Topics', y: otherPercentage })
        }

        return {
            data: chartData,
            centerText:
                '<div style="font-size: 24px; color: #ff5100;">' +
                topicsData[0].percentage.toFixed(1) +
                '%</div><br />' +
                '<div style="font-size: 12px; color: rgba(0,0,0,0.45);">' +
                topicsData[0].name +
                '</div>',
        }
    }

    generateSelectedNumber() {
        if (!this.props.table) {
            return ''
        }

        if (this.state.selectedTopicIds.length > 0 && this.props.table.length === this.state.selectedTopicIds.length) {
            return ' (All)'
        }

        return ' (' + this.state.selectedTopicIds.length + ')'
    }

    render() {
        const nationalityOptions = this.generateNationalityOptions()
        const universityTopicsData = this.prepareTopicsChartData(this.props.charts.universityTopics)
        const allTopicsData = this.prepareTopicsChartData(this.props.charts.allTopics)
        const colorsUniversity = ['#274496', '#67bfe0', '#4e607e', '#4e9bf3', '#1c66a7', '#acc5db']
        const colorsAll = ['#ebeb73', '#bf2525', '#c9ba98', '#ffdeb8', '#ff0000', '#c0910e']
        const dataLabelFormat =
            '<div style="font-size: 16px; font-weight: normal; color: #ff5100;">{point.percentage:.1f}%</div><br />' +
            '<div style="font-size: 12px; font-weight: normal; color: rgba(0,0,0,0.45); opacity: 0.8">{point.name}</div>'
        const selectedNumberLabel = this.generateSelectedNumber()

        if (isIdpRedirect(this.props.account.account_info)) {
            return <HomeRedirect />
        }

        return (
            <>
                <section className="content reporting trend-analyzer-reporting">
                    <div className="prospect-table-title beta-version">Conversation Insights</div>
                    <Card className="reporting-block">
                        <Row gutter={16}>
                            <Col span={6}>
                                <FilterDropdown
                                    onChange={this.handleNationalitySelection}
                                    placeholder="Filter by nationality"
                                    value={this.state.selectedNationality}
                                    options={nationalityOptions}
                                    styles={{ width: 180, marginLeft: 8 }}
                                />
                            </Col>
                            <Col span={18} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {this.generateDatesSelector()}
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <ChartHeader title="Your top five topics" />
                                <DonutChart
                                    colors={colorsUniversity}
                                    data={universityTopicsData.data}
                                    dataLabelFormat={dataLabelFormat}
                                    innerRadius="90%"
                                    height={300}
                                />
                            </Col>
                            <Col span={12}>
                                <ChartHeader title="TAP benchmark top five topics" />
                                <DonutChart
                                    colors={colorsAll}
                                    data={allTopicsData.data}
                                    dataLabelFormat={dataLabelFormat}
                                    innerRadius="90%"
                                    height={300}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card className="reporting-block" style={{ marginTop: 16 }}>
                        <Spin
                            spinning={this.props.isTableLoading}
                            indicator={<LoadingOutlined style={{ fontSize: 50, color: '#ff5100' }} />}
                        >
                            <div className="search-wrapper">
                                <div className="search-wrapper">
                                    <Search onChange={this.handleSearch} placeholder="Search for topics" width={360} />
                                </div>
                                <ExportButtons
                                    onClickSelected={this.handleClickExportSelected}
                                    showExportSelected={this.state.selectedTopicIds.length === 0}
                                    onClickAll={this.handleClickExportAll}
                                    showExportAll={this.props.table && this.props.table.length === 0}
                                    filePath={this.props.csvUrl}
                                    selectedNumber={selectedNumberLabel}
                                />
                            </div>
                            <TopicsTable
                                data={this.props.table}
                                onSelectionChange={this.handleTopicsSelectionChange}
                                onTableChange={this.handleTableChange}
                                searchString={this.state.searchString}
                                onCreateFaq={this.handleCreateFaq}
                                onCreateContent={this.handleCreateContent}
                            />
                        </Spin>
                    </Card>
                </section>
                <Modal
                    open={this.state.showCreateFaqPopup}
                    centered={true}
                    width={600}
                    title={null}
                    footer={null}
                    destroyOnClose={true}
                    keyboard={false}
                    onCancel={this.handleCloseCreateFaqPopup}
                    className="trend-modal-content"
                >
                    <CreateFaq
                        onSubmit={this.handleCloseCreateFaqPopup}
                        onClosePopup={this.handleCloseCreateFaqPopup}
                        isAnsweredQuestions={false}
                        isPublished={false}
                        initialTags={[this.state.activeTagInfo]}
                    />
                </Modal>
                <Modal
                    open={this.state.showCreateContentPopup}
                    centered={true}
                    width={600}
                    title={null}
                    footer={null}
                    destroyOnClose={true}
                    keyboard={false}
                    onCancel={this.handleCloseCreateContentPopup}
                    className="trend-modal-content"
                >
                    <CreateContentGroupConnected
                        onSubmit={this.handleCloseCreateContentPopup}
                        onClosePopup={this.handleCloseCreateContentPopup}
                        initialTags={[this.state.activeTagInfo]}
                    />
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    charts: state.trendAnalyzer.charts,
    table: state.trendAnalyzer.table,
    isTableLoading: state.trendAnalyzer.isTableLoading,
    csvUrl: state.trendAnalyzer.csvUrl,
})

export default connect(mapStateToProps, {
    fetchTrendAnalyzerChartsData,
    fetchTrendAnalyzerTableData,
    fetchTrendAnalyzerCsvData,
})(AnalyticsTrendAnalyzer)
