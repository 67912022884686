import Modal from 'antd/es/modal'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'
import store from '../../../../redux/store'
import { pusherChild } from '../../../../system/services/PusherWrapper'
import NewMessagesPopup from './popups/NewMessagesPopup'

import Footer from './blocks/footer'
import Header from './blocks/header'

import {
    CLOSE_ACTION_MODAL,
    OPEN_ACTION_MODAL,
    OPEN_SETTINGS_MESSAGING_POPUP,
    REPLY_MESSAGE,
} from '../../../../redux/actions/components/componentsActions'

import {
    CHAT_INFO_SEARCH,
    CLOSE_DIALOG,
    FLAG_DIALOG,
    SEND_CHAT_MESSAGE,
} from '../../../../redux/actions/chat/messagingActions'

import SendMessageForm from './forms/SendMessageForm'

import { ReactComponent as Logo } from '../../../../../assets/img/svg/close.svg'
import { ReplyMessageContainer } from 'src/app/components/reply-message-container'
import { DELETE_TOPIC, EDIT_TOPIC } from '../../../../redux/actions/chat/topicsActions'
import { USERS_INFO_REQUESTED } from '../../../../redux/actions/content/usersActions'
import MessagesBody from './blocks/messagesBody'
import TopicsHeader from './blocks/topicsHeader'
import TopicsAddPopup from './popups/TopicsAddPopup'

import { ChatType } from 'src/app/common/constants'

class Messages extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isTopicsPopupOpened: false,
            showDeleteTopicModal: false,
            topic: {},
        }

        this.fireChannelActions = this.fireChannelActions.bind(this)
        this.handleSendMessage = this.handleSendMessage.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
        this.handleOpenConfirmPopup = this.handleOpenConfirmPopup.bind(this)
        this.openTopicsPopup = this.openTopicsPopup.bind(this)
        this.closeTopicsPopup = this.closeTopicsPopup.bind(this)
        this.setTopicStatus = this.setTopicStatus.bind(this)
    }

    componentDidMount() {
        const { universityID } = this.props
        this.channel = pusherChild.subscribe('dashboard')

        this.fireChannelActions()

        store.dispatch({
            type: USERS_INFO_REQUESTED,
            payload: {
                filter: {
                    university_id: universityID,
                },
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.channel.unbind()
            this.fireChannelActions()
        }
    }

    fireChannelActions() {
        const currentGroupUniversityId = this.props.account.currentGroupUniversityId // for group admins
        const universityId = currentGroupUniversityId || this.props.account.account_info.university_id

        this.channel.bind(
            'messages:new:' + universityId,
            function (data) {
                const { currentChat } = this.props

                if (data?.typeKey === ChatType.CHAT || data?.type === ChatType.CHAT) {
                    store.dispatch({
                        type: CHAT_INFO_SEARCH,
                        payload: {
                            dialogId: data.dialog_id,
                            currentDialogId: currentChat.activeChat,
                        },
                    })
                }
            }.bind(this)
        )

        if (currentGroupUniversityId) {
            this.channel.bind('messages:reported:' + currentGroupUniversityId, function (data) {
                store.dispatch({
                    type: FLAG_DIALOG,
                    payload: {
                        dialog_id: data.dialog_id,
                    },
                })
            })
        }
    }

    componentWillUnmount() {
        if (this.channel) {
            this.channel.unbind()
        }
        store.dispatch({
            type: REPLY_MESSAGE,
            payload: {
                activeMessage: null,
                activePublisher: null,
            },
        })
    }

    handleSendMessage() {
        if (document.querySelector('#chat-message-textarea').value.replace(/^\s+|\s+$/g, '') === '') return
        const { currentChat, replyMessage } = this.props

        const onSuccess = () => {
            store.dispatch({
                type: REPLY_MESSAGE,
                payload: {
                    activeMessage: null,
                    activePublisher: null,
                },
            })
        }

        store.dispatch({
            type: SEND_CHAT_MESSAGE,
            payload: {
                type: 'text',
                text: document.querySelector('#chat-message-textarea').value,
                dialog_id: currentChat.activeChat,
                type_chat: currentChat.chatsType,
                parentMessageId: replyMessage.activeMessage ? replyMessage.activeMessage.id : null,
                scrollBottom: true,
            },
            onSuccess,
        })

        document.querySelector('#send-message-form').reset()
    }

    closeDialog() {
        store.dispatch({
            type: CLOSE_DIALOG,
            payload: {
                dialogId: this.props.currentChat.activeChat,
                type: this.props.currentChat.chatsType,
            },
        })
    }

    handleOpenConfirmPopup() {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button type="submit" className="el-btn confirm-delete-button" onClick={this.closeDialog}>
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={Messages.handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    handleOpenSettingsPopup() {
        store.dispatch({
            type: OPEN_SETTINGS_MESSAGING_POPUP,
        })
    }

    static handleCloseConfirmPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    openTopicsPopup() {
        this.setState({ isTopicsPopupOpened: true })
    }

    closeTopicsPopup(showModal) {
        this.setState({ isTopicsPopupOpened: false })
        if (showModal) {
            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Thank you for your feedback!',
                },
            })
        }
    }

    setTopicStatus(topic, confirmed) {
        const afterSuccess = () => {
            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Thank you for your feedback!',
                },
            })
        }

        store.dispatch({
            type: EDIT_TOPIC,
            payload: {
                dialogId: this.props.currentChat.data.id,
                topicId: topic.id,
                confirmation: confirmed ? 'confirmed' : 'removed',
            },
            afterSuccess,
        })
    }

    openDeleteConfirmModal = (topic) => {
        this.setState({
            showDeleteTopicModal: true,
            topic: topic,
        })
    }

    closeDeleteConfirmModal = () => {
        this.setState({
            showDeleteTopicModal: false,
        })
    }

    handleDeleteItem = () => {
        const afterSuccess = () => {
            this.setState({
                showDeleteTopicModal: false,
            })
        }
        store.dispatch({
            type: DELETE_TOPIC,
            payload: {
                dialogId: this.props.currentChat.data.id,
                topicId: this.state.topic.id,
            },
            afterSuccess,
        })
    }

    render() {
        const { topic, isTopicsPopupOpened, showDeleteTopicModal } = this.state
        const { currentChat, dialogMessages } = this.props

        const chat = currentChat.data || {}
        if (currentChat.activeChat === -1 || (!chat.prospect && currentChat.chatsType === 1) || !chat.id) {
            return (
                <div className="chat-block">
                    <div className="chat-block-icon">
                        <svg className="icon icon-chat">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#chat'} />
                        </svg>
                        <h2 className="modal-title">Select a chat</h2>
                    </div>
                </div>
            )
        }

        return (
            <div className="chat-block">
                <Header
                    dialogId={currentChat.activeChat}
                    handleOpenSettingsPopup={this.handleOpenSettingsPopup}
                    handleCloseConfirmPopup={Messages.handleCloseConfirmPopup}
                />

                <TopicsHeader
                    dialogMessages={dialogMessages}
                    dialogId={currentChat.data.id}
                    setTopicStatus={this.setTopicStatus}
                    openDeleteConfirmModal={this.openDeleteConfirmModal}
                    openTopicsPopup={this.openTopicsPopup}
                />

                <div className="chat-body scroll-8" id="chat-body">
                    <div className="chat-body-list">
                        <MessagesBody
                            chat={chat}
                            chatsType={currentChat.chatsType}
                            activeChat={currentChat.activeChat}
                            search={currentChat.search}
                        />
                    </div>

                    <NewMessagesPopup dialogId={currentChat.activeChat} />
                </div>

                <div id="chat-footer" className="chat-footer prospect-sa-chat">
                    <ReplyMessageContainer />

                    <SendMessageForm onSubmit={this.handleSendMessage} />
                    <Footer dialogId={currentChat.activeChat} handleOpenConfirmPopup={this.handleOpenConfirmPopup} />
                </div>

                <Modal
                    open={isTopicsPopupOpened}
                    width={500}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={() => this.closeTopicsPopup(false)}
                    destroyOnClose
                >
                    <TopicsAddPopup
                        dialogId={currentChat.data.id}
                        dialogMessages={dialogMessages.items}
                        chatTopics={dialogMessages.topics}
                        handleCloseTopicsPopup={this.closeTopicsPopup}
                    />
                </Modal>

                <Modal
                    open={showDeleteTopicModal}
                    bodyStyle={{ padding: 0 }}
                    width={400}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.closeDeleteConfirmModal}
                    destroyOnClose
                >
                    <div className="delete-topic-modal">
                        <Logo onClick={this.closeDeleteConfirmModal} />
                        <p>
                            Do you want to delete <span className="tap-orange">&#39;{topic.name}&#39;</span> from the
                            topics?
                        </p>
                        <div className="topic-delete-buttons">
                            <button className="btn" onClick={this.handleDeleteItem}>
                                Delete
                            </button>
                            <button className="btn" onClick={this.closeDeleteConfirmModal}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        currentChat: state.currentChat,
        account: state.account,
        prospects: state.prospects,
        replyMessage: state.replyMessage,
        dialogMessages: state.chatMessages,
    }
})(Messages)
