import React, { PureComponent } from 'react'
import { DateHumanizeHelper, formatLink } from '../../../../helpers/table'

class Analytics extends PureComponent {
    getUtm = (type, link) => {
        const { user } = this.props
        const data = []
        if (user.utmData) {
            for (const utm of user.utmData) {
                if (utm[type]) {
                    data.push(
                        <p>
                            {link
                                ? formatLink(utm[type], 40)
                                : utm[type].length > 40
                                ? utm[type].slice(0, 40) + '...'
                                : utm[type]}
                        </p>
                    )
                }
            }
        }
        return data
    }

    render() {
        const { user } = this.props

        const urmSource = this.getUtm('source')
        const urmCampaign = this.getUtm('campaign')
        const urmMedium = this.getUtm('medium')
        const urmUserCameFrom = this.getUtm('user_came_from', true)

        return (
            <div className="analytics-data-block scroll-8">
                <p className="analytics-text">
                    <span className="analytics-text-name">Sign up date</span>
                    <span className="analytics-text-value">{DateHumanizeHelper(user.university_registration)}</span>
                </p>
                <p className="analytics-text">
                    <span className="analytics-text-name">Last seen date</span>
                    <span className="analytics-text-value">{DateHumanizeHelper(user.last_activity)}</span>
                </p>
                <p className="analytics-text">
                    <span className="analytics-text-name">UTM source</span>
                    <span className="analytics-text-value">{urmSource}</span>
                </p>
                <p className="analytics-text">
                    <span className="analytics-text-name">UTM campaign</span>
                    <span className="analytics-text-value">{urmCampaign}</span>
                </p>
                <p className="analytics-text">
                    <span className="analytics-text-name">UTM medium</span>
                    <span className="analytics-text-value">{urmMedium}</span>
                </p>
                <p className="analytics-text">
                    <span className="analytics-text-name">Link</span>
                    <span className="analytics-text-value">{urmUserCameFrom}</span>
                </p>
                {user.registered_via_group && (
                    <p className="analytics-text">
                        <span className="analytics-text-name">Group</span>
                        <span className="analytics-text-value">{user.registered_via_group}</span>
                    </p>
                )}
            </div>
        )
    }
}

export default Analytics
