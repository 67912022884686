/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LIVE_STREAM_STATUS } from '../../../../common/constants'
import { roles } from '../../../../helpers/helpers'
import { AMBASSADORS_INFO } from '../../../../redux/actions/content/messagingActions'
import {
    closeScheduledStreamTypeModal,
    createLiveStream,
    updateLiveStream,
} from '../../../../redux/actions/live-streams/live-streams-actions'
import {
    getAccountInfoId,
    getLiveStreamsScheduledModal,
    getTimezonesSlice,
    getUniversityInfo,
    getLiveStreamsTypeModal,
    getIsGroupAdmin,
} from '../../../../redux/selectors'

import { EventFormModel, EventRequestModel } from './event-form'

const FORM_STEPS_KEYS = {
    MAIN_FIELDS: 'MAIN_FIELDS',
    SPEAKERS: 'SPEAKERS',
    CHAT: 'CHAT',
    PUBLISH: 'PUBLISH',
}

const FORM_STEPS = [
    FORM_STEPS_KEYS.MAIN_FIELDS,
    FORM_STEPS_KEYS.SPEAKERS,
    FORM_STEPS_KEYS.CHAT,
    FORM_STEPS_KEYS.PUBLISH,
]

const pickTitleText = (step, editMode) => {
    if (editMode) {
        return 'Edit stream'
    }
    switch (step) {
        case 0:
            return 'Live Stream'
        case 1:
            return 'Invite speakers'
        case 2:
            return 'Create stream chat'
        case 3:
            return 'Live Stream recording'
        default:
            return ''
    }
}

const pickFieldsToValidate = (step) => {
    if (step === 0) return ['name', 'image', 'description', 'date', 'fromTime', 'toTime']
    if (step === 1) return ['speakersPlaceholder']
    if (step === 2) return []
}

export const useEventFormModal = () => {
    const dispatch = useDispatch()
    const { open, data, submitting } = useSelector(getLiveStreamsScheduledModal)
    const { data: modalData } = useSelector(getLiveStreamsTypeModal)
    const accountInfoId = useSelector(getAccountInfoId)
    const universityInfo = useSelector(getUniversityInfo)
    const { items: timezones } = useSelector(getTimezonesSlice)

    const [step, setStep] = useState(0)
    const [imageState, setImageState] = useState({})

    const [form] = Form.useForm()

    const initialValues = useMemo(
        () => EventFormModel(data, timezones, accountInfoId),
        [data, timezones, accountInfoId]
    )

    const editMode = useMemo(() => data && data.id, [data])
    const isInProgress = useMemo(() => data && data.status === LIVE_STREAM_STATUS.IN_PROGRESS, [data])
    const isUpcoming = useMemo(() => data && data.status === LIVE_STREAM_STATUS.UPCOMING, [data])

    const isGroupAdmin = useSelector(getIsGroupAdmin)

    const { isRelatedToGroup } = universityInfo

    const modalTitle = useMemo(() => pickTitleText(step, editMode), [step, editMode])
    const submitButtonTitle = useMemo(
        () => (step === FORM_STEPS.length - 1 ? 'Create stream' : editMode ? 'Save' : 'Next'),
        [step, data, editMode]
    )

    useEffect(() => {
        if (initialValues.id) {
            setImageState((prevState) => ({
                ...prevState,
                imageUrl: initialValues.image,
            }))
        }
    }, [initialValues])
    const loadInternalSpeakersOptions = useCallback(() => {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
                roles: [roles.admin, roles.student],
            },
        })
    }, [dispatch])

    const loadAmbassadorsOptions = useCallback(() => {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
                roles: [roles.student],
            },
        })
    }, [dispatch])

    useEffect(() => {
        if (step === 1) {
            loadInternalSpeakersOptions()
        }
        if (step === 2) {
            loadAmbassadorsOptions()
        }
    }, [step])

    const handlePrevClick = useCallback(() => {
        setStep((prevState) => prevState - 1)
    }, [])

    const afterClose = useCallback(() => {
        form.resetFields()
        setStep(0)
        setImageState({})
    }, [form])

    const submitForm = useCallback(() => {
        form.validateFields().then((values) => {
            console.log('submitForm values :', values)
            const requestData = {
                ...values,
                ...modalData,
            }
            const requestPayload = EventRequestModel(requestData)
            dispatch(createLiveStream(requestPayload))
        })
    }, [form, dispatch, modalData])

    const handleNextClick = useCallback(() => {
        if (editMode) {
            form.validateFields().then((values) => {
                const imageTouched = form.isFieldTouched('image')
                const requestPayload = EventRequestModel(values, data, imageTouched)

                dispatch(updateLiveStream(requestPayload))
            })
        } else {
            const isLastStep = step === FORM_STEPS.length - 1

            if (isLastStep) {
                submitForm()
            } else {
                const fieldsToValidate = pickFieldsToValidate(step)
                if (step === 0) {
                    form.validateFields(fieldsToValidate).then(() => {
                        setStep((prevState) => prevState + 1)
                    })
                } else {
                    form.validateFields().then(() => {
                        setStep((prevState) => prevState + 1)
                    })
                }
            }
        }
    }, [step, form, submitForm, editMode])

    const handleCancel = useCallback(() => {
        if (!form.isFieldsTouched()) {
            dispatch(closeScheduledStreamTypeModal())
            return
        }

        const confirmCloseModal = confirm('Are you sure you want to close this popup? Changes will not be saved')

        if (confirmCloseModal) {
            dispatch(closeScheduledStreamTypeModal())
        }
    }, [dispatch, form])

    return {
        form,
        open,
        initialValues,
        modalTitle,
        submitButtonTitle,
        step,
        onPrevClick: handlePrevClick,
        onNextClick: handleNextClick,
        onCancel: handleCancel,
        afterClose,
        submitting,
        imageState,
        setImageState,
        editMode,
        isInProgress,
        isUpcoming,
        isRelatedToGroup,
        isGroupAdmin,
    }
}
