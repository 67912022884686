import { call, put, select } from 'redux-saga/effects'

import API from '../../../api'
import {
    USERS_INFO_SUCCESS,
    USERS_INFO_FAILED,
    USER_TOGGLE_BAN_SUCCESS,
    USER_TOGGLE_BAN_FAILED,
    EDIT_USER_GLOBAL_TAG_FINISH,
    POPCARD_USERS_FAILED,
    POPCARD_USERS_SUCCESS,
    USER_CHANGE_INTRODUCTION_SUCCESS,
    USER_CHANGE_DESCRIPTION_SUCCESS,
    DISABLE_TOGGLE,
    USER_PARTNER_TOGGLE_BAN_SUCCESS,
    USER_PARTNER_TOGGLE_BAN_FAILED,
    MAX_USERS_LIMIT_POPUP_TOGGLE,
    USER_PARTNER_TOGGLE_UNI_BAN_SUCCESS,
    USER_PARTNER_TOGGLE_UNI_BAN_FAILED,
    USER_CHANGE_SUBJECT_SUCCESS,
    USER_CHANGE_COUNTRY_SUCCESS,
    GET_STAFF_TYPES_SUCCESS,
    GET_STUDENT_TYPES_SUCCESS,
    GET_SUBJECTS_SUCCESS,
    GET_YEAR_OF_STUDIES_SUCCESS,
    GET_COURSE_TYPES_SUCCESS,
    GET_INDUSTRIES_SUCCESS,
    GET_SA_PROFILE_TYPES_SUCCESS,
    CHANGE_AMBASSADOR_INTERESTS_SUCCESS,
    DELETE_AMBASSADOR_SUCCESS,
    GET_USER_TABLE_TOTALS_SUCCESS,
    getUserTableTotalsAction,
} from '../../actions/content/usersActions'

const getGroup = (state) => state.account.account_info.universityGroup
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId
const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

const prepareUsers = (users) => {
    if (!Array.isArray(users) || users.length === 0) return users

    const items = users.map((item) => {
        if (item.timeBlockColum) {
            const timeBlocks = {}
            item.timeBlockColum.forEach((element) => {
                timeBlocks[element.permission_id] = element
            })
            item.timeBlockColum = timeBlocks
        }

        /*
        if (item.user_tags && item.user_tags.student) {
            item.user_tags.student.shift()
        } */

        return item
    })
    return items
}

export function* usersInfo(action) {
    const { payload, meta } = action

    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.universityUsers(
                payload.filter,
                payload.search,
                payload.sort,
                payload.limit,
                payload.offset,
                !!payload.withGlobalTags,
                universityIds,
                payload.orderBy,
                payload.order
            )
        })

        if (response.success) {
            yield put({
                type: USERS_INFO_SUCCESS,
                payload: {
                    items: prepareUsers(response.data.users), // should be used Users model and after tested with all users showing places
                    total: response.data.total,
                    partner_groups: response.data.partner_groups,
                },
            })
            meta && meta.onSuccess && meta.onSuccess(response.data.users)
        } else {
            yield put({
                type: USERS_INFO_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: USERS_INFO_FAILED,
        })
    }
}

export function* popcardUsers(action) {
    const payload = action.payload
    const group = yield select(getGroup)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

    try {
        const universityId =
            group && !currentGroupUniversityId ? null : currentGroupUniversityId || payload.university_id
        const response = yield call(() => {
            return API.dashboard.popcardUsers(universityId, group && !currentGroupUniversityId ? group.id : null)
        })

        yield put({
            type: POPCARD_USERS_SUCCESS,
            payload: {
                items: response.data.ambassadorAvatars,
                tapPageSettings: response.data.tapPageSettings,
            },
        })
    } catch (error) {
        console.log(error)

        yield put({
            type: POPCARD_USERS_FAILED,
        })
    }
}

export function* usersToggleBan(action) {
    const payload = action.payload

    try {
        const method = action.method == 'ban' ? 'toggleUserBan' : 'toggleUserPermission'
        const response = yield call(() => {
            return API.dashboard[method](
                payload.university_id,
                payload.user_id,
                payload.permission_id,
                payload.permission_key
            )
        })

        if (response.success) {
            yield put({
                type: USER_TOGGLE_BAN_SUCCESS,
                payload: {
                    permission_id: payload.permission_id,
                    permission_key: payload.permission_key,
                    user_id: payload.user_id,
                    type: payload.type,
                    is_enabled: payload.is_enabled,
                },
            })
            yield put({
                type: DISABLE_TOGGLE,
                payload: false,
            })

            if (payload.requestTotals) {
                yield put(
                    getUserTableTotalsAction({
                        filter: {
                            searchItems: payload.searchItems,
                        },
                    })
                )
            }
        } else {
            yield put({
                type: USER_TOGGLE_BAN_FAILED,
            })
            yield put({
                type: DISABLE_TOGGLE,
                payload: false,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: USER_TOGGLE_BAN_FAILED,
        })
        yield put({
            type: DISABLE_TOGGLE,
            payload: false,
        })
    }
}

export function* usersPartnerToggleBan(action) {
    const payload = action.payload

    try {
        const group = yield select(getGroup)

        const response = yield call(() => {
            return API.dashboard.toggleUserGroupPermission(payload.user_id, group ? null : payload.university_group_id)
        })

        if (response.success) {
            yield put({
                type: USER_PARTNER_TOGGLE_BAN_SUCCESS,
                payload: {
                    university_group_id: payload.university_group_id,
                    user_id: payload.user_id,
                    is_enabled: payload.is_enabled,
                },
            })
            yield put({
                type: DISABLE_TOGGLE,
                payload: false,
            })
            yield put(
                getUserTableTotalsAction({
                    filter: {
                        searchItems: payload.searchItems,
                    },
                })
            )
        } else {
            yield put({
                type: USER_PARTNER_TOGGLE_BAN_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield error && error.status === 499
            ? put({
                  type: MAX_USERS_LIMIT_POPUP_TOGGLE,
                  payload: {
                      errorText: JSON.parse(error.message).error.message,
                  },
              })
            : put({
                  type: USER_PARTNER_TOGGLE_BAN_FAILED,
              })
    }
}

export function* usersPartnerUniToggleBan(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.toggleUserUniPermission(payload.user_id)
        })

        if (response.success) {
            yield put({
                type: USER_PARTNER_TOGGLE_UNI_BAN_SUCCESS,
                payload: {
                    user_id: payload.user_id,
                    is_enabled: payload.is_enabled,
                },
            })
            yield put({
                type: DISABLE_TOGGLE,
                payload: false,
            })
            yield put(
                getUserTableTotalsAction({
                    filter: {
                        searchItems: payload.searchItems,
                    },
                })
            )
        } else {
            yield put({
                type: USER_PARTNER_TOGGLE_UNI_BAN_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield error && error.status === 499
            ? put({
                  type: MAX_USERS_LIMIT_POPUP_TOGGLE,
                  payload: {
                      errorText: JSON.parse(error.message).error.message,
                  },
              })
            : put({
                  type: USER_PARTNER_TOGGLE_BAN_FAILED,
              })
    }
}

export function* changeUserGlobalTags(action) {
    yield put({
        type: EDIT_USER_GLOBAL_TAG_FINISH,
        payload: action.payload,
    })
}

export function* userIntroduction({ payload }) {
    try {
        const response = yield call(() => {
            return API.dashboard.ambassadorIntroduction(payload)
        })

        if (response.success) {
            yield put({
                type: USER_CHANGE_INTRODUCTION_SUCCESS,
                payload,
            })
        } else {
        }
    } catch (error) {
        console.log(error)
    }
}

export function* userDescription({ payload }) {
    try {
        const response = yield call(() => {
            return API.dashboard.ambassadorDescription(payload)
        })

        if (response.success) {
            yield put({
                type: USER_CHANGE_DESCRIPTION_SUCCESS,
                payload,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* userSubject({ payload }) {
    try {
        const response = yield call(() => {
            return API.dashboard.ambassadorSubject(payload)
        })

        if (response.success) {
            yield put({
                type: USER_CHANGE_SUBJECT_SUCCESS,
                payload: {
                    subject: [response.data.subject],
                    userId: payload.user_id,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* changeUserCountry({ payload, meta }) {
    try {
        const response = yield call(() => {
            return API.dashboard.changeUserCountry(payload)
        })

        if (response.success) {
            yield put({
                type: USER_CHANGE_COUNTRY_SUCCESS,
                payload,
            })
            meta.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* changeUserInterests({ payload, meta }) {
    try {
        const response = yield call(() => {
            return API.dashboard.changeUserInterests(payload)
        })

        if (response.success) {
            yield put({
                type: CHANGE_AMBASSADOR_INTERESTS_SUCCESS,
                payload,
            })
            meta.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getSaProfileTypes(action) {
    const { payload } = action
    try {
        const universityGroup = yield select(getGroup)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (universityGroup) {
            payload.universityId = currentGroupUniversityId
            payload.groupId = !currentGroupUniversityId ? universityGroup.id : null
        }
        const response = yield call(() => {
            return API.user.getSaProfileTypes(payload)
        })

        if (response.success) {
            yield put({
                type: GET_SA_PROFILE_TYPES_SUCCESS,
                payload: {
                    items: response.data.profileTypes || [],
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getStaffTypes(action) {
    const { payload } = action
    try {
        const universityGroup = yield select(getGroup)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (universityGroup) {
            payload.universityId = currentGroupUniversityId
            payload.groupId = !currentGroupUniversityId ? universityGroup.id : null
        }
        const response = yield call(() => {
            return API.user.getStaffTypes(payload)
        })

        if (response.success) {
            yield put({
                type: GET_STAFF_TYPES_SUCCESS,
                payload: {
                    items: response.data.staffTypes || [],
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getStudentTypes(action) {
    const { payload } = action
    try {
        const universityGroup = yield select(getGroup)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (universityGroup) {
            payload.universityId = currentGroupUniversityId
            payload.groupId = !currentGroupUniversityId ? universityGroup.id : null
        }
        const response = yield call(() => {
            return API.user.getStudentTypes(payload)
        })

        if (response.success) {
            yield put({
                type: GET_STUDENT_TYPES_SUCCESS,
                payload: {
                    items: response.data.studentTypes || [],
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getSubjects(action) {
    const { payload } = action
    try {
        const universityGroup = yield select(getGroup)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (universityGroup) {
            payload.universityId = currentGroupUniversityId
            payload.groupId = !currentGroupUniversityId ? universityGroup.id : null
        }
        const response = yield call(() => {
            return API.user.getSubjects(payload)
        })

        if (response.success) {
            yield put({
                type: GET_SUBJECTS_SUCCESS,
                payload: {
                    items: response.data.subjects || [],
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getYearOfStudies(action) {
    const { payload } = action
    try {
        const universityGroup = yield select(getGroup)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (universityGroup) {
            payload.universityId = currentGroupUniversityId
            payload.groupId = !currentGroupUniversityId ? universityGroup.id : null
        }
        const response = yield call(() => {
            return API.user.getYearOfStudies(payload)
        })

        if (response.success) {
            yield put({
                type: GET_YEAR_OF_STUDIES_SUCCESS,
                payload: {
                    items: response.data.yearOfStudies,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getCourseTypes(action) {
    const { payload } = action
    try {
        const universityGroup = yield select(getGroup)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (universityGroup) {
            payload.universityId = currentGroupUniversityId
            payload.groupId = !currentGroupUniversityId ? universityGroup.id : null
        }
        const response = yield call(() => {
            return API.user.getCourseTypes(payload)
        })

        if (response.success) {
            yield put({
                type: GET_COURSE_TYPES_SUCCESS,
                payload: {
                    items: response.data.courseTypes,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getIndustries(action) {
    const { payload } = action
    try {
        const universityGroup = yield select(getGroup)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (universityGroup) {
            payload.universityId = currentGroupUniversityId
            payload.groupId = !currentGroupUniversityId ? universityGroup.id : null
        }
        const response = yield call(() => {
            return API.user.getIndustries(payload)
        })

        if (response.success) {
            yield put({
                type: GET_INDUSTRIES_SUCCESS,
                payload: {
                    items: response.data.industries,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* changeAmbassadorInfo(action) {
    const { payload } = action
    try {
        const response = yield call(() => {
            return API.user.changeAmbassadorInfo(payload)
        })

        if (response.success) {
            console.log(response)
        }
    } catch (error) {
        console.log(error)
    }
}

export function* deleteAmbassador(action) {
    const { payload, meta } = action
    try {
        const response = yield call(() => {
            return API.user.deleteAmbassador(payload)
        })

        if (response.success) {
            yield put({
                type: DELETE_AMBASSADOR_SUCCESS,
                payload,
            })
            meta.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getUserTableTotals(action) {
    const { payload } = action
    const universityIds = yield select(getCurrentGroupUniversityIds)

    try {
        const response = yield call(() => {
            return API.dashboard.getUserTableTotals({ ...payload, universityIds })
        })

        if (response.success) {
            yield put({
                type: GET_USER_TABLE_TOTALS_SUCCESS,
                payload: response.data,
            })
        }
    } catch (error) {
        console.log(error)
    }
}
