import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams, Route, Switch } from 'react-router-dom'

import { LIVE_EVENT_STATUS } from '../../../../common/constants'
import { fetchLiveEvents } from '../../../../redux/actions/live-event/live-events-actions'
import { EventsGuideScreen, EventsListScreen, EventItemScreen } from '../index'
import { getCommonRoutePath } from '../../../../helpers/helpers'

const EVENT_TYPE_PARAM = {
    UPCOMING: 'upcoming',
    PREVIOUS: 'previous',
    IN_PROGRESS: 'in-progress',
}

const EVENT_TYPE_PARAM_TO_STATUS = {
    [EVENT_TYPE_PARAM.UPCOMING]: LIVE_EVENT_STATUS.UPCOMING,
    [EVENT_TYPE_PARAM.IN_PROGRESS]: LIVE_EVENT_STATUS.IN_PROGRESS,
    [EVENT_TYPE_PARAM.PREVIOUS]: LIVE_EVENT_STATUS.PREVIOUS,
}

const isValidTypeParam = (eventStatus) => Object.values(EVENT_TYPE_PARAM).includes(eventStatus)

const EventTypeRootScreen = () => {
    const dispatch = useDispatch()
    const { eventStatus } = useParams()
    const history = useHistory()

    useEffect(() => {
        if (!isValidTypeParam(eventStatus)) {
            history.push(getCommonRoutePath() + '/live-events/start')
        } else {
            dispatch(
                fetchLiveEvents({
                    status: EVENT_TYPE_PARAM_TO_STATUS[eventStatus],
                })
            )
        }
    }, [dispatch, history, eventStatus])

    return (
        <Switch>
            <Route path={['/live-events/start', '/:university_id/live-events/start']} component={EventsGuideScreen} />
            <Route
                exact
                path={['/live-events/:eventStatus', '/:university_id/live-events/:eventStatus']}
                component={EventsListScreen}
            />
            <Route
                exact
                path={['/live-events/:eventStatus/:eventId', '/:university_id/live-events/:eventStatus/:eventId']}
                component={EventItemScreen}
            />
        </Switch>
    )
}

export default EventTypeRootScreen
