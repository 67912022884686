import { createAction } from '@reduxjs/toolkit'

export const TWO_TIER_MENU_CHANGE_SUBMENU = 'TWO_TIER_MENU_CHANGE_SUBMENU'
export const CLEAR_TWO_TIER_MENU = 'CLEAR_TWO_TIER_MENU'

export const setSubmenu = createAction(TWO_TIER_MENU_CHANGE_SUBMENU, (activeSubmenu) => {
    return {
        payload: {
            activeSubmenu,
        },
    }
})
