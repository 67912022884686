/**
 * Groups the elements of an array based on the keys returned by a keyGetter function.
 * @param keyGetter A function that takes an item of type `Type` and returns a key of type `Key`.
 * @returns A `Map` object where each key corresponds to an array of elements from the input array that have the same key.
 */
export function groupMapBy(keyGetter) {
    return function (list) {
        var map = new Map();
        list.forEach(function (item) {
            var key = keyGetter(item);
            var collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            }
            else {
                collection.push(item);
            }
        });
        return map;
    };
}
/**
 * Converts a Map into an array of key-value pairs.
 * @param map - The input map from which key-value pairs are extracted.
 * @returns An array of key-value pairs extracted from the input map.
 */
export function toMapPairs(map) {
    // eslint-disable-next-line unicorn/prefer-spread
    return Array.from(map.entries());
}
