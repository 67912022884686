import React, { useState, useCallback } from 'react'

import { Input, Button, Row, Col, Form } from 'antd'

import { useDispatch, useSelector } from 'react-redux'

import { getUniversityInfo } from '../../../../../redux/selectors'
import { getPlsTapFeedLink } from '../../../../../redux/actions/account/account-actions'

const TapHostedTapFeed = () => {
    const universityInfo = useSelector(getUniversityInfo)
    const { url, slug } = universityInfo

    const [editMode, setEditMode] = useState(false)
    const [tapFeedUrl, setTapFeedUrl] = useState(`${url}/${slug}`)

    const dispatch = useDispatch()

    const editUrl = useCallback(() => {
        setTapFeedUrl((tapFeedUrl) => tapFeedUrl.slice(0, Math.max(0, tapFeedUrl.lastIndexOf('/') + 1)))
        setEditMode(true)
    }, [])

    const onGetPlsTapFeedLinkSuccess = useCallback((slug) => {
        setTapFeedUrl((tapFeedUrl) => `${tapFeedUrl}${slug}`)
        setEditMode(false)
    }, [])

    const saveUrl = useCallback(
        (values) => {
            dispatch(getPlsTapFeedLink({ slug: values.editedUrl }, onGetPlsTapFeedLinkSuccess))
        },
        [dispatch, onGetPlsTapFeedLinkSuccess]
    )

    const cancelEditUrl = useCallback(() => {
        setTapFeedUrl(`${url}/${slug}`)
        setEditMode(false)
    }, [url, slug])

    const normalizeValue = useCallback((val) => {
        return val.trim()
    }, [])

    return (
        <Row style={{ marginBottom: '2rem' }}>
            <Col style={{ fontSize: '1.6rem', opacity: 0.6, paddingTop: '4px' }}>
                {!editMode ? (
                    <a className="edit-url-link" href={tapFeedUrl} target="__blank">
                        {tapFeedUrl}
                    </a>
                ) : (
                    tapFeedUrl
                )}
            </Col>
            {editMode ? (
                <Form
                    layout="vertical"
                    initialValues={{
                        editedUrl: '',
                    }}
                    onFinish={saveUrl}
                    autoComplete="off"
                    hideRequiredMark
                    style={{ display: 'flex' }}
                >
                    <Form.Item
                        name="editedUrl"
                        label={null}
                        rules={[
                            { required: true, message: 'Add custom url' },
                            {
                                pattern: new RegExp('^[A-Za-z0-9_-]*$'),
                                message: 'Format is wrong',
                            },
                        ]}
                        normalize={normalizeValue}
                    >
                        <Input maxLength={50} type="text" />
                    </Form.Item>
                    <div>
                        <Button className="settings-btn" type="link" htmlType="submit">
                            Save
                        </Button>
                        <Button className="settings-btn" type="link" onClick={cancelEditUrl}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            ) : (
                <>
                    <Button className="settings-btn" type="link" onClick={editUrl}>
                        Edit
                    </Button>
                </>
            )}
        </Row>
    )
}

export default TapHostedTapFeed
