import {
    OPEN_SETTINGS_MESSAGING_POPUP,
    CLOSE_SETTINGS_MESSAGING_POPUP,
} from '../../actions/components/componentsActions'

import { SETTINGS_MESSAGING_MODAL } from '../../store/initialState'

const initialState = SETTINGS_MESSAGING_MODAL

export default function (state = initialState, action) {
    const type = action.type

    switch (type) {
        case OPEN_SETTINGS_MESSAGING_POPUP: {
            return {
                ...state,
                isOpened: true,
            }
        }

        case CLOSE_SETTINGS_MESSAGING_POPUP: {
            return {
                ...state,
                isOpened: false,
            }
        }

        default: {
            return state
        }
    }
}
