import Modal from 'antd/es/modal'
import React, { PureComponent } from 'react'
import ReactPaginate from 'react-paginate'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DateHumanizeHelper from '../../../../helpers/DateHumanizeHelper'
import store from '../../../../redux/store'
import SharedPostPreviewPopup from './popups/SharedPostPreviewPopup'

import {
    CLOSE_SHARE_POST_CONTENT_PREVIEW_MODAL,
    OPEN_SHARE_POST_CONTENT_PREVIEW_MODAL,
} from '../../../../redux/actions/content/postsActions'

import ReactPlayer from 'react-player'
import { SHARED_POSTS_INFO_REQUESTED } from '../../../../redux/actions/settings/sharing'

class SharedPostsTable extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            pageCount: 0,
            posts: [],
            previewPost: null,
        }
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleOpenFullScreenPreviewPopup = this.handleOpenFullScreenPreviewPopup.bind(this)
        this.handleCloseFullScreenPreviewPopup = this.handleCloseFullScreenPreviewPopup.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        state.pageCount = Number.parseInt(props.posts.total) > 10 ? Math.ceil(props.posts.total / 10) : state.pageCount
        state.posts =
            Number.parseInt(props.posts.total) > 10 && props.posts.items && props.posts.items.length > 0
                ? props.posts.items.slice(0, 10)
                : props.posts.items
        return state
    }

    handleChangePage(select) {
        store.dispatch({
            type: SHARED_POSTS_INFO_REQUESTED,
            payload: {
                limit: 10,
                offset: 10 * select.selected,
            },
        })
    }

    handleOpenFullScreenPreviewPopup(event) {
        const postIndex = event.currentTarget.getAttribute('data-post-index')
        const post = this.state.posts[postIndex]
        this.setState({
            previewPost: post,
        })

        store.dispatch({
            type: OPEN_SHARE_POST_CONTENT_PREVIEW_MODAL,
        })
    }

    handleCloseFullScreenPreviewPopup() {
        store.dispatch({
            type: CLOSE_SHARE_POST_CONTENT_PREVIEW_MODAL,
        })
    }

    render() {
        if (!this.state.posts) {
            return (
                <div className="non-cluster">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 -2 24 24"
                        width="70"
                        height="70"
                        preserveAspectRatio="xMinYMin"
                        className="jam jam-pictures"
                    >
                        <path d="M2 8v10h12V8H2zm2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2zm2 0h8a2 2 0 0 1 2 2v4h2V2H6v4zm0 9a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm1-7a3 3 0 1 1 6 0h-2a1 1 0 0 0-2 0H7zm1.864 13.518l-1.728-1.007 2.725-4.673a3 3 0 0 1 4.8-.52l1.088 1.184-1.473 1.354-1.087-1.184a1 1 0 0 0-1.6.174l-2.725 4.672zm6.512-12.97a2.994 2.994 0 0 1 3.285.77l1.088 1.184-1.473 1.354-1.087-1.184A1 1 0 0 0 16 8.457V8c0-.571-.24-1.087-.624-1.451z" />
                    </svg>
                    You haven't shared any content yet. Share content from the{' '}
                    <Link to={'/content/dashboard'}>Gallery</Link>
                </div>
            )
        }
        let postsRows = ''
        postsRows = this.state.posts.map((post, index) => {
            let preview = ''
            if (post.type == 'image') {
                preview = (
                    <div className="sharing-post-wrapper">
                        <img
                            data-post-index={index}
                            className="shared-content-preview-img"
                            src={post.images[0].sizes['370x370'] || post.images[0].original}
                            alt="Shared content preview image"
                        />
                    </div>
                )
            } else if (post.type === 'video') {
                preview = (
                    <div className="sharing-post-wrapper">
                        <ReactPlayer
                            data-post-index={index}
                            className="shared-content-preview-video"
                            url={post.videos[0].original}
                            aria-label=""
                            playing={false}
                            pip
                            playsinline
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        pip: 'true',
                                    },
                                },
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                        />
                    </div>
                )
            }

            return (
                <tr key={index}>
                    <td data-post-index={index} onClick={this.handleOpenFullScreenPreviewPopup}>
                        {preview}
                    </td>
                    <td>{DateHumanizeHelper(post.created_at)}</td>
                    <td>{post.publisher && post.publisher.name ? post.publisher.name : 'Deleted User'}</td>
                    <td>{post.university && post.university.name ? post.university.name : ''}</td>
                    <td>{post.type}</td>
                    <td>{post.shared_at ? DateHumanizeHelper(post.shared_at) : '-'}</td>
                    <td>
                        {post.shared_at ? 'Rss' : null}
                        {post.shared_at && post.is_tap_sharing ? ' | ' : null}
                        {post.is_tap_sharing ? 'TAP widget' : null}{' '}
                    </td>
                </tr>
            )
        })

        let pagination = null
        if (this.state.pageCount != 0) {
            pagination = (
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handleChangePage}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            )
        }

        return (
            <div className="shared-posts-container">
                <div className="table-container mod-users-table">
                    <table className="table posts-table">
                        <thead className="table-head">
                            <tr>
                                <td className="table-head-item">Preview</td>
                                <td className="table-head-item">Date</td>
                                <td className="table-head-item">User</td>
                                <td className="table-head-item">Institution</td>
                                <td className="table-head-item">Type</td>
                                <td className="table-head-item">Shared at</td>
                                <td className="table-head-item">Location</td>
                            </tr>
                        </thead>
                        <tbody>{postsRows}</tbody>
                    </table>
                    {pagination}
                </div>

                <Modal
                    open={this.props.postSharedContentPreviewModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseFullScreenPreviewPopup}
                    className="react-post-gallery"
                >
                    <SharedPostPreviewPopup
                        post={this.state.previewPost}
                        handleClosePopup={this.handleCloseFullScreenPreviewPopup}
                    />
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        posts: state.postsShared,
        postSharedContentPreviewModal: state.postSharedContentPreviewModal,
    }
})(SharedPostsTable)
