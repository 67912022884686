var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button } from 'antd';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
var validate = function (values) {
    var errors = {};
    if (!values.email) {
        errors.email = 'Please enter your email address';
    }
    else if (!/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,16}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    return errors;
};
var renderField = function (_a) {
    var input = _a.input, label = _a.label, placeholder = _a.placeholder, type = _a.type, className = _a.className, _b = _a.meta, touched = _b.touched, error = _b.error, warning = _b.warning;
    return (React.createElement("div", null,
        React.createElement("input", __assign({}, input, { className: className, placeholder: placeholder, type: type })),
        touched && ((error && React.createElement("span", { className: "error" }, error)) || (warning && React.createElement("span", null, warning)))));
};
/**
 * @deprecated
 * Have to be refactored. Redux forn is not used anymore
 */
function EmailEditForm(_a) {
    var handleSubmit = _a.handleSubmit, onCancel = _a.onCancel;
    return (React.createElement("form", { method: "POST", className: "hidden-form", onSubmit: handleSubmit },
        React.createElement("label", { className: "signin-label" },
            React.createElement(Field, { name: "email", component: renderField, type: "text", placeholder: "New Email", className: "signin-input mod-hidden settings-input" })),
        React.createElement("div", { className: "hidden-buttons-block" },
            React.createElement(Button, { htmlType: "submit", className: "settings-btn", type: "link", style: { paddingLeft: 0 }, "data-automation-id": "submit-email" }, "Submit"),
            React.createElement(Button, { className: "settings-btn mod-cancel js-hide-form", type: "link", onClick: onCancel, "data-automation-id": "cancel-email-change" }, "Cancel"))));
}
export default reduxForm({
    form: 'email_edit_form',
    validate: validate,
})(EmailEditForm);
