export const SCHEDULE_DATA_REQUESTED = 'SCHEDULE_DATA_REQUESTED'
export const SCHEDULE_DATA_SUCCESS = 'SCHEDULE_DATA_SUCCESS'
export const SCHEDULE_DATA_FAILED = 'SCHEDULE_DATA_FAILED'
export const SCHEDULE_ITEM_REQUESTED = 'SCHEDULE_ITEM_REQUESTED'
export const SCHEDULE_ITEM_SUCCESS = 'SCHEDULE_ITEM_SUCCESS'
export const SCHEDULE_ITEM_FAILED = 'SCHEDULE_ITEM_FAILED'
export const SCHEDULE_CREATE_EVENT_FAILED = 'SCHEDULE_CREATE_EVENT_FAILED'
export const SCHEDULE_EDIT_EVENT_REQUESTED = 'SCHEDULE_EDIT_EVENT_REQUESTED'
export const SCHEDULE_EDIT_EVENT_SUCCESS = 'SCHEDULE_EDIT_EVENT_SUCCESS'
export const SCHEDULE_EDIT_EVENT_FAILED = 'SCHEDULE_EDIT_EVENT_FAILED'
export const SCHEDULE_DEACTIVATE_EVENT_REQUESTED = 'SCHEDULE_DEACTIVATE_EVENT_REQUESTED'
export const SCHEDULE_DEACTIVATE_EVENT_SUCCESS = 'SCHEDULE_DEACTIVATE_EVENT_SUCCESS'
export const SCHEDULE_DEACTIVATE_EVENT_FAILED = 'SCHEDULE_DEACTIVATE_EVENT_FAILED'
export const SCHEDULE_UPDATE_STATUSES_REQUESTED = 'SCHEDULE_UPDATE_STATUSES_REQUESTED'
export const SCHEDULE_UPDATE_STATUSES_SUCCESS = 'SCHEDULE_UPDATE_STATUSES_SUCCESS'
export const SCHEDULE_UPDATE_STATUSES_FAILED = 'SCHEDULE_UPDATE_STATUSES_FAILED'

export const fetchScheduleData = (filter) => ({
    type: SCHEDULE_DATA_REQUESTED,
    filter,
})

export const fetchScheduleItem = (eventId) => ({
    type: SCHEDULE_ITEM_REQUESTED,
    eventId,
})

export const editScheduleEvent = (data) => ({
    type: SCHEDULE_EDIT_EVENT_REQUESTED,
    data,
})

export const deactivateScheduleEvent = (eventId) => ({
    type: SCHEDULE_DEACTIVATE_EVENT_REQUESTED,
    eventId,
})

export const updateScheduleEventStatuses = (eventIds, isActive) => ({
    type: SCHEDULE_UPDATE_STATUSES_REQUESTED,
    eventIds,
    isActive,
})
