import { Form } from 'antd'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { completePlsStep, activatePlsAmbassadorToChat } from '../../../redux/actions/account/account-actions'
import { getPlsWizardSetup } from '../../../redux/selectors/account-selectors'

import API from '../../../api'

export const useAmbassadorSelfInviteForm = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const plsWizardSetup = useSelector(getPlsWizardSetup)
    const { isAmbassadorActivated, id: ambassadorId } = plsWizardSetup.ambassador

    const [form] = Form.useForm()

    const initialValues = {
        ambassadorActivationToggle: isAmbassadorActivated ? '1' : '',
    }

    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'setup-ambassador-self-invite',
            },
        })
    }, [dispatch])

    const loggedInValidator = useCallback(
        async (rule, value, callback) => {
            await API.account.checkIfPlsAmbassadorLoggedIn(ambassadorId).then((response) => {
                if ((response.success && response.data.isAmbassadorLoggedIn) || !value) {
                    return Promise.resolve()
                }
                return Promise.reject(new Error('You should log in to the application as an ambassador'))
            })
        },
        [ambassadorId]
    )

    const onSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            dispatch(completePlsStep({ step: 'secondStepComplete' }))
            window.localStorage.setItem('setupWizardStep', '3')
            history.push('/setup/participant-first-message')
        })
    }, [form, history, dispatch])

    const prevStep = useCallback(() => {
        if (!window.localStorage.getItem('setupWizardStep') || +window.localStorage.getItem('setupWizardStep') < 2) {
            window.localStorage.setItem('setupWizardStep', '1')
        }

        history.push('/setup/organization-details')
    }, [history])

    const handleActivationToggle = useCallback(() => {
        dispatch(activatePlsAmbassadorToChat(!isAmbassadorActivated, ambassadorId))
    }, [ambassadorId, isAmbassadorActivated, dispatch])

    return {
        form,
        onSubmit,
        prevStep,
        initialValues,
        handleActivationToggle,
        loggedInValidator,
    }
}
