import { Select } from 'antd'
import { isEmpty, uniq } from 'ramda'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import { CREATE_GLOBAL_TAG, GET_GLOBAL_LIST_TAGS } from '../../redux/actions/tags/tagsActions'
import { getGlobalTagsSlice } from '../../redux/selectors'

const { Option } = Select

const LIST_HEIGHT = 100

const CustomSelect = ({ value, onChange }) => {
    const dispatch = useDispatch()
    const globalTagsSlice = useSelector(getGlobalTagsSlice)

    const [searchValue, setSearchValue] = useState('')

    const { items, total, offset, limit } = globalTagsSlice

    const [localTags, setLocalTags] = useState(value.map((item) => ({ ...item, id: item.id + '' })))
    const [localValue, setLocalValue] = useState([])

    const tags = useMemo(() => {
        const parsedItems = items.map(({ id, name }) => ({ id: id + '', name }))
        return uniq([...parsedItems, ...localTags])
    }, [items, localTags])

    const options = useMemo(() => {
        return tags.map(({ id, name }) => <Option key={id}>{name}</Option>)
    }, [tags])

    const triggerChange = useCallback(
        (changedValue) => {
            onChange(changedValue)
        },
        [onChange]
    )

    const handleInputKeyDown = useCallback((e) => {
        if (e.keyCode === 8) e.stopPropagation()
    }, [])

    const debounced = useDebouncedCallback((inputValue) => {
        const searchName = inputValue.trim()

        if (isEmpty(searchName)) return

        dispatch({
            type: GET_GLOBAL_LIST_TAGS,
            payload: {
                limit: 50,
                offset: 0,
                searchName,
                excludeIds: [],
            },
        })
    }, 300)

    const handleSearch = useCallback(
        (inputValue) => {
            setSearchValue(inputValue)
            debounced(inputValue)
        },
        [debounced]
    )

    const handleSelect = useCallback(
        (selectedValue, option) => {
            if (!isEmpty(option)) {
                const tag = { id: option.value, name: option.children }
                setLocalValue((prevState) => [...prevState, tag])
                triggerChange([...value, tag])
                setLocalTags((prevState) => [...prevState, tag])
                setSearchValue('')
            } else {
                if (selectedValue && selectedValue.trim().length > 1) {
                    dispatch({
                        type: CREATE_GLOBAL_TAG,
                        payload: {
                            name: selectedValue,
                            afterSuccess: (newTag) => {
                                const tag = { id: newTag.id, name: newTag.name }
                                setLocalValue((prevState) => [...prevState, tag])
                                triggerChange([...value, tag])
                                setLocalTags((prevState) => [...prevState, tag])
                                setSearchValue('')
                            },
                        },
                    })
                }
            }
        },
        [triggerChange, value, dispatch]
    )

    const handleDeselect = useCallback(
        (selectedValue) => {
            setLocalValue((prevState) => prevState.filter((tag) => +tag.id !== +selectedValue))
            triggerChange(value.filter((tag) => +tag.id !== +selectedValue))
            setLocalTags((prevState) => prevState.filter((tag) => +tag.id !== +selectedValue))
        },
        [triggerChange, value]
    )

    const handlePopupScroll = useCallback(
        (event) => {
            const target = event.target

            if (target.scrollTop + LIST_HEIGHT === target.scrollHeight) {
                console.log('load more')
                if (total > offset + limit) {
                    dispatch({
                        type: GET_GLOBAL_LIST_TAGS,
                        payload: {
                            limit: limit,
                            offset: offset + limit,
                            // searchName: searchString.trim(),
                            excludeIds: localValue,
                        },
                    })
                }
            }
        },
        [dispatch, localValue, limit, offset, total]
    )

    const handleBlur = useCallback(() => {
        setSearchValue('')
    }, [])

    const dropDownOpen = useMemo(() => {
        return items.length > 0 && searchValue.trim().length > 0
    }, [searchValue, items])

    const selectValue = useMemo(() => {
        const data = value || localValue

        return data.map(({ id }) => id + '')
    }, [value, localValue])

    return (
        <Select
            mode="tags"
            placeholder="Add tags"
            popupClassName="tap-tag-select"
            filterOption={false}
            notFoundContent={null}
            onInputKeyDown={handleInputKeyDown}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            value={selectValue}
            listHeight={LIST_HEIGHT}
            onPopupScroll={handlePopupScroll}
            searchValue={searchValue}
            onBlur={handleBlur}
            open={dropDownOpen}
        >
            {options}
        </Select>
    )
}

export default CustomSelect
