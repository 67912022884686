import { all, call } from 'redux-saga/effects'

import liveEventCreateSaga from './live-event-create-saga'
import liveEventUpdateSaga from './live-event-update-saga'
import liveEventsFetchSaga from './live-events-fetch-saga'
import startLiveEventSaga from './start-live-event-saga'

function* liveEventRootSaga() {
    yield all([
        call(liveEventsFetchSaga),
        call(liveEventCreateSaga),
        call(liveEventUpdateSaga),
        call(startLiveEventSaga),
    ])
}

export default liveEventRootSaga
