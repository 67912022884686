import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import TAPFilter from '../../../components/TAPFilter'
import { ReactComponent as Close } from '../../../../assets/svg/icons/close.svg'

const TAPFilters = (props) => {
    const { handleSubmit, tapFilters } = props

    const initialFilter = {
        filterIndex: 1,
        isAllFilled: false,
        id: 9310299415910419,
    }

    const [filters, setFilters] = useState([])

    useEffect(() => {
        setFilters(
            tapFilters.filters.length
                ? tapFilters.filters.map((filter, index) => ({
                      ...filter,
                      filterIndex: index + 1,
                      isAllFilled: true,
                  }))
                : [initialFilter]
        )
    }, [tapFilters.filters, setFilters])

    const removeFilter = (index) => {
        setFilters((state) => state.filter((item) => item.filterIndex !== index))
    }

    const addFilter = () => {
        if (filters.length < 3) {
            const availableIndex = [1, 2, 3].filter((index) => !filters.find((item) => item.filterIndex === index))[0]
            setFilters((state) => [...state, { filterIndex: availableIndex, isAllFilled: false }])
        }
    }

    const isAllFiltersFilled = filters.reduce((acc, filter) => acc && filter.isAllFilled, true)

    return (
        <div className="filters-wrapper">
            {filters.length ? (
                filters.map((item, index) => {
                    return (
                        <TAPFilter
                            // isInitial={isInitialFilter && !index}
                            initialData={item}
                            setFilter={setFilters}
                            addFilter={addFilter}
                            removeFilter={removeFilter}
                            key={item.id}
                            filterNum={index}
                            possibleFiltersNum={3}
                            activeFiltersNum={filters.length}
                            tapFilters={tapFilters}
                        />
                    )
                })
            ) : (
                <button className="filter__add-btn filter__add-btn--initial" onClick={addFilter}>
                    <Close className="filter__add-btn__icon" />
                    Add filter
                </button>
            )}
            <button
                disabled={!isAllFiltersFilled}
                type="submit"
                onClick={() => handleSubmit('filters', filters)}
                className={cn('settings-submit-btn', { 'settings-submit-btn--disabled': !isAllFiltersFilled })}
            >
                Save configurations
            </button>
        </div>
    )
}

TAPFilters.propTypes = {}

export default TAPFilters
