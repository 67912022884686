import React, { PureComponent } from 'react'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'
import Loader from '../Loader'
import ReactPlayer from 'react-player'

class UploadPostModal extends PureComponent {
    render() {
        const { file, fileId, fileType, videoFile, transcodeStatus, handleSubmitUploadPopup, handleClose } = this.props
        if (!file) return ''

        if (fileType.includes('image/')) {
            return (
                <div className="upload-preview-modal">
                    <button type="button" className="close-modal closer-button" onClick={handleClose}>
                        <svg className="icon icon-close">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#close'}></use>
                        </svg>
                    </button>
                    <img src={URL.createObjectURL(file)} className="upload-preview" />
                    <div className="upload-preview-bottom">
                        <input
                            type="text"
                            className="chat-input"
                            placeholder="Caption…"
                            name="message-picture-caption"
                            id="message-picture-caption"
                            maxLength={200}
                        />
                        <button type="button" onClick={handleSubmitUploadPopup} className="chat-sbm">
                            <svg className="icon icon-send">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#send'} />
                            </svg>
                        </button>
                    </div>
                </div>
            )
        } else if (fileType.includes('video/')) {
            const disabled =
                (!fileId && transcodeStatus === '') || (!fileType.includes('mp4') && transcodeStatus !== 'COMPLETE')
            return (
                <div className="upload-preview-modal">
                    <button type="button" className="close-modal closer-button" onClick={handleClose}>
                        <svg className="icon icon-close">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#close'}></use>
                        </svg>
                    </button>
                    {transcodeStatus === 'COMPLETE' || (fileId && fileType.includes('mp4')) ? (
                        <ReactPlayer
                            className="upload-preview-video"
                            url={videoFile}
                            aria-label=""
                            playing={false}
                            pip
                            playsinline
                            loop
                            controls
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: 'nodownload',
                                        pip: 'true',
                                    },
                                },
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                        />
                    ) : (
                        <div className="loading-video-block">
                            {transcodeStatus === 'CANCELED' || transcodeStatus === 'ERROR' ? (
                                <div className="video-upload-error-block">
                                    Sorry your video hasn't been uploaded. This video format is not currently supported.
                                </div>
                            ) : (
                                <Loader />
                            )}
                        </div>
                    )}
                    <div className="upload-preview-bottom">
                        <input
                            type="text"
                            className="chat-input"
                            placeholder="Caption…"
                            name="message-picture-caption"
                            id="message-picture-caption"
                            maxLength={200}
                        />
                        <button
                            type="button"
                            onClick={handleSubmitUploadPopup}
                            className={disabled ? 'chat-sbm disabled' : 'chat-sbm'}
                            disabled={disabled}
                        >
                            <svg className="icon icon-send">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#send'} />
                            </svg>
                        </button>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

export default UploadPostModal
