import { Form } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { finishPlsSignUp, openSuccessSignUpModal } from '../../../redux/actions/pls-sign-up/pls-sign-up-actions'

import API from '../../../api'

const localStorrageWrapper = window.localStorage

export const useParticipantFirstMessageForm = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [form] = Form.useForm()

    const initialValues = {
        prospectMessage: '',
    }

    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'setup-participant-first-message',
            },
        })
    }, [dispatch])

    const messageSentValidator = useCallback(async () => {
        await API.account.checkIfPlsProspectMessageSent().then((response) => {
            if (response.success && response.data.isMessageSent) {
                setShowErrorMessage(false)
                return Promise.resolve()
            }
            setShowErrorMessage(true)
            return Promise.reject(new Error('You should send a message as a Prospect'))
        })
    }, [])

    const onPlsSignUpSuccess = useCallback(() => {
        localStorrageWrapper.removeItem('setupWizardStep')
        localStorrageWrapper.removeItem('PlsAmbassadorLoginPassword')
        localStorrageWrapper.removeItem('PlsProspectLoginPassword')

        setTimeout(() => {
            history.push('/')
            dispatch(openSuccessSignUpModal())
        }, 0)
    }, [history, dispatch])

    const onSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            console.log('submitForm values :', values)
            dispatch(finishPlsSignUp(onPlsSignUpSuccess))
        })
    }, [form, onPlsSignUpSuccess, dispatch])

    const prevStep = useCallback(() => {
        window.localStorage.setItem('setupWizardStep', '2')
        history.push('/setup/ambassador-self-invite')
    }, [history])

    return {
        form,
        onSubmit,
        prevStep,
        initialValues,
        messageSentValidator,
        showErrorMessage,
    }
}
