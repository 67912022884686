import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import OptionalQuestionsForm from '../../../components/forms/optionalQuestionsForm'
import OTPVerificationToggle from '../../../components/otp-verification-toggle/otp-verification-toggle'
import Loader from '../../content/components/Loader'
import store from '../../../redux/store'
import { Prompt } from 'react-router-dom'

import { GET_OPTIONAL_QUESTIONS_REQUESTED } from '../../../redux/actions/chat/optionalQuestions'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

import { checkCanSeeOTPVerification } from '../../../common/utils/user-account-helpers'

class OptionalQuestions extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            formEdited: false,
        }

        this.changeEditedForm = this.changeEditedForm.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: GET_OPTIONAL_QUESTIONS_REQUESTED,
            payload: {},
        })

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: GET_OPTIONAL_QUESTIONS_REQUESTED,
                payload: {},
            })
        }
    }

    changeEditedForm(formEdited) {
        this.setState({
            formEdited,
        })
    }

    render() {
        const {
            account,
            optionalQuestions: { isLoading, questions },
        } = this.props
        const {
            account_info: { university, isGroupAdmin },
            currentGroupUniversityId,
        } = account

        const canSeeOTPVerification =
            (isGroupAdmin && !currentGroupUniversityId) || checkCanSeeOTPVerification(university)
        return (
            <div>
                <Prompt
                    when={this.state.formEdited}
                    message="Are you sure you want to leave this page? Changes will not be saved"
                />

                {isLoading ? (
                    <Loader />
                ) : (
                    <div id="page-body" className="page-body js-index">
                        <section className="content settings" id="settings-page">
                            <h3 className="settings-title">Prospects' additional data</h3>

                            <div className="settings-grid has-tutorial">
                                <div className="column">
                                    <div className="tutorial-intro">
                                        <p>
                                            When using Chat, your prospects will be creating accounts on The Ambassador
                                            Platform in order to engage with your Ambassadors.
                                        </p>
                                        <p>
                                            They’re your prospects so you decide what information you’d like to collect
                                            from them.
                                        </p>
                                        <p>
                                            Remember, the more data points you ask for, the higher the chance that a
                                            prospective student will not complete sign up. Keep the data points to a
                                            minimum, we recommend you don’t use more than 3 unless there’s a good reason
                                            to do so.
                                        </p>
                                    </div>
                                    <hr className="settings-hr"></hr>

                                    <div className="settings-item">
                                        <h4 className="settings-name">Set your questions</h4>
                                        <p>
                                            Questions set as Mandatory will be included as a part of Prospect sign-up.
                                            Questions marked as Optional will be posted after.
                                        </p>
                                        {canSeeOTPVerification && <OTPVerificationToggle />}
                                        <OptionalQuestionsForm
                                            questions={questions}
                                            questionsLoading={isLoading}
                                            account={account}
                                            changeEditedForm={this.changeEditedForm}
                                        />
                                    </div>
                                </div>

                                <div className="column resources">
                                    <h3 className="video-title">Video tutorial: Setting up additional data</h3>
                                    <div className="video-wrapper">
                                        <iframe
                                            src="https://player.vimeo.com/video/340188110"
                                            width="640"
                                            height="360"
                                            frameBorder="0"
                                            allow="autoplay; fullscreen"
                                            allowFullScreen
                                        ></iframe>
                                    </div>

                                    <div className="resources-box">
                                        <h3>Helpful resources</h3>
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://knowledge.theambassadorplatform.com/data-collection-on-the-ambassador-platform"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    alt="Working with additional data"
                                                >
                                                    Data collection on The Ambassador Platform
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        optionalQuestions: state.optionalQuestions,
    }
})(OptionalQuestions)
