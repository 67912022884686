import { Button, Col, Form, Modal, Row } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg'
import { closeAmbassadorsModal, editAmbassadors } from '../../../../redux/actions/live-event/live-events-actions'
import { getLiveEventsAmbassadorsModal } from '../../../../redux/selectors'

import { AmbassadorsForm, AmbassadorsRequestModel } from './ambassadors-form'

const AmbassadorsFormModal = () => {
    const dispatch = useDispatch()
    const { open, data, submitting } = useSelector(getLiveEventsAmbassadorsModal)
    const [form] = Form.useForm()

    const handleModalClose = useCallback(() => {
        dispatch(closeAmbassadorsModal())
    }, [dispatch])

    const handleSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            const requestPayload = AmbassadorsRequestModel(values, data)
            dispatch(editAmbassadors(requestPayload))
        })
    }, [data, dispatch, form])

    const footer = useMemo(() => {
        return (
            <Row justify="center">
                <Col flex="162px">
                    <Button
                        type="primary"
                        block
                        size="large"
                        disabled={submitting}
                        loading={submitting}
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        )
    }, [handleSubmit, submitting])

    return (
        <Modal
            title="Manage ambassadors"
            footer={footer}
            open={open}
            closeIcon={<CloseIcon />}
            onCancel={handleModalClose}
            width={600}
            className="tap-event-modal"
            destroyOnClose
        >
            <AmbassadorsForm form={form} data={data} />
        </Modal>
    )
}

export default AmbassadorsFormModal
