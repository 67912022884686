import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/store';
import { getStreamModal } from '../../../../redux/selectors';
var ModalWrapper = function (_a) {
    var name = _a.name, Component = _a.component;
    var modalState = useAppSelector(getStreamModal(name)); // TODO: fix type
    var _b = useState(false), mounted = _b[0], setMounted = _b[1];
    useEffect(function () {
        if (!(modalState === null || modalState === void 0 ? void 0 : modalState.open)) {
            var timeoutId_1 = setTimeout(function () {
                setMounted(false);
            }, 1000);
            return function () { return clearTimeout(timeoutId_1); };
        }
        else {
            setMounted(true);
        }
    }, [modalState === null || modalState === void 0 ? void 0 : modalState.open, mounted]);
    return mounted ? React.createElement(Component, null) : null;
};
export default ModalWrapper;
