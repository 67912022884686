import { FilterFilled } from '@ant-design/icons'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as Chevron } from '../../../assets/img/svg/chevron-down.svg'
import { ReactComponent as Close } from '../../../assets/img/svg/close.svg'
import { ReactComponent as Download } from '../../../assets/img/svg/download.svg'
import { ChatType } from '../../common/constants'
import { getDateRange, prepareFromDate, prepareToDate } from '../../helpers/time'
import { CHATS_INFO_SEARCH, GET_CHATS_TRANSCRIPT_DATA } from '../../redux/actions/chat/messagingActions'
import store from '../../redux/store'

const pickerValue = [null, null]

class SearchFilters extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            searchName: props.chats.search,
            calendarIsOpen: false,
            chatTranscriptsSettings: null,
        }

        this.search = this.search.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.onDatesIntervalSelectorUpdate = this.onDatesIntervalSelectorUpdate.bind(this)
        this.getChatTranscripts = this.getChatTranscripts.bind(this)
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.chats.resetSearch && prevProps.chats.resetSearch !== this.props.chats.resetSearch) {
            this.setState({ searchName: '' })
        }
    }

    handleClickOutside(e) {
        if (this.state.isOpen) {
            const element = document.querySelector('#search-filter')

            let targetElement = e.target // clicked element
            do {
                if (targetElement == element || (' ' + targetElement.className + ' ').includes('ant-picker')) {
                    return
                }
                // Go up the DOM
                targetElement = targetElement.parentNode
            } while (targetElement)
            this.setState({
                isOpen: false,
            })
        }
    }

    search({
        searchName,
        unseenByAdminReset,
        items,
        feedback,
        feedbackItems,
        from,
        to,
        dateSet,
        resetUser,
        conversationStatus,
        conversationStatusItems,
    }) {
        const { filters } = this.props.chats

        items = items || filters.items
        const filtersNew = {
            items: items,
            unseenByAdmin: unseenByAdminReset ? false : filters.unseenByAdmin,
            userId: resetUser ? null : filters.userId,
            userName: resetUser ? null : filters.userName,
            cleanItems: {},
        }

        for (const item of items) {
            if (item.checked) {
                filtersNew.cleanItems[item.key] = true
            }
        }
        if (conversationStatusItems) {
            filtersNew.conversationStatus = conversationStatus ? conversationStatus.key : null
            filtersNew.conversationStatusItems = conversationStatusItems
        } else {
            filtersNew.conversationStatus = filters.conversationStatus
            filtersNew.conversationStatusItems = filters.conversationStatusItems
        }
        if (feedbackItems) {
            filtersNew.feedback = feedback ? feedback.key : null
            filtersNew.feedbackItems = feedbackItems
        } else {
            filtersNew.feedback = filters.feedback
            filtersNew.feedbackItems = filters.feedbackItems
        }

        if (!from && !dateSet) {
            from = filters.dates.fromDate
        }
        if (from) {
            filtersNew.fromDate = from
            filtersNew.cleanFromDate = dayjs(from)
                .utcOffset(0)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .format()
        } else {
            filtersNew.fromDate = null
            filtersNew.cleanFromDate = null
        }
        if (!to && !dateSet) {
            to = filters.dates.toDate
        }
        if (to) {
            filtersNew.toDate = to
            filtersNew.cleanToDate = dayjs(to)
                .add(1, 'day')
                .utcOffset(0)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .format()
        } else {
            filtersNew.toDate = null
            filtersNew.cleanToDate = null
        }

        this.setState({
            chatTranscriptsSettings: {
                search: searchName,
                filters: filtersNew,
                type_chat: this.props.type_chat || ChatType.CHAT,
            },
        })

        store.dispatch({
            type: CHATS_INFO_SEARCH,
            payload: {
                resetSearch: !searchName,
                search: searchName,
                filters: filtersNew,
                type_chat: this.props.type_chat || ChatType.CHAT,
            },
        })
    }

    onChange(e) {
        this.setState({
            searchName: e.target.value,
        })
    }

    toggleMenu = () => {
        const { isOpen } = this.state
        this.setState({
            isOpen: !isOpen,
            calendarIsOpen: !!isOpen,
        })
    }

    onSelect = (e, selectedItem) => {
        const { items } = this.props.chats.filters

        const itemsSettled = items.map((item) => {
            return {
                ...item,
                checked: item.key === selectedItem.key ? !item.checked : item.checked,
            }
        })

        const resetUser = selectedItem.key === 'unanswered'
        this.search({ items: itemsSettled, resetUser })
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            this.search({ searchName: this.state.searchName })
        }
    }

    handleFeedbackButton = (e, selectedItem) => {
        const { feedbackItems, feedback } = this.props.chats.filters
        let currentItem = selectedItem

        const itemsSettled = feedbackItems.map((itm, index) => {
            const item = { ...itm }
            if (selectedItem) {
                item.checked = item.key === selectedItem.key
            } else {
                item.checked = feedback ? false : index === 0
                if (!feedback && item.checked) {
                    currentItem = item
                }
            }
            return item
        })

        this.search({ feedback: currentItem, feedbackItems: itemsSettled })
    }

    handleStatusButton = (e, selectedItem) => {
        const { conversationStatus, conversationStatusItems } = this.props.chats.filters
        let currentItem = selectedItem

        const itemsSettled = conversationStatusItems.map((itm, index) => {
            const item = { ...itm }
            if (selectedItem) {
                item.checked = item.key === selectedItem.key
            } else {
                item.checked = conversationStatus ? false : index === 0
                if (!conversationStatus && item.checked) {
                    currentItem = item
                }
            }
            return item
        })

        this.search({ conversationStatus: currentItem, conversationStatusItems: itemsSettled })
    }

    handleDeleteItem = (selectedItem, type = 'item') => {
        const { items, feedbackItems, conversationStatusItems } = this.props.chats.filters
        switch (type) {
            case 'unseen': {
                this.search({ unseenByAdminReset: true })

                break
            }
            case 'feedback': {
                const itemsSettled = feedbackItems.map((item) => {
                    item.checked = false
                    return item
                })
                this.search({ feedback: null, feedbackItems: itemsSettled })

                break
            }
            case 'date': {
                this.search({ from: null, to: null, dateSet: true })

                break
            }
            case 'conversationStatus': {
                const itemsSettled = conversationStatusItems.map((item) => {
                    item.checked = false
                    return item
                })
                this.search({ conversationStatus: null, conversationStatusItems: itemsSettled })

                break
            }
            default: {
                const itemsSettled = items.map((item) => {
                    if (item.key === selectedItem.key) {
                        item.checked = false
                    }
                    return item
                })

                const resetUser = selectedItem.key === 'unanswered'
                this.search({ items: itemsSettled, resetUser })
            }
        }
    }

    toggleCalendar = (e) => {
        this.setState({
            isOpen: true,
            calendarIsOpen: !this.state.calendarIsOpen,
        })
    }

    onDatesIntervalSelectorUpdate(selectedDateRange) {
        this.setState(
            {
                calendarIsOpen: false,
            },
            () => {
                this.search({
                    from: selectedDateRange.fromDate,
                    to: selectedDateRange.toDate,
                })
            }
        )
    }

    getChatTranscripts() {
        const onSuccess = (response) => {
            this.downloadFile(response.csvURL)
        }

        store.dispatch({
            type: GET_CHATS_TRANSCRIPT_DATA,
            payload: this.props.chats.chatsTranscriptSettings,
            onSuccess: onSuccess,
        })
    }

    downloadFile(file) {
        setTimeout(() => {
            const link = document.createElement('a')
            link.download = file
            link.href = file
            document.body.append(link)
            link.click()
            link.remove()
        }, 1000)
    }

    render() {
        const { searchName, isOpen, calendarIsOpen } = this.state
        const { chats, placeholder, type_chat: typeChat } = this.props

        const { filters } = chats

        const dateFormat = 'DD-MM-YYYY'
        const formattedFromDate = filters.dates.fromDate
            ? dayjs.utc(filters.dates.fromDate).format('DD MMM YYYY')
            : null
        const formattedToDate = filters.dates.toDate ? dayjs.utc(filters.dates.toDate).format('DD MMM YYYY') : null

        return (
            <div id="search-filter" className="search-filter-custom">
                <div className="search-message-terms">
                    {filters.unseenByAdmin && (
                        <div className="search-message-term">
                            <span className="term-text">Unreviewed</span>
                            <Close
                                onClick={() => this.handleDeleteItem(null, 'unseen')}
                                className="search-message-term-icon"
                            />
                        </div>
                    )}
                    <>
                        {filters.items.map(
                            (item) =>
                                item.checked && (
                                    <div key={item.key} className="search-message-term">
                                        <span className="term-text">
                                            {item.key === 'unanswered' && filters.userName
                                                ? filters.userName + "'s " + item.name.toLowerCase()
                                                : item.name}
                                        </span>
                                        <Close
                                            onClick={() => this.handleDeleteItem(item)}
                                            className="search-message-term-icon"
                                        />
                                    </div>
                                )
                        )}
                    </>
                    <>
                        {filters.feedbackItems.map(
                            (item) =>
                                item.checked && (
                                    <div key={item.key} className="search-message-term">
                                        <span className="term-text">With {item.name} feedback</span>
                                        <Close
                                            onClick={() => this.handleDeleteItem(item, 'feedback')}
                                            className="search-message-term-icon"
                                        />
                                    </div>
                                )
                        )}
                    </>
                    <>
                        {filters.conversationStatusItems.map(
                            (item) =>
                                item.checked && (
                                    <div key={item.key} className="search-message-term">
                                        <span className="term-text">With {item.name} status</span>
                                        <Close
                                            onClick={() => this.handleDeleteItem(item, 'conversationStatus')}
                                            className="search-message-term-icon"
                                        />
                                    </div>
                                )
                        )}
                    </>
                    <>
                        {formattedFromDate && formattedToDate && (
                            <div key="from-to" className="search-message-term">
                                <div className="term-text">
                                    <span>{formattedFromDate}</span>
                                    <span className="tilde">~</span>
                                    <span>{formattedToDate}</span>
                                    <Close
                                        onClick={() => this.handleDeleteItem('from', 'date')}
                                        className="search-message-term-icon"
                                    />
                                </div>
                            </div>
                        )}
                    </>
                </div>
                <div className={isOpen ? 'input-wrapper input-wrapper-open messaging' : 'input-wrapper messaging'}>
                    <div className="search-messages-input-icon">
                        <svg
                            className="search-icon jam jam-search"
                            viewBox="-2.5 -2.5 24 24"
                            width="20"
                            height="20"
                            preserveAspectRatio="xMinYMin"
                            onClick={this.search}
                        >
                            <path d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zm6.32-1.094l3.58 3.58a1 1 0 1 1-1.415 1.413l-3.58-3.58a8 8 0 1 1 1.414-1.414z" />
                        </svg>
                    </div>
                    <input
                        className="search-filter-input"
                        onChange={this.onChange}
                        onKeyDown={this.onKeyDown}
                        value={searchName || ''}
                        placeholder={placeholder || 'Search messages'}
                    />
                    <div className="icon-open-block messaging">
                        <button className="chat-transcripts-button" onClick={this.toggleMenu}>
                            <FilterFilled style={{ marginRight: '5px', color: '#ff5100' }} />
                            <span className="chat-transcripts-text">Filter</span>
                        </button>
                    </div>
                </div>
                <div
                    className={
                        !calendarIsOpen && isOpen
                            ? 'menu-wrapper menu-wrapper-open messaging'
                            : 'menu-wrapper messaging'
                    }
                >
                    <div id="search-filter-menu" className="search-filter-menu">
                        <div>
                            {filters.items.map(
                                (item) =>
                                    ((typeChat !== ChatType.GROUP_CHAT &&
                                        item.key !== 'private' &&
                                        item.key !== 'public') ||
                                        (typeChat === ChatType.GROUP_CHAT && item.key !== 'reported')) && (
                                        <div
                                            key={item.key}
                                            onClick={(e) => this.onSelect(e, item)}
                                            className="search-filter-item"
                                        >
                                            <span
                                                className={
                                                    item.checked
                                                        ? 'search-filter-check-icon checked'
                                                        : 'search-filter-check-icon'
                                                }
                                            ></span>
                                            <span className="drop-item">{item.name}</span>
                                        </div>
                                    )
                            )}
                        </div>
                        {typeChat !== ChatType.GROUP_CHAT && (
                            <>
                                <div
                                    className="search-filter-item feedback-title"
                                    onClick={(e) => this.handleFeedbackButton(e)}
                                >
                                    <span
                                        className={
                                            filters.feedback
                                                ? 'search-filter-check-icon checked'
                                                : 'search-filter-check-icon'
                                        }
                                    ></span>
                                    <span className="drop-item">Feedback</span>
                                </div>
                                <div className="feedback-items">
                                    <div className="feedback-body">
                                        {filters.feedbackItems.map((item) => (
                                            <div key={item.key} className="feedback-item">
                                                <label
                                                    className={
                                                        item.checked
                                                            ? 'feedback-label feedback-item-checked'
                                                            : 'feedback-label'
                                                    }
                                                    onClick={(e) => this.handleFeedbackButton(e, item)}
                                                >
                                                    {item.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="search-filter-item feedback-title" onClick={(e) => this.handleStatusButton(e)}>
                            <span
                                className={
                                    filters.conversationStatus
                                        ? 'search-filter-check-icon checked'
                                        : 'search-filter-check-icon'
                                }
                            ></span>
                            <span className="drop-item">Conversation status</span>
                        </div>
                        <div className="feedback-items">
                            <div className="feedback-body">
                                {filters.conversationStatusItems.map((item) => (
                                    <div key={item.key} className="feedback-item">
                                        <label
                                            className={
                                                item.checked ? 'feedback-label feedback-item-checked' : 'feedback-label'
                                            }
                                            onClick={(e) => this.handleStatusButton(e, item)}
                                        >
                                            {item.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="p-4">
                            <DatePicker.RangePicker
                                format={dateFormat}
                                value={pickerValue}
                                className="date-picker w-full"
                                popupClassName="search-filters-calendar-custom"
                                renderExtraFooter={() => (
                                    <div className="back-to-filter">
                                        <div className="button-calendar-back" onClick={this.toggleCalendar}>
                                            <Chevron className="date-back-chevron" />
                                            <span className="date-back-text">Back to filter</span>
                                        </div>
                                    </div>
                                )}
                                onChange={(dates) => {
                                    console.log(dates)
                                    if (dates === null) {
                                        this.onDatesIntervalSelectorUpdate(getDateRange(30))
                                    } else {
                                        this.onDatesIntervalSelectorUpdate({
                                            fromDate: prepareFromDate(dates[0]),
                                            toDate: prepareToDate(dates[1]),
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                {typeChat !== ChatType.GROUP_CHAT ? (
                    <div className="chat-transcripts-button" onClick={this.getChatTranscripts}>
                        <Download className="chat-transcripts-icon" />
                        <span className="chat-transcripts-text">Chat transcripts</span>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default connect((state) => {
    return {
        chats: state.chats,
    }
})(SearchFilters)
