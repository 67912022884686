import React, { PureComponent } from 'react'
import { Card, Row, Col } from 'antd'

import AreaChart from '../components/AreaChart'
import DonutChart from '../components/DonutChart'
import LineChart from '../components/LineChart'
import ChartHeader from '../components/ChartHeader'

import {
    prepareTimeData,
    prepareAverageTimeData,
    prepareNumberDataWithAverage,
    numberTooltipFormatter,
    timeTooltipFormatter,
    yAxisTimeFormatter,
    pluralize,
} from '../../../helpers/chart'

class Chat extends PureComponent {
    prepareResponseTimeChartData() {
        return {
            data: prepareTimeData(this.props.data.ambassadorResponseTime, [
                [Date.parse(this.props.fromDate), 0],
                [Date.parse(this.props.toDate), 0],
            ]),
            averageTime: prepareAverageTimeData(this.props.data.ambassadorResponseTimeAsOne, 'No data'),
        }
    }

    prepareFeedbackScoresChartData() {
        const scores = this.props.data.feedbackScores

        if (!scores) {
            return { data: [], centerText: 'No Data' }
        }

        const data = [
            {
                name: 'Positive Feedback',
                y: scores.good.number,
            },
            {
                name: 'Neutral Feedback',
                y: scores.normal.number,
            },
            {
                name: 'Negative Feedback',
                y: scores.bad.number,
            },
        ]

        let color = ''
        let score = 0
        let name = ''

        if (scores.good.number >= scores.normal.number && scores.good.number >= scores.bad.number) {
            color = '#27AE60'
            score = scores.good.percent
            name = 'Positive'
        } else if (scores.normal.number >= scores.good.number && scores.normal.number >= scores.bad.number) {
            color = '#F7DC6F'
            score = scores.normal.percent
            name = 'Neutral'
        } else {
            color = '#E74C3C'
            score = scores.bad.percent
            name = 'Negative'
        }

        return {
            data: data,
            centerText:
                '<div style="font-size: 24px; color: ' +
                color +
                ';">' +
                score +
                '%</div><br />' +
                '<div style="font-size: 10px; color: rgba(0,0,0,0.45);">' +
                name +
                ' Feedback Points</div>',
        }
    }

    prepareConversationsChartData() {
        return prepareNumberDataWithAverage(this.props.data.conversations, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    prepareMessagesChartData() {
        return prepareNumberDataWithAverage(this.props.data.messagesSent, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    responseTimeTooltipFormatter() {
        return timeTooltipFormatter(this.point.x, this.point.y)
    }

    conversationsTooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'conversation'))
    }

    messagesTooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'message'))
    }

    yAxisResponseTimeChart() {
        return yAxisTimeFormatter(this.value)
    }

    render() {
        const responseTimeData = this.prepareResponseTimeChartData()
        const feedbackData = this.prepareFeedbackScoresChartData()
        const conversationsData = this.prepareConversationsChartData()
        const messagesData = this.prepareMessagesChartData()
        const colors = ['#27AE60', '#F7DC6F', '#E74C3C']
        return (
            <>
                <Row gutter={16}>
                    <Col span={16}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Median First Response Time"
                                label={responseTimeData.averageTime}
                                labelDescription="Median First Response Time (across date range)"
                                displaySingleLabel={true}
                            />
                            <AreaChart
                                color="#ff5100"
                                data={responseTimeData.data}
                                tooltipFormatter={this.responseTimeTooltipFormatter}
                                yAxisFormatter={this.yAxisResponseTimeChart}
                                height={200}
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card className="reporting-block">
                            <ChartHeader title="Prospect Feedback Scores" />
                            <DonutChart
                                colors={colors}
                                data={feedbackData.data}
                                centerText={feedbackData.centerText}
                                height={248}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Number of Conversations"
                                label={'Average: ' + conversationsData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + conversationsData.total}
                                totalLabelDescription="Across date range"
                            />
                            <LineChart
                                color="#d2e116"
                                data={conversationsData.data}
                                height={200}
                                tooltipFormatter={this.conversationsTooltipFormatter}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Number of Messages"
                                label={'Average: ' + messagesData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + messagesData.total}
                                totalLabelDescription="Across date range"
                            />
                            <LineChart
                                color="#afd0eb"
                                data={messagesData.data}
                                height={200}
                                tooltipFormatter={this.messagesTooltipFormatter}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default Chat
