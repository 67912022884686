import { Avatar, Form, Typography, Checkbox, Row, Col, Button, Input } from 'antd'
import cn from 'classnames'
import { isEmpty, pluck, uniq, without } from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { getAccountInfo } from '../../../../../../redux/selectors'

const WRAPPER_STYLE = {
    width: '100%',
    height: 230,
    overflowY: 'auto',
    padding: '10px 35px',
}

const STATISTICS_STYLE = {
    minHeight: '50px',
}

const { Text } = Typography

const pluckIds = pluck('id')

const EMPTY_NAME_PLACEHOLDER = 'N/A'
const SELECT_ALL_TEXT = 'Select all'
const UNSELECT_ALL_TEXT = 'Unselect all'

const joinSelectedUsers = (users, savedUsers) =>
    users
        .map((userId) => {
            const item = savedUsers.find(({ id }) => id === userId)
            return item ? item.name : EMPTY_NAME_PLACEHOLDER
        })
        .join(', ')

const UserAvatar = ({ user }) => {
    const { avatar, name } = user

    if (avatar) {
        return <Avatar style={{ margin: 0 }} size={45} src={avatar.sizes['70x70'] || avatar.original} />
    }

    return (
        <Avatar style={{ margin: 0 }} size={45}>
            {name ? name[0] : EMPTY_NAME_PLACEHOLDER}
        </Avatar>
    )
}

const UsersCheckboxList = ({ id, items, form, excludeSpeakers }) => {
    const accountInfo = useSelector(getAccountInfo)
    const [cachedUsers, setCachedUsers] = useState([])

    useEffect(() => {
        setCachedUsers((prevState) => {
            return uniq([...prevState, ...items])
        })
    }, [items])

    const makeButtonText = useCallback(
        (allSelected) => {
            if (isEmpty(items)) {
                return SELECT_ALL_TEXT
            } else {
                return allSelected ? UNSELECT_ALL_TEXT : SELECT_ALL_TEXT
            }
        },
        [items]
    )

    const handleSelectionChange = useCallback(() => {
        const currentValue = form.getFieldValue(id)
        const allSelected = !pluckIds(items).some((selectedId) => !currentValue.includes(selectedId))

        form.setFieldsValue({
            [id]: allSelected ? without(pluckIds(items), currentValue) : uniq([...currentValue, ...pluckIds(items)]),
        })
    }, [form, id, items])

    const handleCheckboxChange = useCallback(
        ({ target: { checked, value } }) => {
            const currentValue = form.getFieldValue(id)

            form.setFieldsValue({
                [id]: checked ? [...currentValue, value] : without([value], currentValue),
            })
            form.validateFields(['speakersPlaceholder'])
        },
        [form, id]
    )

    const sortedRows = useMemo(() => {
        if (excludeSpeakers === false) {
            return items.sort((a, b) => form.getFieldValue(id).includes(b.id) - form.getFieldValue(id).includes(a.id))
        }

        const selectedSpeakers = Array.isArray(excludeSpeakers)
            ? excludeSpeakers
            : form.getFieldValue('internalSpeakerIds')

        return items
            .filter((item) => !selectedSpeakers.includes(item.id))
            .sort((a, b) => form.getFieldValue(id).includes(b.id) - form.getFieldValue(id).includes(a.id))
    }, [form, items, id, excludeSpeakers])
    return (
        <>
            <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                    const allSelected = !pluckIds(items).some((selectedId) => !getFieldValue(id).includes(selectedId))
                    const buttonText = makeButtonText(allSelected)

                    const buttonDisabled = items.length === 0

                    return (
                        <div style={{ paddingTop: '10px' }}>
                            <Button type="link" onClick={handleSelectionChange} disabled={buttonDisabled}>
                                {buttonText}
                            </Button>
                        </div>
                    )
                }}
            </Form.Item>

            <Form.Item shouldUpdate label={null}>
                {({ getFieldValue }) => {
                    const selectedIds = getFieldValue(id)
                    return (
                        <Checkbox.Group style={WRAPPER_STYLE} value={selectedIds} className="checkbox-group-custom">
                            {sortedRows.map((user) => {
                                const isLoggedInUser = user.id === accountInfo.id
                                const name = user.name || EMPTY_NAME_PLACEHOLDER

                                return (
                                    <Row key={user.id} style={{ padding: '5px 0' }}>
                                        <Checkbox
                                            value={user.id}
                                            style={{ width: '100%' }}
                                            onChange={handleCheckboxChange}
                                        >
                                            <Row gutter={15} style={{ paddingLeft: '7.5px' }}>
                                                <Col>
                                                    <UserAvatar user={user} />
                                                </Col>
                                                <Col>
                                                    <div>{name}</div>
                                                    <div>
                                                        <Text type="secondary" style={{ textTransform: 'capitalize' }}>
                                                            {isLoggedInUser ? `Me (${user.role})` : user.role}
                                                        </Text>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Checkbox>
                                    </Row>
                                )
                            })}
                        </Checkbox.Group>
                    )
                }}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                    const selectedUsers = getFieldValue(id)
                    const titleClassName = cn({
                        'tap-color-primary': selectedUsers.length > 0,
                    })

                    return (
                        <div style={STATISTICS_STYLE}>
                            <>
                                <b className={titleClassName}>{selectedUsers.length} selected</b>

                                <div>{joinSelectedUsers(selectedUsers, cachedUsers)}</div>
                            </>
                        </div>
                    )
                }}
            </Form.Item>

            <Form.Item name={id}>
                <Input style={{ display: 'none' }} />
            </Form.Item>
        </>
    )
}

export default UsersCheckboxList
