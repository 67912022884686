import axios from 'axios'
import { getUserFingerprint } from '../../../helpers/helpers'
import { ACCOUNT_LOGIN_SUCCESS } from '../../../redux/actions/account/accountActions'
import store from '../../../redux/store'
import config from '../../config'
import handler from './handler'

export const axiosInstance = axios.create({
    baseURL: config.api_host,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const defaults = {
    debug: true,
    api_host_clear: config.api_host_clear,
    api_host: config.api_host,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
}

const getConfigData = (withCredentials, data, formData) => {
    const configData = {
        headers: { ...defaults.headers },
        withCredentials: !!withCredentials,
    }

    if (data) {
        // GET
        configData.headers['Content-Type'] = 'text/plain'
        configData.params = data
    }
    if (formData) {
        configData.headers['Content-Type'] = 'multipart/form-data'
    }

    const accessToken = window.localStorage.getItem('Account')

    if (accessToken) {
        configData.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`
    }
    return configData
}

const getAccessToken = () => {
    const token = window.localStorage.getItem('Account')
    return token ? JSON.parse(token) : null
}

const refreshToken = async () => {
    const token = getAccessToken()

    if (token) {
        const response = await axiosInstance.post(
            defaults.api_host + '/dashboard/auth/refreshTokens',
            { token, fingerprint: getUserFingerprint() },
            getConfigData(true)
        )
        if (response.data && response.data.data && response.data.data.token) {
            const newToken = response.data.data.token
            window.localStorage.setItem('Account', JSON.stringify(newToken))
            return newToken
        }
    }

    return null
}

axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = getAccessToken()
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
            // Set the Authorization header on the axios instance instead
        }
        return config
    },
    (error) => Promise.reject(error)
)

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
    failedQueue.forEach((promise) => {
        if (error) {
            promise.reject(error)
        } else {
            promise.resolve(token)
        }
    })

    failedQueue = []
}

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject })
                })
                    .then((token) => {
                        originalRequest.headers.Authorization = `Bearer ${token}`
                        return axios(originalRequest)
                    })
                    .catch((error_) => {
                        return Promise.reject(error_)
                    })
            }
            originalRequest._retry = true
            isRefreshing = true

            return refreshToken()
                .then((newAccessToken) => {
                    if (newAccessToken) {
                        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
                        processQueue(null, newAccessToken)
                        store.dispatch({
                            type: ACCOUNT_LOGIN_SUCCESS,
                            payload: newAccessToken,
                        })
                        return axios(originalRequest)
                    }
                    handler.authError()
                    return Promise.reject(error)
                })
                .catch((error_) => {
                    console.log(error_)
                    handler.authError()
                    processQueue(error_, null)
                    return Promise.reject(error_)
                })
                .finally(() => {
                    isRefreshing = false
                })
        }

        return Promise.reject(error)
    }
)

const getOnThen = (response) => {
    if (!response.data.status && response.data.error) {
        handler.errorHandler(response)
        return {
            success: false,
        }
    } else {
        return response.data
    }
}

const getOnCatch = (err, rethrow) => {
    console.log(err)
    handler.errorHandler(err)
    if (rethrow) {
        throw err
    }
    return err.response && err.response.data
        ? {
              errorData: err.response.data,
              success: false,
          }
        : {
              success: false,
          }
}

class AuthService {
    // eslint-disable-next-line no-useless-constructor
    constructor() {}

    /**
     * @template [Res=any]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    static get(path, data, withCredentials = false) {
        return axiosInstance
            .get(defaults.api_host + path, getConfigData(withCredentials, data))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    /**
     * @template [Res=any]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} data
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    static post(path, data, withCredentials = false) {
        return axiosInstance
            .post(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    /**
     * @template [Res=any]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    static put(path, data, withCredentials = false) {
        return axiosInstance
            .put(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    static delete(path, data) {
        return axiosInstance
            .delete(defaults.api_host + path, { headers: defaults.headers, data })
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    /**
     * @template [Res=any]
     * @param {string} path
     * @param {FormData} data
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    static formData(path, data, withCredentials = false) {
        return axiosInstance
            .post(defaults.api_host + path, data, getConfigData(withCredentials, null, true))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    static postGlobal(path, data, withCredentials = false) {
        return axiosInstance
            .post(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }
}

export default AuthService

export const HttpService = {
    /**
     * @template [Res=undefined]
     * @template [Req]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res>>}
     */
    get(path, data, withCredentials = false) {
        return axiosInstance
            .get(defaults.api_host + path, getConfigData(withCredentials, data))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error, true))
    },

    /**
     * @template [Res=undefined]
     * @template [Req]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    safeGet(path, data, withCredentials = false) {
        return axiosInstance
            .get(defaults.api_host + path, getConfigData(withCredentials, data))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    },

    /**
     * @template [Res=undefined]
     * @param {string} path
     * @param {FormData} data
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res>>}
     */
    formData(path, data, withCredentials = false) {
        return axiosInstance
            .post(defaults.api_host + path, data, getConfigData(withCredentials, null, true))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error, true))
    },
    /**
     * @template [Res=undefined]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} data
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res>>}
     */
    post(path, data, withCredentials = false) {
        return axiosInstance
            .post(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error, true))
    },
    /**
     * @template [Res=undefined]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res>>}
     */
    put(path, data, withCredentials = false) {
        return axiosInstance
            .put(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error, true))
    },
}
