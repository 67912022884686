import { call, put, select } from 'redux-saga/effects'
import API from '../../../api'

import {
    GET_SAFEGUARDING_DATA,
    GET_SAFEGUARDING_DATA_SUCCESS,
    KEYWORD_DETECTION_SET_SUCCESS,
    SHOW_SAVE_BUTTON,
    DISABLE_BUTTON,
    CHANGE_PROSPECT_MESSAGING_ACTIVITY_SUCCESS,
} from '../../actions/settings/safeguardingActions'

import { CLOSE_ACTION_MODAL, OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* getSafeguardingData(action) {
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.settings.getSafeguardingData()
                : API.settings.getSafeguardingData(universityId)
        })

        if (response.success) {
            yield put({
                type: GET_SAFEGUARDING_DATA_SUCCESS,
                payload: response.data,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* keywordDetectionSet(action) {
    const { payload } = action
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.settings.keywordSwitch()
                : API.settings.keywordSwitch(universityId)
        })
        if (response.success) {
            yield put({
                type: KEYWORD_DETECTION_SET_SUCCESS,
                payload: {
                    keywords_censore: payload.keywords_censore,
                },
            })
        }
    } catch {}
}

export function* setDefaultBadwords(action) {
    const { payload } = action
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.settings.setDefaultBadwords(payload)
                : API.settings.setDefaultBadwords(universityId, payload)
        })

        if (response.success) {
            yield put({
                type: SHOW_SAVE_BUTTON,
                payload: false,
            })

            yield put({
                type: GET_SAFEGUARDING_DATA,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* addCustomBadword(action) {
    const { payload } = action
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.settings.addCustomBadword(payload)
                : API.settings.addCustomBadword(universityId, payload)
        })

        if (response.success) {
            yield put({
                type: GET_SAFEGUARDING_DATA,
            })
            yield put({
                type: DISABLE_BUTTON,
                payload: false,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* deleteCustomBadword(action) {
    const { payload } = action
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.settings.deleteCustomBadword(payload)
                : API.settings.deleteCustomBadword(universityId, payload)
        })

        if (response.success) {
            yield put({
                type: GET_SAFEGUARDING_DATA,
            })
            yield put({
                type: CLOSE_ACTION_MODAL,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* addWhitlistedBadword(action) {
    const { payload } = action
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.settings.addWhitlistedBadword(payload)
                : API.settings.addWhitlistedBadword(universityId, payload)
        })

        if (response.success) {
            yield put({
                type: GET_SAFEGUARDING_DATA,
            })
            yield put({
                type: DISABLE_BUTTON,
                payload: false,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* deleteWhitlistedBadword(action) {
    const { payload } = action
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.settings.deleteWhitlistedBadword(payload)
                : API.settings.deleteWhitlistedBadword(universityId, payload)
        })

        if (response.success) {
            yield put({
                type: GET_SAFEGUARDING_DATA,
            })
            yield put({
                type: CLOSE_ACTION_MODAL,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* uploadBadwordCsv(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
        if (isGroupAdmin && currentGroupUniversityId) {
            payload.append('universityId', currentGroupUniversityId)
        }

        const response = yield call(() => {
            return API.settings.uploadBadwordCsv(payload)
        })

        if (response.success) {
            yield put({
                type: GET_SAFEGUARDING_DATA,
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'File has been successfully uploaded',
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* changeProspectsMessagingActivity(action) {
    const payload = action.payload
    const { settings, isActivated } = payload
    let universityId
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
        if (isGroupAdmin && currentGroupUniversityId) {
            universityId = currentGroupUniversityId
        }

        const response = yield call(() => {
            return API.settings.changeProspectsMessagingActivity({ ...settings, isActivated, universityId })
        })

        if (response.success) {
            yield put({
                type: CHANGE_PROSPECT_MESSAGING_ACTIVITY_SUCCESS,
                payload: {
                    isActivated: isActivated,
                    settings: settings,
                },
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Configuration saved',
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}
