import React from 'react'

import { prepareAvatars, prepareAmbassadorsCount, allSelectedCheck } from '../../../../helpers/avatars'

const AmbassadorsAvatars = ({ data, selected, unselected, selectedCount, ambassadorsTotal, isAllSelected }) => {
    const renderAvatars = () => {
        return prepareAvatars(selected, unselected, data, isAllSelected)
    }

    const countSelected = () => {
        return prepareAmbassadorsCount(ambassadorsTotal, selectedCount, selected, unselected, data, isAllSelected)
    }

    const renderAllSelectedComponent = () => {
        return allSelectedCheck(ambassadorsTotal, selectedCount)
    }

    const avatars = renderAvatars()
    const allSelected = renderAllSelectedComponent()
    const counter = countSelected()

    return (
        <div className="avatars-wrapper">
            {allSelected}
            <div className="avatars">
                {avatars}
                <div className="counter">{counter}</div>
            </div>
        </div>
    )
}

export default AmbassadorsAvatars
