import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import DetachedAmbassadorMessage from '../../../../../components/messages/DetachedAmbassadorMessage'
import InviteAdminMessage from '../../../../../components/messages/InviteAdminMessage'
import JoinedAmbassadorMessage from '../../../../../components/messages/JoinedAmbassadorMessage'
import {
    CHAT_MESSAGES_INFO,
    DELETE_CHAT_MESSAGE,
    UNWORD_DIALOG,
} from '../../../../../redux/actions/chat/messagingActions'
import {
    CLOSE_ACTION_MODAL,
    OPEN_ACTION_MODAL,
    REPLY_MESSAGE,
} from '../../../../../redux/actions/components/componentsActions'
import store from '../../../../../redux/store'
import Loader from '../../../../content/components/Loader'
import JoinedAdminMessage from '../messages/JoinedAdminMessage'
import TextMessage from '../messages/TextMessage'

import { ChatType } from '../../../../../common/constants'

const scrollActions = {
    block: false,
}

class MessagesBody extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}

        this.onBodyScroll = this.onBodyScroll.bind(this)
        this.unwordDialog = this.unwordDialog.bind(this)
        this.reply = this.reply.bind(this)
        this.closeConfirmationPopup = this.closeConfirmationPopup.bind(this)
        this.renderDeleteMessagePopup = this.renderDeleteMessagePopup.bind(this)
        this.deleteChatMessage = this.deleteChatMessage.bind(this)
    }

    componentDidMount() {
        const element = document.querySelector('#chat-body')
        if (element) {
            element.addEventListener('scroll', this.onBodyScroll)
        }
    }

    componentDidUpdate(prevProps) {
        const { dialogMessages } = this.props
        const element = document.querySelector('#chat-body')
        if (element && dialogMessages.scroll && element.scrollTop !== element.scrollHeight) {
            if (element && !dialogMessages.isBlockedScroll) {
                element.scrollTop = element.scrollHeight
            } else if (
                element &&
                dialogMessages.previousScrollHeight &&
                element.scrollHeight > dialogMessages.previousScrollHeight
            ) {
                element.scrollTop = element.scrollHeight - dialogMessages.previousScrollHeight
            }
        }
    }

    onBodyScroll(event) {
        const { dialogMessages, activeChat } = this.props
        const element = document.querySelector('#chat-body')
        if (
            (!scrollActions.block || dialogMessages.oldLoaded) &&
            element &&
            element.scrollTop < 50 &&
            !dialogMessages.isLoading &&
            dialogMessages.items &&
            dialogMessages.items.length > 0 &&
            dialogMessages.items.length < dialogMessages.total
        ) {
            scrollActions.block = true
            store.dispatch({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: activeChat,
                    type_chat: ChatType.GROUP_CHAT,
                    blockReloadChat: true,
                    isBlockedScroll: true,
                    loaderDisabled: true,
                    onScroll: true,
                },
                onFinish: () => {
                    scrollActions.block = false
                },
            })
        }
    }

    unwordDialog(messageId) {
        store.dispatch({
            type: UNWORD_DIALOG,
            payload: {
                messageId,
                dialogId: this.props.activeChat,
                type_chat: ChatType.GROUP_CHAT,
            },
        })
    }

    reply(message) {
        store.dispatch({
            type: REPLY_MESSAGE,
            payload: {
                activeMessage: message,
            },
        })
        try {
            document.querySelector('#chat-message-textarea').focus()
        } catch {}
    }

    renderDeleteMessagePopup(message) {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Delete this message?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button
                                type="submit"
                                className="el-btn confirm-delete-button"
                                onClick={() => this.deleteChatMessage(message)}
                            >
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={() => this.closeConfirmationPopup()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    deleteChatMessage(chatMessage) {
        store.dispatch({
            type: DELETE_CHAT_MESSAGE,
            payload: {
                dialogId: chatMessage.dialog_id,
                messageId: chatMessage.id,
                type_chat: ChatType.GROUP_CHAT,
            },
        })
    }

    closeConfirmationPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    render() {
        const { chat, dialogMessages, search } = this.props

        if (dialogMessages.isLoading) {
            return (
                <div className="chat-block chat-loader-block">
                    <Loader />
                </div>
            )
        }

        let searchBy = null
        if ((chat.search_message || chat.search_member) && search) {
            searchBy = chat.search_message ? 'message' : 'user'
        }

        const messagesView = dialogMessages.items.map((message, index) => {
            if (
                (message.type === 'text' || message.type === 'autotext') &&
                message.content &&
                typeof message.content.text !== 'object'
            ) {
                return (
                    <TextMessage
                        key={message.id + '' + message.hide_keywords}
                        index={index}
                        message={message}
                        unwordDialog={this.unwordDialog}
                        reply={this.reply}
                        renderDeleteMessagePopup={this.renderDeleteMessagePopup}
                        search={search}
                        searchBy={searchBy}
                    />
                )
            } else
                switch (message.type) {
                    case 'joined':
                        return <JoinedAmbassadorMessage key={message.id} message={message} />
                    case 'invite':
                        return <InviteAdminMessage key={message.id} message={message} />
                    case 'joined-admin':
                        return <JoinedAdminMessage key={message.id} message={message} />
                    case 'detached':
                        return <DetachedAmbassadorMessage key={message.id} message={message} />
                    // No default
                }
        })
        return <>{messagesView}</>
    }
}

export default connect((state) => {
    return {
        dialogMessages: state.chatMessages,
        account: state.account,
    }
})(MessagesBody)
