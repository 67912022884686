/* eslint-disable camelcase */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as Logo } from '../../../assets/img/svg/close.svg'
import Sprite from '../../../assets/img/symbol/sprite.svg'
import { CREATE_GLOBAL_TAG, GET_GLOBAL_LIST_TAGS } from '../../redux/actions/tags/tagsActions'
import store from '../../redux/store'

class TagsAddPopup extends Component {
    constructor(props) {
        super(props)
        let id = 0
        let messageId = null
        let type = null
        let editTags = 'EDIT_POST_GLOBAL_TAG'
        let createTag = 'CREATE_GLOBAL_POST_TAG'
        let removeTag = 'REMOVE_GLOBAL_POST_TAG'

        if (this.props.postId) {
            id = this.props.postId
            type = 'post'
        } else if (this.props.userId) {
            id = this.props.userId
            type = 'user'
            editTags = this.props.isReporting ? 'EDIT_AMBASSADORS_GLOBAL_TAG_FINISH' : 'EDIT_USER_GLOBAL_TAG'
            createTag = 'CREATE_GLOBAL_USER_TAG'
            removeTag = 'REMOVE_GLOBAL_USER_TAG'
        } else if (this.props.faqId) {
            id = this.props.faqId
            type = 'faq'
            editTags = 'EDIT_FAQ_GLOBAL_TAG'
            createTag = 'CREATE_GLOBAL_FAQ_TAG'
            removeTag = 'REMOVE_GLOBAL_FAQ_TAG'
        }
        if (this.props.messageId) {
            messageId = this.props.messageId
            editTags = 'EDIT_MESSAGE_POST_GLOBAL_TAG'
        }
        this.state = {
            id: id,
            messageId: messageId,
            type: type,
            editLink: editTags,
            createLink: createTag,
            removeLink: removeTag,
            offset: 0,
            inputData: '',
            showDrop: false,
            activeTag: '',
        }

        this.searchTags = this.searchTags.bind(this)
        this.handleDeleteTag = this.handleDeleteTag.bind(this)
    }

    componentDidMount() {
        const { globalTags, currentList } = this.props
        let currentListIds = ''
        if (currentList) {
            for (const elem of currentList) {
                currentListIds = currentListIds.length === 0 ? '' + elem.id : currentListIds + ',' + elem.id
            }
        }
        store.dispatch({
            type: GET_GLOBAL_LIST_TAGS,
            payload: {
                limit: globalTags.limit,
                offset: 0,
                excludeIds: currentListIds,
            },
        })
    }

    handleScroll = (obj) => {
        const { globalTags, currentList } = this.props
        const element = document.querySelector('#globalTagsElement')
        if (
            element.scrollHeight - element.scrollTop === element.clientHeight &&
            globalTags.total > globalTags.offset + globalTags.limit
        ) {
            let currentListIds = ''
            if (currentList) {
                for (const elem of currentList) {
                    currentListIds = currentListIds.length === 0 ? '' + elem.id : currentListIds + ',' + elem.id
                }
            }
            store.dispatch({
                type: GET_GLOBAL_LIST_TAGS,
                payload: {
                    limit: globalTags.limit,
                    offset: globalTags.offset + globalTags.limit,
                    searchName: this.state.searchTerm,
                    excludeIds: currentListIds,
                },
            })
        }
    }

    searchTags(e) {
        const { globalTags, currentList } = this.props
        if (e.target.value.length <= 200) {
            this.setState({
                showDrop: true,
                inputData: e.target.value,
                activeTag: e.target.value,
            })

            let currentListIds = ''
            if (currentList) {
                for (const elem of currentList) {
                    currentListIds = currentListIds.length === 0 ? '' + elem.id : currentListIds + ',' + elem.id
                }
            }
            store.dispatch({
                type: GET_GLOBAL_LIST_TAGS,
                payload: {
                    limit: globalTags.limit,
                    offset: 0,
                    searchName: e.target.value,
                    excludeIds: currentListIds,
                },
            })
        }
    }

    addTag = (id) => {
        const { globalTags } = this.props
        const afterSuccess = (currentGlobalTags) => {
            const id = this.state.messageId ? this.state.messageId : this.state.id
            const type = this.state.messageId ? 'message' : this.state.type
            store.dispatch({
                type: this.state.editLink,
                payload: {
                    [type + '_id']: id,
                    globalTags: currentGlobalTags,
                },
            })

            let currentListIds = ''
            for (const elem of currentGlobalTags) {
                currentListIds = currentListIds.length === 0 ? '' + elem.id : currentListIds + ',' + elem.id
            }
            store.dispatch({
                type: GET_GLOBAL_LIST_TAGS,
                payload: {
                    limit: globalTags.limit,
                    offset: 0,
                    excludeIds: currentListIds,
                },
            })
            this.setState({
                inputData: '',
                activeTag: '',
                //  showDrop: false
            })
        }
        store.dispatch({
            type: this.state.createLink,
            payload: {
                [this.state.type + 'Id']: this.state.id,
                globalTagsIds: [id],
                afterSuccess: afterSuccess,
            },
        })
    }

    addNewTag = () => {
        const newTag = this.state.inputData.trim()

        const afterSuccess = (tag) => {
            this.addTag(tag.id)
        }

        let tagFound = false
        const { globalTags, currentList } = this.props
        if (globalTags && globalTags.items) {
            globalTags.items.map((tag) => {
                if (tag.name === newTag) {
                    afterSuccess(tag)
                    tagFound = true
                }
            })
        }
        if (currentList) {
            currentList.map((tag) => {
                if (tag.name === newTag) {
                    afterSuccess(tag)
                    tagFound = true
                }
            })
        }
        if (!tagFound) {
            store.dispatch({
                type: CREATE_GLOBAL_TAG,
                payload: {
                    name: newTag,
                    afterSuccess: afterSuccess,
                },
            })
        }
    }

    handleDeleteTag(tag) {
        const afterSuccess = (currentGlobalTags) => {
            const id = this.state.messageId ? this.state.messageId : this.state.id
            const type = this.state.messageId ? 'message' : this.state.type
            store.dispatch({
                type: this.state.editLink,
                payload: {
                    [type + '_id']: id,
                    globalTags: currentGlobalTags,
                },
            })
        }
        store.dispatch({
            type: this.state.removeLink,
            payload: {
                [this.state.type + 'Id']: this.state.id,
                globalTagsIds: [tag.id],
                afterSuccess: afterSuccess,
            },
        })
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter' && this.state.inputData.trim().length > 1) {
            e.preventDefault()
            this.addNewTag()
            e.stopPropagation()
        } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            let activeTag = ''
            const { globalTags, currentList } = this.props

            const currentListIds = []
            if (currentList) {
                for (const elem of currentList) {
                    currentListIds.push(elem.id)
                }
            }
            if (globalTags && globalTags.items) {
                activeTag = globalTags.items[0] && globalTags.items[0].name ? globalTags.items[0].name : ''
                for (const index in globalTags.items) {
                    if (globalTags.items[index].name === this.state.activeTag || this.state.activeTag === '') {
                        if (e.key === 'ArrowUp') {
                            activeTag =
                                globalTags.items[index - 1] && this.state.activeTag !== ''
                                    ? globalTags.items[index - 1].name
                                    : globalTags.items[index].name
                        } else {
                            activeTag =
                                globalTags.items[+index + 1] && this.state.activeTag !== ''
                                    ? globalTags.items[+index + 1].name
                                    : globalTags.items[index].name
                        }
                        break
                    }
                }
            }
            this.setState({
                activeTag: activeTag,
                inputData: activeTag,
            })

            try {
                let activeElement = null
                const globalTagsElem = document.querySelector('#globalTagsElement')
                if (globalTagsElem) {
                    activeElement = globalTagsElem.querySelectorAll('.active')[0]
                }
                if (activeElement) {
                    const container = activeElement.parentNode
                    let offset = activeElement.offsetTop // get element's y position in list
                    offset -= container.scrollTop // subtract list's scroll position to get relative position

                    const delta_to_bottom = offset + activeElement.clientHeight - container.clientHeight // calculate distance from active element's bottom to the list's bottom
                    if (delta_to_bottom > 0) {
                        // if distance is greater than 0, it must be out of view
                        container.scrollTop += e.key !== 'ArrowUp' ? delta_to_bottom + 25 : delta_to_bottom // add delta to view
                    }

                    // same goes for top
                    const delta_to_top = offset + activeElement.clientHeight - container.clientHeight
                    if (delta_to_top < 0) {
                        container.scrollTop += e.key === 'ArrowUp' ? delta_to_top - 25 : delta_to_top
                    }
                }
            } catch {}
        }
    }

    resetActive = (tag) => {
        this.setState({
            activeTag: tag.name,
        })
    }

    render() {
        const { inputData, activeTag } = this.state
        const { currentList, globalTags } = this.props

        const currentListNames = []
        if (currentList) {
            currentList.map((item) => {
                currentListNames.push(item.name)
            })
        }

        return (
            <div className="modal-global-tags">
                <button type="button" className="close-modal closer-button" onClick={this.props.handleCloseTagsPopup}>
                    <svg className="icon icon-close">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#close'}></use>
                    </svg>
                </button>
                <div className="global-tags-block">
                    <div className="search-term-wrapper tags-term-wrapper w-90">
                        {currentList &&
                            currentList.map((tag) => {
                                return (
                                    <span key={tag.id} className="search-term">
                                        {tag.name}
                                        <Logo
                                            onClick={() => this.handleDeleteTag(tag)}
                                            className="search-term__close-icon"
                                        />
                                    </span>
                                )
                            })}
                    </div>
                </div>
                <div className="add-tags-block position-relative">
                    <div className="input-block w-90">
                        <div className="search" onClick={this.searchTags}></div>
                        <input
                            className="add-tag-input"
                            type="text"
                            placeholder="Add tags"
                            autoComplete="off"
                            value={activeTag}
                            onChange={this.searchTags}
                            onKeyDown={this.onKeyDown}
                        />
                    </div>
                    <div className="dropdown-tags-block w-90">
                        {(globalTags && globalTags.items && globalTags.items.length > 0) ||
                        (currentList &&
                            currentList.length > 0 &&
                            inputData.length > 0 &&
                            currentListNames.includes(inputData)) ? (
                            globalTags.items.length > 0 ? (
                                <div className="dropdown-tags" onScroll={this.handleScroll}>
                                    <div id="globalTagsElement" className="dropdown-tags-menu">
                                        {globalTags.items.map((tag, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={
                                                        activeTag === tag.name
                                                            ? 'dropdown-tags-item active'
                                                            : 'dropdown-tags-item'
                                                    }
                                                    onClick={() => this.addTag(tag.id)}
                                                    onMouseEnter={(e) => this.resetActive(tag)}
                                                >
                                                    {tag.name}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            ) : (
                                <div className="no-tags-found">
                                    <span className="text">
                                        <p>{inputData.length > 0 ? 'Tag' : 'Tags'} successfully added.</p> Type and hit
                                        enter to create and add a new tag
                                    </span>
                                </div>
                            )
                        ) : (
                            <div className="no-tags-found">
                                <span className="text">
                                    <p>Oops no tags found!</p> Type and hit enter to create a new tag
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        globalTags: state.globalTags,
    }
})(TagsAddPopup)
