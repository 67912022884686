/* eslint-disable unicorn/prefer-spread */
import {
    USERS_INFO_SUCCESS,
    EDIT_USER_GLOBAL_TAG_FINISH,
    POPCARD_USERS_SUCCESS,
    USERS_INFO_REQUESTED,
    USER_TOGGLE_BAN_SUCCESS,
    USER_CHANGE_INTRODUCTION_SUCCESS,
    USER_CHANGE_DESCRIPTION_SUCCESS,
    DISABLE_TOGGLE,
    USER_PARTNER_TOGGLE_BAN_SUCCESS,
    USER_PARTNER_TOGGLE_UNI_BAN_SUCCESS,
    MAX_USERS_LIMIT_POPUP_TOGGLE,
    USER_PARTNER_TOGGLE_BAN_FAILED,
    USER_CHANGE_SUBJECT_SUCCESS,
    USER_CHANGE_COUNTRY_SUCCESS,
    CHANGE_AMBASSADOR_TYPE,
    CHANGE_AMBASSADOR_STUDENT_TYPE,
    CHANGE_YEAR_OF_STUDY,
    CHANGE_AMBASSADOR_SUBJECT,
    CHANGE_AMBASSADOR_COURSE_TYPE,
    CHANGE_AMBASSADOR_STAFF_TYPE,
    CHANGE_AMBASSADOR_JOB_TITLE,
    CHANGE_AMBASSADOR_INDUSTRY,
    CHANGE_AMBASSADOR_JOB_ROLE,
    CHANGE_AMBASSADOR_JOB_LOCATION,
    CHANGE_AMBASSADOR_WORKING_SINCE,
    CHANGE_AMBASSADOR_CHILDREN,
    GET_STAFF_TYPES_SUCCESS,
    GET_STUDENT_TYPES_SUCCESS,
    GET_SUBJECTS_SUCCESS,
    GET_YEAR_OF_STUDIES_SUCCESS,
    GET_COURSE_TYPES_SUCCESS,
    GET_INDUSTRIES_SUCCESS,
    GET_SA_PROFILE_TYPES_SUCCESS,
    CHANGE_AMBASSADOR_INTERESTS_SUCCESS,
    DELETE_AMBASSADOR_SUCCESS,
    GET_USER_TABLE_TOTALS_SUCCESS,
    USER_INFO_OPEN_MODAL,
    USER_INFO_CLOSE_MODAL,
} from '../../actions/content/usersActions'
import { USERS } from '../../store/initialState'

const initialState = USERS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case USERS_INFO_REQUESTED: {
            return {
                ...state,
                searchItems: payload.filter && payload.filter.searchItems ? payload.filter.searchItems : [],
            }
        }

        case DISABLE_TOGGLE: {
            return {
                ...state,
                disableToggle: payload,
            }
        }

        case USERS_INFO_SUCCESS: {
            payload.items.map((item, i) => {
                if (!item.permissions) payload.items[i].permissions = {}
                if (!item.permissions.chat) payload.items[i].permissions.chat = { id: 1, is_enabled: false }
                if (!item.permissions.content) payload.items[i].permissions.content = { id: 2, is_enabled: false }
                if (!item.permissions.faq) payload.items[i].permissions.faq = { id: 3, is_enabled: false }
                payload.items[i].isModalOpen = false
            })

            return Object.assign({}, state, {
                hasMore: state.total > payload.offset,
                // eslint-disable-next-line unicorn/prefer-spread
                items: !state.offset ? payload.items : state.items.concat(payload.items),
                offset: state.offset > payload.total ? payload.total : state.offset,
                partner_groups: payload.partner_groups || [],
                total: payload.total,
                usersMaxLimitReached: false,
            })
        }

        case USER_TOGGLE_BAN_SUCCESS: {
            const items = [...state.items]
            items.map((item, i) => {
                if (item.id === payload.user_id) {
                    items[i].permissions[payload.type] = { id: payload.permission_id, is_enabled: payload.is_enabled }
                }
            })

            return {
                ...state,
                items: items,
            }
        }

        case USER_PARTNER_TOGGLE_BAN_SUCCESS: {
            const items = [...state.items]
            items.map((item, i) => {
                if (item.id === payload.user_id) {
                    let permFound = false
                    for (const perm in items[i].permissions.partner_groups) {
                        if (
                            items[i].permissions.partner_groups[perm].university_group_id ===
                            payload.university_group_id
                        ) {
                            items[i].permissions.partner_groups[perm] = {
                                university_group_id: payload.university_group_id,
                                is_enabled: payload.is_enabled,
                            }
                            permFound = true
                        }
                    }
                    if (!permFound) {
                        items[i].permissions.partner_groups.push({
                            university_group_id: payload.university_group_id,
                            is_enabled: payload.is_enabled,
                        })
                    }
                }
            })

            return {
                ...state,
                items: items,
            }
        }

        case USER_PARTNER_TOGGLE_UNI_BAN_SUCCESS: {
            const items = [...state.items]
            items.map((item, i) => {
                if (item.id === payload.user_id) {
                    items[i].permissions.university_tap_page = { is_enabled: payload.is_enabled }
                }
            })

            return {
                ...state,
                items: items,
            }
        }

        case USER_CHANGE_INTRODUCTION_SUCCESS: {
            const { user_id: userId, introduction } = payload
            const updatedUsers = state.items.map((user) => (user.id === userId ? { ...user, introduction } : user))

            return {
                ...state,
                items: updatedUsers,
            }
        }

        case USER_CHANGE_DESCRIPTION_SUCCESS: {
            const { user_id: userId, description } = payload
            const updatedUsers = state.items.map((user) => (user.id === userId ? { ...user, description } : user))

            return {
                ...state,
                items: updatedUsers,
            }
        }

        case USER_CHANGE_SUBJECT_SUCCESS: {
            const { userId, subject } = payload
            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, subject } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case USER_CHANGE_COUNTRY_SUCCESS: {
            const { userId, region, countries } = payload
            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, region, user_tags: { ...user.user_tags, countries } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_TYPE: {
            const { id, userId, key, name } = payload
            const profile = [
                {
                    id,
                    key,
                    name,
                },
            ]
            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, profile } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_STUDENT_TYPE: {
            const { userId, ambassadorStudentType } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId
                    ? { ...user, user_tags: { ...user.user_tags, student: ambassadorStudentType } }
                    : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_YEAR_OF_STUDY: {
            const { userId, yearOfStudy } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, year_of_study: yearOfStudy } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_SUBJECT: {
            const { userId, subject } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, subject } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_COURSE_TYPE: {
            const { userId, courseType } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, courses_types: courseType } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_STAFF_TYPE: {
            const { userId, staffType } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, staff: staffType } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_INDUSTRY: {
            const { userId, industry } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, industry } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_JOB_TITLE: {
            const { userId, jobTitle } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, job_title: jobTitle } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_JOB_ROLE: {
            const { userId, jobRole } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, job_role: jobRole } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_JOB_LOCATION: {
            const { userId, companyRegion, companyCountryId, companyCountryName } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId
                    ? {
                          ...user,
                          additionalData: {
                              ...user.additionalData,
                              company_country_id: companyCountryId,
                              company_country_name: companyCountryName,
                              company_region: companyRegion,
                          },
                      }
                    : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_WORKING_SINCE: {
            const { userId, workingSince } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId
                    ? { ...user, additionalData: { ...user.additionalData, working_since: workingSince } }
                    : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_CHILDREN: {
            const { userId, children } = payload

            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, children: children } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case CHANGE_AMBASSADOR_INTERESTS_SUCCESS: {
            const { userId, interests } = payload
            const interestsArr = interests.map((item) => {
                return {
                    name: item,
                    key: item,
                }
            })

            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, user_tags: { ...user.user_tags, interests: interestsArr } } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case EDIT_USER_GLOBAL_TAG_FINISH: {
            const { user_id: userId, globalTags } = payload
            return {
                ...state,
                items: state.items.map((item) => (item.id === userId ? { ...item, globalTags } : item)),
            }
        }

        case POPCARD_USERS_SUCCESS: {
            return {
                ...state,
                popcardUsers: payload.items,
                popcardSettings: payload.tapPageSettings,
            }
        }

        case USER_PARTNER_TOGGLE_BAN_FAILED: {
            return {
                ...state,
                disableToggle: false,
            }
        }

        case MAX_USERS_LIMIT_POPUP_TOGGLE: {
            return {
                ...state,
                disableToggle: false,
                usersMaxLimitReached: !state.usersMaxLimitReached,
                usersMaxLimitReachedText: payload?.errorText
                    ? payload.errorText
                    : initialState.usersMaxLimitReachedText,
            }
        }

        case GET_SA_PROFILE_TYPES_SUCCESS: {
            return {
                ...state,
                profileTypes: payload.items,
            }
        }

        case GET_STAFF_TYPES_SUCCESS: {
            return {
                ...state,
                staffTypes: payload.items,
            }
        }
        case GET_STUDENT_TYPES_SUCCESS: {
            return {
                ...state,
                studentTypes: payload.items,
            }
        }
        case GET_SUBJECTS_SUCCESS: {
            return {
                ...state,
                subjects: payload.items,
            }
        }
        case GET_YEAR_OF_STUDIES_SUCCESS: {
            return {
                ...state,
                yearOfStudies: payload.items,
            }
        }
        case GET_COURSE_TYPES_SUCCESS: {
            return {
                ...state,
                courseTypes: payload.items,
            }
        }
        case GET_INDUSTRIES_SUCCESS: {
            return {
                ...state,
                industries: payload.items,
            }
        }
        case DELETE_AMBASSADOR_SUCCESS: {
            const { userId } = payload

            const updatedUsers = state.items.filter((item) => item.id !== userId)
            return {
                ...state,
                items: updatedUsers,
            }
        }
        case GET_USER_TABLE_TOTALS_SUCCESS: {
            return {
                ...state,
                usersTotals: payload,
            }
        }

        case USER_INFO_OPEN_MODAL: {
            const { userId } = payload
            const updatedUsers = state.items.map((user) => (user.id === userId ? { ...user, isModalOpen: true } : user))
            return {
                ...state,
                items: updatedUsers,
            }
        }

        case USER_INFO_CLOSE_MODAL: {
            const { userId } = payload
            const updatedUsers = state.items.map((user) =>
                user.id === userId ? { ...user, isModalOpen: false } : user
            )
            return {
                ...state,
                items: updatedUsers,
            }
        }

        default: {
            return state
        }
    }
}
