import React from 'react'
import { Popover } from 'antd'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import store from '../redux/store'
import { GET_REPORT_REASON, CHAT_MESSAGES_INFO } from '../redux/actions/chat/messagingActions'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

const ReportingPopover = (props) => {
    const { data } = props.chats.reportedData

    const getReportData = () => {
        const { reportedData } = props.chats
        if (
            reportedData.dialogId !== props.dialogId ||
            (reportedData.data && reportedData.data.length > 0) !== props.dialogMessages.totalReportedMessages
        ) {
            store.dispatch({
                type: GET_REPORT_REASON,
                payload: {
                    dialogId: props.dialogId,
                },
            })
        }
    }

    return (
        <>
            <Popover
                placement="bottomRight"
                overlayStyle={{ width: 320 }}
                trigger="hover"
                className="options-icon-drop"
                onMouseEnter={getReportData}
                content={
                    <div className={`chat-blocked-content ${data && data.length > 3 ? 'overflow' : ''}`}>
                        <PopupContent {...props} />
                    </div>
                }
            >
                <div className="chat-blocked-button">
                    + {props.dialogMessages.totalReportedMessages} reported messages
                </div>
            </Popover>
        </>
    )
}

const PopupContent = (props) => {
    const { data } = props.chats.reportedData
    if (!data) {
        return <div className="popover-content-block report">No Data</div>
    }
    const getMessage = (messageId) => {
        const messageElement = document.querySelector("[id='" + messageId + "']")
        if (messageElement) {
            const child = messageElement.firstElementChild || messageElement.firstChild
            messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
            child.classList.add('highlight-repoted-message')
            setTimeout(() => child.classList.remove('highlight-repoted-message'), 2000)
        } else {
            store.dispatch({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: props.dialogId,
                    type_chat: props.chatType,
                    blockReloadChat: true,
                    isBlockedScroll: true,
                    loaderDisabled: true,
                    onScroll: true,
                },
                onFinish: () => {
                    const messageElement = document.querySelector("[id='" + messageId + "']")
                    if (messageElement) {
                        const child = messageElement.firstElementChild || messageElement.firstChild
                        messageElement.scrollIntoView()
                        child.classList.add('highlight-repoted-message')
                        setTimeout(() => child.classList.remove('highlight-repoted-message'), 2000)
                    }
                },
            })
        }
    }

    return data.map((message) => {
        let role = null
        const userAvatar =
            message.user && message.user.avatar ? (
                <img
                    className="content-group-check-img"
                    src={message.user.avatar.sizes['70x70'] || message.user.avatar.original}
                />
            ) : (
                <span className="content-group-check-img content-group-check-img-name report">
                    {message.user && message.user.name ? message.user.name[0] : 'D'}
                </span>
            )

        if (message.reporter) {
            // eslint-disable-next-line no-prototype-builtins
            if (message.reporter.user_tags.hasOwnProperty('profile')) {
                const [firstLetter, ...rest] = [...message.reporter.user_tags.profile[0].name]
                role = `(${[firstLetter.toUpperCase(), ...rest].join('')} ambassador)`
            } else if (message.reporter.roles.length > 0 && message.reporter.roles[0].key === 'prospect') {
                role = `(${message.reporter.roles[0].name})`
            }
        }
        return (
            <div className="popover-content-block report" key={message.id}>
                <p className="popover-text">
                    Reported{' '}
                    <span className="blocker-name">
                        by {message.reporter ? message.reporter.name : 'Deleted user'} {role}
                    </span>
                    :
                </p>
                <div className="reported-user-container">
                    <div className="content-group-check-img-block">{userAvatar}</div>
                    <div>
                        <p>{message.user && message.user.name ? message.user.name : 'Deleted user'}</p>
                        <p>
                            {message.user && message.user.roles && message.user.roles[0]
                                ? message.user.roles[0].name
                                : ''}
                        </p>
                    </div>
                    <button className="preview-button" onClick={() => getMessage(message.message_id)}>
                        Preview
                    </button>
                </div>
                <p className="reported-comment">{message.comment}</p>
                <p className="popover-text created-at">
                    {dayjs(message.created_at).calendar(null, {
                        sameDay: 'H:mm',
                        nextDay: '[Tomorrow]',
                        nextWeek: '[Next] dddd',
                        lastDay: '[Yesterday at ]H:mm',
                        lastWeek: 'DD MMMM',
                        sameElse: 'DD MMMM',
                    })}
                </p>
            </div>
        )
    })
}

export default connect((state) => {
    return {
        chats: state.chats,
        account: state.account,
        dialogMessages: state.chatMessages,
    }
})(ReportingPopover)
