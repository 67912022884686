import { ChatType } from '../../../../../../common/constants'

export const TAB_KEY = {
    PARTICIPANTS: 'participants',
    CHAT: 'chat',
    SPEAKERS_CHAT: 'speakers',
}

export const TAB_KEY_TO_CHAT_TYPE = {
    [TAB_KEY.CHAT]: ChatType.LIVE_EVENT_CHAT,
    [TAB_KEY.SPEAKERS_CHAT]: ChatType.LIVE_EVENT_SPEAKERS_CHAT,
}
