import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import store from '../../../redux/store'

import KeywordsControl from './components/KeywordsControl'
import UploadKeywordsButton from './components/UploadKeywordsButton'
import DataControl from './components/DataControl'
import MessagingActivity from './components/MessagingActivity'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { GET_SAFEGUARDING_DATA, KEYWORD_DETECTION_SET } from '../../../redux/actions/settings/safeguardingActions'
import { Redirect } from 'react-router'
import { getCommonRoutePath } from '../../../helpers/helpers'

class Safeguarding extends PureComponent {
    constructor() {
        super()
        this.state = {}

        this.handleSwitchToggle = this.handleSwitchToggle.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })

        store.dispatch({
            type: GET_SAFEGUARDING_DATA,
        })
    }

    handleSwitchToggle() {
        store.dispatch({
            type: KEYWORD_DETECTION_SET,
            payload: {
                keywords_censore: !this.props.safeguarding.keywordsCensore,
            },
        })
    }

    render() {
        const { account, safeguarding } = this.props
        const { customBadwords, defaultBadwords, keywordsCensore, whitelistedBadwords, showSaveBtn, disabledBtn } =
            safeguarding
        if (account.account_info.isGroupAdmin && !account.currentGroupUniversityId) {
            return <Redirect to={getCommonRoutePath() + '/home'} />
        }

        let keywordCheckbox = false
        if (account && account.account_info && account.account_info.university && keywordsCensore) {
            keywordCheckbox = keywordsCensore
        }
        return (
            <div className="safeguard-page">
                <section className="content settings">
                    <h3 className="settings-title">Safeguarding configuration</h3>

                    <div className="settings-grid has-tutorial">
                        <div className="column">
                            <div className="settings-intro">
                                <p>
                                    Keeping people safe online is extremely important. The Ambassador Platform has many
                                    layers of safeguarding which we'd encourage you to read more about in our help
                                    <a
                                        href="https://knowledge.theambassadorplatform.com/how-to-manage-safeguarding-on-the-ambassador-platform"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {' '}
                                        article
                                    </a>
                                    . Please select your keyword detection preferences below.
                                </p>
                            </div>
                            <hr className="settings-hr"></hr>
                            <div className="text-bottom">
                                <div className="safeguarding-explainer">
                                    <div className="toggle-wrapper">
                                        <p>Keyword blocking</p>
                                        <div className="slider-button">
                                            <label className="slideOne js-holder">
                                                <input
                                                    type="checkbox"
                                                    name="wordsCheckbox"
                                                    checked={keywordCheckbox}
                                                    onChange={this.handleSwitchToggle}
                                                />
                                                <span className="slideOne-icon" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="settings-intro">
                                        {keywordCheckbox ? (
                                            <p className="toggle-info">
                                                Keywords blocked out using **** are not visible to chat participants
                                                until you review the conversation. Add custom keywords that you would
                                                like to be detected. Please{' '}
                                                <a
                                                    href="https://knowledge.theambassadorplatform.com/how-to-customize-keyword-blocking-in-conversations"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {' '}
                                                    read our help article
                                                </a>{' '}
                                                for more information.
                                            </p>
                                        ) : (
                                            <p className="toggle-info">Keywords will be visible in all conversations</p>
                                        )}
                                    </div>
                                    <div className="toggle-wrapper">
                                        <UploadKeywordsButton />
                                    </div>
                                    <div className="settings-intro">
                                        <p className="toggle-info">CSV file format, all words listed in 1 column</p>
                                    </div>
                                    <KeywordsControl
                                        showKeyword={keywordCheckbox}
                                        defaultBadwords={defaultBadwords}
                                        customBadwords={customBadwords}
                                        showSaveBtn={showSaveBtn}
                                        disabledBtn={disabledBtn}
                                    />
                                    {!account.account_info.isBuzzOnly && (
                                        <>
                                            <div className="settings-section-title">
                                                <p>Phone numbers, emails and email domains blocking</p>
                                            </div>
                                            <div className="settings-intro">
                                                {keywordCheckbox
                                                    ? "All phone numbers, emails and email domains are blocked by default. If you'd like to create an approved list of items to be shown in conversations, please add them below."
                                                    : "All phone numbers, emails and email domains are blocked by default when you turn on the keyword blocking. If you'd like to create an approved list of items to be shown in conversations, please add them to 'Approved keywords' table."}
                                            </div>
                                            {keywordCheckbox ? (
                                                <DataControl dataList={whitelistedBadwords} disabledBtn={disabledBtn} />
                                            ) : null}
                                            <MessagingActivity />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="column resources">
                            <h3 className="video-title">Video tutorial: Safeguarding Configurations</h3>
                            <div className="video-wrapper">
                                <iframe
                                    src="https://player.vimeo.com/video/429932900"
                                    width="640"
                                    height="373"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen
                                ></iframe>
                            </div>

                            <div className="resources-box">
                                <h3>Helpful resources</h3>
                                <ul>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/how-to-manage-safeguarding-on-the-ambassador-platform"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            How to Manage Safeguarding on The Ambassador Platform
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://legal.theambassadorplatform.com/safeguarding-policy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            The Ambassador Platform's Safeguarding Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/keyword-detection-and-approval-process"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Keyword Detection and Approval Process
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/how-to-customize-keyword-blocking-in-conversations"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            How to customize keyword blocking in conversations
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        safeguarding: state.safeguarding,
    }
})(Safeguarding)
