import { EditOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button } from 'src/app/components/base/Button';
import { textAreaCountFormatter } from 'src/app/components/forms/textarea-count-formatter';
import { useAppDispatch } from 'src/app/hooks/store';
import { changePostCaption } from 'src/app/redux/actions/content/postsActions';
var TextArea = Input.TextArea;
export var Caption = function (_a) {
    var postId = _a.postId, _b = _a.caption, text = _b === void 0 ? '' : _b;
    var dispatch = useAppDispatch();
    var _c = useState(text), caption = _c[0], setCaption = _c[1];
    var _d = useState(false), editCaption = _d[0], setEditCaption = _d[1];
    var handleChangeCaption = function () {
        dispatch(changePostCaption(postId, caption));
        setEditCaption(false);
    };
    useEffect(function () {
        setCaption(text);
    }, [text]);
    return editCaption ? (React.createElement(React.Fragment, null,
        React.createElement(TextArea, { className: "w-full", defaultValue: caption, autoSize: { minRows: 3, maxRows: 5 }, placeholder: "Write a caption...", onChange: function (e) { return setCaption(e.target.value); }, maxLength: 200, showCount: { formatter: textAreaCountFormatter } }),
        React.createElement("div", { className: "flex justify-end gap-4" },
            React.createElement(Button, { type: "default", onClick: function () { return setEditCaption(false); } }, "Cancel"),
            React.createElement(Button, { onClick: handleChangeCaption }, "Save")))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-reading-lg" }, caption || 'This post does not have a caption'),
        React.createElement(Button, { type: "text", onClick: function () { return setEditCaption(true); }, icon: React.createElement(EditOutlined, null), className: "text-brand-orange hover:!text-brand-orange justify-self-end" }, "Edit caption")));
};
