import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import store from '../../redux/store'
import {
    checkCanSeeLiveEvents,
    checkCanSeeSso,
    isMenuItemAllowed,
    checkCanSeeLiveStreams,
    isCampaignManagementAllowed,
} from '../../common/utils/user-account-helpers'
import { getCommonRoutePath } from '../../helpers/helpers'

const ROUTE_TO_PERMISSION = {
    '/reporting/overview': 'reporting_overview',
    '/reporting/activity': 'reporting_activity',
    '/reporting/content': 'reporting_content',
    '/reporting/faq': 'reporting_faq',
    '/reporting/ambassadors': 'reporting_ambassadors',
    '/reporting/prospects': 'reporting_prospects',
    '/reporting/time': 'reporting_time',
    '/reporting/trend': 'reporting_trend',
    '/settings/invite-admins': 'invite_admin',
}

// use it only when you have something to pass to check if page for uni is restricted or no
const IsAllowed = ({ component: Component, ...rest }) => {
    const account = store.getState().account
    let isAllowed = false
    let checkNext = true

    const accountInfo = account.account_info
    const currentGroupUniversityId = account.currentGroupUniversityId
    const { isGroupAdmin, isIdpGroupAdmin } = accountInfo
    const university = accountInfo.university || {}
    const { permissions = [] } = university

    if (rest.testIfUniIsActivated) {
        isAllowed = accountInfo && (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId) || isIdpGroupAdmin)
        checkNext = isAllowed
    }
    if (rest.testIfLiveEventIsActivated && checkNext) {
        isAllowed = accountInfo ? !isGroupAdmin && checkCanSeeLiveEvents(university) : false
        checkNext = isAllowed
    }
    if (rest.testIfLiveStreamsIsActivated && checkNext) {
        isAllowed = accountInfo ? checkCanSeeLiveStreams(university) : false
        checkNext = isAllowed
    }
    if (rest.checkGroupAdminPermissions && checkNext) {
        isAllowed = isMenuItemAllowed(ROUTE_TO_PERMISSION[rest.path], isGroupAdmin, permissions)
        checkNext = isAllowed
    }
    if (rest.isSsoAllowed && checkNext) {
        isAllowed = accountInfo ? checkCanSeeSso(account) : false
        checkNext = isAllowed
    }
    if (rest.testIfCampaignManagementIsActivated && checkNext) {
        isAllowed = accountInfo ? isCampaignManagementAllowed(university) : false
        checkNext = isAllowed
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                return isAllowed ? <Component {...props} /> : <Redirect to={getCommonRoutePath() + '/home'} />
            }}
        />
    )
}

export default IsAllowed
