import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReplyMessageContainer } from 'src/app/components/reply-message-container'
import { SEND_GROUP_CHAT_MESSAGE } from '../../../../../../../../redux/actions/chat/groupMessagingActions'
import { CLOSE_DIALOG } from '../../../../../../../../redux/actions/chat/messagingActions'
import {
    CLOSE_ACTION_MODAL,
    OPEN_ACTION_MODAL,
    REPLY_MESSAGE,
} from '../../../../../../../../redux/actions/components/componentsActions'
import store from '../../../../../../../../redux/store'

import { ChatFooter, MessagesBody, SendMessageForm, NewMessagesPopup } from './components'

const ChatDialogTab = ({ activeEvent, type }) => {
    const dispatch = useDispatch()

    const { dialogId } = activeEvent

    const replyMessage = useSelector((state) => state.replyMessage)

    const handleCloseConfirmPopup = useCallback(() => {
        dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }, [dispatch])

    const handleSendMessage = (val) => {
        if (!val) return

        const onSuccess = () => {
            store.dispatch({
                type: REPLY_MESSAGE,
                payload: {
                    activeMessage: null,
                    activePublisher: null,
                },
            })
        }

        store.dispatch({
            type: SEND_GROUP_CHAT_MESSAGE,
            payload: {
                type: 'text',
                text: val,
                dialog_id: dialogId,
                parentMessageId: replyMessage.activeMessage ? replyMessage.activeMessage.id : null,
                scrollBottom: true,
            },
            onSuccess,
        })
    }

    const closeDialog = () => {
        store.dispatch({
            type: CLOSE_DIALOG,
            payload: {
                dialogId,
                type_chat: type,
            },
        })
    }

    const handleOpenConfirmPopup = () => {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button type="submit" className="el-btn confirm-delete-button" onClick={closeDialog}>
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    return (
        <>
            <div className="chat-body scroll-8" id="chat-body">
                <div className="chat-body-list">
                    <MessagesBody activeChat={dialogId} activeEvent={activeEvent} />
                </div>

                <NewMessagesPopup eventId={dialogId} />
            </div>

            <div id="chat-footer" className="chat-footer prospect-sa-chat">
                <ReplyMessageContainer />
                <SendMessageForm sendMessage={handleSendMessage} />
                <ChatFooter activeEvent={activeEvent} type={type} handleOpenConfirmPopup={handleOpenConfirmPopup} />
            </div>
        </>
    )
}

export default ChatDialogTab
