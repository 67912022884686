import { parse } from 'date-fns'
import { isEmpty } from 'ramda'

const createEmptyFormModel = (accountInfoId) => {
    return {
        name: '',
        image: undefined,
        description: '',
        date: undefined,
        globalTagsIds: [],
        isPromote: false,
        isGroupPromote: false,
        userIds: [],
    }
}

const createEditFormModel = (data) => {
    const date = data?.fromDate?.slice(0, Math.max(0, data.fromDate.lastIndexOf('.')))
    const fromDate = date ? parse(date, "yyyy-MM-dd'T'HH:mm:ss'", new Date()) : null

    return {
        id: data.id,
        name: data.name,
        image: data?.image?.original,
        description: data.description,
        globalTagsIds: data.globalTags,
        isPromote: !data.isPromote,
        isGroupPromote: !data.isGroupPromote,
        date: fromDate,
    }
}

export default (data = {}, accountInfoId) =>
    isEmpty(data) ? createEmptyFormModel(accountInfoId) : createEditFormModel(data)
