import API from '../../../../api'
import { call, put, select } from 'redux-saga/effects'
import { SHARED_POSTS_INFO_SUCCESS, SHARED_POSTS_INFO_FAILED } from '../../../actions/settings/sharing'

const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

export function* sharedPostsInfo(action) {
    const payload = action.payload
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        const response = yield call(() => {
            return API.settings.sharedPosts(payload.limit, payload.offset, universityIds)
        })

        if (response.success) {
            yield put({
                type: SHARED_POSTS_INFO_SUCCESS,
                payload: {
                    items: response.data.posts,
                    total: response.data.total,
                },
            })
        } else {
            yield put({
                type: SHARED_POSTS_INFO_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: SHARED_POSTS_INFO_FAILED,
        })
    }
}
