import { Form, Row, Col, Input, Checkbox } from 'antd'
import { startOfDay, isBefore } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'

import DatePicker from '../../../../../components/date-picker/date-picker'
import GlobalTagsSelect from '../../../../../components/global-tags-select/global-tags-select'
import TimezonesSelect from '../../../../../components/timezones-select/timezones-select'
import { roles } from '../../../../../helpers/helpers'
import { PUBLISH_CONTROL } from '../../../variables'
import { CoverImage, DurationInput, ExternalSpeakers, ScheduledUsersSelect, PublishControl } from '../../index'

const { TextArea } = Input

const EventForm = ({
    form,
    initialValues,
    step,
    imageState,
    setImageState,
    editMode,
    isInProgress = false,
    isUpcoming = false,
    isGroupAdmin,
}) => {
    const [currentDateFromPicker, setCurrentDateFromPicker] = useState(null)
    const [hasFeedback, setHasFeedback] = useState({ 0: { email: false, confirmEmail: false } })
    useEffect(() => {
        form.resetFields()
        setCurrentDateFromPicker(form.getFieldValue('date'))
    }, [initialValues, form])

    const disabledDate = useCallback((currentDate) => {
        const today = new Date()
        return currentDate && isBefore(currentDate, startOfDay(today))
    }, [])

    const showField = !isInProgress || isUpcoming

    const handleDatePickerChange = useCallback((val) => {
        setCurrentDateFromPicker(val)
    }, [])

    const normalizeValue = useCallback((val) => {
        return val.replace(/  +/g, ' ')
    }, [])

    return (
        <Form form={form} layout="vertical" autoComplete="off" initialValues={initialValues} hideRequiredMark>
            <div
                style={{
                    display: step === 0 ? 'block' : 'none',
                }}
            >
                <Row gutter={8} style={{ flexFlow: 'row' }}>
                    <Col flex={1}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                { whitespace: true, message: 'Add event name' },
                                { required: true, message: 'Add event name' },
                                { min: 3, message: 'Event name should contain at least 3 symbols' },
                                { max: 100, message: 'Event name should contain less than 100 symbols' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        {showField && (
                            <div>
                                <span style={{ color: 'red' }}>*</span> The cover image on the Feed will be displayed in
                                4:1 aspect ratio. Please make sure the image you upload fits in this format.
                            </div>
                        )}
                    </Col>
                    <Col>
                        {showField && <CoverImage form={form} imageState={imageState} setImageState={setImageState} />}
                    </Col>
                </Row>

                <Form.Item
                    name="description"
                    label="Stream description"
                    validateTrigger="onBlur"
                    rules={[
                        { whitespace: true },
                        { required: true, message: 'Add description' },
                        { min: 50, message: 'Event description should contain at least 50 symbols' },
                        { max: 1000, message: 'Event description should not contain more than 1000 symbols' },
                        () => ({
                            validator(_, value) {
                                if (value.length === 50 && /\s/g.test(value.charAt(49))) {
                                    form.setFieldsValue({
                                        description: value.trim(),
                                    })
                                    form.validateFields(['description'])
                                }
                                return Promise.resolve()
                            },
                        }),
                    ]}
                    normalize={normalizeValue}
                >
                    <TextArea
                        rows={5}
                        placeholder="Let your audience know what this stream will cover and encourage them to sign up."
                        maxLength={1000}
                        showCount={{
                            formatter: ({ count, maxLength }) => `${count}/${maxLength}`,
                        }}
                    />
                </Form.Item>

                {showField && (
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Choose date' }]}>
                                <DatePicker
                                    placeholder="Choose date"
                                    disabledDate={disabledDate}
                                    allowClear={false}
                                    style={{ width: '100%' }}
                                    onChange={handleDatePickerChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <DurationInput form={form} currentDateFromPicker={currentDateFromPicker} />
                        </Col>
                    </Row>
                )}
                {showField && <TimezonesSelect />}

                <GlobalTagsSelect form={form} />

                {showField && (
                    <Form.Item name="isPromote" valuePropName="checked">
                        <Checkbox>Don’t show on TAP Feed. I will send the link to the stream privately.</Checkbox>
                    </Form.Item>
                )}

                {isGroupAdmin && (
                    <Form.Item name="isGroupPromote" valuePropName="checked">
                        <Checkbox>Don’t show on the Group Account TAP Feed </Checkbox>
                    </Form.Item>
                )}
            </div>

            {!editMode && (
                <>
                    <div
                        style={{
                            display: step === 1 ? 'block' : 'none',
                        }}
                    >
                        <ScheduledUsersSelect
                            id="internalSpeakerIds"
                            label="Internal speakers"
                            roles={[roles.admin, roles.student]}
                            form={form}
                            rules={[
                                {
                                    required: true,
                                    message: 'You should invite at least 1 speaker',
                                },
                            ]}
                            showRoles
                        />

                        <ExternalSpeakers
                            id="externalSpeakerEmails"
                            label="External speakers (emails)"
                            form={form}
                            hasFeedback={hasFeedback}
                            setHasFeedback={setHasFeedback}
                        />

                        <Form.Item
                            className="speakers-placeholder-field"
                            name="speakersPlaceholder"
                            dependencies={['internalSpeakerIds', 'externalSpeakerEmails']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_) {
                                        const internal = getFieldValue('internalSpeakerIds')
                                        const external = getFieldValue('externalSpeakerEmails').filter(
                                            ({ email } = { email: '' }) => email.length > 1
                                        )

                                        if (internal.length === 0 && external.length === 0) {
                                            return Promise.reject(new Error('You should add at least one speaker'))
                                        }

                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                        >
                            <Input style={{ display: 'none' }} />
                        </Form.Item>
                    </div>

                    <div
                        style={{
                            display: step === 2 ? 'block' : 'none',
                        }}
                    >
                        <p>
                            Chosen ambassadors will be answering prospects questions in the general chat during the live
                            event
                        </p>
                        <ScheduledUsersSelect
                            id="userIds"
                            label="Add ambassadors"
                            form={form}
                            roles={[roles.student]}
                            excludeSpeakers
                        />
                    </div>
                    <div
                        style={{
                            display: step === 3 ? 'block' : 'none',
                        }}
                    >
                        <PublishControl
                            id={PUBLISH_CONTROL.ID}
                            label={PUBLISH_CONTROL.LABEL}
                            description={PUBLISH_CONTROL.DESCRIPTON}
                        />
                    </div>
                </>
            )}
        </Form>
    )
}

export default EventForm
