import React from 'react'

import Header from '../../_layout/header'
import NavigationSetupWizard from '../../_layout/navSetupWizard'

import { SetupTitle } from '../components'
import { MobileAppDownloadSection, MobileAppCredentialsSection, AmbassadorActivationForm } from './components'
import LogOutButton from '../../../components/log-out-button/log-out-button'

import { useAmbassadorSelfInviteForm } from './use-ambassador-self-invite-form'

const AmbassadorSelfInviteScreen = () => {
    const { form, onSubmit, prevStep, handleActivationToggle, initialValues, loggedInValidator } =
        useAmbassadorSelfInviteForm()

    return (
        <div>
            <Header submenuUnActive={true} />
            <div id="page-wrapper" className="page-wrapper">
                <NavigationSetupWizard />

                <section className="content settings no-submenu home-start-guide setup-wizard">
                    <LogOutButton step={2} />
                    <div className="start-guide-wizard-grid pls">
                        <div className="column pls">
                            <SetupTitle
                                blockTitle="Test it out: log in as an ambassador"
                                title="The Ambassador Platform Account Setup Guide"
                            />
                            <MobileAppDownloadSection />
                            <MobileAppCredentialsSection />
                            <AmbassadorActivationForm
                                form={form}
                                initialValues={initialValues}
                                handleActivationToggle={handleActivationToggle}
                                prevStep={prevStep}
                                onSubmit={onSubmit}
                                loggedInValidator={loggedInValidator}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AmbassadorSelfInviteScreen
