import HttpService from '../../system/services/HttpService'

export default {
    getListOfCampaigns: ({ limit = 20, offset = 0, universityId = null, filter = {}, order, orderBy }) => {
        const data = {
            limit,
            offset,
            filter,
            order,
            orderBy,
        }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/campaigns', data)
    },

    getListOfCampaignsTypes: () => {
        return HttpService.get(`/dashboard/campaigns/types`)
    },

    getCampaignById: ({ id = null, universityId = null }) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get(`/dashboard/campaigns/${id}`, data)
    },
    createCampaign: (data) => {
        return HttpService.post('/dashboard/campaigns/', data)
    },
}
