import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Form, Input, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import pencil from '../../../../../../assets/img/pencil.png'

import { getCountries } from '../../../../../redux/actions/settings/countries/countries-actions'
import { changeUserCountry } from '../../../../../redux/actions/content/usersActions'
import { getCountriesSlice } from '../../../../../redux/selectors'

const { Option } = Select

const AmbassadorCountry = ({ region, countries, userId }) => {
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()
    const { items: countriesList } = useSelector(getCountriesSlice)

    useEffect(() => {
        dispatch(getCountries())
    }, [dispatch])

    const onSuccess = useCallback(() => {
        setEditMode(false)
    }, [])

    const onFinish = useCallback(
        (values) => {
            dispatch(
                changeUserCountry({
                    userId,
                    region: values.region,
                    countryId: values.country,
                    countries: countriesList.filter((item) => item.id === values.country),
                    onSuccess,
                })
            )
        },
        [dispatch, userId, countriesList, onSuccess]
    )

    const editCountryInfo = useCallback(() => {
        setEditMode(true)
    }, [])

    const onCancel = useCallback(() => {
        setEditMode(false)
    }, [])

    const options = useMemo(
        () =>
            countriesList.map((country) => {
                return (
                    <Option key={country.id} value={country.id} label={country.name}>
                        {country.name}
                    </Option>
                )
            }),
        [countriesList]
    )

    return editMode ? (
        <>
            <Form
                initialValues={{
                    region: region,
                    country: countriesList.find((item) => item.name === countries[0].name)?.id,
                }}
                onFinish={onFinish}
                autoComplete="off"
                hideRequiredMark
            >
                <Form.Item
                    name="country"
                    label="Country"
                    style={{ marginBottom: 20 }}
                    rules={[{ required: true, message: 'Select country' }]}
                >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        optionLabelProp="label"
                        style={{ fontSize: 16, maxWidth: 200 }}
                    >
                        {options}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="region"
                    label="Region"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true, message: 'Add region' }]}
                >
                    <Input placeholder="f.e. London" maxLength={50} style={{ fontSize: 16 }} />
                </Form.Item>
                <div className="input__buttons">
                    <button className="input-control-button" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="input-control-button" type="submit">
                        Save
                    </button>
                </div>
            </Form>
        </>
    ) : (
        <>
            <div className="profile-info-tags-items">
                {region ? region + ', ' : ''}
                {countries.map((tag) => tag.name)}
                <button className="input-control-button" onClick={editCountryInfo}>
                    <img className="pencil-icon" src={pencil} alt="" />
                </button>
            </div>
        </>
    )
}

export default AmbassadorCountry
