import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getCommonRoutePath } from '../../helpers/helpers'

const RedirectIfGuest = ({ children }) => {
    const { isMaintenanceMode, token } = useSelector((state) => state.account)
    if (!token) {
        return <div>{children}</div>
    } else if (isMaintenanceMode) {
        return <Redirect to="/maintenance" />
    } else {
        return <Redirect to={getCommonRoutePath() + '/home'} />
    }
}

export default RedirectIfGuest
