import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import noInactiveImage from '../../../../assets/img/inactive-ambassadors.png'
import userDefaultAvatar from '../../../../assets/img/placeholder-user.png'
import { DateHumanizeHelper } from '../../../helpers/table'
import { getCommonRoutePath } from '../../../helpers/helpers'
import { Image } from 'antd'

class InactiveAmbassadorsBlock extends PureComponent {
    constructor(props) {
        super()
    }

    render() {
        const { account, users } = this.props
        const commonPath = getCommonRoutePath()

        return (
            <div className="inactive-sa-block">
                {!users || users.length === 0 ? (
                    <>
                        <p className="inactive-sa">
                            <b>Inactive Ambassadors</b>
                            <span>0</span>
                        </p>
                        <Image src={noInactiveImage} alt="" className="no-inactive-sa-image" />
                    </>
                ) : (
                    <>
                        <p className="inactive-sa">
                            <b>Inactive Ambassadors</b>
                            <span className="inactive-sa-number">+ {users.length}</span>
                        </p>
                        <div className="inactive-ambassadors">
                            <ul className="inactive-sa-list">
                                {users.map((user, i) => {
                                    return (
                                        <li key={i} className="inactive-sa-item">
                                            <Image src={user.avatar || userDefaultAvatar} alt="" />
                                            <div className="sa-item-text-block">
                                                <div className="sa-item-text-line">
                                                    <span className="inactive-sa-name">{user.name}</span>
                                                    <NavLink
                                                        to={
                                                            commonPath +
                                                            '/chat/messaging?' +
                                                            (account.currentGroupUniversityId
                                                                ? 'universityId=' +
                                                                  account.currentGroupUniversityId +
                                                                  '&'
                                                                : '') +
                                                            'unanswered=true&userId=' +
                                                            user.id +
                                                            '&userName=' +
                                                            user.name
                                                        }
                                                        target="_blank"
                                                    >
                                                        View conversations
                                                    </NavLink>
                                                </div>
                                                <span className="inactive-sa-date-text">
                                                    {user.is_enabled_chat
                                                        ? 'Last seen ' + DateHumanizeHelper(user.last_activity)
                                                        : 'Disabled chat function'}
                                                </span>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(InactiveAmbassadorsBlock)
