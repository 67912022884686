import { Button } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getEventStreamSlice } from '../../../../../../../redux/selectors/common-selectors'
import { openJoinModal } from '../../../../../../../redux/actions/event-stream/event-stream-actions'

const JoinOverlay = ({ onJoinClick }) => {
    const dispatch = useDispatch()
    const {
        joined,
        joinInProgress,
        currentParticipant: { isAdminSpeaker },
    } = useSelector(getEventStreamSlice)

    const handleJoinRoomClick = useCallback(() => {
        if (isAdminSpeaker) {
            dispatch(openJoinModal())
        } else {
            onJoinClick()
        }
    }, [dispatch, isAdminSpeaker, onJoinClick])

    const joinButton = useMemo(() => {
        const buttonText = isAdminSpeaker ? 'Join the room' : 'Watch the stream'

        return (
            <Button type="primary" size="large" onClick={handleJoinRoomClick} loading={joinInProgress}>
                {buttonText}
            </Button>
        )
    }, [isAdminSpeaker, joinInProgress, handleJoinRoomClick])

    return (
        !joined && (
            <div className="tap-event-stream-join-overlay">
                <div>
                    <div>Event is in progress</div>
                    <br />
                    {joinButton}
                </div>
            </div>
        )
    )
}

export default JoinOverlay
