import cn from 'classnames'
import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LIVE_EVENT_STATUS } from '../../../../common/constants'
import Loader from '../../../content/components/Loader'
import { updateEventStatus, updateProspectList } from '../../../../redux/actions/live-event/live-events-actions'
import { getAccountSlice, getActiveLiveEvent, getIsGroupAdmin } from '../../../../redux/selectors'

import { PreviewImage, EventHeader, ChatSidebar, EventStream } from './components'
import { pusherChild } from '../../../../system/services/PusherWrapper'

const CHANNEL_NAME = 'dashboard'

const LiveEventView = () => {
    const isGroupAdmin = useSelector(getIsGroupAdmin)
    const activeEvent = useSelector(getActiveLiveEvent)
    const dispatch = useDispatch()
    const account = useSelector(getAccountSlice)

    const channel = useRef(null)
    const currentId = useRef(null)

    const { id, status, isStreamActive } = activeEvent

    useEffect(() => {
        if (currentId.current !== id) {
            if (channel.current !== null) {
                channel.current = pusherChild.unsubscribe(CHANNEL_NAME)
                channel.current = null
                currentId.current = id
            }
            channel.current = pusherChild.subscribe(CHANNEL_NAME)

            channel.current.bind(`liveEvents:${id}:info`, (data) => {
                const { isChatActive, status: eventStatus, isStreamActive, newProspect, totalProspects } = data

                dispatch(updateEventStatus({ id, isChatActive, status: eventStatus, isStreamActive }))
                if (newProspect) {
                    dispatch(updateProspectList({ id, newProspect, totalProspects }))
                }
            })
        }

        const elem = document.querySelector('.tap-live-event-block-wrapper')

        const toggleWrapperClassname = () => {
            if (document.fullscreenElement) {
                elem.classList.toggle('is-fullscreen', true)
            } else {
                elem.classList.toggle('is-fullscreen', false)
            }
        }

        elem.addEventListener('fullscreenchange', toggleWrapperClassname)

        return () => {
            elem.removeEventListener('fullscreenchange', toggleWrapperClassname)
            channel.current = pusherChild.unsubscribe(CHANNEL_NAME)
            channel.current = null
        }
    }, [id])

    const isInProgress = useMemo(() => status === LIVE_EVENT_STATUS.IN_PROGRESS, [status])
    const isFinished = useMemo(() => !isStreamActive, [isStreamActive])

    const wrapperClassName = useMemo(
        () =>
            cn('live-event tap-live-event-block-wrapper', {
                'live-group-event': isGroupAdmin,
            }),
        [isGroupAdmin]
    )

    const previewImage = useMemo(
        // () => !isInProgress && <PreviewImage activeEvent={activeEvent} />,
        () => {
            const finished = isInProgress && isFinished
            if (!isInProgress || finished) {
                return <PreviewImage activeEvent={activeEvent} />
            }
        },
        [isInProgress, activeEvent, isFinished]
    )

    return (
        <div className={wrapperClassName}>
            {id ? (
                <>
                    <div className="event-preview-block">
                        <EventHeader />
                        {previewImage}
                        {isInProgress && !isFinished && <EventStream activeEvent={activeEvent} account={account} />}
                    </div>
                    <ChatSidebar activeEvent={activeEvent} />
                </>
            ) : (
                <div className="live-events-loader">
                    <Loader />
                </div>
            )}
        </div>
    )
}

export default LiveEventView
