var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Spin, Tag } from 'antd';
import React, { useEffect } from 'react';
import { setSearchItems } from 'src/app/redux/actions/content/postsActions';
import { concatClsx } from '../../../../../helpers/classNames';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { getPopularTags } from '../../../../../redux/actions/tags/tagsActions';
import { selectPopularTags, selectPopularTagsLoading } from '../../../../../redux/reducers/tags/popularTagsReducer';
import { selectSearchItems } from '../../../../../redux/selectors/posts-selectors';
export var PopularTags = function () {
    var popularTags = useAppSelector(selectPopularTags);
    var isLoading = useAppSelector(selectPopularTagsLoading);
    var searchItems = useAppSelector(selectSearchItems);
    var dispatch = useAppDispatch();
    useEffect(function () {
        dispatch(getPopularTags({
            limit: 10,
            offset: 0,
            orderBy: 'count',
        }));
    }, [dispatch]);
    var handleClick = function (tag) {
        var tags = searchItems.includes(tag) ? searchItems.filter(function (item) { return item !== tag; }) : __spreadArray(__spreadArray([], searchItems, true), [tag], false);
        dispatch(setSearchItems(tags));
    };
    return (React.createElement("div", { className: "tags" },
        React.createElement("div", { className: "text-lg mb-4 font-semibold" }, "Top Tags"),
        isLoading ? (React.createElement("div", { className: "flex items-center justify-center p-6" },
            React.createElement(Spin, null))) : (popularTags.map(function (item) {
            return (React.createElement(Tag, { className: concatClsx('text-base rounded-2xl text-white leading-none py-1 mb-2 cursor-pointer whitespace-pre-wrap', searchItems.includes(item.name) ? 'bg-brand-orange' : 'bg-tap-dark'), onClick: function () { return handleClick(item.name); }, key: item.name }, item.name));
        }))));
};
