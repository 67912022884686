import { GET_TITLES_SUCCESS, SOCIAL_PROOF_SUCCESS } from '../../actions/settings/customTitlesActions.js'

const initialState = {}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_TITLES_SUCCESS: {
            if (payload.titles) {
                return {
                    ...payload.titles,
                }
            }
            return {}
        }
        case SOCIAL_PROOF_SUCCESS: {
            return {
                ...state,
                socialProof: payload,
            }
        }

        default: {
            return state
        }
    }
}
