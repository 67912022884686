/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { roles } from '../../../../../helpers/helpers'
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions'
import { ScheduledUsersSelect } from '../../index'

import AmbassadorsFormModel from './ambassadors-form-model'

const AmbassadorsForm = ({ form, data }) => {
    const dispatch = useDispatch()
    const initialValues = useMemo(() => AmbassadorsFormModel(data), [data])

    const {
        meta: { selectedAmbassadors, selectedSpeakers },
    } = initialValues

    useEffect(() => {
        form.resetFields()
    }, [initialValues])

    useEffect(() => {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
                roles: [roles.student],
            },
        })
    }, [])
    return (
        <Form form={form} initialValues={initialValues} layout="vertical">
            <p>Chosen ambassadors will be answering prospects questions in the general chat during the live event</p>
            <ScheduledUsersSelect
                id="userIds"
                form={form}
                roles={[roles.student]}
                selectedUsers={selectedAmbassadors}
                excludeSpeakers={selectedSpeakers}
            />
        </Form>
    )
}

export default AmbassadorsForm
