import liveswitch from 'fm.liveswitch'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { debounce } from '../../../../../../common/utils/debounce'
import {
    addParticipant,
    closeJoinModal,
    setCurrentParticipant,
    setJoined,
    setJoinInProgress,
    setMedia,
    muteLocalVideo,
    removeParticipant,
    setRemoteVideoMuted,
    muteLocalAudio,
    setRemoteAudioMuted,
    resetStream,
    setScreenMedia,
    setLocalScreenShared,
    setStreamAudioMuted,
} from '../../../../../../redux/actions/event-stream/event-stream-actions'
import { getEventStreamSlice } from '../../../../../../redux/selectors/common-selectors'

import { checkIsAdminSpeaker } from '../../../../../../models/live-event-model'
import { StreamApi } from './stream-api'

export const useEventStream = ({ account, activeEvent }) => {
    const dispatch = useDispatch()

    const {
        participants,
        joined,
        videoDevices,
        audioDevices,
        localScreenShared,
        currentPresenterId,
        currentParticipant,
    } = useSelector(getEventStreamSlice)

    const { isAdminSpeaker } = currentParticipant

    const eventVideoStreamRef = useRef(null)
    const layoutManager = useRef(null)

    const streamApiInstance = useRef(new StreamApi({ account, activeEvent }))

    const someoneScreenShared = useMemo(
        () => localScreenShared || currentPresenterId,
        [localScreenShared, currentPresenterId]
    )

    useEffect(() => {
        const instance = streamApiInstance.current

        dispatch(
            setCurrentParticipant({
                ...instance.user,
                isAdminSpeaker: checkIsAdminSpeaker(account, activeEvent),
            })
        )

        layoutManager.current = new liveswitch.DomLayoutManager(eventVideoStreamRef.current)
        layoutManager.current.setMode(liveswitch.LayoutMode.Inline)
        layoutManager.current.setAlignment(liveswitch.LayoutAlignment.TopLeft)
        layoutManager.current.setDirection(liveswitch.LayoutDirection.Horizontal)

        return () => {
            instance.leave({ destroy: true })
            dispatch(resetStream())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const debouncedHandleResize = debounce(function () {
            layoutManager.current.layout()
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    })

    useEffect(() => {
        console.log('participants', participants)
        layoutManager.current.layout()
    }, [participants])

    useEffect(() => {
        if (someoneScreenShared) {
            layoutManager.current.setMode(liveswitch.LayoutMode.InlineOverflow)
            layoutManager.current.setAlignment(liveswitch.LayoutAlignment.TopLeft)
            layoutManager.current.setDirection(liveswitch.LayoutDirection.Vertical)
        } else {
            layoutManager.current.setMode(liveswitch.LayoutMode.Inline)
            layoutManager.current.setAlignment(liveswitch.LayoutAlignment.TopLeft)
            layoutManager.current.setDirection(liveswitch.LayoutDirection.Horizontal)
        }
        layoutManager.current.layout()
    }, [someoneScreenShared])

    const resetMediaDevices = useCallback(() => {
        streamApiInstance.current.localMedia.changeVideoSourceInput(videoDevices[0])
        streamApiInstance.current.localMedia.changeAudioSourceInput(audioDevices[0])
        streamApiInstance.current.localMedia.setVideoMuted(false)
        streamApiInstance.current.localMedia.setAudioMuted(false)
    }, [audioDevices, videoDevices])

    const handleJoinModalClose = useCallback(() => {
        streamApiInstance.current.localMedia.stop().then(() => {
            resetMediaDevices()
            dispatch(muteLocalVideo(false))
            dispatch(muteLocalAudio(false))
            dispatch(closeJoinModal())
        })
    }, [dispatch, resetMediaDevices])

    const handleJoinClick = useCallback(() => {
        dispatch(setJoinInProgress(true))

        streamApiInstance.current
            .join({
                Initializing: (participant, videoMuted, audioMuted, remoteMedia, isScreenShare) => {
                    dispatch(addParticipant(participant))
                    dispatch(setMedia(participant.id, remoteMedia, { videoMuted, audioMuted }))
                },
                onRemoteUpstreamJoined: (participant, videoMuted, audioMuted, remoteMedia, isScreenShare) => {
                    if (isScreenShare) {
                        dispatch(addParticipant(participant))
                        dispatch(setScreenMedia(participant.id, remoteMedia))
                    } else {
                        dispatch(addParticipant(participant))
                        dispatch(setMedia(participant.id, remoteMedia, { videoMuted, audioMuted, loading: false }))
                    }
                },
                onRemoteUpstreamClosed: (participantId, isScreenShare, userId) => {
                    dispatch(removeParticipant(participantId, isScreenShare, userId))
                },
                onRemoteVideoMuted: (participantId, isMuted) => {
                    dispatch(setRemoteVideoMuted(participantId, isMuted))
                },
                onRemoteAudioMuted: (participantId, isMuted) => {
                    dispatch(setRemoteAudioMuted(participantId, isMuted))
                },
            })
            .then(() => {
                if (isAdminSpeaker) {
                    streamApiInstance.current.openUpstreamConnection(
                        streamApiInstance.current.localMedia,
                        { source: 'webcam' },
                        {
                            local: true,
                            onOpen: (participant, localMedia, videoMuted, audioMuted) => {
                                dispatch(addParticipant(participant))
                                dispatch(
                                    setMedia(participant.id, localMedia, {
                                        videoMuted,
                                        audioMuted,
                                        loading: false,
                                    })
                                )
                                dispatch(setJoined(true))
                                dispatch(setJoinInProgress(false))
                                dispatch(closeJoinModal())
                            },
                        }
                    )
                } else {
                    dispatch(setJoined(true))
                    dispatch(setJoinInProgress(false))
                }
            })
    }, [dispatch, isAdminSpeaker])

    const handleMuteVideoClick = useCallback(() => {
        const currentlyMuted = streamApiInstance.current.muteLocalVideo(videoDevices)
        dispatch(muteLocalVideo(!currentlyMuted))
    }, [dispatch, videoDevices])

    const handleMuteAudioClick = useCallback(() => {
        const currentlyMuted = streamApiInstance.current.muteLocalAudio(audioDevices)
        dispatch(muteLocalAudio(!currentlyMuted))
    }, [dispatch, audioDevices])

    const handleLeaveClick = useCallback(() => {
        streamApiInstance.current.leave({
            onLeave: () => {
                resetMediaDevices()
                dispatch(resetStream())
            },
        })
    }, [dispatch, resetMediaDevices])

    const handleShareScreenClick = useCallback(() => {
        const clientId = streamApiInstance.current.clientId
        if (
            streamApiInstance.current.localScreenMedia.getState() === liveswitch.LocalMediaState.New ||
            streamApiInstance.current.localScreenMedia.getState() === liveswitch.LocalMediaState.Stopped
        ) {
            if (typeof currentPresenterId !== 'undefined' && currentPresenterId !== streamApiInstance.current.clientId)
                return

            streamApiInstance.current.startScreenSharing({
                onOpen: () => {
                    dispatch(setScreenMedia(clientId, streamApiInstance.current.localScreenMedia))
                    dispatch(setLocalScreenShared(true))
                },
                onClose: () => {
                    dispatch(
                        setScreenMedia(clientId, null, {
                            remove: true,
                        })
                    )
                    dispatch(setLocalScreenShared(false))
                },
            })
        } else {
            streamApiInstance.current.stopScreenSharing({
                onClose: () => {
                    dispatch(
                        setScreenMedia(clientId, null, {
                            remove: true,
                        })
                    )
                    dispatch(setLocalScreenShared(false))
                },
            })
        }
    }, [dispatch, currentPresenterId])

    const handleMuteRemoteAudioClick = useCallback(() => {
        const isMuted = streamApiInstance.current.muteRemoteAudio()
        dispatch(setStreamAudioMuted(isMuted))
    }, [dispatch])

    return {
        localMedia: streamApiInstance.current.localMedia,
        eventVideoStreamRef,
        joined,
        onJoinModalClose: handleJoinModalClose,
        onJoinClick: handleJoinClick,
        onMuteVideoClick: handleMuteVideoClick,
        onMuteAudioClick: handleMuteAudioClick,
        onLeaveClick: handleLeaveClick,
        onShareScreenClick: handleShareScreenClick,
        onMuteRemoteAudioClick: handleMuteRemoteAudioClick,
    }
}
