import { Form, Input } from 'antd'
import React from 'react'
import { OrganizationDetailsLogo } from '../../components'

const OrganizationDetailsForm = ({ form, initialValues, institutionNameLength, onInputChange, onSubmit }) => {
    return (
        <Form form={form} layout="vertical" autoComplete="off" initialValues={initialValues} onFinish={onSubmit}>
            <div>
                <Form.Item
                    name="websiteUrl"
                    label="Institution website link"
                    className="form-item"
                    rules={[
                        { whitespace: false, message: 'Add institution website link' },
                        { required: true, message: 'Add institution website link' },
                        { type: 'url', message: 'The input is not valid link' },
                    ]}
                >
                    <Input
                        placeholder="f.e. https://www.institutionname.com/"
                        className="form-item-input"
                        maxLength={255}
                    />
                </Form.Item>
                <Form.Item
                    name="organizationLogo"
                    className="form-item"
                    label="Institution logo"
                    rules={[{ required: true, message: 'Add logo' }]}
                >
                    <OrganizationDetailsLogo form={form} />
                </Form.Item>
                <div className="setup-steps-buttons">
                    <button type="submit">Next</button>
                </div>
            </div>
        </Form>
    )
}

export default OrganizationDetailsForm
