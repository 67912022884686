import React, { PureComponent } from 'react'

import AreaChart from '../components/AreaChart'
import ChartHeader from '../components/ChartHeader'

import {
    prepareTimeData,
    prepareAverageTimeData,
    prepareTotalMessagesData,
    prepareNumberDataWithAverage,
    numberTooltipFormatter,
    timeTooltipFormatter,
    yAxisTimeFormatter,
    pluralize,
} from '../../../helpers/chart'

class AmbassadorsCharts extends PureComponent {
    prepareAmbassadorMessagesData() {
        return prepareNumberDataWithAverage(this.props.data.messagesSent, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    prepareResponseTimeChartData() {
        return {
            data: prepareTimeData(this.props.data.ambassadorResponseTime, [
                [Date.parse(this.props.fromDate), 0],
                [Date.parse(this.props.toDate), 0],
            ]),
            averageTime: prepareAverageTimeData(this.props.data.ambassadorResponseTimeAsOne, 'No data'),
        }
    }

    prepareTotalMessages() {
        return {
            totalMessages: prepareTotalMessagesData(this.props.data.messagesSentAsOne, '0'),
        }
    }

    messagesTooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'message'))
    }

    responseTimeTooltipFormatter() {
        return timeTooltipFormatter(this.point.x, this.point.y)
    }

    yAxisResponseTimeChart() {
        return yAxisTimeFormatter(this.value)
    }

    render() {
        const { selectedDiagram } = this.props
        const responseTimeData = this.prepareResponseTimeChartData()
        const messagesSentData = this.prepareAmbassadorMessagesData()
        const totalMessagesData = this.prepareTotalMessages()
        return (
            <>
                <ChartHeader
                    label={
                        selectedDiagram === 'responseTime'
                            ? responseTimeData.averageTime
                            : totalMessagesData.totalMessages + pluralize(totalMessagesData.totalMessages, ' message')
                    }
                    labelDescription={
                        selectedDiagram === 'responseTime'
                            ? 'Median First Response Time (across date range)'
                            : 'Total Number of Chat Messages (across date range)'
                    }
                    displaySingleLabel={true}
                />
                <AreaChart
                    color="#ff5100"
                    data={selectedDiagram === 'responseTime' ? responseTimeData.data : messagesSentData.data}
                    tooltipFormatter={
                        selectedDiagram === 'responseTime'
                            ? this.responseTimeTooltipFormatter
                            : this.messagesTooltipFormatter
                    }
                    yAxisFormatter={selectedDiagram === 'responseTime' ? this.yAxisResponseTimeChart : null}
                    height={320}
                />
            </>
        )
    }
}

export default AmbassadorsCharts
