import { COUNTRIES } from '../../actions/settings/countries/countries-action-types'

const initialState = {
    items: [],
    isLoading: true,
}

export default function (state = initialState, action) {
    const { type, payload = {} } = action

    switch (type) {
        case COUNTRIES.GET_COUNTRIES_SUCCESS: {
            return {
                ...state,
                items: payload.countries || [],
                isLoading: false,
            }
        }

        case COUNTRIES.GET_PROVIDERS_FAILED: {
            return {
                ...state,
                isLoading: false,
            }
        }
        default: {
            return state
        }
    }
}
