/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, Route, Switch } from 'react-router-dom'

import { getCurrentGroupUniversityId } from '../../../../redux/selectors'

import {
    CampaignManagementGuideScreen,
    CampaignsListScreen,
    CampaignsCreateScreen,
    CampaignsViewScreen,
    CampaignsResultsScreen,
} from '../index'
import { getCommonRoutePath } from '../../../../helpers/helpers'

const CampaignManagementTypeRootScreen = () => {
    const dispatch = useDispatch()
    const { param } = useParams()
    const history = useHistory()

    const currentGroupUniversityId = useSelector(getCurrentGroupUniversityId)
    useEffect(() => {
        if (param === 'start') {
            history.push(getCommonRoutePath() + '/campaign-management/start')
        }
    }, [param, dispatch, history, currentGroupUniversityId])

    return (
        <Switch>
            <Route
                path={['/campaign-management/start', '/:university_id/campaign-management/start']}
                component={CampaignManagementGuideScreen}
            />
            <Route
                exact
                path={['/campaign-management/campaigns', '/:university_id/campaign-management/campaigns']}
                component={CampaignsListScreen}
            />
            <Route
                exact
                path={[
                    '/campaign-management/campaigns/create/:id',
                    '/:university_id/campaign-management/campaigns/create/:id',
                ]}
                component={CampaignsCreateScreen}
            />
            <Route
                exact
                path={[
                    '/campaign-management/campaigns/view/:typeKey/:id',
                    '/:university_id/campaign-management/campaigns/view/:typeKey/:id',
                ]}
                component={CampaignsViewScreen}
            />
            <Route
                exact
                path={[
                    '/campaign-management/campaigns/results/:typeKey/:id',
                    '/:university_id/campaign-management/campaigns/results/:typeKey/:id',
                ]}
                component={CampaignsResultsScreen}
            />
        </Switch>
    )
}

export default CampaignManagementTypeRootScreen
