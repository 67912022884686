import {
    ACTIVITY_CHARTS_SUCCESS,
    ACTIVITY_TABLE_REQUESTED,
    ACTIVITY_TABLE_SUCCESS,
    ACTIVITY_CSV_REQUESTED,
    ACTIVITY_CSV_SUCCESS,
} from '../../actions/reporting/activityActions'

const initialState = { charts: {}, table: {}, isTableLoading: true, csvUrl: '' }

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case ACTIVITY_CHARTS_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.charts = payload
            // console.log(newState.charts);
            return newState
        }

        case ACTIVITY_TABLE_REQUESTED: {
            const newState = Object.assign({}, state)
            newState.isTableLoading = true
            return newState
        }

        case ACTIVITY_TABLE_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.table = payload
            newState.isTableLoading = false
            // console.log(newState.table);
            return newState
        }

        case ACTIVITY_CSV_REQUESTED: {
            const newState = Object.assign({}, state)
            newState.csvUrl = ''
            return newState
        }

        case ACTIVITY_CSV_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.csvUrl = payload.csvFileUrl
            // console.log(newState.csvUrl);
            return newState
        }
    }

    return state
}
