import { App, ConfigProvider } from 'antd'
import { enableMapSet } from 'immer'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Application from './app/Application'
import { QueryProvider } from './app/queries/client'
import store from './app/redux/store'

enableMapSet()

render(
    <Provider store={store}>
        <QueryProvider>
            <BrowserRouter basename="/">
                <ConfigProvider
                    theme={{
                        token: {
                            fontFamily:
                                '"InterFace", -system-ui, -apple-system, BlinkMacSystemFont, Arial, "Helvetica Neue", Helvetica, sans-serif, -system-ui, -apple-system, BlinkMacSystemFont',
                            borderRadius: '2px',
                            colorPrimary: '#ff5100',
                        },
                        components: {
                            Segmented: {},
                        },
                    }}
                >
                    <App>
                        <Application />
                    </App>
                </ConfigProvider>
            </BrowserRouter>
        </QueryProvider>
    </Provider>,
    document.querySelector('#root')
)
