import { call, put, select } from 'redux-saga/effects'

import API from '../../../api'
import {
    CREATE_EVENT_SUCCESS,
    GET_EVENTS_LIST,
    GET_EVENTS_LIST_SUCCESS,
    CHANGE_EVENT_STATUS_SUCCESS,
    RENEW_EVENT,
    TOGGLE_EVENT_CHAT_SUCCESS,
    GET_PROSPECTS_SUCCESS,
    LOAD_NEXT_PROSPECTS_SUCCESS,
} from '../../actions/live-event/liveEventActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* createEvent(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            payload.universityId = currentGroupUniversityId || payload.university_id
        }
        const response = yield call(() => {
            return API.liveEvent.createEvent(payload)
        })

        if (response.success) {
            yield put({
                type: CREATE_EVENT_SUCCESS,
                payload: {},
            })

            yield put({
                type: GET_EVENTS_LIST,
                payload: {
                    status: payload.type,
                },
            })
            action.onFinish(true)
        } else {
            action.onFinish(false)
        }
    } catch (error) {
        console.log(error)
        action.onFinish(false)
    }
}

export function* editEvent(action) {
    const payload = action.payload
    try {
        /* const isGroupAdmin = yield select(getGroupAdmin);
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId);

        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            payload.universityId = currentGroupUniversityId || payload.university_id;
        } */
        const response = yield call(() => {
            return API.liveEvent.editEvent(payload.data)
        })

        if (response.success) {
            yield put({
                type: GET_EVENTS_LIST,
                payload: {
                    status: 'upcoming',
                    liveEventId: payload.data.liveEventId,
                },
            })
            action.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* searchEvents(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            payload.universityId = currentGroupUniversityId || payload.university_id
        }
        const response = yield call(() => {
            return API.liveEvent.searchEvents(payload)
        })

        if (response.success) {
            yield payload.liveEventId
                ? put({
                      type: RENEW_EVENT,
                      payload: {
                          items: response.data ? response.data.events : null,
                      },
                  })
                : put({
                      type: GET_EVENTS_LIST_SUCCESS,
                      payload: {
                          offset: payload.offset,
                          items: response.data ? response.data.events : null,
                          activeEventId: payload.activeEventId,
                      },
                  })
        } else {
        }
    } catch (error) {
        console.log(error)
    }
}

export function* changeEventStatus(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.liveEvent.changeStatus(payload.id, payload.status)
        })

        if (response.success) {
            if (payload.status !== 'inProgress') {
                yield put({
                    type: CHANGE_EVENT_STATUS_SUCCESS,
                    payload: {
                        status: payload.status,
                    },
                })
            }

            action.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* toggleEventChat(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.liveEvent.toggleChat(payload.id)
        })

        if (response.success) {
            yield put({
                type: TOGGLE_EVENT_CHAT_SUCCESS,
                payload: {
                    id: payload.id,
                },
            })

            action.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* updateAmbassadors(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.liveEvent.updateAmbassadors(payload)
        })

        if (response.success) {
            yield put({
                type: GET_EVENTS_LIST,
                payload: {
                    status: 'upcoming',
                    withGlobalTags: true,
                    liveEventId: payload.liveEventId,
                },
            })

            action.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* updateSpeakers(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.liveEvent.updateSpeakers(payload)
        })

        if (response.success) {
            yield put({
                type: GET_EVENTS_LIST,
                payload: {
                    status: 'upcoming',
                    withGlobalTags: true,
                    liveEventId: payload.liveEventId,
                },
            })

            action.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getProspects(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.liveEvent.getProspects(payload)
        })

        if (response.success) {
            yield put({
                type: GET_PROSPECTS_SUCCESS,
                payload: {
                    items: response.data ? response.data.users : [],
                    total: response.data ? response.data.total : 0,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* loadNextProspects(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.liveEvent.getProspects(payload)
        })

        if (response.success) {
            yield put({
                type: LOAD_NEXT_PROSPECTS_SUCCESS,
                payload: {
                    items: response.data ? response.data.users : [],
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}
