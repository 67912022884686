import types from '../../actions/content/ambassadorsActions'

const initialState = {
    isLoading: true,
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case types.AMBASSADORS_LOADING_CHANGE: {
            return {
                isLoading: payload.isLoading,
            }
        }

        case types.AMBASSADORS_REPORTS_SUCCESS: {
            return {
                isLoading: false,
            }
        }

        case types.AMBASSADORS_REPORTS_FAILED: {
            return {
                isLoading: false,
            }
        }

        default: {
            return state
        }
    }
}
