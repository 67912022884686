/* eslint-disable camelcase */
import types from '../../actions/content/ambassadorsActions'

const initialState = {
    ambassadorsDiagrams: {},
    selectedAmbassadors: [],
    listOfAmbassadors: [],
    ambassadorReport: [],
    total: 0,
    filters: {},
    ambassadorModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
}

export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case types.AMBASSADORS_REPORTS_SUCCESS: {
            return {
                ...state,
                listOfAmbassadors: payload,
            }
        }
        case types.AMBASSADORS_DIAGRAMS_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.ambassadorsDiagrams = payload
            return newState
        }
        case types.AMBASSADORS_REP_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.ambassadorReport = payload.ambassadorReport.ambassadorData
            newState.selectedAmbassadors = payload.ambassadorReport.ambassadorData.map((item) => item.id)
            newState.total = payload.ambassadorReport.total
            return newState
        }

        case types.AMBASSADORS_DEFAULT_DATA: {
            const newState = Object.assign({}, state)
            newState.ambassadorReport = payload
            newState.selectedAmbassadors = payload
            newState.ambassadorData = {}
            return newState
        }

        case types.EDIT_AMBASSADORS_GLOBAL_TAG_FINISH: {
            const { user_id, globalTags } = payload
            const global_tags = globalTags.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                }
            })
            const newState = Object.assign({}, state)
            newState.ambassadorReport = newState.ambassadorReport.map((item) =>
                item.id === user_id ? { ...item, global_tags } : item
            )
            return newState
        }

        case types.AMBASSADOR_CHANGE_INDRODUCTION_SUCCESS: {
            const { user_id, introduction } = payload
            const newState = Object.assign({}, state)
            newState.ambassadorReport = newState.ambassadorReport.map((item) =>
                item.id === user_id ? { ...item, introduction } : item
            )
            newState.listOfAmbassadors = newState.listOfAmbassadors.map((item) =>
                item.id === user_id ? { ...item, introduction } : item
            )
            return newState
        }

        case types.AMBASSADOR_CHANGE_INDRODUCTION_FAILED: {
            return state
        }

        case types.AMBASSADOR_CHANGE_DESCRIPTION_SUCCESS: {
            const { user_id, description } = payload
            const newState = Object.assign({}, state)
            newState.ambassadorReport = newState.ambassadorReport.map((item) =>
                item.id === user_id ? { ...item, description } : item
            )
            newState.listOfAmbassadors = newState.listOfAmbassadors.map((item) =>
                item.id === user_id ? { ...item, description } : item
            )

            return newState
        }

        case types.AMBASSADOR_CHANGE_DESCRIPTION_FAILED: {
            return state
        }

        case types.SET_FILTERS: {
            const newState = Object.assign({}, state)
            newState.filters = payload
            return newState
        }

        case types.GET_AMBASSADOR_ADDITIONAL_DATA_SUCCESS: {
            return {
                ...state,
                ambassadorModal: {
                    ...state.ambassadorModal,
                    open: true,
                    data: payload,
                },
            }
        }
        case types.CLOSE_AMBASSADOR_MODAL: {
            return {
                ...state,
                ambassadorModal: {
                    ...state.ambassadorModal,
                    open: false,
                },
            }
        }

        default: {
            return state
        }
    }
}
