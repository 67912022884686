import React from 'react'
import edit from '../../../../assets/svg/icons/pencil.svg'
import approve from '../../../../assets/svg/icons/chevron-circle-down.svg'
import DeleteQuestionAnswerButton from './DeleteQuestionAnswerButton'
import { Editor } from 'draft-js'
import 'draft-js/dist/Draft.css'

const ReadableAnswer = ({ answer, ...rest }) => {
    const { user, id, content } = answer
    const { handleToggleEdit, handleToggleApprove, userAvatar, title, openDeleteModal } = rest
    return (
        <>
            <div className="table-item-img-container">{user && user.avatar && userAvatar(user)}</div>
            <div className="answer__body">
                <span className="answer__body__publisher">{user ? `${user.name}'s answer` : 'Deleted User'}</span>
                <Editor
                    readOnly={true}
                    editorState={content}
                    onChange={() => {}}
                    classname="question-answer-edit-field answer__body__content"
                />
            </div>
            <div className="answer__buttons">
                <button
                    type="button"
                    className="answer__buttons__button"
                    onClick={() => handleToggleApprove(answer)}
                    data-automation-id={`${title.toLowerCase()}-answer`}
                >
                    <img className="answer__buttons__button__icon" src={approve} alt={title} />
                    {title}
                </button>
                <button
                    type="submit"
                    className="answer__buttons__button"
                    onClick={() => handleToggleEdit(id, 'edit')}
                    data-automation-id="edit-answer"
                >
                    <img className="answer__buttons__button__icon" src={edit} alt="Edit" />
                    Edit
                </button>
                <DeleteQuestionAnswerButton openDeleteModal={() => openDeleteModal(id, 'answer')} />
            </div>
        </>
    )
}

export default ReadableAnswer
