import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import Textarea from 'react-textarea-autosize'

import TagsMultiInput from '../../../../../components/TagsMultiInput'
import SelectAmbassadorsForm from '../../../../../components/SelectAmbassadorsForm'
import { Popover } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const renderField = ({
    input,
    label,
    handleChange,
    id,
    accept,
    value,
    placeholder,
    type,
    className,
    meta: { touched, error, warning },
    maxLength,
}) => (
    <div>
        <input
            {...input}
            className={className}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            accept={accept}
            id={id}
            maxLength={maxLength}
        />
        {touched &&
            ((error && <span className={'error ' + type}>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

const renderTextareaField = ({ placeholder, type, id, className, minRows, maxRows, maxLength, handleChange }) => (
    <Textarea
        rows={minRows}
        maxRows={maxRows}
        id={id}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        className={className}
        onChange={handleChange}
    />
)

class CreateChatGroupForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            firstMessage: '',
            messageLength: 500,
            is_private: false,
            firstMessagePlaceholder:
                'Hi everyone, hope you’re well and thanks for joining us today. Our ambassadors' +
                ' are looking forward to chatting with you! You can see all participants in the chat header above. Please share' +
                ' your questions below - our ambassadors are here to share their insights and experiences with you.',
            globalTagsIds: [],
            // To pass data to form submit:
            selectedUsersNumber: 0,
            assignedUsers: {},
        }

        if (this.props.initialValues.tags) {
            for (const item of this.props.initialValues.tags) {
                this.state.globalTagsIds.push(item.id)
            }
        }

        this.handleChatGroupNameChange = this.handleChatGroupNameChange.bind(this)
        this.handleChatGroupFirstMessageChange = this.handleChatGroupFirstMessageChange.bind(this)
        this.handleGlobalTagsSelect = this.handleGlobalTagsSelect.bind(this)
        this.handleUserSelectionUpdated = this.handleUserSelectionUpdated.bind(this)
        this.onPrivateChange = this.onPrivateChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChatGroupNameChange(e) {
        this.setState({
            name: e.target.value.trim(),
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleChatGroupFirstMessageChange(e) {
        let input = e.target.value.trim()
        if (input.length > 500) {
            input = input.split(' ').slice(0, -1).join(' ')
        }
        const availableSymbols = 500 - input.length

        this.setState({
            firstMessage: input,
            messageLength: availableSymbols >= 0 ? availableSymbols : -1,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleGlobalTagsSelect(globalTagsIds) {
        this.setState({
            globalTagsIds: globalTagsIds,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleUserSelectionUpdated(assignedUsers, selectedUsersNumber, hasBeenUpdatedByUser) {
        this.setState({
            assignedUsers: assignedUsers,
            selectedUsersNumber: selectedUsersNumber,
        })

        if (hasBeenUpdatedByUser) {
            this.props.setFormUpdatedStatus(true)
        }
    }

    onPrivateChange(e) {
        this.setState({
            is_private: e.target.checked,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleSubmit(event) {
        event.preventDefault()

        const {
            name,
            firstMessage,
            firstMessagePlaceholder,
            globalTagsIds,
            assignedUsers,
            is_private: isPrivate,
        } = this.state

        const selectedUserIds = []

        for (const id in assignedUsers) {
            if (assignedUsers[id].selected) {
                selectedUserIds.push(id)
            }
        }

        const firstChatMessage = firstMessage.length > 0 ? firstMessage : firstMessagePlaceholder

        const values = { name, firstMessage: firstChatMessage, is_private: isPrivate, globalTagsIds, selectedUserIds }

        this.props.onSubmit(values)
    }

    render() {
        const { firstMessagePlaceholder, messageLength, is_private: isPrivate, selectedUsersNumber, name } = this.state
        const { ambassadors, areAmbassadorsLoading, onSearchUpdated, isCreateButtonDisabled, initialValues } =
            this.props
        const { tags } = initialValues

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="create-group-row">
                    <div className="w-100">
                        <p className="content-group-name">Name</p>
                        <Field
                            name="name"
                            component={renderField}
                            handleChange={this.handleChatGroupNameChange}
                            type="text"
                            placeholder="Type Group Chat name"
                            className="ui-kit-input"
                            maxLength={100}
                        />
                    </div>
                </div>
                <div className="create-group-row">
                    <div className="w-100">
                        <p className="content-group-name">First Message in a Chat</p>
                        <div
                            className={
                                'input-chat-wrapper' + (messageLength <= 20 ? ' input-chat-wrapper-warning' : '')
                            }
                        >
                            <Field
                                name="firstMessage"
                                component={renderTextareaField}
                                handleChange={this.handleChatGroupFirstMessageChange}
                                type="textarea"
                                placeholder={firstMessagePlaceholder}
                                className="group-chat-input"
                                maxRows={6}
                                minRows={5}
                                maxLength={500}
                                id="first-message"
                            />
                            <span
                                className={
                                    'input-length-indicator' +
                                    (messageLength <= 20 ? ' input-length-indicator-warning' : '')
                                }
                            >
                                {`${messageLength}/500`}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="private-chat-block">
                    <label className="private-icon">
                        <input
                            type="checkbox"
                            className="content-group-check"
                            name="is_private"
                            checked={isPrivate}
                            onChange={this.onPrivateChange}
                        />
                        <span className="chat-private-check-icon content-group-check-icon"></span>
                    </label>
                    <span className="private-chat-text">Make the Group Chat private</span>
                    <Popover
                        placement="bottomLeft"
                        content={
                            <div className="private-chat-help-text">
                                <p>Private Group Chats are only visible to prospects</p>
                                <p>you share the link with</p>
                            </div>
                        }
                        className="private-icon-info"
                        onClick={(e) => e.preventDefault()}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                </div>

                <TagsMultiInput handleGlobalTags={this.handleGlobalTagsSelect} globalTagsItems={tags || []} />

                <SelectAmbassadorsForm
                    ambassadors={ambassadors}
                    areAmbassadorsLoading={areAmbassadorsLoading}
                    initialAssignedUsers={{}}
                    onSearchUpdated={onSearchUpdated}
                    onUserSelectionUpdated={this.handleUserSelectionUpdated}
                />

                <div className="hidden-buttons-block">
                    <button
                        type="submit"
                        className="el-btn create-group-btn"
                        disabled={isCreateButtonDisabled || !selectedUsersNumber || name.length < 3}
                    >
                        Create
                    </button>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'create_chat_group_form',
})(CreateChatGroupForm)
