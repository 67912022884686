export var PermissionEnum;
(function (PermissionEnum) {
    PermissionEnum["Chat"] = "chat";
    PermissionEnum["Content"] = "content";
    PermissionEnum["Faq"] = "faq";
    PermissionEnum["ContentSharing"] = "content_sharing";
    PermissionEnum["Promotion"] = "promotion";
    PermissionEnum["TapFeed"] = "tap_feed";
    PermissionEnum["BrandingStyles"] = "branding_styles";
    PermissionEnum["LiveEvents"] = "live_events";
    PermissionEnum["ListOfGroupUniversities"] = "list_of_group_universities";
    PermissionEnum["Reporting"] = "reporting";
    PermissionEnum["ReportingOverview"] = "reporting_overview";
    PermissionEnum["ReportingActivity"] = "reporting_activity";
    PermissionEnum["ReportingContent"] = "reporting_content";
    PermissionEnum["ReportingFaq"] = "reporting_faq";
    PermissionEnum["ReportingAmbassadors"] = "reporting_ambassadors";
    PermissionEnum["ReportingProspects"] = "reporting_prospects";
    PermissionEnum["ReportingTime"] = "reporting_time";
    PermissionEnum["ReportingTrend"] = "reporting_trend";
    PermissionEnum["Settings"] = "settings";
    PermissionEnum["InviteAdmin"] = "invite_admin";
    PermissionEnum["ViewProspectProfile"] = "view_prospect_profile";
    PermissionEnum["ReportingProspectsGroupDataAllowed"] = "reporting_prospects_group_data_allowed";
    PermissionEnum["Sso"] = "sso";
    PermissionEnum["Community"] = "community";
    PermissionEnum["LiveStream"] = "live_stream";
    PermissionEnum["ExperienceBasedQuestions"] = "experience_based_questions";
    PermissionEnum["SsoEncryption"] = "sso_encryption";
    PermissionEnum["AdditionalDataPhoneVerification"] = "additional_data_phone_verification";
    PermissionEnum["GroupChats"] = "group_chats";
    PermissionEnum["CampaignManagement"] = "campaign_management";
    PermissionEnum["ProspectsAppLogin"] = "prospects_app_login";
    PermissionEnum["Facebook"] = "facebook";
    PermissionEnum["Tiktok"] = "tiktok";
    PermissionEnum["Google"] = "google";
    PermissionEnum["Apple"] = "apple";
    PermissionEnum["DisplayAmbassadorCountryFlag"] = "display_ambassador_country_flag";
    PermissionEnum["SascOnly"] = "sasc_only";
    PermissionEnum["Pls"] = "pls";
    PermissionEnum["IdpCommunity"] = "idp_community";
    PermissionEnum["IdpLiveApp"] = "idp_conversion_chat";
    PermissionEnum["IdpWhatUni"] = "idp_what_uni";
    PermissionEnum["IdpOnly"] = "idp_only";
    PermissionEnum["BuzzOnly"] = "buzz_only";
})(PermissionEnum || (PermissionEnum = {}));
export var LicensesEnum;
(function (LicensesEnum) {
    LicensesEnum["RSS"] = "Share to RSS";
    LicensesEnum["Download"] = "Download content";
    LicensesEnum["Sharing"] = "Sharing";
    LicensesEnum["TapFeed"] = "TAP Feed";
    LicensesEnum["Crm"] = "CRM";
    LicensesEnum["GroupChats"] = "Group Chats";
    LicensesEnum["Promotion"] = "Promotion";
    LicensesEnum["FAQ"] = "FAQ";
    LicensesEnum["Reporting"] = "Reporting";
})(LicensesEnum || (LicensesEnum = {}));
export var ContentGroupTypeEnum;
(function (ContentGroupTypeEnum) {
    ContentGroupTypeEnum["Content"] = "content";
    ContentGroupTypeEnum["Broadcast"] = "broadcast";
})(ContentGroupTypeEnum || (ContentGroupTypeEnum = {}));
export var IdpServiceTypeEnum;
(function (IdpServiceTypeEnum) {
    IdpServiceTypeEnum["IdpConversionChat"] = "idp_conversion_chat";
    IdpServiceTypeEnum["IdpCommunity"] = "idp_community";
})(IdpServiceTypeEnum || (IdpServiceTypeEnum = {}));
