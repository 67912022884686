import API from '../../../api'
import { call, put, select } from 'redux-saga/effects'
import {
    TREND_ANALYZER_CHARTS_SUCCESS,
    TREND_ANALYZER_CHARTS_FAILED,
    TREND_ANALYZER_TABLE_SUCCESS,
    TREND_ANALYZER_TABLE_FAILED,
    TREND_ANALYZER_CSV_SUCCESS,
    TREND_ANALYZER_CSV_FAILED,
} from '../../actions/analytics/trendAnalyzerActions'

const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

export function* trendAnalyzerChartsData({ filter }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        const response = yield call(() => {
            return API.dashboard.trendAnalyzerChartsData(filter, universityIds)
        })

        if (response.success) {
            yield put({
                type: TREND_ANALYZER_CHARTS_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: TREND_ANALYZER_CHARTS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: TREND_ANALYZER_CHARTS_FAILED,
        })
    }
}

export function* trendAnalyzerTableData({ filter }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.trendAnalyzerTableData(filter, universityIds)
        })

        if (response.success) {
            yield put({
                type: TREND_ANALYZER_TABLE_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: TREND_ANALYZER_TABLE_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: TREND_ANALYZER_TABLE_FAILED,
        })
    }
}

export function* trendAnalyzerCsvData({ filter, topicIds }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.trendAnalyzerCsvData(filter, topicIds, universityIds)
        })

        if (response.success) {
            yield put({
                type: TREND_ANALYZER_CSV_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: TREND_ANALYZER_CSV_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: TREND_ANALYZER_CSV_FAILED,
        })
    }
}
