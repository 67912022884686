import { useDispatch } from 'react-redux'
import { Form, Input, Button, Upload, message, Typography, Col } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SamlFormModel from './models/saml-form-model'

import { UploadOutlined } from '@ant-design/icons'

import { uploadMetadata } from '../../../../redux/actions/settings/sso/sso-actions'
import { checkSsoEncryption } from '../../../../common/utils/user-account-helpers'
import { AdditionalParameters } from './additional-sso-parameters'

const { Text } = Typography

const formStyle = {
    display: 'block',
    width: '100%',
    marginTop: '20px',
}

const SamlForm = ({ providerId, providerKey, data, onSave, onReset }) => {
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const [fileList, setFileList] = useState([])
    const [fileUrl, setFileUrl] = useState('')
    const [isCustomFieslsTouched, setIsCustomFieslsTouched] = useState(false)

    const initialValues = useMemo(() => SamlFormModel(data), [data])
    useEffect(() => {
        form.setFieldsValue(SamlFormModel(data))
    }, [providerId, form, data])

    const handleSubmit = useCallback(
        (values) => {
            const data = {
                providerName: values.providerName.trim(),
                metadataUrl: values.metadataUrl.trim() || fileUrl,
                email: values.email.trim(),
                givenName: values.givenName.trim(),
                familyName: values.familyName.trim(),
                isMetadataFileUploaded: !!fileUrl,
                ssoCustomData: null,
            }

            const ssoCustomData = []
            if (values.custom_1_name && values.custom_1_reference)
                ssoCustomData.push({
                    name: values.custom_1_name.trim(),
                    reference: values.custom_1_reference,
                    unique: values.custom_1_unique,
                })
            if (values.custom_2_name && values.custom_2_reference)
                ssoCustomData.push({
                    name: values.custom_2_name.trim(),
                    reference: values.custom_2_reference,
                    unique: values.custom_2_unique,
                })
            if (values.custom_3_name && values.custom_3_reference)
                ssoCustomData.push({
                    name: values.custom_3_name.trim(),
                    reference: values.custom_3_reference,
                    unique: values.custom_3_unique,
                })

            if (ssoCustomData.length > 0) data.ssoCustomData = ssoCustomData

            onSave(data)
        },
        [onSave, fileUrl]
    )

    const onMetadataUpload = useCallback((url) => {
        setFileUrl(url)
    }, [])

    const resetFileList = useCallback(() => {
        setFileUrl('')
        setFileList([])
    }, [])

    const props = {
        beforeUpload: (file) => {
            if (file.type !== 'text/xml') {
                message.error(`${file.name} is not a xml file`)
            }
            setFileList([file])
            return false
        },
        onChange: (info) => {
            if (info.fileList.length > 0) {
                form.validateFields(['metadataUrl'])
                form.setFieldsValue({ metadataUrl: '' })
                const formData = new window.FormData()
                formData.append('file', new window.Blob([info.file], { type: 'text/xml' }))
                dispatch(uploadMetadata(formData, onMetadataUpload))
            }
        },
        fileList,
    }

    const isSubmitDisabled = () => {
        const { isFieldsTouched, getFieldsError } = form
        return isCustomFieslsTouched
            ? false
            : !isFieldsTouched() || getFieldsError().some(({ errors }) => errors.length)
    }

    return (
        <Form style={formStyle} form={form} layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
            <Form.Item
                label={'Organisation name'}
                name="providerName"
                className="sso-field-item"
                rules={[
                    {
                        required: true,
                        message: 'Field is required',
                    },
                ]}
            >
                <Input placeholder="Organisation Name" />
            </Form.Item>
            <Form.Item
                label={'Metadata Url (where your IDP metadata is hosted)'}
                name="metadataUrl"
                className="sso-field-item"
                rules={[
                    !form.getFieldValue('metadataUrl') &&
                        fileList.length === 0 && {
                            required: true,
                            message:
                                'Input Metadata Url' + (providerKey !== 'azure' ? ' or upload Metadata XML file' : ''),
                        },
                ]}
            >
                <Input
                    placeholder="https://login.microsoftonline.com/dd5b9354-5ccb-499c-8d89-bbc3551e12a5/federationmetadata/2007-06/federationmetadata.xml?appid=42des5b0-41bf-44f2-9f55-7c7b9e124d57"
                    onBlur={resetFileList}
                />
            </Form.Item>
            {providerKey !== 'azure' && (
                <>
                    <p style={{ margin: '-8px 10px 10px' }}>OR</p>
                    <Form.Item
                        label={'Upload Metadata XML (upload IDP metadata to host on our end)'}
                        name="uploadXml"
                        className="sso-field-item"
                    >
                        <Col style={{ display: 'flex', flexDirection: 'column' }}>
                            <Upload {...props} name="file" accept=".xml" showUploadList={false}>
                                <Button icon={<UploadOutlined />}>Select XML File</Button>
                            </Upload>
                            <Text type="success">
                                {((data?.settings.provider_details.isMetadataFileUploaded &&
                                    !form.getFieldValue('metadataUrl')) ||
                                    fileList.length > 0) &&
                                    'The file was uploaded'}
                            </Text>
                        </Col>
                    </Form.Item>
                </>
            )}
            <Form.Item
                label="This is attribute's reference to email and by default is 'email'. You can add yours here or create new in your provider's dashboard"
                name="email"
                className="sso-field-item"
            >
                <Input placeholder="email" />
            </Form.Item>
            <Form.Item
                label="This is attribute's reference to email and by default is 'given_name'. You can add yours here or create new in your provider's dashboard"
                name="givenName"
                className="sso-field-item"
            >
                <Input placeholder="given_name" />
            </Form.Item>
            <Form.Item
                label="This is attribute's reference to email and by default is 'family_name'. You can add yours here or create new in your provider's dashboard"
                name="familyName"
                className="sso-field-item"
            >
                <Input placeholder="family_name" />
            </Form.Item>
            {checkSsoEncryption && (
                <AdditionalParameters form={form} initialValues={initialValues} setTouched={setIsCustomFieslsTouched} />
            )}
            <div className="sso-field-row">
                <Form.Item>
                    <Button
                        type="primary"
                        danger
                        className="el-btn"
                        style={{ height: '40px', marginRight: '20px' }}
                        onClick={onReset}
                        disabled={!data}
                    >
                        Deactivate
                    </Button>
                </Form.Item>
                <Form.Item shouldUpdate>
                    {({ isFieldsTouched, getFieldsError }) => {
                        return (
                            <Button
                                htmlType="submit"
                                className="el-btn"
                                style={{ height: '40px' }}
                                disabled={isSubmitDisabled()}
                            >
                                Activate SSO
                            </Button>
                        )
                    }}
                </Form.Item>
            </div>
        </Form>
    )
}

export default SamlForm
