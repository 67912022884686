import { HttpService } from 'src/app/system/services/HttpService';
export default {
    getDestinationOptions: function (data) {
        return HttpService.get("/dashboardGroup/content/destinations", data);
    },
    getContentGroups: function (data) {
        return HttpService.get("/dashboardGroup/content/contentGroups", data);
    },
    createContentGroup: function (data) {
        return HttpService.post("/dashboardGroup/content/contentGroups/create", data);
    },
    createBroadcastChannal: function (data) {
        return HttpService.post("/dashboardGroup/content/broadcastChannels/create", data);
    },
    getIdpContentGroupAmbassadors: function (data) {
        return HttpService.post("/dashboardGroup/content/ambassadors", data);
    },
    getIdpAmbassadorsSubjects: function () {
        return HttpService.get("/dashboardGroup/content/ambassadors/subjects");
    },
    getIdpAmbassadorsUniversities: function () {
        return HttpService.get("/dashboardGroup/content/ambassadors/universities");
    },
};
