import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setOTPVerificationToggle } from '../../redux/actions/account/account-actions'
import { getUniversityInfo } from '../../redux/selectors'

const OTPVerificationToggle = () => {
    const dispatch = useDispatch()

    const { isEnabledSignUpPhoneVerification } = useSelector(getUniversityInfo)

    const handleToggleOTPVerification = useCallback(
        (event) => {
            dispatch(setOTPVerificationToggle({ isAllowed: event.target.checked }))
        },
        [dispatch]
    )
    return (
        <div className="optional-form" style={{ marginTop: '1.5rem' }}>
            <div className="optional-form-row">
                <div className="optional-form-row-head">
                    <div className="optional-title-block">
                        <h4 className="settings-name">Phone verification</h4>
                    </div>

                    <label className="slideOne js-holder">
                        <input
                            type="checkbox"
                            name="verification"
                            checked={isEnabledSignUpPhoneVerification}
                            onChange={handleToggleOTPVerification}
                        />
                        <span className="slideOne-icon"></span>
                    </label>
                </div>
                <div style={{ fontSize: '1.3rem' }}>
                    When enabled, users will be asked to provide their phone number as a part of the sign-up process.
                    Once the phone number is provided a user will be sent an OTP password. If OTP password was not
                    provided, a user won't be able to sign-up
                </div>
            </div>
        </div>
    )
}

export default OTPVerificationToggle
