import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Checkbox, Popover, Table, Input, message, Button, Space } from 'antd'

import 'react-datepicker/dist/react-datepicker.css'
import store from '../../redux/store'

import {
    CONVERT_SPECIALIZATIONS,
    SAVE_OPTIONAL_QUESTIONS,
    CONVERT_CUSTOM_QUESTIONS_ANSWERS,
    GET_OPTIONAL_QUESTIONS_SUCCESS,
    HANDLE_CUSTOM_OPTIONAL_QUESTION,
} from '../../redux/actions/chat/optionalQuestions'

import csvExample from '../../../assets/csv/courses.csv'
import { connect } from 'react-redux'
import tipIcon from '../../../assets/img/setup/tip.png'
import { ReactComponent as TrashIcon } from '../../../assets/svg/icons/trash.svg'

import { InfoCircleOutlined } from '@ant-design/icons'
import { OPEN_ACTION_MODAL, CLOSE_ACTION_MODAL } from '../../redux/actions/components/componentsActions'

class OptionalQuestionsForm extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            questions: [],
            specializationsLoading: false,
            customQuestionAnswerLoading: false,
            questionIndex: null,
            errors: {
                0: null,
                1: null,
                2: null,
                3: null,
                4: null,
                5: null,
                6: null,
                7: null,
                8: null,
                9: null,
                10: null,
                11: null,
                12: null,
            },
            customQuestions: props.questions.filter(
                (item) => item.key === 'custom' && item.order !== 100 && item.question && item.question.length > 0
            ),
        }
        this.handleChangeQuestions = this.handleChangeQuestions.bind(this)
        this.addAnswer = this.addAnswer.bind(this)
        this.validate = this.validate.bind(this)
        this.handleSpecializationChange = this.handleSpecializationChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.removeAnswer = this.removeAnswer.bind(this)
        this.handleChangeAnswers = this.handleChangeAnswers.bind(this)
        this.handleCustomChange = this.handleCustomChange.bind(this)
        this.setMandatoryChecked = this.setMandatoryChecked.bind(this)
        this.addCustomQuestion = this.addCustomQuestion.bind(this)
        this.removeCustomQuestion = this.removeCustomQuestion.bind(this)
    }

    componentDidUpdate() {
        if (!this.props.questionsLoading && this.state.questions !== this.props.questions) {
            this.setState({ questions: this.props.questions }, () => {
                this.validate()
            })
        }
    }

    handleChangeQuestions(e, questionIndex) {
        const { questions, setupWizard, changeEditedForm } = this.props
        const currentTarget = e.currentTarget

        questions[questionIndex].id = Math.random() + +questions[questionIndex].order
        questions[questionIndex].is_active = currentTarget.checked

        this.updateOptionalQuestions(questions)
        if (!setupWizard) {
            changeEditedForm(true)
        }
        this.forceUpdate()
    }

    handleChangeAnswers(event, index, questionIndex) {
        const { questions, setupWizard, changeEditedForm } = this.props

        questions[questionIndex].predefined_answers[index] = { name: event.target.value }
        questions[questionIndex].id = Math.random() + +questions[questionIndex].order

        this.updateOptionalQuestions(questions)
        if (!setupWizard) {
            changeEditedForm(true)
        }
        this.forceUpdate()
    }

    validate() {
        const { errors } = this.state
        const { questions } = this.props
        let next = true

        for (const error in errors) {
            errors[error] = { question: null, predefined_answers: [] }
        }

        questions.forEach((item, index) => {
            if (item.key === 'custom' || item.key === 'start_study') {
                if (item.key === 'custom' && item.is_active && item.question.trim() === '') {
                    errors[index].question = 'Please add your question'
                    next = false
                }

                if (!errors[index].question) {
                    questions.forEach((questionItem, questionKey) => {
                        if (
                            item.question.trim() !== '' &&
                            questionItem.question.trim() === item.question.trim() &&
                            index !== questionKey
                        ) {
                            errors[questionKey].question = 'This question already exists'
                            next = false
                        }
                    })
                }
                let numberFilledAnswers = 0
                if (item.predefined_answers.length > 0) {
                    item.predefined_answers.forEach((answer, key) => {
                        if (answer.name.trim() !== '') {
                            numberFilledAnswers += 1
                        }
                        if (!errors[index].predefined_answers[key]) {
                            item.predefined_answers.forEach((answerItem, answerKey) => {
                                if (answerItem.name.trim() === answer.name.trim() && key !== answerKey) {
                                    errors[index].predefined_answers[answerKey] = 'This answer already exists'
                                    next = false
                                }
                            })
                        }
                    })
                }

                if (
                    (item.is_active || numberFilledAnswers === 1) &&
                    numberFilledAnswers < 2 &&
                    errors[index].predefined_answers.length === 0
                ) {
                    if (numberFilledAnswers === 1) {
                        errors[index].predefined_answers[item.predefined_answers.length - 1] =
                            'Please add at least two answers'
                        next = false
                    } else if (!errors[index].question && item.key !== 'custom') {
                        errors[index].question = 'Please add at least two answers'
                        next = false
                    }
                }
            }
        })

        this.setState({ errors })
        this.forceUpdate()
        return next
    }

    handleSubmit() {
        const { account, questions } = this.props
        if (!this.validate()) {
            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Please make sure all fields are completed',
                },
            })
            return
        }
        const questionsToSave = []
        for (const item of questions) {
            if (item.key !== 'custom' || (item.key === 'custom' && item.question.trim() !== '')) {
                const filledAnswers = []
                for (const answer of item.predefined_answers) {
                    if (answer.name.trim() !== '') {
                        filledAnswers.push(answer)
                    }
                }
                item.predefined_answers = filledAnswers
                questionsToSave.push(item)
            }
        }

        store.dispatch({
            type: SAVE_OPTIONAL_QUESTIONS,
            payload: {
                university_id: account.account_info.university_id,
                questions: questionsToSave,
            },
        })

        this.props.changeEditedForm(false)
    }

    addAnswer(questionIndex) {
        const { questions, setupWizard, changeEditedForm } = this.props
        if (!questions[questionIndex].is_active) {
            return
        }
        questions[questionIndex].predefined_answers.push({ name: '' })
        questions[questionIndex].id = Math.random() + +questions[questionIndex].order
        if (!this.validate()) return

        this.updateOptionalQuestions(questions)
        if (!setupWizard) {
            changeEditedForm(true)
        }
        this.forceUpdate()
    }

    removeAnswer(index, questionIndex) {
        const { questions, setupWizard, changeEditedForm } = this.props
        for (const question in questions) {
            if (+question === +questionIndex) {
                const newPredefinedAnswers = []
                questions[question].predefined_answers.forEach((answer, answerIndex) => {
                    if (answerIndex !== +index) {
                        newPredefinedAnswers.push(answer)
                    }
                })
                questions[question].predefined_answers = newPredefinedAnswers
                questions[question].id = Math.random() + +questions[question].order
                this.validate()
            }
        }

        this.updateOptionalQuestions(questions)
        if (!setupWizard) {
            changeEditedForm(true)
        }
        this.forceUpdate()
    }

    renderAnswers(data, questionIndex) {
        const { errors } = this.state
        if (!data || data.length === 0) return

        return data.map((item, index) => {
            return (
                <label key={questionIndex + '-custom-answers-' + index} className="optional-custom-label">
                    <span className="optional-date-label-text">Answer {index + 1}</span>
                    <input
                        name={questionIndex + '-custom-answers-' + index}
                        className="optional-custom-input"
                        value={item.name}
                        data-index={index}
                        onChange={(e) => this.handleChangeAnswers(e, index, questionIndex)}
                    />
                    <div onClick={() => this.removeAnswer(index, questionIndex)} className="remove-custom-answer">
                        <TrashIcon />
                        <span>Delete answer</span>
                    </div>
                    {errors[questionIndex] &&
                        errors[questionIndex].predefined_answers &&
                        errors[questionIndex].predefined_answers[index] && (
                            <div className="error-text">{errors[questionIndex].predefined_answers[index]}</div>
                        )}
                </label>
            )
        })
    }

    async handleSpecializationChange(fileInputElement) {
        const { questions, changeEditedForm } = this.props
        try {
            this.setState({ specializationsLoading: true })
            const data = new window.FormData()
            data.append('file', new window.Blob([fileInputElement.target.files[0]], { type: 'text/csv' }))

            const onSuccess = (response) => {
                const specializations = []
                if (response.universitySpecializations) {
                    for (const key in response.universitySpecializations) {
                        const item = response.universitySpecializations[key]
                        let isDuplicate = false
                        specializations.forEach((spec) => {
                            if (item.name === spec.name) {
                                isDuplicate = true
                            }
                        })
                        if (!isDuplicate) {
                            specializations.push({ name: item.name })
                        }
                    }
                }
                for (const key in questions) {
                    if (questions[key].key === 'specialization') {
                        questions[key].predefined_answers = specializations
                        questions[key].id = Math.random() + +questions[key].order
                    }
                }

                this.setState({
                    specializationsLoading: false,
                })

                this.updateOptionalQuestions(questions)

                changeEditedForm(true)
                this.forceUpdate()
            }

            store.dispatch({
                type: CONVERT_SPECIALIZATIONS,
                payload: data,
                onSuccess: onSuccess,
            })
        } catch {
            this.setState({ specializationsLoading: false })
        }
    }

    async handleCustomQuestionAnswerChange(fileInputElement) {
        const { questions, changeEditedForm } = this.props
        try {
            this.setState({ customQuestionAnswerLoading: true })

            const answers = []
            const data = new window.FormData()

            data.append('file', new window.Blob([fileInputElement.target.files[0]], { type: 'text/csv' }))

            const reader = new FileReader()
            const readFile = (event) => {
                const csvText = event.target.result

                const rows = csvText.split('\n').filter((item) => {
                    if (item.length > 0) {
                        answers.push({ name: item })
                        return item
                    }
                })
                if (!csvText || rows.every((currentValue) => currentValue.includes(','))) {
                    message.error('File upload failed. Please, make sure that it meets the requirements')
                }
            }
            reader.addEventListener('load', readFile)
            reader.readAsText(fileInputElement.target.files[0])

            const onSuccess = (response) => {
                if (response && response.length > 0) {
                    const answers = response.map((item) => {
                        return { name: item }
                    })
                    if (answers.length > 100) {
                        message.error('You can save up to 100 predefined answers for custom questions.')
                    } else {
                        const { questionIndex } = this.state
                        if (questionIndex >= 7) {
                            questions[questionIndex].predefined_answers = answers
                            questions[questionIndex].isAnswersUploaded = true
                            questions[questionIndex].id = Math.random() + +questions[questionIndex].order
                        }
                        this.setState({
                            customQuestionAnswerLoading: false,
                        })

                        this.updateOptionalQuestions(questions)

                        changeEditedForm(true)
                        this.forceUpdate()
                    }
                }
            }
            store.dispatch({
                type: CONVERT_CUSTOM_QUESTIONS_ANSWERS,
                payload: data,
                onSuccess: onSuccess,
            })
        } catch {
            this.setState({ customQuestionAnswerLoading: false })
        } finally {
            store.dispatch({
                type: CLOSE_ACTION_MODAL,
            })
        }
    }

    handleCustomChange(event, index) {
        const { questions } = this.props
        questions[index].question = event.target.value
        questions[index].id = Math.random() + +questions[index].order
        this.updateOptionalQuestions(questions)
        this.forceUpdate()
    }

    setMandatoryChecked(checked, index) {
        const { questions, setupWizard, changeEditedForm } = this.props
        questions[index].is_required = checked
        questions[index].id = Math.random() + +questions[index].order

        this.updateOptionalQuestions(questions)
        if (!setupWizard) {
            changeEditedForm(true)
        }
        this.forceUpdate()
    }

    handleAnswersUpload(questionIndex) {
        const { questions } = this.props
        const dataSource = [
            {
                key: '1',
                1: 1,
                2: 'Green',
            },
            {
                key: '2',
                1: 2,
                2: 'Yellow',
            },
            {
                key: '3',
                1: 3,
                2: 'Blue',
            },
            {
                key: '4',
                1: 4,
                2: 'Red',
            },
        ]

        const columns = [
            {
                title: '',
                dataIndex: '1',
                width: 5,
                align: 'center',
            },
            {
                title: 'A',
                dataIndex: '2',
                width: 350,
            },
        ]
        return (
            <button
                type="button"
                onClick={() => {
                    this.setState({ questionIndex })
                    store.dispatch({
                        type: OPEN_ACTION_MODAL,
                        payload: {
                            children: (
                                <div className="general-popup chat-mute-success-popup">
                                    <h2 className="modal-body">Upload multiple answers</h2>
                                    <div className="jc-c upl-bm">
                                        <ul className="ad-uma-modal">
                                            <li>Upload a csv file with answer per line</li>
                                            <li>Don’t create extra titles above the answers</li>
                                            <li>Check that the number of answers matches the number of lines</li>
                                        </ul>
                                        <Table
                                            className="custom-column-upl-ans-modal"
                                            dataSource={dataSource}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </div>

                                    <div>
                                        <label className="jc-c upload-file-label ant-btn ant-btn-primary ant-btn-lg ant-btn-block">
                                            <Input
                                                type="file"
                                                className="upload-file-input"
                                                onChange={this.handleCustomQuestionAnswerChange.bind(this)}
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            />
                                            Upload
                                        </label>
                                    </div>
                                </div>
                            ),
                        },
                    })
                }}
                className={
                    questions[questionIndex].predefined_answers.length >= 100
                        ? 'upload-custom-data-btn'
                        : 'upload-optional-dates-btn'
                }
            >
                {questions[questionIndex].predefined_answers.length > 0
                    ? 'Remove and upload again'
                    : 'Upload multiple answers via CSV'}
            </button>
        )
    }

    addCustomQuestion() {
        const { customQuestions } = this.state
        const { questions } = this.props
        const newCustomQuestionOrder = customQuestions.length + 101
        this.setState(
            {
                customQuestions: [...customQuestions, { order: newCustomQuestionOrder, key: 'custom' }],
            },
            () => {
                store.dispatch({
                    type: GET_OPTIONAL_QUESTIONS_SUCCESS,
                    payload: {
                        questions: [...questions, { order: newCustomQuestionOrder, key: 'custom' }],
                    },
                })
            }
        )
    }

    removeCustomQuestion(order) {
        const { customQuestions } = this.state
        const { questions } = this.props
        const newQuestionsSave = questions.filter((question) => question.order !== order)
        const customQuestionsArray = customQuestions.filter((question) => question.order !== order)
        this.setState({ customQuestions: customQuestionsArray }, () => {
            this.updateOptionalQuestions(newQuestionsSave)
            this.forceUpdate()
        })
    }

    updateOptionalQuestions(questions) {
        store.dispatch({
            type: HANDLE_CUSTOM_OPTIONAL_QUESTION,
            payload: {
                questions,
            },
        })
    }

    render() {
        const { specializationsLoading, errors, customQuestions } = this.state
        const { account, setupWizard, questions } = this.props

        if (!questions || questions.length === 0) return null
        const answers = {}
        const answersAddBtn = {}
        let specializations
        questions.forEach((question, index) => {
            if (question.key === 'start_study') {
                if (question.predefined_answers.length < 5) {
                    answersAddBtn[index] = (
                        <button
                            type="button"
                            onClick={() => this.addAnswer(index, question.key)}
                            className="add-optional-dates-btn"
                        >
                            + Add answer
                        </button>
                    )
                }
                if (question.predefined_answers.length > 0) {
                    answers[index] = this.renderAnswers(question.predefined_answers, index)
                }
            } else if (question.key === 'custom') {
                if (question.predefined_answers.length < 100) {
                    answersAddBtn[index] = (
                        <button
                            type="button"
                            onClick={() => this.addAnswer(index, question.key)}
                            className="add-optional-dates-btn"
                        >
                            + Add answer
                        </button>
                    )
                }
                if (question.predefined_answers.length > 0) {
                    answers[index] = this.renderAnswers(question.predefined_answers, index)
                }
            } else if (question.key === 'specialization' && question.predefined_answers.length > 0) {
                specializations = question.predefined_answers.map((item, index) => {
                    return (
                        <span key={index} className="optional-course-text">
                            {item.name}
                        </span>
                    )
                })
            }
        })

        if (specializationsLoading) {
            specializations = (
                <div className="specializations-load">
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#000000" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            )
        }

        const optionalFormRow = (index, title, template) => {
            return (
                <div key={index} className={'optional-form-row ' + (questions[index].is_active ? '' : 'disabled')}>
                    <div className="optional-form-row-head">
                        <div className="optional-title-block">
                            <h4 className="settings-name">{title}</h4>
                            {(index === 7 || index === 8) && (
                                <Popover
                                    placement="topLeft"
                                    content={
                                        <div className="optional-help-text">
                                            <p>Write your custom optional question in the box below. Please also add</p>
                                            <p>the answers you would like your prospective students to choose from.</p>
                                        </div>
                                    }
                                    className="optional-icon-info"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            )}
                        </div>

                        <label className="slideOne js-holder">
                            <input
                                key={questions[index].id}
                                type="checkbox"
                                name="checkbox"
                                defaultChecked={questions[index] && questions[index].is_active}
                                onChange={(e) => this.handleChangeQuestions(e, index)}
                            />
                            <span className="slideOne-icon"></span>
                        </label>
                        {questions[index].key === 'custom' && questions[index].order !== 100 && (
                            <div
                                onClick={() => this.removeCustomQuestion(questions[index].order)}
                                className="remove-custom-question"
                            >
                                <TrashIcon />
                                <span>Delete question</span>
                            </div>
                        )}
                    </div>

                    {questions[index].key !== 'custom' && (
                        <div className="optional-input-container">
                            <input
                                type="text"
                                disabled={true}
                                value={questions[index] && questions[index].question}
                                placeholder=""
                                className="optional-input"
                                name="question-one"
                            />
                            {errors[index] && errors[index].question && (
                                <div className="error-text">{errors[index].question}</div>
                            )}
                            <Checkbox
                                key={questions[index].id}
                                className="optional-input-checkbox"
                                disabled={!questions[index].is_active}
                                checked={questions[index].is_required}
                                onChange={(e) => this.setMandatoryChecked(e.target.checked, index)}
                            >
                                <span className="optional-input-checkbox-label">Make it mandatory</span>
                            </Checkbox>
                        </div>
                    )}
                    {questions[index].key === 'custom' && (
                        <div className="optional-custom-text-head">
                            Write your own custom question for your prospects in the box below.
                        </div>
                    )}
                    {questions[index].key === 'custom' && index === 7 && (
                        <div className="optional-input-container">
                            <input
                                type="text"
                                disabled={false}
                                value={questions[index] && questions[index].question}
                                placeholder={questions[index].question === '' ? 'Enter your question' : ''}
                                className="optional-input"
                                name="question-seven"
                                onChange={(e) => this.handleCustomChange(e, index)}
                            />

                            {errors[index] && errors[index].question && (
                                <div className="error-text">{errors[index].question}</div>
                            )}
                            <Checkbox
                                key={questions[index].id}
                                className="optional-input-checkbox"
                                disabled={!questions[index].is_active}
                                checked={questions[index].is_required}
                                onChange={(e) => this.setMandatoryChecked(e.target.checked, index)}
                            >
                                <span className="optional-input-checkbox-label">Make it mandatory</span>
                            </Checkbox>
                        </div>
                    )}
                    {questions[index].key === 'custom' && index >= 8 && (
                        <div className="optional-input-container">
                            <input
                                type="text"
                                disabled={false}
                                value={questions[index] && questions[index].question}
                                placeholder={questions[index].question === '' ? 'Enter your question' : ''}
                                className="optional-input"
                                name="question-eight"
                                onChange={(e) => this.handleCustomChange(e, index)}
                            />

                            {errors[index] && errors[index].question && (
                                <div className="error-text">{errors[index].question}</div>
                            )}
                            <Checkbox
                                key={questions[index].id}
                                className="optional-input-checkbox"
                                disabled={!questions[index].is_active}
                                checked={questions[index].is_required}
                                onChange={(e) => this.setMandatoryChecked(e.target.checked, index)}
                            >
                                <span className="optional-input-checkbox-label">Make it mandatory</span>
                            </Checkbox>
                        </div>
                    )}
                    {template}
                </div>
            )
        }

        const renderCustomQuestions = () => {
            return customQuestions.map((item, index) => {
                if (!questions[8 + index]) {
                    return null
                }
                return optionalFormRow(
                    8 + index,
                    `Custom Question ${index + 2}`,
                    <div className="optional-custom-answers-row">
                        {answers[8 + index] || ''}
                        {answersAddBtn[8 + index] || ''}
                        {Reflect.apply(this.handleAnswersUpload, this, [8 + index])}
                        {questions[8 + index].predefined_answers.length > 0 ? (
                            <span
                                className={
                                    questions[8 + index].predefined_answers.length >= 100
                                        ? 'optional-custom-data-label-text'
                                        : 'optional-date-label-text'
                                }
                            >
                                {questions[8 + index].predefined_answers.length} answers added
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                )
            })
        }

        return (
            <div>
                <div className="optional-form">
                    {optionalFormRow(0, 'Question 1')}
                    {optionalFormRow(1, 'Question 2')}
                    {optionalFormRow(2, 'Question 3')}
                    {optionalFormRow(
                        3,
                        'Question 4',
                        <div className="optional-custom-row">
                            {answers[3] || ''}
                            {answersAddBtn[3] || ''}
                        </div>
                    )}
                    {optionalFormRow(
                        4,
                        'Question 5',
                        <>
                            <div className="upload-file-block">
                                <label className="upload-file-label">
                                    <input
                                        type="file"
                                        className="upload-file-input"
                                        onChange={this.handleSpecializationChange}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                    <span className="upload-file-btn">Upload courses</span>
                                </label>
                                <a href={csvExample}>Download CSV template</a>
                            </div>
                            <span className="optional-date-label-text">
                                {questions[4].predefined_answers.length} courses uploaded
                            </span>
                            <div className="optional-courses-list">{specializations}</div>
                        </>
                    )}
                    {optionalFormRow(5, 'Question 6')}
                    {optionalFormRow(6, 'Question 7')}
                    {questions[7] &&
                        optionalFormRow(
                            7,
                            'Custom Question 1',
                            <div className="optional-custom-answers-row">
                                {answers[7] || ''}
                                {answersAddBtn[7] || ''}
                                {Reflect.apply(this.handleAnswersUpload, this, [7])}
                                {questions[7].predefined_answers.length > 0 ? (
                                    <span
                                        className={
                                            questions[7].predefined_answers.length >= 100
                                                ? 'optional-custom-data-label-text'
                                                : 'optional-date-label-text'
                                        }
                                    >
                                        {questions[7].predefined_answers.length} answers added
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        )}
                    {renderCustomQuestions()}
                    <Space>
                        <Button
                            type="primary"
                            size="large"
                            onClick={this.addCustomQuestion}
                            disabled={customQuestions.length > 3}
                        >
                            Add one more custom question
                        </Button>
                    </Space>

                    <div className="optional-custom-text-bottom">
                        <div className="tip-block tap-tip-block">
                            <img src={tipIcon} alt="tip" />
                            <div className="optional-precaution-text">
                                Be aware that the answer will be collected in a written text format and if you’re asking
                                for
                                <a
                                    href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/key-definitions/what-is-personal-data/"
                                    className="person-info-link"
                                    target="__blank"
                                >
                                    {' '}
                                    Personally Identifiable Information
                                </a>
                                , you should double check this is covered in your{' '}
                                <a
                                    href={
                                        account.account_info.university.privacy_policy
                                            ? account.account_info.university.privacy_policy
                                            : ''
                                    }
                                    target="__blank"
                                >
                                    Privacy Policy.
                                </a>
                            </div>
                        </div>
                    </div>
                    {!setupWizard && (
                        <button type="submit" onClick={this.handleSubmit} className="el-btn">
                            Save
                        </button>
                    )}
                </div>
            </div>
        )
    }
}

export default reduxForm({
    form: 'optional_questions_form',
})(
    connect((state) => {
        return {
            account: state.account,
        }
    })(OptionalQuestionsForm)
)
