import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

import { Auth0Client } from '@auth0/auth0-spa-js'

class SsoRedirectionPage extends PureComponent {
    constructor(props) {
        super(props)

        const query = new URLSearchParams(props.location.search)
        const code = query.get('code')
        const ssoClientId = query.get('ssoClientId')
        let ssoDomain = query.get('ssoDomain')
        let auth0
        if (ssoClientId && ssoDomain) {
            ssoDomain = ssoDomain.replace(/\/$/, '')
            auth0 = new Auth0Client({
                domain: ssoDomain.replace(/https:\/\//, ''),
                client_id: ssoClientId, // in v2 different clientId
                audience: ssoDomain + '/api/v2/',
                scope: 'read:records',
            })
        }
        if (code) {
            if (ssoClientId && ssoDomain) {
                const getToken = async () => {
                    await auth0.handleRedirectCallback()
                    // const user = await auth0.getUser();
                    const token = await auth0.getTokenSilently({
                        audience: ssoDomain + '/api/v2/',
                        scope: 'read:records',
                        responseType: 'token id_token email given_name family_name',
                    })
                    window.localStorage.setItem('ssoCode', token)
                    window.close()
                }
                getToken()
            } else {
                window.localStorage.setItem('ssoCode', query.get('code'))
                window.close()
            }
        } else if (ssoClientId && ssoDomain) {
            auth0.loginWithRedirect({
                redirect_uri:
                    window.location.origin +
                    '/account/sign-in-redirect?ssoClientId=' +
                    ssoClientId +
                    '&ssoDomain=' +
                    ssoDomain,
            })
        }
    }

    render() {
        return (
            <div id="page-wrapper" className="page-wrapper unregistered-page">
                <div id="page-body" className="page-body js-index"></div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(withRouter(SsoRedirectionPage))
