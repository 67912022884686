import API from '../../../api'
import { call, put, select } from 'redux-saga/effects'
import { GET_OPTIONAL_QUESTIONS_SUCCESS, GET_OPTIONAL_QUESTIONS_FAILED } from '../../actions/chat/optionalQuestions'

import { OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* getOptionalQuestions(action) {
    try {
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => API.chat.getOptionalQuestions(universityId))

        yield response.success
            ? put({
                  type: GET_OPTIONAL_QUESTIONS_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: GET_OPTIONAL_QUESTIONS_FAILED,
              })
    } catch {
        yield put({
            type: GET_OPTIONAL_QUESTIONS_FAILED,
        })
    }
}
/*
export function * getOptionalQuestionsTags(action) {
    try {
        const universityId = yield select(getCurrentGroupUniversityId);

        const response = yield call(() => API.chat.getOptionalQuestionsTags(universityId));

        if (response.success) {
            yield put({
                type: GET_OPTIONAL_QUESTIONS_TAGS_SUCCESS,
                payload: response.data
            });

            yield put({
                type: GET_OPTIONAL_QUESTIONS_SPECL_REQUESTED,
                payload: {}
            });
        } else {
            yield put({
                type: GET_OPTIONAL_QUESTIONS_TAGS_FAILED
            });
        }
    } catch (e) {
        yield put({
            type: GET_OPTIONAL_QUESTIONS_TAGS_FAILED
        });
    }
}

export function * getOptionalQuestionsSpecialization(action) {
    try {
        const universityId = yield select(getCurrentGroupUniversityId);

        const response = yield call(() => API.chat.getOptionalQuestionsSpecialization(universityId));

        if (response.success) {
            yield put({
                type: GET_OPTIONAL_QUESTIONS_SPECL_SUCCESS,
                payload: response.data
            });
        } else {
            yield put({
                type: GET_OPTIONAL_QUESTIONS_SPECL_FAILED
            });
        }
    } catch (e) {
        yield put({
            type: GET_OPTIONAL_QUESTIONS_SPECL_FAILED
        });
    }
}
*/
export function* saveOptionalQuestions(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        const data = {
            questions: payload.questions,
        }
        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            data.universityId = currentGroupUniversityId || payload.university_id
        }
        const response = yield call(() => API.chat.saveOptionalQuestions(data))

        if (response && response.success) {
            if (!payload.noModal) {
                yield put({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: 'Your additional data has been saved',
                    },
                })
            }
            if (action.onFinish) action.onFinish()
        } else {
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Error. Please contact support using the orange button in the bottom-right of the window',
                },
            })
        }
    } catch {
        /* yield put({
            type: GET_OPTIONAL_QUESTIONS_SPECL_FAILED
        }); */
        if (action.onFinish) action.onFinish()
    }
}

export function* convertSpecializations(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
        if (isGroupAdmin && currentGroupUniversityId) {
            payload.append('universityId', currentGroupUniversityId)
        }
        const response = yield call(() => API.chat.convertSpecializations(payload))

        if (response.success) {
            action.onSuccess(response.data)
        }
    } catch {}
}
export function* convertCustomQuestionAnswers(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
        if (isGroupAdmin && currentGroupUniversityId) {
            payload.append('universityId', currentGroupUniversityId)
        }
        const response = yield call(() => API.chat.parseCsv(payload))

        if (response.success) {
            action.onSuccess(response.data)
        }
    } catch {}
}
