import { Input } from 'antd'
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { endOfDay, isBefore } from 'date-fns'

import SelectAmbassadorsForm from '../../../../../../components/SelectAmbassadorsForm'
import DatePicker from '../../../../../../components/date-picker/date-picker'
import TagsMultiInput from '../../../../../../components/TagsMultiInput'
import { parseDateFromServer } from '../../../../../../common/utils/date-time-utils'

function readURL(file) {
    var reader = new window.FileReader()
    reader.readAsDataURL(file)

    reader.addEventListener('load', function (e) {
        const element = document.querySelector('#content-group-prview')
        element.setAttribute('src', e.target.result)
        element.classList.add('visible')
    })
}

const renderField = ({
    input,
    label,
    id,
    accept,
    value,
    handleChange,
    placeholder,
    type,
    className,
    maxLength,
    meta: { touched, error, warning },
}) => (
    <label className="ui-kit-label">
        <input
            {...input}
            className={className}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            accept={accept}
            id={id}
            maxLength={maxLength}
        />
        {touched &&
            ((error && <span className={'error ' + type}>{error}</span>) || (warning && <span>{warning}</span>))}
    </label>
)

const renderTextareaField = ({
    placeholder,
    type,
    id,
    className,
    minRows,
    maxRows,
    maxLength,
    handleChange,
    textLength,
    defaultValue,
}) => (
    <>
        <Input.TextArea
            rows={minRows}
            maxrows={maxRows}
            id={id}
            type={type}
            placeholder={placeholder}
            maxLength={maxLength}
            className={className}
            onChange={handleChange}
            defaultValue={defaultValue}
        />
        <span className={'panel-text-counter panel-text-counter' + (textLength < 10 ? '-invalid' : '-valid')}>
            {textLength}/250
        </span>
    </>
)

const renderDatePicker = ({ handleChange, className, defaultValue, onOpenChange }) => (
    <DatePicker
        onChange={handleChange}
        className={className}
        defaultValue={defaultValue}
        onOpenChange={onOpenChange}
        disabledDate={(currentDate) => {
            const today = new Date()
            return currentDate && isBefore(currentDate, endOfDay(today.setDate(today.getDate() - 1)))
        }}
    />
)
class SettingsContentGroupForm extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            name: props.contentGroup.name,
            description: props.contentGroup.description ? props.contentGroup.description : '',
            avatar: [],
            globalTagsIds: [],
            // To pass data to form submit:
            selectedUsersNumber: 0,
            assignedUsers: {},
            contentGroupEventDate: props.scheduledDay ? props.scheduledDay : '',
        }

        this.getInitialAssignedUsers = this.getInitialAssignedUsers.bind(this)
        this.handleContentGroupNameChange = this.handleContentGroupNameChange.bind(this)
        this.handleAvatarChange = this.handleAvatarChange.bind(this)
        this.handleGlobalTagsSelect = this.handleGlobalTagsSelect.bind(this)
        this.handleUserSelectionUpdated = this.handleUserSelectionUpdated.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleContentGroupDescriptionChange = this.handleContentGroupDescriptionChange.bind(this)
        this.handleContentGroupDateChange = this.handleContentGroupDateChange.bind(this)
        this.handleDatepickerOpen = this.handleDatepickerOpen.bind(this)
    }

    componentDidMount() {
        if (document.querySelector('#name')) {
            document.querySelector('#name').value = this.props.contentGroup.name
            if (this.props.contentGroup.avatar && this.props.contentGroup.avatar.original)
                document.querySelector('#content-group-icon-input').src = this.props.contentGroup.avatar.original
        } else {
            setTimeout(() => {
                if (document.querySelector('#name')) {
                    document.querySelector('#name').value = this.props.contentGroup.name
                    if (this.props.contentGroup.avatar && this.props.contentGroup.avatar.original)
                        document.querySelector('#content-group-icon-input').src =
                            this.props.contentGroup.avatar.original
                }
            }, 500)
        }

        const globalTags = []
        if (this.props.contentGroup.globalTags) {
            this.props.contentGroup.globalTags.map((item) => {
                globalTags.push(item.id)
            })
        }
        this.setState({
            globalTagsIds: globalTags,
        })
    }

    getInitialAssignedUsers() {
        const { contentGroup } = this.props

        if (!contentGroup) {
            return {}
        }

        const initialMembers = {}

        contentGroup.members.forEach((member) => {
            if (member.id) {
                initialMembers[member.id] = {
                    selected: true,
                    name: member.name ? member.name.split(' ')[0] : 'Deleted User',
                }
            }
        })

        return initialMembers
    }

    handleContentGroupNameChange(event) {
        this.setState({ name: event.target.value.trim() })

        this.props.setFormUpdatedStatus(true)
    }

    handleContentGroupDescriptionChange(e) {
        this.setState({
            description: e.target.value.trim(),
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleContentGroupDateChange(date) {
        this.setState({
            contentGroupEventDate: date,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleDatepickerOpen(open) {
        this.props.checkIfDatePickerOpen(open)
    }

    handleAvatarChange(event) {
        readURL(event.target.files[0])

        this.setState({ avatar: event.target.files })

        this.props.setFormUpdatedStatus(true)
    }

    handleGlobalTagsSelect(globalTagsIds) {
        this.setState({
            globalTagsIds: globalTagsIds,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleUserSelectionUpdated(assignedUsers, selectedUsersNumber, hasBeenUpdatedByUser) {
        this.setState({
            assignedUsers: assignedUsers,
            selectedUsersNumber: selectedUsersNumber,
        })

        if (hasBeenUpdatedByUser) {
            this.props.setFormUpdatedStatus(true)
        }
    }

    handleSubmit(event) {
        event.preventDefault()

        const { name, avatar, globalTagsIds, assignedUsers, description, contentGroupEventDate } = this.state

        const selectedUserIds = []

        for (const id in assignedUsers) {
            if (assignedUsers[id].selected) {
                selectedUserIds.push(id)
            }
        }

        const values = { name, avatar, globalTagsIds, selectedUserIds, description, contentGroupEventDate }

        this.props.onSubmit(values)
    }

    render() {
        const {
            contentGroup,
            ambassadors,
            areAmbassadorsLoading,
            onSearchUpdated,
            onDeactivateContentGroupButtonClick,
            hasFormBeenUpdated,
            isSсheduledContentGroup,
            scheduledDay,
        } = this.props
        const { selectedUsersNumber, name, description } = this.state

        return (
            <form onSubmit={this.handleSubmit}>
                <div>
                    <div className="create-group-row">
                        <div className="w-70">
                            <p className="content-group-name">Name</p>
                            <Field
                                name="name"
                                component={renderField}
                                maxLength={100}
                                type="text"
                                placeholder=""
                                className="ui-kit-input"
                                handleChange={this.handleContentGroupNameChange}
                                id="name"
                            />
                        </div>
                        <div className="ml-auto mb-auto">
                            <label className="create-group-icon-label">
                                <Field
                                    name="avatar"
                                    component={renderField}
                                    type="file"
                                    className="content-group-icon-input"
                                    id="content-group-icon-input"
                                    accept="image/x-png,image/jpeg,"
                                    handleChange={this.handleAvatarChange}
                                />
                                <div className="content-group-prview-block">
                                    <p className="content-group-prview-text">Add</p>
                                    <img className="content-group-prview" id="content-group-prview" src="" />
                                </div>
                            </label>
                            <div className="content-group-name optional-icon">Icon (optional)</div>
                        </div>
                    </div>
                    <div className="w-100 position-relative">
                        <p className="content-group-name">Description</p>
                        <Field
                            name="description"
                            component={renderTextareaField}
                            textLength={description.length}
                            minRows={4}
                            maxLength={250}
                            handleChange={this.handleContentGroupDescriptionChange}
                            type="textarea"
                            placeholder="Type your message..."
                            className="ui-kit-textarea"
                            defaultValue={description}
                        />
                    </div>
                    {isSсheduledContentGroup && (
                        <div className="content-group-date-picker-container">
                            <p className="content-group-name">Date</p>
                            <Field
                                name="date"
                                component={renderDatePicker}
                                handleChange={this.handleContentGroupDateChange}
                                onOpenChange={this.handleDatepickerOpen}
                                className="ui-kit-date-picker"
                                defaultValue={parseDateFromServer(scheduledDay)}
                            />
                        </div>
                    )}
                </div>
                <TagsMultiInput
                    handleGlobalTags={this.handleGlobalTagsSelect}
                    globalTagsItems={contentGroup.globalTags}
                />

                <SelectAmbassadorsForm
                    ambassadors={ambassadors}
                    areAmbassadorsLoading={areAmbassadorsLoading}
                    initialAssignedUsers={this.getInitialAssignedUsers()}
                    onSearchUpdated={onSearchUpdated}
                    onUserSelectionUpdated={this.handleUserSelectionUpdated}
                />

                <div className="hidden-buttons-block">
                    <button
                        type="submit"
                        className="el-btn edit-cg"
                        disabled={!selectedUsersNumber || name.length < 3 || !hasFormBeenUpdated}
                    >
                        Save
                    </button>
                    {!isSсheduledContentGroup && (
                        <button
                            type="button"
                            onClick={onDeactivateContentGroupButtonClick}
                            className="el-btn mod-archive-group"
                        >
                            {contentGroup.closed ? 'Activate group' : 'Deactivate group'}
                        </button>
                    )}
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'edit_content_group_form',
})(SettingsContentGroupForm)
