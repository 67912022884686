import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import React, { useCallback } from 'react'

import SelectedSpeakersList from './selected-speakers-list'
import { defaults } from '../../../../../system/services/HttpService'

const { Text } = Typography

const ExternalSpeakers = ({ id, label, form, editMode = false }) => {
    const speakerEmailValidator = useCallback(async (_, value) => {
        if (/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,16}$/i.test(value)) {
            const token = window.localStorage.getItem('Account')
            await fetch(
                `${defaults.api_host}/dashboard/liveStreams/checkSpeaker?${new URLSearchParams({
                    email: value,
                })}`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'text/plain',
                        Authorization: 'Bearer ' + JSON.parse(token),
                    },
                }
            ).then((response) => {
                const json = response.json()
                if (!response.ok) {
                    return json.then((text) => Promise.reject(new Error(text.error.message)))
                }
            })
        }
    }, [])

    return (
        <>
            <Form.Item label={label}>
                {editMode && <SelectedSpeakersList id={id} />}
                <Form.List name={id}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }, listIndex) => {
                                return (
                                    <div key={key}>
                                        <Row justify="space-between">
                                            <Col>
                                                <Text underline>
                                                    {editMode ? 'New speaker' : 'Speaker'} {listIndex + 1}{' '}
                                                </Text>
                                            </Col>
                                            <Col>
                                                {listIndex > 0 && <CloseCircleOutlined onClick={() => remove(name)} />}
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            {...restField}
                                            label="Email"
                                            name={[name, 'email']}
                                            fieldKey={[fieldKey, 'email']}
                                            validateTrigger="onBlur"
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: 'Invalid email address',
                                                },
                                                {
                                                    validator: speakerEmailValidator,
                                                },
                                            ]}
                                        >
                                            <Input type="email" placeholder="Add email" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Confirm email"
                                            name={[name, 'confirmEmail']}
                                            fieldKey={[fieldKey, 'confirmEmail']}
                                            dependencies={[name, 'email']}
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (getFieldValue([id, fieldKey, 'email']) === value) {
                                                            return Promise.resolve()
                                                        } else if (!getFieldValue([id, fieldKey, 'email'])) {
                                                            return Promise.resolve()
                                                        }
                                                        return Promise.reject(new Error('Emails do not match'))
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input type="email" placeholder="Confirm email" />
                                        </Form.Item>
                                    </div>
                                )
                            })}
                            <Form.Item noStyle>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        form.validateFields([id], {
                                            recursive: true,
                                        }).then(() => {
                                            add()
                                        })
                                    }}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Add new speaker
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form.Item>
        </>
    )
}

export default ExternalSpeakers
