/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../redux/actions/content/componentsActions'

import { ChatType } from '../../common/constants'
import ProspectInfoPopup from '../../components/popups/ProspectInfoPopup'
import { AmbassadorsFormModal, EventFormModal, SpeakersFormModal } from './components'
import { EventTypeRootScreen } from './screens'

const LiveEventsScreen = () => {
    const dispatch = useDispatch()
    const { path } = useRouteMatch()

    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'live-events',
            },
        })
    }, [])

    return (
        <div className="upcoming-events">
            <div className="content">
                <Route path={`${path}/:eventStatus`} component={EventTypeRootScreen} />
            </div>
            <EventFormModal />
            <AmbassadorsFormModal />
            <SpeakersFormModal />

            <ProspectInfoPopup prospectInfoChat={ChatType.LIVE_EVENT_CHAT} />
        </div>
    )
}

export default LiveEventsScreen
