import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

class Footer extends PureComponent {
    componentDidUpdate() {
        const { chats, dialogId } = this.props

        const chat = chats.items.find((item) => +item.id === +dialogId) || {}
        if (chat.admin_closed || chat.closed) {
            document.querySelector('#chat-footer').style.marginTop = '2rem'
        } else {
            document.querySelector('#chat-footer').style.marginTop = '0'
        }
    }

    render() {
        const { chats, dialogId, handleOpenConfirmPopup } = this.props
        const chat = chats.items.find((item) => +item.id === +dialogId) || {}

        if (chat.admin_closed) {
            return (
                <div className="chat-footer-closed">
                    <p className="closed-text">You have closed this conversation</p>
                    <button type="button" className="reopen-btn" onClick={handleOpenConfirmPopup}>
                        Reopen
                    </button>
                </div>
            )
        } else if (chat.closed) {
            return (
                <div className="chat-footer-closed">
                    <p className="closed-text">The conversation is closed</p>
                    <button type="button" className="reopen-btn" onClick={handleOpenConfirmPopup}>
                        Reopen
                    </button>
                </div>
            )
        } else {
            return <></>
        }
    }
}

export default connect((state) => {
    return {
        chats: state.chats,
    }
})(Footer)
