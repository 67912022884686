import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

class ExplanationModal extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const { handleCloseExplanationModal } = this.props
        return (
            <div className="policy-modal-body">
                <iframe
                    src="https://player.vimeo.com/video/387914876"
                    width="620"
                    height="335"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                ></iframe>
                <p className="legal-title-floor">Create your TAP Feed</p>
                <p className="legal-text-floor">Customize and embed your TAP Feed on your website</p>
                <button className="disabled-close-button" onClick={handleCloseExplanationModal}>
                    Close
                </button>
            </div>
        )
    }
}

export default connect((state) => {
    return {}
})(ExplanationModal)
