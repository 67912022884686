const UserTagModel = (data) => {
    const response = {
        id: null,
        key: '',
        name: '',
    }

    if (data) {
        if (data.id) response.id = data.id
        if (data.key) response.key = data.key
        if (data.name) response.name = data.name
    }

    return response
}

export default UserTagModel
