import React, { useEffect, useState } from 'react'
import { GET_TIMEZONES, RESET_TIMEZONES } from '../redux/actions/settings/timezonesActions'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Chevron } from '../../assets/img/svg/chevron-down.svg'
import dayjs from 'dayjs'

export const TimezonesDropdown = (props) => {
    const dispatch = useDispatch()
    const timezones = useSelector((state) => state.timezones.items)

    const [timezonesFound, setTimezonesFound] = useState([])
    const [currentTimezone, setCurrentTimezone] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})
    const [timezonesChecked, setTimezonesChecked] = useState(false)

    if (!timezonesChecked && timezones && timezones.length > 0) {
        setTimezonesChecked(true)
        setTimezonesFound(timezones)
        timezones.map((item) => {
            if (props.timezoneId && props.timezoneId === item.id) {
                setSelectedItem(item)
                setCurrentTimezone(item.name)
                props.handleChange(item, item)
            } else if (item.is_active) {
                setSelectedItem(item)
                setCurrentTimezone(item.name)
                props.handleChange(item, item)
            }
        })
    }

    const handleClickOutside = (e) => {
        if (isOpen) {
            const element = document.querySelector('#custom-dropdown')
            let targetElement = e.target // clicked element
            do {
                if (targetElement == element) {
                    return
                }
                // Go up the DOM
                targetElement = targetElement.parentNode
            } while (targetElement)
            setIsOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        dispatch({
            type: GET_TIMEZONES,
        })
        return () => {
            document.removeEventListener('click', handleClickOutside)
            dispatch({
                type: RESET_TIMEZONES,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    const onChange = (e) => {
        let timezonesFound = []
        timezonesFound = timezones.filter((timezone) =>
            timezone.name.toLowerCase().includes(e.target.value.toLowerCase())
        )

        setTimezonesFound(timezonesFound)
        setCurrentTimezone(e.target.value)
        setIsOpen(timezonesFound.length > 0)
    }

    const onSelect = (e, item) => {
        e.preventDefault()
        setSelectedItem(item)
        setCurrentTimezone(item.name)
        setIsOpen(false)
        props.handleChange(item)
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && currentTimezone.trim().length > 1) {
            e.preventDefault()
            let activeNew = {}
            for (const spec of timezones) {
                if (spec.name === e.target.value) {
                    activeNew = spec
                }
            }
            setSelectedItem(activeNew)
            setCurrentTimezone(activeNew.name)
            setIsOpen(false)
            props.handleChange(activeNew)
        } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            let selectedItemNew = {}
            if (timezones) {
                for (const index in timezones) {
                    if (timezones[index].id === selectedItem.id || !selectedItem.id) {
                        if (e.key === 'ArrowUp') {
                            selectedItemNew =
                                timezones[index - 1] && selectedItem.id ? timezones[index - 1] : timezones[index]
                        } else {
                            selectedItemNew =
                                timezones[+index + 1] && selectedItem.id ? timezones[+index + 1] : timezones[index]
                        }
                        break
                    }
                }
            }

            setSelectedItem(selectedItemNew)
            setCurrentTimezone(selectedItemNew.name ? selectedItemNew.name : '')
            props.handleChange(selectedItemNew)
            try {
                let activeElement = null
                const globalTagsElem = document.querySelector('#dropdown-menu')
                if (globalTagsElem) {
                    activeElement = globalTagsElem.querySelectorAll('.dropdown-item--selected')[0]
                }
                if (activeElement) {
                    const container = activeElement.parentNode
                    let offset = activeElement.offsetTop // get element's y position in list
                    offset -= container.scrollTop // subtract list's scroll position to get relative position

                    const deltaToBottom = offset + activeElement.clientHeight - container.clientHeight // calculate distance from active element's bottom to the list's bottom
                    if (deltaToBottom > 0) {
                        // if distance is greater than 0, it must be out of view
                        container.scrollTop += e.key !== 'ArrowUp' ? deltaToBottom + 35 : deltaToBottom // add delta to view
                    }

                    // same goes for top
                    const deltaToTop = offset + activeElement.clientHeight - container.clientHeight
                    if (deltaToTop < 0) {
                        container.scrollTop += e.key === 'ArrowUp' ? deltaToTop - 35 : deltaToTop
                    }
                }
            } catch {}
        }
    }

    return (
        <>
            <div id="custom-dropdown" className="custom-dropdown">
                <div className={isOpen ? 'input-wrapper input-wrapper-open' : 'input-wrapper'}>
                    <input
                        onClick={toggleMenu}
                        className="dropdown-input"
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={currentTimezone || ''}
                        placeholder="Search by current time / nearest area"
                    />
                    <div className="icon-open-block" onClick={toggleMenu}>
                        <Chevron className={isOpen ? 'chevron chevron-open' : 'chevron'} />
                    </div>
                </div>
                <div className={isOpen ? 'menu-wrapper menu-wrapper-open' : 'menu-wrapper'}>
                    <ul
                        id={'dropdown-menu'}
                        tabIndex="0"
                        className={isOpen ? 'dropdown-menu' : 'dropdown-menu hidden-block'}
                    >
                        {timezonesFound &&
                            timezonesFound.map((item) => (
                                <li
                                    key={item.id}
                                    onClick={(e) => onSelect(e, item)}
                                    className={
                                        'dropdown-item' +
                                        (selectedItem.id && item.id === selectedItem.id
                                            ? ' dropdown-item--selected'
                                            : '')
                                    }
                                    aria-label={item.name}
                                >
                                    {'(' +
                                        dayjs(item.current_time).utc(false).format('HH:mm') +
                                        ') ' +
                                        item.name.charAt(0).toUpperCase() +
                                        item.name.slice(1)}
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </>
    )
}
