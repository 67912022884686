import React, { useState } from 'react'

const EditDisplayNameForm = ({ university, handleSubmit }) => {
    const [displayName, setDisplayName] = useState(university.display_name || '')
    const [error, setError] = useState(false)

    const onChange = (e) => {
        const value = e.target.value
        if (value.length > 150) {
            setError('150 characters maximum')
        } else if (error) {
            setError(false)
        }
        setDisplayName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        handleSubmit(displayName)
    }

    return (
        <form className="university-form" onSubmit={onSubmit}>
            <div>
                <input
                    name="display_name"
                    value={displayName}
                    onChange={onChange}
                    className="university-input"
                    placeholder=""
                    type="text"
                />
                {error && <span className="error">{error}</span>}
            </div>
            <button
                type="submit"
                className={
                    'university-submit' +
                    ((!university.display_name && displayName.length === 0) ||
                    university.display_name === displayName ||
                    error
                        ? ' button-disabled'
                        : '')
                }
            >
                Change
            </button>
        </form>
    )
}

export default EditDisplayNameForm
