const NAMESPACE_PREFIX = 'LIVE_EVENTS'

export const LIVE_EVENTS = {
    OPEN_FORM_MODAL: `${NAMESPACE_PREFIX}.OPEN_FORM_MODAL`,
    CLOSE_FORM_MODAL: `${NAMESPACE_PREFIX}.CLOSE_FORM_MODAL`,
    OPEN_AMBASSADORS_MODAL: `${NAMESPACE_PREFIX}.OPEN_AMBASSADORS_MODAL`,
    CLOSE_AMBASSADORS_MODAL: `${NAMESPACE_PREFIX}.CLOSE_AMBASSADORS_MODAL`,
    CREATE: `${NAMESPACE_PREFIX}.CREATE`,
    CREATE_SUCCESS: `${NAMESPACE_PREFIX}.CREATE_SUCCESS`,
    CREATE_ERROR: `${NAMESPACE_PREFIX}.CREATE_ERROR`,
    UPDATE: `${NAMESPACE_PREFIX}.UPDATE`,
    UPDATE_SUCCESS: `${NAMESPACE_PREFIX}.UPDATE_SUCCESS`,
    UPDATE_ERROR: `${NAMESPACE_PREFIX}.UPDATE_ERROR`,
    SET_ACTIVE: `${NAMESPACE_PREFIX}.SET_ACTIVE`,
    SET_ACTIVE_SIDEBAR_TAB: `${NAMESPACE_PREFIX}.SET_ACTIVE_SIDEBAR_TAB`,
    RESET_ACTIVE: `${NAMESPACE_PREFIX}.RESET_ACTIVE`,
    FETCH: `${NAMESPACE_PREFIX}.FETCH`,
    FETCH_SUCCESS: `${NAMESPACE_PREFIX}.FETCH_SUCCESS`,
    FETCH_ERROR: `${NAMESPACE_PREFIX}.FETCH_ERROR`,
    ADD_TO_CALENDAR: `${NAMESPACE_PREFIX}.FETCH_ERROR`,
    EDIT_AMBASSADORS: `${NAMESPACE_PREFIX}.EDIT_AMBASSADORS`,
    EDIT_AMBASSADORS_SUCCESS: `${NAMESPACE_PREFIX}.EDIT_AMBASSADORS_SUCCESS`,
    EDIT_AMBASSADORS_ERROR: `${NAMESPACE_PREFIX}.EDIT_AMBASSADORS_ERROR`,
    OPEN_SPEAKERS_MODAL: `${NAMESPACE_PREFIX}.OPEN_SPEAKERS_MODAL`,
    CLOSE_SPEAKERS_MODAL: `${NAMESPACE_PREFIX}.CLOSE_SPEAKERS_MODAL`,
    UPDATE_SPEAKERS: `${NAMESPACE_PREFIX}.UPDATE_SPEAKERS`,
    UPDATE_SPEAKERS_SUCCESS: `${NAMESPACE_PREFIX}.UPDATE_SPEAKERS_SUCCESS`,
    UPDATE_SPEAKERS_ERROR: `${NAMESPACE_PREFIX}.UPDATE_SPEAKERS_ERROR`,
    START_EVENT_CONFIRM: `${NAMESPACE_PREFIX}.START_EVENT_CONFIRM`,
    START_EVENT: `${NAMESPACE_PREFIX}.START_EVENT`,
    STOP_EVENT_CONFIRM: `${NAMESPACE_PREFIX}.STOP_EVENT_CONFIRM`,
    STOP_EVENT: `${NAMESPACE_PREFIX}.STOP_EVENT`,
    CANCEL_EVENT_CONFIRM: `${NAMESPACE_PREFIX}.CANCEL_EVENT_CONFIRM`,
    CANCEL_EVENT: `${NAMESPACE_PREFIX}.CANCEL_EVENT`,
    FETCH_PROSPECTS: `${NAMESPACE_PREFIX}.FETCH_PROSPECTS`,
    FETCH_PROSPECTS_SUCCESS: `${NAMESPACE_PREFIX}.FETCH_PROSPECTS_SUCCESS`,
    FETCH_PROSPECTS_ERROR: `${NAMESPACE_PREFIX}.FETCH_PROSPECTS_ERROR`,
    OPEN_JOIN_MODAL: `${NAMESPACE_PREFIX}.OPEN_JOIN_MODAL`,
    CLOSE_JOIN_MODAL: `${NAMESPACE_PREFIX}.CLOSE_JOIN_MODAL`,
    JOIN_STREAM: `${NAMESPACE_PREFIX}.JOIN_STREAM`,
    LEAVE_STREAM: `${NAMESPACE_PREFIX}.LEAVE_STREAM`,
    STOP_STREAM: `${NAMESPACE_PREFIX}.STOP_STREAM`,
    CLOSE_CHAT: `${NAMESPACE_PREFIX}.CLOSE_CHAT`,
    CLOSE_CHAT_SUCCESS: `${NAMESPACE_PREFIX}.CLOSE_CHAT_SUCCESS`,
    CLOSE_CHAT_ERROR: `${NAMESPACE_PREFIX}.CLOSE_CHAT_ERROR`,
    UPDATE_EVENT_STATUS: `${NAMESPACE_PREFIX}.UPDATE_EVENT_STATUS`,
    UPDATE_PROSPECT_LIST: `${NAMESPACE_PREFIX}.UPDATE_PROSPECT_LIST`,
}
