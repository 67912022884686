import { produce } from 'immer'

import {
    USERS_MESSAGING_INFO_SUCCESS,
    USERS_MESSAGING_INFO,
    AMBASSADORS_INFO_SUCCESS,
    AMBASSADORS_INFO,
    getNewAmbassadorsInfoAsync,
} from '../../../actions/content/messagingActions'

import { USERS_MESSAGING } from '../../../store/initialState'

const initialState = USERS_MESSAGING

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case USERS_MESSAGING_INFO_SUCCESS: {
            const newState = Object.assign({}, state, {
                items: payload.items,
                total: payload.total,
                isLoading: false,
            })

            return newState
        }

        case USERS_MESSAGING_INFO: {
            const newState = Object.assign({}, state, {
                isLoading: true,
            })

            return newState
        }

        case AMBASSADORS_INFO_SUCCESS: {
            const newState = Object.assign({}, state, {
                ambassadors: payload.items,
                isLoading: false,
            })

            return newState
        }

        case AMBASSADORS_INFO: {
            const newState = Object.assign({}, state, {
                isLoading: true,
            })

            return newState
        }
        case getNewAmbassadorsInfoAsync.fulfilled.toString(): {
            return produce(state, (draft) => {
                draft.isLoading = false
                draft.ambassadors = payload.data.users
            })
        }

        default: {
            return state
        }
    }
}
