var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable camelcase */
import { Select, Spin } from 'antd';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import API from 'src/app/api';
import { useDebouncedCallback } from 'use-debounce';
var initialState = {
    items: [],
    offset: 0,
    limit: 50,
    loading: false,
};
var reducer = function (state, action) {
    switch (action.type) {
        case 'setLoading':
            return __assign(__assign({}, state), { loading: true });
        case 'setFulfilled':
            return __assign(__assign({}, state), { loading: false, items: action.payload.offset ? __spreadArray(__spreadArray([], state.items, true), action.payload.items, true) : action.payload.items, offset: action.payload.offset || 0 });
        default:
            return state;
    }
};
var LIST_HEIGHT = 200;
function optionsFetcher(dispatch) {
    return function (_a) {
        var searchName = _a.searchName, _b = _a.limit, limit = _b === void 0 ? 50 : _b, _c = _a.offset, offset = _c === void 0 ? 0 : _c;
        dispatch({ type: 'setLoading' });
        API.dashboardGroup
            .getDestinationOptions({ limit: limit, offset: offset, search: searchName })
            .then(function (response) {
            dispatch({ type: 'setFulfilled', payload: { items: response.data, limit: limit, offset: offset, search: searchName } });
        })
            .catch(function (error) { return console.log(error); });
    };
}
var defaultOptionMapper = function (_a) {
    var name = _a.name, countryName = _a.country_name, id = _a.id;
    return {
        label: "".concat(countryName, " - ").concat(name),
        value: "Destination : ".concat(countryName, " - ").concat(name, "_").concat(id),
    };
};
export var DestinationSelect = function (_a) {
    var value = _a.value, onChange = _a.onChange, _b = _a.optionMapper, optionMapper = _b === void 0 ? defaultOptionMapper : _b, _c = _a.listHeight, listHeight = _c === void 0 ? LIST_HEIGHT : _c, maxCount = _a.maxCount, props = __rest(_a, ["value", "onChange", "optionMapper", "listHeight", "maxCount"]);
    var _d = useReducer(reducer, initialState), state = _d[0], dispatch = _d[1];
    var items = state.items, offset = state.offset, limit = state.limit, loading = state.loading;
    var fetchOptions = useRef(optionsFetcher(dispatch));
    var search = useRef('');
    var _e = useState(), options = _e[0], setOptions = _e[1];
    var _f = useState(value || []), localValue = _f[0], setLocalValue = _f[1];
    useEffect(function () {
        if (items.length > 0) {
            var parsedItems = items.map(function (item) { return optionMapper(item); });
            setOptions(parsedItems);
        }
    }, [items, optionMapper]);
    var debounced = useDebouncedCallback(function (inputValue) {
        var searchName = inputValue.trim();
        fetchOptions.current({
            searchName: searchName,
        });
    }, 300);
    var handleSearch = function (inputValue) {
        search.current = inputValue.trim();
        if (!inputValue.trim()) {
            debounced('');
        }
        else if (inputValue.trim().length >= 3) {
            debounced(inputValue);
        }
    };
    var onDropdownVisibleChange = function (open) {
        if (open && !options) {
            fetchOptions.current({
                searchName: search.current,
            });
        }
        if (!open) {
            search.current = '';
            debounced('');
        }
    };
    var handlePopupScroll = useCallback(function (event) {
        var target = event.currentTarget;
        if (target.scrollTop + listHeight === target.scrollHeight) {
            fetchOptions.current({
                searchName: search.current,
                limit: limit,
                offset: offset + limit,
            });
        }
    }, [limit, offset, listHeight]);
    var handleChange = function (value) {
        setLocalValue(value);
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    };
    var onSelect = function () {
        if (search.current) {
            search.current = '';
            fetchOptions.current({
                searchName: '',
                offset: 0,
            });
        }
    };
    return (React.createElement(Select, __assign({ mode: "multiple", filterOption: false, maxCount: maxCount, notFoundContent: loading ? (React.createElement("div", { className: "p-6 text-center" },
            React.createElement(Spin, { size: "small" }))) : null, onChange: handleChange, value: localValue, listHeight: listHeight, onPopupScroll: handlePopupScroll, onSearch: handleSearch, onDropdownVisibleChange: onDropdownVisibleChange }, props, { options: options, onSelect: onSelect })));
};
