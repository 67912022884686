import liveswitch from 'fm.liveswitch'
import { isNil } from 'ramda'

import API from '../../../../../../api'
import config from '../../../../../../system/config'
import { EventParticipantModel } from './models/event-participant-model'

const gatewayUrl = config.liveSwitch
const applicationId = config.liveSwitchAppId

// const getDeviceId = () => liveswitch.Guid.newGuid().toString().replace(/-/g, '')

const VIDEO_CONFIG = {
    WIDTH: 640,
    HEIGHT: 480,
    FPS: 30,
}

const maxBitRate = 2000

export const createUserModel = (account) => {
    const {
        account_info: { id, name, last_name: lastName, avatar = {} },
    } = account

    const image = isNil(avatar) ? null : avatar.sizes['160x160'] || null

    return {
        id,
        name: `${name} ${lastName}`,
        avatar: image,
    }
}

export const createLocalMedia = (screenShare = false) => {
    const config = {
        width: VIDEO_CONFIG.WIDTH,
        height: VIDEO_CONFIG.HEIGHT,
        frameRate: screenShare ? 3 : VIDEO_CONFIG.FPS,
        viewScale: liveswitch.LayoutScale.Cover,
        audio: !screenShare,
    }
    const videoConfig = screenShare || new liveswitch.VideoConfig(config.width, config.height, config.frameRate)

    const localMedia = new liveswitch.LocalMedia(config.audio, videoConfig, screenShare)
    localMedia.getViewSink().setViewScale(screenShare ? liveswitch.LayoutScale.Contain : liveswitch.LayoutScale.Cover)

    return localMedia
}

export class StreamApi {
    user = null
    client = null
    clientId = null
    localMedia = null
    localScreenMedia = null
    channel = null
    downstreamConnections = {}
    upstreamConnection = null
    screenShareUpstreamConnection = null

    #slug = ''
    reRegisterBackoff = 200
    maxRegisterBackoff = 60_000
    unregistering = false
    streamAudioMuted = false

    constructor({ account, activeEvent }) {
        const { slug } = activeEvent

        this.user = createUserModel(account)

        this.client = new liveswitch.Client(gatewayUrl, applicationId, this.user.id + '', this.deviceId)
        this.client.setUserAlias(JSON.stringify(this.user))
        this.clientId = this.client.getId()

        this.localMedia = createLocalMedia()
        this.localScreenMedia = createLocalMedia(true)

        this.#slug = slug

        // liveSwitch.Log.setLogLevel(liveSwitch.LogLevel.Debug)
        // liveSwitch.Log.registerProvider(new liveSwitch.ConsoleLogProvider(liveSwitch.LogLevel.Debug))
    }

    async generateToken() {
        const channelClaim = new liveswitch.ChannelClaim(this.#slug)

        try {
            const { data, success } = await API.liveEvent.getLiveSwitchRegistrationToken({
                deviceId: this.client.getDeviceId(),
                clientId: this.client.getId(),
                channelClaim: {
                    id: channelClaim._id,
                    action: channelClaim._action,
                },
            })

            if (success) {
                return data
            }
        } catch {
            return null
        }
    }

    async join(handlers = {}) {
        const promise = new liveswitch.Promise()

        const token = await this.generateToken()

        if (!token) {
            promise.reject('No token')
        }

        // Allow re-register.
        this.unregistering = false

        this.client.addOnStateChange(() => {
            console.log(`Client is ${new liveswitch.ClientStateWrapper(this.client.getState())}.`)

            if (this.client.getState() === liveswitch.ClientState.Unregistered && !this.unregistering) {
                console.log(`Registering with backoff = ${this.reRegisterBackoff}.`)
                console.log('Client unregistered unexpectedly, trying to re-register.')

                setTimeout(() => {
                    // Incrementally increase register backoff to prevent runaway process.
                    if (this.reRegisterBackoff <= this.maxRegisterBackoff) {
                        this.reRegisterBackoff += this.reRegisterBackoff
                    }

                    // Register client with token.
                    this.client
                        .register(token)
                        .then((channels) => {
                            // Reset re-register backoff after successful registration.
                            this.reRegisterBackoff = 200
                            this.onClientRegistered(channels, handlers)
                            promise.resolve(null)
                        })
                        .fail((ex) => {
                            console.error('Failed to register with Gateway.')
                            promise.reject(ex)
                        })
                }, this.reRegisterBackoff)
            }
        })

        // Register client with token.
        this.client
            .register(token)
            .then((channels) => {
                this.onClientRegistered(channels, handlers)
                promise.resolve(null)
            })
            .fail((ex) => {
                console.error('Failed to register with Gateway.')
                promise.reject(ex)
            })

        return promise
    }

    leave({ onLeave, destroy = false }) {
        this.unregistering = true
        this.streamAudioMuted = false

        this.client.unregister()
        this.localMedia.stop().then(() => {
            if (destroy) {
                this.localMedia.destroy()
            }
        })
        this.stopScreenSharing({
            destroy: true,
        })
        if (onLeave) {
            onLeave()
        }
    }

    onClientRegistered(channels, handlers) {
        this.channel = channels[0]

        this.channel.addOnRemoteUpstreamConnectionOpen((remoteConnectionInfo) => {
            this.openDownstreamConnection(remoteConnectionInfo, handlers)
            this.adjustReceiveBitrate()
        })

        for (const remoteConnectionInfo of this.channel.getRemoteUpstreamConnectionInfos()) {
            this.openDownstreamConnection(remoteConnectionInfo, handlers)
        }
    }

    adjustReceiveBitrate() {
        const videoStreams = []
        for (const connection of Object.values(this.downstreamConnections)) {
            const videoStream = connection.getVideoStream()
            videoStreams.push(videoStream)
        }
        const videoStreamCount = videoStreams.length

        if (videoStreamCount > 0) {
            const videoStreamBitrate = Math.floor(maxBitRate / videoStreamCount)
            console.info(
                'Setting max bitrate for inbound video to ' +
                    videoStreamBitrate +
                    ' kbps per stream (' +
                    videoStreamCount +
                    ' streams).'
            )
            for (const videoStream of videoStreams) {
                videoStream.setMaxReceiveBitrate(videoStreamBitrate)
            }
        }
    }

    openUpstreamConnection(localMedia, tag, { local = false, onOpen }, failedCount = 0) {
        // Create audio and video streams from local media.
        const audioStream = localMedia.getAudioTrack() ? new liveswitch.AudioStream(localMedia) : null
        const videoStream = localMedia.getVideoTrack() ? new liveswitch.VideoStream(localMedia) : null

        // Create a SFU upstream connection with local audio and video.
        const connection = this.channel.createSfuUpstreamConnection(audioStream, videoStream)
        connection.setTag(JSON.stringify(tag))

        connection.addOnStateChange((conn) => {
            console.log(`Upstream connection is ${new liveswitch.ConnectionStateWrapper(conn.getState()).toString()}.`)

            if (conn.getState() === liveswitch.ConnectionState.Connected) {
                console.log('upstream connection Connected')
                if (onOpen) {
                    if (tag.source === 'webcam') {
                        const participant = EventParticipantModel(this.clientId, this.user)

                        const videoMuted = localMedia.getVideoMuted()
                        const audioMuted = localMedia.getAudioMuted()

                        onOpen(participant, localMedia, videoMuted, audioMuted)
                    } else {
                        onOpen()
                    }
                }
            }

            if (conn.getState() === liveswitch.ConnectionState.Failed) {
                const delay = 200 * Math.pow(2, failedCount)

                setTimeout(() => {
                    console.warn('Local upstream connection failed. Attempting reconnect.')
                    this.openUpstreamConnection(localMedia, tag, { onOpen }, failedCount + 1)
                }, delay)
            }
        })

        connection.open()
        if (local) {
            this.upstreamConnection = connection
        }

        return connection
    }

    openDownstreamConnection(remoteConnectionInfo, handlers = {}, failedCountExternal = 0) {
        const { onRemoteUpstreamJoined, onRemoteUpstreamClosed, onRemoteVideoMuted, onRemoteAudioMuted, Initializing } =
            handlers

        let failedCount = failedCountExternal

        const remoteClientId = remoteConnectionInfo.getClientId()
        const remoteConnectionId = remoteConnectionInfo.getId()

        const remoteClients = this.channel.getRemoteClientInfos()
        const remoteClient = remoteClients.find((clientInfo) => clientInfo.getId() === remoteClientId)

        if (!remoteClient) {
            console.log(
                `Could not open a local downstream connection to ${remoteClientId}. No matching remote client info.`
            )

            return
        }

        const remoteUserId = remoteClient.getUserId()

        const remoteUpstreamConnectionInfos = this.channel.getRemoteUpstreamConnectionInfos()
        const remoteUpstreamConnectionInfo = remoteUpstreamConnectionInfos.find(
            (connectionInfo) => connectionInfo.getId() === remoteConnectionId
        )

        if (!remoteUpstreamConnectionInfo) {
            console.log(
                `Could not open a local downstream connection to ${remoteClientId} (${remoteUserId}). No matching remote connection info.`
            )
            return
        }

        const remoteMedia = new liveswitch.RemoteMedia()
        const audioStream = remoteUpstreamConnectionInfo.getHasAudio() ? new liveswitch.AudioStream(remoteMedia) : null
        const videoStream = remoteUpstreamConnectionInfo.getHasVideo() ? new liveswitch.VideoStream(remoteMedia) : null

        const user = JSON.parse(remoteUpstreamConnectionInfo.getUserAlias())
        const tag = JSON.parse(remoteUpstreamConnectionInfo.getTag())

        const isScreenShare = tag.source === 'screen'

        if (isScreenShare) {
            remoteMedia.getViewSink().setViewScale(liveswitch.LayoutScale.Contain)
        } else {
            remoteMedia.getViewSink().setViewScale(liveswitch.LayoutScale.Cover)
        }

        // Create a SFU downstream connection with remote audio and video.
        const connection = this.channel.createSfuDownstreamConnection(remoteConnectionInfo, audioStream, videoStream)

        // Store the downstream connection.
        this.downstreamConnections[connection.getId()] = connection

        connection.addOnStateChange((conn) => {
            console.log(
                `Downstream connection is ${new liveswitch.ConnectionStateWrapper(conn.getState()).toString()}.`
            )

            switch (conn.getState()) {
                case liveswitch.ConnectionState.Initializing: {
                    if (Initializing) {
                        const participant = EventParticipantModel(remoteClientId, user)
                        Initializing(participant, false, false, remoteMedia, isScreenShare)
                    }
                    break
                }
                case liveswitch.ConnectionState.Connected: {
                    failedCount = 0
                    if (onRemoteUpstreamJoined) {
                        const participant = EventParticipantModel(remoteClientId, user)
                        const videoMuted = isScreenShare
                            ? false
                            : remoteUpstreamConnectionInfo.getVideoStream().getSendMuted()
                        const audioMuted = isScreenShare
                            ? false
                            : remoteUpstreamConnectionInfo.getAudioStream().getSendMuted()

                        if (this.streamAudioMuted) {
                            const config = connection.getConfig()
                            config.setRemoteAudioDisabled(true)
                            connection.update(config)
                        }

                        onRemoteUpstreamJoined(participant, videoMuted, audioMuted, remoteMedia, isScreenShare)
                    }
                    break
                }
                case liveswitch.ConnectionState.Closing:
                case liveswitch.ConnectionState.Failing: {
                    if (onRemoteUpstreamClosed) {
                        delete this.downstreamConnections[connection.getId()]
                        remoteMedia.destroy()
                        onRemoteUpstreamClosed(remoteClientId, isScreenShare, user.id)
                    }

                    break
                }
                case liveswitch.ConnectionState.Failed: {
                    const delay = 200 * Math.pow(2, failedCount)

                    setTimeout(() => {
                        this.openDownstreamConnection(remoteConnectionInfo, handlers, failedCount + 1)
                    }, delay)
                    break
                }
            }
        })

        connection.addOnRemoteUpdate((oldVal, newVal) => {
            const videoIsMuted = newVal.getVideoStream().getSendMuted()
            const audioIsMuted = newVal.getAudioStream().getSendMuted()

            if (onRemoteVideoMuted) {
                onRemoteVideoMuted(remoteClientId, videoIsMuted)
            }
            if (onRemoteAudioMuted) {
                onRemoteAudioMuted(remoteClientId, audioIsMuted)
            }
        })

        connection.open()
        return connection
    }

    muteLocalVideo(videoDevices) {
        if (this.upstreamConnection !== null) {
            const config = this.upstreamConnection.getConfig()
            const currentlyMuted = config.getLocalVideoMuted()
            config.setLocalVideoMuted(!currentlyMuted)

            this.localMedia.changeVideoSourceInput(currentlyMuted ? videoDevices[0] : [])
            this.upstreamConnection.update(config)
            return currentlyMuted
        }
    }

    muteLocalAudio(audioDevices) {
        if (this.upstreamConnection !== null) {
            const config = this.upstreamConnection.getConfig()
            const currentlyMuted = config.getLocalAudioMuted()
            config.setLocalAudioMuted(!currentlyMuted)

            this.localMedia.changeAudioSourceInput(currentlyMuted ? audioDevices[0] : [])
            this.upstreamConnection.update(config)
            return currentlyMuted
        }
    }

    startScreenSharing({ onOpen, onClose }) {
        this.localScreenMedia.start().then(() => {
            this.localScreenMedia.addOnVideoStopped(() => {
                this.stopScreenSharing({ onClose })
            })
            this.screenShareUpstreamConnection = this.openUpstreamConnection(
                this.localScreenMedia,
                {
                    source: 'screen',
                },
                {
                    onOpen,
                }
            )
        })
    }

    stopScreenSharing({ onClose, destroy = false }) {
        if (this.screenShareUpstreamConnection !== null) {
            this.screenShareUpstreamConnection.close()
            this.localScreenMedia.stop().then(() => {
                this.localScreenMedia.removeOnVideoStopped()

                if (destroy) {
                    this.localScreenMedia.destroy()
                }
            })
            if (onClose) {
                onClose()
            }
        }
    }

    muteRemoteAudio() {
        const ids = Object.keys(this.downstreamConnections)

        if (ids.length > 0) {
            for (const id in this.downstreamConnections) {
                const connection = this.downstreamConnections[id]
                const config = connection.getConfig()
                const currentlyMuted = connection.getRemoteAudioDisabled()

                config.setRemoteAudioDisabled(!currentlyMuted)
                connection.update(config)
            }

            const isMuted = !this.streamAudioMuted
            this.streamAudioMuted = isMuted

            return isMuted
        } else {
            const isMuted = !this.streamAudioMuted
            this.streamAudioMuted = isMuted

            return isMuted
        }
    }
}
