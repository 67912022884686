import React from 'react'

import { EmailAndSmsInvitation } from './components'

import { ReactComponent as IosBadge } from '../../../../../../assets/svg/ios-badge.svg'
import { ReactComponent as GoogleBadge } from '../../../../../../assets/svg/google-badge.svg'
import { ReactComponent as QrIos } from '../../../../../../assets/svg/qr-ios.svg'
import { ReactComponent as QrAndroid } from '../../../../../../assets/svg/qr-android.svg'

const MobileAppDownloadSection = () => {
    return (
        <div className="settings-item">
            <label className="settings-name">
                1. Download a mobile application{' '}
                <span className="label-rest-text">
                    for ambassadors (to try it out) by scanning the relevant QR code or clicking the link below
                </span>
            </label>
            <div className="qr-code-container">
                <div>
                    <QrAndroid />
                    <GoogleBadge />
                </div>
                <div>
                    <QrIos />
                    <IosBadge />
                </div>
            </div>
            <div className="email-and-sms-invitation-container">
                <EmailAndSmsInvitation />
            </div>
        </div>
    )
}

export default MobileAppDownloadSection
