var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CLEAR_POSTS_STORE, EDIT_POST_CAPTION_SUCCESS, EDIT_POST_GLOBAL_TAG_FINISH, POSTS_DELETE_SUCCESS, POSTS_INFO_REQUESTED, POSTS_INFO_SUCCESS, PUBLISH_OR_UNPUBLISH_POST_SUCCESS, SET_HOME_VISIBLE_POSTS, UPDATE_SHARED_POST, USERS_POSTS_SUCCESS, clearItems, getMorePostsAsync, getNewPostsAsync, setSearchItems, } from '../../actions/content/postsActions';
import { produce } from 'immer';
import PostsDateHumanizeHelper from 'src/app/helpers/DateHumanizeHelper';
import { groupMapBy } from 'src/app/utils/list';
import { ACCOUNT_LOGIN_SUCCESS } from '../../actions/account/accountActions';
var initialState = {
    posts: {
        limit: 20,
        offset: 0,
        total: 0,
        items: [],
        itemIds: new Set(),
        hasMore: true,
        isLoading: false,
        sortBy: 'created_at',
    },
    newPostsReceived: false,
    searchItems: [],
    visiblePostsNumber: 0,
    uiData: new Map(),
};
// TODO Fix action types
export default function (state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type;
    var payload = action.payload;
    switch (type) {
        case POSTS_INFO_REQUESTED: {
            return produce(state, function (draft) {
                draft.posts.isLoading = true;
                if (payload.offset != null) {
                    draft.posts.offset = payload.offset;
                }
                if (payload.limit != null) {
                    draft.posts.limit = payload.limit;
                }
                if (payload.sort != null) {
                    draft.posts.sortBy = payload.sort;
                }
                draft.searchItems =
                    payload.filter && payload.filter.searchItems ? payload.filter.searchItems : draft.searchItems;
            });
        }
        case getMorePostsAsync.pending.toString(): {
            return produce(state, function (draft) {
                draft.posts.isLoading = true;
            });
        }
        case getMorePostsAsync.fulfilled.toString(): {
            return produce(state, function (draft) {
                draft.posts.isLoading = false;
                var payload = action.payload; // FIXME: Add type for action
                draft.posts.items = __spreadArray(__spreadArray([], state.posts.items, true), payload.data.posts.map(function (post) { return (__assign(__assign({}, post), { createdAtHumanized: PostsDateHumanizeHelper(post.created_at) })); }), true);
                draft.posts.offset = payload.data.request.offset;
                draft.posts.total = payload.data.total;
                draft.posts.hasMore = payload.data.total > payload.data.request.offset + payload.data.request.limit;
                payload.data.posts.forEach(function (post) {
                    var key = PostsDateHumanizeHelper(post.created_at);
                    var posts = draft.uiData.get(key);
                    if (posts != null) {
                        posts.push(__assign(__assign({}, post), { createdAtHumanized: PostsDateHumanizeHelper(post.created_at) }));
                    }
                    else {
                        draft.uiData.set(key, [
                            __assign(__assign({}, post), { createdAtHumanized: PostsDateHumanizeHelper(post.created_at) }),
                        ]);
                    }
                });
            });
        }
        case getNewPostsAsync.fulfilled.toString(): {
            return produce(state, function (draft) {
                var _a;
                draft.posts.isLoading = false;
                var data = payload.data; // FIXME: Add type for action
                if (((_a = data.posts) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    var postsToAdd_1 = [];
                    data.posts.forEach(function (item) {
                        if (!draft.posts.itemIds.has(item.id)) {
                            postsToAdd_1.push(__assign(__assign({}, item), { createdAtHumanized: PostsDateHumanizeHelper(item.created_at) }));
                            draft.posts.itemIds.add(item.id);
                        }
                    });
                    draft.posts.items = __spreadArray(__spreadArray([], postsToAdd_1, true), draft.posts.items, true);
                    draft.posts.total = data.total;
                    postsToAdd_1.reverse().forEach(function (post) {
                        var key = post.createdAtHumanized;
                        var posts = draft.uiData.get(key);
                        if (posts != null) {
                            posts.unshift(post);
                        }
                        else {
                            var data_1 = new Map();
                            data_1.set(key, [post]);
                            draft.uiData.forEach(function (value, key) {
                                data_1.set(key, value);
                            });
                            draft.uiData = data_1;
                        }
                    });
                }
            });
        }
        case setSearchItems.toString(): {
            return produce(state, function (draft) {
                draft.searchItems = payload;
            });
        }
        case POSTS_INFO_SUCCESS: {
            return produce(state, function (draft) {
                payload.items.forEach(function (item) {
                    if (!draft.posts.itemIds.has(item.id)) {
                        draft.posts.items.push(__assign(__assign({}, item), { createdAtHumanized: PostsDateHumanizeHelper(item.created_at) }));
                        draft.posts.itemIds.add(item.id);
                    }
                });
                draft.uiData = groupMapBy(function (item) { return item.createdAtHumanized; })(draft.posts.items);
                draft.posts.hasMore =
                    payload.total != payload.request.offset &&
                        payload.total > payload.request.offset + payload.request.limit &&
                        payload.items.length === payload.request.limit;
                draft.posts.offset =
                    payload.total == payload.request.offset + payload.request.limit
                        ? payload.total
                        : payload.request.offset;
                draft.posts.total = payload.total;
                draft.posts.isLoading = false;
            });
        }
        case EDIT_POST_CAPTION_SUCCESS: {
            var postId_1 = payload.post_id, caption_1 = payload.caption;
            return produce(state, function (draft) {
                var itemIndex = draft.posts.items.findIndex(function (item) { return item.id === postId_1; });
                var item = itemIndex !== -1 ? draft.posts.items[itemIndex] : null;
                if (item != null) {
                    item.caption = caption_1;
                    var key = PostsDateHumanizeHelper(item.created_at);
                    var posts = draft.uiData.get(key);
                    if (posts != null) {
                        var postIndex = posts.findIndex(function (post) { return post.id === postId_1; });
                        if (postIndex !== -1) {
                            posts[postIndex] = item;
                        }
                    }
                }
            });
        }
        case PUBLISH_OR_UNPUBLISH_POST_SUCCESS: {
            var postId_2 = payload.post_id, publish = payload.publish;
            var isPublished_1 = publish;
            return produce(state, function (draft) {
                var itemIndex = draft.posts.items.findIndex(function (item) { return item.id === postId_2; });
                var item = itemIndex !== -1 ? draft.posts.items[itemIndex] : null;
                if (item != null) {
                    item.isPublished = isPublished_1;
                    var key = PostsDateHumanizeHelper(item.created_at);
                    var posts = draft.uiData.get(key);
                    if (posts != null) {
                        var postIndex = posts.findIndex(function (post) { return post.id === postId_2; });
                        if (postIndex !== -1) {
                            posts[postIndex] = item;
                        }
                    }
                }
            });
        }
        case EDIT_POST_GLOBAL_TAG_FINISH: {
            var postId_3 = payload.post_id, globalTags_1 = payload.globalTags;
            return produce(state, function (draft) {
                var itemIndex = draft.posts.items.findIndex(function (item) { return item.id === postId_3; });
                var item = itemIndex !== -1 ? draft.posts.items[itemIndex] : null;
                if (item != null) {
                    item.globalTags = globalTags_1;
                    var key = PostsDateHumanizeHelper(item.created_at);
                    var posts = draft.uiData.get(key);
                    if (posts != null) {
                        var postIndex = posts.findIndex(function (post) { return post.id === postId_3; });
                        if (postIndex !== -1) {
                            posts[postIndex] = item;
                        }
                    }
                }
            });
        }
        // TODO Need to fix this
        case USERS_POSTS_SUCCESS: {
            var items_1 = payload.items;
            return produce(state, function (draft) {
                draft.posts.itemIds = new Set();
                draft.posts.items = items_1.map(function (item) {
                    draft.posts.itemIds.add(item.id);
                    return __assign(__assign({}, item), { createdAtHumanized: PostsDateHumanizeHelper(item.created_at) });
                });
                draft.uiData = groupMapBy(function (item) { return item.createdAtHumanized; })(draft.posts.items);
            });
        }
        case ACCOUNT_LOGIN_SUCCESS: {
            return produce(state, function (draft) {
                draft.posts.items = [];
                draft.posts.itemIds = new Set();
                draft.posts.isLoading = false;
            });
        }
        case SET_HOME_VISIBLE_POSTS: {
            return __assign(__assign({}, state), { visiblePostsNumber: payload.visiblePostsNumber });
        }
        case POSTS_DELETE_SUCCESS: {
            return produce(state, function (draft) {
                var _a, _b, _c;
                var postIndex = draft.posts.items.findIndex(function (post) { return post.id === payload.post_id; });
                if (postIndex !== -1) {
                    var key = draft.posts.items[postIndex].createdAtHumanized;
                    draft.posts.items.splice(postIndex, 1);
                    draft.posts.itemIds.delete(payload.post_id);
                    var index = (_a = draft.uiData.get(key)) === null || _a === void 0 ? void 0 : _a.findIndex(function (post) { return post.id === payload.post_id; });
                    if (index !== undefined && index !== -1) {
                        (_b = draft.uiData.get(key)) === null || _b === void 0 ? void 0 : _b.splice(index, 1);
                        if (((_c = draft.uiData.get(key)) === null || _c === void 0 ? void 0 : _c.length) === 0) {
                            draft.uiData.delete(key);
                        }
                    }
                }
            });
        }
        case UPDATE_SHARED_POST: {
            return produce(state, function (draft) {
                var postToUpdate = draft.posts.items.find(function (post) { return post.id === payload.postId; });
                if (postToUpdate != null) {
                    postToUpdate.isShared = true;
                    var key = PostsDateHumanizeHelper(postToUpdate.created_at);
                    var posts = draft.uiData.get(key);
                    if (posts != null) {
                        var postIndex = posts.findIndex(function (post) { return post.id === payload.postId; });
                        if (postIndex !== -1) {
                            posts[postIndex] = postToUpdate;
                        }
                    }
                }
            });
        }
        case CLEAR_POSTS_STORE: {
            return initialState;
        }
        case clearItems.toString(): {
            return produce(state, function (draft) {
                draft.posts.items = [];
                draft.posts.itemIds = new Set();
                draft.posts.isLoading = false;
                draft.posts.hasMore = true;
                draft.posts.offset = 0;
                draft.posts.total = 0;
            });
        }
        default: {
            return state;
        }
    }
}
