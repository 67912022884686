import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import store from '../../../../../redux/store'
import { CREATE_GROUP_CHAT } from '../../../../../redux/actions/chat/groupMessagingActions'
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions'

import CreateChatGroupForm from '../forms/CreateChatGroupForm'

class CreateChatGroup extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            disableCreateButton: false,
            hasFormBeenUpdated: false,
        }

        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleEscPress = this.handleEscPress.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.setFormUpdatedStatus = this.setFormUpdatedStatus.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
            },
        })
        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('keydown', this.handleEscPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('keydown', this.handleEscPress, false)
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.hasFormBeenUpdated) {
            const confirmByClickOutside = confirm(
                'Are you sure you want to close this popup? Changes will not be saved'
            )
            if (confirmByClickOutside) {
                this.props.onClosePopup()
            }
        }
    }

    handleEscPress(event) {
        const escapeButtonCode = 27
        if (event.keyCode !== escapeButtonCode) {
            return
        }

        if (!this.state.hasFormBeenUpdated) {
            this.props.onClosePopup()
            return
        }

        const confirmByEscPress = confirm('Are you sure you want to close this popup? Changes will not be saved')
        if (confirmByEscPress) {
            this.props.onClosePopup()
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    handleSearchUpdated(value) {
        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    keyword: value,
                    permission: 'chat',
                },
            },
        })
    }

    setFormUpdatedStatus(value) {
        this.setState({ hasFormBeenUpdated: value })
    }

    handleFormSubmit(values) {
        this.setState({
            disableCreateButton: true,
        })

        const onFinish = (chatId) => {
            this.setState({
                disableCreateButton: false,
            })
        }

        store.dispatch({
            type: CREATE_GROUP_CHAT,
            payload: {
                name: values.name,
                firstMessage: values.firstMessage,
                users: values.selectedUserIds,
                globalTagsIds: values.globalTagsIds,
                is_private: values.is_private,
                type: 4,
            },
            onFinish,
        })

        if (this.props.onSubmit) {
            this.props.onSubmit(values)
        }
    }

    render() {
        const { usersMessaging, initialTags } = this.props

        return (
            <div ref={this.setWrapperRef}>
                <div className="create-chat-group">
                    <h2 className="modal-title">Create Group Chat</h2>
                    <CreateChatGroupForm
                        isCreateButtonDisabled={this.state.disableCreateButton}
                        ambassadors={usersMessaging.ambassadors}
                        areAmbassadorsLoading={usersMessaging.isLoading}
                        onSearchUpdated={this.handleSearchUpdated}
                        onSubmit={this.handleFormSubmit}
                        setFormUpdatedStatus={this.setFormUpdatedStatus}
                        initialValues={{ tags: initialTags }}
                    />
                </div>
            </div>
        )
    }
}

// eslint-disable-next-line no-class-assign
CreateChatGroup = connect((state) => {
    return {
        account: state.account,
        usersMessaging: state.usersMessaging,
        chats: state.chats,
    }
})(CreateChatGroup)

export default withRouter(CreateChatGroup)
