import { Table } from 'antd'
import React from 'react'

import { useAmbassadorReportTable } from './hooks/use-ambassador-report-table'

const AmbassadorReportTable = ({ ...props }) => {
    const { columnsConfig, dataSource, pagination, rowSelection, onTableChange } = useAmbassadorReportTable(props)

    return (
        <div className="users-container flexible-page table-wrapper">
            <Table
                rowSelection={rowSelection}
                onChange={onTableChange}
                dataSource={dataSource}
                columns={columnsConfig}
                rowKey="id"
                pagination={pagination}
                sortDirections={['descend', 'ascend', 'descend']}
                rowClassName="table-row"
                scroll={{
                    x: 240,
                }}
            />
        </div>
    )
}

export default AmbassadorReportTable
