import React, { useCallback, useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import { prepareFromDate, prepareToDate } from '../../../../helpers/time'

const ReportingTimeFilter = ({ onChecked, isActive }) => {
    const [from, setFrom] = useState()
    const [to, setTo] = useState()

    const onChange = useCallback(() => {
        onChecked(from, to)
    }, [from, to, onChecked])

    useEffect(() => {
        onChange()
    }, [from, to, onChange])

    const handleChangeStart = useCallback((date) => {
        setFrom(prepareFromDate(date))
    }, [])

    const handleChangeEnd = useCallback((date) => {
        setTo(prepareToDate(date))
    }, [])

    return (
        <div className={'rp-time-filter ' + (isActive ? 'active' : '')}>
            <div onClick={onChange} className="rp-time-filter-title">
                <span className="radion-btn"></span>

                <p>Filter by date</p>
            </div>

            <div className="time-filter-dates">
                <DatePicker
                    disabled={!isActive}
                    dateFormat="d MMMM yyyy"
                    placement="bottomLeft"
                    onChange={handleChangeStart}
                    style={{ width: 185, marginRight: 12 }}
                />

                <DatePicker
                    disabled={!isActive}
                    dateFormat="d MMMM yyyy"
                    placement="bottomLeft"
                    onChange={handleChangeEnd}
                    style={{ width: 185, marginRight: 12 }}
                />
            </div>
        </div>
    )
}

export default ReportingTimeFilter
