import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(calendar)
dayjs.extend(relativeTime)

export const PostsDateHumanizeHelper = (date) => {
    return dayjs(date).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: '[Next] dddd',
        lastDay: '[Yesterday]',
        lastWeek: 'dddd',
        sameElse: 'DD MMMM',
    })
}

export const formatForPreview = (date) => {
    return dayjs(date).format('MMMM D, YYYY [at] h:mm A')
}

export default PostsDateHumanizeHelper
