import { format } from 'date-fns'
import React, { useMemo, useState, useCallback } from 'react'

import { Button } from 'antd'

import noPostImage from '../../../../../../../../../assets/img/setup/faq.png'

import { parseDateFromServer } from '../../../../../../../../common/utils/date-time-utils'
import { LIVE_STREAM_STATUS } from '../../../../../../../../common/constants'

const FROM_FORMAT = 'dd.MM.yyyy'

const secondsToHms = (d) => {
    d = Number(d)
    const h = Math.floor(d / 3600)
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)

    const hDisplay = h > 0 ? h + 'h ' : ''
    const mDisplay = m > 0 ? m + 'min ' : ''
    const sDisplay = s > 0 ? s + 'sec ' : ''
    return hDisplay + mDisplay + sDisplay
}

const StreamCard = ({ event }) => {
    const { fromDate, name, image, description, video, status } = event

    const [showMore, setShowMore] = useState(false)

    const vidoFile = video.find((item) => item)

    const videoDuration = secondsToHms(vidoFile ? vidoFile.duration : 0)

    const fromTime = useMemo(() => {
        if (fromDate) {
            const parsed = parseDateFromServer(fromDate)

            return format(parsed, FROM_FORMAT)
        } else {
            return null
        }
    }, [fromDate])

    const showMoreHandler = useCallback(() => {
        setShowMore(!showMore)
    }, [showMore])

    return (
        <div className="event-card-stream-container">
            <div className="stream-image-container">
                <img
                    src={
                        image
                            ? image.sizes && image.sizes['370x370']
                                ? image.sizes['370x370']
                                : image.original
                            : noPostImage
                    }
                    alt="Event Image"
                />
                {status === LIVE_STREAM_STATUS.COMPLETED && !vidoFile && (
                    <div className="stream-image-placeholder">
                        <span> {`Stream is completed (not recorded)`}</span>
                    </div>
                )}
            </div>

            <p className="event-card-stream-name">{`${name}`}</p>
            <div className="event-card-stream-date-container">
                {fromTime && <p className="event-card-stream-date">{fromTime}</p>}
                <p className="event-card-stream-duration">{videoDuration}</p>
            </div>
            <div className="event-card-stream-description-text-block">
                <p>{showMore || description.length < 150 ? description : `${description.slice(0, 150)}...`}</p>
                {description.length > 150 && (
                    <Button type="link" onClick={showMoreHandler} style={{ paddingLeft: 0 }}>
                        {showMore ? 'Show less' : 'Show more...'}
                    </Button>
                )}
            </div>
        </div>
    )
}

export default StreamCard
