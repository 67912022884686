// fixed svg show
import svg4everybody from 'svg4everybody'

svg4everybody()

// checking if element for page
/*
$(document).on('click', '.js-show-form', function () {
    $('.hidden-block').stop().animate().slideUp()
    $(this).parents('.change-item').find('.hidden-block').stop().animate().slideDown()
})

$(document).on('click', '.js-hide-form', function () {
    $(this).parents('.change-item').find('.hidden-block').stop().animate().slideUp()
})
*/
