import { Link } from 'react-router-dom'
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'
import { openSsoWindow } from '../../../../helpers/SsoHelper'
import { Checkbox, Divider, Button } from 'antd'

const validate = (values) => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Please enter your email address'
    } else if (!/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,16}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    if (!values.first_name) {
        errors.first_name = 'Please enter your first name'
    }

    if (!values.last_name) {
        errors.last_name = 'Please enter your last name'
    }

    if (!values.password) {
        errors.password = 'Please enter your password'
    } else if (!/^(?=.*?[\p{Ll}])(?=.*?[\p{Lu}])(?=.*\d)(?=.*?[\W_]).{8,}$/gu.test(values.password)) {
        errors.password =
            'Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol'
    }

    if (!values.confirm_password) {
        errors.confirm_password = 'Please enter your password'
    }

    if (values.confirm_password != values.password) {
        errors.confirm_password = 'This password does not match'
    }

    if (!values.signed) {
        errors.signed = 'Please confirm legal notice'
    }

    if (!values.university_name) {
        errors.university_name = 'Please provide institution name'
    }
    return errors
}

const renderField = ({ input, label, placeholder, type, className, icon, meta: { touched, error, warning } }) => (
    <div className={'input-block-field' + (touched && (error || warning) ? ' input-block-field-error' : '')}>
        <div className="input-icon-block">
            <input {...input} className={className} placeholder={placeholder} type={type} />
        </div>
        {touched &&
            ((error && <span className="error error-relative">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

const renderLegalNotice = ({ input, meta: { touched, error, warning } }) => {
    return (
        <div className="legal-notice-container">
            <Checkbox {...input} />
            <p className="legal-notice">
                I am over the age of 18 and consent to my personal data being processed by my institution, according to
                its Privacy Policy, and being shared with The Ambassador Platform according to their{' '}
                <a className="privacy-link" target="_blank" rel="noopener noreferrer" href="https://tap.st/privacy">
                    Privacy Notice,{' '}
                </a>
                <a
                    className="privacy-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://legal.theambassadorplatform.com/admin-user-terms"
                >
                    Admin User Terms{' '}
                </a>
                and{' '}
                <a
                    className="privacy-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://legal.theambassadorplatform.com/customer-terms"
                >
                    Customer Terms
                </a>{' '}
                so that they can facilitate the management of content generation from representatives of my institution
                and messaging interactions with prospects.
            </p>
            {touched &&
                ((error && <span className="error error-relative">{error}</span>) ||
                    (warning && <span>{warning}</span>))}
        </div>
    )
}

const renderPasswordField = ({
    input,
    label,
    placeholder,
    type,
    className,
    icon,
    rightIcon,
    onShowPassword,
    meta: { touched, error, warning },
}) => (
    <div className={'input-block-field' + (touched && (error || warning) ? ' input-block-field-error' : '')}>
        <div className="input-icon-block">
            <input
                {...input}
                className={className}
                placeholder={placeholder}
                type={type}
                onCopy={(e) => e.preventDefault()}
            />
            <svg className="icon eye-icon" onClick={onShowPassword}>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#' + rightIcon}></use>
            </svg>
        </div>
        {touched &&
            ((error && <span className="error error-relative">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

class RegistrationForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            showPassword: false,
            showConfirmPassword: false,
        }

        this.showPassword = this.showPassword.bind(this)
        this.showConfirmPassword = this.showConfirmPassword.bind(this)
    }

    showPassword(e) {
        e.preventDefault()
        this.setState({
            showPassword: !this.state.showPassword,
        })
    }

    showConfirmPassword(e) {
        e.preventDefault()
        this.setState({
            showConfirmPassword: !this.state.showConfirmPassword,
        })
    }

    ssoSignIn = () => {
        const { account, invitationData } = this.props
        openSsoWindow(account.appDomain, account.appClientId, account.isEncryptionOn, invitationData)
    }

    render() {
        const { account, handleSubmit, invitationData } = this.props
        return (
            <div className="registration-form-container">
                <p className="signin-title">
                    Sign up as an <span>Admin</span>
                </p>

                <form action="#" method="POST" className="signin-form registration-form" onSubmit={handleSubmit}>
                    <div className="registration-form-name-container">
                        <label className="signin-label">
                            <span className="signin-label-text">First name</span>
                            <Field
                                name="first_name"
                                component={renderField}
                                type="text"
                                placeholder="First name"
                                className="signin-input"
                            />
                        </label>
                        <label className="signin-label">
                            <span className="signin-label-text">Last name</span>
                            <Field
                                name="last_name"
                                component={renderField}
                                type="text"
                                placeholder="Last name"
                                className="signin-input"
                            />
                        </label>
                    </div>
                    <label className="signin-label">
                        <span className="signin-label-text">Email address</span>
                        <Field
                            name="email"
                            component={renderField}
                            type="text"
                            placeholder="Email address"
                            className="signin-input"
                        />
                    </label>
                    {!invitationData.inviteCode ? (
                        <label className="signin-label">
                            <span className="signin-label-text">Institution name</span>
                            <Field
                                name="university_name"
                                component={renderField}
                                type="text"
                                placeholder="Institution name"
                                className="signin-input"
                            />
                        </label>
                    ) : null}
                    <label className="signin-label">
                        <span className="signin-label-text">Password</span>
                        <Field
                            name="password"
                            component={renderPasswordField}
                            type={this.state.showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            className="signin-input mod-pass"
                            rightIcon={this.state.showPassword ? 'eye-close' : 'eye'}
                            onShowPassword={this.showPassword}
                        />
                    </label>
                    <label className="signin-label">
                        <span className="signin-label-text">Confirm password</span>
                        <Field
                            name="confirm_password"
                            component={renderPasswordField}
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            placeholder="Password"
                            className="signin-input mod-pass"
                            rightIcon={this.state.showConfirmPassword ? 'eye-close' : 'eye'}
                            onShowPassword={this.showConfirmPassword}
                        />
                    </label>
                    <Field id="signed" name="signed" component={renderLegalNotice} type="checkbox" />

                    <button type="submit" className="signin-btn" style={account.appClientId ? { marginBottom: 0 } : {}}>
                        Sign Up
                    </button>
                    {account.appClientId && (
                        <>
                            <Divider plain>Or</Divider>
                            <Button className="signin-btn sign-up-sso-btn" onClick={this.ssoSignIn}>
                                Sign Up with SSO
                            </Button>
                        </>
                    )}
                    <div className="has-account">
                        <p>Already have an account?</p>
                        <Link className="forgot" to="/account/login">
                            Sign in
                        </Link>
                    </div>
                </form>
            </div>
        )
    }
}

export default reduxForm({
    form: 'registration_form',
    validate,
})(RegistrationForm)
