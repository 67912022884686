import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: function (failureCount, error) {
                var _a, _b, _c;
                if (isAxiosError(error) &&
                    ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) &&
                    ((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) >= 400 &&
                    ((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) < 500) {
                    return false;
                }
                return failureCount < 2;
            },
        },
    },
});
export var QueryProvider = function (_a) {
    var children = _a.children;
    return (React.createElement(QueryClientProvider, { client: queryClient }, children));
};
