/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CHAT_MESSAGES_INFO } from '../../../../../../redux/actions/chat/messagingActions'
import { pusherChild } from '../../../../../../system/services/PusherWrapper'
import { getAccountInfo, getLiveStramsOffset, getLiveStramsFilters } from '../../../../../../redux/selectors'

import { TAB_KEY, TAB_KEY_TO_CHAT_TYPE } from './chat-sidebar-constants'
import { CLEAR_MESSAGES } from '../../../../../../redux/actions/content/messagingActions'
import {
    setActiveLiveStreamSidebarTab,
    fetchProspects,
    getLiveStreamData,
    fetchLiveStreams,
} from '../../../../../../redux/actions/live-streams/live-streams-actions'

const CHANNEL_NAME = 'dashboard'
const isChatTab = (activeKey) => activeKey === TAB_KEY.CHAT || activeKey === TAB_KEY.SPEAKERS_CHAT

export const useChat = (activeEvent) => {
    const offset = useSelector(getLiveStramsOffset)
    const filter = useSelector(getLiveStramsFilters)

    const { chat, speakersChat, id: liveStreamId } = activeEvent

    const dispatch = useDispatch()
    const { university_id: universityId } = useSelector(getAccountInfo)
    const activeTab = useSelector((state) => state.liveStreams.activeTab)

    const channel = useRef(null)

    useEffect(() => {
        dispatch(
            fetchProspects({
                liveStreamId,
            })
        )
        dispatch(getLiveStreamData({ liveStreamId }))
    }, [])

    useEffect(() => {
        if (channel.current === null) {
            channel.current = pusherChild.subscribe(CHANNEL_NAME)

            channel.current.bind('messages:new:' + universityId, (data) => {
                const { dialog_id: dialogId, type } = data

                const chatType = activeTab === 'chat' ? 'chat' : 'speakersChat'

                if (activeEvent[chatType].id === dialogId && TAB_KEY_TO_CHAT_TYPE[activeTab] === type) {
                    dispatch({
                        type: CHAT_MESSAGES_INFO,
                        payload: {
                            dialog_id: dialogId,
                            type_chat: TAB_KEY_TO_CHAT_TYPE[activeTab],
                            blockReloadChat: true,
                            resetMessagesCounter: true,
                        },
                    })
                }
            })

            channel.current.bind(`liveStreams:${liveStreamId}:info`, (data) => {
                if (data && data.action === 'videoAction') {
                    setTimeout(() => {
                        dispatch(fetchLiveStreams({ offset, filter }))
                    }, 5000)
                }
            })
        }

        return () => {
            if (channel.current !== null) {
                channel.current = pusherChild.unsubscribe(CHANNEL_NAME)
                channel.current = null

                dispatch({ type: CLEAR_MESSAGES })
            }
        }
    }, [activeTab])

    const loadMessages = useCallback(
        (activeKey) => {
            const dialogId = activeKey === TAB_KEY.CHAT ? chat.id : speakersChat.id

            dispatch({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: dialogId,
                    type_chat: TAB_KEY_TO_CHAT_TYPE[activeKey],
                    blockReloadChat: true,
                    resetMessagesCounter: true,
                },
            })
        },
        [dispatch, chat, speakersChat]
    )

    const handleTabChange = useCallback(
        (activeKey) => {
            dispatch(setActiveLiveStreamSidebarTab(activeKey))
            if (isChatTab(activeKey)) {
                loadMessages(activeKey)
            }
        },
        [dispatch, loadMessages]
    )

    return {
        loadMessages,
        activeTab,
        onTabChange: handleTabChange,
    }
}
