import React from 'react'
import { useSelector } from 'react-redux'

import { getActiveLiveStream, getChatMessagesSlice } from '../../../../../../redux/selectors'

import ReportingPopover from '../../../../../../components/ReportingPopover'

import { CHAT_TYPE_KEY } from '../../../../../../common/constants'

import EventMenu from './components/event-menu'

const EventHeader = () => {
    const activeEvent = useSelector(getActiveLiveStream)
    const { name, statusName, dialogId } = activeEvent
    const chatMessages = useSelector(getChatMessagesSlice)

    return (
        <div className="event-head">
            <div className="chat-head-left">
                <div className="chat-name-block chat-name-block--chat">
                    <div className="chat-name-block__info">
                        <div className="chat-nav-name-container">
                            <h3 className="chat-name">{name}</h3>
                            {chatMessages.totalReportedMessages ? (
                                <ReportingPopover dialogId={dialogId} chatType={CHAT_TYPE_KEY.LIVE_STREAM_CHAT} />
                            ) : null}
                        </div>
                        <span className="chat-status">{`Stream is ${statusName}`}</span>
                    </div>
                </div>
            </div>

            <div className="chat-head-right">
                <EventMenu activeEvent={activeEvent} />
            </div>
        </div>
    )
}

export default EventHeader
