import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'
import store from '../../../../redux/store'
import { pusherChild } from '../../../../system/services/PusherWrapper'

import Header from './blocks/header'

import {
    CLOSE_ACTION_MODAL,
    OPEN_ACTION_MODAL,
    OPEN_SETTINGS_MESSAGING_POPUP,
    REPLY_MESSAGE,
} from '../../../../redux/actions/components/componentsActions'

import {
    CHATS_INFO_SEARCH,
    CHAT_INFO_SEARCH,
    CHAT_MESSAGES_INFO,
    CLOSE_DIALOG,
    FLAG_DIALOG,
} from '../../../../redux/actions/chat/messagingActions'

import { SEND_GROUP_CHAT_MESSAGE } from '../../../../redux/actions/chat/groupMessagingActions'

import { USERS_INFO_REQUESTED } from '../../../../redux/actions/content/usersActions'
import MessagesBody from './blocks/messagesBody'

import { ChatType } from '../../../../common/constants'

const sendRequestData = {
    timeout: null,
    dialogId: null,
}
const sendRequestToGroupChatData = {
    timeout: null,
    dialogId: null,
    dialogsCount: 0,
}

class Messages extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}

        this.fireChannelActions = this.fireChannelActions.bind(this)
        this.sendRequest = this.sendRequest.bind(this)
        this.sendRequestGroupChats = this.sendRequestGroupChats.bind(this)
        this.handleSendMessage = this.handleSendMessage.bind(this)
        this.handleOpenConfirmPopup = this.handleOpenConfirmPopup.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
    }

    componentDidMount() {
        const { universityID } = this.props
        this.channel = pusherChild.subscribe('dashboard')

        this.fireChannelActions()

        store.dispatch({
            type: USERS_INFO_REQUESTED,
            payload: {
                filter: {
                    university_id: universityID,
                },
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.channel.unbind()
            this.fireChannelActions()
        }
    }

    fireChannelActions() {
        const currentGroupUniversityId = this.props.account.currentGroupUniversityId // for group admins
        const universityId = currentGroupUniversityId || this.props.account.account_info.university_id

        this.channel.bind(
            'messages:new:' + universityId,
            function (data) {
                const { currentChat } = this.props

                if (data.type && data.type === 4) {
                    if (currentChat.activeChat == data.dialog_id) {
                        const sendRequest = this.sendRequest(currentChat)
                        if (sendRequest) {
                            store.dispatch({
                                type: CHAT_INFO_SEARCH,
                                payload: {
                                    dialogId: currentChat.activeChat,
                                    type_chat: ChatType.GROUP_CHAT,
                                    withGlobalTags: true,
                                    currentDialogId: currentChat.activeChat,
                                },
                            })
                        }
                        if (data.reported_message_id) {
                            store.dispatch({
                                type: CHAT_MESSAGES_INFO,
                                payload: {
                                    dialog_id: data.dialog_id,
                                    type_chat: ChatType.GROUP_CHAT,
                                },
                            })
                        }
                    } else {
                        const sendGroupChatsRequest = this.sendRequestGroupChats(data.dialog_id)
                        if (sendGroupChatsRequest) {
                            store.dispatch({
                                type: CHAT_INFO_SEARCH,
                                payload: {
                                    dialogId: data.dialog_id,
                                    type_chat: ChatType.GROUP_CHAT,
                                    withGlobalTags: true,
                                },
                            })
                        }
                    }
                }
            }.bind(this)
        )

        if (currentGroupUniversityId) {
            this.channel.bind('messages:reported:' + currentGroupUniversityId, function (data) {
                store.dispatch({
                    type: FLAG_DIALOG,
                    payload: {
                        dialog_id: data.dialog_id,
                    },
                })
            })
        }
    }

    componentWillUnmount() {
        if (this.channel) {
            this.channel.unbind()
        }
        store.dispatch({
            type: REPLY_MESSAGE,
            payload: {
                activeMessage: null,
                activePublisher: null,
            },
        })
    }

    sendRequest(currentChat) {
        if (currentChat.chatsType === 1) return true
        const lastMessagesDate = window.sessionStorage.getItem('lastMessagesDate')
        let sendNewRequest = true
        const newDate = new Date()
        try {
            if (lastMessagesDate) {
                if (newDate - new Date(lastMessagesDate) < 5000) {
                    sendNewRequest = false
                    if (!sendRequestData.timeout) {
                        sendRequestData.dialogId = currentChat.data.id
                        sendRequestData.timeout = setTimeout(() => {
                            const { currentChat } = store.getState()
                            if (sendRequestData.timeout) {
                                clearTimeout(sendRequestData.timeout)
                                sendRequestData.timeout = null
                            }
                            // writing new date as we don't need to send new request at the same dayjs as we do current, in 6sec will be send new
                            const date = new Date()
                            window.sessionStorage.setItem('lastMessagesDate', date)
                            store.dispatch({
                                type: CHAT_INFO_SEARCH,
                                payload: {
                                    dialogId: sendRequestData.dialogId,
                                    type_chat: ChatType.GROUP_CHAT,
                                    withGlobalTags: true,
                                    currentDialogId:
                                        sendRequestData.dialogId === currentChat.data.id
                                            ? currentChat.activeChat
                                            : null,
                                },
                            })
                        }, 7000)
                    }
                } else {
                    if (sendRequestData.timeout) {
                        clearTimeout(sendRequestData.timeout)
                        sendRequestData.timeout = null
                    }
                    window.sessionStorage.setItem('lastMessagesDate', newDate)
                }
            } else {
                if (sendRequestData.timeout) {
                    clearTimeout(sendRequestData.timeout)
                    sendRequestData.timeout = null
                }
                window.sessionStorage.setItem('lastMessagesDate', newDate)
            }
        } catch {}
        return sendNewRequest
    }

    sendRequestGroupChats(dialogId) {
        const lastChatDate = window.sessionStorage.getItem('lastChatDate')
        let sendNewRequest = true
        try {
            const newDate = new Date()
            if (lastChatDate) {
                if (newDate - new Date(lastChatDate) < 20_000) {
                    sendNewRequest = false
                    sendRequestToGroupChatData.dialogsCount += 1
                    if (!sendRequestToGroupChatData.timeout) {
                        sendRequestToGroupChatData.dialogId = dialogId
                        sendRequestToGroupChatData.timeout = setTimeout(() => {
                            const { currentChat } = store.getState()
                            if (sendRequestToGroupChatData.timeout) {
                                clearTimeout(sendRequestToGroupChatData.timeout)
                                sendRequestToGroupChatData.timeout = null
                            }
                            // writing new date as we don't need to send new request at the same dayjs as we do current, in 5sec will be send new
                            const date = new Date()
                            window.sessionStorage.setItem('lastChatDate', date)
                            if (sendRequestToGroupChatData.dialogsCount > 1) {
                                store.dispatch({
                                    type: CHATS_INFO_SEARCH,
                                    payload: {
                                        search: null,
                                        withGlobalTags: true,
                                        withoutLoading: true,
                                        archived: true,
                                    },
                                })
                                sendRequestToGroupChatData.dialogsCount = 0
                            } else if (sendRequestToGroupChatData.dialogId !== currentChat.data.id) {
                                store.dispatch({
                                    type: CHAT_INFO_SEARCH,
                                    payload: {
                                        dialogId: sendRequestToGroupChatData.dialogId,
                                        type_chat: ChatType.GROUP_CHAT,
                                        withGlobalTags: true,
                                    },
                                })
                            }
                        }, 21_000)
                    }
                } else {
                    if (sendRequestToGroupChatData.timeout) {
                        clearTimeout(sendRequestToGroupChatData.timeout)
                        sendRequestToGroupChatData.timeout = null
                    }
                    window.sessionStorage.setItem('lastChatDate', newDate)
                }
            } else {
                if (sendRequestToGroupChatData.timeout) {
                    clearTimeout(sendRequestToGroupChatData.timeout)
                    sendRequestToGroupChatData.timeout = null
                }
                window.sessionStorage.setItem('lastChatDate', newDate)
            }
        } catch {}
        return sendNewRequest
    }

    handleSendMessage() {
        if (document.querySelector('#chat-message-textarea').value.replace(/^\s+|\s+$/g, '') === '') return
        const { currentChat, replyMessage } = this.props

        const onSuccess = () => {
            store.dispatch({
                type: REPLY_MESSAGE,
                payload: {
                    activeMessage: null,
                    activePublisher: null,
                },
            })
        }

        store.dispatch({
            type: SEND_GROUP_CHAT_MESSAGE,
            payload: {
                type: 'text',
                text: document.querySelector('#chat-message-textarea').value,
                dialog_id: currentChat.activeChat,
                parentMessageId: replyMessage.activeMessage ? replyMessage.activeMessage.id : null,
                scrollBottom: true,
            },
            onSuccess,
        })

        document.querySelector('#send-message-form').reset()
    }

    handleOpenSettingsPopup() {
        store.dispatch({
            type: OPEN_SETTINGS_MESSAGING_POPUP,
        })
    }

    handleOpenConfirmPopup() {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button type="submit" className="el-btn confirm-delete-button" onClick={this.closeDialog}>
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={this.handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    closeDialog() {
        store.dispatch({
            type: CLOSE_DIALOG,
            payload: {
                dialogId: this.props.currentChat.activeChat,
                type_chat: ChatType.GROUP_CHAT,
            },
        })
    }

    handleCloseConfirmPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    render() {
        const { currentChat } = this.props

        const chat = currentChat.data || {}
        if (currentChat.activeChat === -1 || !chat.id) {
            return (
                <div className="chat-block">
                    <div className="chat-block-icon">
                        <svg className="icon icon-chat">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#chat'} />
                        </svg>
                        <h2 className="modal-title">Select a chat</h2>
                    </div>
                </div>
            )
        }

        return (
            <div className="chat-block">
                <Header dialogId={currentChat.activeChat} handleOpenSettingsPopup={this.handleOpenSettingsPopup} />
                <div className="chat-body scroll-8" id="chat-body">
                    <div className="chat-body-list">
                        <MessagesBody chat={chat} activeChat={currentChat.activeChat} search={currentChat.search} />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        currentChat: state.currentChat,
        account: state.account,
        prospects: state.prospects,
        replyMessage: state.replyMessage,
    }
})(Messages)
