import { connect } from 'react-redux'
import React, { PureComponent } from 'react'

class JoinedAdminMessage extends PureComponent {
    render() {
        const { account, message } = this.props

        if (account.account_info.id == message.user_id) return null
        return (
            <div className="join-row">
                <div className="msg-avatar-block">
                    {message.user && message.user.avatar ? (
                        <img
                            className="msg-item-img"
                            src={message.user.avatar.sizes['70x70'] || message.user.avatar.original}
                        />
                    ) : (
                        <div className="msg-avatar-text">{message.user ? message.user.name.charAt(0) : ''}</div>
                    )}
                </div>
                <p className="join-text">{message.content.user} joined the chat</p>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        contentGroups: state.contentGroups,
        account: state.account,
    }
})(JoinedAdminMessage)
