import {
    GET_TOPICS_LIST,
    GET_TOPICS_LIST_SUCCESS,
    GET_TOPICS_LIST_FAILED,
    RESET_TOPICS_LIST,
} from '../../actions/chat/topicsActions'

import { DIALOGS_TOPICS } from '../../store/initialState'
const initialState = DIALOGS_TOPICS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case GET_TOPICS_LIST: {
            return {
                ...state,
                items: payload.offset ? [...state.items] : [],
                isLoading: !payload.noLoading,
            }
        }

        case GET_TOPICS_LIST_SUCCESS: {
            return {
                ...state,
                items: payload.offset ? [...state.items, ...payload.items] : payload.items || [],
                offset: payload.offset || 0,
                total: payload.total ? +payload.total : 0,
                isLoading: false,
            }
        }

        case RESET_TOPICS_LIST: {
            return {
                ...state,
                items: [],
                offset: 0,
                isLoading: false,
            }
        }

        default: {
            return state
        }
    }
}
