import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import store from '../../../redux/store'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { getCommonRoutePath } from '../../../helpers/helpers'
import { Protect } from 'src/app/permissions/Protect'

class FaqStartGuide extends PureComponent {
    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'faq',
            },
        })
    }

    render() {
        const commonPath = getCommonRoutePath()

        return (
            <Protect>
                <section className="content settings home-start-guide">
                    <h1 className="settings-title">Getting started with FAQ</h1>
                    <div className="start-guide-intro">
                        <p>
                            Help prospects curate their own discovery at the pace that’s right for them by sourcing
                            authentic responses to common questions and concerns
                        </p>
                    </div>

                    <div className="start-guide-grid start-guides">
                        <div className="column">
                            <div className="start-block">
                                <span className="point">1</span>
                                <div className="block-body">
                                    <h3>Create and assign a question</h3>
                                    <p>
                                        Curate a diverse base of FAQs and assign to be answered by the most relevant
                                        ambassadors, or answer as the institution.
                                    </p>
                                    <NavLink className="start-button" to={commonPath + '/faq/new-questions'}>
                                        Create a question
                                    </NavLink>
                                </div>
                            </div>
                            <div className="start-block">
                                <div className="point">2</div>
                                <div className="block-body">
                                    <h3>Approve answers to the Library</h3>
                                    <p>
                                        Review and edit answers before publishing to your library to show on your TAP
                                        Feed. Check for typos, edit it (be sure not to change the intended meaning of
                                        the content), and click 'approve' to publish. Content in your Library will
                                        display on your TAP Feed.
                                    </p>
                                    <NavLink className="start-button" to={commonPath + '/faq/new-answers'}>
                                        Edit and approve answers
                                    </NavLink>
                                </div>
                            </div>

                            <div className="callout">
                                If you’d like more advice on building an authentic FAQ library, send us a message on our
                                customer support chat!
                            </div>
                        </div>

                        <div className="column resources">
                            <div className="video-wrapper">
                                <iframe
                                    src="https://player.vimeo.com/video/430274966"
                                    width="640"
                                    height="360"
                                    frameBorder="0"
                                    webkitallowfullscreen="true"
                                    mozallowfullscreen="true"
                                    allowFullScreen={true}
                                ></iframe>
                            </div>

                            <div className="resources-box">
                                <h3>Helpful resources</h3>
                                <ul>
                                    <li>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="https://knowledge.theambassadorplatform.com/what-is-tap-faq"
                                        >
                                            What is FAQ?
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href="https://knowledge.theambassadorplatform.com/explore-key-features#faq"
                                        >
                                            FAQ Suggestions to Help you get Started
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </Protect>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(FaqStartGuide)
