import mixpanel from 'mixpanel-browser'

const envCheck = process.env.APP_ENV === 'production'

if (envCheck) {
    mixpanel.init('532557fa817b09561760eebf7dc08d8a')
} else {
    mixpanel.init('5b9d42887c604ae2c4c4357910ea2ca6')
}

const MixPanelService = {
    identify: (id) => {
        mixpanel.identify(id)
    },
    alias: (id) => {
        mixpanel.alias(id)
    },
    track: (name, props) => {
        mixpanel.track(name, props)
    },
}

export default MixPanelService
