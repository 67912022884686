/* eslint-disable react-hooks/exhaustive-deps */
import { LeftOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row } from 'antd'
import React, { useMemo } from 'react'

import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg'

import { EventForm } from './event-form'
import { useEventFormModal } from './use-event-form-modal'

import { VideoUpload } from '../index'

const EventFormModal = () => {
    const {
        open,
        modalTitle,
        step,
        onPrevClick,
        afterClose,
        submitButtonTitle,
        submitting,
        onNextClick,
        form,
        initialValues,
        onCancel,
        imageState,
        setImageState,
        editMode,
        isInProgress,
        isUpcoming,
        isRelatedToGroup,
        isGroupAdmin,
        isLastStep,
        videoTranscode,
        videoProgress,
        videoProgressStart,
        abortVideoUpload,
        videoFileName,
        onSkipStep,
        isCompleted,
    } = useEventFormModal()
    const title = useMemo(() => {
        const backButton = <Button type="link" onClick={onPrevClick} icon={<LeftOutlined />} />

        return (
            <Row justify="space-between">
                <Col span={2}>{step > 0 && backButton} </Col>
                <Col flex="1">
                    <div>{modalTitle}</div>
                </Col>
                <Col span={2} />
            </Row>
        )
    }, [modalTitle, onPrevClick, editMode])

    const footer = useMemo(
        () => (
            <Row justify="center">
                <Col flex="162px">
                    {isLastStep ? (
                        <>
                            {videoProgressStart ? (
                                <Button type="primary" block size="large" onClick={abortVideoUpload}>
                                    Cancel
                                </Button>
                            ) : (
                                <VideoUpload videoTranscode={videoTranscode} />
                            )}
                        </>
                    ) : (
                        <>
                            <Button
                                type="primary"
                                block
                                size="large"
                                disabled={submitting}
                                loading={submitting}
                                onClick={onNextClick}
                            >
                                {submitButtonTitle}
                            </Button>
                            {step === 1 && (
                                <Button
                                    type="primary"
                                    block
                                    style={{
                                        marginTop: 10,
                                        marginLeft: 0,
                                    }}
                                    size="large"
                                    disabled={submitting}
                                    loading={submitting}
                                    onClick={onSkipStep}
                                >
                                    Skip, add later
                                </Button>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        ),
        [submitButtonTitle, submitting, onNextClick, videoProgressStart]
    )

    return (
        <Modal
            afterClose={afterClose}
            title={title}
            footer={footer}
            open={open}
            closeIcon={<CloseIcon />}
            onCancel={onCancel}
            width={600}
            className="tap-event-modal"
            destroyOnClose
        >
            <EventForm
                form={form}
                initialValues={initialValues}
                step={step}
                imageState={imageState}
                setImageState={setImageState}
                editMode={editMode}
                isInProgress={isInProgress}
                isUpcoming={isUpcoming}
                isCompleted={isCompleted}
                isRelatedToGroup={isRelatedToGroup}
                isGroupAdmin={isGroupAdmin}
                videoProgress={videoProgress}
                videoProgressStart={videoProgressStart}
                videoFileName={videoFileName}
            />
        </Modal>
    )
}

export default EventFormModal
