import Modal from 'antd/es/modal'
import cn from 'classnames'
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import sanitizeHtml from 'sanitize-html'

import pencil from '../../../assets/img/pencil.png'
import Sprite from '../../../assets/img/symbol/sprite.svg'
import TagsAddPopup from '../popups/TagsAddPopup'
import { ProtectedButton } from 'src/app/components/protected-button'
import { LicensesEnum } from 'src/types/general'
import { Button } from 'src/app/components/base/Button'

class ImageModal extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            caption: props.post.caption || 'This post does not have a caption',
            isEditCaption: false,
            isPublished: props.post.isPublished,
            addTagsModalOpened: false,
            messageLength: props.post.caption.length,
            html: {
                message: '',
            },
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.sanitize = this.sanitize.bind(this)
        this.toggleEditable = this.toggleEditable.bind(this)
        this.openModalTags = this.openModalTags.bind(this)
        this.handleCloseTagsPopup = this.handleCloseTagsPopup.bind(this)
    }

    sanitizeConf = {
        allowedTags: [],
        textFilter: function (text) {
            return text.replace(/&amp;/g, '&')
        },
    }

    sanitize() {
        this.setState((state) => ({
            ...state,
            caption: sanitizeHtml(state.caption, this.sanitizeConf),
        }))
    }

    openModalTags() {
        this.setState({
            addTagsModalOpened: true,
        })
    }

    handleCloseTagsPopup() {
        this.setState({
            addTagsModalOpened: false,
        })
    }

    componentDidMount() {
        const { caption } = this.props.post
        this.setState((state) => ({
            ...state,
            html: { ...state.html, message: caption },
            messageLength: caption ? 200 - caption.length : 0,
        }))
    }

    handleInputChange({ target }) {
        let input = target.value
        if (input.length > 200) {
            input = input.split(' ').slice(0, -1).join(' ')
        }

        const availableSymbols = 200 - input.length

        this.setState((state) => ({
            ...state,
            html: {
                message: input,
            },
            caption: target.value,
            messageLength: availableSymbols >= 0 ? availableSymbols : -1,
        }))
    }

    toggleEditable(action) {
        const { caption } = this.state
        const { post, changePostCaption } = this.props

        if (action === 'save') {
            changePostCaption(post.id, caption)
            return this.setState((state) => ({
                ...state,
                isEditCaption: false,
            }))
        }

        if (action === 'cancel') {
            return this.setState((state) => ({
                caption: post.caption || 'This post does not have a caption',
                isEditCaption: false,
            }))
        }

        this.setState((state) => ({
            ...state,
            isEditCaption: !state.isEditCaption,
            html: {
                ...state.html,
                message: post.caption,
            },
            messageLength: 200 - post.caption.length,
        }))
    }

    handlePublishPost(isPublished) {
        const { togglePublishPost, post } = this.props
        isPublished ? togglePublishPost(post.id, false) : togglePublishPost(post.id, true)
    }

    handlePublishClick(isPublished) {
        this.handlePublishPost(isPublished)
        this.setState((state) => ({ ...state, isPublished: !state.isPublished }))
    }

    render() {
        const { caption, isEditCaption, isPublished, addTagsModalOpened, messageLength, html } = this.state
        const {
            handleShareButtonClick,
            handleDownloadButtonClick,
            handleDeleteClick,
            elementIndex,
            post,
            handleClosePopup,
            messageId,
            isIdpContentGroup,
        } = this.props

        // eslint-disable-next-line camelcase
        const { id, isShared, globalTags, images, broadcast_ask, post_publisher, created_at, tags, isExample } = post

        if (!images[0]) {
            return null
        }

        const imageUrl = images[0].sizes['750x1400'] || images[0].original

        // eslint-disable-next-line camelcase
        if (broadcast_ask) {
            broadcast_ask.content.text =
                broadcast_ask.content.text.length > 80
                    ? broadcast_ask.content.text.slice(0, 80 - broadcast_ask.content.text.length) + '...'
                    : broadcast_ask.content.text
        }

        return (
            <div key={id} className="post-galery-item" data-index={id}>
                <button type="button" className="close-modal" onClick={handleClosePopup}>
                    <svg className="icon icon-close">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#close'}></use>
                    </svg>
                </button>

                <div className="post-image">
                    <img src={imageUrl} alt="Content gallery image" />
                </div>

                <div className="post-info">
                    <div className="post-info-wrapper">
                        <div className="post-info-top">
                            <span className="post-galery-name">
                                {/* eslint-disable-next-line camelcase */}
                                {post_publisher && post_publisher.name ? post_publisher.name : 'Deleted User'}
                            </span>
                            {/* eslint-disable-next-line camelcase */}
                            <span className="post-galery-time">{created_at}</span>
                        </div>

                        <div
                            className={`input__wrapper
                        ${isEditCaption && 'input__wrapper--active'}
                        ${isEditCaption && messageLength <= 20 && messageLength > 0 && 'input__wrapper--warning'}`}
                        >
                            {!isEditCaption ? (
                                caption
                            ) : (
                                <textarea
                                    name="span"
                                    className={cn('post-galery-caption', 'textarea-info', {
                                        'post-galery-caption--editable': isEditCaption,
                                    })}
                                    value={html.message} // innerHTML of the edition div
                                    onChange={this.handleInputChange} // handle innerHTML change
                                    onBlur={this.sanitize}
                                />
                            )}
                            {isEditCaption ? (
                                <span
                                    className={`input__length-indicator
                                    ${messageLength <= 20 && 'input__length-indicator--warning'}`}
                                >
                                    {`${messageLength}/200`}
                                </span>
                            ) : null}
                        </div>
                        <div className="input__buttons">
                            {isEditCaption ? (
                                <>
                                    <button
                                        className="input-control-button input__buttons__button"
                                        onClick={() => this.toggleEditable('cancel')}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="input-control-button input__buttons__button"
                                        onClick={() => this.toggleEditable('save')}
                                    >
                                        Save
                                    </button>
                                </>
                            ) : (
                                <button className="input-control-button" onClick={() => this.toggleEditable('toggle')}>
                                    <img className="pencil-icon" src={pencil} alt="" /> Edit caption
                                </button>
                            )}
                        </div>
                        {tags &&
                            tags.map((tag) => {
                                return (
                                    <Link key={tag.id} className="post-galery-tag" to={'/tag/' + tag.id}>
                                        {'#' + tag.name}
                                    </Link>
                                )
                            })}

                        <div className="global-tags-block">
                            <div className="search-term-wrapper">
                                {globalTags &&
                                    globalTags.map((tag, index) => {
                                        return (
                                            <span key={index} className="search-term">
                                                {tag.name}
                                            </span>
                                        )
                                    })}
                            </div>
                            {!isIdpContentGroup && (
                                <div className="add-tags-link">
                                    <span onClick={this.openModalTags}>Open Tag Manager</span>
                                </div>
                            )}
                            <Modal
                                open={addTagsModalOpened}
                                bodyStyle={{ padding: 0 }}
                                centered={true}
                                footer={null}
                                closable={false}
                                maskClosable={true}
                                onCancel={this.handleCloseTagsPopup}
                                className="global-tags-modal"
                                zIndex={11_100}
                            >
                                <TagsAddPopup
                                    postId={id}
                                    messageId={messageId}
                                    currentList={globalTags}
                                    handleCloseTagsPopup={this.handleCloseTagsPopup}
                                />
                            </Modal>
                        </div>

                        {/* {sharedView} //shows data when post was shared */}
                        <div className="mt-auto">
                            <div className="button-row">
                                <ProtectedButton
                                    type="secondary"
                                    data-post-index={elementIndex}
                                    data-automation-id="share-rss"
                                    onClick={handleShareButtonClick}
                                    className={isExample || isIdpContentGroup ? 'hidden' : 'post-galery-btn'}
                                    licenseName={LicensesEnum.RSS}
                                >
                                    {isShared ? 'Share again' : 'Share to RSS'}
                                </ProtectedButton>
                                <Button
                                    type="secondary"
                                    className={isExample || isIdpContentGroup ? 'hidden' : 'post-galery-btn'}
                                    data-post-id={id}
                                    onClick={() => this.handlePublishClick(isPublished)}
                                    data-automation-id="publish"
                                >
                                    {isPublished ? 'Unpublish' : 'Publish'}
                                </Button>
                            </div>

                            <div className="button-row">
                                <ProtectedButton
                                    type="secondary"
                                    licenseName={LicensesEnum.Download}
                                    data-post-id={id}
                                    data-automation-id="download"
                                    onClick={handleDownloadButtonClick}
                                    rel="noopener noreferrer"
                                    className={isExample || isIdpContentGroup ? 'hidden' : 'post-galery-btn'}
                                    href={
                                        images[0].original_background
                                            ? images[0].original_background
                                            : images[0].original
                                    }
                                >
                                    Download
                                </ProtectedButton>
                                <Button
                                    type="default"
                                    className={`post-galery-btn ${
                                        !isExample ? 'content-preview-delete' : 'hidden-block'
                                    }`}
                                    data-post-id={id}
                                    onClick={handleDeleteClick}
                                    data-automation-id="delete"
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ImageModal
