import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'

import SelectAmbassadorsForm from '../../../../../components/SelectAmbassadorsForm'

class SettingsChatForm extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            avatar: [],
            // To pass data to form submit:
            selectedUsersNumber: 0,
            assignedUsers: {},
        }

        this.getInitialAssignedUsers = this.getInitialAssignedUsers.bind(this)
        this.handleUserSelectionUpdated = this.handleUserSelectionUpdated.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {}

    getInitialAssignedUsers() {
        const { chat } = this.props

        if (!chat) {
            return {}
        }

        const initialMembers = {}

        chat.members.forEach((member) => {
            if (member.id) {
                initialMembers[member.id] = {
                    selected: true,
                    name: member.name || 'Deleted User',
                }
            }
        })

        return initialMembers
    }

    handleUserSelectionUpdated(assignedUsers, selectedUsersNumber, hasBeenUpdatedByUser) {
        this.setState({
            assignedUsers: assignedUsers,
            selectedUsersNumber: selectedUsersNumber,
        })

        if (hasBeenUpdatedByUser) {
            this.props.setFormUpdatedStatus(true)
        }
    }

    handleSubmit(event) {
        event.preventDefault()

        const { name, avatar, assignedUsers } = this.state

        const selectedUserIds = []

        for (const id in assignedUsers) {
            if (assignedUsers[id].selected) {
                selectedUserIds.push(id)
            }
        }

        const values = { name, avatar, selectedUserIds }

        this.props.onSubmit(values)
    }

    render() {
        const { ambassadors, areAmbassadorsLoading, onSearchUpdated, onCancelButtonClick, hasFormBeenUpdated } =
            this.props

        const { selectedUsersNumber } = this.state

        return (
            <form onSubmit={this.handleSubmit}>
                <SelectAmbassadorsForm
                    ambassadors={ambassadors}
                    areAmbassadorsLoading={areAmbassadorsLoading}
                    initialAssignedUsers={this.getInitialAssignedUsers()}
                    onSearchUpdated={onSearchUpdated}
                    onUserSelectionUpdated={this.handleUserSelectionUpdated}
                />

                <div className="hidden-buttons-block">
                    <button
                        type="submit"
                        className="el-btn edit-cg"
                        disabled={!selectedUsersNumber || !hasFormBeenUpdated}
                    >
                        Save
                    </button>
                    <button type="button" onClick={onCancelButtonClick} className="el-btn mod-close-group">
                        Cancel
                    </button>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'edit_content_group_form',
})(SettingsChatForm)
