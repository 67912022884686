import {
    OPEN_USER_INFO_MODAL,
    SET_PROSPECT_BLOCK,
    CLOSE_USER_INFO_MODAL,
} from '../../actions/components/componentsActions'

import { USER_INFO_MODAL } from '../../store/initialState'

const initialState = USER_INFO_MODAL

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case OPEN_USER_INFO_MODAL: {
            return {
                isOpened: true,
                userInfo: payload.userInfo,
            }
        }

        case SET_PROSPECT_BLOCK: {
            return {
                isOpened: true,
                userInfo: state.userInfo ? { ...state.userInfo, isBlocked: true } : null,
            }
        }

        case CLOSE_USER_INFO_MODAL: {
            return initialState
        }

        default: {
            return state
        }
    }
}
