import { Form, Input } from 'antd';
import React from 'react';
import { CountrySelect } from 'src/app/components/country-select';
import MobilePhoneInput from 'src/app/components/mobile-phone-input/mobile-phone-input';
export var UserInfoFields = function (_a) {
    var handlePhoneInput = _a.handlePhoneInput;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "md:grid md:grid-cols-2 md:gap-4 [&>.ant-form-item]:mb-4" },
            React.createElement(Form.Item, { name: "name", label: "First Name*", rules: [
                    {
                        required: true,
                        message: 'Please enter your first name',
                    },
                ] },
                React.createElement(Input, { placeholder: "John", type: "text" })),
            React.createElement(Form.Item, { name: "lastName", label: "Last Name*", rules: [
                    {
                        required: true,
                        message: 'Please enter your last name',
                    },
                ] },
                React.createElement(Input, { placeholder: "Adams", type: "text" }))),
        React.createElement(Form.Item, { name: "email", label: "Work Email*", rules: [
                function () { return ({
                    validator: function (_, value) {
                        if (!value) {
                            return Promise.reject(new Error('Please enter your email address'));
                        }
                        if (!/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,16}$/i.test(value)) {
                            return Promise.reject(new Error('Invalid email address'));
                        }
                        return Promise.resolve();
                    },
                }); },
            ] },
            React.createElement(Input, { placeholder: "Your email", type: "email" })),
        React.createElement(Form.Item, { name: "phoneNumber", label: "Phone Number*", rules: [
                {
                    required: true,
                    message: 'Please enter your phone number',
                },
                { min: 7, message: 'Phone number must contain more than 7 digits' },
            ] },
            React.createElement(MobilePhoneInput, { handlePhoneInput: handlePhoneInput, isCountryNotSupported: false })),
        React.createElement(Form.Item, { name: "countryId", label: "Country*", rules: [
                {
                    required: true,
                    message: 'Please select country',
                },
            ] },
            React.createElement(CountrySelect, null)),
        React.createElement(Form.Item, { name: "phoneCountry", label: null, className: "hidden" }),
        React.createElement(Form.Item, { name: "universityName", label: "Institution*", rules: [
                {
                    required: true,
                    message: 'Please enter institution name',
                },
            ] },
            React.createElement(Input, { placeholder: "e.g. Prestonvale University" }))));
};
