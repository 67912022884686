import {
    CONTENT_GROUPS_INFO,
    CONTENT_GROUPS_INFO_SUCCESS,
    RELOAD_CONTENT_GROUP_SUCCESS,
    RESET_DIALOG_MESSAGES_COUNTER,
    SET_ACTIVE_CONTENT_GROUP,
} from '../../../actions/content/messagingActions'

import { produce } from 'immer'

import { ROLE_KEY } from '../../../../common/constants'
import { CONTENT_GROUPS } from '../../../store/initialState'

const initialState = CONTENT_GROUPS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case CONTENT_GROUPS_INFO_SUCCESS: {
            return produce(state, (draft) => {
                draft.isLoading = false
                draft.isFirstRequest = false
                draft.items = payload.items.map((item) => {
                    const membersExcludingAccount = item.members
                        .filter((member) => member.role_key === ROLE_KEY.AMBASSADOR)
                        .map((member) => (member.name ? member.name.split(' ')[0] : 'Deleted User'))

                    let contentGroupTitle = membersExcludingAccount.slice(0, 10).join(', ')

                    if (membersExcludingAccount.length > 10) {
                        contentGroupTitle += ` and ${membersExcludingAccount.length - 10} more`
                    }

                    return {
                        ...item,
                        contentGroupTitle,
                    }
                })
            })
        }

        case CONTENT_GROUPS_INFO: {
            return produce(state, (draft) => {
                draft.isLoading = true
                draft.isFirstRequest = payload && payload.isFirstRequest ? payload.isFirstRequest : state.isFirstRequest
                draft.activeContentGroup =
                    (payload && payload.removeActiveContentGroup) || !state.activeContentGroup
                        ? -1
                        : state.activeContentGroup
            })
        }

        case SET_ACTIVE_CONTENT_GROUP: {
            return {
                ...state,
                activeContentGroup: payload.content_group_id,
            }
        }

        case RELOAD_CONTENT_GROUP_SUCCESS: {
            const dialog = payload.dialog
            return {
                ...state,
                items: state.items.map((item) => (item.id == dialog.id ? dialog : item)),
            }
        }

        case RESET_DIALOG_MESSAGES_COUNTER: {
            const dialogId = parseInt(payload.dialog_id, 10)
            return produce(state, (draft) => {
                draft.items.map((item) => {
                    if (item.id === dialogId) {
                        item.new_messages = 0
                    }
                    return item
                })
            })
        }

        default: {
            return state
        }
    }
}
