import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'

import TagsMultiInput from '../../../../../../components/TagsMultiInput'
import SelectAmbassadorsForm from '../../../../../../components/SelectAmbassadorsForm'

const textArea = ({
    input,
    label,
    id,
    accept,
    placeholder,
    name,
    type,
    className,
    meta: { touched, error, warning },
}) => (
    <label className="ui-kit-label">
        <textarea
            {...input}
            className={className}
            name={name}
            placeholder={placeholder}
            type={type}
            accept={accept}
            id={id}
        />
        {touched &&
            ((error && <span className="error faq-create">{error}</span>) || (warning && <span>{warning}</span>))}
    </label>
)

class CreateQuestionForm extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            question: '',
            answer: '',
            mode: '',
            faqID: null,
            globalTagsIds: [],
            // To pass data to form submit:
            selectedUsersNumber: 0,
            initialSelectedUsersExists: true,
            usersUpdated: false,
            assignedUsers: {},
        }

        if (this.props.initialValues.tags) {
            for (const item of this.props.initialValues.tags) {
                this.state.globalTagsIds.push(item.id)
            }
        }

        this.getInitialAssignedUsers = this.getInitialAssignedUsers.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleGlobalTagsSet = this.handleGlobalTagsSet.bind(this)
        this.handleUserSelectionUpdated = this.handleUserSelectionUpdated.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        const { mode, faqContent, faqID } = this.props.createQuestionPopup

        if (mode && faqContent) {
            this.setState((state) => ({
                ...state,
                question: faqContent,
                mode,
                faqID,
            }))
        }
    }

    getInitialAssignedUsers() {
        if (this.props.createQuestionPopup.members) {
            return this.props.createQuestionPopup.members.length === 0
                ? []
                : this.props.createQuestionPopup.members.reduce(
                      (acc, user) => ({ ...acc, [user.id]: { selected: true, name: user.name } }),
                      {}
                  )
        }
    }

    handleChange(_, value, __, name) {
        this.setState((state) => ({ ...state, [name]: value.trim() }))

        this.props.setFormUpdatedStatus(true)
    }

    handleGlobalTagsSet(globalTagsIds) {
        this.setState({
            globalTagsIds: globalTagsIds,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleUserSelectionUpdated(assignedUsers, selectedUsersNumber, hasBeenUpdatedByUser) {
        let usersActiveLength = 0
        for (const user in assignedUsers) {
            if (assignedUsers[user].selected) usersActiveLength++
        }
        this.setState({
            assignedUsers: assignedUsers,
            selectedUsersNumber: selectedUsersNumber,
            initialSelectedUsersExists:
                Object.keys(this.getInitialAssignedUsers()).length !== usersActiveLength && selectedUsersNumber === 0,
            usersUpdated: true,
        })

        if (hasBeenUpdatedByUser) {
            this.props.setFormUpdatedStatus(true)
        }
    }

    handleSubmit(event) {
        event.preventDefault()

        const { selectedUsersNumber, answer, question, mode, faqID, assignedUsers } = this.state

        let type = ''

        if (selectedUsersNumber.size > 0 && !answer.length > 0) {
            type = 'students'
        }

        if (selectedUsersNumber.size === 0 && answer.length > 0) {
            type = 'admin'
        }

        const globalTagsIds = [...this.state.globalTagsIds]

        const selectedUserIds = []

        for (const id in assignedUsers) {
            if (assignedUsers[id].selected) {
                selectedUserIds.push(id)
            }
        }

        const options = { mode, type, answer, question, faqID, globalTagsIds, selectedUserIds }

        this.props.onSubmit(options)
    }

    render() {
        const { mode, answer, question, selectedUsersNumber, initialSelectedUsersExists, usersUpdated } = this.state
        const {
            ambassadors,
            areAmbassadorsLoading,
            onSearchUpdated,
            isCreateButtonDisabled,
            onCancelButtonClick,
            hasFormBeenUpdated,
        } = this.props

        return (
            <form onSubmit={this.handleSubmit}>
                <h2 className="modal-title">{mode === 'edit' ? 'Edit question' : 'Create question'}</h2>
                <Field
                    name="question"
                    component={textArea}
                    type="text"
                    placeholder="Type your question"
                    className="ui-kit-textarea ui-kit-textarea--question"
                    onChange={this.handleChange}
                />

                <TagsMultiInput
                    handleGlobalTags={this.handleGlobalTagsSet}
                    globalTagsItems={this.props.initialValues.tags || []}
                />

                <div className="admin-answers">
                    <p className="content-group-name">Add your own answer</p>
                    <Field
                        name="answer"
                        component={textArea}
                        type="textarea"
                        placeholder="Type here"
                        className="ui-kit-textarea ui-kit-textarea--answer"
                        onChange={this.handleChange}
                    />
                </div>

                <SelectAmbassadorsForm
                    ambassadors={ambassadors}
                    areAmbassadorsLoading={areAmbassadorsLoading}
                    initialAssignedUsers={this.getInitialAssignedUsers()}
                    onSearchUpdated={onSearchUpdated}
                    onUserSelectionUpdated={this.handleUserSelectionUpdated}
                />

                <div className="hidden-buttons-block">
                    <button
                        type="submit"
                        className="el-btn"
                        disabled={
                            isCreateButtonDisabled ||
                            question.length < 3 ||
                            (mode !== 'edit' && answer.length < 2 && !selectedUsersNumber) ||
                            (mode === 'edit' && !selectedUsersNumber && initialSelectedUsersExists && usersUpdated) ||
                            (mode === 'edit' && !hasFormBeenUpdated)
                        }
                    >
                        {mode === 'edit' ? 'Edit' : 'Create'}
                    </button>
                    <button type="button" onClick={onCancelButtonClick} className="el-btn mod-close-group">
                        Cancel
                    </button>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'create_question_form',
})(CreateQuestionForm)
