import React, { PureComponent } from 'react'

class Loader extends PureComponent {
    render() {
        return (
            <div className="load" key={0}>
                <div className="load__icon-wrap">
                    <svg className="load__icon">
                        <path fill="#000000" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                </div>
            </div>
        )
    }
}

export default Loader
