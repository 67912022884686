import { differenceInMinutes } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LIVE_STREAM_STATUS } from '../../../../../../../common/constants';
import { parseDateFromServer } from '../../../../../../../common/utils/date-time-utils';
import { openAmbassadorsModal, openSpeakersModal, openScheduledStreamTypeModal, joinStreamLobby, openPublishControlModal, } from '../../../../../../../redux/actions/live-streams/live-streams-actions';
import { LIVE_STREAMS } from '../../../../../../../redux/actions/live-streams/live-streams-action-types';
import { openNotificationModal } from '../../../../../../../redux/actions/notification-modal/notification-modal-actions';
import { OPEN_ACTION_MODAL } from '../../../../../../../redux/actions/components/componentsActions';
var EventMenuContent = function (_a) {
    var activeEvent = _a.activeEvent, setIsOpen = _a.setIsOpen;
    var dispatch = useDispatch();
    var _b = useState(true), startEventButtonVisible = _b[0], setStartEventButtonVisible = _b[1];
    var status = activeEvent.status, fromDate = activeEvent.fromDate, isStreamActive = activeEvent.isStreamActive, liveStreamIframelessLink = activeEvent.liveStreamIframelessLink, isLobbyMode = activeEvent.isLobbyMode;
    useEffect(function () {
        var interval = setInterval(function () {
            var parsed = parseDateFromServer(fromDate);
            var now = new Date();
            var minutesBeforeEvent = differenceInMinutes(parsed, now);
            if (minutesBeforeEvent < 11) {
                setStartEventButtonVisible(true);
                clearInterval(interval);
            }
        }, 60000);
        return function () { return clearInterval(interval); };
    });
    var handleStartEventClick = function () {
        var liveStreamId = activeEvent.id, name = activeEvent.name;
        setIsOpen(false);
        dispatch(openNotificationModal(LIVE_STREAMS.START_STREAM_CONFIRM, {
            liveStreamId: liveStreamId,
            status: 'inProgress',
            name: name,
        }));
    };
    var handleEditEventClick = function () {
        setIsOpen(false);
        dispatch(openScheduledStreamTypeModal(activeEvent));
    };
    var handleCancelEventClick = function () {
        var liveStreamId = activeEvent.id, name = activeEvent.name;
        setIsOpen(false);
        dispatch(openNotificationModal(LIVE_STREAMS.CANCEL_STREAM_CONFIRM, {
            liveStreamId: liveStreamId,
            status: 'cancelled',
            name: name,
        }));
    };
    var handleManageAmbassadorsClick = function () {
        setIsOpen(false);
        dispatch(openAmbassadorsModal(activeEvent));
    };
    var handleManageSpeakersClick = function () {
        setIsOpen(false);
        dispatch(openSpeakersModal(activeEvent));
    };
    var handleJoinLobby = function () {
        var liveStreamId = activeEvent.id;
        setIsOpen(false);
        if (!isLobbyMode) {
            dispatch(joinStreamLobby({ isLobbyMode: true, liveStreamId: liveStreamId }));
            dispatch(openNotificationModal(LIVE_STREAMS.JOIN_STREAM_LOBBY));
        }
        else {
            dispatch(joinStreamLobby({ isLobbyMode: false, liveStreamId: liveStreamId }));
        }
    };
    var handleStopStreamClick = function () {
        var liveStreamId = activeEvent.id, name = activeEvent.name;
        setIsOpen(false);
        dispatch(openNotificationModal(LIVE_STREAMS.STOP_STREAM_CONFIRM, {
            liveStreamId: liveStreamId,
            status: 'completed',
            name: name,
        }));
    };
    var handleCopyIframelessLink = function () {
        var tempInput = document.createElement('input');
        tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px';
        tempInput.value = liveStreamIframelessLink;
        document.body.append(tempInput);
        tempInput.select();
        document.execCommand('copy');
        tempInput.remove();
        dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'Event link copied to clipboard',
            },
        });
    };
    var handleManagePublishingClick = function () {
        setIsOpen(false);
        dispatch(openPublishControlModal(activeEvent));
    };
    return (React.createElement("div", { className: "options-drop-block" },
        status === LIVE_STREAM_STATUS.UPCOMING && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "options-drop-item", onClick: handleJoinLobby, "data-automation-id": "".concat(isLobbyMode ? 'leave' : 'join', "-lobby") }, "".concat(isLobbyMode ? 'Leave' : 'Join', " the Stream lobby")),
            startEventButtonVisible && (React.createElement("p", { className: "options-drop-item", onClick: handleStartEventClick, "data-automation-id": "start-stream" }, "Start stream")))),
        (status === LIVE_STREAM_STATUS.UPCOMING || status === LIVE_STREAM_STATUS.IN_PROGRESS) && (React.createElement("p", { className: "options-drop-item", onClick: handleEditEventClick, "data-automation-id": "edit-stream" }, "Edit stream")),
        status === LIVE_STREAM_STATUS.UPCOMING && (React.createElement("p", { className: "options-drop-item", onClick: handleCancelEventClick, "data-automation-id": "cancel-stream" }, "Cancel stream")),
        (status === LIVE_STREAM_STATUS.UPCOMING || status === LIVE_STREAM_STATUS.IN_PROGRESS) && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "options-drop-item", onClick: handleManageAmbassadorsClick, "data-automation-id": "manage-ambassadors" }, "Manage ambassadors"),
            React.createElement("p", { className: "options-drop-item", onClick: handleManageSpeakersClick, "data-automation-id": "manage-speakers" }, "Manage speakers"),
            React.createElement("p", { className: "options-drop-item", onClick: handleManagePublishingClick, "data-automation-id": "manage-publish" }, "Manage publish"),
            React.createElement("p", { className: "options-drop-item", onClick: handleCopyIframelessLink, "data-automation-id": "standalone-page-link" }, "Link to a standalone page"))),
        status === LIVE_STREAM_STATUS.IN_PROGRESS && isStreamActive && (React.createElement("p", { className: "options-drop-item", onClick: handleStopStreamClick, "data-automation-id": "finish-stream" }, "Finish the stream"))));
};
export default EventMenuContent;
