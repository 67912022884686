import { CHAT_TYPE_KEY } from '../common/constants'

import UserModel from './UserModel'
import ChatModel from './ChatModel'
import { roles } from '../helpers/helpers'

const ProspectModel = (data) => {
    const { nationality, name } = data

    const countryName = nationality?.country ? `from ${nationality.country.name}` : ''

    return {
        ...data,
        name: name || '',
        role_name: `Participant ${countryName}`,
        prospect: true,
    }
}

const mapUsers = (items) => items.map((item) => UserModel(item))
const mapProspects = (items) => items.map((item) => ProspectModel(item))

const getChats = (items) => {
    let chat = {}
    let speakersChat = {}
    for (const item in items) {
        if (items[item].typeKey === CHAT_TYPE_KEY.LIVE_STREAM_CHAT) {
            chat = ChatModel(items[item])
        } else {
            speakersChat = ChatModel(items[item])
        }
    }
    return [chat, speakersChat]
}

export const checkIsAdminSpeaker = (account = {}, liveEvent = {}) => {
    const { account_info: { id } = {} } = account
    const { speakers = [] } = liveEvent

    return speakers.some(({ id: roleId, role_key: role }) => roleId === id && role === roles.admin)
}

export default (data = {}) => {
    const {
        id,
        name,
        description,
        image,
        archived,
        is_promote: isPromote,
        is_group_promote: isGroupPromote,
        slug,
        last_activity: lastActivity,
        timezone_id: timezoneId,
        timezoneName,
        from_date: fromDate,
        to_date: toDate,
        is_chat_active: isChatActive,
        is_stream_active: isStreamActive,
        status,
        statusName,
        members = [],
        speakers = [],
        prospects = [],
        globalTags,
        totalProspects,
        streamData,
        dialogs,
        liveStreamLink,
        liveStreamIframelessLink,
        is_auto_publish: autoPublish,
        isLobbyMode = false,
    } = data

    return {
        id,
        name,
        description,
        image,
        archived,
        isPromote,
        slug,
        lastActivity,
        timezoneId,
        timezoneName,
        fromDate,
        toDate,
        status,
        statusName,
        ambassadors: mapUsers(members),
        speakers: mapUsers(speakers),
        prospects: mapProspects(prospects),
        totalProspects,
        isStreamActive,
        chat: getChats(dialogs)[0],
        speakersChat: getChats(dialogs)[1],
        globalTags,
        isChatActive,
        liveStreamLink,
        isGroupPromote,
        streamData,
        liveStreamIframelessLink,
        autoPublish,
        isLobbyMode,
    }
}
