import { Avatar, Popover } from 'antd'
import React, { PureComponent } from 'react'

import BlockingProspectControl from '../../BlockingProspectControl'
import BlockProspectPopover from '../../BlockProspectPopover'

import Analytics from './ProspectInfoBlocks/Analytics'
import Feedback from './ProspectInfoBlocks/Feedback'
import MandatoryData from './ProspectInfoBlocks/MandatoryData'
import OptionalData from './ProspectInfoBlocks/OptionalData'
import Topics from './ProspectInfoBlocks/Topics'
import { InfoCircleOutlined } from '@ant-design/icons'

const DataRow = (props) => {
    return (
        <div style={{ maxWidth: 340, margin: props.margin ? props.margin : '10px 0' }}>
            <span className="prospect-text-name">{props.name}</span>
            <span className="prospect-text-value">{props.value}</span>
        </div>
    )
}

class ProspectInfo extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: 'analytics',
            userId: null,
        }

        this.showInfo = this.showInfo.bind(this)
    }

    componentDidMount() {
        this.setState({ userId: this.props.user ? this.props.user.id : null })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user && props.user.id !== state.userId) {
            state.userId = props.user.id
            state.activeTab = 'analytics'

            return state
        }
        return null
    }

    showInfo(tab) {
        this.setState({ activeTab: tab })
        switch (tab) {
            case 'optionalData': {
                this.props.loadOptionalData()

                break
            }
            case 'mandatoryData': {
                this.props.loadMandatoryData()

                break
            }
            case 'feedback': {
                this.props.loadFeedbackData()

                break
            }
            // No default
        }
    }

    render() {
        const { activeTab } = this.state
        // tableDataProps for prospect reporting and only to reload table data after some changes like blocking prospect were made in popup
        const { account, user, tableDataProps, prospectInfoChat } = this.props

        return (
            <div className="prospect-info-popup">
                <div className="common-prospect-info-block">
                    <Avatar size={70}>{user.full_name[0].toUpperCase()}</Avatar>
                    <div className="prospect-info-text">
                        <div className="heat-score-block">
                            <div className="chat-nav-name-container">
                                <h2>{user.full_name}</h2>
                                {user.isBlocked ? (
                                    <BlockProspectPopover className="chat-blocked-button" userId={user.id} />
                                ) : (
                                    (!account.account_info.isGroupAdmin ||
                                        (account.account_info.isGroupAdmin && account.currentGroupUniversityId)) && (
                                        <BlockingProspectControl
                                            userId={user.id}
                                            isBlocked={user.isBlocked}
                                            blockButtonClass="prospect-popup"
                                            tableDataProps={tableDataProps}
                                            prospectInfoChat={prospectInfoChat}
                                        />
                                    )
                                )}
                            </div>
                            {(!account.account_info.isGroupAdmin ||
                                (account.account_info.isGroupAdmin && account.currentGroupUniversityId)) && (
                                <div className="heat-score-data">
                                    <span className={user.heat_score > 0 ? 'warm-heat-score' : 'cold-heat-score'}>
                                        {user.heat_score > 0
                                            ? '+' + user.heat_score
                                            : user.heat_score < 0
                                            ? user.heat_score
                                            : 0}
                                    </span>
                                    <div className="heat-score-text-block">
                                        <span className="heat-score-text">Heat Score</span>
                                        <Popover
                                            placement="bottomRight"
                                            overlayStyle={{ width: 450 }}
                                            trigger="hover"
                                            content={
                                                <div className="heat-score-popover">
                                                    <p>
                                                        The Heat Score is calculated using a points-based system using
                                                        the following inputs:
                                                    </p>
                                                    <ul className="popover-heat-score-list">
                                                        <li>
                                                            Sentiment analysis on what the prospective student is saying
                                                        </li>
                                                        <li>
                                                            The total word count of conversation they&apos;re involved
                                                            in
                                                        </li>
                                                        <li>
                                                            The feedback ambassadors give following their conversations
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    </div>
                                </div>
                            )}
                        </div>
                        <DataRow name="Email" value={user.email} margin={'0 0 10px'} />
                        {user.phone_number && (
                            <DataRow
                                name="Phone Number"
                                value={(user.phone_country ? '+(' + user.phone_country + ') ' : '') + user.phone_number}
                            />
                        )}
                        {user.nationality && <DataRow name="Nationality" value={user.nationality} />}
                    </div>
                </div>

                <div className="prospect-data-blocks-body">
                    {!account.account_info.isGroupAdmin ||
                    (account.account_info.isGroupAdmin && account.currentGroupUniversityId) ? (
                        <>
                            <div className="prospect-data-blocks">
                                {/* <span onClick={() => this.showInfo("topics")} className={"prospect-data-block-name" + (activeTab === "topics" ? " active" : "")}>Topics</span> */}
                                <span
                                    onClick={() => this.showInfo('optionalData')}
                                    className={
                                        'prospect-data-block-name' + (activeTab === 'optionalData' ? ' active' : '')
                                    }
                                >
                                    Extra Data
                                </span>
                                <span
                                    onClick={() => this.showInfo('analytics')}
                                    className={
                                        'prospect-data-block-name' + (activeTab === 'analytics' ? ' active' : '')
                                    }
                                >
                                    Analytics
                                </span>
                                <span
                                    onClick={() => this.showInfo('feedback')}
                                    className={'prospect-data-block-name' + (activeTab === 'feedback' ? ' active' : '')}
                                >
                                    Feedback
                                </span>
                            </div>

                            <div className="card-info-block">
                                {activeTab === 'topics' && <Topics user={user} />}
                                {activeTab === 'optionalData' && <OptionalData />}
                                {activeTab === 'analytics' && <Analytics user={user} />}
                                {activeTab === 'feedback' && <Feedback />}
                            </div>
                        </>
                    ) : (
                        <>
                            {account.account_info.universityGroup &&
                            account.account_info.universityGroup.has_mandatory_questions ? (
                                <>
                                    <div className="prospect-data-blocks prospect-data-blocks-with-questions-data">
                                        <span
                                            onClick={() => this.showInfo('mandatoryData')}
                                            className={
                                                'prospect-data-block-name' +
                                                (activeTab === 'mandatoryData' ? ' active' : '')
                                            }
                                        >
                                            Extra Data
                                        </span>
                                        <span
                                            onClick={() => this.showInfo('analytics')}
                                            className={
                                                'prospect-data-block-name' +
                                                (activeTab === 'analytics' ? ' active' : '')
                                            }
                                        >
                                            Analytics
                                        </span>
                                    </div>
                                    <div className="card-info-block">
                                        {activeTab === 'mandatoryData' && <MandatoryData />}
                                        {activeTab === 'analytics' && <Analytics user={user} />}
                                    </div>
                                </>
                            ) : (
                                <div className="card-info-block one-card">
                                    {activeTab === 'analytics' && <Analytics user={user} />}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default ProspectInfo
