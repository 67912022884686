import React, { PureComponent } from 'react'
import { Table } from 'antd'

class InvitedAdminsTable extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}

        this.columns = [
            {
                title: 'Name',
                dataIndex: 'admin_name',
            },
            {
                title: 'Email',
                dataIndex: 'email',
            },
            {
                title: 'Account creation',
                dataIndex: 'created_at',
            },
            {
                title: 'Last active',
                dataIndex: 'last_activity',
            },
        ]
    }

    componentDidMount() {}

    componentDidUpdate() {}

    render() {
        return (
            <div className="users-container flexible-page table-wrapper">
                <Table
                    columns={this.columns}
                    dataSource={this.props.data}
                    pagination={
                        this.props.data && this.props.data.length > 10
                            ? { size: 'small', pageSize: 10, position: ['bottomLeft'] }
                            : false
                    }
                    rowKey="email"
                />
            </div>
        )
    }
}

export default InvitedAdminsTable
