import React, { Component } from 'react'
import { DatePicker } from 'antd'

import { prepareFromDate, prepareToDate } from '../../../../../../helpers/time'

import dayjs from 'dayjs'

class DatesIntervalSelector extends Component {
    render() {
        const dateFormat = 'YYYY-MM-DD'
        const restrictBeforeDate = this.props.restrictBeforeDate
        return (
            <div style={{ display: 'flex', alignItems: 'center' }} className="date-interval-selector">
                <DatePicker.RangePicker
                    style={{ marginTop: this.props.topAlign, marginRight: 8 }}
                    format={dateFormat}
                    className="date-picker"
                    onChange={(dates) => {
                        if (dates === null) {
                            this.props.onChange({
                                fromDate: null,
                                toDate: null,
                            })
                        } else {
                            this.props.onChange({
                                fromDate: prepareFromDate(dates[0]),
                                toDate: prepareToDate(dates[1]),
                            })
                        }
                    }}
                    disabledDate={(current) => {
                        if (restrictBeforeDate) {
                            return current && current < dayjs(restrictBeforeDate)
                        }
                    }}
                />
            </div>
        )
    }
}

export default DatesIntervalSelector
