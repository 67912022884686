import { GET_FILTER_OPTIONS_COUNT_SUCCESS, GET_FILTERS_SUCCESS } from '../../actions/settings/filtersActions'

const initialState = {
    filters: [],
    options: {},
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_FILTERS_SUCCESS: {
            return {
                filters: payload.widget_filters ? payload.widget_filters : [],
                available_filters: payload.available_filters,
                options: [],
            }
        }

        case GET_FILTER_OPTIONS_COUNT_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    ...payload,
                },
            }
        }

        default: {
            return state
        }
    }
}
