import React from 'react';
import { useHistory } from 'react-router';
import { Button } from 'src/app/components/base/Button';
import { TagsSearch } from 'src/app/components/base/TagsSearch';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { clearItems, getPosts } from 'src/app/redux/actions/content/postsActions';
import { selectUniversityId } from 'src/app/redux/selectors/account-selectors';
import { selectSearchItems } from 'src/app/redux/selectors/posts-selectors';
import { UploadContentButton } from './UploadContentButton';
export var PostsListHeader = function () {
    var universityId = useAppSelector(selectUniversityId);
    var dispatch = useAppDispatch();
    var searchItems = useAppSelector(selectSearchItems);
    var history = useHistory();
    var handleSearchChange = function (searchValues) {
        dispatch(clearItems());
        dispatch(getPosts({
            filter: {
                university_id: universityId,
                searchItems: searchValues,
            },
            sort: {
                created_at: 'desc',
            },
            withGlobalTags: true,
        }));
    };
    return (React.createElement("div", { className: "flex gap-4 search p-5" },
        React.createElement(TagsSearch, { className: "flex-1", onChange: handleSearchChange, values: searchItems }),
        React.createElement(UploadContentButton, null),
        React.createElement(Button, { type: "secondary", size: "large", onClick: function () {
                return history.push({
                    hash: '#create-content-group',
                });
            } }, "Create Content Group")));
};
