import { GET_ADMINS_SUCCESS } from '../../../actions/settings/admins'

import { ADMINS } from '../../../store/initialState'

const initialState = ADMINS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload
    switch (type) {
        case GET_ADMINS_SUCCESS: {
            const newState = Object.assign({}, state, {
                items: payload.items,
            })

            return newState
        }
        default: {
            return state
        }
    }
}
