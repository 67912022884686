import { message, Statistic } from 'antd'
import cn from 'classnames'
import { subMilliseconds, differenceInMinutes } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { LIVE_EVENT_STATUS } from '../../../../../../common/constants'
import { parseDateFromServer } from '../../../../../../common/utils/date-time-utils'

const { Countdown } = Statistic

// const COUNTDOWN_FORMAT = 'DD [days] HH:mm:ss'
const COUNTDOWN_FORMAT = 'DD:HH:mm:ss'

const pickBannerTitle = (eventStatus) => {
    switch (eventStatus) {
        case LIVE_EVENT_STATUS.UPCOMING:
            return 'Event should be started in:'
        case LIVE_EVENT_STATUS.CANCELLED:
            return 'Event was cancelled'
        default:
            return 'Event is finished'
    }
}

const PreviewImage = ({ activeEvent }) => {
    const [showBanner, setShowBanner] = useState(false)
    const parsedFromTime = parseDateFromServer(activeEvent.fromDate)

    const isUpcoming = useMemo(() => activeEvent.status === LIVE_EVENT_STATUS.UPCOMING, [activeEvent])

    // Need to debounce it somehow
    const handleChange = useCallback(
        (value) => {
            const millisecondsLeft = subMilliseconds(parsedFromTime, value)
            // console.log('millisecondsLeft', millisecondsLeft)
            // console.log('differenceInMinutes(millisecondsLeft)', differenceInMinutes(millisecondsLeft, parsedFromTime))
            if (differenceInMinutes(millisecondsLeft, parsedFromTime) > -1 && !showBanner) {
                setShowBanner(true)
            }
        },
        [showBanner, parsedFromTime]
    )

    useEffect(() => {
        if (showBanner) {
            message.success("Time to start 'UG Open Day - Online Classes Q&A' live event. Start event!")
        }
    }, [showBanner])

    const bannerTitle = useMemo(() => pickBannerTitle(activeEvent.status), [activeEvent])
    const eventMaskClassName = useMemo(
        () =>
            cn('event-mask', {
                cancelled: activeEvent.status === LIVE_EVENT_STATUS.CANCELLED,
            }),
        [activeEvent]
    )

    return (
        <div className="event-preview-image-block">
            <div className="event-preview-image">
                <img src={activeEvent.image.original} alt="Event Image" />
                <div className={eventMaskClassName} style={{ display: 'block' }}>
                    <div className="event-image-placeholder">
                        <p className="event-image-start-header"> {bannerTitle}</p>
                        {isUpcoming && (
                            <Countdown value={parsedFromTime} format={COUNTDOWN_FORMAT} onChange={handleChange} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewImage
