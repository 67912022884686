import { GET_HOME_DATA_FAILED, GET_HOME_DATA_SUCCESS } from '../../actions/home/homeActions'

import { HOME_DATA } from '../../store/initialState'

const initialState = HOME_DATA

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case GET_HOME_DATA_SUCCESS: {
            const wizardSetupData = payload.wizardSetupData
            const activity = { ...state.conversationsActivity }
            if (payload.conversationsActivity) {
                activity.badwordDialogs = payload.conversationsActivity.badwordDialogs || 0
                activity.flaggedDialogs = payload.conversationsActivity.flaggedDialogs || 0
                if (payload.conversationsActivity.prospectFeedbacks) {
                    activity.prospectFeedbacks = {
                        bad: payload.conversationsActivity.prospectFeedbacks.bad || 0,
                        good: payload.conversationsActivity.prospectFeedbacks.good || 0,
                        normal: payload.conversationsActivity.prospectFeedbacks.normal || 0,
                    }
                    activity.totalFeebacksNumber =
                        activity.prospectFeedbacks.bad +
                        activity.prospectFeedbacks.good +
                        activity.prospectFeedbacks.normal
                }
            }
            return {
                ...state,
                conversationsActivity: activity,
                inactiveAmbassadors: payload.inactiveAmbassadors || [],
                recentPosts: payload.recentPosts && payload.recentPosts.posts ? payload.recentPosts.posts : [],
                postsTotal: payload.recentPosts && payload.recentPosts.total ? payload.recentPosts.total : 0,
                recentFaqAnswers:
                    payload.recentFaqAnswers && payload.recentFaqAnswers.faqAnswers
                        ? payload.recentFaqAnswers.faqAnswers
                        : [],
                faqsTotal:
                    payload.recentFaqAnswers && payload.recentFaqAnswers.faqAnswers
                        ? payload.recentFaqAnswers.total
                        : 0,
                wizardSetupData: {
                    embedTapPage: wizardSetupData.embedTAPpage || false,
                    customizeBranding: wizardSetupData.customizeBranding || false,
                    inviteAmbassadors: wizardSetupData.inviteAmbassadors || false,
                    privacyPolicy: wizardSetupData.privacyPolicy || false,
                },
                wizardSetupPassed:
                    wizardSetupData &&
                    wizardSetupData.embedTAPpage &&
                    wizardSetupData.customizeBranding &&
                    wizardSetupData.inviteAmbassadors &&
                    wizardSetupData.privacyPolicy,
                isLoading: false,
            }
        }
        case GET_HOME_DATA_FAILED: {
            return {
                ...state,
                isLoading: false,
            }
        }
        default: {
            return state
        }
    }
}
