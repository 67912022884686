/* eslint-disable camelcase */
import { REPLY_MESSAGE } from '../../actions/components/componentsActions';
var initialState = {
    activeMessage: null,
};
export default function (state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type;
    var payload = action.payload;
    switch (type) {
        case REPLY_MESSAGE: {
            return {
                activeMessage: payload.activeMessage,
            };
        }
        default: {
            return state;
        }
    }
}
