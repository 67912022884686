const PermissionModel = (data) => {
    const response = {
        id: null,
        is_enabled: false,
    }

    if (data) {
        if (data.id) response.id = data.id
        if (data.is_enabled) response.is_enabled = data.is_enabled
    }

    return response
}

export default PermissionModel
