import store from '../../../redux/store'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import InvitedAdminsTable from './components/InvitedAdminsTable'
import InviteAdminPopupContent from './components/InviteAdminPopupContent'
import DateHumanizeHelper from '../../../helpers/DateHumanizeHelper'
import { adminNameRow } from '../../../helpers/table'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'

import { GET_ADMINS } from '../../../redux/actions/settings/admins'
import { SET_UNIVERSITY_GROUP } from '../../../redux/actions/account/accountActions'

class InviteAdmins extends PureComponent {
    componentDidMount() {
        const { account } = this.props
        const isGroupAdmin = account.account_info.isGroupAdmin

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })

        store.dispatch({
            type: GET_ADMINS,
            payload: {},
        })

        if (isGroupAdmin) {
            store.dispatch({
                type: SET_UNIVERSITY_GROUP,
                payload: {
                    university_id: null,
                },
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: GET_ADMINS,
                payload: {},
            })
        }
    }

    openInviteAdminPopup() {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: <InviteAdminPopupContent />,
                hasOutsideClickClose: false,
            },
        })
    }

    render() {
        const { account, admins } = this.props
        const settingsCodeView = []
        if (account.account_info.university && account.account_info.university.invite_code) {
            account.account_info.university.invite_code.split('').forEach((char) => {
                settingsCodeView.push(
                    <input type="text" disabled="disabled" className="settings-code-input" value={char} />
                )
            })
        }

        let adminsRows = null
        if (admins.items.length > 0) {
            adminsRows = admins.items.map((admin) => {
                return {
                    admin_name: adminNameRow(
                        admin.name,
                        admin.last_name,
                        admin.avatar ? admin.avatar.sizes['70x70'] || admin.avatar.original : null
                    ),
                    email: admin.email,
                    created_at: DateHumanizeHelper(admin.created_at),
                    last_activity: admin.last_activity ? DateHumanizeHelper(admin.last_activity) : '-',
                }
            })
        }

        return (
            <div id="page-body" className="page-body js-index">
                <section className="content settings">
                    <div className="invite-admin-container">
                        <div className="invite-admin-header">
                            <h3 className="settings-title">Admin List</h3>
                            <a className="el-btn" onClick={this.openInviteAdminPopup}>
                                + Invite new admin
                            </a>
                        </div>

                        <div className="settings-grid has-tutorial">
                            <div className="column">
                                <div className="tutorial-intro">
                                    <p>
                                        Invite colleagues to join you as an admin on The Ambassador Platform. Please
                                        note that all admins have the same level of access to all areas of the dashboard
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex">
                            <div className="column-prospects-grid">
                                <div className="admins-table">
                                    <InvitedAdminsTable data={adminsRows} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        admins: state.admins,
    }
})(InviteAdmins)
