/* eslint-disable camelcase */
import { Button, List } from 'antd';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Clarity } from '../../../assets/img/svg/clarity_pinned-solid.svg';
import { ReactComponent as Close } from '../../../assets/img/svg/close.svg';
import { useAppSelector } from '../../hooks/store';
import { CHAT_MESSAGES_INFO, UNPIN_MESSAGE } from '../../redux/actions/chat/messagingActions';
import { openNotificationModal } from '../../redux/actions/notification-modal/notification-modal-actions';
import { MessageContent } from '../message-content';
var PinnedMessageContainer = function (_a) {
    var dialogId = _a.dialogId;
    var pinnedMessages = useAppSelector(function (state) { return state.chatMessages.pinnedMessages; });
    var dispatch = useDispatch();
    var data = useMemo(function () {
        return pinnedMessages.map(function (message) {
            return {
                title: message.user.name,
                description: React.createElement(MessageContent, { text: message.content.text }),
                id: message.id,
                dialogId: message.dialog_id,
            };
        });
    }, [pinnedMessages]);
    var onUnpinConfirm = function (e, messageId) {
        e.stopPropagation();
        dispatch(openNotificationModal(UNPIN_MESSAGE, { messageId: messageId, dialogId: dialogId }));
    };
    var getMessage = function (messageId) {
        var messageElement = document.querySelector("[id='".concat(messageId, "']"));
        if (messageElement) {
            var child_1 = messageElement.firstElementChild;
            messageElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            child_1 === null || child_1 === void 0 ? void 0 : child_1.classList.add('highlight-repoted-message');
            setTimeout(function () { return child_1 === null || child_1 === void 0 ? void 0 : child_1.classList.remove('highlight-repoted-message'); }, 2000);
        }
        else {
            dispatch({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: dialogId,
                    blockReloadChat: true,
                    isBlockedScroll: true,
                    loaderDisabled: true,
                    aroundMessageId: messageId,
                },
                onFinish: function () {
                    var messageElement = document.querySelector("[id='".concat(messageId, "']"));
                    if (messageElement) {
                        var child_2 = messageElement.firstElementChild || messageElement.firstChild;
                        messageElement.scrollIntoView({ block: 'nearest', inline: 'start' });
                        child_2.classList.add('highlight-repoted-message');
                        setTimeout(function () { return child_2.classList.remove('highlight-repoted-message'); }, 2000);
                    }
                },
            });
        }
    };
    if (pinnedMessages.length === 0) {
        return null;
    }
    return (React.createElement(List, { itemLayout: "horizontal", className: "pined-messages-container", dataSource: data, renderItem: function (item) { return (React.createElement(List.Item, { onClick: function () { return getMessage(item.id); } },
            React.createElement(List.Item.Meta, { avatar: React.createElement(Clarity, null), title: item.title, description: item.description }),
            React.createElement(Button, { type: "link", onClick: function (e) { return onUnpinConfirm(e, item.id); } },
                React.createElement(Close, { className: "unpin-button" })))); } }));
};
export default PinnedMessageContainer;
