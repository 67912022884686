import React from 'react';
import { Select } from 'antd';
var LeadSelect = function (_a) {
    var onChangeLeadType = _a.onChangeLeadType;
    var options = [
        { value: 'tap_feed', label: 'Tap feed' },
        { value: 'ambassadors_list', label: 'List of ambassadors' },
        { value: 'content', label: 'Content' },
        { value: 'live_streams', label: 'Live streams' },
        { value: 'faq', label: 'FAQ' },
        // { value: 'tracked_link', label: 'Tracked link' },
        { value: 'group_chat', label: 'Group chat' },
        { value: 'single_chat', label: 'Individual chat' },
    ];
    return (React.createElement(Select, { defaultValue: "tap_feed", size: 'large', style: { width: 'calc(80% + 2rem + 2px)', marginBottom: '2.5rem' }, onChange: onChangeLeadType, options: options }));
};
export default LeadSelect;
