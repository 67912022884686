import React, { PureComponent } from 'react'

class ActionPopup extends PureComponent {
    render() {
        const { handleClose, children, title, body } = this.props

        if (children) {
            return children
        } else if (title || body) {
            return (
                <div className="general-popup" id="verification">
                    <h2 className="modal-title">{title}</h2>
                    <p className="subtitle text-footer-modal">{body}</p>
                    <button type="submit" className="el-btn general-action-button" onClick={handleClose}>
                        Close
                    </button>
                </div>
            )
        } else {
            return ''
        }
    }
}

export default ActionPopup
