import store from '../../../../redux/store'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { Button } from 'antd'
import Loader from '../../../content/components/Loader'
import AlertIcon from '../../../../../assets/svg/icons/alert.svg'
import { ReactComponent as VolumeMuted } from '../../../../../assets/svg/volume-mute.svg'
import { SET_ACTIVE_CHAT, CHATS_INFO_SEARCH, CHAT_MESSAGES_INFO } from '../../../../redux/actions/chat/messagingActions'
import SearchFilters from '../../../../components/messages/SearchFilters'
import BlockProspectPopover from '../../../../components/BlockProspectPopover'
import { HIDE_NEW_MESSAGES, REPLY_MESSAGE } from '../../../../redux/actions/components/componentsActions'

import dayjs from 'dayjs'
import cn from 'classnames'

import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

class ChatsSidebar extends PureComponent {
    constructor(props) {
        super(props)

        this.handleChatClick = this.handleChatClick.bind(this)
        this.handleShowMoreButtonClick = this.handleShowMoreButtonClick.bind(this)
    }

    handleChatClick(event) {
        const { chats } = this.props
        const chatId = +event.target.closest('.chat-nav-item').dataset.chatId

        store.dispatch({
            type: REPLY_MESSAGE,
            payload: {
                activeMessage: null,
                activePublisher: null,
            },
        })
        store.dispatch({
            type: SET_ACTIVE_CHAT,
            payload: {
                data: chats.items.find((item) => item.id === chatId),
                chat_id: chatId,
                chats_type: chats.chatsType,
                search: chats.search,
            },
        })

        store.dispatch({
            type: CHAT_MESSAGES_INFO,
            payload: {
                dialog_id: chatId,
                type: chats.chatsType,
                blockReloadChat: true,
                resetMessagesCounter: true,
            },
        })

        store.dispatch({
            type: HIDE_NEW_MESSAGES,
        })
    }

    handleShowMoreButtonClick() {
        store.dispatch({
            type: CHATS_INFO_SEARCH,
            payload: {
                offset: this.props.chats.lastOffset + 100,
            },
        })
    }

    render() {
        const { chats, activeDialog, chatMessages } = this.props
        if (chats.isLoading && chats.isFirstRequest) {
            return (
                <div className="chat-nav">
                    <Loader />
                </div>
            )
        }
        const chatsView = chats.items.map((chat, index) => {
            if (!chat.prospect && chats.chatsType === 1) return ''

            const lastMessage = {
                text: '',
                created_at: dayjs(chat.last_activity).calendar(null, {
                    sameDay: 'H:mm',
                    nextDay: '[Tomorrow]',
                    nextWeek: '[Next] dddd',
                    lastDay: '[Yesterday at ]H:mm',
                    lastWeek: 'DD MMMM',
                    sameElse: 'DD MMMM',
                }),
            }

            if (chat.search_message) {
                lastMessage.text =
                    chat.search_message.content.text &&
                    typeof chat.search_message.content.text === 'string' &&
                    chat.search_message.content.text.replace(/<\/?a.*?>/gm, '')
                if (lastMessage.text) {
                    lastMessage.text = lastMessage.text.replace(/\[\/?badword]/g, '')
                    lastMessage.text = '' + lastMessage.text
                    if (lastMessage.text.length > 50) lastMessage.text = lastMessage.text.slice(0, 50)
                }
            }

            if (chat.last_message) {
                switch (chat.last_message.type) {
                    case 'text':
                    case 'autotext': {
                        lastMessage.text =
                            chat.last_message.content?.text &&
                            typeof chat.last_message.content.text === 'string' &&
                            chat.last_message.content.text.replace(/<\/?a.*?>/gm, '')
                        if (lastMessage.text) {
                            lastMessage.text = lastMessage.text.replace(/\[\/?badword]/g, '')
                            lastMessage.text = '' + lastMessage.text
                            if (lastMessage.text.length > 50) lastMessage.text = lastMessage.text.slice(0, 50)
                        }

                        break
                    }
                    case 'joined-admin': {
                        lastMessage.text = chat.last_message.content.user + ' joined the chat'

                        break
                    }
                    case 'joined': {
                        lastMessage.text =
                            chat.last_message.content.user +
                            ' added ' +
                            chat.last_message.content.ambassador +
                            ' the chat'

                        break
                    }
                    case 'flagged': {
                        lastMessage.text = 'Reported'

                        break
                    }
                    // No default
                }
            }

            let ambassadorName = null

            const currUser = chats.chatsType === 1 ? chat.prospect : chat.ambassador

            chat.members.map((member) => {
                if (
                    member.id !== currUser.id &&
                    !ambassadorName &&
                    member.role_key !== 'admin' &&
                    member.role_key !== 'univercity-admin' &&
                    member.role_key !== 'prospect' &&
                    member.role_key !== null
                ) {
                    ambassadorName = `${member.name} ${member.last_name}`
                }
            })

            ambassadorName = !ambassadorName ? 'Deleted User' : ambassadorName

            if (chat.admin_closed || chat.closed) {
                return (
                    <div
                        key={chat.id}
                        className={chatMessages.dialog_id == chat.id ? 'chat-nav-item is-active' : 'chat-nav-item'}
                        data-chat-id={chat.id}
                        data-chat-array-index={index}
                        onClick={this.handleChatClick}
                    >
                        <div className="chat-nav-info">
                            <h3 className="chat-nav-name d-inline-block">{`${currUser.name} ${currUser.last_name}`}</h3>
                            {ambassadorName && (
                                <span className="chat-nav-sa">
                                    <span className="chat-nav-sa-and">and</span>
                                    <span className="chat-nav-sa-name">{ambassadorName}</span>
                                </span>
                            )}
                            <p className="chat-nav-text">Closed</p>
                        </div>
                        <div className="chat-nav-data">
                            <div className="chat-icons-group">
                                <p className={chat.new_messages > 0 ? 'chat-nav-msg-counter' : 'hidden-block'}>
                                    {chat.new_messages}
                                </p>
                            </div>
                            <p className="chat-nav-date">{lastMessage.created_at}</p>
                        </div>
                    </div>
                )
            }

            return (
                <div
                    key={chat.id}
                    className={cn('chat-nav-item', {
                        'is-active': chatMessages.dialog_id == chat.id,
                        'admin-involved': chat.is_admin_involved,
                    })}
                    data-chat-id={chat.id}
                    data-chat-array-index={index}
                    onClick={this.handleChatClick}
                >
                    <div className="chat-nav-info">
                        <div className="chat-nav-name-container">
                            <h3 className="chat-nav-name chat-nav-name-messaging">
                                <span>{`${currUser.name} ${currUser.last_name}`}</span>
                                {chat.is_mute && <VolumeMuted />}
                            </h3>
                            {chat.isBlocked ? (
                                <BlockProspectPopover
                                    className="chat-blocked-button"
                                    userId={chat.prospect.id}
                                    chatType={1}
                                />
                            ) : null}
                        </div>
                        {ambassadorName && (
                            <span className="chat-nav-sa">
                                <span className="chat-nav-sa-and">and</span>
                                <span className="chat-nav-sa-name">{ambassadorName}</span>
                            </span>
                        )}
                        <p className="chat-nav-text">{lastMessage.text}</p>
                    </div>
                    <div className="chat-nav-data">
                        <div className="chat-icons-group">
                            <p
                                className={
                                    (chat.new_messages > 0 && chat.id !== activeDialog
                                        ? 'chat-nav-msg-counter'
                                        : 'hidden-block') + ' chat-group-item'
                                }
                            >
                                {chat.new_messages}
                            </p>
                            {chat.reported && (
                                <img className="chat-group-item flaged-icon" src={AlertIcon} alt="Reported" />
                            )}
                        </div>
                        <p className="chat-nav-date">{lastMessage.created_at}</p>
                    </div>
                </div>
            )
        })

        const showMoreButton = (
            <Button className="show-more-dialogs-button" type="link" onClick={this.handleShowMoreButtonClick}>
                Show more conversations
            </Button>
        )

        return (
            <div className="chat-nav">
                <div>
                    <SearchFilters />
                </div>
                <div
                    className={
                        chats.isLoading || !chats || !chats.items || chats.items.length === 0 ? '' : 'chat-nav-list'
                    }
                >
                    {chats.isLoading ? (
                        <Loader />
                    ) : chats.items.length > 0 ? (
                        chatsView
                    ) : (
                        <div className="no-dialog-found">No search results</div>
                    )}
                    {!chats.isLoading && chats.lastReceivedCount === 100 && showMoreButton}
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        chats: state.chats,
        chatMessages: state.chatMessages,
        account: state.account,
    }
})(ChatsSidebar)
