import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as UsersIcon } from '../../../../../assets/svg/icons/users.svg'
import { LIVE_EVENT_STATUS } from '../../../../common/constants'
import { parseDateFromServer } from '../../../../common/utils/date-time-utils'

const FROM_FORMAT = 'dd MMMM yyyy, HH:mm'
const TO_FORMAT = 'HH:mm'

const EventCard = ({ event, to }) => {
    const { fromDate, toDate, name, image, status, totalProspects } = event

    const fromTime = useMemo(() => {
        const parsed = parseDateFromServer(fromDate)

        return format(parsed, FROM_FORMAT)
    }, [fromDate])

    const toTime = useMemo(() => {
        const parsed = parseDateFromServer(toDate)

        return format(parsed, TO_FORMAT)
    }, [toDate])

    const cancelledMark = useMemo(
        () => status === LIVE_EVENT_STATUS.CANCELLED && <span className="cancelled-event-text">(Cancelled)</span>,
        [status]
    )

    return (
        <div className="event-item">
            <Link to={to} className="image-event-block">
                <img
                    src={image.sizes && image.sizes['360x360'] ? image.sizes['360x360'] : image.original}
                    alt="Event Image"
                />
            </Link>
            <div className="event-item-description">
                <Link to={to} className="live-event-name">{`${name}`}</Link>
                <p className="live-event-date">
                    {fromTime + ' - ' + toTime} {cancelledMark}
                </p>
                <div className="prospects-number-block">
                    <UsersIcon />
                    <span>{totalProspects} Prospects</span>
                </div>
            </div>
        </div>
    )
}

export default EventCard
