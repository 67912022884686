import { Empty, Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { clearItems, getMorePostsAsync, getNewPostsAsync, getPosts } from 'src/app/redux/actions/content/postsActions';
import { toMapPairs } from 'src/app/utils/list';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { usePusher } from '../../../../../hooks/usePusher';
import { getAccountInfo, selectUniversityId } from '../../../../../redux/selectors/account-selectors';
import { selectPosts } from '../../../../../redux/selectors/posts-selectors';
import { LoadingFooter } from './LoadingFooter';
import { NewPostsButton } from './NewPostsButton';
import { PostsGroupItem } from './PostsGroupItem';
export var PostsList = function () {
    var dispatch = useAppDispatch();
    var accountInfo = useAppSelector(getAccountInfo);
    var adminUniversityId = useAppSelector(selectUniversityId);
    var _a = useAppSelector(selectPosts), isLoading = _a.isLoading, hasMore = _a.hasMore, total = _a.total;
    var uiData = useAppSelector(function (state) { return state.posts.uiData; });
    var history = useHistory();
    var scrollTop = useRef(0);
    var virtuoso = useRef(null);
    var _b = useState([]), postGroups = _b[0], setPostGroups = _b[1];
    var _c = useState(false), showNewPostsButton = _c[0], setShowNewPostsButton = _c[1];
    var universityId = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university.id;
    useEffect(function () {
        setPostGroups(toMapPairs(uiData));
    }, [uiData]);
    useEffect(function () {
        dispatch(getPosts({
            filter: {
                university_id: universityId,
            },
            limit: 20,
            offset: 0,
            withGlobalTags: true,
            sort: {
                created_at: 'desc',
            },
        }));
        return function () {
            dispatch(clearItems());
        };
    }, [dispatch, universityId]);
    var loadMore = useCallback(function () {
        if (!total || !universityId || !hasMore)
            return;
        dispatch(getMorePostsAsync({
            filter: {
                university_id: universityId,
            },
        }));
    }, [dispatch, universityId, total, hasMore]);
    var handleAtBottomStateChange = useCallback(function (atBottom) {
        if (atBottom && !isLoading && hasMore) {
            loadMore();
        }
    }, [loadMore, hasMore, isLoading]);
    usePusher("posts:new_exists:".concat(adminUniversityId), function () {
        dispatch(getNewPostsAsync({
            filter: {
                university_id: universityId,
            },
        })).then(function () {
            if (scrollTop.current > 200) {
                setShowNewPostsButton(true);
            }
        });
    });
    var handleScroll = useCallback(function (event) {
        scrollTop.current = event.currentTarget.scrollTop || 0;
        if (showNewPostsButton && scrollTop.current < 50) {
            setShowNewPostsButton(false);
        }
    }, [showNewPostsButton]);
    var onPostClick = function (post) {
        history.push({
            hash: "#post-preview/".concat(post.id),
        });
    };
    var onShareClick = function (post) {
        history.push({
            hash: "#share-post/".concat(post.id),
            state: post,
        });
    };
    if (isLoading && postGroups.length === 0) {
        return (React.createElement("div", { className: "flex justify-center items-center p-6 flex-1" },
            React.createElement(Spin, { size: "large" })));
    }
    return postGroups.length === 0 ? (React.createElement("div", { className: "flex justify-center items-center p-6 flex-1 text-2xl font-semibold" },
        React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: "No posts found" }))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "list px-5 pb-6" },
            React.createElement(Virtuoso, { ref: virtuoso, totalCount: postGroups.length, endReached: loadMore, components: { Footer: hasMore ? LoadingFooter : undefined }, onScroll: handleScroll, atBottomStateChange: handleAtBottomStateChange, itemContent: function (index) {
                    return (React.createElement(PostsGroupItem, { title: postGroups[index][0], posts: postGroups[index][1], onPostClick: onPostClick, onShareClick: onShareClick }));
                } })),
        React.createElement(NewPostsButton, { showButton: showNewPostsButton, onClick: function () {
                var _a;
                setShowNewPostsButton(false);
                (_a = virtuoso.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                    behavior: 'smooth',
                    top: 0,
                });
            } })));
};
