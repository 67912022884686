import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import store from '../../../../../redux/store'
import { EDIT_CHAT } from '../../../../../redux/actions/chat/messagingActions'
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions'
import { CLOSE_ACTION_MODAL } from '../../../../../redux/actions/components/componentsActions'

import SettingsChatForm from './../forms/SettingsChatForm'

// we are using it for chats too
class SettingsChat extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            chat: null,
            isConfirmationPopupOpened: false,
            hasFormBeenUpdated: false,
        }

        this.closeConfirmationPopup = this.closeConfirmationPopup.bind(this)
        this.handleSearchUpdated = this.handleSearchUpdated.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleEscPress = this.handleEscPress.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.setFormUpdatedStatus = this.setFormUpdatedStatus.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    componentDidMount() {
        const { chats, currentChat } = this.props

        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
            },
        })

        let chat = null
        if (chats.items) {
            chat = chats.items.find((item) => +item.id === currentChat.activeChat)
        }
        this.setState({
            chat: chat,
        })

        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('keydown', this.handleEscPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('keydown', this.handleEscPress, false)
    }

    closeConfirmationPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })

        this.setState({ isConfirmationPopupOpened: false })
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            this.state.hasFormBeenUpdated &&
            !this.state.isConfirmationPopupOpened
        ) {
            const confirmByClickOutside = confirm(
                'Are you sure you want to close this popup? Changes will not be saved'
            )
            if (confirmByClickOutside) {
                this.props.onClosePopup()
            }
        }
    }

    handleEscPress(event) {
        const escapeButtonCode = 27
        if (event.keyCode !== escapeButtonCode) {
            return
        }

        if (this.state.isConfirmationPopupOpened) {
            return
        }

        if (!this.state.hasFormBeenUpdated) {
            this.props.onClosePopup()
            return
        }

        const confirmByEscPress = confirm('Are you sure you want to close this popup? Changes will not be saved')
        if (confirmByEscPress) {
            this.props.onClosePopup()
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    handleSearchUpdated(value) {
        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    keyword: value,
                    permission: 'chat',
                },
            },
        })
    }

    setFormUpdatedStatus(value) {
        this.setState({ hasFormBeenUpdated: value })
    }

    handleFormSubmit(values) {
        const { account } = this.props

        store.dispatch({
            type: EDIT_CHAT,
            payload: {
                name: this.state.chat.name,
                users: values.selectedUserIds,
                avatar: null,
                dialog_id: this.state.chat.id,
                university_id: account.account_info.university_id,
            },
        })
    }

    render() {
        const { onClosePopup, usersMessaging } = this.props
        const { chat, hasFormBeenUpdated } = this.state

        if (!chat) return null

        return (
            <div ref={this.setWrapperRef}>
                <div className="create-content-group">
                    <h2 className="modal-title">Manage ambassadors</h2>
                    <SettingsChatForm
                        chat={chat}
                        onCancelButtonClick={onClosePopup}
                        onSubmit={this.handleFormSubmit}
                        onSearchUpdated={this.handleSearchUpdated}
                        ambassadors={usersMessaging.ambassadors}
                        areAmbassadorsLoading={usersMessaging.isLoading}
                        // TODO: check why this init doesn't work
                        setFormUpdatedStatus={this.setFormUpdatedStatus}
                        hasFormBeenUpdated={hasFormBeenUpdated}
                    />
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        chats: state.chats,
        currentChat: state.currentChat,
        account: state.account,
        usersMessaging: state.usersMessaging,
    }
})(SettingsChat)
