/* eslint-disable react/no-unknown-property */
import CryptoJS from 'crypto-js'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import store from '../../../redux/store'

import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

import { AmbassadorsInviteTemplate } from './components/AmbassadorsInviteTemplate'
import { AmbassadorInviteSelect } from './components/AmbassadorInviteSelect'
import { INVITE_AMBASSADORS_TYPES } from './types'

class Invite extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            radioValue: 7,
            value:
                this.props.account && this.props.account.account_info && this.props.account.account_info.university
                    ? this.props.account.account_info.university.access_code
                    : '',
            copied: false,
            inviteType: INVITE_AMBASSADORS_TYPES.TAP,
        }
        this.handleChange = this.handleChange.bind(this)
        this.onInviteSelect = this.onInviteSelect.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.setState({
                value:
                    this.props.account && this.props.account.account_info && this.props.account.account_info.university
                        ? this.props.account.account_info.university.access_code
                        : '',
            })
        }
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
        })
    }

    handleRadioButton(radioValue) {
        this.setState({
            radioValue: radioValue,
        })
    }

    handleFocus = (event) => event.target.select()

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
    }

    handleCopyAccessCode() {
        const copyText = document.querySelector('#university-access-code')
        copyText.select()
        document.execCommand('copy')

        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'Institution code copied to clipboard',
            },
        })
    }

    onInviteSelect(value) {
        this.setState({ inviteType: value })
    }

    render() {
        const { account } = this.props
        const { inviteType } = this.state
        const unikey = 'NcRfUjXn2r5u8x/A%D*G-KaPdSgVkYp3'
        const code = this.state.value + this.state.radioValue
        // this isn't secure but it's fine for generating ambassador links with basic obfuscation for the average user
        // eslint-disable-next-line no-undef
        const link = btoa(CryptoJS.AES.encrypt(code, unikey).toString())

        const settingsCodeView = []
        if (
            account &&
            account.account_info &&
            account.account_info.university &&
            account.account_info.university.access_code
        ) {
            account.account_info.university.access_code.split('').forEach((char) => {
                settingsCodeView.push(
                    <input type="text" disabled="disabled" className="settings-code-input" value={char} />
                )
            })
        }

        return (
            <div id="page-body" className="page-body js-index">
                <section className="content settings">
                    <h3 className="settings-title">Invite ambassadors to download the mobile app</h3>

                    <div className="settings-grid has-tutorial">
                        <div className="column">
                            <div className="tutorial-intro">
                                <p>
                                    When an ambassador downloads our app and creates an account to represent you, you
                                    will need to &apos;activate&apos; them for the features (Chat, Content and FAQ) that
                                    you want them to access. You should send ambassadors a link to the Ambassador
                                    Training Center so that they can learn the basics of TAP and get their access code
                                    to sign up — they won&apos;t get far without it 😊
                                </p>
                                <p>
                                    We&apos;ve created lots of&nbsp;
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://knowledge.theambassadorplatform.com"
                                    >
                                        helpful resources
                                    </a>
                                    &nbsp; to make The Ambassador Platform successful for you, regardless of the stage
                                    of your ambassador programme. Have a read through, and get in touch if we can help
                                    with anything more specific. We have a lot of expertise in the team and are here to
                                    help.
                                </p>
                                {/* {
                                       isRecruitingUniversity
                                           ? (
                                               <>
                                                   <hr className="settings-hr"></hr>
                                                   <div className="settings-item">
                                                       <h4 className="settings-name">Add company names</h4>
                                                       <p>Before you invite ambassadors to the Ambassador Training Center, please add the relevant company names for your ambassadors to choose from when they create their profile.</p>
                                                       <CompaniesControl />
                                                   </div>
                                               </>
                                           ) : null
                                   } */}
                                <hr className="settings-hr"></hr>
                                <div className="settings-item">
                                    <h4 className="settings-name">Ambassador Training Center</h4>
                                    <AmbassadorInviteSelect onInviteSelect={this.onInviteSelect} />
                                    {/* <p>
                                        Generate a bespoke Training Center link by choosing the training flow of videos
                                        and quizzes that you would like your ambassadors to follow
                                    </p>
                                    <div className="radios" data-toggle="buttons">
                                        <label>
                                            <input
                                                type="radio"
                                                checked={this.state.radioValue === 7}
                                                onChange={() => this.handleRadioButton(7)}
                                            />
                                            All features
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                checked={this.state.radioValue === 3}
                                                onChange={() => this.handleRadioButton(3)}
                                            />
                                            Chat only
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                checked={this.state.radioValue === 1}
                                                onChange={() => this.handleRadioButton(1)}
                                            />
                                            Chat and Content
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                checked={this.state.radioValue === 2}
                                                onChange={() => this.handleRadioButton(2)}
                                            />
                                            Chat and FAQ
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                checked={this.state.radioValue === 5}
                                                onChange={() => this.handleRadioButton(5)}
                                            />
                                            Content only
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                checked={this.state.radioValue === 4}
                                                onChange={() => this.handleRadioButton(4)}
                                            />
                                            Content and FAQ
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                checked={this.state.radioValue === 6}
                                                onChange={() => this.handleRadioButton(6)}
                                            />
                                            FAQ only
                                        </label>
                                    </div>
                                    <h4 className="settings-name">Your Ambassador Training Center link</h4>
                                    <div
                                        className="training-center-code"
                                        text={'https://training.theambassadorplatform.com/?flow=' + link}
                                    >
                                        <span>{'https://training.theambassadorplatform.com/?flow=' + link}</span>
                                    </div> */}
                                </div>
                                <div className="settings-item">
                                    <h4 className="settings-name">Testing the app</h4>
                                    <p>
                                        Admin accounts to access the dashboard are separate from ambassador app
                                        accounts. You will not be able to access the mobile app using the email address
                                        that you use to access the dashboard. To test the app and see what your
                                        ambassadors will see, you can use a dummy email address or a secondary email
                                        address, if you have one
                                    </p>
                                </div>

                                <div className="settings-item">
                                    <h4 className="settings-name">Ambassador invite template</h4>
                                    <p>This email template might be useful to get you started</p>
                                    <AmbassadorsInviteTemplate
                                        usniversityName={account.account_info.university.name}
                                        inviteType={inviteType}
                                        trainingCenterLink={`https://training.theambassadorplatform.com/?flow=${link}`}
                                    />
                                    {/* 
                                    <div className="legal-text">
                                        <p>
                                            Hello <span className="insert-item">[insert first name]</span>,
                                        </p>
                                        <p>
                                            We want to give prospects an authentic insight into the experience of being
                                            part of{' '}
                                            <span className="insert-item">{account.account_info.university.name}</span>{' '}
                                            and we need your help!
                                        </p>
                                        <p>
                                            We’re inviting you to create a profile on The Ambassador Platform (TAP).
                                            Through TAP we’ll be working with you to:
                                        </p>
                                        <p>
                                            <ul className="invite-list">
                                                <li>
                                                    Create user-generated content which can be shared across different
                                                    marketing channels
                                                </li>
                                                <li>Create answers to commonly-asked questions</li>
                                                <li>
                                                    Facilitate peer-to-peer conversations between yourself and
                                                    prospects, either one to one or in a group setting
                                                </li>
                                            </ul>
                                        </p>
                                        <p>
                                            To help you find out more about The Ambassador Platform and why we're using
                                            it, please take a look at this{' '}
                                            <a
                                                href="https://knowledge.theambassadorplatform.com/what-is-the-ambassador-platform"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="orange-link"
                                            >
                                                brief overview
                                            </a>
                                            .
                                        </p>
                                        <p>In order to get set up, please do the following:</p>
                                        <p>
                                            <ul className="invite-list">
                                                <li>
                                                    Visit the{' '}
                                                    <a
                                                        href={
                                                            'https://training.theambassadorplatform.com/?flow=' + link
                                                        }
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        Ambassador Training Center
                                                    </a>{' '}
                                                    to learn about how to carry out your work on The Ambassador Platform
                                                </li>
                                                <li>
                                                    Download 'The Ambassador Platform' app from the{' '}
                                                    <a
                                                        href="https://tap.st/ios"
                                                        alt="The Ambassador Platform App Store link"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        App
                                                    </a>
                                                    /
                                                    <a
                                                        href="https://tap.st/android"
                                                        alt="The Ambassador Platform Play Store link"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        Play
                                                    </a>{' '}
                                                    Store
                                                </li>
                                                <li>
                                                    Create an account in the app using the code you received when you
                                                    completed training
                                                </li>
                                                <li>Complete your profile to the best of your ability</li>
                                                <li>
                                                    Take some time to watch the videos in the ‘Learning Resources’ area
                                                    to learn more about the service
                                                </li>
                                            </ul>
                                        </p>
                                        <p>
                                            I hope that makes sense and sounds fun. Let me know if you have any
                                            questions.
                                        </p>
                                    </div> */}
                                </div>

                                <div className="settings-item">
                                    <h4 className="settings-name">Optional - ambassador registration code</h4>
                                    <p>
                                        If you do not wish to use the Ambassador Training Center, give this code to your
                                        ambassadors when inviting them to sign up for the mobile app
                                    </p>

                                    <div className="settings-code-block">
                                        <input
                                            id="university-access-code"
                                            className="university-access-code"
                                            type="text"
                                            value={account.account_info.university.access_code}
                                        />
                                        &nbsp;
                                        {settingsCodeView}
                                        <button
                                            type="button"
                                            className="university-submit"
                                            onClick={this.handleCopyAccessCode}
                                        >
                                            Copy
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="column resources">
                            <h3 className="video-title">Video tutorial: Inviting ambassadors</h3>
                            <div className="video-wrapper">
                                <iframe
                                    src="https://player.vimeo.com/video/429930484"
                                    width="640"
                                    height="360"
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    allowFullScreen
                                ></iframe>
                            </div>

                            <div className="resources-box">
                                <h3>Helpful resources</h3>
                                <ul>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/how-to-build-a-successful-ambassador-program"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            alt="Recruiting ambassadors"
                                        >
                                            Recruiting and Building an Ambassador Program
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/how-to-incentivize-and-manage-your-ambassadors"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            alt="Incentivising ambassadors"
                                        >
                                            Incentivizing your Ambassadors
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/how-to-train-your-ambassadors"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            alt="Training your ambassadors"
                                        >
                                            Training your Ambassadors
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/what-is-the-ambassador-platform"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            &apos;What is The Ambassador Platform?&apos; for ambassadors
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/writing-job-descriptions-for-being-an-ambassador-on-tap"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Advice on Writing Job Descriptions for Ambassadors
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default connect((state) => {
    return { account: state.account }
})(Invite)
