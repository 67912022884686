import Account from './account'
import Campaign from './campaign'
import Chat from './chat'
import { DialogsCommonApi } from './common/dialog'
import Communities from './communities'
import Dashboard from './dashboard'
import DashboardNew from './dashboard/new'
import Dialog from './dialog'
import Faq from './faq'
import Home from './home'
import LiveEvent from './live-event'
import LiveStreams from './live-streams'
import PlsSignUp from './psl-sign-up'
import Reporting from './reporting'
import Settings from './settings'
import SettingsNew from './settings/new'
import Tag from './tag'
import User from './user'
import DashboardGroup from './dashbord-group'
import IdpService from './idp-services'

const API = {
    account: Account,
    dashboard: Dashboard,
    dashboardNew: DashboardNew,
    settings: Settings,
    settingsNew: SettingsNew,
    chat: Chat,
    dialog: Dialog,
    liveEvent: LiveEvent,
    liveStreams: LiveStreams,
    faq: Faq,
    tag: Tag,
    home: Home,
    reporting: Reporting,
    user: User,
    plsSignUp: PlsSignUp,
    campaign: Campaign,
    communities: Communities,
    common: {
        dialogs: DialogsCommonApi,
    },
    dashboardGroup: DashboardGroup,
    idpService: IdpService,
}

export default API
