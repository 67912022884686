import React, { useState, useEffect } from 'react'
import { Input, Checkbox, Empty } from 'antd'

import store from '../../../../redux/store'

import { SET_DEFAULT_BADWORDS, SHOW_SAVE_BUTTON } from '../../../../redux/actions/settings/safeguardingActions'

const SearchIcon = () => {
    return (
        <div className="keyword-input-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <path
                    fill="#262e45"
                    d="M7.036 12.312a5.277 5.277 0 1 0-5.277-5.276 5.277 5.277 0 0 0 5.277 5.277zm5.558-.962l3.148 3.15a.879.879 0 1 1-1.242 1.241l-3.148-3.149a7.036 7.036 0 1 1 1.244-1.244z"
                    transform="translate(.001 .001)"
                />
            </svg>
        </div>
    )
}

const Keywords = ({ keywords, searchText, setKeywordList, showSaveBtn }) => {
    const [checkedList, setCheckedList] = useState(keywords)
    const [checkAll, setCheckAll] = useState(checkedList.filter((item) => item.is_active).length === checkedList.length)

    useEffect(() => {
        setKeywordList(checkedList)
    }, [checkedList, searchText])

    useEffect(() => {
        return () => {
            store.dispatch({
                type: SHOW_SAVE_BUTTON,
                payload: false,
            })
        }
    }, [])

    const onChange = (list) => {
        setCheckedList(
            checkedList.map((item) => {
                if (item.name === list.target.value) {
                    item.is_active = list.target.checked
                }
                return item
            })
        )

        setCheckAll(checkedList.filter((item) => item.is_active).length === checkedList.length)

        store.dispatch({
            type: SHOW_SAVE_BUTTON,
            payload: true,
        })
    }

    const onCheckAllChange = (e) => {
        const isAllChecked = checkedList.map((item) => {
            if (e.target.checked) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })

        setCheckedList(isAllChecked)
        setCheckAll(e.target.checked)

        store.dispatch({
            type: SHOW_SAVE_BUTTON,
            payload: true,
        })
    }

    const filteredKeywords = checkedList.filter((keyword) => {
        if (searchText.length === 1) {
            return keyword.name.charAt(0).toLowerCase() === searchText.toLowerCase()
        }
        return keyword.name.toLowerCase().includes(searchText.toLowerCase())
    })

    return (
        <div className="keywords-items-container">
            {filteredKeywords.length > 0 ? (
                <>
                    <div className="first-item">
                        <Checkbox onChange={onCheckAllChange} checked={checkAll}>
                            {checkAll ? 'Unselect All' : 'Select All'}
                        </Checkbox>
                    </div>
                    <div className={`items-group ${showSaveBtn && 'save-btn-displayed'}`}>
                        {filteredKeywords.map((item) => {
                            return (
                                <Checkbox
                                    className="ant-checkbox-group-item"
                                    key={item.id}
                                    onChange={onChange}
                                    checked={item.is_active}
                                    value={item.name}
                                >
                                    {item.name}
                                </Checkbox>
                            )
                        })}
                    </div>
                </>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </div>
    )
}

const DefaultKeywordsList = ({ dataList, showSaveBtn }) => {
    const [searchText, setSearchText] = useState('')
    const [keywordList, setKeywordList] = useState([])

    const onSearch = (e) => {
        setSearchText(e.target.value)
    }

    const saveChanges = () => {
        store.dispatch({
            type: SET_DEFAULT_BADWORDS,
            payload: keywordList.filter((keyword) => keyword.is_active).map((item) => item.id),
        })
    }

    return (
        <div className="keyword-list-container">
            <h4 className="settings-name">Default keywords</h4>
            <div className="keywords-list-wrapper">
                <Input allowClear placeholder="Type to search" onChange={onSearch} prefix={<SearchIcon />} />
                <Keywords
                    keywords={dataList}
                    searchText={searchText}
                    setKeywordList={setKeywordList}
                    showSaveBtn={showSaveBtn}
                />
                {showSaveBtn ? (
                    <div className="save-btn-container">
                        <button type="button" className="save-btn" onClick={saveChanges}>
                            Save changes
                        </button>
                    </div>
                ) : null}
            </div>
            <div className="data-control-description">
                Selected words from the 'Default keywords' table above will be shown as *** in a chat if keyword
                blocking is on. Unselected words will be displayed in conversations.
            </div>
        </div>
    )
}

export default DefaultKeywordsList
