import { connect } from 'react-redux'
import React, { PureComponent } from 'react'

import store from '../../../redux/store'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import types, {
    fetchAmbassadorsReports,
    changeAmbassadorIntroduction,
    changeAmbassadorDescription,
} from '../../../redux/actions/content/ambassadorsActions'
import { OPEN_ACTION_MODAL, CLOSE_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'
import Modal from 'antd/es/modal'
import ConfirmBanPopup from '../../SA list/components/UsersTable'
import AmbassadorInfoPopup from '../../../components/popups/ambassador-info-popup/AmbassadorInfoPopup'
import { getDateFromMonday, getDateFromStartMonth } from '../../../helpers/helpers'
import dayjs from 'dayjs'
import { ModalLimit } from './components/Modals'
import { TimeList } from './components/TimeList'
import { TimeTable, TimeTableHead, FilterSelect } from './components/TimeTable'
import ReportingTimeFilter from './components/TimeFilter'

class ReportingTime extends PureComponent {
    calculateStats = (value, returnNull) => {
        if (!value) return returnNull ? 0 : '-'
        return value
    }

    static userAvatar({ avatar, name, id }, avatarSize = '') {
        if (avatar) {
            const avatarSrc = avatarSize === 'smallAvatar' ? avatar.sizes['70x70'] || avatar.original : avatar.original
            return <img className="table-item-img" src={avatarSrc} alt="img" />
        } else {
            return (
                <div
                    className={`table-item-img table-item-img-name ${avatarSize === '' && 'table-item-img-name--big'}`}
                >
                    {name[0]}
                </div>
            )
        }
    }

    state = {
        type: 'month',
        activeFilter: null,
        statsRound: false,
    }

    setStatsRound = (round) => {
        this.setState({ statsRound: round })
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'reporting',
            },
        })

        this.handleChangeTimeFilter()
        this.setState({ filterFrom: null, filterTo: null })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.handleChangeTimeFilter(this.state.filterFrom, this.state.filterTo)
        }
    }

    reloadList = async (type) => {
        const from = type == 'week' ? getDateFromMonday() : getDateFromStartMonth()
        await this.props.fetchAmbassadorsReports(from)
    }

    get totalTitle() {
        const activeFilter = this.state.activeFilter
        if (activeFilter == 'select') {
            return this.state.type == 'week'
                ? 'Total ( Since Monday )'
                : `Total ( since 1st ${dayjs().format('MMMM')} )`
        }
        let result = 'Total'
        if (this.state.filterFrom) {
            result += this.state.filterTo
                ? ` (${dayjs(this.state.filterFrom).format('DD.MM.YYYY')} - ${dayjs(this.state.filterTo).format(
                      'DD.MM.YYYY'
                  )})`
                : ` (from ${dayjs(this.state.filterFrom).format('DD.MM.YYYY')})`
        } else if (this.state.filterTo) {
            result += ` (to ${dayjs(this.state.filterTo).format('DD.MM.YYYY')})`
        }
        return result
    }

    openMTimeLimit = (userId, timeLimit) => {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <ModalLimit
                        user={userId}
                        timeLimit={timeLimit}
                        handleChange={(time, type) => {
                            this.handleChangeTime(userId, time, type)
                        }}
                        handleCancel={this.closeModal}
                    />
                ),
            },
        })
    }

    handleOpenTagsPopup = (user) => {
        const { account, changeAmbassadorIntroduction, changeAmbassadorDescription } = this.props
        const { university } = account.account_info
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                noOutsideClickClose: true,
                children: (
                    <AmbassadorInfoPopup
                        user={user}
                        university={university.name}
                        userAvatar={ReportingTime.userAvatar}
                        handleChangeIntroduction={changeAmbassadorIntroduction}
                        handleChangeDescription={changeAmbassadorDescription}
                        handleCloseUserPopup={this.closeModal}
                    />
                ),
            },
        })
    }

    handleChangeTime = (userId, time, type) => {
        const onSuccess = async () => {
            await this.reloadList(this.state.type)
            this.closeModal()
        }
        store.dispatch({
            type: types.SET_AMBASSADOR_TIME_LIMIT,
            payload: {
                userId,
                time,
                type,
            },
            onSuccess: onSuccess,
        })
    }

    handleChangeTimeFilter = async (from, to) => {
        this.setState({ activeFilter: 'option', filterFrom: from, filterTo: to })
        this.props.fetchAmbassadorsReports(from, to)
    }

    prepareAmbassadors = (ambassadors) => {
        const result = { activeWithLimits: [], active: [], withLimits: [], other: [] }
        const { type, activeFilter } = this.state
        ambassadors
            .map((user) => ({
                id: user.id,
                name: user.name,
                avatar: user.avatar,
                fullInfo: user,
                timeLimit: user.timeLimit,
                roundedFaq: this.calculateStats(user.ambassador_rating ? user.ambassador_rating.faq : null),
                roundedChat: this.calculateStats(user.ambassador_rating ? user.ambassador_rating.chat : null),
                roundedContent: this.calculateStats(user.ambassador_rating ? user.ambassador_rating.content : null),
                roundedLiveEvent: this.calculateStats(user.ambassador_rating ? user.ambassador_rating.liveEvent : null),
                sumTotal: this.sumTotal(user.ambassador_rating),
            }))
            .sort((a, b) => b.sumTotal - a.sumTotal)
            .forEach((item) => {
                if (activeFilter == 'select' && item.timeLimit && item.timeLimit.limit_type != type) return

                if (item.timeLimit && item.sumTotal > 1) result.activeWithLimits.push(item)
                else if (item.sumTotal > 1) result.active.push(item)
                else if (item.timeLimit) result.withLimits.push(item)
                else result.other.push(item)
            })

        if (activeFilter == 'select') {
            result.withLimits.sort((a, b) => {
                return b.timeLimit.limit_minuts - a.timeLimit.limit_minuts
            })
        }

        return [...result.activeWithLimits, ...result.active, ...result.withLimits, ...result.other]
    }

    sumTotal(data) {
        if (!data) {
            return 0
        }
        let total =
            this.calculateStats(data.faq, true) +
            this.calculateStats(data.chat, true) +
            this.calculateStats(data.content, true)
        if (this.state.activeFilter !== 'select') {
            total += this.calculateStats(data.liveEvent, true)
        }
        return total
    }

    closeModal = () => store.dispatch({ type: CLOSE_ACTION_MODAL })

    handleChangeType = async (type) => {
        await this.reloadList(type)
        this.setState({ type, activeFilter: 'select' })
    }

    render() {
        const ambassadors = this.prepareAmbassadors(this.props.ambassadors)

        return (
            <>
                <section className="content settings">
                    <TimeTableHead>
                        <ReportingTimeFilter
                            type="ambassadors"
                            onChecked={this.handleChangeTimeFilter}
                            isActive={this.state.activeFilter == 'option'}
                            fromDate={this.state.filterFrom}
                            toDate={this.state.filterTo}
                        />
                        <FilterSelect
                            handleChoose={this.handleChangeType}
                            isActive={this.state.activeFilter == 'select'}
                            setStatsRound={this.setStatsRound}
                            statsRound={this.state.statsRound}
                            limitsType={this.state.type == 'week' ? 'iso' + this.state.type : this.state.type}
                        />
                    </TimeTableHead>

                    <TimeTable
                        timeLimitsActive={this.state.activeFilter == 'select'}
                        totalTitle={this.totalTitle}
                        isLoading={this.props.ambassadorsLoading}
                    >
                        <TimeList
                            ambassadors={ambassadors}
                            openMTimeLimit={this.openMTimeLimit}
                            timeLimitsActive={this.state.activeFilter == 'select'}
                            choosenLimitType={this.state.type}
                            handleOpenTagsPopup={this.handleOpenTagsPopup}
                            statsRound={this.state.statsRound}
                        ></TimeList>
                    </TimeTable>
                </section>

                <Modal
                    open={this.props.confirmBanModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseConfirmBanPopup}
                >
                    <ConfirmBanPopup
                        ban_type={this.props.confirmBanModal}
                        handleConfirmBan={this.handleConfirmBanSubmit}
                        handleClosePopup={this.handleCloseConfirmBanPopup}
                    />
                </Modal>
            </>
        )
    }
}

const mapStatetoProps = (state) => ({
    account: state.account,
    ambassadors: state.ambassadors.listOfAmbassadors,
    ambassadorsLoading: state.ambassadorsLoading.isLoading,
    university_id: state.account.account_info.university.id,
    confirmBanModal: state.confirmBanModal,
})

export default connect(mapStatetoProps, {
    fetchAmbassadorsReports,
    changeAmbassadorIntroduction,
    changeAmbassadorDescription,
})(ReportingTime)
