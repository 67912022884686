// authorized user email change actions
export const ACCOUNT_CHANGE_EMAIL_SEND_LETTER = 'ACCOUNT_CHANGE_EMAIL_SEND_LETTER'
export const ACCOUNT_CHANGE_EMAIL_SEND_LETTER_SUCCESS = 'ACCOUNT_CHANGE_EMAIL_SEND_LETTER_SUCCESS'
export const ACCOUNT_CHANGE_EMAIL_SEND_LETTER_FAILED = 'ACCOUNT_CHANGE_EMAIL_SEND_LETTER_FAILED'

export const ACCOUNT_CHANGE_EMAIL = 'ACCOUNT_CHANGE_EMAIL'
export const ACCOUNT_CHANGE_EMAIL_SUCCESS = 'ACCOUNT_CHANGE_EMAIL_SUCCESS'
export const ACCOUNT_CHANGE_EMAIL_FAILED = 'ACCOUNT_CHANGE_EMAIL_FAILED'

// authorized user password change actions
export const ACCOUNT_CHANGE_PASSWORD_SEND_LETTER = 'ACCOUNT_CHANGE_PASSWORD_SEND_LETTER'
export const ACCOUNT_CHANGE_PASSWORD_SEND_LETTER_SUCCESS = 'ACCOUNT_CHANGE_PASSWORD_SEND_LETTER_SUCCESS'
export const ACCOUNT_CHANGE_PASSWORD_SEND_LETTER_FAILED = 'ACCOUNT_CHANGE_PASSWORD_SEND_LETTER_FAILED'

export const ACCOUNT_CHANGE_PASSWORD = 'ACCOUNT_CHANGE_PASSWORD'
export const ACCOUNT_CHANGE_PASSWORD_SUCCESS = 'ACCOUNT_CHANGE_PASSWORD_SUCCESS'
export const ACCOUNT_CHANGE_PASSWORD_FAILED = 'ACCOUNT_CHANGE_PASSWORD_FAILED'

export const ACCOUNT_CHANGE_AVATAR = 'ACCOUNT_CHANGE_AVATAR'
export const ACCOUNT_CHANGE_AVATAR_SUCCESS = 'ACCOUNT_CHANGE_AVATAR_SUCCESS'
export const ACCOUNT_CHANGE_AVATAR_FAILED = 'ACCOUNT_CHANGE_AVATAR_FAILED'

export const CLOSE_UNIVERSITY_NAME_CHANGED_MODAL = 'CLOSE_UNIVERSITY_NAME_CHANGED_MODAL'
export const OPEN_UNIVERSITY_NAME_CHANGED_MODAL = 'OPEN_UNIVERSITY_NAME_CHANGED_MODAL'

export const ACCOUNT_CHANGE_FIRST_NAME = 'ACCOUNT_CHANGE_FIRST_NAME'
export const ACCOUNT_CHANGE_FIRST_NAME_SUCCESS = 'ACCOUNT_CHANGE_FIRST_NAME_SUCCESS'
export const ACCOUNT_CHANGE_FIRST_NAME_FAILED = 'ACCOUNT_CHANGE_FIRST_NAME_FAILED'

export const ACCOUNT_CHANGE_LAST_NAME = 'ACCOUNT_CHANGE_LAST_NAME'
export const ACCOUNT_CHANGE_LAST_NAME_SUCCESS = 'ACCOUNT_CHANGE_LAST_NAME_SUCCESS'
export const ACCOUNT_CHANGE_LAST_NAME_FAILED = 'ACCOUNT_CHANGE_LAST_NAME_FAILED'
