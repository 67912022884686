import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { ChatType } from 'src/app/common/constants'

class JoinedAmbassadorMessage extends PureComponent {
    render() {
        const { message, chatType } = this.props
        const { avatar, name } = message.user
        const {
            user,
            ambassador,
            ambassador_avater: ambassadorAvatar,
            university,
            ambassador_type: ambassadorType,
        } = message.content

        let ambassadorDescription = ambassador
        if (chatType === ChatType.COMMUNITY_CHAT) {
            ambassadorDescription += ', ' + university + ' Ambassador, '
        } else {
            ambassadorDescription += ambassadorType
                ? ', ' + university + ' ' + ambassadorType + ', '
                : ' from ' + university + ' '
        }

        return (
            <div className="join-row">
                <div className="msg-avatar-block">
                    {message.user && avatar ? (
                        <img className="msg-item-img" src={avatar.sizes['70x70'] || avatar.original} />
                    ) : (
                        <div className="msg-avatar-text">{message.user ? name.charAt(0) : ''}</div>
                    )}
                </div>
                <p className="join-text">{user} added</p>
                <div className="msg-avatar-block">
                    {message.content && ambassadorAvatar ? (
                        <img className="msg-item-img" src={ambassadorAvatar} />
                    ) : (
                        <div className="msg-avatar-text">{message.user ? name.charAt(0) : ''}</div>
                    )}
                </div>
                <p className="join-text">{ambassadorDescription}to the chat</p>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        contentGroups: state.contentGroups,
        account: state.account,
    }
})(JoinedAmbassadorMessage)
