import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { openEventFormModal } from '../../../../redux/actions/live-event/live-events-actions'

const EventsListHeader = () => {
    const dispatch = useDispatch()

    const handleCreateEventClick = useCallback(() => {
        dispatch(openEventFormModal())
    }, [dispatch])

    return (
        <div className="new-event-button">
            <a onClick={handleCreateEventClick} className="el-btn create-event-popup-btn">
                Create new Live Event
            </a>
        </div>
    )
}

export default EventsListHeader
