import { all, call, put, select, takeEvery, take } from 'redux-saga/effects'

import API from '../../../api'
import { LIVE_EVENT_STATUS } from '../../../common/constants'
import { LIVE_EVENTS } from '../../actions/live-event/live-event-action-types'
import {
    fetchLiveEvents,
    openJoinModal,
    resetActiveLiveEvent,
    stopStream,
} from '../../actions/live-event/live-events-actions'
import {
    closeNotificationModal,
    openNotificationModal,
} from '../../actions/notification-modal/notification-modal-actions'
import { getLiveEventsSlice } from '../../selectors'

const EVENT_TYPE_PARAM = {
    UPCOMING: 'upcoming',
    PREVIOUS: 'previous',
    IN_PROGRESS: 'in-progress',
}

const EVENT_STATUS_TO_TYPE_PARAM = {
    [LIVE_EVENT_STATUS.UPCOMING]: EVENT_TYPE_PARAM.UPCOMING,
    [LIVE_EVENT_STATUS.IN_PROGRESS]: EVENT_TYPE_PARAM.IN_PROGRESS,
    [LIVE_EVENT_STATUS.PREVIOUS]: EVENT_TYPE_PARAM.PREVIOUS,
}

function* confirmStopEventSaga({ payload }) {
    const { id } = payload

    yield put(openNotificationModal(LIVE_EVENTS.STOP_EVENT_CONFIRM, payload))

    const confirm = yield take(LIVE_EVENTS.STOP_EVENT)

    if (confirm) {
        const response = yield call(() => {
            return API.liveEvent.editEvent({
                liveEventId: id,
                isStreamActive: false,
            })
        })

        if (response.success) {
            yield put(stopStream({ id }))
            yield put(closeNotificationModal())
        }
    }
}

function* confirmCancelEventSaga({ payload }) {
    const { id, name, history } = payload

    yield put(openNotificationModal(LIVE_EVENTS.CANCEL_EVENT_CONFIRM, { id, name }))

    const confirm = yield take(LIVE_EVENTS.CANCEL_EVENT)

    if (confirm) {
        const { currentEventStatus } = yield select(getLiveEventsSlice)

        const response = yield call(() => {
            return API.liveEvent.changeStatus(id, LIVE_EVENT_STATUS.CANCELLED)
        })

        if (response.success) {
            yield put(closeNotificationModal())
            yield put(resetActiveLiveEvent())

            yield put(
                fetchLiveEvents({
                    status: currentEventStatus,
                })
            )

            history.push(`/live-events/${EVENT_STATUS_TO_TYPE_PARAM[currentEventStatus]}`)
        }
    }
}

function* confirmStartEventSaga({ payload }) {
    const { id, history } = payload

    yield put(openNotificationModal(LIVE_EVENTS.START_EVENT_CONFIRM, payload))

    const confirm = yield take(LIVE_EVENTS.START_EVENT)

    if (confirm) {
        const response = yield call(() => {
            return API.liveEvent.changeStatus(id, LIVE_EVENT_STATUS.IN_PROGRESS)
        })

        if (response.success) {
            yield put(closeNotificationModal())

            history.push(`/live-events/in-progress/${id}`)

            yield put(openJoinModal())
        }
    }
}

function* closeChatSaga({ payload }) {
    const { id, history } = payload

    const response = yield call(() => {
        return API.liveEvent.changeStatus(id, LIVE_EVENT_STATUS.PREVIOUS)
    })

    if (response.success) {
        history.push(`/live-events/previous/${id}`)
    }
}

function* watchStartLiveEventSaga() {
    yield takeEvery(LIVE_EVENTS.START_EVENT_CONFIRM, confirmStartEventSaga)
    yield takeEvery(LIVE_EVENTS.STOP_EVENT_CONFIRM, confirmStopEventSaga)
    yield takeEvery(LIVE_EVENTS.CANCEL_EVENT_CONFIRM, confirmCancelEventSaga)
    yield takeEvery(LIVE_EVENTS.CLOSE_CHAT, closeChatSaga)
}

function* startLiveEventSaga() {
    yield all([call(watchStartLiveEventSaga)])
}

export default startLiveEventSaga
