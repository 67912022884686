export const GET_TOPICS_LIST = 'GET_TOPICS_LIST'
export const GET_TOPICS_LIST_SUCCESS = 'GET_TOPICS_LIST_SUCCESS'
export const GET_TOPICS_LIST_FAILED = 'GET_TOPICS_LIST_FAILED'

export const RESET_TOPICS_LIST = 'RESET_TOPICS_LIST'
export const ADD_TOPICS = 'ADD_TOPICS'
export const ADD_TOPICS_SUCCESS = 'ADD_TOPICS_SUCCESS'

export const EDIT_TOPIC = 'EDIT_TOPIC'
export const DELETE_TOPIC = 'DELETE_TOPIC'
