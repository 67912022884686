import API from '../../../../api'
import { call, put, select } from 'redux-saga/effects'
import { GET_ADMINS_SUCCESS, GET_ADMINS_FAILED, SEND_ADMIN_INVITATION_FAILED } from '../../../actions/settings/admins'
import { OPEN_ACTION_MODAL, CLOSE_ACTION_MODAL } from '../../../actions/components/componentsActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* admins(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            if (isGroupAdmin) {
                return API.settings.groupAdmins(payload.limit, payload.offset)
            } else {
                return API.settings.admins(payload.limit, payload.offset, universityId)
            }
        })
        if (response.success) {
            yield put({
                type: GET_ADMINS_SUCCESS,
                payload: {
                    items: response.data.users,
                },
            })
        } else {
            yield put({
                type: GET_ADMINS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_ADMINS_FAILED,
        })
    }
}

export function* sendAdminInvitation(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)
        const groupAdminInvitationData = {}

        if (isGroupAdmin && !universityId) {
            groupAdminInvitationData.emails = payload.emails
        }
        const response = yield call(() => {
            if (isGroupAdmin && !universityId) {
                return API.settings.sendGroupAdminInvitation(groupAdminInvitationData)
            } else {
                return API.settings.sendAdminInvitation(payload)
            }
        })
        if (response.success) {
            yield put({
                type: CLOSE_ACTION_MODAL,
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Admin invitation sent',
                },
            })
        } else {
            yield put({
                type: SEND_ADMIN_INVITATION_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: SEND_ADMIN_INVITATION_FAILED,
        })
    }
}
