import dayjs from 'dayjs'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import ReactPlayer from 'react-player'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

class VideoMessage extends PureComponent {
    render() {
        const { account, message, handleOpenSharePostModal, index, handleDownloadButtonClick, isIdpContentGroup } =
            this.props
        const createdAt = dayjs(message.created_at).calendar(null, {
            sameDay: 'H:mm',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday at ]H:mm',
            lastWeek: 'DD MMMM',
            sameElse: 'DD MMMM',
        })

        return (
            <div
                id={message.id}
                className={message.user && message.user.id == account.account_info.id ? 'msg-item mod-my' : 'msg-item'}
            >
                <div className="msg-avatar-block">
                    {message.user && message.user.avatar ? (
                        <img
                            className="msg-item-img"
                            src={message.user.avatar.sizes['70x70'] || message.user.avatar.original}
                        />
                    ) : (
                        <div className="msg-avatar-text">{message.user ? message.user.name.charAt(0) : ''}</div>
                    )}
                </div>
                <div className="msgs-block mod-media">
                    <div className="msg-block-item">
                        <p
                            className={
                                message.user && message.user.id == account.account_info.id ? 'hidden-block' : 'msg-name'
                            }
                        >
                            {message.user ? message.user.name : 'Deleted User'} <span>sent an video</span>
                        </p>
                        <div
                            className="chat-video-container"
                            onClick={(e) => this.props.handleMessageClick(index, message)}
                        >
                            <ReactPlayer
                                className="chat-video"
                                url={message.content.post.videos[0].original}
                                aria-label=""
                                playing={false}
                                pip
                                playsinline
                                loop
                                controls
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                            pip: 'true',
                                        },
                                    },
                                }}
                                onContextMenu={(e) => e.preventDefault()}
                            />
                        </div>
                        <p className="msg-post-caption">{message.content.post.caption}</p>
                        <div className="msg-controls">
                            <div className="msg-controls-left">
                                <p
                                    type="button"
                                    data-post-index={index}
                                    className={message.content.post.isShared ? 'msg-control-link' : 'hidden-block'}
                                >
                                    {' '}
                                    Shared {dayjs(message.content.post.shared_at).format('DD MMMM')}
                                </p>
                                {!isIdpContentGroup && (
                                    <>
                                        <button
                                            type="button"
                                            data-post-index={index}
                                            className="msg-control-link"
                                            onClick={handleOpenSharePostModal}
                                        >
                                            {' '}
                                            {message.content.post.isShared ? 'Share again' : 'Share'}
                                        </button>

                                        <a
                                            href={message.content.post.videos[0].original}
                                            data-post-id={message.content.post.id}
                                            target="_blank"
                                            onClick={handleDownloadButtonClick}
                                            className="btn msg-control-link"
                                            download
                                            rel="noreferrer"
                                        >
                                            Download
                                        </a>
                                    </>
                                )}
                            </div>
                            <p className="msg-time">{createdAt}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        contentGroups: state.contentGroups,
        account: state.account,
    }
})(VideoMessage)
