import { OPEN_LETTER_SEND_POPUP, CLOSE_LETTER_SEND_POPUP } from '../../../actions/account/accountActions'

import { SUCCESS_LETTER_SEND_MODAL } from '../../../store/initialState'

const initialState = SUCCESS_LETTER_SEND_MODAL

export default function (state = initialState, action) {
    const type = action.type

    switch (type) {
        case OPEN_LETTER_SEND_POPUP: {
            let newState = {
                ...state,
                isOpened: true,
            }

            return newState
        }

        case CLOSE_LETTER_SEND_POPUP: {
            let newState = {
                ...state,
                isOpened: false,
            }

            return newState
        }

        default: {
            return state
        }
    }
}
