import Select from 'antd/es/select'
import React, { useCallback, useEffect } from 'react'

import { useHistory, useLocation } from 'react-router'
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store'
import { SET_UNIVERSITY_GROUP } from 'src/app/redux/actions/account/accountActions'
import PlaceholderUser from '../../../../assets/img/placeholder-user.png'
import { isUniversitySelectAllowed } from '../../../common/utils/user-account-helpers'

export default function Header({ submenuUnActive }) {
    const dispatch = useAppDispatch()
    const account = useAppSelector((state) => state.account)
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (account.account_info.isBuzzOnly) {
            history.push('/home')
        }
    }, [account.account_info.isBuzzOnly, history])

    const onChange = useCallback(
        (universityId) => {
            dispatch({
                type: SET_UNIVERSITY_GROUP,
                payload: {
                    university_id: universityId,
                },
            })
            if (location.pathname.includes('settings')) {
                history.push(`${universityId}/settings/${universityId ? 'invite' : 'invite-admins'}`)
            }
        },
        [dispatch, location, history]
    )

    const {
        account_info: { isGroupAdmin, university, universityGroup, isIdpGroupAdmin },
        currentGroupUniversityId,
    } = account
    let permissions = []

    if (university) {
        permissions = university.permissions
    }

    if (!isGroupAdmin) {
        return null
    }

    let avatarSrc =
        university && university.avatar
            ? university.avatar.sizes['160x160'] || university.avatar.original
            : PlaceholderUser

    const dropUniversityOptions = [
        {
            key: null,
            name: universityGroup.name + ' (Group Admin)',
        },
    ]

    let currentName = universityGroup.name + ' (Group Admin)'
    for (const universityItem of universityGroup.universities) {
        const { id, name, avatar: universityAvatar } = universityItem
        dropUniversityOptions.push({
            key: id,
            name: name,
        })
        if (currentGroupUniversityId && id === currentGroupUniversityId) {
            avatarSrc = universityAvatar
                ? universityAvatar.sizes['160x160'] || universityAvatar.original
                : PlaceholderUser
            currentName = name
        }
    }

    return (
        <header id="header" className={submenuUnActive ? 'header-top-nav no-submenu' : 'header-top-nav'}>
            <div className="header-top-nav-container">
                <div className="top-nav-title-block">
                    <img className="group-university-img nav-icon-notooltip" src={avatarSrc} alt="User icon" />
                    <div className="title">{currentName}</div>
                </div>
                {isUniversitySelectAllowed(permissions) && !isIdpGroupAdmin && (
                    <div className="top-nav-dropdown-block">
                        <Select
                            value={currentGroupUniversityId}
                            onChange={onChange}
                            size="large"
                            style={{ width: 220, marginLeft: 8 }}
                        >
                            {dropUniversityOptions.map((item) => (
                                <Select.Option key={item.key} value={item.key}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                )}
            </div>
        </header>
    )
}
