import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import LogInForm from './components/LogInForm'
import { ACCOUNT_LOGIN } from '../../../redux/actions/account/accountActions'
import store from '../../../redux/store'
import config from '../../../system/config'
import { openSsoWindow } from '../../../helpers/SsoHelper'
import SignInSsoForm from './components/SignInSsoForm'
import { getUniversitySso } from '../../../redux/actions/account/account-actions'
import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'
import Divider from 'antd/es/divider'

class Login extends PureComponent {
    handleSubmit = (values) => {
        const goToFinishRegistration = () => {
            this.props.history.push('/account/registration/university/d39dde9d1de1f56a')
        }

        store.dispatch({
            type: ACCOUNT_LOGIN,
            payload: values,
            goToFinishRegistration: goToFinishRegistration,
        })
    }

    ssoSignIn = (email) => {
        const onSuccess = (appDomain, appClientId, isEncryptionOn) => {
            if (!appClientId) {
                store.dispatch({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: 'No SSO provider found for your university',
                    },
                })
            } else {
                openSsoWindow(appDomain, appClientId, isEncryptionOn, {}, email)
            }
        }
        store.dispatch(getUniversitySso(email, onSuccess))
    }

    render() {
        return (
            <div id="page-wrapper" className="page-wrapper unregistered-page">
                <div id="page-body" className="page-body js-index">
                    <div className="signin-container">
                        <div className="signin-block">
                            <p className="signin-title">
                                Sign in as an <span className="tap-orange">Admin</span>
                            </p>
                            <LogInForm onSubmit={this.handleSubmit} />
                            <Divider plain>Or</Divider>
                            <SignInSsoForm onSubmit={this.ssoSignIn} />
                            <p className="signin-bottom-text">
                                Want to log in to the web application as an Ambassador?
                            </p>
                            <p className="tap-login-link">
                                <a href={config.tap_page_url + 'interaction/ambassador/sign-in'}>Click here</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(Login)
