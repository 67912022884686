import React, { Component } from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

class SearchInput extends Component {
    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input.Search
                    allowClear
                    placeholder={this.props.placeholder}
                    onSearch={(value) => this.props.onChange(value)}
                    style={{ width: this.props.width }}
                    prefix={<SearchOutlined style={{ color: '#ff5100' }} />}
                />
            </div>
        )
    }
}

export default SearchInput
