import React from 'react'
import ReactPlayer from 'react-player'

const VIDEO_TRANSCODE_STATUS = {
    COMPLETE: 'COMPLETE',
    SUBMITTED: 'SUBMITTED',
}

const StreamVideo = ({ event }) => {
    const { video } = event

    const videoFile = video.find((item) => item)

    return (
        <div style={{ position: 'relative' }}>
            <ReactPlayer
                className="video-element"
                url={videoFile.original}
                aria-label=""
                playing={true}
                playsinline
                controls
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload',
                        },
                    },
                }}
                onContextMenu={(e) => e.preventDefault()}
            />
            {videoFile.status && videoFile.status !== VIDEO_TRANSCODE_STATUS.COMPLETE && (
                <div className="video-element-processing"> Video is processing... </div>
            )}
        </div>
    )
}

export default StreamVideo
