import { LoadingOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useRef } from 'react'

import ParticipantVideo from './participant-video'
import ParticipantInfo from './participant-info'
import ParticipantAvatar from './participant-avatar'

const ParticipantCard = ({ participant }) => {
    const { view, videoMuted, audioMuted, user, media, loading } = participant

    const mediaRef = useRef(null)
    const participantCardRef = useRef(null)
    const audioMutedRef = useRef(audioMuted)

    const onAudioLevel = useCallback((audioLevel) => {
        const elem = participantCardRef.current
        const level = Math.floor(audioLevel * 100)

        elem.style.border = `5px solid rgba(82, 196, 26, 0.${level})`
    }, [])

    useEffect(() => {
        return () => {
            if (mediaRef.current !== null) {
                mediaRef.current.removeOnAudioLevel(onAudioLevel)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (audioMutedRef.current !== audioMuted && audioMuted) {
            const elem = participantCardRef.current
            elem.style.border = `5px solid rgba(82, 196, 26, 0)`
        }
        audioMutedRef.current = audioMuted
    }, [audioMuted])

    useEffect(() => {
        if (mediaRef.current === null && media !== null) {
            mediaRef.current = media
            mediaRef.current.addOnAudioLevel(onAudioLevel)
        }
    }, [media, onAudioLevel])

    return (
        <div className="participant-card" ref={participantCardRef}>
            <div className="participant-card-content">
                {videoMuted && <ParticipantAvatar user={user} />}
                <ParticipantVideo view={view} />
                <ParticipantInfo participant={participant} />
                {loading && (
                    <div className="participant-loading">
                        <LoadingOutlined />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ParticipantCard
