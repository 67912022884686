import { Upload, Row, Col, Button, message } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { UPLOAD_BADWORDS_CSV } from '../../../../redux/actions/settings/safeguardingActions'
import { csvJSON } from '../../../../helpers/helpers'

const beforeUpload = (file) => {
    const isCsv = file.type.includes('csv')
    if (!isCsv) {
        message.error('File upload failed. Please, make sure that it meets the requirements')
    }
    return isCsv
}

const UploadKeywordsButton = () => {
    const dispatch = useDispatch()

    const handleFileChange = useCallback(
        (info) => {
            if (info.file.status === 'done') {
                const data = new window.FormData()

                data.append('file', new window.Blob([info.file.originFileObj], { type: 'text/csv' }))

                const reader = new FileReader()
                const readFile = (event) => {
                    const csvText = event.target.result

                    const isValidCsv = csvJSON(csvText).map((item) => {
                        if (Object.keys(item).length === 1) {
                            return true
                        }
                        return false
                    })

                    if (!csvText || isValidCsv.some((item) => !item)) {
                        message.error('File upload failed. Please, make sure that it meets the requirements')
                    } else {
                        dispatch({
                            type: UPLOAD_BADWORDS_CSV,
                            payload: data,
                        })
                    }
                }
                reader.addEventListener('load', readFile)
                reader.readAsText(info.file.originFileObj)
            }
        },
        [dispatch]
    )
    return (
        <>
            <Upload
                accept=".csv"
                name="keywords"
                customRequest={({ onSuccess }) => onSuccess()}
                showUploadList={false}
                maxCount={1}
                onChange={handleFileChange}
                beforeUpload={beforeUpload}
            >
                <Row justify="center">
                    <Col flex="162px">
                        <Button type="primary" block size="large">
                            Upload Keywords
                        </Button>
                    </Col>
                </Row>
            </Upload>
        </>
    )
}

export default UploadKeywordsButton
