import { Button, Col, Form, Modal, Row, Select } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { getCommonRoutePath } from '../../../../helpers/helpers'

import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg'
import { closeCampaignTypeModal } from '../../../../redux/actions/campaign/campaign-actions'
import { getCampaignTypeModal } from '../../../../redux/selectors'

const { Option } = Select

const CampaignTypeModal = () => {
    const dispatch = useDispatch()
    const { open, submitting, data } = useSelector(getCampaignTypeModal)
    const [form] = Form.useForm()
    const history = useHistory()

    const handleModalClose = useCallback(() => {
        dispatch(closeCampaignTypeModal())
    }, [dispatch])

    const handleSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            history.push(getCommonRoutePath() + `/campaign-management/campaigns/create/${values.campaignType}`)
            dispatch(closeCampaignTypeModal())
        })
    }, [form, dispatch, history])

    const options = useMemo(
        () =>
            data?.map((item) => (
                <Option key={item.key} value={item.id}>
                    {item.name}
                </Option>
            )),
        [data]
    )

    const footer = useMemo(() => {
        return (
            <Row justify="center">
                <Col flex="162px">
                    <Button
                        type="primary"
                        block
                        size="large"
                        disabled={submitting}
                        loading={submitting}
                        onClick={handleSubmit}
                    >
                        Continue
                    </Button>
                </Col>
            </Row>
        )
    }, [handleSubmit, submitting])

    return (
        <Modal
            title="Choose the type of Campaign"
            footer={footer}
            open={open}
            closeIcon={<CloseIcon />}
            onCancel={handleModalClose}
            width={600}
            className="tap-event-modal"
            destroyOnClose
        >
            <Form form={form} autoComplete="off" hideRequiredMark>
                <Form.Item
                    name="campaignType"
                    label={null}
                    style={{ marginTop: 30 }}
                    rules={[{ required: true, message: 'Choose Campaign Type' }]}
                >
                    <Select placeholder="Choose Campaign Type">{options}</Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CampaignTypeModal
