import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as ArrowIcon } from '../../../../assets/svg/icons/arrow.svg'
import { NavLink } from 'react-router-dom'
import { getCommonRoutePath } from '../../../helpers/helpers'
import { ProtectedNavLink } from 'src/app/components/protected-nav-link'
import { LicensesEnum } from 'src/types/general'

class SetupStepsBlock extends PureComponent {
    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        const { account, wizardSetupData } = this.props
        const completedCount =
            (wizardSetupData.privacyPolicy ? 1 : 0) +
            (wizardSetupData.inviteAmbassadors ? 1 : 0) +
            (wizardSetupData.customizeBranding ? 1 : 0) +
            (wizardSetupData.embedTapPage ? 1 : 0)

        const commonPath = getCommonRoutePath()

        return (
            <div className="complete-setup-block">
                <p className="complete-setup-title">Complete your Setup</p>
                <div className="steps-number-completed">{completedCount} of 4 steps completed</div>
                <div className="complete-setup-list">
                    <NavLink
                        to={
                            commonPath +
                            '/settings/legal' +
                            (account.currentGroupUniversityId
                                ? '?universityId=' + account.currentGroupUniversityId
                                : '')
                        }
                        target="_blank"
                        className="privacy-link-step step-line"
                    >
                        <span
                            className={
                                'complete-circle with-line' + (wizardSetupData.privacyPolicy ? ' completed-circle' : '')
                            }
                        ></span>
                        <span className="complete-step-text">Add Privacy Policy link</span>
                        {!wizardSetupData.privacyPolicy && (
                            <div className="complete-simple-link">
                                <span className="complete-simple-text">Complete step</span>
                                <ArrowIcon />
                            </div>
                        )}
                    </NavLink>
                    <NavLink
                        to={
                            commonPath +
                            '/settings/invite' +
                            (account.currentGroupUniversityId
                                ? '?universityId=' + account.currentGroupUniversityId
                                : '')
                        }
                        target="_blank"
                        className="invite-sa-step step-line"
                    >
                        <span
                            className={
                                'complete-circle with-line' +
                                (wizardSetupData.inviteAmbassadors ? ' completed-circle' : '')
                            }
                        ></span>
                        <span className="complete-step-text">Invite Ambassadors</span>
                        {!wizardSetupData.inviteAmbassadors && (
                            <div className="complete-simple-link">
                                <span className="complete-simple-text">Complete step</span>
                                <ArrowIcon />
                            </div>
                        )}
                    </NavLink>
                    <NavLink
                        to={
                            commonPath +
                            '/settings/custom-styles' +
                            (account.currentGroupUniversityId
                                ? '?universityId=' + account.currentGroupUniversityId
                                : '')
                        }
                        target="_blank"
                        className="customize-branding-step step-line"
                    >
                        <span
                            className={
                                'complete-circle with-line' +
                                (wizardSetupData.customizeBranding ? ' completed-circle' : '')
                            }
                        ></span>
                        <span className="complete-step-text">Customize your branding</span>
                        {!wizardSetupData.customizeBranding && (
                            <div className="complete-simple-link">
                                <span className="complete-simple-text">Complete step</span>
                                <ArrowIcon />
                            </div>
                        )}
                    </NavLink>
                    <ProtectedNavLink
                        className="embed-tap-page-step step-line"
                        to={
                            commonPath +
                            '/settings/tap-page' +
                            (account.currentGroupUniversityId
                                ? '?universityId=' + account.currentGroupUniversityId
                                : '')
                        }
                        licenseName={LicensesEnum.TapFeed}
                        target="_blank"
                    >
                        <span
                            className={'complete-circle' + (wizardSetupData.embedTapPage ? ' completed-circle' : '')}
                        ></span>
                        <span className="complete-step-text">Embed your TAP Feed</span>
                        {!wizardSetupData.embedTapPage && (
                            <div className="complete-simple-link">
                                <span className="complete-simple-text">Complete step</span>
                                <ArrowIcon />
                            </div>
                        )}
                    </ProtectedNavLink>
                </div>
                <div></div>
            </div>
        )
    }
}

export default connect((state) => {
    return { account: state.account }
})(SetupStepsBlock)
