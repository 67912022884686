import React from 'react'
import { Space } from 'antd'

const ScreenInfo = ({ participant }) => {
    const {
        user: { name },
    } = participant

    return (
        <div className="participant-info">
            <Space>{name} is presenting</Space>
        </div>
    )
}

export default ScreenInfo
