import API from '../../../api'
import { call, put, select } from 'redux-saga/effects'
import types from '../../actions/reporting/overviewActions'

const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

export function* universityStatistics({ filter = null }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.universityStatistics(filter, universityIds)
        })

        if (response.success) {
            yield put({
                type: types.UNIVERSITY_STATISTICS_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: types.UNIVERSITY_STATISTICS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: types.UNIVERSITY_STATISTICS_FAILED,
        })
    }
}

export function* overviewSummaryStatistics({ filter = null }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.overviewSummaryStatistics(filter, universityIds)
        })

        if (response.success) {
            yield put({
                type: types.OVERVIEW_SUMMARY_STATISTICS_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: types.OVERVIEW_SUMMARY_STATISTICS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: types.OVERVIEW_SUMMARY_STATISTICS_FAILED,
        })
    }
}

export function* overviewChatStatistics({ filter = null }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.overviewChatStatistics(filter, universityIds)
        })

        if (response.success) {
            yield put({
                type: types.OVERVIEW_CHAT_STATISTICS_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: types.OVERVIEW_CHAT_STATISTICS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: types.OVERVIEW_CHAT_STATISTICS_FAILED,
        })
    }
}

export function* overviewContentStatistics({ filter = null }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.overviewContentStatistics(filter, universityIds)
        })

        if (response.success) {
            yield put({
                type: types.OVERVIEW_CONTENT_STATISTICS_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: types.OVERVIEW_CONTENT_STATISTICS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: types.OVERVIEW_CONTENT_STATISTICS_FAILED,
        })
    }
}

export function* overviewFaqStatistics({ filter = null }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.overviewFaqStatistics(filter, universityIds)
        })

        if (response.success) {
            yield put({
                type: types.OVERVIEW_FAQ_STATISTICS_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: types.OVERVIEW_FAQ_STATISTICS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: types.OVERVIEW_FAQ_STATISTICS_FAILED,
        })
    }
}
