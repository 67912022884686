import React, { PureComponent } from 'react'

class SlideTwo extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <div className="preview-slide">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={518.079}
                    height={487}
                    viewBox="0 0 518.079 487"
                    {...this.props}
                >
                    <g data-name="Group 3606">
                        <g data-name="Group 3595">
                            <g data-name="Group 3592">
                                <g data-name="Rectangle 1885" fill="none" stroke="#707070" opacity={0.22}>
                                    <path stroke="none" d="M45 0h428v487H45z" />
                                    <path d="M45.5.5h427v486h-427z" />
                                </g>
                                <g data-name="Group 3122">
                                    <g data-name="Group 3138">
                                        <g
                                            data-name="Rectangle 1498"
                                            transform="translate(95 90)"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                        >
                                            <rect width={328} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={327} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <g data-name="Group 256">
                                            <text
                                                data-name="First Name"
                                                transform="translate(133 96)"
                                                fill="rgba(34,34,34,0.8)"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'First Name'}
                                                </tspan>
                                            </text>
                                            <path
                                                d="M113.974 110.555a6.325 6.325 0 116.325-6.325 6.325 6.325 0 01-6.325 6.325zm0-1.265a5.06 5.06 0 10-5.06-5.06 5.06 5.06 0 005.06 5.06zm0-8.855a2.53 2.53 0 012.53 2.53v1.266a2.53 2.53 0 11-5.06 0v-1.265a2.53 2.53 0 012.53-2.53zm0 1.265a1.265 1.265 0 00-1.265 1.266v1.265a1.265 1.265 0 102.53 0v-1.265a1.265 1.265 0 00-1.265-1.265zm-2.587 6.88a5.081 5.081 0 01-1-.779 3.523 3.523 0 011.394-1 .635.635 0 11.468 1.18 2.269 2.269 0 00-.859.6zm5.151.014a2.31 2.31 0 00-.889-.61.632.632 0 11.447-1.178 3.573 3.573 0 011.445 1.013 5.08 5.08 0 01-1 .775z"
                                                fill="rgba(38,46,69,0.3)"
                                            />
                                        </g>
                                    </g>
                                    <g data-name="Group 3137">
                                        <g
                                            data-name="Rectangle 1912"
                                            transform="translate(95 138)"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                        >
                                            <rect width={328} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={327} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <g data-name="Group 3119">
                                            <text
                                                data-name="Last Name"
                                                transform="translate(133 144)"
                                                fill="rgba(34,34,34,0.8)"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'Last Name'}
                                                </tspan>
                                            </text>
                                            <path
                                                data-name="user-circle"
                                                d="M113.974 158.555a6.325 6.325 0 116.325-6.325 6.325 6.325 0 01-6.325 6.325zm0-1.265a5.06 5.06 0 10-5.06-5.06 5.06 5.06 0 005.06 5.06zm0-8.855a2.53 2.53 0 012.53 2.53v1.266a2.53 2.53 0 11-5.06 0v-1.265a2.53 2.53 0 012.53-2.53zm0 1.265a1.265 1.265 0 00-1.265 1.266v1.265a1.265 1.265 0 102.53 0v-1.265a1.265 1.265 0 00-1.265-1.265zm-2.587 6.88a5.081 5.081 0 01-1-.779 3.523 3.523 0 011.394-1 .635.635 0 11.468 1.18 2.269 2.269 0 00-.859.6zm5.151.014a2.31 2.31 0 00-.889-.61.632.632 0 11.447-1.178 3.573 3.573 0 011.445 1.013 5.08 5.08 0 01-1 .775z"
                                                fill="rgba(38,46,69,0.3)"
                                            />
                                        </g>
                                    </g>
                                    <g data-name="Group 3139">
                                        <g
                                            data-name="Rectangle 1912"
                                            transform="translate(95 186)"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                        >
                                            <rect width={328} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={327} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <g data-name="Group 3119">
                                            <text
                                                data-name="Create password"
                                                transform="translate(133 192)"
                                                fill="rgba(34,34,34,0.8)"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'Create password'}
                                                </tspan>
                                            </text>
                                        </g>
                                    </g>
                                    <g data-name="Group 3140">
                                        <g
                                            data-name="Rectangle 1912"
                                            transform="translate(95 234)"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                        >
                                            <rect width={328} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={327} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <g data-name="Group 3119">
                                            <text
                                                data-name="Confirm password"
                                                transform="translate(133 240)"
                                                fill="rgba(34,34,34,0.8)"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'Confirm password'}
                                                </tspan>
                                            </text>
                                            <path
                                                d="M114 205.512a2.755 2.755 0 10-2.756-2.757 2.755 2.755 0 002.756 2.757zm-2.756-5.837v-3.808a2.755 2.755 0 015.51 0v3.808a4.133 4.133 0 11-5.51 0zm1.378-.818a4.163 4.163 0 012.755 0v-2.99a1.378 1.378 0 00-2.755 0v2.99zm1.378 5.275a1.378 1.378 0 111.377-1.377 1.378 1.378 0 01-1.377 1.377z"
                                                fill="#bdc0c7"
                                            />
                                            <path
                                                data-name="padlock-alt"
                                                d="M114 253.512a2.755 2.755 0 10-2.756-2.757 2.755 2.755 0 002.756 2.757zm-2.756-5.837v-3.808a2.755 2.755 0 015.51 0v3.808a4.133 4.133 0 11-5.51 0zm1.378-.818a4.163 4.163 0 012.755 0v-2.99a1.378 1.378 0 00-2.755 0v2.99zm1.378 5.275a1.378 1.378 0 111.377-1.377 1.378 1.378 0 01-1.377 1.377z"
                                                fill="#bdc0c7"
                                            />
                                        </g>
                                    </g>
                                    <g data-name="Group 3136">
                                        <g
                                            data-name="Rectangle 1913"
                                            transform="translate(95 42)"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                        >
                                            <rect width={328} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={327} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <g data-name="Group 3120">
                                            <text
                                                data-name="Email address"
                                                transform="translate(133 48)"
                                                fill="rgba(34,34,34,0.8)"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'Email address'}
                                                </tspan>
                                            </text>
                                            <path
                                                d="M109.306 52.509l3.683 3.281a.641.641 0 00.852 0l3.684-3.282zm9.231.816l-3.842 3.423a1.923 1.923 0 01-2.559 0l-3.854-3.435v5.605h10.255zm-10.255-2.098h10.255a1.282 1.282 0 011.282 1.282v6.409a1.282 1.282 0 01-1.282 1.282h-10.255A1.282 1.282 0 01107 58.918v-6.409a1.282 1.282 0 011.282-1.282z"
                                                fill="rgba(38,46,69,0.3)"
                                            />
                                        </g>
                                    </g>
                                    <g data-name="Button create content group">
                                        <g data-name="Group 254">
                                            <g data-name="Group 3121" transform="translate(95 397)">
                                                <rect
                                                    data-name="Rectangle 1496"
                                                    width={328}
                                                    height={32}
                                                    rx={4}
                                                    fill="#ff5100"
                                                />
                                                <text
                                                    transform="translate(20 8)"
                                                    fill="#fff"
                                                    fontSize={12}
                                                    fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                >
                                                    <tspan x={121.514} y={12}>
                                                        {'Continue'}
                                                    </tspan>
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <text
                                        data-name="Already have an account?"
                                        transform="translate(185 442)"
                                        fill="rgba(38,46,69,0.8)"
                                        fontSize={11}
                                        fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                    >
                                        <tspan x={15.997} y={11}>
                                            {'Already have an account?'}
                                        </tspan>
                                    </text>
                                    <g data-name="Group 3135">
                                        <g data-name="Group 3">
                                            <g data-name="Rectangle 130" fill="#fff" stroke="rgba(38,46,69,0.3)">
                                                <path stroke="none" d="M95 296h12v12H95z" />
                                                <path fill="none" d="M95.5 296.5h11v11h-11z" />
                                            </g>
                                        </g>
                                        <g data-name="Group 44">
                                            <text
                                                data-name="I am over the age of 18 and consent to my personal data being processed by my institution, according to its Privacy Policy, and being shared with The Ambassador Platform according to their Privacy Notice and terms so that they can facilitate the management of"
                                                transform="translate(117 294)"
                                                fill="#222"
                                                fontSize={11}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            >
                                                <tspan x={0} y={11}>
                                                    {'I am over the age of 18 and consent to my personal data being '}
                                                </tspan>
                                                <tspan x={0} y={25}>
                                                    {'processed by my institution, according to its '}
                                                </tspan>
                                                <tspan y={25} fill="#ff5100">
                                                    {'Privacy Policy'}
                                                </tspan>
                                                <tspan y={25}>{', and '}</tspan>
                                                <tspan x={0} y={39}>
                                                    {'being shared with'}
                                                </tspan>
                                                <tspan y={39} fill="rgba(38,46,69,0.5)" />
                                                <tspan y={39} fill="#ff5100">
                                                    {' The Ambassador Platform '}
                                                </tspan>
                                                <tspan y={39} fill="rgba(38,46,69,0.5)" />
                                                <tspan y={39}>{'according to their'}</tspan>
                                                <tspan y={39} fill="rgba(38,46,69,0.5)" />
                                                <tspan x={0} y={53} fill="#ff5100">
                                                    {' Privacy '}
                                                </tspan>
                                                <tspan y={53} fill="#ff5100">
                                                    {'Notice '}
                                                </tspan>
                                                <tspan y={53} fill="rgba(38,46,69,0.5)" />
                                                <tspan y={53}>{'and terms so that they can facilitate the '}</tspan>
                                                <tspan x={0} y={67}>
                                                    {'management of content generation from representatives '}
                                                </tspan>
                                                <tspan x={0} y={81}>
                                                    {'of my institution and messaging interactions with prospects.'}
                                                </tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <path
                                data-name="Path 64"
                                d="M415.764 298.658a45.278 45.278 0 0014.985-20.333c.663-1.799 3.448-1.078 2.906.813a47.359 47.359 0 01-5.61 12.475c10.723-7.68 23.831-11.978 36.903-13.244 16.984-1.643 37.786 1.91 51.193 13.19 1.967 1.654-.262 5.433-2.658 4.423-7.692-3.236-14.82-7.038-22.998-9.084a79.831 79.831 0 00-24.725-2.306c-13.822.922-26.456 6.001-38.263 12.81a23.628 23.628 0 0110.85 4.437c2.92 2.193.172 7.07-2.987 4.972-5.391-3.59-10.925-5.227-17.432-4.201-2.034.314-4.186-2.382-2.164-3.952z"
                                fill="rgba(34,34,34,0.4)"
                            />
                        </g>
                        <path data-name="Rectangle 1982" fill="#fff" d="M0 2h45v485H0z" />
                    </g>
                </svg>
            </div>
        )
    }
}

export default SlideTwo
