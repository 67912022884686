import store from '../redux/store';
var UserCountry = /** @class */ (function () {
    // eslint-disable-next-line no-useless-constructor
    function UserCountry(store) {
        this.store = store;
    }
    Object.defineProperty(UserCountry, "instance", {
        get: function () {
            if (!this._instance) {
                var account = store.getState().account;
                if (!store) {
                    throw new Error('Account store is not initialized');
                }
                this._instance = new this(account);
            }
            return this._instance;
        },
        enumerable: false,
        configurable: true
    });
    UserCountry.get = function () {
        var _a, _b, _c;
        var countryCode = (_c = (_b = (_a = this.instance.store.account_info) === null || _a === void 0 ? void 0 : _a.university) === null || _b === void 0 ? void 0 : _b.country) === null || _c === void 0 ? void 0 : _c.alpha_3_code;
        return countryCode || 'GBR';
    };
    return UserCountry;
}());
export { UserCountry };
