import React, { Component } from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

class Search extends Component {
    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                    allowClear
                    placeholder={this.props.placeholder}
                    onChange={(e) => this.props.onChange(e.target.value)}
                    style={{ width: this.props.width }}
                    prefix={<SearchOutlined style={{ color: '#ff5100' }} />}
                />
            </div>
        )
    }
}

export default Search
