import { prop } from 'ramda';
import { createSelector } from 'reselect';
import { getAccountSlice } from './common-selectors';
export var getAccountInfo = createSelector(getAccountSlice, function (account) { return account.account_info; });
export var getAccountInfoId = createSelector(getAccountInfo, function (accountInfo) { return accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.id; });
export var getUniversityInfo = createSelector(getAccountInfo, function (accountInfo) { return accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university; });
export var getIsGroupAdmin = createSelector(getAccountInfo, function (accountInfo) { return accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.isGroupAdmin; });
export var getPlsWizardSetup = createSelector(getAccountInfo, function (accountInfo) { return accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.plsWizardSetup; });
export var getUniversity = createSelector(getAccountSlice, prop('account_info'));
export var getCurrentGroupUniversityId = createSelector(getAccountSlice, function (account) { return account.currentGroupUniversityId; });
export var selectUniversityId = createSelector(getAccountSlice, function (account) { var _a; return (_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.id; });
export var selectAdminUniversityId = createSelector(getAccountSlice, function (account) { var _a; return account.currentGroupUniversityId || ((_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.id); });
export var selectActiveUnivercityId = function (state) { var _a; return state.account.currentGroupUniversityId || ((_a = state.account.account_info) === null || _a === void 0 ? void 0 : _a.university_id); };
export var selectIsSascOrIdp = createSelector(getAccountInfo, function (accountInfo) { return (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.isSasc) || (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.isIdp); });
export var selectIsIdpActivation = createSelector(getAccountSlice, function (account) { return account.showIdpActivation; });
export var selectIdpStepActivation = function (state) {
    var _a, _b;
    var isSecondStepActivation = (_a = state.account.account_info) === null || _a === void 0 ? void 0 : _a.university.secondStepActivation;
    var isThirdStepActivation = (_b = state.account.account_info) === null || _b === void 0 ? void 0 : _b.university.thirdStepActivation;
    if (isThirdStepActivation) {
        return 2;
    }
    else if (isSecondStepActivation) {
        return 1;
    }
    else {
        return 0;
    }
};
