import React, { useCallback } from 'react'
import { Row, Col, Button } from 'antd'
import { useHistory } from 'react-router-dom'

import { getCommonRoutePath } from '../../../../helpers/helpers'

const CampaignsCreateButtons = ({ handleCreateCampaign, viewMode }) => {
    const history = useHistory()

    const handleCancel = useCallback(() => {
        history.push(getCommonRoutePath() + '/campaign-management/campaigns')
    }, [history])

    return (
        <Row justify="start">
            <Col flex="16rem" style={{ marginRight: '2rem' }}>
                <Button onClick={handleCancel} block type="default" className="el-btn" style={{ height: '4rem' }}>
                    {viewMode ? 'Back' : 'Cancel'}
                </Button>
            </Col>
            {!viewMode && (
                <Col flex="16rem">
                    <Button
                        onClick={handleCreateCampaign}
                        block
                        type="default"
                        className="el-btn"
                        style={{ height: '4rem' }}
                    >
                        Create campaign
                    </Button>
                </Col>
            )}
        </Row>
    )
}

export default CampaignsCreateButtons
