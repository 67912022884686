import Modal from 'antd/es/modal'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import universityDefaultAvatar from '../../../../assets/img/university_default_avatar.jpeg'
import { isAllowed } from '../../../helpers/helpers'
import {
    UNIVERSITY_CHANGE_AVATAR,
    CHANGE_UNIVERSITY_NAME_REQUESTED,
    CHANGE_DISPLAY_NAME_FOR_PARTNERS,
} from '../../../redux/actions/account/accountActions'
import { CLOSE_UNIVERSITY_NAME_CHANGED_MODAL } from '../../../redux/actions/account/accountEditActions'
import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { UNIVERSITY_WIDGET_INFO_REQUESTED, SET_WIDGET_STYLES } from '../../../redux/actions/settings/custom-styles'
import store from '../../../redux/store'
import Loader from '../../content/components/Loader'

import EditUniversityNameForm from './components/forms/EditUniversityNameForm'
import StyleForm from './components/forms/StyleForm'
import ChangeUniversityNameSuccessPopup from './components/popups/ChangeUniversityNameSuccessPopup'
import { RestrictedModal } from '../../../components/modals/RestrictedModal'
import EditDisplayNameForm from './components/forms/EditDisplayNameForm'

class CustomStyles extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            showRestrictedModal: false,
        }

        this.handleStylesSubmit = this.handleStylesSubmit.bind(this)
        this.handleChangeUniversityNameSubmit = this.handleChangeUniversityNameSubmit.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: UNIVERSITY_WIDGET_INFO_REQUESTED,
            payload: {
                type: 'common',
            },
        })
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: UNIVERSITY_WIDGET_INFO_REQUESTED,
                payload: {
                    type: 'common',
                },
            })
        }
    }

    handleStylesSubmit(values) {
        if (isAllowed('branding_styles')) {
            store.dispatch({
                type: SET_WIDGET_STYLES,
                payload: {
                    type: 'common',
                    settings: values,
                },
            })

            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Widget branding saved',
                },
            })
        } else {
            this.setState({ showRestrictedModal: true })
        }
    }

    closeRestrictedModal = () => {
        this.setState({ showRestrictedModal: false })
    }

    handleChangeAvatarInput(event) {
        const reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.addEventListener('load', function () {
            store.dispatch({
                type: UNIVERSITY_CHANGE_AVATAR,
                payload: {
                    avatar: reader.result.slice(reader.result.indexOf(',') + 1),
                },
            })
        })
    }

    handleChangeUniversityNameSubmit(name) {
        store.dispatch({
            type: CHANGE_UNIVERSITY_NAME_REQUESTED,
            payload: {
                university_name: name,
            },
        })
    }

    handleChangeDisplayNameSubmit(displayName) {
        store.dispatch({
            type: CHANGE_DISPLAY_NAME_FOR_PARTNERS,
            payload: {
                display_name: displayName,
            },
        })
    }

    handleCloseUniversityNameSuccess() {
        store.dispatch({
            type: CLOSE_UNIVERSITY_NAME_CHANGED_MODAL,
        })
    }

    render() {
        const { showRestrictedModal } = this.state
        const { account, universityWidgets, changeUniversityNameSuccess } = this.props
        /* let settingsCodeView = [];
          this.props.account.account_info.university.access_code.split('').forEach(char => {
              settingsCodeView.push(<input type="text" disabled className="settings-code-input" value={char} />);
          }) */

        let logo = null

        logo =
            account &&
            account.account_info &&
            account.account_info.university &&
            account.account_info.university.avatar &&
            account.account_info.university.avatar.original ? (
                <img
                    className="university-logo-img"
                    src={
                        account.account_info.university.avatar.sizes['160x160'] ||
                        account.account_info.university.avatar.original
                    }
                />
            ) : (
                <img className="university-logo-img" src={universityDefaultAvatar} />
            )

        return (
            <>
                {universityWidgets.isLoading ? (
                    <Loader />
                ) : (
                    <div id="page-body" className="page-body js-index">
                        <section className="content settings">
                            <h3 className="settings-title">Branding</h3>

                            <div className="settings-grid has-tutorial">
                                <div className="column">
                                    <div className="tutorial-intro">
                                        <p>
                                            Make The Ambassador Platform your own by customising it to fit your brand.
                                        </p>
                                        <hr className="settings-hr"></hr>

                                        <div className="settings-item">
                                            <h4 className="settings-name">Institution name</h4>
                                            <p>Change your institution's name</p>
                                            <EditUniversityNameForm
                                                university={account.account_info.university}
                                                handleSubmit={this.handleChangeUniversityNameSubmit}
                                            />
                                        </div>
                                        {account.account_info.university &&
                                            account.account_info.university.partnerGroups &&
                                            account.account_info.university.partnerGroups.length > 0 && (
                                                <div className="settings-item">
                                                    <h4 className="settings-name">Display name</h4>
                                                    <p>Institution's name for partner's page</p>
                                                    <EditDisplayNameForm
                                                        university={account.account_info.university}
                                                        handleSubmit={this.handleChangeDisplayNameSubmit}
                                                    />
                                                </div>
                                            )}

                                        <div className="settings-item">
                                            <h4 className="settings-name">Institution logo</h4>
                                            <p>
                                                Add your institution's logo. This will appear inside the app and on
                                                career references
                                            </p>
                                            <div className="university-logo-block">
                                                <div className="university-logo-preview">{logo}</div>
                                                <label className="university-logo-label">
                                                    <input
                                                        type="file"
                                                        className="university-logo-input"
                                                        onChange={this.handleChangeAvatarInput}
                                                        accept="image/*"
                                                    />
                                                    <span className="university-logo-text">Edit</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="settings-item">
                                            <h4 className="settings-name">Styles</h4>
                                            <p>Add your custom brand colors in hex format</p>
                                            <p>
                                                Your primary color will be used on ambassadors names on profile cards,
                                                and on buttons
                                            </p>{' '}
                                            <p>
                                                Your secondary color will be used on course type blocks and small text
                                                elements
                                            </p>
                                            <p>
                                                <strong>
                                                    When you save your changes, they will be active immediately on all
                                                    of your live widgets
                                                </strong>
                                            </p>
                                            <StyleForm onSubmit={this.handleStylesSubmit} />
                                        </div>
                                    </div>
                                </div>

                                <div className="column resources">
                                    <h3 className="video-title">Video tutorial: Custom branding</h3>
                                    <div className="video-wrapper">
                                        <iframe
                                            src="https://player.vimeo.com/video/340188090"
                                            width="640"
                                            height="373"
                                            frameBorder="0"
                                            allow="autoplay; fullscreen"
                                            allowFullScreen
                                        ></iframe>
                                    </div>

                                    <div className="resources-box">
                                        <h3>Helpful resources</h3>
                                        <ul>
                                            <li>
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="https://knowledge.theambassadorplatform.com/customizing-tap-to-fit-your-brand"
                                                    target="_blank"
                                                >
                                                    Customizing The Ambassador Platform to fit your brand
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )}

                <Modal
                    open={changeUniversityNameSuccess.isOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseUniversityNameSuccess}
                >
                    <ChangeUniversityNameSuccessPopup handleClosePopup={this.handleCloseUniversityNameSuccess} />
                </Modal>

                {showRestrictedModal && <RestrictedModal closable={true} closeModal={this.closeRestrictedModal} />}
            </>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        universityWidgets: state.universityWidgets,
        changeUniversityNameSuccess: state.changeUniversityNameSuccess,
    }
})(CustomStyles)
