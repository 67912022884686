import React, { useCallback, useState } from 'react'

import { Button } from 'antd'
import cn from 'classnames'

import { EmailInvitationForm, SmsInvitationForm } from './components'

import { ReactComponent as Chevron } from '../../../../../../../../assets/img/svg/chevron-down.svg'

const EmailAndSmsInvitation = () => {
    const [isOpen, setIsOpen] = useState(false)

    const handleButtonClick = useCallback(() => {
        setIsOpen((prevState) => !prevState)
    }, [])

    return (
        <>
            <Button type="link" className="email-and-sms-invitation-button" onClick={handleButtonClick}>
                Get an email/SMS with the link to download the app{' '}
                <Chevron className={isOpen ? 'chevron chevron-open' : 'chevron'} />
            </Button>
            <div className={cn('email-and-sms-invitation-fields', isOpen && 'email-and-sms-invitation-fields--opened')}>
                <EmailInvitationForm />
                <SmsInvitationForm />
            </div>
        </>
    )
}

export default EmailAndSmsInvitation
