import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import AnsweredQuestion from './components/AnsweredQuestion'
import CreateQuestionPopup from '../new_questions/components/popups/CreateQuestionPopup'
import Modal from 'antd/es/modal'
import Loader from '../../content/components/Loader'

import { deleteAnswer, deleteQuestion, QUESTIONS_ANSWERS_INFO, saveAnswer } from '../../../redux/actions/faq/faqActions'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

import { OPEN_CREATE_QUESTION_POPUP, CLOSE_CREATE_QUESTION_POPUP } from '../../../redux/actions/faq/componentsActions'
import ConfirmDeletePopup from '../../../components/popups/ConfirmDeletePopup'
import { closeDeleteModal, openDeleteModal } from '../../../redux/actions/content/postsActions'
import Search from '../../../components/Search'
import { Redirect } from 'react-router-dom'
import { getCommonRoutePath } from '../../../helpers/helpers'
import { Protect } from 'src/app/permissions/Protect'

class Library extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isLastTypePublished: props.isPublished,
            scrollId:
                window.location.href.includes('scrollFaqId=') && window.location.href.split('scrollFaqId=')[1]
                    ? window.location.href.split('scrollFaqId=')[1].split('&')[0]
                    : null,
        }

        this.requestSearch = this.requestSearch.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: QUESTIONS_ANSWERS_INFO,
            payload: {
                filter: {
                    published: this.props.isPublished,
                    searchItems: [],
                },
            },
        })

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'faq',
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: QUESTIONS_ANSWERS_INFO,
                payload: {
                    filter: {
                        published: this.props.isPublished,
                        searchItems: [],
                    },
                },
            })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.isLastTypePublished !== props.isPublished) {
            state.isLastTypePublished = props.isPublished

            store.dispatch({
                type: QUESTIONS_ANSWERS_INFO,
                payload: {
                    filter: {
                        published: props.isPublished,
                        searchItems: [],
                    },
                },
            })

            return state
        }

        return null
    }

    handleOpenCreateQuestionPopup() {
        store.dispatch({
            type: OPEN_CREATE_QUESTION_POPUP,
        })
    }

    handleCloseCreateQuestionPopup() {
        store.dispatch({
            type: CLOSE_CREATE_QUESTION_POPUP,
        })
    }

    requestSearch(items) {
        const filter = this.props.isPublished
            ? {
                  published: true,
                  searchItems: items,
              }
            : {
                  published: false,
                  searchItems: items,
              }

        store.dispatch({
            type: QUESTIONS_ANSWERS_INFO,
            payload: {
                filter: filter,
                withGlobalTags: this.props.isPublished,
            },
        })
    }

    render() {
        const { scrollId } = this.state
        const account = this.props.account
        // for now Redirect here, will move later to currentUniversityIsActivated
        if (
            !account.account_info ||
            (account.account_info && account.account_info.isGroupAdmin && !account.currentGroupUniversityId)
        ) {
            return <Redirect to={getCommonRoutePath() + '/home'} />
        }

        const {
            closeDeleteModal,
            openDeleteModal,
            confirmDeleteModal,
            deleteAnswer,
            deleteQuestion,
            saveAnswer,
            faqsAnswers,
            isPublished,
            createQuestionPopup,
        } = this.props

        const { postId, type, isOpened } = confirmDeleteModal

        const isLoading = isPublished ? faqsAnswers.published.isLoading : faqsAnswers.unpublished.isLoading
        const faqItems = isPublished ? faqsAnswers.published.items : faqsAnswers.unpublished.items

        let noData = null
        if (!isLoading && faqItems.length === 0) {
            noData = (
                <div className="non-cluster">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 -2 24 24"
                        width="50"
                        height="50"
                        preserveAspectRatio="xMinYMin"
                        className="jam jam-help"
                    >
                        <path d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.276-3.218a1 1 0 0 1-1.232-1.576l.394-.308a1.5 1.5 0 1 0-1.847-2.364l-.394.308a1 1 0 1 1-1.23-1.576l.393-.308a3.5 3.5 0 1 1 4.31 5.516l-.394.308z" />
                    </svg>
                    {isPublished ? 'No published questions.' : 'No answers pending approval.'}
                </div>
            )
        }

        const questions = faqItems.map((faq) => {
            return (
                <AnsweredQuestion
                    globalTags={faq.globalTags}
                    openDeleteModal={openDeleteModal}
                    saveAnswer={saveAnswer}
                    key={faq.id}
                    faq={faq}
                    isPublished={isPublished}
                    scroll={faq.id == scrollId}
                />
            )
        })

        return (
            <Protect>
                <section className="content flexible-page">
                    <div className="top-side-container">
                        <div className="left-side-container">
                            <Search requestSearch={this.requestSearch} currentItems={faqsAnswers.searchItems} />
                        </div>
                        <div className="right-side-container">
                            <a className="el-btn-outline create-faq-btn" onClick={this.handleOpenCreateQuestionPopup}>
                                Create new question
                            </a>
                        </div>
                    </div>
                    <div className="content-header">
                        <h1>{isPublished ? 'Library' : 'Answers to approve'}</h1>
                    </div>
                    {isLoading ? <Loader /> : questions}
                    {noData}
                </section>
                <Modal
                    open={createQuestionPopup.isOpened}
                    bodyStyle={{ padding: 0 }}
                    width="auto"
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseCreateQuestionPopup}
                    destroyOnClose
                    className="react-create-new-question"
                >
                    <CreateQuestionPopup
                        key={createQuestionPopup.mode}
                        onClosePopup={this.handleCloseCreateQuestionPopup}
                        isAnsweredQuestions={true}
                        isPublished={isPublished}
                    />
                </Modal>
                <Modal
                    open={isOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={closeDeleteModal}
                >
                    <ConfirmDeletePopup
                        type={type}
                        handleConfirmDelete={
                            type === 'question'
                                ? () => deleteQuestion({ id: postId })
                                : () => deleteAnswer({ id: postId })
                        }
                        handleClosePopup={closeDeleteModal}
                    />
                </Modal>
            </Protect>
        )
    }
}

export default connect(
    (state) => {
        return {
            account: state.account,
            createQuestionPopup: state.createQuestionPopup,
            confirmDeleteModal: state.confirmDeleteModal,
            faqsAnswers: state.faqsAnswers,
        }
    },
    { closeDeleteModal, openDeleteModal, saveAnswer, deleteAnswer, deleteQuestion }
)(Library)
