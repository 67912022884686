import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import store from '../../../redux/store'

import DataManagementDropdown from './components/DataManagementDropdown'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { SET_MANAGEMENT_TERM } from '../../../redux/actions/settings/dataManagement'

import { CHANGE_MANAGEMENT_TERM, ACCOUNT_INFO_REQUESTED } from '../../../redux/actions/account/accountActions'

import { OPEN_ACTION_MODAL, CLOSE_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'
import { Redirect } from 'react-router'
import { getCommonRoutePath } from '../../../helpers/helpers'

class AutoDeletion extends PureComponent {
    constructor() {
        super()
        this.state = {}

        this.renderConfirmationPopup = this.renderConfirmationPopup.bind(this)
        this.closeConfirmationPopup = this.closeConfirmationPopup.bind(this)
        this.setTerm = this.setTerm.bind(this)
        this.onManagementTermSelect = this.onManagementTermSelect.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
        store.dispatch({
            type: ACCOUNT_INFO_REQUESTED,
        })
    }

    renderConfirmationPopup(term) {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">
                            By changing the data deletion period you will delete prospective student accounts that
                            weren't active for the last {term} month
                        </h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button
                                type="submit"
                                className="el-btn confirm-delete-button"
                                onClick={() => this.setTerm(term)}
                            >
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={() => this.closeConfirmationPopup()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    setTerm(term) {
        store.dispatch({
            type: SET_MANAGEMENT_TERM,
            payload: {
                dataManagement: term,
            },
        })
    }

    closeConfirmationPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    onManagementTermSelect(dataManagement) {
        store.dispatch({
            type: CHANGE_MANAGEMENT_TERM,
            payload: {
                data_management_term: dataManagement.value,
            },
        })
    }

    render() {
        const { account } = this.props

        if (account.account_info.isGroupAdmin && !account.currentGroupUniversityId) {
            return <Redirect to={getCommonRoutePath() + '/home'} />
        }

        return (
            <div className="auto-deletion-page">
                <section className="content settings">
                    <h3 className="settings-title">Auto deletion</h3>
                    <div className="settings-grid has-tutorial">
                        <div className="auto-deletion-container">
                            <div className="settings-intro">
                                <p>
                                    You are the data controller and we are processing data on your behalf. Control your
                                    data retention preferences by adjusting the configurations below.
                                </p>
                            </div>
                            <hr className="settings-hr"></hr>
                            <div className="text-bottom">
                                <p>
                                    The Ambassador Platform will automatically delete all personally identifiable
                                    information of users, including chat transcripts from the platform after the
                                    following number of months:
                                </p>
                                <DataManagementDropdown
                                    onChange={this.onManagementTermSelect}
                                    dataManagementTerm={account.account_info.university.data_management_term}
                                />
                                <button
                                    type="submit"
                                    className="el-btn"
                                    onClick={() =>
                                        this.renderConfirmationPopup(
                                            account.account_info.university.data_management_term
                                        )
                                    }
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(AutoDeletion)
