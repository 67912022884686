import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import store from '../redux/store'
import { REMOVE_NOTIFICATION, HIDE_NEW_MESSAGES } from '../redux/actions/components/componentsActions'

import { NavLink } from 'react-router-dom'
import { getCommonRoutePath } from '../helpers/helpers'

class Notifications extends PureComponent {
    handleRemoveNotification(index) {
        store.dispatch({
            type: REMOVE_NOTIFICATION,
            payload: {
                notification: index,
            },
        })
    }

    handleClick(link) {
        store.dispatch({
            type: HIDE_NEW_MESSAGES,
        })
    }

    showNotificationView() {
        const notificationKey = this.props.notifications.items.length > 0 ? this.props.notifications.items[0].key : null
        const notificationObject = this.props.notificationControl.items.find(
            (element) => element.key === notificationKey
        )

        if (notificationKey && notificationObject) {
            return notificationObject.is_active
        }
        return true
    }

    render() {
        const notificationsView = this.props.notifications.items.map((notification) => {
            return notification.link ? (
                <div key={notification.unique_index} className={'notification-block ' + notification.className}>
                    <NavLink
                        to={`${getCommonRoutePath()}/${notification.link.split('?')[0]}`}
                        onClick={() => this.handleClick(notification.link)}
                    >
                        <p className="notification-text">{notification.text}</p>
                    </NavLink>

                    <button
                        type="button"
                        onClick={() => {
                            this.handleRemoveNotification(notification.unique_index)
                        }}
                        className="notification-btn"
                    >
                        Dismiss
                    </button>
                </div>
            ) : (
                <div key={notification.unique_index} className={'notification-block ' + notification.className}>
                    <p className="notification-text">{notification.text}</p>
                    <button
                        type="button"
                        onClick={() => {
                            this.handleRemoveNotification(notification.unique_index)
                        }}
                        className="notification-btn"
                    >
                        Dismiss
                    </button>
                </div>
            )
        })

        return <div className="notifications-wrapper">{this.showNotificationView() ? notificationsView : null}</div>
    }
}

export default connect((state) => {
    return {
        notifications: state.customNotifications,
        notificationControl: state.notificationsControl,
    }
})(Notifications)
