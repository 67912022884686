import { Form, Switch, Typography, Col } from 'antd';
import React from 'react';
var Text = Typography.Text;
var PublishControl = function (_a) {
    var id = _a.id, label = _a.label, description = _a.description;
    return (React.createElement(Col, { className: "stream-publish-control" },
        React.createElement(Text, null, label),
        React.createElement(Form.Item, { name: id, valuePropName: "checked" },
            React.createElement(Switch, { "data-automation-id": id })),
        React.createElement(Text, { className: "description" }, description)));
};
export default PublishControl;
