import { call, put, select } from 'redux-saga/effects'
import API from '../../../api'
import { GET_TOPICS_LIST_SUCCESS, GET_TOPICS_LIST_FAILED, ADD_TOPICS_SUCCESS } from '../../actions/chat/topicsActions'

export function* getTopics(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.chat.getAllTopics(payload.limit, payload.offset, payload.search)
        })
        if (response && response.success) {
            yield put({
                type: GET_TOPICS_LIST_SUCCESS,
                payload: {
                    items: response.data.topics,
                    total: response.data.total,
                    offset: payload.offset,
                },
            })
        } else {
            yield put({
                type: GET_TOPICS_LIST_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_TOPICS_LIST_FAILED,
        })
    }
}

export function* addTopics(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.chat.addTopics(payload.dialogId, payload.topics, payload.lastMessageId)
        })
        if (response && response.success) {
            yield put({
                type: ADD_TOPICS_SUCCESS,
                payload: {
                    topics: response.data ? response.data.topics : null,
                },
            })
            action.afterSuccess()
        }
    } catch (e) {
        console.log(e)
    }
}

export function* editTopic(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.chat.editTopic(payload.dialogId, payload.topicId, payload.confirmation)
        })
        if (response && response.success) {
            yield put({
                type: ADD_TOPICS_SUCCESS,
                payload: {
                    topics: response.data ? response.data.topics : null,
                },
            })
            action.afterSuccess()
        }
    } catch (e) {
        console.log(e)
    }
}

export function* deleteTopic(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.chat.deleteTopic(payload.dialogId, payload.topicId)
        })
        if (response && response.success) {
            yield put({
                type: ADD_TOPICS_SUCCESS,
                payload: {
                    topics: response.data ? response.data.topics : null,
                },
            })
            action.afterSuccess()
        }
    } catch (e) {
        console.log(e)
    }
}
