/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'

import { CHAT_TYPE_KEY } from '../../../../../../../../../../common/constants'

const ChatFooter = ({ activeEvent, type }) => {
    const show = useMemo(() => !activeEvent.is_chat_active && type === CHAT_TYPE_KEY.LIVE_STREAM_CHAT, [activeEvent])

    return (
        show && (
            <div className="chat-footer-closed">
                <p className="closed-text">The chat is closed</p>
            </div>
        )
    )
}

export default ChatFooter
