import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'

const MandatoryView = ({ groupData }) => {
    const mandatoryDataView = []
    console.log(groupData)
    if (groupData && groupData.length > 0) {
        for (const key in groupData) {
            mandatoryDataView.push(
                <div key={key} className="optional-data-item">
                    <p className="optional-data-name">{groupData[key].question}</p>
                    <span className="optional-data-value">{groupData[key].answer}</span>
                </div>
            )
        }
    }
    return mandatoryDataView
}

class OptionalData extends PureComponent {
    render() {
        const { mandatoryData, isOptionalDataLoading, account } = this.props
        // Hide marketing preference for Downing College
        const hideMarketingPreferences =
            account.account_info.university.id === 100_061 || account.account_info.university.id === 444
        let optionalData = this.props.optionalData
        if (hideMarketingPreferences && optionalData && optionalData.length > 0) {
            optionalData = optionalData.filter((data) => data.questionKey !== 'marketing_preference')
        }

        if (isOptionalDataLoading) {
            return <Spin spinning={true} className="loader-in-popup"></Spin>
        }

        return (
            <div className="optional-data-block scroll-8">
                {(optionalData && optionalData.length > 0) || (mandatoryData && mandatoryData.length > 0) ? (
                    <>
                        {optionalData &&
                            optionalData.map((item, index) => {
                                return (
                                    <div key={index} className="optional-data-item">
                                        <p className="optional-data-name">{item.question}</p>
                                        <span className="optional-data-value">{item.answer}</span>
                                    </div>
                                )
                            })}
                        {mandatoryData &&
                            mandatoryData.map((item, index) => {
                                return <MandatoryView key={index} groupData={item.data} />
                            })}
                    </>
                ) : (
                    <span className="no-prospect-info-data">No additional data</span>
                )}
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        optionalData: state.prospects.optionalData,
        mandatoryData: state.prospects.mandatoryData,
        isOptionalDataLoading: state.prospects.isOptionalDataLoading,
    }
})(OptionalData)
