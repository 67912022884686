import { PLS_SIGN_UP } from './pls-sign-up-types'

export const getAppDownloadLink = (data) => ({
    type: PLS_SIGN_UP.GET_APP_DOWNLOAD_LINK,
    payload: data,
})

export const finishPlsSignUp = (onPlsSignUpSuccess) => ({
    type: PLS_SIGN_UP.PLS_SETUP_WIZARD_FINISH,
    onPlsSignUpSuccess,
})

export const openSuccessSignUpModal = () => ({
    type: PLS_SIGN_UP.OPEN_SUCCESS_SIGN_UP_MODAL,
})

export const closeSuccessSignUpModal = () => ({
    type: PLS_SIGN_UP.CLOSE_SUCCESS_SIGN_UP_MODAL,
})

export const openOnboardingPlsModal = () => ({
    type: PLS_SIGN_UP.OPEN_ONBOARDING_PLS_MODAL,
})

export const closeOnboardingPlsModal = () => ({
    type: PLS_SIGN_UP.CLOSE_ONBOARDING_PLS_MODAL,
})
