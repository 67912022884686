import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLOSE_ACTION_MODAL, OPEN_ACTION_MODAL } from '../../../../../redux/actions/components/componentsActions'
import { ARCHIVE_DIALOG_TOGGLE } from '../../../../../redux/actions/chat/messagingActions'

const Header = ({ dialogId }) => {
    const dispatch = useDispatch()

    const chat = useSelector((state) => state.chats.items.find((item) => +item.id === +dialogId) || {})

    const unArchive = useCallback(() => {
        const onConfirm = () => {
            dispatch({
                type: ARCHIVE_DIALOG_TOGGLE,
                payload: {
                    dialogId: chat.id,
                    archived: true,
                },
            })
        }
        const handleCloseConfirmPopup = () => {
            dispatch({
                type: CLOSE_ACTION_MODAL,
            })
        }

        dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button type="submit" className="el-btn confirm-delete-button" onClick={onConfirm}>
                                Yes, I am sure
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }, [chat, dispatch])

    return (
        <>
            <div className="chat-head">
                <div className="chat-head-left">
                    <div className="chat-name-block chat-name-block--chat">
                        <div className="chat-name-block__info">
                            <div className="chat-nav-name-container">
                                <h3 className="chat-name">{chat.name}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chat-head-right">
                    <a onClick={unArchive} className="el-btn create-group-chat-btn" style={{ marginBottom: 0 }}>
                        Unarchive
                    </a>
                </div>
            </div>
        </>
    )
}

export default Header
