import { Modal } from 'antd';
import React from 'react';
import { useModalVisibility } from 'src/app/hooks/useModalVisibility';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { CLOSE_CONTENT_GROUP_POPUP } from 'src/app/redux/actions/content/messagingActions';
import { IdpContentGroupForm } from './forms/idp-content-group-form';
export var IdpContentGroupModal = function () {
    var createOpened = useAppSelector(function (state) { return state.createContentGroupModal; }).isOpened;
    var dispatch = useAppDispatch();
    var visible = createOpened;
    var _a = useModalVisibility(visible), mount = _a[0], setMount = _a[1];
    var closeModal = function () {
        dispatch({
            type: CLOSE_CONTENT_GROUP_POPUP,
        });
    };
    return mount ? (React.createElement(Modal, { open: visible, className: "react-create-content-group", centered: true, closable: true, maskClosable: true, destroyOnClose: true, onCancel: closeModal, afterClose: setMount, footer: null },
        React.createElement(IdpContentGroupForm, null))) : null;
};
