import React from 'react'
import classnames from 'classnames'
import dayjs from 'dayjs'

export const TimeList = ({
    statsRound,
    ambassadors,
    openMTimeLimit,
    timeLimitsActive,
    choosenLimitType,
    handleOpenTagsPopup,
}) => {
    if (!Array.isArray(ambassadors)) return null

    return ambassadors.map((user) => (
        <UserItem
            {...user}
            key={user.id}
            statsRound={statsRound}
            openMTimeLimit={openMTimeLimit}
            timeLimitsActive={timeLimitsActive}
            choosenLimitType={choosenLimitType}
            handleOpenTagsPopup={() => handleOpenTagsPopup(user.fullInfo)}
        ></UserItem>
    ))
}

export const UserItem = ({
    id,
    name,
    avatar,
    sumTotal,
    timeLimit,
    roundedFaq,
    roundedChat,
    roundedContent,
    roundedLiveEvent,
    openMTimeLimit,
    timeLimitsActive,
    handleOpenTagsPopup,
    choosenLimitType,
}) => {
    return (
        <tr key={id} className={!sumTotal && 'ambassador-no-activity'}>
            <td className="table-item">
                <div className="ambassador-information">
                    <div className="table-item-img-container">
                        <Avatar avatar={avatar} name={name}></Avatar>
                    </div>
                    <span className="">{name}</span>
                </div>
            </td>

            <td className="table-item full-visible">
                <button
                    type="button"
                    className="view-profile-info-btn"
                    data-user-index={id}
                    onClick={handleOpenTagsPopup}
                >
                    View
                </button>
            </td>

            <td className="table-item">{roundedChat}</td>

            <td className="table-item">{roundedContent}</td>

            <td className="table-item">{roundedFaq}</td>

            {!timeLimitsActive && <td className="table-item">{roundedLiveEvent}</td>}

            <td className="table-item">
                <SumTotal
                    timeLimit={timeLimit}
                    total={sumTotal}
                    timeLimitsActive={timeLimitsActive}
                    choosenLimitType={choosenLimitType}
                />
            </td>

            <td className={classnames('table-item', { disabled: !timeLimitsActive })}>
                <LeftMinuts
                    timeLimit={timeLimit}
                    total={sumTotal}
                    timeLimitsActive={timeLimitsActive}
                    choosenLimitType={choosenLimitType}
                />
            </td>

            <td
                className={classnames(
                    'table-item',
                    { 'full-visible': timeLimitsActive },
                    { disabled: !timeLimitsActive }
                )}
            >
                <ButtonLimit timeLimit={timeLimit} click={openMTimeLimit} id={id} />
            </td>
        </tr>
    )
}

export const SumTotal = ({ timeLimit, total, timeLimitsActive, choosenLimitType }) => {
    if (!timeLimitsActive) return total

    if (!timeLimit || Object.keys(timeLimit).length === 0) return total

    const limit = getRealLimit(timeLimit, choosenLimitType)
    const limitIn80 = (limit * 80) / 100
    const redLabel = total >= limitIn80 ? 'red-label' : null
    return (
        <span className={redLabel}>
            {total > limit ? limit : total}/{limit}
        </span>
    )
}

export const LeftMinuts = ({ total, timeLimit, timeLimitsActive, choosenLimitType }) => {
    if (!timeLimitsActive || !timeLimit || Object.keys(timeLimit).length === 0) return '-'

    const leftMinuts = getRealLimit(timeLimit, choosenLimitType) - total

    return <span className={leftMinuts < 1 ? 'red-label' : null}>{leftMinuts > -1 ? leftMinuts : 0}</span>
}

export const Avatar = ({ avatar, name }) => {
    const avatarSrc = avatar ? avatar.original : null

    return avatarSrc ? (
        <img className="table-item-img" src={avatarSrc} alt="img" />
    ) : (
        <div className="table-item-img table-item-img-name">{name[0]}</div>
    )
}

export const ButtonLimit = ({ timeLimit, click, id }) => {
    let text = 'Set time'
    const isSet = timeLimit && Object.keys(timeLimit).length > 0
    if (isSet) text = 'Change time'

    return (
        <button
            type="button"
            className={'view-profile-info-btn' + (!isSet ? ' blue-mod' : '')}
            onClick={() => click(id, timeLimit)}
        >
            {text}
        </button>
    )
}

export const getRealLimit = (timeLimit, choosenLimitType) => {
    let limit = timeLimit.limit_minuts

    if (choosenLimitType != timeLimit.limit_type) {
        let limitPerDay = null
        const dayInMonth = dayjs().daysInMonth()

        if (choosenLimitType == 'month') {
            limitPerDay = timeLimit.limit_minuts / 7

            limit = limitPerDay * dayInMonth
        } else if (choosenLimitType == 'week') {
            limitPerDay = timeLimit.limit_minuts / dayInMonth
            limit = limitPerDay * 7
        }
    }

    return Math.round(limit)
}
