import axios from 'axios'
import store from '../../../redux/store'
import Notifications from 'react-notification-system-redux'
import { ACCOUNT_LOGOUT } from '../../../redux/actions/account/accountActions'
import { ACCOUNT } from '../../../redux/actions/account/account-types'

class Handler {
    // eslint-disable-next-line no-useless-constructor
    constructor() {}

    static errorHandler(err) {
        if (!axios.isCancel(err) && err.response && err.response.status) {
            const message =
                err.response.data && err.response.data.error && err.response.data.error.message
                    ? err.response.data.error.message
                    : ''

            switch (err.response.status) {
                case 400:
                    this.badRequest(message)
                    break
                case 401:
                    this.authError(message)
                    break
                case 403:
                    this.forbidden(message)
                    break
                case 404:
                    this.notFound(message)
                    break
                case 429:
                    this.forbidden(message)
                    break
                case 499:
                    this.forbidden(message)
                    break
                case 503:
                    this.maintenance(message)
                    break
                default:
                    this.defaultError(message)
                    break
            }
        } else {
            this.networkError()
        }
    }

    static badRequest(message) {
        store.dispatch(
            Notifications.error({
                title: 'Error',
                message: message.length > 0 ? message : 'Something went wrong.',
                position: 'tr',
                action: '',
            })
        )
    }

    static authError(message) {
        store.dispatch({
            type: ACCOUNT_LOGOUT,
        })
    }

    static forbidden(message) {
        store.dispatch(
            Notifications.error({
                title: 'Error',
                message: message.length > 0 ? message : 'Something went wrong.',
                position: 'tr',
                action: '',
            })
        )
    }

    static notFound(message) {
        store.dispatch(
            Notifications.error({
                title: 'Error',
                message: message.length > 0 ? message : 'Something went wrong.',
                position: 'tr',
                action: '',
            })
        )
    }

    static defaultError() {
        store.dispatch(
            Notifications.error({
                title: 'Error',
                message: 'Please contact support.',
                position: 'tr',
                action: '',
            })
        )
    }

    static networkError() {
        store.dispatch(
            Notifications.error({
                title: 'Error',
                message: 'Something went wrong.',
                position: 'tr',
                action: '',
            })
        )
    }

    static maintenance() {
        store.dispatch({
            type: ACCOUNT.SET_MAINTENANCE_MODE,
        })
    }
}

export default Handler
