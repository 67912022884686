import { Form, Input, Button } from 'antd'
import React, { useCallback } from 'react'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'

const MailOutlined = () => {
    return (
        <svg className="icon icon-sso-mail">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#mail'}></use>
        </svg>
    )
}

const SignInSsoForm = ({ onSubmit }) => {
    const [form] = Form.useForm()

    const handleSubmit = useCallback(
        (values) => {
            onSubmit(values.email.trim())
        },
        [onSubmit]
    )

    return (
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
                label={null}
                name="email"
                style={{ width: '100%' }}
                rules={[
                    {
                        required: true,
                        type: 'email',
                        message: 'Not a valid email',
                    },
                ]}
            >
                <Input className="sso-email-field" prefix={<MailOutlined />} placeholder="Email address" />
            </Form.Item>
            <Form.Item shouldUpdate style={{ width: '100%' }}>
                {({ isFieldsTouched, getFieldsError }) => {
                    return (
                        <Button
                            htmlType="submit"
                            className="signin-btn sign-in-sso-btn"
                            disabled={!isFieldsTouched() || getFieldsError().some(({ errors }) => errors.length)}
                        >
                            Sign In with SSO
                        </Button>
                    )
                }}
            </Form.Item>
        </Form>
    )
}

export default SignInSsoForm
