import { connect } from 'react-redux'
import React, { PureComponent } from 'react'

class JoinedAdminMessage extends PureComponent {
    render() {
        const { message, account } = this.props
        const { user_id: userId, content, user: { avatar, name } = {} } = message
        const {
            account_info: { id },
        } = account

        if (id === userId) return null
        return (
            <div className="join-row">
                <div className="msg-avatar-block">
                    {avatar ? (
                        <img className="msg-item-img" src={avatar.sizes['70x70'] || avatar.original} />
                    ) : (
                        <div className="msg-avatar-text">{name ? name.charAt(0) : ''}</div>
                    )}
                </div>
                <p className="join-text">{content.user} joined the chat</p>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        contentGroups: state.contentGroups,
        account: state.account,
    }
})(JoinedAdminMessage)
