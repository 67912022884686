export var CardTypesEnum;
(function (CardTypesEnum) {
    CardTypesEnum["Institution"] = "institution";
    CardTypesEnum["Admin"] = "admin";
    CardTypesEnum["Ambassador"] = "ambassador";
    CardTypesEnum["Student"] = "student";
})(CardTypesEnum || (CardTypesEnum = {}));
export var cardsData = [
    {
        icon: 'pls_institution',
        key: CardTypesEnum.Institution,
        title: 'New institution',
        text: 'I want to create a free account to try TAP',
    },
    {
        icon: 'pls_admin',
        key: CardTypesEnum.Admin,
        title: 'Admin',
        text: 'of an institution already using The Ambassador Platform',
    },
    {
        icon: 'pls_ambassador',
        key: CardTypesEnum.Ambassador,
        title: 'Student Ambassador',
        text: 'of an institution already using The Ambassador Platform',
    },
    {
        icon: 'pls_school',
        key: CardTypesEnum.Student,
        title: 'Student',
        text: "I'm a future student exploring my study options",
    },
];
