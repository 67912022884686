import { NOTIFICATION_MODAL } from './notification-modal-action-types'

export const openNotificationModal = (notificationType, data = {}) => ({
    type: NOTIFICATION_MODAL.OPEN,
    payload: {
        notificationType,
        data,
    },
})

export const closeNotificationModal = () => ({
    type: NOTIFICATION_MODAL.CLOSE,
})
