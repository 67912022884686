import React from 'react';
import { isMobile } from '../../../../../helpers/helpers';
import { SignUpMobile } from './components/mobile';
import { SignUpWeb } from './components/web';
import { App as AntdApp, ConfigProvider } from 'antd';
export var PlsSignUpScreen = function () {
    var isMobileVersion = isMobile();
    return (React.createElement(ConfigProvider, { theme: {
            token: {
                colorError: '#D90000',
                colorLinkHover: 'rgba(255, 81, 0, 0.6)',
                fontSize: isMobileVersion ? 14 : 16,
            },
            components: {
                Input: {
                    borderRadius: 6,
                    fontSizeIcon: 16,
                },
                Checkbox: {
                    colorPrimary: '#222222',
                    colorPrimaryHover: '#222222',
                },
                Button: {
                    borderRadius: 6,
                },
                Select: {
                    borderRadius: 6,
                    colorIcon: '#222222',
                    fontSize: 16,
                },
            },
        } },
        React.createElement(AntdApp, null,
            React.createElement("main", { className: "pls-auth-container" }, isMobileVersion ? React.createElement(SignUpMobile, null) : React.createElement(SignUpWeb, null)))));
};
