import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'
import PlaceholderUser from '../../../../../assets/img/placeholder-user.png'

class Feedback extends PureComponent {
    render() {
        const { data, isFeedbackDataLoading } = this.props

        if (isFeedbackDataLoading) {
            return <Spin spinning={true} className="loader-in-popup"></Spin>
        }

        return (
            <div className="feedback-data-block">
                {data && data.length > 0 ? (
                    <>
                        <p className="feedback-title">
                            <span>Ambassador</span>
                            <span>Feedback</span>
                        </p>
                        <div className="feedback-data-items scroll-8">
                            {data.map((item, index) => {
                                return (
                                    <div key={index} className="feedback-data-item">
                                        <div className="feedback-sa-info">
                                            <img
                                                src={
                                                    item.ambassador && item.ambassador.avatar
                                                        ? item.ambassador.avatar.sizes['70x70'] ||
                                                          item.ambassador.avatar.original
                                                        : PlaceholderUser
                                                }
                                                alt=""
                                            />
                                            <div className="feedback-sa-name-block">
                                                <p className="feedback-sa-name">
                                                    {item.ambassador && item.ambassador.name
                                                        ? item.ambassador.name
                                                        : 'Deleted user'}
                                                </p>
                                                {item.ambassador && item.ambassador.user_tags && (
                                                    <span className="feedback-sa-courses">
                                                        {item.ambassador.user_tags.subject &&
                                                            item.ambassador.user_tags.subject.map(
                                                                ({ name }, index) =>
                                                                    name +
                                                                    (item.ambassador.user_tags.subject[index + 1]
                                                                        ? ', '
                                                                        : '')
                                                            )}
                                                        {item.ambassador.user_tags.courses_types &&
                                                            item.ambassador.user_tags.courses_types.map(
                                                                ({ name }, index) =>
                                                                    ((item.ambassador.user_tags.subject &&
                                                                        item.ambassador.user_tags.subject.length > 0) ||
                                                                    +index
                                                                        ? ', '
                                                                        : '') + name
                                                            )}
                                                        {item.ambassador.user_tags.job_title &&
                                                            item.ambassador.user_tags.job_title.map(
                                                                ({ name }, index) => `${name}`
                                                            )}
                                                        {item.ambassador.user_tags.job_role &&
                                                            item.ambassador.user_tags.job_role.map(
                                                                ({ name }, index) => `${name}, `
                                                            )}
                                                        {item.ambassador.user_tags.industry &&
                                                            item.ambassador.user_tags.industry.map(({ name }) => name)}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="feedback-info">
                                            <div className="feedback-text-title">
                                                How interested in institution -{' '}
                                                <span className="feedback-score">{item.score}/5</span>
                                            </div>
                                            <div className="feedback-text">{item.comments}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                ) : (
                    <span className="no-prospect-info-data">No feedback from Ambassadors</span>
                )}
            </div>
        )
    }
}

export default connect((state) => {
    return {
        data: state.prospects.feedbackData,
        isFeedbackDataLoading: state.prospects.isFeedbackDataLoading,
    }
})(Feedback)
