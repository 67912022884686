import { call, put, select } from 'redux-saga/effects'
import API from '../../../api'

import {
    GET_CSP_DOMAINS_SUCCESS,
    GET_CSP_DOMAINS_FAILED,
    SET_CSP_DOMAINS_SUCCESS,
    SET_CSP_DOMAINS_FAILED,
} from '../../actions/settings/cspDomainsActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* getCspDomains() {
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.settings.getCspDomains()
                : API.settings.getCspDomains(universityId)
        })

        yield response.success
            ? put({
                  type: GET_CSP_DOMAINS_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: GET_CSP_DOMAINS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: GET_CSP_DOMAINS_FAILED,
        })
    }
}

export function* setCspDomains(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.settings.setCspDomains()
                : API.settings.setCspDomains(payload, universityId)
        })
        if (response && response.success) {
            yield put({
                type: SET_CSP_DOMAINS_SUCCESS,
            })

            if (action.onFinish) action.onFinish(true)
        } else {
            yield put({
                type: SET_CSP_DOMAINS_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: SET_CSP_DOMAINS_FAILED,
        })
        if (action.onFinish) action.onFinish()
    }
}
