import React, { useState, useCallback } from 'react'

import { Input, Button, Row, Col, Form } from 'antd'

import { useDispatch } from 'react-redux'

import { changeOrganisationWebsite } from '../../../../../redux/actions/account/account-actions'

const OrganisationUrl = ({ organisationUrl, organisationId }) => {
    const [editMode, setEditMode] = useState(false)

    const dispatch = useDispatch()

    const editUrl = useCallback(() => {
        setEditMode(true)
    }, [])

    const onSuccess = useCallback(() => {
        setEditMode(false)
    }, [])

    const saveUrl = useCallback(
        (values) => {
            const data = {
                websiteUrl: values.organisationUrl,
                institutionId: organisationId,
                onSuccess: onSuccess,
            }
            dispatch(changeOrganisationWebsite(data))
        },
        [dispatch, organisationId, onSuccess]
    )

    const cancelEditUrl = useCallback(() => {
        setEditMode(false)
    }, [])

    return (
        <Col style={{ marginBottom: '4rem' }}>
            <p>
                Users will be redirected to this website when they click on the organisation logo on the tap hosted
                version
            </p>
            {editMode ? (
                <Form
                    layout="vertical"
                    initialValues={{
                        organisationUrl: organisationUrl,
                    }}
                    onFinish={saveUrl}
                    autoComplete="off"
                    hideRequiredMark
                    style={{ display: 'flex' }}
                >
                    <Form.Item
                        name="organisationUrl"
                        label={null}
                        rules={[
                            { required: true, message: 'Add organisation url' },
                            { type: 'url', message: 'Organisation url is not valid' },
                        ]}
                    >
                        <Input maxLength={50} type="text" style={{ minWidth: '20rem' }} />
                    </Form.Item>
                    <div>
                        <Button className="settings-btn" type="link" htmlType="submit">
                            Save
                        </Button>
                        <Button className="settings-btn" type="link" onClick={cancelEditUrl}>
                            Cancel
                        </Button>
                    </div>
                </Form>
            ) : (
                <Row>
                    <Col style={{ fontSize: '1.6rem', opacity: 0.6, paddingTop: '4px' }}>
                        {!editMode ? (
                            <a className="edit-url-link" href={organisationUrl} target="__blank">
                                {organisationUrl}
                            </a>
                        ) : (
                            organisationUrl
                        )}
                    </Col>
                    <Button className="settings-btn" type="link" onClick={editUrl}>
                        Edit
                    </Button>
                </Row>
            )}
        </Col>
    )
}

export default OrganisationUrl
