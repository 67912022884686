import React from 'react'

const ParticipantAvatar = ({ user }) => {
    const { avatar, name } = user

    return (
        <div className="participant-avatar">
            {avatar ? (
                <div className="participant-avatar-img">
                    <div className="participant-avatar-img-content" style={{ backgroundImage: `url(${avatar})` }} />
                </div>
            ) : (
                <div className="participant-avatar-img">
                    <div className="participant-avatar-img-content">
                        <div className="participant-avatar-img-name">{name[0]}</div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ParticipantAvatar
