import { Button, Col, Form, Modal, Row } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg'
import { closeSpeakersModal, updateSpeakers } from '../../../../redux/actions/live-event/live-events-actions'
import { getLiveEventsSpeakersModal } from '../../../../redux/selectors'

import { SpeakersForm, SpeakersRequestModel } from './speakers-form'

const SpeakersFormModal = () => {
    const dispatch = useDispatch()
    const { open, data, submitting } = useSelector(getLiveEventsSpeakersModal)
    const [form] = Form.useForm()

    const handleModalClose = useCallback(() => {
        dispatch(closeSpeakersModal())
    }, [dispatch])

    const handleSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            const requestPayload = SpeakersRequestModel(values, data)
            dispatch(updateSpeakers(requestPayload))
        })
    }, [data, dispatch, form])

    const footer = useMemo(() => {
        return (
            <Row justify="center">
                <Col flex="162px">
                    <Button
                        type="primary"
                        block
                        size="large"
                        disabled={submitting}
                        loading={submitting}
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        )
    }, [handleSubmit, submitting])

    return (
        <Modal
            title="Manage speakers"
            footer={footer}
            open={open}
            closeIcon={<CloseIcon />}
            onCancel={handleModalClose}
            width={600}
            className="tap-event-modal"
            destroyOnClose
        >
            <SpeakersForm form={form} data={data} />
        </Modal>
    )
}

export default SpeakersFormModal
