export const PUBLISH_CONTROL = {
    LABEL: `Publish and share the Live Stream video recording automatically`,
    DESCRIPTON: `When a recorded Live Stream is completed 
    and the video is uploaded on our server, 
    it is automatically published and shared 
    with the registered prospects via e-mail. 
    In case you want to review or download, 
    tweak and re-upload the video before it is live, 
    please, turn automatic publishing and sharing OFF`,
    ID: 'autoPublish',
}

export const MODAL_TYPE = {
    TYPE_MODAL: 'streamTypeModal',
    AMBASSADORS_MODAL: 'ambassadorsModal',
    SPEAKERS_MODAL: 'speakersModal',
    PUBLISH_CONTENT_MODAL: 'publishControlModal',
    VIDEO_RE_UPLOAD_MODAL: 'videoReUploadModal',
    EVENT_MODAL: 'eventModal',
    SHEDULED_EVENT_MODAL: 'scheduledEventModal',
    USERS_MODAL: 'usersModal',
}
