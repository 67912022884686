import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { getLiveEventsSlice } from './common-selectors'
import LiveEventModel from '../../models/live-event-model'
import { getItems } from '../utils/selector-utils'

const getLiveEventsItems = createSelector(getLiveEventsSlice, getItems)
export const getActiveLiveEventId = createSelector(getLiveEventsSlice, ({ activeEventId }) => activeEventId)

export const getLiveEventsModal = createSelector(getLiveEventsSlice, ({ eventModal }) => eventModal)
export const getLiveEventJoinModal = createSelector(getLiveEventsSlice, ({ joinModal }) => joinModal)
export const getLiveEventsAmbassadorsModal = createSelector(
    getLiveEventsSlice,
    ({ ambassadorsModal }) => ambassadorsModal
)
export const getLiveEventsSpeakersModal = createSelector(getLiveEventsSlice, ({ speakersModal }) => speakersModal)

export const getLiveEvents = createSelector(getLiveEventsItems, ({ allIds, byId }) =>
    allIds.map((id) => LiveEventModel(byId[id]))
)
export const getLiveEventsIsLoading = createSelector(getLiveEventsSlice, prop('loading'))

export const getActiveLiveEvent = createSelector(getActiveLiveEventId, getLiveEventsItems, (activeEventId, { byId }) =>
    LiveEventModel(byId[activeEventId])
)
export const getOnlineUsers = createSelector(getLiveEventsSlice, prop('onlineUsers'))
