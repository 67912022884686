var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Form } from 'antd';
import React, { useCallback, useState } from 'react';
import { Button } from 'src/app/components/base/Button';
import { useAppDispatch } from 'src/app/hooks/store';
import { registerAccount } from 'src/app/redux/actions/account/account-actions';
import Logo from 'src/assets/svg/logo.svg';
import { LastStepFields } from '../LastStepFields';
import { ProfileSetupFields } from '../ProfileSetupFields';
import { UserInfoFields } from '../UserInfoFields';
import { pickFieldsToValidate, stepTitles } from '../helpers';
import { RegisteringTypeStep } from '../registering-type-step';
export var SignUpWeb = function () {
    var form = Form.useForm()[0];
    var dispatch = useAppDispatch();
    var _a = useState(0), currentStep = _a[0], setCurrentStep = _a[1];
    var _b = useState(false), isTypeSelected = _b[0], setIsTypeSelected = _b[1];
    var titles = stepTitles(currentStep);
    var onNext = function () {
        var fieldsToValidate = pickFieldsToValidate(currentStep);
        var isLastStep = currentStep === 2;
        if (isLastStep) {
            form.validateFields().then(function (values) {
                var terms = values.terms, confirmPassword = values.confirmPassword, requestValues = __rest(values, ["terms", "confirmPassword"]);
                dispatch(registerAccount(__assign(__assign({}, requestValues), { isTrial: true })));
            });
        }
        else {
            form.validateFields(fieldsToValidate).then(function () {
                setCurrentStep(function (prevStep) { return prevStep + 1; });
            });
        }
        setIsTypeSelected(true);
    };
    var handlePhoneInput = useCallback(function (phone, dialCode) {
        form.setFieldsValue({
            phoneNumber: phone.replace(/[\s()+]/g, '').slice(dialCode.length),
            phoneCountry: dialCode,
        });
        form.validateFields(['phoneNumber']);
    }, [form]);
    var handleTypeSelect = useCallback(function () {
        setIsTypeSelected(true);
    }, []);
    return (React.createElement("div", { className: "flex justify-center items-center md:bg-[url('https://cdn.theaccessplatform.com/img/pls_sign_up_1_1.svg')] bg-cover h-full" },
        React.createElement("div", { className: "w-[58rem] bg-white rounded-2xl my-6 px-6 pt-6" },
            React.createElement("div", { className: " flex justify-center items-end mb-12" },
                React.createElement("img", { src: Logo, alt: "Logo", className: "h-16" })),
            React.createElement("div", { className: "flex flex-col justify-center items-center mb-12" },
                React.createElement("h3", { className: "font-interface-bold text-[2.8rem]" }, isTypeSelected ? titles.title : 'Welcome aboard!'),
                React.createElement("p", { className: "text-center text-[color:var(--explainer-text)]" }, isTypeSelected ? titles.description : 'Let us know who you are and what brings you here')),
            React.createElement("div", { className: isTypeSelected ? 'hidden' : undefined },
                React.createElement(RegisteringTypeStep, { onTypeSelect: handleTypeSelect })),
            React.createElement("div", { className: !isTypeSelected ? 'hidden' : 'pls-sign-up-form' },
                React.createElement(Form, { form: form, requiredMark: false, name: "sign-up-form", layout: "vertical" },
                    React.createElement("div", { className: currentStep !== 0 ? 'hidden' : undefined },
                        React.createElement(UserInfoFields, { handlePhoneInput: handlePhoneInput })),
                    React.createElement("div", { className: currentStep !== 1 ? 'hidden' : undefined },
                        React.createElement(ProfileSetupFields, null)),
                    React.createElement("div", { className: currentStep !== 2 ? 'hidden' : undefined },
                        React.createElement(LastStepFields, null))),
                React.createElement(Button, { className: "pls-continue-button mt-6 mb-4.5", onClick: onNext }, titles.buttonText)))));
};
