import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import store from '../../../redux/store'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { getCommonRoutePath } from '../../../helpers/helpers'
import { ProtectedNavLink } from 'src/app/components/protected-nav-link'
import { LicensesEnum } from 'src/types/general'

class ChatStartGuide extends PureComponent {
    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'chat',
            },
        })
    }

    render() {
        const commonPath = getCommonRoutePath()

        return (
            <section className="content settings home-start-guide">
                <h1 className="settings-title">Getting started with Chat</h1>
                <div className="start-guide-intro">
                    <p>
                        Put voices and perspectives from your community front and center by enabling prospects to have
                        peer-to-peer and group conversations with your ambassadors.
                    </p>
                </div>

                <div className="start-guide-grid start-guides">
                    <div className="column">
                        <div className="start-block">
                            <span className="point">1</span>
                            <div className="block-body">
                                <h3>If you haven't already, set up your TAP Feed</h3>
                                <p>
                                    Your TAP Feed is where your ambassador profiles, content and FAQs will live on your
                                    website - it's the ‘home’ of your ambassadors' experience online
                                </p>

                                <ProtectedNavLink
                                    className="start-button"
                                    to={commonPath + '/settings/tap-page'}
                                    licenseName={LicensesEnum.TapFeed}
                                >
                                    Create your TAP Feed
                                </ProtectedNavLink>
                            </div>
                        </div>
                        <div className="start-block">
                            <div className="point">2</div>
                            <div className="block-body">
                                <h3>Set additional data fields</h3>
                                <p>
                                    You may like to collect additional data from prospects through The Ambassador
                                    Platform. Simply select the fields you would like to collect from our list of
                                    available options
                                </p>
                                <NavLink className="start-button" to={commonPath + '/settings/optional-questions'}>
                                    Set your additional data
                                </NavLink>
                            </div>
                        </div>
                        <div className="start-block">
                            <div className="point">3</div>
                            <div className="block-body">
                                <h3>Promote your TAP Feed</h3>
                                <p>
                                    Drive targeted engagement on your website using pop cards, buttons, tracked links
                                    and profile carousels
                                </p>

                                <ProtectedNavLink
                                    className="start-button"
                                    to={commonPath + '/promotion/pop-card'}
                                    licenseName={LicensesEnum.Promotion}
                                >
                                    Start promoting your TAP Feed
                                </ProtectedNavLink>

                                <p style={{ marginTop: '1rem' }}>
                                    The value you’ll get from TAP is proportional to the number of your prospects who
                                    see it, so promotion is really important!
                                </p>
                            </div>
                        </div>
                        <div className="callout">
                            If you’d like more advice on how to drive engagement on your TAP Feed, send us a message on
                            our customer support chat!
                        </div>
                    </div>

                    <div className="column resources">
                        <div className="video-wrapper">
                            <iframe
                                src="https://player.vimeo.com/video/430275307"
                                width="640"
                                height="360"
                                frameBorder="0"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen={true}
                            ></iframe>
                        </div>

                        <div className="resources-box">
                            <h3>Helpful resources</h3>
                            <ul>
                                <li>
                                    The Ambassador Platform{' '}
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://legal.theambassadorplatform.com/safeguarding-policy"
                                    >
                                        Safeguarding Policy
                                    </a>
                                </li>
                                <li>
                                    What is the{' '}
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://knowledge.theambassadorplatform.com/what-are-group-chats"
                                    >
                                        What are Group Chats?
                                    </a>
                                </li>
                                <li>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://knowledge.theambassadorplatform.com/3-easy-ways-to-promote-your-ambassadors"
                                    >
                                        3 Easy Ways to Promote your Ambassadors
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ChatStartGuide
