export function concatClsx(cls, className) {
    return className ? "".concat(cls, " ").concat(className) : cls;
}
export function clsx() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args.filter(Boolean).join(' ');
}
export function recordToCls(classes) {
    return Object.keys(classes)
        .filter(function (key) { return classes[key]; })
        .join(' ');
}
