const SsoActiveProviderModel = (data = {}) => {
    const {
        id = null,
        sso_provider_id: ssoProviderId = null,
        provider_name: providerName = '',
        is_admin_allowed: isAdminAllowed = true,
        is_ambassador_allowed: isAmbassadorAllowed = true,
        is_prospect_allowed: isProspectAllowed = true,
        settings = {},
    } = data || {}

    return {
        id,
        ssoProviderId,
        providerName,
        isAdminAllowed,
        isAmbassadorAllowed,
        isProspectAllowed,
        settings,
    }
}

export default SsoActiveProviderModel
