/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CHAT_MESSAGES_INFO } from '../../../../../../redux/actions/chat/messagingActions'
import { LIVE_STREAMS } from '../../../../../../redux/actions/live-streams/live-streams-action-types'

import { pusherChild } from '../../../../../../system/services/PusherWrapper'
import { getAccountInfo, getLiveStramsOffset, getLiveStramsFilters } from '../../../../../../redux/selectors'

import { CHAT_TYPE_KEY } from '../../../../../../common/constants'

import { CLEAR_MESSAGES } from '../../../../../../redux/actions/content/messagingActions'
import { fetchLiveStreams } from '../../../../../../redux/actions/live-streams/live-streams-actions'

const CHANNEL_NAME = 'dashboard'

export const useChat = (activeEvent) => {
    const { dialogId, id } = activeEvent

    const dispatch = useDispatch()
    const { university_id: universityId, id: accountId } = useSelector(getAccountInfo)
    const offset = useSelector(getLiveStramsOffset)
    const filter = useSelector(getLiveStramsFilters)

    const channel = useRef(null)

    useEffect(() => {
        if (channel.current === null) {
            channel.current = pusherChild.subscribe(CHANNEL_NAME)

            channel.current.bind('messages:new:' + universityId, (data) => {
                const { dialog_id: dataDialogId, type } = data
                if (dialogId === dataDialogId) {
                    if (CHAT_TYPE_KEY.LIVE_STREAM_CHAT === type) {
                        dispatch({
                            type: CHAT_MESSAGES_INFO,
                            payload: {
                                dialog_id: dataDialogId,
                                type_chat: CHAT_TYPE_KEY.LIVE_STREAM_CHAT,
                                blockReloadChat: true,
                                resetMessagesCounter: true,
                                liveStreamId: id,
                                blockUserUpdate: true,
                            },
                        })
                    } else {
                        console.log('different chat')
                    }
                }
            })
            channel.current.bind('file:transcoding:' + universityId + ':' + accountId, (data) => {
                dispatch(fetchLiveStreams({ offset, filter }))
            })
            channel.current.bind(`liveStreams:${id}:info`, (data) => {
                if (data && data.action === 'userAction') {
                    dispatch({
                        type: LIVE_STREAMS.FETCH_USERS,
                        payload: {
                            liveStreamId: id,
                        },
                    })
                }
                if (data && data.action === 'videoAction') {
                    dispatch(fetchLiveStreams({ offset, filter }))
                }
            })
        }

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        loadMessages()

        return () => {
            if (channel.current !== null) {
                channel.current = pusherChild.unsubscribe(CHANNEL_NAME)
                channel.current = null

                dispatch({ type: CLEAR_MESSAGES })
            }
        }
    }, [])

    const loadMessages = useCallback(() => {
        dispatch({
            type: CHAT_MESSAGES_INFO,
            payload: {
                dialog_id: dialogId,
                type_chat: CHAT_TYPE_KEY.LIVE_STREAM_CHAT,
                blockReloadChat: true,
                resetMessagesCounter: true,
                liveStreamId: id,
            },
        })
    }, [dispatch, dialogId])

    return {
        loadMessages,
    }
}
