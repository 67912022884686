import React, { useCallback } from 'react'
import { Col, Typography, Row, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { getAccountInfo } from '../../../../../redux/selectors'
import { setThirdPartyAuthToggle } from '../../../../../redux/actions/account/account-actions'

import { checkCanSeeThidPartyAuth } from '../../../../../common/utils/user-account-helpers'

const { Title, Text } = Typography

const ThirdPartyAuth = () => {
    const dispatch = useDispatch()

    const { university } = useSelector(getAccountInfo)

    const isFacebookAllowed = checkCanSeeThidPartyAuth(university, 'facebook')
    const isTiktokAllowed = checkCanSeeThidPartyAuth(university, 'tiktok')
    const isGoogleAllowed = checkCanSeeThidPartyAuth(university, 'google')
    // const isAppleAllowed = checkCanSeeThidPartyAuth(university, "apple")

    const handleSwitchToggle = useCallback(
        (e) => {
            const target = e.target
            const value = target.checked
            const name = target.name

            dispatch(setThirdPartyAuthToggle({ isEnabled: value, permissionKey: name }))
        },
        [dispatch]
    )

    return (
        <>
            <Title level={5} className="settings-name">
                Third-party authorisation services
            </Title>
            <Col style={{ marginBottom: '4rem', fontSize: '1.6rem' }}>
                <Row>
                    <Text style={{ fontSize: '1.6rem', marginBottom: '2rem' }}>
                        By default all authorisation services are turned on. You can disable some services below. When
                        disabled a user will not be able to use an external services to sign-in or create an account.
                        Existing users will get an email with a new password to keep access to the platform.
                    </Text>
                </Row>
                <Row>
                    <Space direction={'horizontal'} size={'large'} style={{ alignItems: 'flex-start' }}>
                        <Col style={{ marginRight: '10rem' }}>
                            <Row align="middle" justify="space-between" style={{ marginBottom: '5rem' }}>
                                <Text style={{ fontSize: '1.6rem', marginRight: '4rem' }}>Facebook</Text>
                                <label className="slideOne js-holder">
                                    <input
                                        type="checkbox"
                                        name="facebook"
                                        checked={isFacebookAllowed}
                                        onChange={handleSwitchToggle}
                                    />
                                    <span className="slideOne-icon" />
                                </label>
                            </Row>
                            <Row align="middle" justify="space-between">
                                <Text style={{ fontSize: '1.6rem', marginRight: '4rem' }}>TikTok</Text>
                                <label className="slideOne js-holder">
                                    <input
                                        type="checkbox"
                                        name="tiktok"
                                        checked={isTiktokAllowed}
                                        onChange={handleSwitchToggle}
                                    />
                                    <span className="slideOne-icon" />
                                </label>
                            </Row>
                        </Col>
                        <Col>
                            <Row align="middle" justify="space-between" style={{ marginBottom: '5rem' }}>
                                <Text style={{ fontSize: '1.6rem', marginRight: '4rem' }}>Google</Text>
                                <label className="slideOne js-holder">
                                    <input
                                        type="checkbox"
                                        name="google"
                                        checked={isGoogleAllowed}
                                        onChange={handleSwitchToggle}
                                    />
                                    <span className="slideOne-icon" />
                                </label>
                            </Row>
                            {/* <Row align="middle" justify="space-between">
                                    <Text style={{ fontSize: '1.6rem', marginRight: '4rem' }}>Apple</Text>
                                    <label className="slideOne js-holder">
                                        <input
                                            type="checkbox"
                                            name="facebook"
                                            // checked={isAppleAllowed}
                                            // onChange={handleSwitchToggle}
                                        />
                                        <span className="slideOne-icon" />
                                    </label>
                                </Row> */}
                        </Col>
                    </Space>
                </Row>
            </Col>
        </>
    )
}

export default ThirdPartyAuth
