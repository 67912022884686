import {
    PROSPECTS_CHARTS_SUCCESS,
    PROSPECTS_TABLE_REQUESTED,
    PROSPECTS_TABLE_SUCCESS,
    PROSPECTS_CSV_REQUESTED,
    PROSPECTS_CSV_SUCCESS,
    PROSPECT_OPTIONAL_DATA_SUCCESS,
    PROSPECTS_TABLE_FAILED,
    PROSPECT_FEEDBACK_DATA_SUCCESS,
    PROSPECT_FEEDBACK_DATA_REQUESTED,
    PROSPECT_FEEDBACK_DATA_FAILED,
    PROSPECT_OPTIONAL_DATA_REQUESTED,
    PROSPECT_OPTIONAL_DATA_FAILED,
    PROSPECT_MANDATORY_DATA_REQUESTED,
    PROSPECT_MANDATORY_DATA_SUCCESS,
    PROSPECT_MANDATORY_DATA_FAILED,
} from '../../actions/reporting/prospectsActions'

const initialState = {
    charts: {},
    table: {},
    isTableLoading: true,
    csvUrl: '',
    optionalData: [],
    mandatoryData: [],
    feedbackData: {},
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case PROSPECTS_CHARTS_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.charts = payload
            // console.log(newState.charts);
            return newState
        }

        case PROSPECTS_TABLE_REQUESTED: {
            const newState = Object.assign({}, state)
            newState.isTableLoading = true
            return newState
        }

        case PROSPECTS_TABLE_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.table = payload
            newState.isTableLoading = false
            // console.log(newState.table);
            return newState
        }

        case PROSPECTS_TABLE_FAILED: {
            return {
                ...state,
                isTableLoading: false,
            }
        }

        case PROSPECTS_CSV_REQUESTED: {
            const newState = Object.assign({}, state)
            newState.csvUrl = ''
            return newState
        }

        case PROSPECTS_CSV_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.csvUrl = payload.csvFileUrl
            // console.log(newState.csvUrl);
            return newState
        }

        case PROSPECT_OPTIONAL_DATA_REQUESTED: {
            return {
                ...state,
                isOptionalDataLoading: true,
            }
        }

        case PROSPECT_OPTIONAL_DATA_SUCCESS: {
            return {
                ...state,
                optionalData: payload.optionalData || [],
                mandatoryData: payload.mandatoryData || [],
                isOptionalDataLoading: false,
            }
        }

        case PROSPECT_OPTIONAL_DATA_FAILED: {
            return {
                ...state,
                isOptionalDataLoading: false,
            }
        }

        case PROSPECT_MANDATORY_DATA_REQUESTED: {
            return {
                ...state,
                isMandatoryDataLoading: true,
            }
        }

        case PROSPECT_MANDATORY_DATA_SUCCESS: {
            return {
                ...state,
                mandatoryData: payload,
                isMandatoryDataLoading: false,
            }
        }

        case PROSPECT_MANDATORY_DATA_FAILED: {
            return {
                ...state,
                isMandatoryDataLoading: false,
            }
        }

        case PROSPECT_FEEDBACK_DATA_REQUESTED: {
            return {
                ...state,
                isFeedbackDataLoading: true,
            }
        }

        case PROSPECT_FEEDBACK_DATA_SUCCESS: {
            return {
                ...state,
                feedbackData: payload,
                isFeedbackDataLoading: false,
            }
        }

        case PROSPECT_FEEDBACK_DATA_FAILED: {
            return {
                ...state,
                isFeedbackDataLoading: false,
            }
        }
    }

    return state
}
