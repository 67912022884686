/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'antd/es/modal';
import PlaceholderUser from '../../../../assets/img/placeholder-user.png';
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions';
import EditLastNameForm from './components/forms/EditLastNameForm';
import EditNameForm from './components/forms/EditNameForm';
import EmailEditForm from './components/forms/EmailEditForm';
import PasswordEditForm from './components/forms/PasswordEditForm';
import ChangeEmailPopup from './components/popups/ConfirmEmailPopup';
import ChangePasswordPopup from './components/popups/ConfirmPasswordPopup';
import MarkTestUniversity from './components/popups/MarkTestUniversity';
import { Button, Col, Collapse, Layout, Row, Typography, Upload } from 'antd';
import { ACCOUNT_CHANGE_AVATAR, ACCOUNT_CHANGE_EMAIL, ACCOUNT_CHANGE_EMAIL_SEND_LETTER, ACCOUNT_CHANGE_FIRST_NAME, ACCOUNT_CHANGE_LAST_NAME, ACCOUNT_CHANGE_PASSWORD, ACCOUNT_CHANGE_PASSWORD_SEND_LETTER, } from '../../../redux/actions/account/accountEditActions';
import { ACCOUNT_LOGOUT, CLOSE_CHANGE_EMAIL_MODAL, CLOSE_CHANGE_PASSWORD_MODAL, SET_UNIVERSITY_GROUP, } from '../../../redux/actions/account/accountActions';
import { getAccountInfo, getChangeEmailModalSlice, getChangePasswordModalSlice } from '../../../redux/selectors';
import { isAdminSsoActivated } from '../../../helpers/SsoHelper';
var Title = Typography.Title, Text = Typography.Text;
var preventUploadFn = function (_a) {
    var onSuccess = _a.onSuccess;
    if (typeof onSuccess === 'function')
        onSuccess('ok');
};
var Account = function () {
    var _a;
    var dispatch = useDispatch();
    var _b = useState(null), email = _b[0], setEmail = _b[1];
    var _c = useState(null), oldPassword = _c[0], setOldPassword = _c[1];
    var _d = useState(null), newPassword = _d[0], setNewPassword = _d[1];
    var _e = useState(null), confirmPassword = _e[0], setConfirmPassword = _e[1];
    var _f = useState([]), layoutActiveKey = _f[0], setLayoutActiveKey = _f[1];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    var accountInfo = useSelector(getAccountInfo); // TODO Fix me
    var changeEmailModal = useSelector(getChangeEmailModalSlice);
    var changePasswordModal = useSelector(getChangePasswordModalSlice);
    var isGroupAdmin = accountInfo.isGroupAdmin;
    useEffect(function () {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'account',
            },
        });
        if (isGroupAdmin) {
            dispatch({
                type: SET_UNIVERSITY_GROUP,
                payload: {
                    university_id: null,
                },
            });
        }
    }, [isGroupAdmin, dispatch]);
    var handleLogoutClick = useCallback(function (e) {
        e.preventDefault();
        dispatch({
            type: ACCOUNT_LOGOUT,
        });
    }, [dispatch]);
    var handleClosePasswordChangePopup = useCallback(function () {
        dispatch({
            type: CLOSE_CHANGE_PASSWORD_MODAL,
        });
    }, [dispatch]);
    var handleCloseEmailChangePopup = useCallback(function () {
        dispatch({
            type: CLOSE_CHANGE_EMAIL_MODAL,
        });
    }, [dispatch]);
    var handleChangeAvatarInput = useCallback(function (info) {
        if (info.file.status === 'done' && info.file.originFileObj) {
            try {
                var reader_1 = new FileReader();
                reader_1.readAsDataURL(info.file.originFileObj);
                reader_1.addEventListener('load', function () {
                    var _a;
                    dispatch({
                        type: ACCOUNT_CHANGE_AVATAR,
                        payload: {
                            avatar: (_a = reader_1.result) === null || _a === void 0 ? void 0 : _a.slice(reader_1.result.indexOf(',') + 1),
                        },
                    });
                });
            }
            catch (error) {
                console.log(error);
            }
        }
    }, [dispatch]);
    var handleEmailSubmit = useCallback(function (values) {
        setEmail(values.email);
        dispatch({
            type: ACCOUNT_CHANGE_EMAIL_SEND_LETTER,
            payload: values,
        });
        setLayoutActiveKey([]);
    }, [dispatch]);
    var handleNameSubmit = useCallback(function (values, form) {
        var onFirstNameChangeSuccess = function () {
            form.resetFields();
            setLayoutActiveKey([]);
        };
        dispatch({
            type: ACCOUNT_CHANGE_FIRST_NAME,
            payload: values,
            meta: { onSuccess: onFirstNameChangeSuccess },
        });
    }, [dispatch]);
    var handleLastNameSubmit = useCallback(function (values, form) {
        var onLastNameChangeSuccess = function () {
            form.resetFields();
            setLayoutActiveKey([]);
        };
        dispatch({
            type: ACCOUNT_CHANGE_LAST_NAME,
            payload: values,
            meta: { onSuccess: onLastNameChangeSuccess },
        });
    }, [dispatch]);
    var handlePasswordSubmit = useCallback(function (values) {
        setOldPassword(values.old_password);
        setNewPassword(values.new_password);
        setConfirmPassword(values.confirm_password);
        dispatch({
            type: ACCOUNT_CHANGE_PASSWORD_SEND_LETTER,
            payload: values,
        });
        setLayoutActiveKey([]);
    }, [dispatch]);
    var handleEmailPopupSubmit = useCallback(function (values) {
        dispatch({
            type: ACCOUNT_CHANGE_EMAIL,
            payload: {
                verification_code: values.verification_code,
                email: email,
            },
        });
    }, [dispatch, email]);
    var handlePasswordPopupSubmit = useCallback(function (values) {
        dispatch({
            type: ACCOUNT_CHANGE_PASSWORD,
            payload: {
                verification_code: values.verification_code,
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
            },
        });
    }, [dispatch, oldPassword, newPassword, confirmPassword]);
    var onChangeLayout = useCallback(function (key) {
        setLayoutActiveKey(key);
    }, []);
    var onChancelAction = useCallback(function () {
        setLayoutActiveKey([]);
    }, []);
    var avatarSrc = accountInfo.avatar
        ? accountInfo.avatar.sizes['160x160'] || accountInfo.avatar.original
        : PlaceholderUser;
    var isSsoOnlyEnabled = isAdminSsoActivated();
    var items = [
        {
            key: 'first-name',
            label: (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: "settings-row account" },
                    React.createElement(Title, { level: 4, className: "settings-name" }, "First name")),
                React.createElement(Text, { className: "settings-status" }, accountInfo.name))),
            children: React.createElement(EditNameForm, { onSubmit: handleNameSubmit, onCancel: onChancelAction }),
        },
        {
            key: 'last-name',
            label: (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: "settings-row account" },
                    React.createElement(Title, { level: 4, className: "settings-name" }, "Last name")),
                React.createElement(Text, { className: "settings-status" }, accountInfo.last_name))),
            children: React.createElement(EditLastNameForm, { onSubmit: handleLastNameSubmit, onCancel: onChancelAction }),
        },
        {
            key: 'email',
            label: (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: "settings-row account" },
                    React.createElement(Title, { level: 4, className: "settings-name" }, "Email address")),
                React.createElement(Text, { className: "settings-status" }, accountInfo.email))),
            children: React.createElement(EmailEditForm, { onSubmit: handleEmailSubmit, onCancel: onChancelAction }),
        },
        {
            key: 'password',
            label: (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: "settings-row account" },
                    React.createElement(Title, { level: 4, className: "settings-name" }, "Password")))),
            children: React.createElement(PasswordEditForm, { onSubmit: handlePasswordSubmit, onCancel: onChancelAction }),
        },
        {
            key: 'picture',
            label: (React.createElement(React.Fragment, null,
                React.createElement(Row, { className: "settings-row account", style: { maxWidth: '100%' } },
                    React.createElement(Title, { level: 4, className: "settings-name" }, "Profile picture"),
                    React.createElement(Col, { className: "user-item" },
                        React.createElement(Col, { className: "edit-user-block" },
                            React.createElement("label", { className: "edit-user-label" },
                                React.createElement(Upload, { accept: ".png, .jpg, .jpeg", name: "avatar", className: "edit-user-input js-edit-user-input", showUploadList: false, onChange: handleChangeAvatarInput, customRequest: preventUploadFn },
                                    React.createElement("img", { className: "user-img", src: avatarSrc, alt: "User icon" })),
                                React.createElement("span", { className: "edit-user-text" }, "Edit")),
                            React.createElement("img", { className: "user-img", src: avatarSrc, alt: "User icon" })))))),
            children: null,
            showArrow: false,
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Col, { id: "page-body", className: "page-body js-index" },
            React.createElement(MarkTestUniversity, { isTest: (_a = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university.is_test) !== null && _a !== void 0 ? _a : false }),
            React.createElement(Layout, { className: "content no-submenu" },
                React.createElement(Title, { level: 3, className: "settings-title" }, "Account"),
                !isSsoOnlyEnabled && (React.createElement(Row, { className: "settings-intro" },
                    React.createElement(Text, null, "Update your email address and password"))),
                React.createElement(Col, { className: "column" },
                    React.createElement(React.Fragment, null,
                        !isSsoOnlyEnabled && (React.createElement(React.Fragment, null,
                            React.createElement(Collapse, { bordered: false, items: items, accordion: true, className: "settings-collapse-item", onChange: onChangeLayout, activeKey: layoutActiveKey, collapsible: 'icon', expandIcon: function (data) {
                                    return (React.createElement(Row, { className: "settings-row account" },
                                        React.createElement(Button, { type: "link", className: "settings-btn", "data-automation-id": "change-".concat(data.panelKey) }, "Change")));
                                }, expandIconPosition: 'end' }))),
                        React.createElement(Button, { type: "link", className: "signout-btn", onClick: handleLogoutClick }, "Sign out"))),
                React.createElement(Col, { className: "column resources" }))),
        React.createElement(Modal, { open: changeEmailModal.isOpened, centered: true, footer: null, closable: false, maskClosable: true, onCancel: handleCloseEmailChangePopup },
            React.createElement(ChangeEmailPopup, { onSubmit: handleEmailPopupSubmit })),
        React.createElement(Modal, { open: changePasswordModal.isOpened, centered: true, footer: null, closable: false, maskClosable: true, onCancel: handleClosePasswordChangePopup },
            React.createElement(ChangePasswordPopup, { onSubmit: handlePasswordPopupSubmit }))));
};
export default Account;
