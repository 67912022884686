import { Form, Input } from 'antd'
import React, { useCallback } from 'react'

const initialValues = {
    message: '',
}

const formStyle = {
    display: 'block',
    width: '100%',
}

const inputStyle = {
    display: 'block',
    minWidth: '100%',
    width: '100%',
    border: 0,
}

const SendMessageForm = ({ sendMessage }) => {
    const [form] = Form.useForm()

    const handlePressEnter = useCallback(
        (event = {}) => {
            const { key, shiftKey } = event

            if (key === 'Enter' && !shiftKey) {
                const message = form.getFieldValue('message').trim()

                if (message.length > 0) {
                    sendMessage(message)
                    form.resetFields()
                }
            }
        },
        [form, sendMessage]
    )

    return (
        <Form style={formStyle} form={form} layout="vertical" initialValues={initialValues}>
            <Form.Item noStyle label={null} name="message">
                <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 9 }}
                    style={inputStyle}
                    placeholder="Write a message..."
                    onPressEnter={handlePressEnter}
                />
            </Form.Item>
        </Form>
    )
}

export default SendMessageForm
