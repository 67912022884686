import {
    REPORT_CONTENT_INFO,
    REPORT_CONTENT_INFO_SUCCESS,
    REPORT_CONTENT_INFO_FAILED,
} from '../../actions/reporting/contentActions'

import { CONTENT_REPORT } from '../../store/initialState'

const initialState = CONTENT_REPORT

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case REPORT_CONTENT_INFO: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case REPORT_CONTENT_INFO_SUCCESS: {
            return {
                items: payload.items,
                total: payload.total,
                all_likes: payload.all_likes ? payload.all_likes : 0,
                all_views: payload.all_views ? payload.all_views : 0,
                isLoading: false,
            }
        }

        case REPORT_CONTENT_INFO_FAILED: {
            return {
                ...state,
                items: [],
                isLoading: false,
            }
        }

        default: {
            return state
        }
    }
}
