import sanitizeHtml from 'sanitize-html'

import dayjs from 'dayjs'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

class TextMessage extends PureComponent {
    createLinksInMessage(text) {
        return (text || '').replace(/(\s|^)(((https?:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
            var hyperlink = url
            if (!hyperlink.match('^https?://')) {
                hyperlink = 'https://' + hyperlink
            }
            return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>'
        })
    }

    render() {
        const { account, message, renderDeleteMessagePopup } = this.props
        const { content, user, id, created_at: createdAtTemp } = message
        const urlRegExpression = new RegExp(
            '([a-zA-Z0-9]+://)?(\\w+:\\w+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:\\d+)?(/.*)?'
        )
        const createdAt = dayjs(createdAtTemp).calendar(null, {
            sameDay: 'H:mm',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday at ]H:mm',
            lastWeek: 'DD MMMM',
            sameElse: 'DD MMMM',
        })

        if (content.text && typeof content.text === 'string' && urlRegExpression.test(content.text)) {
            content.text = this.createLinksInMessage(content.text)
        }
        return (
            <div id={id} className={user && user.id == account.account_info.id ? 'msg-item mod-my' : 'msg-item'}>
                <div className="msg-avatar-block">
                    {user && user.avatar ? (
                        <img className="msg-item-img" src={user.avatar.sizes['70x70'] || user.avatar.original} />
                    ) : (
                        <div className="msg-avatar-text">{user ? user.name.charAt(0) : ''}</div>
                    )}
                </div>
                <div className="msgs-block">
                    <div className="msg-block-item">
                        <p className={user && user.id == account.account_info.id ? 'hidden-block' : 'msg-name'}>
                            {user ? user.name : 'Deleted User'}
                        </p>
                        <p
                            className="msg-text"
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(content.text.replace(/\r\n|\r|\n/g, '<br/>'), {
                                    allowedTags: [' ', 'a', 'b', 'span', 'p', 'br'],
                                }),
                            }}
                        />
                        <div className="msg-controls">
                            <div className="reply-and-delete-container">
                                <div className="delete-button" onClick={() => renderDeleteMessagePopup(message)}>
                                    Delete
                                </div>
                            </div>
                            <p className="msg-time">{createdAt}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        contentGroups: state.contentGroups,
        account: state.account,
    }
})(TextMessage)
