import cn from 'classnames'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getEventStreamSlice } from '../../../../../../../../redux/selectors/common-selectors'

import { ParticipantCard } from './participant-card'
import { ScreenView } from './screen-view'

const ParticipantsView = ({ eventVideoStreamRef }) => {
    const { participants, localScreenShared, currentPresenterId } = useSelector(getEventStreamSlice)

    const someoneScreenShared = useMemo(
        () => localScreenShared || currentPresenterId,
        [localScreenShared, currentPresenterId]
    )

    const wrapperClassName = useMemo(
        () =>
            cn('tap-event-stream-participants-wrapper', {
                'screen-share': someoneScreenShared,
            }),
        [someoneScreenShared]
    )

    return (
        <div className={wrapperClassName}>
            {someoneScreenShared && <ScreenView />}
            <div className="tap-event-stream-participants" ref={eventVideoStreamRef}>
                {participants.map((participant) => {
                    return (
                        <div key={participant.id} id={participant.id}>
                            <ParticipantCard participant={participant} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ParticipantsView
