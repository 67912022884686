import { pluckIds } from '../../../../../common/utils/array-utils'

export default (data) => {
    return {
        userIds: data.ambassadors.map(({ id }) => id),
        meta: {
            selectedAmbassadors: data.ambassadors,
            selectedSpeakers: pluckIds(data.speakers),
        },
    }
}
