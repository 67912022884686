const types = {
    AMBASSADORS_REPORTS_REQUESTED: 'AMBASSADORS_REPORTS_REQUESTED',
    AMBASSADORS_REPORTS_SUCCESS: 'AMBASSADORS_REPORTS_SUCCESS',
    AMBASSADORS_REPORTS_FAILED: 'AMBASSADORS_REPORTS_FAILED',

    AMBASSADOR_CHANGE_INDRODUCTION: 'AMBASSADORS_CHANGE_INDRODUCTION',
    AMBASSADOR_CHANGE_INDRODUCTION_SUCCESS: 'AMBASSADORS_CHANGE_INDRODUCTION_SUCCESS',
    AMBASSADOR_CHANGE_INDRODUCTION_FAILED: 'AMBASSADORS_CHANGE_INDRODUCTION_FAILED',

    AMBASSADOR_CHANGE_DESCRIPTION: 'AMBASSADORS_CHANGE_DESCRIPTION',
    AMBASSADOR_CHANGE_DESCRIPTION_SUCCESS: 'AMBASSADORS_CHANGE_DESCRIPTION_SUCCESS',
    AMBASSADOR_CHANGE_DESCRIPTION_FAILED: 'AMBASSADORS_CHANGE_DESCRIPTION_FAILED',

    AMBASSADORS_LOADING_CHANGE: 'AMBASSADORS_LOADING_CHANGE',

    AMBASSADORS_DIAGRAMS_REQUESTED: 'AMBASSADORS_DIAGRAMS_REQUESTED',
    AMBASSADORS_DIAGRAMS_SUCCESS: 'AMBASSADORS_DIAGRAMS_SUCCESS',
    AMBASSADORS_DIAGRAMS_FAILED: 'AMBASSADORS_DIAGRAMS_FAILED',

    AMBASSADORS_REP_REQUESTED: 'AMBASSADORS_REP_REQUESTED',
    AMBASSADORS_REP_SUCCESS: 'AMBASSADORS_REP_SUCCESS',
    AMBASSADORS_REP_FAILED: 'AMBASSADORS_REP_FAILED',
    AMBASSADORS_DEFAULT_DATA: 'AMBASSADORS_DEFAULT_DATA',
    EDIT_AMBASSADORS_GLOBAL_TAG_FINISH: 'EDIT_AMBASSADORS_GLOBAL_TAG_FINISH',

    AMBASSADORS_EXPORT: 'AMBASSADORS_EXPORT',
    AMBASSADORS_EXPORT_SUCCESS: 'AMBASSADORS_EXPORT_SUCCESS',
    AMBASSADORS_EXPORT_FAILED: 'AMBASSADORS_EXPORT_FAILED',

    AMBASSADORS_LIST: 'AMBASSADORS_LIST',
    AMBASSADORS_LIST_SUCCESS: 'AMBASSADORS_LIST_SUCCESS',
    AMBASSADORS_LIST_FAILED: 'AMBASSADORS_LIST_FAILED',

    SET_AMBASSADOR_TIME_LIMIT: 'SET_AMBASSADOR_TIME_LIMIT',
    SET_FILTERS: 'SET_FILTERS',

    GET_AMBASSADOR_ADDITIONAL_DATA: 'GET_AMBASSADOR_ADDITIONAL_DATA',
    GET_AMBASSADOR_ADDITIONAL_DATA_SUCCESS: 'GET_AMBASSADOR_ADDITIONAL_DATA_SUCCESS',
    CLOSE_AMBASSADOR_MODAL: 'CLOSE_AMBASSADOR_MODAL',
}

export const fetchAmbassadorsReports = (from, to) => ({
    type: types.AMBASSADORS_REPORTS_REQUESTED,
    payload: {
        filter: {
            from,
            to,
        },
    },
})

export const changeAmbassadorIntroduction = (userId, introduction) => {
    return {
        type: types.AMBASSADOR_CHANGE_INDRODUCTION,
        payload: {
            user_id: userId,
            introduction,
        },
    }
}

export const changeAmbassadorDescription = (userId, description) => ({
    type: types.AMBASSADOR_CHANGE_DESCRIPTION,
    payload: {
        user_id: userId,
        description,
    },
})

export const fetchAmbasadorsDiagrams = (filter, ambassadorsIds, excludedAmbassadorIds) => ({
    type: types.AMBASSADORS_DIAGRAMS_REQUESTED,
    payload: {
        filter,
        ambassadorsIds,
        excludedAmbassadorIds,
    },
})

export const fetchAmbasadorsRep = (payload) => ({
    type: types.AMBASSADORS_REP_REQUESTED,
    payload,
})

export const setAmbassadorFilters = (payload) => ({
    type: types.SET_FILTERS,
    payload,
})

export const getAmbassadorAdditionalData = ({ userId, avatar, fullName }) => ({
    type: types.GET_AMBASSADOR_ADDITIONAL_DATA,
    payload: {
        userId,
        avatar,
        fullName,
    },
})

export const closeAmbassadorModal = () => ({
    type: types.CLOSE_AMBASSADOR_MODAL,
})

export const setToInitialState = (payload) => ({
    type: types.AMBASSADORS_DEFAULT_DATA,
    payload,
})

export default types
