import { call, put, select } from 'redux-saga/effects'
import API from '../../../api'

import {
    CHATS_INFO_SEARCH,
    CHAT_MESSAGES_INFO,
    CLEAR_MESSAGES,
    SEND_CHAT_MESSAGE_FAILED,
    SEND_CHAT_MESSAGE_SUCCESS,
} from '../../actions/chat/messagingActions'

import { OPEN_COPY_LINK_POPUP } from '../../actions/chat/commonActions'
import {
    CLOSE_CHAT_GROUP_POPUP,
    CREATE_GROUP_CHAT_FAILED,
    CREATE_GROUP_CHAT_SUCCESS,
} from '../../actions/chat/groupMessagingActions'

import { ChatType } from '../../../common/constants'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* createGroupChat(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            payload.universityId = currentGroupUniversityId || payload.university_id
        }
        const response = yield call(() => {
            return API.chat.createGroupChat(payload)
        })

        if (response.success) {
            yield put({
                type: CREATE_GROUP_CHAT_SUCCESS,
                payload: {},
            })
            yield put({
                type: CLEAR_MESSAGES,
            })
            if (response.data && response.data.dialog_id) {
                yield put({
                    type: CHATS_INFO_SEARCH,
                    payload: {
                        type_chat: ChatType.GROUP_CHAT,
                        chatId: response.data.dialog_id,
                        resetSearchAndFilters: true,
                        setNewGroupChat: true,
                    },
                })
            }
            yield put({
                type: CLOSE_CHAT_GROUP_POPUP,
            })
            yield put({
                type: OPEN_COPY_LINK_POPUP,
                payload: {
                    dialog_id: response.data.dialog_id,
                    private_code: response.data.private_code,
                },
            })
        } else {
            yield put({
                type: CREATE_GROUP_CHAT_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CREATE_GROUP_CHAT_FAILED,
        })
    }
    action.onFinish()
}

export function* sendGroupChatMessage(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.dashboard.sendGroupMessage(payload)
        })

        if (response.success) {
            yield put({
                type: SEND_CHAT_MESSAGE_SUCCESS,
            })

            yield put({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                    type: payload.type_chat,
                    scrollBottom: true,
                    type_chat: ChatType.GROUP_CHAT,
                    blockReloadChat: true,
                },
            })

            if (payload.file_id) {
                yield call(() => {
                    return payload.callback()
                })
            }
            if (action.onSuccess) action.onSuccess()
        } else {
            yield put({
                type: SEND_CHAT_MESSAGE_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: SEND_CHAT_MESSAGE_FAILED,
        })
    }
}

export function* pinChat(action) {
    const { payload, onSuccess } = action
    try {
        const response = yield call(() => {
            return API.chat.pinChat(payload.dialogId, payload.is_pinned)
        })
        if (response.success) {
            onSuccess()

            yield put({
                type: CHATS_INFO_SEARCH,
                payload: {
                    type_chat: payload.type_chat || ChatType.CHAT,
                    withGlobalTags: payload.withGlobalTags,
                },
            })
        }
    } catch {}
}
