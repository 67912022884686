import React, { Component } from 'react'
import store from '../../../../redux/store'
import types from '../../../../redux/actions/content/ambassadorsActions'

class ExportButtons extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date('1 January 2019'),
            endDate: new Date(),
        }

        this.downloadFile = this.downloadFile.bind(this)
        this.handleExportAll = this.handleExportAll.bind(this)
        this.handleExportCurrent = this.handleExportCurrent.bind(this)
    }

    async handleExportAll() {
        const onSuccess = (response) => {
            this.downloadFile(response.csvFileUrl)
        }
        store.dispatch({
            type: types.AMBASSADORS_EXPORT,
            payload: {
                filter: {
                    fromDate: this.props.dateRanges.fromDate,
                    toDate: this.props.dateRanges.toDate,
                },
            },
            onSuccess: onSuccess,
        })
    }

    async handleExportCurrent() {
        const { isAllSelected, selected, unselected, dateRanges, search } = this.props
        const onSuccess = (response) => {
            this.downloadFile(response.csvFileUrl)
        }
        const requestData = {
            filter: {
                fromDate: dateRanges.fromDate,
                toDate: dateRanges.toDate,
            },
            ambassadorsIds: isAllSelected ? null : [...selected],
            excludedAmbassadorIds: isAllSelected ? [...unselected] : null,
        }

        if (search) {
            requestData.search = search
        }

        store.dispatch({
            type: types.AMBASSADORS_EXPORT,
            payload: requestData,
            onSuccess: onSuccess,
        })
    }

    downloadFile(file) {
        setTimeout(() => {
            const link = document.createElement('a')
            link.download = file
            link.href = file
            document.body.append(link)
            link.click()
            link.remove()
        }, 1000)
    }

    render() {
        return (
            <div className="export-buttons-container">
                <button className="el-btn" onClick={this.handleExportCurrent} disabled={!this.props.selectedCount}>
                    Export Selected{this.props.selectedNumber}
                </button>
                <button className="el-btn" onClick={this.handleExportAll}>
                    Export all
                </button>
            </div>
        )
    }
}

export default ExportButtons
