export const GET_CSP_DOMAINS_REQUESTED = 'GET_CSP_DOMAINS_REQUESTED'
export const GET_CSP_DOMAINS_SUCCESS = 'GET_CSP_DOMAINS_SUCCESS'
export const GET_CSP_DOMAINS_FAILED = 'GET_CSP_DOMAINS_FAILED'

export const SET_CSP_DOMAINS_REQUESTED = 'SET_CSP_DOMAINS_REQUESTED'
export const SET_CSP_DOMAINS_SUCCESS = 'SET_CSP_DOMAINS_SUCCESS'
export const SET_CSP_DOMAINS_FAILED = 'SET_CSP_DOMAINS_FAILED'

export const getCspDomains = () => ({
    type: GET_CSP_DOMAINS_REQUESTED,
})

export const setCspDomains = (domains, onFinish) => ({
    type: SET_CSP_DOMAINS_REQUESTED,
    payload: domains,
    onFinish: onFinish,
})
