import { LoadingOutlined } from '@ant-design/icons'
import { Card, Row, Col, Spin, Select } from 'antd'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { prepareNumberDataWithAverage, numberTooltipFormatter, pluralize } from '../../../helpers/chart'
import { getDateRange } from '../../../helpers/time'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import {
    fetchProspectsChartsData,
    fetchProspectsTableData,
    fetchProspectsCsvData,
} from '../../../redux/actions/reporting/prospectsActions'
import store from '../../../redux/store'
import AreaChart from '../components/AreaChart'
import ChartHeader from '../components/ChartHeader'
import DatesIntervalSelector from '../components/DatesIntervalSelector'
import DonutChart from '../components/DonutChart'
import ExportButtons from '../components/ExportButtons'
import Search from '../components/Search'
import SearchCriteria from '../components/SearchCriteria'

import ProspectInfoPopup from '../../../components/popups/ProspectInfoPopup'
import ProspectsTable from './ProspectsTable'
import { checkIfIsTHE, isMenuItemAllowed, checkCanSeeLiveStreams } from '../../../common/utils/user-account-helpers'

import { isIdpRedirect } from 'src/app/helpers/idpRedirect'
import { HomeRedirect } from 'src/app/containers/_layout/nav/components/HomeRedirect'

// import SelectedProspects from "./SelectedProspects";
const { Option } = Select

const searchCriteriaOptionsDefault = [
    {
        key: 'full_name',
        name: 'Name',
    },
    {
        key: 'email',
        name: 'Email',
    },
    {
        key: 'nationality',
        name: 'Nationality',
    },
    {
        key: 'source',
        name: 'Source',
    },
    {
        key: 'medium',
        name: 'Medium',
    },
    {
        key: 'campaign',
        name: 'Campaign',
    },
    {
        key: 'chats',
        name: "Ambassador's name",
    },
    {
        key: 'group_chats',
        name: 'Group chat name',
    },
    {
        key: 'live_streams',
        name: 'Stream name',
    },
]

class ReportingProspects extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedButton: '30Days',
            selectedDateRange: getDateRange(30),
            searchString: '',
            searchColumn: 'full_name',
            statusColumn: 'all',
            groupColumn: 'all',
            searchEnter: false,
            registeredViaGroupIds: [],
            registeredViaUniversityIds: [],
            options: [],
            limit: 10,
            orderBy: 'university_registration',
            order: 'desc',
            orderByInitial: 'university_registration',
            orderInitial: 'desc',
            currentPage: 1,
            offset: 0,
            areSelectedAll: true,
            userOpenModal: false,
            userData: null,
            selectedProspectIds: new Set(),
            excludedProspectIds: new Set(),
        }

        this.searchCriteriaOptions = searchCriteriaOptionsDefault

        this.statusCriteriaOptions = [
            {
                key: 'all',
                name: 'All prospects',
            },
            {
                key: 'active',
                name: 'Active prospects',
            },
            {
                key: 'blocked',
                name: 'Blocked prospects',
            },
        ]

        this.groupsOptions = []

        this.handleDatesIntervalSelectorUpdate = this.handleDatesIntervalSelectorUpdate.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleSearchCriteria = this.handleSearchCriteria.bind(this)
        this.handleStatusCriteria = this.handleStatusCriteria.bind(this)
        this.handleGroupCriteria = this.handleGroupCriteria.bind(this)
        this.handleProspectsSelectionChange = this.handleProspectsSelectionChange.bind(this)
        this.handleClickExportSelected = this.handleClickExportSelected.bind(this)
        this.handleClickExportAll = this.handleClickExportAll.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.searchData = this.searchData.bind(this)
    }

    componentDidMount() {
        let {
            selectedDateRange,
            searchString,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            limit,
            orderBy,
            order,
            groupColumn,
        } = this.state
        const account = this.props.account
        const { account_info: accountInfo } = account

        if (accountInfo && accountInfo.isGroupAdmin && accountInfo.university && accountInfo.university.partnerGroups) {
            this.groupsOptions.push(
                {
                    key: 'all',
                    name: 'All',
                },
                {
                    key: 'university',
                    name: accountInfo.university.name,
                }
            )

            for (const group of accountInfo.university.partnerGroups) {
                this.groupsOptions.push({
                    key: group.id,
                    name: group.name,
                })
            }
        } else {
            const isTHE = checkIfIsTHE(accountInfo.universityGroup ? accountInfo.universityGroup.id : {})
            const canSeeLiveStreams = checkCanSeeLiveStreams(accountInfo.university)

            if (!canSeeLiveStreams) {
                this.searchCriteriaOptions = this.searchCriteriaOptions.filter(
                    (column) => column.key !== 'live_streams'
                )
            }

            if (isTHE) {
                this.groupsOptions.push(
                    {
                        key: 'all',
                        name: 'All',
                    },
                    {
                        key: accountInfo.universityGroup.id,
                        name: 'By group',
                    }
                )
            }
        }

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'reporting',
            },
        })

        const filter = this.getPreparedFilterData()

        if (groupColumn === 'university') {
            registeredViaUniversityIds = accountInfo ? [accountInfo.university.id] : []
        } else if (groupColumn !== 'all') {
            registeredViaGroupIds = [groupColumn]
        }
        this.props.fetchProspectsChartsData({
            filter: selectedDateRange,
            registeredViaGroupIds,
            registeredViaUniversityIds,
        })
        this.props.fetchProspectsTableData({
            filter,
            search: searchString.length > 0 ? searchString : null,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            limit,
            offset: 0,
            orderBy,
            order,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const account = this.props.account
        const { currentGroupUniversityId, account_info: accountInfo } = account
        if (prevProps.account.currentGroupUniversityId !== currentGroupUniversityId) {
            let {
                selectedDateRange,
                registeredViaGroupIds,
                registeredViaUniversityIds,
                limit,
                orderBy,
                order,
                groupColumn,
            } = this.state
            const search = this.getPreparedSearchData()
            const filter = this.getPreparedFilterData()

            const canSeeLiveStreams = checkCanSeeLiveStreams(accountInfo.university)

            this.searchCriteriaOptions = !canSeeLiveStreams
                ? this.searchCriteriaOptions.filter((column) => column.key !== 'live_streams')
                : searchCriteriaOptionsDefault

            if (groupColumn === 'university') {
                registeredViaUniversityIds = accountInfo ? [accountInfo.university.id] : []
            } else if (groupColumn !== 'all') {
                registeredViaGroupIds = [groupColumn]
            }
            this.props.fetchProspectsChartsData({
                filter: selectedDateRange,
                registeredViaGroupIds,
                registeredViaUniversityIds: currentGroupUniversityId ? [] : registeredViaUniversityIds,
            })
            this.props.fetchProspectsTableData({
                filter,
                search,
                registeredViaGroupIds,
                registeredViaUniversityIds: currentGroupUniversityId ? [] : registeredViaUniversityIds,
                limit,
                offset: 0,
                orderBy,
                order,
            })
            if (
                accountInfo?.isGroupAdmin &&
                currentGroupUniversityId &&
                registeredViaUniversityIds &&
                registeredViaUniversityIds.length > 0
            ) {
                this.setState({ registeredViaUniversityIds: [] })
            }
        }
    }

    generateDatesSelector() {
        return (
            <DatesIntervalSelector
                selectedButton={this.state.selectedButton}
                fromDate={this.state.selectedDateRange.fromDate}
                toDate={this.state.selectedDateRange.toDate}
                onChange={this.handleDatesIntervalSelectorUpdate}
            />
        )
    }

    handleDatesIntervalSelectorUpdate(selectedButton, selectedDateRange) {
        this.setState({
            selectedButton,
            selectedDateRange,
            areSelectedAll: true,
            selectedProspectIds: new Set(),
            excludedProspectIds: new Set(),
            currentPage: 1,
        })

        let { registeredViaGroupIds, registeredViaUniversityIds, limit, orderBy, order, groupColumn } = this.state
        const account = this.props.account
        const search = this.getPreparedSearchData()
        const filter = this.getPreparedFilterData(null, selectedDateRange)

        if (groupColumn === 'university') {
            registeredViaUniversityIds = [account.account_info.university.id]
        } else if (groupColumn !== 'all') {
            registeredViaGroupIds = [groupColumn]
        }
        this.props.fetchProspectsChartsData({
            filter: selectedDateRange,
            registeredViaGroupIds,
            registeredViaUniversityIds,
        })
        this.props.fetchProspectsTableData({
            filter,
            search,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            limit,
            offset: 0,
            orderBy,
            order,
        })
    }

    handleTableChange(pagination, filters, sorter) {
        let { registeredViaGroupIds, registeredViaUniversityIds, limit, orderByInitial, orderInitial, groupColumn } =
            this.state
        const { account } = this.props

        const offset = limit * (pagination ? pagination.current - 1 : 0)
        const orderBy = sorter && sorter.order && sorter.columnKey ? sorter.columnKey : orderByInitial
        const order =
            sorter && sorter.order && sorter.columnKey
                ? sorter.order === 'ascend'
                    ? 'asc'
                    : sorter.order === 'descend'
                    ? 'desc'
                    : orderInitial
                : orderInitial
        this.setState({
            orderBy: orderBy,
            order: order,
            currentPage: pagination.current,
            offset: offset,
        })
        const search = this.getPreparedSearchData()
        const filter = this.getPreparedFilterData()

        if (groupColumn === 'university') {
            registeredViaUniversityIds = [account.account_info.university.id]
        } else if (groupColumn !== 'all') {
            registeredViaGroupIds = [groupColumn]
        }
        this.props.fetchProspectsTableData({
            filter,
            search,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            limit,
            offset,
            orderBy,
            order,
        })
    }

    getPreparedSearchData = (searchStringParam, searchColumnParam) => {
        const { searchString, searchColumn } = this.state
        if (!searchStringParam) {
            searchStringParam = searchString
        }
        if (!searchColumnParam) {
            searchColumnParam = searchColumn
        }

        return {
            keyword: searchStringParam.length > 0 ? searchStringParam : null,
            field: searchColumnParam,
        }
    }

    getPreparedFilterData = (statusColumnParam, selectedDateRangeParam) => {
        const { selectedDateRange, statusColumn } = this.state

        return {
            ...(selectedDateRangeParam || selectedDateRange),
            status: statusColumnParam || statusColumn,
        }
    }

    prepareNationalitiesChartData() {
        const nationalities = this.props.charts.prospectAccountsByNationality

        if (!nationalities) {
            return { data: [] }
        }

        const data = []
        let totalCount = 0
        let maxCount = 0
        let maxName = 'No Data'

        for (const nationality in nationalities) {
            data.push({ name: nationality, y: nationalities[nationality] })

            if (nationalities[nationality] > maxCount) {
                maxCount = nationalities[nationality]
                maxName = nationality
            }

            totalCount += nationalities[nationality]
        }

        const percentage = totalCount ? (maxCount * 100) / totalCount : 0

        return {
            data: data.sort((a, b) => b.y - a.y),
            centerText:
                '<div style="font-size: 24px; color: #ff5100;">' +
                percentage.toFixed(0) +
                '%</div><br />' +
                '<div style="font-size: 12px; color: rgba(0,0,0,0.45);">' +
                maxName +
                '</div>',
        }
    }

    prepareProspectsAccountsChartData() {
        return prepareNumberDataWithAverage(this.props.charts.prospectAccountsByDate, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    prospectAccountsTooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'account'))
    }

    handleSearch(e) {
        if (e.keyCode === 13) {
            const searchString = e.target.value
            this.searchData(searchString, true)
        }
    }

    handleReset(e) {
        // couldn't find how to make it work in another way
        if (e.target.value.length === 0 && this.state.searchEnter) {
            this.searchData('')
        }
    }

    searchData(searchString, searchEnter = false) {
        let { registeredViaGroupIds, registeredViaUniversityIds, limit, orderBy, order, groupColumn } = this.state
        const account = this.props.account

        this.setState({
            searchString,
            searchEnter,
            currentPage: 1,
            selectedProspectIds: new Set(),
            excludedProspectIds: new Set(),
        })

        const search = this.getPreparedSearchData(searchString)
        const filter = this.getPreparedFilterData()
        if (groupColumn === 'university') {
            registeredViaUniversityIds = [account.account_info.university.id]
        } else if (groupColumn !== 'all') {
            registeredViaGroupIds = [groupColumn]
        }
        this.props.fetchProspectsTableData({
            filter,
            search,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            limit,
            offset: 0,
            orderBy,
            order,
        })
    }

    handleSearchCriteria(searchColumn) {
        let { searchString, registeredViaGroupIds, registeredViaUniversityIds, limit, orderBy, order, groupColumn } =
            this.state
        const account = this.props.account

        this.setState({ searchColumn, selectedProspectIds: new Set(), excludedProspectIds: new Set() })

        const search = this.getPreparedSearchData(searchString, searchColumn)
        const filter = this.getPreparedFilterData()

        if (groupColumn === 'university') {
            registeredViaUniversityIds = [account.account_info.university.id]
        } else if (groupColumn !== 'all') {
            registeredViaGroupIds = [groupColumn]
        }
        if (searchString.length > 0) {
            this.props.fetchProspectsTableData({
                filter,
                search,
                registeredViaGroupIds,
                registeredViaUniversityIds,
                limit,
                offset: 0,
                orderBy,
                order,
            })
        }
    }

    handleStatusCriteria(statusColumn) {
        let { registeredViaGroupIds, registeredViaUniversityIds, limit, orderBy, order, groupColumn } = this.state
        const account = this.props.account

        this.setState({ statusColumn, selectedProspectIds: new Set(), excludedProspectIds: new Set() })

        const search = this.getPreparedSearchData()
        const filter = this.getPreparedFilterData(statusColumn)

        if (groupColumn === 'university') {
            registeredViaUniversityIds = [account.account_info.university.id]
        } else if (groupColumn !== 'all') {
            registeredViaGroupIds = [groupColumn]
        }
        this.props.fetchProspectsTableData({
            filter,
            search,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            limit,
            offset: 0,
            orderBy,
            order,
        })
    }

    handleGroupCriteria(groupColumn) {
        let {
            selectedDateRange,
            searchString,
            searchColumn,
            statusColumn,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            limit,
            orderBy,
            order,
        } = this.state
        const { account } = this.props

        this.setState({ groupColumn })

        const search = {
            keyword: searchString.length > 0 ? searchString : null,
            field: searchColumn,
        }

        const filter = {
            ...selectedDateRange,
            status: statusColumn,
        }
        if (groupColumn === 'university') {
            registeredViaUniversityIds = [account.account_info.university.id]
        } else if (groupColumn !== 'all') {
            registeredViaGroupIds = [groupColumn]
        }

        this.props.fetchProspectsTableData({
            filter,
            search,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            limit,
            offset: 0,
            orderBy,
            order,
        })
    }

    handleProspectsSelectionChange(addedItems, removedItems, areSelectedAll) {
        const {
            selectedDateRange,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            excludedProspectIds,
            selectedProspectIds,
        } = this.state
        if (areSelectedAll !== undefined) {
            this.setState({
                areSelectedAll,
                selectedProspectIds: new Set(),
                excludedProspectIds: new Set(),
            })

            this.props.fetchProspectsChartsData({
                filter: selectedDateRange,
                prospectsIds: areSelectedAll ? null : [],
                registeredViaGroupIds,
                registeredViaUniversityIds,
            })

            return
        }

        if (Array.isArray(addedItems)) {
            for (const item of addedItems) {
                selectedProspectIds.add(item)
                excludedProspectIds.delete(item)
            }
        }

        if (Array.isArray(removedItems)) {
            for (const item of removedItems) {
                excludedProspectIds.add(item)
                selectedProspectIds.delete(item)
            }
        }

        this.setState({ selectedProspectIds, excludedProspectIds })

        this.props.fetchProspectsChartsData({
            filter: selectedDateRange,
            prospectsIds: areSelectedAll ? null : [...selectedProspectIds],
            excludedProspectsIds: areSelectedAll ? [...excludedProspectIds] : null,
            registeredViaGroupIds,
            registeredViaUniversityIds,
        })
    }

    handleClickExportSelected() {
        let {
            selectedDateRange,
            searchString,
            searchColumn,
            statusColumn,
            groupColumn,
            areSelectedAll,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            selectedProspectIds,
            excludedProspectIds,
        } = this.state
        const filter = {
            ...selectedDateRange,
            status: statusColumn,
        }
        if (groupColumn === 'university') {
            registeredViaUniversityIds = [this.props.account.account_info.university.id]
        } else if (groupColumn !== 'all') {
            registeredViaGroupIds = [groupColumn]
        }
        const search = {
            keyword: searchString.length > 0 ? searchString : null,
            field: searchColumn,
        }
        this.props.fetchProspectsCsvData({
            filter,
            search,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            prospectsIds: areSelectedAll ? null : [...selectedProspectIds],
            excludedProspectsIds: areSelectedAll ? [...excludedProspectIds] : null,
        })
    }

    handleClickExportAll() {
        const { selectedDateRange, registeredViaGroupIds, registeredViaUniversityIds } = this.state
        this.props.fetchProspectsCsvData({
            filter: selectedDateRange,
            registeredViaGroupIds,
            registeredViaUniversityIds,
        })
    }

    generateSelectedNumber() {
        return this.state.areSelectedAll
            ? this.props.table.total - this.state.excludedProspectIds.size
            : this.state.selectedProspectIds.size
    }

    generateExportSelectedCountLabel(selectedCount) {
        if (!this.props.table.prospectsData) {
            return ''
        }

        if (
            this.props.table.total > 0 &&
            ((!this.state.areSelectedAll && this.state.selectedProspectIds.size === this.props.table.total) ||
                (this.state.areSelectedAll && this.state.excludedProspectIds.size === 0))
        ) {
            return ' (All)'
        }

        return ' (' + selectedCount + ')'
    }

    getTableDataProps() {
        const { selectedDateRange, searchString, searchColumn, statusColumn, limit, orderBy, order, offset } =
            this.state

        const search = {
            keyword: searchString.length > 0 ? searchString : null,
            field: searchColumn,
        }

        const filter = {
            ...selectedDateRange,
            status: statusColumn,
        }

        return {
            filter: filter,
            search: search,
            limit: limit,
            offset: offset,
            orderBy: orderBy,
            order: order,
        }
    }

    setUniversities = (universities) => {
        const currentUniversity = universities.find((university) => (university + '').includes('-university'))
        this.setState(
            {
                registeredViaGroupIds: currentUniversity
                    ? universities.filter((university) => !(university + '').includes('-university'))
                    : universities,
                registeredViaUniversityIds: currentUniversity ? [currentUniversity.split('-university')] : [],
            },
            () => {
                let {
                    selectedDateRange,
                    registeredViaGroupIds,
                    registeredViaUniversityIds,
                    areSelectedAll,
                    selectedProspectIds,
                    excludedProspectIds,
                    limit,
                    orderBy,
                    order,
                    groupColumn,
                } = this.state
                const account = this.props.account

                const search = this.getPreparedSearchData()
                const filter = this.getPreparedFilterData()

                if (groupColumn === 'university') {
                    registeredViaUniversityIds = [account.account_info.university.id]
                } else if (groupColumn !== 'all') {
                    registeredViaGroupIds = [groupColumn]
                }
                this.props.fetchProspectsChartsData({
                    filter: selectedDateRange,
                    registeredViaGroupIds,
                    registeredViaUniversityIds,
                    prospectsIds: areSelectedAll ? null : [...selectedProspectIds],
                    excludedProspectsIds: areSelectedAll ? [...excludedProspectIds] : null,
                })
                this.props.fetchProspectsTableData({
                    filter,
                    search,
                    registeredViaGroupIds,
                    registeredViaUniversityIds,
                    limit,
                    offset: 0,
                    orderBy,
                    order,
                })
            }
        )
    }

    addUniversity = (value) => {
        const { account_info: accountInfo } = this.props.account
        const preparedUniversity = accountInfo?.university?.groups?.find(
            (university) => university.name.toUpperCase() === value.trim().toUpperCase()
        )
        this.setUniversities(
            accountInfo?.university?.groups ? [...accountInfo.university.groups, preparedUniversity?.id] : []
        )
    }

    render() {
        const {
            currentPage,
            searchColumn,
            statusColumn,
            groupColumn,
            areSelectedAll,
            selectedProspectIds,
            excludedProspectIds,
        } = this.state
        const { account, table, isTableLoading, csvUrl } = this.props

        const {
            account_info: { isGroupAdmin, university = {} },
        } = account
        const { id, name, permissions = [], groups = [] } = university
        const nationalitiesData = this.prepareNationalitiesChartData()
        const prospectsAccountsData = this.prepareProspectsAccountsChartData()
        const colors = ['#ff5100', '#27AE60', '#F7DC6F', '#E74C3C', '#afd0eb', '#d2e116', '#fde971', '#aaaaff']
        const selectedCount = this.generateSelectedNumber()
        const selectedNumberLabel = this.generateExportSelectedCountLabel(selectedCount)
        const tableDataProps = this.getTableDataProps()

        const options = !isGroupAdmin ? (groups ? [{ id: id + '-university', name }, ...groups] : [university]) : [] // isGroupAdmin ? (!currentGroupUniversityId && universityGroup?.universities ? universityGroup.universities : []) : groups || [];
        const preparedOptions = options.map((item) => {
            return (
                <Option key={item.id} value={item.id} label={item.name}>
                    {item.name}
                </Option>
            )
        })

        if (isIdpRedirect(account.account_info)) {
            return <HomeRedirect />
        }

        return (
            <section className="content reporting prospects-reporting">
                <Card className="reporting-block">
                    <Row gutter={[16, 16]} className="reporting-title-block">
                        <Col span={isGroupAdmin ? 8 : 24}>
                            <div className="prospect-table-title">Prospects Reporting</div>
                        </Col>
                        {!isGroupAdmin ? (
                            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
                                    <Select
                                        mode="multiple"
                                        onChange={this.setUniversities}
                                        onClick={(e) => e.preventDefault()}
                                        clearIcon={true}
                                        bordered={false}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        placeholder="Places where your ambassadors are displayed"
                                        onInputKeyDown={(e) => e.key === 'Enter' && this.addUniversity(e.target.value)}
                                        className="uni-multi-select"
                                    >
                                        {preparedOptions}
                                    </Select>
                                    {this.generateDatesSelector()}
                                </div>
                            </Col>
                        ) : (
                            <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {this.generateDatesSelector()}
                            </Col>
                        )}
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <ChartHeader title={`Prospect Nationalities (${nationalitiesData.data.length})`} />
                            <DonutChart
                                colors={colors}
                                data={nationalitiesData.data}
                                centerText={nationalitiesData.centerText}
                                height={248}
                            />
                        </Col>
                        <Col span={16}>
                            <ChartHeader
                                title="Prospect Accounts Created"
                                label={'Average: ' + prospectsAccountsData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + prospectsAccountsData.total}
                                totalLabelDescription="Across date range"
                            />
                            <AreaChart
                                color="#ff5100"
                                data={prospectsAccountsData.data}
                                tooltipFormatter={this.prospectAccountsTooltipFormatter}
                                height={200}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card className="reporting-block" style={{ marginTop: 16 }}>
                    <Spin
                        spinning={isTableLoading}
                        indicator={<LoadingOutlined style={{ fontSize: 50, color: '#ff5100' }} />}
                    >
                        <div className="search-wrapper">
                            <div className="search-control-container">
                                {isMenuItemAllowed(
                                    'reporting_prospects_group_data_allowed',
                                    isGroupAdmin,
                                    permissions
                                ) && (
                                    <>
                                        <div className="search-control-item search">
                                            <div className="search-control-title">Search by:</div>
                                            <div className="search-wrapper">
                                                <SearchCriteria
                                                    onChange={this.handleSearchCriteria}
                                                    placeholder="Select search filter"
                                                    value={searchColumn}
                                                    options={this.searchCriteriaOptions}
                                                />
                                                <Search
                                                    onChange={this.handleSearch}
                                                    handleReset={this.handleReset}
                                                    placeholder="Search for prospects"
                                                    width={240}
                                                />
                                            </div>
                                        </div>
                                        <div className="search-control-item filter">
                                            <div className="search-control-title">Filter by:</div>
                                            <div className="search-wrapper">
                                                <SearchCriteria
                                                    onChange={this.handleStatusCriteria}
                                                    placeholder="Select search filter"
                                                    value={statusColumn}
                                                    options={this.statusCriteriaOptions}
                                                    styles={{ width: 177 }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                                {isGroupAdmin &&
                                    this.groupsOptions.length > 0 &&
                                    isMenuItemAllowed('reporting_prospects_group_data_allowed', true, permissions) && (
                                        <div className="search-control-item filter">
                                            <div className="search-control-title">Registered TAP page:</div>
                                            <div className="search-wrapper">
                                                <SearchCriteria
                                                    onChange={this.handleGroupCriteria}
                                                    placeholder="Select search filter"
                                                    value={groupColumn}
                                                    options={this.groupsOptions}
                                                    styles={{ width: 177 }}
                                                />
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <ExportButtons
                                onClickSelected={this.handleClickExportSelected}
                                showExportSelected={!selectedCount}
                                onClickAll={this.handleClickExportAll}
                                showExportAll={table.prospectsData && table.prospectsData.length === 0}
                                filePath={csvUrl}
                                selectedNumber={selectedNumberLabel}
                            />
                        </div>
                        <ProspectsTable
                            account={account}
                            data={table}
                            areSelectedAll={areSelectedAll}
                            selected={selectedProspectIds}
                            unselected={excludedProspectIds}
                            onSelectionChange={this.handleProspectsSelectionChange}
                            searchColumn={searchColumn}
                            handleTableChange={this.handleTableChange}
                            currentPage={currentPage}
                        />
                    </Spin>
                    <ProspectInfoPopup tableDataProps={tableDataProps} />
                </Card>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    charts: state.prospects.charts,
    table: state.prospects.table,
    csvUrl: state.prospects.csvUrl,
    isTableLoading: state.prospects.isTableLoading,
})

export default connect(mapStateToProps, {
    fetchProspectsChartsData,
    fetchProspectsTableData,
    fetchProspectsCsvData,
})(ReportingProspects)
