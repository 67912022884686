import { differenceInMinutes, startOfDay, isSameDay, addMinutes, roundToNearestMinutes } from 'date-fns'
import { isEmpty } from 'ramda'

import { LIVE_STREAM_STATUS } from '../../../../../common/constants'
import { parseUtcToTimezone } from '../../../../../common/utils/date-time-utils'

const createEmptyFormModel = (timezones, accountInfoId) => {
    const timezone = timezones.find(({ is_active: isActive }) => isActive) || {}

    return {
        name: '',
        image: undefined,
        description: '',
        date: undefined,
        fromTime: undefined,
        toTime: undefined,
        timezoneId: timezone.id,
        globalTagsIds: [],
        isPromote: false,
        isGroupPromote: false,
        internalSpeakerIds: [accountInfoId],
        externalSpeakerEmails: [
            {
                email: '',
                confirmEmail: '',
            },
        ],
        userIds: [],
        speakersPlaceholder: '',
        autoPublish: true,
    }
}

const createEditFormModel = (data) => {
    if (data.status === LIVE_STREAM_STATUS.IN_PROGRESS) {
        return {
            name: data.name,
            description: data.description,
            globalTagsIds: data.globalTags,
        }
    }
    const isSameDayChosen = isSameDay(new Date(data.fromDate), new Date())

    const fromDate = parseUtcToTimezone(data.fromDate, data.timezoneName)
    const toDate = parseUtcToTimezone(data.toDate, data.timezoneName)

    const fromTime = isSameDayChosen
        ? differenceInMinutes(fromDate, roundToNearestMinutes(addMinutes(new Date(), 30), { nearestTo: 15 }))
        : differenceInMinutes(fromDate, startOfDay(fromDate))

    const toTime = differenceInMinutes(toDate, fromDate) + fromTime

    return {
        id: data.id,
        name: data.name,
        image: data.image.original,
        description: data.description,
        timezoneId: data.timezoneId,
        globalTagsIds: data.globalTags,
        isPromote: !data.isPromote,
        isGroupPromote: !data.isGroupPromote,
        date: fromDate,
        fromTime,
        toTime,
    }
}

export default (data = {}, timezones, accountInfoId) =>
    isEmpty(data) ? createEmptyFormModel(timezones, accountInfoId) : createEditFormModel(data)
