import { Modal } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useModalVisibility } from 'src/app/hooks/useModalVisibility';
import CreateContentGroup from '../../../messaging/components/popups/CreateContentGroup';
export var CreateContentGroupModal = function () {
    var location = useLocation();
    var history = useHistory();
    var visible = location.hash === '#create-content-group';
    var _a = useModalVisibility(visible), mount = _a[0], setMount = _a[1];
    var closeModal = function () {
        history.replace({ hash: '' });
    };
    return mount ? (React.createElement(Modal, { open: visible, className: "react-create-content-group", centered: true, closable: true, maskClosable: true, destroyOnClose: true, onCancel: closeModal, afterClose: setMount, footer: null },
        React.createElement(CreateContentGroup, { onClosePopup: closeModal, location: location, history: history }))) : null;
};
