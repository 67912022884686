import React from 'react'
import { Field } from 'redux-form'

import Loader from '../containers/content/components/Loader'

const EMPTY_NAME_PLACEHOLDER = 'N/A'

const SearchField = ({ input, id, accept, name, placeholder, type, className, meta: { touched, error, warning } }) => (
    <label className="ui-kit-label">
        <input
            {...input}
            className={className}
            name={name}
            placeholder={placeholder}
            type={type}
            accept={accept}
            id={id}
        />
        {touched &&
            ((error && <span className="error faq-create">{error}</span>) || (warning && <span>{warning}</span>))}
    </label>
)

const UserAvatar = ({ user }) => {
    const { avatar, name } = user

    if (avatar) return <img className="content-group-check-img" src={avatar.sizes['70x70'] || avatar.original} />

    return (
        <span className="content-group-check-img content-group-check-img-name ">
            {name ? name[0] : EMPTY_NAME_PLACEHOLDER}
        </span>
    )
}

const UserLineItem = ({ user, onUserSelectionUpdated, isSelected }) => (
    <label className="content-group-user">
        <input
            type="checkbox"
            className="content-group-check"
            name={'user-' + user.id}
            value={user.id}
            data-username={user.name}
            checked={isSelected}
            onChange={(e) => onUserSelectionUpdated(e.target.value, e.target.dataset.username)}
        />
        <span className="content-group-check-icon" />
        <div className="content-group-check-img-block">
            <UserAvatar user={user} />
        </div>
        <span className="user-name">{user.name || EMPTY_NAME_PLACEHOLDER}</span>
        {user.courseType !== '' && <p className="user-study-time">{user.courseType}</p>}
    </label>
)

const SelectAllButton = ({ showSelectAll, onClick }) => (
    <p className="select-all" onClick={onClick}>
        {showSelectAll ? 'Select All' : 'Unselect All'}
    </p>
)

const UsersList = ({ isLoading, users, onUserSelectionUpdated, assignedUsers }) => (
    <div className="content-group-users scroll-8">
        {isLoading ? (
            <Loader />
        ) : (
            users.map((user) => (
                <UserLineItem
                    key={user.id}
                    user={user}
                    onUserSelectionUpdated={onUserSelectionUpdated}
                    isSelected={assignedUsers[user.id] ? assignedUsers[user.id].selected : false}
                />
            ))
        )}
    </div>
)

const SelectedUsers = ({ selectedUsers }) => {
    let selectedStudentsNames = []
    selectedUsers.forEach((value) => {
        selectedStudentsNames.push(value.name ? value.name.split(' ')[0] : EMPTY_NAME_PLACEHOLDER)
    })

    selectedStudentsNames = selectedStudentsNames.join(', ')

    return (
        <>
            <p
                className={`content-group-selected-count ${
                    selectedUsers.size > 0 && 'content-group-selected-count--active'
                }`}
            >
                {selectedUsers.size + ' selected'}
            </p>
            <p className="content-group-selected-names">{selectedStudentsNames}</p>
        </>
    )
}

const SelectAmbassadors = (props) => {
    const {
        areAmbassadorsLoading,
        ambassadors,
        onUserSelectionUpdated,
        assignedUsers,
        selectedUsers,
        onSearchUpdated,
        onSelectAll,
        isEveryoneSelected,
        hideTitle,
    } = props

    return (
        <div className="students-addings">
            {!hideTitle && (
                <p className="content-group-name add-amb-title">Add or remove ambassadors by checking the boxes</p>
            )}
            <div className="add-amb-block">
                <Field
                    name="user_name"
                    component={SearchField}
                    type="text"
                    placeholder="Search by keyword "
                    className="ui-kit-input mod-users"
                    onChange={onSearchUpdated}
                />
            </div>
            <SelectAllButton showSelectAll={!isEveryoneSelected} onClick={onSelectAll} />
            <UsersList
                isLoading={areAmbassadorsLoading}
                users={ambassadors}
                onUserSelectionUpdated={onUserSelectionUpdated}
                assignedUsers={assignedUsers}
            />
            <SelectedUsers selectedUsers={selectedUsers} />
        </div>
    )
}

export default SelectAmbassadors
