import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tryParseJSON } from '../../../../common/utils/jsonUtils';
import { defaults } from '../../../../system/services/HttpService';
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg';
import { closeVideoReUploadModal, onVideoReUploadSuccess, } from '../../../../redux/actions/live-streams/live-streams-actions';
import { openNotificationModal } from '../../../../redux/actions/notification-modal/notification-modal-actions';
import { LIVE_STREAMS } from '../../../../redux/actions/live-streams/live-streams-action-types';
import { getAccountSlice, getVideoReUploadModal } from '../../../../redux/selectors';
import { VideoUpload } from '../index';
import UploadModalBody from './upload-modal-body';
var UploadVideoModal = function () {
    var dispatch = useDispatch();
    var _a = useState(null), videoFileRequest = _a[0], setVideoFileRequest = _a[1];
    var _b = useState(0), videoProgress = _b[0], setVideoProgress = _b[1];
    var _c = useState(false), videoProgressStart = _c[0], setVideoProgressStart = _c[1];
    var _d = useState(''), videoFileName = _d[0], setVideoFileName = _d[1];
    var videoReUploadModalData = useSelector(getVideoReUploadModal);
    var accountInfo = useSelector(getAccountSlice);
    var handleModalClose = function () {
        dispatch(closeVideoReUploadModal());
    };
    var progressHandler = function (event) {
        var percent = (event.loaded / event.total) * 100;
        var numberBeforeDecimal = Number.parseInt(percent.toString(), 10);
        setVideoProgress(numberBeforeDecimal);
    };
    var completeHandler = function (event) {
        var _a, _b;
        setVideoProgressStart(false);
        var request = event.currentTarget;
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = tryParseJSON(request.responseText);
                if (response && response.data) {
                    dispatch(onVideoReUploadSuccess({
                        file: (_a = response.data) === null || _a === void 0 ? void 0 : _a.file,
                        liveStreamId: (_b = videoReUploadModalData === null || videoReUploadModalData === void 0 ? void 0 : videoReUploadModalData.data) === null || _b === void 0 ? void 0 : _b.liveStreamId, // TODO Fix me
                    }));
                    setVideoFileName('');
                    dispatch(closeVideoReUploadModal());
                    dispatch(openNotificationModal(LIVE_STREAMS.UPDATE_SUCCESS));
                }
            }
            else {
                console.log('Error loading file');
            }
        }
    };
    var errorHandler = function (event) {
        console.log(event);
        setVideoProgressStart(false);
        setVideoProgress(0);
        setVideoFileName('');
    };
    var abortHandler = function () {
        setVideoProgressStart(false);
        setVideoProgress(0);
        setVideoFileName('');
    };
    var abortVideoUpload = function () {
        videoFileRequest === null || videoFileRequest === void 0 ? void 0 : videoFileRequest.abort();
    };
    var videoTranscode = function (file, _, fileName) {
        var _a;
        var url = "".concat(defaults.api_host, "/dashboard/liveStreams/").concat((_a = videoReUploadModalData === null || videoReUploadModalData === void 0 ? void 0 : videoReUploadModalData.data) === null || _a === void 0 ? void 0 : _a.liveStreamId // TODO FIX ME
        , "/upload");
        var formData = new window.FormData();
        formData.append('file', file);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        var request = new window.XMLHttpRequest();
        setVideoFileRequest(request);
        setVideoFileName(fileName);
        request.upload.addEventListener('progress', progressHandler, false);
        request.addEventListener('load', completeHandler, false);
        request.addEventListener('error', errorHandler, false);
        request.addEventListener('abort', abortHandler, false);
        request.open('POST', url);
        request.setRequestHeader('Authorization', 'Bearer ' + accountInfo.token);
        request.send(formData);
        setVideoProgressStart(true);
    };
    return (React.createElement(Modal, { title: "Re-upload video recording", open: videoReUploadModalData.open, closeIcon: React.createElement(CloseIcon, null), onCancel: handleModalClose, width: 600, className: "tap-event-modal", destroyOnClose: true, footer: React.createElement(React.Fragment, null, videoProgressStart ? (React.createElement(Button, { type: "primary", size: "large", onClick: abortVideoUpload, "data-automation-id": "cancel-upload-btn" }, "Cancel")) : (React.createElement(VideoUpload, { videoTranscode: videoTranscode }))) },
        React.createElement(UploadModalBody, { videoProgress: videoProgress, videoFileName: videoFileName, videoProgressStart: videoProgressStart })));
};
export default UploadVideoModal;
