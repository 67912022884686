import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import Sprite from '../../../../../../assets/img/symbol/sprite.svg'
import Textarea from 'react-textarea-autosize'

const renderField = ({ placeholder, type, id, className, minRows, maxRows }) => (
    <Textarea rows={minRows} maxRows={maxRows} id={id} type={type} placeholder={placeholder} className={className} />
)

const handleKeyDown = (e, cb) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
        e.preventDefault()
        cb()
    }
}

class SendMessageForm extends PureComponent {
    render() {
        const { handleSubmit } = this.props

        return (
            <form
                action="#"
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                    handleKeyDown(e, handleSubmit)
                }}
                className="chat-form"
                id="send-message-form"
            >
                <Field
                    name="text"
                    component={renderField}
                    type="text"
                    placeholder="To enter the conversation, write a message"
                    className="chat-input"
                    id="chat-message-textarea"
                    maxRows={9}
                    minRows={1}
                />
                <button type="submit" className="chat-sbm">
                    <svg className="icon icon-send">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#send'} />
                    </svg>
                </button>
            </form>
        )
    }
}

export default reduxForm({
    form: 'send_message_form',
})(SendMessageForm)
