var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CREATE_GLOBAL_TAG_FAILED, CREATE_GLOBAL_TAG_SUCCESS, GET_GLOBAL_LIST_TAGS_FAILED, GET_GLOBAL_LIST_TAGS_SUCCESS, } from '../../actions/tags/tagsActions';
var initialState = {
    items: [],
    total: 0,
    limit: 50,
    offset: 0,
    isLoading: true,
};
export default function (state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case CREATE_GLOBAL_TAG_SUCCESS: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case GET_GLOBAL_LIST_TAGS_SUCCESS: {
            return __assign(__assign({}, state), { items: payload.offset ? __spreadArray(__spreadArray([], state.items, true), payload.items, true) : payload.items, offset: payload.offset ? payload.offset : 0, total: payload.total, isLoading: false });
        }
        case CREATE_GLOBAL_TAG_FAILED: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case GET_GLOBAL_LIST_TAGS_FAILED: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        default: {
            return state;
        }
    }
}
