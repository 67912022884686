/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getUsersMessagingSlice } from '../../../../../redux/selectors'

import UsersCheckboxList from './components/users-checkbox-list'
import UsersSearchField from './components/users-search-field'

const EMPTY_NAME_PLACEHOLDER = 'N/A'

const UserCheckboxModel = (user) => {
    return !user.roles
        ? {
              id: user.id,
              avatar: user.avatar,
              name: user.name || EMPTY_NAME_PLACEHOLDER,
              role: user.profileType ? user.profileType : user.role_name,
          }
        : {
              id: user.id,
              avatar: user.avatar,
              name: user.name || EMPTY_NAME_PLACEHOLDER,
              role: user.profileType ? user.profileType : user.roles[0].name,
          }
}

const parseUsers = (users) => users.map((user) => UserCheckboxModel(user))

const UsersSelect = ({ id, label, roles, rules, showRoles = false, form, selectedUsers = [] }) => {
    const userMessaging = useSelector(getUsersMessagingSlice)
    const { isLoading, ambassadors } = userMessaging

    const items = useMemo(() => {
        return parseUsers(ambassadors)
    }, [ambassadors])

    return (
        <>
            <UsersSearchField roles={roles} label={label} showRoles={showRoles} isLoading={isLoading} />
            <UsersCheckboxList id={id} items={items} rules={rules} form={form} />
        </>
    )
}

export default UsersSelect
