import React from 'react';
import { concatClsx } from 'src/app/helpers/classNames';
var ChartHeader = function (_a) {
    var _b = _a.title, title = _b === void 0 ? '' : _b, _c = _a.label, label = _c === void 0 ? '' : _c, _d = _a.labelDescription, labelDescription = _d === void 0 ? '' : _d, _e = _a.totalLabel, totalLabel = _e === void 0 ? '' : _e, _f = _a.totalLabelDescription, totalLabelDescription = _f === void 0 ? '' : _f, _g = _a.displaySingleLabel, displaySingleLabel = _g === void 0 ? false : _g, className = _a.className;
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "chart-title" }, title),
        React.createElement("div", { className: concatClsx('chart-label-wrapper', displaySingleLabel ? 'chart-label-wrapper--display-single-label' : '') },
            React.createElement("div", { className: "chart-label-item-wrapper" },
                React.createElement("div", { className: "chart-label" }, totalLabel),
                React.createElement("div", { className: "chart-label-description" }, totalLabelDescription)),
            React.createElement("div", { className: "chart-label-item-wrapper" },
                React.createElement("div", { className: "chart-label" }, label),
                React.createElement("div", { className: "chart-label-description" }, labelDescription)))));
};
export default ChartHeader;
