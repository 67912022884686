import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Form, Select, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import pencil from '../../../../../../assets/img/pencil.png'

import { getUsersSlice } from '../../../../../redux/selectors'

import {
    changeUserSubject,
    changeAmbassadorSubject,
    getSubjects,
} from '../../../../../redux/actions/content/usersActions'

const { Option } = Select

const AmbassadorSubject = ({ subject, userId, saveStudentInfo }) => {
    const [editMode, setEditMode] = useState(false)
    const [customSubject, setCustomSubject] = useState('')

    const { subjects: subjectList } = useSelector(getUsersSlice)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSubjects())
    }, [dispatch])

    const onFinish = useCallback(
        (values) => {
            if (subjectList.some((item) => item.id === values.subject)) {
                dispatch(
                    changeAmbassadorSubject({
                        userId,
                        data: subjectList?.filter((item) => item.id === values.subject),
                    })
                )
            }
            setEditMode(false)
            saveStudentInfo()
        },
        [dispatch, subjectList, userId, saveStudentInfo]
    )

    const editType = useCallback(() => {
        setEditMode(true)
        setCustomSubject('')
    }, [])

    const onCancel = useCallback(() => {
        setEditMode(false)
    }, [])

    const onSearch = useCallback((val) => {
        setCustomSubject(val)
    }, [])

    const addCustomSubject = useCallback(() => {
        dispatch(changeUserSubject(userId, customSubject))
        setEditMode(false)
    }, [dispatch, userId, customSubject])

    const options = useMemo(
        () =>
            subjectList?.map((subject) => {
                return (
                    <Option key={subject.id} value={subject.id} label={subject.name}>
                        {subject.name}
                    </Option>
                )
            }),
        [subjectList]
    )

    const emptyContent = useMemo(
        () =>
            customSubject &&
            subject && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button type="link" onClick={addCustomSubject}>
                        Add Custom Subject
                    </Button>
                </div>
            ),
        [addCustomSubject, customSubject, subject]
    )

    return editMode ? (
        <>
            <Form
                initialValues={{
                    subject: subject ? subject[0].name : '',
                }}
                onFinish={onFinish}
                autoComplete="off"
                hideRequiredMark
            >
                <Form.Item
                    name="subject"
                    label={null}
                    style={{ marginBottom: 20 }}
                    rules={[{ required: true, message: 'Select subject' }]}
                >
                    <Select
                        showSearch
                        notFoundContent={emptyContent}
                        optionFilterProp="children"
                        optionLabelProp="label"
                        onSearch={onSearch}
                        style={{ fontSize: 16, maxWidth: 200, minWidth: 150 }}
                    >
                        {options}
                    </Select>
                </Form.Item>
                <div className="input__buttons">
                    {subject && (
                        <button className="input-control-button" onClick={onCancel}>
                            Cancel
                        </button>
                    )}
                    <button className="input-control-button" type="submit">
                        Save
                    </button>
                </div>
            </Form>
        </>
    ) : (
        <>
            <div className="profile-info-tags-items">
                {subject ? subject[0].name : <p style={{ color: 'red' }}>Please, specify</p>}
                <button className="input-control-button" onClick={editType}>
                    <img className="pencil-icon" src={pencil} alt="" />
                </button>
            </div>
        </>
    )
}

export default AmbassadorSubject
