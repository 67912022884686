var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable camelcase */
import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { Permissions } from 'src/app/permissions/permissions';
import { PermissionEnum } from 'src/types/general';
import { CellTitle, CopyLinkCell, DeleteUserCell, LastActiveCell, NameCell, PartnerGroupCellTitle, ToggleCell, } from './column-components';
var Text = Typography.Text;
export var useUsersColumnsConfig = function (isTHEPartner, partnerGroups, usersTotals) {
    return useMemo(function () {
        var cells = [
            {
                title: React.createElement(CellTitle, { text: "Ambassadors", record: usersTotals, titleKey: "total_ambassadors" }),
                dataIndex: 'full_name',
                key: 'fullName',
                sorter: true,
                render: NameCell,
            },
            {
                title: React.createElement(Text, { className: "text-submenu-lg" }, "Last active"),
                dataIndex: 'last_activity',
                key: 'lastActivity',
                sorter: true,
                render: LastActiveCell,
            },
        ];
        if (Permissions.can(PermissionEnum.IdpCommunity, false)) {
            cells.push({
                title: React.createElement(Text, { className: "text-submenu-lg" }, "IDP P2P Community"),
                dataIndex: 'idp_community_permission',
                render: function (data, record) { return (React.createElement(ToggleCell, { columnName: "idpCommunity", data: data, record: record, permissionId: data === null || data === void 0 ? void 0 : data.id, permissionKey: PermissionEnum.IdpCommunity })); },
            });
        }
        if (Permissions.can(PermissionEnum.IdpLiveApp, false)) {
            cells.push({
                title: React.createElement(Text, { className: "text-submenu-lg" }, "IDP Live App"),
                dataIndex: 'idp_live_app_permission',
                render: function (data, record) { return (React.createElement(ToggleCell, { columnName: "idpLiveApp", data: data, record: record, permissionId: data === null || data === void 0 ? void 0 : data.id, permissionKey: PermissionEnum.IdpLiveApp })); },
            });
        }
        if (Permissions.can(PermissionEnum.IdpWhatUni, false)) {
            cells.push({
                title: React.createElement(Text, { className: "text-submenu-lg" }, "IDP WhatUni"),
                dataIndex: 'idp_what_uni_permission',
                render: function (data, record) { return (React.createElement(ToggleCell, { columnName: "idpWhatUni", data: data, record: record, permissionId: data === null || data === void 0 ? void 0 : data.id, permissionKey: PermissionEnum.IdpWhatUni })); },
            });
        }
        if (partnerGroups && partnerGroups.length > 0) {
            partnerGroups.map(function (group) {
                return cells.push({
                    title: (React.createElement(PartnerGroupCellTitle, { text: group.name, record: usersTotals, groupId: group.id, titleKey: "total_partner_group_tap_feeds" })),
                    dataIndex: "partner_group_tap_feed_permission_".concat(group.id),
                    key: group.id,
                    sorter: true,
                    render: function (data, record) { return (React.createElement(ToggleCell, { columnName: "chatPartnerCheckbox", data: data, record: record, permissionId: group.id, permissionKey: PermissionEnum.Chat })); },
                });
            });
        }
        if (!isTHEPartner) {
            cells.push({
                title: React.createElement(CellTitle, { text: "Tap Feed", record: usersTotals, titleKey: "total_university_tap_feed" }),
                dataIndex: 'university_tap_feed_permission',
                sorter: true,
                render: function (data, record) { return (React.createElement(ToggleCell, { columnName: "chatUniCheckbox", data: data, record: record, permissionId: data.id, permissionKey: PermissionEnum.Chat })); },
            });
        }
        var restCells = [
            {
                title: React.createElement(CellTitle, { text: "Chat", record: usersTotals, titleKey: "total_chat" }),
                dataIndex: 'chat_permission',
                sorter: true,
                render: function (data, record) { return (React.createElement(ToggleCell, { columnName: "chatCheckbox", data: data, record: record, permissionId: data.id, permissionKey: PermissionEnum.Chat })); },
            },
            {
                title: React.createElement(CellTitle, { text: "Content", record: usersTotals, titleKey: "total_content" }),
                dataIndex: 'content_permission',
                sorter: true,
                render: function (data, record) { return (React.createElement(ToggleCell, { columnName: "contentCheckbox", data: data, record: record, permissionId: data.id, permissionKey: PermissionEnum.Content })); },
            },
            {
                title: React.createElement(CellTitle, { text: "FAQ", record: usersTotals, titleKey: "total_faq" }),
                dataIndex: 'faq_permission',
                sorter: true,
                render: function (data, record) { return (React.createElement(ToggleCell, { columnName: "faqCheckbox", data: data, record: record, permissionId: data.id, permissionKey: PermissionEnum.Faq })); },
            },
            {
                title: React.createElement(Text, { className: "text-submenu-lg" }, "Link"),
                dataIndex: 'link',
                key: 'link',
                render: CopyLinkCell,
                width: 130,
            },
            {
                title: '',
                dataIndex: 'delete',
                key: 'delete',
                render: DeleteUserCell,
            },
        ];
        cells = __spreadArray(__spreadArray([], cells, true), restCells, true);
        return cells;
    }, [isTHEPartner, partnerGroups, usersTotals]);
};
