import { connect } from 'react-redux'
import React, { Component } from 'react'
import dayjs from 'dayjs'
import '../../../../../assets/js/custom/common/common'
import store from '../../../../redux/store'
import types from '../../../../redux/actions/content/ambassadorsActions'

class ExportButtons extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: new Date('1 January 2019'),
            endDate: new Date(),
        }

        this.downloadFile = this.downloadFile.bind(this)
        this.handleExportAll = this.handleExportAll.bind(this)
        this.handleExportCurrent = this.handleExportCurrent.bind(this)
    }

    async handleExportAll() {
        const onSuccess = (response) => {
            this.downloadFile(response.csvFileUrl)
        }
        store.dispatch({
            type: types.AMBASSADORS_LIST,
            payload: {},
            onSuccess: onSuccess,
        })
    }

    async handleExportCurrent() {
        let startDate = this.props.fromDate
        let endDate = this.props.toDate

        if (this.props.isFilteredByDate) {
            if (this.props.fromDate && this.props.toDate) {
                startDate = this.props.fromDate
                endDate = this.props.toDate
            } else if (this.props.fromDate && !this.props.toDate) {
                startDate = this.props.fromDate
                endDate = null
            } else if (!this.props.fromDate && this.props.toDate) {
                startDate = null
                endDate = this.props.toDate
            } else {
                startDate = null
                endDate = null
            }
        } else {
            startDate = dayjs().startOf(this.props.limitsType).toDate()
            endDate = new Date()
        }

        const onSuccess = (response) => {
            this.downloadFile(response.csvFileUrl)
        }
        store.dispatch({
            type: types.AMBASSADORS_LIST,
            payload: {
                filter: {
                    from: startDate === null ? null : dayjs(startDate).utcOffset(0).startOf('day').format(),
                    to: endDate === null ? null : dayjs(endDate).add(1, 'day').utcOffset(0).startOf('day').format(),
                },
            },
            onSuccess: onSuccess,
        })
    }

    downloadFile(file) {
        setTimeout(() => {
            const link = document.createElement('a')
            link.download = file
            link.href = file
            document.body.append(link)
            link.click()
            link.remove()
        }, 1000)
    }

    render() {
        return (
            <div className="export-buttons-container">
                <button className="el-btn" onClick={this.handleExportAll}>
                    Export all
                </button>
                <button className="el-btn" onClick={this.handleExportCurrent}>
                    Export selected
                </button>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(ExportButtons)
