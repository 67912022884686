import React from 'react'

export const ArrowTop = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.19" height="15.795" viewBox="0 0 18.19 15.795">
            <path
                className="time-arrow"
                id="triangle"
                d="M10.528 2.358a1 1 0 0 0-1.377.32l-6.1 9.794A1 1 0 0 0 3.9 14h12.19a1 1 0 0 0 .85-1.528l-6.1-9.794a1 1 0 0 0-.32-.32zm2.019-.737l6.1 9.794A3 3 0 0 1 16.095 16H3.9a3 3 0 0 1-2.547-4.585l6.1-9.795a3 3 0 0 1 5.094 0z"
                transform="translate(-.905 -.205)"
            />
        </svg>
    )
}

export const ArrowDown = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.19" height="15.795" viewBox="0 0 18.19 15.795">
            <path
                className="time-arrow"
                id="triangle"
                d="M10.528 2.358a1 1 0 0 0-1.377.32l-6.1 9.794A1 1 0 0 0 3.9 14h12.19a1 1 0 0 0 .85-1.528l-6.1-9.794a1 1 0 0 0-.32-.32zm2.019-.737l6.1 9.794A3 3 0 0 1 16.095 16H3.9a3 3 0 0 1-2.547-4.585l6.1-9.795a3 3 0 0 1 5.094 0z"
                transform="rotate(180 9.547 8)"
            />
        </svg>
    )
}

export const Calendar = ({ className, isHide }) => {
    if (isHide) return null

    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
            <path
                style={{ fill: 'rgba(38, 46, 69, 0.3)' }}
                id="calendar-alt"
                d="M9 2V1a1 1 0 0 1 2 0v1h3V1a1 1 0 0 1 2 0v1h1a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h1V1a1 1 0 0 1 2 0v1zm0 2H6v1a1 1 0 0 1-2 0V4H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-1v1a1 1 0 0 1-2 0V4h-3v1a1 1 0 0 1-2 0zM3 8h2v2H3zm0 4h2v2H3zm12 0h2v2h-2zm0-4h2v2h-2zM7 8h2v2H7zm4 0h2v2h-2zm0 4h2v2h-2zm-4 0h2v2H7z"
                className="cls-1"
            />
        </svg>
    )
}
