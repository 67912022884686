/* eslint-disable react-hooks/exhaustive-deps */
import { endOfDay, startOfDay } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchAmbasadorsRep, setAmbassadorFilters } from '../../../../../../redux/actions/content/ambassadorsActions'
import { getAmbassadorsSlice, getIsGroupAdmin } from '../../../../../../redux/selectors'
import { formatToISO, getDaysRange } from '../../../../../../common/utils/date-time-utils'
import { AmbassadorReportRowModel } from '../models'

import { useAmbassadorReportColumnsConfig } from './use-use-ambassador-report-columns-config'

const makeFromDate = (data) => formatToISO(startOfDay(data))
const makeToDate = (data) => formatToISO(endOfDay(data))

const ORDER_DIRECTION_MAP = {
    ascend: 'ASC',
    descend: 'DESC',
}

const DATES_DEFAULT_VALUE = {
    fromDate: makeFromDate(getDaysRange(30).fromDate),
    toDate: makeToDate(getDaysRange(30).toDate),
}

const SETTINGS_INITIAL_STATE = {
    sorter: {
        field: 'full_name',
        order: 'ascend',
    },
    pagination: { current: 1, pageSize: 10 },
    filter: {
        dates: [DATES_DEFAULT_VALUE.fromDate, DATES_DEFAULT_VALUE.toDate],
    },
}

const createRequestParams = ({ sorter, pagination, filter, search = '' }) => {
    const { field: orderBy, order: orderValue } = sorter
    const { current, pageSize } = pagination
    const { dates } = filter

    const order = ORDER_DIRECTION_MAP[orderValue]
    const offset = pageSize * (current - 1)

    const date = {
        fromDate: dates[0],
        toDate: dates[1],
    }

    return {
        orderBy,
        order,
        offset,
        filter: {
            ...date,
        },
        search,
    }
}

export const useAmbassadorReportTable = ({ data, onSelect, areSelectedAll, unselected, selected }) => {
    const dispatch = useDispatch()

    const { ambassadorReport, total, filters } = useSelector(getAmbassadorsSlice)
    const isGroupAdmin = useSelector(getIsGroupAdmin)

    const [gridSettings, setGridSettings] = useState(SETTINGS_INITIAL_STATE)

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [lastData, setLastData] = useState([])

    const columnsConfig = useAmbassadorReportColumnsConfig(isGroupAdmin)

    useEffect(() => {
        const params = createRequestParams(gridSettings)
        filters.fromDate && dispatch(fetchAmbasadorsRep(params))
    }, [gridSettings])

    useEffect(() => {
        filters.fromDate &&
            setGridSettings(() => ({
                ...gridSettings,
                filter: {
                    dates: [filters.fromDate, filters.toDate],
                },
                search: filters.search ? filters.search : '',
                pagination: SETTINGS_INITIAL_STATE.pagination,
            }))
    }, [filters])

    useEffect(() => {
        return () => {
            dispatch(setAmbassadorFilters({}))
        }
    }, [])

    const handleFiltersChange = useCallback((values) => {
        setGridSettings((prevState) => ({
            ...prevState,
            filter: { ...values, filterKeys: prevState.filter.filterKeys },
            pagination: SETTINGS_INITIAL_STATE.pagination,
        }))
    }, [])

    const handleTableChange = useCallback((pagination, filters, sorter) => {
        setGridSettings((prevState) => ({
            ...prevState,
            sorter,
            pagination,
        }))
    }, [])

    if (data !== lastData) {
        setLastData(data)
        const ambassadorsIds = data.map((item) => item.id)

        setSelectedRowKeys(
            areSelectedAll
                ? ambassadorsIds.filter((item) => !unselected.has(item))
                : ambassadorsIds.filter((item) => selected.has(item))
        )
    }

    const onSelectChange = (selectedRowKeysData) => {
        const addedItems = []
        const removedItems = []

        if (selectedRowKeysData.length > selectedRowKeys.length) {
            const newItems = new Set(selectedRowKeys)
            for (const item of selectedRowKeysData) {
                if (!newItems.has(item)) {
                    addedItems.push(item)
                }
            }
        } else {
            const previousItems = new Set(selectedRowKeysData)
            for (const item of selectedRowKeys) {
                if (!previousItems.has(item)) {
                    removedItems.push(item)
                }
            }
        }

        setSelectedRowKeys(selectedRowKeysData)
        onSelect(addedItems, removedItems)
    }

    const rowSelection = {
        selectedRowKeys,
        unselectAll: false,
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
            {
                key: 'all-data',
                text: 'Select All',
                onSelect: () => {
                    setSelectedRowKeys(lastData.map((item) => item.id))
                    onSelect(null, null, true)
                },
            },
            {
                key: 'no-data',
                text: 'Unselect All',
                onSelect: () => {
                    setSelectedRowKeys([])
                    onSelect(null, null, false)
                },
            },
        ],
    }

    const dataSource = useMemo(() => ambassadorReport.map((item) => AmbassadorReportRowModel(item)), [ambassadorReport])

    const pagination = useMemo(
        () => ({
            pageSize: 10,
            current: gridSettings.pagination.current,
            total: total,
            position: ['bottomRight'],
            showSizeChanger: false,
            size: 'small',
        }),
        [total, gridSettings]
    )

    return {
        columnsConfig,
        dataSource,
        gridSettings,
        pagination,
        rowSelection,
        onFiltersChange: handleFiltersChange,
        onTableChange: handleTableChange,
    }
}
