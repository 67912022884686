const NAMESPACE_PREFIX = 'LIVE_STREAMS'

export const LIVE_STREAMS = {
    FETCH: `${NAMESPACE_PREFIX}.FETCH`,
    FETCH_SUCCESS: `${NAMESPACE_PREFIX}.FETCH_SUCCESS`,
    FETCH_ERROR: `${NAMESPACE_PREFIX}.FETCH_ERROR`,
    OPEN_FORM_MODAL: `${NAMESPACE_PREFIX}.OPEN_FORM_MODAL`,
    CLOSE_FORM_MODAL: `${NAMESPACE_PREFIX}.CLOSE_FORM_MODAL`,
    CREATE: `${NAMESPACE_PREFIX}.CREATE`,
    CREATE_SUCCESS: `${NAMESPACE_PREFIX}.CREATE_SUCCESS`,
    CREATE_ERROR: `${NAMESPACE_PREFIX}.CREATE_ERROR`,
    SET_ACTIVE: `${NAMESPACE_PREFIX}.SET_ACTIVE`,
    UPDATE: `${NAMESPACE_PREFIX}.UPDATE`,
    UPDATE_SUCCESS: `${NAMESPACE_PREFIX}.UPDATE_SUCCESS`,
    UPDATE_ERROR: `${NAMESPACE_PREFIX}.UPDATE_ERROR`,
    ARCHIVE_STREAM: `${NAMESPACE_PREFIX}.ARCHIVE_STREAM`,
    FETCH_USERS: `${NAMESPACE_PREFIX}.FETCH_USERS`,
    FETCH_USERS_SUCCESS: `${NAMESPACE_PREFIX}.FETCH_USERS_SUCCESS`,
    FETCH_USERS_ERROR: `${NAMESPACE_PREFIX}.FETCH_USERS_ERROR`,
    FETCH_PROSPECTS: `${NAMESPACE_PREFIX}.FETCH_PROSPECTS`,
    FETCH_PROSPECTS_SUCCESS: `${NAMESPACE_PREFIX}.FETCH_PROSPECTS_SUCCESS`,
    FETCH_PROSPECTS_ERROR: `${NAMESPACE_PREFIX}.FETCH_PROSPECTS_ERROR`,
    OPEN_USERS_MODAL: `${NAMESPACE_PREFIX}.OPEN_USERS_MODAL`,
    CLOSE_USERS_MODAL: `${NAMESPACE_PREFIX}.CLOSE_USERS_MODAL`,
    OPEN_STREAM_TYPE_MODAL: `${NAMESPACE_PREFIX}.OPEN_STREAM_TYPE_MODAL`,
    CLOSE_STREAM_TYPE_MODAL: `${NAMESPACE_PREFIX}.CLOSE_STREAM_TYPE_MODAL`,
    OPEN_SCHEDULED_FORM_MODAL: `${NAMESPACE_PREFIX}.OPEN_SCHEDULED_FORM_MODAL`,
    CLOSE_SCHEDULED_FORM_MODAL: `${NAMESPACE_PREFIX}.CLOSE_SCHEDULED_FORM_MODAL`,
    OPEN_SPEAKERS_MODAL: `${NAMESPACE_PREFIX}.OPEN_SPEAKERS_MODAL`,
    CLOSE_SPEAKERS_MODAL: `${NAMESPACE_PREFIX}.CLOSE_SPEAKERS_MODAL`,
    OPEN_AMBASSADORS_MODAL: `${NAMESPACE_PREFIX}.OPEN_AMBASSADORS_MODAL`,
    CLOSE_AMBASSADORS_MODAL: `${NAMESPACE_PREFIX}.CLOSE_AMBASSADORS_MODAL`,
    SET_ACTIVE_SIDEBAR_TAB: `${NAMESPACE_PREFIX}.SET_ACTIVE_SIDEBAR_TAB`,
    CANCEL_STREAM_CONFIRM: `${NAMESPACE_PREFIX}.CANCEL_STREAM_CONFIRM`,
    START_STREAM_CONFIRM: `${NAMESPACE_PREFIX}.START_STREAM_CONFIRM`,
    CLOSE_CHAT: `${NAMESPACE_PREFIX}.CLOSE_CHAT`,
    STOP_STREAM_CONFIRM: `${NAMESPACE_PREFIX}.STOP_STREAM_CONFIRM`,
    FETCH_LIVE_STREAM_DATA: `${NAMESPACE_PREFIX}.FETCH_LIVE_STREAM_DATA`,
    FETCH_LIVE_STREAM_DATA_SUCCESS: `${NAMESPACE_PREFIX}.FETCH_LIVE_STREAM_DATA_SUCCESS`,
    FETCH_LIVE_STREAM_DATA_ERROR: `${NAMESPACE_PREFIX}.FETCH_LIVE_STREAM_DATA_ERROR`,
    OPEN_PUBLISH_CONTROL_MODAL: `${NAMESPACE_PREFIX}.OPEN_PUBLISH_CONTROL_MODAL`,
    CLOSE_PUBLISH_CONTROL_MODAL: `${NAMESPACE_PREFIX}.CLOSE_PUBLISH_CONTROL_MODAL`,
    JOIN_STREAM_LOBBY: `${NAMESPACE_PREFIX}.JOIN_STREAM_LOBBY`,
    OPEN_VIDEO_RE_UPLOAD_MODAL: `${NAMESPACE_PREFIX}.OPEN_VIDEO_RE_UPLOAD_MODAL`,
    CLOSE_VIDEO_RE_UPLOAD_MODAL: `${NAMESPACE_PREFIX}.CLOSE_VIDEO_RE_UPLOAD_MODAL`,
    UPDATE_STREAM_STATUS: `${NAMESPACE_PREFIX}.UPDATE_STREAM_STATUS`,
    VIDEO_RE_UPLOAD_SUCCESS: `${NAMESPACE_PREFIX}.VIDEO_RE_UPLOAD_SUCCESS`,
    SHARE_STREAM_RECORD: `${NAMESPACE_PREFIX}.SHARE_STREAM_RECORD`,
    SHARE_STREAM_RECORD_SUCCESS: `${NAMESPACE_PREFIX}.SHARE_STREAM_RECORD_SUCCESS`,
}
