import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as UserIcon } from '../../../../assets/img/svg/user.svg'
import { checkCanSeeLiveStreams, checkCanSeeGroupChats } from '../../../common/utils/user-account-helpers'

const TAPTitles = (props) => {
    const {
        page_title: pageTitle,
        page_desc: pageDesc,
        display_search_bar: displaySearchBar,
        social_proof: socialProof,
        show_number_likes_views: showNumberLikesViews,
        group_chat_title: groupChatTitle,
        profile_title: profileTitle,
        content_title: contentTitle,
        faq_title: faqTitle,
        display_tap_logo: displayTapLogo,
        live_stream_title: liveStreamTitle,
        handleChange,
        handleSubmit,
        tapTitles,
        isGroupAdmin,
        universityName,
        account,
    } = props

    const {
        account_info: { university },
        currentGroupUniversityId,
    } = account

    const canSeeLiveStreams = (isGroupAdmin && !currentGroupUniversityId) || checkCanSeeLiveStreams(university)
    const canSeeGroupChats = (isGroupAdmin && !currentGroupUniversityId) || checkCanSeeGroupChats(university)

    return (
        <>
            <div className="settings-intro">
                <p>When creating your TAP Feed, add these configurations in an options section:</p>
            </div>

            <div className="settings-wrapper">
                <div>
                    <div className="settings-item tap-page-embed">
                        <h4 className="settings-name">Page Title</h4>
                        <input
                            className="promotion-input promotion-input--settings"
                            placeholder={`Ask us about what life at ${universityName} is really like`}
                            name="page_title"
                            value={pageTitle}
                            onChange={(e) => handleChange(e, 'titles')}
                            type="text"
                        />
                    </div>

                    <div className="settings-item tap-page-embed">
                        <h4 className="settings-name">Page Description</h4>
                        <textarea
                            className="promotion-input promotion-input--settings"
                            placeholder={`Do you want to speak to someone about their experiences at ${universityName}? Start a chat with our friendly ambassadors now!`}
                            name="page_desc"
                            value={pageDesc}
                            onChange={(e) => handleChange(e, 'titles')}
                        />
                    </div>

                    <div className="settings-item tap-page-embed">
                        <h4 className="settings-name">Include text search bar?</h4>
                        <label className="slideOne js-holder">
                            <input
                                type="checkbox"
                                name="display_search_bar"
                                checked={displaySearchBar}
                                onChange={(e) => handleChange(e, 'titles')}
                            />
                            <span className="slideOne-icon"></span>
                        </label>
                    </div>
                    <div className="settings-item tap-page-embed">
                        <h4 className="settings-name">Include TAP logo?</h4>
                        <label className="slideOne js-holder">
                            <input
                                type="checkbox"
                                name="display_tap_logo"
                                checked={displayTapLogo}
                                onChange={(e) => handleChange(e, 'titles')}
                            />
                            <span className="slideOne-icon"></span>
                        </label>
                    </div>

                    <div className="settings-item tap-page-embed">
                        <h4 className="settings-name">Show number of likes and views on the tap feed?</h4>
                        <label className="slideOne social-slide js-holder">
                            <input
                                type="checkbox"
                                name="show_number_likes_views"
                                checked={showNumberLikesViews}
                                onChange={(e) => handleChange(e, 'titles')}
                            />
                            <span className="slideOne-icon"></span>
                        </label>
                    </div>

                    {!isGroupAdmin ? (
                        <div className="settings-item tap-page-embed">
                            <h4 className="settings-name">Include social proof?</h4>
                            <label className="slideOne social-slide js-holder">
                                <input
                                    type="checkbox"
                                    name="social_proof"
                                    checked={socialProof}
                                    onChange={(e) => handleChange(e, 'titles')}
                                />
                                <span className="slideOne-icon"></span>
                            </label>
                            {/* eslint-disable-next-line no-prototype-builtins */}
                            {tapTitles.hasOwnProperty('socialProof') &&
                                (tapTitles.socialProof.social_proof_data >= 200 ? (
                                    <>
                                        <p className="social-proof-description">
                                            To provide maximum impact social proof text will only be displayed when 200
                                            new users have viewed your TAP Feed. Check out our{' '}
                                            <a
                                                rel="noopener noreferrer"
                                                href="https://knowledge.theambassadorplatform.com/what-is-social-proof"
                                                target="_blank"
                                                className="social-article-link"
                                            >
                                                help article
                                            </a>{' '}
                                            for more information on how this feature works.{' '}
                                        </p>
                                        <p className="preview-social-title">Preview:</p>
                                        {tapTitles.socialProof.social_proof_data < 500 ? (
                                            <p className="join-users-proof-text">
                                                Join hundreds who have gained first hand insights into life at{' '}
                                                {tapTitles.socialProof.university_name}
                                            </p>
                                        ) : (
                                            <p className="join-users-proof-text">
                                                Join{' '}
                                                <span className="social-proof-amount">
                                                    <UserIcon /> {tapTitles.socialProof.social_proof_data} people
                                                </span>{' '}
                                                who have gained first hand insights into life at{' '}
                                                {tapTitles.socialProof.university_name}
                                            </p>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <p className="social-proof-description">
                                            Less than 200 new users have viewed your TAP Feed. You can activate this
                                            feature now and the text will automatically display after more than 200 new
                                            users have viewed your TAP Feed.
                                        </p>
                                        <p className="preview-social-title">Preview:</p>
                                        <p className="join-users-proof-text">
                                            Join hundreds who have gained first hand insights into life at{' '}
                                            {tapTitles.socialProof.university_name}
                                        </p>
                                    </>
                                ))}
                        </div>
                    ) : null}
                </div>

                <div>
                    {canSeeGroupChats && (
                        <div className="settings-item tap-page-embed">
                            <h4 className="settings-name">Group Chat Section</h4>
                            <input
                                className="promotion-input promotion-input--settings"
                                placeholder="Speak with our ambassadors and others like you"
                                name="group_chat_title"
                                value={groupChatTitle}
                                onChange={(e) => handleChange(e, 'titles')}
                                type="text"
                            />
                        </div>
                    )}

                    <div className="settings-item tap-page-embed">
                        <h4 className="settings-name">Profile Section</h4>
                        <input
                            className="promotion-input promotion-input--settings"
                            placeholder="Chat with our ambassadors"
                            name="profile_title"
                            value={profileTitle}
                            onChange={(e) => handleChange(e, 'titles')}
                            type="text"
                        />
                    </div>

                    <div className="settings-item tap-page-embed">
                        <h4 className="settings-name">Content Section</h4>
                        <input
                            className="promotion-input promotion-input--settings"
                            placeholder={`See what life at ${universityName} is really like`}
                            name="content_title"
                            value={contentTitle}
                            onChange={(e) => handleChange(e, 'titles')}
                            type="text"
                        />
                    </div>

                    <div className="settings-item tap-page-embed">
                        <h4 className="settings-name">FAQ Section</h4>
                        <input
                            className="promotion-input promotion-input--settings"
                            placeholder="Check out what our ambassadors say"
                            name="faq_title"
                            value={faqTitle}
                            onChange={(e) => handleChange(e, 'titles')}
                            type="text"
                        />
                    </div>
                    {canSeeLiveStreams && (
                        <div className="settings-item tap-page-embed">
                            <h4 className="settings-name">Streams</h4>
                            <input
                                className="promotion-input promotion-input--settings"
                                placeholder="Check out Streams"
                                name="live_stream_title"
                                value={liveStreamTitle}
                                onChange={(e) => handleChange(e, 'titles')}
                                type="text"
                            />
                        </div>
                    )}
                </div>
                <button type="submit" onClick={() => handleSubmit('titles')} className="settings-submit-btn">
                    Save configurations
                </button>
            </div>
        </>
    )
}

TAPTitles.propTypes = {
    page_title: PropTypes.string,
    page_desc: PropTypes.string,
    display_search_bar: PropTypes.bool,
    social_proof: PropTypes.bool,
    group_chat_title: PropTypes.string,
    profile_title: PropTypes.string,
    content_title: PropTypes.string,
    faq_title: PropTypes.string,
    display_tap_logo: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
}

export default TAPTitles
