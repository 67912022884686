import { SSO } from '../../actions/settings/sso/sso-action-types'
import SsoProviderModel from '../../../models/SsoProviderModel'
import SsoActiveProviderModel from '../../../models/SsoActiveProviderModel'

const initialState = {
    items: [],
    activeProvider: SsoActiveProviderModel(),
    isLoading: true,
}

export default function (state = initialState, action) {
    const { type, payload = {} } = action

    switch (type) {
        case SSO.GET_PROVIDERS: {
            return {
                ...state,
                isLoading: payload.firstLoad || false,
            }
        }

        case SSO.GET_PROVIDERS_SUCCESS: {
            const newItems = []
            for (const provider of payload.items) {
                if (provider.key === 'azure' || provider.key === 'open_id' || provider.key === 'saml') {
                    newItems.push(SsoProviderModel(provider))
                }
            }
            return {
                ...state,
                items: newItems,
                activeProvider: SsoActiveProviderModel(payload.activeProvider),
                isLoading: false,
            }
        }

        case SSO.GET_PROVIDERS_FAILED: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case SSO.RESET_UNIVERSITY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                activeProvider: SsoActiveProviderModel(),
            }
        }
        default: {
            return state
        }
    }
}
