import React from 'react'
import { connect } from 'react-redux'
import store from '../../../../redux/store'
import BlockReasonPopup from '../../../../containers/chat/messaging/components/popups/BlockReasonPopup'
import { UNBLOCK_CHAT_MEMBER, SET_REPORT_REASON } from '../../../../redux/actions/chat/messagingActions'
import { OPEN_ACTION_MODAL } from '../../../../redux/actions/components/componentsActions'

const BlockingProspectControl = (props) => {
    const { dialogId, userId, isBlocked, isHidden, message, blockButtonClass, unblockButtonClass, tableDataProps } =
        props

    const unblockInChat = () => {
        store.dispatch({
            type: UNBLOCK_CHAT_MEMBER,
            payload: {
                dialogId: dialogId,
                userId: userId,
                type_chat: 6,
            },
        })
        store.dispatch({
            type: SET_REPORT_REASON,
            payload: {
                reportedData: null,
                dialogId: null,
            },
        })
    }

    const blockProspectChat = () => {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <BlockReasonPopup
                        userId={userId}
                        chatType={6}
                        dialogId={dialogId}
                        tableDataProps={tableDataProps}
                    />
                ),
                className: 'block-prospect-popup',
            },
        })
    }

    return (
        <>
            {!isBlocked ? (
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                    {isHidden ? (
                        <div className={`unblock-button ${unblockButtonClass || ''}`} onClick={unblockInChat}>
                            Unblock in this chat
                        </div>
                    ) : null}
                    {message.user && message.user.role_key === 'prospect' && isHidden ? (
                        <div className={`block-button ${blockButtonClass || ''}`} onClick={blockProspectChat}>
                            Block this prospect
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    )
}

export default connect((state) => {
    return {
        chats: state.chats,
        account: state.account,
        currentChat: state.currentChat,
    }
})(BlockingProspectControl)
