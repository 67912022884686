import { LoadingOutlined } from '@ant-design/icons'
import { Col, Form, Input, Row } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import { roles } from '../../../../../../helpers/helpers'
import { AMBASSADORS_INFO } from '../../../../../../redux/actions/content/messagingActions'

const ROLES_NAMES_MAP = {
    [roles.admin]: 'admins',
    [roles.student]: 'ambassadors',
}

const joinRolesNames = (roles) => roles.map((role) => ROLES_NAMES_MAP[role]).join(', ')

const UsersSearchField = ({ roles, label, showRoles, isLoading }) => {
    const dispatch = useDispatch()

    const labelNode = useMemo(() => {
        if (!label) return

        const rolesString = joinRolesNames(roles)

        return (
            <>
                <Row gutter={8}>
                    <Col>
                        {label} {showRoles && `(${rolesString})`}
                    </Col>
                    <Col>{isLoading && <LoadingOutlined />}</Col>
                </Row>
            </>
        )
    }, [label, roles, showRoles, isLoading])

    const debounced = useDebouncedCallback(({ target: { value } }) => {
        const searchString = value.trim()

        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    keyword: searchString,
                    permission: 'chat',
                },
                roles,
            },
        })
    }, 300)

    const handleSearchChange = useCallback(
        (event) => {
            event.persist()
            return debounced(event)
        },
        [debounced]
    )

    return (
        <Form.Item label={labelNode} style={{ marginBottom: 0 }}>
            <Input placeholder="Search by keyword" onChange={handleSearchChange} />
        </Form.Item>
    )
}

export default UsersSearchField
