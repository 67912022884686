export var clientPriorities = [
    'Recruitment',
    'Conversion',
    'Diversity & Inclusion',
    'Post-enrolment support',
    'Student services',
    'Study abroad',
    'Parent advocacy',
    'Careers services',
    'Ambassador management',
    'Other',
];
