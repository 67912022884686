/* eslint-disable camelcase */
export default (ambassador) => {
    const {
        id,
        first_name,
        last_name,
        avatar,
        daily_average_usage,
        median_response_time,
        average_feedback,
        average_chat_messages,
        total_posts,
        total_faq_answers,
        total_career_points,
        university,
    } = ambassador

    return {
        id,
        first_name,
        last_name,
        avatar,
        daily_average_usage,
        median_response_time,
        average_feedback,
        average_chat_messages,
        total_posts,
        total_faq_answers,
        total_career_points,
        full_name: `${first_name} ${last_name}`,
        university,
        university_name: university && university.name ? university.name : '',
    }
}
