import { Input } from 'antd';
import React from 'react';
import { Button } from 'src/app/components/base/Button';
import { concatClsx } from 'src/app/helpers/classNames';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { usePusher } from 'src/app/hooks/usePusher';
import { UPLOAD_FILE_INFO_SUCCESS, transcodeVideo, uploadImage, uploadVideo, } from 'src/app/redux/actions/content/postsActions';
import { openNotificationModal } from 'src/app/redux/actions/notification-modal/notification-modal-actions';
import { getAccountInfo } from 'src/app/redux/selectors';
import Sprite from 'src/assets/img/symbol/sprite.svg';
export var UploadContent = function (_a) {
    var file = _a.file, onDone = _a.onDone;
    var src = URL.createObjectURL(file);
    var ref = React.useRef(null);
    var _b = React.useState(false), loading = _b[0], setLoading = _b[1];
    var _c = React.useState(null), error = _c[0], setError = _c[1];
    var type = file.type.split('/')[0];
    var showInput = type === 'video' || type === 'image';
    var accountInfo = useAppSelector(getAccountInfo);
    var dispatch = useAppDispatch();
    // const [fileData, setFileData] = React.useState<{
    //     caption: string
    //     jobId: number
    //     fileData: FileData
    // } | null>(null)
    // Never fires
    usePusher("file:transcoding:".concat(accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university_id, ":").concat(accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.id), function (data) {
        console.log('usePusher', data);
    });
    var handleSendFile = function () {
        var _a, _b;
        setError(null);
        setLoading(true);
        if (!file) {
            return;
        }
        var caption = ((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.input) === null || _b === void 0 ? void 0 : _b.value) || '';
        var fileType = file.type;
        if (!fileType) {
            if (file.name.indexOf('.vob')) {
                fileType = 'video/vob';
            }
            else if (file.name.indexOf('.flv')) {
                fileType = 'video/flv';
            }
            else if (file.name.indexOf('.rmvb')) {
                fileType = 'video/rmvb';
            }
        }
        if (fileType.startsWith('image/')) {
            var reader_1 = new FileReader();
            reader_1.readAsDataURL(file);
            reader_1.addEventListener('load', function () {
                if (reader_1.result != null && typeof reader_1.result === 'string') {
                    dispatch(uploadImage({
                        type: 'image',
                        base64: reader_1.result.slice(reader_1.result.indexOf(',') + 1),
                        base64_original: reader_1.result.slice(reader_1.result.indexOf(',') + 1),
                        caption: caption,
                        onReady: function () {
                            onDone();
                        },
                        onError: function () {
                            setError('File upload failed');
                            setLoading(false);
                        },
                    }));
                }
            });
        }
        else if (file.type.includes('video/')) {
            var formData = new FormData();
            formData.append('file', file);
            dispatch(transcodeVideo({
                formData: formData,
                callback: function (jobId, fileData) {
                    // setFileData({
                    //     caption: caption,
                    //     jobId,
                    //     fileData,
                    // })
                    dispatch(uploadVideo({
                        file_id: fileData.id,
                        caption: caption,
                        // jobId is null
                        currentJobId: jobId,
                        callback: function () {
                            onDone();
                            openNotificationModal(UPLOAD_FILE_INFO_SUCCESS);
                        },
                    }));
                },
            }));
        }
    };
    return (React.createElement("div", { className: "px-4 pb-4" },
        React.createElement("h3", { className: "text-lg py-4 pr-10" }, file.name || 'Preview'),
        React.createElement("div", { className: "pb-4" }, type === 'image' ? (React.createElement("img", { alt: "preview", className: "w-full", src: src })) : type === 'video' ? (React.createElement("video", { controls: true, controlsList: "nodownload", className: "w-full", src: src })) : (React.createElement("div", { className: "h-16 flex items-center justify-center text-xl" }, "Unsupported file type"))),
        error && React.createElement("div", { className: "text-red-500 py-4" }, error),
        showInput && (React.createElement("div", { className: "flex gap-4 " },
            React.createElement(Input, { type: "text", className: "flex-1 rounded-full", placeholder: "Caption\u2026", maxLength: 200, ref: ref, size: "large" }),
            React.createElement(Button, { type: "primary", onClick: handleSendFile, loading: loading, shape: "circle", size: "large" },
                React.createElement("svg", { className: concatClsx('icon icon-send', loading ? '!hidden' : undefined) },
                    React.createElement("use", { xmlnsXlink: "http://www.w3.org/1999/xlink", xlinkHref: Sprite + '#send' })))))));
};
