import React from 'react'

import { ChatDialogTab } from './components'
import { useChat } from './use-chat'
import { CHAT_TYPE_KEY } from '../../../../../../common/constants'

const StreamChat = ({ activeEvent }) => {
    useChat(activeEvent)
    return <ChatDialogTab activeEvent={activeEvent} type={CHAT_TYPE_KEY.LIVE_STREAM_CHAT} />
}

export default StreamChat
