/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { connect } from 'react-redux'

import { ReactComponent as Close } from '../../assets/svg/icons/close.svg'
import Tooltip from './Tooltip'

// import { getFilterOptionsCount } from '../redux/actions/settings/filtersActions'
import store from '../redux/store'
import { GET_GLOBAL_LIST_TAGS } from '../redux/actions/tags/tagsActions'

const PRESS_ENTER = 13
const PRESS_BACKSPACE = 8

const TAPFilter = (props) => {
    const {
        filterNum,
        possibleFiltersNum = 3,
        activeFiltersNum,
        addFilter,
        removeFilter,
        setFilter,
        initialData,
        isInitial,
        //  getFilterOptionsCount,
        tapFilters,
        globalTags,
        setupWizard,
    } = props

    const {
        title: initialTitle = '',
        type: initialType = null,
        fixed_type: initialOption = null,
        custom_fields: initialTerms = [],
        filterIndex,
        id,
    } = initialData

    const wrapperRef1 = useRef(null)
    const wrapperRef2 = useRef(null)

    const [title, setTitle] = useState(initialTitle)
    const [options, setOptions] = useState({
        [`type-${filterIndex}`]: initialType,
        [`option-${filterIndex}`]: initialOption,
    })
    const [terms, setTerms] = useState({ input: '', values: initialTerms })
    const [isTouched, setIsTouched] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setTitle(initialTitle)
        setOptions({
            [`type-${filterIndex}`]: initialType,
            [`option-${filterIndex}`]: initialOption,
        })
        setTerms({ input: '', values: initialTerms })

        /* if (initialOption) {
          getFilterOptionsCount(initialOption)
        } */
    }, [id])

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    })

    const handleClickOutside = (event) => {
        if (
            wrapperRef1.current &&
            !wrapperRef1.current.contains(event.target) &&
            wrapperRef2.current &&
            !wrapperRef2.current.contains(event.target)
        ) {
            setIsOpen(false)
        }
    }

    // const getOptionName = () => {
    //     switch (options[`option-${filterIndex}`]) {
    //         case '_courses_types':
    //             return 'courses'
    //         case '_countries':
    //             return 'countries'
    //         case 'account_type':
    //             return 'account type'
    //         case 'group_universities':
    //             return 'group universities'
    //     }
    // }

    const getOptionCount = () => {
        let optionCount = null
        tapFilters.available_filters.map((filter) => {
            if (options[`option-${filterIndex}`] == filter.key) optionCount = filter.count
        })

        return optionCount
    }
    const setFilterFields = (isFieldsFilled, options) => {
        if (setupWizard) {
            setFilter(
                {
                    isAllFilled: isFieldsFilled,
                    ...options,
                },
                filterIndex
            )
        } else {
            setFilter((state) => [
                ...state.map((item) =>
                    item.filterIndex === filterIndex
                        ? {
                              ...item,
                              isAllFilled: isFieldsFilled,
                              ...options,
                          }
                        : item
                ),
            ])
        }
    }

    const checkFilterFields = () =>
        title.length > 0 &&
        ((options[`type-${filterIndex}`] === 'fixed' && !!options[`option-${filterIndex}`]) ||
            (options[`type-${filterIndex}`] === 'custom' && terms.values && terms.values.length > 0))

    const handleChange = ({ target }, title = '') => {
        setOptions((state) => {
            const { name, value } = target
            if (name === `type-${filterIndex}`) {
                setFilterFields(false, { type: value })
                if (value === 'custom') {
                    return {
                        [name]: value,
                        [`option-${filterIndex}`]: null,
                    }
                }
            }

            if (name === `option-${filterIndex}`) {
                setTitle(title)
                setFilterFields(true, { title, fixed_type: value })
                if (!tapFilters.options[value]) {
                    tapFilters.options[value] = getOptionCount()
                }
            }

            return {
                ...state,
                [name]: value,
            }
        })
    }

    const handleTitleChange = ({ target }) => {
        setTitle(target.value)

        if (
            (options[`type-${filterIndex}`] === 'fixed' && !!options[`option-${filterIndex}`]) || // check title only if all other fields in filled to prevent excessive updates
            (options[`type-${filterIndex}`] === 'custom' && terms.values && terms.values.length > 0)
        ) {
            setFilterFields(target.value.trim().length > 0, { title: target.value.trim() })
        }

        title.trim().length > 0 && setIsTouched(false)
    }

    const handleScroll = (obj) => {
        const element = document.querySelector('#globalTags')
        if (
            element.scrollHeight - element.scrollTop === element.clientHeight &&
            globalTags.total > globalTags.offset + globalTags.limit
        ) {
            store.dispatch({
                type: GET_GLOBAL_LIST_TAGS,
                payload: {
                    limit: globalTags.limit,
                    offset: globalTags.offset + globalTags.limit,
                    searchName: terms.input,
                },
            })
        }
    }

    const handleTermChange = ({ target }) => {
        if (target.value.length <= 45) {
            setTerms((state) => ({ ...state, input: target.value }))
            const afterFinished = () => {
                setIsOpen(true)
            }
            store.dispatch({
                type: GET_GLOBAL_LIST_TAGS,
                payload: {
                    limit: globalTags.limit,
                    offset: 0,
                    searchName: target.value,
                    afterFinished: afterFinished,
                },
            })
        }
    }

    const handleKeyUp = (e) => {
        const key = e.keyCode

        if (key === PRESS_ENTER) {
            addNewTag(e.target.value)
        }
    }

    const handleKeyDown = (e) => {
        const key = e.keyCode
        if (key === PRESS_BACKSPACE && !terms.input) {
            editPreviousTag()
        }
    }

    const addNewTag = (data) => {
        const term = data
            .trim()
            .replace(/(^,)|(,$)/g, '')
            .trim()

        if (!term) {
            return
        }

        setTerms((state) => ({ ...state, input: '', values: state.values ? [...state.values, term] : [term] }))
        setIsOpen(false)
        if (title.length > 0)
            setFilterFields(true, { custom_fields: terms.values ? [...terms.values, term] : [term], title })
    }

    const editPreviousTag = () => {
        const values = terms.values
        const term = values.pop()
        setTerms((state) => ({ ...state, input: term, values }))
        setIsOpen(true)
        if (!this.props.account.account_info.isGroupAdmin) {
            // remove later when api will be finished
            store.dispatch({
                type: GET_GLOBAL_LIST_TAGS,
                payload: {
                    limit: globalTags.limit,
                    offset: 0,
                    searchName: term,
                },
            })
        }
    }

    const handleRemoveTerm = (filterNum) => {
        setFilterFields(!!(terms.values.length - 1), { custom_fields: terms.values.filter((_, i) => i !== filterNum) })
        setTerms((state) => ({
            ...state,
            values: state.values.filter((_, i) => i !== filterNum),
        }))
    }

    const handleBlurInput = () => {
        setIsTouched(true)

        setFilterFields(checkFilterFields())
    }

    const isAllFilled = checkFilterFields()

    const availableFilters = tapFilters.available_filters.map((filter, id) => {
        let titleName = filter.name
        if (filter.key === 'account_type') {
            titleName = 'Profile Type'
        }
        return (
            <label className="filter__body__option-title" key={id}>
                <input
                    type="radio"
                    className="filter__body__option"
                    value={filter.key}
                    name={`option-${filterIndex}`}
                    onChange={(e) => handleChange(e, titleName)}
                    checked={filter.key === options[`option-${filterIndex}`]}
                />
                {titleName}
            </label>
        )
    })

    const currentTagsListNames = []
    if (terms.values) {
        for (const elem of terms.values) {
            currentTagsListNames.push(elem)
        }
    }

    let name = ''
    switch (options[`option-${filterIndex}`]) {
        case 'group_universities':
            name = 'Available institutions will show in the drop down'
            break
        case '_countries':
            name = 'Available countries will show in the drop down'
            break
        case '_subject':
            name = 'Available courses will show in the drop down'
            break
        case 'account_type':
            name = 'Five profile types will show in the drop down: Student, Staff, Alumni, Advisor, Parent'
            break
    }

    return (
        <div className={cn('filter', { 'filter--third': filterNum === 2 })}>
            <div className="filter__header">
                <span className="filter__header__title">Filter {filterNum + 1}</span>
                <span className="filter__header__counter">
                    ({filterNum + 1} of {possibleFiltersNum})
                </span>
                {!isInitial && (
                    <Close
                        onClick={() => removeFilter(filterIndex)}
                        className="icon icon-close icon-close--settings"
                        data-automation-id={`submenu-content-shedule-${filterNum + 1}`}
                    />
                )}
            </div>
            <div className="filter__body">
                <div className="settings-item tap-page-embed">
                    <h4 className="settings-name">Title</h4>
                    <input
                        onBlur={handleBlurInput}
                        className={cn('promotion-input promotion-input--settings', {
                            'promotion-input--error': title.length === 0 && isTouched,
                        })}
                        name="page_title"
                        value={title}
                        onChange={handleTitleChange}
                        type="text"
                    />
                    {title.length === 0 && isTouched && (
                        <span className="promotion-input--error-message">Enter a title!</span>
                    )}
                </div>

                <div className="settings-item tap-page-embed">
                    <h4 className="settings-name">Type</h4>
                    <div className="filter__body__option-wrapper">
                        <Tooltip description="A selection of fixed categories">
                            <label className="filter__body__option-title">
                                <input
                                    type="radio"
                                    className="filter__body__option"
                                    value="fixed"
                                    name={`type-${filterIndex}`}
                                    onChange={handleChange}
                                    checked={options[`type-${filterIndex}`] === 'fixed'}
                                />
                                Default Options
                            </label>
                        </Tooltip>
                        <Tooltip description="Add options using tags and search filters">
                            <label className="filter__body__option-title">
                                <input
                                    type="radio"
                                    className="filter__body__option"
                                    value="custom"
                                    name={`type-${filterIndex}`}
                                    onChange={handleChange}
                                    checked={options[`type-${filterIndex}`] === 'custom'}
                                />
                                Custom Options
                            </label>
                        </Tooltip>
                    </div>
                </div>

                {options[`type-${filterIndex}`] && options[`type-${filterIndex}`] === 'fixed' && (
                    <div className="settings-item tap-page-embed">
                        <h4 className="settings-name">Options</h4>
                        <div className="filter__body__option-wrapper">{availableFilters}</div>
                        {options[`option-${filterIndex}`] && <span className="filter__body__option-info">{name}</span>}
                    </div>
                )}

                {options[`type-${filterIndex}`] && options[`type-${filterIndex}`] === 'custom' && (
                    <div className="filter__body__terms" ref={wrapperRef1}>
                        <ul className="filter__body__terms-wrapper">
                            {terms.values &&
                                terms.values.length > 0 &&
                                terms.values.map((terms, i) => (
                                    <li key={terms + i} className="filter__body__terms__term">
                                        {terms}
                                        <Close
                                            onClick={() => handleRemoveTerm(i)}
                                            className="icon icon-close icon-close--term"
                                        />
                                    </li>
                                ))}
                            <input
                                type="text"
                                placeholder="Add a search filter.."
                                value={terms.input}
                                onChange={handleTermChange}
                                className="filter__body__terms__input"
                                onKeyUp={handleKeyUp}
                                onKeyDown={handleKeyDown}
                            />
                        </ul>
                    </div>
                )}
                <div className=" position-relative" ref={wrapperRef2}>
                    {isOpen && globalTags && globalTags.items && globalTags.items.length > 0 && (
                        <div
                            className="dropdown-tags dropdown-tags-main dropdown-tags-filter w-100"
                            onScroll={handleScroll}
                        >
                            <div id="globalTags" className="dropdown-tags-menu">
                                {globalTags &&
                                    globalTags.items &&
                                    globalTags.items.map((tag, index) => {
                                        if (!currentTagsListNames.includes(tag.name)) {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={() => addNewTag(tag.name)}
                                                    className="dropdown-tags-item"
                                                >
                                                    {tag.name}
                                                </div>
                                            )
                                        }
                                    })}
                            </div>
                        </div>
                    )}
                </div>

                {activeFiltersNum < 3 && (activeFiltersNum !== 2 || filterNum !== 0) && (
                    <Tooltip description="Complete all the fields!" disabled={!isAllFilled}>
                        <button
                            className={cn('filter__add-btn', { 'filter__add-btn--disabled': !isAllFilled })}
                            onClick={title.length > 0 ? addFilter : () => setIsTouched(true)}
                        >
                            <Close
                                className={cn('filter__add-btn__icon', {
                                    'filter__add-btn__icon--disabled': !isAllFilled,
                                })}
                            />
                            Add another filter
                        </button>
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

TAPFilter.propTypes = {
    filterNum: PropTypes.number.isRequired,
    activeFiltersNum: PropTypes.number.isRequired,
    possibleFiltersNum: PropTypes.number,
    removeFilter: PropTypes.func.isRequired,
}

export default connect((state) => {
    return {
        account: state.account,
        globalTags: state.globalTags,
    }
})(TAPFilter)
// }, { getFilterOptionsCount })(TAPFilter)
