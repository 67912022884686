import { UserOutlined } from '@ant-design/icons'
import { Form, Row, Col, Typography, Button } from 'antd'
import React, { useCallback } from 'react'

const { Text } = Typography
const SelectedSpeakersList = () => {
    const handleDeleteClick = useCallback(
        (name, remove) => () => {
            remove(name)
        },
        []
    )

    return (
        <>
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                    <Text underline>Invited ({getFieldValue('externalSpeakerEmailsExisting').length})</Text>
                )}
            </Form.Item>

            <Form.List name="externalSpeakerEmailsExisting">
                {(fields, { remove }) => {
                    return fields.map(({ key, name, fieldKey, ...restField }) => {
                        return (
                            <div key={key}>
                                <Form.Item
                                    {...restField}
                                    // name={[name, 'email']}
                                    // fieldKey={[fieldKey, 'email']}
                                    noStyle
                                    shouldUpdate
                                >
                                    {({ getFieldValue }) => {
                                        return (
                                            <div style={{ padding: '5px 0 5px 20px' }}>
                                                <Row justify="space-between">
                                                    <Col>
                                                        <UserOutlined />
                                                    </Col>
                                                    <Col flex="1">
                                                        {getFieldValue([
                                                            'externalSpeakerEmailsExisting',
                                                            name,
                                                            'email',
                                                        ])}
                                                    </Col>
                                                    <Col>
                                                        <Button type="link" onClick={handleDeleteClick(name, remove)}>
                                                            Remove
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    }}
                                </Form.Item>
                            </div>
                        )
                    })
                }}
            </Form.List>
        </>
    )
}

export default SelectedSpeakersList
