import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Typography, Row, Col, Button, List, ConfigProvider } from 'antd'
import { slice } from 'ramda'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LIVE_STREAM_STATUS } from '../../../../../../../../../../common/constants'
import {
    openAmbassadorsModal,
    openSpeakersModal,
} from '../../../../../../../../../../redux/actions/live-streams/live-streams-actions'
import { getOnlineUsers, getAccountInfo } from '../../../../../../../../../../redux/selectors'
import { OPEN_ACTION_MODAL } from '../../../../../../../../../../redux/actions/components/componentsActions'

import { ParticipantsListItem } from '../index'

const { Text } = Typography

const renderEmpty = () => <></>

const ParticipantsList = ({ activeEvent, participantsType, title }) => {
    const dispatch = useDispatch()
    const accountInfo = useSelector(getAccountInfo)
    const onlineUsers = useSelector(getOnlineUsers)

    const [viewMore, setViewMore] = useState(false)

    const { status } = activeEvent

    const canManageParticipants = useMemo(
        () => status === LIVE_STREAM_STATUS.UPCOMING || status === LIVE_STREAM_STATUS.IN_PROGRESS,
        [status]
    )

    const isProspectsList = useMemo(() => participantsType === 'prospects', [participantsType])

    const users = useMemo(() => activeEvent[participantsType], [activeEvent, participantsType])

    const itemsCount = useMemo(() => users.length, [users])
    const listDataSource = useMemo(() => {
        return viewMore ? users : slice(0, 3, users)
    }, [viewMore, users])

    const handleManageClick = useCallback(() => {
        if (participantsType === 'speakers') {
            dispatch(openSpeakersModal(activeEvent))
        }
        if (participantsType === 'ambassadors') {
            dispatch(openAmbassadorsModal(activeEvent))
        }
    }, [dispatch, participantsType, activeEvent])

    const copyInvitationLink = useCallback(
        (invitationLink, email) => {
            const tempInput = document.createElement('input')
            tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px'
            tempInput.value = invitationLink
            document.body.append(tempInput)
            tempInput.select()
            document.execCommand('copy')
            tempInput.remove()
            dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: `Invitation link for ${email} was copied to clipboard`,
                },
            })
        },
        [dispatch]
    )

    const renderItem = useCallback(
        (user) => {
            const isOnline = onlineUsers.includes(user.id)

            return (
                <List.Item key={user.id}>
                    <ParticipantsListItem
                        user={user}
                        accountInfo={accountInfo}
                        isOnline={isOnline}
                        copyInvitationLink={copyInvitationLink}
                        canManageParticipants={canManageParticipants}
                    />
                </List.Item>
            )
        },
        [accountInfo, onlineUsers, copyInvitationLink, canManageParticipants]
    )

    const handleViewMoreClick = useCallback(() => {
        setViewMore((prevState) => !prevState)
    }, [])

    const viewMoreButton = useMemo(() => {
        if (itemsCount < 4) return <></>

        const buttonText = viewMore ? 'Show less' : 'Show more'
        const buttonIcon = viewMore ? <ArrowUpOutlined /> : <ArrowDownOutlined />

        return (
            <Button type="text" icon={buttonIcon} onClick={handleViewMoreClick}>
                {buttonText}
            </Button>
        )
    }, [viewMore, handleViewMoreClick, itemsCount])

    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col>
                    <Text className="tap-color-primary">
                        {title} ({itemsCount})
                    </Text>
                </Col>
                <Col>
                    {canManageParticipants && !isProspectsList && (
                        <Button type="text" onClick={handleManageClick}>
                            Manage
                        </Button>
                    )}
                </Col>
            </Row>
            <ConfigProvider renderEmpty={renderEmpty}>
                <List itemLayout="horizontal" dataSource={listDataSource} renderItem={renderItem} />
            </ConfigProvider>

            {viewMoreButton}
        </div>
    )
}

export default ParticipantsList
