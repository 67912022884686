import React from 'react';
import { selectIsSascOrIdp } from 'src/app/redux/selectors';
import { useAppSelector } from '../hooks/store';
import { Permissions } from './permissions';
import { HomeRedirect } from 'src/app/containers/_layout/nav/components/HomeRedirect';
export var Protect = function (_a) {
    var children = _a.children, permission = _a.permission, defaultValue = _a.defaultValue;
    var isSascOrIdp = useAppSelector(selectIsSascOrIdp) && !permission;
    var hasPermission = !permission || Permissions.can(permission, defaultValue);
    if (isSascOrIdp || !hasPermission) {
        return React.createElement(HomeRedirect, null);
    }
    return React.createElement(React.Fragment, null, children);
};
