import React from 'react'
import { useLocation } from 'react-router-dom'

import Loader from '../../../content/components/Loader'

import EventCard from './event-card'

const LiveEventsList = ({ loading, items }) => {
    const { pathname } = useLocation()

    if (loading) {
        return (
            <div className="live-events-loader">
                <Loader />
            </div>
        )
    }

    return (
        <div className="events-list">
            {items.map((event) => {
                const { id } = event
                return <EventCard key={id} to={`${pathname}/${id}`} event={event} />
            })}
        </div>
    )
}

export default LiveEventsList
