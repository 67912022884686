import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { OPEN_ACTION_MODAL, CLOSE_ACTION_MODAL } from '../../../../../redux/actions/components/componentsActions'
import {
    EDIT_CONTENT_GROUP,
    AMBASSADORS_INFO,
    CLOSE_CONTENT_GROUP,
} from '../../../../../redux/actions/content/messagingActions'
import store from '../../../../../redux/store'

import SettingsContentGroupForm from './forms/SettingsContentGroupForm'

const { confirm } = window

class SettingsContentGroup extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            contentGroup: null,
            isConfirmationPopupOpened: false,
            hasFormBeenUpdated: false,
            isDatePickerOpen: false,
        }

        this.showStatusChangeConfirmationPopup = this.showStatusChangeConfirmationPopup.bind(this)
        this.closeContentGroup = this.closeContentGroup.bind(this)
        this.closeConfirmationPopup = this.closeConfirmationPopup.bind(this)
        this.handleSearchUpdated = this.handleSearchUpdated.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleEscPress = this.handleEscPress.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.setFormUpdatedStatus = this.setFormUpdatedStatus.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.checkIfDatePickerOpen = this.checkIfDatePickerOpen.bind(this)
    }

    componentDidMount() {
        const { contentGroups, activeContentGroup, isSсheduledContentGroup } = this.props

        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'content',
                },
            },
        })

        const contentGroup = isSсheduledContentGroup
            ? activeContentGroup
            : contentGroups.items.find((item) => item.id == activeContentGroup)

        const globalTags = []
        if (contentGroup && contentGroup.globalTags) {
            contentGroup.globalTags.map((item) => {
                globalTags.push(item.id)
            })
        }
        this.setState({
            contentGroup: contentGroup,
            globalTagsIds: globalTags,
        })

        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('keydown', this.handleEscPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('keydown', this.handleEscPress, false)
    }

    showStatusChangeConfirmationPopup() {
        this.setState({ isConfirmationPopupOpened: true })

        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button
                                type="submit"
                                className="el-btn confirm-delete-button"
                                onClick={this.closeContentGroup}
                            >
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={this.closeConfirmationPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    closeContentGroup() {
        store.dispatch({
            type: CLOSE_CONTENT_GROUP,
            payload: {
                dialog_id: this.state.contentGroup.id,
            },
        })

        this.setState({ isConfirmationPopupOpened: false })
    }

    closeConfirmationPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })

        this.setState({ isConfirmationPopupOpened: false })
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            this.state.hasFormBeenUpdated &&
            !this.state.isDatePickerOpen &&
            !this.state.isConfirmationPopupOpened
        ) {
            const confirmByClickOutside = confirm(
                'Are you sure you want to close this popup? Changes will not be saved'
            )
            if (confirmByClickOutside) {
                this.props.onClosePopup()
            }
        }
    }

    handleEscPress(event) {
        const escapeButtonCode = 27
        if (event.keyCode !== escapeButtonCode) {
            return
        }

        if (this.state.isConfirmationPopupOpened) {
            return
        }

        if (!this.state.hasFormBeenUpdated) {
            this.props.onClosePopup()
            return
        }

        const confirmByEscPress = confirm('Are you sure you want to close this popup? Changes will not be saved')
        if (confirmByEscPress) {
            this.props.onClosePopup()
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    handleSearchUpdated(value) {
        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    keyword: value,
                    permission: 'content',
                },
            },
        })
    }

    setFormUpdatedStatus(value) {
        this.setState({ hasFormBeenUpdated: value })
    }

    checkIfDatePickerOpen(open) {
        this.setState({ isDatePickerOpen: open })
    }

    handleFormSubmit(values) {
        const { account, editScheduleEvent, isSсheduledContentGroup } = this.props
        console.log(values)
        if (values.avatar && values.avatar.length > 0) {
            const reader = new window.FileReader()
            reader.readAsDataURL(values.avatar[0])
            reader.addEventListener(
                'load',
                function () {
                    !isSсheduledContentGroup
                        ? store.dispatch({
                              type: EDIT_CONTENT_GROUP,
                              payload: {
                                  name: values.name ? values.name : this.state.contentGroup.name,
                                  users: values.selectedUserIds,
                                  globalTagsIds: values.globalTagsIds,
                                  avatar: reader.result.slice(reader.result.indexOf(',') + 1),
                                  dialogId: this.state.contentGroup.id,
                                  university_id: account.account_info.university_id,
                                  description: values.description,
                              },
                          })
                        : editScheduleEvent({
                              name: values.name ? values.name : this.state.contentGroup.name,
                              membersIds: values.selectedUserIds,
                              globalTagsIds: values.globalTagsIds,
                              avatar: reader.result.slice(reader.result.indexOf(',') + 1),
                              eventId: this.state.contentGroup.id,
                              description: values.description,
                              eventSettings: { date: values.contentGroupEventDate },
                          })
                }.bind(this)
            )
        } else {
            !isSсheduledContentGroup
                ? store.dispatch({
                      type: EDIT_CONTENT_GROUP,
                      payload: {
                          name: values.name ? values.name : this.state.contentGroup.name,
                          users: values.selectedUserIds,
                          globalTagsIds: values.globalTagsIds,
                          avatar: null,
                          dialogId: this.state.contentGroup.id,
                          university_id: account.account_info.university_id,
                          description: values.description,
                      },
                  })
                : editScheduleEvent({
                      name: values.name ? values.name : this.state.contentGroup.name,
                      membersIds: values.selectedUserIds,
                      globalTagsIds: values.globalTagsIds,
                      eventId: this.state.contentGroup.id,
                      description: values.description,
                      eventSettings: { date: values.contentGroupEventDate },
                  })
        }
    }

    render() {
        const { usersMessaging, isSсheduledContentGroup, scheduledDay } = this.props
        const { contentGroup, hasFormBeenUpdated } = this.state

        if (!contentGroup) return null

        return (
            <div ref={this.setWrapperRef}>
                <div className="create-content-group">
                    <h2 className="modal-title">Edit content group</h2>
                    <SettingsContentGroupForm
                        contentGroup={contentGroup}
                        onDeactivateContentGroupButtonClick={this.showStatusChangeConfirmationPopup}
                        onSubmit={this.handleFormSubmit}
                        onSearchUpdated={this.handleSearchUpdated}
                        ambassadors={usersMessaging.ambassadors}
                        areAmbassadorsLoading={usersMessaging.isLoading}
                        // TODO: check why this init doesn't work
                        initialValues={{ name: contentGroup.name }}
                        setFormUpdatedStatus={this.setFormUpdatedStatus}
                        hasFormBeenUpdated={hasFormBeenUpdated}
                        isSсheduledContentGroup={isSсheduledContentGroup}
                        scheduledDay={scheduledDay}
                        checkIfDatePickerOpen={this.checkIfDatePickerOpen}
                    />
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        usersMessaging: state.usersMessaging,
        contentGroups: state.contentGroups,
    }
})(SettingsContentGroup)
