import { all, call, takeEvery } from 'redux-saga/effects'

import { ACCOUNT } from '../actions/account/account-types'
import {
    ACCOUNT_INFO_REQUESTED,
    ACCOUNT_LOGIN,
    ACCOUNT_LOGOUT,
    ACCOUNT_REGISTRATION,
    CHANGE_DISPLAY_NAME_FOR_PARTNERS,
    CHANGE_UNIVERSITY_NAME_REQUESTED,
    CHECK_ADMIN_INVITATION,
    CHECK_GROUP_ADMIN_INVITATION,
    FIND_USER_RECOVER_REQUESTED,
    GROUP_ACCOUNT_REGISTRATION,
    MARK_UNIVERSITY_AS_TEST_REQUEST,
    RECOVER_COMFIRM_REQUESTED,
    UNIVERSITY_CHANGE_AVATAR,
} from '../actions/account/accountActions'
import {
    ACCOUNT_CHANGE_AVATAR,
    ACCOUNT_CHANGE_EMAIL,
    ACCOUNT_CHANGE_EMAIL_SEND_LETTER,
    ACCOUNT_CHANGE_FIRST_NAME,
    ACCOUNT_CHANGE_LAST_NAME,
    ACCOUNT_CHANGE_PASSWORD,
    ACCOUNT_CHANGE_PASSWORD_SEND_LETTER,
} from '../actions/account/accountEditActions'
import {
    TREND_ANALYZER_CHARTS_REQUESTED,
    TREND_ANALYZER_CSV_REQUESTED,
    TREND_ANALYZER_TABLE_REQUESTED,
} from '../actions/analytics/trendAnalyzerActions'
import { CREATE_GROUP_CHAT, SEND_GROUP_CHAT_MESSAGE } from '../actions/chat/groupMessagingActions'
import {
    ARCHIVE_DIALOG_TOGGLE,
    BLOCK_PROSPECT_TO_CHATS,
    CHATS_INFO_SEARCH,
    CHAT_INFO_SEARCH,
    CHAT_MESSAGES_INFO,
    CLOSE_DIALOG,
    DELETE_CHAT_MESSAGE,
    EDIT_CHAT,
    FIND_OR_CREATE_CHAT,
    GET_BLOCK_REASON,
    GET_CHATS_TRANSCRIPT_DATA,
    GET_CHAT_TRANSCRIPT_DATA,
    GET_DIALOG_BY_ID,
    GET_REPORT_REASON,
    MUTE_CHAT_TRIGGER,
    PIN_CHAT,
    PIN_MESSAGE,
    SEND_CHAT_MESSAGE,
    UNBLOCK_CHAT_MEMBER,
    UNFLAG_DIALOG,
    UNPIN_MESSAGE,
    UNWORD_DIALOG,
} from '../actions/chat/messagingActions'
import {
    CONVERT_CUSTOM_QUESTIONS_ANSWERS,
    CONVERT_SPECIALIZATIONS,
    GET_OPTIONAL_QUESTIONS_REQUESTED,
    SAVE_OPTIONAL_QUESTIONS,
} from '../actions/chat/optionalQuestions'
import { ADD_TOPICS, DELETE_TOPIC, EDIT_TOPIC, GET_TOPICS_LIST } from '../actions/chat/topicsActions'
import { GET_WIDGET } from '../actions/chat/widgetsActions'
import ambassadorsTypes from '../actions/content/ambassadorsActions'
import {
    AMBASSADORS_INFO,
    CLOSE_CONTENT_GROUP,
    CONTENT_GROUPS_INFO,
    CREATE_CONTENT_GROUP,
    DELETE_CONTENT_MESSAGE,
    DIALOG_MESSAGES_INFO,
    EDIT_CONTENT_GROUP,
    EDIT_MESSAGE_POST_GLOBAL_TAG,
    RELOAD_CONTENT_GROUP,
    SEND_MESSAGE,
    USERS_MESSAGING_INFO,
    VIDEO_TRANSCODE,
} from '../actions/content/messagingActions'
import {
    ADD_DOWNLOAD_POINTS,
    CONTENT_GROUP_POSTS_REQUESTED,
    EDIT_POST_CAPTION_REQUESTED,
    EDIT_POST_GLOBAL_TAG,
    POSTS_DELETE,
    POSTS_INFO_REQUESTED,
    PUBLISH_POST_REQUESTED,
    SHARE_POST_REQUESTED,
    UPLOAD_FILE_INFO,
    USERS_POSTS_REQUESTED,
    VIDEO_UNIVERSITY_TRANSCODE,
    VIDEO_UPLOAD,
} from '../actions/content/postsActions'
import {
    SCHEDULE_DATA_REQUESTED,
    SCHEDULE_DEACTIVATE_EVENT_REQUESTED,
    SCHEDULE_EDIT_EVENT_REQUESTED,
    SCHEDULE_ITEM_REQUESTED,
    SCHEDULE_UPDATE_STATUSES_REQUESTED,
} from '../actions/content/scheduleActions'
import {
    CHANGE_AMBASSADOR_INFO,
    CHANGE_AMBASSADOR_INTERESTS,
    DELETE_AMBASSADOR,
    EDIT_USER_GLOBAL_TAG,
    GET_COURSE_TYPES,
    GET_INDUSTRIES,
    GET_SA_PROFILE_TYPES,
    GET_STAFF_TYPES,
    GET_STUDENT_TYPES,
    GET_SUBJECTS,
    GET_USER_TABLE_TOTALS,
    GET_YEAR_OF_STUDIES,
    POPCARD_USERS_REQUESTED,
    USERS_INFO_REQUESTED,
    USER_CHANGE_COUNTRY,
    USER_CHANGE_DESCRIPTION,
    USER_CHANGE_INTRODUCTION,
    USER_CHANGE_SUBJECT,
    USER_PARTNER_TOGGLE_BAN_REQUESTED,
    USER_PARTNER_TOGGLE_UNI_BAN_REQUESTED,
    USER_TOGGLE_BAN_REQUESTED,
} from '../actions/content/usersActions'
import {
    ANSWER_TOGGLE_PUBLISH,
    CREATE_QUESTION,
    DELETE_ANSWER,
    DELETE_QUESTION,
    EDIT_ANSWER,
    EDIT_FAQ,
    EDIT_FAQ_GLOBAL_TAG,
    QUESTIONS_ANSWERS_INFO,
    QUESTIONS_INFO,
} from '../actions/faq/faqActions'
import { GET_HOME_CHART_DATA, GET_HOME_DATA } from '../actions/home/homeActions'
import {
    CHANGE_EVENT_STATUS,
    CREATE_EVENT,
    EDIT_EVENT,
    GET_EVENTS_LIST,
    GET_PROSPECTS,
    LOAD_NEXT_PROSPECTS,
    SET_EVENT_AMBASSADORS,
    SET_EVENT_SPEAKERS,
    TOGGLE_EVENT_CHAT,
} from '../actions/live-event/liveEventActions'
import {
    ACTIVITY_CHARTS_REQUESTED,
    ACTIVITY_CSV_REQUESTED,
    ACTIVITY_TABLE_REQUESTED,
} from '../actions/reporting/activityActions'
import { REPORT_CONTENT_INFO } from '../actions/reporting/contentActions'
import { REPORT_QUESTIONS_INFO } from '../actions/reporting/faqActions'
import overviewReportingTypes from '../actions/reporting/overviewActions'
import {
    PROSPECTS_CHARTS_REQUESTED,
    PROSPECTS_CSV_REQUESTED,
    PROSPECTS_TABLE_REQUESTED,
    PROSPECT_FEEDBACK_DATA_REQUESTED,
    PROSPECT_MANDATORY_DATA_REQUESTED,
    PROSPECT_OPTIONAL_DATA_REQUESTED,
} from '../actions/reporting/prospectsActions'
import { GET_ADMINS, SEND_ADMIN_INVITATION } from '../actions/settings/admins'
import {
    ADD_COMPANY,
    EDIT_COMPANY,
    GET_COMPANIES_DATA,
    UPLOAD_COMPANIES_CSV,
} from '../actions/settings/companies-control'
import { GENERATE_API_KEY, GET_API_KEY } from '../actions/settings/crmActions'
import { GET_CSP_DOMAINS_REQUESTED, SET_CSP_DOMAINS_REQUESTED } from '../actions/settings/cspDomainsActions'
import { SET_WIDGET_STYLES, UNIVERSITY_WIDGET_INFO_REQUESTED } from '../actions/settings/custom-styles'
import { CHANGE_TITLES_REQUESTED, GET_TITLES_REQUESTED, SOCIAL_PROOF } from '../actions/settings/customTitlesActions'
import { SET_MANAGEMENT_TERM } from '../actions/settings/dataManagement'
import { EXPORT_DATA } from '../actions/settings/exportData'
import {
    GET_FILTERS_REQUESTED,
    GET_FILTER_OPTIONS_COUNT_REQUESTED,
    SET_FILTERS_REQUESTED,
} from '../actions/settings/filtersActions'
import { GET_NOTIFICATIONS_LIST, TOGGLE_NOTIFICATION } from '../actions/settings/notifications-control'
import {
    ADD_CUSTOM_BADWORD,
    ADD_WHITELISTED_BADWORD,
    CHANGE_PROSPECT_MESSAGING_ACTIVITY,
    DELETE_CUSTOM_BADWORD,
    DELETE_WHITELISTED_BADWORD,
    GET_SAFEGUARDING_DATA,
    KEYWORD_DETECTION_SET,
    SET_DEFAULT_BADWORDS,
    UPLOAD_BADWORDS_CSV,
} from '../actions/settings/safeguardingActions'
import { SETUP_WIZARD_FINISH } from '../actions/settings/setupWizardActions'
import { SHARED_POSTS_INFO_REQUESTED } from '../actions/settings/sharing'
import { GET_TIMEZONES, SET_TIMEZONE } from '../actions/settings/timezonesActions'
import {
    CREATE_GLOBAL_FAQ_TAG,
    CREATE_GLOBAL_POST_TAG,
    CREATE_GLOBAL_TAG,
    CREATE_GLOBAL_USER_TAG,
    GET_GLOBAL_FAQ_TAGS,
    GET_GLOBAL_LIST_TAGS,
    GET_GLOBAL_POPULAR_LIST_TAGS,
    GET_GLOBAL_POST_TAGS,
    GET_GLOBAL_USER_TAGS,
    REMOVE_GLOBAL_FAQ_TAG,
    REMOVE_GLOBAL_POST_TAG,
    REMOVE_GLOBAL_USER_TAG,
} from '../actions/tags/tagsActions'

import { COUNTRIES } from '../actions/settings/countries/countries-action-types'
import { SSO } from '../actions/settings/sso/sso-action-types'
import { GET_PROSPECT_INFO } from '../actions/user/userActions'
import {
    accountChangeAvatar,
    accountChangeEmail,
    accountChangeEmailSendLetter,
    accountChangeFirstName,
    accountChangeLastName,
    accountChangePassword,
    accountChangePasswordSendLetter,
    displayNameForPartnersChange,
    setMarketingConsent,
    setMarketingConsentText,
    universityAvatarChange,
    universityNameChange,
    universityPrivacyPolicyChange,
} from './account/accountEditSaga'
import {
    OTPVerificationToggle,
    accountInfo,
    accountLogin,
    accountLogout,
    accountRegistration,
    accountSsoLogin,
    activatePlsAmbassadorToChat,
    changeOrganisationWebsite,
    checkAdminInvitation,
    checkGroupAdminInvitation,
    experienceBasedQuestionsToggle,
    findUserRecover,
    finishPlsSignUpRegistration,
    flagsToggle,
    getPlsTapFeedLink,
    getProfileTypes,
    getUniversitySsoData,
    groupAccountRegistration,
    hubspotLogin,
    recoverConfirm,
    setProfileType,
    testUniToggle,
    thirdPartyAuthToggle,
} from './account/accountSaga'
import { trendAnalyzerChartsData, trendAnalyzerCsvData, trendAnalyzerTableData } from './analytics/trendAnalyzerSaga'
import campaignRootSaga from './campaign/campaign-root-saga'
import { createGroupChat, pinChat, sendGroupChatMessage } from './chat/groupMessagingSaga'
import {
    archiveDialog,
    blockProspectToChat,
    chatInfoSearch,
    chatsInfoSearch,
    closeDialog,
    deleteChatMessage,
    editChat,
    findOrCreateChat,
    getAmbassadorsList,
    getBlockedProspectData,
    getChatTranscript,
    getChatsTranscript,
    getDialogById,
    getReportReasonData,
    messagesChatInfo,
    muteChatTrigger,
    pinMessage,
    sendChatMessage,
    unblockChatMember,
    unflagDialog,
    unpinMessage,
    unwordDialog,
} from './chat/messagingSaga'
import {
    convertCustomQuestionAnswers,
    convertSpecializations,
    getOptionalQuestions,
    saveOptionalQuestions,
} from './chat/optionalQuestions'
import { addTopics, deleteTopic, editTopic, getTopics } from './chat/topicsSaga'
import { getWidget } from './chat/widgetsSaga'
import {
    ambassadorDescription,
    ambassadorIntroduction,
    ambassadorsDiagrams,
    ambassadorsExport,
    ambassadorsReports,
    getAmbassadorAdditionalData,
    reportingAmbassadors,
    setAmbassadorTimeLimit,
} from './content/ambassadorsSaga'
import {
    ambassadorsInfo,
    changeMessagePostGlobalTags,
    closeContentGroup,
    contentGroupsInfo,
    createContentGroup,
    deleteContentMessage,
    editContentGroup,
    messagesInfo,
    messagingUsers,
    reloadContentGroup,
    sendMessage,
    videoTranscode,
} from './content/messagingSaga'
import {
    addDownloadPoints,
    changePostGlobalTags,
    contentGroupPosts,
    editPost,
    fileUpload,
    postsDelete,
    postsInfo,
    sharePostToRSS,
    togglePublishPost,
    userPosts,
    videoUniversityTranscode,
    videoUpload,
} from './content/postsSaga'
import {
    deactivateScheduleEvent,
    editScheduleEvent,
    getScheduleData,
    getScheduleItem,
    updateScheduleEventStatuses,
} from './content/scheduleSaga'
import {
    changeAmbassadorInfo,
    changeUserCountry,
    changeUserGlobalTags,
    changeUserInterests,
    deleteAmbassador,
    getCourseTypes,
    getIndustries,
    getSaProfileTypes,
    getStaffTypes,
    getStudentTypes,
    getSubjects,
    getUserTableTotals,
    getYearOfStudies,
    popcardUsers,
    userDescription,
    userIntroduction,
    userSubject,
    usersInfo,
    usersPartnerToggleBan,
    usersPartnerUniToggleBan,
    usersToggleBan,
} from './content/usersSaga'
import {
    changeFaqGlobalTags,
    createQuestion,
    deleteAnswer,
    deleteQuestion,
    editAnswer,
    editFAQ,
    questions,
    questionsAnswers,
    togglePublished,
} from './faq/questionsSaga'
import { getHomeChartData, getHomeData } from './home/homeSaga'
import liveEventRootSaga from './live-event/live-event-root-saga'
import {
    changeEventStatus,
    createEvent,
    editEvent,
    getProspects,
    loadNextProspects,
    searchEvents,
    toggleEventChat,
    updateAmbassadors,
    updateSpeakers,
} from './live-event/liveEventSaga'
import liveStreamsRootSaga from './live-streams/live-streams-root-saga'
import plsSignUpRootSaga from './pls-sign-up/pls-sign-up-root-saga'
import { activityChartsData, activityCsvData, activityTableData } from './reporting/activitySaga'
import { contentReport } from './reporting/contentReportSaga'
import { faqReport } from './reporting/faqReportSaga'
import {
    overviewChatStatistics,
    overviewContentStatistics,
    overviewFaqStatistics,
    overviewSummaryStatistics,
    universityStatistics,
} from './reporting/overviewSaga'
import {
    prospectFeedbackData,
    prospectMandatoryData,
    prospectOptionalData,
    prospectsChartsData,
    prospectsCsvData,
    prospectsTableData,
} from './reporting/prospectsSaga'
import { admins, sendAdminInvitation } from './settings/admins'
import { addCompany, editCompany, getCompanies, uploadCompanies } from './settings/companies-control'
import { generateApiKey, getApiKey } from './settings/crm'
import { getCspDomains, setCspDomains } from './settings/cspDomains'
import {
    changeTAPTitles,
    getFilterOptionsCount,
    getFilters,
    getSocialProofInfo,
    getTAPTitles,
    setFilters,
    setUniversityWidget,
    universityWidget,
} from './settings/custom-styles'
import { setManagementTerm } from './settings/dataManagement'
import { exportData } from './settings/exportData'
import { getNotificationsList, toggleNotification } from './settings/notifications-control'
import {
    addCustomBadword,
    addWhitlistedBadword,
    changeProspectsMessagingActivity,
    deleteCustomBadword,
    deleteWhitlistedBadword,
    getSafeguardingData,
    keywordDetectionSet,
    setDefaultBadwords,
    uploadBadwordCsv,
} from './settings/safeguarding'
import { setupWizardFinish } from './settings/setupWizard'
import { sharedPostsInfo } from './settings/sharing'
import {
    activateToggleUniversitySsoOnly,
    getSsoProviders,
    resetUniversitySso,
    setUniversitySso,
    uploadMetadata,
    userToggleSso,
} from './settings/sso'
import { getTimezones, setTimezone } from './settings/timezones'
import {
    createFaqGlobalTag,
    createGlobalTag,
    createPostGlobalTag,
    createUserGlobalTag,
    faqGlobalTags,
    globalTags,
    popularGlobalTags,
    postGlobalTags,
    removeFaqGlobalTag,
    removePostGlobalTag,
    removeUserGlobalTag,
    userGlobalTags,
} from './tags/tagSaga'
import { getProspectInfo } from './user/userSaga'

import { getCountriesList } from './settings/countries'

function* saga() {
    yield takeEvery(ACCOUNT_LOGIN, accountLogin)
    yield takeEvery(ACCOUNT_LOGOUT, accountLogout)
    yield takeEvery(ACCOUNT_REGISTRATION, accountRegistration)
    yield takeEvery(GROUP_ACCOUNT_REGISTRATION, groupAccountRegistration)
    yield takeEvery(CHECK_ADMIN_INVITATION, checkAdminInvitation)
    yield takeEvery(CHECK_GROUP_ADMIN_INVITATION, checkGroupAdminInvitation)

    yield takeEvery(ACCOUNT.FINISH_PLS_SIGN_UP_REGISTRATION, finishPlsSignUpRegistration)
    yield takeEvery(ACCOUNT.REGISTER, accountRegistration)
    yield takeEvery(ACCOUNT.GET_PLS_TAP_FEED_LINK, getPlsTapFeedLink)
    yield takeEvery(ACCOUNT.ACTIVATE_PLS_AMBASSADOR_TO_CHAT, activatePlsAmbassadorToChat)

    yield takeEvery(ACCOUNT_CHANGE_EMAIL_SEND_LETTER, accountChangeEmailSendLetter)
    yield takeEvery(ACCOUNT_CHANGE_PASSWORD_SEND_LETTER, accountChangePasswordSendLetter)
    yield takeEvery(ACCOUNT_CHANGE_EMAIL, accountChangeEmail)
    yield takeEvery(ACCOUNT_CHANGE_PASSWORD, accountChangePassword)
    yield takeEvery(ACCOUNT_CHANGE_AVATAR, accountChangeAvatar)
    yield takeEvery(ACCOUNT_CHANGE_FIRST_NAME, accountChangeFirstName)
    yield takeEvery(ACCOUNT_CHANGE_LAST_NAME, accountChangeLastName)

    yield takeEvery(GET_HOME_CHART_DATA, getHomeChartData)
    yield takeEvery(GET_HOME_DATA, getHomeData)

    yield takeEvery(POSTS_INFO_REQUESTED, postsInfo)
    yield takeEvery(VIDEO_UNIVERSITY_TRANSCODE, videoUniversityTranscode)
    yield takeEvery(VIDEO_UPLOAD, videoUpload)
    yield takeEvery(ACCOUNT_INFO_REQUESTED, accountInfo)
    yield takeEvery(USERS_INFO_REQUESTED, usersInfo)
    yield takeEvery(USERS_POSTS_REQUESTED, userPosts)
    yield takeEvery(USER_TOGGLE_BAN_REQUESTED, usersToggleBan)
    yield takeEvery(USER_PARTNER_TOGGLE_BAN_REQUESTED, usersPartnerToggleBan)
    yield takeEvery(USER_PARTNER_TOGGLE_UNI_BAN_REQUESTED, usersPartnerUniToggleBan)

    yield takeEvery(SCHEDULE_DATA_REQUESTED, getScheduleData)
    yield takeEvery(SCHEDULE_ITEM_REQUESTED, getScheduleItem)
    yield takeEvery(SCHEDULE_EDIT_EVENT_REQUESTED, editScheduleEvent)
    yield takeEvery(SCHEDULE_DEACTIVATE_EVENT_REQUESTED, deactivateScheduleEvent)
    yield takeEvery(SCHEDULE_UPDATE_STATUSES_REQUESTED, updateScheduleEventStatuses)

    yield takeEvery(POPCARD_USERS_REQUESTED, popcardUsers)
    yield takeEvery(USER_CHANGE_INTRODUCTION, userIntroduction)
    yield takeEvery(USER_CHANGE_DESCRIPTION, userDescription)
    yield takeEvery(USER_CHANGE_SUBJECT, userSubject)
    yield takeEvery(USER_CHANGE_COUNTRY, changeUserCountry)
    yield takeEvery(CHANGE_AMBASSADOR_INTERESTS, changeUserInterests)

    yield takeEvery(CONTENT_GROUP_POSTS_REQUESTED, contentGroupPosts)
    yield takeEvery(EDIT_POST_GLOBAL_TAG, changePostGlobalTags)
    yield takeEvery(EDIT_USER_GLOBAL_TAG, changeUserGlobalTags)
    yield takeEvery(EDIT_FAQ_GLOBAL_TAG, changeFaqGlobalTags)
    yield takeEvery(EDIT_MESSAGE_POST_GLOBAL_TAG, changeMessagePostGlobalTags)

    yield takeEvery(ambassadorsTypes.AMBASSADORS_REPORTS_REQUESTED, ambassadorsReports)
    yield takeEvery(ambassadorsTypes.AMBASSADOR_CHANGE_INDRODUCTION, ambassadorIntroduction)
    yield takeEvery(ambassadorsTypes.AMBASSADOR_CHANGE_DESCRIPTION, ambassadorDescription)
    yield takeEvery(ambassadorsTypes.AMBASSADORS_DIAGRAMS_REQUESTED, ambassadorsDiagrams)
    yield takeEvery(ambassadorsTypes.AMBASSADORS_REP_REQUESTED, reportingAmbassadors)
    yield takeEvery(ambassadorsTypes.GET_AMBASSADOR_ADDITIONAL_DATA, getAmbassadorAdditionalData)
    yield takeEvery(ambassadorsTypes.AMBASSADORS_EXPORT, ambassadorsExport)
    yield takeEvery(ambassadorsTypes.AMBASSADORS_LIST, getAmbassadorsList)
    yield takeEvery(ambassadorsTypes.SET_AMBASSADOR_TIME_LIMIT, setAmbassadorTimeLimit)

    yield takeEvery(overviewReportingTypes.UNIVERSITY_STATISTICS_REQUESTED, universityStatistics)
    yield takeEvery(overviewReportingTypes.OVERVIEW_SUMMARY_STATISTICS_REQUESTED, overviewSummaryStatistics)
    yield takeEvery(overviewReportingTypes.OVERVIEW_CHAT_STATISTICS_REQUESTED, overviewChatStatistics)
    yield takeEvery(overviewReportingTypes.OVERVIEW_CONTENT_STATISTICS_REQUESTED, overviewContentStatistics)
    yield takeEvery(overviewReportingTypes.OVERVIEW_FAQ_STATISTICS_REQUESTED, overviewFaqStatistics)

    yield takeEvery(PROSPECTS_CHARTS_REQUESTED, prospectsChartsData)
    yield takeEvery(PROSPECTS_TABLE_REQUESTED, prospectsTableData)
    yield takeEvery(PROSPECTS_CSV_REQUESTED, prospectsCsvData)
    yield takeEvery(PROSPECT_OPTIONAL_DATA_REQUESTED, prospectOptionalData)
    yield takeEvery(PROSPECT_MANDATORY_DATA_REQUESTED, prospectMandatoryData)
    yield takeEvery(PROSPECT_FEEDBACK_DATA_REQUESTED, prospectFeedbackData)

    yield takeEvery(TREND_ANALYZER_CHARTS_REQUESTED, trendAnalyzerChartsData)
    yield takeEvery(TREND_ANALYZER_TABLE_REQUESTED, trendAnalyzerTableData)
    yield takeEvery(TREND_ANALYZER_CSV_REQUESTED, trendAnalyzerCsvData)

    yield takeEvery(ACTIVITY_CHARTS_REQUESTED, activityChartsData)
    yield takeEvery(ACTIVITY_TABLE_REQUESTED, activityTableData)
    yield takeEvery(ACTIVITY_CSV_REQUESTED, activityCsvData)

    yield takeEvery(REPORT_QUESTIONS_INFO, faqReport)
    yield takeEvery(REPORT_CONTENT_INFO, contentReport)

    yield takeEvery(ADD_DOWNLOAD_POINTS, addDownloadPoints)
    yield takeEvery(POSTS_DELETE, postsDelete)
    yield takeEvery(SHARE_POST_REQUESTED, sharePostToRSS)
    yield takeEvery(PUBLISH_POST_REQUESTED, togglePublishPost)
    yield takeEvery(EDIT_POST_CAPTION_REQUESTED, editPost)
    yield takeEvery(UPLOAD_FILE_INFO, fileUpload)

    yield takeEvery(FIND_USER_RECOVER_REQUESTED, findUserRecover)
    yield takeEvery(RECOVER_COMFIRM_REQUESTED, recoverConfirm)
    yield takeEvery(UNIVERSITY_CHANGE_AVATAR, universityAvatarChange)
    yield takeEvery(CHANGE_UNIVERSITY_NAME_REQUESTED, universityNameChange)
    yield takeEvery(CHANGE_DISPLAY_NAME_FOR_PARTNERS, displayNameForPartnersChange)
    yield takeEvery(MARK_UNIVERSITY_AS_TEST_REQUEST, testUniToggle)

    yield takeEvery(SETUP_WIZARD_FINISH, setupWizardFinish)
    yield takeEvery(SHARED_POSTS_INFO_REQUESTED, sharedPostsInfo)
    yield takeEvery(UNIVERSITY_WIDGET_INFO_REQUESTED, universityWidget)
    yield takeEvery(SET_WIDGET_STYLES, setUniversityWidget)
    yield takeEvery(GET_TITLES_REQUESTED, getTAPTitles)
    yield takeEvery(CHANGE_TITLES_REQUESTED, changeTAPTitles)
    yield takeEvery(SET_FILTERS_REQUESTED, setFilters)
    yield takeEvery(GET_FILTERS_REQUESTED, getFilters)
    yield takeEvery(GET_FILTER_OPTIONS_COUNT_REQUESTED, getFilterOptionsCount)
    yield takeEvery(SOCIAL_PROOF, getSocialProofInfo)

    yield takeEvery(GET_CSP_DOMAINS_REQUESTED, getCspDomains)
    yield takeEvery(SET_CSP_DOMAINS_REQUESTED, setCspDomains)

    yield takeEvery(GET_SAFEGUARDING_DATA, getSafeguardingData)
    yield takeEvery(KEYWORD_DETECTION_SET, keywordDetectionSet)
    yield takeEvery(SET_DEFAULT_BADWORDS, setDefaultBadwords)
    yield takeEvery(ADD_CUSTOM_BADWORD, addCustomBadword)
    yield takeEvery(DELETE_CUSTOM_BADWORD, deleteCustomBadword)
    yield takeEvery(ADD_WHITELISTED_BADWORD, addWhitlistedBadword)
    yield takeEvery(DELETE_WHITELISTED_BADWORD, deleteWhitlistedBadword)
    yield takeEvery(UPLOAD_BADWORDS_CSV, uploadBadwordCsv)
    yield takeEvery(CHANGE_PROSPECT_MESSAGING_ACTIVITY, changeProspectsMessagingActivity)

    yield takeEvery(SET_MANAGEMENT_TERM, setManagementTerm)
    yield takeEvery(EXPORT_DATA, exportData)
    yield takeEvery(GET_TIMEZONES, getTimezones)
    yield takeEvery(SET_TIMEZONE, setTimezone)

    yield takeEvery(GET_API_KEY, getApiKey)
    yield takeEvery(GENERATE_API_KEY, generateApiKey)

    yield takeEvery(USERS_MESSAGING_INFO, messagingUsers)
    yield takeEvery(AMBASSADORS_INFO, ambassadorsInfo)
    yield takeEvery(CREATE_CONTENT_GROUP, createContentGroup)

    yield takeEvery(EDIT_CONTENT_GROUP, editContentGroup)

    yield takeEvery(CONTENT_GROUPS_INFO, contentGroupsInfo)
    yield takeEvery(DIALOG_MESSAGES_INFO, messagesInfo)
    yield takeEvery(CHATS_INFO_SEARCH, chatsInfoSearch)
    yield takeEvery(CHAT_INFO_SEARCH, chatInfoSearch)
    yield takeEvery(SEND_CHAT_MESSAGE, sendChatMessage)
    yield takeEvery(CHAT_MESSAGES_INFO, messagesChatInfo)
    yield takeEvery(EDIT_CHAT, editChat)
    yield takeEvery(DELETE_CHAT_MESSAGE, deleteChatMessage)
    yield takeEvery(DELETE_CONTENT_MESSAGE, deleteContentMessage)
    yield takeEvery(GET_CHAT_TRANSCRIPT_DATA, getChatTranscript)
    yield takeEvery(GET_CHATS_TRANSCRIPT_DATA, getChatsTranscript)
    yield takeEvery(PIN_MESSAGE, pinMessage)
    yield takeEvery(UNPIN_MESSAGE, unpinMessage)
    yield takeEvery(GET_DIALOG_BY_ID, getDialogById)

    yield takeEvery(SEND_GROUP_CHAT_MESSAGE, sendGroupChatMessage)

    yield takeEvery(CREATE_EVENT, createEvent)
    yield takeEvery(EDIT_EVENT, editEvent)
    yield takeEvery(GET_EVENTS_LIST, searchEvents)
    yield takeEvery(CHANGE_EVENT_STATUS, changeEventStatus)
    yield takeEvery(SET_EVENT_AMBASSADORS, updateAmbassadors)
    yield takeEvery(SET_EVENT_SPEAKERS, updateSpeakers)
    yield takeEvery(TOGGLE_EVENT_CHAT, toggleEventChat)
    yield takeEvery(GET_PROSPECTS, getProspects)
    yield takeEvery(LOAD_NEXT_PROSPECTS, loadNextProspects)

    yield takeEvery(VIDEO_TRANSCODE, videoTranscode)
    yield takeEvery(SEND_MESSAGE, sendMessage)

    yield takeEvery(CLOSE_CONTENT_GROUP, closeContentGroup)

    yield takeEvery(GET_WIDGET, getWidget)

    yield takeEvery(CREATE_QUESTION, createQuestion)
    yield takeEvery(QUESTIONS_INFO, questions)
    yield takeEvery(DELETE_QUESTION, deleteQuestion)
    yield takeEvery(EDIT_FAQ, editFAQ)
    yield takeEvery(QUESTIONS_ANSWERS_INFO, questionsAnswers)
    yield takeEvery(EDIT_ANSWER, editAnswer)
    yield takeEvery(DELETE_ANSWER, deleteAnswer)
    yield takeEvery(ANSWER_TOGGLE_PUBLISH, togglePublished)
    yield takeEvery(EDIT_FAQ_GLOBAL_TAG, changeFaqGlobalTags)

    yield takeEvery(CREATE_GLOBAL_TAG, createGlobalTag)
    yield takeEvery(CREATE_GLOBAL_USER_TAG, createUserGlobalTag)
    yield takeEvery(CREATE_GLOBAL_POST_TAG, createPostGlobalTag)
    yield takeEvery(CREATE_GLOBAL_FAQ_TAG, createFaqGlobalTag)
    yield takeEvery(GET_GLOBAL_LIST_TAGS, globalTags)
    yield takeEvery(GET_GLOBAL_POPULAR_LIST_TAGS, popularGlobalTags)
    yield takeEvery(GET_GLOBAL_USER_TAGS, userGlobalTags)
    yield takeEvery(GET_GLOBAL_POST_TAGS, postGlobalTags)
    yield takeEvery(GET_GLOBAL_FAQ_TAGS, faqGlobalTags)
    yield takeEvery(REMOVE_GLOBAL_USER_TAG, removeUserGlobalTag)
    yield takeEvery(REMOVE_GLOBAL_POST_TAG, removePostGlobalTag)
    yield takeEvery(REMOVE_GLOBAL_FAQ_TAG, removeFaqGlobalTag)

    yield takeEvery(GET_OPTIONAL_QUESTIONS_REQUESTED, getOptionalQuestions)
    yield takeEvery(GET_TOPICS_LIST, getTopics)
    yield takeEvery(ADD_TOPICS, addTopics)
    yield takeEvery(EDIT_TOPIC, editTopic)
    yield takeEvery(DELETE_TOPIC, deleteTopic)

    yield takeEvery(SAVE_OPTIONAL_QUESTIONS, saveOptionalQuestions)
    yield takeEvery(CONVERT_SPECIALIZATIONS, convertSpecializations)
    yield takeEvery(CONVERT_CUSTOM_QUESTIONS_ANSWERS, convertCustomQuestionAnswers)

    yield takeEvery(ACCOUNT.EDIT_PRIVACY_POLICY, universityPrivacyPolicyChange)
    yield takeEvery(ACCOUNT.SET_MARKETING_CONSENT, setMarketingConsent)
    yield takeEvery(ACCOUNT.SET_MARKETING_CONSENT_CUSTOM_TEXT, setMarketingConsentText)
    yield takeEvery(CLOSE_DIALOG, closeDialog)
    yield takeEvery(ARCHIVE_DIALOG_TOGGLE, archiveDialog)
    yield takeEvery(UNFLAG_DIALOG, unflagDialog)
    yield takeEvery(UNWORD_DIALOG, unwordDialog)
    yield takeEvery(UNBLOCK_CHAT_MEMBER, unblockChatMember)
    yield takeEvery(BLOCK_PROSPECT_TO_CHATS, blockProspectToChat)
    yield takeEvery(GET_BLOCK_REASON, getBlockedProspectData)
    yield takeEvery(GET_REPORT_REASON, getReportReasonData)
    yield takeEvery(MUTE_CHAT_TRIGGER, muteChatTrigger)
    yield takeEvery(PIN_CHAT, pinChat)

    yield takeEvery(CREATE_GROUP_CHAT, createGroupChat)

    yield takeEvery(RELOAD_CONTENT_GROUP, reloadContentGroup)

    yield takeEvery(FIND_OR_CREATE_CHAT, findOrCreateChat)

    yield takeEvery(GET_ADMINS, admins)
    yield takeEvery(SEND_ADMIN_INVITATION, sendAdminInvitation)

    yield takeEvery(GET_NOTIFICATIONS_LIST, getNotificationsList)
    yield takeEvery(TOGGLE_NOTIFICATION, toggleNotification)

    yield takeEvery(UPLOAD_COMPANIES_CSV, uploadCompanies)
    yield takeEvery(GET_COMPANIES_DATA, getCompanies)
    yield takeEvery(ADD_COMPANY, addCompany)
    yield takeEvery(EDIT_COMPANY, editCompany)

    yield takeEvery(GET_PROSPECT_INFO, getProspectInfo)
    yield takeEvery(CHANGE_AMBASSADOR_INFO, changeAmbassadorInfo)
    yield takeEvery(DELETE_AMBASSADOR, deleteAmbassador)
    yield takeEvery(GET_SA_PROFILE_TYPES, getSaProfileTypes)
    yield takeEvery(GET_STAFF_TYPES, getStaffTypes)
    yield takeEvery(GET_STUDENT_TYPES, getStudentTypes)
    yield takeEvery(GET_SUBJECTS, getSubjects)
    yield takeEvery(GET_YEAR_OF_STUDIES, getYearOfStudies)
    yield takeEvery(GET_COURSE_TYPES, getCourseTypes)
    yield takeEvery(GET_INDUSTRIES, getIndustries)
    yield takeEvery(GET_USER_TABLE_TOTALS, getUserTableTotals)

    yield takeEvery(SSO.GET_PROVIDERS, getSsoProviders)
    yield takeEvery(SSO.SET_UNIVERSITY, setUniversitySso)
    yield takeEvery(SSO.RESET_UNIVERSITY, resetUniversitySso)
    yield takeEvery(SSO.USER_TOGGLE, userToggleSso)
    yield takeEvery(SSO.UPLOAD_METADATA, uploadMetadata)
    yield takeEvery(ACCOUNT.GET_UNIVERSITY_SSO_DATA, getUniversitySsoData)
    yield takeEvery(ACCOUNT.ACTIVATE_TOGGLE_UNIVERSITY_SSO_ONLY, activateToggleUniversitySsoOnly)
    yield takeEvery(ACCOUNT.SSO_LOGIN, accountSsoLogin)
    yield takeEvery(ACCOUNT.HUBSPOT_LOGIN, hubspotLogin)
    yield takeEvery(ACCOUNT.CHANGE_ORGANISATION_WEBSITE, changeOrganisationWebsite)
    yield takeEvery(ACCOUNT.GET_PROFILE_TYPES, getProfileTypes)
    yield takeEvery(ACCOUNT.SET_PROFILE_TYPE, setProfileType)
    yield takeEvery(ACCOUNT.SET_EXPERIANCE_BASED_QUESTIONS_TOGGLE, experienceBasedQuestionsToggle)
    yield takeEvery(ACCOUNT.SET_OTP_VERIFICATION_TOGGLE, OTPVerificationToggle)
    yield takeEvery(ACCOUNT.SET_THIRD_PARTY_AUTH_TOGGLE, thirdPartyAuthToggle)
    yield takeEvery(ACCOUNT.SET_FLAGS_TOGGLE, flagsToggle)
    yield takeEvery(COUNTRIES.GET_COUNTRIES, getCountriesList)
    yield all([call(liveEventRootSaga), call(plsSignUpRootSaga), call(liveStreamsRootSaga), call(campaignRootSaga)])
}

export default saga
