import { all, call, takeEvery, select, put } from 'redux-saga/effects'

import API from '../../../api'
import { CAMPAIGN } from '../../actions/campaign/campaign-action-types'

import { getIsGroupAdmin, getCurrentGroupUniversityId } from '../../selectors'

function* fetchCampaignSaga({ payload }) {
    const isGroupAdmin = yield select(getIsGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        payload.universityId = currentGroupUniversityId || payload.university_id
    }
    const response = yield call(() => {
        return API.campaign.getListOfCampaigns(payload)
    })

    if (response.success) {
        yield put({
            type: CAMPAIGN.FETCH_SUCCESS,
            payload: response.data,
        })
    }
}

function* fetchCampaignTypesSaga() {
    const response = yield call(() => {
        return API.campaign.getListOfCampaignsTypes()
    })

    if (response.success) {
        yield put({
            type: CAMPAIGN.SET_CAMPAIGN_TYPES,
            payload: response.data,
        })
    }
}

function* handleFetchCampaignById({ payload }) {
    const isGroupAdmin = yield select(getIsGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        payload.universityId = currentGroupUniversityId || payload.university_id
    }

    try {
        const response = yield call(() => {
            return API.campaign.getCampaignById(payload)
        })

        if (response.success) {
            yield put({
                type: CAMPAIGN.FETCH_CAMPAIGN_BY_ID_SUCCESS,
                payload: response.data,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* watchFetchCampaignById() {
    yield takeEvery(CAMPAIGN.FETCH_CAMPAIGN_BY_ID, handleFetchCampaignById)
}

function* watchCampaignFetchSaga() {
    yield takeEvery(CAMPAIGN.FETCH, fetchCampaignSaga)
}

function* watchCampaignTypesFetchSaga() {
    yield takeEvery(CAMPAIGN.OPEN_CAMPAIGN_TYPE_MODAL, fetchCampaignTypesSaga)
}

function* CampaignFetchSaga() {
    yield all([call(watchCampaignFetchSaga), call(watchCampaignTypesFetchSaga), call(watchFetchCampaignById)])
}

export default CampaignFetchSaga
