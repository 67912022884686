import HttpService from '../../system/services/HttpService'
import { pusherChild } from '../../system/services/PusherWrapper'

export default {
    // TODO: delete from backend
    /* prospectsInfo: (filter) => {
        return HttpService.get("/dashboard/universities/prospects", { filter: filter });
    }, */

    getAmbassadorsList: async (data) => {
        const preparedData = {
            from: data.filter && data.filter.from,
            to: data.filter && data.filter.to,
        }
        if (data.universityIds) {
            preparedData.universityIds = data.universityIds
        }
        return await HttpService.get('/dashboard/universities/ambassadors/export', data)
    },

    getWidget: (universityId) => {
        return HttpService.get('/dashboard/universities/getWidget/' + universityId)
    },

    convertSpecializations: (payload) => {
        return HttpService.formData('/dashboard/universities/convertSpecializations', payload)
    },

    saveOptionalQuestions: (data) => {
        return HttpService.post('/dashboard/universities/optionalData', data)
    },

    closeDialog: (dialogId) => {
        return HttpService.put('/dashboard/dialogs/' + dialogId + '/close/toggle', {
            socketId: pusherChild.connection.socket_id,
        })
    },

    archiveDialog: (dialogId) => {
        return HttpService.put('/dashboard/dialogs/' + dialogId + '/archive/toggle', {
            socketId: pusherChild.connection.socket_id,
        })
    },

    getOptionalQuestions: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/optionalData', data)
    },

    chatsSearch: ({ filters = {}, search = null, dialogId = null, limit = 100, offset = 0 }, universityIds) => {
        const data = {
            limit,
            offset,
            filter: filters,
        }
        if (search) {
            data.search = search
        }
        if (dialogId) {
            data.dialogId = dialogId
        }
        if (universityIds) {
            data.universityIds = universityIds
        }
        return HttpService.get('/dashboard/dialogs/search', data)
    },
    messagesInfo: (dialogId, withGlobalTags = false) => {
        return HttpService.get('/dashboard/dialogs/' + dialogId + '/messages', {
            withGlobalTags: withGlobalTags,
        })
    },

    createGroupChat: (data) => {
        return HttpService.post('/dashboard/dialogs/create', data)
    },

    deleteMessage: (dialogId, messageId) => {
        return HttpService.delete('/dashboard/dialogs/' + dialogId + '/messages/' + messageId)
    },

    getAllTopics: (limit, offset, searchName) => {
        const data = {
            limit: limit,
            offset: offset,
            search: searchName,
        }

        return HttpService.get('/dashboard/dialogs/topics', data)
    },

    addTopics: (dialogId, topics, lastMessageId) => {
        return HttpService.post('/dashboard/dialogs/' + dialogId + '/addTopics', {
            topics: topics,
            lastMessageId: lastMessageId,
        })
    },

    editTopic: (dialogId, topicId, confirmation) => {
        return HttpService.put('/dashboard/dialogs/' + dialogId + '/editTopic', {
            topicId: topicId,
            confirmation: confirmation,
        })
    },

    deleteTopic: (dialogId, topicId) => {
        return HttpService.put('/dashboard/dialogs/' + dialogId + '/editTopic', { topicId: topicId, isDelete: true })
    },

    stream: (data) => HttpService.postGlobal('system/stream', data),

    unblockChatMember: (dialogId, userId) => {
        return HttpService.put('/dashboard/dialogs/' + dialogId + '/unblock', { userId: userId })
    },
    blockProspectToChat: (blockingReason, universityId, userId) => {
        return HttpService.put('/dashboard/users/' + userId + '/block', {
            blockingReason: blockingReason,
            universityId: universityId,
        })
    },
    getBlockedProspectData: (universityId, userId) => {
        const data = {
            universityId: universityId,
        }

        return HttpService.get('/dashboard/users/' + userId + '/blockedProspectData', data)
    },
    getReportReasonData: (dialogId) => {
        const data = {
            dialogId: dialogId,
        }

        return HttpService.get('/dashboard/dialogs/reportedMessage', data)
    },
    muteChatTrigger: (dialogId, isMute) => {
        return HttpService.put('/dashboard/dialogs/' + dialogId + '/muteChatToggle', { is_mute: isMute })
    },
    pinChat: (dialogId, isPinned) => {
        return HttpService.put('/dashboard/dialogs/' + dialogId + '/pinChatToggle', { is_pinned: isPinned })
    },
    parseCsv: (payload) => {
        return HttpService.formData('/dashboard/data/parseCsv', payload)
    },

    handleMessagePin: ({ dialogId, messageId, isPinned }) => {
        return HttpService.post('/dashboard/dialogs/' + dialogId + '/messages/' + messageId + '/pinToggle', {
            is_pinned: isPinned,
        })
    },
    getDialogById: ({ dialogId }) => {
        return HttpService.get(`/dashboard/dialogs/${dialogId}`)
    },
}
