import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { replyMessage } from 'src/app/redux/actions/components/messaging';
import { ReplyMessage } from '../messages/reply-message';
export var ReplyMessageContainer = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var message = useAppSelector(function (state) { return state.replyMessage.activeMessage; });
    var dispatch = useAppDispatch();
    var onCloseActiveMessage = function () {
        dispatch(replyMessage({ activeMessage: null }));
    };
    return message ? (React.createElement(ReplyMessage, { avatarSrc: ((_b = (_a = message.user) === null || _a === void 0 ? void 0 : _a.avatar) === null || _b === void 0 ? void 0 : _b.sizes['70x70']) || ((_d = (_c = message.user) === null || _c === void 0 ? void 0 : _c.avatar) === null || _d === void 0 ? void 0 : _d.original), avatarInitial: ((_f = (_e = message.user) === null || _e === void 0 ? void 0 : _e.name) === null || _f === void 0 ? void 0 : _f.slice(0, 1).toUpperCase()) || 'D', onCloseActiveMessage: onCloseActiveMessage, userName: ((_g = message.user) === null || _g === void 0 ? void 0 : _g.name) || 'Deleted User', messageText: (_h = message.content) === null || _h === void 0 ? void 0 : _h.text })) : null;
};
