const prepareFormModel = (data) => {
    const { providerName = '', settings: { attributes = {}, provider_details: providerDetails = {} } = {} } = data || {}
    const { metadata_url: metadataUrl, isMetadataFileUploaded = false } = providerDetails
    const {
        email = '',
        given_name: givenName = '',
        family_name: familyName = '',
        custom_1: custom1 = {},
        custom_2: custom2 = {},
        custom_3: custom3 = {},
    } = attributes

    return {
        providerName,
        metadataUrl: isMetadataFileUploaded ? '' : metadataUrl,
        email,
        givenName,
        familyName,
        custom_1_name: custom1 ? custom1.name : null,
        custom_2_name: custom2 ? custom2.name : null,
        custom_3_name: custom3 ? custom3.name : null,
        custom_1_reference: custom1 ? custom1.reference : null,
        custom_2_reference: custom2 ? custom2.reference : null,
        custom_3_reference: custom3 ? custom3.reference : null,
        custom_1_unique: custom1 ? custom1.unique : false,
        custom_2_unique: custom2 ? custom2.unique : false,
        custom_3_unique: custom3 ? custom3.unique : false,
    }
}

export default (data = {}) => prepareFormModel(data)
