import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'

import Logo from '../../../../assets/img/svg/logo.svg'
import Sprite from '../../../../assets/img/symbol/sprite.svg'
import {
    checkCanSeeCommunity,
    checkCanSeeExperienceBasedQuestions,
    checkCanSeeGroupChats,
    checkCanSeeLiveEvents,
    checkCanSeeLiveStreams,
    checkCanSeeSso,
    checkCanSeeTrendInsights,
    checkIsInTHE,
    isCampaignManagementAllowed,
    isMenuItemAllowed,
} from '../../../common/utils/user-account-helpers'

import { getCommonRoutePath } from '../../../helpers/helpers'
import NavBottom from './components/NavBottom'

import { Permissions } from 'src/app/permissions/permissions'
import { LicensesEnum, PermissionEnum } from 'src/types/general'
import { ProtectedNavLink } from '../../../components/protected-nav-link'

class Navigation extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            activeSubmenu: 'home',
        }
    }

    isSubmenuItemAllowed(submenuItemId) {
        const { account } = this.props

        const {
            account_info: { isGroupAdmin, university = {} },
        } = account
        const { permissions = [] } = university
        return isMenuItemAllowed(submenuItemId, isGroupAdmin, permissions)
    }

    render() {
        const { account, /* goBack, */ twoTierMenu } = this.props
        const isHomeActive = twoTierMenu.activeSubmenu === 'home'
        const isContentActive = twoTierMenu.activeSubmenu === 'content'
        const isSettingsActive = twoTierMenu.activeSubmenu === 'settings'
        const isChatActive = twoTierMenu.activeSubmenu === 'chat'
        const isLiveEventsActive = twoTierMenu.activeSubmenu === 'live-events'
        const isLiveStreamsActive = twoTierMenu.activeSubmenu === 'streams'
        const isCommunityActive = twoTierMenu.activeSubmenu === 'buzz'
        const isSaActive = twoTierMenu.activeSubmenu === 'sa-list'
        const isFaqActive = twoTierMenu.activeSubmenu === 'faq'
        const isReportingActive = twoTierMenu.activeSubmenu === 'reporting'
        const isPromotionActive = twoTierMenu.activeSubmenu === 'promotion'
        const isMyAccount = twoTierMenu.activeSubmenu === 'account'
        const isPartnersActive = twoTierMenu.activeSubmenu === 'partners-integrations'
        const isLiveEventsFeatureActive = twoTierMenu.activeSubmenu === 'live-events-feature'
        const isCampaignManagementActive = twoTierMenu.activeSubmenu === 'campaign-management'
        const isIdpActivationActive = twoTierMenu.activeSubmenu === 'idp-activation'
        let submenuLinks = ''
        const {
            currentGroupUniversityId,
            account_info: { isGroupAdmin, university = {}, isIdpGroupAdmin, isBuzzOnly },
        } = account

        const canSeeLiveEvents = !isGroupAdmin && checkCanSeeLiveEvents(university)
        const canSeeLiveStreams = checkCanSeeLiveStreams(university)
        const canSeeSso = checkCanSeeSso(account)
        const canSeeEBQ = checkCanSeeExperienceBasedQuestions(university)
        const canSeeGroupChats = checkCanSeeGroupChats(university)
        const canSeeTrendInsights = checkCanSeeTrendInsights(university)
        const canSeeCampaignManagement = isCampaignManagementAllowed(university)
        const canSeeCommunity = checkCanSeeCommunity(university)
        const canSeeIdpReport =
            Permissions.can(PermissionEnum.IdpLiveApp, false) || Permissions.can(PermissionEnum.IdpWhatUni, false)

        const { permissions = [], isRegisteredPartner } = university

        const isSubmenuVisible =
            !isSaActive &&
            !isHomeActive &&
            // !isInviteAdmins &&
            !isMyAccount &&
            // !isNotifications &&
            !isPartnersActive &&
            !isLiveEventsFeatureActive &&
            !isIdpActivationActive
        const inTHE = checkIsInTHE(university)
        const isTHEPartnerRegistration = inTHE && isRegisteredPartner

        const commonPath = getCommonRoutePath()

        if (isContentActive) {
            submenuLinks = (
                <div className="submenu-list">
                    {isIdpGroupAdmin ? (
                        <NavLink
                            exact
                            to={commonPath + '/content/messaging'}
                            className="submenu-link"
                            data-automation-id="submenu-content-groups"
                        >
                            Groups
                        </NavLink>
                    ) : (
                        <>
                            <NavLink
                                exact
                                to={commonPath + '/content/dashboard'}
                                className="submenu-link"
                                data-automation-id="submenu-content-gallery"
                            >
                                Gallery
                            </NavLink>
                            <NavLink
                                exact
                                to={commonPath + '/content/messaging'}
                                className="submenu-link"
                                data-automation-id="submenu-content-groups"
                            >
                                Groups
                            </NavLink>
                            <NavLink
                                exact
                                to={commonPath + '/content/schedule'}
                                className="submenu-link"
                                data-automation-id="submenu-content-shedule"
                            >
                                Schedule
                            </NavLink>
                            <ProtectedNavLink
                                exact
                                to={commonPath + '/content/sharing'}
                                className="submenu-link"
                                data-automation-id="submenu-content-sharing"
                                licenseName={LicensesEnum.Sharing}
                            >
                                Sharing
                            </ProtectedNavLink>
                            {!isTHEPartnerRegistration && (
                                <>
                                    <NavLink
                                        exact
                                        to={commonPath + '/content/start'}
                                        className="submenu-link"
                                        data-automation-id="submenu-content-start-guide"
                                    >
                                        Start guide
                                    </NavLink>
                                </>
                            )}
                        </>
                    )}
                </div>
            )
        } else if (isSettingsActive) {
            submenuLinks = (
                <div className="submenu-list">
                    {/* { (!isGroupAdmin || (isGroupAdmin && !currentGroupUniversityId)) && (<NavLink exact to={commonPath + "/settings/account" className="submenu-link">My account</NavLink>)}
                { (!isGroupAdmin || (isGroupAdmin && !currentGroupUniversityId)) && (<NavLink exact to={commonPath + "/settings/notifications-control" className="submenu-link">Notifications</NavLink>)}
                <hr className="menu-sep" /> */}
                    {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/invite'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-invite"
                        >
                            <p>Invite</p>
                            <p>ambassadors</p>
                        </NavLink>
                    )}
                    {(!isGroupAdmin || (isGroupAdmin && !currentGroupUniversityId)) && !isBuzzOnly && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/invite-admins'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-invite-admins"
                        >
                            Invite admins
                        </NavLink>
                    )}

                    {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/legal'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-legal"
                        >
                            Legal
                        </NavLink>
                    )}
                    {!isBuzzOnly && (
                        <ProtectedNavLink
                            exact
                            to={commonPath + '/settings/tap-page'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-tap-feed"
                            licenseName={LicensesEnum.TapFeed}
                        >
                            TAP Feed
                        </ProtectedNavLink>
                    )}

                    <NavLink
                        exact
                        to={commonPath + '/settings/custom-styles'}
                        className="submenu-link"
                        data-automation-id="submenu-settings-branding"
                    >
                        Branding
                    </NavLink>
                    {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && !isBuzzOnly && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/optional-questions'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-optional-data"
                        >
                            Additional data
                        </NavLink>
                    )}
                    {!isGroupAdmin && !isBuzzOnly && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/auto-deletion'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-auto-deletion"
                        >
                            Auto deletion
                        </NavLink>
                    )}
                    {!isGroupAdmin && !isBuzzOnly && (
                        <ProtectedNavLink
                            exact
                            to={commonPath + '/settings/crm'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-crm"
                            licenseName={LicensesEnum.Crm}
                        >
                            CRM
                        </ProtectedNavLink>
                    )}
                    {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && !isBuzzOnly && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/export-data'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-export-data"
                        >
                            Export data
                        </NavLink>
                    )}
                    {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/safeguarding'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-safeguarding"
                        >
                            Safeguarding
                        </NavLink>
                    )}
                    {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && canSeeEBQ && !isBuzzOnly && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/experience-based-questions'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-experience-based-data"
                        >
                            <p>Experience based</p>
                            <p>questions</p>
                        </NavLink>
                    )}

                    {canSeeSso && !isBuzzOnly && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/sso'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-sso"
                        >
                            SSO
                        </NavLink>
                    )}

                    {(!isGroupAdmin || (isGroupAdmin && !currentGroupUniversityId)) && !isBuzzOnly && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/notifications-control'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-notifications-control"
                        >
                            Notification
                        </NavLink>
                    )}

                    {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/timezone'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-timezone"
                        >
                            Timezone
                        </NavLink>
                    )}

                    {isGroupAdmin && !currentGroupUniversityId && (
                        <NavLink
                            exact
                            to={commonPath + '/settings/legal'}
                            className="submenu-link"
                            data-automation-id="submenu-settings-legal"
                        >
                            Legal
                        </NavLink>
                    )}
                </div>
            )
        } else if (isChatActive) {
            submenuLinks = (
                <div className="submenu-list">
                    <NavLink
                        exact
                        to={commonPath + '/chat/messaging'}
                        className="submenu-link"
                        data-automation-id="submenu-chat-messaging"
                    >
                        Messaging
                    </NavLink>
                    {!isTHEPartnerRegistration && canSeeGroupChats && (
                        <ProtectedNavLink
                            exact
                            to={commonPath + '/chat/group-chats'}
                            className="submenu-link"
                            data-automation-id="submenu-chat-group-chats"
                            licenseName={LicensesEnum.GroupChats}
                        >
                            Group Chats
                        </ProtectedNavLink>
                    )}
                    <NavLink
                        exact
                        to={commonPath + '/chat/archive'}
                        className="submenu-link"
                        data-automation-id="submenu-chat-archive"
                    >
                        Archive
                    </NavLink>
                    <NavLink
                        exact
                        to={commonPath + '/chat/start'}
                        className="submenu-link"
                        data-automation-id="submenu-chat-start-guide"
                    >
                        Start guide
                    </NavLink>
                </div>
            )
        } else if (isLiveEventsActive) {
            submenuLinks = (
                <div className="submenu-list">
                    <NavLink
                        to={commonPath + '/live-events/upcoming'}
                        className="submenu-link"
                        data-automation-id="submenu-live-events-upcoming"
                        // onClick={(e) => goBack(e, 'upcoming')}
                    >
                        Upcoming
                    </NavLink>
                    <NavLink
                        to={commonPath + '/live-events/in-progress'}
                        className="submenu-link"
                        data-automation-id="submenu-live-events-in-progress"
                        // onClick={(e) => goBack(e, 'inProgress')}
                    >
                        In progress
                    </NavLink>
                    <NavLink
                        to={commonPath + '/live-events/previous'}
                        className="submenu-link"
                        data-automation-id="submenu-live-events-previous"
                        // onClick={(e) => goBack(e, 'previous')}
                    >
                        Finished
                    </NavLink>
                    <NavLink
                        to={commonPath + '/live-events/start'}
                        className="submenu-link"
                        data-automation-id="submenu-live-events-start-guide"
                    >
                        Start guide
                    </NavLink>
                </div>
            )
        } else if (isLiveStreamsActive) {
            submenuLinks = (
                <div className="submenu-list">
                    <NavLink
                        to={commonPath + '/live-streams/streams'}
                        className="submenu-link"
                        data-automation-id="submenu-live-streams-list"
                    >
                        Streams
                    </NavLink>
                    <NavLink
                        to={commonPath + '/live-streams/start'}
                        className="submenu-link"
                        data-automation-id="submenu-live-streams-start-guide"
                    >
                        Start guide
                    </NavLink>
                </div>
            )
        } else if (isCommunityActive) {
            submenuLinks = (
                <div className="submenu-list">
                    <NavLink
                        to={commonPath + '/community/chats'}
                        className="submenu-link"
                        data-automation-id="submenu-community-list"
                        title="Community Chats"
                    >
                        Community Chats
                    </NavLink>

                    <NavLink
                        to={commonPath + '/community/individual-chats'}
                        className="submenu-link"
                        data-automation-id="submenu-community-private-list"
                        title="Direct Messages"
                    >
                        Direct Messages
                    </NavLink>

                    <NavLink
                        to={commonPath + '/community/reporting'}
                        className="submenu-link"
                        data-automation-id="submenu-community-reporting"
                    >
                        Reporting
                    </NavLink>

                    <NavLink
                        to={commonPath + '/community/members'}
                        className="submenu-link"
                        data-automation-id="submenu-community-members"
                    >
                        Members
                    </NavLink>
                    <NavLink
                        to={commonPath + '/community/start'}
                        className="submenu-link"
                        data-automation-id="submenu-community-start-guide"
                    >
                        Start guide
                    </NavLink>
                </div>
            )
        } else if (isSaActive) {
            submenuLinks = ''
        } else if (isHomeActive) {
            submenuLinks = ''
        } else if (isFaqActive) {
            submenuLinks = (
                <div className="submenu-list">
                    <NavLink
                        exact
                        to={commonPath + '/faq/library'}
                        className="submenu-link"
                        data-automation-id="submenu-faq-library"
                    >
                        Library
                    </NavLink>
                    <NavLink
                        exact
                        to={commonPath + '/faq/new-questions'}
                        className="submenu-link"
                        data-automation-id="submenu-faq-new-questions"
                    >
                        Unanswered
                    </NavLink>
                    <NavLink
                        exact
                        to={commonPath + '/faq/new-answers'}
                        className="submenu-link"
                        data-automation-id="submenu-faq-new-answers"
                    >
                        To approve
                    </NavLink>
                    {!isTHEPartnerRegistration && (
                        <>
                            <NavLink
                                exact
                                to={commonPath + '/faq/start'}
                                className="submenu-link"
                                data-automation-id="submenu-faq-start-guide"
                            >
                                Start guide
                            </NavLink>
                        </>
                    )}
                </div>
            )
        } else if (isReportingActive) {
            submenuLinks = (
                <div className="submenu-list">
                    {this.isSubmenuItemAllowed('reporting_overview') && !isBuzzOnly && (
                        <ProtectedNavLink
                            exact
                            to={commonPath + '/reporting/overview'}
                            className="submenu-link"
                            data-automation-id="submenu-reporting-overview"
                        >
                            Overview
                        </ProtectedNavLink>
                    )}
                    {this.isSubmenuItemAllowed('reporting_activity') && !isBuzzOnly && (
                        <ProtectedNavLink
                            exact
                            to={commonPath + '/reporting/activity'}
                            className="submenu-link"
                            data-automation-id="submenu-reporting-activity"
                        >
                            Activity
                        </ProtectedNavLink>
                    )}

                    {this.isSubmenuItemAllowed('reporting_content') && !isBuzzOnly && (
                        <ProtectedNavLink
                            exact
                            to={commonPath + '/reporting/content'}
                            className="submenu-link"
                            data-automation-id="submenu-reporting-content"
                        >
                            Content
                        </ProtectedNavLink>
                    )}

                    {this.isSubmenuItemAllowed('reporting_faq') && !isBuzzOnly && (
                        <ProtectedNavLink
                            exact
                            to={commonPath + '/reporting/faq'}
                            className="submenu-link"
                            data-automation-id="submenu-reporting-faq"
                        >
                            FAQ
                        </ProtectedNavLink>
                    )}

                    {this.isSubmenuItemAllowed('reporting_ambassadors') && !isBuzzOnly && (
                        <NavLink
                            exact
                            to={commonPath + '/reporting/ambassadors'}
                            className="submenu-link"
                            data-automation-id="submenu-reporting-ambassadors"
                        >
                            Ambassadors
                        </NavLink>
                    )}

                    {this.isSubmenuItemAllowed('reporting_prospects') && !isBuzzOnly && (
                        <ProtectedNavLink
                            exact
                            to={commonPath + '/reporting/prospects'}
                            className="submenu-link"
                            data-automation-id="submenu-reporting-prospects"
                        >
                            Prospects
                        </ProtectedNavLink>
                    )}

                    {this.isSubmenuItemAllowed('reporting_time') && (
                        <NavLink
                            exact
                            to={commonPath + '/reporting/time'}
                            className="submenu-link"
                            data-automation-id="submenu-reporting-time"
                        >
                            Time
                        </NavLink>
                    )}

                    {this.isSubmenuItemAllowed('reporting_trend') && canSeeTrendInsights && !isBuzzOnly && (
                        <ProtectedNavLink
                            exact
                            to={commonPath + '/reporting/trend'}
                            className="submenu-link beta-version"
                            data-automation-id="submenu-reporting-trend"
                        >
                            <p>Conversation</p>
                            <p>insights</p>
                        </ProtectedNavLink>
                    )}
                    {canSeeIdpReport && !isBuzzOnly && (
                        <NavLink
                            exact
                            to={commonPath + '/reporting/idp-conversion'}
                            className="submenu-link beta-version"
                            data-automation-id="submenu-reporting-idp-conversion"
                        >
                            <p>P2P</p>
                            <p>Conversion</p>
                        </NavLink>
                    )}
                </div>
            )
        } else if (isPromotionActive) {
            submenuLinks = (
                <div className="submenu-list">
                    <NavLink
                        exact
                        to={commonPath + '/promotion/pop-card'}
                        className="submenu-link"
                        data-automation-id="submenu-promotion-pop-card"
                    >
                        Pop card
                    </NavLink>
                    <NavLink
                        exact
                        to={commonPath + '/promotion/carousel'}
                        className="submenu-link"
                        data-automation-id="submenu-promotion-carousel"
                    >
                        Carousel
                    </NavLink>
                    <NavLink
                        exact
                        to={commonPath + '/promotion/link'}
                        className="submenu-link"
                        data-automation-id="submenu-promotion-link"
                    >
                        Tracked link
                    </NavLink>
                    <NavLink
                        exact
                        to={commonPath + '/promotion/button'}
                        className="submenu-link"
                        data-automation-id="submenu-promotion-button"
                    >
                        Button
                    </NavLink>
                </div>
            )
        } else if (isCampaignManagementActive) {
            submenuLinks = (
                <div className="submenu-list">
                    <NavLink
                        to={commonPath + '/campaign-management/campaigns'}
                        className="submenu-link"
                        data-automation-id="submenu-campaign-management-list"
                    >
                        Campaigns
                    </NavLink>
                    {/* <NavLink
                        to={commonPath + '/campaign-management/start'}
                        className="submenu-link"
                        data-automation-id="submenu-campaign-management-start-guide"
                    >
                        Start guide
                    </NavLink> */}
                </div>
            )
        } else if (isIdpActivationActive) {
            submenuLinks = ''
        }

        return (
            <>
                <nav className="nav">
                    <div className="nav-top">
                        <NavLink
                            exact
                            to={commonPath + '/home'}
                            data-menu-value="home"
                            className="logo nav-link nav-icon-notooltip"
                            data-automation-id="nav-link-home-logo"
                        >
                            <img className="logo-img" src={Logo} alt="The Ambassador Platform logo" />
                        </NavLink>

                        <NavLink
                            to={commonPath + '/home'}
                            data-menu-value="home"
                            className={isHomeActive ? 'nav-link nav-icon-home is-active' : 'nav-link nav-icon-home'}
                            data-automation-id="nav-link-home"
                        >
                            <span className="nav-link-icon">
                                <svg className="icon">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#home'} />
                                </svg>
                            </span>
                        </NavLink>

                        {canSeeLiveEvents && !isBuzzOnly ? (
                            <>
                                {((!isGroupAdmin && !isTHEPartnerRegistration) ||
                                    (isGroupAdmin && currentGroupUniversityId)) && (
                                    <NavLink
                                        to={commonPath + '/live-events/start'}
                                        data-menu-value="live-events"
                                        className={
                                            isLiveEventsActive
                                                ? 'nav-link nav-icon-camera beta-version is-active'
                                                : 'nav-link nav-icon-camera beta-version'
                                        }
                                        data-automation-id="nav-link-live-events"
                                    >
                                        <span className="nav-link-icon">
                                            <svg className="icon">
                                                <use
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    xlinkHref={Sprite + '#camera'}
                                                />
                                            </svg>
                                        </span>
                                        <div className="beta-mark">BETA</div>
                                    </NavLink>
                                )}
                            </>
                        ) : null}

                        {canSeeLiveStreams && !isBuzzOnly ? (
                            <>
                                {((!isGroupAdmin && !isTHEPartnerRegistration) ||
                                    (isGroupAdmin && currentGroupUniversityId)) && (
                                    <NavLink
                                        to={commonPath + '/live-streams/start'}
                                        data-menu-value="live-streams"
                                        className={
                                            isLiveStreamsActive
                                                ? 'nav-link nav-icon-camera-stream is-active'
                                                : 'nav-link nav-icon-camera-stream'
                                        }
                                        data-automation-id="nav-link-live-events"
                                    >
                                        <span className="nav-link-icon">
                                            <svg className="icon">
                                                <use
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    xlinkHref={Sprite + '#camera'}
                                                />
                                            </svg>
                                        </span>
                                    </NavLink>
                                )}
                            </>
                        ) : null}

                        {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && !isBuzzOnly && (
                            <NavLink
                                to={commonPath + (!isTHEPartnerRegistration ? '/chat/start' : '/chat/messaging')}
                                data-menu-value="chat"
                                className={isChatActive ? 'nav-link nav-icon-chat is-active' : 'nav-link nav-icon-chat'}
                                data-automation-id="nav-link-chat"
                            >
                                <span className="nav-link-icon">
                                    <svg className="icon">
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#chat'} />
                                    </svg>
                                </span>
                            </NavLink>
                        )}

                        {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId) || isIdpGroupAdmin) &&
                            !isBuzzOnly && (
                                <NavLink
                                    to={
                                        commonPath +
                                        (isTHEPartnerRegistration
                                            ? '/content/dashboard'
                                            : isIdpGroupAdmin
                                              ? '/content/messaging'
                                              : '/content/start')
                                    }
                                    data-menu-value="content"
                                    className={
                                        isContentActive
                                            ? 'nav-link nav-icon-content is-active'
                                            : 'nav-link nav-icon-content'
                                    }
                                    data-automation-id="nav-link-content"
                                >
                                    <span className="nav-link-icon">
                                        <svg className="icon">
                                            <use
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                xlinkHref={Sprite + '#content'}
                                            />
                                        </svg>
                                    </span>
                                </NavLink>
                            )}

                        {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && !isBuzzOnly && (
                            <ProtectedNavLink
                                to={commonPath + (!isTHEPartnerRegistration ? '/faq/start' : '/faq/library')}
                                data-menu-value="faq"
                                className={isFaqActive ? 'nav-link nav-icon-faq is-active' : 'nav-link nav-icon-faq'}
                                data-automation-id="nav-link-faq"
                                licenseName={LicensesEnum.FAQ}
                            >
                                <span className="nav-link-icon">
                                    <svg className="icon">
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#faq'} />
                                    </svg>
                                </span>
                            </ProtectedNavLink>
                        )}

                        {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) && (
                            <NavLink
                                exact
                                to={commonPath + '/sa-list'}
                                data-menu-value="sa"
                                className={
                                    isSaActive ? 'nav-link nav-icon-salist is-active' : 'nav-link nav-icon-salist'
                                }
                                data-automation-id="nav-link-sa"
                            >
                                <span className="nav-link-icon">
                                    <svg className="icon">
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#salist'} />
                                    </svg>
                                </span>
                            </NavLink>
                        )}
                        {!isIdpGroupAdmin && (
                            <NavLink
                                exact
                                to={
                                    commonPath +
                                    (isBuzzOnly
                                        ? '/reporting/time'
                                        : isMenuItemAllowed('reporting_overview', isGroupAdmin, permissions)
                                          ? '/reporting/overview'
                                          : '/reporting/prospects')
                                }
                                data-menu-value="reporting-overview"
                                className={
                                    isReportingActive
                                        ? 'nav-link nav-icon-reporting is-active'
                                        : 'nav-link nav-icon-reporting'
                                }
                                data-automation-id="nav-link-reporting-overview"
                            >
                                <span className="nav-link-icon">
                                    <svg className="icon">
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            xlinkHref={Sprite + '#reporting'}
                                        />
                                    </svg>
                                </span>
                            </NavLink>
                        )}
                        {!isIdpGroupAdmin && !isBuzzOnly && (
                            <ProtectedNavLink
                                exact
                                to={commonPath + '/promotion/pop-card'}
                                className={
                                    isPromotionActive
                                        ? 'nav-link nav-icon-promotion is-active'
                                        : 'nav-link nav-icon-promotion'
                                }
                                data-automation-id="nav-link-promotion"
                                licenseName={LicensesEnum.Promotion}
                            >
                                <span className="nav-link-icon">
                                    <svg className="icon">
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            xlinkHref={Sprite + '#promotion'}
                                        />
                                    </svg>
                                </span>
                            </ProtectedNavLink>
                        )}

                        {(!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) &&
                            canSeeCampaignManagement &&
                            !isBuzzOnly && (
                                <NavLink
                                    exact
                                    to={commonPath + '/campaign-management/campaigns'}
                                    data-menu-value="campaign-management"
                                    className={
                                        isCampaignManagementActive
                                            ? 'nav-link nav-icon-campaign-management is-active'
                                            : 'nav-link nav-icon-campaign-management'
                                    }
                                    data-automation-id="nav-link-campaign-management"
                                >
                                    <span className="nav-link-icon">
                                        <svg className="icon">
                                            <use
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                xlinkHref={Sprite + '#bullseye-regular'}
                                            />
                                        </svg>
                                    </span>
                                </NavLink>
                            )}

                        {!isGroupAdmin && !isBuzzOnly && (
                            <NavLink
                                exact
                                to={{ pathname: 'https://www.theambassadorplatform.com/marketplace' }}
                                target="_blank"
                                className={
                                    isPartnersActive
                                        ? 'nav-link nav-link-marketplace is-active'
                                        : 'nav-link nav-link-marketplace'
                                }
                                data-automation-id="nav-link-marketplace"
                            >
                                <span className="nav-link-icon">
                                    <svg className="icon marketplace">
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            xlinkHref={Sprite + '#store-solid'}
                                        />
                                    </svg>
                                </span>
                            </NavLink>
                        )}
                        {canSeeCommunity ? (
                            <>
                                {((!isGroupAdmin && !isTHEPartnerRegistration) ||
                                    (isGroupAdmin && currentGroupUniversityId)) && (
                                    <NavLink
                                        to={commonPath + '/community/start'}
                                        data-menu-value="community"
                                        className={
                                            isCommunityActive
                                                ? 'nav-link nav-icon-community is-active'
                                                : 'nav-link nav-icon-community'
                                        }
                                        data-automation-id="nav-link-community"
                                    >
                                        <span className="nav-link-icon">
                                            <svg className="icon">
                                                <use
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    xlinkHref={Sprite + '#community'}
                                                />
                                            </svg>
                                        </span>
                                    </NavLink>
                                )}
                            </>
                        ) : null}

                        {!isGroupAdmin && university.showIdpMenu && !isBuzzOnly && (
                            <NavLink
                                exact
                                to={`${commonPath}/idp-activation`}
                                className={
                                    isIdpActivationActive ? 'nav-link nav-link-idp is-active' : 'nav-link nav-link-idp'
                                }
                                data-automation-id="nav-link-idp"
                            >
                                <span className="nav-link-icon">
                                    <svg className="icon idp">
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            xlinkHref={Sprite + '#idp-icon'}
                                        />
                                    </svg>

                                    {university.showIdpMenu === 'blink' && (
                                        <div className="uf-lziqq-beacon right-1">
                                            <div className="uf-lziqq-beacon__around"></div>
                                        </div>
                                    )}
                                </span>
                            </NavLink>
                        )}

                        {isMenuItemAllowed('settings', isGroupAdmin, permissions) && !isIdpGroupAdmin && (
                            <NavLink
                                to={`${commonPath}/settings/${
                                    isGroupAdmin && !currentGroupUniversityId ? 'invite-admins' : 'invite'
                                }`}
                                data-menu-value="settings"
                                className={
                                    isSettingsActive
                                        ? 'nav-link nav-icon-settings is-active'
                                        : 'nav-link nav-icon-settings'
                                }
                                data-automation-id="nav-link-settings"
                            >
                                <span className="nav-link-icon">
                                    <svg className="icon">
                                        <use
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            xlinkHref={Sprite + '#settings'}
                                        />
                                    </svg>
                                </span>
                            </NavLink>
                        )}
                    </div>
                    <NavBottom account={account} />
                </nav>
                <div className={isSubmenuVisible ? 'submenu' : 'hidden-block'}>
                    <h1 className="submenu-name">
                        {twoTierMenu.activeSubmenu === 'faq'
                            ? twoTierMenu.activeSubmenu.toUpperCase()
                            : twoTierMenu.activeSubmenu === 'streams'
                              ? twoTierMenu.activeSubmenu.replace(/-/g, ' ')
                              : twoTierMenu.activeSubmenu === 'campaign-management'
                                ? 'Campaign'
                                : twoTierMenu.activeSubmenu}
                        {twoTierMenu.activeSubmenu === 'campaign-management' ? (
                            <>
                                <br />
                                <span>management</span>
                            </>
                        ) : null}
                    </h1>
                    {submenuLinks}
                </div>
            </>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        twoTierMenu: state.twoTierMenu,
    }
})(withRouter(Navigation))
