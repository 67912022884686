import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

class UploadMessage extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            uploadFiled: false,
        }
    }

    componentDidMount() {
        const stream = this.props.streams[this.props.message.content.key]
        if (stream) {
            this.interval = setInterval(() => {
                this.forceUpdate()
            }, 100)
        } // else if (this.props.message.user_id == this.props.account.account_info.id) this.setState({ uploadFiled: true });
    }

    render() {
        const { account, message, streams } = this.props

        const key = message.content.key
        const stream = streams[key]

        if (!this.state.uploadFiled && !stream) return ''

        const createdAt = dayjs(message.created_at).calendar(null, {
            sameDay: 'H:mm',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday at ]H:mm',
            lastWeek: 'DD MMMM',
            sameElse: 'DD MMMM',
        })

        return (
            <div
                id={message.id}
                className={message.user && message.user.id == account.account_info.id ? 'msg-item mod-my' : 'msg-item'}
            >
                <div className="msg-avatar-block">
                    {message.user && message.user.avatar ? (
                        <img
                            className="msg-item-img"
                            src={message.user.avatar.sizes['70x70'] || message.user.avatar.original}
                        />
                    ) : (
                        <div className="msg-avatar-text">{message.user ? message.user.name.charAt(0) : ''}</div>
                    )}
                </div>
                <div className="msgs-block">
                    <div className="msg-block-item">
                        <p
                            className={
                                message.user && message.user.id == account.account_info.id ? 'hidden-block' : 'msg-name'
                            }
                        >
                            {message.user ? message.user.name : 'Deleted User'}
                        </p>
                        {!this.state.uploadFiled ? (
                            <p className="msg-text msg-test-uploading">
                                Uploading mediafile, please wait
                                <br />
                                <div className="uploaded-line">
                                    <span
                                        className="uploaded-progress"
                                        style={{ width: (stream.nowPathNumber * 100) / stream.count + '%' }}
                                    ></span>
                                </div>
                            </p>
                        ) : (
                            <p className="msg-text msg-test-uploading msg-text-uploading-filed">
                                Uploading mediafile is field
                            </p>
                        )}

                        <div className="msg-controls">
                            <p className="msg-time">{createdAt}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(UploadMessage)
