import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Typography, Row, Col, Button, List, ConfigProvider } from 'antd'
import { slice } from 'ramda'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LIVE_EVENT_STATUS } from '../../../../../../../../../../common/constants'
import {
    openAmbassadorsModal,
    openSpeakersModal,
} from '../../../../../../../../../../redux/actions/live-event/live-events-actions'
import { getOnlineUsers, getAccountInfo } from '../../../../../../../../../../redux/selectors'
import { ParticipantsListItem } from '../index'

const { Text } = Typography

const renderEmpty = () => <></>

const ParticipantsList = ({ activeEvent, participantsType, title }) => {
    const dispatch = useDispatch()
    const accountInfo = useSelector(getAccountInfo)
    const onlineUsers = useSelector(getOnlineUsers)

    const [viewMore, setViewMore] = useState(false)

    const { status } = activeEvent

    const canManageParticipants = useMemo(
        () => status === LIVE_EVENT_STATUS.UPCOMING || status === LIVE_EVENT_STATUS.IN_PROGRESS,
        [status]
    )

    const isProspectsList = useMemo(() => participantsType === 'prospects', [participantsType])

    const users = useMemo(() => activeEvent[participantsType], [activeEvent, participantsType])

    const itemsCount = useMemo(() => users.length, [users])

    const listDataSource = useMemo(() => {
        return viewMore ? users : slice(0, 3, users)
    }, [viewMore, users])

    const handleManageClick = useCallback(() => {
        if (participantsType === 'speakers') {
            dispatch(openSpeakersModal(activeEvent))
        }
        if (participantsType === 'ambassadors') {
            dispatch(openAmbassadorsModal(activeEvent))
        }
    }, [dispatch, participantsType, activeEvent])

    const renderItem = useCallback(
        (user) => {
            const isOnline = onlineUsers.includes(user.id)

            return (
                <List.Item key={user.id}>
                    <ParticipantsListItem user={user} accountInfo={accountInfo} isOnline={isOnline} />
                </List.Item>
            )
        },
        [accountInfo, onlineUsers]
    )

    const handleViewMoreClick = useCallback(() => {
        setViewMore((prevState) => !prevState)
    }, [])

    const viewMoreButton = useMemo(() => {
        if (itemsCount < 4) return <></>

        const buttonText = viewMore ? 'Show less' : 'Show more'
        const buttonIcon = viewMore ? <ArrowUpOutlined /> : <ArrowDownOutlined />

        return (
            <Button type="text" icon={buttonIcon} onClick={handleViewMoreClick}>
                {buttonText}
            </Button>
        )
    }, [viewMore, handleViewMoreClick, itemsCount])

    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col>
                    <Text className="tap-color-primary">
                        {title} ({itemsCount})
                    </Text>
                </Col>
                <Col>
                    {canManageParticipants && !isProspectsList && (
                        <Button type="text" onClick={handleManageClick}>
                            Manage
                        </Button>
                    )}
                </Col>
            </Row>
            <ConfigProvider renderEmpty={renderEmpty}>
                <List itemLayout="horizontal" dataSource={listDataSource} renderItem={renderItem} />
            </ConfigProvider>

            {viewMoreButton}
        </div>
    )
}

export default ParticipantsList
