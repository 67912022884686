import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import store from '../../../redux/store'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { getCommonRoutePath } from '../../../helpers/helpers'
import { ProtectedNavLink } from 'src/app/components/protected-nav-link'
import { LicensesEnum } from 'src/types/general'

class ContentStartGuide extends PureComponent {
    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'content',
            },
        })
    }

    render() {
        const commonPath = getCommonRoutePath()

        return (
            <section className="content settings home-start-guide">
                <h1 className="settings-title">Getting started with Content</h1>
                <div className="start-guide-intro">
                    <p>
                        Provide a rich insight into what the experience of being part of your institution is really like
                        through user-generated content
                    </p>
                </div>

                <div className="start-guide-grid start-guides">
                    <div className="column">
                        <div className="start-block">
                            <span className="point">1</span>
                            <div className="block-body">
                                <h3>Create a content group</h3>
                                <p>
                                    Content groups allow you to collaborate with ambassador to create and organise
                                    content in one place. Create a group, name it so that it’s unique, and add your
                                    ambassadors. Give a short brief, encourage creativity, and give feedback
                                </p>
                                <NavLink className="start-button" to={commonPath + '/content/messaging'}>
                                    Create a content group
                                </NavLink>
                            </div>
                        </div>
                        <div className="start-block">
                            <div className="point">2</div>
                            <div className="block-body">
                                <h3>Set up integrations</h3>
                                <p>
                                    Set up an RSS feed to share content directly to your social media management tools
                                    at the click of a button. Your ambassadors automatically receive notification and
                                    career points when you use their content
                                </p>
                                <ProtectedNavLink
                                    className="start-button"
                                    to={commonPath + '/content/sharing'}
                                    licenseName={LicensesEnum.Sharing}
                                >
                                    Set up an RSS feed
                                </ProtectedNavLink>
                            </div>
                        </div>
                        <div className="start-block">
                            <div className="point">3</div>
                            <div className="block-body">
                                <h3>Download and share</h3>
                                <p>
                                    All posts are curated in the{' '}
                                    <NavLink to={commonPath + '/content/dashboard'}>gallery</NavLink>. Publish directly
                                    to your TAP Feed, share via RSS feed or download to use in campaigns
                                </p>
                                <NavLink className="start-button" to={commonPath + '/content/dashboard'}>
                                    Go to your gallery
                                </NavLink>
                            </div>
                        </div>

                        <div className="callout">
                            If you’d like more advice on creating a user-generated content strategy, send us a message
                            on our customer support chat!
                        </div>
                    </div>

                    <div className="column resources">
                        <div className="video-wrapper">
                            <iframe
                                src="https://player.vimeo.com/video/429954499"
                                width="640"
                                height="360"
                                frameBorder="0"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen={true}
                            ></iframe>
                        </div>

                        <div className="resources-box">
                            <h3>Helpful resources</h3>
                            <ul>
                                <li>
                                    Working with{' '}
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://knowledge.theambassadorplatform.com/top-tips-for-collaborating-with-ambassadors-on-user-generated-content"
                                    >
                                        Ambassadors on User-Generated Content
                                    </a>
                                </li>
                                <li>
                                    Setting up an{' '}
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://knowledge.theambassadorplatform.com/setting-up-rss-feeds-from-the-ambassador-platform"
                                    >
                                        RSS feed
                                    </a>{' '}
                                    on The Ambassador Platform
                                </li>
                                <li>
                                    What is the{' '}
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://knowledge.theambassadorplatform.com/what-is-the-content-schedule-and-how-does-it-work"
                                    >
                                        Content Schedule
                                    </a>{' '}
                                    ?
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(ContentStartGuide)
