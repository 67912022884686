import { Avatar, Skeleton, List, Tooltip } from 'antd'
import dayjs from 'dayjs'
import React from 'react'

import PlaceholderUser from '../../assets/img/placeholder-user.png'
import { NavLink } from 'react-router-dom'
import { getCommonRoutePath } from './helpers'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

const EMPTY_NAME_PLACEHOLDER = 'N/A'

export const nameRow = (name, avatar) => {
    return (
        <Skeleton loading={false} avatar active paragraph={{ rows: 0 }}>
            <List.Item.Meta avatar={<Avatar src={avatar} size={50} />} title={name} />
        </Skeleton>
    )
}

export const formatTime = (data) => {
    const num = data
    const hours = num / 60
    const rhours = Math.floor(hours)
    const minutes = (hours - rhours) * 60
    const rminutes = Math.round(minutes)
    if (rhours > 0) {
        return rhours + ' h' + ' ' + rminutes + ' min'
    }
    return rminutes + ' min'
}

export const formatRating = (middleRate) => {
    const rate = {
        '-1': '😟',
        0: '😐',
        1: '🙂',
    }

    return (
        <div className={`table-item-rate ${middleRate === null && 'table-item-rate-placeholder'} table-item`}>
            {rate[middleRate || 0]}
        </div>
    )
}

export const formatEngagementRatings = (engagementData) => {
    const rate = {
        '-1': '😟',
        0: '😐',
        1: '🙂',
    }
    const arrayRatings = []

    for (const point of engagementData) {
        arrayRatings.push(
            <div className={`table-item-rate ${!point.feedback_score && 'table-item-rate-placeholder'} table-item`}>
                {point.feedback_score != null ? rate[point.feedback_score] : '-'}
            </div>
        )
    }
    return arrayRatings
}

export const formatName = (fullName, isBlocked) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {fullName}
            {isBlocked && <BlockIcon />}
        </div>
    )
}

export const formatHeatScore = (heatScore) => {
    return (
        <div className={`table-item-heat-score ${heatScore < 1 && 'table-item-heat-score-red'} table-item`}>
            {heatScore > 0 ? '+' + heatScore : heatScore < 0 ? heatScore : 0}
        </div>
    )
}

export const timeResponse = (median) => {
    if (median) {
        let seconds = median.seconds
        if (median.minutes) {
            seconds += median.minutes * 60
        }
        if (median.hours) {
            seconds += median.hours * 3600
        }
        if (median.days) {
            seconds += median.days * 86_400
        }
        return seconds
    } else {
        return 0
    }
}

export const timeResponseRow = (median) => {
    if (Object.keys(median).length > 0) {
        let seconds = median.seconds || 0
        if (median.minutes) {
            seconds += median.minutes * 60
        }
        if (median.hours) {
            seconds += median.hours * 3600
        }
        if (median.days) {
            seconds += median.days * 86_400
        }
        return prepareMedianTime(seconds)
    } else {
        return <div>-</div>
    }
}

const prepareMedianTime = (value) => {
    let averageTime = ''

    let seconds = value
    const showMinutes = true
    let showSeconds = true

    const hours = Math.trunc(seconds / 3600)
    if (hours > 0) {
        averageTime += ' ' + hours + ' h'
        showSeconds = false
    }
    seconds -= hours * 3600

    const minutes = Math.trunc(seconds / 60)
    if (showMinutes && minutes > 0) {
        averageTime += ' ' + minutes + ' min'
    }
    seconds -= minutes * 60

    if (showSeconds && seconds > 0 && value < 60) {
        averageTime = 'less than 1 min'
    }
    return averageTime
}

const universityRow = (university) => {
    return (
        <div className="table-university-field">
            <img
                src={
                    university.avatar
                        ? university.avatar.sizes['160x160'] || university.avatar.original
                        : PlaceholderUser
                }
                className="table-university-img"
            />
            {university.name}
        </div>
    )
}

export const prepareTableData = (data, rate, defaultValue) => {
    if (!data || !Array.isArray(data)) {
        return defaultValue
    }
    return data.map((item) => {
        return {
            key: item.id,
            full_name_with_avatar: nameRow(
                item.first_name,
                item.avatar ? item.avatar.sizes['70x70'] || item.avatar.original : null
            ),
            full_name: `${item.first_name} ${item.last_name}`,
            daily_average_usage: formatTime(item.daily_average_usage),
            average: item.daily_average_usage,
            average_messages: item.average_chat_messages,
            content_created: item.total_posts,
            faq_answered: item.total_faq_answers,
            career_points: item.total_career_points,
            average_feedback: formatRating(item.average_feedback),
            middle_rate: item.average_feedback === null ? -2 : Number(item.average_feedback),
            median_response_time: timeResponseRow(item.median_response_time.median),
            median: timeResponse(item.median_response_time.median),
            university: item.university && item.university.name ? universityRow(item.university) : '',
            university_name: item.university && item.university.name ? item.university.name : '',
        }
    })
}

export const preparePagination = (data, defaultValue) => {
    if (!data || !Array.isArray(data)) {
        return defaultValue
    }
    const pagination = {}
    pagination.size = 'small'
    return pagination
}

export const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleDateString()
}

export const DateHumanizeHelper = (date) => {
    return dayjs(date).calendar(null, {
        sameDay: function (now) {
            return dayjs(date).fromNow()
        },
        nextDay: '[Tomorrow]',
        nextWeek: '[Next] dddd',
        lastDay: '[Yesterday]',
        lastWeek: 'dddd',
        sameElse: 'D MMM YYYY',
    })
}

export const formatEngagementData = (engagementData, uniqueId) => {
    const dataArray = []
    const commonPath = getCommonRoutePath()

    for (const item of engagementData) {
        switch (item.type) {
            case 'chat':
                dataArray.push(
                    <div key={uniqueId + '_' + item.id} className="engagement-line">
                        <span>Chat </span>
                        {formatEngagementPointLink(
                            commonPath +
                                '/chat/messaging' +
                                (item.university_id ? '?universityId=' + item.university_id + '&' : '?') +
                                'activeDialog=' +
                                item.id,
                            'with ' + item.name
                        )}
                    </div>
                )
                break
            case 'group-chat':
                dataArray.push(
                    <div key={uniqueId + '_' + item.id} className="engagement-line">
                        <span>Group chat </span>
                        {formatEngagementPointLink(
                            commonPath +
                                '/chat/group-chats' +
                                (item.university_id ? '?universityId=' + item.university_id + '&' : '?') +
                                'activeDialog=' +
                                item.id,
                            item.name
                        )}
                    </div>
                )
                break
            case 'live-stream':
                dataArray.push(
                    <div key={uniqueId + '_' + item.id} className="engagement-line">
                        <span>Live stream </span>
                        {item.status
                            ? formatEngagementPointLink(
                                  commonPath +
                                      '/live-streams/streams' +
                                      (item.university_id ? '?universityId=' + item.university_id : ''),
                                  "'" + item.name + "'"
                              )
                            : ' ' + item.name}
                    </div>
                )
                break
            case 'post':
                dataArray.push(
                    <div key={uniqueId + '_' + item.id} className="engagement-line">
                        {formatEngagementPointLink(
                            commonPath +
                                '/content/dashboard/' +
                                (item.university_id ? '?universityId=' + item.university_id : ''),
                            'Post'
                        )}
                    </div>
                )
                break
            case 'faq':
                dataArray.push(
                    <div key={uniqueId + '_' + item.id} className="engagement-line">
                        {formatEngagementPointLink(
                            commonPath +
                                '/faq/library/' +
                                (item.university_id ? '?universityId=' + item.university_id + '&' : '?') +
                                'scrollFaqId=' +
                                item.id,
                            'Faq'
                        )}
                    </div>
                )
                break
            default:
                break
        }
    }
    return dataArray
}

export const formatEngagementPointLink = (url, name) => {
    if (!url) {
        return ''
    }
    const nameShort = name.length > 25 ? name.slice(0, 25) + '...' : name
    return (
        <Tooltip title={name}>
            <NavLink exact to={url} target="_blank" rel="noopener noreferrer">
                {nameShort}
            </NavLink>
        </Tooltip>
    )
}

export const formatText = (text) => {
    if (!text) {
        return ''
    }
    const name = text.length > 25 ? text.slice(0, 25) + '...' : text
    return (
        <Tooltip title={text}>
            <span className="group-name-popup">{name}</span>
        </Tooltip>
    )
}

export const formatArrayOfUniversities = (engagementData, universities, uniqueId) => {
    const dataArray = []
    const getView = (university, id) => (
        <div key={id} className="engagement-line table-university-field">
            {university && (
                <>
                    <img
                        alt=""
                        src={
                            university.avatar
                                ? university.avatar.sizes['160x160'] || university.avatar.original
                                : PlaceholderUser
                        }
                        className="table-university-img"
                    />
                    <span>{university.name}</span>
                </>
            )}
        </div>
    )
    if (engagementData && engagementData.length > 0) {
        for (const item of engagementData) {
            const university =
                universities && universities.length > 0
                    ? universities.find((uni) => uni.id === item.university_id)
                    : null
            dataArray.push(getView(university, uniqueId + '_' + item.id))
        }
    } else {
        for (const university of universities) {
            dataArray.push(getView(university, university.id))
        }
    }
    return dataArray
}

export const formatArrayUniversitiesOfTotalMessages = (engagementData, uniqueId) => {
    const dataArray = []
    for (const item of engagementData) {
        dataArray.push(
            <div key={uniqueId + '_' + item.id} className="engagement-line">
                <span>{item.total_messages}</span>
            </div>
        )
    }
    return dataArray
}

export const formatArrayOfUniversitiesAllQuestionsAnswered = (engagementData, universities, uniqueId) => {
    const dataArray = []
    const getView = (university, id) => (
        <div key={id} className="engagement-line table-university-field table-university-field-centered">
            <span>{university && university.all_questions_answered ? 'Yes' : 'No'}</span>
        </div>
    )
    if (engagementData && engagementData.length > 0) {
        for (const item of engagementData) {
            const university =
                universities && universities.length > 0
                    ? universities.find((uni) => uni.id === item.university_id)
                    : null
            dataArray.push(getView(university, uniqueId + '_' + item.id))
        }
    } else {
        for (const university of universities) {
            dataArray.push(getView(university, university.id))
        }
    }
    return dataArray
}

export const formatArrayOfUtm = (namesArray, uniqueId, type) => {
    return namesArray.map((item) => (
        <div key={uniqueId + '_' + item.university_id}>
            {type === 'user_came_from' ? formatLink(item[type]) : item[type]}
        </div>
    ))
}

export const formatLink = (url, length = 25) => {
    if (!url) {
        return ''
    }
    const name = url.length > length ? url.slice(0, length) + '...' : url
    return (
        <Tooltip title={url}>
            <a href={url} target="_blank" rel="noopener noreferrer">
                {name}
            </a>
        </Tooltip>
    )
}

export const formatLikelyToApply = (data) => {
    if (data === null || data === undefined) {
        return 'No answer'
    }
    return data ? 'Yes' : 'No'
}

export const adminNameRow = (name, lastName, avatar) => {
    const fullName = name + ' ' + lastName

    return (
        <Skeleton loading={false} avatar active paragraph={{ rows: 0 }}>
            {avatar ? (
                <List.Item.Meta avatar={<Avatar src={avatar} size="large" />} title={fullName} />
            ) : (
                <List.Item.Meta
                    avatar={
                        <Avatar
                            style={{
                                color: '#fff',
                                backgroundColor: '#464d60',
                            }}
                            size="large"
                        >
                            {name && lastName
                                ? name.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
                                : EMPTY_NAME_PLACEHOLDER}
                        </Avatar>
                    }
                    title={fullName}
                />
            )}
        </Skeleton>
    )
}

const BlockIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 3896"
        viewBox="0 0 15.25 15.25"
        className="search-term__block-icon-report"
    >
        <defs>
            <style>
                {`.cls-4, .cls-5 { fill:none; stroke: rgba(34,34,34,0.5); stroke-miterlimit: 10;
                                stroke-width: 1.3px;} .cls-5 {
                                    stroke-linecap: round;
                                }`}
            </style>
        </defs>
        <circle cx="11" cy="11" r="3.6" className="cls-4"></circle>
        <path d="M8.47 13.55L13.66 8.36" className="cls-4"></path>
        <rect width="4.22" height="5.66" x="2.79" y="0.67" className="cls-4" rx="2.11" ry="2.11"></rect>
        <path
            d="M7.81 13.32H2A1.31 1.31 0 01.69 12v-1.38A3 3 0 012.8 7.71M7 7.71a2.93 2.93 0 011.2.73"
            className="cls-5"
        ></path>
    </svg>
)
