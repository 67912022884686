import {
    CLOSE_UNIVERSITY_NAME_CHANGED_MODAL,
    OPEN_UNIVERSITY_NAME_CHANGED_MODAL,
} from '../../../actions/account/accountEditActions'

import { UNIVERSITY_NAME_CHANGED_MODAL } from '../../../store/initialState'

const initialState = UNIVERSITY_NAME_CHANGED_MODAL

export default function (state = initialState, action) {
    const type = action.type

    switch (type) {
        case CLOSE_UNIVERSITY_NAME_CHANGED_MODAL: {
            let new_state = Object.assign({}, state)
            new_state.isOpened = false
            return new_state
        }

        case OPEN_UNIVERSITY_NAME_CHANGED_MODAL: {
            let new_state = Object.assign({}, state)
            new_state.isOpened = true
            return new_state
        }

        default: {
            return state
        }
    }
}
