import React from 'react'

const SetupTitle = ({ blockTitle, blockDescription, title }) => (
    <>
        <div>
            <h1 className="settings-title trial">{title}</h1>
            {blockDescription && <div>{blockDescription}</div>}
        </div>
        <div className="setup-start-block-title">{blockTitle}</div>
    </>
)

export default SetupTitle
