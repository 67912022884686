import { CAMPAIGN } from './campaign-action-types'

export const openCampaignTypeModal = (payload = {}) => ({
    type: CAMPAIGN.OPEN_CAMPAIGN_TYPE_MODAL,
})

export const closeCampaignTypeModal = (payload = {}) => ({
    type: CAMPAIGN.CLOSE_CAMPAIGN_TYPE_MODAL,
    payload,
})

export const createCampaign = (payload = {}, onSuccess) => ({
    type: CAMPAIGN.CREATE_CAMPAIGN,
    payload,
    meta: {
        onSuccess,
    },
})

export const fetchCampaigns = (payload) => ({
    type: CAMPAIGN.FETCH,
    payload,
})

export const fetchCampaignsById = (payload) => ({
    type: CAMPAIGN.FETCH_CAMPAIGN_BY_ID,
    payload,
})

export const resetActiveCampaign = () => ({
    type: CAMPAIGN.RESET_ACTIVE_CAMPAIGN,
})
