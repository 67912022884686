import HttpService from '../../system/services/HttpService'
import { getUserFingerprint, isMobile } from '../../helpers/helpers'

export default {
    login: (email, password) => {
        return HttpService.post(
            '/dashboard/auth/login',
            {
                email: email,
                password: password,
                fingerprint: getUserFingerprint(),
                accessType: isMobile() ? 'mobile_web' : 'web',
            },
            true
        )
    },
    ssoLogin: (email, ssoCode) => {
        return HttpService.post(
            '/dashboard/auth/ssoLogin',
            {
                email,
                ssoCode,
                fingerprint: getUserFingerprint(),
                accessType: isMobile() ? 'mobile_web' : 'web',
            },
            true
        )
    },
    getUniversitySsoData: (email) => {
        return HttpService.get('/dashboard/auth/universitySsoData', { email })
    },
    getHubspotLoginToken: () => {
        return HttpService.get('/dashboard/account/hubspotLogin')
    },
    get: () => {
        return HttpService.get('/dashboard/account/info')
    },
    updateAccountInfo: (data) => {
        return HttpService.post('/dashboard/account/info', data)
    },
    changeEmailSendLetter: () => {
        return HttpService.get('/dashboard/account/edit/email')
    },
    changePasswordSendLetter: () => {
        return HttpService.get('/dashboard/account/edit/password')
    },
    changeEmail: (email, verificationCode) => {
        return HttpService.post('/dashboard/account/edit/email', {
            email: email,
            verification_code: verificationCode,
        })
    },
    changePassword: (oldPassword, newPassword, confirmPassword, verificationCode) => {
        return HttpService.post('/dashboard/account/edit/password', {
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword,
            verification_code: verificationCode,
        })
    },
    changeAvatar: (avatar) => {
        return HttpService.post('/dashboard/account/edit/avatar', {
            file: avatar,
        })
    },
    registration: (registrationData) => {
        registrationData.fingerprint = getUserFingerprint()
        registrationData.accessType = isMobile() ? 'mobile_web' : 'web'
        return HttpService.post('/dashboard/auth/registration', registrationData, true)
    },
    groupAccountRegistration: (registrationData) => {
        registrationData.fingerprint = getUserFingerprint()
        registrationData.accessType = isMobile() ? 'mobile_web' : 'web'
        return HttpService.post('/dashboardGroup/auth/registration', registrationData, true)
    },
    getUserRecover: (email) => {
        return HttpService.post('/dashboard/account/recovery/password', {
            email: email,
        })
    },
    recoverConfirm: (hex, userId, newPassword, confirmPassword) => {
        return HttpService.post('/dashboard/account/recovery/passwordConfirm', {
            user_id: userId,
            hex: hex,
            new_password: newPassword,
            confirm_password: confirmPassword,
        })
    },
    changeUniversityAvatar: (avatar, universityId) => {
        return HttpService.post('/dashboard/account/edit/avatar-university', {
            file: avatar,
            universityId: universityId,
        })
    },
    changeUniGroupAvatar: (avatar) => {
        return HttpService.post('/dashboardGroup/groups/edit/avatar', {
            file: avatar,
        })
    },
    changeUniversityName: (universityName, universityId) => {
        const data = { university_name: universityName }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/edit/name', data)
    },
    changeDisplayName: (displayName, universityId) => {
        const data = { display_name: displayName }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/edit/displayName', data)
    },
    changeUniGroupName: (name) => {
        return HttpService.post('/dashboardGroup/groups/edit/name', {
            name: name,
        })
    },
    changeUniversityFirstName: (firstName, universityId) => {
        const data = { firstName: firstName }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/account/edit/firstName', data)
    },

    changeUniversityLastName: (lastName, universityId) => {
        const data = { lastName: lastName }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/account/edit/lastName', data)
    },
    changePrivacyPolicy: (privacyPolicy, universityId) => {
        const data = { privacy_policy: privacyPolicy }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.put('/dashboard/universities/privacy-policy', data)
    },
    setActiveTime: (time) => {
        // return HttpService.post('/dashboard/account/activity', {
        //     time: time
        // });
    },
    testUniversityToggle: () => {
        return HttpService.put('/dashboard/universities/test/toggle')
    },
    checkAdminInvitation: (data) => {
        return HttpService.post('/dashboard/auth/checkAdminInvitation', data)
    },
    checkGroupAdminInvitation: (data) => {
        return HttpService.post('/dashboardGroup/auth/checkAdminInvitation', data)
    },
    checkIfPlsAmbassadorLoggedIn: (userId) => {
        return HttpService.get(`/dashboard/users/${userId}/checkLogIn`)
    },
    getPlsTapFeedLink: (data) => {
        return HttpService.put(`/dashboard/universities/slug`, data)
    },
    checkIfPlsProspectMessageSent: () => {
        return HttpService.get(`/dashboard/account/checkProspectMessageSent`)
    },
    changeOrganisationWebsite: ({ websiteUrl, institutionId }) => {
        return HttpService.put(`/dashboard/universities/websiteUrl`, { websiteUrl, institutionId })
    },
    getProfileTypes: () => {
        return HttpService.get(`/dashboard/account/profileTypes`)
    },
    setProfileType: ({ profileTypeId }) => {
        return HttpService.post(`/dashboard/account/profileType`, { profileTypeId })
    },
    experienceBasedQuestionsToggle: ({ allowedType, universityId }) => {
        const data = { toggle: allowedType }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.put('/dashboard/universities/experienceBasedQuestions', data)
    },
    OTPVerificationToggle: ({ universityId }) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.put('/dashboard/universities/phoneVerificationToggle', data)
    },
    thirdPartyAuthToggle: ({ universityId, isEnabled, permissionKey }) => {
        const data = { isEnabled, permissionKey }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.put('/dashboard/universities/toggleSocialAuth', data)
    },
    thirdPartyAuthGroupToggle: ({ universityId, isEnabled, permissionKey }) => {
        const data = { isEnabled, permissionKey }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboardGroup/groups/toggleSocialAuth ', data)
    },
    flagsToggle: ({ universityId, isEnabled }) => {
        const data = { isEnabled }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.put('/dashboard/universities/toggleAmbassadorFlags', data)
    },
    flagsGroupToggle: ({ universityId, isEnabled }) => {
        const data = { isEnabled }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.put('/dashboardGroup/groups/toggleAmbassadorFlags ', data)
    },
    logout: (token) => {
        return HttpService.post('/dashboard/auth/logout', {
            token: token,
            accessType: isMobile() ? 'mobile_web' : 'web',
        })
    },

    setMarketingConsent: ({ reqestData, universityId }) => {
        const data = { ...reqestData }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/marketingConsent', data)
    },
    setMarketingConsentText: ({ reqestData, universityId }) => {
        const data = { ...reqestData }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/marketingConsentText', data)
    },
}
