import React from 'react'
import { reduxForm, Field, FieldArray, isValid } from 'redux-form'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'
import { ReactComponent as Close } from '../../../../../assets/svg/icons/close.svg'
import store from '../../../../redux/store'
import { connect } from 'react-redux'
import API from '../../../../api'

import { SEND_ADMIN_INVITATION } from '../../../../redux/actions/settings/admins'

const validate = (values) => {
    const errors = {}

    function checkDuplicateEmail(arr) {
        let result = false
        result = arr.some((element, index) => {
            return arr.indexOf(element) !== index
        })
        if (result && arr.length > 0) {
            return true
        } else {
            return false
        }
    }

    if (!values.email) {
        errors.email = 'Enter email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    if (!values.confirm_email) {
        errors.confirm_email = 'Confirm email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.confirm_email)) {
        errors.confirm_email = 'Invalid email address'
    }

    if (values.confirm_email != values.email) {
        errors.confirm_email = 'Emails do not match'
    }

    if (values.admins) {
        const adminsArrayErrors = []
        const adminsArrayEmails = []
        values.admins.forEach((admin, adminIndex, arr) => {
            const adminErrors = {}
            if (!admin || !admin.email) {
                adminErrors.email = 'Enter email'
                adminsArrayErrors[adminIndex] = adminErrors
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(admin.email)) {
                adminErrors.email = 'Invalid email address'
                adminsArrayErrors[adminIndex] = adminErrors
            }

            if (!admin || !admin.confirm_email) {
                adminErrors.confirm_email = 'Confirm email'
                adminsArrayErrors[adminIndex] = adminErrors
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(admin.confirm_email)) {
                adminErrors.confirm_email = 'Invalid email address'
                adminsArrayErrors[adminIndex] = adminErrors
            }
            if (admin.confirm_email !== admin.email) {
                adminErrors.confirm_email = 'Emails do not match'
                adminsArrayErrors[adminIndex] = adminErrors
            }

            if (admin.email && values.email && admin.email === values.email) {
                adminErrors.email = 'This email has already been entered'
                adminsArrayErrors[adminIndex] = adminErrors
            }

            if (arr[adminIndex] && arr[adminIndex].email) {
                adminsArrayEmails.push(arr[adminIndex].email)
                if (checkDuplicateEmail(adminsArrayEmails)) {
                    adminErrors.email = 'This email has already been entered'
                    adminsArrayErrors[adminIndex] = adminErrors
                }
            }
        })
        if (adminsArrayErrors.length) {
            errors.admins = adminsArrayErrors
        }
    }
    return errors
}

const asyncValidate = (values, fn, props, index) => {
    let indexNumber
    let matches
    if (index) {
        matches = index.match(/\[(.*?)\]/)
    }

    if (matches) {
        indexNumber = matches[1]
    }

    if (indexNumber) {
        if (values.admins[indexNumber].email) {
            return API.settings.checkEmailExists(values.admins[indexNumber].email).then((resp) => {
                if (resp.success) {
                    throw {
                        admins: {
                            [indexNumber]: { email: 'This email is already registered' },
                        },
                    }
                }
            })
        } else {
            return new Promise((resolver) => {
                resolver()
            })
        }
    }
    if (values.email) {
        return API.settings.checkEmailExists(values.email).then((resp) => {
            if (resp.success) {
                throw {
                    email: 'This email is already registered',
                }
            }
        })
    }
}

const trimValue = (value) => value && value.trim()

const renderField = ({ input, placeholder, type, className, name, meta: { touched, error, warning } }) => {
    const hasError = !!(touched && (error || warning))
    return (
        <div className="input-block-field">
            <div className="input-icon-block">
                <input
                    {...input}
                    className={className}
                    placeholder={placeholder}
                    type={type}
                    name={name}
                    autoComplete="new-password"
                    style={touched ? { borderColor: hasError ? '#dc2e2e' : '#4cd964' } : null}
                    onCopy={(e) => e.preventDefault()}
                />
            </div>
            {touched && !error ? (
                <div className="validation-icon-container">
                    <div className="validation-icon-wrapper">
                        <svg className="icon validation-icon">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#validation'}></use>
                        </svg>
                    </div>
                </div>
            ) : null}
            {touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    )
}

const renderRows = ({ fields, valid, meta: { error, submitFailed } }) => {
    return (
        <div>
            <div className="table-row">
                <h3>Admin 1</h3>
                <label className="signin-label">
                    <span className="signin-label-text">Email</span>
                    <Field
                        name={'email'}
                        component={renderField}
                        type="text"
                        placeholder="Enter email"
                        className="signin-input"
                        normalize={trimValue}
                    />
                </label>
                <label className="signin-label">
                    <span className="signin-label-text">Confirm email</span>
                    <Field
                        name={'confirm_email'}
                        component={renderField}
                        type="text"
                        placeholder="Confirm email"
                        className="signin-input"
                        normalize={trimValue}
                    />
                </label>
            </div>
            {fields.map((admin, index) => (
                <div key={index} className="table-row">
                    <div className="table-row-header">
                        <h3>Admin {index + 2}</h3>
                        <Close onClick={() => fields.remove(index)} className="icon icon-close icon-close--settings" />
                    </div>
                    <label className="signin-label">
                        <span className="signin-label-text">Email</span>
                        <Field
                            name={`${admin}.email`}
                            component={renderField}
                            type="text"
                            placeholder="Enter email"
                            className="signin-input"
                            normalize={trimValue}
                        />
                    </label>
                    <label className="signin-label">
                        <span className="signin-label-text">Confirm email</span>
                        <Field
                            name={`${admin}.confirm_email`}
                            component={renderField}
                            type="text"
                            placeholder="Confirm email"
                            className="signin-input"
                            normalize={trimValue}
                        />
                    </label>
                </div>
            ))}
            <div className="add-admin-btn-container">
                <button
                    type="submit"
                    className="settings-btn js-show-form"
                    onClick={() => (valid ? fields.push({}) : null)}
                >
                    + Add new admin
                </button>
            </div>
        </div>
    )
}

export const InviteAdminPopupContent = (props) => {
    const { handleSubmit, account, valid } = props
    const onAddAmbassador = (val) => {
        const emails = [val.email]
        if (val.admins && val.admins.length > 0) {
            val.admins.map((item) => emails.push(item.email))
        }

        store.dispatch({
            type: SEND_ADMIN_INVITATION,
            payload: {
                emails: emails,
                universityId: account.account_info.university.id,
            },
        })

        props.dispatch(props.reset('add_admin_form'))
    }

    return (
        <div className="invite-admin-popup">
            <h2 className="modal-title">Invite new admins</h2>
            <p className="modal-explainer">To invite a new admin, simply add their email below.</p>
            <>
                <form className="signin-form registration-form" onSubmit={handleSubmit(onAddAmbassador)}>
                    <div>
                        <FieldArray name="admins" component={renderRows} valid={valid} />
                    </div>
                    <div className="send-invitation-btn-container">
                        <button type="submit" className="el-btn">
                            Send invitation
                        </button>
                    </div>
                </form>
            </>
        </div>
    )
}

export default reduxForm({
    form: 'add_admin_form',
    destroyOnUnmount: false,
    validate,
    asyncValidate,
    asyncBlurFields: ['email', 'admins[].email'],
})(
    connect((state) => {
        return {
            account: state.account,
            valid: isValid('add_admin_form')(state),
        }
    })(InviteAdminPopupContent)
)
