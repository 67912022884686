var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { App } from 'antd';
import React from 'react';
import { Button } from '../base/Button';
import { licenseModal } from '../modals/LicenseModal';
import { useAppSelector } from 'src/app/hooks/store';
import { selectIsSascOrIdp } from 'src/app/redux/selectors';
export var ProtectedButton = function (props) {
    var modal = App.useApp().modal;
    var isSascOrIdp = useAppSelector(selectIsSascOrIdp);
    var handleClick = function (event) {
        if (isSascOrIdp) {
            event.stopPropagation();
            event.preventDefault();
            modal.warning(licenseModal(props.licenseName));
        }
        else {
            props.onClick(event);
        }
    };
    return (React.createElement(Button, __assign({}, props, { type: props.type, onClick: handleClick }), props.children));
};
