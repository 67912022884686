import { Form } from 'antd'
import React from 'react'

import CustomSelect from './custom-select'

const GlobalTagsSelect = () => {
    return (
        <Form.Item name="globalTagsIds" label="Tags">
            <CustomSelect />
        </Form.Item>
    )
}

export default GlobalTagsSelect
