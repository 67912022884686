export const GET_SAFEGUARDING_DATA = 'GET_SAFEGUARDING_DATA'
export const GET_SAFEGUARDING_DATA_SUCCESS = 'GET_SAFEGUARDING_DATA_SUCCESS'

export const KEYWORD_DETECTION_SET = 'KEYWORD_DETECTION_SET'
export const KEYWORD_DETECTION_SET_SUCCESS = 'KEYWORD_DETECTION_SET_SUCCESS'
export const KEYWORD_DETECTION_SET_FAILED = 'KEYWORD_DETECTION_SET_FAILED'

export const SET_DEFAULT_BADWORDS = 'SET_DEFAULT_BADWORDS'

export const ADD_CUSTOM_BADWORD = 'ADD_CUSTOM_BADWORD'
export const DELETE_CUSTOM_BADWORD = 'DELETE_CUSTOM_BADWORD'
export const DISABLE_BUTTON = 'DISABLE_BUTTON'

export const ADD_WHITELISTED_BADWORD = 'ADD_WHITELISTED_BADWORD'
export const DELETE_WHITELISTED_BADWORD = 'DELETE_WHITELISTED_BADWORD'

export const SHOW_SAVE_BUTTON = 'SHOW_SAVE_BUTTON'
export const SHOW_CONFIRMATION_POPUP = 'SHOW_CONFIRMATION_POPUP'

export const UPLOAD_BADWORDS_CSV = 'UPLOAD_BADWORDS_CSV'
export const UPLOAD_BADWORDS_CSV_SUCCESS = 'UPLOAD_BADWORDS_CSV_SUCCESS'
export const UPLOAD_BADWORDS_CSV_FAILED = 'UPLOAD_BADWORDS_CSV_FAILED'

export const CHANGE_PROSPECT_MESSAGING_ACTIVITY = 'CHANGE_PROSPECT_MESSAGING_ACTIVITY'
export const CHANGE_PROSPECT_MESSAGING_ACTIVITY_SUCCESS = 'CHANGE_PROSPECT_MESSAGING_ACTIVITY_SUCCESS'
