import { Popover } from 'antd'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as OptionsIcon } from '../../../../../../assets/svg/icons/options.svg'
import { ChatType } from '../../../../../common/constants'
import ReportingPopover from '../../../../../components/ReportingPopover'
import {
    ARCHIVE_DIALOG_TOGGLE,
    CLOSE_DIALOG,
    GET_CHAT_TRANSCRIPT_DATA,
    PIN_CHAT,
} from '../../../../../redux/actions/chat/messagingActions'
import {
    CLOSE_ACTION_MODAL,
    CLOSE_SETTINGS_MESSAGING_POPUP,
    OPEN_ACTION_MODAL,
} from '../../../../../redux/actions/components/componentsActions'
import store from '../../../../../redux/store'
import config from '../../../../../system/config'

class Header extends PureComponent {
    constructor() {
        super()

        this.state = {
            visible: false,
        }

        this.handleOpenSettingsPopup = this.handleOpenSettingsPopup.bind(this)
        this.openActivateChatPopup = this.openActivateChatPopup.bind(this)
        this.copyLink = this.copyLink.bind(this)
        this.handleCloseConfirmPopup = this.handleCloseConfirmPopup.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
    }

    handleOpenSettingsPopup() {
        this.setState({ visible: false })
        this.props.handleOpenSettingsPopup()
    }

    openActivateChatPopup() {
        this.setState({ visible: false })
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button type="submit" className="el-btn confirm-delete-button" onClick={this.closeDialog}>
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={this.handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    copyLink(chat) {
        const { account } = this.props
        this.setState({ visible: false })

        if (account.account_info.university || account.account_info.universityGroup) {
            let copyText = ''
            if (account.account_info.universityGroup) {
                copyText = account.account_info.universityGroup.tap_page_url
                    ? account.account_info.universityGroup.tap_page_url +
                      '?dialog_id=' +
                      chat.id +
                      (chat.is_private && chat.private_code ? '&dialog_code=' + chat.private_code : '')
                    : config.tap_page_url +
                      'group/' +
                      account.account_info.universityGroup.id +
                      '/interaction/dialogs/' +
                      chat.id +
                      (chat.is_private && chat.private_code ? '?dialog_code=' + chat.private_code : '')
            } else if (account.account_info.university.university_url) {
                copyText =
                    account.account_info.university.university_url +
                    '?dialog_id=' +
                    chat.id +
                    (chat.is_private && chat.private_code ? '&dialog_code=' + chat.private_code : '')
            } else {
                copyText =
                    config.tap_page_url +
                    account.account_info.university.id +
                    '/interaction/dialogs/' +
                    chat.id +
                    (chat.is_private && chat.private_code ? '?dialog_code=' + chat.private_code : '')
            }
            const tempInput = document.createElement('input')
            tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px'
            tempInput.value = copyText
            document.body.append(tempInput)
            tempInput.select()
            document.execCommand('copy')
            tempInput.remove()
            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Group Chat link copied to clipboard',
                },
            })
        }
    }

    handleCloseConfirmPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    pinChat(chat) {
        const onSuccess = () => {
            const element = document.querySelector('#chat-nav')
            element.scrollTop = 0

            this.setState({
                visible: false,
            })

            store.dispatch({
                type: CLOSE_SETTINGS_MESSAGING_POPUP,
            })

            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    children: (
                        <div className="general-popup chat-mute-success-popup">
                            <h2 className="modal-body">Chat is {!chat.is_pinned ? '' : 'un'}pinned now</h2>
                            <div className="btn-wrap confirm-mute-buttons">
                                <button
                                    type="button"
                                    className="el-btn cancel-delete-button"
                                    onClick={this.handleCloseConfirmPopup}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    ),
                },
            })
        }
        store.dispatch({
            type: PIN_CHAT,
            payload: {
                dialogId: chat.id,
                type_chat: ChatType.GROUP_CHAT,
                is_pinned: !chat.is_pinned,
            },
            onSuccess,
        })
    }

    downloadTranscripts = () => {
        const { chats, dialogId } = this.props
        const chat = chats.items.find((item) => +item.id === +dialogId) || {}

        // eslint-disable-next-line unicorn/consistent-function-scoping
        const onSuccess = (response) => {
            setTimeout(() => {
                const link = document.createElement('a')
                link.download = response.csvURL
                link.href = response.csvURL
                document.body.append(link)
                link.click()
                link.remove()
            }, 1000)
        }

        store.dispatch({
            type: GET_CHAT_TRANSCRIPT_DATA,
            payload: {
                dialogId: chat.id,
            },
            onSuccess: onSuccess,
        })
    }

    closeDialog() {
        const { chats, dialogId } = this.props
        const chat = chats.items.find((item) => +item.id === +dialogId) || {}

        const onSuccess = () => {
            this.setState({
                chat: {
                    ...chat,
                    closed: !chat.closed,
                },
                visible: false,
            })

            store.dispatch({
                type: CLOSE_SETTINGS_MESSAGING_POPUP,
            })
        }
        store.dispatch({
            type: CLOSE_DIALOG,
            payload: {
                dialogId: chat.id,
                type_chat: ChatType.GROUP_CHAT,
            },
            onSuccess,
        })
    }

    archive(chat) {
        const element = document.querySelector('#chat-nav')
        element.scrollTop = 0

        this.setState({
            visible: false,
        })

        store.dispatch({
            type: CLOSE_SETTINGS_MESSAGING_POPUP,
        })

        const onConfirm = () => {
            store.dispatch({
                type: ARCHIVE_DIALOG_TOGGLE,
                payload: {
                    dialogId: chat.id,
                    withGlobalTags: true,
                    type_chat: ChatType.GROUP_CHAT,
                },
            })
        }

        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button type="submit" className="el-btn confirm-delete-button" onClick={onConfirm}>
                                Yes, I am sure
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={this.handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    handleVisibleChange = (visible) => {
        this.setState({ visible })
    }

    render() {
        const { visible } = this.state
        const { chats, dialogId } = this.props
        const chat = chats.items.find((item) => +item.id === +dialogId) || {}
        return (
            <>
                <div className="chat-head">
                    <div className="chat-head-left">
                        <div className="chat-name-block chat-name-block--chat">
                            <div className="chat-name-block__info">
                                <div className="chat-nav-name-container">
                                    <h3 className="chat-name">{chat.name}</h3>
                                    {chat.reported ? (
                                        <ReportingPopover dialogId={chat.id} chatType={ChatType.GROUP_CHAT} />
                                    ) : null}
                                </div>
                                <span className="chat-status">
                                    {chat.closed ? 'Group Chat is inactive' : 'Group Chat is active'}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="chat-head-right">
                        <Popover
                            placement="bottomRight"
                            trigger="click"
                            open={visible}
                            onOpenChange={this.handleVisibleChange}
                            className="options-icon-drop"
                            content={
                                <div className="options-drop-block">
                                    <p className="options-drop-item" onClick={this.handleOpenSettingsPopup}>
                                        Edit Group Chat
                                    </p>
                                    <p className="options-drop-item" onClick={this.openActivateChatPopup}>
                                        {chat.closed ? 'Activate ' : 'Deactivate '}Group Chat
                                    </p>
                                    <p className="options-drop-item" onClick={() => this.pinChat(chat)}>
                                        {chat.is_pinned ? 'Unpin ' : 'Pin '}Group Chat
                                    </p>
                                    <p className="options-drop-item" onClick={() => this.copyLink(chat)}>
                                        Copy Group Chat link
                                    </p>
                                    <p className="options-drop-item" onClick={this.downloadTranscripts}>
                                        Download chat transcripts
                                    </p>
                                    {chat.closed && (
                                        <p className="options-drop-item" onClick={() => this.archive(chat)}>
                                            Archive
                                        </p>
                                    )}
                                </div>
                            }
                        >
                            <OptionsIcon />
                        </Popover>
                    </div>
                </div>
            </>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        chats: state.chats,
    }
})(Header)
