import { Avatar, Skeleton, List } from 'antd'
import React from 'react'

const NameCell = ({ record }) => {
    return (
        <Skeleton loading={false} avatar active paragraph={{ rows: 0 }}>
            <List.Item.Meta
                avatar={
                    <Avatar
                        src={record.avatar ? record.avatar.sizes['70x70'] || record.avatar.original : null}
                        size={50}
                    />
                }
                title={record.full_name}
            />
        </Skeleton>
    )
}

const nameCellRenderer = (text, record) => <NameCell record={record} />

export default nameCellRenderer
