import types from '../../actions/reporting/overviewActions'

const initialState = { old: {}, summary: {}, chat: {}, content: {}, faq: {} }

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case types.UNIVERSITY_STATISTICS_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.old = payload
            return newState
        }

        case types.OVERVIEW_SUMMARY_STATISTICS_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.summary = payload
            return newState
        }

        case types.OVERVIEW_CHAT_STATISTICS_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.chat = payload
            return newState
        }

        case types.OVERVIEW_CONTENT_STATISTICS_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.content = payload
            return newState
        }

        case types.OVERVIEW_FAQ_STATISTICS_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.faq = payload
            return newState
        }

        default: {
            return state
        }
    }
}
