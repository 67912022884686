var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Input, Tag } from 'antd';
import React, { useEffect, useRef } from 'react';
import { concatClsx } from '../../../helpers/classNames';
import { Button } from '../Button';
import './Search.scss';
var SearchInput = Input.Search;
export var TagsSearch = function (_a) {
    var className = _a.className, onChange = _a.onChange, values = _a.values;
    var _b = React.useState(''), searchValue = _b[0], setSearchValue = _b[1];
    var _c = React.useState(values || []), searchValues = _c[0], setSearchValues = _c[1];
    var onChangeRef = React.useRef(onChange);
    var isMountRef = useRef(false);
    // Add animation to list of tags
    var parent = useAutoAnimate()[0];
    var handleSearch = function (value) {
        var valToAdd = value.trim();
        if (valToAdd && !searchValues.includes(valToAdd)) {
            setSearchValues(function (oldValues) { return __spreadArray(__spreadArray([], oldValues, true), [valToAdd], false); });
        }
        setSearchValue('');
    };
    useEffect(function () {
        var _a;
        if (isMountRef.current) {
            (_a = onChangeRef.current) === null || _a === void 0 ? void 0 : _a.call(onChangeRef, searchValues);
        }
        else {
            isMountRef.current = true;
        }
    }, [searchValues]);
    useEffect(function () {
        if (values) {
            setSearchValues(values);
        }
    }, [values]);
    var handleRemoveItem = function (index) {
        setSearchValues(function (oldValues) { return oldValues.filter(function (_, i) { return i !== index; }); });
    };
    return (React.createElement("div", { className: concatClsx('flex flex-col', className) },
        React.createElement(SearchInput, { allowClear: true, className: "tap-search", size: "large", placeholder: "Search", value: searchValue, onSearch: handleSearch, onChange: function (e) {
                setSearchValue(e.target.value);
            } }),
        React.createElement("div", { ref: parent, className: "mt-4 empty:mt-0" },
            searchValues.map(function (value, index) { return (React.createElement(Tag, { key: value, closable: true, className: "my-1 bg-tap-dark text-white rounded-full [&_svg]:fill-white", onClose: function (e) {
                    e.preventDefault();
                    handleRemoveItem(index);
                } }, value)); }),
            searchValues.length > 0 && (React.createElement(Button, { type: "text", size: "small", onClick: function () { return setSearchValues([]); } }, "Clear All")))));
};
