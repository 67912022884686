import { OPEN_CHANGE_EMAIL_MODAL, CLOSE_CHANGE_EMAIL_MODAL } from '../../../actions/account/accountActions'

import { CHANGE_EMAIL_MODAL } from '../../../store/initialState'

const initialState = CHANGE_EMAIL_MODAL

export default function (state = initialState, action) {
    const type = action.type

    switch (type) {
        case OPEN_CHANGE_EMAIL_MODAL: {
            let newState = {
                ...state,
                isOpened: true,
            }

            return newState
        }

        case CLOSE_CHANGE_EMAIL_MODAL: {
            let newState = {
                ...state,
                isOpened: false,
            }

            return newState
        }

        default: {
            return state
        }
    }
}
