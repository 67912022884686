import { Form, Avatar } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

import { getPlsWizardSetup } from '../../../../../redux/selectors/account-selectors'

const EMPTY_NAME_PLACEHOLDER = 'N/A'

const UserAvatar = ({ user }) => {
    const { avatar, name } = user

    if (avatar) {
        return <Avatar style={{ margin: 0 }} size={35} src={avatar.sizes['70x70'] || avatar.original} />
    }

    return (
        <Avatar style={{ margin: 0 }} size={35}>
            {name || EMPTY_NAME_PLACEHOLDER}
        </Avatar>
    )
}

const AmbassadorActivationForm = ({
    form,
    initialValues,
    prevStep,
    handleActivationToggle,
    onSubmit,
    loggedInValidator,
}) => {
    const plsWizardSetup = useSelector(getPlsWizardSetup)
    const { isAmbassadorActivated, name, lastName } = plsWizardSetup.ambassador

    return (
        <div className="settings-item">
            <label className="settings-name">
                3. Activate yourself as an ambassador on the TAP Feed{' '}
                <span className="label-rest-text">
                    using the toggle below (you’ll need to approve each of your real ambassadors from the dashboard
                    later)
                </span>
            </label>
            <Form
                form={form}
                onFinish={onSubmit}
                layout="vertical"
                autoComplete="off"
                initialValues={initialValues}
                hideRequiredMark
            >
                <Form.Item
                    name="ambassadorActivationToggle"
                    className="form-item toggle"
                    label={null}
                    rules={[
                        { validator: loggedInValidator },
                        { required: true, message: 'You should activate your ambassador' },
                    ]}
                >
                    <div className="form-item-toggle">
                        <UserAvatar user={{ avatar: null, name: `${name[0]} ${lastName[0]}` }} />
                        <div>{`${name} ${lastName}`}</div>
                        <div>
                            <label className="slideOne js-holder">
                                <input
                                    type="checkbox"
                                    name="check"
                                    data-ban-type="general"
                                    checked={isAmbassadorActivated}
                                    value={isAmbassadorActivated ? '' : '1'}
                                    onChange={handleActivationToggle}
                                />
                                <span className="slideOne-icon" />
                            </label>
                        </div>
                    </div>
                </Form.Item>
                <div className="setup-steps-buttons">
                    <button className="previous-step-button" onClick={prevStep}>
                        Previous
                    </button>
                    <button type="submit">Next</button>
                </div>
            </Form>
        </div>
    )
}

export default AmbassadorActivationForm
