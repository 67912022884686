/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { roles } from '../../../../../helpers/helpers'
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions'
import { ExternalSpeakers, ScheduledUsersSelect } from '../../index'

import SpeakersFormModel from './speakers-form-model'

const SpeakersForm = ({ form, data }) => {
    const dispatch = useDispatch()
    const initialValues = useMemo(() => SpeakersFormModel(data), [data])
    const [hasFeedback, setHasFeedback] = useState({ 0: { email: false, confirmEmail: false } })

    const {
        meta: { selectedInternalSpeakers },
    } = initialValues

    useEffect(() => {
        form.resetFields()
    }, [initialValues])

    useEffect(() => {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
                roles: [roles.student, roles.admin],
            },
        })
    }, [])

    return (
        <Form form={form} initialValues={initialValues} layout="vertical">
            <ScheduledUsersSelect
                id="internalSpeakerIds"
                label="Internal speakers"
                roles={[roles.admin, roles.student]}
                form={form}
                rules={[
                    {
                        required: true,
                        message: 'You should invite at least 1 speaker',
                    },
                ]}
                showRoles
                selectedUsers={selectedInternalSpeakers}
            />
            <ExternalSpeakers
                id="externalSpeakerEmails"
                label="External speakers (emails)"
                form={form}
                editMode
                hasFeedback={hasFeedback}
                setHasFeedback={setHasFeedback}
            />

            <Form.Item
                className="speakers-placeholder-field"
                name="speakersPlaceholder"
                dependencies={['internalSpeakerIds', 'externalSpeakerEmails']}
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_) {
                            const internal = getFieldValue('internalSpeakerIds')
                            const externalExist = getFieldValue('externalSpeakerEmailsExisting').filter(
                                ({ email } = { email: '' }) => email.length > 1
                            )
                            const external = getFieldValue('externalSpeakerEmails').filter(
                                ({ email } = { email: '' }) => email.length > 1
                            )

                            if (internal.length === 0 && externalExist.length === 0 && external.length === 0) {
                                return Promise.reject(new Error('You should add at least one speaker'))
                            }

                            return Promise.resolve()
                        },
                    }),
                ]}
            >
                <Input style={{ display: 'none' }} />
            </Form.Item>
        </Form>
    )
}

export default SpeakersForm
