/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react'
import { Checkbox } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { setToggleExperienceBasedQuestions } from '../../../redux/actions/account/account-actions'
import { getUniversityInfo } from '../../../redux/selectors'

const ExperienceBasedQuestions = () => {
    const dispatch = useDispatch()

    const { isAllowedToSkipExperienceBasedQuestions, isEnabledExperienceBasedQuestions } =
        useSelector(getUniversityInfo)

    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
    }, [])

    const handleChange = useCallback(
        (isAllowed, allowedType) => {
            dispatch(setToggleExperienceBasedQuestions({ isAllowed, allowedType }))
        },
        [dispatch]
    )

    return (
        <section className="content settings" style={{ paddingLeft: '4rem' }}>
            <h3 className="settings-title">Experience based questions</h3>

            <div className="settings-grid has-tutorial">
                <div className="column">
                    <div className="tutorial-intro">
                        <p>
                            Help your prospective students to ask the right questions. We have analysed thousands of
                            conversations and came up with the list of questions that helps to have meaningful
                            conversations.
                        </p>
                        <p>
                            If you enable experience based questions a prospective student will be asked to choose what
                            they are interested in (e.g.admissions, entry requirements, course information) and will be
                            suggested a good question to be asked (e.g. What did you do to prepare for interviews?’).
                        </p>
                        <p>
                            After selecting a question a students will follow a standard sign-up flow (including
                            additional data questions) . You can let a student skip experience based questions.
                        </p>
                    </div>

                    <div className="experience-based-questions-container">
                        <Checkbox
                            key="enable-questions"
                            checked={isEnabledExperienceBasedQuestions}
                            onChange={(e) => handleChange(e.target.checked, 'enabledFeature')}
                        >
                            Enable experience based questions
                        </Checkbox>
                        <br />
                        <Checkbox
                            key="skip-questions"
                            checked={isAllowedToSkipExperienceBasedQuestions}
                            onChange={(e) => handleChange(e.target.checked, 'skipQuestion')}
                        >
                            Prospective students can't skip experience based questions
                        </Checkbox>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExperienceBasedQuestions
