import React, { PureComponent } from 'react'

class SlideOne extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <div className="preview-slide">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={543.079}
                    height={487}
                    viewBox="0 0 543.079 487"
                    {...this.props}
                >
                    <g data-name="Group 3605">
                        <path data-name="Rectangle 1981" fill="#fff" d="M0 0h57v487H0z" />
                        <g data-name="Group 3593">
                            <g data-name="Group 3591">
                                <g data-name="Rectangle 1885" fill="none" stroke="#707070" opacity={0.22}>
                                    <path stroke="none" d="M58 0h428v487H58z" />
                                    <path d="M58.5.5h427v486h-427z" />
                                </g>
                                <path
                                    d="M92.415 39.657l4.95 4.95a1 1 0 11-1.414 1.414l-5.657-5.657a1 1 0 010-1.414l5.657-5.657a1 1 0 111.414 1.414l-4.95 4.95z"
                                    fill="#ff5100"
                                />
                                <g data-name="Group 3132">
                                    <g data-name="Group 3123">
                                        <text
                                            data-name="Sign up to chat with Ambassador"
                                            transform="translate(135 25)"
                                            fill="#222"
                                            fontSize={20}
                                            fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                        >
                                            <tspan x={0.28} y={20}>
                                                {'Sign up to chat with Ambassador'}
                                            </tspan>
                                        </text>
                                        <g data-name="Group 3130">
                                            <g
                                                data-name="Rectangle 1498"
                                                transform="translate(108 153)"
                                                fill="#fff"
                                                stroke="rgba(38,46,69,0.2)"
                                            >
                                                <rect width={324} height={29} rx={5} stroke="none" />
                                                <rect x={0.5} y={0.5} width={323} height={28} rx={4.5} fill="none" />
                                            </g>
                                            <text
                                                transform="translate(120 159)"
                                                fill="#222"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                data-name="Group 256"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'British'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <g data-name="Group 3129">
                                            <g
                                                data-name="Rectangle 1915"
                                                transform="translate(108 228)"
                                                fill="#fff"
                                                stroke="rgba(38,46,69,0.2)"
                                            >
                                                <rect width={50} height={28} rx={5} stroke="none" />
                                                <rect x={0.5} y={0.5} width={49} height={27} rx={4.5} fill="none" />
                                            </g>
                                            <g
                                                data-name="Rectangle 1916"
                                                transform="translate(174 228)"
                                                fill="#fff"
                                                stroke="rgba(38,46,69,0.2)"
                                            >
                                                <rect width={50} height={28} rx={5} stroke="none" />
                                                <rect x={0.5} y={0.5} width={49} height={27} rx={4.5} fill="none" />
                                            </g>
                                            <text
                                                transform="translate(125 234)"
                                                fill="#222"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                data-name="Group 3126"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'Yes'}
                                                </tspan>
                                            </text>
                                            <text
                                                transform="translate(191 234)"
                                                fill="#222"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                data-name="Group 3127"
                                            >
                                                <tspan x={1.366} y={12}>
                                                    {'No'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <g data-name="Group 3124">
                                            <text
                                                data-name="*Nationality"
                                                transform="translate(108 137)"
                                                fill="#ff5100"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Light, Source Sans Pro"
                                                fontWeight={300}
                                            >
                                                <tspan x={0} y={12}>
                                                    {'*'}
                                                </tspan>
                                                <tspan
                                                    y={12}
                                                    fill="#222"
                                                    fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                    fontWeight={400}
                                                >
                                                    {'Nationality'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <g
                                            data-name="Group 3131"
                                            fill="#909090"
                                            fontSize={12}
                                            fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                        >
                                            <text data-name="Step 1" transform="translate(209 97)">
                                                <tspan x={0} y={12}>
                                                    {'Step 1'}
                                                </tspan>
                                            </text>
                                            <text data-name="Step 2" transform="translate(300 97)">
                                                <tspan x={0} y={12}>
                                                    {'Step 2'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <g data-name="Group 3125">
                                            <text
                                                data-name="Do you want to receive email marketing communications?"
                                                transform="translate(108 204)"
                                                fill="#222"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'Do you want to receive email marketing communications?'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <g data-name="Button create content group">
                                            <g data-name="Group 254">
                                                <g data-name="Group 3121" transform="translate(158 412)">
                                                    <rect
                                                        data-name="Rectangle 1496"
                                                        width={220}
                                                        height={32}
                                                        rx={4}
                                                        fill="#ff5100"
                                                    />
                                                    <text
                                                        data-name="Start Conversation"
                                                        transform="translate(20 8)"
                                                        fill="#fff"
                                                        fontSize={12}
                                                        fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                    >
                                                        <tspan x={43.94} y={12}>
                                                            {'Start Conversation'}
                                                        </tspan>
                                                    </text>
                                                </g>
                                            </g>
                                        </g>
                                        <g data-name="Group 3128">
                                            <g data-name="Group 3">
                                                <g data-name="Rectangle 130" fill="#fff" stroke="rgba(38,46,69,0.3)">
                                                    <path stroke="none" d="M108 281h12v12h-12z" />
                                                    <path fill="none" d="M108.5 281.5h11v11h-11z" />
                                                </g>
                                            </g>
                                            <g data-name="Group 44">
                                                <text
                                                    data-name="*I am over the age of 18 and consent to my personal data being processed by my institution, according to its Privacy Policy, and being shared with The Ambassador Platform according to their Privacy Notice and terms so that they can facilitate the management of"
                                                    transform="translate(130 279)"
                                                    fill="#ff5100"
                                                    fontSize={11}
                                                    fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                >
                                                    <tspan x={0} y={11}>
                                                        {'*'}
                                                    </tspan>
                                                    <tspan y={11} fill="#222">
                                                        {
                                                            'I am over the age of 18 and consent to my personal data being '
                                                        }
                                                    </tspan>
                                                    <tspan x={0} y={26} fill="#222">
                                                        {'processed by my institution, according to its '}
                                                    </tspan>
                                                    <tspan y={26}>{'Privacy Policy'}</tspan>
                                                    <tspan y={26} fill="#222">
                                                        {', and '}
                                                    </tspan>
                                                    <tspan x={0} y={41} fill="#222">
                                                        {'being shared with'}
                                                    </tspan>
                                                    <tspan y={41} fill="rgba(38,46,69,0.5)" />
                                                    <tspan y={41}>{' The Ambassador Platform '}</tspan>
                                                    <tspan y={41} fill="rgba(38,46,69,0.5)" />
                                                    <tspan y={41} fill="#222">
                                                        {'according to their'}
                                                    </tspan>
                                                    <tspan y={41} fill="rgba(38,46,69,0.5)" />
                                                    <tspan x={0} y={56}>
                                                        {' Privacy '}
                                                    </tspan>
                                                    <tspan y={56}>{'Notice '}</tspan>
                                                    <tspan y={56} fill="rgba(38,46,69,0.5)" />
                                                    <tspan y={56} fill="#222">
                                                        {'and terms so that they can facilitate the '}
                                                    </tspan>
                                                    <tspan x={0} y={71} fill="#222">
                                                        {'management of content generation from representatives '}
                                                    </tspan>
                                                    <tspan x={0} y={86} fill="#222">
                                                        {'of my institution and messaging interactions with prospects.'}
                                                    </tspan>
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <path
                                        data-name="chevron-right"
                                        d="M413.829 170.072l4.95-4.95a1 1 0 111.414 1.414l-5.657 5.657a1 1 0 01-1.414 0l-5.657-5.657a1 1 0 111.414-1.414l4.95 4.95z"
                                        fill="#222"
                                    />
                                    <path
                                        data-name="chevron-right"
                                        d="M225.097 87.252l2.62-2.621a.53.53 0 11.749.749l-2.995 2.994a.529.529 0 01-.749 0l-2.994-2.994a.53.53 0 11.749-.749l2.62 2.62zM314.097 87.252l2.62-2.621a.53.53 0 11.749.749l-2.995 2.994a.529.529 0 01-.749 0l-2.994-2.994a.53.53 0 11.749-.749l2.62 2.62z"
                                        fill="#ff5100"
                                    />
                                    <g
                                        data-name="Ellipse 31"
                                        transform="translate(219 80)"
                                        fill="none"
                                        stroke="#ff5100"
                                    >
                                        <circle cx={6.5} cy={6.5} r={6.5} stroke="none" />
                                        <circle cx={6.5} cy={6.5} r={6} />
                                    </g>
                                    <g
                                        data-name="Ellipse 32"
                                        transform="translate(308 80)"
                                        fill="none"
                                        stroke="#ff5100"
                                    >
                                        <circle cx={6.5} cy={6.5} r={6.5} stroke="none" />
                                        <circle cx={6.5} cy={6.5} r={6} />
                                    </g>
                                    <path data-name="Line 198" fill="none" stroke="#ff5100" d="M237.5 86.5h64" />
                                </g>
                            </g>
                            <path
                                data-name="Path 64"
                                d="M440.764 284.658a45.278 45.278 0 0014.985-20.333c.663-1.799 3.448-1.078 2.906.813a47.359 47.359 0 01-5.61 12.475c10.723-7.68 23.831-11.978 36.903-13.244 16.984-1.643 37.786 1.91 51.193 13.19 1.967 1.654-.262 5.433-2.658 4.423-7.692-3.236-14.82-7.038-22.998-9.084a79.831 79.831 0 00-24.725-2.306c-13.822.922-26.456 6.001-38.263 12.81a23.628 23.628 0 0110.85 4.437c2.92 2.193.172 7.07-2.987 4.972-5.391-3.59-10.925-5.227-17.432-4.201-2.034.314-4.186-2.382-2.164-3.952z"
                                fill="rgba(34,34,34,0.4)"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

export default SlideOne
