import Header from '../../_layout/header'
import NavigationSetupWizard from '../../_layout/navSetupWizard'
import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import { connect } from 'react-redux'
import Modal from 'antd/es/modal'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import Slider from 'react-slick'
import Sprite from '../../../../assets/img/symbol/sprite.svg'
import { ACCOUNT } from '../../../redux/actions/account/account-types'
import PolicyExplanationModal from './components/PolicyExplanationModal'
import { ReactComponent as SettingsIcon } from '../../../../assets/svg/icons/settings.svg'
import SlideOne from './components/SlideOne'
import SlideTwo from './components/SlideTwo'
import SlideThree from './components/SlideThree'
import { trackAddedPrivacyPolicy } from '../../../helpers/MixpanelHelper'

function PrevArrow(props) {
    const { onClick } = props
    return (
        <button type="button" onClick={onClick} className="slick-prev">
            <svg className="icon icon-slider-arrow">
                <use xlinkHref={Sprite + '#slider-arrow'}></use>
            </svg>
        </button>
    )
}

function NextArrow(props) {
    const { onClick } = props
    return (
        <button type="button" onClick={onClick} className="slick-next">
            <svg className="icon icon-slider-arrow">
                <use xlinkHref={Sprite + '#slider-arrow'}></use>
            </svg>
        </button>
    )
}

class PolicyPage extends PureComponent {
    constructor(props) {
        super()
        this.state = {
            initialSlide: 0,
            activeSlide: 0,
            showPrevArrow: false,
            showNextArrow: true,
            showPolicyModal: false,
            privacyPolicyName:
                props.account && props.account.account_info && props.account.account_info.university
                    ? props.account.account_info.university.privacy_policy
                    : '',
            privacyPolicyNameInitial:
                props.account && props.account.account_info && props.account.account_info.university
                    ? props.account.account_info.university.privacy_policy
                    : '',
        }

        this.handleChangeSlide = this.handleChangeSlide.bind(this)
        this.showPolicyModal = this.showPolicyModal.bind(this)
        this.handleClosePolicyModal = this.handleClosePolicyModal.bind(this)
        this.nextStep = this.nextStep.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'setup-privacy-policy',
            },
        })
    }

    handleChangeSlide(number) {
        if (number > 0) {
            this.setState({
                showPrevArrow: true,
                showNextArrow: number < 2,
                activeSlide: number,
            })
        } else {
            this.setState({
                showPrevArrow: false,
                activeSlide: number,
            })
        }
    }

    nextStep() {
        const { privacyPolicyName, privacyPolicyNameInitial } = this.state
        const { history, account } = this.props
        const onFinish = (added) => {
            // only when server error or success
            if (added) {
                trackAddedPrivacyPolicy(account.account_info.university.id)
            }
            if (!window.localStorage.getItem('setupWizardStep')) {
                window.localStorage.setItem('setupWizardStep', '1')
            }
            history.push('/setup/training-centre')
        }
        if (privacyPolicyNameInitial !== privacyPolicyName && privacyPolicyName.length > 0) {
            store.dispatch({
                type: ACCOUNT.EDIT_PRIVACY_POLICY,
                payload: {
                    privacy_policy: privacyPolicyName,
                    noPopup: true,
                },
                meta: {
                    onFinish,
                },
            })
        } else {
            onFinish()
        }
    }

    onChange(e) {
        this.setState({
            privacyPolicyName: e.target.value.trim(),
        })
    }

    showPolicyModal() {
        this.setState({
            showPolicyModal: true,
        })
    }

    handleClosePolicyModal() {
        this.setState({
            showPolicyModal: false,
        })
    }

    render() {
        const { privacyPolicyName, initialSlide, showPrevArrow, showNextArrow, activeSlide, showPolicyModal } =
            this.state
        const { account } = this.props

        const settings = {
            infinite: false,
            arrows: true,
            nextArrow: showNextArrow ? <NextArrow /> : <></>,
            prevArrow: showPrevArrow ? <PrevArrow /> : <></>,
            draggable: false,
            initialSlide: Number(initialSlide),
            afterChange: this.handleChangeSlide,
        }

        let previewTitle = 'Privacy Policy on the TAP Feed'
        let floorText = 'Prospects will find the link on the TAP Feed sign up form'
        if (activeSlide === 1) {
            previewTitle = 'Privacy Policy on the Admin Dashboard'
            floorText = 'Admins will find the link on the Admin Dashboard sign up form'
        } else if (activeSlide === 2) {
            previewTitle = 'Privacy Policy in the App for Ambassadors'
            floorText = "Ambassadors will find the link in the app's sign up form"
        }

        return (
            <div className="setup-policy-page">
                <Header submenuUnActive={true} />
                <div id="page-wrapper" className="page-wrapper">
                    <NavigationSetupWizard />

                    <section className="content settings no-submenu home-start-guide setup-wizard">
                        <h1 className="settings-title">Admin Dashboard Setup Guide</h1>

                        <div className="start-guide-wizard-grid">
                            <div className="column">
                                <div className="setup-start-intro">
                                    Welcome to Setup Guide of The Ambassador Platform’s Admin Dashboard! This section is
                                    designed to guide you through set up.
                                </div>
                                <div className="setup-start-block">
                                    <div className="policy-title">1. Privacy Policy Setup</div>
                                    <p className="video-privacy-link">
                                        Watch this <span onClick={this.showPolicyModal}>video explanation</span> to see
                                        how it works
                                    </p>

                                    <p>
                                        Everyone who creates an account on TAP, whether to represent or to engage with{' '}
                                        {account.account_info.university.name}, will be operating under{' '}
                                        {account.account_info.university.name}'s privacy policy. Therefore, we need a
                                        link to this Privacy Policy to show prospects, ambassadors, and additional
                                        administrators on registration.
                                    </p>

                                    <input
                                        name="privacy_policy"
                                        value={privacyPolicyName}
                                        className="signin-input privacy-input university-form-input"
                                        placeholder="Add Privacy policy URL"
                                        type="text"
                                        onChange={this.onChange}
                                    />
                                    <div className="setup-steps-buttons">
                                        <button className="disabled-button">Previous</button>
                                        <button onClick={this.nextStep}>Next</button>
                                    </div>
                                    <div className="setup-skip-text">
                                        If you skip this step, you'll find it later in <SettingsIcon /> Settings
                                    </div>
                                </div>
                            </div>

                            <div className={'preview column preview-policy active-slide-' + activeSlide}>
                                <div className="preview-title">
                                    <span>Preview </span>
                                    <span>{previewTitle}</span>
                                </div>
                                <div className="preview-body">
                                    <Slider
                                        ref={(slider) => (this.slider = slider)}
                                        className="svg-wizard-slider"
                                        {...settings}
                                    >
                                        <SlideOne />
                                        <SlideTwo />
                                        <SlideThree />
                                    </Slider>
                                </div>
                                <div className="preview-floor">{floorText}</div>
                            </div>
                        </div>
                    </section>
                </div>
                <Modal
                    open={showPolicyModal}
                    bodyStyles={{ padding: 0 }}
                    centered={true}
                    width={800}
                    title={null}
                    footer={null}
                    destroyOnClose={true}
                    keyboard={false}
                    onCancel={this.handleClosePolicyModal}
                    className="setup-modal"
                >
                    <PolicyExplanationModal handleClosePolicyModal={this.handleClosePolicyModal} />
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return { account: state.account }
})(PolicyPage)
