import Modal from 'antd/es/modal'
import dayjs from 'dayjs'
import React, { Component } from 'react'

import AmbassadorInfoPopup from '../../../components/popups/ambassador-info-popup/AmbassadorInfoPopup'
import DateHumanizeHelper from '../../../helpers/DateHumanizeHelper'
import { FIND_OR_CREATE_CHAT } from '../../../redux/actions/chat/messagingActions'
import { OPEN_ACTION_MODAL, CLOSE_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'
import {
    OPEN_CONFIRM_BAN_POPUP,
    USER_TOGGLE_BAN_REQUESTED,
    DISABLE_TOGGLE,
    USER_PARTNER_TOGGLE_BAN_REQUESTED,
    USER_PARTNER_TOGGLE_UNI_BAN_REQUESTED,
} from '../../../redux/actions/content/usersActions'
import store from '../../../redux/store'
import { trackAmbassadorActivated } from '../../../helpers/MixpanelHelper'

class UserRow extends Component {
    constructor() {
        super()

        this.state = {
            banCheckbox: false,
            chatCheckbox: false,
            contentCheckbox: false,
            faqCheckbox: false,
            chatUniCheckbox: false,
            partnerGroupsCheckBoxes: {},
            firstInteract: true,
            isNewUser: false,
            userOpenModal: false,
            rate: {
                '-1': '😟',
                0: '😐',
                1: '🙂',
            },
        }

        this.handleOpenConfirmBanPopup = this.handleOpenConfirmBanPopup.bind(this)
        this.handleOpenUserPopup = this.handleOpenUserPopup.bind(this)
        this.handleCloseUserPopup = this.handleCloseUserPopup.bind(this)
        this.handlePermissionToggle = this.handlePermissionToggle.bind(this)
        this.handleOpenChat = this.handleOpenChat.bind(this)
    }

    componentDidMount() {
        const { user, handleNewUser } = this.props
        const isNewUser = dayjs(user.created_at).isSame(new Date(), 'day')
        if (isNewUser) {
            handleNewUser()
        }
        this.setState((state) => ({
            ...state,
            banCheckbox: !user.banned,
            chatCheckbox: user.permissions.chat.is_enabled,
            contentCheckbox: user.permissions.content.is_enabled,
            faqCheckbox: user.permissions.faq.is_enabled,
            chatUniCheckbox: user.university_tap_page_permission,
            idpCommunityCheckbox: user.permissions.idp_community.is_enabled,
            idpLiveAppCheckbox: user.permissions.idp_conversion_chat.is_enabled,
            idpWhatUniCheckbox: user.permissions.idp_what_uni.is_enabled,
            isNewUser,
        }))
    }

    static getDerivedStateFromProps(props, state) {
        const { user, universityId } = props
        let changed = false
        let partnerEnabled = false
        if (user.partner_groups_permissions) {
            for (const perm of user.partner_groups_permissions) {
                if (perm.is_enabled !== state.partnerGroupsCheckBoxes[perm.university_group_id]) {
                    changed = true
                    partnerEnabled = perm.is_enabled
                }
            }
        }
        if (
            user.banned !== state.banCheckbox ||
            user.permissions.chat.is_enabled !== state.chatCheckbox ||
            user.permissions.content.is_enabled !== state.contentCheckbox ||
            user.permissions.faq.is_enabled !== state.faqCheckbox ||
            user.university_tap_page_permission !== state.chatUniCheckbox ||
            user.permissions.idp_community.is_enabled !== state.idpCommunityCheckbox ||
            user.permissions.idp_conversion_chat.is_enabled !== state.idpLiveAppCheckbox ||
            user.permissions.idp_what_uni.is_enabled !== state.idpWhatUniCheckbox ||
            changed
        ) {
            if (
                (user.permissions.chat.is_enabled !== state.chatCheckbox && user.permissions.chat.is_enabled) ||
                partnerEnabled
            ) {
                trackAmbassadorActivated(universityId, user.id)
            }
            state.banCheckbox = user.banned
            state.chatCheckbox = user.permissions.chat.is_enabled
            state.contentCheckbox = user.permissions.content.is_enabled
            state.faqCheckbox = user.permissions.faq.is_enabled
            const partnerGroupsCheckBoxes = {}
            if (user.partner_groups_permissions) {
                for (const perm of user.partner_groups_permissions) {
                    partnerGroupsCheckBoxes[perm.university_group_id] = perm.is_enabled
                }
            }
            state.partnerGroupsCheckBoxes = partnerGroupsCheckBoxes
            state.chatUniCheckbox = user.university_tap_page_permission

            state.idpCommunityCheckbox = user.permissions.idp_community.is_enabled
            state.idpLiveAppCheckbox = user.permissions.idp_conversion_chat.is_enabled
            state.idpWhatUniCheckbox = user.permissions.idp_what_uni.is_enabled

            return state
        }
        return null
    }

    handleOpenConfirmBanPopup(event) {
        this.setState({ firstInteract: false })
        store.dispatch({
            type: OPEN_CONFIRM_BAN_POPUP,
            payload: {
                user_id: event.target.getAttribute('data-user-id'),
                ban_type: 'ban',
            },
        })
    }

    handleOpenUserPopup() {
        this.setState({
            userOpenModal: true,
        })
    }

    handleCloseUserPopup() {
        this.setState({
            userOpenModal: false,
        })
    }

    /* handleOpenTagsPopup() {
    const { user, handleChangeIntroduction, handleChangeDescription } = this.props

    store.dispatch({
      type: OPEN_ACTION_MODAL,
      payload: {
        children: <AmbassadorInfoPopup
          user={user}
          userAvatar={UserRow.userAvatar}
          handleChangeIntroduction={handleChangeIntroduction}
          handleChangeDescription={handleChangeDescription}
        />
      }
    })
  } */

    afterCreated = (id) => {
        window.open('/#/chat/messaging?type=4&activeDialog=' + id, '_blank')
    }

    handleOpenChat() {
        const { user } = this.props
        store.dispatch({
            type: FIND_OR_CREATE_CHAT,
            payload: {
                user_id: user.id,
                afterCreated: this.afterCreated,
            },
        })
    }

    static getPermissionID(type) {
        switch (type) {
            case 'chatCheckbox': {
                return 1
            }
            case 'contentCheckbox': {
                return 2
            }
            case 'faqCheckbox': {
                return 3
            }
            // No default
        }
    }

    handleClosePopup = () => store.dispatch({ type: CLOSE_ACTION_MODAL })

    modalUserIsBlock() {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="text-popup">
                        <h3 className="modal-title">
                            Sorry, you can&apos;t activate this feature because your ambassador has reached their time
                            limit. Please increase the time limit to automatically reactivate your ambassador
                        </h3>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={this.handleClosePopup}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    handlePermissionToggle(event, partnerGroupId) {
        const { partnerGroupsCheckBoxes, chatUniCheckbox } = this.state
        const { user, universityId } = this.props
        const { id, name, last_name: lastName, timeBlockColum, introduction, description, avatar } = user

        const method = 'permission'
        const type = event.target.name
        const permissionId = UserRow.getPermissionID(type)

        if (timeBlockColum[permissionId]) {
            this.modalUserIsBlock()
            return
        }

        let typeCheckbox = 'checkbox'
        let currentType = null
        switch (type) {
            case 'chatPartnerCheckbox':
                typeCheckbox = 'Chat feature'
                currentType = 'chat'
                break
            case 'chatUniCheckbox':
                typeCheckbox = 'Chat feature'
                currentType = 'chat'
                break
            case 'chatCheckbox':
                typeCheckbox = 'Chat feature'
                currentType = 'chat'
                break
            case 'contentCheckbox':
                typeCheckbox = 'Content feature'
                currentType = 'content'
                break
            case 'faqCheckbox':
                typeCheckbox = 'FAQ feature'
                currentType = 'faq'
                break
        }
        if (
            (!introduction || !description || !avatar || !avatar.original) &&
            (type === 'chatCheckbox' || type === 'chatUniCheckbox')
        ) {
            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title:
                        "You can't enable " +
                        name +
                        ' ' +
                        lastName +
                        ' for ' +
                        typeCheckbox +
                        " yet because they haven't completed their profile during sign-up. Please contact " +
                        name +
                        ' ' +
                        lastName +
                        ' and ask them to complete their profile',
                },
            })
        } else if (currentType) {
            store.dispatch({
                type: DISABLE_TOGGLE,
                payload: true,
            })
            if (type === 'chatPartnerCheckbox') {
                store.dispatch({
                    type: USER_PARTNER_TOGGLE_BAN_REQUESTED,
                    method,
                    payload: {
                        university_group_id: partnerGroupId,
                        user_id: id,
                        is_enabled: !partnerGroupsCheckBoxes[partnerGroupId],
                    },
                })
            } else if (type === 'chatUniCheckbox') {
                store.dispatch({
                    type: USER_PARTNER_TOGGLE_UNI_BAN_REQUESTED,
                    method,
                    payload: {
                        user_id: id,
                        is_enabled: !chatUniCheckbox,
                    },
                })
            } else {
                store.dispatch({
                    type: USER_TOGGLE_BAN_REQUESTED,
                    method,
                    payload: {
                        universityId,
                        user_id: id,
                        permission_id: permissionId,
                        type: currentType,
                        is_enabled: !this.state[type],
                    },
                })
            }
        }
    }

    static userAvatar({ avatar, name, id }, avatarSize = '') {
        if (avatar) {
            const avatarSrc = avatarSize === 'smallAvatar' ? avatar.sizes['70x70'] || avatar.original : avatar.original
            return <img className="table-item-img" src={avatarSrc} alt="img" />
        } else {
            return (
                <div
                    className={`table-item-img table-item-img-name ${avatarSize === '' && 'table-item-img-name--big'}`}
                >
                    {name[0]}
                </div>
            )
        }
    }

    render() {
        const {
            user,
            index,
            handleCopyUserLink,
            handleChangeIntroduction,
            handleChangeDescription,
            disableToggle,
            universityName,
            partnerGroups,
            handleChangeSubject,
            editableProfile,
            changeAmbassadorInfo,
        } = this.props
        const {
            banCheckbox,
            chatCheckbox,
            chatUniCheckbox,
            contentCheckbox,
            faqCheckbox,
            partnerGroupsCheckBoxes,
            rate,
            isNewUser,
            userOpenModal,
        } = this.state

        const lastActive = user.last_activity ? DateHumanizeHelper(user.last_activity) : 'N/A'
        user.time_activity = Number(user.time_activity)
        const activeTimeHours = user.time_activity ? Math.floor(user.time_activity / 60 / 60) : 0
        const activeTimeMinutes = user.time_activity
            ? Math.floor((user.time_activity - activeTimeHours * 60 * 60) / 60)
            : 0
        let userTags = []
        for (const tag in user.user_tags) {
            // eslint-disable-next-line no-prototype-builtins, unicorn/prefer-ternary
            if (user.user_tags.hasOwnProperty(tag) && (tag === 'profile' || tag === 'subject')) {
                // eslint-disable-next-line unicorn/prefer-spread
                userTags = userTags.concat(user.user_tags[tag].slice(0, user.user_tags[tag].length / 2))
            } else {
                // eslint-disable-next-line unicorn/prefer-spread
                userTags = userTags.concat(user.user_tags[tag])
            }
        }
        return (
            <tr key={user.id} className="sa-item">
                <td className="table-item">
                    <div className="table-item-first">
                        <div className="table-item-img-container">{UserRow.userAvatar(user, 'smallAvatar')}</div>

                        <span className={isNewUser ? 'mod-new' : ''}>
                            {user.name} {user.last_name}
                        </span>
                    </div>
                </td>
                <td className="table-item actions-buttons-block">
                    <button
                        type="button"
                        className="view-profile-info-btn"
                        data-user-index={index}
                        onClick={this.handleOpenUserPopup}
                    >
                        View
                    </button>
                    {/* <button type="button" className="chat-profile-btn" data-user-index={index}
                  onClick={this.handleOpenChat}>Chat
          </button> */}

                    <Modal
                        open={userOpenModal}
                        centered={true}
                        footer={null}
                        closable={false}
                        maskClosable={false}
                        onCancel={this.handleCloseUserPopup}
                        className="user-profile-modal"
                    >
                        <AmbassadorInfoPopup
                            user={user}
                            userAvatar={UserRow.userAvatar}
                            handleChangeIntroduction={handleChangeIntroduction}
                            handleChangeDescription={handleChangeDescription}
                            handleChangeSubject={handleChangeSubject}
                            changeAmbassadorInfo={changeAmbassadorInfo}
                            handleCloseUserPopup={this.handleCloseUserPopup}
                            handleOpenChat={this.handleOpenChat}
                            university={universityName}
                            editableProfile={editableProfile}
                        />
                    </Modal>
                </td>
                <td className="table-item">
                    {user.time_activity ? activeTimeHours + 'h ' + activeTimeMinutes + 'm' : '0'}
                </td>
                <td className="table-item">{lastActive}</td>
                <td
                    className={`table-item-rate ${
                        user.middleRate === null && 'table-item-rate-placeholder'
                    } table-item`}
                >
                    {rate[user.middleRate || 0]}
                </td>
                <td className="table-item user-status-row">
                    <label className="slideOne js-holder" data-user-id={user.id}>
                        <input
                            type="checkbox"
                            data-user-id={user.id}
                            name="check"
                            data-ban-type="general"
                            checked={banCheckbox}
                            onChange={this.handleOpenConfirmBanPopup}
                        />
                        <span className="slideOne-icon" />
                    </label>
                </td>
                {partnerGroups &&
                    partnerGroups.length > 0 &&
                    partnerGroups.map((group, index) => (
                        <td key={index} className="table-item user-status-row">
                            <label className="slideOne js-holder" data-group-id={group.id}>
                                <input
                                    type="checkbox"
                                    data-user-id={group.id}
                                    name="chatPartnerCheckbox"
                                    checked={partnerGroupsCheckBoxes[group.id]}
                                    disabled={disableToggle}
                                    onChange={(e) => this.handlePermissionToggle(e, group.id)}
                                />
                                <span className="slideOne-icon" />
                            </label>
                        </td>
                    ))}
                <td className="table-item user-status-row">
                    <label className="slideOne js-holder" data-user-id={user.id}>
                        <input
                            type="checkbox"
                            data-user-id={user.id}
                            name="chatUniCheckbox"
                            checked={chatUniCheckbox}
                            disabled={disableToggle}
                            onChange={this.handlePermissionToggle}
                        />
                        <span className="slideOne-icon" />
                    </label>
                </td>
                <td className="table-item user-status-row">
                    <label className="slideOne js-holder" data-user-id={user.id}>
                        <input
                            type="checkbox"
                            data-user-id={user.id}
                            name="chatCheckbox"
                            checked={chatCheckbox}
                            disabled={disableToggle}
                            onChange={this.handlePermissionToggle}
                        />
                        <span className="slideOne-icon" />
                    </label>
                </td>
                <td className="table-item user-status-row">
                    <label className="slideOne js-holder" data-user-id={user.id}>
                        <input
                            type="checkbox"
                            data-user-id={user.id}
                            name="contentCheckbox"
                            checked={contentCheckbox}
                            disabled={disableToggle}
                            onChange={this.handlePermissionToggle}
                        />
                        <span className="slideOne-icon" />
                    </label>
                </td>
                <td className="table-item user-status-row">
                    <label className="slideOne js-holder" data-user-id={user.id}>
                        <input
                            type="checkbox"
                            data-user-id={user.id}
                            name="faqCheckbox"
                            checked={faqCheckbox}
                            disabled={disableToggle}
                            onChange={this.handlePermissionToggle}
                        />
                        <span className="slideOne-icon" />
                    </label>
                </td>
                <td className="table-item user-status-row">
                    <button
                        type="button"
                        id={'user-link-' + user.id}
                        className={chatCheckbox ? 'view-profile-info-btn' : 'view-profile-info-btn hidden-block'}
                        data-user-index={index}
                        onClick={() => handleCopyUserLink(user.id)}
                    >
                        Copy link
                    </button>
                </td>
            </tr>
        )
    }
}

export default UserRow
