import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { useHistory, useLocation } from 'react-router'
import { AMBASSADORS_INFO, CREATE_CONTENT_GROUP } from '../../../../../redux/actions/content/messagingActions'
import store from '../../../../../redux/store'
import CreateContentGroupForm from '../../../components/CreateContentGroupForm'

const { confirm } = window
class CreateContentGroup extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            disableCreateButton: false,
            hasFormBeenUpdated: false,
            isDatePickerOpen: false,
        }

        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleEscPress = this.handleEscPress.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.setFormUpdatedStatus = this.setFormUpdatedStatus.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.checkIfDatePickerOpen = this.checkIfDatePickerOpen.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'content',
                },
            },
        })
        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('keydown', this.handleEscPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('keydown', this.handleEscPress, false)
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            !this.state.isDatePickerOpen &&
            this.state.hasFormBeenUpdated
        ) {
            const confirmByClickOutside = confirm(
                'Are you sure you want to close this popup? Changes will not be saved'
            )
            if (confirmByClickOutside) {
                this.props.onClosePopup()
            }
        }
    }

    handleEscPress(event) {
        const escapeButtonCode = 27
        if (event.keyCode !== escapeButtonCode) {
            return
        }

        if (!this.state.hasFormBeenUpdated) {
            this.props.onClosePopup()
            return
        }

        const confirmByEscPress = confirm('Are you sure you want to close this popup? Changes will not be saved')
        if (confirmByEscPress) {
            this.props.onClosePopup()
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    handleSearchUpdated(value) {
        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    keyword: value,
                    permission: 'content',
                },
            },
        })
    }

    setFormUpdatedStatus(value) {
        this.setState({ hasFormBeenUpdated: value })
    }

    checkIfDatePickerOpen(open) {
        this.setState({ isDatePickerOpen: open })
    }

    handleFormSubmit(values) {
        this.setState({
            disableCreateButton: true,
        })

        const runAfterCreate = () => {
            if (this.props.location.pathname === '/content/dashboard') {
                this.props.history.push({
                    pathname: '/content/dashboard',
                    hash: '',
                })
            } else {
                this.props.history.replace({
                    hash: '',
                })
            }
        }

        if (values.avatar && values.avatar.length > 0) {
            const reader = new window.FileReader()
            reader.readAsDataURL(values.avatar[0])
            reader.addEventListener('load', () => {
                store.dispatch({
                    type: CREATE_CONTENT_GROUP,
                    payload: {
                        name: values.name,
                        description: values.description,
                        selectedUserIds: values.selectedUserIds,
                        globalTagsIds: values.globalTagsIds,
                        avatar: reader.result.slice(reader.result.indexOf(',') + 1),
                        eventSettings:
                            this.props.type === 'scheduled-content-group'
                                ? { date: values.contentGroupEventDate }
                                : null,
                        runAfter: runAfterCreate,
                    },
                })
            })
        } else {
            store.dispatch({
                type: CREATE_CONTENT_GROUP,
                payload: {
                    name: values.name,
                    description: values.description,
                    selectedUserIds: values.selectedUserIds,
                    globalTagsIds: values.globalTagsIds,
                    avatar: null,
                    eventSettings:
                        this.props.type === 'scheduled-content-group' ? { date: values.contentGroupEventDate } : null,
                    runAfter: runAfterCreate,
                },
            })
        }

        if (this.props.onSubmit) {
            this.props.onSubmit(values)
        }
    }

    render() {
        const { usersMessaging, initialTags, scheduledDay, isSсheduledContentGroup } = this.props

        return (
            <div ref={this.setWrapperRef}>
                <div className="create-content-group">
                    <h2 className="modal-title">Create content group</h2>
                    <CreateContentGroupForm
                        isCreateButtonDisabled={this.state.disableCreateButton}
                        ambassadors={usersMessaging.ambassadors}
                        areAmbassadorsLoading={usersMessaging.isLoading}
                        onSearchUpdated={this.handleSearchUpdated}
                        onSubmit={this.handleFormSubmit}
                        setFormUpdatedStatus={this.setFormUpdatedStatus}
                        initialValues={{ tags: initialTags, scheduledDay }}
                        checkIfDatePickerOpen={this.checkIfDatePickerOpen}
                        isSсheduledContentGroup={isSсheduledContentGroup}
                    />
                </div>
            </div>
        )
    }
}

const ConnectCreateContentGroup = connect((state) => {
    return {
        usersMessaging: state.usersMessaging,
    }
})(CreateContentGroup)

export default ConnectCreateContentGroup

export const CreateContentGroupConnected = (props) => {
    const location = useLocation()
    const history = useHistory()

    return <ConnectCreateContentGroup {...props} location={location} history={history} />
}
