import React, { PureComponent } from 'react'

class SlideThree extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <div className="preview-slide">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={389.079}
                    height={426}
                    viewBox="0 0 389.079 426"
                    {...this.props}
                >
                    <g data-name="Group 3607">
                        <g data-name="Group 3596">
                            <g data-name="Group 3142">
                                <g data-name="Rectangle 1885" fill="none" stroke="#707070" opacity={0.22}>
                                    <path stroke="none" d="M70 0h248v426H70z" />
                                    <path d="M70.5.5h247v425h-247z" />
                                </g>
                                <g data-name="Group 3122">
                                    <g data-name="Group 3138">
                                        <g
                                            data-name="Rectangle 1498"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                            transform="translate(90 27)"
                                        >
                                            <rect width={208} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={207} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <g data-name="Group 256">
                                            <text
                                                data-name="First Name"
                                                fill="rgba(34,34,34,0.8)"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                transform="translate(102 33)"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'First Name'}
                                                </tspan>
                                            </text>
                                        </g>
                                    </g>
                                    <g data-name="Group 3137">
                                        <g
                                            data-name="Rectangle 1912"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                            transform="translate(90 63)"
                                        >
                                            <rect width={208} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={207} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <g data-name="Group 3119">
                                            <text
                                                data-name="Last Name"
                                                fill="rgba(34,34,34,0.8)"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                transform="translate(102 69)"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'Last Name'}
                                                </tspan>
                                            </text>
                                        </g>
                                    </g>
                                    <g data-name="Group 3140">
                                        <g
                                            data-name="Rectangle 1912"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                            transform="translate(90 171)"
                                        >
                                            <rect width={208} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={207} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <text
                                            fill="rgba(34,34,34,0.8)"
                                            fontSize={12}
                                            fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            transform="translate(102 177)"
                                            data-name="Group 3119"
                                        >
                                            <tspan x={0} y={12}>
                                                {'Password'}
                                            </tspan>
                                        </text>
                                    </g>
                                    <g data-name="Group 3136">
                                        <g
                                            data-name="Rectangle 1913"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                            transform="translate(90 99)"
                                        >
                                            <rect width={208} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={207} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <g data-name="Group 3120">
                                            <text
                                                data-name="Email address"
                                                fill="rgba(34,34,34,0.8)"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                transform="translate(102 105)"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'Email address'}
                                                </tspan>
                                            </text>
                                        </g>
                                    </g>
                                    <g data-name="Group 3141">
                                        <g
                                            data-name="Rectangle 1913"
                                            fill="#fff"
                                            stroke="rgba(38,46,69,0.2)"
                                            transform="translate(90 135)"
                                        >
                                            <rect width={208} height={28} rx={5} stroke="none" />
                                            <rect x={0.5} y={0.5} width={207} height={27} rx={4.5} fill="none" />
                                        </g>
                                        <g data-name="Group 3120">
                                            <text
                                                data-name="Confirm Email address"
                                                fill="rgba(34,34,34,0.8)"
                                                fontSize={12}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                transform="translate(102 141)"
                                            >
                                                <tspan x={0} y={12}>
                                                    {'Confirm Email address'}
                                                </tspan>
                                            </text>
                                        </g>
                                    </g>
                                    <g data-name="Button create content group">
                                        <g data-name="Group 254">
                                            <g data-name="Group 3121" transform="translate(90 366)">
                                                <rect
                                                    data-name="Rectangle 1496"
                                                    width={208}
                                                    height={32}
                                                    rx={4}
                                                    fill="#ff5100"
                                                />
                                                <text
                                                    data-name="Sign up"
                                                    transform="translate(20 8)"
                                                    fill="#fff"
                                                    fontSize={12}
                                                    fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                >
                                                    <tspan x={65.72} y={12}>
                                                        {'Sign up'}
                                                    </tspan>
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <g data-name="Group 3135">
                                        <g data-name="Group 3">
                                            <g data-name="Rectangle 130" fill="#fff" stroke="rgba(38,46,69,0.3)">
                                                <path stroke="none" d="M90 221h12v12H90z" />
                                                <path fill="none" d="M90.5 221.5h11v11h-11z" />
                                            </g>
                                        </g>
                                        <g data-name="Group 44">
                                            <text
                                                data-name="I am over the age of 16 and consent to my personal data being processed by UT University, according to their Privacy Policy and being shared with The Ambassador Platform according to their Privacy Notice and terms so that they can facilitate content generation"
                                                fill="#222"
                                                fontSize={11}
                                                fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                                transform="translate(112 219)"
                                            >
                                                <tspan x={0} y={11}>
                                                    {'I am over the age of 16 and consent to '}
                                                </tspan>
                                                <tspan x={0} y={25}>
                                                    {'my personal data being processed by UT '}
                                                </tspan>
                                                <tspan x={0} y={39}>
                                                    {'University, according to their '}
                                                </tspan>
                                                <tspan y={39} fill="#ff5100">
                                                    {'Privacy '}
                                                </tspan>
                                                <tspan x={0} y={53} fill="#ff5100">
                                                    {'Policy'}
                                                </tspan>
                                                <tspan y={53}>{' and being shared with'}</tspan>
                                                <tspan y={53} fill="rgba(38,46,69,0.5)" />
                                                <tspan x={0} y={67}>
                                                    {' The Ambassador '}
                                                </tspan>
                                                <tspan y={67}>{'Platform '}</tspan>
                                                <tspan y={67} fill="rgba(38,46,69,0.5)" />
                                                <tspan y={67}>{'according to their'}</tspan>
                                                <tspan y={67} fill="rgba(38,46,69,0.5)" />
                                                <tspan x={0} y={81} fill="#ff5100">
                                                    {'Privacy '}
                                                </tspan>
                                                <tspan y={81} fill="#ff5100">
                                                    {'Notice '}
                                                </tspan>
                                                <tspan y={81} fill="rgba(38,46,69,0.5)" />
                                                <tspan y={81}>{'and '}</tspan>
                                                <tspan y={81} fill="#ff5100">
                                                    {'terms '}
                                                </tspan>
                                                <tspan y={81}>{'so that they can '}</tspan>
                                                <tspan x={0} y={95}>
                                                    {'facilitate content generation for UT '}
                                                </tspan>
                                                <tspan x={0} y={109}>
                                                    {'University and messaging interactions '}
                                                </tspan>
                                                <tspan x={0} y={123}>
                                                    {'with prospects.'}
                                                </tspan>
                                            </text>
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <path
                                data-name="Path 64"
                                d="M286.764 252.658a45.278 45.278 0 0014.985-20.333c.663-1.799 3.448-1.078 2.906.813a47.359 47.359 0 01-5.61 12.475c10.723-7.68 23.831-11.978 36.903-13.244 16.984-1.643 37.786 1.91 51.193 13.19 1.967 1.654-.262 5.433-2.658 4.423-7.692-3.236-14.82-7.038-22.998-9.084a79.831 79.831 0 00-24.725-2.306c-13.822.922-26.456 6.001-38.263 12.81a23.628 23.628 0 0110.85 4.437c2.92 2.193.172 7.07-2.987 4.972-5.391-3.59-10.925-5.227-17.432-4.201-2.034.314-4.186-2.382-2.164-3.952z"
                                fill="rgba(34,34,34,0.4)"
                            />
                        </g>
                        <path data-name="Rectangle 1983" fill="#fff" d="M0 0h70v426H0z" />
                    </g>
                </svg>
            </div>
        )
    }
}

export default SlideThree
