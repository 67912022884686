import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import Sprite from '../../../../../../assets/img/symbol/sprite.svg'

import store from '../../../../../redux/store'
import { DIALOG_MESSAGES_INFO } from '../../../../../redux/actions/content/messagingActions'
import { HIDE_NEW_MESSAGES } from '../../../../../redux/actions/components/componentsActions'

class NewMessagesPopup extends PureComponent {
    constructor(props) {
        super(props)

        this.handlePopupClick = this.handlePopupClick.bind(this)
    }

    handlePopupClick() {
        store.dispatch({
            type: DIALOG_MESSAGES_INFO,
            payload: {
                dialog_id: this.props.dialogMessages.dialog_id,
                withGlobalTags: true,
                scrollBottomWithNoDelay: true,
                blockReloadContentGroup: true,
                resetMessagesCounter: true,
            },
        })

        store.dispatch({
            type: HIDE_NEW_MESSAGES,
        })
    }

    render() {
        return this.props.newMessages.isNewMessagesModalOpened ? (
            <button type="button" className="new-messages-button" onClick={this.handlePopupClick}>
                <span className="new-update-icon">
                    <svg className="icon icon-arrow-top">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#arrow-top'}></use>
                    </svg>
                </span>
                <span className="new-update-text">New messages received</span>
            </button>
        ) : null
    }
}

export default connect((state) => {
    return {
        dialogMessages: state.dialogMessages,
        newMessages: state.newMessages,
        account: state.account,
    }
})(NewMessagesPopup)
