import { all, call, takeEvery, select, put } from 'redux-saga/effects'

import API from '../../../api'
import { CAMPAIGN } from '../../actions/campaign/campaign-action-types'
import { OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'
import { getIsGroupAdmin, getCurrentGroupUniversityId } from '../../selectors'

function* createCampaignSaga({ payload, meta }) {
    const isGroupAdmin = yield select(getIsGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        payload.universityId = currentGroupUniversityId || payload.university_id
    }

    try {
        const response = yield call(() => {
            return API.campaign.createCampaign(payload)
        })

        if (response.success) {
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Campaign was successfuly created',
                },
            })
            meta.onSuccess()
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CAMPAIGN.CREATE_CAMPAIGN_FAILED,
        })
    }
}

function* watchCampaignCreateSaga() {
    yield takeEvery(CAMPAIGN.CREATE_CAMPAIGN, createCampaignSaga)
}

function* campaignCreateSaga() {
    yield all([call(watchCampaignCreateSaga)])
}

export default campaignCreateSaga
