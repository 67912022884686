import React from 'react'
import CustomKeywordsList from './CustomKeywordsList'
import DefaultKeywordsList from './DefaultKeywordsList'

const DefaultListOfKeywords = ({ dataList }) => {
    return (
        <div className="keyword-list-container">
            <h4 className="settings-name">Keywords in our Safeguarding Policy</h4>
            <div className="keywords-list-wrapper">
                <div className="default-keywords-items-container">
                    {dataList.map((item) => (
                        <div key={item.id} className="keywords-item">
                            {item.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

const KeywordsControlSection = ({ defaultBadwords, customBadwords, showSaveBtn, disabledBtn }) => {
    return (
        <>
            <DefaultKeywordsList dataList={defaultBadwords} showSaveBtn={showSaveBtn} />
            <CustomKeywordsList dataList={customBadwords} disabledBtn={disabledBtn} defaultBadwords={defaultBadwords} />
        </>
    )
}

const KeywordsControl = ({ showKeyword, defaultBadwords, customBadwords, showSaveBtn, disabledBtn }) => {
    return (
        <>
            <div className="keywords-control-container">
                {showKeyword ? (
                    <KeywordsControlSection
                        defaultBadwords={defaultBadwords}
                        customBadwords={customBadwords}
                        showSaveBtn={showSaveBtn}
                        disabledBtn={disabledBtn}
                    />
                ) : (
                    <DefaultListOfKeywords dataList={defaultBadwords} />
                )}
            </div>
        </>
    )
}

export default KeywordsControl
