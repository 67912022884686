export const GROUP_CHATS_INFO_SEARCH = 'GROUP_CHATS_INFO_SEARCH'
export const GROUP_CHATS_INFO_SUCCESS = 'GROUP_CHATS_INFO_SUCCESS'
export const GROUP_CHATS_INFO_FAILED = 'GROUP_CHATS_INFO_FAILED'

export const GROUP_CHAT_INFO_SEARCH = 'GROUP_CHAT_INFO_SEARCH'
export const GROUP_CHAT_INFO_SUCCESS = 'GROUP_CHAT_INFO_SUCCESS'
export const GROUP_CHAT_INFO_FAILED = 'GROUP_CHAT_INFO_FAILED'

export const CREATE_GROUP_CHAT = 'CREATE_GROUP_CHAT'
export const CREATE_GROUP_CHAT_SUCCESS = 'CREATE_GROUP_CHAT_SUCCESS'
export const CREATE_GROUP_CHAT_FAILED = 'CREATE_GROUP_CHAT_FAILED'

export const SEND_GROUP_CHAT_MESSAGE = 'SEND_GROUP_CHAT_MESSAGE'
export const SEND_GROUP_CHAT_MESSAGE_SUCCESS = 'SEND_GROUP_CHAT_MESSAGE_SUCCESS'
export const SEND_GROUP_CHAT_MESSAGE_FAILED = 'SEND_GROUP_CHAT_MESSAGE_FAILED'

export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

export const SET_ACTIVE_GROUP_CHAT = 'SET_ACTIVE_GROUP_CHAT'

export const GROUP_CHAT_MESSAGES_INFO = 'GROUP_CHAT_MESSAGES_INFO'
export const GROUP_CHAT_MESSAGES_INFO_SUCCESS = 'GROUP_CHAT_MESSAGES_INFO_SUCCESS'
export const GROUP_CHAT_MESSAGES_INFO_FAILED = 'GROUP_CHAT_MESSAGES_INFO_FAILED'
export const CLEAR_GROUP_CHAT_MESSAGES = 'CLEAR_GROUP_CHAT_MESSAGES'

export const OPEN_CHAT_GROUP_POPUP = 'OPEN_CHAT_GROUP_POPUP'
export const CLOSE_CHAT_GROUP_POPUP = 'CLOSE_CHAT_GROUP_POPUP'

export const FLAG_DIALOG = 'FLAG_DIALOG'
