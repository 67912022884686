import { Empty, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useHistory, useLocation, useParams } from 'react-router';
import { Button } from 'src/app/components/base/Button';
import { NextArrow, PrevArrow } from 'src/app/components/modals/Buttons';
import ConfirmDeletePopup from 'src/app/components/popups/ConfirmDeletePopup';
import { formatForPreview } from 'src/app/helpers/DateHumanizeHelper';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { useModalVisibility } from 'src/app/hooks/useModalVisibility';
import { addDownloadPoints, deletePost, togglePublishPost } from 'src/app/redux/actions/content/postsActions';
import { Caption } from './Caption';
import { TagsManager } from './TagsManager';
import { ProtectedButton } from 'src/app/components/protected-button';
import { LicensesEnum } from 'src/types/general';
function getPostById(posts, id) {
    var _a, _b, _c, _d, _e, _f;
    if (posts.length === 0)
        return { post: null, nextId: null, prevId: null };
    if (Number.isNaN(id) || id == null)
        return { post: null, nextId: null, prevId: null };
    var index = posts.findIndex(function (post) { return post.id === id; });
    if (index === -1)
        return { post: null, nextId: null, prevId: null };
    return {
        post: posts[index],
        nextId: (_b = (_a = posts[index + 1]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : (_c = posts[0]) === null || _c === void 0 ? void 0 : _c.id,
        prevId: (_e = (_d = posts[index - 1]) === null || _d === void 0 ? void 0 : _d.id) !== null && _e !== void 0 ? _e : (_f = posts[posts.length - 1]) === null || _f === void 0 ? void 0 : _f.id,
    };
}
export var PreviewPostModal = function () {
    var _a;
    var dispatch = useAppDispatch();
    var history = useHistory();
    var hash = useLocation().hash;
    var visible = hash.startsWith('#post-preview');
    var _b = useModalVisibility(visible), mount = _b[0], setMount = _b[1];
    var _c = useState(false), confirmOpened = _c[0], setConfirmOpened = _c[1];
    // eslint-disable-next-line camelcase
    var params = useParams();
    var posts = useAppSelector(function (state) { return state.posts.posts.items; });
    var id = visible ? Number(hash.split('/')[1]) : undefined;
    var _d = getPostById(posts, id), post = _d.post, prevId = _d.prevId, nextId = _d.nextId;
    useEffect(function () {
        return function () {
            setConfirmOpened(false);
        };
    }, [post]);
    useEffect(function () {
        var handler;
        if (visible) {
            handler = function (event) {
                if (event.key === 'ArrowLeft') {
                    event.preventDefault();
                    history.replace({ hash: "#post-preview/".concat(prevId) });
                }
                else if (event.key === 'ArrowRight') {
                    event.preventDefault();
                    history.replace({ hash: "#post-preview/".concat(nextId) });
                }
            };
            window.addEventListener('keydown', handler);
        }
        return function () {
            if (handler != null) {
                window.removeEventListener('keydown', handler);
            }
        };
    }, [history, nextId, prevId, visible]);
    var closeModal = function () {
        history.replace({ hash: '' });
    };
    var handleDownload = function () {
        if (post != null) {
            dispatch(addDownloadPoints(post.id));
        }
    };
    var handleDelete = function () {
        setConfirmOpened(true);
    };
    var closeDelete = function () {
        setConfirmOpened(false);
    };
    var handleDeleteConfirmed = function () {
        if (post == null)
            return;
        var postsType = 'all';
        var typeId = 0;
        if (params.user_id) {
            postsType = 'users';
            typeId = Number(params.user_id);
        }
        else if (params.content_request_id) {
            postsType = 'content_responses';
            typeId = Number(params.content_request_id);
        }
        dispatch(deletePost({
            post_id: post.id,
            postsInfo: true,
            postsType: postsType,
            typeId: typeId,
        }));
        history.replace({ hash: "#post-preview/".concat(nextId) });
        setConfirmOpened(false);
    };
    return mount ? (React.createElement(React.Fragment, null,
        React.createElement(Modal, { open: visible, className: "preview-post-gallery", centered: true, closable: true, maskClosable: true, destroyOnClose: true, onCancel: closeModal, afterClose: setMount, footer: null, width: "auto" }, post != null ? (React.createElement(React.Fragment, null,
            React.createElement(PrevArrow, { tabIndex: 0, onClick: function () {
                    history.replace({ hash: "#post-preview/".concat(prevId) });
                } }),
            React.createElement("div", { className: "grid grid-cols-[1fr_26rem]" },
                React.createElement("div", { className: "min-h-[50vh] max-h-[80vh] aspect-square" }, post.type === 'image' ? (React.createElement("img", { className: "w-full h-full object-cover", src: post.images[0].original, alt: post.caption })) : (React.createElement(ReactPlayer, { key: post.id, className: "w-full h-full [&>video]:object-cover", url: post.videos[0].qualities['370x370'] || post.videos[0].original, "aria-label": "", width: "100%", height: "100%", playing: false, pip: true, playsinline: true, loop: true, controls: true, config: {
                        file: {
                            attributes: {
                                controlsList: 'nodownload',
                                pip: 'true',
                            },
                        },
                    }, onContextMenu: function (event) { return event.preventDefault(); } }))),
                React.createElement("div", { className: "pl-4 pt-6 grid gap-6 grid-rows-[auto_auto_auto_1fr_auto]" },
                    React.createElement("div", { className: "flex justify-between items-baseline flex-wrap" },
                        React.createElement("span", { className: "font-semibold text-header-sm" }, ((_a = post.post_publisher) === null || _a === void 0 ? void 0 : _a.name) || 'Deleted User'),
                        React.createElement("span", { className: "text-opacity-90 text-reading-base whitespace-nowrap" }, formatForPreview(post.created_at))),
                    React.createElement(Caption, { caption: post.caption, postId: post.id }),
                    React.createElement(TagsManager, { postId: post.id, tags: post.globalTags }),
                    React.createElement("div", { className: "grid grid-cols-2 gap-4" },
                        React.createElement(ProtectedButton, { licenseName: LicensesEnum.RSS, type: "secondary", "data-automation-id": "share-rss", onClick: function () {
                                history.push({
                                    hash: "#share-post/".concat(post.id),
                                    state: post,
                                });
                            } }, post.isShared ? 'Share again' : 'Share to RSS'),
                        React.createElement(Button, { type: "secondary", "data-testid": "publish", onClick: function () {
                                dispatch(togglePublishPost(post.id, !post.isPublished));
                            } }, post.isPublished ? 'Unpublish' : 'Publish'),
                        !post.isExample && (React.createElement(ProtectedButton, { licenseName: LicensesEnum.Download, type: "secondary", "data-automation-id": "share-rss", onClick: handleDownload, rel: "noopener noreferrer", href: post.type === 'image'
                                ? post.images[0].original_background != null
                                    ? post.images[0].original_background
                                    : post.images[0].original
                                : post.videos[0].original }, "Download")),
                        React.createElement(Button, { type: "danger", "data-testid": "delete", onClick: handleDelete }, "Delete")))),
            React.createElement(NextArrow, { tabIndex: 0, onClick: function () {
                    history.replace({ hash: "#post-preview/".concat(nextId) });
                } }))) : (React.createElement("div", { className: "h-[40rem] flex items-center justify-center" },
            React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: "No post found" })))),
        React.createElement(Modal, { open: confirmOpened, footer: null, closable: false, maskClosable: true, onCancel: closeDelete, zIndex: 9999 },
            React.createElement(ConfirmDeletePopup, { handleConfirmDelete: handleDeleteConfirmed, handleClosePopup: closeDelete })))) : null;
};
