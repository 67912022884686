import React, { PureComponent } from 'react'
import { Select } from 'antd'

const { Option } = Select

class ChartDropdown extends PureComponent {
    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                    labelInValue
                    defaultValue={{ key: 'responseTime' }}
                    style={{ width: this.props.selectedDiagram === 'responseTime' ? 280 : 320 }}
                    onChange={this.props.onChange}
                    size="default"
                    className="graph-dropdown"
                >
                    <Option value="responseTime" className="graph-dropdown-options">
                        Median First Response Time
                    </Option>
                    <Option value="messagesSent" className="graph-dropdown-options">
                        Total Number of Chat Messages
                    </Option>
                </Select>
            </div>
        )
    }
}

export default ChartDropdown
