import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'

const validate = (values) => {
    const errors = {}

    if (!values.new_password) {
        errors.new_password = 'Please enter a password'
    } else if (!/^(?=.*?[\p{Ll}])(?=.*?[\p{Lu}])(?=.*\d)(?=.*?[\W_]).{8,}$/gu.test(values.new_password)) {
        errors.new_password =
            'Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol'
    }

    if (!values.confirm_password) {
        errors.confirm_password = 'Please enter your password'
    }

    return errors
}

const renderField = ({ input, label, placeholder, type, className, icon, meta: { touched, error, warning } }) => (
    <div className={'input-block-field' + (touched && (error || warning) ? ' input-block-field-error' : '')}>
        <div className="input-icon-block">
            <input {...input} className={className} placeholder={placeholder} type={type} />
            <svg className="icon icon-lock">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#' + icon}></use>
            </svg>
        </div>
        {touched &&
            ((error && <span className="error error-relative">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

class PasswordRecoverForm extends PureComponent {
    render() {
        const { handleSubmit } = this.props

        return (
            <form action="#" method="POST" className="signin-form recovery-form" onSubmit={handleSubmit}>
                <label className="signin-label">
                    <Field
                        name="new_password"
                        component={renderField}
                        type="password"
                        placeholder="New Password"
                        className="signin-input mod-pass"
                        icon="lock"
                    />
                </label>
                <label className="signin-label">
                    <Field
                        name="confirm_password"
                        component={renderField}
                        type="password"
                        placeholder="Confirm Password"
                        className="signin-input mod-pass"
                        icon="lock"
                    />
                </label>
                <button type="submit" className="signin-btn">
                    Recover
                </button>
            </form>
        )
    }
}

export default reduxForm({
    form: 'recover_form',
    validate,
})(PasswordRecoverForm)
