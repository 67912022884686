const OptionalQuestionModel = (data) => {
    const response = {
        id: null,
        answer_type: 'default',
        is_active: false,
        key: '',
        order: null,
        predefined_answers: [], // we could create additional model for answers
        question: '',
        is_required: false,
    }

    if (data) {
        response.id = data.id || Math.random()
        if (data.answer_type) response.answer_type = data.answer_type
        if (data.is_active) response.is_active = data.is_active
        if (data.key) response.key = data.key
        if (data.order) response.order = data.order
        if (data.predefined_answers) response.predefined_answers = data.predefined_answers
        if (data.question) response.question = data.question
        if (data.is_required) response.is_required = data.is_required
    }

    return response
}

export default OptionalQuestionModel
