import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import store from '../../redux/store'
import { getCommonRoutePath } from '../../helpers/helpers'

const SimpleAdmin = ({ component: Component, ...rest }) => {
    const account = store.getState().account

    return (
        <Route
            {...rest}
            render={(props) =>
                account.account_info &&
                account.account_info.id &&
                !account.account_info.isGroupAdmin &&
                (!rest.setupWizard ||
                    (rest.setupWizard &&
                        account.account_info.university &&
                        !account.account_info.university.wizard_setup_passed)) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={getCommonRoutePath() + '/home'} />
                )
            }
        />
    )
}

export default SimpleAdmin
