import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import Textarea from 'react-textarea-autosize'
import store from '../../../../../redux/store'
import { BLOCK_PROSPECT_TO_CHATS, CHAT_MESSAGES_INFO } from '../../../../../redux/actions/chat/messagingActions'
import { PROSPECTS_TABLE_REQUESTED } from '../../../../../redux/actions/reporting/prospectsActions'
import { SET_PROSPECT_BLOCK } from '../../../../../redux/actions/components/componentsActions'

const validate = (values) => {
    const errors = {}

    if (!values.report_text) {
        errors.report_text = 'Please type your reason'
    }
    return errors
}

const renderTextarea = ({
    input,
    placeholder,
    type,
    id,
    className,
    minRows,
    maxRows,
    onKeyUp,
    onChange,
    isDisabled,
    meta: { touched, error, warning },
}) => (
    <>
        <Textarea
            onKeyUp={onKeyUp}
            minRows={minRows}
            maxRows={maxRows}
            id={id}
            type={type}
            placeholder={placeholder}
            className={className}
            disabled={isDisabled}
            maxLength="140"
            {...input}
        />
        <span className="text-size-flagged">
            <span aria-live="polite">{input.value.length}</span>/{140}
        </span>
        {touched && ((error && <span className="report-error">{error}</span>) || (warning && <span>{warning}</span>))}
    </>
)

class BlockReasonPopup extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            showConfirmModal: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(value) {
        const { account, userId, chatType, dispatch, reset, dialogId, tableDataProps, prospectInfoChat, currentChat } =
            this.props
        const currentGroupUniversityId = account.currentGroupUniversityId // for group admins
        const universityId = currentGroupUniversityId || account.account_info.university_id

        const onSuccess = (resp) => {
            dispatch(reset('block_prospect_form'))

            if (chatType) {
                store.dispatch({
                    type: CHAT_MESSAGES_INFO,
                    payload: {
                        dialog_id: dialogId,
                        type_chat: chatType,
                    },
                })
            } else if (tableDataProps) {
                store.dispatch({
                    type: PROSPECTS_TABLE_REQUESTED,
                    ...tableDataProps,
                })
                store.dispatch({
                    type: SET_PROSPECT_BLOCK,
                })
            } else if (prospectInfoChat) {
                store.dispatch({
                    type: CHAT_MESSAGES_INFO,
                    payload: {
                        dialog_id: currentChat.activeChat,
                        type_chat: prospectInfoChat,
                    },
                })
                store.dispatch({
                    type: SET_PROSPECT_BLOCK,
                })
            }
        }

        store.dispatch({
            type: BLOCK_PROSPECT_TO_CHATS,
            payload: {
                userId: userId,
                universityId: universityId,
                blockingReason: value.report_text,
                type_chat: chatType,
                onSuccess: onSuccess,
            },
        })
    }

    render() {
        const { handleSubmit } = this.props
        return (
            <div className="block-prospect-popup-container">
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <h2 className="modal-name">Reason of prospect blocking</h2>
                    <p>Provide a reason to make it easier to manage this prospect in the list later</p>
                    <p className="interface-bold">
                        If you block the prospect, you will be unable to unblock them at a later date from the dashboard
                    </p>
                    <div className="repotrt-field-block">
                        <Field
                            name="report_text"
                            component={renderTextarea}
                            type="text"
                            placeholder="Type your reason here"
                            className="repotrt-field"
                            maxRows={3}
                            minRows={1}
                        />
                    </div>
                    <div className="btn-wrap confirm-ban-buttons">
                        <button type="submit" className="el-btn block-prospect-button">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

export default reduxForm({
    form: 'block_prospect_form',
    validate,
    destroyOnUnmount: false,
})(
    connect((state) => {
        return {
            account: state.account,
        }
    })(BlockReasonPopup)
)
