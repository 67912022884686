import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'

import store from '../../../redux/store'

import DatesIntervalSelector from '../components/DatesIntervalSelector'
import Summary from './Summary'
import Chat from './Chat'
import Content from './Content'
import FAQ from './FAQ'
import OldSummary from './OldSummary'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import {
    fetchUniversityStatistics,
    fetchOverviewSummaryStatistics,
    fetchOverviewChatStatistics,
    fetchOverviewContentStatistics,
    fetchOverviewFaqStatistics,
} from '../../../redux/actions/reporting/overviewActions'

import { getDateRange } from '../../../helpers/time'
import { isIdpRedirect } from 'src/app/helpers/idpRedirect'
import { Redirect } from 'react-router'
import { getCommonRoutePath } from 'src/app/helpers/helpers'

class ReportingOverview extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedTab: 'summary',
            selectedButton: '30Days',
            selectedDateRange: getDateRange(30),
            summaryLastFetchDateRange: {},
            chatLastFetchDateRange: {},
            contentLastFetchDateRange: {},
            faqLastFetchDateRange: {},
            otherLastFetchDateRange: {},
        }

        this.fetchData = this.fetchData.bind(this)
        this.fetchTabData = this.fetchTabData.bind(this)
        this.onDatesIntervalSelectorUpdate = this.onDatesIntervalSelectorUpdate.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'reporting',
            },
        })

        this.fetchTabData(this.state.selectedTab, this.state.selectedDateRange)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.fetchTabData(this.state.selectedTab, this.state.selectedDateRange, true)
        }
    }

    fetchTabData(selectedTab, selectedDateRange, refresh = false) {
        switch (selectedTab) {
            case 'summary': {
                if (
                    this.fetchData(
                        selectedDateRange,
                        this.state.summaryLastFetchDateRange,
                        this.props.fetchOverviewSummaryStatistics,
                        refresh
                    )
                ) {
                    this.setState({ summaryLastFetchDateRange: selectedDateRange })
                }

                break
            }
            case 'chat': {
                if (
                    this.fetchData(
                        selectedDateRange,
                        this.state.chatLastFetchDateRange,
                        this.props.fetchOverviewChatStatistics,
                        refresh
                    )
                ) {
                    this.setState({ chatLastFetchDateRange: selectedDateRange })
                }

                break
            }
            case 'content': {
                if (
                    this.fetchData(
                        selectedDateRange,
                        this.state.contentLastFetchDateRange,
                        this.props.fetchOverviewContentStatistics,
                        refresh
                    )
                ) {
                    this.setState({ contentLastFetchDateRange: selectedDateRange })
                }

                break
            }
            case 'faq': {
                if (
                    this.fetchData(
                        selectedDateRange,
                        this.state.faqLastFetchDateRange,
                        this.props.fetchOverviewFaqStatistics,
                        refresh
                    )
                ) {
                    this.setState({ faqLastFetchDateRange: selectedDateRange })
                }

                break
            }
            case 'other': {
                if (
                    this.fetchData(
                        selectedDateRange,
                        this.state.otherLastFetchDateRange,
                        this.props.fetchUniversityStatistics,
                        refresh
                    )
                ) {
                    this.setState({ otherLastFetchDateRange: selectedDateRange })
                }

                break
            }
            // No default
        }
    }

    fetchData(selectedDateRange, previousDateRange, fetchMethod, refresh = false) {
        if (selectedDateRange !== previousDateRange || refresh) {
            fetchMethod(selectedDateRange)

            return true
        }

        return false
    }

    generateDatesSelector() {
        return (
            <DatesIntervalSelector
                selectedButton={this.state.selectedButton}
                fromDate={this.state.selectedDateRange.fromDate}
                toDate={this.state.selectedDateRange.toDate}
                onChange={this.onDatesIntervalSelectorUpdate}
                topAlign={4}
            />
        )
    }

    onDatesIntervalSelectorUpdate(selectedButton, selectedDateRange) {
        this.setState({ selectedButton, selectedDateRange })

        this.fetchTabData(this.state.selectedTab, selectedDateRange)
    }

    onTabSwitch(selectedTab) {
        this.setState({ selectedTab })

        this.fetchTabData(selectedTab, this.state.selectedDateRange)
    }

    render() {
        if (isIdpRedirect(this.props.account.account_info)) {
            return <Redirect to={`${getCommonRoutePath()}/reporting/ambassadors`} />
        }
        return (
            <section className="content reporting">
                <div className="prospect-table-title">Overview</div>
                <Tabs
                    activeKey={this.state.selectedTab}
                    size="large"
                    onChange={(activeKey) => {
                        this.onTabSwitch(activeKey)
                    }}
                    tabBarExtraContent={this.generateDatesSelector()}
                >
                    <Tabs.TabPane tab="Summary" key="summary">
                        <Summary
                            data={this.props.summary}
                            fromDate={this.state.selectedDateRange.fromDate}
                            toDate={this.state.selectedDateRange.toDate}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Chat" key="chat">
                        <Chat
                            data={this.props.chat}
                            fromDate={this.state.selectedDateRange.fromDate}
                            toDate={this.state.selectedDateRange.toDate}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Content" key="content">
                        <Content
                            data={this.props.content}
                            fromDate={this.state.selectedDateRange.fromDate}
                            toDate={this.state.selectedDateRange.toDate}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="FAQ" key="faq">
                        <FAQ
                            data={this.props.faq}
                            fromDate={this.state.selectedDateRange.fromDate}
                            toDate={this.state.selectedDateRange.toDate}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Other" key="other">
                        <OldSummary
                            data={this.props.old}
                            fromDate={this.state.selectedDateRange.fromDate}
                            toDate={this.state.selectedDateRange.toDate}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    summary: state.overview.summary,
    chat: state.overview.chat,
    content: state.overview.content,
    faq: state.overview.faq,
    old: state.overview.old,
})

export default connect(mapStateToProps, {
    fetchUniversityStatistics,
    fetchOverviewSummaryStatistics,
    fetchOverviewChatStatistics,
    fetchOverviewContentStatistics,
    fetchOverviewFaqStatistics,
})(ReportingOverview)
