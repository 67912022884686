import cn from 'classnames'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import Loader from '../../../content/components/Loader'
import { getActiveLiveStream, getIsGroupAdmin } from '../../../../redux/selectors'

import { EventHeader, Sidebar, StreamChat } from './components'

import { CHAT_TYPE_KEY } from '../../../../common/constants'
import PinnedMessageContainer from '../../../../components/pinned-message-container/pinned-message-container'

const LiveStreamView = () => {
    const isGroupAdmin = useSelector(getIsGroupAdmin)
    const activeEvent = useSelector(getActiveLiveStream)

    const { id, dialogId } = activeEvent

    const wrapperClassName = useMemo(
        () =>
            cn('chat mod-messaging', {
                'live-group-event': isGroupAdmin,
            }),
        [isGroupAdmin]
    )
    return (
        <div className={wrapperClassName}>
            {id ? (
                <>
                    <div className="chat-block" style={{ paddingTop: '1.9rem' }}>
                        <EventHeader />
                        <PinnedMessageContainer dialogId={dialogId} chatType={CHAT_TYPE_KEY.LIVE_STREAM_CHAT} />

                        <StreamChat activeEvent={activeEvent} />
                    </div>
                    <Sidebar activeEvent={activeEvent} />
                </>
            ) : (
                <div className="live-events-loader">
                    <Loader />
                </div>
            )}
        </div>
    )
}

export default LiveStreamView
