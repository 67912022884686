/* eslint-disable react-hooks/exhaustive-deps */
import cn from 'classnames'
import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LIVE_STREAM_STATUS } from '../../../../common/constants'
import Loader from '../../../content/components/Loader'
import {
    fetchProspects,
    joinStreamLobby,
    updateStreamStatus,
} from '../../../../redux/actions/live-streams/live-streams-actions'
import { openNotificationModal } from '../../../../redux/actions/notification-modal/notification-modal-actions'

import { LIVE_STREAMS } from '../../../../redux/actions/live-streams/live-streams-action-types'

import { getAccountSlice, getIsGroupAdmin, getActiveLiveStream } from '../../../../redux/selectors'

import { PreviewImage, EventHeader, ChatSidebar, EventStream } from './components'
import { pusherChild } from '../../../../system/services/PusherWrapper'

const CHANNEL_NAME = 'dashboard'

const ScheduledLiveStreamView = () => {
    const isGroupAdmin = useSelector(getIsGroupAdmin)
    const activeEvent = useSelector(getActiveLiveStream)
    const dispatch = useDispatch()
    const account = useSelector(getAccountSlice)

    const channel = useRef(null)
    const currentId = useRef(null)

    const { id, status, isStreamActive, isLobbyMode } = activeEvent

    useEffect(() => {
        if (currentId.current !== id) {
            if (channel.current !== null) {
                channel.current = pusherChild.unsubscribe(CHANNEL_NAME)
                channel.current = null
                currentId.current = id
            }
            channel.current = pusherChild.subscribe(CHANNEL_NAME)

            channel.current.bind(`liveStreams:${id}:info`, (data) => {
                if (data && data.action === 'userAction') {
                    dispatch(
                        fetchProspects({
                            liveStreamId: id,
                        })
                    )
                }

                if (data && data.status) {
                    dispatch(
                        updateStreamStatus({
                            liveStreamId: id,
                            status: data.status,
                            isStreamActive: data.isStreamActive,
                        })
                    )
                    dispatch(openNotificationModal(LIVE_STREAMS.UPDATE_SUCCESS, { status: data.status }))
                }
            })
        }

        return () => {
            channel.current = pusherChild.unsubscribe(CHANNEL_NAME)
            channel.current = null
            if (isLobbyMode) {
                dispatch(joinStreamLobby({ isLobbyMode: false, liveStreamId: id }))
            }
        }
    }, [id])

    const isInProgress = useMemo(() => status === LIVE_STREAM_STATUS.IN_PROGRESS, [status])

    const wrapperClassName = useMemo(
        () =>
            cn('live-event tap-live-event-block-wrapper', {
                'live-group-event': isGroupAdmin,
            }),
        [isGroupAdmin]
    )

    return (
        <div className={wrapperClassName}>
            {id ? (
                <>
                    <div className="event-preview-block">
                        <EventHeader />
                        {!isLobbyMode && (!isInProgress || !isStreamActive) && (
                            <PreviewImage activeEvent={activeEvent} />
                        )}
                        {(isInProgress || isLobbyMode) && <EventStream activeEvent={activeEvent} account={account} />}
                    </div>
                    <ChatSidebar activeEvent={activeEvent} />
                </>
            ) : (
                <div className="live-events-loader">
                    <Loader />
                </div>
            )}
        </div>
    )
}

export default ScheduledLiveStreamView
