import React, { useCallback } from 'react'
import { Button } from 'antd'

import { useDispatch, useSelector } from 'react-redux'

import { OPEN_ACTION_MODAL } from '../../../../../redux/actions/components/componentsActions'
import { getAccountInfo } from '../../../../../redux/selectors'

const CopyLinkCell = ({ record }) => {
    const dispatch = useDispatch()
    const { university } = useSelector(getAccountInfo)

    const chatCheckbox = record.chat_permission.is_enabled

    const handleCopyUserLink = useCallback(
        (userId) => {
            if (!university.university_url) {
                dispatch({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: "You haven't yet set up your TAP Feed widget. Please embed a TAP Feed widget in your website in order to share links to your ambassadors' profile cards.",
                    },
                })
            } else {
                const copyText = document.querySelector('.university-access-code')
                copyText.value = university.university_url + '?user_id=' + userId
                copyText.select()
                document.execCommand('copy')

                dispatch({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: 'Student link copied to clipboard',
                    },
                })
            }
        },
        [dispatch, university]
    )

    return (
        <Button
            type="button"
            id={'user-link-' + record.userInfo.id}
            className={chatCheckbox ? 'view-profile-info-btn' : 'view-profile-info-btn hidden-block'}
            onClick={() => handleCopyUserLink(record.userInfo.id)}
        >
            Copy link
        </Button>
    )
}

const copyLinkCellRenderer = (text, record) => <CopyLinkCell text={text} record={record} />

export default copyLinkCellRenderer
