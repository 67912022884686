import React, { useState, useRef } from 'react'
import { Input } from 'antd'

import store from '../../../../redux/store'

import {
    ADD_WHITELISTED_BADWORD,
    DELETE_WHITELISTED_BADWORD,
    DISABLE_BUTTON,
} from '../../../../redux/actions/settings/safeguardingActions'

import { OPEN_ACTION_MODAL, CLOSE_ACTION_MODAL } from '../../../../redux/actions/components/componentsActions'

const AddItemIcon = () => {
    return (
        <div className="keyword-input-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16.001" viewBox="0 0 16 16.001">
                <path
                    fill="#262e45"
                    d="M4.576 11.563l1.409-.406 8.482-8.584a.365.365 0 0 0 0-.512l-.508-.514a.354.354 0 0 0-.506 0l-8.46 8.562-.416 1.456zM14.962.53l.508.514a1.824 1.824 0 0 1 .013 2.556l-8.741 8.847-3.011.867a.721.721 0 0 1-.888-.5.732.732 0 0 1 0-.4l.876-3.072L12.435.516a1.772 1.772 0 0 1 2.527.013zm-9.215.922a.727.727 0 0 1 0 1.454H2.874A1.445 1.445 0 0 0 1.437 4.36v8.725a1.446 1.446 0 0 0 1.437 1.454h8.621a1.446 1.446 0 0 0 1.438-1.454v-2.908a.718.718 0 1 1 1.437 0v2.907a2.892 2.892 0 0 1-2.874 2.909H2.874A2.891 2.891 0 0 1 0 13.085V4.36a2.891 2.891 0 0 1 2.874-2.908z"
                    transform="translate(0 .007)"
                />
            </svg>
        </div>
    )
}

const CloseIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                <path
                    fill="#ff5100"
                    d="M7.006 5.657l3.371-3.372A.954.954 0 1 0 9.029.936L5.657 4.308 2.285.936A.954.954 0 1 0 .936 2.285l3.372 3.372L.936 9.029a.954.954 0 1 0 1.349 1.349l3.372-3.372 3.372 3.371a.954.954 0 1 0 1.349-1.349L7.006 5.657z"
                    transform="translate(-.657 -.657)"
                />
            </svg>
        </>
    )
}

const Data = ({ dataList, handleRemoveItem, handleRemoveAll, searchText }) => {
    const filteredKeywords = dataList.filter((keyword) => {
        if (searchText.length === 1) {
            return keyword.name.charAt(0).toLowerCase() === searchText.toLowerCase()
        }
        return keyword.name.toLowerCase().includes(searchText.toLowerCase())
    })

    return (
        <div className="keywords-items-container">
            {filteredKeywords.length > 0 ? (
                <>
                    <div className="first-item custom">
                        <button type="button" className="add-keyword-btn" onClick={handleRemoveAll}>
                            Remove All
                        </button>
                    </div>
                    <div className="custom-items-group data-control">
                        {filteredKeywords.map((item, i) => {
                            return (
                                <div key={i} className="group-item-container">
                                    <div className="group-item">{item.name}</div>
                                    <button type="button" className="close-btn" onClick={() => handleRemoveItem(item)}>
                                        <CloseIcon />
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </>
            ) : null}
        </div>
    )
}

const DataControl = ({ dataList, disabledBtn }) => {
    const refContainer = useRef(null)
    const [searchText, setSearchText] = useState('')
    const [isValid, checkIsValid] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    const onSearch = (e) => {
        checkIsValid(true)
        setSearchText(e.target.value.trim())
    }

    const onPressEnter = () => {
        const isKeywordExist = dataList.some((keywordsItems) => keywordsItems.name === searchText)
        const isEmailOrDomain = /@.+\.[a-zA-Z]+$/.test(searchText)
        const isPhoneNumber = /^[\d!@#$%\^&*)(+=._-]*$/.test(searchText)

        if (searchText && !isKeywordExist && (isEmailOrDomain || isPhoneNumber)) {
            store.dispatch({
                type: ADD_WHITELISTED_BADWORD,
                payload: searchText,
            })
        } else {
            checkIsValid(false)
        }

        if (isKeywordExist) {
            setErrorMessage('This keyword already exists')
        } else if (!isEmailOrDomain && searchText) {
            setErrorMessage('Invalid email or email domain')
        } else {
            setErrorMessage('')
        }

        setSearchText('')
    }

    const handleRemoveAll = () => {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="safeguarding-popup">
                        <button type="button" className="close-btn" onClick={handleCancel}>
                            <CloseIcon />
                        </button>
                        <p className="safeguarding-modal-title">
                            Do you want to <span>delete all keywords</span> from the approved list? They will be blocked
                            in conversations.
                        </p>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button
                                ref={refContainer}
                                type="submit"
                                className="el-btn confirm-delete-button"
                                onClick={() => {
                                    store.dispatch({
                                        type: DELETE_WHITELISTED_BADWORD,
                                        payload: dataList.map((item) => item.id),
                                    })
                                    store.dispatch({
                                        type: DISABLE_BUTTON,
                                        payload: true,
                                    })
                                }}
                            >
                                Delete
                            </button>
                            <button type="button" className="el-btn cancel-delete-button" onClick={handleCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
        checkIsValid(true)
    }

    const handleRemoveItem = (keyword) => {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="safeguarding-popup">
                        <button type="button" className="close-btn" onClick={handleCancel}>
                            <CloseIcon />
                        </button>
                        <p className="safeguarding-modal-title">
                            Do you want to delete <span>'{keyword.name}'</span> from the approved list? It will be
                            blocked in conversations.
                        </p>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button
                                ref={refContainer}
                                type="submit"
                                className="el-btn confirm-delete-button"
                                onClick={() => {
                                    store.dispatch({
                                        type: DELETE_WHITELISTED_BADWORD,
                                        payload: [keyword.id],
                                    })
                                    store.dispatch({
                                        type: DISABLE_BUTTON,
                                        payload: true,
                                    })
                                }}
                            >
                                Delete
                            </button>
                            <button type="button" className="el-btn cancel-delete-button" onClick={handleCancel}>
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
        checkIsValid(true)
    }

    const handleCancel = () => {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
        store.dispatch({
            type: DISABLE_BUTTON,
            payload: false,
        })
        checkIsValid(true)
    }

    if (refContainer.current) {
        if (disabledBtn) {
            refContainer.current.setAttribute('disabled', true)
        } else {
            refContainer.current.removeAttribute('disabled')
        }
    }

    return (
        <div className="data-list-container">
            <h4 className="settings-name">Approved phone numbers, emails and email domains</h4>
            <div
                className={`data-list-wrapper ${
                    dataList.length === 1 ? 'one-item' : dataList.length == 2 ? 'two-items' : ''
                }`}
            >
                <div className="search-container">
                    <Input
                        allowClear
                        placeholder="Type and press enter to add "
                        onChange={onSearch}
                        onPressEnter={onPressEnter}
                        prefix={<AddItemIcon />}
                        maxLength={255}
                        value={searchText.trim()}
                    />
                    {!isValid ? <span className="error-message">{errorMessage}</span> : null}
                </div>
                <Data
                    dataList={dataList}
                    searchText={searchText}
                    onSearch={onSearch}
                    handleRemoveAll={handleRemoveAll}
                    handleRemoveItem={handleRemoveItem}
                />
            </div>
        </div>
    )
}

export default DataControl
