import cn from 'classnames'
import React, { useState } from 'react'

// direction is to align tooltip to different sides (left or right)
const Tooltip = ({ description, children, direction = 'left', disabled }) => {
    const [isVisible, setIsVisible] = useState(false)
    return (
        <>
            {!disabled ? (
                <div
                    className="tooltip-block"
                    onMouseEnter={() => setIsVisible(true)}
                    onMouseLeave={() => setIsVisible(false)}
                >
                    {children}
                    <span
                        className={cn(
                            { 'tooltip-block__description--visible': isVisible },
                            `tooltip-block__description tooltip-block__description--${direction}`
                        )}
                    >
                        {description}
                    </span>
                    <span
                        className={cn('tooltip-block__triangle', { 'tooltip-block__triangle--visible': isVisible })}
                    />
                </div>
            ) : (
                children
            )}
        </>
    )
}

export default Tooltip
