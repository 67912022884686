export const ACTIVITY_CHARTS_REQUESTED = 'ACTIVITY_CHARTS_REQUESTED'
export const ACTIVITY_CHARTS_SUCCESS = 'ACTIVITY_CHARTS_SUCCESS'
export const ACTIVITY_CHARTS_FAILED = 'ACTIVITY_CHARTS_FAILED'
export const ACTIVITY_TABLE_REQUESTED = 'ACTIVITY_TABLE_REQUESTED'
export const ACTIVITY_TABLE_SUCCESS = 'ACTIVITY_TABLE_SUCCESS'
export const ACTIVITY_TABLE_FAILED = 'ACTIVITY_TABLE_FAILED'
export const ACTIVITY_CSV_REQUESTED = 'ACTIVITY_CSV_REQUESTED'
export const ACTIVITY_CSV_SUCCESS = 'ACTIVITY_CSV_SUCCESS'
export const ACTIVITY_CSV_FAILED = 'ACTIVITY_CSV_FAILED'

export const fetchActivityChartsData = (filter, utmParams) => ({
    type: ACTIVITY_CHARTS_REQUESTED,
    filter,
    utmParams,
})

export const fetchActivityTableData = (filter, search, limit, offset, orderBy, order) => ({
    type: ACTIVITY_TABLE_REQUESTED,
    filter,
    search,
    limit,
    offset,
    orderBy,
    order,
})

export const fetchActivityCsvData = (filter, utmParams) => ({
    type: ACTIVITY_CSV_REQUESTED,
    filter,
    utmParams,
})
