import API from '../../../api'
import { call, put, select } from 'redux-saga/effects'
import {
    ACTIVITY_CHARTS_SUCCESS,
    ACTIVITY_CHARTS_FAILED,
    ACTIVITY_TABLE_SUCCESS,
    ACTIVITY_TABLE_FAILED,
    ACTIVITY_CSV_SUCCESS,
    ACTIVITY_CSV_FAILED,
} from '../../actions/reporting/activityActions'

const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

export function* activityChartsData({ filter, utmParams }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        if (utmParams && !utmParams.length) {
            yield put({
                type: ACTIVITY_CHARTS_SUCCESS,
                payload: {},
            })

            return
        }

        const response = yield call(() => {
            return API.dashboard.activityChartsData(filter, utmParams, universityIds)
        })

        if (response.success) {
            yield put({
                type: ACTIVITY_CHARTS_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: ACTIVITY_CHARTS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: ACTIVITY_CHARTS_FAILED,
        })
    }
}

export function* activityTableData({ filter, search, limit, offset, orderBy, order }) {
    const universityIds = yield select(getCurrentGroupUniversityIds)
    try {
        const response = yield call(() => {
            return API.dashboard.activityTableData(filter, search, limit, offset, orderBy, order, universityIds)
        })

        if (response.success) {
            yield put({
                type: ACTIVITY_TABLE_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: ACTIVITY_TABLE_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: ACTIVITY_TABLE_FAILED,
        })
    }
}

export function* activityCsvData({ filter, utmParams }) {
    const universityIds = yield select(getCurrentGroupUniversityIds)
    try {
        const response = yield call(() => {
            return API.dashboard.activityCsvData(filter, utmParams, universityIds)
        })

        if (response.success) {
            yield put({
                type: ACTIVITY_CSV_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: ACTIVITY_CSV_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: ACTIVITY_CSV_FAILED,
        })
    }
}
