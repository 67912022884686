import { produce } from 'immer'
import { ACCOUNT } from '../../actions/account/account-types'
import {
    ACCOUNT_INFO_SUCCESS,
    ACCOUNT_LOGIN_SUCCESS,
    ACCOUNT_LOGOUT_SUCCESS,
    CHANGE_MANAGEMENT_TERM,
    CHECK_ADMIN_INVITATION_FAILED,
    CHECK_ADMIN_INVITATION_SUCCESS,
    CLEAR_REGISTER_STEP,
    CLOSE_SUCCESS_REGISTRATION_MODAL,
    SET_TEST_UNIVERSITY_TOGGLE_VALUE,
    SET_UNIVERSITY_GROUP,
} from '../../actions/account/accountActions'
import {
    ACCOUNT_CHANGE_FIRST_NAME_SUCCESS,
    ACCOUNT_CHANGE_LAST_NAME_SUCCESS,
} from '../../actions/account/accountEditActions'
import { SET_SETUP_WIZARD_SUCCESS } from '../../actions/settings/setupWizardActions'

import { PermissionEnum } from 'src/types/general'

import { GROUPS_IDS } from 'src/app/common/ids'

/**
 * @type {import('../types/account').AccountStore}
 */
export const initialState = {
    token: JSON.parse(window.localStorage.getItem('Account')),
    account_info: JSON.parse(window.localStorage.getItem('AccountInfo')),
    plsSignUpStep: JSON.parse(window.localStorage.getItem('PLSSignUpStep')) || 1,
    currentGroupUniversityId: null,
    isChangeEmailModalOpened: false,
    isChangePasswordModalOpened: false,
    registrationStep: 1,
    isTutorialModalOpened: false,
    user_id: null,
    userEmail: null,
    userPassword: null,
    isValidInvitation: true,
    isMaintenanceMode: false,
    showIdpActivation: false,
    appDomain: null,
    appClientId: null,
    profileTypes: [],
    hubspotToken: null,
    refreshTokenIsLoading: false,
    isEncryptionOn: false,
}

/**
 * @param {import('../../types/account').AccountStore} state
 * @param {import('redux').AnyAction} action
 * @returns {import('../../types/account').AccountStore}
 */
export default function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case ACCOUNT_LOGIN_SUCCESS: {
            const newState = {
                ...state,
                token: payload,
            }
            return newState
        }

        case CLOSE_SUCCESS_REGISTRATION_MODAL: {
            return {
                ...state,
                registrationStep: 3,
            }
        }

        case CLEAR_REGISTER_STEP: {
            return {
                ...state,
                registrationStep: 1,
            }
        }

        case ACCOUNT_INFO_SUCCESS: {
            const { accountInfo, university_id: universityId } = payload
            return produce(state, (draft) => {
                draft.account_info = accountInfo
                draft.currentGroupUniversityId = universityId || state.currentGroupUniversityId
                draft.hubspotToken = null
                draft.showIdpActivation = accountInfo.showIdpActivation || state.showIdpActivation

                if (accountInfo?.university?.permissions) {
                    const sascPermission = accountInfo.university.permissions.find(
                        (item) => item.key === PermissionEnum.SascOnly
                    )
                    const idpPermission = accountInfo.university.permissions.find(
                        (item) => item.key === PermissionEnum.IdpOnly
                    )
                    const buzzPermission = accountInfo.university.permissions.find(
                        (item) => item.key === PermissionEnum.BuzzOnly
                    )

                    draft.account_info.isSasc = sascPermission ? sascPermission.is_enabled : false
                    draft.account_info.isIdp = idpPermission ? idpPermission.is_enabled : false
                    draft.account_info.isBuzzOnly = buzzPermission ? buzzPermission.is_enabled : false
                }
                if (accountInfo?.isGroupAdmin) {
                    draft.account_info.isIdpGroupAdmin =
                        accountInfo.email.includes('idp@') &&
                        (accountInfo.universityGroup.id === GROUPS_IDS.IDP ||
                            accountInfo.universityGroup.id === GROUPS_IDS.TEST_IDP_GROUP)
                }
            })
        }

        case SET_UNIVERSITY_GROUP: {
            // from dropdown which in header, if admin chooses group then value is null otherwise university id
            // to know if group admin currently on group dash or on concrete university dash
            const universities = [...state.account_info.universityGroup.universities]
            const accountInfo = { ...state.account_info }

            if (payload.university_id) {
                for (const university of universities) {
                    if (university.id === payload.university_id) {
                        accountInfo.university = university
                        if (state.account_info.universityGroup.type === 'partner') {
                            accountInfo.university.partnerGroups = [
                                {
                                    id: state.account_info.universityGroup.id,
                                    name: state.account_info.universityGroup.name,
                                },
                            ]
                        }
                    }
                }
            } else if (state.account_info.universityGroup) {
                accountInfo.university = { ...state.account_info.universityGroup }
                accountInfo.university.university_url = accountInfo.university.tap_page_url
            }

            return produce(state, (draft) => {
                draft.currentGroupUniversityId = payload.university_id
                draft.account_info = accountInfo

                if (accountInfo?.university?.permissions) {
                    const sascPermission = accountInfo.university.permissions.find(
                        (item) => item.key === PermissionEnum.SascOnly
                    )
                    const idpPermission = accountInfo.university.permissions.find(
                        (item) => item.key === PermissionEnum.IdpOnly
                    )
                    const buzzPermission = accountInfo.university.permissions.find(
                        (item) => item.key === PermissionEnum.BuzzOnly
                    )

                    draft.account_info.isSasc = sascPermission ? sascPermission.is_enabled : false
                    draft.account_info.isIdp = idpPermission ? idpPermission.is_enabled : false
                    draft.account_info.isBuzzOnly = buzzPermission ? buzzPermission.is_enabled : false
                }
            })
        }

        case ACCOUNT.EDIT_PRIVACY_POLICY_SUCCESS: {
            const newState = { ...state }
            newState.account_info.university.privacy_policy = payload.privacy_policy
            return newState
        }

        case ACCOUNT.SET_MARKETING_CONSENT_SUCCESS: {
            const newState = { ...state }
            newState.account_info.university.isMarketingConsentDefault = payload.isDefault
            newState.account_info.university.isMarketingConsentShown = payload.isShown
            return newState
        }

        case ACCOUNT.SET_MARKETING_CONSENT_CUSTOM_TEXT_SUCCESS: {
            const newState = { ...state }
            newState.account_info.university.marketingConsentText = payload.text
            return newState
        }

        case ACCOUNT_LOGOUT_SUCCESS: {
            return {
                ...state,
                token: null,
                account_info: null,
                currentGroupUniversityId: null,
                plsSignUpStep: 1,
                hubspotToken: null,
            }
        }

        case CHANGE_MANAGEMENT_TERM: {
            const newState = { ...state }
            newState.account_info.university.data_management_term = payload.data_management_term
            return newState
        }

        case SET_TEST_UNIVERSITY_TOGGLE_VALUE: {
            const newState = { ...state }
            newState.account_info.university.is_test = payload.isTest
            return newState
        }

        case SET_SETUP_WIZARD_SUCCESS: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    university: {
                        ...state.account_info.university,
                        wizard_setup_passed: true,
                    },
                },
            }
        }
        case ACCOUNT.UNIVERSITY_SSO_SUCCESS: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    university: {
                        ...state.account_info.university,
                        is_sso_activated: payload.is_sso_activated,
                        is_sso_only_activated: !payload.is_sso_activated
                            ? false
                            : state.account_info.university.is_sso_only_activated,
                    },
                },
            }
        }
        case ACCOUNT.ACTIVATE_TOGGLE_UNIVERSITY_SSO_ONLY_SUCCESS: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    university: {
                        ...state.account_info.university,
                        is_sso_only_activated: payload.is_sso_only_activated,
                    },
                },
            }
        }
        case CHECK_ADMIN_INVITATION_SUCCESS: {
            return {
                ...state,
                isValidInvitation: true,
                appDomain: payload.appDomain,
                appClientId: payload.appClientId,
                isEncryptionOn: payload.isEncryptionOn,
            }
        }

        case CHECK_ADMIN_INVITATION_FAILED: {
            return {
                ...state,
                isValidInvitation: false,
            }
        }

        case ACCOUNT.SET_MAINTENANCE_MODE: {
            return {
                ...state,
                isMaintenanceMode: true,
            }
        }
        case ACCOUNT.UPDATE_ACCOUNT_INFO_SUCCESS: {
            const { name, lastName, institutionName, websiteUrl, slug } = payload
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    name: name,
                    last_name: lastName,
                    university: {
                        ...state.account_info.university,
                        website_url: websiteUrl,
                        name: institutionName,
                        slug,
                    },
                },
            }
        }
        case ACCOUNT.COMPLETE_PLS_STEP: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    plsWizardSetup: {
                        ...state.account_info.plsWizardSetup,
                        [payload.step]: true,
                    },
                },
            }
        }
        case ACCOUNT.ACTIVATE_PLS_AMBASSADOR_TO_CHAT_SUCCESS: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    plsWizardSetup: {
                        ...state.account_info.plsWizardSetup,
                        ambassador: {
                            ...state.account_info.plsWizardSetup.ambassador,
                            isAmbassadorActivated: payload,
                        },
                    },
                },
            }
        }
        case ACCOUNT.GET_PLS_TAP_FEED_LINK_SUCCESS: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    university: {
                        ...state.account_info.university,
                        slug: payload,
                    },
                },
            }
        }
        case ACCOUNT.CHANGE_ORGANISATION_WEBSITE_SUCCESS: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    university: {
                        ...state.account_info.university,
                        website_url: payload,
                    },
                },
            }
        }
        case ACCOUNT.CHANGE_ORGANISATION_WEBSITE_FAILED: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    university: {
                        ...state.account_info.university,
                        website_url: '',
                    },
                },
            }
        }
        case ACCOUNT.SET_PLS_SIGN_UP_STEP: {
            return {
                ...state,
                plsSignUpStep: payload.step,
                token: payload.token,
            }
        }
        case ACCOUNT.GET_PROFILE_TYPES_SUCCESS: {
            return {
                ...state,
                profileTypes: payload,
            }
        }

        case ACCOUNT.SET_PROFILE_TYPE_SUCCESS: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    profileType: state.profileTypes.find((item) => item.id === payload.profileTypeId),
                },
            }
        }
        case ACCOUNT.COMPLETE_PLS_ONBOARDING: {
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    plsWizardSetup: {
                        ...state.account_info.plsWizardSetup,
                        isCompleted: true,
                    },
                },
            }
        }
        case ACCOUNT.HUBSPOT_LOGIN_SUCCESS: {
            return {
                ...state,
                hubspotToken: payload.token,
            }
        }
        case ACCOUNT.SET_EXPERIANCE_BASED_QUESTIONS_TOGGLE_SUCCESS: {
            const { isAllowed, allowedType } = payload
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    university: {
                        ...state.account_info.university,
                        isAllowedToSkipExperienceBasedQuestions:
                            allowedType === 'skipQuestion'
                                ? isAllowed
                                : state.account_info.university.isAllowedToSkipExperienceBasedQuestions,
                        isEnabledExperienceBasedQuestions:
                            allowedType === 'enabledFeature'
                                ? isAllowed
                                : state.account_info.university.isEnabledExperienceBasedQuestions,
                    },
                },
            }
        }
        case ACCOUNT.SET_OTP_VERIFICATION_TOGGLE_SUCCESS: {
            const { isAllowed } = payload
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    university: {
                        ...state.account_info.university,
                        isEnabledSignUpPhoneVerification: isAllowed,
                    },
                },
            }
        }
        case ACCOUNT.SET_PERMISSON_TOGGLE_SUCCESS: {
            const { isEnabled, permissionKey } = payload

            return produce(state, (draft) => {
                draft.account_info.university.permissions.forEach((item) => {
                    if (item.key === permissionKey) {
                        item.is_enabled = isEnabled
                    }
                })
            })
        }
        case ACCOUNT_CHANGE_FIRST_NAME_SUCCESS: {
            const { firstName } = payload
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    name: firstName,
                },
            }
        }
        case ACCOUNT_CHANGE_LAST_NAME_SUCCESS: {
            const { lastName } = payload
            return {
                ...state,
                account_info: {
                    ...state.account_info,
                    last_name: lastName,
                },
            }
        }
        case ACCOUNT.COMPLETE_IDP_ACIVATION: {
            return produce(state, (draft) => {
                draft.showIdpActivation = false
            })
        }
        default: {
            return state
        }
    }
}
