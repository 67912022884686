import { Button, Col, Form, Modal, Row, Select } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg'
import {
    closeStreamTypeModal,
    openStreamFormModal,
    openScheduledStreamTypeModal,
} from '../../../../redux/actions/live-streams/live-streams-actions'
import { getLiveStreamsTypeModal } from '../../../../redux/selectors'

const { Option } = Select

const StreamTypeModal = () => {
    const dispatch = useDispatch()
    const { open, submitting } = useSelector(getLiveStreamsTypeModal)
    const [form] = Form.useForm()

    const handleModalClose = useCallback(() => {
        dispatch(closeStreamTypeModal())
    }, [dispatch])

    const handleSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            if (values.status === 'onDemand' || values.status === 'completed') {
                dispatch(openStreamFormModal())
                dispatch(closeStreamTypeModal(values))
            } else {
                dispatch(openScheduledStreamTypeModal())
                dispatch(closeStreamTypeModal(values))
            }
        })
    }, [dispatch, form])

    const options = useMemo(
        () => (
            <>
                <Option key="onDemand" value="onDemand">
                    Upload Video
                </Option>
                <Option key="upcoming" value="upcoming">
                    Schedule Live Stream
                </Option>
            </>
        ),
        []
    )

    const footer = useMemo(() => {
        return (
            <Row justify="center">
                <Col flex="162px">
                    <Button
                        type="primary"
                        block
                        size="large"
                        disabled={submitting}
                        loading={submitting}
                        onClick={handleSubmit}
                    >
                        Next
                    </Button>
                </Col>
            </Row>
        )
    }, [handleSubmit, submitting])

    return (
        <Modal
            title="Stream Type"
            footer={footer}
            open={open}
            closeIcon={<CloseIcon />}
            onCancel={handleModalClose}
            width={600}
            className="tap-event-modal"
            destroyOnClose
        >
            <Form form={form} autoComplete="off" hideRequiredMark>
                <Form.Item
                    name="status"
                    label={null}
                    style={{ marginTop: 30 }}
                    rules={[{ required: true, message: 'Choose Stream Type' }]}
                >
                    <Select placeholder="Choose Stream Type">{options}</Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default StreamTypeModal
