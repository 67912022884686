import { SSO } from './sso-action-types'

export const getSsoProviders = ({ firstLoad }) => ({
    type: SSO.GET_PROVIDERS,
    payload: { firstLoad },
})

export const setUniversitySso = (data = {}, onSuccess = () => {}) => ({
    type: SSO.SET_UNIVERSITY,
    payload: data,
    meta: { onSuccess },
})

export const resetUniversitySso = (onSuccess) => ({
    type: SSO.RESET_UNIVERSITY,
    payload: {},
    meta: { onSuccess },
})

export const userToggle = (isAllowed, allowedType) => ({
    type: SSO.USER_TOGGLE,
    payload: { isAllowed, allowedType },
})

export const uploadMetadata = (file, onSuccess) => ({
    type: SSO.UPLOAD_METADATA,
    payload: { file },
    meta: { onSuccess },
})
