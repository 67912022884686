// authorize actions
export const ACCOUNT_LOGIN = 'ACCOUNT_LOGIN'
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS'
export const ACCOUNT_LOGIN_FAILED = 'ACCOUNT_LOGIN_FAILED'

// registration actions
export const ACCOUNT_REGISTRATION = 'ACCOUNT_REGISTRATION'
export const ACCOUNT_REGISTRATION_SUCCESS = 'ACCOUNT_REGISTRATION_SUCCESS'
export const ACCOUNT_REGISTRATION_FAILED = 'ACCOUNT_REGISTRATION_FAILED'

export const GROUP_ACCOUNT_REGISTRATION = 'GROUP_ACCOUNT_REGISTRATION'

// info about authorized user actions
export const ACCOUNT_INFO_REQUESTED = 'ACCOUNT_INFO_REQUESTED'
export const ACCOUNT_INFO_SUCCESS = 'ACCOUNT_INFO_SUCCESS'
export const ACCOUNT_INFO_FAILED = 'ACCOUNT_INFO_FAILED'

// logout actions
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT'
export const ACCOUNT_LOGOUT_SUCCESS = 'ACCOUNT_LOGOUT_SUCCESS'
export const ACCOUNT_LOGOUT_FAILED = 'ACCOUNT_LOGOUT_FAILED'

// set university group by super admin
export const SET_UNIVERSITY_GROUP = 'SET_UNIVERSITY_GROUP'

// change credentials modals actions
export const OPEN_CHANGE_EMAIL_MODAL = 'OPEN_CHANGE_EMAIL_MODAL'
export const OPEN_CHANGE_PASSWORD_MODAL = 'OPEN_CHANGE_PASSWORD_MODAL'
export const CLOSE_CHANGE_EMAIL_MODAL = 'CLOSE_CHANGE_EMAIL_MODAL'
export const CLOSE_CHANGE_PASSWORD_MODAL = 'CLOSE_CHANGE_PASSWORD_MODAL'

// tutorial ppopup actions
export const OPEN_TUTORIAL_POPUP = 'OPEN_TUTORIAL_POPUP'
export const CLOSE_TUTORIAL_POPUP = 'CLOSE_TUTORIAL_POPUP'

export const CLEAR_REGISTER_STEP = 'CLEAR_REGISTER_STEP'

export const OPEN_SUCCESS_REGISTRATION_MODAL = 'OPEN_SUCCESS_REGISTRATION_MODAL'
export const CLOSE_SUCCESS_REGISTRATION_MODAL = 'CLOSE_SUCCESS_REGISTRATION_MODAL'

export const FIND_USER_RECOVER_REQUESTED = 'FIND_USER_RECOVER_REQUESTED'
export const FIND_USER_RECOVER_SUCCESS = 'FIND_USER_RECOVER_SUCCESS'
export const FIND_USER_RECOVER_FAILED = 'FIND_USER_RECOVER_FAILED'

export const RECOVER_COMFIRM_REQUESTED = 'RECOVER_COMFIRM_REQUESTED'
export const RECOVER_COMFIRM_SUCCESS = 'RECOVER_COMFIRM_SUCCESS'
export const RECOVER_COMFIRM_FAILED = 'RECOVER_COMFIRM_FAILED'

export const OPEN_LETTER_SEND_POPUP = 'OPEN_LETTER_SEND_POPUP'
export const CLOSE_LETTER_SEND_POPUP = 'CLOSE_LETTER_SEND_POPUP'

export const OPEN_RECOVERY_SUCCESS_POPUP = 'OPEN_RECOVERY_SUCCESS_POPUP'
export const CLOSE_RECOVERY_SUCCESS_POPUP = 'CLOSE_ECOVERY_SUCCESS_POPUP'

export const UNIVERSITY_CHANGE_AVATAR = 'UNIVERSITY_CHANGE_AVATAR'
export const UNIVERSITY_CHANGE_AVATAR_SUCCESS = 'UNIVERSITY_CHANGE_AVATAR_SUCCESS'
export const UNIVERSITY_CHANGE_AVATAR_FAILED = 'UNIVERSITY_CHANGE_AVATAR_FAILED'

export const CHANGE_UNIVERSITY_NAME_REQUESTED = 'CHANGE_UNIVERSITY_NAME_REQUESTED'
export const CHANGE_UNIVERSITY_NAME_SUCCESS = 'CHANGE_UNIVERSITY_NAME_SUCCESS'
export const CHANGE_UNIVERSITY_NAME_FAILED = 'CHANGE_UNIVERSITY_NAME_FAILED'

export const CHANGE_DISPLAY_NAME_FOR_PARTNERS = 'CHANGE_DISPLAY_NAME_FOR_PARTNERS'
export const CHANGE_DISPLAY_NAME_FOR_PARTNERS_SUCCESS = 'CHANGE_DISPLAY_NAME_FOR_PARTNERS_SUCCESS'
export const CHANGE_DISPLAY_NAME_FOR_PARTNERS_FAILED = 'CHANGE_DISPLAY_NAME_FOR_PARTNERS_FAILED'

export const CHANGE_MANAGEMENT_TERM = 'CHANGE_MANAGEMENT_TERM'

export const SET_TEST_UNIVERSITY_TOGGLE_VALUE = 'SET_TEST_UNIVERSITY_TOGGLE_VALUE'
export const MARK_UNIVERSITY_AS_TEST_REQUEST = 'MARK_UNIVERSITY_AS_TEST_REQUEST'

// check admin invitation
export const CHECK_ADMIN_INVITATION = 'CHECK_ADMIN_INVITATION'
export const CHECK_GROUP_ADMIN_INVITATION = 'CHECK_GROUP_ADMIN_INVITATION'
export const CHECK_ADMIN_INVITATION_SUCCESS = 'CHECK_ADMIN_INVITATION_SUCCESS'
export const CHECK_ADMIN_INVITATION_FAILED = 'CHECK_ADMIN_INVITATION_FAILED'
