import API from '../../../api'
import { call, put } from 'redux-saga/effects'

import { GET_WIDGET_SUCCESS } from '../../actions/chat/widgetsActions'

export function* getWidget(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.chat.getWidget(payload.universityId, payload.filter)
        })

        if (response.success) {
            yield put({
                type: GET_WIDGET_SUCCESS,
                payload: {
                    widget: response.data.widget,
                },
            })
        }

        if (payload.func) payload.func(response.data.widget)
    } catch (error) {
        console.log(error)
    }
}
