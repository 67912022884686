import { uniqBy } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { getUsersMessagingSlice } from '../../../../../redux/selectors'

import UsersCheckboxList from './components/users-checkbox-list'
import UsersSearchField from './components/users-search-field'

const EMPTY_NAME_PLACEHOLDER = 'N/A'

const UserCheckboxModel = (user) => {
    return !user.roles
        ? {
              id: user.id,
              avatar: user.avatar,
              name: user.name || EMPTY_NAME_PLACEHOLDER,
              role: user.profileType ? user.profileType : user.role_name,
          }
        : {
              id: user.id,
              avatar: user.avatar,
              name: user.name || EMPTY_NAME_PLACEHOLDER,
              role: user.profileType ? user.profileType : user.roles[0].name,
          }
}

const parseUsers = (users) => users.map((user) => UserCheckboxModel(user))

const ScheduledUsersSelect = ({
    id,
    label,
    roles,
    rules,
    showRoles = false,
    form,
    selectedUsers = [],
    excludeSpeakers = false,
}) => {
    const [searchMode, setSearchMode] = useState(false)
    const userMessaging = useSelector(getUsersMessagingSlice)

    useEffect(() => {
        setSearchMode(false)
    }, [])

    const { isLoading, ambassadors } = userMessaging

    const items = useMemo(() => {
        return searchMode
            ? parseUsers(ambassadors)
            : uniqBy(({ id }) => id, [...parseUsers(selectedUsers), ...parseUsers(ambassadors)])
    }, [selectedUsers, ambassadors, searchMode])
    return (
        <>
            <UsersSearchField
                roles={roles}
                label={label}
                showRoles={showRoles}
                isLoading={isLoading}
                setSearchMode={setSearchMode}
            />
            <UsersCheckboxList id={id} items={items} rules={rules} form={form} excludeSpeakers={excludeSpeakers} />
        </>
    )
}

export default ScheduledUsersSelect
