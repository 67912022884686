import Modal from 'antd/es/modal'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import sanitizeHtml from 'sanitize-html' // dompurify
import child from '../../../../assets/img/child.png'
import pencil from '../../../../assets/img/pencil.png'
import TagsAddPopup from '../TagsAddPopup'
import Sprite from './../../../../assets/img/symbol/sprite.svg'

import { getSaProfileTypes } from '../../../redux/actions/content/usersActions'
import store from '../../../redux/store'
import {
    AmbassadorChildren,
    AmbassadorCountry,
    AmbassadorCourseType,
    AmbassadorEmployeeJobRole,
    AmbassadorIndustry,
    AmbassadorInterests,
    AmbassadorJobLocation,
    AmbassadorJobRole,
    AmbassadorJobTitle,
    AmbassadorStaffType,
    AmbassadorStudentType,
    AmbassadorSubject,
    AmbassadorType,
    AmbassadorYearOfStudy,
    DownloadCareerReferenceButton,
} from './components'

const Button = ({ children, handleClick, classNames, disabled }) => {
    return (
        <button disabled={disabled} className={`input-control-button ${classNames}`} onClick={handleClick}>
            {children}
        </button>
    )
}

const editButtons = (type, props) => {
    const { toggleEditable, editable } = props
    const isEnough = props[`${type}Length`] >= 0

    return (
        <div className="input__buttons">
            {editable[type] ? (
                <>
                    <Button classNames="input__buttons__button" handleClick={toggleEditable(type, 'cancel')}>
                        Cancel
                    </Button>
                    <Button
                        classNames={`input__buttons__button ${!isEnough && 'input__buttons__button--disabled'}`}
                        disabled={!isEnough}
                        handleClick={toggleEditable(type, 'save')}
                    >
                        Save
                    </Button>
                </>
            ) : (
                <Button handleClick={toggleEditable(type, 'toggle')}>
                    <img className="pencil-icon" src={pencil} alt="" /> Edit{' '}
                    {type === 'description' ? 'bio' : 'message'}
                </Button>
            )}
        </div>
    )
}
const editableInput = (type, props) => {
    const { editable, html, handleInputChange, sanitize } = props
    const edit = editable[type]
    const length = props[`${type}Length`]
    const content = html[type]

    return (
        <div
            className={`input__wrapper
        ${edit && 'input__wrapper--active'}
        ${edit && length <= 20 && 'input__wrapper--warning'}`}
        >
            {type === 'message' && !edit && <div className="first-message__triangle" />}

            {!edit ? (
                content
            ) : (
                <textarea
                    name="span"
                    className="textarea-info"
                    value={html[type]} // innerHTML of the edition div
                    onChange={(event) => handleInputChange(event, type)} // handle innerHTML change
                    onBlur={() => sanitize(type)}
                />
            )}

            {edit && (
                <span
                    className={`input__length-indicator
            ${length <= 20 && 'input__length-indicator--warning'}`}
                >
                    {`${length}/${type === 'message' ? '280' : '500'}`}
                </span>
            )}
        </div>
    )
}

const childrenInfo = (children, university, props, editableProfile, userId) => {
    const { getSchoolCurriculum, prepareYearOfStuding, saveStudentInfo } = props
    const childrenArrLength = children.length
    const childrenInfoFields = children.map((item) => {
        return (
            <div
                key={item.id}
                className={
                    childrenArrLength == 3
                        ? 'profile-info-child-age-container-one-row'
                        : 'profile-info-child-age-container'
                }
            >
                <div>
                    <img className="child-icon chat-group-item" src={child} alt="Child" />
                    <span className="profile-info-child-age">{dayjs().diff(item.age, 'years')} years old</span>
                </div>
                <div key={item.id} className="profile-info-child-year-of-study-container">
                    <span className="profile-info-child-year-of-study">
                        {getSchoolCurriculum(item.studying_year)} {childrenArrLength == 3 ? <br /> : null}
                        {prepareYearOfStuding(item.studying_year)}
                    </span>
                </div>
            </div>
        )
    })
    return (
        <div>
            {editableProfile ? (
                <AmbassadorChildren
                    userId={userId}
                    saveStudentInfo={saveStudentInfo}
                    university={university}
                    childrenInfoFields={childrenInfoFields}
                    childrenArrLength={childrenArrLength}
                    childrenArr={children}
                />
            ) : (
                <>
                    <span className="profile-info-tags-name description-row__name">Children at&nbsp;{university}</span>
                    <div className="profile-info-child-container">{childrenInfoFields}</div>
                </>
            )}
        </div>
    )
}
class AmbassadorInfoPopup extends Component {
    constructor() {
        super()
        this.inputRef = React.createRef()
        this.state = {
            userId: null,
            html: {
                description: '',
                message: '',
                subject: '',
            },
            editable: {
                description: false,
                message: false,
                subject: false,
            },
            showInfo: true,
            descriptionLength: 0,
            messageLength: 0,
            addTagsModalOpened: false,
            studentStaffType: null,
            closablePopup: true,
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.sanitize = this.sanitize.bind(this)
        this.toggleEditable = this.toggleEditable.bind(this)
        this.openModalTags = this.openModalTags.bind(this)
        this.handleCloseTagsPopup = this.handleCloseTagsPopup.bind(this)
        this.getSchoolCurriculum = this.getSchoolCurriculum.bind(this)
        this.prepareYearOfStuding = this.prepareYearOfStuding.bind(this)
        this.changeStudentStaffType = this.changeStudentStaffType.bind(this)
        this.saveStudentInfo = this.saveStudentInfo.bind(this)
        this.handleClosePopup = this.handleClosePopup.bind(this)
    }

    componentDidMount() {
        const user = this.getUser()
        const { user_tags: userTags, id, description, introduction } = user
        const subject = userTags.subject ? userTags.subject[0].name : ''

        this.setState((state) => ({
            ...state,
            userId: id,
            html: { ...state.html, description: description, message: introduction, subject },
            descriptionLength: description ? 500 - description.length : 0,
            messageLength: introduction ? 280 - introduction.length : 0,
        }))
        store.dispatch(getSaProfileTypes())
    }

    static getDerivedStateFromProps(props, state) {
        const user = Array.isArray(props.user)
            ? props.user.find((obj) => {
                  return obj.id === props.userId
              })
            : props.user
        if (user && user.id !== state.userId) {
            return {
                ...state,
                userId: user.id,
                html: { ...state.html, description: user.description, message: user.introduction },
                descriptionLength: user.description ? 500 - user.description.length : 0,
                messageLength: user.introduction ? 280 - user.introduction.length : 0,
            }
        }

        return null
    }

    sanitizeConf = {
        allowedTags: [],
        textFilter: function (text) {
            return text.replace(/&amp;/g, '&')
        },
    }

    sanitize(type) {
        this.setState((state) => ({
            ...state,
            html: {
                ...state.html,
                [type]: sanitizeHtml(state.html[type], this.sanitizeConf),
            },
        }))
    }

    changeStudentStaffType(type) {
        this.setState({
            studentStaffType: type,
        })
    }

    saveStudentInfo() {
        const user = this.getUser()
        const {
            student: typeOfStudent,
            industry,
            job_role: jobRole,
            job_title: jobTitle,
            courses_types: coursesTypes,
            subject,
            staff,
            profile,
            year_of_study: yearOfStuding,
        } = user.user_tags

        const { changeAmbassadorInfo } = this.props
        const { additionalData, id, children } = user

        const {
            company_country_id: companyCountryId,
            company_country_name: companyCountryName,
            company_region: companyRegion,
            working_since: workingSince,
        } = additionalData

        this.setState({ closablePopup: false })

        if (profile[0].key === 'student' && typeOfStudent && subject && coursesTypes) {
            changeAmbassadorInfo({
                userId: id,
                data: {
                    profileTypeId: profile[0].id,
                    studentTypeId: typeOfStudent[0].id,
                    subjectId: subject[0].id,
                    courseTypeId: coursesTypes[0].id,
                    studyingYearId: yearOfStuding ? yearOfStuding[0].id : null,
                },
            })
            this.setState({ closablePopup: true })
        } else if (profile[0].key === 'staff' && staff) {
            if (staff[0].key === '_staff_Academic' && subject) {
                changeAmbassadorInfo({
                    userId: id,
                    data: {
                        profileTypeId: profile[0].id,
                        staffTypeId: staff[0].id,
                        subjectId: subject[0].id,
                    },
                })
                this.setState({ closablePopup: true })
            } else if (staff[0].key !== '_staff_Academic' && jobTitle) {
                changeAmbassadorInfo({
                    userId: id,
                    data: {
                        profileTypeId: profile[0].id,
                        staffTypeId: staff[0].id,
                        jobTitle: jobTitle[0].name,
                    },
                })
                this.setState({ closablePopup: true })
            }
        } else if (profile[0].key === 'alumni' && jobRole && industry) {
            changeAmbassadorInfo({
                userId: id,
                data: {
                    profileTypeId: profile[0].id,
                    industryId: industry[0].id,
                    jobRole: jobRole[0].name,
                },
            })
            this.setState({ closablePopup: true })
        } else if (profile[0].key === 'employee' && companyCountryName && companyRegion && jobRole && workingSince) {
            changeAmbassadorInfo({
                userId: id,
                data: {
                    profileTypeId: profile[0].id,
                    companyRegion,
                    companyCountryId,
                    workingSince,
                    jobRole: jobRole[0].name,
                },
            })
            this.setState({ closablePopup: true })
        } else if (profile[0].key === 'parent' && children && children.length > 0) {
            const childrenRequestData = children.map((child) => {
                return {
                    age: child.age,
                    studyingYearId: child.studying_year,
                }
            })

            changeAmbassadorInfo({
                userId: id,
                data: {
                    profileTypeId: profile[0].id,
                    children: childrenRequestData,
                },
            })
            this.setState({ closablePopup: true })
        } else if (profile[0].key === 'school_student') {
            changeAmbassadorInfo({
                userId: id,
                data: {
                    profileTypeId: profile[0].id,
                },
            })
            this.setState({ closablePopup: true })
        }
    }

    toggleEditable(type, action) {
        return () => {
            const { handleChangeIntroduction, handleChangeDescription, handleChangeSubject } = this.props
            const callback =
                type === 'description'
                    ? handleChangeDescription
                    : type === 'subject'
                    ? handleChangeSubject
                    : handleChangeIntroduction
            const user = this.getUser()
            if (action === 'save') {
                callback(user.id, this.state.html[type])
                return this.setState((state) => ({
                    ...state,
                    editable: {
                        ...state.editable,
                        [type]: !state.editable[type],
                    },
                }))
            }

            if (action === 'cancel') {
                return this.setState((state) => ({
                    ...state,
                    editable: {
                        ...state.editable,
                        [type]: !state.editable[type],
                    },
                    html: {
                        ...state.html,
                        [type]:
                            type === 'description'
                                ? user[type]
                                : type === 'subject'
                                ? user.user_tags.subject[0].name
                                : user.introduction,
                    },
                }))
            }

            this.setState((state) => ({
                ...state,
                editable: {
                    ...state.editable,
                    [type]: !state.editable[type],
                },
            }))
        }
    }

    handleInputChange({ target }, type) {
        let input = target.value

        if (type === 'description' && input.length > 500) {
            input = input.split(' ').slice(0, -1).join(' ')
        }

        if (type === 'message' && input.length > 280) {
            input = input.split(' ').slice(0, -1).join(' ')
        }

        const availableSymbols = (type === 'description' ? 500 : 280) - input.length

        this.setState((state) => ({
            ...state,
            html: {
                ...state.html,
                [type]: input,
            },
            [`${type}Length`]: availableSymbols >= 0 ? availableSymbols : -1,
        }))
    }

    openModalTags() {
        this.setState({
            addTagsModalOpened: true,
        })
    }

    handleCloseTagsPopup() {
        this.setState({
            addTagsModalOpened: false,
        })
    }

    showInfo = (show = true) => {
        this.setState({
            showInfo: show,
        })
    }

    getUser() {
        const { user, userId } = this.props
        return Array.isArray(user)
            ? user.find((obj) => {
                  return obj.id === userId
              })
            : user
    }

    getSchoolCurriculum(year) {
        switch (year) {
            case 100: // Toddlers
            case 101: // Nursery
            case 102: // Reception
                return 'Early Years'

            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                return 'Primary School'

            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
                return 'Secondary School'

            default:
                return 'Upper Senior School'
        }
    }

    prepareYearOfStuding(yearOfStudying) {
        if (!yearOfStudying) {
            return ''
        }

        switch (yearOfStudying) {
            case 100:
                return '( Toddlers )'

            case 101:
                return '( Nursery )'

            case 102:
                return '( Reception )'

            default:
                return `( Year ${yearOfStudying} )`
        }
    }

    handleClosePopup(handleCloseUserPopup) {
        this.state.closablePopup && handleCloseUserPopup()
    }

    render() {
        const { userAvatar, university, handleCloseUserPopup, isReporting, editableProfile } = this.props
        const { showInfo, editable, addTagsModalOpened, studentStaffType } = this.state
        const props = { ...this.state, ...this }
        const user = this.getUser()

        const globalTags = user.global_tags ? user.global_tags : user.globalTags

        const {
            industry,
            job_role: jobRole,
            job_title: jobTitle,
            courses_types: coursesTypes,
            subject,
            countries,
            staff,
            interests,
            profile,
            year_of_study: yearOfStuding,
            student: typeOfStudent,
        } = user.user_tags

        const {
            id,
            description,
            region,
            children,
            additionalData,
            full_name: fullName,
            name,
            last_name: lastName,
        } = user
        const isEmployeeProfile = profile && profile[0].key === 'employee'
        const isParentProfile = profile && profile[0].key === 'parent'
        const isStudentProfile = profile && profile[0].key === 'student'
        const isStaffProfile = profile && profile[0].key === 'staff'
        const isAlumniProfile = profile && profile[0].key === 'alumni'
        const isAcademicStaff =
            (staff && staff[0].key === '_staff_Academic') || (studentStaffType && studentStaffType === 4295)
        return (
            <div className="profile-info-popup">
                <button
                    type="button"
                    className="close-modal closer-button"
                    onClick={() => this.handleClosePopup(handleCloseUserPopup)}
                >
                    <svg className="icon icon-close">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#close'}></use>
                    </svg>
                </button>
                <div className="d-flex flex-row">
                    <div className="d-flex flex-column justify-content-center popup-header">
                        <div style={{ display: 'flex' }}>
                            <div>
                                <div className="table-item-img-container profile-avatar">{userAvatar(user)}</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {fullName ? (
                                    <h2 className="modal-title sa-detail-title">{fullName}</h2>
                                ) : (
                                    <h2 className="modal-title sa-detail-title">
                                        {name} {lastName}
                                    </h2>
                                )}
                                {countries && (
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <div className="profile-info-tags-name">From</div>
                                        {editableProfile ? (
                                            <AmbassadorCountry
                                                region={region}
                                                countries={countries}
                                                userId={id}
                                                editableProfile
                                            />
                                        ) : (
                                            <div className="profile-info-tags-items">
                                                {region ? region + ', ' : ''}
                                                {countries.map((tag) => tag.name)}
                                            </div>
                                        )}
                                    </div>
                                )}
                                {profile && (
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <div className="profile-info-tags-name">Type</div>
                                        {editableProfile ? (
                                            <AmbassadorType
                                                profile={profile}
                                                userId={id}
                                                editableProfile
                                                changeStudentStaffType={this.changeStudentStaffType}
                                                saveStudentInfo={this.saveStudentInfo}
                                            />
                                        ) : (
                                            <div className="profile-info-tags-items">
                                                {profile &&
                                                    profile.map((tag) =>
                                                        tag.key === 'school_student'
                                                            ? 'School/College student'
                                                            : ` ${tag.name.charAt(0).toUpperCase() + tag.name.slice(1)}`
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div style={{ margin: '0 3rem 0 3rem' }}>
                            {isStudentProfile && (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <div className="profile-info-tags-name">Student type</div>
                                        {editableProfile ? (
                                            <AmbassadorStudentType
                                                studentType={typeOfStudent}
                                                userId={id}
                                                saveStudentInfo={this.saveStudentInfo}
                                            />
                                        ) : (
                                            <div className="profile-info-tags-items">
                                                {typeOfStudent && typeOfStudent[0].name}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        {editableProfile ? (
                                            <>
                                                <div className="profile-info-tags-name">Year of study</div>
                                                <AmbassadorYearOfStudy
                                                    yearOfStuding={yearOfStuding}
                                                    userId={id}
                                                    saveStudentInfo={this.saveStudentInfo}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {yearOfStuding && (
                                                    <>
                                                        <div className="profile-info-tags-name">Year of study</div>
                                                        <div className="profile-info-tags-items">
                                                            {yearOfStuding && yearOfStuding[0].name}
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <div className="profile-info-tags-name">Subject</div>
                                        {editableProfile ? (
                                            <AmbassadorSubject
                                                subject={subject}
                                                userId={id}
                                                saveStudentInfo={this.saveStudentInfo}
                                            />
                                        ) : (
                                            <div className="profile-info-tags-items">
                                                {subject &&
                                                    subject[0].name.charAt(0).toUpperCase() + subject[0].name.slice(1)}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <div className="profile-info-tags-name">Course type</div>
                                        {editableProfile ? (
                                            <AmbassadorCourseType
                                                coursesTypes={coursesTypes}
                                                userId={id}
                                                saveStudentInfo={this.saveStudentInfo}
                                            />
                                        ) : (
                                            <div className="profile-info-tags-items">
                                                {coursesTypes &&
                                                    coursesTypes[0].name.charAt(0).toUpperCase() +
                                                        coursesTypes[0].name.slice(1)}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {isStaffProfile && (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <div className="profile-info-tags-name">Staff member type</div>
                                        {editableProfile ? (
                                            <AmbassadorStaffType
                                                changeStudentStaffType={this.changeStudentStaffType}
                                                staff={staff}
                                                userId={id}
                                                saveStudentInfo={this.saveStudentInfo}
                                            />
                                        ) : (
                                            <div className="profile-info-tags-items">
                                                {staff &&
                                                    staff[0].name.charAt(0).toUpperCase() + staff[0].name.slice(1)}
                                            </div>
                                        )}
                                    </div>
                                    {!isAcademicStaff ? (
                                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                            <div className="profile-info-tags-name">Job title</div>
                                            {editableProfile ? (
                                                <AmbassadorJobTitle
                                                    jobTitle={jobTitle}
                                                    userId={id}
                                                    saveStudentInfo={this.saveStudentInfo}
                                                />
                                            ) : (
                                                <div className="profile-info-tags-items">
                                                    {jobTitle &&
                                                        jobTitle[0].name.charAt(0).toUpperCase() +
                                                            jobTitle[0].name.slice(1)}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                            <div className="profile-info-tags-name">Subject</div>
                                            {editableProfile ? (
                                                <AmbassadorSubject
                                                    subject={subject}
                                                    userId={id}
                                                    saveStudentInfo={this.saveStudentInfo}
                                                />
                                            ) : (
                                                <div className="profile-info-tags-items">
                                                    {subject &&
                                                        subject[0].name.charAt(0).toUpperCase() +
                                                            subject[0].name.slice(1)}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                            {isAlumniProfile && (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <div className="profile-info-tags-name">Industry</div>
                                        {editableProfile ? (
                                            <AmbassadorIndustry
                                                industry={industry}
                                                userId={id}
                                                saveStudentInfo={this.saveStudentInfo}
                                            />
                                        ) : (
                                            <div className="profile-info-tags-items">
                                                {industry &&
                                                    industry[0].name.charAt(0).toUpperCase() +
                                                        industry[0].name.slice(1)}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <div className="profile-info-tags-name">Job role</div>
                                        {editableProfile ? (
                                            <AmbassadorJobRole
                                                jobRole={jobRole}
                                                userId={id}
                                                saveStudentInfo={this.saveStudentInfo}
                                            />
                                        ) : (
                                            <div className="profile-info-tags-items">
                                                {jobRole &&
                                                    jobRole[0].name.charAt(0).toUpperCase() + jobRole[0].name.slice(1)}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {isEmployeeProfile ? (
                    <div className="employee-profile-info-container">
                        {/* <div className="employee-profile-info-row">
                                  <div className="profile-info-tags-name">Company</div>
                                  <p>{additionalData && additionalData.company_name ? additionalData.company_name : ""}</p>
                              </div> */}
                        <div className="employee-profile-info-row">
                            <div className="profile-info-tags-name">Job location</div>
                            {editableProfile ? (
                                <AmbassadorJobLocation
                                    region={additionalData?.company_region}
                                    countries={additionalData?.company_country_name}
                                    userId={id}
                                    saveStudentInfo={this.saveStudentInfo}
                                    editableProfile
                                />
                            ) : (
                                <p>
                                    {additionalData && additionalData.company_region
                                        ? additionalData.company_region + ', '
                                        : ''}
                                    {additionalData && additionalData.company_country_name
                                        ? additionalData.company_country_name
                                        : ''}
                                </p>
                            )}
                        </div>
                        <div className="employee-profile-info-row">
                            <div className="profile-info-tags-name">Job Role</div>
                            <div>
                                {editableProfile ? (
                                    <AmbassadorEmployeeJobRole
                                        jobRole={jobRole}
                                        userId={id}
                                        saveStudentInfo={this.saveStudentInfo}
                                        additionalData={additionalData}
                                    />
                                ) : (
                                    <>
                                        <p>{jobRole && jobRole.map(({ name }, index) => `${name}`)}</p>
                                        <p className="post-no-caption">
                                            (since{' '}
                                            {additionalData &&
                                                additionalData.working_since &&
                                                dayjs(additionalData.working_since).format('MMM YYYY')}
                                            )
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ) : null}
                <div style={{ margin: '2rem 1.4rem 0.5rem' }}>
                    <DownloadCareerReferenceButton userId={user.id} />
                </div>
                <div className="profile-info-wrap">
                    <div className="btn-wrap confirm-ban-buttons">
                        <div className="global-tags-block">
                            <div className="tags-title">
                                <span className="">Tags</span>
                                <div className="add-tags-link">
                                    <span onClick={this.openModalTags}>Open Tag Manager</span>
                                </div>
                            </div>
                            <div className="search-term-wrapper">
                                {globalTags && globalTags.length > 0 ? (
                                    globalTags.map((tag, index) => {
                                        return (
                                            <span key={index} className="search-term">
                                                {tag.name}
                                            </span>
                                        )
                                    })
                                ) : (
                                    <div className="no-info-found">No tags added yet.</div>
                                )}
                            </div>
                            <Modal
                                open={addTagsModalOpened}
                                centered={true}
                                footer={null}
                                closable={false}
                                maskClosable={true}
                                onCancel={this.handleCloseTagsPopup}
                                className="global-tags-modal"
                                zIndex={11_000}
                            >
                                <TagsAddPopup
                                    userId={id}
                                    currentList={globalTags}
                                    handleCloseTagsPopup={this.handleCloseTagsPopup}
                                    isReporting={isReporting}
                                />
                            </Modal>
                        </div>
                    </div>

                    <div className="tabs">
                        <span onClick={this.showInfo} className={'tab-name' + (showInfo ? ' active' : '')}>
                            Profile Information
                        </span>
                        <span
                            onClick={() => this.showInfo(false)}
                            className={'tab-name' + (!showInfo ? ' active' : '')}
                        >
                            First Message
                        </span>
                    </div>
                    <div className="card-info-block">
                        {showInfo ? (
                            <div>
                                {!description && !interests && !interests && (
                                    <div className="no-info-found description-row">No profile information found.</div>
                                )}
                                {description && (
                                    <div className="description-row">
                                        <span className="profile-info-tags-name description-row__name">Bio </span>
                                        {editableInput('description', props)}
                                        {editButtons('description', props)}
                                        {isParentProfile ? (
                                            <>{childrenInfo(children, university, props, editableProfile, id)}</>
                                        ) : null}
                                    </div>
                                )}
                                {editableProfile && !isParentProfile ? (
                                    <AmbassadorInterests interests={interests} userId={id} />
                                ) : (
                                    <>
                                        {interests && (
                                            <div>
                                                <p className="profile-info-tags-name interests-societies-row">
                                                    Interests and hobbies
                                                </p>
                                                <p className="profile-info-tags-row">
                                                    {interests &&
                                                        interests.map(({ name }) => {
                                                            return (
                                                                <span
                                                                    key={name.replace(/\s/gi, '')}
                                                                    className="sa-info-tag"
                                                                >
                                                                    {name}
                                                                </span>
                                                            )
                                                        })}
                                                </p>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <article className="first-message-wrapper">
                                {fullName ? (
                                    <h4 className="first-message__title">{fullName}&apos;s first message</h4>
                                ) : (
                                    <h4 className="first-message__title">
                                        {name} {lastName}&apos;s first message
                                    </h4>
                                )}
                                <div className="first-message-content-wrapper">
                                    <div className="table-item-img-container profile-avatar--message-section">
                                        {userAvatar(user)}
                                    </div>
                                    <div
                                        className={`first-message__message-wrapper ${
                                            editable.message && 'first-message__message-wrapper--active'
                                        }`}
                                    >
                                        {editableInput('message', props)}
                                        {editButtons('message', props)}
                                    </div>
                                </div>
                            </article>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default AmbassadorInfoPopup
