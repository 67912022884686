import React, { PureComponent } from 'react'

import SelectAmbassadors from './SelectAmbassadors'

const getAvailableAmbassadors = (ambassadors) => {
    const result = new Map()
    if (ambassadors) {
        ambassadors.map((item) => {
            result.set(item.id.toString(), item)
        })
    }

    return result
}

const areAllAmbassadorsSelected = (ambassadors, selectedUsersMap) => {
    if (!ambassadors.length || !selectedUsersMap.size || ambassadors.length > selectedUsersMap.size) {
        return false
    }

    for (const ambassador of ambassadors) {
        if (!selectedUsersMap.has(ambassador.id.toString())) {
            return false
        }
    }

    return true
}

class SelectAmbassadorsForm extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            isEveryoneSelected: false,
            selectedUsers: new Map(),
            assignedUsers: props.initialAssignedUsers,
            isDataFiltered: false,
        }

        this.handleSearchUpdated = this.handleSearchUpdated.bind(this)
        this.calculateSelectedUsers = this.calculateSelectedUsers.bind(this)
        this.handleUserSelectionUpdated = this.handleUserSelectionUpdated.bind(this)
        this.handleSelectAll = this.handleSelectAll.bind(this)
    }

    handleSearchUpdated(_, value) {
        const filter = value.trim()
        const isDataFiltered = filter !== ''

        this.setState({
            isDataFiltered: isDataFiltered,
        })

        this.props.onSearchUpdated(filter)
    }

    calculateSelectedUsers() {
        let selectedUsers

        if (this.state.isDataFiltered) {
            selectedUsers = this.state.selectedUsers
        } else {
            selectedUsers = new Map()
            const availableAmbassadors = getAvailableAmbassadors(this.props.ambassadors)

            for (const user in this.state.assignedUsers) {
                if (
                    this.state.assignedUsers[user] &&
                    this.state.assignedUsers[user].selected &&
                    availableAmbassadors.get(user)
                ) {
                    selectedUsers.set(user.toString(), this.state.assignedUsers[user])
                }
            }
        }

        const isEveryoneSelected = areAllAmbassadorsSelected(this.props.ambassadors, selectedUsers)

        if (
            this.state.selectedUsers.size !== selectedUsers.size ||
            this.state.isEveryoneSelected !== isEveryoneSelected
        ) {
            this.setState({
                selectedUsers: selectedUsers,
                isEveryoneSelected: isEveryoneSelected,
            })

            this.props.onUserSelectionUpdated(this.state.assignedUsers, selectedUsers.size, false)
        }

        return selectedUsers
    }

    handleUserSelectionUpdated(userId, userName) {
        const isUserSelected = this.state.assignedUsers[userId] && this.state.assignedUsers[userId].selected
        const userInfo = {
            selected: !isUserSelected,
            name: userName,
        }

        const updatedSelectedUsers = new Map(this.state.selectedUsers)
        if (this.state.selectedUsers.has(userId)) {
            updatedSelectedUsers.delete(userId)
        } else {
            updatedSelectedUsers.set(userId, userInfo)
        }

        const updatedAssignedUsers = Object.assign({}, this.state.assignedUsers, { [userId]: userInfo })

        this.setState({
            assignedUsers: updatedAssignedUsers,
            selectedUsers: updatedSelectedUsers,
            isEveryoneSelected: areAllAmbassadorsSelected(this.props.ambassadors, updatedSelectedUsers),
        })

        this.props.onUserSelectionUpdated(updatedAssignedUsers, updatedSelectedUsers.size, true)
    }

    handleSelectAll() {
        const isSelectAction = !this.state.isEveryoneSelected
        const updatedAssignedUsers = Object.assign({}, this.state.assignedUsers)
        const updatedSelectedUsers = new Map(this.state.selectedUsers)

        const availableAmbassadors = getAvailableAmbassadors(this.props.ambassadors)
        availableAmbassadors.forEach((userData, userId) => {
            updatedAssignedUsers[userId] = { selected: isSelectAction, name: userData.name }

            if (isSelectAction) {
                if (!updatedSelectedUsers.has(userId.toString())) {
                    updatedSelectedUsers.set(userId.toString(), updatedAssignedUsers[userId])
                }
            } else {
                if (updatedSelectedUsers.has(userId.toString())) {
                    updatedSelectedUsers.delete(userId.toString())
                }
            }
        })

        this.setState({
            assignedUsers: updatedAssignedUsers,
            selectedUsers: updatedSelectedUsers,
            isEveryoneSelected: isSelectAction,
        })

        this.props.onUserSelectionUpdated(updatedAssignedUsers, updatedSelectedUsers.size, true)
    }

    render() {
        const selectedUsers = this.calculateSelectedUsers()

        return (
            <SelectAmbassadors
                areAmbassadorsLoading={this.props.areAmbassadorsLoading}
                ambassadors={this.props.ambassadors}
                onUserSelectionUpdated={this.handleUserSelectionUpdated}
                assignedUsers={this.state.assignedUsers}
                selectedUsers={selectedUsers}
                onSearchUpdated={this.handleSearchUpdated}
                onSelectAll={this.handleSelectAll}
                isEveryoneSelected={this.state.isEveryoneSelected}
                hideTitle={this.props.hideTitle}
            />
        )
    }
}

export default SelectAmbassadorsForm
