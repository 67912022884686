import { Spin } from 'antd'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

class MandatoryData extends PureComponent {
    render() {
        const { data, isMandatoryDataLoading } = this.props

        if (isMandatoryDataLoading) {
            return <Spin spinning={true} className="loader-in-popup"></Spin>
        }

        return (
            <div className="optional-data-block scroll-8">
                {data && data.mandatoryData && data.mandatoryData.length > 0 ? (
                    data.mandatoryData.map((item, index) => {
                        return (
                            <div key={index} className="optional-data-item">
                                <p className="optional-data-name">{item.question}</p>
                                <span className="optional-data-value">{item.answer}</span>
                            </div>
                        )
                    })
                ) : (
                    <span className="no-prospect-info-data">No additional data</span>
                )}
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        data: state.prospects.mandatoryData,
        isMandatoryDataLoading: state.prospects.isMandatoryDataLoading,
    }
})(MandatoryData)
