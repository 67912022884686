import store, { startAppListening } from '../redux/store';
var Permissions = /** @class */ (function () {
    // eslint-disable-next-line no-useless-constructor
    function Permissions() {
        var _this = this;
        var _a;
        if (!store) {
            throw new Error('Account store is not initialized');
        }
        var account = store.getState().account;
        this.permissions = ((_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.permissions) || [];
        startAppListening({
            predicate: function (_, currentState, originalState) {
                var _a, _b;
                return (((_a = currentState.account.account_info) === null || _a === void 0 ? void 0 : _a.university.id) !=
                    ((_b = originalState.account.account_info) === null || _b === void 0 ? void 0 : _b.university.id));
            },
            effect: function (_, api) {
                var _a;
                var account = api.getState().account;
                _this.permissions = ((_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.permissions) || [];
            },
        });
    }
    Object.defineProperty(Permissions, "instance", {
        get: function () {
            if (!this._instance) {
                this._instance = new this();
            }
            return this._instance;
        },
        enumerable: false,
        configurable: true
    });
    Permissions.can = function (permission, isDefaultAllowed) {
        if (isDefaultAllowed === void 0) { isDefaultAllowed = true; }
        if (!this.instance.permissions) {
            return false;
        }
        var can = isDefaultAllowed;
        var foundPermission = this.instance.permissions.find(function (p) { return p.key === permission; });
        if (foundPermission) {
            can = foundPermission.is_enabled;
        }
        return can;
    };
    return Permissions;
}());
export { Permissions };
