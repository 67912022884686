import React from 'react'
import { Col, Typography, Row } from 'antd'

import { MarkenitgConsentTypes } from './components'

const { Title, Text } = Typography

const MarketingConsent = ({ accountInfo }) => {
    const { university } = accountInfo

    return (
        <Row style={{ maxWidth: '80%' }}>
            <Title level={5} className="settings-name">
                2. Marketing consent
            </Title>
            <Col style={{ marginBottom: '4rem', fontSize: '1.6rem' }}>
                <Row>
                    <Text style={{ fontSize: '1.6rem', marginBottom: '2rem' }}>
                        {`Users can express their consent to receive marketing material from you. You can use the standard
                        text (by default) or provide your own text for the marketing consent. Standard text is 'I agree
                        to receive email marketing communications from ${university.name}'`}
                    </Text>
                </Row>
                <Row>
                    <MarkenitgConsentTypes university={university} />
                </Row>
            </Col>
        </Row>
    )
}

export default MarketingConsent
