import HttpService from '../../system/services/HttpService'

export default {
    createTag: (name, universityId) => {
        const data = { name }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/globalTags', data)
    },
    createUserTag: (id, globalTagsIds) => {
        return HttpService.post('/dashboard/users/' + id + '/globalTags', { globalTagsIds: globalTagsIds })
    },
    createPostTag: (id, globalTagsIds) => {
        return HttpService.post('/dashboard/posts/globalTags/' + id, { globalTagsIds: globalTagsIds })
    },
    createFaqTag: (id, globalTagsIds) => {
        return HttpService.post('/dashboard/faqs/globalTags/' + id, { globalTagsIds: globalTagsIds })
    },
    getAllTags: (currentGroupUniversityIds, limit, offset, searchName, excludeIds) => {
        const data = {
            universityIds: currentGroupUniversityIds,
            limit: limit,
            offset: offset,
            search: {
                name: searchName,
            },
            excludeIds: excludeIds,
        }
        if (currentGroupUniversityIds) {
            data.universityIds = currentGroupUniversityIds
        }

        return HttpService.get('/dashboard/globalTags', data)
    },
    getPopularTags: (limit, offset, orderBy, universityIds) => {
        return HttpService.get('/dashboard/globalTags', {
            limit: limit,
            offset: offset,
            orderBy: orderBy,
            universityIds,
        })
    },
    getUserTags: (user_id) => {
        return HttpService.get('/dashboard/users/globalTags/' + user_id)
    },
    getPostTags: (post_id) => {
        return HttpService.get('/dashboard/posts/globalTags/' + post_id)
    },
    getFaqTags: (post_id) => {
        return HttpService.get('/dashboard/faqs/globalTags/' + post_id)
    },
    removeUserTag: (id, globalTagsIds) => {
        return HttpService.delete('/dashboard/users/' + id + '/globalTags', { globalTagsIds: globalTagsIds })
    },
    removePostTag: (id, globalTagsIds) => {
        return HttpService.delete('/dashboard/posts/globalTags/' + id, { globalTagsIds: globalTagsIds })
    },
    removeFaqTag: (id, globalTagsIds) => {
        return HttpService.delete('/dashboard/faqs/globalTags/' + id, { globalTagsIds: globalTagsIds })
    },
}
