import GlobalTagModel from './GlobalTagModel'
import PermissionModel from './PermissionModel'
import UserTagModel from './UserTagModel'

const getPermissions = (items) => {
    const permissions = []
    for (const item in items) {
        permissions[item] = PermissionModel(items[item])
    }
    return permissions
}

const getUserTags = (items) => {
    const tags = []
    for (const item in items) {
        if (items[item][0]) {
            tags[item] = UserTagModel(items[item][0])
        }
    }
    return tags
}

const getGlobalTags = (items) => {
    const globalTags = []
    for (const item in items) {
        globalTags[item] = GlobalTagModel(items[item])
    }
    return globalTags
}

const getRole = (role) => {
    return role.key === 'univercity-student'
        ? 'Ambassador'
        : role.key === 'univercity-admin'
        ? 'University Admin'
        : role.name
}

const UserModel = (data) => {
    const response = {
        id: null,
        name: '',
        email: '',
        last_name: '',
        full_name: '',
        role_key: null,
        role_name: '',
        profileType: null,
        banned: false,
        original: null,
        sizes: {},
        introduction: false,
        description: false,
        last_activity: new Date(),
        time_activity: 0,
        created_at: new Date(),
        middleRate: null,
        timeBlockColum: {},
        region: null,
        avatar: null, // Avatar Model
        permissions: [], // Permissions Model
        partner_groups_permissions: [],
        user_tags: null, // Tag Model
        globalTags: null, // Global Tag Model
        authLink: null,
    }

    if (data) {
        if (data.id) response.id = data.id
        if (data.name) response.name = data.name
        if (data.email) response.email = data.email
        if (data.last_name) response.last_name = data.last_name
        if (data.full_name) response.full_name = data.full_name
        if (data.profileType) response.profileType = data.profileType
        if (data.role_key) response.role_key = data.role_key
        if (data.roles) response.role_key = data.roles && data.roles[0] ? data.roles[0].key : null
        if (data.roles) response.role_name = data.roles && data.roles[0] ? getRole(data.roles[0]) : ''
        if (data.banned) response.banned = data.banned
        if (data.original) response.original = data.original
        if (data.sizes) response.sizes = data.sizes
        if (data.introduction) response.introduction = data.introduction
        if (data.description) response.description = data.description
        if (data.last_activity) response.last_activity = data.last_activity
        if (data.time_activity) response.time_activity = data.time_activity
        if (data.created_at) response.created_at = data.created_at
        if (data.middleRate) response.middleRate = data.middleRate
        if (data.timeBlockColum) response.timeBlockColum = data.timeBlockColum
        if (data.region) response.region = data.region
        if (data.avatar) response.avatar = data.avatar
        if (data.permissions) response.permissions = getPermissions(data.permissions)
        if (data.partner_groups_permissions) response.partner_groups_permissions = data.partner_groups_permissions
        if (data.user_tags) response.user_tags = getUserTags(data.user_tags)
        if (data.globalTags) response.globalTags = getGlobalTags(data.globalTags)
        if (data.authLink) response.authLink = data.authLink
    }

    return response
}

export default UserModel
