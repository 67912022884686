import { LIVE_EVENTS } from './live-event-action-types'

export const openEventFormModal = (payload = {}) => ({
    type: LIVE_EVENTS.OPEN_FORM_MODAL,
    payload,
})

export const closeEventFormModal = () => ({
    type: LIVE_EVENTS.CLOSE_FORM_MODAL,
})

export const openAmbassadorsModal = (payload) => ({
    type: LIVE_EVENTS.OPEN_AMBASSADORS_MODAL,
    payload,
})

export const closeAmbassadorsModal = () => ({
    type: LIVE_EVENTS.CLOSE_AMBASSADORS_MODAL,
})

export const createLiveEvent = (payload) => ({
    type: LIVE_EVENTS.CREATE,
    payload,
})

export const updateLiveEvent = (payload) => ({
    type: LIVE_EVENTS.UPDATE,
    payload,
})

export const fetchLiveEvents = (payload) => ({
    type: LIVE_EVENTS.FETCH,
    payload,
})

export const setActiveLiveEvent = (payload) => ({
    type: LIVE_EVENTS.SET_ACTIVE,
    payload,
})

export const setActiveLiveEventSidebarTab = (payload) => ({
    type: LIVE_EVENTS.SET_ACTIVE_SIDEBAR_TAB,
    payload,
})

export const resetActiveLiveEvent = () => ({
    type: LIVE_EVENTS.RESET_ACTIVE,
})

export const addToCalendar = (payload) => ({
    type: LIVE_EVENTS.ADD_TO_CALENDAR,
    payload,
})

export const editAmbassadors = (payload) => ({
    type: LIVE_EVENTS.EDIT_AMBASSADORS,
    payload,
})

export const openSpeakersModal = (payload) => ({
    type: LIVE_EVENTS.OPEN_SPEAKERS_MODAL,
    payload,
})

export const closeSpeakersModal = () => ({
    type: LIVE_EVENTS.CLOSE_SPEAKERS_MODAL,
})

export const updateSpeakers = (payload) => ({
    type: LIVE_EVENTS.UPDATE_SPEAKERS,
    payload,
})

export const startLiveEventConfirm = ({ id, name, history }) => ({
    type: LIVE_EVENTS.START_EVENT_CONFIRM,
    payload: { id, name, history },
})

export const startLiveEvent = ({ id }) => ({
    type: LIVE_EVENTS.START_EVENT,
    payload: { id },
})

export const stopLiveEventConfirm = ({ id }) => ({
    type: LIVE_EVENTS.STOP_EVENT_CONFIRM,
    payload: { id },
})

export const stopLiveEvent = ({ id }) => ({
    type: LIVE_EVENTS.STOP_EVENT,
    payload: { id },
})

export const cancelLiveEventConfirm = ({ id, name, history }) => ({
    type: LIVE_EVENTS.CANCEL_EVENT_CONFIRM,
    payload: { id, name, history },
})

export const cancelLiveEvent = ({ id }) => ({
    type: LIVE_EVENTS.CANCEL_EVENT,
    payload: { id },
})

export const openJoinModal = (payload = {}) => ({
    type: LIVE_EVENTS.OPEN_JOIN_MODAL,
    payload,
})

export const closeJoinModal = () => ({
    type: LIVE_EVENTS.CLOSE_JOIN_MODAL,
})

export const joinStream = () => ({
    type: LIVE_EVENTS.JOIN_STREAM,
})

export const leaveStream = () => ({
    type: LIVE_EVENTS.LEAVE_STREAM,
})

export const stopStream = ({ id }) => ({
    type: LIVE_EVENTS.STOP_STREAM,
    payload: {
        id,
    },
})

export const closeChat = ({ id, history }) => ({
    type: LIVE_EVENTS.CLOSE_CHAT,
    payload: {
        id,
        history,
    },
})

export const updateEventStatus = ({ id, isChatActive, status, isStreamActive }) => ({
    type: LIVE_EVENTS.UPDATE_EVENT_STATUS,
    payload: {
        id,
        isChatActive,
        status,
        isStreamActive,
    },
})

export const updateProspectList = ({ id, newProspect, totalProspects }) => ({
    type: LIVE_EVENTS.UPDATE_PROSPECT_LIST,
    payload: {
        id,
        newProspect,
        totalProspects,
    },
})
