import React, { PureComponent } from 'react'
import store from '../../../redux/store'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { EXPORT_DATA } from '../../../redux/actions/settings/exportData'
import Modal from 'antd/es/modal'

class ExportData extends PureComponent {
    constructor() {
        super()
        this.state = {
            hasStarted: false,
            hasFinished: false,
            error: false,
            showModal: false,
        }
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
    }

    exportData = async () => {
        const { hasStarted } = this.state
        const onFinish = (success) => {
            this.setState({
                hasStarted: false,
                hasFinished: true,
                error: !success,
            })
        }

        if (!hasStarted) {
            this.setState({
                hasStarted: true,
            })

            await store.dispatch({
                type: EXPORT_DATA,
                onFinish,
            })
        }
    }

    openModal = () => {
        this.setState({
            showModal: true,
        })
    }

    closeModal = () => {
        this.setState({
            hasStarted: false,
            hasFinished: false,
            error: false,
            showModal: false,
        })
    }

    render() {
        const { hasStarted, hasFinished, error, showModal } = this.state

        return (
            <div className="export-data-page">
                <section className="content settings">
                    <h3 className="settings-title">Export data</h3>
                    <div className="settings-grid has-tutorial">
                        <div className="auto-deletion-container">
                            <div className="settings-intro">
                                <p>
                                    As an admin of TAP account you are able to export your data. This process will take
                                    some time.
                                </p>
                            </div>
                            <hr className="settings-hr"></hr>
                            <div className="text-bottom">
                                <p>Your export will include the following:</p>
                                <div className="export-list">
                                    <p>- content</p>
                                    <p>- FAQs with answers</p>
                                    <p>- chat transcripts.</p>
                                </div>

                                <p>Previously deleted data won&apos;t be included in this export.</p>
                            </div>
                            <button type="submit" className="el-btn" onClick={this.openModal}>
                                Export your data
                            </button>
                        </div>
                    </div>
                </section>
                <Modal
                    open={showModal}
                    centered={true}
                    width={hasFinished && !error ? 350 : 460}
                    bodyStyle={{ padding: 0 }}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.closeModal}
                    className="export-data-modal"
                >
                    {hasFinished && !error ? (
                        <div className="general-popup export-success-popup">
                            <div className="modal-body">
                                Export in progress you will receive an email when done within an hour
                            </div>
                            <button type="submit" className="el-btn confirm-delete-button" onClick={this.closeModal}>
                                Ok
                            </button>
                        </div>
                    ) : (
                        <div className="general-popup">
                            <h2 className="modal-title">Export data</h2>
                            <div className="modal-body">You are going to export your data. Are you sure?</div>
                            <div className="btn-wrap confirm-ban-buttons">
                                <button
                                    type="submit"
                                    className="el-btn confirm-delete-button"
                                    onClick={this.exportData}
                                    disabled={hasStarted}
                                >
                                    Confirm
                                </button>
                                <button type="button" className="el-btn cancel-delete-button" onClick={this.closeModal}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        )
    }
}

export default ExportData
