import React from 'react';
import { ChartCard } from './ChartCard';
import ChartHeader from '../../components/ChartHeader';
import { Spin } from 'antd';
import { colorsUniversity, dataLabelFormat } from '../helpers';
import { useAppSelector } from 'src/app/hooks/store';
import { selectActiveUnivercityId } from 'src/app/redux/selectors/account-selectors';
import DonutChart from '../../components/DonutChart';
import { useGetProspectsQuery } from 'src/app/redux/slices/reporting/idp-conversion-reporting/query';
export var NationalityBreakdownChart = function (_a) {
    var filter = _a.filter;
    var universityId = useAppSelector(selectActiveUnivercityId);
    var _b = useGetProspectsQuery({
        filter: filter,
        universityIds: universityId ? [universityId] : null,
    }), data = _b.data, isFetching = _b.isFetching;
    return (React.createElement(ChartCard, null,
        React.createElement(ChartHeader, { title: "Nationality breakdown" }),
        isFetching ? (React.createElement(Spin, { size: "large", className: "flex justify-center mt-20" })) : (React.createElement(DonutChart, { colors: colorsUniversity, data: data ? data.nationalities.data : [], centerText: data ? data.nationalities.centerText : 'No data', height: 250, dataLabelFormat: dataLabelFormat }))));
};
