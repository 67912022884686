import React, { PureComponent } from 'react'

class RemovedAccountSystemMessage extends PureComponent {
    render() {
        const { message } = this.props

        return (
            <div className="deleted-user-system-message">
                <p>{message.content.text}</p>
            </div>
        )
    }
}

export default RemovedAccountSystemMessage
