import Modal from 'antd/es/modal'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import PlaceholderGroup from '../../../../../assets/img/placeholder-group.png'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'
import { ChatType } from '../../../../common/constants'
import JoinedAmbassadorMessage from '../../../../components/messages/JoinedAmbassadorMessage'
import ImageModal from '../../../../components/modals/ImageModal'
import { RestrictedModal } from '../../../../components/modals/RestrictedModal'
import VideoModal from '../../../../components/modals/VideoModal'
import ConfirmDeletePopup from '../../../../components/popups/ConfirmDeletePopup'
import { isAllowed } from '../../../../helpers/helpers'
import {
    CLOSE_ACTION_MODAL,
    NEW_MESSAGES_RECEIVED,
    OPEN_ACTION_MODAL,
    OPEN_SETTINGS_MESSAGING_POPUP,
} from '../../../../redux/actions/components/componentsActions'
import {
    CLEAR_MESSAGES,
    DELETE_CONTENT_MESSAGE,
    DIALOG_MESSAGES_INFO,
    OPEN_CONTENT_GROUP_POPUP,
    RELOAD_CONTENT_GROUP,
    SEND_MESSAGE,
    VIDEO_TRANSCODE,
} from '../../../../redux/actions/content/messagingActions'
import {
    ADD_DOWNLOAD_POINTS,
    CLOSE_CONFIRM_DELETE_POPUP,
    CLOSE_SHARE_POST_POPUP,
    OPEN_SHARE_POST_POPUP,
    POSTS_DELETE,
    changePostCaption,
    openDeleteModal,
    sharePostToRSS,
    togglePublishPost,
} from '../../../../redux/actions/content/postsActions'

import store from '../../../../redux/store'
import { pusherChild } from '../../../../system/services/PusherWrapper'

import { PostShareForm } from '../../dashboard/components/modals/forms/PostsShareForm'
import SendMessageForm from './forms/SendMessageForm'
import DeletedPostMessage from './messages/DeletedPostMessage'
import ImageMessage from './messages/ImageMessage'
import TextMessage from './messages/TextMessage'
import UploadMessage from './messages/UploadMessage'
import VideoMessage from './messages/VideoMessage'
import NewMessagesPopup from './popups/NewMessagesPopup'

import { ChatDescription } from 'src/app/components/chat-description'

class Messages extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            streams: {},
            isModalImageOpened: false,
            isModalVideoOpened: false,
            currentIndex: null,
            message: null,
            post: null,
            fileId: null,
            fileLocation: null,
            transcodeStatus: '',
            currentJobId: null,
            showRestrictedModal: false,
        }

        this.fireChannelActions = this.fireChannelActions.bind(this)
        this.handleDownloadButtonClick = this.handleDownloadButtonClick.bind(this)
        this.videoTranscode = this.videoTranscode.bind(this)
        this.handleSendMessage = this.handleSendMessage.bind(this)
        this.handleOpenPopup = this.handleOpenPopup.bind(this)
        this.handleClosePopup = this.handleClosePopup.bind(this)
        this.handleOpenSharePopup = this.handleOpenSharePopup.bind(this)
        this.handleCloseSharePopup = this.handleCloseSharePopup.bind(this)
        this.handleSubmitSharePost = this.handleSubmitSharePost.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.resetFileSettings = this.resetFileSettings.bind(this)
        this.closeConfirmationPopup = this.closeConfirmationPopup.bind(this)
        this.renderDeleteMessagePopup = this.renderDeleteMessagePopup.bind(this)
        this.deleteChatMessage = this.deleteChatMessage.bind(this)
        this.onBodyScroll = this.onBodyScroll.bind(this)

        this.chatBodyRef = React.createRef(null)
    }

    componentDidMount() {
        if (this.props.activeContentGroup != -1) {
            store.dispatch({
                type: DIALOG_MESSAGES_INFO,
                payload: {
                    dialog_id: this.props.contentGroups.activeContentGroup,
                    scrollBottom: true,
                    type_chat: ChatType.CONTENT_GROUP,
                    withGlobalTags: true,
                },
            })
        }

        this.channel = pusherChild.subscribe('dashboard')

        this.fireChannelActions()
    }

    componentDidUpdate(prevProps, prevState) {
        const { account, contentGroups, dialogMessages } = this.props
        const { currentIndex } = this.state
        const element = this.chatBodyRef.current

        if (prevProps.account.currentGroupUniversityId !== account.currentGroupUniversityId) {
            this.channel.unbind()
            this.fireChannelActions()
            this.setState({ currentIndex: null })
        }
        // Scroll listener
        if (prevProps.activeContentGroup !== contentGroups.activeContentGroup && element) {
            element.addEventListener('scroll', this.onBodyScroll)
        }
        // Handle scroll actions
        if (element && dialogMessages.scroll && element.scrollTop !== element.scrollHeight) {
            if (!dialogMessages.isBlockedScroll && !currentIndex) {
                element.scrollTop = element.scrollHeight
            } else if (
                dialogMessages.previousScrollHeight &&
                element.scrollHeight > dialogMessages.previousScrollHeight &&
                prevProps.dialogMessages.previousScrollHeight !== dialogMessages.previousScrollHeight
            ) {
                element.scrollTop = element.scrollHeight - dialogMessages.previousScrollHeight
            }
        }
    }

    componentWillUnmount() {
        if (this.channel) {
            this.channel.unbind()
        }
        store.dispatch({
            type: CLEAR_MESSAGES,
        })
    }

    fireChannelActions() {
        const currentGroupUniversityId = this.props.account.currentGroupUniversityId // for group admins
        const universityId = currentGroupUniversityId || this.props.account.account_info.university_id

        this.channel.bind(
            'messages:new:' + universityId,
            function (data) {
                if (data.type && (data.type === 2 || data.type === 'content')) {
                    const element = this.chatBodyRef.current
                    // I think we don't need it document.querySelector('.chat-nav-msg-counter .hidden-block').remove()
                    store.dispatch({
                        type: RELOAD_CONTENT_GROUP,
                        payload: {
                            dialog_id: data.dialog_id,
                            type: 2,
                        },
                    })

                    if (
                        element &&
                        (element.scrollHeight - element.scrollTop === element.clientHeight || !element.scrollTop)
                    ) {
                        if (data.dialog_id == this.props.contentGroups.activeContentGroup) {
                            store.dispatch({
                                type: DIALOG_MESSAGES_INFO,
                                payload: {
                                    dialog_id: data.dialog_id,
                                    scrollBottom: true,
                                    type_chat: ChatType.CONTENT_GROUP,
                                    blockReloadContentGroup: true,
                                    withGlobalTags: true,
                                },
                            })
                        }
                    } else {
                        if (data.dialog_id == this.props.contentGroups.activeContentGroup) {
                            store.dispatch({
                                type: NEW_MESSAGES_RECEIVED,
                            })
                        }
                    }
                }
            }.bind(this)
        )

        if (this.props.account.account_info) {
            // no need for group admins
            this.channel.bind(
                'file:transcoding:' +
                    this.props.account.account_info.university_id +
                    ':' +
                    this.props.account.account_info.id,
                function (data) {
                    if (data && data.job_id === this.state.currentJobId) {
                        this.setState({
                            fileId: data.file_id || null,
                            fileLocation: data.fileLocation || null,
                            transcodeStatus: data.status,
                        })
                    }
                }.bind(this)
            )
        }
    }

    handleDownloadButtonClick(event) {
        store.dispatch({
            type: ADD_DOWNLOAD_POINTS,
            payload: {
                post_id: event.currentTarget.dataset.postId,
            },
        })
    }

    handleContentGroupClick() {
        store.dispatch({
            type: OPEN_CONTENT_GROUP_POPUP,
        })
    }

    handleOpenSharePopup(event) {
        if (isAllowed('content_sharing')) {
            const messageIndex = event.currentTarget.dataset.postIndex
            this.setState({
                post: this.props.dialogMessages.items[messageIndex].content.post,
            })

            store.dispatch({
                type: OPEN_SHARE_POST_POPUP,
                payload: {
                    post: this.props.dialogMessages.items[messageIndex].content.post,
                },
            })
        } else {
            this.setState({ showRestrictedModal: true })
        }
    }

    closeRestrictedModal = () => {
        this.setState({ showRestrictedModal: false })
    }

    handleCloseSharePopup() {
        store.dispatch({
            type: CLOSE_SHARE_POST_POPUP,
        })
    }

    handleSubmitSharePost(values) {
        this.props.sharePostToRSS(this.state.post.id, values.caption)
    }

    handleSendMessage(values) {
        if (document.querySelector('#content-messaging-textarea').value.replace(/^\s+|\s+$/g, '') == '' && !values.file)
            return

        const dialogId = this.props.activeContentGroup

        if (!values.file || !values.file[0]) {
            store.dispatch({
                type: SEND_MESSAGE,
                payload: {
                    type: 'text',
                    type_chat: ChatType.CONTENT_GROUP,
                    text: document.querySelector('#content-messaging-textarea').value,
                    dialog_id: dialogId,
                },
            })
        } else if (values.file[0].type.includes('image/')) {
            const caption = document.querySelector('#content-messaging-textarea').value
            const reader = new window.FileReader()
            reader.readAsDataURL(values.file[0])
            reader.addEventListener('load', function () {
                store.dispatch({
                    type: SEND_MESSAGE,
                    payload: {
                        type: 'image',
                        type_chat: ChatType.CONTENT_GROUP,
                        base64: reader.result.slice(reader.result.indexOf(',') + 1),
                        base64_original: reader.result.slice(reader.result.indexOf(',') + 1),
                        caption: caption,
                        dialog_id: dialogId,
                    },
                })
            })
        } else if (values.file[0].type.includes('video/') || this.state.fileType.includes('video/')) {
            const caption = document.querySelector('#content-messaging-textarea').value
            const payload = {
                type: 'video',
                file_id: this.state.fileId,
                type_chat: ChatType.CONTENT_GROUP,
                caption: caption,
                dialog_id: dialogId,
                currentJobId: this.state.currentJobId,
            }

            store.dispatch({
                type: SEND_MESSAGE,
                payload: {
                    ...payload,

                    callback: async () => {
                        this.setState({
                            fileId: null,
                            fileLocation: null,
                            transcodeStatus: '',
                            currentJobId: null,
                        })
                    },
                },
            })
        }

        document.querySelector('#send-message-form').reset()
    }

    videoTranscode(file, fileType) {
        const dialogId = this.props.contentGroups.items.find((item) => item.id == this.props.activeContentGroup).id
        const formData = new window.FormData()
        formData.append('file', file)

        // console.log(file.type, file.size, 149766636)
        store.dispatch({
            type: VIDEO_TRANSCODE,
            payload: {
                formData: formData,
                dialog_id: dialogId,
                callback: async (jobId, fileData) => {
                    this.setState({
                        fileType: fileType,
                        currentJobId: jobId,
                        fileId: fileData ? fileData.id : null,
                        fileLocation: fileData ? fileData.fileLocation : null,
                        transcodeStatus: '',
                        uploadPopupOpened: true,
                    })
                },
            },
        })
    }

    handleOpenSettingsPopup() {
        store.dispatch({
            type: OPEN_SETTINGS_MESSAGING_POPUP,
        })
    }

    handleConfirmDelete(post) {
        const postsType = 'all'
        const typeId = 0
        const onConfirm = () => {
            store.dispatch({
                type: DIALOG_MESSAGES_INFO,
                payload: {
                    dialog_id: this.props.contentGroups.activeContentGroup,
                    scrollBottom: true,
                    blockReloadContentGroup: true,
                    withGlobalTags: true,
                },
            })
            this.handleClosePopup()
        }

        store.dispatch({
            type: POSTS_DELETE,
            payload: {
                post_id: this.props.confirmDeleteModal.postId,
                postsInfo: true,
                postsType: postsType,
                typeId: typeId,
                onConfirm: onConfirm,
            },
        })
    }

    handleCloseConfirmDeletePopup() {
        store.dispatch({
            type: CLOSE_CONFIRM_DELETE_POPUP,
        })
    }

    handleOpenConfirmDeletePopup(index, nextItem) {
        this.props.openModal(index)
    }

    handleOpenPopup(index, message) {
        if (message.content && message.content.post && message.content.post.type === 'video') {
            this.setState({ isModalVideoOpened: true, message: message, currentIndex: index })
        } else {
            this.setState({ isModalImageOpened: true, message: message, currentIndex: index })
        }
    }

    handleClosePopup() {
        this.handleCloseSharePopup()
        this.setState({ isModalImageOpened: false, isModalVideoOpened: false })
    }

    resetFileSettings() {
        this.setState({
            fileId: null,
            fileLocation: null,
            transcodeStatus: '',
            currentJobId: null,
        })
    }

    renderDeleteMessagePopup(message) {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Delete this message?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button
                                type="submit"
                                className="el-btn confirm-delete-button"
                                onClick={() => this.deleteChatMessage(message)}
                            >
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={() => this.closeConfirmationPopup()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    deleteChatMessage(chatMessage) {
        store.dispatch({
            type: DELETE_CONTENT_MESSAGE,
            payload: {
                dialogId: chatMessage.dialog_id,
                messageId: chatMessage.id,
            },
        })
    }

    closeConfirmationPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    onBodyScroll() {
        const { dialogMessages, contentGroups } = this.props
        const element = this.chatBodyRef.current
        if (
            element &&
            element.scrollTop < 50 &&
            !dialogMessages.isLoading &&
            dialogMessages.items &&
            dialogMessages.items.length > 0 &&
            dialogMessages.items.length < dialogMessages.total
        ) {
            store.dispatch({
                type: DIALOG_MESSAGES_INFO,
                payload: {
                    dialog_id: contentGroups.activeContentGroup,
                    onScroll: true,
                    type_chat: ChatType.CONTENT_GROUP,
                    blockReloadContentGroup: true,
                    withGlobalTags: true,
                    isBlockedScroll: true,
                    previousScrollHeight: element.scrollHeight,
                },
            })
        }
    }

    render() {
        const {
            fileId,
            fileLocation,
            transcodeStatus,
            streams,
            isModalImageOpened,
            currentIndex,
            isModalVideoOpened,
            showRestrictedModal,
        } = this.state

        const {
            activeContentGroup,
            changePostCaption,
            togglePublishPost,
            account,
            contentGroups,
            dialogMessages,
            sharePostModal,
            confirmDeleteModal,
        } = this.props
        // eslint-disable-next-line unicorn/consistent-destructuring
        const post = this.state.message && this.state.message.content ? this.state.message.content.post : null

        if (activeContentGroup == -1 || contentGroups.isFirstRequest) {
            return (
                <div className="chat-block">
                    <svg className="icon icon-chat">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#chat'}></use>
                    </svg>
                    <h2 className="modal-title">Select a content group</h2>
                </div>
            )
        }

        const isGroupAdmin = account.account_info && account.account_info.isGroupAdmin
        const contentGroup = contentGroups.items.find((item) => item.id == activeContentGroup)
        // const commonPath = getCommonRoutePath()

        const isIdpContentGroup = contentGroup.content_type || contentGroup.is_broadcast_channel

        const contentGroupTitle = contentGroup.contentGroupTitle
        const isLoading = dialogMessages.isLoading && !dialogMessages.isFirstRequest

        let messagesView = []

        messagesView = dialogMessages.items.map((message, index) => {
            if (message.type == 'text') {
                return (
                    <TextMessage
                        key={message.id}
                        message={message}
                        renderDeleteMessagePopup={this.renderDeleteMessagePopup}
                    />
                )
            } else if (message.type === 'joined') {
                return <JoinedAmbassadorMessage key={message.id} message={message} />
            } else if (message.type == 'upload') {
                return <UploadMessage key={message.id} message={message} streams={streams} />
            } else if (message.type == 'post' && message.content.post == null) {
                return <DeletedPostMessage key={message.id} message={message} />
            } else if (message.type == 'post' && message.content.post.type == 'image') {
                return (
                    <ImageMessage
                        key={message.id}
                        message={message}
                        handleOpenSharePostModal={this.handleOpenSharePopup}
                        handleDownloadButtonClick={this.handleDownloadButtonClick}
                        handleMessageClick={this.handleOpenPopup}
                        index={index}
                        isIdpContentGroup={isIdpContentGroup}
                    />
                )
            } else if (message.type == 'post' && message.content.post.type == 'video') {
                return (
                    <VideoMessage
                        key={message.id}
                        message={message}
                        handleOpenSharePostModal={this.handleOpenSharePopup}
                        handleDownloadButtonClick={this.handleDownloadButtonClick}
                        handleMessageClick={this.handleOpenPopup}
                        index={index}
                        isIdpContentGroup={isIdpContentGroup}
                    />
                )
            } else if (message.type == 'unarchived') {
                return (
                    <div key={message.id} className="msg-reopened">
                        Group has been reopened
                    </div>
                )
            }
        })
        let load = ''

        if (isLoading) {
            load = (
                <div className="load-dialog">
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#000000" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            )
        }
        let messageId = null
        // eslint-disable-next-line unicorn/consistent-destructuring
        if (this.state.message && this.state.message.id) {
            // eslint-disable-next-line unicorn/consistent-destructuring
            messageId = this.state.message.id
        }
        return (
            <div className="chat-block">
                <div className="chat-head">
                    <div className="chat-head-left">
                        <div className="chat-name-img-block">
                            <img
                                className="chat-name-img"
                                src={
                                    contentGroup.avatar
                                        ? contentGroup.avatar.sizes['70x70'] || contentGroup.avatar.original
                                        : PlaceholderGroup
                                }
                            />
                        </div>
                        <div className="chat-name-block">
                            <h3 className="chat-name">{contentGroup.name}</h3>
                            <p className="chat-members">{contentGroupTitle}</p>
                        </div>
                    </div>
                    <div className="chat-head-right">
                        {/* <NavLink
                            exact
                            to={{
                                pathname: commonPath + `/content-group/${contentGroup.id}`,
                                state: {
                                    contentGroupName: contentGroup.name,
                                },
                            }}
                            className="chat-btn"
                        >
                            Content library
                        </NavLink> */}
                        {!isIdpContentGroup && (
                            <button type="button" className="chat-btn" onClick={this.handleOpenSettingsPopup}>
                                Edit group
                            </button>
                        )}

                        <p className="chat-status">{contentGroup.closed ? 'Group inactive' : 'Group active'}</p>
                    </div>
                </div>

                {isIdpContentGroup && <ChatDescription text={contentGroup.description} />}

                <div className="chat-body scroll-8" ref={this.chatBodyRef}>
                    <div className="chat-body-list">{messagesView}</div>

                    <NewMessagesPopup />
                </div>
                {contentGroup.closed ? (
                    <div className="flex h-21 w-full items-center justify-center bg-secondary-gray">
                        <p>This group has been closed</p>
                    </div>
                ) : (
                    <div className={'chat-footer ' + (isLoading ? 'loaded' : '')}>
                        <SendMessageForm
                            onSubmit={this.handleSendMessage}
                            resetFileSettings={this.resetFileSettings}
                            videoTranscode={this.videoTranscode}
                            fileId={fileId}
                            fileLocation={fileLocation}
                            transcodeStatus={transcodeStatus}
                            isGroupAdmin={isGroupAdmin}
                            isIdpContentGroup={isIdpContentGroup}
                        />

                        {load}
                    </div>
                )}

                <Modal
                    open={sharePostModal.isOpened}
                    centered={true}
                    footer={null}
                    closable
                    maskClosable
                    zIndex={9999}
                    onCancel={this.handleCloseSharePopup}
                    className="preview-post-gallery"
                >
                    <PostShareForm post={this.state.post} onClose={this.handleCloseSharePopup} />
                </Modal>
                <Modal
                    open={isModalImageOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleClosePopup}
                    hasOutsideClickClose={false}
                    hasEscapeClose={true}
                    className="react-post-gallery"
                >
                    <ImageModal
                        key={currentIndex}
                        togglePublishPost={togglePublishPost}
                        changePostCaption={changePostCaption}
                        handleClosePopup={this.handleClosePopup}
                        handleShareButtonClick={this.handleOpenSharePopup}
                        handleDownloadButtonClick={this.handleDownloadButtonClick}
                        handleDeleteClick={() => {
                            this.handleOpenConfirmDeletePopup(post.id, currentIndex)
                        }}
                        post={post}
                        messageId={messageId}
                        elementIndex={currentIndex}
                        isIdpContentGroup={isIdpContentGroup}
                    />
                </Modal>
                <Modal
                    open={isModalVideoOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleClosePopup}
                    className="react-post-gallery"
                >
                    <VideoModal
                        key={currentIndex}
                        togglePublishPost={togglePublishPost}
                        changePostCaption={changePostCaption}
                        handleClosePopup={this.handleClosePopup}
                        handleShareButtonClick={this.handleOpenSharePopup}
                        handleDownloadButtonClick={this.handleDownloadButtonClick}
                        handleDeleteClick={() => {
                            this.handleOpenConfirmDeletePopup(post.id, currentIndex)
                        }}
                        post={post}
                        messageId={messageId}
                        elementIndex={currentIndex}
                        isIdpContentGroup={isIdpContentGroup}
                    />
                </Modal>

                <Modal
                    open={confirmDeleteModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseConfirmDeletePopup}
                >
                    <ConfirmDeletePopup
                        handleConfirmDelete={this.handleConfirmDelete}
                        handleClosePopup={this.handleCloseConfirmDeletePopup}
                    />
                </Modal>
                {showRestrictedModal && <RestrictedModal closable={true} closeModal={this.closeRestrictedModal} />}
            </div>
        )
    }
}

export default connect(
    (state) => {
        return {
            contentGroups: state.contentGroups,
            dialogMessages: state.dialogMessages,
            account: state.account,
            sharePostModal: state.sharePostModal,
            confirmDeleteModal: state.confirmDeleteModal,
        }
    },
    { changePostCaption, togglePublishPost, sharePostToRSS, openModal: openDeleteModal }
)(Messages)
