import { produce } from 'immer'

import {
    SET_ACTIVE_CHAT,
    RESET_ACTIVE_CHAT,
    UPDATE_ACTIVE_CHAT,
    UPDATE_CHAT_FIRST_MESSAGE,
} from '../../../actions/chat/messagingActions'

import { CURRENT_CHAT } from '../../../store/initialState'

const initialState = CURRENT_CHAT

// This reducer is a bridge between dialogs and dialog messages
export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case SET_ACTIVE_CHAT: {
            return {
                ...state,
                data: payload.data,
                activeChat: payload.chat_id,
                chatsType: payload.chats_type,
                search: payload.search && payload.search.length > 0 ? payload.search : null,
            }
        }

        case UPDATE_ACTIVE_CHAT: {
            return produce(state, (draft) => {
                draft.data = { ...draft.data, ...payload.dialogData }
            })
        }
        case UPDATE_CHAT_FIRST_MESSAGE: {
            return produce(state, (draft) => {
                draft.data.first_message.content.text = payload.firstMessage
            })
        }

        case RESET_ACTIVE_CHAT: {
            return {
                ...initialState,
            }
        }

        default: {
            return state
        }
    }
}
