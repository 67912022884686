import dayjs from 'dayjs'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import view from '../../../../../../assets/img/svg/view.svg'
import viewHidden from '../../../../../../assets/img/svg/view_hidden.svg'
import { ChatType } from '../../../../../common/constants'
import BlockProspectPopover from '../../../../../components/BlockProspectPopover'
import BlockingProspectControl from '../../../../../components/BlockingProspectControl'
import { GET_PROSPECT_INFO } from '../../../../../redux/actions/user/userActions'
import store from '../../../../../redux/store'

import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { MessageContent } from 'src/app/components/message-content'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

class TextMessage extends PureComponent {
    createLinksInMessage(text) {
        return (text || '').replace(/(\s|^)(((https?:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
            var hyperlink = url
            if (!hyperlink.match('^https?://')) {
                hyperlink = 'https://' + hyperlink
            }
            return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>'
        })
    }

    handleOpenUserInfoPopup(userInfo) {
        if (userInfo.role_key === 'prospect') {
            store.dispatch({
                type: GET_PROSPECT_INFO,
                payload: {
                    userId: userInfo.id,
                },
            })
        }
    }

    render() {
        const {
            account,
            message,
            index,
            unwordDialog,
            search,
            searchBy,
            reply,
            renderDeleteMessagePopup,
            isChatReported,
        } = this.props
        const {
            user,
            content,
            id,
            parentMessage,
            isUserBlocked,
            type,
            hide_keywords: hideKeywords,
            dialog_id: dialogId,
            is_hidden: isHidden,
            created_at: messageCreatedAt,
        } = message

        const createdAt = dayjs(messageCreatedAt).calendar(null, {
            sameDay: 'H:mm',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday at ]H:mm',
            lastWeek: 'DD MMMM',
            sameElse: 'DD MMMM',
        })

        const textSearch = searchBy === 'message' && content.text.includes(search)
        const userSearch = searchBy === 'user' && user.name.includes(search)

        let parentTextSearch = false
        let parentUserSearch = false
        if (parentMessage) {
            parentTextSearch =
                searchBy === 'message' && parentMessage.content && parentMessage.content.text.includes(search)
            parentUserSearch = searchBy === 'user' && parentMessage.user && parentMessage.user.name.includes(search)
        }
        const { hasBadWord } = message

        const links = document.querySelectorAll('.message-hyperlink')
        links.length > 0 && links.forEach((link) => (link.style.textDecoration = 'underline'))

        return (
            <div id={id} className={user && user.id == account.account_info.id ? 'msg-item mod-my' : 'msg-item'}>
                <div className={'msg-avatar-block' + (user.role_key === 'prospect' ? ' msg-prospect-avatar' : '')}>
                    {user && user.avatar ? (
                        <img
                            className="msg-item-img"
                            src={user.avatar.sizes['70x70'] || user.avatar.original}
                            alt="img"
                            onClick={() => this.handleOpenUserInfoPopup(user)}
                        />
                    ) : (
                        <div className="msg-avatar-text" onClick={() => this.handleOpenUserInfoPopup(user)}>
                            {user ? user.name.charAt(0) : ''}
                        </div>
                    )}
                </div>
                <div
                    className={`msgs-block ${
                        isChatReported.reported &&
                        isChatReported.prospect &&
                        isChatReported.prospect.id === user.id &&
                        !isUserBlocked
                            ? 'blocking-control'
                            : ''
                    }`}
                >
                    <div
                        className={`msg-block-item ${
                            (isChatReported.reported &&
                                isChatReported.prospect &&
                                isChatReported.prospect.id === user.id) ||
                            isUserBlocked
                                ? 'blocked'
                                : ''
                        }`}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent:
                                    user && user.id == account.account_info.id ? 'flex-end' : 'space-between',
                            }}
                        >
                            <div className="chat-nav-name-container">
                                <p
                                    className={
                                        user && user.id == account.account_info.id
                                            ? 'hidden-block'
                                            : 'msg-name' + (userSearch ? ' search-word' : '')
                                    }
                                >
                                    {user && user.name ? user.name : 'Deleted User'}
                                    {user && user.registered_via_group ? ' (' + user.registered_via_group + ')' : ''}
                                </p>
                                {isUserBlocked ? (
                                    <BlockProspectPopover
                                        className="chat-blocked-button-chat"
                                        userId={message.user.id}
                                    />
                                ) : null}
                            </div>
                            <span className="msg-time">{createdAt}</span>
                        </div>
                        {message.parentMessage && (
                            <div className="msg-parent">
                                {account.account_info && account.account_info.id != message.parentMessage.user_id && (
                                    <p className={'msg-name' + (parentUserSearch ? ' search-word' : '')}>
                                        {parentMessage.user && parentMessage.user.name
                                            ? parentMessage.user.name
                                            : 'Deleted user'}
                                    </p>
                                )}
                                {parentMessage.content && parentMessage.content.text && (
                                    <p className={'msg-text' + (parentTextSearch ? ' search-word' : '')}>
                                        <MessageContent text={parentMessage.content.text} />
                                    </p>
                                )}
                            </div>
                        )}
                        <p className={'msg-text' + (textSearch ? ' search-word' : '')}>
                            <MessageContent text={content.text} />
                        </p>
                        <div className={`msg-controls ${!hasBadWord && 'msg-controls--to-left'}`}>
                            {!isUserBlocked ? (
                                <>
                                    {hasBadWord && (
                                        <div onClick={() => unwordDialog(id)} className="msg-controls__wrapper">
                                            {hideKeywords ? (
                                                <img
                                                    className="msg-controls__view-icon-hidden"
                                                    src={viewHidden}
                                                    alt=""
                                                />
                                            ) : (
                                                <img className="msg-controls__view-icon-visible" src={view} alt="" />
                                            )}
                                            <span
                                                className={`msg-controls__text ${
                                                    !hideKeywords && 'msg-controls__text--visible'
                                                }`}
                                            >
                                                {hideKeywords
                                                    ? 'Keyword is hidden from users'
                                                    : 'Keyword is visible to all users'}
                                            </span>
                                        </div>
                                    )}
                                    <div className="reply-and-delete-container">
                                        {account.account_info &&
                                            account.account_info.id != user.id &&
                                            index > 0 &&
                                            !isChatReported.reported && (
                                                <div className="reply-button" onClick={() => reply(message)}>
                                                    Reply
                                                </div>
                                            )}
                                        {type !== 'autotext' && !isChatReported.reported && (
                                            <div
                                                className="delete-button"
                                                onClick={() => renderDeleteMessagePopup(message)}
                                            >
                                                Delete
                                            </div>
                                        )}
                                        {account.account_info && account.account_info.id != user.id && index > 0 && (
                                            <BlockingProspectControl
                                                dialogId={dialogId}
                                                isBlocked={isUserBlocked}
                                                isHidden={isHidden}
                                                userId={user.id}
                                                chatType={ChatType.CHAT}
                                            />
                                        )}
                                    </div>
                                </>
                            ) : (
                                <div className="block-prospect-msg">message is hidden from the chat</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(TextMessage)
