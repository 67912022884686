import { Form, Input, Select } from 'antd';
import React from 'react';
import { Button } from 'src/app/components/base/Button';
import { channel, contentTypeOptions, journeyStep, topics } from './formData';
import { useIdpContentGroup } from './useIdpConentGroup';
import { DestinationSelect } from 'src/app/components/destination-select';
import IdpAmbassadorsList from './components/IdpAmbassadorsList';
import IdpAmbassadorsFilters from './components/IdpAmbassadorsFilters';
var TextArea = Input.TextArea;
export var IdpContentGroupForm = function () {
    var _a = useIdpContentGroup(), form = _a.form, ambassadors = _a.ambassadors, isAmbassadorsListLoading = _a.isAmbassadorsListLoading, isCreateGroupDisabled = _a.isCreateGroupDisabled, isBroadcast = _a.isBroadcast, onFinish = _a.onFinish, handleJurneyStepChange = _a.handleJurneyStepChange, handleTopicsOfInterestChange = _a.handleTopicsOfInterestChange, handleChannelChange = _a.handleChannelChange, handleDestinationChange = _a.handleDestinationChange, setFilters = _a.setFilters, handleAmbassadorsSelect = _a.handleAmbassadorsSelect;
    return (React.createElement("div", { className: "create-content-group" },
        React.createElement("h2", { className: "modal-title" }, isBroadcast ? 'Create broadcast channel' : 'Create content request'),
        React.createElement(Form, { name: "content-group-form", className: "content-group-form", layout: "vertical", requiredMark: false, form: form, onFinish: onFinish },
            !isBroadcast && (React.createElement(Form.Item, { name: "contentType", rules: [{ required: true, message: 'Select content type' }], label: "Content type" },
                React.createElement(Select, { placeholder: "Select content type", options: contentTypeOptions }))),
            React.createElement("div", { className: "flex flex-row items-center" },
                React.createElement(Form.Item, { name: "name", label: "Request", rules: [{ required: true, message: 'Add  group name' }], className: "w-full" },
                    React.createElement(Input, { maxLength: 100, placeholder: "Type content group name" })),
                React.createElement("img", { className: "ml-13 h-24 w-24 object-cover", src: isBroadcast
                        ? 'https://cdn.theaccessplatform.com/img/idp-broadcast.jpg'
                        : 'https://cdn.theaccessplatform.com/img/idp-logo.jpg' })),
            React.createElement(Form.Item, { name: "description", label: "Instruction" },
                React.createElement(TextArea, { className: "h-30 p-0", style: { resize: 'none' }, showCount: true, maxLength: 250, placeholder: "Type your message..." })),
            !isBroadcast && (React.createElement(React.Fragment, null,
                React.createElement(Form.Item, { name: "jurneyStep", rules: [{ required: true, message: 'Select at least one jurney step' }], label: "Journey step" },
                    React.createElement(Select, { placeholder: "Select jurney step", mode: "multiple", onChange: handleJurneyStepChange, options: journeyStep })),
                React.createElement(Form.Item, { name: "destination", rules: [{ required: true, message: 'Select at least one destination' }], label: "Destination (Country - City)" },
                    React.createElement(DestinationSelect, { placeholder: "Select destination", onChange: handleDestinationChange })),
                React.createElement(Form.Item, { name: "topicOfInterests", rules: [{ required: true, message: 'Select at least one topic' }], label: "Topics of Interest" },
                    React.createElement(Select, { placeholder: "Select topic", mode: "multiple", onChange: handleTopicsOfInterestChange, options: topics })),
                React.createElement(Form.Item, { name: "channel", rules: [{ required: true, message: 'Select at least one channel' }], label: "Channel" },
                    React.createElement(Select, { placeholder: "Select channel", mode: "multiple", onChange: handleChannelChange, options: channel })))),
            React.createElement(IdpAmbassadorsFilters, { onFilter: setFilters }),
            !isBroadcast && (React.createElement(Form.Item, { name: "tags", label: "Tags" },
                React.createElement(Select, { disabled: true, mode: "tags", suffixIcon: null }))),
            React.createElement(IdpAmbassadorsList, { items: ambassadors, isLoading: isAmbassadorsListLoading, onSelect: handleAmbassadorsSelect }),
            React.createElement("div", { className: "hidden-buttons-block mt-8" },
                React.createElement(Button, { type: "primary", htmlType: "submit", className: "el-btn create-group-btn h-auto", disabled: isCreateGroupDisabled }, "Create")))));
};
