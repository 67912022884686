import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
var EditPrivacyPolicyForm = function (_a) {
    var _b, _c;
    var handleSubmit = _a.handleSubmit;
    var form = Form.useForm()[0];
    var _d = useSelector(function (state) { return state.account; }), currentGroupUniversityId = _d.currentGroupUniversityId, accountInfo = _d.account_info;
    var _e = useState(''), privacyPolicy = _e[0], setPrivacyPolicy = _e[1];
    var prevGroupUniversityId = useRef(currentGroupUniversityId);
    useEffect(function () {
        if (prevGroupUniversityId !== currentGroupUniversityId) {
            prevGroupUniversityId.current = currentGroupUniversityId;
            form.setFieldValue('privacyPolicy', accountInfo.university ? accountInfo.university.privacy_policy : '');
        }
    }, [currentGroupUniversityId, accountInfo, form]);
    var onPrivacyChange = useCallback(function (e) {
        console.log(e.currentTarget.value);
        setPrivacyPolicy(e.currentTarget.value);
    }, []);
    return (React.createElement(Form, { form: form, layout: "vertical", onFinish: handleSubmit, initialValues: { privacyPolicy: (_b = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university) === null || _b === void 0 ? void 0 : _b.privacy_policy }, className: "university-form privacy-form" },
        React.createElement(Form.Item, { name: "privacyPolicy", rules: [
                {
                    required: true,
                    message: 'Please enter your privacy policy link',
                },
            ], className: "signin-input privacy-input" },
            React.createElement(Input, { placeholder: "Privacy policy URL", onChange: onPrivacyChange })),
        React.createElement(Button, { htmlType: "submit", className: "settings-btn privacy-save", disabled: privacyPolicy.length === 0 || privacyPolicy === ((_c = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university) === null || _c === void 0 ? void 0 : _c.privacy_policy) }, "Save")));
};
export default EditPrivacyPolicyForm;
