import API from '../../../api'
import { call, put, select } from 'redux-saga/effects'

import {
    GET_HOME_CHART_SUCCESS,
    GET_HOME_CHART_FAILED,
    GET_HOME_DATA_SUCCESS,
    GET_HOME_DATA_FAILED,
} from '../../actions/home/homeActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* getHomeChartData(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        let universityId = null
        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            universityId = currentGroupUniversityId || payload.university_id
        }
        const response = yield call(() => {
            return API.home.getChartData(payload.chartName, payload.filter, universityId)
        })

        if (response.success) {
            yield put({
                type: GET_HOME_CHART_SUCCESS,
                payload: {
                    items: response.data.mainChart.data ? response.data.mainChart.data : [],
                    currentChartOption: payload.chartName,
                    total: response.data.mainChart.total ? response.data.mainChart.total : 0,
                },
            })
        } else {
            yield put({
                type: GET_HOME_CHART_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_HOME_CHART_FAILED,
        })
    }
}

export function* getHomeData(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        let universityId = null
        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            universityId = currentGroupUniversityId || payload.university_id
        }
        const response = yield call(() => {
            return API.home.getData(payload.dateFilter, payload.postsLimit, payload.faqAnswersLimit, universityId)
        })

        if (response.success) {
            yield put({
                type: GET_HOME_DATA_SUCCESS,
                payload: {
                    conversationsActivity: response.data.conversationsActivity,
                    inactiveAmbassadors: response.data.inactiveAmbassadors,
                    recentPosts: response.data.recentPosts,
                    recentFaqAnswers: response.data.recentFaqAnswers,
                    wizardSetupData: response.data.wizardSetupData,
                },
            })
        } else {
            yield put({
                type: GET_HOME_DATA_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_HOME_DATA_FAILED,
        })
    }
}
