/* eslint-disable camelcase */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export default (function (user) {
    var id = user.id, name = user.name, lastName = user.last_name, avatar = user.avatar, last_activity = user.last_activity, permissions = user.permissions, _a = user.partnerGroups, partnerGroups = _a === void 0 ? [] : _a;
    var formatedPartnerGroupArr = permissions.partner_groups
        ? partnerGroups.map(function (group) {
            var permissionsArr = permissions.partner_groups.find(function (permission) { return permission.university_group_id === group.id; });
            return (permissionsArr || {
                university_group_id: group.id,
                is_enabled: false,
            });
        })
        : [];
    var partnerGroupObj = Object.assign.apply(Object, __spreadArray([{}], formatedPartnerGroupArr.map(function (item) {
        var _a;
        return (_a = {},
            _a["partner_group_tap_feed_permission_".concat(item.university_group_id)] = item,
            _a);
    }), false));
    var mainData = {
        id: id,
        name: name,
        lastName: lastName,
        avatar: avatar,
        last_activity: last_activity,
        fullName: "".concat(name, " ").concat(lastName),
        chat_permission: permissions.chat,
        content_permission: permissions.content,
        faq_permission: permissions.faq,
        university_tap_feed_permission: permissions.university_tap_page,
        idp_community_permission: permissions.idp_community || {},
        idp_live_app_permission: permissions.idp_conversion_chat || {},
        idp_what_uni_permission: permissions.idp_what_uni || {},
        userInfo: user,
    };
    var data = __assign(__assign({}, mainData), partnerGroupObj);
    return data;
});
