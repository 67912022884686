import { call, put } from 'redux-saga/effects'
import API from '../../../api'

import { CLOSE_ACTION_MODAL } from '../../actions/components/componentsActions'

export function* setManagementTerm(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.settings.setManagementTerm(payload)
        })

        if (response.success) {
            yield put({
                type: CLOSE_ACTION_MODAL,
            })
        }
    } catch (e) {
        console.log(e)
    }
}
