import React from 'react';
import { MessageContent } from 'src/app/components/message-content';
import { Avatar } from '../../base/Avatar';
import { Button } from '../../base/Button';
import { Icon } from '../../base/Icon';
export var ReplyMessage = function (_a) {
    var avatarSrc = _a.avatarSrc, avatarInitial = _a.avatarInitial, userName = _a.userName, messageText = _a.messageText, onCloseActiveMessage = _a.onCloseActiveMessage;
    return (React.createElement("div", { className: "box-border flex w-full items-center gap-3 bg-light-gray p-4" },
        React.createElement(Avatar, { size: 28, className: "min-w-7", src: avatarSrc }, avatarInitial),
        React.createElement("div", { className: "w-full" },
            React.createElement("p", { className: "!text-brand-orange" }, userName),
            React.createElement("p", { className: "line-clamp-1 break-all" },
                React.createElement(MessageContent, { text: messageText }))),
        React.createElement(Button, { onClick: onCloseActiveMessage, type: "link", "data-automation-id": "reply-close", icon: React.createElement(Icon, { icon: "close", className: "reply-close" }) })));
};
