import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'

const renderField = ({ input, label, placeholder, min, max, type, className, meta: { touched, error } }) => (
    <div className={className}>
        <input {...input} min={min} max={max} placeholder={placeholder} type={type} />
    </div>
)

class ConfirmEmailPopup extends PureComponent {
    render() {
        const { handleSubmit } = this.props

        return (
            <div className="verification" id="verification">
                <form action="#" method="POST" onSubmit={handleSubmit}>
                    <h3 className="modal-title">Verification</h3>
                    <p className="subtitle">Please enter your code</p>
                    <Field
                        name="verification_code"
                        component={renderField}
                        type="text"
                        min={6}
                        max={6}
                        className="wrap-input-number"
                    />
                    <p className="subtitle text-footer-modal">
                        A verification code has been sent to your email address. <br />
                        Please enter this here to continue. <br />
                        <br />
                        {/* <a href="#">Not received?</a> */}
                    </p>

                    <button type="submit" className="el-btn">
                        Send
                    </button>
                </form>
            </div>
        )
    }
}

export default reduxForm({
    form: 'confirm_email_edit',
})(ConfirmEmailPopup)
