import {
    OPEN_SHARE_POST_POPUP,
    CLOSE_SHARE_POST_POPUP,
    SHARE_POST_SUCCESS,
} from '../../../actions/content/postsActions'

import { SHARE_POST_MODAL } from '../../../store/initialState'

const initialState = SHARE_POST_MODAL

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case OPEN_SHARE_POST_POPUP: {
            let newState = {
                ...state,
                isOpened: true,
                post: payload.post,
            }

            return newState
        }

        case CLOSE_SHARE_POST_POPUP: {
            let newState = {
                ...state,
                isOpened: false,
                isShared: false,
                post: null,
            }

            return newState
        }

        case SHARE_POST_SUCCESS: {
            let newState = {
                ...state,
                isShared: true,
            }

            return newState
        }

        default: {
            return state
        }
    }
}
