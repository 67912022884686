import React from 'react'

import Header from '../../_layout/header'
import NavigationSetupWizard from '../../_layout/navSetupWizard'

import { SetupTitle } from '../components'
import { TapFeedLinkSection, TapFeedCredentialsSection, TapFeedStartConversationSection } from './components'
import LogOutButton from '../../../components/log-out-button/log-out-button'

import { useParticipantFirstMessageForm } from './use-participant-first-message-form'

const ParticipantFirstMessageScreen = () => {
    const { form, onSubmit, prevStep, initialValues, messageSentValidator, showErrorMessage } =
        useParticipantFirstMessageForm()
    return (
        <div>
            <Header submenuUnActive={true} />
            <div id="page-wrapper" className="page-wrapper">
                <NavigationSetupWizard />

                <section className="content settings no-submenu home-start-guide setup-wizard">
                    <LogOutButton step={3} />
                    <div className="start-guide-wizard-grid pls">
                        <div className="column pls">
                            <SetupTitle
                                blockTitle="Test it out: log in as a prospect"
                                title="The Ambassador Platform Account Setup Guide"
                            />
                            <TapFeedLinkSection />
                            <TapFeedCredentialsSection />
                            <TapFeedStartConversationSection
                                form={form}
                                initialValues={initialValues}
                                prevStep={prevStep}
                                onSubmit={onSubmit}
                                messageSentValidator={messageSentValidator}
                                showErrorMessage={showErrorMessage}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ParticipantFirstMessageScreen
