import Header from '../../_layout/header'
import NavigationSetupWizard from '../../_layout/navSetupWizard'
import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import { connect } from 'react-redux'
import Modal from 'antd/es/modal'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import ExplanationModal from './components/ExplanationModal'
import { changeTAPTitles, getSocialProofInfo, getTAPTitles } from '../../../redux/actions/settings/customTitlesActions'
import { getFilters, setFilters } from '../../../redux/actions/settings/filtersActions'
import { getCspDomains, setCspDomains } from '../../../redux/actions/settings/cspDomainsActions'
import { ReactComponent as SearchIcon } from '../../../../assets/svg/search-thin.svg'
import { ReactComponent as UserIcon } from '../../../../assets/img/svg/user.svg'
import ProfileExample from '../../../../assets/img/setup/profile.png'
import PostExample from '../../../../assets/img/setup/post.png'
import FaqExample from '../../../../assets/img/setup/faq.png'
import tipIcon from '../../../../assets/img/setup/tip.png'
import TAPFilter from '../../../components/TAPFilter'
import { ReactComponent as Close } from '../../../../assets/svg/icons/close.svg'
import DomainsSelector from './components/DomainsSelector'
import chevronDown from '../../../../assets/img/svg/chevron-down.svg'
import { ReactComponent as SettingsIcon } from '../../../../assets/svg/icons/settings.svg'
import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'
import { trackSocialProof, trackTapFeed } from '../../../helpers/MixpanelHelper'

class TapPage extends PureComponent {
    constructor(props) {
        super()
        this.state = {
            titles: {
                page_title: '',
                page_desc: '',
                display_search_bar: true,
                display_tap_logo: true,
                social_proof: false,
                show_number_likes_views: false,
                group_chat_title: 'Speak with our ambassadors and others like you',
                profile_title: 'Chat with our ambassadors',
                content_title: "Check out what we've been up to",
                faq_title: 'Explore our frequently asked questions',
            },
            filtersData: {},
            domains: [],
            errors: {
                domains: null,
            },
            pageTitleSymbolsLeft: 255,
            pageDescSymbolsLeft: 2000,
            groupChatTitleSymbolsLeft: 255,
            profileTitleSymbolsLeft: 255,
            contentTitleSymbolsLeft: 255,
            faqTitleSymbolsLeft: 255,
            initialSocialProof: false,
            showExplanationModal: false,
        }

        this.showExplanationModal = this.showExplanationModal.bind(this)
        this.handleCloseExplanationModal = this.handleCloseExplanationModal.bind(this)
        this.prevStep = this.prevStep.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'setup-tap-page',
            },
        })
        const onSuccess = (titles) => {
            if (!titles) {
                titles = {}
            }
            this.setState({
                titles: {
                    page_title: titles.page_title ? titles.page_title : '',
                    page_desc: titles.page_desc ? titles.page_desc : '',
                    display_search_bar: titles.display_search_bar,
                    display_tap_logo: titles.display_tap_logo,
                    social_proof: titles.social_proof,
                    show_number_likes_views: titles.show_number_likes_views,
                    group_chat_title: titles.group_chat_title ? titles.group_chat_title : '',
                    profile_title: titles.profile_title ? titles.profile_title : '',
                    content_title: titles.content_title ? titles.content_title : '',
                    faq_title: titles.faq_title ? titles.faq_title : '',
                },
                initialSocialProof: titles.social_proof,
                pageTitleSymbolsLeft: titles.page_title ? 255 - titles.page_title.length : 255,
                pageDescSymbolsLeft: titles.page_desc ? 2000 - titles.page_desc.length : 2000,
                groupChatTitleSymbolsLeft: titles.group_chat_title ? 255 - titles.group_chat_title.length : 255,
                profileTitleSymbolsLeft: titles.profile_title ? 255 - titles.profile_title.length : 255,
                contentTitleSymbolsLeft: titles.content_title ? 255 - titles.content_title.length : 255,
                faqTitleSymbolsLeft: titles.faq_title ? 255 - titles.faq_title.length : 255,
                titlesChanged: false,
            })
        }

        this.props.getTAPTitles(onSuccess)
        this.props.getFilters()
        // this.props.getSocialProofInfo();
        this.props.getCspDomains()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tapFilters !== this.props.tapFilters && this.props.tapFilters) {
            const filtersData = this.props.tapFilters
            let filters = filtersData.filters
            if (filters && filters.length > 0) {
                for (const key in filters) {
                    filters[key] = {
                        ...filters[key],
                        filterIndex: +key + 1,
                        isAllFilled: true,
                    }
                }
            } else {
                filters = [
                    {
                        filterIndex: 1,
                        isAllFilled: false,
                    },
                ]
            }
            filtersData.filters = filters

            this.setState({
                filtersData: filtersData,
            })
        }
        if (prevProps.cspDomains !== this.props.cspDomains) {
            this.setState({
                domains: this.props.cspDomains,
            })
        }
    }

    nextStep = async () => {
        const {
            page_title: pageTitle,
            page_desc: pageDesc,
            display_search_bar: displaySearchBar,
            group_chat_title: groupChatTitle,
            profile_title: profileTitle,
            content_title: contentTitle,
            faq_title: faqTitle,
            social_proof: socialProof,
            show_number_likes_views: showNumberLikesViews,
            display_tap_logo: displayTapLogo,
        } = this.state.titles
        const { filtersData, domains, titlesChanged, filtersChanged, domainsChanged, initialSocialProof } = this.state
        const { account, history } = this.props

        const onAllRequestsFinish = () => {
            if (
                !window.localStorage.getItem('setupWizardStep') ||
                +window.localStorage.getItem('setupWizardStep') < 5
            ) {
                window.localStorage.setItem('setupWizardStep', '5')
            }
            history.push('/setup/optional-data')
        }
        const onFiltersFinish = () => {
            if (domainsChanged && domains && domains.length > 0) {
                const onFinish = (added) => {
                    if (added) {
                        trackTapFeed(account.account_info.university.id)
                    }
                    onAllRequestsFinish()
                }
                this.props.setCspDomains(domains, onFinish)
            } else {
                onAllRequestsFinish()
            }
        }
        const onFinish = () => {
            try {
                if (titlesChanged && socialProof !== initialSocialProof) {
                    trackSocialProof(account.account_info.university.id, socialProof)
                }
                if (filtersChanged) {
                    const filtersPost = []
                    if (filtersData.filters && filtersData.filters.length > 0) {
                        for (const filter of filtersData.filters) {
                            if (filter.isAllFilled) {
                                filtersPost.push(filter)
                            }
                        }
                    }
                    this.props.setFilters(
                        filtersPost.map((option) => ({
                            ...option,
                        })),
                        onFiltersFinish
                    )
                } else {
                    onFiltersFinish()
                }
            } catch (error) {
                console.log(error)
                onAllRequestsFinish()
            }
        }

        if (titlesChanged) {
            await this.props.changeTAPTitles(
                {
                    page_title: pageTitle,
                    page_desc: pageDesc,
                    display_search_bar: displaySearchBar,
                    group_chat_title: groupChatTitle,
                    profile_title: profileTitle,
                    content_title: contentTitle,
                    faq_title: faqTitle,
                    social_proof: socialProof,
                    show_number_likes_views: showNumberLikesViews,
                    display_tap_logo: displayTapLogo,
                },
                null,
                onFinish
            )
        } else if (filtersChanged) {
            onFinish()
        } else if (domainsChanged) {
            onFiltersFinish()
        } else {
            onAllRequestsFinish()
        }
    }

    prevStep() {
        this.props.history.push('/setup/branding')
    }

    showExplanationModal() {
        this.setState({
            showExplanationModal: true,
        })
    }

    handleCloseExplanationModal() {
        this.setState({
            showExplanationModal: false,
        })
    }

    handleChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        if (
            (target.name === 'page_title' ||
                target.name === 'group_chat_title' ||
                target.name === 'profile_title' ||
                target.name === 'content_title' ||
                target.name === 'faq_title') &&
            value.length >= 255
        ) {
            return
        }
        if (target.name === 'page_desc' && value.length >= 2000) {
            return
        }

        this.setState((state) => ({
            ...state,
            titles: {
                ...state.titles,
                [name]: value,
            },
            pageTitleSymbolsLeft: target.name === 'page_title' ? 255 - value.length : state.pageTitleSymbolsLeft,
            pageDescSymbolsLeft: target.name === 'page_desc' ? 2000 - value.length : state.pageDescSymbolsLeft,
            groupChatTitleSymbolsLeft:
                target.name === 'group_chat_title' ? 255 - value.length : state.groupChatTitleSymbolsLeft,
            profileTitleSymbolsLeft:
                target.name === 'profile_title' ? 255 - value.length : state.profileTitleSymbolsLeft,
            contentTitleSymbolsLeft:
                target.name === 'content_title' ? 255 - value.length : state.contentTitleSymbolsLeft,
            faqTitleSymbolsLeft: target.name === 'faq_title' ? 255 - value.length : state.faqTitleSymbolsLeft,
            titlesChanged: true,
        }))
    }

    copyLink(code) {
        const tempInput = document.createElement('input')
        tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px'
        tempInput.value = code
        document.body.append(tempInput)
        tempInput.select()
        document.execCommand('copy')
        tempInput.remove()
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'TAP Feed embed code copied to clipboard',
            },
        })
    }

    addFilter = () => {
        const { filtersData } = this.state
        if (filtersData.filters.length < 3) {
            const availableIndex = [1, 2, 3].find(
                // eslint-disable-next-line unicorn/prefer-array-some
                (index) => !filtersData.filters.find((item) => item.filterIndex === index)
            )
            this.setState({
                filtersData: {
                    ...filtersData,
                    filters: [...filtersData.filters, { filterIndex: availableIndex, isAllFilled: false }],
                },
                filtersChanged: true,
            })
        }
    }

    removeFilter = (index) => {
        const { filtersData } = this.state
        const filtersNew = filtersData.filters.filter((item) => item.filterIndex !== index)

        this.setState({
            filtersData: {
                ...filtersData,
                filters: filtersNew,
            },
            filtersChanged: true,
        })
    }

    handleFilterChanges = (filter, filterIndex) => {
        const { filters } = this.state.filtersData
        const index = +filterIndex - 1
        if (filters && filters[index]) {
            filters[index] = {
                ...filters[index],
                filterIndex: index + 1,
                ...filter,
            }
        }

        this.setState({
            filtersData: {
                ...this.state.filtersData,
                filters: [...filters],
            },
            filtersChanged: true,
        })
    }

    handleChangeDomains = (domains) => {
        const regex = new RegExp(/^(?:http|https?:\/\/)?([\da-z-]+\.){0,5}[\da-z-]{2,}\.[a-z]{1,64}?$/gi)

        let domainsError = null
        for (const domain of domains) {
            // eslint-disable-next-line unicorn/prefer-regexp-test
            if (!domain.match(regex)) {
                domainsError =
                    'Domain link should be full and ' +
                    domain +
                    " shouldn't contain any additional paths and special symbols."
            }
        }
        this.setState({
            domains: domainsError ? this.state.domains : domains,
            domainsChanged: true,
            errors: {
                ...this.state.errors,
                domains: domainsError,
            },
        })
    }

    render() {
        const {
            page_title: pageTitle,
            page_desc: pageDesc,
            display_search_bar: displaySearchBar,
            social_proof: socialProof,
            show_number_likes_views: showNumberLikesViews,
            group_chat_title: groupChatTitle,
            profile_title: profileTitle,
            content_title: contentTitle,
            faq_title: faqTitle,
            display_tap_logo: displayTapLogo,
        } = this.state.titles
        const {
            filtersData,
            domains,
            errors,
            pageTitleSymbolsLeft,
            pageDescSymbolsLeft,
            groupChatTitleSymbolsLeft,
            profileTitleSymbolsLeft,
            contentTitleSymbolsLeft,
            faqTitleSymbolsLeft,
            showExplanationModal,
        } = this.state
        const { university } = this.props.account.account_info

        const code = `<iframe id='tap-iframe-widget' style='border:none' src='' width='100%' height='700' scrolling='no' data-type='full-widget' data-university-id='${
            university ? university.id : ''
        }'></iframe><script defer src='https://cdn.theaccessplatform.com/widget.js'></script>`

        let filtersView = []
        const filtersPreview = []
        if (filtersData && filtersData.filters && filtersData.filters.length > 0) {
            filtersView = filtersData.filters.map((item, index) => {
                console.log(item.isAllFilled)
                if (item.isAllFilled) {
                    filtersPreview.push(
                        <div className="dropdown-custom">
                            <div className="filter-name">{item.title}</div>
                            <div className="input-wrapper">
                                <input className="dropdown-input" placeholder="Select or type to search" />
                                <img className="chevron" src={chevronDown} alt="" />
                            </div>
                        </div>
                    )
                }

                return (
                    <TAPFilter
                        initialData={item}
                        setFilter={this.handleFilterChanges}
                        addFilter={this.addFilter}
                        removeFilter={this.removeFilter}
                        key={item.id}
                        filterNum={index}
                        possibleFiltersNum={3}
                        activeFiltersNum={filtersData.filters.length}
                        tapFilters={filtersData}
                        setupWizard={true}
                    />
                )
            })
        } else {
            filtersView.push(
                <button className="filter__add-btn filter__add-btn--initial" onClick={this.addFilter}>
                    <Close className="filter__add-btn__icon" />
                    Add filter
                </button>
            )
        }

        return (
            <div>
                <Header submenuUnActive={true} />
                <div id="page-wrapper" className="page-wrapper">
                    <NavigationSetupWizard />

                    <section className="content settings no-submenu home-start-guide setup-wizard tap-settings-step">
                        <h1 className="settings-title">Admin Dashboard Setup Guide</h1>
                        <div className="setup-start-intro">
                            Welcome to Setup Guide of The Ambassador Platform’s Admin Dashboard! This section is
                            designed to guide you through set up.
                        </div>

                        <div className="start-guide-wizard-grid">
                            <div className="column">
                                <div className="setup-start-block">
                                    <div className="policy-title">4. Embed your TAP Feed</div>
                                    <p className="video-privacy-link">
                                        Watch this <span onClick={this.showExplanationModal}>video explanation</span> to
                                        see how it works
                                    </p>

                                    <h4 className="settings-name">Embed code for TAP Feed</h4>
                                    <div className="invite-link-block">
                                        <span className="invite-training-link">{code}</span>
                                        <span className="copy" onClick={(e) => this.copyLink(code)}>
                                            Copy
                                        </span>
                                    </div>
                                    <h4 className="settings-name">Domains</h4>
                                    <div>
                                        Enter the domain(s) where your TAP Feed and TAP Promotions (carousels) will be
                                        hosted. Find out more about{' '}
                                        <a
                                            rel="noopener noreferrer"
                                            href="https://knowledge.theambassadorplatform.com/what-is-content-security-policy-csp"
                                            target="_blank"
                                        >
                                            Content Security Policy
                                        </a>
                                        .
                                    </div>

                                    <div className="tip-block tap-tip-block">
                                        <img src={tipIcon} alt="tip" />
                                        <span>
                                            This is a really important step: we will only display TAP on predefined
                                            domains to provide extra security.
                                        </span>
                                    </div>

                                    <div className="tap-settings-domain-names">
                                        <DomainsSelector domains={domains} setDomains={this.handleChangeDomains} />
                                        <span className="input-error-message">{errors.domains}</span>
                                    </div>

                                    <h4 className="tap-configuration-text">Tap Feed configuration</h4>
                                    <div className="tap-settings-wrapper">
                                        <div className="tap-settings-item">
                                            <h4 className="tap-settings-name">Page Title</h4>
                                            <div className="title-input">
                                                <input
                                                    className="tap-promotion-input"
                                                    placeholder={`Ask us about what life at ${university.name} is really like`}
                                                    name="page_title"
                                                    value={pageTitle}
                                                    onChange={this.handleChange}
                                                    type="text"
                                                />
                                                <span>{pageTitleSymbolsLeft}/255</span>
                                            </div>
                                        </div>

                                        <div className="tap-settings-item">
                                            <h4 className="tap-settings-name">Page Description</h4>
                                            <div className="title-input">
                                                <textarea
                                                    className="tap-promotion-input-textarea scroll-8"
                                                    placeholder={`Do you want to speak to someone about their experiences at ${university.name}? Start a chat with our friendly ambassadors now!`}
                                                    name="page_desc"
                                                    value={pageDesc}
                                                    onChange={this.handleChange}
                                                />
                                                <span>{pageDescSymbolsLeft}/2000</span>
                                            </div>
                                        </div>

                                        <div className="tap-settings-item">
                                            <h4 className="tap-settings-name">Group Chat Tab Description</h4>
                                            <div className="title-input">
                                                <input
                                                    className="tap-promotion-input"
                                                    placeholder="Speak with our ambassadors and others like you"
                                                    name="group_chat_title"
                                                    value={groupChatTitle}
                                                    onChange={this.handleChange}
                                                    type="text"
                                                />
                                                <span>{groupChatTitleSymbolsLeft}/255</span>
                                            </div>
                                        </div>

                                        <div className="tap-settings-item">
                                            <h4 className="tap-settings-name">Profile Section</h4>
                                            <div className="title-input">
                                                <input
                                                    className="tap-promotion-input"
                                                    placeholder="Chat with our ambassadors"
                                                    name="profile_title"
                                                    value={profileTitle}
                                                    onChange={this.handleChange}
                                                    type="text"
                                                />
                                                <span>{profileTitleSymbolsLeft}/255</span>
                                            </div>
                                        </div>

                                        <div className="tap-settings-item">
                                            <h4 className="tap-settings-name">Content Section</h4>
                                            <div className="title-input">
                                                <input
                                                    className="tap-promotion-input"
                                                    placeholder={`See what life at ${university.name} is really like`}
                                                    name="content_title"
                                                    value={contentTitle}
                                                    onChange={this.handleChange}
                                                    type="text"
                                                />
                                                <span>{contentTitleSymbolsLeft}/255</span>
                                            </div>
                                        </div>

                                        <div className="tap-settings-item">
                                            <h4 className="tap-settings-name">FAQ Section</h4>
                                            <div className="title-input">
                                                <input
                                                    className="tap-promotion-input"
                                                    placeholder="Check out what our ambassadors say"
                                                    name="faq_title"
                                                    value={faqTitle}
                                                    onChange={this.handleChange}
                                                    type="text"
                                                />
                                                <span>{faqTitleSymbolsLeft}/255</span>
                                            </div>
                                        </div>

                                        <div className="tap-settings-item">
                                            <h4 className="tap-settings-name">Include text search bar?</h4>
                                            <label className="slideOne js-holder">
                                                <input
                                                    type="checkbox"
                                                    name="display_search_bar"
                                                    checked={displaySearchBar}
                                                    onChange={this.handleChange}
                                                />
                                                <span className="slideOne-icon"></span>
                                            </label>
                                        </div>
                                        <div className="tap-settings-item">
                                            <h4 className="tap-settings-name">Include TAP logo?</h4>
                                            <label className="slideOne js-holder">
                                                <input
                                                    type="checkbox"
                                                    name="display_tap_logo"
                                                    checked={displayTapLogo}
                                                    onChange={this.handleChange}
                                                />
                                                <span className="slideOne-icon"></span>
                                            </label>
                                        </div>
                                        <div className="tap-settings-item">
                                            <h4 className="tap-settings-name">
                                                Show number of likes and views on the tap feed?
                                            </h4>
                                            <label className="slideOne js-holder">
                                                <input
                                                    type="checkbox"
                                                    name="show_number_likes_views"
                                                    checked={showNumberLikesViews}
                                                    onChange={this.handleChange}
                                                />
                                                <span className="slideOne-icon"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <p className="tap-dropdown-filters">Dropdown filters</p>

                                    {filtersView}

                                    <div className="settings-item">
                                        <div className="tap-social-proof">Social proof</div>
                                        <h4 className="settings-name">Include social proof?</h4>
                                        <label className="slideOne js-holder">
                                            <input
                                                type="checkbox"
                                                name="social_proof"
                                                checked={socialProof}
                                                onChange={this.handleChange}
                                            />
                                            <span className="slideOne-icon"></span>
                                        </label>
                                        <div className="social-proof-floor-text">
                                            To provide maximum impact social proof text will only be displayed when 200
                                            new users have viewed your TAP Feed. Check out our{' '}
                                            <a
                                                rel="noopener noreferrer"
                                                href="https://knowledge.theambassadorplatform.com/what-is-social-proof"
                                                target="_blank"
                                            >
                                                help article
                                            </a>{' '}
                                            for more information on how this feature works.
                                        </div>

                                        <h4 className="settings-name">Preview:</h4>
                                        <div className="joined-social-text">
                                            Joined{' '}
                                            <div className="user-social-icon">
                                                <UserIcon />
                                            </div>
                                            <span>1975 people</span>who have gained first hand insights into life at UT
                                            University{' '}
                                        </div>
                                    </div>
                                    <div className="setup-steps-buttons">
                                        <button className="previous-step-button" onClick={this.prevStep}>
                                            Previous
                                        </button>
                                        <button onClick={this.nextStep}>Next</button>
                                    </div>
                                    <div className="setup-skip-text">
                                        If you skip this step, you'll find it later in <SettingsIcon /> Settings
                                    </div>
                                </div>
                            </div>

                            <div className="column preview">
                                <div className="preview-body tap-page-preview-body">
                                    <div className="preview-title">
                                        <span>Preview </span>
                                        <span>TAP Feed template</span>
                                    </div>
                                    <div className="preview-header-text">{pageTitle}</div>
                                    <p className="preview-header-text-small">{pageDesc}</p>
                                    {displaySearchBar && (
                                        <div className="preview-search-form">
                                            <SearchIcon className="preview-search-icon" />
                                            <span className="preview-search-input"></span>
                                            <button className="preview-widget-head-filter-btn">Search</button>
                                        </div>
                                    )}

                                    <div className="preview-filters">{filtersPreview}</div>

                                    <p className="cards-title-left">
                                        <span>{profileTitle}</span>
                                        <span>Ambassadors' profile cards</span>
                                    </p>
                                    <div className="profile-cards">
                                        <img src={ProfileExample} alt="Profile" />
                                        <img src={ProfileExample} alt="Profile" />
                                        <img src={ProfileExample} alt="Profile" />
                                    </div>

                                    <p className="cards-title-left">
                                        <span>{contentTitle}</span>
                                        <span>Ambassadors' content</span>
                                    </p>
                                    <div className="post-cards">
                                        <img src={PostExample} alt="Post" />
                                        <img src={PostExample} alt="Post" />
                                        <img src={PostExample} alt="Post" />
                                    </div>

                                    <p className="cards-title-left">
                                        <span>{faqTitle}</span>
                                        <span>Ambassadors' answers to FAQs</span>
                                    </p>
                                    <div className="faq-cards">
                                        <img src={FaqExample} alt="Faq" />
                                        <img src={FaqExample} alt="Faq" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Modal
                    open={showExplanationModal}
                    bodyStyles={{ padding: 0 }}
                    centered={true}
                    width={800}
                    title={null}
                    footer={null}
                    destroyOnClose={true}
                    keyboard={false}
                    onCancel={this.handleCloseExplanationModal}
                    className="setup-modal"
                >
                    <ExplanationModal handleCloseExplanationModal={this.handleCloseExplanationModal} />
                </Modal>
            </div>
        )
    }
}

export default connect(
    (state) => {
        return {
            account: state.account,
            tapTitles: state.tapTitles,
            tapFilters: state.tapFilters,
            cspDomains: state.cspDomains,
        }
    },
    { getTAPTitles, changeTAPTitles, getFilters, setFilters, getCspDomains, setCspDomains, getSocialProofInfo }
)(TapPage)
