import React from 'react'
import { Select } from 'antd'

const { Option } = Select

const DataManagementDropdown = (props) => {
    const optionsValues = [6, 12, 18, 24, 30, 36, 42, 48, 54, 60]

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
                    <path
                        id="prefix__pie-chart"
                        fill="#ff5100"
                        d="M10 18a8 8 0 1 0-8-8 8 8 0 0 0 8 8zm0 2a10 10 0 1 1 10-10 10 10 0 0 1-10 10zM9 1h2v10H9zm0 8h10v2H9z"
                    />
                </svg>
            </div>
            <Select
                labelInValue
                value={{ key: props.dataManagementTerm }}
                onChange={props.onChange}
                size="default"
                className="data-management-select"
                popupClassName="data-management-dropdown"
                dropdownMatchSelectWidth={false}
                dropdownRender={(menu) => (
                    <div>
                        <h2 className="data-management-dropdown-header">Delete Prospect Information after:</h2>
                        {menu}
                    </div>
                )}
            >
                {optionsValues.map((item, i) => (
                    <Option key={i} value={item} className="data-management-dropdown-option">
                        {item} months
                    </Option>
                ))}
            </Select>
        </div>
    )
}

export default DataManagementDropdown
