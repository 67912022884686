import React, { useCallback, useState, useMemo } from 'react'
import { Form, Button, Row, Col, Typography, Select } from 'antd'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { useDispatch } from 'react-redux'

import { changeAmbassadorChildren } from '../../../../../redux/actions/content/usersActions'

import pencil from '../../../../../../assets/img/pencil.png'

import DatePicker from '../../../../date-picker/date-picker'

const { Text } = Typography
const { Option } = Select

const yearsOfStudying = [
    {
        id: 100,
        name: 'Toddlers',
    },
    {
        id: 101,
        name: 'Nursery',
    },
    {
        id: 102,
        name: 'Reception',
    },
    {
        id: 1,
        name: 'Year 1',
    },
    {
        id: 2,
        name: 'Year 2',
    },
    {
        id: 3,
        name: 'Year 3',
    },
    {
        id: 4,
        name: 'Year 4',
    },
    {
        id: 5,
        name: 'Year 5',
    },
    {
        id: 6,
        name: 'Year 6',
    },
    {
        id: 7,
        name: 'Year 7',
    },
    {
        id: 8,
        name: 'Year 8',
    },
    {
        id: 9,
        name: 'Year 9',
    },
    {
        id: 10,
        name: 'Year 10',
    },
    {
        id: 11,
        name: 'Year 11',
    },
    {
        id: 12,
        name: 'Year 12',
    },
    {
        id: 13,
        name: 'Year 13',
    },
]

const AmbassadorChildren = ({
    userId,
    saveStudentInfo,
    university,
    childrenInfoFields,
    childrenArrLength,
    childrenArr,
}) => {
    const [editMode, setEditMode] = useState(false)
    const [checkRequired, setCheckRequired] = useState(false)

    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const onFinish = useCallback(() => {
        setCheckRequired(true)
        form.validateFields(['ambassadorChildren'], {
            recursive: true,
        }).then((values) => {
            dispatch(
                changeAmbassadorChildren({
                    userId,
                    children: values.ambassadorChildren,
                })
            )
            setCheckRequired(false)
            setEditMode(false)
            saveStudentInfo()
        })
    }, [form, saveStudentInfo, dispatch, userId])

    const editType = useCallback(() => {
        setEditMode(true)
    }, [])

    const onCancel = useCallback(() => {
        setEditMode(false)
    }, [])

    const options = useMemo(
        () =>
            yearsOfStudying.map((year) => {
                return (
                    <Option key={year.id} value={year.id} label={year.name}>
                        {year.name}
                    </Option>
                )
            }),
        []
    )

    const prepareInitialValue = childrenArr.map((item) => {
        item.age = new Date(item.age)
        return item
    })

    return editMode ? (
        <>
            <Form
                initialValues={{
                    ambassadorChildren: prepareInitialValue,
                }}
                autoComplete="off"
                hideRequiredMark
                form={form}
                layout="vertical"
            >
                <Form.List name="ambassadorChildren">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }, listIndex) => {
                                console.log(fields)
                                return (
                                    <div key={key}>
                                        <Row justify="space-between">
                                            <Col>
                                                <Text underline>Child {listIndex + 1} </Text>
                                            </Col>
                                            <Col>
                                                {listIndex > 0 && <CloseCircleOutlined onClick={() => remove(name)} />}
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            {...restField}
                                            label="Date of birth"
                                            name={[name, 'age']}
                                            fieldKey={[fieldKey, 'age']}
                                            rules={[
                                                {
                                                    required: checkRequired,
                                                    message: 'Please select age',
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                placeholder="Choose date"
                                                allowClear={false}
                                                showToday={false}
                                                style={{ fontSize: 16, width: 200 }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Year of studying"
                                            name={[name, 'studying_year']}
                                            fieldKey={[fieldKey, 'studying_year']}
                                            rules={[
                                                { required: checkRequired, message: 'Please select year of studying' },
                                            ]}
                                        >
                                            <Select
                                                optionFilterProp="children"
                                                optionLabelProp="label"
                                                style={{ fontSize: 16, width: 200 }}
                                            >
                                                {options}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                )
                            })}
                            {fields.length < 4 && (
                                <Form.Item noStyle>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            setCheckRequired(true)
                                            form.validateFields(['ambassadorChildren'], {
                                                recursive: true,
                                            }).then(() => {
                                                add()
                                                setCheckRequired(false)
                                            })
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add a child
                                    </Button>
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.List>
                <div className="input__buttons">
                    <button className="input-control-button" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="input-control-button" onClick={onFinish}>
                        Save
                    </button>
                </div>
            </Form>
        </>
    ) : (
        <>
            <>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <span className="profile-info-tags-name description-row__name">Children at&nbsp;{university}</span>

                    <button className="input-control-button" onClick={editType}>
                        <img className="pencil-icon" src={pencil} alt="" />
                    </button>
                </div>
                {childrenArrLength === 0 ? (
                    <p style={{ color: 'red', fontSize: '1.6rem' }}>Please, specify</p>
                ) : (
                    <div className="profile-info-child-container">{childrenInfoFields}</div>
                )}
            </>
        </>
    )
}

export default AmbassadorChildren
