export const GET_TITLES_REQUESTED = 'GET_TITLES_REQUESTED'
export const GET_TITLES_SUCCESS = 'GET_TITLES_SUCCESS'
export const GET_TITLES_FAILED = 'GET_TITLES_FAILED'

export const CHANGE_TITLES_REQUESTED = 'CHANGE_TITLES_REQUESTED'
export const CHANGE_TITLES_SUCCESS = 'CHANGE_TITLES_SUCCESS'
export const CHANGE_TITLES_FAILED = 'CHANGE_TITLES_FAILED'

export const SOCIAL_PROOF = 'SOCIAL_PROOF'
export const SOCIAL_PROOF_SUCCESS = 'SOCIAL_PROOF_SUCCESS'
export const SOCIAL_PROOF_FAIL = 'SOCIAL_PROOF_FAIL'

export const getTAPTitles = (onSuccess) => ({
    type: GET_TITLES_REQUESTED,
    onSuccess,
})

export const changeTAPTitles = (options, onSuccess, onFinish) => ({
    type: CHANGE_TITLES_REQUESTED,
    payload: options,
    onSuccess: onSuccess,
    onFinish: onFinish,
})

export const getSocialProofInfo = () => ({
    type: SOCIAL_PROOF,
})
