import { call, put } from 'redux-saga/effects'
import API from '../../../api'
import { SET_SETUP_WIZARD_SUCCESS } from '../../actions/settings/setupWizardActions'

export function* setupWizardFinish(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.settings.setupWizardFinish(payload)
        })

        if (response.success) {
            yield put({
                type: SET_SETUP_WIZARD_SUCCESS,
            })
            action.onSuccess()
        }
    } catch (e) {
        console.log(e)
    }
}
