/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { roles } from '../../../../helpers/helpers'
import { AMBASSADORS_INFO } from '../../../../redux/actions/content/messagingActions'

import {
    closeStreamFormModal,
    createLiveStream,
    updateLiveStream,
} from '../../../../redux/actions/live-streams/live-streams-actions'
import {
    getAccountInfoId,
    getUniversityInfo,
    getLiveStreamsModal,
    getAccountSlice,
    getIsGroupAdmin,
    getLiveStreamsTypeModal,
} from '../../../../redux/selectors'

import { EventFormModel, EventRequestModel } from './event-form'

import { defaults } from '../../../../system/services/HttpService'

import { LIVE_STREAM_STATUS } from '../../../../common/constants'

const FORM_STEPS_KEYS = {
    MAIN_FIELDS: 'MAIN_FIELDS',
    USERS: 'USERS',
    VIDO_UPLOAD: 'VIDO_UPLOAD',
}

const FORM_STEPS = [FORM_STEPS_KEYS.MAIN_FIELDS, FORM_STEPS_KEYS.USERS, FORM_STEPS_KEYS.VIDO_UPLOAD]

const pickTitleText = (step, editMode) => {
    if (editMode) {
        return 'Edit stream'
    }
    switch (step) {
        case 0:
            return 'On-Demand Stream'
        case 1:
            return 'Chat participants'
        case 2:
            return 'Upload Video'
        default:
            return ''
    }
}

const pickFieldsToValidate = (step) => {
    if (step === 0) return ['name', 'image', 'description', 'date']
    if (step === 1) return []
    if (step === 2) return []
}

export const useEventFormModal = () => {
    const dispatch = useDispatch()
    const { open, data, submitting } = useSelector(getLiveStreamsModal)
    const { data: modalData } = useSelector(getLiveStreamsTypeModal)
    const accountInfoId = useSelector(getAccountInfoId)
    const universityInfo = useSelector(getUniversityInfo)
    const isGroupAdmin = useSelector(getIsGroupAdmin)
    const accountInfo = useSelector(getAccountSlice)

    const [step, setStep] = useState(0)
    const [imageState, setImageState] = useState({})

    const [videoFileRequest, setVideoFileRequest] = useState(null)
    const [videoProgress, setVideoProgress] = useState(0)
    const [videoProgressStart, setVideoProgressStart] = useState(false)
    const [videoFileName, setVideoFileName] = useState('')

    const [form] = Form.useForm()

    const initialValues = useMemo(() => EventFormModel(data, accountInfoId), [data, accountInfoId])

    const editMode = useMemo(() => data && data.id, [data])
    const isCompleted = useMemo(() => data && data.status === LIVE_STREAM_STATUS.COMPLETED, [data])

    const { isRelatedToGroup } = universityInfo

    const isLastStep = step === FORM_STEPS.length - 1

    const modalTitle = useMemo(() => pickTitleText(step, editMode), [step, editMode])
    const submitButtonTitle = useMemo(
        () => (step === FORM_STEPS.length - 1 ? 'Upload' : editMode ? 'Save' : 'Next'),
        [step, editMode]
    )

    useEffect(() => {
        if (initialValues.id) {
            setImageState((prevState) => ({
                ...prevState,
                imageUrl: initialValues.image,
            }))
        }
    }, [initialValues])

    const loadUsers = useCallback(() => {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
                roles: [roles.student],
            },
        })
    }, [dispatch])

    useEffect(() => {
        if (step === 1) {
            loadUsers()
        }
    }, [step])

    const handlePrevClick = useCallback(() => {
        setStep((prevState) => prevState - 1)
    }, [])

    const afterClose = useCallback(() => {
        form.resetFields()
        setStep(0)
        setImageState({})
    }, [form])

    const submitForm = useCallback(
        ({ videoId, videoDuration }) => {
            form.validateFields().then((values) => {
                const requestData = {
                    ...values,
                    ...modalData,
                    ...{
                        videoId,
                        videoDuration,
                    },
                }
                const requestPayload = EventRequestModel(requestData)

                dispatch(createLiveStream(requestPayload))
            })
        },
        [form, dispatch, modalData]
    )

    const handleNextClick = useCallback(() => {
        if (editMode) {
            form.validateFields().then((values) => {
                const imageTouched = form.isFieldTouched('image')
                const requestPayload = EventRequestModel(values, data, imageTouched)
                dispatch(updateLiveStream(requestPayload))
            })
        } else {
            const fieldsToValidate = pickFieldsToValidate(step)
            form.validateFields(fieldsToValidate).then(() => {
                setStep((prevState) => prevState + 1)
            })
        }
    }, [step, form, submitForm, editMode])

    const onSkipStep = useCallback(() => {
        form.setFieldsValue({
            userIds: [],
        })
        setStep((prevState) => prevState + 1)
    }, [])

    const handleCancel = useCallback(() => {
        if (!form.isFieldsTouched()) {
            dispatch(closeStreamFormModal())
            return
        }

        const confirmCloseModal = confirm('Are you sure you want to close this popup? Changes will not be saved')

        if (confirmCloseModal) {
            dispatch(closeStreamFormModal())
        }
    }, [dispatch, form])

    const progressHandler = useCallback((event) => {
        const percent = (event.loaded / event.total) * 100
        const numberBeforeDecimal = Number.parseInt(percent)
        setVideoProgress(numberBeforeDecimal)
    }, [])

    const completeHandler = useCallback(
        (event, videoDuration) => {
            setVideoProgressStart(false)
            const request = event.currentTarget
            if (request.readyState == 4) {
                if (request.status == 200) {
                    const response = JSON.parse(request.response)
                    submitForm({
                        videoId: response.data.file.id,
                        videoDuration,
                    })
                    setVideoFileName('')
                } else {
                    console.log('Error loading file')
                }
            }
        },
        [submitForm]
    )

    const errorHandler = useCallback((event) => {
        console.log(event)
    }, [])

    const abortHandler = useCallback((event) => {
        setVideoProgressStart(false)
        setVideoProgress(0)
        setVideoFileName('')
    }, [])

    const videoTranscode = useCallback(
        (file, fileType, fileName) => {
            const url = defaults.api_host + '/dashboard/posts/videoTranscode/'
            let videoDur = 0

            const formData = new window.FormData()
            formData.append('file', file)

            var reader = new FileReader()
            reader.addEventListener('load', () => {
                var aud = new window.Audio(reader.result)
                aud.addEventListener('loadedmetadata', () => {
                    videoDur = aud.duration
                })
            })
            reader.readAsDataURL(file)

            const request = new window.XMLHttpRequest()
            setVideoFileRequest(request)
            setVideoFileName(fileName)
            request.upload.addEventListener('progress', progressHandler, false)
            request.addEventListener('load', (event) => completeHandler(event, videoDur), false)
            request.addEventListener('error', errorHandler, false)
            request.addEventListener('abort', abortHandler, false)
            request.open('POST', url)
            request.setRequestHeader('Authorization', 'Bearer ' + accountInfo.token)
            request.send(formData)

            setVideoProgressStart(true)
        },
        [abortHandler, errorHandler, completeHandler, progressHandler, accountInfo]
    )

    const abortVideoUpload = useCallback(
        (event) => {
            videoFileRequest.abort()
        },
        [videoFileRequest]
    )

    return {
        form,
        open,
        initialValues,
        modalTitle,
        submitButtonTitle,
        step,
        onPrevClick: handlePrevClick,
        onNextClick: handleNextClick,
        onCancel: handleCancel,
        afterClose,
        submitting,
        imageState,
        setImageState,
        editMode,
        isRelatedToGroup,
        isGroupAdmin,
        isLastStep,
        videoTranscode,
        videoProgress,
        videoProgressStart,
        abortVideoUpload,
        videoFileName,
        onSkipStep,
        isCompleted,
    }
}
