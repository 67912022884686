import Pusher from 'pusher-js'
import config from '../../config'

// For main purposes as global events in App and global notifications which shouldn't be removed
export const pusherGlobal = new Pusher(config.pusher_key, {
    cluster: 'eu',
    forceTLS: true,
})

// For notifications which we wanna see only in some components, on only some pages
export const pusherChild = new Pusher(config.pusher_key, {
    cluster: 'eu',
    forceTLS: true,
})
