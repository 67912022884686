import React, { useEffect } from 'react'
import { useAppDispatch } from 'src/app/hooks/store'
import { setSubmenu } from 'src/app/redux/actions/content/componentsActions'
import { PostsList } from './PostsList'
import { PopularTags } from './PostsList/PopularTags'
import { PostsListHeader } from './PostsList/PostsListHeader'
import { CreateContentGroupModal } from './modals/CreateContentGroup'
import { PostsShareModal } from './modals/PostsShareModal'
import { PreviewPostModal } from './modals/PreviewPostModal'

const PostsCore = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setSubmenu('content'))
    })

    return (
        <section id="content" className="content-layout bg-light-gray h-full">
            <PostsListHeader />
            <PostsList />
            <PopularTags />
            <CreateContentGroupModal />
            <PostsShareModal />
            <PreviewPostModal />
        </section>
    )
}
export default PostsCore
