import React, { useCallback } from 'react'

import { Form, Input, Button } from 'antd'

import { useDispatch } from 'react-redux'

import { changeOrganisationWebsite } from '../../../../../redux/actions/account/account-actions'

const OrganisationUrlForm = ({ organisationId }) => {
    const dispatch = useDispatch()

    const saveUrl = useCallback(
        (values) => {
            const data = {
                websiteUrl: values.organisationUrl,
                institutionId: organisationId,
            }
            dispatch(changeOrganisationWebsite(data))
        },
        [dispatch, organisationId]
    )

    return (
        <>
            <h4 className="settings-name">Organization website link</h4>
            <Form
                layout="vertical"
                initialValues={{
                    organisationUrl: '',
                }}
                onFinish={saveUrl}
                autoComplete="off"
                hideRequiredMark
                style={{ display: 'flex', alignItems: 'baseline' }}
            >
                <Form.Item
                    name="organisationUrl"
                    label={null}
                    style={{ width: '80%' }}
                    rules={[
                        { whitespace: false, message: 'Add organization website link' },
                        { required: true, message: 'Add organization website link' },
                        { type: 'url', message: 'The input is not valid link' },
                    ]}
                >
                    <Input placeholder="f.e. https://www.organization.com/" maxLength={255} />
                </Form.Item>
                <Button className="settings-btn" type="link" htmlType="submit">
                    Save
                </Button>
            </Form>
        </>
    )
}

export default OrganisationUrlForm
