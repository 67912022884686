import React, { PureComponent } from 'react'

class CopyRssSuccessPopup extends PureComponent {
    render() {
        const { handleClosePopup } = this.props

        return (
            <div className="confirm-copy-popup">
                <h3 className="modal-title">The RSS link has been copied</h3>
                <p>Please paste this in your feed reader or social media management platform</p>
                <div className="btn-wrap confirm-ban-buttons">
                    <button type="submit" className="el-btn confirm-delete-button" onClick={handleClosePopup}>
                        Close
                    </button>
                </div>
            </div>
        )
    }
}

export default CopyRssSuccessPopup
