import HttpService from '../../system/services/HttpService'

export default {
    getChartData: (chartName, filter, universityId) => {
        const data = {
            chartName: chartName,
            filter: filter,
        }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/homepage/chart', data)
    },
    getData: (dateFilter, postsLimit, faqAnswersLimit, universityId) => {
        const data = {
            dateFilter: dateFilter,
            postsLimit: postsLimit,
            faqAnswersLimit: faqAnswersLimit,
        }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/homepage/data', data)
    },
}
