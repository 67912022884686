import React from 'react';
export var MessageContent = function (_a) {
    var text = _a.text;
    if (!text)
        return null;
    var hasBadWord = text.includes('[badword]') && text.includes('[/badword]');
    var hasLink = text.includes('[link]') && text.includes('[/link]');
    if (!hasLink && !hasBadWord) {
        return React.createElement(React.Fragment, null, text);
    }
    var combinedParts = text.split(/(\[badword].*?\[\/badword])|(\[link].*?\[\/link])/g);
    return (React.createElement(React.Fragment, null, combinedParts.map(function (part, index) {
        if (!part)
            return null;
        if (part.startsWith('[badword]') && part.endsWith('[/badword]')) {
            var badWord = part.replace('[badword]', '').replace('[/badword]', '');
            return (React.createElement("span", { className: "bad-word", key: index }, badWord));
        }
        if (part.startsWith('[link]') && part.endsWith('[/link]')) {
            var link = part.replace('[link]', '').replace('[/link]', '');
            var hyperlink = link;
            if (!link.startsWith('http://') && !link.startsWith('https://')) {
                hyperlink = "https://".concat(link);
            }
            return (React.createElement("a", { key: index, target: "_blank", href: hyperlink, rel: "noopener noreferrer" }, link));
        }
        return part;
    })));
};
