import { call, put, select } from 'redux-saga/effects'

import API from '../../../api'
import {
    ACCOUNT_LOGIN_FAILED,
    ACCOUNT_LOGIN_SUCCESS,
    ACCOUNT_INFO_SUCCESS,
    ACCOUNT_INFO_FAILED,
    ACCOUNT_INFO_REQUESTED,
    ACCOUNT_LOGOUT_SUCCESS,
    ACCOUNT_REGISTRATION_FAILED,
    OPEN_SUCCESS_REGISTRATION_MODAL,
    FIND_USER_RECOVER_SUCCESS,
    FIND_USER_RECOVER_FAILED,
    RECOVER_COMFIRM_SUCCESS,
    RECOVER_COMFIRM_FAILED,
    OPEN_LETTER_SEND_POPUP,
    OPEN_RECOVERY_SUCCESS_POPUP,
    SET_TEST_UNIVERSITY_TOGGLE_VALUE,
    CHECK_ADMIN_INVITATION_SUCCESS,
    CHECK_ADMIN_INVITATION_FAILED,
} from '../../actions/account/accountActions'
import { ACCOUNT } from '../../actions/account/account-types'
import { GET_NOTIFICATIONS_LIST } from '../../actions/settings/notifications-control'

export const getAccountState = (state) => state.account
const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* accountLogin(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.account.login(payload.email, payload.password)
        })

        if (response.success) {
            // eslint-disable-next-line no-undef
            localStorage.setItem('Account', JSON.stringify(response.data.token))

            yield put({
                type: ACCOUNT_LOGIN_SUCCESS,
                payload: response.data.token,
            })

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
            })
        } else if (response.user_id) {
            const userData = {
                user_id: response.user_id,
                userEmail: payload.email,
                userPassword: payload.password,
            }

            // eslint-disable-next-line no-undef
            localStorage.setItem('user_data', JSON.stringify(userData))

            if (action.goToFinishRegistration) {
                action.goToFinishRegistration()
            }
        } else {
            yield put({
                type: ACCOUNT_LOGIN_FAILED,
            })

            /*
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: "Alas, it looks like you're trying to log into our Dashboard on an ambassador account. A web app for ambassadors is coming soon but for now, you'll have to use the app we're afraid. Email our support team here (support@theambassadorplatform.com) if you have questions.",
                },
            }) */
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_LOGIN_FAILED,
        })
    }
}

export function* accountInfo(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.account.get()
        })

        if (response.success) {
            // const getAccountInfo = (state) => state.account;
            //  const account = yield select(getAccountInfo);

            const accountInfo = response.data.account

            if (accountInfo.universityGroup) {
                if (payload && payload.university_id && accountInfo.universityGroup.universities) {
                    for (const university of accountInfo.universityGroup.universities) {
                        if (university.id === payload.university_id) {
                            accountInfo.university = university
                            if (accountInfo.universityGroup.type === 'partner') {
                                accountInfo.university.partnerGroups = [
                                    {
                                        id: accountInfo.universityGroup.id,
                                        name: accountInfo.universityGroup.name,
                                    },
                                ]
                            }
                        }
                    }
                } else if (accountInfo.universityGroup) {
                    accountInfo.university = { ...accountInfo.universityGroup }
                }
                if (accountInfo.university) {
                    accountInfo.university.university_url = accountInfo.universityGroup.tap_page_url
                }
            }
            // eslint-disable-next-line no-undef
            localStorage.setItem('AccountInfo', JSON.stringify(accountInfo))

            yield put({
                type: ACCOUNT_INFO_SUCCESS,
                payload: {
                    accountInfo,
                    university_id: payload ? payload.university_id : null,
                },
            })

            if (!accountInfo.isGroupAdmin) {
                yield put({
                    type: GET_NOTIFICATIONS_LIST,
                })
            }
        } else {
            yield put({
                type: ACCOUNT_INFO_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_INFO_FAILED,
        })
    }
}

export function* accountLogout(action) {
    try {
        yield call(() => {
            return API.account.logout(JSON.parse(window.localStorage.getItem('Account')))
        })
        window.localStorage.removeItem('Account')
        window.localStorage.removeItem('AccountInfo')
        window.localStorage.removeItem('AccountFingerprint')
        window.localStorage.removeItem('ActiveMenu')
        window.document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
        if (window.HubSpotConversations) {
            window.hsConversationsSettings = {
                loadImmediately: false,
                identificationEmail: '',
                identificationToken: '',
            }
            window.HubSpotConversations.clear({ resetWidget: true })
            window.HubSpotConversations.widget.refresh()
        }
        yield put({
            type: ACCOUNT_LOGOUT_SUCCESS,
        })
    } catch (error) {
        console.log(error)
    }
}

export function* accountRegistration(action) {
    const { payload, onSuccess } = action

    try {
        const response = yield call(() => {
            return API.account.registration(payload)
        })

        if (response.success) {
            window.localStorage.setItem('Account', JSON.stringify(response.data.token))

            yield put({
                type: ACCOUNT_LOGIN_SUCCESS,
                payload: response.data.token,
            })

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
            })
            if (onSuccess) onSuccess()
        } else {
            yield put({
                type: ACCOUNT_REGISTRATION_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_REGISTRATION_FAILED,
        })
    }
}

export function* groupAccountRegistration(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.account.groupAccountRegistration(payload)
        })

        if (response.success) {
            yield put({
                type: OPEN_SUCCESS_REGISTRATION_MODAL,
            })
            window.localStorage.setItem('Account', JSON.stringify(response.data.token))

            yield put({
                type: ACCOUNT_LOGIN_SUCCESS,
                payload: response.data.token,
            })

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
            })
            if (action.onSuccess) action.onSuccess()
        } else {
            yield put({
                type: ACCOUNT_REGISTRATION_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_REGISTRATION_FAILED,
        })
    }
}

export function* findUserRecover(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.account.getUserRecover(payload.email)
        })

        if (response.success) {
            yield put({
                type: FIND_USER_RECOVER_SUCCESS,
            })

            yield put({
                type: OPEN_LETTER_SEND_POPUP,
            })
        } else {
            yield put({
                type: FIND_USER_RECOVER_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: FIND_USER_RECOVER_FAILED,
        })
    }
}

export function* recoverConfirm(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.account.recoverConfirm(
                payload.hex,
                payload.user_id,
                payload.new_password,
                payload.confirm_password
            )
        })

        if (response.success) {
            yield put({
                type: RECOVER_COMFIRM_SUCCESS,
            })

            yield put({
                type: OPEN_RECOVERY_SUCCESS_POPUP,
            })
        } else {
            yield put({
                type: RECOVER_COMFIRM_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: RECOVER_COMFIRM_FAILED,
        })
    }
}

export function* hubspotLogin(action) {
    const { meta } = action

    try {
        const response = yield call(() => {
            return API.account.getHubspotLoginToken()
        })

        if (response.success) {
            yield put({
                type: ACCOUNT.HUBSPOT_LOGIN_SUCCESS,
                payload: { token: response.data.token },
            })
            meta.onHubspotLoginSuccess(response.data.token)
        }
    } catch (error) {
        console.log(error)
    }
}

export function* setActiveTime() {
    try {
        yield call(() => {
            return API.account.setActiveTime(1000)
        })
    } catch (error) {
        console.log(error)
    }
}

export function* testUniToggle(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.account.testUniversityToggle()
        })
        if (response.success) {
            yield put({
                type: SET_TEST_UNIVERSITY_TOGGLE_VALUE,
                payload,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* checkAdminInvitation(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.account.checkAdminInvitation(payload)
        })
        if (response.success && response.data) {
            yield put({
                type: CHECK_ADMIN_INVITATION_SUCCESS,
                payload: {
                    appDomain: response.data.appDomain,
                    appClientId: response.data.appClientId,
                    isEncryptionOn: response.data.isEncryptionOn,
                },
            })
        }
    } catch (error) {
        yield put({
            type: CHECK_ADMIN_INVITATION_FAILED,
        })
        console.log(error)
    }
}

export function* checkGroupAdminInvitation(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.account.checkGroupAdminInvitation(payload)
        })
        if (response.success && response.data) {
            yield put({
                type: CHECK_ADMIN_INVITATION_SUCCESS,
                payload: {
                    appDomain: response.data.appDomain,
                    appClientId: response.data.appClientId,
                    isEncryptionOn: response.data.isEncryptionOn,
                },
            })
        }
    } catch (error) {
        yield put({
            type: CHECK_ADMIN_INVITATION_FAILED,
        })
        console.log(error)
    }
}

export function* accountSsoLogin(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.account.ssoLogin(payload.email, payload.ssoCode)
        })

        if (response.success) {
            window.localStorage.setItem('Account', JSON.stringify(response.data.token))

            yield put({
                type: ACCOUNT_LOGIN_SUCCESS,
                payload: response.data.token,
            })

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
            })
        } else if (response.user_id) {
            const userData = {
                user_id: response.user_id,
                userEmail: payload.email,
                userPassword: payload.password,
            }

            window.localStorage.setItem('user_data', JSON.stringify(userData))

            if (action.goToFinishRegistration) {
                action.goToFinishRegistration()
            }
        } else {
            yield put({
                type: ACCOUNT_LOGIN_FAILED,
            })
        }
    } catch (error) {
        yield put({
            type: ACCOUNT_LOGIN_FAILED,
        })
        console.log(error)
    }
}

export function* finishPlsSignUpRegistration(action) {
    const { payload, meta } = action
    try {
        const response = yield call(() => {
            return API.account.updateAccountInfo(payload)
        })
        if (response.success) {
            const { ambassador, prospect } = response.data

            window.localStorage.removeItem('PLSSignUpStep')
            window.localStorage.setItem('PlsAmbassadorLoginPassword', ambassador.password)
            window.localStorage.setItem('PlsProspectLoginPassword', prospect.password)

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
            })
            meta.onPlsSignUpRegistrationSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* activatePlsAmbassadorToChat(action) {
    const { userId, isActive } = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.toggleUserUniPermission(userId)
        })
        if (response.success) {
            yield put({
                type: ACCOUNT.ACTIVATE_PLS_AMBASSADOR_TO_CHAT_SUCCESS,
                payload: isActive,
            })

            const accountInfo = JSON.parse(window.localStorage.getItem('AccountInfo'))

            const updatedAccountInfo = {
                ...accountInfo,
                plsWizardSetup: {
                    ...accountInfo.plsWizardSetup,
                    ambassador: {
                        ...accountInfo.plsWizardSetup.ambassador,
                        isAmbassadorActivated: isActive,
                    },
                },
            }
            window.localStorage.setItem('AccountInfo', JSON.stringify(updatedAccountInfo))
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_LOGIN_FAILED,
        })
    }
}

export function* getUniversitySsoData(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.account.getUniversitySsoData(payload.email)
        })
        if (response.success && response.data) {
            action.onSuccess(response.data.appDomain, response.data.appClientId, response.data.isEncryptionOn)
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getPlsTapFeedLink(action) {
    const { payload, meta } = action

    try {
        const response = yield call(() => {
            return API.account.getPlsTapFeedLink(payload)
        })
        if (response.success) {
            yield put({
                type: ACCOUNT.GET_PLS_TAP_FEED_LINK_SUCCESS,
                payload: payload.slug,
            })

            meta.onGetPlsTapFeedLinkSuccess(payload.slug)

            const accountInfo = JSON.parse(window.localStorage.getItem('AccountInfo'))

            const updatedAccountInfo = {
                ...accountInfo,
                university: {
                    ...accountInfo.university,
                    slug: payload.slug,
                },
            }
            window.localStorage.setItem('AccountInfo', JSON.stringify(updatedAccountInfo))
        }
    } catch (error) {
        console.log(error)
    }
}

export function* changeOrganisationWebsite(action) {
    const { payload, meta } = action

    try {
        const response = yield call(() => {
            return API.account.changeOrganisationWebsite(payload)
        })
        if (response.success) {
            yield put({
                type: ACCOUNT.CHANGE_ORGANISATION_WEBSITE_SUCCESS,
                payload: payload.websiteUrl,
            })

            meta.onSuccess && meta.onSuccess()
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: ACCOUNT.CHANGE_ORGANISATION_WEBSITE_FAILED,
        })
    }
}

export function* getProfileTypes() {
    try {
        const response = yield call(() => {
            return API.account.getProfileTypes()
        })
        if (response.success) {
            yield put({
                type: ACCOUNT.GET_PROFILE_TYPES_SUCCESS,
                payload: response.data,
            })
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: ACCOUNT.GET_PROFILE_TYPES_FAILED,
        })
    }
}

export function* setProfileType(action) {
    const { payload, meta } = action
    const account = yield select(getAccountState)
    try {
        const response = yield call(() => {
            return API.account.setProfileType(payload)
        })
        if (response.success) {
            yield put({
                type: ACCOUNT.SET_PROFILE_TYPE_SUCCESS,
                payload,
            })
            meta.onSuccess && meta.onSuccess()

            const accountInfo = JSON.parse(window.localStorage.getItem('AccountInfo'))

            const updatedAccountInfo = {
                ...accountInfo,
                profileType: account.profileTypes.find((item) => item.id === payload.profileTypeId),
            }
            window.localStorage.setItem('AccountInfo', JSON.stringify(updatedAccountInfo))
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: ACCOUNT.GET_PROFILE_TYPES_FAILED,
        })
    }
}

export function* experienceBasedQuestionsToggle(action) {
    const { isAllowed, allowedType } = action.payload
    const isGroupAdmin = yield select(getGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

    const requestPayload = {
        allowedType,
    }

    if (isGroupAdmin && currentGroupUniversityId) {
        requestPayload.universityId = currentGroupUniversityId
    }
    try {
        const response = yield call(() => {
            return API.account.experienceBasedQuestionsToggle(requestPayload)
        })
        if (response.success) {
            yield put({
                type: ACCOUNT.SET_EXPERIANCE_BASED_QUESTIONS_TOGGLE_SUCCESS,
                payload: { isAllowed, allowedType },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* OTPVerificationToggle(action) {
    const { isAllowed } = action.payload
    const isGroupAdmin = yield select(getGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

    const requestPayload = {}

    if (isGroupAdmin && currentGroupUniversityId) {
        requestPayload.universityId = currentGroupUniversityId
    }
    try {
        const response = yield call(() => {
            return API.account.OTPVerificationToggle(requestPayload)
        })
        if (response.success) {
            yield put({
                type: ACCOUNT.SET_OTP_VERIFICATION_TOGGLE_SUCCESS,
                payload: { isAllowed },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* thirdPartyAuthToggle(action) {
    const { isEnabled, permissionKey } = action.payload
    const isGroupAdmin = yield select(getGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

    const requestPayload = { isEnabled, permissionKey }

    if (isGroupAdmin && currentGroupUniversityId) {
        requestPayload.universityId = currentGroupUniversityId
    }
    try {
        const response = yield call(() => {
            return isGroupAdmin && !currentGroupUniversityId
                ? API.account.thirdPartyAuthGroupToggle(requestPayload)
                : API.account.thirdPartyAuthToggle(requestPayload)
        })
        if (response.success) {
            yield put({
                type: ACCOUNT.SET_PERMISSON_TOGGLE_SUCCESS,
                payload: { isEnabled, permissionKey },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* flagsToggle(action) {
    const { isEnabled, permissionKey } = action.payload
    const isGroupAdmin = yield select(getGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

    const requestPayload = { isEnabled, permissionKey }

    if (isGroupAdmin && currentGroupUniversityId) {
        requestPayload.universityId = currentGroupUniversityId
    }
    try {
        const response = yield call(() => {
            return isGroupAdmin && !currentGroupUniversityId
                ? API.account.flagsGroupToggle(requestPayload)
                : API.account.flagsToggle(requestPayload)
        })
        if (response.success) {
            yield put({
                type: ACCOUNT.SET_PERMISSON_TOGGLE_SUCCESS,
                payload: { isEnabled, permissionKey },
            })
        }
    } catch (error) {
        console.log(error)
    }
}
