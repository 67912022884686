import React from 'react'
import { Row, Col, Typography } from 'antd'

const { Text } = Typography

const Toggle = ({ id, name, keyName, checked, disableToggle, brightName, handleToggleNotification }) => {
    return (
        <Col className="notification-control-form-row">
            <Col style={{ maxWidth: '80%' }}>
                <Row>
                    <h4 className={!brightName ? 'notification-name' : 'toggle-block-name'}>{name}</h4>
                    {keyName === 'prospect_messaging_activity' && (
                        <Text style={{ fontSize: 14 }}>
                            If set, you will be notified in case Prospect reaches number of Ambassadors addressed within
                            time. Please, see details in 'Safeguarding' part of 'Settings'.
                        </Text>
                    )}
                </Row>
            </Col>

            <label className="slideOne js-holder">
                <input
                    type="checkbox"
                    name={keyName}
                    checked={checked}
                    disabled={disableToggle}
                    onChange={(e) => handleToggleNotification(e, id)}
                />
                <span className="slideOne-icon"></span>
            </label>
        </Col>
    )
}

const NotificationToggles = ({
    account,
    notifications,
    disableToggle,
    handleToggleNotification,
    handleReceivingOfNotifications,
    receiveNotifications,
}) => {
    let reportToggle
    const ambassadorToggles = []
    const prospectToggles = []
    notifications.map((toggle) => {
        switch (toggle.type) {
            case 'report_toggle':
                if (account.account_info && !account.account_info.isGroupAdmin) {
                    reportToggle = toggle
                }
                break
            case 'ambassador_toggle':
                ambassadorToggles.push(toggle)
                break
            case 'prospect_toggle':
                prospectToggles.push(toggle)
                break
            default:
                break
        }
    })

    return (
        <>
            <div className="notifications-toggle-block">
                <Toggle
                    name="Receive notifications"
                    keyName="receive_notifications"
                    checked={receiveNotifications}
                    disableToggle={disableToggle}
                    brightName={true}
                    handleToggleNotification={handleReceivingOfNotifications}
                />
            </div>
            {receiveNotifications && notifications.length > 0 ? (
                <>
                    <div className="notifications-toggle-block">
                        {reportToggle && (
                            <Toggle
                                id={reportToggle.id}
                                name={reportToggle.name}
                                keyName={reportToggle.key}
                                checked={reportToggle.is_active}
                                disableToggle={disableToggle}
                                brightName={true}
                                handleToggleNotification={handleToggleNotification}
                                key={reportToggle.id}
                            />
                        )}
                    </div>
                    <div className="notifications-toggle-block">
                        <h4 className="toggle-block-name header">Notifications related to Ambassadors</h4>
                        {ambassadorToggles.map((item) => {
                            return (
                                <Toggle
                                    id={item.id}
                                    name={item.name}
                                    keyName={item.key}
                                    checked={item.is_active}
                                    disableToggle={disableToggle}
                                    handleToggleNotification={handleToggleNotification}
                                    key={item.id}
                                />
                            )
                        })}
                    </div>
                    <div className="notifications-toggle-block">
                        <h4 className="toggle-block-name header">Notifications related to Prospects</h4>
                        {prospectToggles.map((item) => {
                            return (
                                <Toggle
                                    id={item.id}
                                    name={item.name}
                                    keyName={item.key}
                                    checked={item.is_active}
                                    disableToggle={disableToggle}
                                    handleToggleNotification={handleToggleNotification}
                                    key={item.id}
                                />
                            )
                        })}
                    </div>
                </>
            ) : null}
        </>
    )
}

export default NotificationToggles
