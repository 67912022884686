import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import { Card } from 'antd'
import Modal from 'antd/es/modal'

import AmbassadorsCharts from './AmbassadorsCharts'

import DatesIntervalSelector from '../components/DatesIntervalSelector'
import ChartDropdown from './components/ChartDropdown'
import SearchInput from '../components/SearchInput'
import ExportButtons from './components/ExportButtons'
import AmbassadorsAvatars from './components/AmbassadorsAvatars'
import AmbassadorReportTable from './components/ambassador-report-table/ambassador-report-table'
import AmbassadorInfoPopup from '../../../components/popups/ambassador-info-popup/AmbassadorInfoPopup'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

import {
    fetchAmbasadorsDiagrams,
    fetchAmbasadorsRep,
    setAmbassadorFilters,
    changeAmbassadorIntroduction,
    changeAmbassadorDescription,
    setToInitialState,
    closeAmbassadorModal,
} from '../../../redux/actions/content/ambassadorsActions'

import { getDateRange } from '../../../helpers/time'

class ReportingAmbassadors extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedButton: '30Days',
            selectedDateRange: getDateRange(30),
            selectedDiagram: 'responseTime',
            search: '',
            userOpenModal: false,
            isAllSelected: true,
            isAllUnselected: false,
            userId: '',
            areSelectedAll: true,
            selectedAmbasadorIds: new Set(),
            excludedAmbasadorIds: new Set(),
        }

        this.onDatesIntervalSelectorUpdate = this.onDatesIntervalSelectorUpdate.bind(this)
        this.onDropdownChange = this.onDropdownChange.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.handleCloseUserPopup = this.handleCloseUserPopup.bind(this)
        this.onSelectChange = this.onSelectChange.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'reporting',
            },
        })
        this.props.fetchAmbasadorsDiagrams(this.state.selectedDateRange, null)
        this.props.setAmbassadorFilters(this.state.selectedDateRange)
    }

    componentWillUnmount() {
        store.dispatch(setToInitialState([]))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.setState({
                areSelectedAll: true,
                selectedAmbasadorIds: new Set(),
                excludedAmbasadorIds: new Set(),
            })
            this.props.fetchAmbasadorsDiagrams(this.state.selectedDateRange)
            this.props.setAmbassadorFilters({
                ...this.state.selectedDateRange,
                universityIds: this.props.account.currentGroupUniversityId,
            })
        }
    }

    onDatesIntervalSelectorUpdate(selectedButton, selectedDateRange) {
        this.setState({
            selectedButton,
            selectedDateRange,
            areSelectedAll: true,
            selectedAmbasadorIds: new Set(),
            excludedAmbasadorIds: new Set(),
        })

        this.props.fetchAmbasadorsDiagrams(selectedDateRange)

        this.props.setAmbassadorFilters({
            ...selectedDateRange,
            search: this.state.search,
        })
    }

    onDropdownChange(selectedDiagram) {
        this.setState({
            selectedDiagram: selectedDiagram.key,
        })
    }

    onSearch(searchData) {
        this.props.setAmbassadorFilters({
            ...this.state.selectedDateRange,
            search: searchData,
        })
        this.props.fetchAmbasadorsDiagrams({ ...this.state.selectedDateRange })
        this.setState({
            search: searchData,
            selectedAmbasadorIds: new Set(),
            excludedAmbasadorIds: new Set(),
        })
    }

    onSelectChange(addedItems, removedItems, areSelectedAll) {
        if (areSelectedAll !== undefined) {
            this.setState({
                areSelectedAll,
                selectedAmbasadorIds: new Set(),
                excludedAmbasadorIds: new Set(),
            })

            this.props.fetchAmbasadorsDiagrams(this.state.selectedDateRange, areSelectedAll ? null : [])

            return
        }

        const excludedAmbasadorIds = this.state.excludedAmbasadorIds
        const selectedAmbasadorIds = this.state.selectedAmbasadorIds

        if (Array.isArray(addedItems)) {
            for (const item of addedItems) {
                selectedAmbasadorIds.add(item)
                excludedAmbasadorIds.delete(item)
            }
        }

        if (Array.isArray(removedItems)) {
            for (const item of removedItems) {
                excludedAmbasadorIds.add(item)
                selectedAmbasadorIds.delete(item)
            }
        }

        this.setState({ selectedAmbasadorIds, excludedAmbasadorIds })

        this.props.fetchAmbasadorsDiagrams(
            this.state.selectedDateRange,
            this.state.areSelectedAll ? null : [...selectedAmbasadorIds],
            this.state.areSelectedAll ? [...excludedAmbasadorIds] : null
        )
    }

    handleCloseUserPopup() {
        this.props.closeAmbassadorModal()
    }

    userAvatar({ avatar, full_name: fullName }, avatarSize = '') {
        if (avatar) {
            const avatarSrc =
                avatarSize === 'smallAvatar'
                    ? avatar.sizes['70x70'] || avatar.original
                    : avatar.sizes['160x160'] || avatar.original

            return <img className="table-item-img" src={avatarSrc} alt="img" />
        } else {
            return (
                <div
                    className={`table-item-img table-item-img-name ${avatarSize === '' && 'table-item-img-name--big'}`}
                >
                    {fullName}
                </div>
            )
        }
    }

    generateSelectedNumber() {
        return this.state.areSelectedAll
            ? this.props.ambassadorsTotal - this.state.excludedAmbasadorIds.size
            : this.state.selectedAmbasadorIds.size
    }

    generateExportSelectedCountLabel(selectedCount) {
        if (!this.props.ambassadorsReport) {
            return ''
        }

        if (
            this.props.ambassadorsTotal > 0 &&
            ((!this.state.areSelectedAll && this.state.selectedAmbasadorIds.size === this.props.ambassadorsTotal) ||
                (this.state.areSelectedAll && this.state.excludedAmbasadorIds.size === 0))
        ) {
            return ' (All)'
        }

        return ' (' + selectedCount + ')'
    }

    render() {
        const {
            changeAmbassadorIntroduction,
            changeAmbassadorDescription,
            ambassadorsReport,
            account,
            ambassadorsDiagram,
            ambassadorModal,
            ambassadorsTotal,
        } = this.props
        const { university } = account.account_info
        const selectedCount = this.generateSelectedNumber()
        const selectedNumberLabel = this.generateExportSelectedCountLabel(selectedCount)

        return (
            <section className="content reporting ambassador-reporting">
                <div className="prospect-table-title">Ambassador Report</div>
                <Card className="reporting-block" style={{ paddingHorizontal: 50 }}>
                    <div className="diagram-wrapper">
                        <div className="report-diagram-header">
                            <ChartDropdown
                                onChange={this.onDropdownChange}
                                selectedDiagram={this.state.selectedDiagram}
                            />
                            <DatesIntervalSelector
                                selectedButton={this.state.selectedButton}
                                fromDate={this.state.selectedDateRange.fromDate}
                                toDate={this.state.selectedDateRange.toDate}
                                onChange={this.onDatesIntervalSelectorUpdate}
                            />
                        </div>
                        <div className="diagram">
                            <AmbassadorsCharts
                                data={!this.state.isAllUnselected ? ambassadorsDiagram : []}
                                fromDate={this.state.selectedDateRange.fromDate}
                                toDate={this.state.selectedDateRange.toDate}
                                selectedDiagram={this.state.selectedDiagram}
                            />
                        </div>
                    </div>
                    <AmbassadorsAvatars
                        data={ambassadorsReport}
                        selected={this.state.selectedAmbasadorIds}
                        unselected={this.state.excludedAmbasadorIds}
                        isAllSelected={this.state.areSelectedAll}
                        ambassadorsTotal={ambassadorsTotal}
                        selectedCount={selectedCount}
                    />
                    <div className="search-wrapper ambassadors-search-wrapper">
                        <SearchInput onChange={this.onSearch} placeholder="Search for ambassadors" width={400} />
                        <ExportButtons
                            dateRanges={this.state.selectedDateRange}
                            selected={this.state.selectedAmbasadorIds}
                            unselected={this.state.excludedAmbasadorIds}
                            isAllSelected={this.state.areSelectedAll}
                            selectedCount={selectedCount}
                            selectedNumber={selectedNumberLabel}
                            search={this.state.search}
                        />
                    </div>
                    <AmbassadorReportTable
                        data={ambassadorsReport}
                        onSelect={this.onSelectChange}
                        areSelectedAll={this.state.areSelectedAll}
                        selected={this.state.selectedAmbasadorIds}
                        unselected={this.state.excludedAmbasadorIds}
                    />
                    <div>
                        <Modal
                            open={ambassadorModal.open}
                            footer={null}
                            closable={false}
                            maskClosable={true}
                            onCancel={this.handleCloseUserPopup}
                            className="user-profile-modal"
                        >
                            <AmbassadorInfoPopup
                                userId={this.state.userId}
                                university={university.name}
                                user={ambassadorModal.data}
                                userAvatar={this.userAvatar}
                                handleChangeIntroduction={changeAmbassadorIntroduction}
                                handleChangeDescription={changeAmbassadorDescription}
                                handleCloseUserPopup={this.handleCloseUserPopup}
                                isReporting={true}
                            />
                        </Modal>
                    </div>
                </Card>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    ambassadorsDiagram: state.ambassadors.ambassadorsDiagrams,
    ambassadorsReport: state.ambassadors.ambassadorReport,
    ambassadorModal: state.ambassadors.ambassadorModal,
    ambassadorsTotal: state.ambassadors.total,
})

export default connect(mapStateToProps, {
    fetchAmbasadorsDiagrams,
    fetchAmbasadorsRep,
    setAmbassadorFilters,
    changeAmbassadorIntroduction,
    changeAmbassadorDescription,
    closeAmbassadorModal,
})(ReportingAmbassadors)
