import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getEventStreamSlice } from '../../../../../../../../../redux/selectors/common-selectors'
import ScreenInfo from './screen-info'
import ScreenVideo from './screen-video'

const ScreenView = () => {
    const { participants, currentPresenterId } = useSelector(getEventStreamSlice)

    const participant = useMemo(() => {
        const presenter = participants.find(({ id }) => id === currentPresenterId)

        if (!presenter) return null

        return presenter
    }, [participants, currentPresenterId])

    return (
        participant && (
            <div className="tap-event-stream-screen-view">
                <div className="participant-card">
                    <div className="participant-card-content">
                        <ScreenVideo view={participant.screenView} />
                        <ScreenInfo participant={participant} />
                    </div>
                </div>
            </div>
        )
    )
}

export default ScreenView
