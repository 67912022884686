var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { ChartCard } from './ChartCard';
import ChartHeader from '../../components/ChartHeader';
import { Spin } from 'antd';
import AreaChart from '../../components/AreaChart';
import { useGetFirstResponseTimeQuery } from 'src/app/redux/slices/reporting/idp-conversion-reporting/query';
import { responseTimeTooltipFormatter, yAxisResponseTimeChart } from '../helpers';
import { useAppSelector } from 'src/app/hooks/store';
import { selectActiveUnivercityId } from 'src/app/redux/selectors/account-selectors';
export var FirstResponseTimeChart = function (_a) {
    var filter = _a.filter;
    var universityId = useAppSelector(selectActiveUnivercityId);
    var _b = useGetFirstResponseTimeQuery({
        filter: filter,
        universityIds: universityId ? [universityId] : null,
    }), responseTimeData = _b.data, isFetching = _b.isFetching;
    return (React.createElement(ChartCard, null,
        React.createElement(ChartHeader, { title: "Median First Response Time", label: !isFetching && responseTimeData ? responseTimeData.average : 'No data', labelDescription: "Median First Response Time (across date range)", displaySingleLabel: true }),
        isFetching ? (React.createElement(Spin, { size: "large", className: "flex justify-center mt-20" })) : (React.createElement(AreaChart, { color: "#4e607e", data: responseTimeData ? __spreadArray([], responseTimeData.data, true) : [], tooltipFormatter: responseTimeTooltipFormatter, yAxisFormatter: yAxisResponseTimeChart, height: 200 }))));
};
