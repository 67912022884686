import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../redux/actions/content/componentsActions'
import { MODAL_TYPE } from './variables'

import {
    EventFormModal,
    UsersFormModal,
    StreamTypeModal,
    ScheduledEventFormModal,
    AmbassadorsFormModal,
    SpeakersFormModal,
    PublishControlModal,
    UploadVideoModal,
    ModalWrapper,
} from './components'
import { streamsTypeRootScreen } from './screens'

import ProspectInfoPopup from '../../components/popups/ProspectInfoPopup'

import { CHAT_TYPE_KEY } from '../../common/constants'

const LiveStreamsScreen = () => {
    const dispatch = useDispatch()
    const { path } = useRouteMatch()

    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'streams',
            },
        })
    }, [dispatch])

    return (
        <div className="upcoming-events">
            <div className="content">
                <Route path={`${path}/:param`} component={streamsTypeRootScreen} />
            </div>
            <ModalWrapper name={MODAL_TYPE.EVENT_MODAL} component={EventFormModal} />
            <ModalWrapper name={MODAL_TYPE.USERS_MODAL} component={UsersFormModal} />
            <ModalWrapper name={MODAL_TYPE.TYPE_MODAL} component={StreamTypeModal} />
            <ModalWrapper name={MODAL_TYPE.SHEDULED_EVENT_MODAL} component={ScheduledEventFormModal} />
            <ModalWrapper name={MODAL_TYPE.AMBASSADORS_MODAL} component={AmbassadorsFormModal} />
            <ModalWrapper name={MODAL_TYPE.SPEAKERS_MODAL} component={SpeakersFormModal} />
            <ModalWrapper name={MODAL_TYPE.PUBLISH_CONTENT_MODAL} component={PublishControlModal} />
            <ModalWrapper name={MODAL_TYPE.VIDEO_RE_UPLOAD_MODAL} component={UploadVideoModal} />

            <ProspectInfoPopup prospectInfoChat={CHAT_TYPE_KEY.LIVE_STREAM_CHAT} />
        </div>
    )
}

export default LiveStreamsScreen
