import { GET_API_KEY_SUCCESS, GENERATE_API_KEY_SUCCESS } from '../../actions/settings/crmActions'
import { CRM } from '../../store/initialState'
const initialState = CRM;

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_API_KEY_SUCCESS: {
            return payload;
        }

        case GENERATE_API_KEY_SUCCESS: {
            return payload;
        }

        default: {
            return state;
        }
    }
}
