import { HttpService } from '../../system/services/HttpService';
export default {
    getCommunities: function (_a) {
        var _b = _a.limit, limit = _b === void 0 ? 20 : _b, _c = _a.offset, offset = _c === void 0 ? 0 : _c, universityIds = _a.universityIds, _d = _a.filters, filters = _d === void 0 ? {} : _d, withGlobalTags = _a.withGlobalTags;
        var data = {
            limit: limit,
            offset: offset,
            filter: filters,
        };
        if (withGlobalTags) {
            data.withGlobalTags = withGlobalTags;
        }
        if (universityIds) {
            data.universityIds = universityIds;
        }
        return HttpService.get('/dashboard/communities/chats', data);
    },
    getCommunity: function (_a) {
        var id = _a.id;
        return HttpService.get("/dashboard/communities/chats/".concat(id));
    },
    createCommunity: function (data) {
        return HttpService.formData('/dashboard/communities/chats', data);
    },
    updateCommunityMembers: function (data) {
        return HttpService.put("/dashboard/communities/chats/".concat(data.dialog_id, "/ambassadors"), data);
    },
    updateCommunity: function (id, data) {
        return HttpService.formData("/dashboard/communities/chats/".concat(id), data, false);
    },
    uploadCommunityMembers: function (data) {
        return HttpService.formData('/dashboard/communities/members', data);
    },
    getCommunitiesMembers: function (_a) {
        var _b = _a.limit, limit = _b === void 0 ? 10 : _b, _c = _a.offset, offset = _c === void 0 ? 0 : _c, _d = _a.order, order = _d === void 0 ? 'asc' : _d, _e = _a.orderBy, orderBy = _e === void 0 ? 'created_at' : _e, universityId = _a.universityId, withGlobalTags = _a.withGlobalTags;
        var data = {
            limit: limit,
            offset: offset,
            order: order,
            orderBy: orderBy,
        };
        if (universityId) {
            data.universityId = universityId;
        }
        if (withGlobalTags) {
            data.withGlobalTags = withGlobalTags;
        }
        return HttpService.get('/dashboard/communities/members', data);
    },
    getCommunitiesSettings: function (data) {
        return HttpService.get('/dashboard/communities/settings', data);
    },
    updateCommunityVisibility: function (id, isHidden) {
        return HttpService.put("/dashboard/communities/chats/".concat(id, "/visibilityToggle"), {
            isHidden: isHidden,
        });
    },
    reporting: {
        getOverviewChardData: function (data) {
            return HttpService.get('/dashboard/reporting/community/overview', data);
        },
        getConnectionsData: function (data) {
            return HttpService.get('/dashboard/reporting/community/newChats', data);
        },
        getNumberOfMessagesData: function (data) {
            return HttpService.get('/dashboard/reporting/community/newMessages', data);
        },
        getNationalitiesData: function (data) {
            return HttpService.get('/dashboard/reporting/community/prospectNationalities', data);
        },
    },
};
