import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Sprite from '../../../../../../assets/img/symbol/sprite.svg'
import { GET_TOPICS_LIST, ADD_TOPICS } from '../../../../../redux/actions/chat/topicsActions'
import Select from 'antd/es/select'
import Spin from 'antd/es/spin'

const getTopicsToShow = (currentList, topics, chatTopics) => {
    let topicsToShow = []
    const checkAddedList = [...currentList]
    chatTopics.map((topic) => {
        if (!checkAddedList.includes(topic.name)) {
            checkAddedList.push(topic.name)
        }
    })
    if (checkAddedList.length > 0) {
        for (const topic of topics.items) {
            if (!checkAddedList.includes(topic.name)) {
                topicsToShow.push(topic)
            }
        }
    } else {
        topicsToShow = topics.items
    }
    return topicsToShow
}

const TopicsAddPopup = ({ chatTopics, dialogMessages, dialogId, handleCloseTopicsPopup }) => {
    const topics = useSelector((state) => state.topics)
    const dispatch = useDispatch()
    const [activeTopic, setActiveTopic] = useState('')
    const [currentList, setCurrentList] = useState([])
    const [error, setError] = useState(false)

    useEffect(() => {
        dispatch({
            type: GET_TOPICS_LIST,
            payload: {
                limit: topics.limit,
                offset: 0,
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const topicsToShow = getTopicsToShow(currentList, topics, chatTopics)

    const handleScroll = (event) => {
        const target = event.target
        if (
            !topics.isLoading &&
            target.scrollTop + target.offsetHeight + 60 >= target.scrollHeight &&
            topics.total > topics.offset + topics.limit
        ) {
            dispatch({
                type: GET_TOPICS_LIST,
                payload: {
                    limit: topics.limit,
                    offset: topics.offset + topics.limit,
                    search: activeTopic,
                    noLoading: true,
                },
            })
        }
    }

    const searchTopics = (value) => {
        let topicFound = false
        chatTopics.map((topic) => {
            if (topic.name.toUpperCase() === value.trim().toUpperCase()) {
                topicFound = true
            }
        })

        if (topicFound) {
            setActiveTopic(value)
            setError(true)
        } else {
            setActiveTopic(value)
            setError(false)

            dispatch({
                type: GET_TOPICS_LIST,
                payload: {
                    limit: topics.limit,
                    offset: 0,
                    search: value,
                },
            })
        }
    }

    const addTopic = (newTopic) => {
        let topicFound = false
        chatTopics.map((topic) => {
            if (topic.name.toUpperCase() === newTopic.toUpperCase()) {
                topicFound = true
            }
        })
        if (!topicFound && currentList.length > 0) {
            currentList.map((topic) => {
                if (topic.toUpperCase() === newTopic.toUpperCase()) {
                    topicFound = true
                }
            })
        }

        if (topicFound) {
            setError(true)
        } else {
            setActiveTopic('')
            setError(false)
            setCurrentList([...currentList, newTopic])

            dispatch({
                type: GET_TOPICS_LIST,
                payload: {
                    limit: topics.limit,
                    offset: 0,
                },
            })
        }
    }

    const saveTopics = () => {
        if (currentList.length === 0) return
        // eslint-disable-next-line unicorn/consistent-function-scoping
        const afterSuccess = () => {
            handleCloseTopicsPopup(true)
        }

        dispatch({
            type: ADD_TOPICS,
            payload: {
                dialogId: dialogId,
                topics: currentList,
                lastMessageId:
                    dialogMessages && dialogMessages.length > 0 ? dialogMessages[dialogMessages.length - 1].id : 0,
            },
            afterSuccess,
        })
    }

    const setTopics = (topics) => {
        dispatch({
            type: GET_TOPICS_LIST,
            payload: {
                limit: topics.limit,
                offset: 0,
            },
        })

        setActiveTopic('')
        setError(false)
        setCurrentList(topics)
    }

    return (
        <div className="modal-topics">
            <button type="button" className="close-modal closer-button" onClick={() => handleCloseTopicsPopup(false)}>
                <svg className="icon icon-close">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#close'}></use>
                </svg>
            </button>
            <div className="add-topics-block position-relative">
                <p className="topics-modal-title">Add new topic</p>
                <p className="topics-modal-text">
                    If you notice a topic that has not been detected, please add it below
                </p>
                <p className="topics-input-title">Add new topic</p>
                <Select
                    mode="multiple"
                    value={currentList}
                    onChange={setTopics}
                    onClick={(e) => e.preventDefault()}
                    clearIcon={true}
                    bordered={false}
                    onSearch={searchTopics}
                    searchValue={activeTopic}
                    placeholder="Select or enter topic"
                    onInputKeyDown={(e) => e.key === 'Enter' && addTopic(e.target.value)}
                    onPopupScroll={handleScroll}
                    notFoundContent={topics.isLoading ? <Spin size="small" /> : null}
                    className="topics-select"
                    popupClassName="topics-dropdown"
                >
                    {topicsToShow.length > 0 &&
                        !error &&
                        !topics.isLoading &&
                        topicsToShow.map((item) => (
                            <Select.Option key={item.name} value={item.name}>
                                {item.name + (item.is_default ? ' (default)' : '')}
                            </Select.Option>
                        ))}
                </Select>
                {error && <p className="topic-error">This topic is already among the detected ones</p>}
                <button type="button" onClick={saveTopics} className="el-btn save-topics-btn">
                    Save
                </button>
            </div>
        </div>
    )
}

export default TopicsAddPopup
