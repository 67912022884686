import { Input } from 'antd'
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { endOfDay, isBefore } from 'date-fns'

import SelectAmbassadorsForm from '../../../components/SelectAmbassadorsForm'
import DatePicker from '../../../components/date-picker/date-picker'
import TagsMultiInput from '../../../components/TagsMultiInput'

import { parseDateFromServer } from '../../../common/utils/date-time-utils'

const renderField = ({
    input,
    handleChange,
    id,
    accept,
    value,
    placeholder,
    type,
    className,
    maxLength,
    meta: { touched, error, warning },
}) => (
    <div>
        <Input
            {...input}
            className={className}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            accept={accept}
            id={id}
            maxLength={maxLength}
        />
        {touched &&
            ((error && <span className={'error ' + type}>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

const renderTextareaField = ({
    placeholder,
    type,
    id,
    className,
    minRows,
    maxRows,
    maxLength,
    handleChange,
    textLength,
}) => (
    <>
        <Input.TextArea
            rows={minRows}
            maxrows={maxRows}
            id={id}
            type={type}
            placeholder={placeholder}
            maxLength={maxLength}
            className={className}
            onChange={handleChange}
        />
        <span className={'panel-text-counter panel-text-counter' + (textLength < 10 ? '-invalid' : '-valid')}>
            {textLength}/250
        </span>
    </>
)

const renderDatePicker = ({ handleChange, className, defaultValue, onOpenChange, disabledDate }) => (
    <DatePicker
        onChange={handleChange}
        className={className}
        defaultValue={defaultValue}
        onOpenChange={onOpenChange}
        disabledDate={(currentDate) => {
            const today = new Date()
            return currentDate && isBefore(currentDate, endOfDay(today.setDate(today.getDate() - 1)))
        }}
    />
)

class CreateContentGroupForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            description: '',
            avatar: [],
            globalTagsIds: [],
            // To pass data to form submit:
            selectedUsersNumber: 0,
            assignedUsers: {},
            file: null,
            contentGroupEventDate: props.initialValues.scheduledDay ? props.initialValues.scheduledDay : '',
        }

        if (this.props.initialValues.tags) {
            for (const item of this.props.initialValues.tags) {
                this.state.globalTagsIds.push(item.id)
            }
        }

        this.handleContentGroupNameChange = this.handleContentGroupNameChange.bind(this)
        this.handleContentGroupDescriptionChange = this.handleContentGroupDescriptionChange.bind(this)
        this.handleAvatarChange = this.handleAvatarChange.bind(this)
        this.handleGlobalTagsSelect = this.handleGlobalTagsSelect.bind(this)
        this.handleUserSelectionUpdated = this.handleUserSelectionUpdated.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleContentGroupDateChange = this.handleContentGroupDateChange.bind(this)
        this.handleDatepickerOpen = this.handleDatepickerOpen.bind(this)
    }

    handleContentGroupNameChange(e) {
        this.setState({
            name: e.target.value.trim(),
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleContentGroupDescriptionChange(e) {
        this.setState({
            description: e.target.value.trim(),
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleAvatarChange(event) {
        this.setState({
            avatar: event.target.files,
            file: URL.createObjectURL(event.target.files[0]),
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleGlobalTagsSelect(globalTagsIds) {
        this.setState({
            globalTagsIds: globalTagsIds,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleUserSelectionUpdated(assignedUsers, selectedUsersNumber, hasBeenUpdatedByUser) {
        this.setState({
            assignedUsers: assignedUsers,
            selectedUsersNumber: selectedUsersNumber,
        })

        if (hasBeenUpdatedByUser) {
            this.props.setFormUpdatedStatus(true)
        }
    }

    handleContentGroupDateChange(date) {
        this.setState({
            contentGroupEventDate: date,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleDatepickerOpen(open) {
        this.props.checkIfDatePickerOpen(open)
    }

    handleSubmit(event) {
        event.preventDefault()

        const { name, avatar, globalTagsIds, assignedUsers, description, contentGroupEventDate } = this.state

        const selectedUserIds = []

        for (const id in assignedUsers) {
            if (assignedUsers[id].selected) {
                selectedUserIds.push(id)
            }
        }

        const values = { name, description, avatar, globalTagsIds, selectedUserIds, contentGroupEventDate }

        this.props.onSubmit(values)
    }

    render() {
        const {
            ambassadors,
            areAmbassadorsLoading,
            onSearchUpdated,
            isCreateButtonDisabled,
            initialValues,
            isSсheduledContentGroup,
        } = this.props

        return (
            <form onSubmit={this.handleSubmit}>
                <div>
                    <div className="create-group-row">
                        <div className="w-70">
                            <p className="content-group-name">Name</p>
                            <Field
                                name="name"
                                component={renderField}
                                handleChange={this.handleContentGroupNameChange}
                                maxLength={100}
                                type="text"
                                placeholder="Type content group name"
                                className="ui-kit-input"
                            />
                        </div>
                        <div className="ml-auto mb-auto">
                            <div className="create-group-icon-label">
                                <div className="content-group-prview-block">
                                    <Field
                                        name="avatar"
                                        component={renderField}
                                        type="file"
                                        handleChange={this.handleAvatarChange}
                                        className="content-group-icon-input"
                                        id="content-group-icon-input"
                                        accept="image/x-png,image/jpeg,"
                                    />
                                    <p className="content-group-prview-text">Add</p>
                                    <img
                                        className={`content-group-prview ${this.state.file ? 'visible' : ''}`}
                                        src={this.state.file}
                                    />
                                </div>
                            </div>
                            <div className="content-group-name optional-icon">Icon (optional)</div>
                        </div>
                    </div>
                    <div className="w-100 position-relative">
                        <p className="content-group-name">Description</p>
                        <Field
                            name="description"
                            component={renderTextareaField}
                            textLength={this.state.description.length}
                            minRows={4}
                            maxLength={250}
                            handleChange={this.handleContentGroupDescriptionChange}
                            type="textarea"
                            placeholder="Type your message..."
                            className="ui-kit-textarea"
                        />
                    </div>
                    {isSсheduledContentGroup && (
                        <div className="content-group-date-picker-container">
                            <p className="content-group-name">Date</p>
                            <Field
                                name="date"
                                component={renderDatePicker}
                                handleChange={this.handleContentGroupDateChange}
                                onOpenChange={this.handleDatepickerOpen}
                                className="ui-kit-date-picker"
                                defaultValue={parseDateFromServer(initialValues.scheduledDay)}
                            />
                        </div>
                    )}
                </div>

                <TagsMultiInput
                    handleGlobalTags={this.handleGlobalTagsSelect}
                    globalTagsItems={initialValues.tags || []}
                />

                <SelectAmbassadorsForm
                    ambassadors={ambassadors}
                    areAmbassadorsLoading={areAmbassadorsLoading}
                    initialAssignedUsers={{}}
                    onSearchUpdated={onSearchUpdated}
                    onUserSelectionUpdated={this.handleUserSelectionUpdated}
                />

                <div className="hidden-buttons-block">
                    <button
                        type="submit"
                        className="el-btn create-group-btn"
                        disabled={
                            isCreateButtonDisabled || !this.state.selectedUsersNumber || this.state.name.length < 3
                        }
                    >
                        Create
                    </button>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'create_content_group_form',
})(CreateContentGroupForm)
