import React from 'react';
export var TapInviteTemplate = function (_a) {
    var usniversityName = _a.usniversityName, trainingCenterLink = _a.trainingCenterLink;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "Hello ",
            React.createElement("span", { className: "bg-color-selection/40" }, "[insert first name],")),
        React.createElement("p", null,
            "We want to give prospects an authentic insight into the experience of being part of\u00A0",
            React.createElement("span", { className: "bg-color-selection/40" }, usniversityName),
            " and we need your help!"),
        React.createElement("p", null, "We're inviting you to create a profile on The Ambassador Platform (TAP). Through TAP we'll be working with you to:"),
        React.createElement("p", null,
            React.createElement("ul", { className: "invite-list" },
                React.createElement("li", null, "Create user-generated content which can be shared across different marketing channels"),
                React.createElement("li", null, "Create answers to commonly-asked questions"),
                React.createElement("li", null, "Facilitate peer-to-peer conversations between yourself and prospects, either one to one or in a group setting"))),
        React.createElement("p", null,
            "To help you find out more about The Ambassador Platform and why we're using it, please take a look at this\u00A0",
            React.createElement("a", { href: "https://knowledge.theambassadorplatform.com/what-is-the-ambassador-platform", rel: "noopener noreferrer", target: "_blank", className: "orange-link" }, "brief overview"),
            "."),
        React.createElement("p", null, "In order to get set up, please do the following:"),
        React.createElement("p", null,
            React.createElement("ul", { className: "invite-list" },
                React.createElement("li", null,
                    "Visit the\u00A0",
                    React.createElement("a", { href: trainingCenterLink, rel: "noopener noreferrer", target: "_blank" }, "Ambassador Training Center"),
                    "\u00A0 to learn about how to carry out your work on The Ambassador Platform"),
                React.createElement("li", null,
                    "Download 'The Ambassador Platform' app from the\u00A0",
                    React.createElement("a", { href: "https://tap.st/ios", rel: "noopener noreferrer", target: "_blank" }, "App"),
                    "/",
                    React.createElement("a", { href: "https://tap.st/android", rel: "noopener noreferrer", target: "_blank" }, "Play"),
                    "\u00A0 Store"),
                React.createElement("li", null, "Create an account in the app using the code you received when you completed training"),
                React.createElement("li", null, "Complete your profile to the best of your ability"),
                React.createElement("li", null, "Take some time to watch the videos in the 'Learning Resources' area to learn more about the service"))),
        React.createElement("p", null, "I hope that makes sense and sounds fun. Let me know if you have any questions.")));
};
