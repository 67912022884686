import {
    OPEN_SHARE_POST_CONTENT_PREVIEW_MODAL,
    CLOSE_SHARE_POST_CONTENT_PREVIEW_MODAL,
} from '../../../actions/content/postsActions'

import { SHARED_POSTS_PREVIEW_MODAL } from '../../../store/initialState'

const initialState = SHARED_POSTS_PREVIEW_MODAL

export default function (state = initialState, action) {
    const type = action.type

    switch (type) {
        case OPEN_SHARE_POST_CONTENT_PREVIEW_MODAL: {
            let newState = {
                ...state,
                isOpened: true,
            }

            return newState
        }

        case CLOSE_SHARE_POST_CONTENT_PREVIEW_MODAL: {
            let newState = {
                ...state,
                isOpened: false,
            }

            return newState
        }

        default: {
            return state
        }
    }
}
