import { OPEN_CONFIRM_BAN_POPUP, CLOSE_CONFIRM_BAN_POPUP } from '../../../actions/content/usersActions'

import { CONFIRM_BAN_MODAL } from '../../../store/initialState'

const initialState = CONFIRM_BAN_MODAL

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload
    switch (type) {
        case OPEN_CONFIRM_BAN_POPUP: {
            let newState = {
                ...state,
                isOpened: true,
                userId: payload.user_id,
                ban_type: payload.ban_type,
            }

            return newState
        }

        case CLOSE_CONFIRM_BAN_POPUP: {
            let newState = {
                ...state,
                isOpened: false,
                userId: 0,
                ban_type: null,
            }

            return newState
        }

        default: {
            return state
        }
    }
}
