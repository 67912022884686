import {
    GET_SAFEGUARDING_DATA_SUCCESS,
    KEYWORD_DETECTION_SET_SUCCESS,
    SHOW_SAVE_BUTTON,
    DISABLE_BUTTON,
    CHANGE_PROSPECT_MESSAGING_ACTIVITY_SUCCESS,
} from '../../actions/settings/safeguardingActions'

const initialState = {
    customBadwords: [],
    defaultBadwords: [],
    keywordsCensore: false,
    whitelistedBadwords: [],
    showSaveBtn: false,
    disabledBtn: false,
    prospectsMessagingActivity: {
        isActivated: false,
        settings: {
            ambassadors: null,
            days: null,
        },
    },
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case GET_SAFEGUARDING_DATA_SUCCESS: {
            return {
                ...state,
                customBadwords: payload.customBadwords,
                defaultBadwords: payload.defaultBadwords,
                keywordsCensore: payload.keywordsCensore,
                whitelistedBadwords: payload.whitelistedBadwords,
                prospectsMessagingActivity: payload.prospectsMessagingActivity
                    ? payload.prospectsMessagingActivity
                    : state.prospectsMessagingActivity,
            }
        }
        case KEYWORD_DETECTION_SET_SUCCESS: {
            const newState = { ...state }
            newState.keywordsCensore = payload.keywords_censore
            return newState
        }
        case CHANGE_PROSPECT_MESSAGING_ACTIVITY_SUCCESS: {
            const newState = { ...state }
            newState.prospectsMessagingActivity = {
                isActivated: payload.isActivated,
                settings: {
                    ambassadors: payload.settings
                        ? payload.settings.numberOfAmbassadors
                        : state.prospectsMessagingActivity.settings.ambassadors,
                    days: payload.settings
                        ? payload.settings.numberOfDays
                        : state.prospectsMessagingActivity.settings.days,
                },
            }
            return newState
        }
        case SHOW_SAVE_BUTTON: {
            return {
                ...state,
                showSaveBtn: payload,
            }
        }
        case DISABLE_BUTTON: {
            return {
                ...state,
                disabledBtn: payload,
            }
        }

        default: {
            return state
        }
    }
}
