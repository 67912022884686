import { Form } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { changeOrganisationWebsite, completePlsStep } from '../../../redux/actions/account/account-actions'
import { getUniversityInfo } from '../../../redux/selectors'

export const useOrganizationDetailsForm = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { name: institutionName, avatar, website_url: websiteUrl } = useSelector(getUniversityInfo)

    const [institutionNameLength, setInstitutionNameLength] = useState(institutionName ? institutionName.length : 0)

    const [form] = Form.useForm()
    const initialValues = {
        organizationLogo: avatar?.original ? avatar.original : '',
        websiteUrl: websiteUrl,
    }

    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'setup-organization-details',
            },
        })
    }, [dispatch])

    const onSuccess = useCallback(() => {
        history.push('/setup/ambassador-self-invite')
    }, [history])

    const onInputChange = useCallback((e) => {
        setInstitutionNameLength(e.target.value.length)
    }, [])

    const onSubmit = useCallback(() => {
        form.validateFields().then((values) => {
            console.log('submitForm values :', values)

            if (!window.localStorage.getItem('setupWizardStep')) {
                window.localStorage.setItem('setupWizardStep', '2')
            }

            dispatch(changeOrganisationWebsite({ ...values, onSuccess }))
            dispatch(completePlsStep({ step: 'firstStepComplete' }))
        })
    }, [form, dispatch, onSuccess])

    return {
        form,
        onSubmit,
        onInputChange,
        institutionNameLength,
        initialValues,
    }
}
