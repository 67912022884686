const NAMESPACE_PREFIX = 'PLS_SIGN_UP'

export const PLS_SIGN_UP = {
    GET_APP_DOWNLOAD_LINK: `${NAMESPACE_PREFIX}.GET_APP_DOWNLOAD_LINK`,
    TOGGLE_AMBASSADOR_PERMISSIONS: `${NAMESPACE_PREFIX}.TOGGLE_AMBASSADOR_PERMISSIONS`,
    PLS_SETUP_WIZARD_FINISH: `${NAMESPACE_PREFIX}.PLS_SETUP_WIZARD_FINISH`,
    OPEN_SUCCESS_SIGN_UP_MODAL: `${NAMESPACE_PREFIX}.OPEN_SUCCESS_SIGN_UP_MODAL`,
    CLOSE_SUCCESS_SIGN_UP_MODAL: `${NAMESPACE_PREFIX}.CLOSE_SUCCESS_SIGN_UP_MODAL`,
    OPEN_ONBOARDING_PLS_MODAL: `${NAMESPACE_PREFIX}.OPEN_ONBOARDING_PLS_MODAL`,
    CLOSE_ONBOARDING_PLS_MODAL: `${NAMESPACE_PREFIX}.CLOSE_ONBOARDING_PLS_MODAL`,
}
