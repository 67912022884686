import { MenuOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import { differenceInMinutes } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { LIVE_EVENT_STATUS } from '../../../../../../../common/constants'
import { parseDateFromServer } from '../../../../../../../common/utils/date-time-utils'
import {
    cancelLiveEventConfirm,
    closeChat,
    openAmbassadorsModal,
    openEventFormModal,
    openSpeakersModal,
    startLiveEventConfirm,
    stopLiveEventConfirm,
} from '../../../../../../../redux/actions/live-event/live-events-actions'

const canStartStream = true

const EventMenu = ({ activeEvent }) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const history = useHistory()

    const [startEventButtonVisible, setStartEventButtonVisible] = useState(true)
    const { status, fromDate, isStreamActive } = activeEvent

    useEffect(() => {
        const interval = setInterval(() => {
            const parsed = parseDateFromServer(fromDate)
            const now = new Date()
            const minutesBeforeEvent = differenceInMinutes(parsed, now)
            if (minutesBeforeEvent < 11) {
                setStartEventButtonVisible(true)
                clearInterval(interval)
            }
        }, 60_000)

        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleStartEventClick = useCallback(() => {
        const { id: eventId, name } = activeEvent
        setIsOpen(false)
        dispatch(startLiveEventConfirm({ id: eventId, name, history }))
    }, [dispatch, activeEvent, history])

    const handleEditEventClick = useCallback(() => {
        setIsOpen(false)
        dispatch(openEventFormModal(activeEvent))
    }, [dispatch, activeEvent])

    const handleCancelEventClick = useCallback(() => {
        const { id: eventId, name } = activeEvent
        setIsOpen(false)
        dispatch(cancelLiveEventConfirm({ id: eventId, name, history }))
    }, [dispatch, history, activeEvent])

    const handleManageAmbassadorsClick = useCallback(() => {
        setIsOpen(false)
        dispatch(openAmbassadorsModal(activeEvent))
    }, [dispatch, activeEvent])

    const handleManageSpeakersClick = useCallback(() => {
        setIsOpen(false)
        dispatch(openSpeakersModal(activeEvent))
    }, [dispatch, activeEvent])

    const handleStopStreamClick = useCallback(() => {
        const { id: eventId } = activeEvent
        setIsOpen(false)
        dispatch(stopLiveEventConfirm({ id: eventId }))
    }, [dispatch, activeEvent])

    const handleCloseChatClick = useCallback(() => {
        const { id: eventId } = activeEvent
        setIsOpen(false)
        dispatch(closeChat({ id: eventId, history }))
    }, [dispatch, activeEvent, history])

    const startEventButton = useMemo(
        () =>
            startEventButtonVisible &&
            canStartStream && (
                <p className="options-drop-item" onClick={handleStartEventClick}>
                    Start event
                </p>
            ),
        [startEventButtonVisible, handleStartEventClick]
    )

    const stopStreamButton = useMemo(
        () =>
            isStreamActive && (
                <p className="options-drop-item" onClick={handleStopStreamClick}>
                    Finish the event
                </p>
            ),
        [isStreamActive, handleStopStreamClick]
    )

    const closeChatButton = useMemo(
        () =>
            !isStreamActive && (
                <p className="options-drop-item" onClick={handleCloseChatClick}>
                    Close chat
                </p>
            ),
        [isStreamActive, handleCloseChatClick]
    )

    const content = useMemo(() => {
        return (
            <div className="options-drop-block">
                {status === LIVE_EVENT_STATUS.UPCOMING && <>{startEventButton}</>}

                {(status === LIVE_EVENT_STATUS.UPCOMING || status === LIVE_EVENT_STATUS.IN_PROGRESS) && (
                    <p className="options-drop-item" onClick={handleEditEventClick}>
                        Edit event
                    </p>
                )}
                {status === LIVE_EVENT_STATUS.UPCOMING && (
                    <p className="options-drop-item" onClick={handleCancelEventClick}>
                        Cancel event
                    </p>
                )}
                {(status === LIVE_EVENT_STATUS.UPCOMING || status === LIVE_EVENT_STATUS.IN_PROGRESS) && (
                    <>
                        <p className="options-drop-item" onClick={handleManageAmbassadorsClick}>
                            Manage ambassadors
                        </p>
                        <p className="options-drop-item" onClick={handleManageSpeakersClick}>
                            Manage speakers
                        </p>
                    </>
                )}
                {status === LIVE_EVENT_STATUS.IN_PROGRESS && (
                    <>
                        {stopStreamButton}
                        {closeChatButton}
                    </>
                )}
                {status === LIVE_EVENT_STATUS.PREVIOUS && (
                    <>
                        <p className="options-drop-item">Download stream video</p>
                        <p className="options-drop-item">Download live chat transcripts</p>
                    </>
                )}
            </div>
        )
    }, [
        status,
        handleEditEventClick,
        handleCancelEventClick,
        handleManageAmbassadorsClick,
        handleManageSpeakersClick,
        startEventButton,
        closeChatButton,
        stopStreamButton,
    ])

    const handleMenuClick = useCallback(() => {
        setIsOpen(true)
    }, [])

    const handleVisibleChange = useCallback((open) => {
        setIsOpen(open)
    }, [])

    return (
        <Popover
            placement="bottomRight"
            trigger="click"
            content={content}
            open={isOpen}
            onOpenChange={handleVisibleChange}
        >
            <Button type="text" onClick={handleMenuClick}>
                <MenuOutlined />
            </Button>
        </Popover>
    )
}

export default EventMenu
