import dayjs from 'dayjs'

export const prepareNumberDataWithAverage = (data, defaultValue) => {
    if (!data || data.length === 0) {
        return { data: defaultValue, averageNumber: 0, total: 0 }
    }

    let totalNumber = 0

    const preparedData = data.map((item) => {
        const number = Number(item.count)
        totalNumber += number
        return [Date.parse(item.date), number]
    })

    return {
        data: preparedData,
        averageNumber: Math.round(totalNumber / data.length),
        total: totalNumber,
    }
}

export const prepareSpecificNumberData = (data, fieldName, defaultValue) => {
    if (!data || data.length === 0) {
        return defaultValue
    }

    return data.map((item) => {
        return [Date.parse(item.date), Number(item[fieldName])]
    })
}

export const prepareTimeData = (data, defaultValue) => {
    if (!data || data.length === 0) {
        return defaultValue
    }

    return data.map((item) => {
        let seconds
        seconds = item.median.seconds ? item.median.seconds : 0
        if (item.median.minutes) {
            seconds += item.median.minutes * 60
        }
        if (item.median.hours) {
            seconds += item.median.hours * 3600
        }
        if (item.median.days) {
            seconds += item.median.days * 86_400
        }

        return [Date.parse(item.date), seconds]
    })
}

export const prepareAverageTimeData = (data, defaultValue) => {
    if (!data || !data.median) {
        return defaultValue
    }

    let result = ''

    let showMinutes = true
    let showSeconds = true
    if (data.median.days) {
        result += data.median.days + ' d'
        showMinutes = false
        showSeconds = false
    }

    if (data.median.hours) {
        result += ' ' + data.median.hours + ' h'
        showSeconds = false
    } else if (result !== '') {
        result += ' 0 h'
    }

    if (showMinutes) {
        if (data.median.minutes) {
            result += ' ' + data.median.minutes + ' min'
        } else if (result !== '') {
            result += ' 0 min'
        }
    }

    if (showSeconds) {
        if (data.median.seconds) {
            result += ' ' + data.median.seconds + ' seconds'
        } else if (result !== '') {
            result += ' 0 seconds'
        }
    }

    if (result === '') {
        return defaultValue
    }

    return result
}

export const prepareTotalMessagesData = (data, defaultValue) => {
    if (!data || !data.count) {
        return defaultValue
    }
    return data.count
}

export const numberTooltipFormatter = (date, value, text) => {
    return (
        '<table><tr><td style="text-align: center; font-size: 12px; color: #fff";>' +
        dayjs(date).format('D MMM, dddd') +
        '</td></tr><tr><td style="text-align: center; font-size: 14px; color: #fff"; font-weight: bold"><b>' +
        value +
        ' ' +
        text +
        '</b></td></tr></table>'
    )
}

export const timeTooltipFormatter = (date, value) => {
    let averageTime = ''

    let seconds = value
    let showMinutes = true
    let showSeconds = true

    if (seconds === 0) {
        averageTime = 'No data'
    }

    const days = Math.trunc(seconds / 86_400)
    if (days > 0) {
        averageTime += days + ' d'
        showMinutes = false
        showSeconds = false
    }
    seconds -= days * 86_400

    const hours = Math.trunc(seconds / 3600)
    if (hours > 0) {
        averageTime += ' ' + hours + ' h'
        showSeconds = false
    }
    seconds -= hours * 3600

    const minutes = Math.trunc(seconds / 60)
    if (showMinutes && minutes > 0) {
        averageTime += ' ' + minutes + ' min'
    }
    seconds -= minutes * 60

    if (showSeconds && seconds > 0) {
        averageTime += ' ' + seconds + ' seconds'
    }

    return (
        '<table><tr><td style="text-align: center; font-size: 12px; color: #fff";>' +
        dayjs(date).format('D MMM, dddd') +
        '</td></tr><tr><td style="text-align: center; font-size: 14px; color: #fff"; font-weight: bold"><b>' +
        averageTime +
        '</b></td></tr></table>'
    )
}

export const yAxisTimeFormatter = (value) => {
    let averageTime = ''

    let seconds = value
    const showMinutes = true

    const hours = Math.trunc(seconds / 3600)
    if (hours > 0) {
        averageTime += ' ' + hours + ' h'
    }
    seconds -= hours * 3600

    const minutes = Math.trunc(seconds / 60)
    if (showMinutes && minutes > 0) {
        averageTime += ' ' + minutes + ' min'
    }
    seconds -= minutes * 60

    if (value < 3600) {
        averageTime += ' ' + seconds + ' sec'
    }

    return averageTime
}

export const yAxisConversionChartFormatter = (value) => {
    return value + ' %'
}

export const pluralize = (val, word, plural = word + 's') => {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const _pluralize = (num, word, plural = word + 's') => ([1, -1].includes(Number(num)) ? word : plural)
    if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word])
    return _pluralize(val, word, plural)
}
