export var getAccountSlice = function (state) { return state.account; };
export var getLiveEventsSlice = function (state) { return state.liveEvents; };
export var getLiveStreamsSlice = function (state) { return state.liveStreams; };
export var getTimezonesSlice = function (state) { return state.timezones; };
export var getGlobalTagsSlice = function (state) { return state.globalTags; };
export var getNotificationModalSlice = function (state) { return state.notificationModal; };
export var getUsersMessagingSlice = function (state) { return state.usersMessaging; };
export var getChatMessagesSlice = function (state) { return state.chatMessages; };
export var getEventStreamSlice = function (state) { return state.eventStream; };
export var getPlsSignUpSlice = function (state) { return state.plsSignUp; };
export var getCountriesSlice = function (state) { return state.countries; };
export var getUsersSlice = function (state) { return state.users; };
export var getAmbassadorsSlice = function (state) { return state.ambassadors; };
export var getCampaignsSlice = function (state) { return state.campaign; };
export var getChangeEmailModalSlice = function (state) { return state.changeEmailModal; };
export var getChangePasswordModalSlice = function (state) { return state.changePasswordModal; };
export var getChatsSlice = function (state) { return state.chats; };
export var getCurrentChatSlice = function (state) { return state.currentChat; };
export var getCommunitySlice = function (state) { return state.community; };
