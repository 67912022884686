import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import Textarea from 'react-textarea-autosize'
import { Popover } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import TagsMultiInput from '../../../../../components/TagsMultiInput'
import SelectAmbassadorsForm from '../../../../../components/SelectAmbassadorsForm'

const renderField = ({
    input,
    label,
    handleChange,
    id,
    accept,
    value,
    placeholder,
    type,
    className,
    meta: { touched, error, warning },
    maxLength,
}) => (
    <div>
        <input
            {...input}
            className={className}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            type={type}
            accept={accept}
            id={id}
            maxLength={maxLength}
        />
        {touched &&
            ((error && <span className={'error ' + type}>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

const renderTextareaField = ({ value, type, id, className, minRows, maxRows, maxLength, handleChange }) => (
    <Textarea
        rows={minRows}
        maxRows={maxRows}
        id={id}
        type={type}
        value={value}
        maxLength={maxLength}
        className={className}
        onChange={handleChange}
    />
)

class CreateChatGroupForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            firstMessage: '',
            is_private: false,
            messageLength: 500,
            globalTagsIds: [],
            firstMessagePlaceholder:
                'Hi everyone, hope you’re well and thanks for joining us today. Our ambassadors' +
                ' are looking forward to chatting with you! You can see all participants in the chat header above. Please share' +
                ' your questions below - our ambassadors are here to share their insights and experiences with you.',
            selectedUsersNumber: 0,
            assignedUsers: {},
        }

        this.handleChatGroupNameChange = this.handleChatGroupNameChange.bind(this)
        this.handleChatGroupFirstMessageChange = this.handleChatGroupFirstMessageChange.bind(this)
        this.handleGlobalTagsSelect = this.handleGlobalTagsSelect.bind(this)
        this.handleUserSelectionUpdated = this.handleUserSelectionUpdated.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onPrivateChange = this.onPrivateChange.bind(this)
    }

    componentDidMount() {
        const { chat, firstMessage } = this.props
        if (document.querySelector('#name')) {
            document.querySelector('#name').value = chat.name
            document.querySelector('#first-message').value = firstMessage
        } else {
            setTimeout(() => {
                if (document.querySelector('#name')) {
                    document.querySelector('#name').value = chat.name
                    document.querySelector('#first-message').value = firstMessage
                }
            }, 500)
        }

        const globalTagsIds = []
        if (chat.globalTags) {
            chat.globalTags.map((item) => {
                globalTagsIds.push(item.id)
            })
        }
        let input = firstMessage
        if (input.length > 500) {
            input = input.split(' ').slice(0, -1).join(' ')
        }
        const availableSymbols = 500 - input.length
        this.setState({
            name: chat.name,
            is_private: chat.is_private,
            firstMessage,
            messageLength: availableSymbols >= 0 ? availableSymbols : -1,
            globalTagsIds,
        })
    }

    getInitialAssignedUsers() {
        const { chat } = this.props

        if (!chat) {
            return {}
        }

        const initialMembers = {}

        chat.members.forEach((member) => {
            if (member.id) {
                initialMembers[member.id] = {
                    selected: true,
                    name: member.name ? member.name.split(' ')[0] : 'Deleted User',
                }
            }
        })

        return initialMembers
    }

    handleChatGroupNameChange(e) {
        this.setState({
            name: e.target.value.trim(),
        })

        this.props.setFormUpdatedStatus(true)
    }

    onPrivateChange(e) {
        this.setState({
            is_private: e.target.checked,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleChatGroupFirstMessageChange(e) {
        let input = e.target.value.trim()
        if (input.length > 500) {
            input = input.split(' ').slice(0, -1).join(' ')
        }
        const availableSymbols = 500 - input.length

        this.setState({
            firstMessage: input,
            messageLength: availableSymbols >= 0 ? availableSymbols : -1,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleGlobalTagsSelect(globalTagsIds) {
        this.setState({
            globalTagsIds: globalTagsIds,
        })

        this.props.setFormUpdatedStatus(true)
    }

    handleUserSelectionUpdated(assignedUsers, selectedUsersNumber, hasBeenUpdatedByUser) {
        this.setState({
            assignedUsers: assignedUsers,
            selectedUsersNumber: selectedUsersNumber,
        })

        if (hasBeenUpdatedByUser) {
            this.props.setFormUpdatedStatus(true)
        }
    }

    handleSubmit(event) {
        event.preventDefault()

        const {
            name,
            firstMessage,
            firstMessagePlaceholder,
            globalTagsIds,
            assignedUsers,
            is_private: isPrivate,
        } = this.state

        const selectedUserIds = []

        for (const id in assignedUsers) {
            if (assignedUsers[id].selected) {
                selectedUserIds.push(id)
            }
        }

        const firstChatMessage = firstMessage.length > 0 ? firstMessage : firstMessagePlaceholder

        const values = { name, firstMessage: firstChatMessage, globalTagsIds, selectedUserIds, is_private: isPrivate }

        this.props.onSubmit(values)
    }

    render() {
        const { firstMessage, messageLength, selectedUsersNumber, name, is_private: isPrivate } = this.state
        const {
            chat,
            ambassadors,
            areAmbassadorsLoading,
            onSearchUpdated,
            hasFormBeenUpdated,
            onDeactivateChatGroupButtonClick,
        } = this.props

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="create-group-row">
                    <div className="w-100">
                        <p className="content-group-name">Name</p>
                        <Field
                            name="name"
                            component={renderField}
                            handleChange={this.handleChatGroupNameChange}
                            type="text"
                            placeholder="Type Group Chat name"
                            className="ui-kit-input"
                            id="name"
                            maxLength={100}
                        />
                    </div>
                </div>
                <div className="create-group-row">
                    <div className="w-100">
                        <p className="content-group-name">First Message in a Chat</p>
                        <div
                            className={
                                'input-chat-wrapper' + (messageLength <= 20 ? ' input-chat-wrapper-warning' : '')
                            }
                        >
                            <Field
                                name="firstMessage"
                                component={renderTextareaField}
                                handleChange={this.handleChatGroupFirstMessageChange}
                                type="textarea"
                                value={firstMessage}
                                className="group-chat-input"
                                maxRows={6}
                                minRows={5}
                                maxLength={500}
                                id="first-message"
                            />
                            <span
                                className={
                                    'input-length-indicator' +
                                    (messageLength <= 20 ? ' input-length-indicator-warning' : '')
                                }
                            >
                                {`${messageLength}/500`}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="private-chat-block">
                    <label className="private-icon">
                        <input
                            type="checkbox"
                            className="content-group-check"
                            name="private"
                            checked={isPrivate}
                            onChange={this.onPrivateChange}
                        />
                        <span className="chat-private-check-icon content-group-check-icon"></span>
                    </label>
                    <span className="private-chat-text">Make the Group Chat private</span>
                    <Popover
                        placement="bottomLeft"
                        content={
                            <div className="private-chat-help-text">
                                <p>Private Group Chats are only visible to prospects</p>
                                <p>you share the link with</p>
                            </div>
                        }
                        className="private-icon-info"
                        onClick={(e) => e.preventDefault()}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                </div>

                <TagsMultiInput
                    handleGlobalTags={this.handleGlobalTagsSelect}
                    globalTagsItems={chat.globalTags || []}
                />

                <SelectAmbassadorsForm
                    ambassadors={ambassadors}
                    areAmbassadorsLoading={areAmbassadorsLoading}
                    initialAssignedUsers={this.getInitialAssignedUsers()}
                    onSearchUpdated={onSearchUpdated}
                    onUserSelectionUpdated={this.handleUserSelectionUpdated}
                />

                <div className="hidden-buttons-block">
                    <button
                        type="submit"
                        className="el-btn create-group-btn"
                        disabled={!selectedUsersNumber || name.length < 3 || !hasFormBeenUpdated}
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={onDeactivateChatGroupButtonClick}
                        className="el-btn mod-archive-group"
                    >
                        {chat.closed ? 'Activate group' : 'Deactivate group'}
                    </button>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'create_chat_group_form',
})(CreateChatGroupForm)
