import { GET_WIDGET_SUCCESS } from '../../actions/chat/widgetsActions'

import { WIDGET_USERS } from '../../store/initialState'

const initialState = WIDGET_USERS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case GET_WIDGET_SUCCESS: {
            const newState = Object.assign({}, state, {
                widget: payload.widget,
                isLoading: false,
            })

            return newState
        }

        default: {
            return state
        }
    }
}
