import HttpService from '../../system/services/HttpService'

// others old call reports in api/dashboard and must be removed here in future

export default {
    faqData: (data) => HttpService.get('/dashboard/reporting/faq', data),

    contentData: (data) => HttpService.get('/dashboard/reporting/content', data),

    prospectFeedbackData: (prospectId, universityIds) =>
        HttpService.get('/dashboard/reporting/prospects/feedbacks', { prospectId: prospectId, universityIds }),
}
