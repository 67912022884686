/* eslint-disable react-hooks/exhaustive-deps */
import { LeftOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row, Typography } from 'antd'
import React, { useMemo } from 'react'

import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg'

import { EventForm } from './event-form'
import { useEventFormModal } from './use-event-form-modal'

const { Text } = Typography

const ScheduledEventFormModal = () => {
    const {
        open,
        modalTitle,
        step,
        onPrevClick,
        afterClose,
        submitButtonTitle,
        submitting,
        onNextClick,
        form,
        initialValues,
        onCancel,
        imageState,
        setImageState,
        editMode,
        isInProgress,
        isUpcoming,
        isRelatedToGroup,
        isGroupAdmin,
    } = useEventFormModal()
    const title = useMemo(() => {
        const backButton = <Button type="link" onClick={onPrevClick} icon={<LeftOutlined />} />

        return (
            <Row justify="space-between">
                <Col span={2}>{step > 0 && backButton} </Col>
                <Col flex="1">
                    <div>{modalTitle}</div>
                    {!editMode && (
                        <Text type="secondary" className="modal-subtitle">
                            Step {step + 1}/4
                        </Text>
                    )}
                </Col>
                <Col span={2} />
            </Row>
        )
    }, [modalTitle, onPrevClick, editMode])

    const footer = useMemo(
        () => (
            <Row justify="center">
                <Col flex="162px">
                    <Button
                        type="primary"
                        block
                        size="large"
                        disabled={submitting}
                        loading={submitting}
                        onClick={onNextClick}
                    >
                        {submitButtonTitle}
                    </Button>
                </Col>
            </Row>
        ),
        [submitButtonTitle, submitting, onNextClick]
    )

    return (
        <Modal
            afterClose={afterClose}
            title={title}
            footer={footer}
            open={open}
            closeIcon={<CloseIcon />}
            onCancel={onCancel}
            width={600}
            className="tap-event-modal"
            destroyOnClose
        >
            <EventForm
                form={form}
                initialValues={initialValues}
                step={step}
                imageState={imageState}
                setImageState={setImageState}
                editMode={editMode}
                isInProgress={isInProgress}
                isUpcoming={isUpcoming}
                isGroupAdmin={isGroupAdmin}
                isRelatedToGroup={isRelatedToGroup}
            />
        </Modal>
    )
}

export default ScheduledEventFormModal
