import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Typography, Button } from 'antd'

import { OPEN_ACTION_MODAL, CLOSE_ACTION_MODAL } from '../../../../../redux/actions/components/componentsActions'
import {
    USER_TOGGLE_BAN_REQUESTED,
    DISABLE_TOGGLE,
    USER_PARTNER_TOGGLE_BAN_REQUESTED,
    USER_PARTNER_TOGGLE_UNI_BAN_REQUESTED,
} from '../../../../../redux/actions/content/usersActions'

import { getAccountInfo, getUsersSlice } from '../../../../../redux/selectors'

const { Title } = Typography

const ToggleCell = ({ record, data, columnName, permissionId, permissionKey }) => {
    const { userInfo: user } = record
    const dispatch = useDispatch()
    const { university_id: universityId } = useSelector(getAccountInfo)
    const { searchItems } = useSelector(getUsersSlice)

    const handleClosePopup = useCallback(() => dispatch({ type: CLOSE_ACTION_MODAL }), [dispatch])

    const modalUserIsBlock = useCallback(() => {
        dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <Col
                        className="text-popup"
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    >
                        <Title level={3} style={{ fontSize: '1.4rem' }}>
                            Sorry, you can&apos;t activate this feature because your ambassador has reached their time
                            limit. Please increase the time limit to automatically reactivate your ambassador
                        </Title>

                        <Button
                            style={{ display: 'flex', width: '10rem' }}
                            type="button"
                            className="el-btn cancel-delete-button"
                            onClick={handleClosePopup}
                        >
                            Close
                        </Button>
                    </Col>
                ),
            },
        })
    }, [dispatch, handleClosePopup])

    const handlePermissionToggle = useCallback(() => {
        const { id, name, last_name: lastName, introduction, description, avatar, timeBlockColum } = user

        const method = 'permission'

        if (timeBlockColum[permissionId]) {
            modalUserIsBlock()
            return
        }

        let typeCheckbox = 'checkbox'
        let currentType = null
        switch (columnName) {
            case 'chatPartnerCheckbox':
                typeCheckbox = 'Chat feature'
                currentType = 'chat'
                break
            case 'chatUniCheckbox':
                typeCheckbox = 'Chat feature'
                currentType = 'chat'
                break
            case 'chatCheckbox':
                typeCheckbox = 'Chat feature'
                currentType = 'chat'
                break
            case 'contentCheckbox':
                typeCheckbox = 'Content feature'
                currentType = 'content'
                break
            case 'faqCheckbox':
                typeCheckbox = 'FAQ feature'
                currentType = 'faq'
                break
            default:
                currentType = permissionKey
                break
        }

        if (
            (!introduction || !description || !avatar || !avatar.original) &&
            (columnName === 'chatCheckbox' || columnName === 'chatUniCheckbox' || columnName === 'chatPartnerCheckbox')
        ) {
            dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title:
                        "You can't enable " +
                        name +
                        ' ' +
                        lastName +
                        ' for ' +
                        typeCheckbox +
                        " yet because they haven't completed their profile during sign-up. Please contact " +
                        name +
                        ' ' +
                        lastName +
                        ' and ask them to complete their profile',
                },
            })
        } else if (currentType) {
            dispatch({
                type: DISABLE_TOGGLE,
                payload: true,
            })
            if (columnName === 'chatPartnerCheckbox') {
                dispatch({
                    type: USER_PARTNER_TOGGLE_BAN_REQUESTED,
                    method,
                    payload: {
                        university_group_id: permissionId,
                        user_id: id,
                        is_enabled: !data.is_enabled,
                        searchItems,
                    },
                })
            } else if (columnName === 'chatUniCheckbox') {
                dispatch({
                    type: USER_PARTNER_TOGGLE_UNI_BAN_REQUESTED,
                    method,
                    payload: {
                        user_id: id,
                        is_enabled: !data.is_enabled,
                        searchItems,
                    },
                })
            } else {
                dispatch({
                    type: USER_TOGGLE_BAN_REQUESTED,
                    method,
                    payload: {
                        universityId,
                        user_id: id,
                        permission_id: permissionId,
                        permission_key: permissionKey,
                        type: currentType,
                        is_enabled: !data.is_enabled,
                        searchItems,
                        requestTotals: !currentType.includes('idp'),
                    },
                })
            }
        }
    }, [columnName, dispatch, user, data, permissionId, permissionKey, universityId, modalUserIsBlock, searchItems])
    return (
        <>
            <label className="slideOne js-holder">
                <input
                    type="checkbox"
                    name={columnName}
                    checked={!!data?.is_enabled}
                    disabled={user.disableToggle}
                    onChange={handlePermissionToggle}
                />
                <span className="slideOne-icon" />
            </label>
        </>
    )
}

const toggleCellRenderer = ({ data, record, columnName, permissionId, permissionKey }) => (
    <ToggleCell
        record={record}
        data={data}
        columnName={columnName}
        permissionId={permissionId}
        permissionKey={permissionKey}
    />
)

export default toggleCellRenderer
