import {
    CREATE_GLOBAL_POST_TAG_SUCCESS,
    CREATE_GLOBAL_POST_TAG_FAILED,
    GET_GLOBAL_POST_TAGS_SUCCESS,
    GET_GLOBAL_POST_TAGS_FAILED,
    REMOVE_GLOBAL_POST_TAG_SUCCESS,
    REMOVE_GLOBAL_POST_TAG_FAILED,
} from '../../actions/tags/tagsActions'

import { POST_GLOBAL_TAGS } from '../../store/initialState'

const initialState = POST_GLOBAL_TAGS

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case CREATE_GLOBAL_POST_TAG_SUCCESS: {
            return {
                ...state,
            }
        }
        case GET_GLOBAL_POST_TAGS_SUCCESS: {
            return {
                ...state,
                items: payload.items,
                isLoading: false,
            }
        }
        case GET_GLOBAL_POST_TAGS_FAILED: {
            return {
                ...state,
            }
        }
        case REMOVE_GLOBAL_POST_TAG_SUCCESS: {
            return {
                ...state,
            }
        }
        case REMOVE_GLOBAL_POST_TAG_FAILED: {
            return {
                ...state,
            }
        }

        default: {
            return state
        }
    }
}
