import React, { useCallback, useState } from 'react'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Row, Col, Typography } from 'antd'
import { useDispatch } from 'react-redux'

import pencil from '../../../../../../assets/img/pencil.png'

import { changeAmbassadorInterests } from '../../../../../redux/actions/content/usersActions'

const { Text } = Typography

const AmbassadorInterests = ({ interests, userId, saveInterests }) => {
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const onSuccess = useCallback(() => {
        setEditMode(false)
    }, [])

    const onFinish = useCallback(() => {
        form.validateFields(['ambassadorInterests'], {
            recursive: true,
        }).then(({ ambassadorInterests }) => {
            const preparedRequestData = ambassadorInterests.map((item) => item && item.interest)
            dispatch(
                changeAmbassadorInterests({
                    userId,
                    interests: preparedRequestData,
                    onSuccess,
                })
            )
        })
    }, [form, userId, dispatch, onSuccess])

    const editType = useCallback(() => {
        setEditMode(true)
    }, [])

    const onCancel = useCallback(() => {
        setEditMode(false)
    }, [])

    const prepareInitialValue = interests?.map((item) => {
        return {
            interest: item.name,
        }
    })

    return editMode ? (
        <>
            <Text className="profile-info-tags-name interests-societies-row">Interests and hobbies</Text>

            <Form
                initialValues={{
                    ambassadorInterests: prepareInitialValue,
                }}
                autoComplete="off"
                hideRequiredMark
                form={form}
                layout="vertical"
            >
                <Form.List
                    name="ambassadorInterests"
                    rules={[
                        {
                            validator: async (_, ambassadorInterests) => {
                                if (!ambassadorInterests || ambassadorInterests.length < 2) {
                                    return Promise.reject(new Error('Add at least 2 interests'))
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map(({ key, name, fieldKey, ...restField }, listIndex) => {
                                return (
                                    <div key={key}>
                                        <Row justify="end">
                                            <Col>
                                                {listIndex > 1 && (
                                                    <CloseCircleOutlined
                                                        onClick={() => remove(name)}
                                                        style={{ position: 'absolute', top: '10px' }}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            {...restField}
                                            label=""
                                            name={[name, 'interest']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please input interest`,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Add interest" style={{ width: '60%' }} />
                                        </Form.Item>
                                    </div>
                                )
                            })}
                            {fields.length < 4 && (
                                <Form.Item noStyle>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            add()
                                            form.validateFields(['ambassadorInterests'])
                                        }}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add interest
                                    </Button>
                                </Form.Item>
                            )}
                            <Row style={{ color: '#ff4d4f' }}>
                                <Form.ErrorList errors={errors} />
                            </Row>
                        </>
                    )}
                </Form.List>
                <Row className="input__buttons">
                    <Button className="input-control-button" onClick={onCancel} type="link">
                        Cancel
                    </Button>
                    <Button className="input-control-button" onClick={onFinish} type="link">
                        Save
                    </Button>
                </Row>
            </Form>
        </>
    ) : (
        <>
            <Col style={{ display: 'flex', alignItems: 'baseline' }}>
                <Text className="profile-info-tags-name interests-societies-row">Interests and hobbies</Text>

                <Button className="input-control-button" onClick={editType} type="link">
                    <img className="pencil-icon" src={pencil} alt="" />
                </Button>
            </Col>
            {!interests ? (
                <Text style={{ color: 'red', fontSize: '1.6rem' }}>Please, specify</Text>
            ) : (
                <Text className="profile-info-tags-row">
                    {interests &&
                        interests.map(({ name }) => {
                            return (
                                <span key={name.replace(/\s/gi, '')} className="sa-info-tag">
                                    {name}
                                </span>
                            )
                        })}
                </Text>
            )}
        </>
    )
}

export default AmbassadorInterests
