import PostsCore from './components/PostsCore'

import './styles.scss'

// TODO: remove this after deploy to production
// class Home extends PureComponent {
//     constructor(props) {
//         super(props)

//         store.dispatch({
//             type: USERS_INFO_REQUESTED,
//             payload: {
//                 filter: {},
//             },
//         })

//         this.loadData = this.loadData.bind(this)
//     }

//     componentDidMount() {
//         store.dispatch(setSubmenu('content'))
//     }

//     loadData() {
//         const propsStore = this.props.posts.posts
//         const routerVars = this.props.match.params

//         // TODO page that contain tag_id is not working
//         if (routerVars.tag_id) {
//             store.dispatch({
//                 type: POSTS_INFO_REQUESTED,
//                 payload: {
//                     filter: {
//                         university_id: this.props.account.account_info.university.id,
//                         tag_id: routerVars.tag_id,
//                         globalTagsIds: [],
//                     },
//                     limit: propsStore.limit,
//                     offset: propsStore.offset,
//                     withGlobalTags: true,
//                 },
//             })
//             // TODO page that contain user_id is not working
//         } else if (routerVars.user_id) {
//             store.dispatch({
//                 type: USERS_INFO_REQUESTED,
//                 payload: {
//                     filter: {},
//                 },
//             })

//             store.dispatch({
//                 type: USERS_POSTS_REQUESTED,
//                 payload: {
//                     filter: {
//                         university_id: this.props.account.account_info.university.id,
//                     },
//                     user_id: routerVars.user_id,
//                     limit: propsStore.total,
//                     offset: 0,
//                 },
//             })
//         } else if (routerVars.content_group_id) {
//             store.dispatch({
//                 type: USERS_INFO_REQUESTED,
//                 payload: {
//                     filter: {},
//                 },
//             })

//             store.dispatch({
//                 type: CONTENT_GROUP_POSTS_REQUESTED,
//                 payload: {
//                     filter: {
//                         content_group_id: routerVars.content_group_id,
//                         globalTagsIds: [],
//                     },
//                     limit: propsStore.limit,
//                     offset: propsStore.offset,
//                 },
//             })
//         } else if (this.props.account.account_info) {
//             store.dispatch({
//                 type: POSTS_INFO_REQUESTED,
//                 payload: {
//                     filter: {
//                         university_id: this.props.account.account_info.university.id,
//                         globalTagsIds: [],
//                     },
//                     limit: propsStore.limit,
//                     offset: propsStore.offset,
//                     withGlobalTags: true,
//                 },
//             })
//         }
//     }

//     render() {
//         console.log('Render')
//         return <PostsCore />
//     }
// }

// export default connect((state) => {
//     return {
//         account: state.account,
//         posts: state.posts,
//         currentGlobalTags: state.currentGlobalTags,
//     }
// })(withRouter(Home))

export default PostsCore
