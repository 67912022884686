import Header from '../../_layout/header'
import NavigationSetupWizard from '../../_layout/navSetupWizard'
import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import { connect } from 'react-redux'
import Modal from 'antd/es/modal'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import tipIcon from '../../../../assets/img/setup/tip.png'
import ExplanationModal from './components/ExplanationModal'
import CryptoJS from 'crypto-js'
import { ReactComponent as SettingsIcon } from '../../../../assets/svg/icons/settings.svg'
import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'

class TrainingCentrePage extends PureComponent {
    constructor(props) {
        super()
        this.state = {
            showModal: false,
        }

        this.showModal = this.showModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.nextStep = this.nextStep.bind(this)
        this.prevStep = this.prevStep.bind(this)
        this.copyLink = this.copyLink.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'setup-training-centre',
            },
        })
    }

    nextStep() {
        if (!window.localStorage.getItem('setupWizardStep') || +window.localStorage.getItem('setupWizardStep') < 3) {
            window.localStorage.setItem('setupWizardStep', '3')
        }
        this.props.history.push('/setup/branding')
    }

    prevStep() {
        this.props.history.push('/setup/privacy-policy')
    }

    showModal() {
        this.setState({
            showModal: true,
        })
    }

    handleCloseModal() {
        this.setState({
            showModal: false,
        })
    }

    copyLink() {
        const tempInput = document.createElement('input')
        tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px'
        tempInput.value = this.getLink()
        document.body.append(tempInput)
        tempInput.select()
        document.execCommand('copy')
        tempInput.remove()
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'Ambassador Training Center link copied to clipboard',
            },
        })
    }

    copyTemplate() {
        const range = document.createRange()
        range.selectNode(document.querySelector('#legal-text-setup'))
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(range)
        document.execCommand('copy')
        window.getSelection().removeAllRanges()
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'Template copied to clipboard',
            },
        })
    }

    getLink = () => {
        const { account } = this.props
        const unikey = 'NcRfUjXn2r5u8x/A%D*G-KaPdSgVkYp3'
        const code =
            account.account_info && account.account_info.university
                ? account.account_info.university.access_code
                : '' + 1
        // this isn't secure but it's fine for generating ambassador links with basic obfuscation for the average user
        return 'https://training.theambassadorplatform.com/?flow=' + btoa(CryptoJS.AES.encrypt(code, unikey).toString())
    }

    render() {
        const { showModal } = this.state

        const trainingCentreLink = this.getLink()

        return (
            <div className="training-centre-page">
                <Header submenuUnActive={true} />
                <div id="page-wrapper" className="page-wrapper">
                    <NavigationSetupWizard />

                    <section className="content settings no-submenu home-start-guide setup-wizard">
                        <h1 className="settings-title">Admin Dashboard Setup Guide</h1>

                        <div className="start-guide-wizard-grid">
                            <div className="column">
                                <div className="setup-start-intro">
                                    Welcome to Setup Guide of The Ambassador Platform’s Admin Dashboard! This section is
                                    designed to guide you through set up.
                                </div>
                                <div className="setup-start-block">
                                    <div className="policy-title">
                                        2. Invite your ambassadors to the Ambassador Training Center
                                    </div>
                                    <p className="video-privacy-link">
                                        Watch this <span onClick={this.showModal}>video explanation</span> to see how it
                                        works
                                    </p>

                                    <p>
                                        Send the Ambassador Training Center link to your Ambassadors. Once they have
                                        completed the training center, they will be asked to create a profile. You can
                                        find out more information about this&nbsp;
                                        <a
                                            rel="noopener noreferrer"
                                            href="https://knowledge.theambassadorplatform.com/how-to-invite-your-ambassadors-to-the-ambassador-training-center"
                                            target="_blank"
                                        >
                                            here
                                        </a>
                                        .
                                    </p>
                                    <h4 className="settings-name training-centre-link-title">
                                        Ambassador Training Center link
                                    </h4>
                                    <div className="invite-link-block">
                                        <span className="invite-training-link">{trainingCentreLink}</span>
                                        <span className="copy" onClick={this.copyLink}>
                                            Copy
                                        </span>
                                    </div>

                                    <div className="tip-block">
                                        <img src={tipIcon} alt="tip" />
                                        <span>
                                            You may have as many ambassadors as you like, and you can use the Ambassador
                                            List to activate them for different features.
                                        </span>
                                    </div>

                                    <div className="setup-steps-buttons">
                                        <button className="previous-step-button" onClick={this.prevStep}>
                                            Previous
                                        </button>
                                        <button onClick={this.nextStep}>Next</button>
                                    </div>
                                    <div className="setup-skip-text">
                                        If you skip this step, you&apos;ll find it later in <SettingsIcon /> Settings
                                    </div>
                                </div>
                            </div>

                            <div className="column preview">
                                <div className="preview-title">
                                    <span>Template </span>
                                    <span>inviting your Ambassadors</span>
                                </div>
                                <div className="copy-template" onClick={this.copyTemplate}>
                                    Copy template
                                </div>
                                <div className="preview-body">
                                    <div className="legal-text-setup scroll-8">
                                        <div id="legal-text-setup" className="legal-template-setup">
                                            <p className="legal-first-text">Hello [insert first name],</p>

                                            <p className="legal-second-text">
                                                We want to give prospects an authentic insight into the experience of
                                                being part of&nbsp;
                                                <span className="insert-item">
                                                    {this.props.account.account_info.university.name}
                                                </span>
                                                &nbsp; and we need your help!
                                            </p>

                                            <p className="legal-third-text">
                                                We&apos;re inviting you to create a profile on The Ambassador Platform
                                                (TAP). Through TAP we&apos;ll be working with you to:
                                            </p>

                                            <ul>
                                                <li className="legal-option-text">
                                                    Create user-generated content which can be shared across different
                                                    marketing channels
                                                </li>
                                                <li className="legal-option-text">
                                                    Create answers to commonly-asked questions
                                                </li>
                                                <li className="legal-option-text">
                                                    Facilitate peer-to-peer conversations between yourself and
                                                    prospects, either one to one or in a group setting
                                                </li>
                                            </ul>
                                            <p className="legal-forth-text">
                                                To help you find out more about The Ambassador Platform and why
                                                we&apos;re using it, please take a look at this&nbsp;
                                                <a
                                                    href="https://knowledge.theambassadorplatform.com/what-is-the-ambassador-platform"
                                                    target="_blank"
                                                    className="orange-link"
                                                    rel="noreferrer"
                                                >
                                                    brief overview
                                                </a>
                                                .
                                            </p>
                                            <p className="legal-fifth-text">
                                                In order to get set up, please do the following:
                                            </p>
                                            <p>
                                                <ul>
                                                    <li className="legal-option-text">
                                                        Visit the&nbsp;
                                                        <a
                                                            rel="noopener noreferrer"
                                                            href={trainingCentreLink}
                                                            target="_blank"
                                                        >
                                                            Ambassador Training Center
                                                        </a>
                                                        &nbsp; to learn about how to carry out your work on The
                                                        Ambassador Platform
                                                    </li>
                                                    <li className="legal-option-text">
                                                        Download &apos;The Ambassador Platform&apos; app from the&nbsp;
                                                        <a
                                                            href="https://tap.st/ios"
                                                            alt="The Ambassador Platform App Store link"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            App
                                                        </a>
                                                        /
                                                        <a
                                                            href="https://tap.st/android"
                                                            alt="The Ambassador Platform Play Store link"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Play
                                                        </a>
                                                        &nbsp; Store
                                                    </li>
                                                    <li className="legal-option-text">
                                                        Create an account in the app using the code you received when
                                                        you completed training
                                                    </li>
                                                    <li className="legal-option-text">
                                                        Complete your profile to the best of your ability
                                                    </li>
                                                    <li className="legal-option-text">
                                                        Take some time to watch the videos in the ‘Learning Resources’
                                                        area to learn more about the service
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                I hope that makes sense and sounds fun. Let me know if you have any
                                                questions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="preview-floor">
                                    You may use that message to sent to your Ambassadors
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Modal
                    open={showModal}
                    bodyStyles={{ padding: 0 }}
                    centered={true}
                    width={800}
                    title={null}
                    footer={null}
                    destroyOnClose={true}
                    keyboard={false}
                    onCancel={this.handleCloseModal}
                    className="setup-modal"
                >
                    <ExplanationModal handleCloseModal={this.handleCloseModal} />
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return { account: state.account }
})(TrainingCentrePage)
