import React, { PureComponent } from 'react'
import { Table } from 'antd'

import { DateHumanizeHelper } from '../../../helpers/table'
import ReactPlayer from 'react-player'

const prepareTableData = (data) => {
    if (!data) {
        return []
    }

    return data.map((item) => {
        return {
            key: item.id,
            post: { type: item.type, caption: item.caption, images: item.images, videos: item.videos },
            sharing_at: DateHumanizeHelper(item.sharing_at),
            original_date: item.sharing_at,
            watch_count: item.watch_count ? item.watch_count : '',
            like_count: item.like_count ? item.like_count : '',
            share_link_count: item.share_link_count ? item.share_link_count : '',
        }
    })
}

class ContentTable extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedRowKeys: [],
            lastData: props.data,
            tableData: [],
        }

        this.columns = this.prepareColumnsData()
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data !== state.lastData) {
            state.lastData = props.data
            state.tableData = prepareTableData(props.data)

            return state
        }

        return null
    }

    prepareColumnsData() {
        return [
            {
                title: 'Content Post',
                dataIndex: 'post',
                width: '30%',
                render: (post, item) => {
                    return post.type === 'image' ? (
                        <div className="post-image-wrapper">
                            <img
                                className="report-post-image"
                                src={post.images[0].sizes['370x370'] || post.images[0].original}
                                alt="Post preview image"
                            />
                            <span className="report-post-text">{post.caption + ' '}</span>
                        </div>
                    ) : (
                        <div className="post-video-wrapper">
                            <ReactPlayer
                                className="report-post-video"
                                url={post.videos[0].original}
                                aria-label=""
                                playing={false}
                                playsinline
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                        },
                                    },
                                }}
                                onContextMenu={(e) => e.preventDefault()}
                            />
                            <span className="report-post-text">{post.caption + ' '}</span>
                        </div>
                    )
                },
            },
            {
                title: 'Publishing date',
                dataIndex: 'sharing_at',
                width: 100,
                sorter: (a, b) => (a.original_date > b.original_date ? 1 : -1),
            },
            {
                title: 'Views',
                dataIndex: 'watch_count',
                width: 70,
                sorter: (a, b) => (a.watch_count > b.watch_count ? 1 : -1),
            },
            {
                title: 'Total Likes',
                dataIndex: 'like_count',
                width: 70,
                sorter: (a, b) => (a.like_count > b.like_count ? 1 : -1),
            },
            {
                title: 'Shares',
                dataIndex: 'share_link_count',
                width: 70,
                sorter: (a, b) => (a.share_link_count > b.share_link_count ? 1 : -1),
            },
        ]
    }

    render() {
        const { tableData } = this.state
        const { total, handleTableChange } = this.props
        return (
            <div
                className="users-container flexible-page table-wrapper"
                style={{
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                }}
            >
                <Table
                    columns={this.columns}
                    dataSource={tableData}
                    pagination={{ total: total, size: 'small' }}
                    onChange={handleTableChange}
                />
            </div>
        )
    }
}

export default ContentTable
