import { Calendar, DatePicker, Checkbox, Popover } from 'antd'
import React, { PureComponent } from 'react'
import cn from 'classnames'

import { isSameDay, isSameMonth, getCurrentDay } from '../../../helpers/time'

const checkActiveStates = (data) => {
    let numberOfActive = 0
    for (const item of data) {
        if (item.is_active) {
            numberOfActive++
        }
    }

    return {
        isDisabled: data.length === 0,
        isAnyActive: numberOfActive,
        areAllActive: data.length > 0 && numberOfActive === data.length,
    }
}

const getEventForDate = (data, date) => {
    for (const item of data) {
        if (isSameDay(date, item.date)) {
            return item
        }
    }

    return { name: '', description: '' }
}

const Header = (props) => {
    const checkboxStyle = props.activeStates.areAllActive
        ? 'calendar-header-checked'
        : props.activeStates.isAnyActive
        ? 'calendar-header-indeterminate'
        : 'calendar-header-unchecked'

    return (
        <div className="calendar-header-column">
            <DatePicker.MonthPicker
                className="calendar-header-date"
                value={props.date}
                onChange={props.onMonthChange}
                format={'MMMM YYYY'}
                size={'large'}
                allowClear={false}
            />
            <Checkbox
                className={'calendar-header-checkbox ' + checkboxStyle}
                disabled={props.activeStates.isDisabled}
                indeterminate={props.activeStates.isAnyActive && !props.activeStates.areAllActive}
                checked={props.activeStates.areAllActive}
                onChange={(e) => props.setChecked(e.target.checked)}
            >
                <span className="calendar-header-checkbox-text">
                    {props.activeStates.areAllActive ? 'Deactivate' : 'Activate'} all
                </span>
            </Checkbox>
        </div>
    )
}

const EventInfo = (props) => {
    const isArchived = props.data.closed && props.data.name

    const text = props.data.name === '' ? 'Content Group' : props.data.name
    const description =
        props.data.description === ''
            ? 'No content group created'
            : isArchived
            ? 'This content group has been deactivated'
            : props.data.description

    const calendarPopoverDate = cn('calendar-popover-date', {
        archived: isArchived,
    })

    return (
        <div className="calendar-popover-content">
            <div className={calendarPopoverDate}>
                <div className="calendar-popover-date-main">{props.date.format('DD/MM')}</div>
                <div className="calendar-popover-date-additional">{props.date.format('dddd')}</div>
            </div>
            <div className="calendar-popover-event">
                <div className="calendar-popover-event-title">{text}</div>
                <div className="calendar-popover-event-text">{description}</div>
            </div>
        </div>
    )
}

const DisabledCell = (props) => {
    return <div className="calendar-event-cell calendar-event-disabled-cell" />
}

const EventCell = (props) => {
    const isEmpty = props.data.name === ''
    let cellClass = 'calendar-event-cell '
    if (isSameDay(props.date, props.selectedDate)) {
        cellClass += 'calendar-event-cell-selected'
    } else if (isEmpty) {
        cellClass += 'calendar-event-empty-cell'
    } else if (props.data.closed) {
        cellClass += 'calendar-event-archived-cell'
    } else {
        cellClass += 'calendar-event-full-cell'
    }
    if (isSameDay(props.date, getCurrentDay())) {
        cellClass += ' calendar-event-cell-today'
    }

    return (
        <Popover mouseEnterDelay={0.5} content={<EventInfo data={props.data} date={props.date} />}>
            <div className={cellClass}>
                <div className="calendar-event-cell-day-row">
                    <span className="calendar-day">{props.date.format('DD')}</span>
                    {!isEmpty && (
                        <Checkbox
                            className={
                                'calendar-day-checkbox ' +
                                (props.data.is_active ? 'calendar-day-checked' : 'calendar-day-unchecked')
                            }
                            checked={props.data.is_active}
                            onChange={(e) => props.setChecked(props.data.id, e.target.checked)}
                        />
                    )}
                </div>
                <div className="calendar-day-text">{props.data.name}</div>
            </div>
        </Popover>
    )
}

class EventsCalendar extends PureComponent {
    constructor(props) {
        super(props)

        this.setAllEventsStatus = this.setAllEventsStatus.bind(this)
        this.setEventStatus = this.setEventStatus.bind(this)
        this.headerRender = this.headerRender.bind(this)
        this.dayCellRender = this.dayCellRender.bind(this)
    }

    setAllEventsStatus(value) {
        const eventIds = []
        for (const event of this.props.data) {
            eventIds.push(event.id)
        }
        this.props.onSelect(eventIds, value)
    }

    setEventStatus(eventId, value) {
        this.props.onSelect([eventId], value)
    }

    headerRender({ value, type, onChange, onTypeChange }) {
        const activeStates = checkActiveStates(this.props.data)

        return (
            <Header
                date={value}
                activeStates={activeStates}
                setChecked={this.setAllEventsStatus}
                onMonthChange={onChange}
            />
        )
    }

    dayCellRender(date) {
        if (!isSameMonth(date, this.props.date)) {
            return <DisabledCell />
        }

        const eventData = getEventForDate(this.props.data, date)

        return (
            <EventCell date={date} selectedDate={this.props.date} data={eventData} setChecked={this.setEventStatus} />
        )
    }

    render() {
        return (
            <>
                <Calendar
                    headerRender={this.headerRender}
                    dateFullCellRender={this.dayCellRender}
                    onSelect={this.props.onDateSelection}
                    onPanelChange={this.props.onMonthChange}
                />
            </>
        )
    }
}

export default EventsCalendar
