import { LIVE_STREAMS } from './live-streams-action-types'

export const fetchLiveStreams = (payload) => ({
    type: LIVE_STREAMS.FETCH,
    payload,
})

export const getLiveStreamData = (payload) => ({
    type: LIVE_STREAMS.FETCH_LIVE_STREAM_DATA,
    payload,
})

export const openStreamTypeModal = (payload = {}) => ({
    type: LIVE_STREAMS.OPEN_STREAM_TYPE_MODAL,
})

export const closeStreamTypeModal = (payload = {}) => ({
    type: LIVE_STREAMS.CLOSE_STREAM_TYPE_MODAL,
    payload,
})

export const openStreamFormModal = (payload = {}) => ({
    type: LIVE_STREAMS.OPEN_FORM_MODAL,
    payload,
})

export const closeStreamFormModal = () => ({
    type: LIVE_STREAMS.CLOSE_FORM_MODAL,
})

export const openScheduledStreamTypeModal = (payload = {}) => ({
    type: LIVE_STREAMS.OPEN_SCHEDULED_FORM_MODAL,
    payload,
})

export const closeScheduledStreamTypeModal = () => ({
    type: LIVE_STREAMS.CLOSE_SCHEDULED_FORM_MODAL,
})

export const createLiveStream = (payload) => ({
    type: LIVE_STREAMS.CREATE,
    payload,
})

export const setActiveStream = (payload) => ({
    type: LIVE_STREAMS.SET_ACTIVE,
    payload,
})

export const updateLiveStream = (payload) => ({
    type: LIVE_STREAMS.UPDATE,
    payload,
})

export const openUsersModal = (payload) => ({
    type: LIVE_STREAMS.OPEN_USERS_MODAL,
    payload,
})

export const closeUsersModal = () => ({
    type: LIVE_STREAMS.CLOSE_USERS_MODAL,
})

export const openAmbassadorsModal = (payload) => ({
    type: LIVE_STREAMS.OPEN_AMBASSADORS_MODAL,
    payload,
})

export const closeAmbassadorsModal = () => ({
    type: LIVE_STREAMS.CLOSE_AMBASSADORS_MODAL,
})

export const openSpeakersModal = (payload) => ({
    type: LIVE_STREAMS.OPEN_SPEAKERS_MODAL,
    payload,
})

export const closeSpeakersModal = () => ({
    type: LIVE_STREAMS.CLOSE_SPEAKERS_MODAL,
})

export const setActiveLiveStreamSidebarTab = (payload) => ({
    type: LIVE_STREAMS.SET_ACTIVE_SIDEBAR_TAB,
    payload,
})
export const fetchProspects = (payload) => ({
    type: LIVE_STREAMS.FETCH_PROSPECTS,
    payload,
})

export const openPublishControlModal = (payload) => ({
    type: LIVE_STREAMS.OPEN_PUBLISH_CONTROL_MODAL,
    payload,
})

export const closePublishControlModal = () => ({
    type: LIVE_STREAMS.CLOSE_PUBLISH_CONTROL_MODAL,
})

export const joinStreamLobby = (payload) => ({
    type: LIVE_STREAMS.JOIN_STREAM_LOBBY,
    payload,
})

export const openVideoReUploadModal = (payload) => ({
    type: LIVE_STREAMS.OPEN_VIDEO_RE_UPLOAD_MODAL,
    payload,
})

export const closeVideoReUploadModal = () => ({
    type: LIVE_STREAMS.CLOSE_VIDEO_RE_UPLOAD_MODAL,
})

export const updateStreamStatus = (payload) => ({
    type: LIVE_STREAMS.UPDATE_STREAM_STATUS,
    payload,
})

export const onVideoReUploadSuccess = (payload) => ({
    type: LIVE_STREAMS.VIDEO_RE_UPLOAD_SUCCESS,
    payload,
})

export const shareLiveStreamRecord = (payload) => ({
    type: LIVE_STREAMS.SHARE_STREAM_RECORD,
    payload,
})
