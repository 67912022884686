import React from 'react'
import { useSelector } from 'react-redux'
import { getPlsWizardSetup } from '../../../../../redux/selectors'

const MobileAppCredentialsSection = () => {
    const plsWizardSetup = useSelector(getPlsWizardSetup)
    const { ambassador } = plsWizardSetup

    const ambassadorPassword = window.localStorage.getItem('PlsAmbassadorLoginPassword')

    return (
        <div className="settings-item">
            <label className="settings-name">
                2. Log in to the mobile application{' '}
                <span className="label-rest-text">using the following credentials:</span>
            </label>
            <div>
                <p className="credential-item">
                    Login <span> - {ambassador?.email}</span>
                </p>
                <p className="credential-item">
                    Password <span> - {ambassadorPassword}</span>
                </p>
            </div>
        </div>
    )
}

export default MobileAppCredentialsSection
