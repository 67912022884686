const NAMESPACE_PREFIX = 'CAMPAIGN'

export const CAMPAIGN = {
    FETCH: `${NAMESPACE_PREFIX}.FETCH`,
    FETCH_SUCCESS: `${NAMESPACE_PREFIX}.FETCH_SUCCESS`,
    FETCH_ERROR: `${NAMESPACE_PREFIX}.FETCH_ERROR`,
    FETCH_CAMPAIGN_BY_ID: `${NAMESPACE_PREFIX}.FETCH_CAMPAIGN_BY_ID`,
    FETCH_CAMPAIGN_BY_ID_SUCCESS: `${NAMESPACE_PREFIX}.FETCH_CAMPAIGN_BY_ID_SUCCESS`,
    FETCH_CAMPAIGN_BY_ID_ERROR: `${NAMESPACE_PREFIX}.FETCH_CAMPAIGN_BY_ID_ERROR`,
    RESET_ACTIVE_CAMPAIGN: `${NAMESPACE_PREFIX}.RESET_ACTIVE_CAMPAIGN`,
    OPEN_CAMPAIGN_TYPE_MODAL: `${NAMESPACE_PREFIX}.OPEN_CAMPAIGN_TYPE_MODAL`,
    CLOSE_CAMPAIGN_TYPE_MODAL: `${NAMESPACE_PREFIX}.CLOSE_CAMPAIGN_TYPE_MODAL`,
    SET_CAMPAIGN_TYPES: `${NAMESPACE_PREFIX}.SET_CAMPAIGN_TYPES`,
    CREATE_CAMPAIGN: `${NAMESPACE_PREFIX}.CREATE_CAMPAIGN`,
    CREATE_CAMPAIGN_SUCCESS: `${NAMESPACE_PREFIX}.CREATE_CAMPAIGN_SUCCESS`,
    CREATE_CAMPAIGN_FAILED: `${NAMESPACE_PREFIX}.CREATE_CAMPAIGN_FAILED`,
}
