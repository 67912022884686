export const CHATS_INFO_SEARCH = 'CHATS_INFO_SEARCH'
export const CHATS_INFO_SUCCESS = 'CHATS_INFO_SUCCESS'
export const CHATS_INFO_FAILED = 'CHATS_INFO_FAILED'

export const LOAD_NEW_MESSAGES_SUCCESS = 'LOAD_NEW_MESSAGES_SUCCESS'
export const LOAD_OLD_MESSAGES_SUCCESS = 'LOAD_OLD_MESSAGES_SUCCESS'
export const LOAD_AROUND_MESSAGES_SUCCESS = 'LOAD_AROUND_MESSAGES_SUCCESS'

export const CHAT_INFO_SEARCH = 'CHAT_INFO_SEARCH'
export const CHAT_INFO_SUCCESS = 'CHAT_INFO_SUCCESS'
export const CHAT_INFO_FAILED = 'CHAT_INFO_FAILED'

export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE'
export const SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS'
export const SEND_CHAT_MESSAGE_FAILED = 'SEND_CHAT_MESSAGE_FAILED'

export const CLOSE_DIALOG = 'CLOSE_DIALOG'
export const ARCHIVE_DIALOG_TOGGLE = 'ARCHIVE_DIALOG_TOGGLE'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

export const PIN_CHAT = 'PIN_CHAT'
export const PIN_CHAT_SUCCESS = 'PIN_CHAT_SUCCESS'

export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT'
export const UPDATE_ACTIVE_CHAT = 'UPDATE_ACTIVE_CHAT'
export const UPDATE_CHAT_FIRST_MESSAGE = 'UPDATE_CHAT_FIRST_MESSAGE'
export const RESET_ACTIVE_CHAT = 'RESET_ACTIVE_CHAT'

export const FLAG_DIALOG = 'FLAG_DIALOG'

export const UNFLAG_DIALOG = 'UNFLAG_DIALOG'
export const UNFLAG_DIALOG_FAILED = 'UNFLAG_DIALOG_FAILED'
export const UNFLAG_DIALOG_SUCCESS = 'UNFLAG_DIALOG_SUCCESS'

export const UNBLOCK_CHAT_MEMBER = 'UNBLOCK_CHAT_MEMBER'
export const BLOCK_PROSPECT_TO_CHATS = 'BLOCK_PROSPECT_TO_CHATS'
export const GET_BLOCK_REASON = 'GET_BLOCK_REASON'
export const SET_BLOCKED_REASON = 'SET_BLOCKED_REASON'
export const GET_REPORT_REASON = 'GET_REPORT_REASON'
export const SET_REPORT_REASON = 'SET_REPORT_REASON'

export const UNWORD_DIALOG = 'UNWORD_DIALOG'
export const UNWORD_DIALOG_FAILED = 'UNWORD_DIALOG_FAILED'
export const UNWORD_DIALOG_SUCCESS = 'UNWORD_DIALOG_SUCCESS'

export const RESET_CHAT_MESSAGES_COUNTER = 'RESET_CHAT_MESSAGES_COUNTER'
export const RESET_CHAT_FILTERS = 'RESET_CHAT_FILTERS'

export const CHAT_MESSAGES_INFO = 'CHAT_MESSAGES_INFO'
export const CHAT_MESSAGES_INFO_SUCCESS = 'CHAT_MESSAGES_INFO_SUCCESS'
export const CHAT_MESSAGES_INFO_FAILED = 'CHAT_MESSAGES_INFO_FAILED'
export const CLEAR_CHAT_MESSAGES = 'CLEAR_CHAT_MESSAGES'

export const FIND_OR_CREATE_CHAT = 'FIND_OR_CREATE_CHAT'
export const FIND_OR_CREATE_CHAT_SUCCESS = 'FIND_OR_CREATE_CHAT_SUCCESS'
export const FIND_OR_CREATE_CHAT_FAILED = 'FIND_OR_CREATE_CHAT_FAILED'

export const EDIT_CHAT = 'EDIT_CHAT'
export const EDIT_CHAT_SUCCESS = 'EDIT_CHAT_SUCCESS'
export const EDIT_CHAT_FAILED = 'EDIT_CHAT_FAILED'

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'

export const DELETE_CHAT_MESSAGE = 'DELETE_CHAT_MESSAGE'

export const GET_CHAT_TRANSCRIPT_DATA = 'GET_CHAT_TRANSCRIPT_DATA'

export const SET_CHATS_TRANSCRIPT_SETTINGS = 'SET_CHATS_TRANSCRIPT_SETTINGS'
export const GET_CHATS_TRANSCRIPT_DATA_FAILED = 'GET_CHATS_TRANSCRIPT_DATA_FAILED'
export const GET_CHATS_TRANSCRIPT_DATA = 'GET_CHATS_TRANSCRIPT_DATA'

export const MUTE_CHAT_TRIGGER = 'MUTE_CHAT_TRIGGER'

export const PIN_MESSAGE = 'PIN_MESSAGE'
export const PIN_MESSAGE_SUCCESS = 'PIN_MESSAGE_SUCCESS'
export const PIN_MESSAGE_FAILED = 'PIN_MESSAGE_FAILED'

export const UNPIN_MESSAGE = 'UNPIN_MESSAGE'
export const UNPIN_MESSAGE_SUCCESS = 'UNPIN_MESSAGE_SUCCESS'
export const UNPIN_MESSAGE_FAILED = 'UNPIN_MESSAGE_FAILED'

export const GET_DIALOG_BY_ID = 'GET_DIALOG_BY_ID'

export const resetSearchFilters = () => ({
    type: RESET_CHAT_FILTERS,
})

export const pinMessage = ({ message }) => ({
    type: PIN_MESSAGE,
    payload: {
        message,
    },
})

export const unpinMessage = ({ messageId, onSuccess, dialogId }) => ({
    type: UNPIN_MESSAGE,
    payload: {
        messageId,
        dialogId,
    },
})

export const searchDialogs = (payload) => ({
    type: CHATS_INFO_SEARCH,
    payload,
})

export const setActiveChat = (payload) => ({
    type: SET_ACTIVE_CHAT,
    payload,
})

export const getDialogById = ({ dialogId }) => ({
    type: GET_DIALOG_BY_ID,
    payload: {
        dialogId,
    },
})
