import React, { PureComponent } from 'react'

class ConfirmBanPopup extends PureComponent {
    render() {
        const { handleConfirmBan, handleClosePopup } = this.props

        return (
            <div className="confirm-delete-popup">
                <h3 className="modal-title">Are you sure that you wish to disable this ambassador?</h3>
                <div className="btn-wrap confirm-ban-buttons">
                    <button type="submit" className="el-btn confirm-delete-button" onClick={handleConfirmBan}>
                        Yes
                    </button>
                    <button type="button" className="el-btn cancel-delete-button" onClick={handleClosePopup}>
                        Cancel
                    </button>
                </div>
            </div>
        )
    }
}

export default ConfirmBanPopup
