import { all, call, put, takeEvery } from 'redux-saga/effects'

import API from '../../../api'
import { PLS_SIGN_UP } from '../../actions/pls-sign-up/pls-sign-up-types'
import { SET_SETUP_WIZARD_SUCCESS } from '../../actions/settings/setupWizardActions'
import { OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'

export function* getDownloadLink(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.plsSignUp.getAppDownloadLink(payload)
        })
        if (response.success) {
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: `The link has been sent to your ${payload.method === 'sms' ? 'phone' : 'email'}`,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* plsSetupWizardFinish(action) {
    try {
        const response = yield call(() => {
            return API.settings.setupWizardFinish()
        })

        if (response.success) {
            yield put({
                type: SET_SETUP_WIZARD_SUCCESS,
            })
            action.onPlsSignUpSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

function* watchDownloadLinkFetchSaga() {
    yield takeEvery(PLS_SIGN_UP.GET_APP_DOWNLOAD_LINK, getDownloadLink)
}

function* watchPlsSetupWizardFinish() {
    yield takeEvery(PLS_SIGN_UP.PLS_SETUP_WIZARD_FINISH, plsSetupWizardFinish)
}

function* plsSignUpFetchSaga() {
    yield all([call(watchDownloadLinkFetchSaga), call(watchPlsSetupWizardFinish)])
}

export default plsSignUpFetchSaga
