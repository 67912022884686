import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState, useMemo } from 'react'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { CLOSE_ACTION_MODAL, OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'
import { Checkbox, Select, Switch } from 'antd'
import SamlForm from './components/saml-form'
import {
    getSsoProviders,
    setUniversitySso,
    resetUniversitySso,
    userToggle,
} from '../../../redux/actions/settings/sso/sso-actions'
import { activateToggleUniversitySsoOnly } from '../../../redux/actions/account/account-actions'
import Loader from '../../content/components/Loader'
import OpenIdForm from './components/open-id-form'

const { Option } = Select

const Sso = () => {
    const dispatch = useDispatch()
    const account = useSelector((state) => state.account)
    const ssoProviders = useSelector((state) => state.ssoProviders)
    const [selectedProvider, setSelectedProvider] = useState(null)

    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
        dispatch(getSsoProviders({ firstLoad: true }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (ssoProviders.activeProvider.ssoProviderId && ssoProviders.items.length > 0) {
            let selectedProviderNew = null
            ssoProviders.items.map((item) => {
                if (item.id === ssoProviders.activeProvider.ssoProviderId) {
                    selectedProviderNew = item
                }
            })
            setSelectedProvider(selectedProviderNew)
        }
    }, [ssoProviders])

    const isSsoActivated = useMemo(() => {
        if (account.account_info) {
            if (account.account_info.universityGroup) {
                return account.account_info.universityGroup.is_sso_activated
            } else if (account.account_info.university) {
                return account.account_info.university.is_sso_activated
            }
        }
        return false
    }, [account])

    const isSsoOnlyActivated = useMemo(() => {
        if (account.account_info) {
            if (account.account_info.universityGroup) {
                return account.account_info.universityGroup.is_sso_only_activated
            } else if (account.account_info.university) {
                return account.account_info.university.is_sso_only_activated
            }
        }
        return false
    }, [account])

    const handleCloseConfirmPopup = useCallback(() => {
        dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }, [dispatch])

    const handleOpenConfirmPopup = useCallback(
        (bodyText, onConfirm) => {
            dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    children: (
                        <div className="general-popup">
                            <h2 className="modal-title">Are you sure?</h2>
                            <div className="modal-body">{bodyText}</div>
                            <div className="btn-wrap confirm-ban-buttons">
                                <button type="submit" className="el-btn confirm-delete-button" onClick={onConfirm}>
                                    Yes, I am sure
                                </button>
                                <button
                                    type="button"
                                    className="el-btn cancel-delete-button"
                                    onClick={handleCloseConfirmPopup}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    ),
                },
            })
        },
        [dispatch, handleCloseConfirmPopup]
    )

    const onSave = useCallback(
        (data) => {
            const onConfirm = () => {
                const onSuccess = () => {
                    dispatch({
                        type: OPEN_ACTION_MODAL,
                        payload: {
                            title: 'Success',
                            className: 'success-popup',
                        },
                    })
                }
                data.providerId = selectedProvider.id
                dispatch(setUniversitySso(data, onSuccess))
            }
            const bodyText =
                'You are going to activate SSO for your organisation. Indicated user groups  will be able to sign in and sign up via SSO according to your configuration.'
            handleOpenConfirmPopup(bodyText, onConfirm)
        },
        [dispatch, selectedProvider, handleOpenConfirmPopup]
    )

    const onChange = useCallback(
        (selectedItem) => {
            const newSelectedProvider = ssoProviders.items.find((item) => item.id === selectedItem)
            setSelectedProvider(newSelectedProvider)
        },
        [ssoProviders]
    )

    const confirmReset = useCallback(() => {
        const onSuccess = () => setSelectedProvider(null)
        dispatch(resetUniversitySso(onSuccess))
    }, [dispatch])

    const onReset = useCallback(() => {
        const bodyText =
            'You are going to de-activate SSO for your organisation. Indicated user groups  will not be able to sign in and sign up via SSO according to your configuration.'
        handleOpenConfirmPopup(bodyText, confirmReset)
    }, [confirmReset, handleOpenConfirmPopup])

    const onSsoOnlyActivationChange = useCallback(
        (checked) => {
            if (isSsoActivated) {
                const onConfirm = () => {
                    dispatch(activateToggleUniversitySsoOnly(checked))
                }
                dispatch({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        children: (
                            <div className="general-popup">
                                <h2 className="modal-title">Are you sure?</h2>
                                <div className="modal-body">
                                    You are going to enable sign in / sing up only with SSO. You will be logged out
                                    automatically. After log out users will be able to access the product only via SSO
                                    credentials.
                                </div>
                                <div className="btn-wrap confirm-ban-buttons">
                                    <button type="submit" className="el-btn confirm-delete-button" onClick={onConfirm}>
                                        Yes, I am sure
                                    </button>
                                    <button
                                        type="button"
                                        className="el-btn cancel-delete-button"
                                        onClick={handleCloseConfirmPopup}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        ),
                    },
                })
            } else {
                dispatch({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        children: (
                            <div className="general-popup">
                                <h2 className="modal-title">Set up SSO first</h2>
                                <div className="modal-body">
                                    Set up your SSO configuration according to instructions provided in order to
                                    activate SSO-only access.
                                </div>
                                <div className="btn-wrap confirm-ban-buttons">
                                    <button
                                        type="button"
                                        className="el-btn cancel-delete-button"
                                        onClick={handleCloseConfirmPopup}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        ),
                    },
                })
            }
        },
        [dispatch, isSsoActivated, handleCloseConfirmPopup]
    )

    const onUserToggle = useCallback(
        (isAllowed, allowedType) => {
            dispatch(userToggle(isAllowed, allowedType))
        },
        [dispatch]
    )

    return (
        <div className="sso-settings-page">
            {ssoProviders.isLoading ? (
                <Loader />
            ) : (
                <section className="content settings">
                    <h3 className="settings-title">Configure SSO</h3>
                    <div className="settings-grid has-tutorial">
                        <div className="column" style={{ paddingRight: '10%' }}>
                            <p style={{ marginBottom: 10 }}>
                                Get set up with your custom SAML solution, Azure, Okta, etc.
                            </p>
                            <p style={{ marginBottom: 10 }}>
                                SSO implementation of TAP enables a company to set up SSO-based access for different
                                types of users across different TAP applications.
                            </p>
                            <p>
                                When enabled users can rely on the credentials of their organisation to log into the TAP
                                applications without a need to create new accounts and remember credentials, therefore,
                                enjoying the benefits of improved security and usability.
                            </p>
                            <hr className="settings-hr"></hr>
                            <div className="sso-provider-body-title-block">
                                <h3 className="settings-title">Choose your provider</h3>
                            </div>
                            <div className="sso-drop-block">
                                <Select
                                    key={ssoProviders.activeProvider.ssoProviderId}
                                    onChange={onChange}
                                    dropdownStyle={{ minWidth: '220px' }}
                                    size="large"
                                    className="sso-dropdown"
                                    placeholder="Choose Provider"
                                    defaultValue={ssoProviders.activeProvider.ssoProviderId}
                                >
                                    {ssoProviders.items.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>

                            {selectedProvider && (
                                <>
                                    <hr className="settings-hr"></hr>
                                    <h3 className="settings-title">Who is allowed to use it?</h3>
                                    <Checkbox
                                        checked={ssoProviders.activeProvider.isAdminAllowed}
                                        onChange={(e) => onUserToggle(e.target.checked, 'is_admin_allowed')}
                                    >
                                        Admins
                                    </Checkbox>
                                    <Checkbox
                                        checked={ssoProviders.activeProvider.isAmbassadorAllowed}
                                        onChange={(e) => onUserToggle(e.target.checked, 'is_ambassador_allowed')}
                                    >
                                        Ambassadors
                                    </Checkbox>
                                    <Checkbox
                                        checked={ssoProviders.activeProvider.isProspectAllowed}
                                        onChange={(e) => onUserToggle(e.target.checked, 'is_prospect_allowed')}
                                    >
                                        Prospects
                                    </Checkbox>
                                    <hr className="settings-hr"></hr>
                                    <h3 className="settings-title">Configure Authentication for named provider</h3>
                                    {selectedProvider.key === 'open_id' ? (
                                        <OpenIdForm
                                            providerId={selectedProvider.id}
                                            data={
                                                ssoProviders.activeProvider.ssoProviderId === selectedProvider.id
                                                    ? ssoProviders.activeProvider
                                                    : null
                                            }
                                            onSave={onSave}
                                            onReset={onReset}
                                        />
                                    ) : (
                                        <SamlForm
                                            providerId={selectedProvider.id}
                                            providerKey={selectedProvider.key}
                                            data={
                                                ssoProviders.activeProvider.ssoProviderId === selectedProvider.id
                                                    ? ssoProviders.activeProvider
                                                    : null
                                            }
                                            onSave={onSave}
                                            onReset={onReset}
                                        />
                                    )}

                                    <div className="sso-deactivate-button-block">
                                        <Switch
                                            onChange={onSsoOnlyActivationChange}
                                            checked={isSsoOnlyActivated}
                                            className="sso-deactivate-button"
                                        />
                                        <span className="sso-deactivate-text">
                                            Allow sign-up / sign-in only with SSO
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="column resources">
                            <div className="resources-box">
                                <h3>Helpful resources</h3>
                                <ul>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/enabling-sso"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            alt="Working with SSO"
                                        >
                                            Enabling Single Sign-on (SSO)
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/sso-on-tap"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            alt="Working with SSO"
                                        >
                                            Configuring SSO on the TAP Dashboard
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/sso-on-tap-azure-ad"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            alt="Working with SSO"
                                        >
                                            SSO on TAP: Azure AD
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/sso-on-tap-google-saml"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            alt="Working with SSO"
                                        >
                                            SSO on TAP: Google SAML
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/logging-into-tap-with-sso"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            alt="Working with SSO"
                                        >
                                            Logging into the Admin Dashboard with SSO
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/logging-into-the-tap-feed-with-sso"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            alt="Working with SSO"
                                        >
                                            Logging into the TAP Feed with SSO
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    )
}

export default Sso
