import { all, call, takeEvery, select, put } from 'redux-saga/effects'

import API from '../../../api'
import { LIVE_STREAMS } from '../../actions/live-streams/live-streams-action-types'

import { CHAT_MESSAGES_INFO } from '../../actions/chat/messagingActions'
import { getIsGroupAdmin, getCurrentGroupUniversityId, getAccountSlice, getActiveLiveStream } from '../../selectors'

function* fetchLiveStreamsSaga({ payload }) {
    const isGroupAdmin = yield select(getIsGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
    const activeEvent = yield select(getActiveLiveStream)
    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        payload.universityId = currentGroupUniversityId || payload.university_id
    }
    const response = yield call(() => {
        return API.liveStreams.searchStreams(payload)
    })

    if (response.success) {
        const { data: { events = [], total = 0 } = {} } = response

        yield put({
            type: LIVE_STREAMS.FETCH_SUCCESS,
            payload: {
                items: events,
                total,
                currentOffset: payload.offset || 0,
                currentFilters: payload.filter || {},
            },
        })

        if (activeEvent.status) {
            yield put({
                type: activeEvent.status === 'onDemand' ? LIVE_STREAMS.FETCH_USERS : LIVE_STREAMS.FETCH_PROSPECTS,
                payload: {
                    liveStreamId: activeEvent.id,
                },
            })
        }
    }
}

function* handleSetActiveLiveStreamSaga({ payload }) {
    const { liveStreamId, blockUserUpdate } = payload

    const activeEvent = yield select(getActiveLiveStream)

    if (activeEvent.dialogId && !blockUserUpdate) {
        yield put({
            type: LIVE_STREAMS.FETCH_USERS,
            payload: {
                liveStreamId,
            },
        })
    }
}

function* handleFetchLiveStreamUsersSaga({ payload }) {
    const account = yield select(getAccountSlice)
    const activeEvent = yield select(getActiveLiveStream)
    const { liveStreamId } = payload

    const requestData = {
        liveStreamId,
        dialogId: activeEvent.dialogId,
        universityId: account.currentGroupUniversityId || account.account_info.university_id,
    }

    try {
        const response = yield call(() => {
            return API.liveStreams.getStreamMembers(requestData)
        })

        if (response.success) {
            yield put({
                type: LIVE_STREAMS.FETCH_USERS_SUCCESS,
                payload: {
                    liveStreamId,
                    items: response.data ? response.data.data : [],
                    userIds: response.data ? response.data.userIds : [],
                    total: response.data && response.data.data.length > 0 ? response.data.data.length : 0,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* handleFetchLiveStreamProspectsSaga({ payload }) {
    const account = yield select(getAccountSlice)
    const activeEvent = yield select(getActiveLiveStream)
    const { liveStreamId } = payload

    const requestData = {
        liveStreamId,
        dialogId: activeEvent.dialogId,
        universityId: account.currentGroupUniversityId || account.account_info.university_id,
    }

    try {
        const response = yield call(() => {
            return API.liveStreams.getProspects(requestData)
        })

        if (response.success) {
            yield put({
                type: LIVE_STREAMS.FETCH_PROSPECTS_SUCCESS,
                payload: {
                    liveStreamId,
                    items: response.data ? response.data.users : [],
                    userIds: response.data && response.data.userIds ? response.data.userIds : [],
                    total: response.data && response.data.users.length > 0 ? response.data.users.length : 0,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* handleFetchLiveStreamSaga({ payload }) {
    const { liveStreamId } = payload

    try {
        const response = yield call(() => {
            return API.liveStreams.getStream({ id: liveStreamId })
        })

        if (response.success) {
            yield put({
                type: LIVE_STREAMS.FETCH_LIVE_STREAM_DATA_SUCCESS,
                payload: {
                    liveStreamId,
                    streamData: response.data,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* watchLiveStreamsFetchSaga() {
    yield takeEvery(LIVE_STREAMS.FETCH, fetchLiveStreamsSaga)
}

function* watchSetActiveLiveStreamSaga() {
    yield takeEvery(CHAT_MESSAGES_INFO, handleSetActiveLiveStreamSaga)
}

function* watchFetchLiveStreamUsers() {
    yield takeEvery(LIVE_STREAMS.FETCH_USERS, handleFetchLiveStreamUsersSaga)
}

function* watchFetchLiveStreamProspects() {
    yield takeEvery(LIVE_STREAMS.FETCH_PROSPECTS, handleFetchLiveStreamProspectsSaga)
}

function* watchFetchLiveStream() {
    yield takeEvery(LIVE_STREAMS.FETCH_LIVE_STREAM_DATA, handleFetchLiveStreamSaga)
}

function* liveStreamsFetchSaga() {
    yield all([
        call(watchLiveStreamsFetchSaga),
        call(watchSetActiveLiveStreamSaga),
        call(watchFetchLiveStreamUsers),
        call(watchFetchLiveStreamProspects),
        call(watchFetchLiveStream),
    ])
}

export default liveStreamsFetchSaga
