import { call, put, select } from 'redux-saga/effects'

import API from '../../../api'
import { getDateRangeForMonth } from '../../../helpers/time'
import {
    OPEN_ACTION_MODAL,
    CLOSE_SETTINGS_MESSAGING_POPUP,
    CLOSE_ACTION_MODAL,
} from '../../actions/components/componentsActions'
import {
    USERS_MESSAGING_INFO_SUCCESS,
    USERS_MESSAGING_INFO_FAILED,
    AMBASSADORS_INFO_SUCCESS,
    AMBASSADORS_INFO_FAILED,
    CONTENT_GROUPS_INFO_SUCCESS,
    CONTENT_GROUPS_INFO_FAILED,
    CONTENT_GROUPS_INFO,
    DIALOG_MESSAGES_INFO_SUCCESS,
    DIALOG_MESSAGES_INFO_FAILED,
    VIDEO_TRANSCODE_FAILED,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAILED,
    DIALOG_MESSAGES_INFO,
    CLOSE_CONTENT_GROUP_POPUP,
    CREATE_CONTENT_GROUP_SUCCESS,
    CREATE_CONTENT_GROUP_FAILED,
    EDIT_CONTENT_GROUP_SUCCESS,
    EDIT_CONTENT_GROUP_FAILED,
    CLOSE_CONTENT_GROUP_SUCCESS,
    CLOSE_CONTENT_GROUP_FAILED,
    SET_ACTIVE_CONTENT_GROUP,
    RELOAD_CONTENT_GROUP_SUCCESS,
    RELOAD_CONTENT_GROUP,
    CLEAR_MESSAGES,
    EDIT_MESSAGE_POST_GLOBAL_TAG_FINISH,
    RESET_DIALOG_MESSAGES_COUNTER,
    LOAD_OLD_MESSAGES_SUCCESS,
} from '../../actions/content/messagingActions'
import { fetchScheduleData } from '../../actions/content/scheduleActions'

import { ContentGroupTypeEnum } from 'src/types/general'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* messagingUsers(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.universityUsers(
                payload.filter,
                payload.search,
                payload.sort,
                payload.limit,
                payload.offset
            )
        })

        if (response.success) {
            yield put({
                type: USERS_MESSAGING_INFO_SUCCESS,
                payload: {
                    items: response.data.users,
                    total: response.data.total,
                },
            })

            if (payload.func) payload.func(response.data.users)
        } else {
            yield put({
                type: USERS_MESSAGING_INFO_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: USERS_MESSAGING_INFO_FAILED,
        })
    }
}

export function* ambassadorsInfo(action) {
    const payload = action.payload

    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        const response = yield call(() => {
            return API.dashboard.universityAmbassadors(payload.search, payload.roles, universityIds)
        })

        if (response.success) {
            yield put({
                type: AMBASSADORS_INFO_SUCCESS,
                payload: {
                    items: response.data.users,
                },
            })

            if (payload.func) payload.func(response.data.users)
        } else {
            yield put({
                type: AMBASSADORS_INFO_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: AMBASSADORS_INFO_FAILED,
        })
    }
}

export function* createContentGroup(action) {
    const payload = action.payload
    const { idpContentGroupType, ...restPayload } = payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        let universityId = null
        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            universityId = currentGroupUniversityId || payload.university_id
        }

        const response = yield call(() => {
            if (payload.idpContentGroupType === ContentGroupTypeEnum.Broadcast) {
                return API.dashboardGroup.createBroadcastChannal(restPayload)
            } else if (payload.idpContentGroupType === ContentGroupTypeEnum.Content) {
                return API.dashboardGroup.createContentGroup(restPayload)
            } else {
                return API.dashboard.createContentGroup(
                    payload.name,
                    payload.description,
                    payload.selectedUserIds,
                    payload.globalTagsIds,
                    payload.avatar,
                    payload.eventSettings,
                    universityId
                )
            }
        })

        if (response.success) {
            yield put({
                type: CREATE_CONTENT_GROUP_SUCCESS,
            })

            yield put({
                type: CONTENT_GROUPS_INFO,
                payload: {
                    isFirstRequest: true,
                    isIdpGroupAdmin: idpContentGroupType || false,
                },
            })

            yield put({
                type: CLEAR_MESSAGES,
            })

            if (!payload.eventSettings) {
                yield put({
                    type: SET_ACTIVE_CONTENT_GROUP,
                    payload: {
                        content_group_id: response.data.dialog_id || response.data.id,
                    },
                })
            }

            yield put({
                type: CLOSE_CONTENT_GROUP_POPUP,
            })

            if (payload.eventSettings) {
                yield put(fetchScheduleData(getDateRangeForMonth(payload.eventSettings.date)))
            }

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: payload.eventSettings
                        ? 'Content group has been created. It will be displayed in the list of groups by the time of the event, and ambassadors will receive push notifications'
                        : 'Content group created',
                },
            })
        } else {
            yield put({
                type: CREATE_CONTENT_GROUP_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CREATE_CONTENT_GROUP_FAILED,
        })
    }
    if (payload.runAfter) payload.runAfter()
}

export function* contentGroupsInfo(action) {
    const payload = action.payload
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        const response = yield call(() => {
            return payload?.isIdpGroupAdmin
                ? API.dashboardGroup.getContentGroups()
                : API.dashboard.dialogsInfo(2, null, true, null, universityIds)
        })

        yield response.success
            ? put({
                  type: CONTENT_GROUPS_INFO_SUCCESS,
                  payload: {
                      items: response.data.dialogs,
                  },
              })
            : put({
                  type: CONTENT_GROUPS_INFO_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: CONTENT_GROUPS_INFO_FAILED,
        })
    }
}

export function* messagesInfo(action) {
    const {
        dialog_id: dialogId,
        withGlobalTags,
        type_chat: typeChat,
        blockReloadContentGroup,
        beforeMessageId,
        limit,
        previousScrollHeight,
    } = action.payload
    try {
        const response = yield call(() => {
            return API.dashboard.contentMessagesInfo({ dialogId, withGlobalTags, beforeMessageId, limit })
        })

        if (response.success) {
            yield beforeMessageId && previousScrollHeight
                ? put({
                      type: LOAD_OLD_MESSAGES_SUCCESS,
                      payload: {
                          items: response.data.messages,
                          previousScrollHeight,
                      },
                  })
                : put({
                      type: DIALOG_MESSAGES_INFO_SUCCESS,
                      payload: {
                          items: response.data.messages,
                          type: typeChat,
                          dialog_id: dialogId,
                          total: response.data.total,
                      },
                  })

            if (!blockReloadContentGroup) {
                yield put({
                    type: RELOAD_CONTENT_GROUP,
                    payload: {
                        dialog_id: dialogId,
                        type: typeChat,
                    },
                })
            } else if (blockReloadContentGroup) {
                yield put({
                    type: RESET_DIALOG_MESSAGES_COUNTER,
                    payload: {
                        dialog_id: dialogId,
                    },
                })
            }
        } else {
            yield put({
                type: DIALOG_MESSAGES_INFO_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: DIALOG_MESSAGES_INFO_FAILED,
        })
    }
}

export function* videoTranscode(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.videoTranscode(payload.formData, payload.dialog_id)
        })

        yield response.success
            ? call(() => {
                  return payload.callback(response.data.job_id, response.data.file)
              })
            : put({
                  type: VIDEO_TRANSCODE_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: VIDEO_TRANSCODE_FAILED,
        })
    }
}

export function* sendMessage(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.dashboard.sendMessage(payload)
        })

        if (response.success) {
            yield put({
                type: SEND_MESSAGE_SUCCESS,
            })

            yield put({
                type: DIALOG_MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                    type: payload.type_chat,
                    scrollBottom: true,
                },
            })

            if (payload.file_id) {
                yield call(() => {
                    return payload.callback()
                })
            }
        } else {
            yield put({
                type: SEND_MESSAGE_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: SEND_MESSAGE_FAILED,
        })
    }
}
/*
// Do we need messagesView ? (seems we are not using it now)
export function * messagesView(action) {
    const payload = action.payload;

    try {
        yield call(() => {
            return API.dashboard.messagesView(payload.dialog_id);
        });

        yield put({
            type: RELOAD_CONTENT_GROUP,
            payload: {
                dialog_id: payload.dialog_id,
                type: payload.type_chat
            }
        });
    } catch (e) {
        console.log(e);

        yield put({
            type: DIALOG_MESSAGES_INFO_FAILED
        });
    }
} */

export function* closeContentGroup(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.closeContentGroup(payload.dialog_id)
        })

        if (response.success) {
            yield put({
                type: CLOSE_CONTENT_GROUP_SUCCESS,
            })

            yield put({
                type: CONTENT_GROUPS_INFO,
            })

            yield put({
                type: CLOSE_SETTINGS_MESSAGING_POPUP,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your content group has been updated',
                },
            })
        } else {
            yield put({
                type: CLOSE_CONTENT_GROUP_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CLOSE_CONTENT_GROUP_FAILED,
        })
    }
}

export function* editContentGroup(action) {
    const payload = action.payload
    const { name, users, avatar, globalTagsIds, dialogId, afterFinished, description } = payload

    try {
        const response = yield call(() => {
            return API.dashboard.editDialog(name, users, avatar, globalTagsIds, dialogId, '', false, description)
        })

        if (response.success) {
            yield put({
                type: EDIT_CONTENT_GROUP_SUCCESS,
            })

            yield put({
                type: CONTENT_GROUPS_INFO,
            })

            yield put({
                type: CLOSE_SETTINGS_MESSAGING_POPUP,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your content group has been edited',
                },
            })
        } else {
            yield put({
                type: EDIT_CONTENT_GROUP_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: EDIT_CONTENT_GROUP_FAILED,
        })
    }
    if (afterFinished) afterFinished()
}

export function* deleteContentMessage(action) {
    const { payload } = action
    try {
        const response = yield call(() => {
            return API.chat.deleteMessage(payload.dialogId, payload.messageId)
        })
        if (response.success) {
            yield put({
                type: CLOSE_ACTION_MODAL,
            })

            yield put({
                type: DIALOG_MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialogId,
                    scrollBottom: true,
                },
            })
        }
    } catch {}
}

export function* reloadContentGroup(action) {
    const payload = action.payload

    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        const response = yield call(() => {
            return API.dashboard.dialogsInfo(payload.type, payload.dialog_id, true, null, universityIds)
        })

        if (response.success && response.data && response.data.dialog && response.data.dialog.id) {
            yield put({
                type: RELOAD_CONTENT_GROUP_SUCCESS,
                payload: {
                    dialog: response.data.dialog,
                },
            })
        }
    } catch {}
}

export function* changeMessagePostGlobalTags(action) {
    yield put({
        type: EDIT_MESSAGE_POST_GLOBAL_TAG_FINISH,
        payload: action.payload,
    })
}
