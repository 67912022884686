import { call, put, select } from 'redux-saga/effects'
import API from '../../../api'

import {
    CHATS_INFO_FAILED,
    CHATS_INFO_SEARCH,
    CHATS_INFO_SUCCESS,
    CHAT_INFO_FAILED,
    CHAT_INFO_SUCCESS,
    CHAT_MESSAGES_INFO,
    CHAT_MESSAGES_INFO_FAILED,
    CHAT_MESSAGES_INFO_SUCCESS,
    EDIT_CHAT_FAILED,
    EDIT_CHAT_SUCCESS,
    FIND_OR_CREATE_CHAT_FAILED,
    FIND_OR_CREATE_CHAT_SUCCESS,
    GET_CHATS_TRANSCRIPT_DATA_FAILED,
    LOAD_AROUND_MESSAGES_SUCCESS,
    LOAD_NEW_MESSAGES_SUCCESS,
    LOAD_OLD_MESSAGES_SUCCESS,
    PIN_MESSAGE_SUCCESS,
    RESET_ACTIVE_CHAT,
    RESET_CHAT_MESSAGES_COUNTER,
    SEND_CHAT_MESSAGE_FAILED,
    SEND_CHAT_MESSAGE_SUCCESS,
    SET_ACTIVE_CHAT,
    SET_BLOCKED_REASON,
    SET_CHATS_TRANSCRIPT_SETTINGS,
    SET_REPORT_REASON,
    UNFLAG_DIALOG_FAILED,
    UNFLAG_DIALOG_SUCCESS,
    UNPIN_MESSAGE_SUCCESS,
    UNWORD_DIALOG_FAILED,
    UNWORD_DIALOG_SUCCESS,
    UPDATE_ACTIVE_CHAT,
    UPDATE_CHAT_FIRST_MESSAGE,
    UPDATE_MESSAGE,
} from '../../actions/chat/messagingActions'

import {
    CLOSE_ACTION_MODAL,
    CLOSE_SETTINGS_MESSAGING_POPUP,
    HIDE_NEW_MESSAGES,
    NEW_MESSAGES_RECEIVED,
    OPEN_ACTION_MODAL,
    REPLY_MESSAGE,
} from '../../actions/components/componentsActions'

import types from '../../actions/content/ambassadorsActions'
import { closeNotificationModal } from '../../actions/notification-modal/notification-modal-actions'

import { ChatType } from '../../../common/constants'
import { formatChatMessage } from '../../../helpers/text'

const getCurrentGroupUniversityId = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null
const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

export function* chatsInfoSearch(action) {
    const payload = action.payload
    try {
        yield put({
            type: SET_CHATS_TRANSCRIPT_SETTINGS,
            payload,
        })
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.chatsSearch(payload, universityIds)
        })

        if (response.success) {
            // When we've received new messages
            if (payload.newMessagesCheck) {
                try {
                    for (const dialogKey in response.data.dialogs) {
                        const dialog = response.data.dialogs[dialogKey]
                        if (dialog.id === payload.dialog_id && +dialog.new_messages) {
                            yield put({
                                type: NEW_MESSAGES_RECEIVED,
                            })
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            }

            yield put({
                type: CHATS_INFO_SUCCESS,
                payload: {
                    items: response.data.dialogs,
                    offset: payload.offset,
                },
            })
            if (payload.setNewGroupChat && response.data.dialogs && response.data.dialogs.length > 0) {
                yield put({
                    type: REPLY_MESSAGE,
                    payload: {
                        activeMessage: null,
                        activePublisher: null,
                    },
                })
                yield put({
                    type: SET_ACTIVE_CHAT,
                    payload: {
                        data: response.data.dialogs.find((item) => item.id === payload.chatId),
                        chat_id: payload.chatId,
                        type_chat: ChatType.GROUP_CHAT,
                    },
                })

                yield put({
                    type: CHAT_MESSAGES_INFO,
                    payload: {
                        dialog_id: payload.chatId,
                        type_chat: ChatType.GROUP_CHAT,
                        blockReloadChat: true,
                        resetMessagesCounter: true,
                    },
                })

                yield put({
                    type: HIDE_NEW_MESSAGES,
                })
            }
        } else {
            yield put({
                type: CHATS_INFO_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CHATS_INFO_FAILED,
        })
    }
}

export function* getChatsTranscript(action) {
    const payload = action.payload

    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        const response = yield call(() => {
            return API.dashboard.getChatsTranscript(payload, universityIds)
        })

        if (response.success) {
            action.onSuccess(response.data)
        } else {
            yield put({
                type: GET_CHATS_TRANSCRIPT_DATA_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: GET_CHATS_TRANSCRIPT_DATA_FAILED,
        })
    }
}

export function* getChatTranscript(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.getChatTranscript(payload.dialogId)
        })

        if (response.success) {
            action.onSuccess(response.data)
        }
    } catch (error) {
        console.log(error)
    }
}

export function* chatInfoSearch(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.dashboard.chatsSearch(payload)
        })

        if (response.success) {
            try {
                for (const dialogKey in response.data.dialogs) {
                    const dialog = response.data.dialogs[dialogKey]
                    if (dialog.id === payload.currentDialogId && +dialog.new_messages) {
                        yield put({
                            type: NEW_MESSAGES_RECEIVED,
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }

            yield put({
                type: CHAT_INFO_SUCCESS,
                payload: {
                    dialog: response.data.dialogs[0],
                },
            })
            if (action.onSuccess) {
                action.onSuccess(response.data)
            }
        } else {
            yield put({
                type: CHAT_INFO_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CHAT_INFO_FAILED,
        })
    }
}

export function* getAmbassadorsList(action) {
    const payload = action.payload
    try {
        const currentGroupUniversityIds = yield select(getCurrentGroupUniversityId)

        if (currentGroupUniversityIds) {
            payload.universityIds = currentGroupUniversityIds
        }
        const response = yield call(() => {
            return API.chat.getAmbassadorsList(payload)
        })

        if (response.success) {
            yield put({
                type: types.AMBASSADORS_LIST_SUCCESS,
                payload: response.data,
            })
            action.onSuccess(response.data)
        } else {
            yield put({
                type: types.AMBASSADORS_LIST_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: types.AMBASSADORS_LIST_FAILED,
        })
    }
}

export function* findOrCreateChat(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.findOrCreate(payload.user_id)
        })

        if (response.success) {
            yield put({
                type: FIND_OR_CREATE_CHAT_SUCCESS,
                payload: {
                    dialog_id: response.data.dialog_id,
                },
            })
            if (payload.afterCreated && response.data.dialog_id) payload.afterCreated(response.data.dialog_id)
        } else {
            yield put({
                type: FIND_OR_CREATE_CHAT_FAILED,
            })
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: FIND_OR_CREATE_CHAT_FAILED,
        })
    }
}

export function* messagesChatInfo(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.dashboard.messagesInfo(
                payload.dialog_id,
                payload.withGlobalTags,
                payload.beforeMessageId,
                payload.afterMessageId,
                payload.limit,
                payload.aroundMessageId
            )
        })

        if (response && response.success) {
            const messages = response.data.messages.map((message) => {
                if (message.content === null) {
                    return {
                        ...message,
                        content: null,
                    }
                }
                return {
                    ...message,
                    content: {
                        ...message.content,
                        text: message.content.text ? formatChatMessage(message.content.text) : '',
                    },
                }
            })

            if (payload.afterMessageId) {
                yield put({
                    type: LOAD_NEW_MESSAGES_SUCCESS,
                    payload: {
                        items: messages,
                    },
                })
            } else if (payload.beforeMessageId) {
                yield put({
                    type: LOAD_OLD_MESSAGES_SUCCESS,
                    payload: {
                        items: messages,
                    },
                })
            } else if (payload.aroundMessageId) {
                yield put({
                    type: LOAD_AROUND_MESSAGES_SUCCESS,
                    payload: {
                        items: messages,
                    },
                })
            } else {
                yield put({
                    type: CHAT_MESSAGES_INFO_SUCCESS,
                    payload: {
                        items: messages,
                        total: response.data.total,
                        topics: response.data.topics,
                        type: payload.type_chat,
                        dialog_id: payload.dialog_id,
                        totalReportedMessages: response.data.totalReportedMessages,
                        pinnedMessages: response.data.pinned_messages,
                    },
                })
            }

            if (!payload.blockReloadChat) {
                yield put({
                    type: CHATS_INFO_SEARCH,
                    payload: {
                        type_chat: payload.type_chat,
                        withGlobalTags: payload.withGlobalTags,
                        archived: payload.archived,
                    },
                })
            } else if (payload.resetMessagesCounter) {
                yield put({
                    type: RESET_CHAT_MESSAGES_COUNTER,
                    payload: {
                        dialog_id: payload.dialog_id,
                        dialogId: payload.dialog_id,
                    },
                })
            }

            if (action.onFinish) action.onFinish()
            /* if (payload.scrollBottom) {
                $(".chat-body").animate({
                    scrollTop: document.getElementById("chat-body").scrollHeight
                }, 200);
            } else if (payload.scrollBottomWithNoDelay) {
                const element = document.getElementById("chat-body");
                if (element && element.scrollTop != element.scrollHeight) element.scrollTop = element.scrollHeight;
            } */
        } else {
            yield put({
                type: CHAT_MESSAGES_INFO_FAILED,
            })
            yield put({
                type: CHATS_INFO_SEARCH,
                payload: {
                    withGlobalTags: payload.withGlobalTags,
                    archived: payload.archived,
                },
            })
            if (action.onFinish) action.onFinish(false, false)
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CHAT_MESSAGES_INFO_FAILED,
        })
        if (action.onFinish) action.onFinish(false, true)
    }
}

export function* sendChatMessage(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.sendMessage(payload)
        })

        if (response.success) {
            yield put({
                type: SEND_CHAT_MESSAGE_SUCCESS,
            })

            yield put({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialog_id,
                    type: payload.type_chat,
                    scrollBottom: true,
                },
            })

            if (payload.file_id) {
                yield call(() => {
                    return payload.callback()
                })
            }
            if (action.onSuccess) action.onSuccess()
        } else {
            yield put({
                type: SEND_CHAT_MESSAGE_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: SEND_CHAT_MESSAGE_FAILED,
        })
    }
}

export function* editChat(action) {
    const payload = action.payload
    const {
        name,
        users,
        avatar,
        dialog_id: dialogId,
        globalTagsIds,
        firstMessage,
        is_private: isPrivate,
        type_chat: typeChat,
        afterFinished,
    } = payload

    try {
        const response = yield call(() => {
            return API.dashboard.editDialog(name, users, avatar, globalTagsIds, dialogId, firstMessage, isPrivate)
        })

        if (response.success) {
            yield put({
                type: EDIT_CHAT_SUCCESS,
            })

            yield put({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: dialogId,
                    withGlobalTags: true,
                    type_chat: typeChat || ChatType.CHAT,
                },
            })

            yield put({
                type: CLOSE_SETTINGS_MESSAGING_POPUP,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your chat has been edited',
                },
            })
            yield put({
                type: UPDATE_CHAT_FIRST_MESSAGE,
                payload: {
                    firstMessage,
                },
            })
        } else {
            yield put({
                type: EDIT_CHAT_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: EDIT_CHAT_FAILED,
        })
    }
    if (afterFinished) afterFinished()
}

export function* closeDialog(action) {
    const { payload, onSuccess } = action
    try {
        const response = yield call(() => {
            return API.chat.closeDialog(payload.dialogId)
        })
        if (response.success) {
            yield put({
                type: CHATS_INFO_SEARCH,
                payload: {
                    type_chat: payload.type_chat || ChatType.CHAT,
                    withGlobalTags: payload.withGlobalTags,
                },
            })

            yield put({
                type: CLOSE_ACTION_MODAL,
            })
            if (onSuccess) onSuccess()
        }
    } catch {}
}

export function* archiveDialog(action) {
    const { payload, onSuccess } = action
    try {
        const response = yield call(() => {
            return API.chat.archiveDialog(payload.dialogId)
        })
        if (response.success) {
            yield put({
                type: RESET_ACTIVE_CHAT,
            })

            yield put({
                type: CHATS_INFO_SEARCH,
                payload: {
                    type_chat: payload.type_chat || ChatType.CHAT,
                    withGlobalTags: payload.withGlobalTags,
                    archived: payload.archived,
                },
            })

            yield put({
                type: CLOSE_ACTION_MODAL,
            })
            if (onSuccess) onSuccess()
        }
    } catch {}
}

export function* deleteChatMessage(action) {
    const { payload } = action
    try {
        const response = yield call(() => {
            return API.chat.deleteMessage(payload.dialogId, payload.messageId)
        })
        if (response.success) {
            yield put({
                type: CLOSE_ACTION_MODAL,
            })

            yield put({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialogId,
                    type_chat: payload.type_chat,
                },
            })
        }
    } catch {}
}

export function* unflagDialog(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.unflagDialog(payload.dialogId)
        })

        if (response.success) {
            yield put({
                type: UNFLAG_DIALOG_SUCCESS,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Conversation has been unreported!',
                },
            })

            yield put({
                type: CHATS_INFO_SEARCH,
                payload: {
                    withGlobalTags: payload.withGlobalTags,
                },
            })

            const elem = document.querySelector('.unflag')
            if (elem) elem.classList.add('hidden-block')
        } else {
            yield put({
                type: UNFLAG_DIALOG_FAILED,
            })
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: UNFLAG_DIALOG_FAILED,
        })
    }
}

export function* unwordDialog(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.unwordDialog(payload.messageId)
        })

        if (response.success) {
            yield put({
                type: UNWORD_DIALOG_SUCCESS,
            })

            yield put({
                type: UPDATE_MESSAGE,
                payload: { messageId: payload.messageId },
            })
        } else {
            yield put({
                type: UNWORD_DIALOG_FAILED,
            })
        }
    } catch (error) {
        console.log(error)

        yield put({
            type: UNWORD_DIALOG_FAILED,
        })
    }
}

export function* unblockChatMember(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.chat.unblockChatMember(payload.dialogId, payload.userId)
        })
        if (response && response.success) {
            yield put({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialogId,
                    type_chat: payload.type_chat,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* muteChatTrigger(action) {
    const { payload, onSuccess } = action
    try {
        const response = yield call(() => {
            return API.chat.muteChatTrigger(payload.dialogId, payload.is_mute)
        })
        if (response.success) {
            yield put({
                type: CHATS_INFO_SEARCH,
                payload: {
                    type_chat: payload.type_chat || ChatType.CHAT,
                    withGlobalTags: payload.withGlobalTags,
                },
            })

            yield put({
                type: CLOSE_ACTION_MODAL,
            })
            onSuccess()
        }
    } catch {}
}

export function* blockProspectToChat(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.chat.blockProspectToChat(payload.blockingReason, payload.universityId, payload.userId)
        })
        if (response && response.success) {
            yield put({
                type: CLOSE_ACTION_MODAL,
            })
            payload.onSuccess(response)
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Prospect is blocked',
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getBlockedProspectData(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.chat.getBlockedProspectData(payload.universityId, payload.userId)
        })
        if (response && response.success) {
            yield put({
                type: SET_BLOCKED_REASON,
                payload: {
                    blockedData: response.blockedData,
                    userId: payload.userId,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getReportReasonData(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.chat.getReportReasonData(payload.dialogId)
        })
        if (response && response.success) {
            yield put({
                type: SET_REPORT_REASON,
                payload: {
                    reportedData: response.data,
                    dialogId: payload.dialogId,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* pinMessage(action) {
    const { payload } = action
    try {
        const response = yield call(() => {
            return API.chat.handleMessagePin({
                dialogId: payload.message.dialog_id,
                messageId: payload.message.id,
                isPinned: true,
            })
        })
        if (response.success) {
            yield put({
                type: PIN_MESSAGE_SUCCESS,
                payload: {
                    message: payload.message,
                },
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Message pinned!',
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* unpinMessage(action) {
    const { payload } = action
    try {
        const response = yield call(() => {
            return API.chat.handleMessagePin({
                dialogId: payload.dialogId,
                messageId: payload.messageId,
                isPinned: false,
            })
        })
        if (response.success) {
            yield put({
                type: UNPIN_MESSAGE_SUCCESS,
                payload: {
                    messageId: payload.messageId,
                },
            })
            yield put(closeNotificationModal())
        }
    } catch (error) {
        console.log(error)
    }
}

export function* getDialogById(action) {
    const { payload } = action
    try {
        const response = yield call(() => {
            return API.chat.getDialogById({
                dialogId: payload.dialogId,
            })
        })
        if (response.success) {
            yield put({
                type: UPDATE_ACTIVE_CHAT,
                payload: {
                    dialogData: response.data.dialog,
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}
