import { add, format, startOfDay, isSameDay, roundToNearestMinutes, addMinutes } from 'date-fns'

import { LIVE_STREAM_STATUS } from '../../../../../common/constants'

const TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm"

const prepareImageCode = (image) => image.slice(image.indexOf(',') + 1)
const formatToISO = (date) => format(date, TIMESTAMP_FORMAT)

export default (
    {
        name,
        image,
        description,
        date,
        fromTime,
        toTime,
        timezoneId,
        globalTagsIds,
        isPromote,
        isGroupPromote,
        internalSpeakerIds,
        externalSpeakerEmails,
        userIds,
        status,
        autoPublish,
    },
    data = {},
    imageTouched = false
) => {
    const { id, status: streamStatus } = data

    if (!id) {
        const isSameDayChosen = isSameDay(date, new Date())
        const eventDay = isSameDayChosen
            ? roundToNearestMinutes(addMinutes(new Date(), 30), { nearestTo: 15 })
            : startOfDay(date)

        const fromDate = formatToISO(
            add(eventDay, {
                minutes: fromTime,
            })
        )

        const toDate = formatToISO(
            add(eventDay, {
                minutes: toTime,
            })
        )

        return {
            name,
            image: prepareImageCode(image),
            description,
            fromDate,
            toDate,
            timezoneId,
            internalSpeakerIds,
            externalSpeakerEmails: externalSpeakerEmails
                .filter(({ email }) => email.length > 0)
                .map(({ email }) => email),
            globalTagsIds: globalTagsIds.map(({ id }) => +id),
            userIds,
            isPromote: !isPromote,
            isGroupPromote: !isGroupPromote,
            status,
            autoPublish,
        }
    }

    if (id) {
        if (streamStatus === LIVE_STREAM_STATUS.IN_PROGRESS) {
            return {
                liveStreamId: id,
                name,
                description,
                globalTagsIds: globalTagsIds.map(({ id }) => +id),
            }
        }
        const isSameDayChosen = isSameDay(date, new Date())
        const eventDay = isSameDayChosen
            ? roundToNearestMinutes(addMinutes(new Date(), 30), { nearestTo: 15 })
            : startOfDay(date)

        const fromDate = formatToISO(
            add(eventDay, {
                minutes: fromTime,
            })
        )

        const toDate = formatToISO(
            add(eventDay, {
                minutes: toTime,
            })
        )

        return {
            liveStreamId: id,
            name,
            image: imageTouched ? prepareImageCode(image) : undefined,
            description,
            fromDate,
            toDate,
            timezoneId,
            globalTagsIds: globalTagsIds.map(({ id }) => +id),
            isPromote: !isPromote,
            isGroupPromote: !isGroupPromote,
            editType: 'event',
            autoPublish,
        }
    }
}
