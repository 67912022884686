import UserModel from './UserModel'

const ProspectModel = (data) => {
    const { name } = data

    return {
        ...data,
        name: name || '',
        role_name: 'Participant',
        prospect: true,
    }
}

const mapUsers = (items) => items.map((item) => UserModel(item))
const mapProspects = (items) => items.map((item) => ProspectModel(item))

export default (data = {}) => {
    const {
        id,
        name,
        description,
        image,
        video,
        timezone_id: timezoneId,
        timezoneName,
        from_date: fromDate,
        to_date: toDate,
        dialogs,
        status,
        statusName,
        state,
        is_promote: isPromote,
        is_group_promote: isGroupPromote,
        globalTags,
        members = [],
        prospects = [],
        speakers = [],
        userIds = [],
        totalProspects,
        liveStreamLink,
        liveStreamIframelessLink,
        is_auto_publish: autoPublish,
    } = data
    return {
        id,
        name,
        description,
        image,
        timezoneId,
        timezoneName,
        fromDate: fromDate || null,
        toDate: toDate || null,
        status,
        statusName,
        state,
        video,
        dialogId: dialogs ? dialogs.find((item) => item.typeKey === 'live-stream-chat').id : null,
        dialogs,
        isPromote,
        isGroupPromote,
        globalTags,
        members: mapUsers(members),
        prospects: mapProspects(prospects),
        speakers: mapUsers(speakers),
        userIds,
        totalProspects,
        liveStreamLink,
        liveStreamIframelessLink,
        autoPublish,
    }
}
