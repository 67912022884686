import {
    CHATS_INFO_SEARCH,
    CHATS_INFO_SUCCESS,
    CHAT_INFO_SUCCESS,
    FLAG_DIALOG,
    RESET_CHAT_FILTERS,
    RESET_CHAT_MESSAGES_COUNTER,
    SET_BLOCKED_REASON,
    SET_CHATS_TRANSCRIPT_SETTINGS,
    SET_REPORT_REASON,
} from '../../../actions/chat/messagingActions'

import { RELOAD_CONTENT_GROUP_SUCCESS } from '../../../actions/content/messagingActions'
import { CHATS } from '../../../store/initialState'

import { produce } from 'immer'
import ChatModel from '../../../../models/ChatModel'

const initialState = CHATS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case CHATS_INFO_SUCCESS: {
            // eslint-disable-next-line unicorn/prefer-spread
            const newItems = (payload.offset ? [...state.lastItems.concat(payload.items)] : payload.items) || []
            for (const item in newItems) {
                newItems[item] = ChatModel(newItems[item])
            }
            const newState = Object.assign({}, state, {
                items: newItems,
                lastItems: newItems,
                lastReceivedCount: payload.items.length,
                lastOffset: payload.offset || 0,
                isLoading: false,
                isFirstRequest: false,
            })

            return newState
        }

        case CHATS_INFO_SEARCH: {
            payload.search = payload.search ? payload.search : payload.resetSearch ? null : state.search

            let filters = JSON.parse(JSON.stringify(state.filters))
            if (payload.searchBy) {
                const filtersNew = {}
                if (payload.searchBy.unseenByAdmin) filters.unseenByAdmin = true
                if (payload.searchBy.userId) {
                    filtersNew.userId = payload.searchBy.userId
                    filters.userId = payload.searchBy.userId
                    filters.userName = payload.searchBy.userName
                }
                if (payload.searchBy.feedback) {
                    for (const item in filters.feedbackItems) {
                        if (filters.feedbackItems[item].key === payload.searchType) {
                            filtersNew.feedback = payload.searchType
                            filters.feedback = filters.feedbackItems[item].key
                            filters.feedbackItems[item].checked = true
                            if (payload.searchBy.unseenByAdmin) {
                                filtersNew.adminUnseenFeedback = true
                            }
                        }
                    }
                }
                if (payload.searchBy.conversationStatus) {
                    for (const item in filters.conversationStatusItems) {
                        if (filters.conversationStatusItems[item].key === payload.searchType) {
                            filtersNew.conversationStatus = payload.searchType
                            filters.conversationStatus = filters.conversationStatus[item].key
                            filters.conversationStatus[item].checked = true
                        }
                    }
                }
                for (const item in filters.items) {
                    if (payload.searchBy[filters.items[item].key]) {
                        filtersNew[filters.items[item].key] = true
                        filters.items[item].checked = true
                        if (payload.searchBy.unseenByAdmin) {
                            filtersNew.adminUnseen = true
                        }
                    }
                }
                payload.filters = filtersNew
            } else if (payload.filters) {
                let cleanFilters = {}
                filters.userId = payload.filters.userId
                filters.userName = payload.filters.userName
                filters.unseenByAdmin = payload.filters.unseenByAdmin
                filters.items = payload.filters.items
                filters.feedback = payload.filters.feedback
                filters.feedbackItems = payload.filters.feedbackItems
                filters.conversationStatus = payload.filters.conversationStatus
                filters.conversationStatusItems = payload.filters.conversationStatusItems
                filters.dates.fromDate = payload.filters.fromDate
                filters.dates.toDate = payload.filters.toDate
                filters.cleanDates.fromDate = payload.filters.cleanFromDate || null
                filters.cleanDates.toDate = payload.filters.cleanToDate || null
                filters.cleanItems = cleanFilters = { ...payload.filters.cleanItems }
                if (payload.filters.unseenByAdmin) {
                    cleanFilters.adminUnseen = true
                    cleanFilters.adminUnseenFeedback = true
                }
                if (payload.filters.userId) {
                    cleanFilters.userId = payload.filters.userId
                }
                if (payload.filters.feedback) {
                    cleanFilters.feedback = payload.filters.feedback
                }
                if (payload.filters.conversationStatus) {
                    cleanFilters.conversationStatus = payload.filters.conversationStatus
                }
                if (payload.filters.cleanFromDate) {
                    cleanFilters.fromDate = payload.filters.cleanFromDate
                }
                if (payload.filters.cleanToDate) {
                    cleanFilters.toDate = payload.filters.cleanToDate
                }
                payload.filters = cleanFilters
            } else {
                const filtersNew = { ...filters.cleanItems }
                if (filters.unseenByAdmin) {
                    filtersNew.adminUnseen = filters.unseenByAdmin
                    filtersNew.adminUnseenFeedback = filters.unseenByAdmin
                }
                if (filters.userId) {
                    filtersNew.userId = filters.userId
                }
                if (filters.feedback) {
                    filtersNew.feedback = filters.feedback
                }
                if (filters.conversationStatus) {
                    filtersNew.conversationStatus = filters.conversationStatus
                }
                if (filters.cleanDates.fromDate) {
                    filtersNew.fromDate = filters.cleanDates.fromDate
                }
                if (filters.cleanDates.toDate) {
                    filtersNew.toDate = filters.cleanDates.toDate
                }
                payload.filters = filtersNew
            }
            if (payload.resetSearchAndFilters) {
                payload.search = null
                filters = JSON.parse(JSON.stringify(initialState.filters))
                payload.filters = null
            }
            return {
                ...state,
                items: payload.withoutLoading ? [...state.items] : [],
                isLoading: !payload.withoutLoading,
                chatsType: payload.type ? payload.type : state.chatsType,
                search: payload.search,
                filters: filters,
                resetSearch: payload.resetSearchAndFilters || payload.resetSearch,
            }
        }

        case CHAT_INFO_SUCCESS: {
            if (!payload.dialog) {
                return state
            }
            // current dialog up in the list
            const items = [...state.items]
            const newItemsList = [payload.dialog]
            for (const item of items) {
                if (item.id !== payload.dialog.id) {
                    newItemsList.push(item)
                }
            }
            return {
                ...state,
                items: newItemsList,
            }
        }

        case RELOAD_CONTENT_GROUP_SUCCESS: {
            const dialog = payload.dialog
            return {
                ...state,
                items: state.items.map((item) => (item.id == dialog.id ? dialog : item)),
            }
        }

        case RESET_CHAT_MESSAGES_COUNTER: {
            return produce(state, (draft) => {
                const item = draft.items.find((item) => item.id === payload.dialog_id)
                if (item) {
                    item.new_messages = 0
                }
            })
        }

        case RESET_CHAT_FILTERS: {
            // TODO: make it through initial state

            const filterItems = state.filters.items.map((item) => ({ ...item, checked: false }))
            const feedbackItems = state.filters.feedbackItems.map((item) => ({ ...item, checked: false }))
            const conversationStatusItems = state.filters.conversationStatusItems.map((item) => ({
                ...item,
                checked: false,
            }))

            return {
                ...state,
                isLoading: true,
                search: null,
                filters: {
                    unseenByAdmin: false,
                    userId: null,
                    items: filterItems,
                    cleanItems: {},
                    dates: {
                        fromDate: null,
                        toDate: null,
                    },
                    cleanDates: {
                        fromDate: null,
                        toDate: null,
                    },
                    feedback: null,
                    feedbackItems: feedbackItems,
                    conversationStatus: null,
                    conversationStatusItems: conversationStatusItems,
                },
            }
        }

        case FLAG_DIALOG: {
            const flagged = state.items.findIndex((dialog) => dialog.id === payload.dialog_id)
            const items = [
                ...state.items.slice(0, flagged),
                { ...state.items[flagged], reported: true },
                ...state.items.slice(flagged + 1),
            ]
            return {
                ...state,
                items,
            }
        }

        case SET_CHATS_TRANSCRIPT_SETTINGS: {
            return {
                ...state,
                chatsTranscriptSettings: payload,
            }
        }

        case SET_BLOCKED_REASON: {
            return {
                ...state,
                blockedData: {
                    data: payload.blockedData,
                    userId: payload.userId,
                },
            }
        }

        case SET_REPORT_REASON: {
            return {
                ...state,
                reportedData: {
                    data: payload.reportedData,
                    dialogId: payload.dialogId,
                },
            }
        }

        default: {
            return state
        }
    }
}
