import { CheckCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { isNil } from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AudioLevel from './audio-level'
import StreamControls from '../stream-controls'
import {
    muteLocalAudio,
    muteLocalVideo,
    setAudioDevices,
    setVideoDevices,
} from '../../../../../../../../redux/actions/event-stream/event-stream-actions'
import { getEventStreamSlice } from '../../../../../../../../redux/selectors/common-selectors'

const STATUS_LINE_STYLE = {
    textAlign: 'center',
    padding: '8px',
}

const LocalMediaPreview = ({ localMedia, onLocalMediaStarted }) => {
    const dispatch = useDispatch()

    const { videoDevices } = useSelector(getEventStreamSlice)

    const videoBlockRef = useRef(null)

    const [audioLevel, setAudioLevel] = useState(0)

    const [localMediaLoading, setLocalMediaLoading] = useState(true)
    const [localMediaError, setLocalMediaError] = useState(null)
    const [controlsEnabled, setControlsEnabled] = useState(false)

    useEffect(() => {
        videoBlockRef.current.append(localMedia.getView())

        const updateAudioLevel = (value) => {
            setAudioLevel(Math.floor(value * 100))
        }

        localMedia.getAudioSourceInputs().then((inputs) => {
            dispatch(setAudioDevices(inputs))
        })

        localMedia.getVideoSourceInputs().then((inputs) => {
            dispatch(setVideoDevices(inputs))
        })

        localMedia
            .start()
            .then(() => {
                setControlsEnabled(true)
                setLocalMediaLoading(false)
                setLocalMediaError(null)
                onLocalMediaStarted(false)

                localMedia.addOnAudioLevel(updateAudioLevel)
            })
            .fail((ex) => {
                setLocalMediaLoading(false)
                setLocalMediaError(ex)
                onLocalMediaStarted(true)
                console.error(ex)
            })

        return () => {
            localMedia.removeOnAudioLevel(updateAudioLevel)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleMuteAudioClick = useCallback(() => {
        const isMuted = localMedia.getAudioMuted()
        localMedia.setAudioMuted(!isMuted)
        dispatch(muteLocalAudio(!isMuted))
    }, [dispatch, localMedia])

    const handleMuteVideoClick = useCallback(() => {
        const isMuted = localMedia.getVideoMuted()
        localMedia.setVideoMuted(!isMuted)
        localMedia.changeVideoSourceInput(isMuted ? videoDevices[0] : {})
        dispatch(muteLocalVideo(!isMuted))
    }, [dispatch, localMedia, videoDevices])

    const status = useMemo(() => {
        if (localMediaLoading) {
            return (
                <>
                    <Space>
                        <LoadingOutlined />
                        <span>Starting camera and microphone</span>
                    </Space>
                </>
            )
        } else if (!isNil(localMediaError)) {
            return (
                <>
                    <Space>
                        <WarningOutlined style={{ color: '#f5222d' }} />
                        <span>Can not start camera and microphone. Permission denied.</span>
                    </Space>
                </>
            )
        } else {
            return (
                <>
                    <Space>
                        <CheckCircleOutlined style={{ color: '#52c41a' }} />
                        <span>Camera and microphone ready</span>
                    </Space>
                </>
            )
        }
    }, [localMediaLoading, localMediaError])

    return (
        <>
            <div className="tap-event-stream">
                <div className="tap-event-stream-participants-wrapper video-preview">
                    <div className="tap-event-stream-participants" ref={videoBlockRef} />
                </div>
                <StreamControls
                    open={controlsEnabled}
                    onMuteAudioClick={handleMuteAudioClick}
                    onMuteVideoClick={handleMuteVideoClick}
                />
            </div>
            <div style={STATUS_LINE_STYLE}>{status}</div>
            <AudioLevel audioLevel={audioLevel} />
        </>
    )
}

export default LocalMediaPreview
