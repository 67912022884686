var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { getCountriesSlice } from 'src/app/redux/selectors';
import { getCountries } from 'src/app/redux/actions/settings/countries/countries-actions';
function optionsFetcher(dispatch) {
    return function () {
        dispatch(getCountries({ isLocationRelated: true }));
    };
}
var defaultOptionMapper = function (_a) {
    var name = _a.name, id = _a.id;
    return { label: name, value: id };
};
export var CountrySelect = function (props) {
    var dispatch = useAppDispatch();
    var items = useAppSelector(getCountriesSlice).items;
    var fetchOptions = useRef(optionsFetcher(dispatch));
    var _a = useState(), options = _a[0], setOptions = _a[1];
    useEffect(function () {
        if (items.length > 0) {
            var parsedItems = items.map(function (item) { return defaultOptionMapper(item); });
            setOptions(parsedItems);
        }
    }, [items]);
    var onDropdownVisibleChange = function (open) {
        if (open && !options) {
            fetchOptions.current();
        }
    };
    return (React.createElement(Select, __assign({ showSearch: true, placeholder: "Please select", optionFilterProp: "label", onDropdownVisibleChange: onDropdownVisibleChange, options: options }, props)));
};
