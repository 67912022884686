import Modal from 'antd/es/modal'
import dayjs from 'dayjs'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { Button } from 'antd'
import Sprite from '../../../../assets/img/symbol/sprite.svg'
import PostsSlider from '../../../components/PostsSlider'
import { RestrictedModal } from '../../../components/modals/RestrictedModal'
import { isAllowed } from '../../../helpers/helpers'
import {
    CLEAR_POSTS_STORE,
    OPEN_SHARE_POST_POPUP,
    POSTS_INFO_REQUESTED,
} from '../../../redux/actions/content/postsActions'
import store from '../../../redux/store'
import { PostsShareModal } from '../../content/dashboard/components/modals/PostsShareModal'

class PostsModal extends PureComponent {
    constructor(props) {
        super()

        this.state = {
            clickedPost: null,
            showRestrictedModal: false,
        }

        this.handleOpenSharePopup = this.handleOpenSharePopup.bind(this)
        this.handleDeleteIndex = this.handleDeleteIndex.bind(this)
    }

    componentDidMount() {
        const { account, posts, recentPosts, total, postsLimit, clickedSlideId, recentTotal } = this.props

        const visiblePostsIds = []
        const excludeBefore =
            total - posts.visiblePostsNumber < 10 ? (total - 10 > 0 ? total - 10 : 0) : posts.visiblePostsNumber
        recentPosts.map((post, index) => {
            if (+index < excludeBefore) {
                visiblePostsIds.push(post.id)
            }
        })
        store.dispatch({
            type: POSTS_INFO_REQUESTED,
            payload: {
                filter: {
                    university_id: account.account_info.university.id,
                    globalTagsIds: [],
                    fromDate: dayjs().subtract(7, 'd').format('YYYY-MM-DD') + 'T00:00:00Z',
                },
                postsInIds: clickedSlideId ? visiblePostsIds : null,
                postsNotInIds: clickedSlideId ? null : recentTotal - postsLimit <= postsLimit ? null : visiblePostsIds,
                offset: posts.posts.offset,
                withGlobalTags: true,
            },
        })
    }

    componentWillUnmount() {
        store.dispatch({
            type: CLEAR_POSTS_STORE,
        })
    }

    handleOpenSharePopup(post) {
        if (isAllowed('content_sharing')) {
            this.setState({ clickedPost: post })
            store.dispatch({
                type: OPEN_SHARE_POST_POPUP,
                payload: {
                    post: post,
                },
            })
        } else {
            this.setState({ showRestrictedModal: true })
        }
    }

    closeRestrictedModal = () => {
        this.setState({ showRestrictedModal: false })
    }

    handleDeleteIndex(deletedIndex) {
        const { posts } = this.props
        if (posts.posts.total) {
            const waitClearPostsTimmer = setInterval(() => {
                if (!posts.posts.total) {
                    const timmer = setInterval(() => {
                        if (posts.posts.total) {
                            clearInterval(timmer)
                            this.setState({ clickedSlide: deletedIndex })
                        }
                    }, 30)

                    clearInterval(waitClearPostsTimmer)
                }
            }, 10)
        } else {
            const timmer = setInterval(() => {
                if (posts.posts.total) {
                    clearInterval(timmer)
                    this.setState({ clickedSlide: deletedIndex })
                    this.forceUpdate()
                }
            }, 30)
        }
    }

    getNextPosts = () => {
        const { posts } = this.props.posts
        const ids = []
        posts.items.map((post, index) => {
            if ((post.images && post.images.length > 0) || (post.videos && post.videos.length > 0)) {
                ids.push(post.id)
            }
        })

        store.dispatch({
            type: POSTS_INFO_REQUESTED,
            payload: {
                filter: {
                    university_id: this.props.account.account_info.university.id,
                    globalTagsIds: [],
                    fromDate: dayjs().subtract(7, 'd').format('YYYY-MM-DD') + 'T00:00:00Z',
                },
                postsNotInIds: ids,
                offset: 0,
                withGlobalTags: true,
            },
        })
    }

    render() {
        const { clickedPost, showRestrictedModal } = this.state
        const { posts, clickedSlideId, handleClosePost } = this.props
        let clickedSlide = 0
        if (posts.posts.items.length === 0) {
            return <></>
        }
        posts.posts.items.map((post, index) => {
            if (+clickedSlideId === +post.id) {
                clickedSlide = index
            }
        })

        return (
            <>
                <Modal
                    open={true}
                    destroyOnClose={true}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                    onCancel={handleClosePost}
                    className="react-post-gallery"
                >
                    <div className="post-galery" id="post-galery">
                        <Button type="button" className="close-modal" onClick={handleClosePost}>
                            <svg className="icon icon-close">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#close'}></use>
                            </svg>
                        </Button>
                        <PostsSlider
                            handleShareButtonClick={this.handleOpenSharePopup}
                            initialSlide={clickedSlide}
                            handleOpenSharePopup={this.handleOpenSharePopup}
                            handleDeleteIndex={this.handleDeleteIndex}
                            getNextPosts={this.getNextPosts}
                        />
                    </div>
                </Modal>
                {isAllowed('content_sharing') && <PostsShareModal clickedSlide={clickedPost} />}
                {showRestrictedModal && <RestrictedModal closable={true} closeModal={this.closeRestrictedModal} />}
            </>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        posts: state.posts,
    }
})(PostsModal)
