import API from '../../../../api'
import { call, put, select } from 'redux-saga/effects'
import {
    GET_NOTIFICATIONS_LIST_SUCCESS,
    GET_NOTIFICATIONS_LIST_FAILED,
    DISABLE_TOGGLE,
} from '../../../actions/settings/notifications-control'

const getNotificationsControlItems = (state) => state.notificationsControl.items

export function* getNotificationsList(action) {
    try {
        const response = yield call(() => {
            return API.settings.getNotificationsList()
        })

        if (response.success) {
            yield put({
                type: GET_NOTIFICATIONS_LIST_SUCCESS,
                payload: {
                    items: response.data.settings,
                },
            })
        } else {
            yield put({
                type: GET_NOTIFICATIONS_LIST_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_NOTIFICATIONS_LIST_FAILED,
        })
    }
}

export function* toggleNotification(action) {
    const payload = action.payload
    try {
        const notificationsControlState = yield select(getNotificationsControlItems)

        const response = yield call(() => {
            return API.settings.toggleNotification(payload.requestData)
        })

        if (response.success) {
            let newState

            if (Object.prototype.hasOwnProperty.call(payload.notificationToggle, 'checked')) {
                newState = notificationsControlState.map((item, i) => {
                    if (payload.notificationToggle && item.key === payload.notificationToggle.name) {
                        item.is_active = payload.notificationToggle.checked
                    }
                    return item
                })
            } else {
                newState = payload.notificationToggle
            }

            yield put({
                type: DISABLE_TOGGLE,
                payload: false,
            })

            yield put({
                type: GET_NOTIFICATIONS_LIST_SUCCESS,
                payload: {
                    items: newState,
                },
            })
        } else {
            yield put({
                type: GET_NOTIFICATIONS_LIST_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_NOTIFICATIONS_LIST_FAILED,
        })
    }
}
