import React, { PureComponent } from 'react'

import { connect } from 'react-redux'
import store from '../../redux/store'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../redux/actions/content/componentsActions'
import Modal from 'antd/es/modal'
import thePartnerImg from '../../../assets/img/the-partner.png'

class PartnersIntegrations extends PureComponent {
    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'partners-integrations',
            },
        })
    }

    render() {
        return (
            <div>
                <div id="modal-partner-area" className="modal-partner-area" />
                <Modal
                    open={true}
                    bodyStyle={{ padding: 0 }}
                    width={'60rem'}
                    zIndex={9999}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                    destroyOnClose
                    getContainer="#modal-partner-area"
                >
                    <div className="partner-modal-body">
                        <p className="partner-modal-header">THE Student Chat - TAP</p>
                        <img src={thePartnerImg} className="image-partner" alt="partner" />
                        <p className="partner-modal-text">
                            THE Student Chat is a new kind of offering for students around the world. It is built by
                            Times Higher Education and The Ambassador Platform, and it will be accessed by thousands of
                            students every year, looking to understand what life at university is really like.
                        </p>
                        <p className="partner-modal-text">
                            It is a feed of ambassador profiles from universities all over the world and is embedded
                            onto the THE Student website.
                        </p>
                        <p className="partner-modal-text">
                            In addition to representing your university on your university’s website, you can now
                            represent your university on the THE Student website, and start building connections with
                            students all over the world, who are starting their journey to higher education.
                        </p>
                        <p className="partner-modal-text">
                            Through THE Student Chat, you'll be able to help hundreds if not thousands of people make a
                            more informed and confident decision about their higher education options.
                        </p>
                        <a
                            href="https://www.timeshighereducation.com/student/ambassadors"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="the-partner-link"
                        >
                            Click here to explore THE Student chat
                        </a>
                        <a
                            href="https://share.hsforms.com/1bfDlnubgTgCv4fuAIum8MA1fc7h"
                            className="contact-button"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Book a demo
                        </a>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(PartnersIntegrations)
