import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAccountInfoId, getActiveStreamsData, getAccountInfo } from '../../../../../../redux/selectors'

import { prepareStreamHostUrl, prepareStreamUrl } from '../../../../../../helpers/streamUrl'

const EventStream = ({ activeEvent }) => {
    const { speakers } = activeEvent
    const accountId = useSelector(getAccountInfoId)
    const accountInfo = useSelector(getAccountInfo)
    const streamData = useSelector(getActiveStreamsData)

    const isSpeaker = useMemo(() => {
        return speakers.some((speaker) => speaker.id === accountId)
    }, [accountId, speakers])
    const streamUrl = isSpeaker
        ? prepareStreamHostUrl({ accountInfo, streamData })
        : prepareStreamUrl({ accountInfo, streamData })

    return (
        <div className="tap-event-stream">
            <iframe
                src={streamUrl}
                allow="camera; microphone; fullscreen; speaker; display-capture; autoplay"
                style={{ height: '100%', width: '100%' }}
                data-automation-id="live-stream-player"
            />
        </div>
    )
}

export default EventStream
