import React from 'react'
import { Typography } from 'antd'

const { Title, Text } = Typography

const CampaignsTypeHeader = ({ title, description }) => {
    return (
        <>
            <Title>{title}</Title>
            <Text>{description}</Text>
        </>
    )
}

export default CampaignsTypeHeader
