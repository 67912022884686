import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { GET_API_KEY, GENERATE_API_KEY } from '../../../redux/actions/settings/crmActions'
import store from '../../../redux/store'
import { Protect } from 'src/app/permissions/Protect'

import ApiKeyBlock from './components/ApiKeyBlock'
class Crm extends PureComponent {
    constructor() {
        super()

        this.generateNewApiKey = this.generateNewApiKey.bind(this)
        this.notifyClipboardCopy = this.notifyClipboardCopy.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })

        store.dispatch({
            type: GET_API_KEY,
        })
    }

    generateNewApiKey() {
        store.dispatch({
            type: GENERATE_API_KEY,
            payload: {
                successMessage: 'API key has been successfully generated',
            },
        })
    }

    notifyClipboardCopy() {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'API key has been successfully copied to the clipboard',
            },
        })
    }

    render() {
        return (
            <Protect>
                <div id="page-body" className="page-body js-index">
                    <section className="content settings">
                        <h3 className="settings-title">CRM integration</h3>
                        <div className="settings-grid has-tutorial">
                            <div className="column">
                                <div className="settings-intro">
                                    <p>
                                        The Ambassador Platform integrates with dozens of CRM systems, including
                                        Salesforce, Microsoft Dynamics 365 and Hubspot.
                                    </p>
                                    <p>
                                        With TAP's integration, you will be able to pull prospect data and messaging
                                        information into your CRM.
                                    </p>
                                </div>
                                <hr className="settings-hr" />
                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">What data can I capture?</h4>
                                    <h5>Prospect data</h5>
                                    <p>
                                        ID, email address, first name, last name, time profile created, date profile
                                        updated, answers to optional questions, marketing preferences
                                    </p>
                                    <h5>Chat conversations</h5>
                                    <p>Messages, participants, time created, time updated</p>
                                </div>

                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">Setting up your integration</h4>
                                    <p>We recommend using Zapier to complete your integration.</p>
                                    <p>
                                        Zapier is a piece of software that sits in between TAP and your CRM, and takes
                                        the manual development work out of integration.
                                    </p>
                                    <p>
                                        Using Zapier, you create a 'Zap', match up your TAP data fields with your CRM
                                        fields, and get started right away.
                                    </p>
                                    <p>
                                        Please note that Zapier incurs a charge for more than 100 data pulls per month.
                                    </p>
                                    <h4 className="settings-name">Completing an integration with Zapier</h4>
                                    <ol className="ordered-list">
                                        <li>Create an account with Zapier</li>
                                        <li>
                                            <a
                                                href="https://zapier.com/developer/public-invite/33103/f6ea1a32319dcfa79f9acda4f6f7f6db/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Follow this link
                                            </a>{' '}
                                            to gain access to the TAP integration
                                        </li>
                                        <li>
                                            When requested, enter your admin email address, API key (see below), and the
                                            name of your CRM software
                                        </li>
                                        <li>Zapier will make a test connection</li>
                                        <li>
                                            Set up one or more zaps to tell Zapier when it should sync data with your
                                            CRM
                                        </li>
                                    </ol>
                                </div>

                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">Manual integration (for technical users)</h4>
                                    <p>
                                        If you are a technical user, wish to have more flexibility with your
                                        integration, or are looking to pull a large amount of data and avoid Zapier
                                        fees, manual integration may be suitable.
                                    </p>
                                    <p>
                                        We expose a REST API endpoint with prospect and messaging data in standard JSON
                                        format. Use your TAP admin email address, API key, and define an intID (name of
                                        the CRM software you are using to connect) to authenticate at
                                        <code>/auth</code>. Then you can pull data from the endpoints as described in
                                        the{' '}
                                        <a
                                            href="https://public-api.theambassadorplatform.com/docs"
                                            className="orange"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            API reference
                                        </a>
                                        .
                                    </p>
                                    <p>
                                        Please contact your Customer Success Manager if you have questions about manual
                                        integration.
                                    </p>
                                </div>

                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">Your credentials</h4>
                                    <p>
                                        Always use your TAP administrator email address to authenticate with Zapier or
                                        the API.
                                    </p>
                                    <p>
                                        You can see below your API key (or generate it).
                                        <strong>
                                            {' '}
                                            Keep this in secret as anyone with it may be able to gain access to your
                                            university data.
                                        </strong>
                                    </p>
                                    <ApiKeyBlock
                                        data={this.props.apiKey}
                                        generateNewApiKey={this.generateNewApiKey}
                                        notifyClipboardCopy={this.notifyClipboardCopy}
                                    />
                                </div>
                            </div>

                            <div className="column resources">
                                <h3 className="video-title">Video tutorial: CRM integration with Zapier</h3>
                                <div className="video-wrapper">
                                    <iframe
                                        src="https://player.vimeo.com/video/418472494"
                                        width="640"
                                        height="373"
                                        frameBorder="0"
                                        allow="autoplay; fullscreen"
                                        allowFullScreen
                                    ></iframe>
                                </div>

                                <div className="resources-box">
                                    <h3>Helpful resources</h3>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://knowledge.theambassadorplatform.com/how-to-set-up-crm-integration"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                How does TAP integrate with CRM?
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://zapier.com/pricing"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Zapier pricing
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://knowledge.theambassadorplatform.com/setting-up-crm-integration-via-zapier"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Setting up CRM integration via Zapier
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://public-api.theambassadorplatform.com/docs"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                TAP public API documentation
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Protect>
        )
    }
}
export default connect((state) => {
    return {
        account: state.account,
        apiKey: state.crm.apiKey,
    }
})(Crm)
