import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Card, Row, Col, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import store from '../../../redux/store'

import DatesIntervalSelector from '../components/DatesIntervalSelector'
import FaqTable from './FaqTable'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { REPORT_QUESTIONS_INFO } from '../../../redux/actions/reporting/faqActions'

import { getDateRange } from '../../../helpers/time'

import { isIdpRedirect } from 'src/app/helpers/idpRedirect'
import { HomeRedirect } from 'src/app/containers/_layout/nav/components/HomeRedirect'

class ReportingFaq extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedButton: '30Days',
            selectedDateRange: getDateRange(30),
        }

        this.handleDatesIntervalSelectorUpdate = this.handleDatesIntervalSelectorUpdate.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'reporting',
            },
        })

        store.dispatch({
            type: REPORT_QUESTIONS_INFO,
            payload: {
                filter: this.state.selectedDateRange,
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: REPORT_QUESTIONS_INFO,
                payload: {
                    filter: this.state.selectedDateRange,
                },
            })
        }
    }

    generateDatesSelector() {
        return (
            <DatesIntervalSelector
                selectedButton={this.state.selectedButton}
                fromDate={this.state.selectedDateRange.fromDate}
                toDate={this.state.selectedDateRange.toDate}
                onChange={this.handleDatesIntervalSelectorUpdate}
            />
        )
    }

    handleDatesIntervalSelectorUpdate(selectedButton, selectedDateRange) {
        this.setState({ selectedButton, selectedDateRange }, () => {
            store.dispatch({
                type: REPORT_QUESTIONS_INFO,
                payload: {
                    filter: this.state.selectedDateRange,
                },
            })
        })
    }

    handleTableChange(pagination, filters, sorter) {
        store.dispatch({
            type: REPORT_QUESTIONS_INFO,
            payload: {
                filter: this.state.selectedDateRange,
                limit: 10,
                offset: 10 * (pagination ? pagination.current - 1 : 0),
                orderBy: sorter && sorter.order && sorter.columnKey ? sorter.columnKey : '',
                order:
                    sorter && sorter.order && sorter.columnKey
                        ? sorter.order === 'ascend'
                            ? 'asc'
                            : sorter.order === 'descend'
                            ? 'desc'
                            : ''
                        : '',
            },
        })
    }

    render() {
        const { faqs, isTableLoading, account } = this.props

        if (isIdpRedirect(account.account_info)) {
            return <HomeRedirect />
        }

        return (
            <section className="content reporting faq-reporting">
                <Row gutter={16}>
                    <Col span={6}>
                        <div className="faq-table-title">FAQ</div>
                    </Col>
                </Row>
                <Card className="reporting-block" style={{ marginTop: 16 }}>
                    <Spin
                        spinning={isTableLoading}
                        indicator={<LoadingOutlined style={{ fontSize: 50, color: '#ff5100' }} />}
                    >
                        <div className="top-wrapper">
                            <div className="left-wrapper">
                                <div className="total-views">
                                    <span className="numbers">{faqs.all_views}</span>views
                                </div>
                                <div className="total-likes">
                                    <span className="numbers">{faqs.all_likes}</span>likes
                                </div>
                            </div>
                            <div className="right-wrapper">{this.generateDatesSelector()}</div>
                        </div>
                        <FaqTable data={faqs.items} total={faqs.total} handleTableChange={this.handleTableChange} />
                    </Spin>
                </Card>
            </section>
        )
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    faqs: state.faqReport,
    isTableLoading: state.faqReport.isLoading,
})

export default connect(mapStateToProps)(ReportingFaq)
