import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import config from '../../../../../system/config'
import store from '../../../../../redux/store'
import { OPEN_ACTION_MODAL } from '../../../../../redux/actions/components/componentsActions'

class CopyLinkPopup extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}

        this.handleEscPress = this.handleEscPress.bind(this)
        this.copyLink = this.copyLink.bind(this)
    }

    componentDidMount() {}

    handleEscPress(event) {
        const escapeButtonCode = 27
        if (event.keyCode !== escapeButtonCode) {
            return
        }

        const confirmByEscPress = confirm('Are you sure you want to close this popup? Changes will not be saved')
        if (confirmByEscPress) {
            this.props.onClosePopup()
        }
    }

    copyLink(privateChatLink) {
        const tempInput = document.createElement('input')
        tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px'
        tempInput.value = privateChatLink
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        document.body.removeChild(tempInput)
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'Group Chat link copied to clipboard',
            },
        })
    }

    render() {
        const { account, dialogId, privateCode } = this.props

        let privateChatLink = ''
        if (account.account_info.universityGroup) {
            if (account.account_info.universityGroup.tap_page_url) {
                privateChatLink =
                    account.account_info.universityGroup.tap_page_url +
                    '?dialog_id=' +
                    dialogId +
                    (privateCode ? '&dialog_code=' + privateCode : '')
            } else {
                privateChatLink =
                    config.tap_page_url +
                    'group/' +
                    account.account_info.universityGroup.id +
                    '/interaction/dialogs/' +
                    dialogId +
                    (privateCode ? '?dialog_code=' + privateCode : '')
            }
        } else if (account.account_info.university.university_url) {
            privateChatLink =
                account.account_info.university.university_url +
                '?dialog_id=' +
                dialogId +
                (privateCode ? '&dialog_code=' + privateCode : '')
        } else {
            privateChatLink =
                config.tap_page_url +
                account.account_info.university.id +
                '/interaction/dialogs/' +
                dialogId +
                (privateCode ? '?dialog_code=' + privateCode : '')
        }

        return (
            <div className="copy-link-popup">
                <h3>Share Group Chat link</h3>
                {privateCode ? (
                    <div className="copy-link-popup-text">
                        The private Group Chat you have created is only visible to prospects you share the link with.
                        You can also find this link in the chat header menu.
                    </div>
                ) : (
                    <div className="copy-link-popup-text">
                        Group Chat has been created. The Group Chat you've just created will be visible on the TAP Feed.
                        You can also find this link in the chat header menu.
                    </div>
                )}
                <p className="copy-link-input-title">Group Chat link</p>
                <div className="private-link-input-block">
                    <span className="private-link-input">{privateChatLink}</span>
                    <span className="copy" onClick={() => this.copyLink(privateChatLink)}>
                        Copy
                    </span>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(CopyLinkPopup)
