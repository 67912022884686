import { createApi } from '@reduxjs/toolkit/query/react';
import { highcartDataMaper, prepareFirstResponseTimeChartData, prepareNationalitiesChartData, } from 'src/app/containers/reporting/idp-conversion/helpers';
import { axiosBaseQuery } from 'src/app/system/query/axiosQuery';
export var idpConversionReportApi = createApi({
    reducerPath: 'idpConversionReportApi',
    baseQuery: axiosBaseQuery({ baseUrl: '/dashboard/reporting/idpP2P' }),
    refetchOnReconnect: true,
    tagTypes: ['IdpReport'],
    endpoints: function (builder) { return ({
        getProspects: builder.query({
            query: function (data) {
                return {
                    url: '/prospects',
                    method: 'POST',
                    data: data,
                };
            },
            transformResponse: function (response) {
                return {
                    prospects: highcartDataMaper(response.prospects),
                    nationalities: prepareNationalitiesChartData(response.nationalities),
                };
            },
        }),
        getConversations: builder.query({
            query: function (data) {
                return {
                    url: '/conversations',
                    method: 'POST',
                    data: data,
                };
            },
            transformResponse: function (response) {
                return highcartDataMaper(response);
            },
        }),
        getMessages: builder.query({
            query: function (data) {
                return {
                    url: '/messages',
                    method: 'POST',
                    data: data,
                };
            },
            transformResponse: function (response) {
                return highcartDataMaper(response);
            },
        }),
        getFirstResponseTime: builder.query({
            query: function (data) {
                return {
                    url: '/firstResponseTime',
                    method: 'POST',
                    data: data,
                };
            },
            transformResponse: function (response) {
                return prepareFirstResponseTimeChartData(response);
            },
        }),
    }); },
});
export var useGetProspectsQuery = idpConversionReportApi.useGetProspectsQuery, useGetConversationsQuery = idpConversionReportApi.useGetConversationsQuery, useGetMessagesQuery = idpConversionReportApi.useGetMessagesQuery, useGetFirstResponseTimeQuery = idpConversionReportApi.useGetFirstResponseTimeQuery;
