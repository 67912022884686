export default ({ internalSpeakerIds, externalSpeakerEmails, externalSpeakerEmailsExisting }, data) => {
    const existingExternalIds = externalSpeakerEmailsExisting.map(({ id }) => id)

    return {
        liveStreamId: data.id,
        speakerIds: [...internalSpeakerIds, ...existingExternalIds],
        newExternalSpeakers: externalSpeakerEmails.map(({ email }) => email).filter((email) => !!email),
        editType: 'speakers',
    }
}
