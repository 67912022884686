import React, { Component } from 'react'

class ExportButtons extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lastDownloadedFile: '',
            shouldFileBeDownloaded: false,
        }

        this.downloadFile = this.downloadFile.bind(this)
        this.handleClickAll = this.handleClickAll.bind(this)
        this.handleClickSelected = this.handleClickSelected.bind(this)
    }

    downloadFile() {
        if (
            this.state.shouldFileBeDownloaded &&
            this.state.lastDownloadedFile !== this.props.filePath &&
            this.props.filePath.length
        ) {
            const link = document.createElement('a')
            link.download = this.props.filePath
            link.href = this.props.filePath
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            this.setState({ lastDownloadedFile: this.props.filePath, shouldFileBeDownloaded: false })
        }
    }

    handleClickAll() {
        const lastDownloadedFile = this.state.lastDownloadedFile === '' ? this.props.filePath : ''
        this.setState({ shouldFileBeDownloaded: true, lastDownloadedFile })

        this.props.onClickAll()
    }

    handleClickSelected() {
        const lastDownloadedFile = this.state.lastDownloadedFile === '' ? this.props.filePath : ''
        this.setState({ shouldFileBeDownloaded: true, lastDownloadedFile })

        this.props.onClickSelected()
    }

    render() {
        this.downloadFile()

        return (
            <div className="export-buttons-container">
                <button className="el-btn" onClick={this.handleClickSelected} disabled={this.props.showExportSelected}>
                    Export Selected{this.props.selectedNumber}
                </button>
                <button className="el-btn" onClick={this.handleClickAll} disabled={this.props.showExportAll}>
                    Export All
                </button>
            </div>
        )
    }
}

export default ExportButtons
