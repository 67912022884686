import { createSelector } from 'reselect'

import { getPlsSignUpSlice } from './common-selectors'

export const getPlsSignUpSuccessModal = createSelector(
    getPlsSignUpSlice,
    ({ successSignUpModal }) => successSignUpModal
)

export const getPlsOnboardingModal = createSelector(getPlsSignUpSlice, ({ onboardingPlsModal }) => onboardingPlsModal)
