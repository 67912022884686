import { connect } from 'react-redux'
import React, { PureComponent } from 'react'

class InviteAdminMessage extends PureComponent {
    render() {
        const { message } = this.props
        const { user, admin, admin_avatar: adminAvatar, university, userAvatar } = message.content

        return (
            <div className="join-row">
                <div className="msg-avatar-block">
                    {message.user && userAvatar ? (
                        <img className="msg-item-img" src={userAvatar} />
                    ) : (
                        <div className="msg-avatar-text">{message.user?.name.charAt(0) || ''}</div>
                    )}
                </div>
                <p className="join-text">{user} invite</p>
                <div className="msg-avatar-block">
                    {message.content && adminAvatar ? (
                        <img className="msg-item-img" src={adminAvatar} />
                    ) : (
                        <div className="msg-avatar-text">{message.user?.name.charAt(0) || ''}</div>
                    )}
                </div>
                <p className="join-text">
                    {admin}
                    {', ' + university + ' staff representative '}
                    to the chat
                </p>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        contentGroups: state.contentGroups,
        account: state.account,
    }
})(InviteAdminMessage)
