import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { getCampaignsSlice } from './common-selectors'

export const getCampaignTypeModal = createSelector(getCampaignsSlice, ({ campaignTypeModal }) => campaignTypeModal)

export const getCampaignsItems = createSelector(getCampaignsSlice, prop('items'))
export const getCampaignsTotal = createSelector(getCampaignsSlice, prop('total'))
export const getActiveCampaign = createSelector(getCampaignsSlice, prop('activeCampaign'))
