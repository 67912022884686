import { produce } from 'immer'
import { LIVE_STREAMS } from '../../actions/live-streams/live-streams-action-types'

import { createItemsShape } from '../../utils/reducer-utils'

const initialState = {
    items: {
        byId: {},
        allIds: [],
    },
    total: 0,
    currentOffset: 0,
    currentFilters: {},
    loading: false,
    activeEventId: undefined,
    activeStreamData: undefined,
    onlineUsers: [],
    streamTypeModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    eventModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    usersModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    scheduledEventModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    ambassadorsModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    speakersModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    publishControlModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    videoReUploadModal: {
        data: undefined,
        open: false,
    },
    activeTab: 'participants',
}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case LIVE_STREAMS.FETCH: {
            return {
                ...state,
                loading: true,
            }
        }

        case LIVE_STREAMS.FETCH_SUCCESS: {
            return {
                ...state,
                items: createItemsShape(payload.items),
                total: payload.total,
                currentOffset: payload.currentOffset,
                currentFilters: payload.currentFilters,
                loading: false,
            }
        }

        case LIVE_STREAMS.FETCH_ERROR: {
            return {
                ...state,
                items: [],
                loading: false,
            }
        }

        case LIVE_STREAMS.OPEN_STREAM_TYPE_MODAL: {
            return {
                ...state,
                streamTypeModal: {
                    ...state.streamTypeModal,
                    open: true,
                    data: undefined,
                },
            }
        }

        case LIVE_STREAMS.CLOSE_STREAM_TYPE_MODAL: {
            return {
                ...state,
                streamTypeModal: {
                    ...state.streamTypeModal,
                    open: false,
                    data: payload,
                },
            }
        }

        case LIVE_STREAMS.OPEN_VIDEO_RE_UPLOAD_MODAL: {
            return produce(state, (draft) => {
                draft.videoReUploadModal.open = true
                draft.videoReUploadModal.data = payload
            })
        }

        case LIVE_STREAMS.CLOSE_VIDEO_RE_UPLOAD_MODAL: {
            return produce(state, (draft) => {
                draft.videoReUploadModal.open = false
            })
        }

        case LIVE_STREAMS.OPEN_FORM_MODAL: {
            return {
                ...state,
                eventModal: {
                    ...state.eventModal,
                    open: true,
                    data: payload,
                },
            }
        }

        case LIVE_STREAMS.CLOSE_FORM_MODAL: {
            return {
                ...state,
                eventModal: initialState.eventModal,
                streamTypeModal: initialState.streamTypeModal,
            }
        }

        case LIVE_STREAMS.OPEN_SCHEDULED_FORM_MODAL: {
            return {
                ...state,
                scheduledEventModal: {
                    ...state.scheduledEventModal,
                    open: true,
                    data: payload,
                },
            }
        }

        case LIVE_STREAMS.CLOSE_SCHEDULED_FORM_MODAL: {
            return {
                ...state,
                scheduledEventModal: initialState.scheduledEventModal,
            }
        }

        case LIVE_STREAMS.CREATE_SUCCESS:
        case LIVE_STREAMS.UPDATE_SUCCESS: {
            return {
                ...state,
                eventModal: initialState.eventModal,
                usersModal: initialState.usersModal,
                scheduledEventModal: initialState.scheduledEventModal,
                speakersModal: initialState.speakersModal,
                ambassadorsModal: initialState.ambassadorsModal,
                publishControlModal: initialState.publishControlModal,
            }
        }

        case LIVE_STREAMS.OPEN_SPEAKERS_MODAL: {
            return {
                ...state,
                speakersModal: {
                    ...state.speakersModal,
                    open: true,
                    data: payload,
                },
            }
        }

        case LIVE_STREAMS.CLOSE_SPEAKERS_MODAL: {
            return {
                ...state,
                speakersModal: initialState.speakersModal,
            }
        }

        case LIVE_STREAMS.OPEN_PUBLISH_CONTROL_MODAL: {
            return {
                ...state,
                publishControlModal: {
                    ...state.publishControlModal,
                    open: true,
                    data: payload,
                },
            }
        }

        case LIVE_STREAMS.CLOSE_PUBLISH_CONTROL_MODAL: {
            return {
                ...state,
                publishControlModal: initialState.publishControlModal,
            }
        }

        case LIVE_STREAMS.OPEN_AMBASSADORS_MODAL: {
            return {
                ...state,
                ambassadorsModal: {
                    ...state.ambassadorsModal,
                    open: true,
                    data: payload,
                },
            }
        }

        case LIVE_STREAMS.CLOSE_AMBASSADORS_MODAL: {
            return {
                ...state,
                ambassadorsModal: initialState.ambassadorsModal,
            }
        }

        case LIVE_STREAMS.SET_ACTIVE: {
            return {
                ...state,
                activeEventId: payload,
                onlineUsers: [],
                joinedStream: false,
            }
        }

        case LIVE_STREAMS.SET_ACTIVE_SIDEBAR_TAB: {
            return {
                ...state,
                activeTab: payload,
            }
        }

        case LIVE_STREAMS.OPEN_USERS_MODAL: {
            return {
                ...state,
                usersModal: {
                    ...state.usersModal,
                    open: true,
                    data: payload,
                },
            }
        }

        case LIVE_STREAMS.CLOSE_USERS_MODAL: {
            return {
                ...state,
                usersModal: initialState.usersModal,
            }
        }

        case LIVE_STREAMS.FETCH_USERS_SUCCESS:
        case LIVE_STREAMS.FETCH_PROSPECTS_SUCCESS: {
            return {
                ...state,
                items: {
                    ...state.items,
                    byId: {
                        ...state.items.byId,
                        [+payload.liveStreamId]: {
                            ...state.items.byId[+payload.liveStreamId],
                            prospects: payload.items,
                            totalProspects: payload.total,
                            userIds: payload.userIds,
                        },
                    },
                },
            }
        }

        case LIVE_STREAMS.FETCH_LIVE_STREAM_DATA_SUCCESS: {
            return {
                ...state,
                activeStreamData: payload.streamData,
            }
        }

        case LIVE_STREAMS.CREATE:
        case LIVE_STREAMS.UPDATE: {
            return {
                ...state,
                eventModal: {
                    ...state.eventModal,
                    submitting: true,
                },
                scheduledEventModal: {
                    ...state.scheduledEventModal,
                    submitting: true,
                },
            }
        }

        case LIVE_STREAMS.UPDATE_ERROR:
        case LIVE_STREAMS.CREATE_ERROR: {
            return {
                ...state,
                eventModal: {
                    ...state.eventModal,
                    submitting: false,
                },
                scheduledEventModal: {
                    ...state.scheduledEventModal,
                    submitting: false,
                },
            }
        }
        case LIVE_STREAMS.JOIN_STREAM_LOBBY: {
            const { liveStreamId, isLobbyMode } = payload
            return produce(state, (draft) => {
                draft.items.byId[liveStreamId].isLobbyMode = isLobbyMode
            })
        }

        case LIVE_STREAMS.UPDATE_STREAM_STATUS: {
            const { liveStreamId, status, isStreamActive } = payload

            return produce(state, (draft) => {
                draft.items.byId[liveStreamId].status = status
                draft.items.byId[liveStreamId].is_stream_active = isStreamActive
            })
        }

        case LIVE_STREAMS.VIDEO_RE_UPLOAD_SUCCESS: {
            const { liveStreamId, file } = payload

            return produce(state, (draft) => {
                draft.items.byId[liveStreamId].video = [file]
            })
        }

        case LIVE_STREAMS.SHARE_STREAM_RECORD_SUCCESS: {
            const { liveStreamId } = payload

            return produce(state, (draft) => {
                draft.items.byId[liveStreamId].video[0].isPublished = true
            })
        }

        default: {
            return state
        }
    }
}
