import HttpService from '../../system/services/HttpService'

export default {
    setupWizardFinish: () => {
        return HttpService.post('/dashboard/universities/passWizardSetup')
    },
    sharedPosts: (limit, offset, universityIds) => {
        return HttpService.get('/dashboard/posts/shared', {
            limit: limit,
            offset: offset,
            universityIds,
        })
    },
    universityWidgets: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/tapPage/settings', data)
    },
    setUniversityWidget: (universityId, settings, type) => {
        const data = {
            settings: settings,
            type: type,
        }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/tapPage/settings', data)
    },
    getGroupTapSettings: () => {
        return HttpService.get('/dashboardGroup/groups/tapPage/settings')
    },
    setGroupTapSettings: (settings) => {
        return HttpService.post('/dashboardGroup/groups/tapPage/settings', {
            settings,
        })
    },
    admins: (limit, offset, universityId) => {
        const data = {
            limit: limit,
            offset: offset,
        }
        if (universityId) {
            data.universityIds = [universityId]
        }
        return HttpService.get('/dashboard/universities/admins', data)
    },
    groupAdmins: (limit, offset) => {
        return HttpService.get('/dashboardGroup/groups/admins', {
            limit: limit,
            offset: offset,
        })
    },
    checkEmailExists: (email) => {
        return HttpService.post('/dashboard/universities/checkEmailExists', {
            email: email,
        })
    },
    sendAdminInvitation: (data) => {
        return HttpService.post('/dashboard/universities/inviteAdmins', data)
    },
    sendGroupAdminInvitation: (data) => {
        return HttpService.post('/dashboardGroup/groups/inviteAdmins', data)
    },
    changeTAPTitles: (options) => {
        return HttpService.put('/dashboard/universities/tapPage/titles', {
            ...options,
        })
    },
    getTAPTitles: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/tapPage/titles', data)
    },
    getGroupTapTitles: () => {
        return HttpService.get('/dashboardGroup/groups/tapPage/titles')
    },
    setGroupTapTitles: (data) => {
        return HttpService.put('/dashboardGroup/groups/tapPage/titles', data)
    },
    setFilters: (widgetFilters, universityId) => {
        const data = { widget_filters: widgetFilters }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/tapPage/filters', data)
    },
    getFilters: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/tapPage/filters', data)
    },

    getSocialProofInfo: (universityId = null) => {
        return HttpService.get('/widget/social/proof', {
            universityId,
        })
    },
    getGroupTapFilters: () => {
        return HttpService.get('/dashboardGroup/groups/tapPage/filters')
    },
    setGroupTapFilters: (widgetFilters) => {
        return HttpService.post('/dashboardGroup/groups/tapPage/filters', { widget_filters: widgetFilters })
    },
    getFilterOptionsCount: (type) => {
        return HttpService.get('/dashboard/tags/count', {
            parent: type,
        })
    },
    keywordSwitch: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.put('/dashboard/universities/keywords-censore', data)
    },
    getCspDomains: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/domains', data)
    },
    setCspDomains: (domains, universityId) => {
        const data = { domains }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/domains', data)
    },
    getNotificationsList: () => {
        return HttpService.get('/dashboard/account/notifications/settings')
    },
    toggleNotification: (data) => {
        return HttpService.put('/dashboard/account/change/notifications/settings', data)
    },
    setManagementTerm: (data) => {
        return HttpService.put('/dashboard/universities/data/management', data)
    },
    getSafeguardingData: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/safeguardingData', data)
    },
    changeProspectsMessagingActivity: (data) => {
        return HttpService.put('/dashboard/universities/prospectsMessagingActivity ', data)
    },
    setDefaultBadwords: (universityId, badwordIds) => {
        const data = { badwordIds }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/defaultBadwords', data)
    },
    addCustomBadword: (universityId, badwordName) => {
        const data = { badwordName }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/customBadword', data)
    },
    deleteCustomBadword: (universityId, badwordIds) => {
        const data = { badwordIds }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.delete('/dashboard/universities/customBadwords', data)
    },
    addWhitlistedBadword: (universityId, badwordName) => {
        const data = { badwordName }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/whitelistedBadword', data)
    },
    deleteWhitlistedBadword: (universityId, badwordIds) => {
        const data = { badwordIds }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.delete('/dashboard/universities/whitelistedBadwords', data)
    },
    uploadCompanies: (payload) => {
        return HttpService.formData('/dashboard/universities/uploadCompanies', payload)
    },
    getCompanies: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/companies', data)
    },
    addCompany: (data) => {
        return HttpService.post('/dashboard/universities/addCompany', data)
    },
    editCompany: (data) => {
        return HttpService.put('/dashboard/universities/editCompany ', data)
    },
    getTimezones: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/timezones', data)
    },
    setTimezone: (timezoneId, universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/universities/timezones/' + timezoneId, data)
    },
    getApiKey: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/apiKey', data)
    },
    generateApiKey: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/universities/apiKey/generate', data)
    },
    exportData: (universityId) => {
        const data = {}
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/exportsData', data)
    },
    getSsoProviders: () => {
        return HttpService.get('/dashboard/sso/providers')
    },
    saveUniversitySso: (data) => {
        return HttpService.post('/dashboard/sso/saveUniversitySso', data)
    },
    activateToggleUniversitySsoOnly: (data) => {
        return HttpService.put('/dashboard/sso/ssoOnlyToggle', data)
    },
    resetUniversitySso: () => {
        return HttpService.delete('/dashboard/sso/resetUniversitySso')
    },
    userToggleUniversitySso: (data) => {
        return HttpService.put('/dashboard/sso/userToggle', data)
    },
    saveGroupUniversitySso: (data) => {
        return HttpService.post('/dashboardGroup/sso/saveUniversityGroupSso', data)
    },
    userToggleUniversityGroupSso: (data) => {
        return HttpService.put('/dashboardGroup/sso/userToggle', data)
    },
    activateToggleUniversityGroupSsoOnly: (data) => {
        return HttpService.put('/dashboardGroup/sso/ssoOnlyToggle', data)
    },
    resetUniversityGroupSso: () => {
        return HttpService.delete('/dashboardGroup/sso/resetUniversityGroupSso')
    },
    uploadSsoMetadata: (data) => {
        return HttpService.formData('/dashboard/sso/uploadMetadata', data.file)
    },
    uploadBadwordCsv: (data) => {
        return HttpService.formData('/dashboard/universities/convertCustomBadwords', data)
    },
}
