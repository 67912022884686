import {
    CLEAR_CONTENT_GROUP,
    CLEAR_MESSAGES,
    DIALOG_MESSAGES_INFO,
    DIALOG_MESSAGES_INFO_SUCCESS,
    EDIT_MESSAGE_POST_CAPTION_SUCCESS,
    EDIT_MESSAGE_POST_GLOBAL_TAG_FINISH,
    LOAD_OLD_MESSAGES_SUCCESS,
} from '../../../actions/content/messagingActions'

import { DIALOG_MESSAGES } from '../../../store/initialState'

const initialState = DIALOG_MESSAGES

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case DIALOG_MESSAGES_INFO: {
            if (payload.onScroll) {
                payload.beforeMessageId =
                    !state.items || (!state.items && state.items.length === 0) ? 0 : state.items[0].id
            }
            payload.limit = state.limit
            const newState = Object.assign({}, state, {
                dialog_id: payload.dialog_id,
                isLoading: true,
                oldLoaded: false,
                isBlockedScroll: !!payload.isBlockedScroll,
            })

            return newState
        }

        case DIALOG_MESSAGES_INFO_SUCCESS: {
            const items = payload.items && payload.items.length > 0 ? payload.items.reverse() : []

            const newState = Object.assign({}, state, {
                items: items,
                total: payload.total,
                isLoading: false,
                dialog_id: payload.dialog_id,
                isFirstRequest: false,
                scroll: true,
            })

            return newState
        }
        case LOAD_OLD_MESSAGES_SUCCESS: {
            let previousScrollHeight = 0
            if (payload.previousScrollHeight) {
                previousScrollHeight = payload.previousScrollHeight
            }
            let items = state.items ? [...state.items] : []
            // on some case we will check if messages new
            if (
                items.length > 0 &&
                payload.items &&
                payload.items.length > 0 &&
                items[0].id > payload.items[payload.items.length - 1].id
            ) {
                items = [...payload.items.reverse(), ...items]
            }
            return {
                ...state,
                items: items,
                previousScrollHeight: previousScrollHeight,
                oldLoaded: true,
                isLoading: false,
            }
        }

        case EDIT_MESSAGE_POST_CAPTION_SUCCESS: {
            const { post_id: postId, caption } = payload
            const editedPostMessage = state.items.find((item) => item.content.post_id == postId)

            if (editedPostMessage) {
                editedPostMessage.content.post.caption = caption
            }

            return {
                ...state,
                ...state.items,
                items: state.items.map((item) =>
                    item.id === editedPostMessage?.id ? { ...item, editedPostMessage } : item
                ),
            }
        }

        case EDIT_MESSAGE_POST_GLOBAL_TAG_FINISH: {
            const { message_id: messageId, globalTags } = payload
            const items = state.items.map((item) => {
                if (item.id === messageId && item.content && item.content.post) {
                    item.content.post.globalTags = globalTags
                } else {
                    return item
                }
            })
            return {
                ...state,
                posts: {
                    ...state.posts,
                    items: items,
                },
            }
        }

        case CLEAR_CONTENT_GROUP: {
            const newState = Object.assign({}, state, {
                dialog_id: -1,
            })

            return newState
        }

        case CLEAR_MESSAGES: {
            return initialState
        }

        default: {
            return state
        }
    }
}
