import React, { Component, createRef } from 'react'
import Modal from 'antd/es/modal'
import { EditorState, ContentState, RichUtils, Modifier, CompositeDecorator, convertFromRaw } from 'draft-js'
import cn from 'classnames'
import dayjs from 'dayjs'

import DeleteQuestionAnswerButton from '../../components/DeleteQuestionAnswerButton'
import ReadableAnswer from '../../components/ReadableAnswer'
import EditableAnswer from '../../components/EditableAnswer'
import TagsAddPopup from '../../../../components/popups/TagsAddPopup'

import store from '../../../../redux/store'
import { EDIT_ANSWER, ANSWER_TOGGLE_PUBLISH } from '../../../../redux/actions/faq/faqActions'
import { OPEN_CREATE_QUESTION_POPUP } from '../../../../redux/actions/faq/componentsActions'

import questionMark from '../../../../../assets/svg/icons/question-mark.svg'
import editRed from '../../../../../assets/svg/icons/pencilRed.svg'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { checkTypeObject } from '../../../../helpers/faqHelper'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity()
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
}
const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData()
    return (
        <a href={url} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    )
}

class AnsweredQuestion extends Component {
    constructor(props) {
        super(props)

        this.decorator = new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: Link,
            },
        ])

        this.state = {
            answers: [],
            isLoaded: false,
            addTagsModalOpened: false,
        }

        this.scrollRef = createRef()

        this.handleChange = this.handleChange.bind(this)
        this.handleToggleEdit = this.handleToggleEdit.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.onBoldClick = this.onBoldClick.bind(this)
        this.onItalicClick = this.onItalicClick.bind(this)
        this.onUnderlineClick = this.onUnderlineClick.bind(this)
        this.onLinkClick = this.onLinkClick.bind(this)
        this.onListClick = this.onListClick.bind(this)
        this.openModalTags = this.openModalTags.bind(this)
        this.handleCloseTagsPopup = this.handleCloseTagsPopup.bind(this)
        this.handleOpenCreateQuestionPopup = this.handleOpenCreateQuestionPopup.bind(this)
    }

    handleToggleEdit(id, type) {
        if (type === 'edit' || type === 'save') {
            this.setState((state) => ({
                answers: state.answers.map((answer) =>
                    id === answer.id ? { ...answer, isEditable: !answer.isEditable } : answer
                ),
            }))
        }

        if (type === 'close') {
            const initialAnswer = this.props.faq.answers.find((answer) => +answer.id === +id)
            const { text } = initialAnswer.content || { content: '' }
            const content = checkTypeObject(text)
                ? EditorState.createWithContent(convertFromRaw(JSON.parse(text)), this.decorator)
                : EditorState.createWithContent(ContentState.createFromText(text), this.decorator)
            this.setState((state) => ({
                answers: state.answers.map((answer) =>
                    id === answer.id
                        ? {
                              ...initialAnswer,
                              content,
                              isEditable: false,
                          }
                        : answer
                ),
            }))
        }
    }

    handleOpenCreateQuestionPopup(options) {
        store.dispatch({
            type: OPEN_CREATE_QUESTION_POPUP,
            payload: { ...options },
        })
    }

    handleToggleApprove(answer) {
        store.dispatch({
            type: ANSWER_TOGGLE_PUBLISH,
            payload: {
                answer_id: answer.id,
                published: answer.published,
            },
        })
    }

    handleSaveEdits(id, content) {
        store.dispatch({
            type: EDIT_ANSWER,
            payload: {
                answer_id: id,
                answer: content,
                filter: {
                    published: false,
                },
            },
        })
    }

    handleChange(content, id) {
        this.setState((state) => ({
            ...state,
            answers: state.answers.map((answer) => (+answer.id === +id ? { ...answer, content } : answer)),
        }))
    }

    handleUpdate() {
        const answers = this.props.faq.answers.map((answer) => {
            const text = answer.content.text

            try {
                return {
                    ...answer,
                    content: checkTypeObject(text)
                        ? EditorState.createWithContent(convertFromRaw(JSON.parse(text)), this.decorator)
                        : EditorState.createWithContent(ContentState.createFromText(text), this.decorator),
                    isEditable: false,
                }
            } catch {
                return {
                    ...answer,
                    content: EditorState.createWithContent(convertFromRaw(JSON.parse(text)), this.decorator),
                    isEditable: false,
                }
            }
        })

        this.setState((state) => ({
            answers,
            isLoaded: true,
        }))
    }

    onBoldClick(content, id) {
        this.handleChange(RichUtils.toggleInlineStyle(content, 'BOLD'), id)
    }

    onItalicClick(content, id) {
        this.handleChange(RichUtils.toggleInlineStyle(content, 'ITALIC'), id)
    }

    onUnderlineClick(content, id) {
        this.handleChange(RichUtils.toggleInlineStyle(content, 'UNDERLINE'), id)
    }

    onListClick(content, id) {
        this.handleChange(RichUtils.toggleBlockType(content, 'unordered-list-item'), id)
    }

    onLinkClick(content, id, link) {
        if (!link) {
            return
        }
        const editorState = content
        const selection = editorState.getSelection()

        const contentState = editorState.getCurrentContent()
        const contentWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: link })
        const entityKey = contentWithEntity.getLastCreatedEntityKey()

        const contentStateWithLink = Modifier.applyEntity(contentWithEntity, selection, entityKey)

        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithLink })
        this.handleChange(RichUtils.toggleLink(newEditorState, selection, entityKey), id)
    }

    handleFocus(e) {
        if (e.target.nodeName === 'IMG') {
            const container = e.currentTarget.querySelectorAll('[data-text]')
            container[container.length - 1].focus()
        }
    }

    static userAvatar({ avatar, name }) {
        const avatarSrc = avatar && (avatar.sizes['70x70'] || avatar.original)
        return avatar ? (
            <img className="table-item-img" src={avatarSrc} alt="img" />
        ) : (
            <div className="table-item-img table-item-img-name">{name[0]}</div>
        )
    }

    openModalTags() {
        this.setState({
            addTagsModalOpened: true,
        })
    }

    handleCloseTagsPopup() {
        this.setState({
            addTagsModalOpened: false,
        })
    }

    checkAnswersForUpdates(prev, current) {
        if (prev.length !== current.length) {
            return true
        }
        for (const [i, element] of prev.entries()) {
            if (element.id !== current[i].id) {
                return true
            }
        }
        return false
    }

    onScrollElemLoad = (elem) => {
        if (this.props.scroll) {
            try {
                elem.scrollIntoView()
            } catch {}
        }
    }

    render() {
        const { answers, isLoaded, addTagsModalOpened } = this.state
        const { faq, openDeleteModal, saveAnswer, globalTags, isPublished } = this.props
        const optionsForForm = {
            mode: 'edit',
            faqContent: faq.question,
            faqID: faq.id,
            globalTags: faq.globalTags,
            members: faq.members || [],
        }
        let date = faq.created_at
        if (faq.answers && faq.answers.length > 0) {
            date = faq.answers[0].created_at
            if (faq.answers.length > 1) {
                for (const answer of faq.answers) {
                    if (Date.parse(answer.created_at) > Date.parse(date)) {
                        date = answer.created_at
                    }
                }
            }
        }

        const lastUpdateDate = dayjs(date).calendar(null, {
            sameDay: 'H:mm',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday at ]H:mm',
            lastWeek: 'DD MMMM',
            sameElse: 'DD MMMM',
        })

        if (answers.length === 0 && faq.answers && faq.answers.length > 0 && !isLoaded) {
            this.handleUpdate()
        }

        if (!isLoaded) {
            return null
        }

        if (
            (!faq.answers && answers && answers.length > 0) ||
            (faq.answers && faq.answers.length > 0 && this.checkAnswersForUpdates(faq.answers, answers))
        ) {
            this.handleUpdate()
        }

        const buttonName = isPublished ? 'Unpublish' : 'Approve'

        return (
            <div className="answered-question" ref={this.onScrollElemLoad}>
                <div className="question">
                    <img className="question__title-icon" src={questionMark} alt="Q" />
                    <div className="question__body">
                        <div className="question__body__time">{lastUpdateDate}</div>
                        <div className="question__body__content">
                            <p className="question__body__content__text">{faq.question}</p>
                            {/* <button type="button" className="tags-manager-button" onClick={this.openModalTags}>Open Tag Manager</button> */}
                            <button
                                type="submit"
                                className="tags-manager-button"
                                onClick={() => this.handleOpenCreateQuestionPopup(optionsForForm)}
                                data-automation-id="edit-question"
                            >
                                <img className="question__buttons__button__icon" src={editRed} alt="Edit" />
                                Edit question
                            </button>
                            <DeleteQuestionAnswerButton
                                type="red"
                                openDeleteModal={() => openDeleteModal(faq.id, 'question')}
                            />
                        </div>
                        <div className="question__body__answers">
                            {answers.map((answer) => {
                                const props = {
                                    answer,
                                    ...this,
                                    userAvatar: AnsweredQuestion.userAvatar,
                                    title: buttonName,
                                    published: isPublished,
                                }
                                return (
                                    <div
                                        key={answer.id}
                                        className={cn('answer', answer.isEditable && 'answer--editing')}
                                    >
                                        {answer.isEditable ? (
                                            <EditableAnswer {...{ ...props, saveAnswer }} />
                                        ) : (
                                            <ReadableAnswer
                                                {...{
                                                    ...props,
                                                    handleToggleApprove: this.handleToggleApprove,
                                                    openDeleteModal,
                                                }}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <Modal
                    open={addTagsModalOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseTagsPopup}
                    className="global-tags-modal"
                >
                    <TagsAddPopup
                        faqId={faq.id}
                        currentList={globalTags}
                        handleCloseTagsPopup={this.handleCloseTagsPopup}
                    />
                </Modal>
            </div>
        )
    }
}

export default AnsweredQuestion
