import { connect } from 'react-redux'
import config from '../../../system/config'
import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import SharedPostsTable from './components/SharedPostsTable'
import Modal from 'antd/es/modal'
import CopyRssSuccessPopup from './components/popups/CopyRssSuccessPopup'

import { SHARED_POSTS_INFO_REQUESTED } from '../../../redux/actions/settings/sharing'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { isAllowed } from '../../../helpers/helpers'
import { RestrictedModal } from '../../../components/modals/RestrictedModal'
import { Protect } from 'src/app/permissions/Protect'

class Sharing extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            copyRssSuccessPopupOpen: false,
        }

        this.handleCopyRSS = this.handleCopyRSS.bind(this)
        this.handleCloseSuccessPopup = this.handleCloseSuccessPopup.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'content',
            },
        })

        store.dispatch({
            type: SHARED_POSTS_INFO_REQUESTED,
            payload: {
                limit: 10,
                offset: 0,
            },
        })
    }

    handleCopyRSS() {
        const copyText = document.querySelectorAll('.rss-link')[0]
        copyText.select()
        document.execCommand('copy')

        this.setState({
            copyRssSuccessPopupOpen: true,
        })
    }

    handleCloseSuccessPopup() {
        this.setState({ copyRssSuccessPopupOpen: false })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: SHARED_POSTS_INFO_REQUESTED,
                payload: {
                    limit: 10,
                    offset: 0,
                },
            })
        }
    }

    render() {
        return (
            <Protect>
                <div id="page-body" className="page-body js-index">
                    <section className="content modal-restricted-area" id="modal-restricted-area">
                        <div className="sharing-item">
                            <h4 className="settings-name">Sharing</h4>
                            <div className="settings-row">
                                <p className="settings-row-name">Feed URL</p>
                                <input
                                    type="text"
                                    value={config.rss_url + this.props.account.account_info.university.id + '/rss.xml'}
                                    className="rss-link"
                                />
                                <button
                                    onClick={this.handleCopyRSS}
                                    type="button"
                                    className="settings-btn js-show-form"
                                >
                                    Copy
                                </button>
                            </div>
                            <a
                                href={config.rss_url + this.props.account.account_info.university.id + '/rss.xml'}
                                target="_blank"
                                className="settings-url"
                                rel="noreferrer"
                            >
                                {config.rss_url + this.props.account.account_info.university.id + '/rss.xml'}
                            </a>
                        </div>
                        <SharedPostsTable />
                    </section>
                </div>

                <Modal
                    open={this.state.copyRssSuccessPopupOpen}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseSuccessPopup}
                >
                    <CopyRssSuccessPopup handleClosePopup={this.handleCloseSuccessPopup} />
                </Modal>
                {!isAllowed('content_sharing') && <RestrictedModal closable={false} />}
            </Protect>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(Sharing)
