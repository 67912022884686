import Header from '../../_layout/header'
import NavigationSetupWizard from '../../_layout/navSetupWizard'
import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import { connect } from 'react-redux'
import Modal from 'antd/es/modal'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import ExplanationModal from './components/ExplanationModal'
import tipIcon from '../../../../assets/img/setup/tip.png'
import welcomeIcon from '../../../../assets/img/setup/welcome.png'
import {
    GET_OPTIONAL_QUESTIONS_REQUESTED,
    SAVE_OPTIONAL_QUESTIONS,
} from '../../../redux/actions/chat/optionalQuestions'
import { ReactComponent as ChevronIcon } from '../../../../assets/img/svg/chevron-down.svg'
import PreviewOptionalQuestions from './components/PreviewOptionalQuestions'
import { SETUP_WIZARD_FINISH } from '../../../redux/actions/settings/setupWizardActions'
import OptionalQuestionsForm from '../../../components/forms/optionalQuestionsForm'
import { ReactComponent as SettingsIcon } from '../../../../assets/svg/icons/settings.svg'
import Sprite from '../../../../assets/img/symbol/sprite.svg'
import { CLOSE_ACTION_MODAL, OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'

class OptionalData extends PureComponent {
    constructor(props) {
        super()
        this.state = {
            showExplanationModal: false,
        }

        this.showExplanationModal = this.showExplanationModal.bind(this)
        this.handleCloseExplanationModal = this.handleCloseExplanationModal.bind(this)
        this.finish = this.finish.bind(this)
        this.prevStep = this.prevStep.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'setup-optional-data',
            },
        })

        store.dispatch({
            type: GET_OPTIONAL_QUESTIONS_REQUESTED,
            payload: {},
        })
    }

    finish() {
        const {
            account,
            optionalQuestions: { questions },
            history,
        } = this.props
        let errors = false
        const questionsToSave = []
        questions.forEach((item, index) => {
            if (item.key === 'custom' || item.key === 'start_study') {
                const filledAnswers = []
                if (item.key === 'custom' && item.is_active && item.question.trim() === '') {
                    errors = true
                }
                if (!errors) {
                    questions.forEach((questionItem, questionKey) => {
                        if (
                            item.question.trim() !== '' &&
                            questionItem.question.trim() === item.question.trim() &&
                            index !== questionKey
                        ) {
                            errors = true
                        }
                    })
                }
                if (item.key !== 'custom' || (item.key === 'custom' && item.question.trim() !== '')) {
                    if (item.predefined_answers.length > 0) {
                        item.predefined_answers.forEach((answer, key) => {
                            if (answer.name.trim() !== '') {
                                filledAnswers.push(answer)
                            }
                            if (!errors) {
                                item.predefined_answers.forEach((answerItem, answerKey) => {
                                    if (answerItem.name.trim() === answer.name.trim() && key !== answerKey) {
                                        errors = true
                                    }
                                })
                            }
                        })
                    }
                    if (
                        (item.is_active || filledAnswers.length === 1) &&
                        !errors &&
                        filledAnswers.length < 2 &&
                        (item.key !== 'custom' || (item.key === 'custom' && filledAnswers.length === 1))
                    ) {
                        errors = true
                    }
                    item.predefined_answers = filledAnswers
                }
            }
            if (item.key !== 'custom' || (item.key === 'custom' && item.question.trim() !== '')) {
                questionsToSave.push(item)
            }
        })

        if (errors) {
            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Please make sure all fields are completed',
                },
            })
            return
        }

        const onFinish = () => {
            const onSuccess = () => {
                if (window.localStorage.getItem('setupWizardStep')) {
                    window.localStorage.removeItem('setupWizardStep')
                }

                setTimeout(() => {
                    history.push('/')

                    const closeModal = () => {
                        store.dispatch({
                            type: CLOSE_ACTION_MODAL,
                        })
                    }
                    store.dispatch({
                        type: OPEN_ACTION_MODAL,
                        payload: {
                            children: (
                                <div className="modal-welcome">
                                    <button type="button" className="close-modal-internal" onClick={closeModal}>
                                        <svg className="icon icon-close">
                                            <use
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                xlinkHref={Sprite + '#close'}
                                            ></use>
                                        </svg>
                                    </button>
                                    <img src={welcomeIcon} className="image-welcome" alt="welcome" />
                                    <p className="welcome-text">Congratulations - your account has been created!</p>
                                    <p className="welcome-text">Welcome to your dashboard!</p>
                                </div>
                            ),
                        },
                    })
                }, 0)
            }
            store.dispatch({
                type: SETUP_WIZARD_FINISH,
                payload: {},
                onSuccess,
            })
        }
        store.dispatch({
            type: SAVE_OPTIONAL_QUESTIONS,
            payload: {
                university_id: account.account_info.university_id,
                questions: questionsToSave,
                noModal: true,
            },
            onFinish,
        })
    }

    prevStep() {
        this.props.history.push('/setup/tap-page')
    }

    showExplanationModal() {
        this.setState({
            showExplanationModal: true,
        })
    }

    handleCloseExplanationModal() {
        this.setState({
            showExplanationModal: false,
        })
    }

    render() {
        const { showExplanationModal } = this.state
        const {
            account,
            optionalQuestions: { questions, isLoading },
        } = this.props

        const activeQuestions = []
        if (questions) {
            for (const question of questions) {
                if (question.is_active) {
                    activeQuestions.push(question)
                }
            }
        }

        return (
            <div className="setup-optional-data-page">
                <Header submenuUnActive={true} />
                <div id="page-wrapper" className="page-wrapper">
                    <NavigationSetupWizard />

                    <section className="content settings no-submenu home-start-guide setup-wizard">
                        <h1 className="settings-title">Admin Dashboard Setup Guide</h1>
                        <div className="setup-start-intro">
                            Welcome to Setup Guide of The Ambassador Platform’s Admin Dashboard! This section is
                            designed to guide you through set up.
                        </div>

                        <div className="start-guide-wizard-grid">
                            <div className="column">
                                <div className="setup-start-block">
                                    <div className="policy-title">5. Additional Data</div>
                                    <p className="video-privacy-link">
                                        Watch this <span onClick={this.showExplanationModal}>video explanation</span> to
                                        see how it works
                                    </p>

                                    <p>
                                        Activate the additional data questions you'd like to ask your prospects when
                                        they sign up.
                                    </p>

                                    <div className="tip-block tap-tip-block">
                                        <img src={tipIcon} alt="tip" />
                                        <span>
                                            We recommend you don’t use more than three unless there’s a good reason to
                                            do so. Activate a question to see how it'll look like in the preview.
                                        </span>
                                    </div>

                                    {!isLoading && questions && (
                                        <OptionalQuestionsForm
                                            questions={questions}
                                            questionsLoading={false}
                                            account={account}
                                            setupWizard={true}
                                        />
                                    )}

                                    <div className="setup-steps-buttons">
                                        <button className="previous-step-button" onClick={this.prevStep}>
                                            Previous
                                        </button>
                                        <button onClick={this.finish}>Finish</button>
                                    </div>
                                    <div className="setup-skip-text">
                                        If you skip this step, you'll find it later in <SettingsIcon /> Settings
                                    </div>
                                </div>
                            </div>

                            <div className="column preview">
                                <div className="preview-title">
                                    <span>Preview </span>
                                    <span>Additional data questions in TAP Feed sign up</span>
                                </div>
                                <div className="preview-optional-data">
                                    <div className="optional-data-header-title">
                                        <ChevronIcon />
                                        <span>Sign up to chat with Ambassador</span>
                                    </div>
                                    <div className="step-block-title">
                                        <div className="first-step">
                                            <span className="register-step-circle"></span>
                                            <span className="step-text">Step 1</span>
                                        </div>
                                        <div className="steps-line active"></div>
                                        <div className="second-step">
                                            <span className="register-step-circle"></span>
                                            <span className="step-text">Step 2</span>
                                        </div>
                                    </div>
                                    <PreviewOptionalQuestions questions={activeQuestions} />

                                    <button className="preview-start-conversation-button">Start Conversation</button>
                                </div>
                                <div className="preview-floor">
                                    Prospects will find the additional data questions in the sign up form. Activate a
                                    question to see the preview here
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Modal
                    open={showExplanationModal}
                    bodyStyles={{ padding: 0 }}
                    centered={true}
                    width={800}
                    title={null}
                    footer={null}
                    destroyOnClose={true}
                    keyboard={false}
                    onCancel={this.handleCloseExplanationModal}
                    className="setup-modal"
                >
                    <ExplanationModal handleCloseExplanationModal={this.handleCloseExplanationModal} />
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        optionalQuestions: state.optionalQuestions,
    }
}, {})(OptionalData)
