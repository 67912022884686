import { all, call } from 'redux-saga/effects'

import campaignCreateSaga from './campaign-create-saga'
import campaignFetchSaga from './campaign-fetch-saga'

function* campaignRootSaga() {
    yield all([call(campaignFetchSaga), call(campaignCreateSaga)])
}

export default campaignRootSaga
