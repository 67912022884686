import React from 'react'

const avatar = (avatar, key, name) => {
    const formatedName = name.split(' ')[0].slice(0, 8)
    return (
        <div key={key} className="avatars-block">
            <div className="amnassadors-img-block">
                <img className="content-group-check-img" src={avatar} />
            </div>
            <div className="amnassadors-avatar-name" key={key}>
                {formatedName}
            </div>
        </div>
    )
}

export const prepareAmbassadorsCount = (ambassadorsTotal, selectedCount, selected, unselected, data, isAllSelected) => {
    const selectedAvatars = !isAllSelected
        ? data.filter((item) => selected.has(item.id))
        : data.filter((item) => !unselected.has(item.id))

    if (selectedCount === 0) {
        return null
    } else if (isAllSelected && selectedCount === 0) {
        return `+ ${selectedCount - selectedAvatars.length} more`
    } else if (isAllSelected && selectedCount - selectedAvatars.length === 0) {
        return null
    } else if (isAllSelected) {
        return `+ ${selectedCount - selectedAvatars.length} more`
    } else if (!isAllSelected && selectedCount > 10) {
        return `+ ${selectedCount - selectedAvatars.length} more`
    } else {
        return null
    }
}

export const prepareAvatars = (selected, unselected, data, isAllSelected) => {
    const selectedAvatars = !isAllSelected
        ? data.filter((item) => selected.has(item.id))
        : data.filter((item) => !unselected.has(item.id))

    return selectedAvatars.map((item, i) => {
        if (i < 10) {
            return avatar(
                item.avatar ? item.avatar.sizes['70x70'] || item.avatar.original : null,
                item.id,
                item.first_name
            )
        }
    })
}

export const allSelectedCheck = (ambassadorsTotal, selectedCount) => {
    if (ambassadorsTotal === selectedCount) {
        return <div className="all-selected">All Ambassadors are selected</div>
    }
    return null
}
