import sanitizeHtml from 'sanitize-html'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import view from '../../../../../../assets/img/svg/view.svg'
import viewHidden from '../../../../../../assets/img/svg/view_hidden.svg'
import BlockProspectPopover from '../../../../../components/BlockProspectPopover'

import dayjs from 'dayjs'
import store from '../../../../../redux/store'
import { GET_PROSPECT_INFO } from '../../../../../redux/actions/user/userActions'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

class TextMessage extends PureComponent {
    createLinksInMessage(text) {
        return (text || '').replace(/(\s|^)(((https?:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
            var hyperlink = url
            if (!hyperlink.match('^https?://')) {
                hyperlink = 'https://' + hyperlink
            }
            return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>'
        })
    }

    handleOpenUserInfoPopup(userInfo) {
        if (userInfo.role_key === 'prospect') {
            store.dispatch({
                type: GET_PROSPECT_INFO,
                payload: {
                    userId: userInfo.id,
                },
            })
        }
    }

    render() {
        const { message, unwordDialog, search, searchBy, account } = this.props
        const {
            user,
            content,
            id,
            hide_keywords: hideKeywords,
            parentMessage,
            created_at: messageCreatedAt,
            isUserBlocked,
            is_hidden: isHidden,
        } = message
        const urlRegExpression = new RegExp(
            '([a-zA-Z0-9]+://)?(\\w+:\\w+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:\\d+)?(/.*)?'
        )
        const createdAt = dayjs(messageCreatedAt).calendar(null, {
            sameDay: 'H:mm',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday at ]H:mm',
            lastWeek: 'DD MMMM',
            sameElse: 'DD MMMM',
        })

        const textSearch = searchBy === 'message' && content.text.includes(search)
        const userSearch = searchBy === 'user' && user.name.includes(search)

        const getText = (text) => {
            if (text && typeof text === 'string') {
                if (hideKeywords && /\[\/badword]/g.test(text)) {
                    message.toggleable = true
                    text = text.replace(/\[badword]/g, '<span class="bad-word">')
                    text = text.replace(/\[\/badword]/g, '</span>')
                } else if (!hideKeywords && /\[\/badword]/g.test(text)) {
                    message.toggleable = true
                    text = text.replace(/\[badword]/g, '<span class="bad-word">')
                    text = text.replace(/\[\/badword]/g, '</span>')
                } else if (urlRegExpression.test(text)) {
                    text = this.createLinksInMessage(text)
                } else {
                    text = text.replace(/\[badword]/g, '<span>')
                    text = text.replace(/\[\/badword]/g, '</span>')
                }
            }
            return text
        }

        content.text = getText(content.text)
        let parentTextSearch = false
        let parentUserSearch = false
        if (parentMessage) {
            parentTextSearch =
                searchBy === 'message' && parentMessage.content && parentMessage.content.text.includes(search)
            parentUserSearch = searchBy === 'user' && parentMessage.user && parentMessage.user.name.includes(search)
            parentMessage.content.text = getText(parentMessage.content.text)
        }
        const { toggleable } = message

        const links = document.querySelectorAll('.message-hyperlink')
        links.length > 0 && links.forEach((link) => (link.style.textDecoration = 'underline'))
        return (
            <div id={id} className={user && user.id == account.account_info.id ? 'msg-item mod-my' : 'msg-item'}>
                <div className="layer" />
                <div className={'msg-avatar-block' + (user.role_key === 'prospect' ? ' msg-prospect-avatar' : '')}>
                    {user && user.avatar ? (
                        <img
                            className="msg-item-img"
                            src={user.avatar.sizes['70x70'] || user.avatar.original}
                            alt="img"
                            onClick={() => this.handleOpenUserInfoPopup(user)}
                        />
                    ) : (
                        <div className="msg-avatar-text" onClick={() => this.handleOpenUserInfoPopup(user)}>
                            {user ? user.name.charAt(0) : ''}
                        </div>
                    )}
                </div>
                <div className={`msgs-block ${isHidden && !isUserBlocked ? 'blocking-control' : ''}`}>
                    <div className={`msg-block-item ${isHidden || isUserBlocked ? 'blocked' : ''}`}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent:
                                    user && user.id == account.account_info.id ? 'flex-end' : 'space-between',
                            }}
                        >
                            <div className="chat-nav-name-container">
                                <p
                                    className={
                                        user && user.id == account.account_info.id
                                            ? 'hidden-block'
                                            : 'msg-name' + (userSearch ? ' search-word' : '')
                                    }
                                >
                                    {user && user.name ? user.name : 'Deleted User'}
                                </p>
                                {isUserBlocked ? (
                                    <BlockProspectPopover className="chat-blocked-button-chat" userId={user.id} />
                                ) : null}
                            </div>
                            <span className="msg-time">{createdAt}</span>
                        </div>
                        {parentMessage && (
                            <div className="msg-parent">
                                {account.account_info && account.account_info.id != parentMessage.user_id && (
                                    <p className={'msg-name' + (parentUserSearch ? ' search-word' : '')}>
                                        {parentMessage.user && parentMessage.user.name
                                            ? parentMessage.user.name
                                            : 'Deleted user'}
                                    </p>
                                )}
                                {parentMessage.content && parentMessage.content.text && (
                                    <p
                                        className={'msg-text' + (parentTextSearch ? ' search-word' : '')}
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(
                                                parentMessage.content.text.replace(/\r\n|\r|\n/g, '<br/>'),
                                                {
                                                    allowedTags: [' ', 'a', 'b', 'span', 'p', 'br'],
                                                    allowedClasses: { span: ['bad-word'] },
                                                }
                                            ),
                                        }}
                                    />
                                )}
                            </div>
                        )}

                        <p
                            className={'msg-text' + (textSearch ? ' search-word' : '')}
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(content.text, {
                                    allowedTags: [' ', 'a', 'b', 'span', 'p'],
                                    allowedClasses: { span: ['bad-word'] },
                                }),
                            }}
                        />
                        <div className={`msg-controls ${!toggleable && 'msg-controls--to-left'}`}>
                            {!isUserBlocked ? (
                                <>
                                    {toggleable && (
                                        <div onClick={() => unwordDialog(id)} className="msg-controls__wrapper">
                                            {hideKeywords ? (
                                                <img
                                                    className="msg-controls__view-icon-hidden"
                                                    src={viewHidden}
                                                    alt=""
                                                />
                                            ) : (
                                                <img className="msg-controls__view-icon-visible" src={view} alt="" />
                                            )}
                                            <span
                                                className={`msg-controls__text ${
                                                    !hideKeywords && 'msg-controls__text--visible'
                                                }`}
                                            >
                                                {hideKeywords
                                                    ? 'Keyword is hidden from users'
                                                    : 'Keyword is visible to all users'}
                                            </span>
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        currentChat: state.currentChat,
    }
})(TextMessage)
