import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { SearchCriteria, DatesIntervalSelector } from './components'
import { fetchLiveStreams } from '../../../../redux/actions/live-streams/live-streams-actions'

const statusOptions = [
    {
        key: 'all',
        name: 'All Streams',
    },
    {
        key: 'upcoming',
        name: 'Upcoming',
    },
    {
        key: 'inProgress',
        name: 'In Progress',
    },
    {
        key: 'completed',
        name: 'Completed',
    },
    {
        key: 'cancelled',
        name: 'Cancelled',
    },
    {
        key: 'onDemand',
        name: 'On-Demand',
    },
]

const stateOptions = [
    {
        key: 'all',
        name: 'All Statuses',
    },
    {
        key: 'public',
        name: 'Public',
    },
    {
        key: 'private',
        name: 'Private',
    },
    {
        key: 'archived',
        name: 'Archived',
    },
]

const StreamsListFilters = ({ resetPagination }) => {
    const dispatch = useDispatch()

    const [status, setStatus] = useState('all')
    const [streamState, setStreamState] = useState('all')
    const [selectedDateRange, setSelectedDateRange] = useState({
        fromDate: null,
        toDate: null,
    })

    const isFirstRun = useRef(true)

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false
            return
        }

        const filter = {}
        if (status && status !== 'all') filter.status = status
        if (streamState && streamState !== 'all') filter.state = streamState
        if (selectedDateRange.fromDate) filter.date = selectedDateRange

        dispatch(fetchLiveStreams({ filter }))
        resetPagination(filter)
    }, [status, streamState, selectedDateRange, dispatch, resetPagination])

    const handleStatusChange = useCallback((status) => {
        setStatus(status)
    }, [])

    const handlestreamStateChange = useCallback((streamState) => {
        setStreamState(streamState)
    }, [])

    const onDatesIntervalSelectorUpdate = useCallback((dateRange) => {
        setSelectedDateRange(dateRange)
    }, [])

    return (
        <div style={{ display: 'flex', marginBottom: '3rem' }}>
            <SearchCriteria
                onChange={handleStatusChange}
                placeholder="Select search filter"
                value={status}
                options={statusOptions}
                styles={{ width: 177, marginRight: '2rem' }}
            />
            <SearchCriteria
                onChange={handlestreamStateChange}
                placeholder="Select search filter"
                value={streamState}
                options={stateOptions}
                styles={{ width: 177, marginRight: '2rem' }}
            />
            <DatesIntervalSelector onChange={onDatesIntervalSelectorUpdate} />
        </div>
    )
}

export default StreamsListFilters
