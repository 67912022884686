/* eslint-disable react/no-unescaped-entities */

import Modal from 'antd/es/modal'
import React from 'react'

import { ReactComponent as CloseIcon } from '../../../assets/svg/icons/close.svg'
import restrictedIcon from '../../../assets/svg/restricted.svg'

export const RestrictedModal = ({ closable, closeModal }) => {
    return (
        <Modal
            open={true}
            bodyStyle={{ padding: 0 }}
            width={'60rem'}
            zIndex={9999}
            centered={true}
            footer={null}
            closable={false}
            maskClosable={!!closable}
            destroyOnClose
            onCancel={closeModal}
            className="restricted-modal"
            getContainer={!closable ? '#modal-restricted-area' : ''}
        >
            <div className="restricted-modal-body">
                {closable && <CloseIcon className="close-modal-button" onClick={closeModal} />}
                <p className="restricted-modal-header">Oops, this feature is not supported by your license</p>
                <img src={restrictedIcon} className="image-restricted" alt="restricted" />
                <p className="restricted-modal-text">
                    You have a THE Student Chat license. This functionality is only available for organizations that
                    have TAP Full License. We'd love to support you in recruiting your best-fit students.
                </p>
                <p className="restricted-modal-text">
                    If you want to provide a similar to THE Student Chat experience to users on your institution's
                    website, along with other useful features, please let us know, and we'll get back to you as soon as
                    we can.
                </p>
                <a
                    href="https://www.theambassadorplatform.com/book-a-demo?hsCtaTracking=535c1b08-9e1c-44ad-88bf-f69dea284e5c%7Cc6f20b98-2f1a-4b99-b836-f56dd5c3d84a"
                    className="contact-button"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Contact our team
                </a>
            </div>
        </Modal>
    )
}
