import { all, call, takeEvery, select, put } from 'redux-saga/effects'

import API from '../../../api'
import { LIVE_STREAMS } from '../../actions/live-streams/live-streams-action-types'
import { fetchLiveStreams } from '../../actions/live-streams/live-streams-actions'
import { openNotificationModal } from '../../actions/notification-modal/notification-modal-actions'
import {
    getIsGroupAdmin,
    getCurrentGroupUniversityId,
    getLiveStramsOffset,
    getLiveStramsFilters,
} from '../../selectors'

function* createLiveSreamSaga({ payload }) {
    const isGroupAdmin = yield select(getIsGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
    const offset = yield select(getLiveStramsOffset)
    const filter = yield select(getLiveStramsFilters)

    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        payload.universityId = currentGroupUniversityId || payload.university_id
    }

    try {
        const response = yield call(() => {
            return API.liveStreams.createStream(payload)
        })

        if (response.success) {
            yield put({
                type: LIVE_STREAMS.CREATE_SUCCESS,
                payload: {},
            })

            yield put(fetchLiveStreams({ offset, filter }))

            yield put(openNotificationModal(LIVE_STREAMS.CREATE_SUCCESS))
        } else {
            yield put({
                type: LIVE_STREAMS.CREATE_ERROR,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: LIVE_STREAMS.CREATE_ERROR,
        })
    }
}

function* watchLiveSreamCreateSaga() {
    yield takeEvery(LIVE_STREAMS.CREATE, createLiveSreamSaga)
}

function* liveStreamCreateSaga() {
    yield all([call(watchLiveSreamCreateSaga)])
}

export default liveStreamCreateSaga
