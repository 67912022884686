import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import { connect } from 'react-redux'
import './styles.css'
import config from '../../../system/config'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

import { USERS_INFO_REQUESTED } from '../../../redux/actions/content/usersActions'
import Notifications from 'react-notification-system-redux'
import { isAllowed, checkIfAnyTapUrlAvailable, getAnyTapUrl, getCommonRoutePath } from '../../../helpers/helpers'
import { RestrictedModal } from '../../../components/modals/RestrictedModal'
import { ProtectedNavLink } from 'src/app/components/protected-nav-link'
import { LicensesEnum } from 'src/types/general'
import { Protect } from 'src/app/permissions/Protect'

const PRESS_ENTER = 13
const PRESS_BACKSPACE = 8

class TrackedButton extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            tags: [],
            tagsInput: '',
            campaign: '',
            source: '',
            title: '',
            description: '',
            buttonTitle: '',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: USERS_INFO_REQUESTED,
            payload: {
                filter: {
                    university_id: this.props.account.account_info.university_id,
                },
                limit: this.props.users.limit,
                offset: this.props.users.offset,
            },
        })

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'promotion',
            },
        })
    }

    handleChange({ target }) {
        const { value, name } = target
        this.setState((state) => ({
            ...state,
            [name]:
                name === 'tagsInput' || name === 'description' || name === 'title' || name === 'buttonTitle'
                    ? value
                    : value.replace(/\s/gm, ''),
        }))
    }

    buttonLoaded = (e) => {
        if (!this.state.scrollButton) {
            document.querySelector('#frameID').style.height =
                document.querySelector('#frameID').scrollHeight + 70 + 'px'
            this.setState({ scrollButton: true })
        }
    }

    handleKeyUp(e) {
        const key = e.keyCode

        if (key === PRESS_ENTER) {
            this.addNewTag()
        }
    }

    handleKeyDown(e) {
        const key = e.keyCode
        if (key === PRESS_BACKSPACE && !this.state.tagsInput) {
            this.editPreviousTag()
        }
    }

    addNewTag() {
        const { tags, tagsInput } = this.state
        const tag = tagsInput.trim()

        if (!tag) {
            return
        }
        if (tags.length === 10) {
            store.dispatch(
                Notifications.error({
                    title: 'Error',
                    message: "You can't add more than 10 terms.",
                    position: 'tr',
                    action: '',
                })
            )
            return
        }

        this.setState({
            tags: [...tags, tag],
            tagsInput: '',
        })
    }

    editPreviousTag() {
        const { tags } = this.state

        const tag = tags.pop()

        this.setState({ tags, tagsInput: tag })
    }

    render() {
        const { campaign, source, tags, tagsInput, title, description, buttonTitle } = this.state
        const { account } = this.props

        const tapType =
            account.account_info.universityGroup && !account.currentGroupUniversityId ? 'groupID' : 'universityID'
        const tapId =
            account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? account.account_info.universityGroup.id
                : account.currentGroupUniversityId
                ? account.currentGroupUniversityId
                : account.account_info.university.id

        const universityURL = account.account_info.university.university_url
            ? account.account_info.university.university_url
            : ''
        const urlTags = tags.map((tag) => encodeURIComponent(tag.replace(/"|'/gm, ''))).join('&searchTerms=')
        const buttonScr = `<script>(function(w,d,s,o,f,js,fjs){w['button']=o;w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments)};js=d.createElement(s),fjs=d.getElementsByTagName(s)[0];js.id=o;js.src=f;js.async=1;fjs.parentNode.insertBefore(js,fjs);}(window,document,'script','mw', '${
            config.button_script
        }'));mw('init',{${tapType}:${tapId},title:'${buttonTitle}',href:'${universityURL}?utm_medium=button${
            source ? '&utm_source=' + source : ''
        }${campaign ? '&utm_campaign=' + campaign : ''}${title ? '&pageTitle=' + title + '' : ''}${
            description ? '&pageDescription=' + description + '' : ''
        }${urlTags ? '&searchTerms=' + urlTags : ''}'});</script>`

        const friendlyTags = tags.map((tag) => tag.replace(/"|'/gm, '')).join(', ')

        let iframePath =
            config.tap_page_url +
            (account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? 'group/' + account.account_info.universityGroup.id
                : account.currentGroupUniversityId || account.account_info.university.id)
        const params = `?tap-dashboard=true&utm_medium=button${urlTags ? '&searchTerms=' + urlTags : ''}${
            source ? '&utm_source=' + encodeURIComponent(source) : ''
        }${campaign ? '&utm_campaign=' + encodeURIComponent(campaign) : ''}${
            title ? '&pageTitle=' + encodeURIComponent(title) + '' : ''
        }${description ? '&pageDescription=' + encodeURIComponent(description) + '' : ''}`
        iframePath += params
        const randomKey = Math.random()

        return (
            <Protect>
                {checkIfAnyTapUrlAvailable() ? (
                    <div id="page-body" className="page-body js-index">
                        <section className="content settings modal-restricted-area" id="modal-restricted-area">
                            <h3 className="settings-title">Button</h3>

                            <div className="settings-grid has-tutorial">
                                <div className="column">
                                    <div className="tutorial-intro">
                                        <p>
                                            You can place buttons on your website or elsewhere to link to your TAP Feed
                                            and track the source of your prospects. Configure your button using the
                                            settings below, then paste the URL into links on your website pages. There
                                            is no limit to the number of buttons you can set up, and you can configure
                                            each one differently.
                                        </p>
                                    </div>
                                    <hr className="settings-hr"></hr>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Select search filters</h4>
                                        <p>Please choose the search filters that your Button should link to.</p>
                                        <p>
                                            Please add search filters that will pre-filter your Button. Remember that
                                            the page will be filtered by all of these search terms eg. if you search
                                            'International' and 'Business' results will be shown for content types with
                                            both of those search terms.
                                        </p>
                                        <p>
                                            Press
                                            <code>enter</code>
                                            to add a term. Press <code>backspace</code>
                                            to edit the last term.
                                        </p>
                                        <div className="tagger">
                                            <div className="tags">
                                                <ul>
                                                    {tags.map((tag, i) => (
                                                        <li key={tag + i} className="tag">
                                                            {tag}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <input
                                                    type="text"
                                                    placeholder="Add a search filter.."
                                                    name="tagsInput"
                                                    value={tagsInput}
                                                    onChange={this.handleChange}
                                                    className="tag-input"
                                                    onKeyUp={this.handleKeyUp}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">
                                            Choose your button title text
                                            <span>(optional)</span>
                                        </h4>
                                        <p>
                                            Choose the text that you would like to be on your button (40 characters
                                            maximum). If you do not enter anything, the text will show 'Chat to our
                                            community'
                                        </p>
                                        <input
                                            maxLength="40"
                                            className="promotion-input"
                                            placeholder="Add a button title..."
                                            name="buttonTitle"
                                            value={buttonTitle}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">
                                            Add a UTM source tag
                                            <span>(optional)</span>
                                        </h4>
                                        <p>
                                            Add a source (for example, 'website') that you would like to see in tracking
                                            data. This is usually the place where you will be using the button
                                        </p>
                                        <input
                                            className="promotion-input"
                                            placeholder="Add a UTM source tag..."
                                            name="source"
                                            value={source}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">
                                            Add a UTM campaign tag
                                            <span>(optional)</span>
                                        </h4>
                                        <p>
                                            Add a campaign (for example, 'Open Day') that you would like to see in
                                            tracking data. This is so that you can track individual campaigns
                                        </p>
                                        <input
                                            className="promotion-input"
                                            placeholder="Add a UTM campaign tag..."
                                            name="campaign"
                                            value={campaign}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Write a page title for this promotion</h4>
                                        <p>
                                            This optional title will be shown on the TAP Feed that opens when a user
                                            clicks on this button
                                        </p>
                                        <input
                                            className="promotion-input"
                                            placeholder="Chat to our international students"
                                            name="title"
                                            value={title}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Write a page description</h4>
                                        <p>
                                            This optional description will be shown on the TAP Feed that opens when a
                                            user clicks on this button
                                        </p>
                                        <input
                                            className="promotion-input"
                                            name="description"
                                            value={description}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>
                                </div>

                                <div className="column resources">
                                    <h3 className="video-title">Video tutorial: Using buttons</h3>
                                    <div className="video-wrapper">
                                        <iframe
                                            src="https://player.vimeo.com/video/757181355?h=a71cd53d35&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
                                            width="640"
                                            height="373"
                                            frameBorder="0"
                                            allow="autoplay; fullscreen"
                                            allowFullScreen
                                        ></iframe>
                                    </div>

                                    <div className="resources-box">
                                        <h3>Helpful resources</h3>
                                        <ul>
                                            <li>
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="https://knowledge.theambassadorplatform.com/how-to-use-the-promotion-feature"
                                                    target="_blank"
                                                >
                                                    How to use the Promotion feature
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="column spacer"></div>
                            </div>

                            <div className="settings-grid tap-page-widget">
                                <div className="column">
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Summary</h4>
                                        <p>Тhese are the settings for your button</p>
                                        <table className="promotion-table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Search filters
                                                        {friendlyTags ? <small>{tags.length} selected</small> : null}
                                                    </td>
                                                    <td>{friendlyTags || <span>None selected</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>UTM source tag</td>
                                                    <td>
                                                        {source ? <code>{source}</code> : <span>None selected</span>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>UTM campaign tag</td>
                                                    <td>
                                                        {campaign ? (
                                                            <code>{campaign}</code>
                                                        ) : (
                                                            <span>None selected</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        UTM medium tag
                                                        <small>Not editable</small>
                                                    </td>
                                                    <td>
                                                        <code>button</code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        TAP Feed link
                                                        <small>Detected automatically</small>
                                                    </td>
                                                    <td>
                                                        <code>{getAnyTapUrl()}</code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Page title</td>
                                                    <td>{title ? <code>{title}</code> : <span>None selected</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>Page description</td>
                                                    <td>
                                                        {description ? (
                                                            <code>{description}</code>
                                                        ) : (
                                                            <span>None selected</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Use your button</h4>
                                        <p>
                                            Тhis is your button code. Copy and paste this anywhere you would like the
                                            button to appear
                                        </p>
                                        <textarea
                                            cols="40"
                                            rows="5"
                                            readOnly="readOnly"
                                            className="settings-url"
                                            value={`<script>(function(w,d,s,o,f,js,fjs){w['button']=o;w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments)};js=d.createElement(s),fjs=d.getElementsByTagName(s)[0];js.id=o;js.src=f;js.async=1;fjs.parentNode.insertBefore(js,fjs);}(window,document,'script','mw', '${
                                                config.button_script
                                            }'));mw('init',{${tapType}:${tapId},title:"${buttonTitle || ''}",href:"${
                                                getAnyTapUrl() + params
                                            }"});</script>`}
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Button example</h4>
                                        <p>
                                            Тhis is how your button will look, unless you choose to edit or Customize
                                            the styles further yourself
                                        </p>
                                        <iframe
                                            id="frameID"
                                            srcDoc={buttonScr}
                                            width="100%"
                                            onLoad={this.buttonLoaded}
                                            sandbox="allow-forms allow-scripts allow-top-navigation allow-same-origin allow-popups"
                                        ></iframe>
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Widget example</h4>
                                        <p>
                                            This is an example of how your TAP Feed, that is linked to a filtered
                                            button, will look like
                                        </p>
                                        <iframe
                                            key={randomKey}
                                            name="tap-dashboard-iframe"
                                            id="tap-iframe-widget"
                                            src={iframePath}
                                            width="100%"
                                            height="700"
                                            style={{ border: 'none' }}
                                            scrolling="yes"
                                            data-type="widget"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                ) : (
                    <div id="page-body" className="page-body js-index">
                        <section className="content settings modal-restricted-area" id="modal-restricted-area">
                            <h3 className="settings-title">Unavailable</h3>
                            <div className="settings-intro">
                                <p>
                                    You must set up your{' '}
                                    <ProtectedNavLink
                                        exact
                                        to={getCommonRoutePath() + '/settings/tap-page'}
                                        licenseName={LicensesEnum.TapFeed}
                                    >
                                        TAP Feed
                                    </ProtectedNavLink>{' '}
                                    in order to use Promotion
                                </p>
                            </div>
                        </section>
                    </div>
                )}
                {!isAllowed('promotion') && <RestrictedModal closable={false} />}
            </Protect>
        )
    }
}

export default connect((state) => {
    return { account: state.account, users: state.users }
})(TrackedButton)
