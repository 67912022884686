const ALLOWED_THE_GROUP_IDS = new Set([
    40, // THE Group
    42, // Demo THE Group
])

export const checkCanSeeLiveEvents = (university) => {
    const liveEventPermission = university.permissions.find((item) => item.key === 'live_events')
    return liveEventPermission ? liveEventPermission.is_enabled : true
}

export const checkCanSeeLiveStreams = (university) => {
    const liveEventPermission = university.permissions.find((item) => item.key === 'live_stream')
    return liveEventPermission ? liveEventPermission.is_enabled : false
}

export const checkCanSeeOTPVerification = (university) => {
    const otpVerificationPermission = university.permissions.find(
        (item) => item.key === 'additional_data_phone_verification'
    )
    return otpVerificationPermission ? otpVerificationPermission.is_enabled : false
}

export const checkCanSeeThidPartyAuth = (university, type) => {
    const thidPartyAuthPermission = university.permissions.find((item) => item.key === type)
    return thidPartyAuthPermission ? thidPartyAuthPermission.is_enabled : false
}

export const checkCanSeeFlags = (university) => {
    const flagsPermission = university.permissions.find((item) => item.key === 'display_ambassador_country_flag')
    return flagsPermission ? flagsPermission.is_enabled : false
}

export const checkCanSeeSso = (account) => {
    const {
        account_info: { isGroupAdmin, university },
        currentGroupUniversityId,
    } = account
    // not allowed change sso for some uni under group admin
    if ((isGroupAdmin && currentGroupUniversityId) || !university) {
        return false
    }
    const ssoPermission = university.permissions.find((item) => item.key === 'sso')
    return ssoPermission ? ssoPermission.is_enabled : true // by default if permission doesn't exist then allowed seeing
}

export const checkSsoEncryption = (university) => {
    const SsoEncryptionPermission = university.permissions.find((item) => item.key === 'sso_encryption')
    return SsoEncryptionPermission ? SsoEncryptionPermission.is_enabled : false
}

export const checkCanSeeExperienceBasedQuestions = (university) => {
    const EBQPermission = university.permissions.find((item) => item.key === 'experience_based_questions')
    return EBQPermission ? EBQPermission.is_enabled : false
}

export const checkCanSeeGroupChats = (university) => {
    const GroupChatsPermission = university.permissions.find((item) => item.key === 'group_chats')
    return GroupChatsPermission ? GroupChatsPermission.is_enabled : false
}

export const checkCanSeeTrendInsights = (university) => {
    const TrendInsightsPermission = university.permissions.find((item) => item.key === 'reporting_trend')
    return TrendInsightsPermission ? TrendInsightsPermission.is_enabled : false
}

export const checkIsInTHE = ({ partnerGroups = [] }) => partnerGroups.some(({ id }) => ALLOWED_THE_GROUP_IDS.has(id))

export const checkIfIsTHE = (id) => ALLOWED_THE_GROUP_IDS.has(id)

export const isMenuItemAllowed = (menuItemId, isGroupAdmin, permissions) => {
    if (!isGroupAdmin) return true

    const itemPermission = permissions.find(({ key }) => key === menuItemId)

    if (!itemPermission) return true

    return itemPermission.is_enabled
}

export const isCampaignManagementAllowed = (university) => {
    const CampaignManagementPermission = university.permissions.find((item) => item.key === 'campaign_management')
    return CampaignManagementPermission ? CampaignManagementPermission.is_enabled : false
}

export const isUniversitySelectAllowed = (permissions) => {
    const itemPermission = permissions.find(({ key }) => key === 'list_of_group_universities')

    if (!itemPermission) return true

    return itemPermission.is_enabled
}

export const checkCanSeeCommunity = (university) => {
    const communityPermission = university.permissions.find((item) => item.key === 'community')
    return communityPermission ? communityPermission.is_enabled : false
}
