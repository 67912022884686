import API from '../../../api'
import { call, put, select } from 'redux-saga/effects'
import {
    ACCOUNT_INFO_REQUESTED,
    OPEN_CHANGE_EMAIL_MODAL,
    CLOSE_CHANGE_EMAIL_MODAL,
    OPEN_CHANGE_PASSWORD_MODAL,
    CLOSE_CHANGE_PASSWORD_MODAL,
    UNIVERSITY_CHANGE_AVATAR_FAILED,
    UNIVERSITY_CHANGE_AVATAR_SUCCESS,
    CHANGE_UNIVERSITY_NAME_SUCCESS,
    CHANGE_UNIVERSITY_NAME_FAILED,
    CHANGE_DISPLAY_NAME_FOR_PARTNERS_SUCCESS,
    CHANGE_DISPLAY_NAME_FOR_PARTNERS_FAILED,
} from '../../actions/account/accountActions'

import {
    ACCOUNT_CHANGE_EMAIL_FAILED,
    ACCOUNT_CHANGE_EMAIL_SUCCESS,
    ACCOUNT_CHANGE_PASSWORD_FAILED,
    ACCOUNT_CHANGE_PASSWORD_SUCCESS,
    ACCOUNT_CHANGE_PASSWORD_SEND_LETTER_SUCCESS,
    ACCOUNT_CHANGE_PASSWORD_SEND_LETTER_FAILED,
    ACCOUNT_CHANGE_EMAIL_SEND_LETTER_SUCCESS,
    ACCOUNT_CHANGE_EMAIL_SEND_LETTER_FAILED,
    ACCOUNT_CHANGE_AVATAR_FAILED,
    ACCOUNT_CHANGE_AVATAR_SUCCESS,
    OPEN_UNIVERSITY_NAME_CHANGED_MODAL,
    ACCOUNT_CHANGE_FIRST_NAME_SUCCESS,
    ACCOUNT_CHANGE_FIRST_NAME_FAILED,
    ACCOUNT_CHANGE_LAST_NAME_SUCCESS,
    ACCOUNT_CHANGE_LAST_NAME_FAILED,
} from '../../actions/account/accountEditActions'
import { OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'
import { ACCOUNT } from '../../actions/account/account-types'
import { closeSuccessSignUpModal } from '../../actions/pls-sign-up/pls-sign-up-actions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* accountChangeEmailSendLetter(action) {
    try {
        const response = yield call(() => {
            return API.account.changeEmailSendLetter()
        })

        if (response.success) {
            yield put({
                type: ACCOUNT_CHANGE_EMAIL_SEND_LETTER_SUCCESS,
            })

            yield put({
                type: OPEN_CHANGE_EMAIL_MODAL,
            })
        } else {
            yield put({
                type: ACCOUNT_CHANGE_EMAIL_SEND_LETTER_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_CHANGE_EMAIL_SEND_LETTER_FAILED,
        })
    }
}

export function* accountChangePasswordSendLetter(action) {
    try {
        const response = yield call(() => {
            return API.account.changePasswordSendLetter()
        })

        if (response.success) {
            yield put({
                type: ACCOUNT_CHANGE_PASSWORD_SEND_LETTER_SUCCESS,
            })

            yield put({
                type: OPEN_CHANGE_PASSWORD_MODAL,
            })
        } else {
            yield put({
                type: ACCOUNT_CHANGE_PASSWORD_SEND_LETTER_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_CHANGE_PASSWORD_SEND_LETTER_FAILED,
        })
    }
}

export function* accountChangeEmail(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.account.changeEmail(payload.email, payload.verification_code)
        })

        if (response.success) {
            yield put({
                type: ACCOUNT_CHANGE_EMAIL_SUCCESS,
            })

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
            })

            yield put({
                type: CLOSE_CHANGE_EMAIL_MODAL,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your email address has been changed',
                },
            })
        } else {
            yield put({
                type: ACCOUNT_CHANGE_EMAIL_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_CHANGE_EMAIL_FAILED,
        })
    }
}

export function* accountChangePassword(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.account.changePassword(
                payload.old_password,
                payload.new_password,
                payload.confirm_password,
                payload.verification_code
            )
        })

        if (response.success) {
            yield put({
                type: ACCOUNT_CHANGE_PASSWORD_SUCCESS,
            })

            yield put({
                type: CLOSE_CHANGE_PASSWORD_MODAL,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your password has been changed',
                },
            })
        } else {
            yield put({
                type: ACCOUNT_CHANGE_PASSWORD_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_CHANGE_PASSWORD_FAILED,
        })
    }
}

export function* accountChangeAvatar(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.account.changeAvatar(payload.avatar)
        })

        if (response.success) {
            yield put({
                type: ACCOUNT_CHANGE_AVATAR_SUCCESS,
            })

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
            })
        } else {
            yield put({
                type: ACCOUNT_CHANGE_AVATAR_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_CHANGE_AVATAR_FAILED,
        })
    }
}

export function* universityAvatarChange(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.account.changeUniGroupAvatar(payload.avatar)
                : API.account.changeUniversityAvatar(payload.avatar, universityId)
        })

        if (response.success) {
            yield put({
                type: UNIVERSITY_CHANGE_AVATAR_SUCCESS,
            })

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
                payload: {
                    university_id: universityId,
                },
            })
        } else {
            yield put({
                type: UNIVERSITY_CHANGE_AVATAR_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: UNIVERSITY_CHANGE_AVATAR_FAILED,
        })
    }
}

export function* universityNameChange(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.account.changeUniGroupName(payload.university_name)
                : API.account.changeUniversityName(payload.university_name, universityId)
        })

        if (response && response.success) {
            yield put({
                type: CHANGE_UNIVERSITY_NAME_SUCCESS,
            })

            yield put({
                type: OPEN_UNIVERSITY_NAME_CHANGED_MODAL,
            })

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
                payload: {
                    university_id: universityId,
                },
            })
            if (action.onSuccess) action.onSuccess()
            if (action.onFinish) action.onFinish()
        } else {
            yield put({
                type: CHANGE_UNIVERSITY_NAME_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CHANGE_UNIVERSITY_NAME_FAILED,
        })
        if (action.onFinish) action.onFinish()
    }
}

export function* displayNameForPartnersChange(action) {
    const payload = action.payload

    try {
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return API.account.changeDisplayName(payload.display_name, universityId)
        })

        if (response && response.success) {
            yield put({
                type: CHANGE_DISPLAY_NAME_FOR_PARTNERS_SUCCESS,
            })

            yield put({
                type: OPEN_UNIVERSITY_NAME_CHANGED_MODAL,
            })

            yield put({
                type: ACCOUNT_INFO_REQUESTED,
                payload: {
                    university_id: universityId,
                },
            })
            if (action.onSuccess) action.onSuccess()
            if (action.onFinish) action.onFinish()
        } else {
            yield put({
                type: CHANGE_DISPLAY_NAME_FOR_PARTNERS_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CHANGE_UNIVERSITY_NAME_FAILED,
        })
        if (action.onFinish) action.onFinish()
    }
}

export function* universityPrivacyPolicyChange(action) {
    const { payload, meta } = action
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return isGroupAdmin && !universityId
                ? API.account.changePrivacyPolicy(payload.privacy_policy)
                : API.account.changePrivacyPolicy(payload.privacy_policy, universityId)
        })

        if (response && response.success) {
            yield put({
                type: ACCOUNT.EDIT_PRIVACY_POLICY_SUCCESS,
                payload,
            })

            if (!payload.noPopup) {
                yield put({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: 'Privacy policy changed',
                    },
                })
            }

            if (payload.isPlsPrivacyPolicyModal) {
                yield put(closeSuccessSignUpModal())
            }

            // TODO: Why do we need it here and why we are resetting account info ? I think we could remove this or at least do in another way to achieve something
            const getAccountInfo = (state) => state.account
            const account = yield select(getAccountInfo)

            // eslint-disable-next-line no-undef
            localStorage.removeItem('AccountInfo')
            // eslint-disable-next-line no-undef
            localStorage.setItem('AccountInfo', JSON.stringify(account.account_info))
            if (meta.onFinish) meta.onFinish(true)
        } else {
            yield put({
                type: ACCOUNT.EDIT_PRIVACY_POLICY_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT.EDIT_PRIVACY_POLICY_FAILED,
        })
        if (meta.onFinish) meta.onFinish()
    }
}

export function* setMarketingConsent(action) {
    const { payload } = action
    try {
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return API.account.setMarketingConsent({ reqestData: payload, universityId })
        })

        if (response && response.success) {
            yield put({
                type: ACCOUNT.SET_MARKETING_CONSENT_SUCCESS,
                payload,
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Marketing consent was changed',
                },
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT.SET_MARKETING_CONSENT_FAILED,
        })
    }
}

export function* setMarketingConsentText(action) {
    const { payload, meta } = action
    try {
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return API.account.setMarketingConsentText({ reqestData: payload, universityId })
        })

        if (response && response.success) {
            yield put({
                type: ACCOUNT.SET_MARKETING_CONSENT_CUSTOM_TEXT_SUCCESS,
                payload,
            })
            if (meta.onSuccess) meta.onSuccess()
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT.SET_MARKETING_CONSENT_CUSTOM_TEXT_FAILED,
        })
    }
}

export function* accountChangeFirstName(action) {
    const { payload, meta } = action

    try {
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return API.account.changeUniversityFirstName(payload.firstName, universityId)
        })

        if (response && response.success) {
            yield put({
                type: ACCOUNT_CHANGE_FIRST_NAME_SUCCESS,
                payload,
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your first name has been changed',
                },
            })
            if (meta.onSuccess) meta.onSuccess()
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_CHANGE_FIRST_NAME_FAILED,
        })
    }
}

export function* accountChangeLastName(action) {
    const { payload, meta } = action

    try {
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return API.account.changeUniversityLastName(payload.lastName, universityId)
        })

        if (response && response.success) {
            yield put({
                type: ACCOUNT_CHANGE_LAST_NAME_SUCCESS,
                payload,
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your first name has been changed',
                },
            })
            if (meta.onSuccess) meta.onSuccess()
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: ACCOUNT_CHANGE_LAST_NAME_FAILED,
        })
    }
}
