import React, { PureComponent } from 'react'
import { Card, Row, Col } from 'antd'

import AreaChart from '../components/AreaChart'
import ChartHeader from '../components/ChartHeader'

import {
    prepareTimeData,
    prepareAverageTimeData,
    prepareNumberDataWithAverage,
    numberTooltipFormatter,
    timeTooltipFormatter,
    yAxisTimeFormatter,
    pluralize,
} from '../../../helpers/chart'

class Summary extends PureComponent {
    prepareProspectAccountsData() {
        return prepareNumberDataWithAverage(this.props.data.prospectAccounts, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    prepareResponseTimeChartData() {
        return {
            data: prepareTimeData(this.props.data.ambassadorResponseTime, [
                [Date.parse(this.props.fromDate), 0],
                [Date.parse(this.props.toDate), 0],
            ]),
            averageTime: prepareAverageTimeData(this.props.data.ambassadorResponseTimeAsOne, 'No data'),
        }
    }

    prospectAccountsTooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'account'))
    }

    responseTimeTooltipFormatter() {
        return timeTooltipFormatter(this.point.x, this.point.y)
    }

    yAxisResponseTimeChart() {
        return yAxisTimeFormatter(this.value)
    }

    render() {
        const prospectAccountsData = this.prepareProspectAccountsData()
        const responseTimeData = this.prepareResponseTimeChartData()

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Prospect Accounts Created"
                                label={'Average: ' + prospectAccountsData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + prospectAccountsData.total}
                                totalLabelDescription="Across date range"
                            />
                            <AreaChart
                                color="#aaaaff"
                                data={prospectAccountsData.data}
                                tooltipFormatter={this.prospectAccountsTooltipFormatter}
                                height={200}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Median First Response Time"
                                label={responseTimeData.averageTime}
                                labelDescription="Median First Response Time (across date range)"
                                displaySingleLabel={true}
                            />
                            <AreaChart
                                color="#ff5100"
                                data={responseTimeData.data}
                                tooltipFormatter={this.responseTimeTooltipFormatter}
                                yAxisFormatter={this.yAxisResponseTimeChart}
                                height={200}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default Summary
