import React from 'react';
export function textAreaCountFormatter(_a) {
    var count = _a.count, maxLength = _a.maxLength;
    if (maxLength == null) {
        return count;
    }
    var remaining = maxLength - count;
    return (React.createElement("span", { className: remaining < 20 ? 'text-red-500' : undefined },
        remaining,
        "/",
        maxLength));
}
