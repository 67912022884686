var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { ChartCard } from './ChartCard';
import ChartHeader from '../../components/ChartHeader';
import { Spin } from 'antd';
import { useGetConversationsQuery } from 'src/app/redux/slices/reporting/idp-conversion-reporting/query';
import { useAppSelector } from 'src/app/hooks/store';
import { selectActiveUnivercityId } from 'src/app/redux/selectors/account-selectors';
import LineChart from '../../components/LineChart';
import { conversationsTooltipFormatter } from '../helpers';
export var ConversationsChart = function (_a) {
    var filter = _a.filter;
    var universityId = useAppSelector(selectActiveUnivercityId);
    var _b = useGetConversationsQuery({
        filter: filter,
        universityIds: universityId ? [universityId] : null,
    }), conversationsData = _b.data, isFetching = _b.isFetching;
    return (React.createElement(ChartCard, null,
        React.createElement(ChartHeader, { title: "Number of Conversations", label: "Average: ".concat(!isFetching && conversationsData ? conversationsData.average : 0), labelDescription: "Per day across date range", totalLabel: "Total: ".concat(!isFetching && conversationsData ? conversationsData.total : 0), totalLabelDescription: "Across date range" }),
        isFetching ? (React.createElement(Spin, { size: "large", className: "flex justify-center mt-20" })) : (React.createElement(LineChart, { color: "#d2e116", data: conversationsData ? __spreadArray([], conversationsData.data, true) : [], height: 200, tooltipFormatter: conversationsTooltipFormatter }))));
};
