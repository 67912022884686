export const CREATE_EVENT = 'CREATE_EVENT'
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS'

export const GET_EVENTS_LIST = 'GET_EVENTS_LIST'
export const GET_EVENTS_LIST_SUCCESS = 'GET_EVENTS_LIST_SUCCESS'

export const EDIT_EVENT = 'EDIT_EVENT'

export const RENEW_EVENT = 'RENEW_EVENT'

export const SET_ACTIVE_LIVE_EVENT = 'SET_ACTIVE_LIVE_EVENT'
export const SET_ACTIVE_EVENT_TAB = 'SET_ACTIVE_EVENT_TAB'

export const CHANGE_EVENT_STATUS = 'CHANGE_EVENT_STATUS'
export const CHANGE_EVENT_STATUS_SUCCESS = 'CHANGE_EVENT_STATUS_SUCCESS'

export const SET_EVENT_SPEAKERS = 'SET_EVENT_SPEAKERS'
export const SET_EVENT_AMBASSADORS = 'SET_EVENT_AMBASSADORS'

export const EVENT_CHANGE_ONLINE_USERS = 'EVENT_CHANGE_ONLINE_USERS'

export const RESET_EVENTS_LIST = 'RESET_EVENTS_LIST'

export const TOGGLE_EVENT_CHAT = 'TOGGLE_EVENT_CHAT'
export const TOGGLE_EVENT_CHAT_SUCCESS = 'TOGGLE_EVENT_CHAT_SUCCESS'

export const GET_PROSPECTS = 'GET_PROSPECTS'
export const GET_PROSPECTS_SUCCESS = 'GET_PROSPECTS_SUCCESS'
export const LOAD_NEXT_PROSPECTS = 'LOAD_NEXT_PROSPECTS'
export const LOAD_NEXT_PROSPECTS_SUCCESS = 'LOAD_NEXT_PROSPECTS_SUCCESS'
