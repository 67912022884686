import {
    NEW_NOTIFICATION,
    REMOVE_LAST_NOTIFICATION,
    REMOVE_NOTIFICATION,
} from '../../actions/components/componentsActions'

import { NOTIFICATIONS } from '../../store/initialState'

const initialState = NOTIFICATIONS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case NEW_NOTIFICATION: {
            let newItems = state.items
            newItems[newItems.length] = payload.notification

            if (newItems.length > 3) newItems.splice(0, 1)

            return {
                ...state,
                items: newItems,
            }
        }

        case REMOVE_LAST_NOTIFICATION: {
            let newNotifications = state.items
            newNotifications.splice(-1, 1)

            return {
                ...state,
                items: newNotifications,
            }
        }

        case REMOVE_NOTIFICATION: {
            let newNotifications = state.items

            let notification_position = null
            newNotifications.map((notification, index) => {
                if (notification.unique_index == payload.index) notification_position = index
            })

            newNotifications.splice(notification_position, 1)
            return {
                ...state,
                items: newNotifications,
            }
        }

        default: {
            return state
        }
    }
}
