import { all, call, takeEvery, select, put } from 'redux-saga/effects'

import API from '../../../api'
import { LIVE_EVENTS } from '../../actions/live-event/live-event-action-types'
import { fetchLiveEvents } from '../../actions/live-event/live-events-actions'
import { openNotificationModal } from '../../actions/notification-modal/notification-modal-actions'
import { getLiveEventsSlice } from '../../selectors'

function* updateLiveEventSaga({ payload }) {
    const { currentEventStatus } = yield select(getLiveEventsSlice)

    const response = yield call(() => {
        return API.liveEvent.editEvent(payload)
    })

    if (response.success) {
        yield put({
            type: LIVE_EVENTS.UPDATE_SUCCESS,
            payload: {},
        })

        yield put(
            fetchLiveEvents({
                status: currentEventStatus,
            })
        )

        yield put(openNotificationModal(LIVE_EVENTS.UPDATE_SUCCESS))
    } else {
        yield put({
            type: LIVE_EVENTS.UPDATE_ERROR,
        })
    }
}

function* updateLiveEventAmbassadorsSaga({ payload }) {
    const { currentEventStatus } = yield select(getLiveEventsSlice)

    const response = yield call(() => {
        return API.liveEvent.updateAmbassadors(payload)
    })

    if (response.success) {
        yield put({
            type: LIVE_EVENTS.EDIT_AMBASSADORS_SUCCESS,
            payload: {},
        })

        yield put(
            fetchLiveEvents({
                status: currentEventStatus,
                withGlobalTags: true,
            })
        )
    } else {
        yield put({
            type: LIVE_EVENTS.EDIT_AMBASSADORS_ERROR,
        })
    }
}

function* updateLiveEventSpeakersSaga({ payload }) {
    const { currentEventStatus } = yield select(getLiveEventsSlice)

    const response = yield call(() => {
        return API.liveEvent.updateSpeakers(payload)
    })

    if (response.success) {
        yield put({
            type: LIVE_EVENTS.UPDATE_SPEAKERS_SUCCESS,
            payload: {},
        })

        yield put(
            fetchLiveEvents({
                status: currentEventStatus,
                withGlobalTags: true,
            })
        )
    } else {
        yield put({
            type: LIVE_EVENTS.UPDATE_SPEAKERS_ERROR,
        })
    }
}

function* watchLiveEventUpdateSaga() {
    yield takeEvery(LIVE_EVENTS.UPDATE, updateLiveEventSaga)
    yield takeEvery(LIVE_EVENTS.EDIT_AMBASSADORS, updateLiveEventAmbassadorsSaga)
    yield takeEvery(LIVE_EVENTS.UPDATE_SPEAKERS, updateLiveEventSpeakersSaga)
}

function* liveEventUpdateSaga() {
    yield all([call(watchLiveEventUpdateSaga)])
}

export default liveEventUpdateSaga
