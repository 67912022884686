import { Upload, Row, Col, Button, message } from 'antd'
import React from 'react'

const beforeUpload = (file) => {
    const isVideo = file.type.includes('video/')
    if (!isVideo) {
        message.error('You can only upload video file')
    }
    return isVideo
}

const VideoUpload = ({ videoTranscode }) => {
    const handleFileChange = (info) => {
        if (info.file.status === 'done') {
            let fileType = info.file.type
            if (info.file.type === '') {
                if (info.file.name.indexOf('.vob')) {
                    fileType = 'video/vob'
                } else if (info.file.name.indexOf('.flv')) {
                    fileType = 'video/flv'
                } else if (info.file.name.indexOf('.rmvb')) {
                    fileType = 'video/rmvb'
                }
            }
            if (fileType.includes('video/')) {
                videoTranscode(info.file.originFileObj, fileType, info.file.name)
            }
        }
    }
    return (
        <>
            <Upload
                accept="video/mp4,video/x-m4v,video/x-vob,video/x-flv,video/x-rmvb,video/*"
                name="avatar"
                customRequest={({ onSuccess }) => onSuccess()}
                showUploadList={false}
                maxCount={1}
                onChange={handleFileChange}
                beforeUpload={beforeUpload}
            >
                <Row justify="center">
                    <Col flex="162px">
                        <Button type="primary" block size="large" data-automation-id="upload-btn">
                            Upload
                        </Button>
                    </Col>
                </Row>
            </Upload>
        </>
    )
}

export default VideoUpload
