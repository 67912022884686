import { QUESTIONS_INFO, QUESTIONS_INFO_SUCCESS } from '../../actions/faq/faqActions'

import { FAQ } from '../../store/initialState'

const initialState = FAQ

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case QUESTIONS_INFO: {
            return {
                ...state,
                searchItems: payload.filter && payload.filter.searchItems ? payload.filter.searchItems : [],
            }
        }

        case QUESTIONS_INFO_SUCCESS: {
            let new_state = Object.assign({}, state)
            new_state.isLoading = false
            new_state.items = payload.items

            return new_state
        }

        default: {
            return state
        }
    }
}
