import { Button } from 'antd'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import AlertIcon from '../../../../../assets/svg/icons/alert.svg'
import { ReactComponent as PinIcon } from '../../../../../assets/svg/icons/pin.svg'
import { OPEN_CHAT_GROUP_POPUP } from '../../../../redux/actions/chat/groupMessagingActions'
import { CHATS_INFO_SEARCH, CHAT_MESSAGES_INFO, SET_ACTIVE_CHAT } from '../../../../redux/actions/chat/messagingActions'
import { HIDE_NEW_MESSAGES, REPLY_MESSAGE } from '../../../../redux/actions/components/componentsActions'
import store from '../../../../redux/store'
import Loader from '../../../content/components/Loader'

import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { ChatType } from '../../../../common/constants'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

class ChatsSidebar extends PureComponent {
    constructor(props) {
        super(props)

        this.handleChatClick = this.handleChatClick.bind(this)
        this.handleCreateGroup = this.handleCreateGroup.bind(this)
        this.handleShowMoreButtonClick = this.handleShowMoreButtonClick.bind(this)
    }

    handleChatClick(event) {
        const chatId = +event.target.closest('.chat-nav-item').getAttribute('data-chat-id')

        store.dispatch({
            type: REPLY_MESSAGE,
            payload: {
                activeMessage: null,
                activePublisher: null,
            },
        })
        store.dispatch({
            type: SET_ACTIVE_CHAT,
            payload: {
                data: this.props.chats.items.find((item) => item.id === chatId),
                chat_id: chatId,
                type_chat: ChatType.GROUP_CHAT,
                search: this.props.chats.search,
            },
        })

        store.dispatch({
            type: CHAT_MESSAGES_INFO,
            payload: {
                dialog_id: chatId,
                blockReloadChat: true,
                resetMessagesCounter: true,
            },
        })

        store.dispatch({
            type: HIDE_NEW_MESSAGES,
        })
    }

    handleShowMoreButtonClick() {
        store.dispatch({
            type: CHATS_INFO_SEARCH,
            payload: {
                offset: this.props.chats.lastOffset + 100,
                withGlobalTags: true,
                archived: true,
            },
        })
    }

    handleCreateGroup() {
        store.dispatch({
            type: OPEN_CHAT_GROUP_POPUP,
        })
    }

    render() {
        const { chats, activeDialog, chatMessages } = this.props
        if (chats.isLoading && chats.isFirstRequest) {
            return (
                <div className="chat-nav">
                    <Loader />
                </div>
            )
        }

        const chatsView = chats.items.map((chat, index) => {
            const lastMessage = {
                text: '',
                created_at: dayjs(chat.last_activity).calendar(null, {
                    sameDay: 'H:mm',
                    nextDay: '[Tomorrow]',
                    nextWeek: '[Next] dddd',
                    lastDay: '[Yesterday at ]H:mm',
                    lastWeek: 'DD MMMM',
                    sameElse: 'DD MMMM',
                }),
            }

            const ambassadorsLength = chat.members
                ? chat.members.filter(({ role_key: roleKey }) => roleKey && roleKey.includes('student')).length
                : 0
            const prospectsLength = chat.members
                ? chat.members.filter(({ role_key: roleKey }) => roleKey && roleKey.includes('prospect')).length
                : 0

            return (
                <div
                    key={chat.id}
                    className={chatMessages.dialog_id == chat.id ? 'chat-nav-item is-active' : 'chat-nav-item'}
                    data-chat-id={chat.id}
                    data-chat-array-index={index}
                    onClick={this.handleChatClick}
                >
                    <div className="chat-nav-info">
                        <h3 className="chat-nav-name chat-nav-name-group-messaging">
                            <span>{`${chat.name}`}</span>
                            {chat.is_pinned && <PinIcon />}
                        </h3>
                        <p className="chat-nav-text">
                            {ambassadorsLength} Ambassadors, {prospectsLength} Prospects
                        </p>
                        <p className="chat-nav-text">{chat.type === 1 ? 'Individual' : 'Group'}</p>
                    </div>
                    <div className="chat-nav-data">
                        <div className="chat-icons-group">
                            <p
                                className={
                                    (chat.new_messages > 0 && chat.id !== activeDialog
                                        ? 'chat-nav-msg-counter'
                                        : 'hidden-block') + ' chat-group-item'
                                }
                            >
                                {chat.new_messages}
                            </p>
                            {chat.reported && (
                                <img className="chat-group-item flaged-icon" src={AlertIcon} alt="Reported" />
                            )}
                        </div>
                        <p className="chat-nav-date">{lastMessage.created_at}</p>
                    </div>
                </div>
            )
        })

        const showMoreButton = (
            <Button className="show-more-dialogs-button" type="link" onClick={this.handleShowMoreButtonClick}>
                Show more conversations
            </Button>
        )

        return (
            <div id="chat-nav" className="chat-nav">
                <div
                    id="chat-nav-list"
                    className={
                        chats.isLoading || !chats || !chats.items || chats.items.length === 0 ? '' : 'chat-nav-list'
                    }
                >
                    {chats.isLoading ? (
                        <Loader />
                    ) : chats.items.length > 0 ? (
                        chatsView
                    ) : (
                        <div className="no-dialog-found">No search results</div>
                    )}
                    {!chats.isLoading && chats.lastReceivedCount === 100 && showMoreButton}
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        chats: state.chats,
        chatMessages: state.chatMessages,
        account: state.account,
    }
})(ChatsSidebar)
