import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { Permissions } from 'src/app/permissions/permissions'
import { PermissionEnum } from 'src/types/general'

const IsAuthorized = (props) => {
    const { children, history } = props

    const account = useSelector((state) => state.account)
    const { account_info: accountInfo, isMaintenanceMode, showIdpActivation } = account
    const isPls = Permissions.can(PermissionEnum.Pls, false)
    const isTrial = accountInfo?.university.is_trial

    if (
        window.location.href.lastIndexOf('/registration/') != -1 ||
        window.location.href.lastIndexOf('/forgot') != -1 ||
        window.location.href.lastIndexOf('/sign-in-redirect') != -1 ||
        (accountInfo && accountInfo.id && !isMaintenanceMode && !isPls && !showIdpActivation)
    ) {
        try {
            if (
                accountInfo &&
                !accountInfo.isGroupAdmin &&
                accountInfo.university &&
                !accountInfo.university.wizard_setup_passed &&
                !history.location.pathname.includes('/setup/') &&
                !isTrial
            ) {
                if (window.localStorage.getItem('setupWizardStep')) {
                    let stepLink = 'privacy-policy'
                    switch (window.localStorage.getItem('setupWizardStep')) {
                        case '2':
                            stepLink = 'training-centre'
                            break
                        case '3':
                            stepLink = 'branding'
                            break
                        case '4':
                            stepLink = 'tap-page'
                            break
                        case '5':
                            stepLink = 'optional-data'
                            break
                    }
                    history.push('/setup/' + stepLink)
                } else {
                    history.push('/setup/privacy-policy')
                }
            }
        } catch (error) {
            console.log(error)
        }
        return <div>{children}</div>
    } else if (showIdpActivation) {
        return <Redirect to="/idp-activation" />
    } else if (isPls) {
        return <Redirect to="./pls-onboarding" />
    } else if (isMaintenanceMode) {
        return <Redirect to="/maintenance" />
    } else {
        return <Redirect to="/account/login" />
    }
}

export default withRouter(IsAuthorized)
