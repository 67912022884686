import Modal from 'antd/es/modal'
import React, { PureComponent } from 'react'
import Textarea from 'react-textarea-autosize'
import { reduxForm, Field, submit } from 'redux-form'

import Sprite from '../../../../../../assets/img/symbol/sprite.svg'
import store from '../../../../../redux/store'
import UploadPostModal from '../../../components/forms/UploadPostModal'

const renderField = ({ input, accept, value, placeholder, type, className, id, meta: { touched, error, warning } }) => (
    <input
        {...input}
        className={className}
        accept={accept}
        value={value}
        id={id}
        placeholder={placeholder}
        type={type}
    />
)

const renderTextarea = ({ placeholder, type, id, className, minRows, maxRows }) => (
    <Textarea minRows={minRows} maxRows={maxRows} id={id} type={type} placeholder={placeholder} className={className} />
)

class SendMessageForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            uploadPopupOpened: false,
            image: '',
            fileType: '',
        }

        this.handelOpenUploadPopup = this.handelOpenUploadPopup.bind(this)
        this.handleCloseUploadPopup = this.handleCloseUploadPopup.bind(this)
        this.handleSubmitUploadPopup = this.handleSubmitUploadPopup.bind(this)
    }

    handelOpenUploadPopup(event) {
        const file = event.target.files[0]

        let fileType = file.type
        if (file.type === '') {
            if (file.name.indexOf('.vob')) {
                fileType = 'video/vob'
            } else if (file.name.indexOf('.flv')) {
                fileType = 'video/flv'
            } else if (file.name.indexOf('.rmvb')) {
                fileType = 'video/rmvb'
            }
        }
        this.setState({
            file: file,
            fileType: fileType,
            uploadPopupOpened: true,
        })
        if (fileType.includes('video/')) {
            // request with file data to the server for transcoding
            this.props.videoTranscode(file, fileType)
        }
    }

    handleSubmitUploadPopup() {
        document.querySelector('#content-messaging-textarea').value =
            document.querySelector('#message-picture-caption').value
        this.setState({
            uploadPopupOpened: false,
            file: '',
        })

        store.dispatch(submit('send_message_form'))
    }

    handleCloseUploadPopup() {
        this.setState({
            uploadPopupOpened: false,
            file: '',
        })
        document.querySelector('#send-message-form').reset()
        this.props.resetFileSettings()
    }

    handleKeyDown(e, cb) {
        if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
            cb()
        }
    }

    render() {
        const { handleSubmit, fileId, fileLocation, transcodeStatus, isIdpContentGroup } = this.props

        return (
            <div className="send-message-form-container">
                <form
                    action="#"
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                        this.handleKeyDown(e, handleSubmit)
                    }}
                    className="chat-form"
                    id="send-message-form"
                >
                    <Field
                        name="text"
                        component={renderTextarea}
                        type="text"
                        placeholder="Type a message"
                        className="chat-input"
                        maxRows={9}
                        minRows={1}
                        id="content-messaging-textarea"
                    />
                    {!isIdpContentGroup && (
                        <label className="chat-file-label">
                            <Field
                                name="file"
                                component={renderField}
                                type="file"
                                value={this.state.file}
                                accept="image/x-png,image/gif,image/jpeg,image/*,video/mp4,video/x-m4v,video/*"
                                className="chat-file-input"
                                onChange={this.handelOpenUploadPopup}
                            />
                            <span className="chat-file-icon">
                                <svg className="icon icon-photo">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#photo'}></use>
                                </svg>
                            </span>
                        </label>
                    )}

                    <button type="submit" className="chat-sbm">
                        <svg className="icon icon-send">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#send'}></use>
                        </svg>
                    </button>
                </form>
                <Modal
                    open={this.state.uploadPopupOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                    onCancel={this.handleCloseUploadPopup}
                    className="upload-preview-popup"
                >
                    <UploadPostModal
                        handleSubmitUploadPopup={this.handleSubmitUploadPopup}
                        file={this.state.file}
                        fileId={fileId}
                        fileType={this.state.fileType}
                        videoFile={fileLocation}
                        transcodeStatus={transcodeStatus}
                        handleClose={this.handleCloseUploadPopup}
                    />
                </Modal>
            </div>
        )
    }
}

export default reduxForm({
    form: 'send_message_form',
})(SendMessageForm)
