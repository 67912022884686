import { Form, Select, Row, Col } from 'antd'
import { add, differenceInMinutes, format, startOfDay } from 'date-fns'
import { isNil } from 'ramda'
import React, { useCallback } from 'react'

const { Option } = Select

const DURATION_STEP = 30

const createSelectOptions = () => {
    const start = startOfDay(new Date())

    const options = []

    for (let i = 0; i < 48; i++) {
        const timeValue = add(start, { minutes: DURATION_STEP * i })
        const minutes = differenceInMinutes(timeValue, start)

        const item = {
            text: format(timeValue, 'hh:mm aa'),
            value: minutes,
        }

        options.push(item)
    }

    return options
}

const options = createSelectOptions()

const renderSelectOptions = (options, fromTime) => {
    const data = !isNil(fromTime) ? options.filter(({ value }) => value > fromTime) : options

    return data.map(({ text, value }) => (
        <Option key={value} value={value}>
            {text}
        </Option>
    ))
}

const DurationInput = ({ form }) => {
    const selectOptions = renderSelectOptions(options)

    const handleFromTimeChange = useCallback(
        (value) => {
            const toTime = form.getFieldValue('toTime')

            if (toTime < value) {
                form.setFieldsValue({
                    toTime: value + DURATION_STEP,
                })
            }
        },
        [form]
    )

    return (
        <>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item label="From" name="fromTime" rules={[{ required: true, message: 'Choose time' }]}>
                        <Select placeholder="Choose time" onChange={handleFromTimeChange}>
                            {selectOptions}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            const fromTime = getFieldValue('fromTime')
                            const filteredOptions = renderSelectOptions(options, fromTime)

                            return (
                                <Form.Item
                                    label="To"
                                    name="toTime"
                                    rules={[{ required: true, message: 'Choose time' }]}
                                >
                                    <Select placeholder="Choose time">{filteredOptions}</Select>
                                </Form.Item>
                            )
                        }}
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default DurationInput
