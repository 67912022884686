var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable camelcase */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../api';
export var POSTS_INFO_REQUESTED = 'POSTS_INFO_REQUESTED';
export var POSTS_INFO_SUCCESS = 'POSTS_INFO_SUCCESS';
export var POSTS_INFO_FAILED = 'POSTS_INFO_FAILED';
export var TAGS_INFO_FAILED = 'TAGS_INFO_FAILED';
export var POSTS_TAGS_FILTER_REQUESTED = 'POSTS_TAGS_FILTER_REQUESTED';
export var ADD_DOWNLOAD_POINTS = 'ADD_DOWNLOAD_POINTS';
export var POSTS_DELETE = 'POSTS_DELETE';
export var POSTS_DELETE_FAILED = 'POSTS_DELETE_FAILED';
export var POSTS_DELETE_SUCCESS = 'POSTS_DELETE_SUCCESS';
export var OPEN_SHARE_POST_POPUP = 'OPEN_SHARE_POST_POPUP';
export var CLOSE_SHARE_POST_POPUP = 'CLOSE_SHARE_POST_POPUP';
export var SHARE_POST_REQUESTED = 'SHARE_POST_REQUESTED';
export var SHARE_POST_SUCCESS = 'SHARE_POST_SUCCESS';
export var SHARE_POST_FAILED = 'SHARE_POST_FAILED';
export var PUBLISH_POST_REQUESTED = 'PUBLISH_POST_REQUESTED';
export var PUBLISH_POST_SUCCESS = 'PUBLISH_POST_SUCCESS';
export var PUBLISH_POST_FAILED = 'PUBLISH_POST_FAILED';
export var PUBLISH_OR_UNPUBLISH_POST_SUCCESS = 'PUBLISH_OR_UNPUBLISH_POST_SUCCESS';
export var EDIT_POST_CAPTION_REQUESTED = 'EDIT_POST_CAPTION_REQUESTED';
export var EDIT_POST_CAPTION_SUCCESS = 'EDIT_POST_CAPTION_SUCCESS';
export var EDIT_POST_CAPTION_FAILED = 'EDIT_POST_CAPTION_FAILED';
export var USERS_POSTS_REQUESTED = 'USERS_POSTS_REQUESTED';
export var USERS_POSTS_SUCCESS = 'USERS_POSTS_SUCCESS';
export var USERS_POSTS_FAILED = 'USERS_POSTS_FAILED';
export var UPLOAD_FILE_INFO = 'UPLOAD_FILE_INFO';
export var UPLOAD_FILE_INFO_SUCCESS = 'UPLOAD_FILE_INFO_SUCCESS';
export var UPLOAD_FILE_INFO_FAILED = 'UPLOAD_FILE_INFO_FAILED';
export var OPEN_CONFIRM_DELETE_POPUP = 'OPEN_CONFIRM_DELETE_POPUP';
export var CLOSE_CONFIRM_DELETE_POPUP = 'CLOSE_CONFIRM_DELETE_POPUP';
export var CLEAR_POSTS_STORE = 'CLEAR_POSTS_STORE';
export var OPEN_SHARE_POST_CONTENT_PREVIEW_MODAL = 'OPEN_SHARE_POST_CONTENT_PREVIEW_MODAL';
export var CLOSE_SHARE_POST_CONTENT_PREVIEW_MODAL = 'CLOSE_SHARE_POST_CONTENT_PREVIEW_MODAL';
export var CONTENT_GROUP_POSTS_REQUESTED = 'CONTENT_GROUP_POSTS_REQUESTED';
export var EDIT_POST_GLOBAL_TAG = 'EDIT_POST_GLOBAL_TAG';
export var EDIT_POST_GLOBAL_TAG_FINISH = 'EDIT_POST_GLOBAL_TAG_FINISH';
export var VIDEO_UNIVERSITY_TRANSCODE = 'VIDEO_UNIVERSITY_TRANSCODE';
export var VIDEO_UPLOAD = 'VIDEO_UPLOAD';
export var UPDATE_SHARED_POST = 'UPDATE_SHARED_POST';
export var SET_HOME_VISIBLE_POSTS = 'SET_HOME_VISIBLE_POSTS';
export var changePostCaption = function (postId, caption) { return ({
    type: EDIT_POST_CAPTION_REQUESTED,
    payload: {
        post_id: postId,
        caption: caption,
    },
}); };
export var sharePostToRSS = function (postId, caption, onSuccess) { return ({
    type: SHARE_POST_REQUESTED,
    payload: {
        post_id: postId,
        caption: caption,
        onSuccess: onSuccess,
    },
}); };
export var togglePublishPost = function (postId, publish) { return ({
    type: PUBLISH_POST_REQUESTED,
    payload: {
        post_id: postId,
        publish: publish,
    },
}); };
export var openDeleteModal = function (id, type) { return ({
    type: OPEN_CONFIRM_DELETE_POPUP,
    payload: {
        postId: id,
        type: type,
    },
}); };
export var closeDeleteModal = function () { return ({
    type: CLOSE_CONFIRM_DELETE_POPUP,
}); };
export var getPosts = createAction(POSTS_INFO_REQUESTED);
export var clearPosts = createAction(CLEAR_POSTS_STORE);
export var clearItems = createAction('CLEAR_POSTS_ITEMS');
export var addDownloadPoints = createAction(ADD_DOWNLOAD_POINTS, function (postId) { return ({
    payload: {
        post_id: postId,
    },
}); });
export var setSearchItems = createAction('SET_SEARCH_ITEMS');
export var getMorePostsAsync = createAsyncThunk('POSTS_INFO_REQUESTED_MORE', function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, universityIds, data, filters;
        return __generator(this, function (_b) {
            state = getState();
            universityIds = state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null;
            data = state.posts.posts;
            filters = __assign({ searchItems: state.posts.searchItems }, payload === null || payload === void 0 ? void 0 : payload.filter);
            return [2 /*return*/, API.dashboardNew.posts({
                    filter: filters,
                    search: payload === null || payload === void 0 ? void 0 : payload.search,
                    sort: (payload === null || payload === void 0 ? void 0 : payload.sort) || data.sortBy,
                    limit: data.limit,
                    offset: data.offset + data.limit,
                    withGlobalTags: (payload === null || payload === void 0 ? void 0 : payload.withGlobalTags) || true,
                    universityIds: universityIds,
                    postsInIds: payload === null || payload === void 0 ? void 0 : payload.postsInIds,
                    postsNotInIds: payload === null || payload === void 0 ? void 0 : payload.postsNotInIds,
                })];
        });
    });
});
export var getNewPostsAsync = createAsyncThunk('POSTS_INFO_REQUESTED_NEW', function (payload, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, universityIds, data, filters;
        return __generator(this, function (_b) {
            state = getState();
            universityIds = state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null;
            data = state.posts.posts;
            filters = __assign({ searchItems: state.posts.searchItems }, payload === null || payload === void 0 ? void 0 : payload.filter);
            return [2 /*return*/, API.dashboardNew.posts({
                    filter: filters,
                    search: payload === null || payload === void 0 ? void 0 : payload.search,
                    sort: (payload === null || payload === void 0 ? void 0 : payload.sort) || data.sortBy,
                    limit: 10,
                    offset: 0,
                    withGlobalTags: (payload === null || payload === void 0 ? void 0 : payload.withGlobalTags) || true,
                    universityIds: universityIds,
                    postsInIds: payload === null || payload === void 0 ? void 0 : payload.postsInIds,
                    postsNotInIds: payload === null || payload === void 0 ? void 0 : payload.postsNotInIds,
                })];
        });
    });
});
export var uploadImage = createAction(UPLOAD_FILE_INFO);
export var transcodeVideo = createAction(VIDEO_UNIVERSITY_TRANSCODE);
export var uploadVideo = createAction(VIDEO_UPLOAD);
export var deletePost = createAction(POSTS_DELETE);
