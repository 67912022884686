import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
dayjs.extend(utc);
export var prepareFromDate = function (dayjsObj) {
    return dayjsObj.startOf('day').utcOffset(0, true).format();
};
export var prepareToDate = function (dayjsObj) {
    return dayjsObj.endOf('day').utcOffset(0, true).format();
};
export var getDateRange = function (days) {
    return {
        fromDate: prepareFromDate(dayjs().subtract(days - 1, 'days')),
        toDate: prepareToDate(dayjs()),
    };
};
export var getCurrentDay = function () {
    return dayjs().startOf('day');
};
export var getDateRangeForMonth = function (dayjsObj) {
    return {
        fromDate: dayjs(dayjsObj).startOf('month').format(),
        toDate: dayjs(dayjsObj).endOf('month').format(),
    };
};
export var getCurrentMonthDateRange = function () {
    return getDateRangeForMonth(dayjs());
};
export var isSameDay = function (dayjsObj, dayjsObjOrString) {
    return dayjsObj.isSame(dayjsObjOrString, 'day');
};
export var isSameMonth = function (dayjsObj, dayjsObjOrString) {
    return dayjsObj.isSame(dayjsObjOrString, 'month');
};
export var normalizeDate = function (dayjsObj) {
    return dayjs(dayjsObj).startOf('day').utcOffset(0, true).format();
};
