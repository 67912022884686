import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import store from '../../../redux/store'
import config from '../../../system/config'
import './styles.css'

import Select from 'antd/es/select'
import Notifications from 'react-notification-system-redux'
import { RestrictedModal } from '../../../components/modals/RestrictedModal'
import { checkIfAnyTapUrlAvailable, getAnyTapUrl, getCommonRoutePath, isAllowed } from '../../../helpers/helpers'
import { POPCARD_USERS_REQUESTED } from '../../../redux/actions/content/usersActions'

import { AmbassadorSelect, GroupChatSelect, LeadSelect, TrackedLinkInput } from './components'
import { ProtectedNavLink } from 'src/app/components/protected-nav-link'
import { LicensesEnum } from 'src/types/general'
import { Protect } from 'src/app/permissions/Protect'

const PRESS_ENTER = 13
const PRESS_BACKSPACE = 8

class PopCard extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            tags: [],
            tagsInput: '',
            campaign: '',
            source: '',
            ambassadorsAvatars: [],
            isAvatars: false,
            ambassadors: [],
            title: '',
            popcardButtonText: '',
            description: '',
            alignment: 'right',
            titleColor: '',
            titleDefaultColor: '#efefef',
            buttonTextColor: '',
            buttonTextDefaultColor: '#ffffff',
            backgroundColor: '',
            backgroundDefaultColor: '#4f4c4c',
            backgroundDefaultRgbaColor: 'rgba(0,0,0,.69)',
            viewType: 'standard',
            leadType: 'tap_feed',
            leadName: 'Tap feed',
            leadAmbassadorId: null,
            leadAmbassadorName: '',
            leadGroupChatId: null,
            leadGroupChatName: '',
            leadGroupChatPrivateCode: null,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: POPCARD_USERS_REQUESTED,
            payload: {
                university_id: this.props.account.account_info.university_id,
            },
        })

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'promotion',
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: POPCARD_USERS_REQUESTED,
                payload: {
                    university_id: this.props.account.account_info.university_id,
                },
            })
        }
    }

    handleChange({ target }) {
        const { value, name } = target
        this.setState((state) => ({
            ...state,
            [name]: value,
        }))
    }

    onDropChange = (val) => {
        this.setState({
            alignment: val,
        })
    }

    onLeadTypeChange = (_, select) => {
        this.setState({
            leadType: select.value,
            leadName: select.label,
            leadAmbassadorId: null,
            leadAmbassadorName: '',
            leadGroupChatId: null,
            leadGroupChatName: '',
        })
    }

    onChangeAmbassadorSelect = (_, select) => {
        this.setState({
            leadAmbassadorId: select.value,
            leadAmbassadorName: select.children,
        })
    }

    onGroupChatSelect = (_, select) => {
        this.setState({
            leadGroupChatId: select.value,
            leadGroupChatName: select.label,
            leadGroupChatPrivateCode: select.privateCode,
        })
    }

    onDropVersionChange = (val) => {
        this.setState({
            viewType: val,
        })
    }

    handleKeyUp(e) {
        const key = e.keyCode

        if (key === PRESS_ENTER) {
            this.addNewTag()
        }
    }

    handleKeyDown(e) {
        const key = e.keyCode
        if (key === PRESS_BACKSPACE && !this.state.tagsInput) {
            this.editPreviousTag()
        }
    }

    addNewTag() {
        const { tags, tagsInput } = this.state
        const tag = tagsInput.trim()

        if (!tag) {
            return
        }
        if (tags.length === 10) {
            store.dispatch(
                Notifications.error({
                    title: 'Error',
                    message: "You can't add more than 10 terms.",
                    position: 'tr',
                    action: '',
                })
            )
            return
        }

        this.setState({
            tags: [...tags, tag],
            tagsInput: '',
        })
    }

    editPreviousTag() {
        const { tags } = this.state

        const tag = tags.pop()

        this.setState({ tags, tagsInput: tag })
    }

    render() {
        const {
            tags,
            tagsInput,
            campaign,
            source,
            popcardTitle,
            popcardButtonText,
            title,
            description,
            alignment,
            backgroundColor,
            backgroundDefaultColor,
            backgroundDefaultRgbaColor,
            viewType,
            titleColor,
            titleDefaultColor,
            buttonTextColor,
            buttonTextDefaultColor,
            leadType,
            leadName,
            leadAmbassadorId,
            leadAmbassadorName,
            leadGroupChatId,
            leadGroupChatName,
            leadGroupChatPrivateCode,
        } = this.state
        const { account, ambassadorsAvatars, popcardSettings } = this.props
        const urlTags = tags.map((tag) => encodeURIComponent(tag.replace(/"|'/gm, ''))).join('&searchTerms=')
        const joinedTags = tags
            .map((tag) => {
                return "'" + tag.replace(/"|'/gm, '') + "'"
            })
            .join(',')

        const friendlyTags = tags.map((tag) => tag.replace(/"|'/gm, '')).join(', ')

        const tapType =
            account.account_info.universityGroup && !account.currentGroupUniversityId ? 'groupID' : 'universityID'
        const tapId =
            account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? account.account_info.universityGroup.id
                : account.currentGroupUniversityId
                ? account.currentGroupUniversityId
                : account.account_info.university.id

        let iframePath =
            config.tap_page_url +
            (account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? 'group/' + account.account_info.universityGroup.id
                : account.currentGroupUniversityId || account.account_info.university.id)
        const params = `?tap-dashboard=true&utm_medium=popcard${urlTags ? '&searchTerms=' + urlTags : ''}${
            source ? '&utm_source=' + encodeURIComponent(source) : ''
        }${campaign ? '&utm_campaign=' + encodeURIComponent(campaign) : ''}${
            title ? '&pageTitle=' + encodeURIComponent(title) + '' : ''
        }${description ? '&pageDescription=' + encodeURIComponent(description) + '' : ''}${
            leadType ? '&leadType=' + leadType + '' : ''
        }${leadType === 'group_chat' && leadGroupChatId ? '&leadGroupChatId=' + leadGroupChatId + '' : ''}${
            leadType === 'group_chat' && leadGroupChatId && leadGroupChatPrivateCode
                ? '&leadGroupChatPrivateCode=' + leadGroupChatPrivateCode + ''
                : ''
        }${leadType === 'single_chat' && leadAmbassadorId ? '&leadAmbassadorId=' + leadAmbassadorId + '' : ''}`

        iframePath += params
        const randomKey = Math.random()
        const backgroundFinalColor = backgroundColor.length > 0 ? backgroundColor : backgroundDefaultColor
        const titleFinalColor = titleColor.length > 0 ? titleColor : titleDefaultColor
        const buttonTextFinalColor = buttonTextColor.length > 0 ? buttonTextColor : buttonTextDefaultColor

        return (
            <Protect>
                {checkIfAnyTapUrlAvailable() ? (
                    <div id="page-body" className="page-body js-index">
                        <section className="content settings modal-restricted-area" id="modal-restricted-area">
                            <h3 className="settings-title">Pop card</h3>

                            <div className="settings-grid has-tutorial">
                                <div className="column">
                                    <div className="tutorial-intro">
                                        <p>
                                            You can place one or multiple pop cards on your website to showcase your
                                            ambassador profiles and direct traffic to your TAP Feed. Configure your pop
                                            card using the settings below, then paste the embed code into the pages on
                                            your website where you would like the pop card to appear. There is no limit
                                            to the number of pop cards that you set up, and you can configure each one
                                            differently.
                                        </p>
                                    </div>
                                    <hr className="settings-hr"></hr>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Select search filters</h4>
                                        <p>Please choose the search filters that your pop card should link to. </p>
                                        <p>
                                            Please add search filters that will pre-filter your TAP Feed. Remember that
                                            the page will be filtered by all of these search terms eg. if you search
                                            &lsquo;International&lsquo; and &lsquo;Business&lsquo; results will be shown
                                            for content types with both of those search terms.
                                        </p>
                                        <p>
                                            Press
                                            <code>enter</code>
                                            to add a term. Press <code>backspace</code>
                                            to edit the last term.
                                        </p>
                                        <div className="tagger">
                                            <div className="tags">
                                                <ul>
                                                    {tags.map((tag, i) => (
                                                        <li key={tag + i} className="tag">
                                                            {tag}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <input
                                                    type="text"
                                                    placeholder="Add a search filter.."
                                                    name="tagsInput"
                                                    value={tagsInput}
                                                    onChange={this.handleChange}
                                                    className="tag-input"
                                                    onKeyUp={this.handleKeyUp}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">
                                            Choose your pop card title text
                                            <span>(optional)</span>
                                        </h4>
                                        <p>
                                            Choose the text that you would like to be on your pop card (40 characters
                                            maximum). If you do not enter anything, the text will show &lsquo;Chat to
                                            our community&lsquo;
                                        </p>
                                        <input
                                            maxLength="40"
                                            className="promotion-input"
                                            placeholder="Add a pop card title..."
                                            name="popcardTitle"
                                            value={popcardTitle}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Pop card title text color </h4>
                                        <input
                                            name="titleColor"
                                            placeholder={titleFinalColor}
                                            value={titleFinalColor}
                                            type="text"
                                            onChange={this.handleChange}
                                            className="promotion-input"
                                            style={{ borderColor: titleColor || backgroundDefaultRgbaColor }}
                                        />
                                    </div>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Pop card button text color </h4>
                                        <input
                                            name="buttonTextColor"
                                            placeholder={buttonTextFinalColor}
                                            value={buttonTextFinalColor}
                                            type="text"
                                            onChange={this.handleChange}
                                            className="promotion-input"
                                            style={{ borderColor: buttonTextColor || backgroundDefaultRgbaColor }}
                                        />
                                    </div>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">
                                            Customise your pop card button text
                                            <span>(optional)</span>
                                        </h4>
                                        <p>
                                            You can customise the button text (20 characters maximum). If you do not
                                            enter anything, the button will show ‘Chat now‘
                                        </p>
                                        <input
                                            maxLength="20"
                                            className="promotion-input"
                                            placeholder="Add a pop card button text..."
                                            name="popcardButtonText"
                                            value={popcardButtonText}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">
                                            Add a UTM source tag
                                            <span>(optional)</span>
                                        </h4>
                                        <p>
                                            Add a source (for example, &lsquo;website&lsquo;) that you would like to see
                                            in tracking data. This is usually the place where you will be using the pop
                                            card
                                        </p>
                                        <input
                                            className="promotion-input"
                                            placeholder="Add a UTM source tag..."
                                            name="source"
                                            value={source}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">
                                            Add a UTM campaign tag
                                            <span>(optional)</span>
                                        </h4>
                                        <p>
                                            Add a campaign (for example, &lsquo;Open Day&lsquo;) that you would like to
                                            see in tracking data. This is so that you can track individual campaigns
                                        </p>
                                        <input
                                            className="promotion-input"
                                            placeholder="Add a UTM campaign tag..."
                                            name="campaign"
                                            value={campaign}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Write your page title for this promotion</h4>
                                        <p>
                                            This optional title will be shown on the TAP Feed that opens when a user
                                            clicks on this pop card
                                        </p>
                                        <input
                                            className="promotion-input"
                                            placeholder="Chat to our international students"
                                            name="title"
                                            value={title}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Write your page description</h4>
                                        <p>
                                            This optional description will be shown on the TAP Feed that opens when a
                                            user clicks on this pop card
                                        </p>
                                        <input
                                            className="promotion-input"
                                            name="description"
                                            value={description}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Choose view type</h4>
                                        <Select
                                            value={viewType}
                                            onChange={(value) => this.onDropVersionChange(value)}
                                            size="large"
                                            style={{ width: 'calc(80% + 2rem + 2px)' }}
                                        >
                                            <Select.Option value="standard">Standard view</Select.Option>
                                            <Select.Option value="mini">Small view</Select.Option>
                                            <Select.Option value="common">
                                                Standard view for web, small view for mobile
                                            </Select.Option>
                                        </Select>
                                    </div>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Choose an alignment for your pop-card</h4>
                                        <Select
                                            value={alignment}
                                            onChange={(value) => this.onDropChange(value)}
                                            size="large"
                                            style={{ width: 'calc(80% + 2rem + 2px)' }}
                                        >
                                            <Select.Option value="left">
                                                Align to the left side of the screen
                                            </Select.Option>
                                            <Select.Option value="right">
                                                Align to the right side of the screen
                                            </Select.Option>
                                        </Select>
                                    </div>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Pop card color</h4>
                                        <input
                                            name="backgroundColor"
                                            placeholder={backgroundFinalColor}
                                            value={backgroundFinalColor}
                                            type="text"
                                            onChange={this.handleChange}
                                            className="promotion-input"
                                            style={{ borderColor: backgroundColor || backgroundDefaultRgbaColor }}
                                        />
                                    </div>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Where shall a pop-card lead</h4>
                                        <LeadSelect onChangeLeadType={this.onLeadTypeChange} />
                                        {leadType === 'tracked_link' && <TrackedLinkInput />}
                                        {leadType === 'group_chat' && (
                                            <GroupChatSelect onGroupChatSelect={this.onGroupChatSelect} />
                                        )}
                                        {leadType === 'single_chat' && (
                                            <AmbassadorSelect
                                                onChangeAmbassadorSelect={this.onChangeAmbassadorSelect}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="column resources">
                                    <h3 className="video-title">Video tutorial: Using popcards</h3>
                                    <div className="video-wrapper">
                                        <iframe
                                            src="https://player.vimeo.com/video/757131738?h=f054afe011&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
                                            width="640"
                                            height="373"
                                            frameBorder="0"
                                            allow="autoplay; fullscreen"
                                            allowFullScreen
                                        ></iframe>
                                    </div>

                                    <div className="resources-box">
                                        <h3>Helpful resources</h3>
                                        <ul>
                                            <li>
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="https://knowledge.theambassadorplatform.com/how-to-use-the-promotion-feature"
                                                    target="_blank"
                                                >
                                                    How to use the Promotion feature
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="column spacer"></div>
                            </div>

                            <div className="settings-grid tap-page-widget">
                                <div className="column">
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Summary</h4>
                                        <p>These are the settings for your pop card</p>
                                        <table className="promotion-table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Search filters
                                                        {friendlyTags ? <small>{tags.length} selected</small> : null}
                                                    </td>
                                                    <td>{friendlyTags || <span>None selected</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>Pop card title</td>
                                                    <td>
                                                        {popcardTitle ? (
                                                            <code>{popcardTitle}</code>
                                                        ) : (
                                                            <span>Chat to our community</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Pop card button text</td>
                                                    <td>
                                                        {popcardButtonText ? (
                                                            <code>{popcardButtonText}</code>
                                                        ) : (
                                                            <span>Chat now</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>UTM source tag</td>
                                                    <td>
                                                        {source ? <code>{source}</code> : <span>None selected</span>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>UTM campaign tag</td>
                                                    <td>
                                                        {campaign ? (
                                                            <code>{campaign}</code>
                                                        ) : (
                                                            <span>None selected</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        UTM medium tag
                                                        <small>Not editable</small>
                                                    </td>
                                                    <td>
                                                        <code>popcard</code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        TAP Feed link
                                                        <small>Detected automatically</small>
                                                    </td>
                                                    <td>
                                                        <code>{getAnyTapUrl()}</code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Page title</td>
                                                    <td>{title ? <code>{title}</code> : <span>None selected</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>Page description</td>
                                                    <td>
                                                        {description ? (
                                                            <code>{description}</code>
                                                        ) : (
                                                            <span>None selected</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Pop card lead</td>
                                                    <td>
                                                        {leadName ? (
                                                            <code>{leadName}</code>
                                                        ) : (
                                                            <span>None selected</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                {leadType === 'group_chat' && (
                                                    <tr>
                                                        <td>Lead to group chat</td>
                                                        <td>
                                                            {leadGroupChatName ? (
                                                                <code>{leadGroupChatName}</code>
                                                            ) : (
                                                                <span>None selected</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                                {leadType === 'single_chat' && (
                                                    <tr>
                                                        <td>Lead to chat with</td>
                                                        <td>
                                                            {leadAmbassadorName ? (
                                                                <code>{leadAmbassadorName}</code>
                                                            ) : (
                                                                <span>None selected</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Embed</h4>
                                        <p>
                                            This is your pop card embed code. Copy and paste this between the{' '}
                                            <code>{'<body> </body>'}</code> tags on pages where you would like the pop
                                            card to appear
                                        </p>
                                        <textarea
                                            cols="40"
                                            rows="5"
                                            readOnly="readOnly"
                                            // resize="none"
                                            className="settings-url"
                                            value={`<script>(function(w,d,s,o,f,js,fjs){w['popCard']=o;w[o]=w[o]||function(){(w[o].q=w[o].q||[]).push(arguments)};js=d.createElement(s),fjs=d.getElementsByTagName(s)[0];js.id=o;js.src=f;js.async=1;fjs.parentNode.insertBefore(js,fjs);}(window,document,'script','mw', '${
                                                config.popcard_script
                                            }'));mw('init', {${tapType}: ${tapId},terms:[${joinedTags}],title:"${
                                                popcardTitle || ''
                                            }", popcardButtonText:"${popcardButtonText || ''}",
alignment: "${alignment}",viewType: "${viewType}", backgroundColor: "${backgroundFinalColor}", titleColor: "${titleFinalColor}", buttonTextColor: "${buttonTextFinalColor}", href:"${
                                                getAnyTapUrl() + params
                                            }"});</script>`}
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Pop card example</h4>
                                        <p>This is an example of how your pop card will look on your website</p>

                                        {(viewType === 'standard' || viewType === 'common') && (
                                            <div className="tap-pop-card">
                                                <div
                                                    className="pop-card-wrapper"
                                                    style={{ backgroundColor: backgroundFinalColor }}
                                                >
                                                    <div className="pop-card">
                                                        <div className="minimize-pop-card"></div>
                                                        <span
                                                            className="pop-card-title"
                                                            style={{ color: titleFinalColor }}
                                                        >
                                                            {popcardTitle || 'Chat to our community'}
                                                        </span>
                                                        <div className="avatar-wrapper">
                                                            {ambassadorsAvatars.length > 0 &&
                                                                ambassadorsAvatars.map((avatar, index) => (
                                                                    <img key={index} src={avatar} alt="avatar" />
                                                                ))}
                                                        </div>
                                                        <div
                                                            className="button chat-now-button"
                                                            style={
                                                                popcardSettings && popcardSettings.primary_color
                                                                    ? {
                                                                          color: buttonTextFinalColor,
                                                                          background: popcardSettings.primary_color,
                                                                      }
                                                                    : null
                                                            }
                                                        >
                                                            {popcardButtonText && popcardButtonText.trim().length > 0
                                                                ? popcardButtonText
                                                                : 'Chat now'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(viewType === 'mini' || viewType === 'common') && (
                                            <div className="tap-pop-card mini-pop-card">
                                                <div
                                                    className="button chat-now-button chat-now-mini-button"
                                                    style={{
                                                        backgroundColor: backgroundFinalColor,
                                                        color: buttonTextFinalColor,
                                                    }}
                                                >
                                                    {ambassadorsAvatars.length > 0 && (
                                                        <img
                                                            src={ambassadorsAvatars[0]}
                                                            alt="avatar"
                                                            className="pop-card-avatar"
                                                        />
                                                    )}
                                                    <span>
                                                        {popcardButtonText && popcardButtonText.trim().length > 0
                                                            ? popcardButtonText
                                                            : 'Chat now'}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Widget example</h4>
                                        <p>
                                            This is an example of how your TAP Feed, that is linked to a filtered pop
                                            card, will look like
                                        </p>
                                        <iframe
                                            key={randomKey}
                                            name="tap-dashboard-iframe"
                                            id="tap-iframe-widget"
                                            src={iframePath}
                                            width="100%"
                                            height="700"
                                            style={{ border: 'none' }}
                                            scrolling="yes"
                                            data-type="widget"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                ) : (
                    <div id="page-body" className="page-body js-index">
                        <section className="content settings modal-restricted-area" id="modal-restricted-area">
                            <h3 className="settings-title">Unavailable</h3>
                            <div className="settings-intro">
                                <p>
                                    You must set up your{' '}
                                    <ProtectedNavLink
                                        exact
                                        to={getCommonRoutePath() + '/settings/tap-page'}
                                        licenseName={LicensesEnum.TapFeed}
                                    >
                                        TAP Feed
                                    </ProtectedNavLink>{' '}
                                    in order to use Promotion
                                </p>
                            </div>
                        </section>
                    </div>
                )}
                {!isAllowed('promotion') && <RestrictedModal closable={false} />}
            </Protect>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        account: state.account,
        ambassadorsAvatars:
            state.users && state.users.popcardUsers
                ? [...state.users.popcardUsers].sort(() => Math.random() - 0.5).slice(0, 4)
                : [],
        popcardSettings: state.users.popcardSettings,
    }
}

export default connect(mapStateToProps)(PopCard)
