import React from 'react'
import Notifications from 'react-notification-system-redux'
import { connect } from 'react-redux'

class NotificationsStack extends React.Component {
    render() {
        const notifications = this.props.notifications

        const style = {
            NotificationItem: {
                DefaultStyle: {
                    margin: '10px 5px 2px 1px',
                },
            },
        }

        return (
            <>
                <Notifications notifications={notifications} style={style} />
                {this.props.children}
            </>
        )
    }
}

export default connect((state) => ({
    notifications: state.notifications,
}))(NotificationsStack)
