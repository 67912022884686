/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Row, Col, Input, Checkbox, Progress, Typography } from 'antd'
// import { endOfDay, isBefore } from 'date-fns'
import React, { useCallback } from 'react'

import DatePicker from '../../../../../components/date-picker/date-picker'
import GlobalTagsSelect from '../../../../../components/global-tags-select/global-tags-select'
import { roles } from '../../../../../helpers/helpers'
import { CoverImage, UsersSelect } from '../../index'

const { TextArea } = Input
const { Text } = Typography

const EventForm = ({
    form,
    initialValues,
    step,
    imageState,
    setImageState,
    editMode,
    isGroupAdmin = false,
    videoProgress = 0,
    videoProgressStart = false,
    videoFileName = '',
    isCompleted = false,
}) => {
    const handleBlur = useCallback(
        (val) => {
            const trimDescription = form.getFieldValue('description').trim()

            form.setFieldsValue({
                description: trimDescription,
            })
        },
        [form]
    )

    return (
        <Form form={form} layout="vertical" autoComplete="off" initialValues={initialValues} hideRequiredMark>
            <div
                style={{
                    display: step === 0 ? 'block' : 'none',
                }}
            >
                <Row gutter={8} style={{ flexFlow: 'row' }}>
                    <Col flex={1}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                { whitespace: true, message: 'Add stream name' },
                                { required: true, message: 'Add stream name' },
                                { min: 3, message: 'Stream name should contain at least 3 symbols' },
                                { max: 100, message: 'Stream name should contain less than 100 symbols' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <div>
                            <span style={{ color: 'red' }}>*</span> The cover image on the Feed will be displayed in 4:1
                            aspect ratio. Please make sure the image you upload fits in this format.
                        </div>
                    </Col>
                    <Col>
                        <CoverImage form={form} imageState={imageState} setImageState={setImageState} />
                    </Col>
                </Row>

                <Form.Item
                    name="description"
                    label="Stream description"
                    rules={[
                        { whitespace: true },
                        { required: true, message: 'Add description' },
                        { min: 50, message: 'Stream description should contain at least 50 symbols' },
                        { max: 1000, message: 'Stream description should not contain more than 1000 symbols' },
                    ]}
                    onBlur={handleBlur}
                >
                    <TextArea
                        rows={5}
                        placeholder="Let your audience know what this stream will cover and encourage them to sign up."
                        maxLength={1000}
                        showCount={{
                            formatter: ({ count, maxLength }) => `${count}/${maxLength}`,
                        }}
                    />
                </Form.Item>
                {!isCompleted && (
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item name="date" label="Date">
                                <DatePicker
                                    placeholder="Choose date"
                                    // disabledDate={disabledDate}
                                    allowClear={false}
                                    style={{ width: '100%' }}
                                    showToday={false}
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <DurationInput form={form} />
                        </Col> */}
                    </Row>
                )}

                <GlobalTagsSelect form={form} />

                <Form.Item name="isPromote" valuePropName="checked">
                    <Checkbox>Don’t show on TAP Feed. I will send the link to the stream privately.</Checkbox>
                </Form.Item>

                {isGroupAdmin && (
                    <Form.Item name="isGroupPromote" valuePropName="checked">
                        <Checkbox>Don’t show on the Group Account TAP Feed </Checkbox>
                    </Form.Item>
                )}
            </div>

            {!editMode && (
                <>
                    <div
                        style={{
                            display: step === 1 ? 'block' : 'none',
                        }}
                    >
                        <UsersSelect
                            id="userIds"
                            label="Add chat participants"
                            roles={[roles.student]}
                            form={form}
                            showRoles
                        />
                    </div>

                    <div
                        style={{
                            display: step === 2 ? 'block' : 'none',
                        }}
                    >
                        <p>
                            Select the video you want to share and upload it on The Ambassador Platform. Depending on
                            the size of the file, it can take a few minutes for it to upload on the platform.
                        </p>
                        <p>Only horizontally oriented video format is supported.</p>
                        <p
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '2.2rem',
                            }}
                        >
                            {videoFileName}
                        </p>
                        <Text
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '1.8rem',
                                marginTop: '2rem',
                            }}
                            strong
                        >
                            Don’t leave the page until the upload is completed.
                        </Text>
                        {videoProgressStart && (
                            <Progress
                                strokeLinecap="butt"
                                percent={videoProgress}
                                strokeColor={{
                                    '0%': '#ff5100',
                                    '100%': '#ff5100',
                                }}
                            />
                        )}
                    </div>
                </>
            )}
        </Form>
    )
}

export default EventForm
