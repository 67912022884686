import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import DetachedAmbassadorMessage from '../../../../../components/messages/DetachedAmbassadorMessage'
import InviteAdminMessage from '../../../../../components/messages/InviteAdminMessage'
import JoinedAmbassadorMessage from '../../../../../components/messages/JoinedAmbassadorMessage'
import { DELETE_CHAT_MESSAGE, UNWORD_DIALOG } from '../../../../../redux/actions/chat/messagingActions'
import {
    CLOSE_ACTION_MODAL,
    OPEN_ACTION_MODAL,
    REPLY_MESSAGE,
} from '../../../../../redux/actions/components/componentsActions'
import store from '../../../../../redux/store'
import Loader from '../../../../content/components/Loader'
import JoinedAdminMessage from '../messages/JoinedAdminMessage'
import RemovedAccountSystemMessage from '../messages/RemovedAccountSystemMessage'
import TextMessage from '../messages/TextMessage'

import { ChatType } from '../../../../../common/constants'

class MessagesBody extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}

        this.unwordDialog = this.unwordDialog.bind(this)
        this.reply = this.reply.bind(this)
        this.closeConfirmationPopup = this.closeConfirmationPopup.bind(this)
        this.renderDeleteMessagePopup = this.renderDeleteMessagePopup.bind(this)
        this.deleteChatMessage = this.deleteChatMessage.bind(this)
    }

    componentDidUpdate(prevProps) {
        const element = document.querySelector('#chat-body')
        if (element && this.props.dialogMessages.scroll && element.scrollTop !== element.scrollHeight) {
            element.scrollTop = element.scrollHeight
        }
    }

    unwordDialog(messageId) {
        store.dispatch({
            type: UNWORD_DIALOG,
            payload: {
                messageId,
                dialogId: this.props.chat.id,
                type: this.props.chatsType,
            },
        })
    }

    reply(message) {
        store.dispatch({
            type: REPLY_MESSAGE,
            payload: {
                activeMessage: message,
            },
        })
        try {
            document.querySelector('#chat-message-textarea').focus()
        } catch {}
    }

    renderDeleteMessagePopup(message) {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Delete this message?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button
                                type="submit"
                                className="el-btn confirm-delete-button"
                                onClick={() => this.deleteChatMessage(message)}
                            >
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={() => this.closeConfirmationPopup()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    deleteChatMessage(chatMessage) {
        store.dispatch({
            type: DELETE_CHAT_MESSAGE,
            payload: {
                dialogId: chatMessage.dialog_id,
                messageId: chatMessage.id,
                type_chat: ChatType.CHAT,
            },
        })
    }

    closeConfirmationPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    render() {
        const { chat, dialogMessages, search } = this.props

        if (dialogMessages.isLoading) {
            return (
                <div className="chat-block chat-loader-block">
                    <Loader />
                </div>
            )
        }

        let searchBy = null
        if ((chat.search_message || chat.search_member) && search) {
            searchBy = chat.search_message ? 'message' : 'user'
        }

        const messagesView = dialogMessages.items.map((message, index) => {
            if (
                (message.type === 'text' || message.type === 'autotext') &&
                message.content &&
                typeof message.content.text !== 'object'
            ) {
                return (
                    <TextMessage
                        key={message.id + '' + message.hide_keywords}
                        index={index}
                        message={message}
                        unwordDialog={this.unwordDialog}
                        reply={this.reply}
                        renderDeleteMessagePopup={this.renderDeleteMessagePopup}
                        search={search}
                        searchBy={searchBy}
                        isChatReported={chat}
                    />
                )
            } else
                switch (message.type) {
                    case 'joined':
                        return <JoinedAmbassadorMessage key={message.id} message={message} />
                    case 'invite':
                        return <InviteAdminMessage key={message.id} message={message} />
                    case 'joined-admin':
                        return <JoinedAdminMessage key={message.id} message={message} />
                    case 'detached':
                        return <DetachedAmbassadorMessage key={message.id} message={message} />
                    case 'removed':
                        return <RemovedAccountSystemMessage message={message} />
                    // No default
                }
        })
        return <>{messagesView}</>
    }
}

export default connect((state, props) => {
    return {
        dialogMessages: state.chatMessages,
    }
})(MessagesBody)
