import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { reduxForm, Field } from 'redux-form'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'

const validate = (values) => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Please enter your email address'
    }
    // comented cause of prod dump on dev database, pls contact julia if needed
    // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.email)) {
    //   errors.email = 'Invalid email address'
    // }
    if (!values.password) {
        errors.password = 'Please enter your password'
    }

    return errors
}

const renderField = ({ input, label, placeholder, type, className, meta: { touched, error, warning } }) => (
    <div>
        <input {...input} className={className} placeholder={placeholder} type={type} />
        {touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

class LogInForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            showPassword: false,
        }

        this.showPassword = this.showPassword.bind(this)
    }

    showPassword(e) {
        e.preventDefault()
        this.setState({
            showPassword: !this.state.showPassword,
        })
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <form action="#" method="POST" className="signin-form" onSubmit={handleSubmit}>
                <label className="signin-label">
                    <Field
                        name="email"
                        component={renderField}
                        type="text"
                        placeholder="Email address"
                        className="signin-input"
                    />
                    <svg className="icon icon-mail">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#mail'}></use>
                    </svg>
                </label>
                <label className="signin-label">
                    <Field
                        name="password"
                        component={renderField}
                        type="password"
                        placeholder="Password"
                        className="signin-input mod-pass"
                    />
                    <svg className="icon icon-lock">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#lock'}></use>
                    </svg>
                </label>
                <Link to="/account/forgot" className="forgot recover-link">
                    Recover password
                </Link>
                <button type="submit" className="signin-btn">
                    Sign in
                </button>
            </form>
        )
    }
}

export default reduxForm({
    form: 'log_in_form',
    validate,
})(LogInForm)
