/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, Route, Switch } from 'react-router-dom'

import { getLiveStramsOffset, getCurrentGroupUniversityId, getLiveStramsFilters } from '../../../../redux/selectors'

import { fetchLiveStreams } from '../../../../redux/actions/live-streams/live-streams-actions'

import { StreamsGuideScreen, StreamsListScreen, StreamsItemScreen } from '../index'
import { getCommonRoutePath } from '../../../../helpers/helpers'

const StreamsTypeRootScreen = () => {
    const dispatch = useDispatch()
    const { param } = useParams()
    const history = useHistory()

    const offset = useSelector(getLiveStramsOffset)
    const filter = useSelector(getLiveStramsFilters)
    const currentGroupUniversityId = useSelector(getCurrentGroupUniversityId)

    useEffect(() => {
        if (param === 'start') {
            history.push(getCommonRoutePath() + '/live-streams/start')
        } else {
            dispatch(fetchLiveStreams({ offset, filter }))
        }
    }, [param, dispatch, history, currentGroupUniversityId])

    return (
        <Switch>
            <Route
                path={['/live-streams/start', '/:university_id/live-streams/start']}
                component={StreamsGuideScreen}
            />
            <Route
                exact
                path={['/live-streams/streams', '/:university_id/live-streams/streams']}
                component={StreamsListScreen}
            />
            <Route
                exact
                path={['/live-streams/streams/:eventId', '/:university_id/live-streams/streams/:eventId']}
                component={StreamsItemScreen}
            />
        </Switch>
    )
}

export default StreamsTypeRootScreen
