import React, { useState } from 'react'
import { Popover, Switch } from 'antd'

import { MARK_UNIVERSITY_AS_TEST_REQUEST } from '../../../../../redux/actions/account/accountActions'

import store from '../../../../../redux/store'

const MarkTestUniversity = ({ isTest }) => {
    return (
        <div style={{ alignSelf: 'flex-start', position: 'absolute', top: 0, right: 0 }}>
            <Popover content={<TestUniPopup isTest={isTest} />} trigger="click" placement="bottomRight">
                <button type="button" style={{ width: 30, height: 30 }}></button>
            </Popover>
        </div>
    )
}

const TestUniPopup = ({ isTest }) => {
    const [toggleValue, setoggleValue] = useState(isTest)

    const checkIfUniIsTest = (checked) => {
        store.dispatch({
            type: MARK_UNIVERSITY_AS_TEST_REQUEST,
            payload: {
                isTest: checked,
            },
        })
        setoggleValue(checked)
    }

    return (
        <div style={{ display: 'flex', width: 170, padding: 10 }}>
            <p style={{ marginRight: 8 }}>Test University ?</p>
            <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={checkIfUniIsTest} checked={toggleValue} />
        </div>
    )
}

export default MarkTestUniversity
