import React, { useState } from 'react'
import cn from 'classnames'
import bold from '../../assets/svg/icons/bold.svg'
import italic from '../../assets/svg/icons/italic.svg'
import underline from '../../assets/svg/icons/underline.svg'
import list from '../../assets/svg/icons/list.svg'
import link from '../../assets/svg/icons/link.svg'

const options = {
    bold,
    italic,
    underline,
    list,
    link,
}

const Toolbar = ({ utils }) => {
    const [link, setLink] = useState('')
    const [isLinkInput, setIsLinkInput] = useState(false)

    const handleLinkConfirm = () => {
        const handler = utils.filter((util) => util.name === 'link')[0].handler
        setIsLinkInput(false)
        handler(link)
    }

    return (
        <div className="textarea-toolbar">
            <div className="textarea-toolbar__buttons">
                {utils.map(({ name, handler }) => (
                    <img
                        key={name}
                        onClick={name === 'link' ? () => setIsLinkInput(!isLinkInput) : handler}
                        className={cn('textarea-toolbar__buttons__button', {
                            'textarea-toolbar__buttons__button--link': name === 'link',
                        })}
                        src={options[name]}
                        alt={name}
                    />
                ))}
            </div>
            <div
                className={cn('textarea-toolbar__input-block', { 'textarea-toolbar__input-block--hide': !isLinkInput })}
            >
                <input
                    className="textarea-toolbar__input-block__input"
                    value={link}
                    onChange={({ target }) => setLink(target.value)}
                    type="text"
                    placeholder="hyperlink to source"
                />
                <button className="textarea-toolbar__input-block__button" onClick={() => handleLinkConfirm(link)}>
                    OK
                </button>
            </div>
        </div>
    )
}

export default Toolbar
