import Modal from 'antd/es/modal'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { CLOSE_SETTINGS_MESSAGING_POPUP, HIDE_NEW_MESSAGES } from '../../../redux/actions/components/componentsActions'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import {
    CLEAR_CONTENT_GROUP,
    CLOSE_CONTENT_GROUP_POPUP,
    CONTENT_GROUPS_INFO,
    DIALOG_MESSAGES_INFO,
    SET_ACTIVE_CONTENT_GROUP,
} from '../../../redux/actions/content/messagingActions'
import store from '../../../redux/store'

import ContentGroupsSidebar from './components/ContentGroupsSidebar'
import Messages from './components/Messages'
import { CreateContentGroupConnected } from './components/popups/CreateContentGroup'
import SettingsContentGroup from './components/popups/SettingsContentGroup'

import { ChatType } from '../../../common/constants'

import { IdpContentGroupModal } from 'src/app/containers/content/dashboard/components/modals/IdpContentGroup'

class Messaging extends PureComponent {
    constructor(props) {
        super(props)

        this.handleContentGroupClick = this.handleContentGroupClick.bind(this)
        this.handleCloseContentGroupSettingsPopup = this.handleCloseContentGroupSettingsPopup.bind(this)
    }

    componentDidMount() {
        const { isIdpGroupAdmin } = this.props.account.account_info
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'content',
            },
        })

        store.dispatch({
            type: CONTENT_GROUPS_INFO,
            payload: {
                isIdpGroupAdmin,
            },
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: CONTENT_GROUPS_INFO,
                payload: {
                    isFirstRequest: true,
                    removeActiveContentGroup: true,
                    isIdpGroupAdmin: this.props.account.account_info.isIdpGroupAdmin,
                },
            })
        }
    }

    componentWillUnmount() {
        store.dispatch({
            type: CLEAR_CONTENT_GROUP,
        })
        store.dispatch({
            type: SET_ACTIVE_CONTENT_GROUP,
            payload: {
                content_group_id: -1,
            },
        })
    }

    handleContentGroupClick(event) {
        const contentGroupId = event.target.getAttribute('data-content-group-id')

        store.dispatch({
            type: SET_ACTIVE_CONTENT_GROUP,
            payload: {
                content_group_id: contentGroupId,
            },
        })

        setTimeout(() => {
            store.dispatch({
                type: DIALOG_MESSAGES_INFO,
                payload: {
                    dialog_id: contentGroupId,
                    scrollBottomWithNoDelay: true,
                    type_chat: ChatType.CONTENT_GROUP,
                    withGlobalTags: true,
                    blockReloadContentGroup: true,
                    resetMessagesCounter: true,
                },
            })

            store.dispatch({
                type: HIDE_NEW_MESSAGES,
            })
        }, 0)
    }

    handleCloseContentGroupPopup() {
        store.dispatch({
            type: CLOSE_CONTENT_GROUP_POPUP,
        })
    }

    handleCloseContentGroupSettingsPopup() {
        store.dispatch({
            type: CLOSE_SETTINGS_MESSAGING_POPUP,
        })
    }

    render() {
        const { account } = this.props
        const { isIdpGroupAdmin } = account.account_info
        return (
            <>
                <div className="content mod-chat">
                    <div className="chat">
                        <Messages
                            activeContentGroup={this.props.contentGroups.activeContentGroup}
                            handleOpenSettingsPopup={this.handleOpenSettingsPopup}
                        />
                        <ContentGroupsSidebar
                            handleContentGroupClick={this.handleContentGroupClick}
                            contentGroupIndex={this.props.contentGroups.activeContentGroup}
                        />
                    </div>
                </div>

                {isIdpGroupAdmin ? (
                    <IdpContentGroupModal />
                ) : (
                    <>
                        <Modal
                            open={this.props.createContentGroupModal.isOpened}
                            centered={true}
                            footer={null}
                            closable={false}
                            maskClosable={true}
                            onCancel={this.handleCloseContentGroupPopup}
                            destroyOnClose
                            className="react-create-content-group"
                        >
                            <CreateContentGroupConnected onClosePopup={this.handleCloseContentGroupPopup} />
                        </Modal>

                        <Modal
                            open={this.props.settingsMessagingModal.isOpened}
                            centered={true}
                            footer={null}
                            closable={false}
                            maskClosable={true}
                            onCancel={this.handleCloseContentGroupSettingsPopup}
                            destroyOnClose
                            className="react-create-content-group"
                        >
                            <SettingsContentGroup
                                activeContentGroup={this.props.contentGroups.activeContentGroup}
                                onClosePopup={this.handleCloseContentGroupSettingsPopup}
                            />
                        </Modal>
                    </>
                )}
            </>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        createContentGroupModal: state.createContentGroupModal,
        contentGroups: state.contentGroups,
        settingsMessagingModal: state.settingsMessagingModal,
    }
})(Messaging)
