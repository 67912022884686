import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import RegistrationForm from './components/RegistrationForm'
import Modal from 'antd/es/modal'
import RegistrationSuccessModal from '../registration/components/RegistrationSuccessModal'
import Logo from '../../../../assets/svg/logo.svg'
import {
    GROUP_ACCOUNT_REGISTRATION,
    CLEAR_REGISTER_STEP,
    CLOSE_SUCCESS_REGISTRATION_MODAL,
    CHECK_GROUP_ADMIN_INVITATION,
} from '../../../redux/actions/account/accountActions'
import store from '../../../redux/store'

class RegistrationGroup extends PureComponent {
    constructor(props) {
        super(props)

        const query = new URLSearchParams(props.location.search)
        this.inviteToken = query.get('inviteToken')
        this.inviteCode = query.get('inviteCode')

        this.invitationData = {
            inviteToken: this.inviteToken,
            inviteCode: this.inviteCode,
            isGroup: true,
        }
        this.handleRegistrationSubmit = this.handleRegistrationSubmit.bind(this)
    }

    componentDidMount() {
        if (this.inviteCode) {
            store.dispatch({
                type: CHECK_GROUP_ADMIN_INVITATION,
                payload: {
                    inviteToken: this.inviteToken,
                    inviteCode: this.inviteCode,
                },
            })
        }
    }

    handleRegistrationSubmit(values) {
        const registrationData = {
            name: values.first_name,
            email: values.email,
            lastName: values.last_name,
            password: values.password,
        }

        if (this.inviteCode) {
            registrationData.inviteToken = this.inviteToken
            registrationData.inviteCode = this.inviteCode
        }

        store.dispatch({
            type: GROUP_ACCOUNT_REGISTRATION,
            payload: registrationData,
        })
    }

    handleCloseRegistrationSuccessPopup() {
        store.dispatch({
            type: CLOSE_SUCCESS_REGISTRATION_MODAL,
        })
    }

    goBack = (e) => {
        e.preventDefault()
        store.dispatch({
            type: CLEAR_REGISTER_STEP,
        })
    }

    render() {
        const { account, successRegistrationModal } = this.props

        let renderForm = ''

        if (account.registrationStep == 1) {
            renderForm = (
                <RegistrationForm
                    account={account}
                    onSubmit={this.handleRegistrationSubmit}
                    invitationData={this.invitationData}
                />
            )
        } else if (account.registrationStep == 3) {
            store.dispatch({
                type: CLEAR_REGISTER_STEP,
            })

            return <Redirect push to="/account/login" />
        }

        return (
            <div id="page-wrapper" className="page-wrapper unregistered-page">
                <div id="page-body" className="page-body js-index">
                    <div className="signin-container">
                        {account.isValidInvitation ? (
                            <>
                                <div className="registration-preamble">
                                    <img src={Logo} alt="Logo" />
                                </div>
                                <div className="signin-block">{renderForm}</div>
                            </>
                        ) : (
                            <>
                                <div className="registration-preamble">
                                    <img src={Logo} alt="Logo" />
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <Modal
                    open={successRegistrationModal.isOpened}
                    centered={true}
                    bodyStyle={{ height: 'auto' }}
                    footer={null}
                    closable={true}
                    maskClosable={false}
                    onCancel={this.handleCloseRegistrationSuccessPopup}
                >
                    <RegistrationSuccessModal handleClosePopup={this.handleCloseRegistrationSuccessPopup} />
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        successRegistrationModal: state.successRegistrationModal,
    }
})(withRouter(RegistrationGroup))
