import React from 'react'

import { ProspectsCsvButton } from './components'

const StreamParticipants = ({ event }) => {
    const { totalProspects } = event

    return (
        <div className="event-card-participants-container">
            <p className="event-card-participants-count">
                {totalProspects ? `${totalProspects} participant${totalProspects > 1 ? 's' : ''}` : '0 participants'}
            </p>
            <div className="event-card-participants-statistic">
                <ProspectsCsvButton event={event} />
            </div>
        </div>
    )
}

export default StreamParticipants
