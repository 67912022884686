import { Space, Radio, Typography, Form } from 'antd'
import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import MarkenitgConsentForm from '../../../forms/MarkenitgConsentForm'
import {
    setMarketingConsent,
    setMarketingConsentText,
} from '../../../../../../../redux/actions/account/account-actions'
import { convertToRaw } from 'draft-js'

const { Text } = Typography

const prepareRadioButtonsDefaultValue = ({ university }) => {
    const { isMarketingConsentDefault, isMarketingConsentShown, marketingConsentText } = university

    if (isMarketingConsentDefault) {
        return 1
    } else if (isMarketingConsentShown && marketingConsentText) {
        return 2
    } else if (!isMarketingConsentShown) {
        return 3
    }
}

const MarkenitgConsentTypes = ({ university }) => {
    const [buttonValue, setButtonValue] = useState(prepareRadioButtonsDefaultValue({ university }))
    const { marketingConsentText } = university
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const onChangeButton = useCallback(
        (e) => {
            setButtonValue(e.target.value)
            if (e.target.value === 1 || e.target.value === 3) {
                dispatch(
                    setMarketingConsent({
                        isShown: e.target.value !== 3,
                        isDefault: e.target.value === 1,
                    })
                )
            }
        },
        [dispatch]
    )

    const saveMarketingConsentQuestion = useCallback(
        (event = {}) => {
            const message = JSON.stringify(convertToRaw(event.getCurrentContent()))
            const hasText = convertToRaw(event.getCurrentContent()).blocks.some((key) => key.text)

            if (hasText) {
                const onSuccess = () => {
                    dispatch(
                        setMarketingConsent({
                            isShown: true,
                            isDefault: false,
                        })
                    )
                }
                dispatch(
                    setMarketingConsentText({
                        text: message,
                        onSuccess: onSuccess,
                    })
                )
            }
        },
        [dispatch]
    )

    return (
        <Radio.Group onChange={onChangeButton} value={buttonValue}>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Radio value={1} className="sign-up-form-radio-button">
                    <Text style={{ paddingLeft: '5px' }}>Use standard marketing consent text</Text>
                </Radio>
                <Radio value={2} className="sign-up-form-radio-button">
                    <Text style={{ paddingLeft: '5px' }}>Use custom marketing consent text</Text>
                </Radio>
                {buttonValue === 2 && (
                    <MarkenitgConsentForm
                        saveMarketingConsentQuestion={saveMarketingConsentQuestion}
                        marketingConsentText={marketingConsentText || ''}
                        form={form}
                    />
                )}
                <Radio value={3} className="sign-up-form-radio-button">
                    <Text>Don't show marketing consent checkbox on the sign-up form</Text>
                </Radio>
            </Space>
        </Radio.Group>
    )
}

export default MarkenitgConsentTypes
