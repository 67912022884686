import React, { useEffect } from 'react';
import { IdpActivationSteps } from './components/Steps';
import { StepsProvider } from './StepsProvider';
import { useAppDispatch } from 'src/app/hooks/store';
import { TWO_TIER_MENU_CHANGE_SUBMENU } from 'src/app/redux/actions/content/componentsActions';
import './styles.scss';
export var IdpActivation = function () {
    var dispatch = useAppDispatch();
    useEffect(function () {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'idp-activation',
            },
        });
    }, [dispatch]);
    return (React.createElement(StepsProvider, null,
        React.createElement("div", { className: "no-submenu idp-activation-container" },
            React.createElement(IdpActivationSteps, null))));
};
