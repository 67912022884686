import { Popover } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as OptionsIcon } from '../../../../../../assets/svg/icons/options.svg'
import { ReactComponent as VolumeMuted } from '../../../../../../assets/svg/volume-mute.svg'
import BlockProspectPopover from '../../../../../components/BlockProspectPopover'
import ReportingPopover from '../ReportingPopover'

import {
    ARCHIVE_DIALOG_TOGGLE,
    CLOSE_DIALOG,
    GET_CHAT_TRANSCRIPT_DATA,
    MUTE_CHAT_TRIGGER,
} from '../../../../../redux/actions/chat/messagingActions'
import {
    CLOSE_ACTION_MODAL,
    CLOSE_SETTINGS_MESSAGING_POPUP,
    OPEN_ACTION_MODAL,
} from '../../../../../redux/actions/components/componentsActions'

import { ChatType } from '../../../../../common/constants'

const Header = ({ dialogId, handleOpenSettingsPopup }) => {
    const dispatch = useDispatch()

    const chats = useSelector((state) => state.chats)
    const users = useSelector((state) => state.users)

    const [visible, setVisible] = useState(false)
    const [chat, setChat] = useState({})
    let user = {
        id: null,
        avatar: null,
        name: null,
        last_name: null,
        sizes: null,
        original: null,
    }

    let ambassadorsIDs = []

    if (chat.prospect) {
        user = chat.prospect
    } else if (chat.ambassador) {
        user = chat.ambassador
    }

    if (chat.members) {
        ambassadorsIDs =
            chat.members
                .filter(({ role_key: roleKey }) => roleKey && roleKey.includes('student'))
                .map(({ id }) => id) || []
    }
    const ambassadors = users
        ? users.items.filter(({ id }) => ambassadorsIDs.some((ambassadorID) => +ambassadorID === +id))
        : []

    useEffect(() => {
        setChat(chats && chats.items ? chats.items.find((item) => +item.id === +dialogId) || {} : {})
    }, [chats, dialogId])

    const handleVisibleChange = (visible) => {
        setVisible(visible)
    }

    const invite = () => {
        setVisible(false)
        handleOpenSettingsPopup()
    }

    const handleCloseConfirmPopup = () => {
        dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    const muteTrigger = () => {
        const onSuccess = () => {
            dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    children: (
                        <div className="general-popup chat-mute-success-popup">
                            <h2 className="modal-body">
                                Your notifications are {!chat.is_mute ? '' : 'un'}muted in this chat
                            </h2>
                            <div className="btn-wrap confirm-mute-buttons">
                                <button
                                    type="button"
                                    className="el-btn cancel-delete-button"
                                    onClick={handleCloseConfirmPopup}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    ),
                },
            })
        }
        dispatch({
            type: MUTE_CHAT_TRIGGER,
            payload: {
                dialogId,
                is_mute: !chat.is_mute,
            },
            onSuccess,
        })
    }

    const downloadTranscripts = () => {
        // eslint-disable-next-line unicorn/consistent-function-scoping
        const onSuccess = (response) => {
            setTimeout(() => {
                const link = document.createElement('a')
                link.download = response.csvURL
                link.href = response.csvURL
                document.body.append(link)
                link.click()
                link.remove()
            }, 1000)
        }

        dispatch({
            type: GET_CHAT_TRANSCRIPT_DATA,
            payload: {
                dialogId: chat.id,
            },
            onSuccess: onSuccess,
        })
    }

    const closeDialog = () => {
        const onSuccess = () => {
            setChat({
                ...chat,
                closed: !chat.closed,
            })

            setVisible(false)

            dispatch({
                type: CLOSE_SETTINGS_MESSAGING_POPUP,
            })
        }
        dispatch({
            type: CLOSE_DIALOG,
            payload: {
                dialogId: chat.id,
                type_chat: ChatType.CHAT,
            },
            onSuccess,
        })
    }

    const handleOpenConfirmPopup = () => {
        setVisible(false)
        dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup chat-mute-popup">
                        <h2 className="modal-title">
                            Are you sure you want to {chat.is_mute ? 'un' : ''}mute notifications in this chat?
                        </h2>
                        {chat.is_mute ? (
                            <div className="modal-body">
                                Now you will receive all the email notifications related to this chat.
                            </div>
                        ) : (
                            <div className="modal-body">
                                Now you will only receive the email notifications when someone replies to your message
                            </div>
                        )}
                        <div className="btn-wrap confirm-mute-buttons">
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="el-btn confirm-delete-button" onClick={muteTrigger}>
                                Yes, {chat.is_mute ? 'un' : ''}mute
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    const openActivateChatPopup = () => {
        setVisible(false)
        dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button type="submit" className="el-btn confirm-delete-button" onClick={closeDialog}>
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    const archive = (chat) => {
        setVisible(false)

        dispatch({
            type: CLOSE_SETTINGS_MESSAGING_POPUP,
        })

        const onConfirm = () => {
            dispatch({
                type: ARCHIVE_DIALOG_TOGGLE,
                payload: {
                    dialogId: chat.id,
                    withGlobalTags: true,
                    type_chat: ChatType.CHAT,
                },
            })
        }

        dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button type="submit" className="el-btn confirm-delete-button" onClick={onConfirm}>
                                Yes, I am sure
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    return (
        <>
            <div className="chat-head">
                <div className="chat-head-left">
                    <div className="chat-name-block chat-name-block--chat">
                        {!chat.prospect && (
                            <>
                                {user && user.sizes ? (
                                    <img
                                        className="msg-item-img"
                                        src={user.sizes['70x70'] || user.original}
                                        alt="img"
                                    />
                                ) : (
                                    <div className="msg-avatar-text msg-avatar-text--chat">
                                        {user && user.name ? user.name.charAt(0) : ''}
                                    </div>
                                )}
                            </>
                        )}
                        <div className="chat-name-block__info">
                            <div className="chat-nav-name-container">
                                <h3 className="chat-name">
                                    {user.name} {user.last_name}
                                </h3>
                                {ambassadors.length - 1 > 0 && (
                                    <span className="chat-sa-number">
                                        {'+ ' +
                                            (ambassadors.length - 1) +
                                            ' ambassador' +
                                            (ambassadors.length - 1 > 1 ? 's' : '')}
                                    </span>
                                )}
                                {chat.isBlocked ? (
                                    <BlockProspectPopover className="chat-blocked-button" userId={chat.prospect.id} />
                                ) : null}
                            </div>
                            {chat.reported && !chat.isBlocked ? (
                                <ReportingPopover chat={chat} chatType={ChatType.CHAT} />
                            ) : (
                                <span className="chat-status chat-messaging-status">
                                    {chat.is_mute && <VolumeMuted />}
                                    <span>
                                        {chat.closed || chat.is_prospect_deleted
                                            ? 'Conversation is inactive'
                                            : 'Conversation is active'}
                                        {chat.is_mute ? ', notifications are muted' : ''}
                                    </span>
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                {!chat.is_prospect_deleted ? (
                    <div className="chat-head-right">
                        <Popover
                            placement="bottomRight"
                            trigger="click"
                            open={visible}
                            onOpenChange={handleVisibleChange}
                            className="options-icon-drop"
                            content={
                                <div className="options-drop-block">
                                    <p className="options-drop-item" onClick={invite}>
                                        Manage Ambassadors
                                    </p>
                                    <p className="options-drop-item" onClick={handleOpenConfirmPopup}>
                                        {chat.is_mute ? 'Unmute ' : 'Mute '}notifications
                                    </p>
                                    <p className="options-drop-item" onClick={downloadTranscripts}>
                                        Download chat transcripts
                                    </p>
                                    {!chat.closed && (
                                        <p className="options-drop-item" onClick={openActivateChatPopup}>
                                            Close chat
                                        </p>
                                    )}
                                    {chat.closed && (
                                        <p className="options-drop-item" onClick={() => archive(chat)}>
                                            Archive
                                        </p>
                                    )}
                                </div>
                            }
                        >
                            <OptionsIcon />
                        </Popover>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default Header
