import React, { useCallback, useEffect, useRef, useState } from 'react'
import noPostImage from '../../../../assets/img/no-post.png'
import store from '../../../redux/store'
import { SET_HOME_VISIBLE_POSTS } from '../../../redux/actions/content/postsActions'
import { Image } from 'antd'
import ReactPlayer from 'react-player'

export const RecentPostsBlock = (props) => {
    const { recentPosts, postsLimit, total, today, sevenDaysAgo, handlePostClick, viewMorePosts } = props

    const [postsNumberToShow, setPostsNumberToShow] = useState(8)
    const [gridNumber, setGridNumber] = useState(5)
    const postsBlock = useRef()

    const updatePosts = useCallback(() => {
        if (postsBlock) {
            let gridNumber = 5
            let postsNumber = total > 9 ? 8 : 9
            if (postsBlock.offsetWidth > 450) {
                gridNumber = 6
                postsNumber = total > 12 ? 11 : 12
            }
            if (postsBlock.offsetWidth > 530) {
                gridNumber = 7
                postsNumber = total > 15 ? 14 : 15
            }
            if (postsBlock.offsetWidth > 600) {
                gridNumber = 8
                postsNumber = total > 18 ? 17 : 18
            }
            setGridNumber(gridNumber)
            setPostsNumberToShow(postsNumber)
            store.dispatch({
                type: SET_HOME_VISIBLE_POSTS,
                payload: {
                    visiblePostsNumber: postsNumber,
                },
            })
        }
    }, [total, postsBlock])

    useEffect(() => {
        updatePosts()
        window.addEventListener('resize', updatePosts)
        return () => {
            // Unbind the event listener on clean up
            window.addEventListener('resize', updatePosts)
        }
    }, [updatePosts])

    return (
        <div
            id="recent-posts-block"
            className={
                'recent-posts-block' + (!recentPosts || recentPosts.length === 0 ? '' : ' recent-posts-block-not-empty')
            }
            ref={postsBlock}
        >
            {!recentPosts || recentPosts.length === 0 ? (
                <>
                    <p className="recent-posts-title">
                        <b>Recent posts</b>
                        <span>0 posts</span>
                    </p>
                    <div className="no-answers-image">
                        <Image src={noPostImage} alt="" />
                    </div>
                </>
            ) : (
                <div className="recent-posts-with-data">
                    <p className="recent-posts-title">
                        <b>Recent posts</b>
                        <span className="recent-posts-number">+ {total}</span>
                    </p>
                    <p className="recent-posts-date-range">Last 7 days ({sevenDaysAgo + ' - ' + today} )</p>
                    <div
                        className="recent-posts-list"
                        style={{ gridTemplateColumns: 'repeat(' + gridNumber + ', 1fr)' }}
                    >
                        {recentPosts.map((post, index) => {
                            if (+index < postsNumberToShow) {
                                if (post.images && post.images.length > 0) {
                                    return (
                                        <Image
                                            key={post.id}
                                            src={post.images[0].sizes['370x370'] || post.images[0].original}
                                            alt=""
                                            wrapperClassName="recent-post"
                                            onClick={() => handlePostClick(post)}
                                        />
                                    )
                                } else if (post.videos && post.videos.length > 0) {
                                    return (
                                        <ReactPlayer
                                            key={post.id}
                                            className="recent-post"
                                            url={post.videos[0].original}
                                            width="100%"
                                            height="100%"
                                            aria-label=""
                                            playing={false}
                                            muted
                                            playsinline
                                            config={{
                                                file: {
                                                    attributes: {
                                                        controlsList: 'nodownload',
                                                    },
                                                },
                                            }}
                                            onContextMenu={(e) => e.preventDefault()}
                                            onClick={() => handlePostClick(post)}
                                        />
                                    )
                                }
                            }
                        })}
                        {total > (postsNumberToShow > postsLimit ? postsLimit : postsNumberToShow) && (
                            <div className="view-more-posts" onClick={viewMorePosts}>
                                + {total - (postsNumberToShow > postsLimit ? postsLimit : postsNumberToShow)} more
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
