import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import Loader from '../../content/components/Loader'
import CreateQuestionPopup from './components/popups/CreateQuestionPopup'
import store from '../../../redux/store'
import Modal from 'antd/es/modal'
import NewQuestion from './components/NewQuestion'
import { pusherChild } from '../../../system/services/PusherWrapper'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

import { OPEN_CREATE_QUESTION_POPUP, CLOSE_CREATE_QUESTION_POPUP } from '../../../redux/actions/faq/componentsActions'

import { deleteQuestion, QUESTIONS_INFO } from '../../../redux/actions/faq/faqActions'
import ConfirmDeletePopup from '../../../components/popups/ConfirmDeletePopup'
import { closeDeleteModal, openDeleteModal } from '../../../redux/actions/content/postsActions'
import Search from '../../../components/Search'
import { Protect } from 'src/app/permissions/Protect'

class NewQuestions extends PureComponent {
    constructor(props) {
        super(props)

        this.fireChannelActions = this.fireChannelActions.bind(this)
        this.requestSearch = this.requestSearch.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: QUESTIONS_INFO,
            payload: {
                filter: {
                    no_answers: true,
                    searchItems: [],
                },
                withGlobalTags: true,
            },
        })

        this.channel = pusherChild.subscribe('dashboard')
        this.fireChannelActions()

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'faq',
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: QUESTIONS_INFO,
                payload: {
                    filter: {
                        no_answers: true,
                        searchItems: [],
                    },
                    withGlobalTags: true,
                },
            })

            this.channel.unbind()
            this.fireChannelActions()
        }
    }

    fireChannelActions() {
        this.channel.bind(
            'faq:answer:' +
                (this.props.account.currentGroupUniversityId
                    ? this.props.account.currentGroupUniversityId
                    : this.props.account.account_info.university_id),
            (data) => {
                store.dispatch({
                    type: QUESTIONS_INFO,
                    payload: {
                        filter: {
                            no_answers: true,
                            searchItems: this.props.faqs.searchItems,
                        },
                    },
                })
            }
        )
    }

    componentWillUnmount() {
        if (this.channel) {
            this.channel.unbind()
        }
    }

    handleOpenCreateQuestionPopup() {
        store.dispatch({
            type: OPEN_CREATE_QUESTION_POPUP,
        })
    }

    handleCloseCreateQuestionPopup() {
        store.dispatch({
            type: CLOSE_CREATE_QUESTION_POPUP,
        })
    }

    requestSearch(items) {
        store.dispatch({
            type: QUESTIONS_INFO,
            payload: {
                filter: {
                    no_answers: true,
                    searchItems: items,
                },
                withGlobalTags: true,
            },
        })
    }

    render() {
        const { faqs, deleteQuestion, closeDeleteModal, openDeleteModal, confirmDeleteModal, createQuestionPopup } =
            this.props

        const { postId, type, isOpened } = confirmDeleteModal

        if (!faqs.isLoading && faqs.items.length === 0) {
            var nonCluster = (
                <div className="non-cluster">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 -2 24 24"
                        width="70"
                        height="70"
                        preserveAspectRatio="xMinYMin"
                        className="jam jam-help"
                    >
                        <path d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-3a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.276-3.218a1 1 0 0 1-1.232-1.576l.394-.308a1.5 1.5 0 1 0-1.847-2.364l-.394.308a1 1 0 1 1-1.23-1.576l.393-.308a3.5 3.5 0 1 1 4.31 5.516l-.394.308z" />
                    </svg>
                    No new questions. Why not{' '}
                    <a onClick={this.handleOpenCreateQuestionPopup} data-automation-id="create-new-question">
                        create
                    </a>{' '}
                    a new one?
                </div>
            )
        }

        if (faqs.items) {
            var newQuestions = faqs.items.map((faq) => {
                return <NewQuestion openDeleteModal={openDeleteModal} key={faq.id} faq={faq} />
            })
        }

        return (
            <Protect>
                <div id="page-body" className="page-body js-index">
                    <section className="content flexible-page">
                        <div className="top-side-container">
                            <div className="left-side-container">
                                <Search requestSearch={this.requestSearch} currentItems={faqs.searchItems} />
                            </div>
                            <div className="right-side-container">
                                <a
                                    className="el-btn-outline create-faq-btn"
                                    onClick={this.handleOpenCreateQuestionPopup}
                                >
                                    Create new question
                                </a>
                            </div>
                        </div>
                        <div className="content-header">
                            <h1>Unanswered questions</h1>
                        </div>

                        {faqs.isLoading ? <Loader /> : newQuestions}
                        {nonCluster}
                    </section>
                </div>
                <Modal
                    open={createQuestionPopup.isOpened}
                    bodyStyle={{ padding: 0 }}
                    width="auto"
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseCreateQuestionPopup}
                    destroyOnClose
                    className="react-create-new-question"
                >
                    <CreateQuestionPopup
                        key={createQuestionPopup.mode}
                        onClosePopup={this.handleCloseCreateQuestionPopup}
                        isAnsweredQuestions={false}
                        isPublished={false}
                    />
                </Modal>
                <Modal
                    open={isOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={closeDeleteModal}
                >
                    <ConfirmDeletePopup
                        handleConfirmDelete={() => deleteQuestion({ id: postId })}
                        handleClosePopup={closeDeleteModal}
                        type={type}
                    />
                </Modal>
            </Protect>
        )
    }
}

export default connect(
    (state) => {
        return {
            account: state.account,
            createQuestionPopup: state.createQuestionPopup,
            confirmDeleteModal: state.confirmDeleteModal,
            faqs: state.faqs,
        }
    },
    { deleteQuestion, closeDeleteModal, openDeleteModal }
)(NewQuestions)
