import { OPEN_COPY_LINK_POPUP, CLOSE_COPY_LINK_POPUP } from '../../../actions/chat/commonActions'

import { CHAT_COPY_MODAL } from '../../../store/initialState'

const initialState = CHAT_COPY_MODAL

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case OPEN_COPY_LINK_POPUP: {
            return {
                ...state,
                dialog_id: payload.dialog_id,
                private_code: payload.private_code,
                isOpened: true,
            }
        }

        case CLOSE_COPY_LINK_POPUP: {
            return {
                ...state,
                dialog_id: null,
                private_code: null,
                isOpened: false,
            }
        }

        default: {
            return state
        }
    }
}
