import { Avatar, Skeleton, List } from 'antd'
import React from 'react'

const UniversityCell = ({ record }) => {
    return (
        <Skeleton loading={false} avatar active paragraph={{ rows: 0 }}>
            <List.Item.Meta
                avatar={
                    <Avatar
                        src={
                            record.university.avatar
                                ? record.university.avatar.sizes['70x70'] || record.university.avatar.original
                                : null
                        }
                        size={50}
                    />
                }
                title={record.university_name}
            />
        </Skeleton>
    )
}

const universityCellRenderer = (text, record) => <UniversityCell record={record} />

export default universityCellRenderer
