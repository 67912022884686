import { lazy } from 'react'

export { default as CampaignManagementTypeRootScreen } from './campaign-management-type-root-screen/campaign-management-type-root-screen'

const CampaignManagementGuideScreen = lazy(() =>
    import('./campaign-management-gide-screen/campaign-management-gide-screen')
)

const CampaignsListScreen = lazy(() => import('./campaigns-list-screen/campaigns-list-screen'))
const CampaignsCreateScreen = lazy(() => import('./campaigns-create-screen/campaigns-create-screen'))
const CampaignsViewScreen = lazy(() => import('./campaign-view-screen/campaign-view-screen'))
const CampaignsResultsScreen = lazy(() => import('./campaign-results-screen/campaign-results-screen'))

export {
    CampaignManagementGuideScreen,
    CampaignsListScreen,
    CampaignsCreateScreen,
    CampaignsViewScreen,
    CampaignsResultsScreen,
}
