import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import store from '../../../redux/store/index'
import FindUserForm from './components/FindUserForm'
import Modal from 'antd/es/modal'
import LetterSendSuccess from './components/LetterSendSuccess'
import PasswordRecoverSuccess from './components/PasswordRecoverSuccess'
import PasswordRecoverForm from './components/PasswordRecoverForm'

import {
    FIND_USER_RECOVER_REQUESTED,
    RECOVER_COMFIRM_REQUESTED,
    CLOSE_LETTER_SEND_POPUP,
    CLOSE_RECOVERY_SUCCESS_POPUP,
} from '../../../redux/actions/account/accountActions'

class Forgot extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            redirectToLogin: false,
        }
        this.handlePasswordRecoveryConfirmSubmit = this.handlePasswordRecoveryConfirmSubmit.bind(this)
        this.handleCloseRecoverSuccessPopup = this.handleCloseRecoverSuccessPopup.bind(this)
    }

    handleCloseSuccessPopup() {
        store.dispatch({
            type: CLOSE_LETTER_SEND_POPUP,
        })
    }

    handleCloseRecoverSuccessPopup() {
        store.dispatch({
            type: CLOSE_RECOVERY_SUCCESS_POPUP,
        })

        this.setState({
            redirectToLogin: true,
        })
    }

    handleFindUserSubmit(values) {
        store.dispatch({
            type: FIND_USER_RECOVER_REQUESTED,
            payload: {
                email: values.email,
            },
        })
    }

    handlePasswordRecoveryConfirmSubmit(values) {
        store.dispatch({
            type: RECOVER_COMFIRM_REQUESTED,
            payload: {
                user_id: this.props.match.params.user_id,
                hex: this.props.match.params.hex,
                new_password: values.new_password,
                confirm_password: values.confirm_password,
            },
        })
    }

    render() {
        if (this.state.redirectToLogin) return <Redirect push to="/account/login" />

        let renderForm = ''
        renderForm =
            window.location.href.lastIndexOf('/confirm/') != -1 ? (
                <PasswordRecoverForm onSubmit={this.handlePasswordRecoveryConfirmSubmit.bind(this)} />
            ) : (
                <FindUserForm onSubmit={this.handleFindUserSubmit.bind(this)} />
            )
        return (
            <div id="page-wrapper" className="page-wrapper unregistered-page">
                <div id="page-body" className="page-body js-index">
                    <div className="signin-container">
                        <div className="signin-block">{renderForm}</div>
                    </div>
                </div>

                <Modal
                    open={this.props.successLetterSendModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={true}
                    maskClosable={false}
                    onCancel={this.handleCloseSuccessPopup}
                >
                    <LetterSendSuccess handleClosePopup={this.handleCloseSuccessPopup} />
                </Modal>

                <Modal
                    open={this.props.successRecovery.isOpened}
                    centered={true}
                    bodyStyle={{ height: 'auto' }}
                    footer={null}
                    closable={true}
                    maskClosable={false}
                    onCancel={this.handleCloseRecoverSuccessPopup}
                >
                    <PasswordRecoverSuccess handleClosePopup={this.handleCloseRecoverSuccessPopup} />
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        successLetterSendModal: state.successLetterSendModal,
        successRecovery: state.successRecovery,
    }
})(withRouter(Forgot))
