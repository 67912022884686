import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, useRouteMatch } from 'react-router-dom'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../redux/actions/content/componentsActions'

import { CampaignTypeModal } from './components'

import { CampaignManagementTypeRootScreen } from './screens'

const CampaignManagementScreen = () => {
    const dispatch = useDispatch()
    const { path } = useRouteMatch()

    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'campaign-management',
            },
        })
    }, [dispatch])

    return (
        <div className="upcoming-events">
            <div className="content">
                <Route path={`${path}/:param`} component={CampaignManagementTypeRootScreen} />
            </div>
            <CampaignTypeModal />
        </div>
    )
}

export default CampaignManagementScreen
