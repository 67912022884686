import { call, put, select } from 'redux-saga/effects'

import API from '../../../api'
import {
    PROSPECTS_CHARTS_SUCCESS,
    PROSPECTS_CHARTS_FAILED,
    PROSPECTS_TABLE_SUCCESS,
    PROSPECTS_TABLE_FAILED,
    PROSPECTS_CSV_SUCCESS,
    PROSPECTS_CSV_FAILED,
    PROSPECT_OPTIONAL_DATA_SUCCESS,
    PROSPECT_OPTIONAL_DATA_FAILED,
    PROSPECT_FEEDBACK_DATA_SUCCESS,
    PROSPECT_FEEDBACK_DATA_FAILED,
    PROSPECT_MANDATORY_DATA_SUCCESS,
    PROSPECT_MANDATORY_DATA_FAILED,
} from '../../actions/reporting/prospectsActions'

const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

export function* prospectsChartsData({ payload }) {
    try {
        payload.universityIds = yield select(getCurrentGroupUniversityIds)
        if (payload.prospectsIds && payload.prospectsIds.length === 0) {
            yield put({
                type: PROSPECTS_CHARTS_SUCCESS,
                payload: {},
            })

            return
        }

        const response = yield call(() => {
            return API.dashboard.prospectsChartsData(payload)
        })

        yield response.success
            ? put({
                  type: PROSPECTS_CHARTS_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: PROSPECTS_CHARTS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: PROSPECTS_CHARTS_FAILED,
        })
    }
}

export function* prospectsTableData({ payload }) {
    try {
        payload.universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.prospectsTableData(payload)
        })

        yield response.success
            ? put({
                  type: PROSPECTS_TABLE_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: PROSPECTS_TABLE_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: PROSPECTS_TABLE_FAILED,
        })
    }
}

export function* prospectsCsvData({ payload }) {
    try {
        payload.universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.prospectsCsvData(payload)
        })

        yield response.success
            ? put({
                  type: PROSPECTS_CSV_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: PROSPECTS_CSV_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: PROSPECTS_CSV_FAILED,
        })
    }
}

export function* prospectOptionalData({ prospectId }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.dashboard.prospectOptionalData(prospectId, universityIds)
        })

        yield response.success
            ? put({
                  type: PROSPECT_OPTIONAL_DATA_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: PROSPECT_OPTIONAL_DATA_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: PROSPECT_OPTIONAL_DATA_FAILED,
        })
    }
}

export function* prospectMandatoryData({ prospectId }) {
    try {
        const response = yield call(() => {
            return API.dashboard.prospectMandatoryData(prospectId)
        })

        yield response.success
            ? put({
                  type: PROSPECT_MANDATORY_DATA_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: PROSPECT_MANDATORY_DATA_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: PROSPECT_MANDATORY_DATA_FAILED,
        })
    }
}

export function* prospectFeedbackData({ prospectId }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const response = yield call(() => {
            return API.reporting.prospectFeedbackData(prospectId, universityIds)
        })

        yield response.success
            ? put({
                  type: PROSPECT_FEEDBACK_DATA_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: PROSPECT_FEEDBACK_DATA_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: PROSPECT_FEEDBACK_DATA_FAILED,
        })
    }
}
