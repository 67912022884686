import { Form, Button, Row, Space, Col } from 'antd'
import React, { useCallback, useState } from 'react'
import {
    Editor,
    convertToRaw,
    EditorState,
    ContentState,
    RichUtils,
    Modifier,
    CompositeDecorator,
    convertFromRaw,
} from 'draft-js'

import EditorToolbar from '../../../../../components/EditorToolbar'

const formStyle = {
    display: 'block',
    width: '100%',
}

const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity()
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
}
const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData()
    return (
        <a href={url} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    )
}

const decorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: Link,
    },
])

function checkTypeObject(str) {
    try {
        JSON.parse(str)
    } catch {
        return false
    }
    return true
}

const MarkenitgConsentForm = ({ saveMarketingConsentQuestion, marketingConsentText, form }) => {
    const content = checkTypeObject(marketingConsentText)
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(marketingConsentText)), decorator)
        : EditorState.createWithContent(ContentState.createFromText(marketingConsentText), decorator)
    const [text, setText] = useState(content)
    const initialValues = {
        message: text,
    }

    const onTextChange = useCallback(
        (e) => {
            setText(e)
            const hasText = convertToRaw(e.getCurrentContent()).blocks.some((key) => key.text)
            if (hasText) {
                form.setFieldsValue({ message: e })
            } else {
                form.setFieldsValue({ message: '' })
            }
            form.validateFields()
        },
        [form]
    )

    const onBoldClick = useCallback(
        (content, id) => {
            onTextChange(RichUtils.toggleInlineStyle(content, 'BOLD'))
        },
        [onTextChange]
    )

    const onItalicClick = useCallback(
        (content) => {
            onTextChange(RichUtils.toggleInlineStyle(content, 'ITALIC'))
        },
        [onTextChange]
    )

    const onUnderlineClick = useCallback(
        (content) => {
            onTextChange(RichUtils.toggleInlineStyle(content, 'UNDERLINE'))
        },
        [onTextChange]
    )

    const onListClick = useCallback(
        (content) => {
            onTextChange(RichUtils.toggleBlockType(content, 'unordered-list-item'))
        },
        [onTextChange]
    )

    const onLinkClick = useCallback(
        (content, link) => {
            if (!link) {
                return
            }
            const editorState = content
            const selection = editorState.getSelection()

            const contentState = editorState.getCurrentContent()
            const contentWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: link })
            const entityKey = contentWithEntity.getLastCreatedEntityKey()

            const contentStateWithLink = Modifier.applyEntity(contentWithEntity, selection, entityKey)

            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithLink })
            onTextChange(RichUtils.toggleLink(newEditorState, selection, entityKey))
        },
        [onTextChange]
    )

    return (
        <Form style={formStyle} layout="vertical" initialValues={initialValues} form={form}>
            <Space direction={'vertical'} size={'large'}>
                <Row style={{ alignItems: 'center' }}>
                    <Form.Item
                        shouldUpdate
                        label={null}
                        name="message"
                        rules={[{ required: true, message: 'Add marketing consent' }]}
                        style={{ margin: 0 }}
                    >
                        <Col className="ant-input" style={{ width: '40rem' }}>
                            <EditorToolbar
                                utils={[
                                    { name: 'bold', handler: () => onBoldClick(text) },
                                    { name: 'italic', handler: () => onItalicClick(text) },
                                    { name: 'underline', handler: () => onUnderlineClick(text) },
                                    { name: 'list', handler: () => onListClick(text) },
                                    { name: 'link', handler: (link) => onLinkClick(text, link) },
                                ]}
                            />
                            <Editor readOnly={false} editorState={text} onChange={onTextChange} />
                        </Col>
                    </Form.Item>
                    <Button
                        className="settings-btn"
                        type="link"
                        htmlType="submit"
                        data-automation-id="marketing-consent-save-btn"
                        onClick={() => saveMarketingConsentQuestion(text)}
                    >
                        Save
                    </Button>
                </Row>
            </Space>
        </Form>
    )
}

export default MarkenitgConsentForm
