import { OPEN_CHAT_GROUP_POPUP, CLOSE_CHAT_GROUP_POPUP } from '../../../actions/chat/groupMessagingActions'

import { CHAT_GROUP_MODAL } from '../../../store/initialState'

const initialState = CHAT_GROUP_MODAL

export default function (state = initialState, action) {
    const type = action.type

    switch (type) {
        case OPEN_CHAT_GROUP_POPUP: {
            return {
                ...state,
                isOpened: true,
            }
        }

        case CLOSE_CHAT_GROUP_POPUP: {
            return {
                ...state,
                isOpened: false,
            }
        }

        default: {
            return state
        }
    }
}
