import { isNil } from 'ramda'

import { LIVE_EVENTS } from '../../actions/live-event/live-event-action-types'

import { createItemsShape } from '../../utils/reducer-utils'
import { EVENT_STREAM } from '../../actions/event-stream/event-stream-action-types'

const eventStatusUpdate = (item, payload) => ({
    ...item,
    is_chat_active: isNil(payload.isChatActive) ? item.is_chat_active : payload.isChatActive,
    status: isNil(payload.status) ? item.status : payload.status,
    is_stream_active: isNil(payload.isStreamActive) ? item.is_stream_active : payload.isStreamActive,
})

const initialState = {
    items: {
        byId: {},
        allIds: [],
    },
    loading: false,
    activeEventId: undefined,
    onlineUsers: [],
    eventModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    ambassadorsModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    speakersModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
    joinModal: {
        open: false,
    },
    currentEventStatus: undefined,
    joinedStream: false,
    activeTab: 'participants',
}

export default (state = initialState, action) => {
    const { type, payload, meta = {} } = action

    switch (type) {
        case LIVE_EVENTS.FETCH: {
            return {
                ...state,
                loading: true,
                currentEventStatus: payload.status,
            }
        }

        case LIVE_EVENTS.FETCH_SUCCESS: {
            const activeEventId = state.activeEventId

            const items = activeEventId
                ? payload.items.map((item) => {
                      if (+item.id === +activeEventId) {
                          const event = state.items.byId[activeEventId]

                          return {
                              ...item,
                              prospects: event ? event.prospects : [],
                          }
                      }
                      return item
                  })
                : payload.items

            return {
                ...state,
                items: createItemsShape(
                    meta.addToExisting ? [...Object.values(state.items.byId), ...payload.items] : items
                ),
                loading: false,
            }
        }

        case LIVE_EVENTS.FETCH_ERROR: {
            return {
                ...state,
                items: [],
                loading: false,
            }
        }

        case LIVE_EVENTS.SET_ACTIVE: {
            return {
                ...state,
                activeEventId: payload,
                onlineUsers: [],
                joinedStream: false,
            }
        }

        case LIVE_EVENTS.SET_ACTIVE_SIDEBAR_TAB: {
            return {
                ...state,
                activeTab: payload,
            }
        }

        case LIVE_EVENTS.RESET_ACTIVE: {
            return {
                ...state,
                activeEventId: initialState.activeEventId,
                activeTab: initialState.activeTab,
                onlineUsers: [],
            }
        }

        case LIVE_EVENTS.OPEN_FORM_MODAL: {
            return {
                ...state,
                eventModal: {
                    ...state.eventModal,
                    open: true,
                    data: payload,
                },
            }
        }

        case LIVE_EVENTS.CLOSE_FORM_MODAL: {
            return {
                ...state,
                eventModal: initialState.eventModal,
            }
        }

        case LIVE_EVENTS.CREATE:
        case LIVE_EVENTS.UPDATE: {
            return {
                ...state,
                eventModal: {
                    ...state.eventModal,
                    submitting: true,
                },
            }
        }

        case LIVE_EVENTS.CREATE_SUCCESS:
        case LIVE_EVENTS.UPDATE_SUCCESS: {
            return {
                ...state,
                eventModal: initialState.eventModal,
            }
        }

        case LIVE_EVENTS.UPDATE_ERROR:
        case LIVE_EVENTS.CREATE_ERROR: {
            return {
                ...state,
                eventModal: initialState.eventModal,
            }
        }

        case LIVE_EVENTS.OPEN_AMBASSADORS_MODAL: {
            return {
                ...state,
                ambassadorsModal: {
                    ...state.ambassadorsModal,
                    open: true,
                    data: payload,
                },
            }
        }

        case LIVE_EVENTS.CLOSE_AMBASSADORS_MODAL: {
            return {
                ...state,
                ambassadorsModal: initialState.ambassadorsModal,
            }
        }

        case LIVE_EVENTS.EDIT_AMBASSADORS: {
            return {
                ...state,
                ambassadorsModal: {
                    ...state.ambassadorsModal,
                    submitting: true,
                },
            }
        }

        case LIVE_EVENTS.EDIT_AMBASSADORS_SUCCESS:
        case LIVE_EVENTS.EDIT_AMBASSADORS_ERROR: {
            return {
                ...state,
                ambassadorsModal: initialState.ambassadorsModal,
            }
        }

        case LIVE_EVENTS.OPEN_SPEAKERS_MODAL: {
            return {
                ...state,
                speakersModal: {
                    ...state.speakersModal,
                    open: true,
                    data: payload,
                },
            }
        }

        case LIVE_EVENTS.CLOSE_SPEAKERS_MODAL: {
            return {
                ...state,
                speakersModal: initialState.speakersModal,
            }
        }

        case LIVE_EVENTS.UPDATE_SPEAKERS: {
            return {
                ...state,
                speakersModal: {
                    ...state.speakersModal,
                    submitting: true,
                },
            }
        }

        case LIVE_EVENTS.UPDATE_SPEAKERS_SUCCESS:
        case LIVE_EVENTS.UPDATE_SPEAKERS_ERROR: {
            return {
                ...state,
                speakersModal: initialState.speakersModal,
            }
        }

        case LIVE_EVENTS.FETCH_PROSPECTS_SUCCESS: {
            return {
                ...state,
                items: {
                    ...state.items,
                    byId: {
                        ...state.items.byId,
                        [+payload.liveEventId]: {
                            ...state.items.byId[+payload.liveEventId],
                            prospects: payload.items,
                            totalProspects: payload.total,
                        },
                    },
                },
            }
        }

        case LIVE_EVENTS.OPEN_JOIN_MODAL: {
            return {
                ...state,
                joinModal: {
                    open: true,
                },
            }
        }

        case LIVE_EVENTS.CLOSE_JOIN_MODAL: {
            return {
                ...state,
                joinModal: initialState.joinModal,
            }
        }

        case LIVE_EVENTS.JOIN_STREAM: {
            return {
                ...state,
                joinedStream: true,
            }
        }

        case LIVE_EVENTS.LEAVE_STREAM: {
            return {
                ...state,
                joinedStream: false,
            }
        }

        case LIVE_EVENTS.STOP_STREAM: {
            return {
                ...state,
                items: {
                    ...state.items,
                    byId: {
                        ...state.items.byId,
                        [+payload.id]: {
                            ...state.items.byId[+payload.id],
                            is_stream_active: false,
                        },
                    },
                },
            }
        }

        case LIVE_EVENTS.UPDATE_EVENT_STATUS: {
            return {
                ...state,
                items: {
                    ...state.items,
                    byId: {
                        ...state.items.byId,
                        [+payload.id]: eventStatusUpdate(state.items.byId[+payload.id], payload),
                    },
                },
            }
        }

        case LIVE_EVENTS.UPDATE_PROSPECT_LIST: {
            return {
                ...state,
                items: {
                    ...state.items,
                    byId: {
                        ...state.items.byId,
                        [+payload.id]: {
                            ...state.items.byId[+payload.id],
                            prospects: [...state.items.byId[+payload.id].prospects, payload.newProspect],
                            totalProspects: payload.totalProspects,
                        },
                    },
                },
            }
        }

        case EVENT_STREAM.ADD_PARTICIPANT: {
            return {
                ...state,
                onlineUsers: [...state.onlineUsers, payload.user.id],
            }
        }

        case EVENT_STREAM.REMOVE_PARTICIPANT: {
            return {
                ...state,
                onlineUsers: state.onlineUsers.filter((id) => id !== payload.userId),
            }
        }

        default: {
            return state
        }
    }
}
