import { all, call } from 'redux-saga/effects'

import liveStreamCreateSaga from './live-sreams-create-saga'
import liveStreamsFetchSaga from './live-streams-fetch-saga'
import liveStreamUpdateSaga from './live-stream-update-saga'

function* liveStreamsRootSaga() {
    yield all([call(liveStreamsFetchSaga), call(liveStreamCreateSaga), call(liveStreamUpdateSaga)])
}

export default liveStreamsRootSaga
