export const TREND_ANALYZER_CHARTS_REQUESTED = 'TREND_ANALYZER_CHARTS_REQUESTED'
export const TREND_ANALYZER_CHARTS_SUCCESS = 'TREND_ANALYZER_CHARTS_SUCCESS'
export const TREND_ANALYZER_CHARTS_FAILED = 'TREND_ANALYZER_CHARTS_FAILED'
export const TREND_ANALYZER_TABLE_REQUESTED = 'TREND_ANALYZER_TABLE_REQUESTED'
export const TREND_ANALYZER_TABLE_SUCCESS = 'TREND_ANALYZER_TABLE_SUCCESS'
export const TREND_ANALYZER_TABLE_FAILED = 'TREND_ANALYZER_TABLE_FAILED'
export const TREND_ANALYZER_CSV_REQUESTED = 'TREND_ANALYZER_CSV_REQUESTED'
export const TREND_ANALYZER_CSV_SUCCESS = 'TREND_ANALYZER_CSV_SUCCESS'
export const TREND_ANALYZER_CSV_FAILED = 'TREND_ANALYZER_CSV_FAILED'

export const fetchTrendAnalyzerChartsData = (filter) => ({
    type: TREND_ANALYZER_CHARTS_REQUESTED,
    filter,
})

export const fetchTrendAnalyzerTableData = (filter) => ({
    type: TREND_ANALYZER_TABLE_REQUESTED,
    filter,
})

export const fetchTrendAnalyzerCsvData = (filter, topicIds) => ({
    type: TREND_ANALYZER_CSV_REQUESTED,
    filter,
    topicIds,
})
