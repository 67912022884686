import { call, select } from 'redux-saga/effects'
import API from '../../../api'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* exportData(action) {
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return API.settings.exportData(isGroupAdmin && universityId ? universityId : null)
        })

        if (response.success) {
            action.onFinish(true)
        } else {
            action.onFinish()
        }
    } catch (error) {
        console.log(error)
        action.onFinish()
    }
}
