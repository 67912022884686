import React, { useMemo } from 'react'

import { ChatType } from '../../../../../../../../../../common/constants'

const ChatFooter = ({ activeEvent, type }) => {
    const show = useMemo(
        () => !activeEvent.is_chat_active && type === ChatType.LIVE_EVENT_CHAT,
        [activeEvent.is_chat_active, type]
    )

    return (
        show && (
            <div className="chat-footer-closed">
                <p className="closed-text">The chat is closed</p>
            </div>
        )
    )
}

export default ChatFooter
