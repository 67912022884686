import classnames from 'classnames'
import React from 'react'
import Loader from '../../../../components/Loader'
import dayjs from 'dayjs'
import ExportButtons from './ExportButtons'

export const TimeTable = ({ children, timeLimitsActive, totalTitle, isLoading }) => {
    return (
        <div className="prospect-table-wraper">
            <div className="users-container prospect-table-container time-table-container ">
                <div className="table-container mod-users-table mod-prospect-table">
                    {!isLoading ? (
                        <table className="table">
                            <thead className="table-head">
                                <tr>
                                    <td className="table-head-item">Name</td>
                                    <td className="table-head-item">Profile information</td>
                                    <td className="table-head-item">Chat (minutes)</td>
                                    <td className="table-head-item">Content (minutes)</td>
                                    <td className="table-head-item">FAQ (minutes)</td>
                                    {!timeLimitsActive && <td className="table-head-item">Live events (minutes)</td>}
                                    <td className="table-head-item">{totalTitle}</td>
                                    <td className={classnames('table-head-item', { disabled: !timeLimitsActive })}>
                                        Time left (minutes)
                                    </td>
                                    <td className={classnames('table-head-item', { disabled: !timeLimitsActive })}>
                                        Set Time Limits
                                    </td>
                                </tr>
                            </thead>
                            <tbody>{children}</tbody>
                        </table>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </div>
    )
}

export const TimeTableHead = ({ children }) => {
    return (
        <div className="prospect-table-head home-start-guide">
            <div className="prospect-table-head-container">
                <div className="prospect-table-head-title-container">
                    <div className="prospect-table-title">Ambassador time report</div>
                    <p>
                        Filter by date to see how much time your ambassadors are spending on the service across
                        different time periods. Show time limits to set and review time caps for your ambassadors on a
                        weekly or monthly basis. Click{' '}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://knowledge.theambassadorplatform.com/time-report-and-setting-time-limits-for-ambassadors"
                        >
                            here
                        </a>{' '}
                        to read more about how we measure time, and how to use time limits.
                    </p>
                </div>
                <ExportButtons
                    isFilteredByDate={children[0].props.isActive}
                    fromDate={children[0].props.fromDate}
                    toDate={children[0].props.toDate}
                    limitsType={children[1].props.limitsType}
                />
            </div>
            <div className="prospect-table-head-filter">{children}</div>
        </div>
    )
}

export class FilterSelect extends React.PureComponent {
    state = {
        choosen: 'week',
    }

    handleChoose = (item) => {
        this.props.handleChoose(item)
        this.setState({ choosen: item })
    }

    onCheked = () => {
        if (!this.state.choosen) this.handleChoose('week')
        else this.handleChoose(this.state.choosen)
    }

    render() {
        const { isActive } = this.props
        return (
            <div className={'rp-time-filter ' + (isActive ? 'active' : '')}>
                <div className="rp-time-filter-title">
                    <span onClick={this.onCheked} className="radion-btn"></span>

                    <p onClick={this.onCheked}>Show time limits</p>
                </div>

                <div>
                    <ul className={classnames('rp-time-filter-btns', { disabled: !isActive })}>
                        <li
                            onClick={isActive ? () => this.handleChoose('week') : null}
                            className={this.state.choosen == 'week' ? 'active' : ''}
                        >
                            Since Monday
                        </li>

                        <li
                            onClick={isActive ? () => this.handleChoose('month') : null}
                            className={this.state.choosen == 'month' ? 'active' : ''}
                        >
                            Since 1st {dayjs().format('MMMM')}
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
