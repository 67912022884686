import React from 'react'
import { connect } from 'react-redux'
import { ChatType } from '../common/constants'
import BlockReasonPopup from '../containers/chat/messaging/components/popups/BlockReasonPopup'
import { SET_REPORT_REASON, UNBLOCK_CHAT_MEMBER, UNFLAG_DIALOG } from '../redux/actions/chat/messagingActions'
import { OPEN_ACTION_MODAL } from '../redux/actions/components/componentsActions'
import store from '../redux/store'

const BlockingProspectControl = (props) => {
    const {
        dialogId,
        userId,
        chatType,
        isBlocked,
        isHidden,
        chats,
        blockButtonClass,
        unblockButtonClass,
        tableDataProps,
        prospectInfoChat,
        currentChat,
    } = props

    const chat = chats.items.find((item) => +item.id === +dialogId) || null

    const checkIfProspect = () => {
        return chat && chat.members.some((item) => item.id === userId && item.role_key === 'prospect')
    }

    const unblockInChat = () => {
        if (chatType === ChatType.GROUP_CHAT) {
            store.dispatch({
                type: UNBLOCK_CHAT_MEMBER,
                payload: {
                    dialogId: dialogId,
                    userId: userId,
                    type_chat: chatType,
                },
            })
            store.dispatch({
                type: SET_REPORT_REASON,
                payload: {
                    reportedData: null,
                    dialogId: null,
                },
            })
        } else {
            store.dispatch({
                type: UNFLAG_DIALOG,
                payload: {
                    dialogId: dialogId,
                    type: ChatType.CHAT,
                },
            })
        }
    }

    const blockProspectChat = () => {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <BlockReasonPopup
                        userId={userId}
                        chatType={chatType}
                        dialogId={dialogId}
                        tableDataProps={tableDataProps}
                        prospectInfoChat={prospectInfoChat}
                        currentChat={currentChat}
                    />
                ),
                className: 'block-prospect-popup',
            },
        })
    }

    return (
        <>
            {!isBlocked ? (
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 'auto' }}>
                    {(chatType === ChatType.GROUP_CHAT && isHidden) ||
                    (chatType === ChatType.CHAT &&
                        chat &&
                        chat.reported &&
                        chat.prospect &&
                        chat.prospect.id === userId) ? (
                        <div className={`unblock-button ${unblockButtonClass || ''}`} onClick={unblockInChat}>
                            Unblock in this chat
                        </div>
                    ) : null}
                    {(chatType === ChatType.GROUP_CHAT && checkIfProspect() && isHidden) ||
                    (chatType === ChatType.CHAT &&
                        chat &&
                        chat.reported &&
                        chat.prospect &&
                        chat.prospect.id === userId) ||
                    !chatType ? (
                        <div className={`block-button ${blockButtonClass || ''}`} onClick={blockProspectChat}>
                            Block this prospect
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    )
}

export default connect((state) => {
    return {
        chats: state.chats,
        account: state.account,
        currentChat: state.currentChat,
    }
})(BlockingProspectControl)
