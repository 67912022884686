import { EVENT_STREAM } from './event-stream-action-types'

export const openJoinModal = () => ({
    type: EVENT_STREAM.OPEN_JOIN_MODAL,
})

export const closeJoinModal = () => ({
    type: EVENT_STREAM.CLOSE_JOIN_MODAL,
})

export const muteLocalAudio = (muted) => ({
    type: EVENT_STREAM.MUTE_LOCAL_AUDIO,
    payload: {
        muted,
    },
})

export const muteLocalVideo = (muted) => ({
    type: EVENT_STREAM.MUTE_LOCAL_VIDEO,
    payload: {
        muted,
    },
})

export const setAudioDevices = (devices) => ({
    type: EVENT_STREAM.SET_AUDIO_DEVICES,
    payload: {
        devices,
    },
})

export const setVideoDevices = (devices) => ({
    type: EVENT_STREAM.SET_VIDEO_DEVICES,
    payload: {
        devices,
    },
})

export const setJoinInProgress = (joinInProgress) => ({
    type: EVENT_STREAM.SET_JOIN_IN_PROGRESS,
    payload: {
        joinInProgress,
    },
})

export const setCurrentParticipant = ({ id, name, avatar, isAdminSpeaker }) => ({
    type: EVENT_STREAM.SET_CURRENT_PARTICIPANT,
    payload: { id, name, avatar, isAdminSpeaker },
})

export const addParticipant = ({ id, user, media, view, videoMuted, audioMuted }) => ({
    type: EVENT_STREAM.ADD_PARTICIPANT,
    payload: {
        id,
        user,
        media,
        view,
        videoMuted,
        audioMuted,
    },
})

export const setJoined = (joined) => ({
    type: EVENT_STREAM.SET_JOINED,
    payload: {
        joined,
    },
})

export const setMedia = (participantId, media, { videoMuted, audioMuted, loading = true }) => ({
    type: EVENT_STREAM.SET_MEDIA,
    payload: {
        participantId,
        media,
        config: { videoMuted, audioMuted, loading },
    },
})

export const removeParticipant = (participantId, isScreenShare = false, userId) => ({
    type: EVENT_STREAM.REMOVE_PARTICIPANT,
    payload: {
        participantId,
        isScreenShare,
        userId,
    },
})

export const setRemoteVideoMuted = (participantId, isMuted) => ({
    type: EVENT_STREAM.SET_REMOTE_VIDEO_MUTED,
    payload: {
        participantId,
        isMuted,
    },
})

export const setRemoteAudioMuted = (participantId, isMuted) => ({
    type: EVENT_STREAM.SET_REMOTE_AUDIO_MUTED,
    payload: {
        participantId,
        isMuted,
    },
})

export const resetStream = () => ({
    type: EVENT_STREAM.RESET,
})

export const setScreenMedia = (participantId, media, { remove = false } = {}) => ({
    type: EVENT_STREAM.SET_SCREEN_MEDIA,
    payload: {
        participantId,
        media,
        config: { remove },
    },
})

export const setLocalScreenShared = (isShared) => ({
    type: EVENT_STREAM.SET_LOCAL_SCREEN_SHARED,
    payload: {
        isShared,
    },
})

export const setStreamAudioMuted = (isMuted) => ({
    type: EVENT_STREAM.SET_STREAM_AUDIO_MUTED,
    payload: {
        isMuted,
    },
})
