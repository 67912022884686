import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import noPostImage from '../../../../../assets/img/setup/faq.png'

import { parseDateFromServer } from '../../../../common/utils/date-time-utils'

const FROM_FORMAT = 'dd.MM.yyyy'

const EventCard = ({ event, to }) => {
    const { fromDate, name, image, statusName, description, state } = event

    const fromTime = useMemo(() => {
        if (fromDate) {
            const parsed = parseDateFromServer(fromDate)

            return format(parsed, FROM_FORMAT)
        } else {
            return null
        }
    }, [fromDate])

    const cancelledMark = useMemo(
        () => <span className={`status-event-element ${statusName}`}>{statusName}</span>,
        [statusName]
    )
    const stateMark = useMemo(() => <span className={`state-event-element ${state}`}>{state}</span>, [state])
    return (
        <div className="event-item stream">
            <Link to={to} className="image-event-block">
                <img
                    src={
                        image
                            ? image.sizes && image.sizes['370x370']
                                ? image.sizes['370x370']
                                : image.original
                            : noPostImage
                    }
                    alt="Event Image"
                />
            </Link>
            <div className="event-stream-item-description">
                <Link to={to} className="live-event-name">{`${name}`}</Link>
                <p className="event-item-date">
                    {fromTime && <span className="event-item-date-time">{fromTime}</span>}
                    <span className="event-item-date-status">{stateMark}</span>
                    <span className="event-item-date-status">{cancelledMark}</span>
                </p>
                <div className="event-item-description-text-block">
                    <span>{description}</span>
                </div>
            </div>
        </div>
    )
}

export default EventCard
