import React, { useState, Children } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const HeaderSelector = ({ title, selectors, children }) => {
    const [panel, setPanel] = useState(0)
    return (
        <div className="selector-wrapper">
            {title && <h3 className="settings-title">{title}</h3>}
            <div className="selectors">
                {selectors.map((selector, index) => {
                    return (
                        <span
                            key={selector.replace(/\s/gm, '')}
                            onClick={() => setPanel(index)}
                            className={cn('selectors__selector', { 'selectors__selector--active': index === panel })}
                        >
                            {selector}
                        </span>
                    )
                })}
            </div>
            {Children.map(children, (child, index) => index === panel && child)}
        </div>
    )
}

HeaderSelector.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    title: PropTypes.string,
    selectors: PropTypes.array.isRequired,
}

export default HeaderSelector
