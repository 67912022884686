export const GET_FILTERS_REQUESTED = 'GET_FILTERS_REQUESTED'
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS'
export const GET_FILTERS_FAILED = 'GET_FILTERS_FAILED'

export const SET_FILTERS_REQUESTED = 'SET_FILTERS_REQUESTED'
export const SET_FILTERS_SUCCESS = 'SET_FILTERS_SUCCESS'
export const SET_FILTERS_FAILED = 'SET_FILTERS_FAILED'

export const GET_FILTER_OPTIONS_COUNT_REQUESTED = 'GET_FILTER_OPTIONS_COUNT_REQUESTED'
export const GET_FILTER_OPTIONS_COUNT_SUCCESS = 'GET_FILTER_OPTIONS_COUNT_SUCCESS'
export const GET_FILTER_OPTIONS_COUNT_FAILED = 'GET_FILTER_OPTIONS_COUNT_FAILED'

export const getFilters = () => ({
    type: GET_FILTERS_REQUESTED,
})

export const setFilters = (widget_filter, onFinish) => ({
    type: SET_FILTERS_REQUESTED,
    payload: widget_filter,
    onFinish: onFinish,
})

export const getFilterOptionsCount = (type) => ({
    type: GET_FILTER_OPTIONS_COUNT_REQUESTED,
    payload: type,
})
