import React, { useState, useCallback } from 'react'

import { Input, Button, Row, Col, Form } from 'antd'

import { useDispatch, useSelector } from 'react-redux'

import { getUniversityInfo } from '../../../../../redux/selectors'
import { getPlsTapFeedLink } from '../../../../../redux/actions/account/account-actions'

const TapFeedLinkSection = () => {
    const universityInfo = useSelector(getUniversityInfo)
    const { url, slug } = universityInfo

    const [editMode, setEditMode] = useState(false)
    const [tapFeedUrl, setTapFeedUrl] = useState(`${url}/${slug}`)

    const dispatch = useDispatch()

    const editUrl = useCallback(() => {
        setTapFeedUrl((tapFeedUrl) => tapFeedUrl.slice(0, Math.max(0, tapFeedUrl.lastIndexOf('/') + 1)))
        setEditMode(true)
    }, [])

    const onGetPlsTapFeedLinkSuccess = useCallback((slug) => {
        setTapFeedUrl((tapFeedUrl) => `${tapFeedUrl}${slug}`)
        setEditMode(false)
    }, [])

    const saveUrl = useCallback(
        (values) => {
            dispatch(getPlsTapFeedLink({ slug: values.editedUrl }, onGetPlsTapFeedLinkSuccess))
        },
        [dispatch, onGetPlsTapFeedLinkSuccess]
    )

    const cancelEditUrl = useCallback(() => {
        setTapFeedUrl(`${url}/${slug}`)
        setEditMode(false)
    }, [url, slug])

    const normalizeValue = useCallback((val) => {
        return val.trim()
    }, [])

    return (
        <div className="settings-item">
            <label className="settings-name">
                1.Go to the TAP Feed{' '}
                <span className="label-rest-text">
                    (platform you have to promote in order to get prospects) that has been automatically generated:{' '}
                </span>
            </label>
            <div style={{ marginTop: '2rem', marginBottom: '4rem' }}>
                <Row style={{ position: 'absolute', flexFlow: 'unset' }}>
                    <Col style={{ fontSize: '1.6rem', opacity: 0.6, paddingTop: '4px' }}>
                        {!editMode ? (
                            <a className="edit-url-link" href={tapFeedUrl} target="__blank">
                                {tapFeedUrl}
                            </a>
                        ) : (
                            tapFeedUrl
                        )}
                    </Col>
                    {editMode ? (
                        <Form
                            layout="vertical"
                            initialValues={{
                                editedUrl: '',
                            }}
                            onFinish={saveUrl}
                            autoComplete="off"
                            hideRequiredMark
                        >
                            <Form.Item
                                name="editedUrl"
                                label={null}
                                className="form-item"
                                rules={[
                                    { required: true, message: 'Add custom url' },
                                    {
                                        pattern: new RegExp('^[A-Za-z0-9_-]*$'),
                                        message: 'Format is wrong',
                                    },
                                ]}
                                normalize={normalizeValue}
                            >
                                <Input maxLength={50} type="text" />
                            </Form.Item>
                            <div style={{ position: 'absolute', top: 0, right: '-10rem' }}>
                                <Button type="link" htmlType="submit">
                                    Save
                                </Button>
                                <Button type="link" onClick={cancelEditUrl}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    ) : (
                        <>
                            <button className="edit-url-button" onClick={editUrl}>
                                Edit
                            </button>
                        </>
                    )}
                </Row>
            </div>
        </div>
    )
}

export default TapFeedLinkSection
