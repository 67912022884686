import React from 'react'
import { ArrowDown, ArrowTop } from './Icons'
import classnames from 'classnames'

export class ModalLimit extends React.PureComponent {
    state = {
        hourse: 0,
        minutes: 30,
        type: 'week',
    }

    componentDidMount() {
        const oldTimeLimit = this.props.timeLimit

        if (oldTimeLimit) {
            this.setState({
                hourse: Math.floor(oldTimeLimit.limit_minuts / 60),
                minutes: oldTimeLimit.limit_minuts % 60,
                type: oldTimeLimit.limit_type,
            })
        }
    }

    apply = () => {
        this.props.handleChange(Number(this.state.minutes) + Number(this.state.hourse) * 60, this.state.type)
    }

    incMinutes = () => {
        let minutes = this.state.minutes
        if (minutes >= 30) {
            this.setState({
                minutes: 0,
                hourse: this.state.hourse + 1,
            })
        } else {
            this.setState({
                minutes: minutes + 30,
            })
        }
    }

    decMinutes = () => {
        let minutes = this.state.minutes
        if (minutes < 30) {
            if (this.state.hourse > 0) {
                this.setState({
                    minutes: 30,
                    hourse: this.state.hourse - 1,
                })
            }
        } else {
            if (this.state.hourse > 0)
                this.setState({
                    minutes: minutes - 30,
                })
        }
    }

    decHour = () => {
        if (this.state.hourse > 1) {
            this.setState({ hourse: this.state.hourse - 1 })
        }
    }

    changeInput = ({ target }) => {
        if (target.value < 1) target.value = 1
        this.setState({ [target.name]: target.value })
    }

    render() {
        const { handleCancel } = this.props

        return (
            <div class="time-limit-modal">
                <h2 class="modal-title">Set time limits for the Ambassador</h2>

                <div className="time-limit-types">
                    <div
                        onClick={() => this.setState({ type: 'week' })}
                        className={classnames('rp-time-filter-title', { 'active-item': this.state.type == 'week' })}
                    >
                        <span className="radion-btn"></span>
                        <p>per week</p>
                    </div>

                    <div
                        onClick={() => this.setState({ type: 'month' })}
                        className={classnames('rp-time-filter-title', { 'active-item': this.state.type == 'month' })}
                    >
                        <span className="radion-btn"></span>
                        <p>per month</p>
                    </div>
                </div>

                <div class="input-row">
                    <div class="number-time-input">
                        <div>
                            <button
                                class="number-time-btn"
                                onClick={() => this.setState({ hourse: this.state.hourse + 1 })}
                            >
                                <ArrowTop />
                            </button>
                            <input value={this.state.hourse} onChange={this.changeInput} name="hourse" />
                            <button class="number-time-btn" onClick={this.decHour}>
                                <ArrowDown />
                            </button>
                        </div>
                        <p>hours</p>
                    </div>
                    <div class="number-time-input">
                        <div>
                            <button class="number-time-btn" onClick={this.incMinutes}>
                                <ArrowTop />
                            </button>
                            <input value={this.state.minutes} onChange={this.changeInput} name="minutes" />
                            <button class="number-time-btn" onClick={this.decMinutes}>
                                <ArrowDown />
                            </button>
                        </div>
                        <p>minutes</p>
                    </div>
                </div>

                <div class="hidden-buttons-block">
                    <button type="button" class="el-btn mod-close-group" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button type="submit" class="el-btn" onClick={this.apply}>
                        Apply
                    </button>
                </div>
            </div>
        )
    }
}
