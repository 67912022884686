import API from '../../../../api'
import { call, put, select } from 'redux-saga/effects'
import {
    UNIVERSITY_WIDGET_INFO_SUCCESS,
    UNIVERSITY_WIDGET_INFO_FAILED,
    SET_WIDGET_STYLES_SUCCESS,
    SET_WIDGET_STYLES_FAILED,
} from '../../../actions/settings/custom-styles'
import {
    CHANGE_TITLES_FAILED,
    CHANGE_TITLES_SUCCESS,
    GET_TITLES_FAILED,
    GET_TITLES_SUCCESS,
    SOCIAL_PROOF_SUCCESS,
    SOCIAL_PROOF_FAIL,
} from '../../../actions/settings/customTitlesActions'
import { OPEN_ACTION_MODAL } from '../../../actions/components/componentsActions'
import {
    GET_FILTER_OPTIONS_COUNT_FAILED,
    GET_FILTER_OPTIONS_COUNT_SUCCESS,
    GET_FILTERS_FAILED,
    GET_FILTERS_SUCCESS,
    SET_FILTERS_FAILED,
    SET_FILTERS_SUCCESS,
} from '../../../actions/settings/filtersActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId
const getUniversityId = (state) => state.account.account_info.university_id

export function* universityWidget(action) {
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            if (isGroupAdmin && !universityId) {
                return API.settings.getGroupTapSettings()
            } else {
                return API.settings.universityWidgets(universityId)
            }
        })
        if (response.success) {
            yield put({
                type: UNIVERSITY_WIDGET_INFO_SUCCESS,
                payload: {
                    settings: response.data.settings,
                },
            })
        } else {
            yield put({
                type: UNIVERSITY_WIDGET_INFO_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: UNIVERSITY_WIDGET_INFO_FAILED,
        })
    }
}

export function* setUniversityWidget(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            if (isGroupAdmin && !universityId) {
                return API.settings.setGroupTapSettings(payload.settings)
            } else {
                return API.settings.setUniversityWidget(universityId, payload.settings, payload.type)
            }
        })
        if (response && response.success) {
            yield put({
                type: SET_WIDGET_STYLES_SUCCESS,
            })
            if (action.onFinish) action.onFinish()
        } else {
            yield put({
                type: SET_WIDGET_STYLES_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: SET_WIDGET_STYLES_FAILED,
        })
        if (action.onFinish) action.onFinish()
    }
}

export function* changeTAPTitles(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)
        if (universityId) {
            payload.universityId = universityId
        }

        const response = yield call(() => {
            if (isGroupAdmin && !universityId) {
                return API.settings.setGroupTapTitles(payload)
            } else {
                return API.settings.changeTAPTitles(payload)
            }
        })
        if (response && response.success) {
            yield put({
                type: CHANGE_TITLES_SUCCESS,
            })

            if (!action.onFinish) {
                yield put({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: 'Configuration saved',
                    },
                })
            }
            if (action.onSuccess) {
                action.onSuccess()
            }
            if (action.onFinish) action.onFinish()
        } else {
            yield put({
                type: CHANGE_TITLES_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: CHANGE_TITLES_FAILED,
        })
        if (action.onFinish) action.onFinish()
    }
}

export function* getTAPTitles(action) {
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            if (isGroupAdmin && !universityId) {
                return API.settings.getGroupTapTitles()
            } else {
                return API.settings.getTAPTitles(universityId)
            }
        })
        if (response.success) {
            /* For current functionality we don't need it
            yield put({
                type: GET_TITLES_SUCCESS,
                payload: {
                    titles: response.data.titles
                }
            });*/
            const { page_title, page_desc } = response.data.titles
            if (page_title.includes('Chat to our students') && page_desc.includes('Find out what life at')) {
                response.data.titles.page_title = ''
                response.data.titles.page_desc = ''
            }
            action.onSuccess(response.data.titles)
        } else {
            yield put({
                type: GET_TITLES_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_TITLES_FAILED,
        })
    }
}

export function* setFilters(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            if (isGroupAdmin && !universityId) {
                return API.settings.setGroupTapFilters(payload)
            } else {
                return API.settings.setFilters(payload, universityId)
            }
        })
        if (response && response.success) {
            yield put({
                type: SET_FILTERS_SUCCESS,
            })

            if (!action.onFinish) {
                yield put({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: 'Configuration saved',
                    },
                })
            } else {
                action.onFinish()
            }
        } else {
            yield put({
                type: SET_FILTERS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: SET_FILTERS_FAILED,
        })
        if (action.onFinish) action.onFinish()
    }
}

export function* getSocialProofInfo(action) {
    const isGroupAdmin = yield select(getGroupAdmin)
    const universityId = yield select(getUniversityId)

    try {
        const response = yield call(() => {
            if (!isGroupAdmin && universityId) {
                return API.settings.getSocialProofInfo(universityId)
            }
        })
        if (response.success) {
            yield put({
                type: SOCIAL_PROOF_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: SOCIAL_PROOF_FAIL,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: SOCIAL_PROOF_FAIL,
        })
    }
}

export function* getFilters(action) {
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            if (isGroupAdmin && !universityId) {
                return API.settings.getGroupTapFilters()
            } else {
                return API.settings.getFilters(universityId)
            }
        })

        if (response.success) {
            yield put({
                type: GET_FILTERS_SUCCESS,
                payload: response.data,
            })
        } else {
            yield put({
                type: GET_FILTERS_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_FILTERS_FAILED,
        })
    }
}

export function* getFilterOptionsCount(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.settings.getFilterOptionsCount(payload)
        })

        if (response.success) {
            yield put({
                type: GET_FILTER_OPTIONS_COUNT_SUCCESS,
                payload: {
                    [payload]: response.count,
                },
            })
        } else {
            yield put({
                type: GET_FILTER_OPTIONS_COUNT_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: GET_FILTER_OPTIONS_COUNT_FAILED,
        })
    }
}
