import { ACCOUNT_REGISTRATION, GROUP_ACCOUNT_REGISTRATION } from '../redux/actions/account/accountActions'
import store from '../redux/store'
import { ACCOUNT } from '../redux/actions/account/account-types'

const afterWindowOrTabClosed = (interval, invitationData, loginEmail) => {
    clearInterval(interval)
    setTimeout(() => {
        const ssoCode = window.localStorage.getItem('ssoCode')
        if (ssoCode) {
            const data = {
                ssoCode,
            }

            if (invitationData.inviteCode) {
                data.inviteToken = invitationData.inviteToken
                data.inviteCode = invitationData.inviteCode

                store.dispatch({
                    type: invitationData.isGroup ? GROUP_ACCOUNT_REGISTRATION : ACCOUNT_REGISTRATION,
                    payload: data,
                })
            } else if (loginEmail) {
                data.email = loginEmail
                store.dispatch({
                    type: ACCOUNT.SSO_LOGIN,
                    payload: data,
                })
            }
            window.localStorage.removeItem('ssoCode')
        }
    }, 500) // we can't access instantly to storage data after saving it in child window
}

export const openSsoWindow = (appDomain, appClientId, isEncryptionOn, invitationData = {}, loginEmail) => {
    const left = window.innerWidth / 2 - 200
    const top = window.innerHeight / 2 - 250
    if (!appClientId) {
        // show popup
        return false
    }
    const win = window.open(
        !isEncryptionOn
            ? appDomain +
                  'login?client_id=' +
                  appClientId +
                  '&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=' +
                  window.location.origin +
                  '/account/sign-in-redirect'
            : window.location.origin +
                  '/account/sign-in-redirect?ssoClientId=' +
                  appClientId +
                  '&ssoDomain=' +
                  appDomain,
        'TAP',
        'resizable=yes,width=400,height=500,left=' + left + ',top=' + top
    )
    if (win) {
        let leftDomain = false
        const interval = setInterval(() => {
            try {
                if (win.document.domain !== document.domain) {
                    leftDomain = true
                }
                if (leftDomain && win.document.readyState === 'complete' && win.closed) {
                    // we're here when the child window returned to our domain
                    clearInterval(interval)
                    afterWindowOrTabClosed(interval, invitationData, loginEmail)
                }
            } catch {
                // we're here when the child window has been navigated away or closed
                if (win.closed) {
                    afterWindowOrTabClosed(interval, invitationData, loginEmail)
                    return
                }
                // navigated to another domain
                leftDomain = true
            }
        }, 500)

        if (window.focus) win.focus()
        return true
    } else {
        const timer = setInterval(() => {
            afterWindowOrTabClosed(timer, invitationData, loginEmail)
        }, 500)
    }
}

export const isAdminSsoActivated = (withSsoOnly = true) => {
    const { account_info: accountInfo } = store.getState().account
    if (!accountInfo || !accountInfo.university) {
        return false
    }
    let activated = accountInfo.university.is_sso_activated && accountInfo.university.is_admin_allowed
    if (withSsoOnly) {
        activated = activated && accountInfo.university.is_sso_only_activated
    }
    return activated
}
