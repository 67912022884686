import React from 'react';
import { Card, Checkbox } from 'antd';
import { clsx } from 'src/app/helpers/classNames';
import { ReactComponent as IdpCommunity } from 'src/assets/svg/idp-comunity.svg';
import { ReactComponent as IdpConversion } from 'src/assets/svg/idp-conversion.svg';
import { useStepsData } from '../StepsProvider';
import { selectIsIdpActivation } from 'src/app/redux/selectors';
import { useAppSelector } from 'src/app/hooks/store';
export var ServiceCards = function () {
    var isIdpActivation = useAppSelector(selectIsIdpActivation);
    var _a = useStepsData(), isConversionSelected = _a.isConversionSelected, setIsConversionSelected = _a.setIsConversionSelected, setIsCommunitySelected = _a.setIsCommunitySelected, isCommunitySelected = _a.isCommunitySelected, isCommunityActive = _a.isCommunityActive, isConversionAcive = _a.isConversionAcive;
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { className: clsx('card', isConversionSelected ? 'selected' : undefined) },
            React.createElement("div", { className: "card-container" },
                React.createElement(IdpConversion, { className: "pr-2.5" }),
                React.createElement("div", null,
                    React.createElement("p", { className: "card-title font-interface-bold" }, "P2P Conversion - IDP Live app"),
                    React.createElement("div", { className: clsx('card-label', isConversionAcive ? 'active' : undefined) }, isConversionAcive ? 'Active' : 'Pre-activated')),
                React.createElement("p", { className: "card-description" }, "Connect prospective students already matched with an IDP Counselor with your international student ambassadors on the IDP Live app."),
                React.createElement(Checkbox, { className: "card-checkbox", checked: isConversionSelected, onChange: function (e) { return setIsConversionSelected(e.target.checked); }, disabled: !isIdpActivation }))),
        React.createElement(Card, { className: clsx('card', isCommunitySelected ? 'selected' : undefined) },
            React.createElement("div", { className: "card-container" },
                React.createElement(IdpCommunity, { className: "pr-2.5" }),
                React.createElement("div", null,
                    React.createElement("p", { className: "card-title font-interface-bold" }, "P2P Community"),
                    React.createElement("div", { className: clsx('card-label', isCommunityActive ? 'active' : undefined) }, isCommunityActive ? 'Active' : 'Pre-activated')),
                React.createElement("p", { className: "card-description" }, "Showcase content created by your international student ambassadors across the IDP websites to millions of future students, and boost your reach."),
                React.createElement(Checkbox, { className: "card-checkbox", checked: isCommunitySelected, onChange: function (e) { return setIsCommunitySelected(e.target.checked); }, disabled: !isIdpActivation })))));
};
