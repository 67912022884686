import { CLOSE_CREATE_QUESTION_POPUP, OPEN_CREATE_QUESTION_POPUP } from '../../../actions/faq/componentsActions'

import { CREATE_QUESTION_MODAL } from '../../../store/initialState'

const initialState = CREATE_QUESTION_MODAL

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case CLOSE_CREATE_QUESTION_POPUP: {
            return initialState
        }

        case OPEN_CREATE_QUESTION_POPUP: {
            return {
                ...state,
                isOpened: true,
                ...payload,
            }
        }

        default: {
            return state
        }
    }
}
