import { all, call, takeEvery, select, put } from 'redux-saga/effects'

import API from '../../../api'
import { LIVE_EVENTS } from '../../actions/live-event/live-event-action-types'
import { fetchLiveEvents } from '../../actions/live-event/live-events-actions'
import { openNotificationModal } from '../../actions/notification-modal/notification-modal-actions'
import { getIsGroupAdmin, getCurrentGroupUniversityId, getLiveEventsSlice } from '../../selectors'

function* createLiveEventSaga({ payload }) {
    const { currentEventStatus } = yield select(getLiveEventsSlice)
    const isGroupAdmin = yield select(getIsGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        payload.universityId = currentGroupUniversityId || payload.university_id
    }
    const response = yield call(() => {
        return API.liveEvent.createEvent(payload)
    })

    if (response.success) {
        yield put({
            type: LIVE_EVENTS.CREATE_SUCCESS,
            payload: {},
        })

        yield put(
            fetchLiveEvents({
                status: currentEventStatus,
            })
        )

        yield put(openNotificationModal(LIVE_EVENTS.CREATE_SUCCESS))
    } else {
        yield put({
            type: LIVE_EVENTS.CREATE_ERROR,
        })
    }
}

function* watchLiveEventCreateSaga() {
    yield takeEvery(LIVE_EVENTS.CREATE, createLiveEventSaga)
}

function* liveEventCreateSaga() {
    yield all([call(watchLiveEventCreateSaga)])
}

export default liveEventCreateSaga
