import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import Logo from '../../../../assets/svg/logo.svg'

const MaintenanceScreen = (props) => {
    const account = useSelector((state) => state.account)
    const { account_info: accountInfo, isMaintenanceMode } = account
    const { push } = props.history

    if (accountInfo && !isMaintenanceMode) {
        push('/')
    } else if (!accountInfo && !isMaintenanceMode) {
        push('/account/login')
    }

    return (
        <div id="page-wrapper" className="page-wrapper unregistered-page">
            <div id="page-body" className="page-body js-index">
                <div className="maintenance-container">
                    <div className="maintenance-logo">
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className="maintenance-description">
                        <h2 className="maintenance-description-title">We'll be back soon</h2>
                        <p className="maintenance-description-text">
                            The Ambassador Platform is down for scheduled maintenance and expect to be back online soon.
                        </p>
                        <p>You can always reach out to our customer support team</p>
                        <p className="maintenance-description-email">support@theambassadorplatform.com</p>
                    </div>
                    <div className="maintenance-description-footer">The Ambassador Platform Limited</div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MaintenanceScreen)
