import { Button, Input }  from 'antd';
import React, { useRef } from 'react';

const ApiKeyBlock = ({ data, generateNewApiKey, notifyClipboardCopy }) => {
    const isEmptyKey = (data === "");

    const textInputRef = useRef(null);

    const buttonClickHandler = () => {
        if (isEmptyKey) {
            generateNewApiKey();
        } else {
            textInputRef.current.select();
            document.execCommand('copy');

            notifyClipboardCopy();
        }
    };

    return (
        <div className="api-key-block-container">
            <Input
                value={data}
                placeholder="API key should be generated"
                size="large"
                maxLength={50}
                style={{ width: "300px" }}
                disabled={isEmptyKey}
                ref={textInputRef}
            />
            <Button
                type="link"
                className="settings-btn"
                onClick={buttonClickHandler}
            >
                { isEmptyKey ? "Generate" : "Copy" }
            </Button>
        </div>
    )
}

export default ApiKeyBlock;
