import {
    parseISO,
    endOfDay,
    sub,
    format,
    startOfDay,
    isToday,
    isTomorrow,
    isYesterday,
    isThisWeek,
    isSameWeek,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

const TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm:ss"
const TABLE_DATE_FORMAT = 'dd.MM.yyyy'

const getUTCDate = (dateString = new Date()) => {
    const date = new Date(dateString)

    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    )
}

export const parseDateFromServer = (date) => {
    return parseISO(date)
}

export const parseUtcToTimezone = (date, timezone) => {
    return utcToZonedTime(date, timezone)
}

export const formatToISO = (date) => format(date, TIMESTAMP_FORMAT)

export const formatTableDate = (dateString) => {
    if (!dateString) {
        return ''
    }
    const date = new Date(dateString)

    return format(date, TABLE_DATE_FORMAT)
}

export const getDaysRange = (days) => {
    const toDate = endOfDay(getUTCDate())

    const fromDate = startOfDay(
        sub(getUTCDate(), {
            days,
        })
    )

    return {
        fromDate,
        toDate,
    }
}

export const formatLastActivity = (lastActivity) => {
    const activityDate = new Date(lastActivity)
    const currentDate = new Date()

    if (isToday(activityDate)) {
        return format(activityDate, 'H:mm')
    } else if (isTomorrow(activityDate)) {
        return 'Tomorrow'
    } else if (isYesterday(activityDate)) {
        return `Yesterday`
    } else if (isThisWeek(activityDate, { weekStartsOn: 1 })) {
        return format(activityDate, 'EEEE')
    } else if (isSameWeek(activityDate, currentDate, { weekStartsOn: 1 })) {
        return format(activityDate, 'dd MMMM')
    } else {
        return format(activityDate, 'dd MMMM')
    }
}
