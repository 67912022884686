import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card, Row, Col, Tooltip } from 'antd'

import store from '../../../redux/store'

import DatesIntervalSelector from '../components/DatesIntervalSelector'
import AreaChart from '../components/AreaChart'
import ChartHeader from '../components/ChartHeader'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import {
    fetchActivityChartsData,
    fetchActivityTableData,
    fetchActivityCsvData,
} from '../../../redux/actions/reporting/activityActions'

import {
    prepareSpecificNumberData,
    numberTooltipFormatter,
    yAxisConversionChartFormatter,
    pluralize,
} from '../../../helpers/chart'

import { getDateRange } from '../../../helpers/time'

import { isIdpRedirect } from 'src/app/helpers/idpRedirect'
import { HomeRedirect } from 'src/app/containers/_layout/nav/components/HomeRedirect'

class ReportingActivity extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedButton: '30Days',
            selectedDateRange: getDateRange(30),
            selectedChart: 'new_users',
            searchString: '',
            searchColumn: 'source',
            selectedActivities: [],
        }

        this.searchCriteriaOptions = [
            {
                key: 'source',
                name: 'Source',
            },
            {
                key: 'medium',
                name: 'Medium',
            },
            {
                key: 'campaign',
                name: 'Campaign',
            },
        ]

        this.handleDatesIntervalSelectorUpdate = this.handleDatesIntervalSelectorUpdate.bind(this)
        this.handleChartSelection = this.handleChartSelection.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleSearchCriteria = this.handleSearchCriteria.bind(this)
        this.handleProspectsSelectionChange = this.handleProspectsSelectionChange.bind(this)
        this.handleClickExportSelected = this.handleClickExportSelected.bind(this)
        this.handleClickExportAll = this.handleClickExportAll.bind(this)
        this.handleClickCreateCampaign = this.handleClickCreateCampaign.bind(this)
        this.selectedChartTooltipFormatter = this.selectedChartTooltipFormatter.bind(this)
    }

    componentDidMount() {
        const { account } = this.props
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'reporting',
            },
        })

        if (
            !account.account_info.universityGroup ||
            (account.account_info.universityGroup && account.account_info.universityGroup.tap_page_url)
        ) {
            this.props.fetchActivityChartsData(this.state.selectedDateRange)
            // this.props.fetchActivityTableData(this.state.selectedDateRange);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.props.fetchActivityChartsData(this.state.selectedDateRange)
            // this.props.fetchActivityTableData(this.state.selectedDateRange);
        }
    }

    generateDatesSelector() {
        return (
            <DatesIntervalSelector
                selectedButton={this.state.selectedButton}
                fromDate={this.state.selectedDateRange.fromDate}
                toDate={this.state.selectedDateRange.toDate}
                onChange={this.handleDatesIntervalSelectorUpdate}
                restrictBeforeDate={new Date(2020, 7, 17)}
            />
        )
    }

    handleDatesIntervalSelectorUpdate(selectedButton, selectedDateRange) {
        this.setState({ selectedButton, selectedDateRange })

        this.props.fetchActivityChartsData(selectedDateRange)
        // this.props.fetchActivityTableData(selectedDateRange);
    }

    handleChartSelection(key) {
        this.setState({ selectedChart: key })
    }

    prepareSelectedChartData() {
        return prepareSpecificNumberData(this.props.charts.mainChart, this.state.selectedChart, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    pageNewUserChartTooltip() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'new user'))
    }

    conversationChartTooltip() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'account') + ' created')
    }

    conversionChartTooltip() {
        return numberTooltipFormatter(this.point.x, this.point.y, '%')
    }

    selectedChartTooltipFormatter() {
        if (this.state.selectedChart === 'new_users') {
            return this.pageNewUserChartTooltip
        } else if (this.state.selectedChart === 'accounts_created') {
            return this.conversationChartTooltip
        } else {
            return this.conversionChartTooltip
        }
    }

    selectedChartGraphTotalValue() {
        if (!this.props.charts.mainChart) {
            return ''
        }

        // eslint-disable-next-line unicorn/no-array-reduce
        const totalValues = this.props.charts.mainChart.reduce((previousValue, currentValue) => {
            return {
                new_users: previousValue.new_users + currentValue.new_users,
                accounts_created: previousValue.accounts_created + currentValue.accounts_created,
            }
        })

        let totalConversion

        if (totalValues.new_users < totalValues.accounts_created) {
            totalConversion = '100.00'
        } else if (!totalValues.new_users) {
            totalConversion = '0.00'
        } else {
            totalConversion = ((totalValues.accounts_created / totalValues.new_users) * 100).toFixed(2)
        }

        if (this.state.selectedChart === 'new_users') {
            return totalValues.new_users
        } else if (this.state.selectedChart === 'accounts_created') {
            return totalValues.accounts_created
        } else {
            return totalConversion + ' %'
        }
    }

    yAxisResponseTimeChart() {
        return yAxisConversionChartFormatter(this.value)
    }

    handleSearch(searchString) {
        this.setState({ searchString })
    }

    handleSearchCriteria(searchColumn) {
        this.setState({ searchColumn })
    }

    getSelectedActivitiesObjects(selectedActivities) {
        const result = selectedActivities.map((activityId) => {
            const fieldValues = activityId.split('|')
            if (fieldValues.length < 3) {
                return {}
            }

            return {
                source: fieldValues[0],
                medium: fieldValues[1],
                campaign: fieldValues[2],
            }
        })

        return result
    }

    handleProspectsSelectionChange(selectedActivities, doNotFetch) {
        this.setState({ selectedActivities })

        if (doNotFetch) {
            return
        }

        this.props.fetchActivityChartsData(
            this.state.selectedDateRange,
            this.getSelectedActivitiesObjects(selectedActivities)
        )
    }

    handleClickExportSelected() {
        this.props.fetchActivityCsvData(
            this.state.selectedDateRange,
            this.getSelectedActivitiesObjects(this.state.selectedActivities)
        )
    }

    handleClickExportAll() {
        this.props.fetchActivityCsvData(this.state.selectedDateRange)
    }

    handleClickCreateCampaign() {
        const path = '/promotion/pop-card'
        this.props.history.push(path)
    }

    generateSelectedNumber() {
        if (!this.props.table.activityData) {
            return ''
        }

        if (
            this.state.selectedActivities.length > 0 &&
            this.props.table.activityData.length === this.state.selectedActivities.length
        ) {
            return ' (All)'
        }

        return ' (' + this.state.selectedActivities.length + ')'
    }

    render() {
        const { account } = this.props
        const selectedChartData = this.prepareSelectedChartData()
        const totalValue = this.selectedChartGraphTotalValue()

        if (isIdpRedirect(account.account_info)) {
            return <HomeRedirect />
        }

        return (
            <>
                {account?.account_info?.universityGroup &&
                !account.account_info.universityGroup?.tap_page_url &&
                !account.currentGroupUniversityId ? (
                    <section className="content reporting activity-reporting">
                        <Card className="reporting-block home-start-guide">
                            <div className="prospect-table-title">Activity Report</div>
                            <p>
                                This group does not have a group tap feed so you can’t see an activity report. Go to
                                individual tap feeds in order to see activity report data for these tap feeds
                            </p>
                        </Card>
                    </section>
                ) : (
                    <section className="content reporting activity-reporting">
                        <Card className="reporting-block home-start-guide">
                            <div className="prospect-table-title">Activity Report</div>
                            <p>
                                This report provides an overview of the total traffic to your TAP Feed, the number of
                                prospect accounts created, and the conversion to prospect sign up rate. You can read
                                more about this in our{' '}
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href="https://knowledge.theambassadorplatform.com/what-is-the-activity-report"
                                >
                                    help article
                                </a>
                                .
                            </p>
                        </Card>
                        <Card className="reporting-block" style={{ marginTop: 16 }}>
                            <Row className="active-reporting-drop-dates-block" gutter={[16, 16]}>
                                <Col>
                                    <Tooltip
                                        title={
                                            "'new user' is a visitor who, according to Google's tracking snippet, has never been to your site before and is initiating their first session on your site."
                                        }
                                    >
                                        <span
                                            onClick={() => this.handleChartSelection('new_users')}
                                            className={
                                                'chart-button' +
                                                (this.state.selectedChart === 'new_users'
                                                    ? ' selected-chart-button'
                                                    : '')
                                            }
                                        >
                                            New users on the TAP Feed
                                        </span>
                                    </Tooltip>
                                    <span
                                        onClick={() => this.handleChartSelection('accounts_created')}
                                        className={
                                            'chart-button' +
                                            (this.state.selectedChart === 'accounts_created'
                                                ? ' selected-chart-button'
                                                : '')
                                        }
                                    >
                                        TAP Feed accounts created
                                    </span>
                                    <span
                                        onClick={() => this.handleChartSelection('conversion')}
                                        className={
                                            'chart-button' +
                                            (this.state.selectedChart === 'conversion' ? ' selected-chart-button' : '')
                                        }
                                    >
                                        Conversion
                                    </span>
                                </Col>
                                <Col style={{ display: 'flex' }}>{this.generateDatesSelector()}</Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="chart-title-header-block">
                                        <span className="chart-title-header">
                                            {this.state.selectedChart === 'new_users'
                                                ? 'New users on the TAP Feed'
                                                : this.state.selectedChart === 'accounts_created'
                                                ? 'TAP Feed accounts created'
                                                : 'Conversion'}
                                        </span>
                                        <ChartHeader
                                            label={'Total: ' + totalValue}
                                            labelDescription="Across date range"
                                            displaySingleLabel={true}
                                            className="m-left-auto"
                                        />
                                    </div>
                                    <AreaChart
                                        color="#ff5100"
                                        data={selectedChartData}
                                        tooltipFormatter={this.selectedChartTooltipFormatter()}
                                        height={300}
                                        yAxisFormatter={
                                            this.state.selectedChart === 'conversion'
                                                ? this.yAxisResponseTimeChart
                                                : null
                                        }
                                    />
                                </Col>
                            </Row>
                        </Card>
                        {/* <Card className="reporting-block" style={{ marginTop: 16 }}>
                            <Spin spinning={this.props.isTableLoading} indicator={<LoadingOutlined style={{ fontSize: 50, color: "#ff5100" }} />}>
                                <div className="search-wrapper">
                                    <div className="search-wrapper">
                                        <Search
                                            onChange={this.handleSearch}
                                            placeholder={"Search for campaign"}
                                            width={240}
                                        />
                                        <SearchCriteria
                                            onChange={this.handleSearchCriteria}
                                            placeholder={"Select search filter"}
                                            value={this.state.searchColumn}
                                            options={this.searchCriteriaOptions}
                                            styles={{ width: 180, marginLeft: 8 }}
                                        />
                                    </div>
                                    <div className="search-wrapper">
                                        <Button
                                            className="normal-action-button"
                                            onClick={this.handleClickCreateCampaign}
                                        >
                                            Create Campaign
                                        </Button>
                                        <ExportButtons
                                            onClickSelected={this.handleClickExportSelected}
                                            showExportSelected={!this.state.selectedActivities.length}
                                            onClickAll={this.handleClickExportAll}
                                            showExportAll={this.props.table.activityData && !this.props.table.activityData.length}
                                            filePath={this.props.csvUrl}
                                            selectedNumber={selectedNumberLabel}
                                        />
                                    </div>
                                </div>
                                <ActivityTable
                                    data={this.props.table}
                                    onSelectionChange={this.handleProspectsSelectionChange}
                                    searchString={this.state.searchString}
                                    searchColumn={this.state.searchColumn}
                                />
                            </Spin>
                        </Card> */}
                    </section>
                )}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    charts: state.activity.charts,
    table: state.activity.table,
    isTableLoading: state.activity.isTableLoading,
    csvUrl: state.activity.csvUrl,
})

export default connect(mapStateToProps, {
    fetchActivityChartsData,
    fetchActivityTableData,
    fetchActivityCsvData,
})(withRouter(ReportingActivity))
