import React from 'react'
import { Popover } from 'antd'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

const ReportingPopover = (props) => {
    return (
        <>
            <Popover
                placement="bottomRight"
                overlayStyle={{ width: 320 }}
                trigger="hover"
                className="options-icon-drop"
                content={<PopupContent {...props} />}
            >
                <div className="chat-reported-button">Conversation was reported</div>
            </Popover>
        </>
    )
}

const PopupContent = (props) => {
    const { chat } = props
    const userType = chat.user_reported && chat.reported ? 'prospect' : 'ambassador'
    const role = chat.user_reported && chat.reported ? 'Prospect' : 'University student'

    if (chat.last_message) {
        return (
            <div className="popover-content-block">
                <p className="popover-text">
                    Reported <span className="blocker-name">by {chat[userType].name ? chat[userType].name : ''}</span>
                    &nbsp;
                    {role}:
                </p>
                <p className="popover-text reason">{chat.reported_reason ? chat.reported_reason : ''}</p>
                <p className="popover-text created-at">
                    {dayjs(chat.last_message.created_at).calendar(null, {
                        sameDay: 'H:mm',
                        nextDay: '[Tomorrow]',
                        nextWeek: '[Next] dddd',
                        lastDay: '[Yesterday at ]H:mm',
                        lastWeek: 'DD MMMM',
                        sameElse: 'DD MMMM',
                    })}
                </p>
            </div>
        )
    }
    return <div className="popover-content-block">No Data</div>
}

export default ReportingPopover
