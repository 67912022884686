import React, { PureComponent } from 'react'
import Notifications from 'react-notification-system-redux'
import { connect } from 'react-redux'

import { RestrictedModal } from '../../../components/modals/RestrictedModal'
import { isAllowed, checkIfAnyTapUrlAvailable, getAnyTapUrl, getCommonRoutePath } from '../../../helpers/helpers'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import store from '../../../redux/store'
import config from '../../../system/config'
import Select from 'antd/es/select'
import { Typography } from 'antd'
import { ProtectedNavLink } from 'src/app/components/protected-nav-link'
import { LicensesEnum } from 'src/types/general'
import { Protect } from 'src/app/permissions/Protect'

const { Text } = Typography

const PRESS_ENTER = 13
const PRESS_BACKSPACE = 8

class TrackedLink extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            tags: [],
            tagsInput: '',
            campaign: '',
            source: '',
            ambassadorsAvatars: [],
            isAvatars: false,
            ambassadors: [],
            title: '',
            description: '',
            searchType: 'default',
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    componentDidMount() {
        /* No need in request below
    store.dispatch({
      type: USERS_INFO_REQUESTED,
      payload: {
        filter: {
          university_id: this.props.account.account_info.university_id
        },
        limit: this.props.users.limit,
        offset: this.props.users.offset
      }
    }); */

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'promotion',
            },
        })
    }

    /* handleCopyIframeCode() {
        var copyText = $('.university-access-code')
        copyText.select()
        document.execCommand('copy')

        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'Pop card embed code copied to clipboard',
            },
        })
    } */

    handleChange({ target }) {
        const { value, name } = target
        this.setState((state) => ({
            ...state,
            [name]: value,
        }))
    }

    handleKeyUp(e) {
        const key = e.keyCode

        if (key === PRESS_ENTER) {
            this.addNewTag()
        }
    }

    handleKeyDown(e) {
        const key = e.keyCode
        if (key === PRESS_BACKSPACE && !this.state.tagsInput) {
            this.editPreviousTag()
        }
    }

    addNewTag() {
        const { tags, tagsInput } = this.state
        const tag = tagsInput.trim()

        if (!tag) {
            return
        }
        if (tags.length === 10) {
            store.dispatch(
                Notifications.error({
                    title: 'Error',
                    message: "You can't add more than 10 terms.",
                    position: 'tr',
                    action: '',
                })
            )
            return
        }

        this.setState({
            tags: [...tags, tag],
            tagsInput: '',
        })
    }

    editPreviousTag() {
        const { tags } = this.state

        const tag = tags.pop()

        this.setState({ tags, tagsInput: tag })
    }

    static userAvatar({ avatar, name, id }, avatarSize = '') {
        if (avatar) {
            const avatarSrc = avatar.original
            return <img className="table-item-img" src={avatarSrc} alt="img" />
        } else {
            return (
                <div
                    className={`table-item-img table-item-img-name ${avatarSize === '' && 'table-item-img-name--big'}`}
                >
                    {name[0]}
                </div>
            )
        }
    }

    shuffle(array) {
        return [...array].sort(() => Math.random() - 0.5)
    }

    getAvatars(array) {
        const ambassadors = this.shuffle(array)
            .filter(({ avatar, permissions }) => !!avatar && permissions.chat.is_enabled)
            .slice(0, 4)
        return {
            ambassadors,
            ambassadorsAvatars: ambassadors.map(({ avatar }) => avatar.sizes['370x370']),
        }
    }

    onDropChange = (val) => {
        this.setState({
            searchType: val,
        })
    }

    render() {
        const { tags, tagsInput, campaign, source, isAvatars, title, description, searchType } = this.state
        const { users, account } = this.props

        if (!isAvatars) {
            const { ambassadors, ambassadorsAvatars } = this.getAvatars(users.items)
            if (ambassadors.length > 0) {
                this.setState((state) => ({
                    ...state,
                    isAvatars: true,
                    ambassadors,
                    ambassadorsAvatars,
                }))
            }
        }

        const urlTags = tags.map((tag) => encodeURIComponent(tag.replace(/"|'/gm, ''))).join('&searchTerms=')

        /* let joinedTags = tags.map(tag => {
      return "'" + tag.replace(/\"|\'/gm, '') + "'";
    }); */

        const friendlyTags = tags.map((tag) => tag.replace(/"|'/gm, '')).join(', ')

        let iframePath =
            config.tap_page_url +
            (account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? 'group/' + account.account_info.universityGroup.id
                : account.currentGroupUniversityId || account.account_info.university.id)
        const params = `&utm_medium=link${urlTags ? '&searchTerms=' + urlTags : ''}${
            source ? '&utm_source=' + encodeURIComponent(source) : ''
        }${campaign ? '&utm_campaign=' + encodeURIComponent(campaign) : ''}${
            title ? '&pageTitle=' + encodeURIComponent(title) + '' : ''
        }${description ? '&pageDescription=' + encodeURIComponent(description) + '' : ''}${'&searchType=' + searchType}`
        iframePath += '?tap-dashboard=true' + params
        const randomKey = Math.random()

        return (
            <Protect>
                {checkIfAnyTapUrlAvailable() ? (
                    <div id="page-body" className="page-body js-index">
                        <section className="content settings modal-restricted-area" id="modal-restricted-area">
                            <h3 className="settings-title">Tracked link</h3>

                            <div className="settings-grid has-tutorial">
                                <div className="column">
                                    <div className="tutorial-intro">
                                        <p>
                                            You can place tracked links on social media or elsewhere to link to your TAP
                                            Feed and track the source of your prospects. Configure your tracked link
                                            using the settings below, then paste the link into a campaign of your
                                            choice, for example an email or social post. There is no limit to the number
                                            of tracked links that you can set up, and you can configure each one
                                            differently.
                                        </p>
                                    </div>

                                    <hr className="settings-hr"></hr>

                                    <div className="settings-item tap-page-embed">
                                        <Text style={{ whiteSpace: 'pre-line' }}>
                                            {`Before setting up your tracked link, please, make sure that the ambassadors you would like to display meet the following requirements:
                                                - have relevant tag(s) set
                                                - have the Chat function enabled`}
                                        </Text>
                                        <h4 className="settings-name">Select search filters</h4>
                                        <p>Please choose the search filters that your Tracked Link should link to.</p>
                                        <p>
                                            Please add search filters that will pre-filter your Tracked Link. Remember
                                            that the page will be filtered by all of these search terms eg. if you
                                            search &apos;International&apos; and &apos;Business&apos; results will be
                                            shown for content types with both of those search terms.
                                        </p>
                                        <p>
                                            Press
                                            <code>enter</code>
                                            to add a term. Press <code>backspace</code>
                                            to edit the last term.
                                        </p>
                                        <div className="tagger">
                                            <div className="tags">
                                                <ul>
                                                    {tags.map((tag, i) => (
                                                        <li key={tag + i} className="tag">
                                                            {tag}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <input
                                                    type="text"
                                                    placeholder="Add a search filter.."
                                                    name="tagsInput"
                                                    value={tagsInput}
                                                    onChange={this.handleChange}
                                                    className="tag-input"
                                                    onKeyUp={this.handleKeyUp}
                                                    onKeyDown={this.handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {tags && tags.length > 0 && (
                                        <div className="settings-item tap-page-embed">
                                            <h4 className="settings-name"></h4>
                                            <Select
                                                value={searchType}
                                                onChange={(value) => this.onDropChange(value)}
                                                size="large"
                                                style={{ width: 'calc(80% + 2rem + 2px)' }}
                                            >
                                                <Select.Option value="default">
                                                    Show content filtered by term (may include ambassadors, content,
                                                    faq)
                                                </Select.Option>
                                                <Select.Option value="userDependent">
                                                    Show ambassadors filtered by term and all content created by them
                                                </Select.Option>
                                            </Select>
                                        </div>
                                    )}

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">
                                            Add a UTM source tag
                                            <span>(optional)</span>
                                        </h4>
                                        <p>
                                            Add a source (for example, &apos;website&apos;) that you would like to see
                                            in tracking data
                                        </p>
                                        <input
                                            className="promotion-input"
                                            placeholder="Add a UTM source tag..."
                                            name="source"
                                            value={source}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">
                                            Add a UTM campaign tag
                                            <span>(optional)</span>
                                        </h4>
                                        <p>
                                            Add a campaign (for example, &apos;Open Day&apos;) that you would like to
                                            see in tracking data. This is so that you can track individual campaigns
                                        </p>
                                        <input
                                            className="promotion-input"
                                            placeholder="Add a UTM campaign tag..."
                                            name="campaign"
                                            value={campaign}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Write your page title</h4>
                                        <p>
                                            This optional title will be shown on the TAP Feed that opens when a user
                                            clicks on this link
                                        </p>
                                        <input
                                            className="promotion-input"
                                            placeholder="Chat to our international students"
                                            name="title"
                                            value={title}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Write your page description</h4>
                                        <p>
                                            This optional description will be shown on the TAP Feed that opens when a
                                            user clicks on this link
                                        </p>
                                        <input
                                            className="promotion-input"
                                            name="description"
                                            value={description}
                                            onChange={this.handleChange}
                                            type="text"
                                        />
                                    </div>
                                </div>

                                <div className="column resources">
                                    <h3 className="video-title">Video tutorial: Creating tracked links</h3>
                                    <div className="video-wrapper">
                                        <iframe
                                            src="https://player.vimeo.com/video/757181430?h=c117a62d57&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
                                            width="640"
                                            height="373"
                                            frameBorder="0"
                                            allow="autoplay; fullscreen"
                                            allowFullScreen="allowFullScreen"
                                        ></iframe>
                                    </div>

                                    <div className="resources-box">
                                        <h3>Helpful resources</h3>
                                        <ul>
                                            <li>
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="https://knowledge.theambassadorplatform.com/how-to-use-the-promotion-feature"
                                                    target="_blank"
                                                >
                                                    How to use the Promotion feature
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="column spacer"></div>
                            </div>

                            <div className="settings-grid tap-page-widget">
                                <div className="column">
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Summary</h4>
                                        <p>Тhese are the settings for your tracked link</p>
                                        <table className="promotion-table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Search filters
                                                        {friendlyTags ? <small>{tags.length} selected</small> : null}
                                                    </td>
                                                    <td>{friendlyTags || <span>None selected</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>UTM source tag</td>
                                                    <td>
                                                        {source ? <code>{source}</code> : <span>None selected</span>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>UTM campaign tag</td>
                                                    <td>
                                                        {campaign ? (
                                                            <code>{campaign}</code>
                                                        ) : (
                                                            <span>None selected</span>
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        UTM medium tag
                                                        <small>Not editable</small>
                                                    </td>
                                                    <td>
                                                        <code>link</code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        TAP Feed link
                                                        <small>Detected automatically</small>
                                                    </td>
                                                    <td>
                                                        <code>{getAnyTapUrl()}</code>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Page title</td>
                                                    <td>{title ? <code>{title}</code> : <span>None selected</span>}</td>
                                                </tr>
                                                <tr>
                                                    <td>Page description</td>
                                                    <td>
                                                        {description ? (
                                                            <code>{description}</code>
                                                        ) : (
                                                            <span>None selected</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Hyperlink</h4>
                                        <p>
                                            Тhis is your tracked link. Copy and paste this directly to social media or
                                            as the
                                            <code>href</code>
                                            of links that you want to track
                                        </p>

                                        <div className="legal-text">{`${
                                            getAnyTapUrl() + `?tap-dashboard=false` + params
                                        }`}</div>
                                    </div>

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Widget example</h4>
                                        <p>This is an example of how your TAP Feed, that is filtered, will look like</p>
                                        <iframe
                                            key={randomKey}
                                            name="tap-dashboard-iframe"
                                            id="tap-iframe-widget"
                                            src={iframePath}
                                            width="100%"
                                            height="700"
                                            style={{ border: 'none' }}
                                            scrolling="yes"
                                            data-type="widget"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                ) : (
                    <div id="page-body" className="page-body js-index">
                        <section className="content settings modal-restricted-area" id="modal-restricted-area">
                            <h3 className="settings-title">Unavailable</h3>
                            <div className="settings-intro">
                                <p>
                                    You must set up your{' '}
                                    <ProtectedNavLink
                                        exact
                                        to={getCommonRoutePath() + '/settings/tap-page'}
                                        licenseName={LicensesEnum.TapFeed}
                                    >
                                        TAP Feed
                                    </ProtectedNavLink>{' '}
                                    in order to use Promotion
                                </p>
                            </div>
                        </section>
                    </div>
                )}
                {!isAllowed('promotion') && <RestrictedModal closable={false} />}
            </Protect>
        )
    }
}

export default connect((state) => {
    return { account: state.account, users: state.users }
})(TrackedLink)
