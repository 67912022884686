import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import userImage from '../../../../assets/img/placeholder-user.png'
import noAnswersImage from '../../../../assets/img/no-faqs-answers.png'
import { Editor, ContentState, EditorState, CompositeDecorator, convertFromRaw } from 'draft-js'
import { checkTypeObject } from '../../../helpers/faqHelper'
import { Image } from 'antd'

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity()
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
}
const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData()
    return (
        <a href={url} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    )
}

class RecentAnswersBlock extends PureComponent {
    constructor(props) {
        super()

        this.decorator = new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: Link,
            },
        ])
    }

    goToFaq = (item) => {
        const { account } = this.props
        console.log(item.faq_section)
        window
            .open(
                window.location.origin +
                    '/#/faq/' +
                    (item.faq_section === 'published' ? 'library' : 'new-answers') +
                    '?scrollFaqId=' +
                    item.faq_id +
                    (account.currentGroupUniversityId ? '&universityId=' + account.currentGroupUniversityId : ''),
                '_blank'
            )
            .focus()
    }

    render() {
        const { recentFaqAnswers, total, today, sevenDaysAgo } = this.props

        return (
            <div
                className={
                    'recent-answers-block' +
                    (!recentFaqAnswers || recentFaqAnswers.length === 0 ? '' : ' recent-answers-block-not-empty')
                }
            >
                {!recentFaqAnswers || recentFaqAnswers.length === 0 ? (
                    <>
                        <p className="recent-answers-title">
                            <b>Recent answers on FAQs</b>
                            <span>0 answers</span>
                        </p>
                        <div className="no-answers-image">
                            <Image src={noAnswersImage} alt="" />
                        </div>
                    </>
                ) : (
                    <div className="recent-answers-block-with-data">
                        <div className="recent-answers-title-block">
                            <p className="recent-answers-title">
                                <b>Recent FAQ answers</b>
                                <span className="recent-answers-number">+ {total}</span>
                            </p>
                            <p className="recent-posts-answers-range">Last 7 days ({sevenDaysAgo + ' - ' + today} )</p>
                        </div>
                        <div className="recent-answers-list scroll-8">
                            {recentFaqAnswers.map((item) => {
                                const content = checkTypeObject(item.answer)
                                    ? EditorState.createWithContent(
                                          convertFromRaw(JSON.parse(item.answer)),
                                          this.decorator
                                      )
                                    : EditorState.createWithContent(
                                          ContentState.createFromText(item.answer),
                                          this.decorator
                                      )
                                return (
                                    <div key={item.answer_id} className="recent-answer-item">
                                        <b className="recent-answer-item-question" onClick={() => this.goToFaq(item)}>
                                            {item.question}
                                        </b>
                                        <div className="recent-answer-item-data">
                                            <Image
                                                src={
                                                    item.user && item.user.avatar
                                                        ? item.user.avatar.sizes['70x70'] || userImage
                                                        : userImage
                                                }
                                                alt=""
                                            />
                                            <span
                                                className="recent-answer-text-block"
                                                onClick={() => this.goToFaq(item)}
                                            >
                                                <Editor
                                                    readOnly={true}
                                                    editorState={content}
                                                    classname="question-answer-edit-field answer__body__content"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default connect((state) => {
    return { account: state.account }
})(RecentAnswersBlock)
