import React from 'react';
import { INVITE_AMBASSADORS_TYPES } from '../types';
import { TapAndIdpInviteTemplate } from './inviteTemplates/TapAndIdpTemplate';
import { TapInviteTemplate } from './inviteTemplates/TapTemplate';
import { IdpInviteTemplate } from './inviteTemplates/IdpTemplate';
export var AmbassadorsInviteTemplate = function (_a) {
    var usniversityName = _a.usniversityName, inviteType = _a.inviteType, trainingCenterLink = _a.trainingCenterLink;
    return (React.createElement("div", { className: "legal-text" },
        React.createElement("div", { className: "grid gap-6 font-monospace mb-6" },
            inviteType === INVITE_AMBASSADORS_TYPES.TAP && (React.createElement(TapInviteTemplate, { usniversityName: usniversityName, trainingCenterLink: trainingCenterLink })),
            inviteType === INVITE_AMBASSADORS_TYPES.IDP_AND_TAP && (React.createElement(TapAndIdpInviteTemplate, { usniversityName: usniversityName })),
            inviteType === INVITE_AMBASSADORS_TYPES.IDP && (React.createElement(IdpInviteTemplate, { usniversityName: usniversityName, trainingCenterLink: trainingCenterLink })))));
};
