import HttpService from '../../system/services/HttpService'

export default {
    create: (data, universityId) => {
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/faqs/create', data)
    },
    deleteQuestion: (id) => {
        return HttpService.delete(`/dashboard/faqs/${id}`)
    },
    deleteAnswer: (id) => {
        return HttpService.delete(`/dashboard/faqs/answer/${id}`)
    },
    info: (filter, universityIds) => {
        return HttpService.get('/dashboard/faqs', {
            filter: filter,
            withGlobalTags: true,
            universityIds,
        })
    },

    editFAQ: (id, options) => {
        return HttpService.put(`/dashboard/faqs/${id}`, {
            ...options,
        })
    },
    editAnswer: (answer_id, answer) => {
        return HttpService.put(`/dashboard/faqs/answer/${answer_id}`, {
            answer,
        })
    },
    toggleApproved: (answer_id) => {
        return HttpService.put('/dashboard/faqs/' + answer_id + '/approved/toggle')
    },
    togglePublished: (answer_id) => {
        return HttpService.put('/dashboard/faqs/' + answer_id + '/published/toggle')
    },
}
