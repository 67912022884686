import React, { PureComponent } from 'react'
import { Card, Row, Col } from 'antd'

import LineChart from '../components/LineChart'
import ChartHeader from '../components/ChartHeader'

import { prepareNumberDataWithAverage, numberTooltipFormatter, pluralize } from '../../../helpers/chart'

class FAQ extends PureComponent {
    prepareQuestionsCreatedChartData() {
        return prepareNumberDataWithAverage(this.props.data.questionsCreated, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    prepareAnswersCreatedChartData() {
        return prepareNumberDataWithAverage(this.props.data.answersCreated, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    prepareAnswersPublishedChartData() {
        return prepareNumberDataWithAverage(this.props.data.answersPublished, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    questionsTooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'question'))
    }

    answersTooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'answer'))
    }

    render() {
        const questionsCreatedData = this.prepareQuestionsCreatedChartData()
        const answersCreatedData = this.prepareAnswersCreatedChartData()
        const answersPublishedData = this.prepareAnswersPublishedChartData()

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Questions Created"
                                label={'Average: ' + questionsCreatedData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + questionsCreatedData.total}
                                totalLabelDescription="Across date range"
                            />
                            <LineChart
                                color="#d2e116"
                                data={questionsCreatedData.data}
                                height={200}
                                tooltipFormatter={this.questionsTooltipFormatter}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Answers Created"
                                label={'Average: ' + answersCreatedData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + answersCreatedData.total}
                                totalLabelDescription="Across date range"
                            />
                            <LineChart
                                color="#afd0eb"
                                data={answersCreatedData.data}
                                height={200}
                                tooltipFormatter={this.answersTooltipFormatter}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Answers Published"
                                label={'Average: ' + answersPublishedData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + answersPublishedData.total}
                                totalLabelDescription="Across date range"
                            />
                            <LineChart
                                color="#fde971"
                                data={answersPublishedData.data}
                                height={200}
                                tooltipFormatter={this.answersTooltipFormatter}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default FAQ
