import {
    GET_OPTIONAL_QUESTIONS_SUCCESS,
    GET_OPTIONAL_QUESTIONS_FAILED,
    GET_OPTIONAL_QUESTIONS_REQUESTED,
    HANDLE_CUSTOM_OPTIONAL_QUESTION,
} from '../../actions/chat/optionalQuestions'

import { OPTIONAL_QUESTIONS } from '../../store/initialState'
import OptionalQuestionModel from '../../../models/OptionalQuestionModel'

const initialState = OPTIONAL_QUESTIONS

const getQuestionsToSave = () => [
    OptionalQuestionModel(),
    OptionalQuestionModel(),
    OptionalQuestionModel(),
    OptionalQuestionModel(),
    OptionalQuestionModel(),
    OptionalQuestionModel(),
    OptionalQuestionModel(),
    OptionalQuestionModel({ order: 100, key: 'custom' }),
    OptionalQuestionModel({ order: 101, key: 'custom' }),
    OptionalQuestionModel({ order: 102, key: 'custom' }),
    OptionalQuestionModel({ order: 103, key: 'custom' }),
    OptionalQuestionModel({ order: 104, key: 'custom' }),
]

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case GET_OPTIONAL_QUESTIONS_SUCCESS: {
            const questionsSave = getQuestionsToSave()
            payload.questions.forEach((val, index) => {
                if (val.key === 'custom') {
                    for (const key in questionsSave) {
                        if (questionsSave[key].order === val.order) {
                            questionsSave[key] = OptionalQuestionModel(val)
                        }
                    }
                } else {
                    questionsSave[index] = OptionalQuestionModel(val)
                }
            })

            const customQuestionsArr = questionsSave.splice(8, 5).sort((a, b) => b.is_active - a.is_active)
            const sortedQuestionsSave = [...questionsSave, ...customQuestionsArr]

            return {
                ...state,
                questions: sortedQuestionsSave,
                isLoading: false,
            }
        }

        case HANDLE_CUSTOM_OPTIONAL_QUESTION: {
            const questionsSave = getQuestionsToSave()
            const newQuestionsSave = []
            payload.questions.forEach((val, index) => {
                if (val.key === 'custom') {
                    for (const key in questionsSave) {
                        if (questionsSave[key].order === val.order) {
                            newQuestionsSave.push(OptionalQuestionModel(val))
                        }
                    }
                } else {
                    newQuestionsSave.push(OptionalQuestionModel(val))
                }
            })

            const customQuestionsArr = newQuestionsSave.splice(8, 5).sort((a, b) => b.is_active - a.is_active)
            const sortedQuestionsSave = [...newQuestionsSave, ...customQuestionsArr]

            return {
                ...state,
                questions: sortedQuestionsSave,
                isLoading: false,
            }
        }

        case GET_OPTIONAL_QUESTIONS_FAILED: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case GET_OPTIONAL_QUESTIONS_REQUESTED: {
            return {
                ...state,
                isLoading: true,
            }
        }

        /* OPTIONAL_QUESTIONS_DATES */
        /* case GET_OPTIONAL_QUESTIONS_TAGS_SUCCESS: {
        const dates = []; const customFirstAnswers = []; const customSecondAnswers = [];
        if (payload.tags) {
            const tags = payload.tags;
            for (const tag of tags) {
                if (tag.key.includes("wantstrartstuding")) {
                    dates.push(tag);
                } else if (tag.key.includes("customquestionregisteruser")) {
                    customFirstAnswers.push(tag);
                } else if (tag.key.includes("customsecondquestionregisteruser")) {
                    customSecondAnswers.push(tag);
                }
            }
        }

        return {
            ...state,
            dates: dates,
            customFirstAnswers: customFirstAnswers,
            customSecondAnswers: customSecondAnswers,
            tagsLoaded: true
        };
    }

    case GET_OPTIONAL_QUESTIONS_TAGS_FAILED: {
        return Object.assign({}, state, {
            isLoading: false
        });
    }

    case GET_OPTIONAL_QUESTIONS_TAGS_REQUESTED: {
        return Object.assign({}, state, {
            isLoading: true
        });
    } */

        /* OPTIONAL_QUESTIONS_SPECL */
        /* case GET_OPTIONAL_QUESTIONS_SPECL_SUCCESS: {
        return Object.assign({}, state, {
            isLoading: false,
            specializations: payload.specializations
        });
    }

    case GET_OPTIONAL_QUESTIONS_SPECL_FAILED: {
        return Object.assign({}, state, {
            isLoading: false
        });
    }

    case GET_OPTIONAL_QUESTIONS_SPECL_REQUESTED: {
        return Object.assign({}, state, {
            isLoading: true
        });
    } */

        default: {
            return state
        }
    }
}
