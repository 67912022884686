import Slider from 'react-slick'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import ImageModal from './modals/ImageModal'
import VideoModal from './modals/VideoModal'
import ConfirmDeletePopup from './popups/ConfirmDeletePopup'
import Modal from 'antd/es/modal'
import Sprite from '../../assets/img/symbol/sprite.svg'

import store from '../redux/store'
import {
    ADD_DOWNLOAD_POINTS,
    POSTS_DELETE,
    CLOSE_CONFIRM_DELETE_POPUP,
    POSTS_INFO_REQUESTED,
    openDeleteModal,
    changePostCaption,
    togglePublishPost,
} from '../redux/actions/content/postsActions'

function NextArrow(props) {
    const { onClick } = props

    return (
        <button type="button" onClick={onClick} className="slick-next">
            <svg className="icon icon-slider-arrow">
                <use xlinkHref={Sprite + '#slider-arrow'}></use>
            </svg>
        </button>
    )
}

function PrevArrow(props) {
    const { onClick, disabled } = props
    return (
        <button type="button" onClick={onClick} className="slick-prev" disabled={disabled}>
            <svg className="icon icon-slider-arrow">
                <use xlinkHref={Sprite + '#slider-arrow'}></use>
            </svg>
        </button>
    )
}

class PostsSlider extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            post: null,
            nextItemAfterDelete: null,
            loadAfterDelete: false,
            disableBackArrow: props.initialSlide === 0,
        }

        this.handleContentDownload = this.handleContentDownload.bind(this)
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this)
        this.handleChangeSlide = this.handleChangeSlide.bind(this)
    }

    handleConfirmDelete(post) {
        const routerVars = this.props.match.params

        let postsType = 'all'
        let typeId = 0
        if (routerVars.user_id) {
            postsType = 'users'
            typeId = routerVars.user_id
        } else if (routerVars.content_request_id) {
            postsType = 'content_responses'
            typeId = routerVars.content_request_id
        }

        this.handleDeleteIndex(this.state.nextItemAfterDelete)
        this.setState({ loadAfterDelete: true })

        store.dispatch({
            type: POSTS_DELETE,
            payload: {
                post_id: this.props.confirmDeleteModal.postId,
                postsInfo: true,
                postsType: postsType,
                typeId: typeId,
            },
        })
    }

    handleContentDownload(event) {
        store.dispatch({
            type: ADD_DOWNLOAD_POINTS,
            payload: {
                post_id: event.target.getAttribute('data-post-id'),
            },
        })
    }

    handleOpenConfirmDeletePopup(index, nextItem) {
        this.setState({ nextItemAfterDelete: nextItem })
        this.props.openModal(index)
    }

    handleCloseConfirmDeletePopup() {
        store.dispatch({
            type: CLOSE_CONFIRM_DELETE_POPUP,
        })
    }

    handleChangeSlide(slideIndex) {
        if (slideIndex === this.props.posts.posts.items.length - 1 && !this.props.home) {
            this.handleLoadMore()
        }

        this.setState({ disableBackArrow: slideIndex === 0 })
    }

    handleLoadMore() {
        const { match, account, posts, getNextPosts } = this.props
        if (!posts.posts.isLoading && posts.posts.hasMore) {
            const currentOffset = posts.posts.offset
            const limit = posts.posts.limit
            const total = posts.posts.total
            const routerVars = match.params
            const queryOffset = currentOffset + limit < total ? currentOffset + limit : total
            if (!total) return

            if (routerVars.tag_id) {
                store.dispatch({
                    type: POSTS_INFO_REQUESTED,
                    payload: {
                        filter: {
                            university_id: account.account_info.university.id,
                            tag_id: routerVars.tag_id,
                            searchItems: posts.searchItems,
                        },
                        offset: queryOffset,
                        limit: 12,
                        sort: {
                            created_at: 'desc',
                        },
                        withGlobalTags: true,
                    },
                })
            } else if (getNextPosts) {
                this.props.getNextPosts()
            } else {
                store.dispatch({
                    type: POSTS_INFO_REQUESTED,
                    payload: {
                        filter: {
                            university_id: account.account_info.university.id,
                            searchItems: posts.searchItems,
                        },
                        limit: 12,
                        offset: queryOffset,
                        sort: {
                            created_at: 'desc',
                        },
                        withGlobalTags: true,
                    },
                })
            }
        }
    }

    componentDidMount() {
        if (this.state.nextItemAfterDelete) {
            setTimeout(() => {
                this.slider.slickGoTo(this.state.nextItemAfterDelete)
            }, 500)
        }
    }

    render() {
        const {
            initialSlide,
            confirmDeleteModal,
            handleOpenSharePopup,
            changePostCaption,
            togglePublishPost,
            handleDeleteIndex,
            posts,
        } = this.props

        this.handleDeleteIndex = handleDeleteIndex.bind(this)

        const settings = {
            arrows: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow disabled={this.state.disableBackArrow} />,
            draggable: false,
            initialSlide: Number(initialSlide),
            afterChange: (slide) => this.handleChangeSlide(slide),
        }
        let slidesViews = []
        const isUniversityHasPosts = posts.posts && posts.posts.items.length > 0

        if (isUniversityHasPosts) {
            slidesViews = posts.posts.items.map((post, index) => {
                if (post.type == 'image') {
                    return (
                        <ImageModal
                            key={post.created_at.toString()}
                            sliderIndex={index}
                            post={post}
                            handleDeleteClick={() => {
                                this.handleOpenConfirmDeletePopup(post.id, index)
                            }}
                            handleDownloadButtonClick={this.handleContentDownload}
                            handleShareButtonClick={() => {
                                handleOpenSharePopup(post)
                            }}
                            changePostCaption={changePostCaption}
                            togglePublishPost={togglePublishPost}
                        />
                    )
                } else if (post.type == 'video') {
                    return (
                        <VideoModal
                            key={post.created_at.toString()}
                            sliderIndex={index}
                            post={post}
                            handleDeleteClick={() => {
                                this.handleOpenConfirmDeletePopup(post.id, index)
                            }}
                            handleDownloadButtonClick={this.handleContentDownload}
                            handleShareButtonClick={() => {
                                handleOpenSharePopup(post)
                            }}
                            changePostCaption={changePostCaption}
                            togglePublishPost={togglePublishPost}
                        />
                    )
                }
            })
        } else {
            slidesViews.push(<div></div>)
        }

        const secretFunc = () => {
            if (this.state.loadAfterDelete) {
                this.setState({ loadAfterDelete: false })
                setTimeout(() => {
                    this.slider.slickGoTo(this.state.nextItemAfterDelete)
                }, 50)
            }
        }
        if (!this.state.loadAfterDelete) {
            var slider = (
                <Slider
                    ref={(slider) => (this.slider = slider)}
                    className="post-galery-slider js-post-galery-slider"
                    {...settings}
                >
                    {slidesViews}
                </Slider>
            )
        }

        return (
            <div>
                {slider}

                <Modal
                    open={confirmDeleteModal.isOpened}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseConfirmDeletePopup}
                >
                    <ConfirmDeletePopup
                        handleConfirmDelete={this.handleConfirmDelete}
                        handleClosePopup={this.handleCloseConfirmDeletePopup}
                    />
                </Modal>

                {secretFunc()}
            </div>
        )
    }
}

export default connect(
    (state) => {
        return {
            posts: state.posts,
            confirmDeleteModal: state.confirmDeleteModal,
            account: state.account,
        }
    },
    { changePostCaption, togglePublishPost, openModal: openDeleteModal }
)(withRouter(PostsSlider))
