import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { openStreamTypeModal } from '../../../../redux/actions/live-streams/live-streams-actions'

const StreamsListHeader = () => {
    const dispatch = useDispatch()

    const handleCreateEventClick = useCallback(() => {
        dispatch(openStreamTypeModal())
    }, [dispatch])

    return (
        <div className="new-event-button">
            <a onClick={handleCreateEventClick} className="el-btn create-event-popup-btn">
                Create new Stream
            </a>
        </div>
    )
}

export default StreamsListHeader
