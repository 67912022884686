import { GET_HOME_CHART_FAILED, GET_HOME_CHART_SUCCESS } from '../../actions/home/homeActions'

import { HOME_CHART } from '../../store/initialState'

import { prepareAverageTimeData } from '../../../helpers/chart'

const initialState = HOME_CHART

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case GET_HOME_CHART_SUCCESS: {
            let total = 0
            if (payload.items) {
                if (payload.currentChartOption === 'ambassadorResponseTime') {
                    total = prepareAverageTimeData(payload.total, 0)
                }
                payload.items.map((item) => {
                    if (payload.currentChartOption === 'conversion') {
                        total = payload.total + `${payload.total ? ' %' : ''}`
                    } else if (
                        payload.currentChartOption === 'prospectAccounts' ||
                        payload.currentChartOption === 'messagesSent'
                    ) {
                        total = payload.total
                    }
                })
            }
            return {
                ...state,
                items: payload.items,
                currentChartOption: payload.currentChartOption,
                total,
                isLoading: false,
            }
        }
        case GET_HOME_CHART_FAILED: {
            return {
                ...state,
                isLoading: false,
            }
        }
        default: {
            return state
        }
    }
}
