import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'

const renderPrimaryColorInput = ({
    input,
    label,
    chosenColor,
    placeholder,
    value,
    type,
    handleChange,
    className,
    meta: { touched, error, warning },
}) => (
    <div className="color-row">
        <p className="color-row-text">Primary color</p>
        <label className="color-input-label">
            <input
                {...input}
                className={className}
                placeholder={placeholder}
                value={chosenColor}
                type={type}
                onChange={handleChange}
                style={{ borderColor: chosenColor, width: '100px' }}
            />
            {touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
        </label>
    </div>
)

const renderSecondaryColorInput = ({
    input,
    label,
    chosenColor,
    placeholder,
    value,
    type,
    handleChange,
    className,
    meta: { touched, error, warning },
}) => (
    <div className="color-row">
        <p className="color-row-text">Secondary color</p>
        <label className="color-input-label">
            <input
                {...input}
                className={className}
                placeholder={placeholder}
                value={chosenColor}
                type={type}
                onChange={handleChange}
                style={{ borderColor: chosenColor, width: '100px' }}
            />
            {touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
        </label>
    </div>
)

const renderTextArea = ({ input, placeholder, type, className, meta: { touched, error } }) => (
    <div>
        <textarea {...input} className={className} placeholder={placeholder} type={type}></textarea>
    </div>
)

class StyleForm extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            primaryColorValue: this.props.initialValues.primary_color,
            secondaryColorValue: this.props.initialValues.secondary_color,
        }

        this.handleSecondaryColorChange = this.handleSecondaryColorChange.bind(this)
        this.handlePrimaryColorChange = this.handlePrimaryColorChange.bind(this)
    }

    handlePrimaryColorChange(event) {
        this.setState({
            primaryColorValue: event.target.value,
        })
    }

    handleSecondaryColorChange(event) {
        this.setState({
            secondaryColorValue: event.target.value,
        })
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <form action="#" className="style-form" onSubmit={handleSubmit}>
                <Field
                    name="primary_color"
                    component={renderPrimaryColorInput}
                    type="text"
                    handleChange={this.handlePrimaryColorChange}
                    chosenColor={this.state.primaryColorValue}
                    className="signin-input privacy-input branding-input"
                />
                <Field
                    name="secondary_color"
                    component={renderSecondaryColorInput}
                    type="text"
                    handleChange={this.handleSecondaryColorChange}
                    chosenColor={this.state.secondaryColorValue}
                    className="signin-input privacy-input branding-input"
                />
                {/* <p className="style-preview-name">Custom CSS overrides</p>
            <p className="style-page-text">Styles entered below override any settings selected above</p>
            <Field
              name='custom_css'
              component={renderTextArea}
              placeholder=''
              className="color-textarea"
            /> */}
                <button type="submit" className="el-btn">
                    Save styles
                </button>
            </form>
        )
    }
}

StyleForm = reduxForm({
    form: 'style_form',
})(StyleForm)

StyleForm = connect((state) => ({
    initialValues: {
        primary_color: state.universityWidgets.primary_color,
        secondary_color: state.universityWidgets.secondary_color,
        typeface: state.universityWidgets.typeface,
        custom_css: state.universityWidgets.custom_css,
    },
}))(StyleForm)

export default StyleForm
