var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCurrentGroupUniversityId, getUniversityInfo, getUsersSlice } from '../../../../redux/selectors';
import { useUsersColumnsConfig } from './use-users-columns-config';
import { MAX_USERS_LIMIT_POPUP_TOGGLE, USERS_INFO_REQUESTED, getUserTableTotalsAction, openUserInfoModal, } from '../../../../redux/actions/content/usersActions';
import { UserRowModel } from './models';
import { checkIsInTHE } from '../../../../common/utils/user-account-helpers';
import { useAppSelector } from '../../../../hooks/store';
var ORDER_DIRECTION_MAP = {
    ascend: 'asc',
    descend: 'desc',
};
var SETTINGS_INITIAL_STATE = {
    sorter: {
        field: 'full_name',
        order: 'ascend',
    },
    pagination: { current: 1, pageSize: 20 },
    filter: {},
    search: {},
    withGlobalTags: true,
};
var createRequestParams = function (_a) {
    var sorter = _a.sorter, pagination = _a.pagination, filter = _a.filter, search = _a.search, withGlobalTags = _a.withGlobalTags;
    var field = sorter.field, orderValue = sorter.order, columnKey = sorter.columnKey;
    var current = pagination.current, pageSize = pagination.pageSize;
    var order = ORDER_DIRECTION_MAP[orderValue];
    var offset = pageSize * (current - 1);
    var limit = 10;
    var orderByField = { field: field };
    if (field && field.includes('partner_group_tap_feed_permission')) {
        orderByField = {
            field: 'partner_group_tap_feed_permission',
            id: columnKey,
        };
    }
    if (!order) {
        order = 'asc';
        orderByField = {
            field: 'full_name',
        };
    }
    var orderBy = orderByField;
    return {
        orderBy: orderBy,
        order: order,
        offset: offset,
        filter: filter,
        search: search,
        withGlobalTags: withGlobalTags,
        limit: limit,
    };
};
export var useUserTable = function () {
    var dispatch = useDispatch();
    var search = useLocation().search;
    var universityId = useAppSelector(function (state) { var _a; return (_a = state.account.account_info) === null || _a === void 0 ? void 0 : _a.university_id; });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    var university = useSelector(getUniversityInfo);
    var currentGroupUniversityId = useAppSelector(getCurrentGroupUniversityId);
    var _a = useSelector(getUsersSlice), users = _a.items, total = _a.total, searchItems = _a.searchItems, usersMaxLimitReached = _a.usersMaxLimitReached, usersMaxLimitReachedText = _a.usersMaxLimitReachedText, usersTotals = _a.usersTotals, usersRestData = __rest(_a, ["items", "total", "searchItems", "usersMaxLimitReached", "usersMaxLimitReachedText", "usersTotals"]);
    var isTableEmpty = users.length === 0 && searchItems.length === 0;
    var isTHEPartnerRegistration = checkIsInTHE(university) && university.isRegisteredPartner;
    var _b = useState(SETTINGS_INITIAL_STATE), gridSettings = _b[0], setGridSettings = _b[1];
    var _c = useState(0), newUsersCount = _c[0], setNewUsersCount = _c[1];
    var _d = useState(search.split('ambassadorEmail=')[1]
        ? decodeURIComponent(search.split('ambassadorEmail=')[1]).split('&')[0]
        : null), ambassadorEmail = _d[0], setAmbassadorEmail = _d[1];
    var columnsConfig = useUsersColumnsConfig(isTHEPartnerRegistration, university.partnerGroups, usersTotals);
    useEffect(function () {
        var beforeFilter = usersTotals.before_filter, afterFilter = usersTotals.after_filter;
        var beforFilterTotal = beforeFilter ? beforeFilter.new_ambassadors_today : 0;
        var afterFilterTotal = afterFilter ? afterFilter.new_ambassadors_today : 0;
        setNewUsersCount(afterFilter ? afterFilterTotal : beforFilterTotal);
    }, [usersTotals]);
    useEffect(function () {
        if (!ambassadorEmail) {
            var params = createRequestParams(gridSettings);
            dispatch({
                type: USERS_INFO_REQUESTED,
                payload: params,
            });
        }
    }, [gridSettings, dispatch, currentGroupUniversityId]);
    useEffect(function () {
        if (!ambassadorEmail) {
            dispatch(getUserTableTotalsAction({
                filter: {},
            }));
        }
        else {
            var onSuccess = function (data) {
                var _a;
                setAmbassadorEmail(null);
                dispatch(openUserInfoModal({
                    userId: (_a = data.find(function (item) { return item; })) === null || _a === void 0 ? void 0 : _a.id,
                }));
            };
            dispatch(getUserTableTotalsAction({
                filter: {
                    searchItems: [ambassadorEmail],
                },
            }));
            dispatch({
                type: USERS_INFO_REQUESTED,
                payload: {
                    filter: {
                        searchItems: [ambassadorEmail],
                    },
                },
                meta: {
                    onSuccess: onSuccess,
                },
            });
        }
    }, [dispatch, currentGroupUniversityId]);
    var requestSearch = useCallback(function (items) {
        dispatch(getUserTableTotalsAction({
            filter: {
                searchItems: items,
            },
        }));
        setGridSettings(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), { pagination: { current: 1, pageSize: ((_a = prevState === null || prevState === void 0 ? void 0 : prevState.pagination) === null || _a === void 0 ? void 0 : _a.pageSize) || 20 }, filter: {
                    searchItems: items,
                    university_id: universityId,
                } }));
        });
    }, [dispatch, universityId]);
    var handleTableChange = useCallback(function (pagination, filters, sorter) {
        setGridSettings(function (prevState) { return (__assign(__assign({}, prevState), { sorter: sorter, pagination: pagination })); });
    }, []);
    var handleCloseMaxLimitPopup = useCallback(function () {
        dispatch({
            type: MAX_USERS_LIMIT_POPUP_TOGGLE,
        });
    }, [dispatch]);
    var dataSource = useMemo(function () {
        return users.map(function (item) {
            return UserRowModel(__assign(__assign(__assign({}, item), usersRestData), { partnerGroups: university.partnerGroups }));
        });
    }, [users, usersRestData, university]);
    var pagination = useMemo(function () { return ({
        pageSize: 10,
        current: gridSettings.pagination.current,
        total: total,
        position: ['bottomCenter'],
        showSizeChanger: false,
        size: 'small',
    }); }, [total, gridSettings]);
    return {
        requestSearch: requestSearch,
        handleTableChange: handleTableChange,
        searchItems: searchItems,
        isTableEmpty: isTableEmpty,
        newUsersCount: newUsersCount,
        columnsConfig: columnsConfig,
        isTHEPartnerRegistration: isTHEPartnerRegistration,
        dataSource: dataSource,
        pagination: pagination,
        usersMaxLimitReached: usersMaxLimitReached,
        usersMaxLimitReachedText: usersMaxLimitReachedText,
        handleCloseMaxLimitPopup: handleCloseMaxLimitPopup,
    };
};
