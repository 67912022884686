import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import RegistrationForm from './components/RegistrationForm'
import { PlsSignUpScreen } from './components/pls-sign-up'
import Modal from 'antd/es/modal'
import RegistrationSuccessModal from './components/RegistrationSuccessModal'
import Logo from '../../../../assets/svg/logo.svg'
import {
    ACCOUNT_REGISTRATION,
    CLOSE_SUCCESS_REGISTRATION_MODAL,
    CHECK_ADMIN_INVITATION,
} from '../../../redux/actions/account/accountActions'
import store from '../../../redux/store'

class Registration extends PureComponent {
    constructor(props) {
        super(props)

        const query = new URLSearchParams(props.location.search)
        this.inviteToken = query.get('inviteToken')
        this.inviteCode = query.get('inviteCode')
        this.isPartner = query.get('isPartner')
        this.isTrial = query.get('isTrial')
        this.invitationData = {
            inviteToken: this.inviteToken,
            inviteCode: this.inviteCode,
            isGroup: false,
        }

        this.handleRegistrationSubmit = this.handleRegistrationSubmit.bind(this)
    }

    componentDidMount() {
        if (this.inviteCode) {
            store.dispatch({
                type: CHECK_ADMIN_INVITATION,
                payload: {
                    inviteToken: this.inviteToken,
                    inviteCode: this.inviteCode,
                },
            })
        }
    }

    handleRegistrationSubmit(values) {
        const registrationData = {
            name: values.first_name,
            email: values.email,
            lastName: values.last_name,
            password: values.password,
            isTrial: false,
        }

        if (this.inviteCode) {
            registrationData.inviteToken = this.inviteToken
            registrationData.inviteCode = this.inviteCode
        } else {
            registrationData.universityName = values.university_name
        }
        if (this.isPartner) {
            registrationData.isPartner = true
        }
        if (this.isTrial) {
            registrationData.isTrial = true
        }

        store.dispatch({
            type: ACCOUNT_REGISTRATION,
            payload: registrationData,
        })
    }

    handleCloseRegistrationSuccessPopup() {
        store.dispatch({
            type: CLOSE_SUCCESS_REGISTRATION_MODAL,
        })
    }

    render() {
        const { account, successRegistrationModal } = this.props

        return (
            <div id="page-wrapper" className="page-wrapper unregistered-page">
                <div id="page-body" className="page-body js-index">
                    <div className={`${!this.isTrial ? 'signin-container' : 'signin-container-trial'}`}>
                        {account.isValidInvitation ? (
                            <>
                                {!this.isTrial ? (
                                    <>
                                        <div className="registration-preamble">
                                            <img src={Logo} alt="Logo" />
                                        </div>
                                        <div className="signin-block">
                                            <RegistrationForm
                                                account={account}
                                                onSubmit={this.handleRegistrationSubmit}
                                                invitationData={this.invitationData}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <PlsSignUpScreen />
                                )}
                            </>
                        ) : (
                            <>
                                <div className="registration-preamble">
                                    <img src={Logo} alt="Logo" />
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <Modal
                    open={successRegistrationModal.isOpened}
                    centered={true}
                    bodyStyle={{ height: 'auto' }}
                    footer={null}
                    closable={true}
                    maskClosable={false}
                    onCancel={this.handleCloseRegistrationSuccessPopup}
                >
                    <RegistrationSuccessModal handleClosePopup={this.handleCloseRegistrationSuccessPopup} />
                </Modal>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        successRegistrationModal: state.successRegistrationModal,
    }
})(withRouter(Registration))
