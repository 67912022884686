import { Form } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { roles } from '../../../../../helpers/helpers'
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions'
import { ExternalSpeakers, UsersSelect } from '../../index'

import SpeakersFormModel from './speakers-form-model'

const SpeakersForm = ({ form, data }) => {
    const dispatch = useDispatch()
    const initialValues = useMemo(() => SpeakersFormModel(data), [data])

    const {
        meta: { selectedInternalSpeakers },
    } = initialValues

    useEffect(() => {
        form.resetFields()
    }, [initialValues])

    useEffect(() => {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
                roles: [roles.student, roles.admin],
            },
        })
    }, [])

    return (
        <Form form={form} initialValues={initialValues} layout="vertical">
            <UsersSelect
                id="internalSpeakerIds"
                label="Internal speakers"
                roles={[roles.admin, roles.student]}
                form={form}
                rules={[
                    {
                        required: true,
                        message: 'You should invite at least 1 speaker',
                    },
                ]}
                showRoles
                selectedUsers={selectedInternalSpeakers}
            />
            <ExternalSpeakers id="externalSpeakerEmails" label="External speakers (emails)" form={form} editMode />
        </Form>
    )
}

export default SpeakersForm
