import { call, put, select } from 'redux-saga/effects'

import API from '../../../api'
import {
    OPEN_SETTINGS_MESSAGING_POPUP,
    CLOSE_SETTINGS_MESSAGING_POPUP,
    OPEN_ACTION_MODAL,
} from '../../actions/components/componentsActions'
import {
    SCHEDULE_DATA_SUCCESS,
    SCHEDULE_DATA_FAILED,
    SCHEDULE_EDIT_EVENT_SUCCESS,
    SCHEDULE_EDIT_EVENT_FAILED,
    SCHEDULE_DEACTIVATE_EVENT_SUCCESS,
    SCHEDULE_DEACTIVATE_EVENT_FAILED,
    SCHEDULE_UPDATE_STATUSES_SUCCESS,
    SCHEDULE_UPDATE_STATUSES_FAILED,
    SCHEDULE_ITEM_SUCCESS,
    SCHEDULE_ITEM_FAILED,
} from '../../actions/content/scheduleActions'

const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

export function* getScheduleData({ filter }) {
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)

        const response = yield call(() => {
            return API.dashboard.getScheduleData(filter, universityIds)
        })

        yield response && response.success
            ? put({
                  type: SCHEDULE_DATA_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: SCHEDULE_DATA_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: SCHEDULE_DATA_FAILED,
        })
    }
}

export function* getScheduleItem({ eventId }) {
    try {
        const response = yield call(() => {
            return API.dashboard.getScheduleItem(eventId)
        })
        if (response && response.success) {
            yield put({
                type: SCHEDULE_ITEM_SUCCESS,
                payload: response.data,
            })
            yield put({
                type: OPEN_SETTINGS_MESSAGING_POPUP,
            })
        } else {
            yield put({
                type: SCHEDULE_ITEM_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: SCHEDULE_ITEM_FAILED,
        })
    }
}

export function* editScheduleEvent({ data }) {
    try {
        const response = yield call(() => {
            return API.dashboard.editScheduleEvent(data)
        })

        if (response && response.success) {
            yield put({
                type: SCHEDULE_EDIT_EVENT_SUCCESS,
                payload: {
                    id: data.eventId,
                    name: data.name,
                    description: data.description,
                    date: data.eventSettings.date,
                },
            })
            yield put({
                type: CLOSE_SETTINGS_MESSAGING_POPUP,
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your content group has been edited',
                },
            })
        } else {
            yield put({
                type: SCHEDULE_EDIT_EVENT_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: SCHEDULE_EDIT_EVENT_FAILED,
        })
    }
}

export function* deactivateScheduleEvent({ eventId }) {
    try {
        const response = yield call(() => {
            return API.dashboard.closeContentGroup(eventId)
        })

        if (response.success) {
            yield put({
                type: SCHEDULE_DEACTIVATE_EVENT_SUCCESS,
                payload: { eventId },
            })

            yield put({
                type: CLOSE_SETTINGS_MESSAGING_POPUP,
            })

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Your content group has been updated',
                },
            })
        } else {
            yield put({
                type: SCHEDULE_DEACTIVATE_EVENT_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* updateScheduleEventStatuses({ eventIds, isActive }) {
    try {
        const response = yield call(() => {
            return API.dashboard.updateScheduleEventStatuses(eventIds, isActive)
        })

        yield response && response.success
            ? put({
                  type: SCHEDULE_UPDATE_STATUSES_SUCCESS,
                  payload: { ids: eventIds, isActive },
              })
            : put({
                  type: SCHEDULE_UPDATE_STATUSES_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: SCHEDULE_UPDATE_STATUSES_FAILED,
        })
    }
}
