var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Tabs } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import DatesIntervalSelector from 'src/app/components/DatesIntervalSelector';
import { HomeRedirect } from 'src/app/containers/_layout/nav/components/HomeRedirect';
import { getDateRange } from 'src/app/helpers/time';
import { Permissions } from 'src/app/permissions/permissions';
import { TWO_TIER_MENU_CHANGE_SUBMENU } from 'src/app/redux/actions/content/componentsActions';
import { PermissionEnum } from 'src/types/general';
import { ConversationsChart } from './components/ConversationsChart';
import { FirstResponseTimeChart } from './components/FirstResponseTimeChart';
import { MessagesChart } from './components/MessagesChart';
import { NationalityBreakdownChart } from './components/NationalityBreakdownChart';
import { ProspectCountChart } from './components/ProspectCountChart';
var IdpConversionReport = function () {
    var dispatch = useDispatch();
    var canSeeIdpReport = Permissions.can(PermissionEnum.IdpLiveApp, false) || Permissions.can(PermissionEnum.IdpWhatUni, false);
    var showReportTypeFilter = Permissions.can(PermissionEnum.IdpLiveApp, false) && Permissions.can(PermissionEnum.IdpWhatUni, false);
    var _a = useState(PermissionEnum.IdpLiveApp), reportType = _a[0], setReportType = _a[1];
    var _b = useState('30Days'), selectedButton = _b[0], setSelectedButton = _b[1];
    var _c = useState(getDateRange(30)), selectedDateRange = _c[0], setSelectedDateRange = _c[1];
    var filterRef = useRef(__assign(__assign({}, selectedDateRange), { product: showReportTypeFilter || Permissions.can(PermissionEnum.IdpLiveApp, false)
            ? PermissionEnum.IdpLiveApp
            : PermissionEnum.IdpWhatUni }));
    useEffect(function () {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'reporting',
            },
        });
    }, [dispatch]);
    var onReportTypeChange = useCallback(function (value) {
        setReportType(value);
        filterRef.current = __assign(__assign({}, filterRef.current), { product: value === 'all'
                ? undefined
                : showReportTypeFilter
                    ? value
                    : Permissions.can(PermissionEnum.IdpLiveApp, false)
                        ? PermissionEnum.IdpLiveApp
                        : PermissionEnum.IdpWhatUni });
    }, [showReportTypeFilter]);
    var onReportDateChange = useCallback(function (selectedButtonVal, selectedDateRangeVal) {
        setSelectedButton(selectedButtonVal);
        setSelectedDateRange(selectedDateRangeVal);
        filterRef.current = __assign(__assign({}, filterRef.current), selectedDateRangeVal);
    }, []);
    if (!canSeeIdpReport) {
        return React.createElement(HomeRedirect, null);
    }
    return (React.createElement("div", { className: "idp-conversion-container" },
        React.createElement("div", { className: "content reporting" },
            React.createElement("p", { className: "prospect-table-title" }, "P2P Conversion"),
            React.createElement("div", { className: "flex flex-row items-baseline justify-between" },
                React.createElement(Tabs, { activeKey: reportType, size: "large", onChange: onReportTypeChange, className: !showReportTypeFilter ? 'invisible' : undefined },
                    React.createElement(Tabs.TabPane, { tab: "Live App", key: PermissionEnum.IdpLiveApp }),
                    React.createElement(Tabs.TabPane, { tab: "WhatUni app", key: PermissionEnum.IdpWhatUni }),
                    React.createElement(Tabs.TabPane, { tab: "All", key: "all" })),
                React.createElement(DatesIntervalSelector, { selectedButton: selectedButton, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, onChange: onReportDateChange, restrictBeforeDate: new Date(2020, 7, 17) })),
            React.createElement("div", { className: "grid grid-cols-2 gap-4 mb-4" },
                React.createElement(ProspectCountChart, { filter: filterRef.current }),
                React.createElement(NationalityBreakdownChart, { filter: filterRef.current })),
            React.createElement("div", { className: "grid grid-cols-3 gap-4" },
                React.createElement(ConversationsChart, { filter: filterRef.current }),
                React.createElement(MessagesChart, { filter: filterRef.current }),
                React.createElement(FirstResponseTimeChart, { filter: filterRef.current })))));
};
export default IdpConversionReport;
