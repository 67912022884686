import API from '../../../api'
import { call, put, select } from 'redux-saga/effects'
import { REPORT_QUESTIONS_INFO_SUCCESS, REPORT_QUESTIONS_INFO_FAILED } from '../../actions/reporting/faqActions'

const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

export function* faqReport(action) {
    const payload = action.payload

    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const data = {
            filter: payload.filter,
            limit: payload.limit,
            offset: payload.offset,
            orderBy: payload.orderBy,
            order: payload.order,
            universityIds,
        }
        const response = yield call(() => {
            return API.reporting.faqData(data)
        })

        if (response.success && response.data) {
            yield put({
                type: REPORT_QUESTIONS_INFO_SUCCESS,
                payload: {
                    items: response.data.faqReport,
                    total: response.data.total,
                    all_likes: response.data.all_likes,
                    all_views: response.data.all_views,
                },
            })
        } else {
            yield put({
                type: REPORT_QUESTIONS_INFO_FAILED,
            })
        }
    } catch (e) {
        console.log(e)
        yield put({
            type: REPORT_QUESTIONS_INFO_FAILED,
        })
    }
}
