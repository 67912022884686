import React from 'react';
import { ProtectedButton } from 'src/app/components/protected-button';
import { LicensesEnum } from 'src/types/general';
export var PostItemView = function (_a) {
    var post = _a.post, index = _a.index, onClick = _a.onClick, onShareClick = _a.onShareClick;
    if ((post.type === 'image' && !post.images[0]) || (post.type === 'video' && !post.videos[0])) {
        return null;
    }
    return (React.createElement("div", { className: "relative aspect-square group overflow-hidden", key: post.id, "data-index": index },
        post.type === 'video' ? (React.createElement("video", { className: "w-full h-full object-cover", controlsList: "nodownload", src: (post.videos[0].qualities && post.videos[0].qualities['370x370']) || post.videos[0].original })) : (React.createElement("img", { className: "w-full h-full object-cover", src: post.images[0].sizes['370x370'] || post.images[0].original, alt: "Content preview image" })),
        post.type === 'video' && (React.createElement("div", { className: "absolute w-0 h-0 border-t-[2.4rem] border-solid border-t-transparent border-l-[3.2rem] border-l-white border-b-[2.4rem] border-b-transparent bottom-4 right-4" })),
        React.createElement("div", { className: "absolute inset-0 cursor-pointer opacity-0 grid bg-black/70 p-4 grid-rows-1 group-hover:opacity-100 transition-opacity duration-300 ease-in-out", onClick: function () {
                onClick(post);
            }, role: "button" },
            post.post_publisher != null ? (React.createElement("div", null,
                React.createElement("h3", { className: "content-preview-user-name" }, post.post_publisher && post.post_publisher.name
                    ? post.post_publisher.name
                    : 'Deleted user'),
                React.createElement("p", { className: "content-preview-user-text" }, post.post_publisher
                    ? Object.entries(post.post_publisher.user_tags)
                        .map(function (tags) {
                        tags[1].map(function (tag) { return tag.name; });
                    })
                        .join(', ')
                    : ''))) : (React.createElement("h3", { className: "content-preview-user-name" }, "Deleted user")),
            React.createElement("p", { className: "font-interface text-white text-xs sr-only" }, post.caption),
            React.createElement("div", null,
                React.createElement(ProtectedButton, { licenseName: LicensesEnum.Sharing, type: "secondary", "data-automation-id": "share-rss", className: post.isExample ? 'hidden' : 'content-preview-share', "data-post-index": index, onClick: function (event) {
                        event.stopPropagation();
                        onShareClick(post);
                    } },
                    post.isShared ? 'Share again' : 'Share',
                    ' ')))));
};
