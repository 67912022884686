import { call, select } from 'redux-saga/effects'
import API from '../../../api'
import store from '../../store'
import { OPEN_USER_INFO_MODAL } from '../../actions/components/componentsActions'
import { getAccountSlice } from '../../selectors'

export function* getProspectInfo(action) {
    const payload = action.payload
    try {
        const account = yield select(getAccountSlice)

        const universityIds = [account.currentGroupUniversityId || account.account_info.university_id]

        const response = yield call(() => {
            return API.user.getProspect(payload.userId, universityIds)
        })

        if (response.success) {
            store.dispatch({
                type: OPEN_USER_INFO_MODAL,
                payload: { userInfo: response.data },
            })
        }
    } catch (error) {
        console.log(error)
    }
}
