import { CAMPAIGN } from '../../actions/campaign/campaign-action-types'

const initialState = {
    items: [],
    activeCampaign: undefined,
    total: 0,
    campaignTypeModal: {
        data: undefined,
        open: false,
        submitting: false,
    },
}

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case CAMPAIGN.OPEN_CAMPAIGN_TYPE_MODAL: {
            return {
                ...state,
                campaignTypeModal: {
                    ...state.campaignTypeModal,
                    open: true,
                },
            }
        }

        case CAMPAIGN.CLOSE_CAMPAIGN_TYPE_MODAL: {
            return {
                ...state,
                campaignTypeModal: {
                    ...state.campaignTypeModal,
                    open: false,
                },
            }
        }
        case CAMPAIGN.SET_CAMPAIGN_TYPES: {
            return {
                ...state,
                campaignTypeModal: {
                    ...state.campaignTypeModal,
                    data: payload.campaignTypes,
                },
            }
        }

        case CAMPAIGN.FETCH_SUCCESS: {
            return {
                ...state,
                items: payload.campaigns,
                total: payload.total,
            }
        }
        case CAMPAIGN.FETCH_CAMPAIGN_BY_ID_SUCCESS: {
            return {
                ...state,
                activeCampaign: payload.campaign,
            }
        }

        case CAMPAIGN.RESET_ACTIVE_CAMPAIGN: {
            return {
                ...state,
                activeCampaign: undefined,
            }
        }

        default: {
            return state
        }
    }
}
