import React, { useEffect, useRef } from 'react'

const ParticipantVideo = ({ view }) => {
    const wrapperRef = useRef(null)

    useEffect(() => {
        if (view) {
            if (wrapperRef.current.firstChild) {
                wrapperRef.current.firstChild.remove()
            }

            if (!view.classList.contains('fm-video-local') && !view.classList.contains('fm-video-remote')) {
                view.classList.add('fm-video-remote')
            }
            wrapperRef.current.append(view)
        }
    }, [view])

    return <div className="participant-video" ref={wrapperRef} />
}

export default ParticipantVideo
