import React from 'react';
import { Button } from 'src/app/components/base/Button';
import { useStepsData } from '../StepsProvider';
import { setIdpActivation } from 'src/app/redux/actions/account/account-actions';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { useHistory } from 'react-router';
import { getAccountInfo } from 'src/app/redux/selectors';
export var StepFour = function () {
    var _a = useStepsData(), isCompleted = _a.isCompleted, isConversionSelected = _a.isConversionSelected, isCommunitySelected = _a.isCommunitySelected;
    var dispatch = useAppDispatch();
    var accountInfo = useAppSelector(getAccountInfo);
    var history = useHistory();
    var onClose = function () {
        dispatch(setIdpActivation());
        history.push('./home');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: "text-header-xl font-interface-bold pb-6" }, isCompleted ? '🎉 Success!' : '🤔 Until next time?'),
        React.createElement("div", { className: "mx-23 mb-7" }, isCompleted ? (React.createElement(React.Fragment, null,
            React.createElement("p", null, "We are excited to have you on board!"),
            React.createElement("p", { className: "my-6" },
                "You are now active on\u00A0",
                isConversionSelected && isCommunitySelected
                    ? 'P2P Conversion and P2P Community'
                    : isCommunitySelected
                        ? 'P2P Community'
                        : 'P2P Conversion',
                ". Your ambassadors received an email containing the additional training required."),
            React.createElement("p", { className: "mb-6" }, "You can always change the list of active ambassadors in the dashboard, and also activate or de-activate some of the services at no extra cost or penalties."),
            React.createElement("p", { className: "mb-1" },
                "Feel free to contact your Customer Success Manager if you have any additional questions about TAP x IDP Joint Service offering or write to us at\u00A0",
                React.createElement("span", { className: "text-brand-orange" }, "support@theambassadorplatform.com.")))) : (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                "Dear ", accountInfo === null || accountInfo === void 0 ? void 0 :
                accountInfo.name,
                ","),
            React.createElement("p", { className: "my-6" }, "We are sorry to see that you have completed the onboarding flow without activation of any of the IDP Joint Services."),
            React.createElement("p", { className: "mb-6" },
                "You can always change your mind by going through the flow one more time. Just\u00A0",
                React.createElement("span", { className: "font-interface-bold" }, "click on the IDP icon in the dashboard"),
                " and you will be able to go though these steps again."),
            React.createElement("p", { className: "mb-1" },
                "Feel free to contact your Customer Success Manager if you have any additional questions about TAP x IDP Joint Service offering or write to us at\u00A0",
                React.createElement("span", { className: "text-brand-orange" }, "support@theambassadorplatform.com."))))),
        React.createElement(Button, { className: "process-btn", onClick: onClose }, "Close")));
};
