export function debounce(fn, ms) {
    let timer
    return (_) => {
        clearTimeout(timer)
        timer = setTimeout((_) => {
            timer = null
            Reflect.apply(fn, this, arguments)
        }, ms)
    }
}
