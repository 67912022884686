import { PhoneFilled, FullscreenExitOutlined, FullscreenOutlined, SoundOutlined, StopOutlined } from '@ant-design/icons'
import { Row, Col, Space, Button, Tooltip } from 'antd'
import React, { useCallback, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as ScreenIcon } from '../../../../../../../../assets/svg/icons/screen.svg'
import { ReactComponent as MicIcon } from '../../../../../../../../assets/svg/icons/mic.svg'
import { ReactComponent as MicIconOff } from '../../../../../../../../assets/svg/icons/mic-off.svg'
import { ReactComponent as CameraIcon } from '../../../../../../../../assets/svg/icons/camera.svg'
import { ReactComponent as CameraIconOff } from '../../../../../../../../assets/svg/icons/camera-off.svg'

import { getEventStreamSlice } from '../../../../../../../redux/selectors/common-selectors'

const StreamControls = ({
    visible,
    onMuteAudioClick,
    onMuteVideoClick,
    onLeaveClick,
    onShareScreenClick,
    onMuteRemoteAudioClick,
}) => {
    const {
        joined,
        settings: { audioMuted, videoMuted, streamAudioMuted },
        currentParticipant: { name, isAdminSpeaker },
    } = useSelector(getEventStreamSlice)

    const wrapperRef = useRef(null)

    const getPopupContainer = useCallback(() => wrapperRef.current, [])

    const shareScreenButton = useMemo(() => {
        return (
            onShareScreenClick && (
                <Tooltip placement="top" title="Share screen">
                    <div className="control-wrapper" onClick={onShareScreenClick}>
                        <ScreenIcon />
                    </div>
                </Tooltip>
            )
        )
    }, [onShareScreenClick])

    const audioButton = useMemo(() => {
        const icon = audioMuted ? <MicIconOff /> : <MicIcon />
        const tooltipTitle = audioMuted ? 'Unmute mic' : 'Mute mic'

        return (
            <Tooltip placement="top" title={tooltipTitle}>
                <div className="control-wrapper" onClick={onMuteAudioClick}>
                    {icon}
                </div>
            </Tooltip>
        )
    }, [audioMuted, onMuteAudioClick])

    const videoButton = useMemo(() => {
        const icon = videoMuted ? <CameraIconOff /> : <CameraIcon />
        const tooltipTitle = videoMuted ? 'Unmute camera' : 'Mute camera'

        return (
            <Tooltip placement="top" title={tooltipTitle}>
                <div className="control-wrapper" onClick={onMuteVideoClick}>
                    {icon}
                </div>
            </Tooltip>
        )
    }, [videoMuted, onMuteVideoClick])

    const leaveButton = useMemo(
        () => onLeaveClick && <Button type="primary" danger icon={<PhoneFilled />} onClick={onLeaveClick} />,
        [onLeaveClick]
    )

    const toggleFullscreen = useCallback(() => {
        const elem = document.querySelector('.tap-live-event-block-wrapper')

        if (!document.fullscreenElement) {
            elem.requestFullscreen().catch((error) => {
                window.alert(`Error attempting to enable full-screen mode: ${error.message} (${error.name})`)
            })
        } else {
            document.exitFullscreen()
        }
    }, [])

    const fullScreenButton = useMemo(() => {
        const icon = document.fullscreenElement ? <FullscreenExitOutlined /> : <FullscreenOutlined />
        const tooltipTitle = !document.fullscreenElement ? 'Enter fullscreen' : 'Exit fullscreen'

        return (
            joined && (
                <Tooltip placement="top" title={tooltipTitle} getTooltipContainer={getPopupContainer}>
                    <Button type="text" onClick={toggleFullscreen} className="btn-fullscreen">
                        {icon}
                    </Button>
                </Tooltip>
            )
        )
    }, [toggleFullscreen, joined, getPopupContainer])

    const streamControls = useMemo(() => {
        return (
            isAdminSpeaker && (
                <Row justify="center">
                    <Col>
                        <Space>
                            {audioButton} {videoButton} {shareScreenButton} {leaveButton}
                        </Space>
                    </Col>
                </Row>
            )
        )
    }, [audioButton, videoButton, leaveButton, shareScreenButton, isAdminSpeaker])

    const muteStreamButton = useMemo(() => {
        const tooltipTitle = streamAudioMuted ? 'Unmute sound' : 'Mute sound'
        const icon = streamAudioMuted ? <StopOutlined /> : <SoundOutlined />

        return (
            !isAdminSpeaker &&
            joined && (
                <Tooltip placement="top" title={tooltipTitle} getTooltipContainer={getPopupContainer}>
                    <Button type="text" onClick={onMuteRemoteAudioClick}>
                        {icon}
                    </Button>
                </Tooltip>
            )
        )
    }, [isAdminSpeaker, streamAudioMuted, onMuteRemoteAudioClick, joined])

    return (
        visible && (
            <div className="tap-event-stream-controls" ref={wrapperRef}>
                <Row align="middle">
                    <Col span={24}>
                        <Row justify="space-between" align="middle" gutter={16}>
                            <Col span={6}>{name}</Col>
                            <Col span={12}>{streamControls}</Col>
                            <Col span={6} style={{ textAlign: 'right' }}>
                                {muteStreamButton}
                                {fullScreenButton}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    )
}

export default StreamControls
