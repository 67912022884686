import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import pencil from '../../../../../../assets/img/pencil.png'

import { getUsersSlice } from '../../../../../redux/selectors'

import { changeAmbassadorStudentType, getStudentTypes } from '../../../../../redux/actions/content/usersActions'

const { Option } = Select

const AmbassadorStudentType = ({ studentType, userId, saveStudentInfo }) => {
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()

    const { studentTypes: typeOfStudent } = useSelector(getUsersSlice)
    useEffect(() => {
        dispatch(getStudentTypes({ orderBy: 'id' }))
    }, [dispatch])

    const onFinish = useCallback(
        (values) => {
            dispatch(
                changeAmbassadorStudentType({
                    userId,
                    data: typeOfStudent?.filter((item) => item.id === values.studentType),
                })
            )

            setEditMode(false)
            saveStudentInfo()
        },
        [dispatch, typeOfStudent, userId, saveStudentInfo]
    )

    const editType = useCallback(() => {
        setEditMode(true)
    }, [])

    const onCancel = useCallback(() => {
        setEditMode(false)
    }, [])

    const options = useMemo(
        () =>
            typeOfStudent?.map((country) => {
                return (
                    <Option key={country.id} value={country.id} label={country.name}>
                        {country.name}
                    </Option>
                )
            }),
        [typeOfStudent]
    )

    return editMode ? (
        <>
            <Form
                initialValues={{
                    studentType: studentType && studentType[0] ? studentType[0].id : '',
                }}
                onFinish={onFinish}
                autoComplete="off"
                hideRequiredMark
            >
                <Form.Item
                    name="studentType"
                    label={null}
                    style={{ marginBottom: 20 }}
                    rules={[{ required: true, message: 'Select student type' }]}
                >
                    <Select
                        optionFilterProp="children"
                        optionLabelProp="label"
                        style={{ fontSize: 16, maxWidth: 200, minWidth: 150 }}
                    >
                        {options}
                    </Select>
                </Form.Item>
                <div className="input__buttons">
                    {studentType && (
                        <button className="input-control-button" onClick={onCancel}>
                            Cancel
                        </button>
                    )}
                    <button className="input-control-button" type="submit">
                        Save
                    </button>
                </div>
            </Form>
        </>
    ) : (
        <>
            <div className="profile-info-tags-items">
                {studentType && studentType[0] ? studentType[0].name : <p style={{ color: 'red' }}>Please, specify</p>}
                <button className="input-control-button" onClick={editType}>
                    <img className="pencil-icon" src={pencil} alt="" />
                </button>
            </div>
        </>
    )
}

export default AmbassadorStudentType
