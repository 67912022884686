import { call, put } from 'redux-saga/effects'
import API from '../../../api'

import { COUNTRIES } from '../../actions/settings/countries/countries-action-types'

export function* getCountriesList(action) {
    const payload = { ...action.payload, source: 'dashboard' }
    try {
        const response = yield call(() => {
            return API.settingsNew.getCountriesList(payload)
        })

        if (response.success) {
            yield put({
                type: COUNTRIES.GET_COUNTRIES_SUCCESS,
                payload: response.data,
            })
        }
    } catch {
        yield put({
            type: COUNTRIES.GET_COUNTRIES_FAILED,
        })
    }
}
