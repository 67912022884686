import { call, put, select } from 'redux-saga/effects'
import API from '../../../api'

import { GET_TIMEZONES_SUCCESS, SET_TIMEZONE_SUCCESS } from '../../actions/settings/timezonesActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* getTimezones(action) {
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)
        const response = yield call(() => {
            return API.settings.getTimezones(isGroupAdmin && !universityId ? null : universityId)
        })

        if (response.success) {
            yield put({
                type: GET_TIMEZONES_SUCCESS,
                payload: {
                    items: response.data && response.data.timezones ? response.data.timezones : [],
                },
            })
        }
    } catch (e) {
        console.log(e)
    }
}

export function* setTimezone(action) {
    const { payload } = action
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return API.settings.setTimezone(payload.timezoneId, isGroupAdmin && !universityId ? null : universityId)
        })
        if (response.success) {
            yield put({
                type: SET_TIMEZONE_SUCCESS,
                payload: {
                    timezoneId: payload.timezoneId,
                },
            })
            action.onSuccess()
        }
    } catch (e) {}
}
