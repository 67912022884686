import React from 'react'
import { useSelector } from 'react-redux'
import plusIcon from '../../../../../../assets/svg/icons/plus-circle.svg'
import Popover from 'antd/es/popover'
import { ReactComponent as Logo } from '../../../../../../assets/img/svg/close.svg'

const TopicsHeader = ({ dialogId, dialogMessages, setTopicStatus, openDeleteConfirmModal, openTopicsPopup }) => {
    const chats = useSelector((state) => state.chats)
    const chat = chats.items.filter((item) => +item.id === +dialogId)[0] || {}
    if (dialogMessages.isLoading || !chat.id || chat.is_prospect_deleted) {
        return null
    }

    return (
        <div className="chat-topics">
            <div className="chat-head-left">
                {dialogMessages.topics && dialogMessages.topics.length > 0 && (
                    <>
                        <span className="chat-topics-title">Topics:</span>
                        <div className="chat-topics-block scroll-8">
                            {dialogMessages.topics.map((item, index) => {
                                return (
                                    <div key={index} className="topic-item">
                                        {item.confirmation !== 'added' ? (
                                            <Popover
                                                placement="bottomLeft"
                                                overlayClassName="topic-item-popover"
                                                content={
                                                    <div className="topic-item-popover-body">
                                                        <p>
                                                            Is the topic{' '}
                                                            <span className="tap-orange">&#39;{item.name}&#39;</span>{' '}
                                                            mentioned in this conversation?
                                                        </p>
                                                        <div className="topic-feedback-buttons">
                                                            <button onClick={(e) => setTopicStatus(item, true)}>
                                                                Yes
                                                            </button>
                                                            <button onClick={(e) => setTopicStatus(item)}>No</button>
                                                        </div>
                                                    </div>
                                                }
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <span
                                                    className={
                                                        'topic-item-text ' +
                                                        (item.confirmation === 'confirmed' ||
                                                        item.confirmation === 'added'
                                                            ? 'topic-item-confirmed'
                                                            : item.confirmation === 'removed'
                                                            ? 'topic-item-removed'
                                                            : 'topic-item-new')
                                                    }
                                                >
                                                    {item.name}
                                                </span>
                                            </Popover>
                                        ) : (
                                            <>
                                                <span className="topic-item-text topic-item-confirmed">
                                                    {item.name}
                                                </span>
                                                <Logo
                                                    onClick={() => openDeleteConfirmModal(item)}
                                                    className="topic-delete"
                                                />
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )}
            </div>
            <div className="chat-head-right">
                <div className="add-topic-block" onClick={openTopicsPopup}>
                    <img src={plusIcon} alt="add" />
                    <span>Add topic</span>
                </div>
            </div>
        </div>
    )
}

export default TopicsHeader
