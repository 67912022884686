var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OPEN_CONTENT_GROUP_POPUP, CLOSE_CONTENT_GROUP_POPUP } from '../../../actions/content/messagingActions';
import { ContentGroupTypeEnum } from 'src/types/general';
var initialState = {
    isOpened: false,
    groupType: ContentGroupTypeEnum.Content,
};
export default function contentGroupModalReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type;
    var payload = action.payload;
    switch (type) {
        case OPEN_CONTENT_GROUP_POPUP: {
            var newState = __assign(__assign({}, state), { isOpened: true, groupType: payload || initialState.groupType });
            return newState;
        }
        case CLOSE_CONTENT_GROUP_POPUP: {
            var newState = __assign(__assign({}, state), { isOpened: false });
            return newState;
        }
        default: {
            return state;
        }
    }
}
