import React, { Component } from 'react'
import { Select } from 'antd'

class SearchCriteria extends Component {
    render() {
        return (
            <Select
                value={this.props.value}
                placeholder={this.props.placeholder}
                onChange={(value) => this.props.onChange(value)}
                style={this.props.styles}
                popupClassName="search-criteria-dropdown"
            >
                {this.props.options.map((item) => (
                    <Select.Option key={item.key} value={item.key}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
        )
    }
}

export default SearchCriteria
