import React, { PureComponent } from 'react'

class Scripts extends PureComponent {
    render() {
        return (
            <div>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/svg4everybody/2.1.9/svg4everybody.js"></script>
            </div>
        )
    }
}

export default Scripts
