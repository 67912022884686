import React, { useMemo } from 'react'

import Header from '../../_layout/header'
import NavigationSetupWizard from '../../_layout/navSetupWizard'

import { SetupTitle } from '../components'
import { OrganizationDetailsForm } from './components'
import { useOrganizationDetailsForm } from './use-organisation-details-form'
import LogOutButton from '../../../components/log-out-button/log-out-button'

const OrganisationDetailsScreen = () => {
    const { form, onSubmit, initialValues, onInputChange, institutionNameLength } = useOrganizationDetailsForm()

    const description = useMemo(() => {
        return (
            <p className="setup-start-block-description">
                Many thanks for setting up your free trial. We're going to run you through a few easy steps to get you
                up and running, this shouldn't take more than 10 minutes and you don't need to add any payment details.
                <br />
                All fields marked with <span style={{ color: '#ff4d4f' }}>*</span> shall be filled
            </p>
        )
    }, [])

    return (
        <div className="setup-organization-details">
            <Header submenuUnActive={true} />
            <div id="page-wrapper" className="page-wrapper">
                <NavigationSetupWizard />
                <section className="content settings no-submenu home-start-guide setup-wizard">
                    <LogOutButton step={1} />
                    <div className="start-guide-wizard-grid pls">
                        <div className="column pls">
                            <SetupTitle
                                blockTitle="Add logo and institution website link"
                                blockDescription={description}
                                title="The Ambassador Platform Account Setup Guide"
                            />
                            <OrganizationDetailsForm
                                form={form}
                                initialValues={initialValues}
                                institutionNameLength={institutionNameLength}
                                onInputChange={onInputChange}
                                onSubmit={onSubmit}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default OrganisationDetailsScreen
