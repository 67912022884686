import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'antd'

import { UNIVERSITY_CHANGE_AVATAR } from '../../../../../redux/actions/account/accountActions'
import { getUniversityInfo } from '../../../../../redux/selectors'

import tipIcon from '../../../../../../assets/img/setup/tip.png'
import universityDefaultAvatar from '../../../../../../assets/img/university_default_avatar.jpeg'

const OrganizationLogo = ({ form }) => {
    const dispatch = useDispatch()
    const universityInfo = useSelector(getUniversityInfo)
    const [avatar, setAvatar] = useState(null)

    const handleChangeAvatarInput = useCallback(
        (event) => {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.addEventListener('load', function () {
                dispatch({
                    type: UNIVERSITY_CHANGE_AVATAR,
                    payload: {
                        avatar: reader.result.slice(reader.result.indexOf(',') + 1),
                    },
                })
            })
        },
        [dispatch]
    )

    const logo = useMemo(() => {
        if (universityInfo.avatar?.original) {
            form.setFieldsValue({
                organizationLogo: true,
            })

            setAvatar(universityInfo.avatar.sizes['160x160'] || universityInfo.avatar.original)

            return <img className="setup-university-logo-img" src={avatar || universityDefaultAvatar} alt="Logo" />
        } else {
            form.setFieldsValue({
                organizationLogo: '',
            })

            return <span className="setup-university-logo-img setup-university-logo-img-empty">+ Add logo</span>
        }
    }, [universityInfo, avatar, form])

    return (
        <div>
            <div className="tip-block branding-tip">
                <img src={tipIcon} alt="tip" />
                <span>Preferably in a square format, minimum 300x300 pixels, maximum 1000x1000 pixels.</span>
            </div>
            <div className="setup-university-logo-block with-avatar">
                <div className="setup-university-logo-preview">
                    <div className="edit-university-block" onChange={handleChangeAvatarInput}>
                        <label className={'edit-university-label' + (avatar ? ' with-avatar' : '')}>
                            <Input className="edit-university-input" accept="image/x-png,image/jpeg" type="file" />
                            {avatar && <span className="edit-university-text">Edit</span>}
                        </label>
                        {logo}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrganizationLogo
