import React, { PureComponent } from 'react'
import { Table } from 'antd'
import store from '../../../redux/store'

import {
    formatEngagementRatings,
    formatEngagementData,
    formatArrayOfUniversities,
    formatHeatScore,
    formatName,
    formatArrayUniversitiesOfTotalMessages,
    formatArrayOfUniversitiesAllQuestionsAnswered,
    formatLikelyToApply,
} from '../../../helpers/table'
import { OPEN_USER_INFO_MODAL } from '../../../redux/actions/components/componentsActions'
import { isMenuItemAllowed } from '../../../common/utils/user-account-helpers'

const prepareTableData = (data) => {
    if (!data) {
        return []
    }
    return data.map((item) => {
        return {
            key: item.id,
            fullName: item.full_name,
            email: item.email,
            nationality: item.nationality ? item.nationality : '',
            profile_info: item,
            country: item.country ? item.country : '',
            heat_score: formatHeatScore(item.heat_score || 0),
            averageEngagementFeedback: formatEngagementRatings(item.engagement_data),
            pointsOfEngagement: formatEngagementData(item.engagement_data, item.id),
            relatedAmbassador:
                item.relatedAmbassadors && item.relatedAmbassadors.length > 0
                    ? item.relatedAmbassadors[0].full_name
                    : '',
            universitiesFormatted:
                item.universities && item.universities.length > 0
                    ? formatArrayOfUniversities(item.engagement_data, item.universities, item.id)
                    : [],
            universitiesTotalMessagesFormatted: formatArrayUniversitiesOfTotalMessages(item.engagement_data, item.id),
            universitiesAllQuestionsAnsweredFormatted:
                item.universities && item.universities.length > 0
                    ? formatArrayOfUniversitiesAllQuestionsAnswered(item.engagement_data, item.universities, item.id)
                    : [],
            universityAllQuestionsAnswered: item.all_questions_answered ? 'Yes' : 'No',
            university: item.universities && item.universities.length > 0 ? item.universities[0].name : '',
            isBlocked: item.isBlocked,
            is_likely_to_apply: formatLikelyToApply(item.is_likely_to_apply),
        }
    })
}

class ProspectsTable extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedRowKeys: [],
            lastData: props.data.prospectsData,
            tableData: [],
            unselectAll: false,
        }

        this.columns = this.prepareColumnsData()

        this.handleRowSelection = this.handleRowSelection.bind(this)
        this.handleSelectionChange = this.handleSelectionChange.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.columns = this.prepareColumnsData()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data.prospectsData !== state.lastData) {
            state.lastData = props.data.prospectsData
            const prospectIds = props.data.prospectsData.map((item) => item.id)

            state.unselectAll = !props.areSelectedAll

            state.selectedRowKeys = !state.unselectAll
                ? prospectIds.filter((item) => !props.unselected.has(item))
                : prospectIds.filter((item) => props.selected.has(item))

            state.tableData = prepareTableData(props.data.prospectsData)

            return state
        }

        return null
    }

    prepareColumnsData() {
        const account = this.props.account

        const {
            account_info: { isGroupAdmin, university = {} },
            currentGroupUniversityId,
        } = account
        const { permissions = [] } = university

        const columnsData = [
            {
                title: 'Name',
                dataIndex: 'fullName',
                key: 'full_name',
                width: 150,
                render: (text, record) => (
                    <div
                        onClick={() => this.handleOpenUserPopup(record.profile_info)}
                        style={{ textDecoration: 'underline', width: 'max-content', cursor: 'pointer' }}
                    >
                        {formatName(text, record.isBlocked)}
                    </div>
                ),
                sorter: (a, b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase() ? 1 : -1),
            },
            {
                title: 'Nationality',
                dataIndex: 'nationality',
                key: 'nationality',
                width: 100,
                sorter: true,
            },
            {
                title: 'Point of engagement',
                dataIndex: 'pointsOfEngagement',
                key: 'engagement_point',
                width: 150,
            },
            {
                title: 'Total messages',
                dataIndex: 'universitiesTotalMessagesFormatted',
                key: 'university_total_messages',
                width: 100,
            },
        ]
        if (isGroupAdmin) {
            columnsData.push(
                {
                    title: 'Answered additional data',
                    dataIndex: 'universitiesAllQuestionsAnsweredFormatted',
                    key: 'all_questions_answered',
                    width: 100,
                },
                {
                    title: 'Institution',
                    dataIndex: 'universitiesFormatted',
                    key: 'university_name',
                    width: 100,
                }
            )
        } else {
            columnsData.push({
                title: 'Answered additional data',
                dataIndex: 'universityAllQuestionsAnswered',
                key: 'all_questions_answered',
                width: 100,
            })
        }
        columnsData.push({
            title: 'Feedback',
            dataIndex: 'averageEngagementFeedback',
            key: 'middleRate',
            width: 85,
        })

        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            columnsData.push({
                title: 'Heat Score',
                dataIndex: 'heat_score',
                key: 'heat_score',
                width: 100,
                sorter: true,
            })
        }
        columnsData.push({
            title: 'More likely to choose this institution',
            dataIndex: 'is_likely_to_apply',
            key: 'is_likely_to_apply',
            width: 85,
            sorter: true,
        })
        return columnsData.filter((column) => {
            if (column.key === 'full_name' || column.key === 'profile_info' || column.key === 'engagement_point') {
                return isMenuItemAllowed('view_prospect_profile', isGroupAdmin, permissions)
            }

            return true
        })
    }

    handleRowSelection() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            unselectAll: false,
            onChange: this.handleSelectionChange,
            hideDefaultSelections: true,
            selections: [
                {
                    key: 'all-data',
                    text: 'Select All',
                    onSelect: () => {
                        this.setState({
                            selectedRowKeys: this.state.lastData.map((item) => item.id),
                            unselectAll: false,
                        })
                        this.props.onSelectionChange(null, null, true)
                    },
                },
                {
                    key: 'no-data',
                    text: 'Unselect All',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [], unselectAll: true })
                        this.props.onSelectionChange(null, null, false)
                    },
                },
            ],
        }

        return rowSelection
    }

    handleSelectionChange(selectedRowKeys) {
        const addedItems = []
        const removedItems = []

        if (selectedRowKeys.length > this.state.selectedRowKeys.length) {
            const newItems = new Set(this.state.selectedRowKeys)
            for (const item of selectedRowKeys) {
                if (!newItems.has(item)) {
                    addedItems.push(item)
                }
            }
        } else {
            const previousItems = new Set(selectedRowKeys)
            for (const item of this.state.selectedRowKeys) {
                if (!previousItems.has(item)) {
                    removedItems.push(item)
                }
            }
        }

        this.setState({ selectedRowKeys })
        this.props.onSelectionChange(addedItems, removedItems)
    }

    handleOpenUserPopup = (userInfo) => {
        store.dispatch({
            type: OPEN_USER_INFO_MODAL,
            payload: { userInfo },
        })
    }

    render() {
        const { tableData } = this.state
        const { handleTableChange, data, currentPage } = this.props

        return (
            <div
                className="users-container flexible-page table-wrapper"
                style={{
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                }}
            >
                <Table
                    rowSelection={this.handleRowSelection()}
                    columns={this.columns}
                    dataSource={tableData}
                    pagination={{
                        total: data && data.total ? data.total : 0,
                        size: 'small',
                        current: currentPage,
                    }}
                    onChange={handleTableChange}
                />
            </div>
        )
    }
}

export default ProspectsTable
