import { NOTIFICATION_MODAL } from '../actions/notification-modal/notification-modal-action-types'

const INITIAL_STATE = {
    open: false,
    type: undefined,
    data: {},
}

export default (state = INITIAL_STATE, action) => {
    const { type, payload = {} } = action

    const { data = {}, notificationType } = payload

    switch (type) {
        case NOTIFICATION_MODAL.OPEN: {
            return {
                ...state,
                open: true,
                type: notificationType,
                data: data,
            }
        }

        case NOTIFICATION_MODAL.CLOSE: {
            return INITIAL_STATE
        }

        default:
            return state
    }
}
