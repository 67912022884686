import { CLOSE_ACTION_MODAL, OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'

import { ACTION_MODAL } from '../../store/initialState'

const initialState = ACTION_MODAL

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case CLOSE_ACTION_MODAL: {
            return initialState
        }

        case OPEN_ACTION_MODAL: {
            let new_state = Object.assign({}, state)
            new_state.isOpened = true
            new_state.title = payload.title
            new_state.body = payload.body
            new_state.children = payload.children
            new_state.hasOutsideClickClose = !payload.noOutsideClickClose
            new_state.className = payload.className
            return new_state
        }

        default: {
            return state
        }
    }
}
