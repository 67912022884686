import { connect } from 'react-redux'
import React, { PureComponent } from 'react'

class DetachedAmbassadorMessage extends PureComponent {
    render() {
        const { message } = this.props
        const { avatar, name } = message.user
        const { user, ambassador, ambassador_avater: ambassadorAvatar } = message.content

        return (
            <div className="join-row">
                <div className="msg-avatar-block">
                    {message.user && avatar ? (
                        <img className="msg-item-img" src={avatar.sizes['70x70'] || avatar.original} />
                    ) : (
                        <div className="msg-avatar-text">{message.user ? name.charAt(0) : ''}</div>
                    )}
                </div>
                <p className="join-text">{user} removed</p>
                <div className="msg-avatar-block">
                    {message.content && ambassadorAvatar ? (
                        <img className="msg-item-img" src={ambassadorAvatar} />
                    ) : (
                        <div className="msg-avatar-text">{message.user ? name.charAt(0) : ''}</div>
                    )}
                </div>
                <p className="join-text">
                    {ambassador + ' '}
                    from the chat
                </p>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(DetachedAmbassadorMessage)
