import React from 'react'

import { useSelector } from 'react-redux'
import { getPlsWizardSetup } from '../../../../../redux/selectors'

const TapFeedCredentialsSection = () => {
    const plsWizardSetup = useSelector(getPlsWizardSetup)
    const { prospect } = plsWizardSetup

    const prospectPassword = window.localStorage.getItem('PlsProspectLoginPassword')
    return (
        <div className="settings-item">
            <label className="settings-name">
                2. Log in to the TAP Feed{' '}
                <span className="label-rest-text">as a prospect (to try it out) using the following credentials:</span>
            </label>
            <div>
                <p className="credential-item">
                    Login <span> - {prospect?.email}</span>
                </p>
                <p className="credential-item">
                    Password <span> - {prospectPassword}</span>
                </p>
            </div>
        </div>
    )
}

export default TapFeedCredentialsSection
