import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { CLOSE_DIALOG, EDIT_CHAT } from '../../../../../redux/actions/chat/messagingActions'
import {
    CLOSE_ACTION_MODAL,
    CLOSE_SETTINGS_MESSAGING_POPUP,
    OPEN_ACTION_MODAL,
} from '../../../../../redux/actions/components/componentsActions'
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions'
import store from '../../../../../redux/store'

import SettingsChatForm from './../forms/SettingsChatForm'

import { ChatType } from '../../../../../common/constants'

// we are using it for chats too
class SettingsChat extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            chat: null,
            isConfirmationPopupOpened: false,
            hasFormBeenUpdated: false,
        }

        this.closeConfirmationPopup = this.closeConfirmationPopup.bind(this)
        this.handleSearchUpdated = this.handleSearchUpdated.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleEscPress = this.handleEscPress.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.setFormUpdatedStatus = this.setFormUpdatedStatus.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
        this.handleOpenConfirmPopup = this.handleOpenConfirmPopup.bind(this)
    }

    componentDidMount() {
        const { chats, currentChat } = this.props

        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
            },
        })

        let chat = null
        if (chats.items) {
            chat = chats.items.find((item) => +item.id === currentChat.activeChat)
        }
        this.setState({
            chat: chat,
        })

        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('keydown', this.handleEscPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('keydown', this.handleEscPress, false)
    }

    closeConfirmationPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })

        this.setState({ isConfirmationPopupOpened: false })
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            this.state.hasFormBeenUpdated &&
            !this.state.isConfirmationPopupOpened
        ) {
            const confirmByClickOutside = confirm(
                'Are you sure you want to close this popup? Changes will not be saved'
            )
            if (confirmByClickOutside) {
                this.props.onClosePopup()
            }
        }
    }

    handleEscPress(event) {
        const escapeButtonCode = 27
        if (event.keyCode !== escapeButtonCode) {
            return
        }

        if (this.state.isConfirmationPopupOpened) {
            return
        }

        if (!this.state.hasFormBeenUpdated) {
            this.props.onClosePopup()
            return
        }

        const confirmByEscPress = confirm('Are you sure you want to close this popup? Changes will not be saved')
        if (confirmByEscPress) {
            this.props.onClosePopup()
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    handleSearchUpdated(value) {
        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    keyword: value,
                    permission: 'chat',
                },
            },
        })
    }

    setFormUpdatedStatus(value) {
        this.setState({ hasFormBeenUpdated: value })
    }

    handleFormSubmit(values) {
        const { account } = this.props

        store.dispatch({
            type: EDIT_CHAT,
            payload: {
                name: values.name,
                firstMessage: values.firstMessage,
                users: values.selectedUserIds,
                globalTagsIds: values.globalTagsIds,
                is_private: values.is_private,
                dialog_id: this.state.chat.id,
                university_id: account.account_info.university_id,
                type_chat: ChatType.GROUP_CHAT,
            },
        })
    }

    handleOpenConfirmPopup() {
        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                children: (
                    <div className="general-popup">
                        <h2 className="modal-title">Are you sure?</h2>
                        <div className="btn-wrap confirm-ban-buttons">
                            <button type="submit" className="el-btn confirm-delete-button" onClick={this.closeDialog}>
                                Confirm
                            </button>
                            <button
                                type="button"
                                className="el-btn cancel-delete-button"
                                onClick={this.handleCloseConfirmPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                ),
            },
        })
    }

    handleCloseConfirmPopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    closeDialog() {
        const onSuccess = () => {
            this.setState({
                chat: {
                    ...this.state.chat,
                    closed: !this.state.chat.closed,
                },
            })

            store.dispatch({
                type: CLOSE_SETTINGS_MESSAGING_POPUP,
            })
        }
        store.dispatch({
            type: CLOSE_DIALOG,
            payload: {
                dialogId: this.props.currentChat.activeChat,
                type_chat: ChatType.GROUP_CHAT,
            },
            onSuccess,
        })
    }

    render() {
        const { usersMessaging, currentChat } = this.props
        const { chat, hasFormBeenUpdated } = this.state

        if (!chat) return null

        let firstMessage = ''
        if (currentChat.data.first_message) {
            firstMessage =
                currentChat.data.first_message.content && currentChat.data.first_message.content.text
                    ? currentChat.data.first_message.content.text
                    : ''
        }
        return (
            <div ref={this.setWrapperRef}>
                <div className="create-content-group">
                    <h2 className="modal-title">Edit Group Chat</h2>
                    <SettingsChatForm
                        chat={chat}
                        firstMessage={firstMessage}
                        onSubmit={this.handleFormSubmit}
                        onSearchUpdated={this.handleSearchUpdated}
                        ambassadors={usersMessaging.ambassadors}
                        areAmbassadorsLoading={usersMessaging.isLoading}
                        setFormUpdatedStatus={this.setFormUpdatedStatus}
                        hasFormBeenUpdated={hasFormBeenUpdated}
                        onDeactivateChatGroupButtonClick={this.handleOpenConfirmPopup}
                    />
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        chats: state.chats,
        currentChat: state.currentChat,
        account: state.account,
        usersMessaging: state.usersMessaging,
        dialogMessages: state.chatMessages,
    }
})(SettingsChat)
