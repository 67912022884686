import ProspectInfoPopup from './ProspectInfoPopup'
import Modal from 'antd/es/modal'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLOSE_USER_INFO_MODAL } from '../../../redux/actions/components/componentsActions'
import {
    PROSPECT_FEEDBACK_DATA_REQUESTED,
    PROSPECT_MANDATORY_DATA_REQUESTED,
    PROSPECT_OPTIONAL_DATA_REQUESTED,
} from '../../../redux/actions/reporting/prospectsActions'

const ProspectModal = ({ tableDataProps, prospectInfoChat }) => {
    const dispatch = useDispatch()
    const account = useSelector((state) => state.account)
    const { isOpened, userInfo } = useSelector((state) => state.userInfoPopup)

    const handleCloseUserPopup = useCallback(() => {
        dispatch({
            type: CLOSE_USER_INFO_MODAL,
        })
    }, [dispatch])

    const loadOptionalData = useCallback(() => {
        dispatch({
            type: PROSPECT_OPTIONAL_DATA_REQUESTED,
            prospectId: userInfo?.id,
        })
    }, [dispatch, userInfo])

    const loadMandatoryData = useCallback(
        (prospect) => {
            dispatch({
                type: PROSPECT_MANDATORY_DATA_REQUESTED,
                prospectId: userInfo?.id,
            })
        },
        [dispatch, userInfo]
    )

    const loadFeedbackData = useCallback(
        (prospect) => {
            dispatch({
                type: PROSPECT_FEEDBACK_DATA_REQUESTED,
                prospectId: userInfo?.id,
            })
        },
        [dispatch, userInfo]
    )

    return (
        <Modal
            open={isOpened && userInfo}
            width={700}
            height={600}
            style={{ top: '50px', minWidth: '630px' }}
            bodyStyle={{ height: '600px' }}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={handleCloseUserPopup}
            className="user-profile-modal"
            destroyOnClose={true}
        >
            <ProspectInfoPopup
                account={account}
                user={userInfo}
                loadOptionalData={loadOptionalData}
                loadMandatoryData={loadMandatoryData}
                loadFeedbackData={loadFeedbackData}
                handleCloseUserPopup={handleCloseUserPopup}
                tableDataProps={tableDataProps}
                prospectInfoChat={prospectInfoChat}
            />
        </Modal>
    )
}

export default ProspectModal
