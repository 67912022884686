import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Upload, message, Form, Row, Col } from 'antd'
import React, { useMemo } from 'react'

const getBase64 = (img, callback) => {
    try {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        // reader.addEventListener('load', () => callback(reader.result.slice(reader.result.indexOf(',') + 1)))
        reader.readAsDataURL(img)
    } catch (error) {
        console.log(error)
    }
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file')
    }
    return isJpgOrPng
}

const CoverImage = ({ imageState, setImageState, form }) => {
    const handleFileChange = (info) => {
        if (info.file.status === 'uploading') {
            setImageState((prevState) => ({ ...prevState, loading: true }))
            return
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                console.log('imageUrl', imageUrl)
                setImageState({
                    imageUrl,
                    loading: false,
                })
                form.setFieldsValue({
                    image: imageUrl,
                })
            })
        }
    }

    const uploadButton = (
        <div>
            {imageState.loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )

    const label = useMemo(
        () => (
            <>
                <Row gutter={8}>
                    <Col>
                        <span style={{ color: 'red' }}>*</span> Cover image
                    </Col>
                </Row>
            </>
        ),
        []
    )
    return (
        <>
            <Form.Item label={label}>
                <></>
                <Upload
                    accept=".png, .jpg, .jpeg"
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader tap-upload-field"
                    customRequest={({ onSuccess }) => onSuccess()}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleFileChange}
                >
                    {imageState.imageUrl ? (
                        <img src={imageState.imageUrl} alt="Cover image" style={{ width: '100%' }} />
                    ) : (
                        uploadButton
                    )}
                </Upload>
                <Form.Item
                    noStyle
                    name="image"
                    rules={[
                        {
                            required: true,
                            message: 'Upload image',
                        },
                    ]}
                >
                    <></>
                </Form.Item>
            </Form.Item>
        </>
    )
}

export default CoverImage
