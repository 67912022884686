import React, { PureComponent } from 'react'
import closeIcon from '../../../assets/svg/icons/close.svg'
import deleteQuestionImg from '../../../assets/img/deleteQuestion.png'

const deleteItem = ({ handleClosePopup, type }) => {
    return (
        <>
            <img className="modal__closeIcon" src={closeIcon} alt="close" onClick={handleClosePopup} />
            <img className="modal__img" src={deleteQuestionImg} alt="img" />
            <h3 className="modal__title modal__title--upd">{`You are going to delete ${
                type === 'answer' ? 'an' : 'a'
            } ${type}`}</h3>
            <span className="modal__warning">You have no chance to restore it in the future</span>
            <span className="modal__warning-question">Are you sure?</span>
        </>
    )
}

class ConfirmDeletePopup extends PureComponent {
    render() {
        const { handleConfirmDelete, handleClosePopup, type = '' } = this.props

        return (
            <div className="confirm-delete-popup modal">
                {(type === 'question' || 'answer') && deleteItem({ type, handleClosePopup })}
                {type !== 'question' && type !== 'answer' && (
                    <h3 className="modal__title">Are you sure that you want to delete this?</h3>
                )}
                <div className="btn-wrap modal__buttons">
                    <button type="button" className="cancel-delete-button" onClick={handleClosePopup}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="el-btn confirm-delete-button"
                        onClick={() => {
                            handleClosePopup()
                            handleConfirmDelete()
                        }}
                    >
                        Delete
                    </button>
                </div>
            </div>
        )
    }
}

export default ConfirmDeletePopup
