import Modal from 'antd/es/modal'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import store from '../../../redux/store'
import ChatsSidebar from './components/ChatsSidebar'
import Messages from './components/Messages'

import {
    CHATS_INFO_SEARCH,
    CHAT_INFO_SEARCH,
    CHAT_MESSAGES_INFO,
    CLEAR_CHAT_MESSAGES,
    SET_ACTIVE_CHAT,
    resetSearchFilters,
} from '../../../redux/actions/chat/messagingActions'

import { CLOSE_SETTINGS_MESSAGING_POPUP, HIDE_NEW_MESSAGES } from '../../../redux/actions/components/componentsActions'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { SET_CURRENT_SEARCH_LIST_TAGS } from '../../../redux/actions/tags/tagsActions'
import SettingsChat from './components/popups/SettingsChat'

import { Protect } from 'src/app/permissions/Protect'
import { ChatType } from '../../../common/constants'
import ProspectInfoPopup from '../../../components/popups/ProspectInfoPopup'
import { CLOSE_COPY_LINK_POPUP } from '../../../redux/actions/chat/commonActions'
import { CLOSE_CHAT_GROUP_POPUP } from '../../../redux/actions/chat/groupMessagingActions'
import CreateChatGroup from '../../chat/group-chats/components/popups/CreateChatGroup'
import CopyLinkPopup from './components/popups/CopyLinkPopup'

class ChatMessaging extends PureComponent {
    constructor(props) {
        super(props)

        const dialog = window.location.search.split('activeDialog=')[1]
        const activeDialog = dialog ? +dialog.split('&')[0] : null
        this.state = {
            activeDialog: activeDialog,
            activeDialogType: 1,
        }
    }

    componentDidMount() {
        const { activeDialog } = this.state
        store.dispatch({
            type: SET_CURRENT_SEARCH_LIST_TAGS,
            payload: {
                items: [],
            },
        })

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'chat',
            },
        })
        if (activeDialog) {
            const onSuccess = (data) => {
                const selectedDialog = data.dialogs.filter((dialog) => dialog.id == activeDialog)
                if (!selectedDialog) return

                store.dispatch({
                    type: SET_ACTIVE_CHAT,
                    payload: {
                        chat_id: activeDialog,
                        chats_type: 4,
                        data: selectedDialog ? selectedDialog[0] : null,
                    },
                })

                store.dispatch({
                    type: CHAT_MESSAGES_INFO,
                    payload: {
                        dialog_id: activeDialog,
                        type: ChatType.GROUP_CHAT,
                        type_chat: ChatType.GROUP_CHAT,
                    },
                })

                store.dispatch({
                    type: HIDE_NEW_MESSAGES,
                })
            }
            store.dispatch({
                type: CHAT_INFO_SEARCH,
                payload: {
                    search: null,
                    withGlobalTags: true,
                    type_chat: ChatType.GROUP_CHAT,
                },
                onSuccess: onSuccess,
            })
            // window.location.hash = window.location.hash.split('?activeDialog=')[0]
        } else {
            const searchByText = window.location.search.includes('search=')
            const searchByUnanswered = window.location.search.includes('unanswered=true')
            const searchByKeyword = window.location.search.includes('keywords=true')
            const searchByReported = window.location.search.includes('reported=true')
            const searchByUnseen = window.location.search.includes('unread=true')
            const searchByUnseenAdmin = window.location.search.includes('unreviewed=true')

            let search = null
            const searchBy = {
                unanswered: false,
                keywords: false,
                reported: false,
                unseen: false,
                unseenByAdmin: false,
                feedback: false,
                userId: null,
                userName: null,
            }
            if (searchByText) {
                search = window.location.search.split('search=')[1]
                    ? decodeURIComponent(window.location.search.split('search=')[1]).split('&')[0]
                    : null
            }
            if (searchByUnanswered) searchBy.unanswered = true
            if (searchByKeyword) searchBy.keywords = true
            if (searchByReported) searchBy.reported = true
            if (searchByUnseen) searchBy.unseen = true
            if (searchByUnseenAdmin) searchBy.unseenByAdmin = true
            store.dispatch({
                type: CHATS_INFO_SEARCH,
                payload: {
                    search: search,
                    resetSearch: !search,
                    searchBy,
                    withGlobalTags: true,
                    type_chat: ChatType.GROUP_CHAT,
                },
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const searchByUnseenAdmin = window.location.search.includes('unreviewed=true')
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: CLEAR_CHAT_MESSAGES,
            })

            store.dispatch({
                type: SET_ACTIVE_CHAT,
                payload: {
                    chat_id: -1,
                },
            })

            if (!searchByUnseenAdmin) {
                store.dispatch({
                    type: CHATS_INFO_SEARCH,
                    payload: {
                        withGlobalTags: true,
                        type_chat: ChatType.GROUP_CHAT,
                    },
                })
            }
        }
    }

    componentWillUnmount() {
        store.dispatch({
            type: CLEAR_CHAT_MESSAGES,
        })

        store.dispatch({
            type: SET_ACTIVE_CHAT,
            payload: {
                chat_id: -1,
            },
        })

        this.props.resetSearchFilters()
    }

    handleCloseChatGroupPopup = () => {
        store.dispatch({
            type: CLOSE_CHAT_GROUP_POPUP,
        })
    }

    handleCloseChatSettingsPopup = () => {
        store.dispatch({
            type: CLOSE_SETTINGS_MESSAGING_POPUP,
        })
    }

    closeCopyLinkPopup() {
        store.dispatch({
            type: CLOSE_COPY_LINK_POPUP,
        })
    }

    render() {
        const { activeDialog, activeDialogType } = this.state
        const { account, createChatGroupModal, settingsMessagingModal, copyLinkChatGroupModal } = this.props

        return (
            <Protect>
                <div className="content mod-chat group-chats">
                    <div className="chat mod-messaging">
                        <Messages universityID={account.account_info.university_id} />
                        <ChatsSidebar activeDialog={activeDialog} activeDialogType={activeDialogType} />
                    </div>
                </div>

                <Modal
                    open={createChatGroupModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    onCancel={this.handleCloseChatGroupPopup}
                    destroyOnClose
                    className="react-create-chat-group"
                >
                    <CreateChatGroup onClosePopup={this.handleCloseChatGroupPopup} />
                </Modal>

                <Modal
                    open={settingsMessagingModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    onCancel={this.handleCloseChatSettingsPopup}
                    destroyOnClose
                    className="react-edit-chat-group"
                >
                    <SettingsChat onClosePopup={this.handleCloseChatSettingsPopup} />
                </Modal>

                <Modal
                    open={copyLinkChatGroupModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    onCancel={this.closeCopyLinkPopup}
                    destroyOnClose
                    className="react-create-chat-group"
                >
                    <CopyLinkPopup
                        privateCode={copyLinkChatGroupModal.private_code}
                        dialogId={copyLinkChatGroupModal.dialog_id}
                        onClose={this.closeCopyLinkPopup}
                    />
                </Modal>

                <ProspectInfoPopup prospectInfoChat={ChatType.GROUP_CHAT} />
            </Protect>
        )
    }
}

export default connect(
    (state) => {
        return {
            account: state.account,
            createChatGroupModal: state.createChatGroupModal,
            settingsMessagingModal: state.settingsMessagingModal,
            copyLinkChatGroupModal: state.copyLinkChatGroupModal,
        }
    },
    { resetSearchFilters }
)(ChatMessaging)
