import API from '../../../api'
import { call, put, select } from 'redux-saga/effects'
import types from '../../actions/content/ambassadorsActions'

const getCurrentGroupUniversityId = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null

export function* ambassadorsReports({ payload }) {
    try {
        yield put({
            type: types.AMBASSADORS_LOADING_CHANGE,
            payload: {
                isLoading: true,
            },
        })

        const currentGroupUniversityIds = yield select(getCurrentGroupUniversityId)

        const data = { filter: payload.filter }
        if (currentGroupUniversityIds) {
            data.universityIds = currentGroupUniversityIds
        }

        const response = yield call(() => {
            return API.dashboard.ambassadorsReports(data)
        })

        yield response.success
            ? put({
                  type: types.AMBASSADORS_REPORTS_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: types.AMBASSADORS_REPORTS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: types.AMBASSADORS_REPORTS_FAILED,
        })
    }
}

export function* ambassadorIntroduction({ payload }) {
    try {
        const response = yield call(() => {
            return API.dashboard.ambassadorIntroduction(payload)
        })

        yield response.success
            ? put({
                  type: types.AMBASSADOR_CHANGE_INDRODUCTION_SUCCESS,
                  payload,
              })
            : put({
                  type: types.AMBASSADOR_CHANGE_INDRODUCTION_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: types.AMBASSADOR_CHANGE_INDRODUCTION_FAILED,
        })
    }
}

export function* ambassadorDescription({ payload }) {
    try {
        const response = yield call(() => {
            return API.dashboard.ambassadorDescription(payload)
        })

        yield response.success
            ? put({
                  type: types.AMBASSADOR_CHANGE_DESCRIPTION_SUCCESS,
                  payload,
              })
            : put({
                  type: types.AMBASSADOR_CHANGE_DESCRIPTION_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: types.AMBASSADOR_CHANGE_DESCRIPTION_FAILED,
        })
    }
}

export function* ambassadorsDiagrams({ payload }) {
    try {
        const currentGroupUniversityIds = yield select(getCurrentGroupUniversityId)

        if (payload.ambassadorsIds && payload.ambassadorsIds.length === 0) {
            yield put({
                type: types.AMBASSADORS_DIAGRAMS_SUCCESS,
                payload: {},
            })

            return
        }

        if (currentGroupUniversityIds) {
            payload.universityIds = currentGroupUniversityIds
        }
        const response = yield call(() => {
            return API.dashboard.ambassadorsDiagrams(payload)
        })

        yield response.success
            ? put({
                  type: types.AMBASSADORS_DIAGRAMS_SUCCESS,
                  payload: response.data,
              })
            : put({
                  type: types.AMBASSADORS_DIAGRAMS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: types.AMBASSADORS_DIAGRAMS_FAILED,
        })
    }
}

export function* reportingAmbassadors(action) {
    const payload = action.payload

    try {
        const currentGroupUniversityIds = yield select(getCurrentGroupUniversityId)

        if (currentGroupUniversityIds) {
            payload.universityIds = currentGroupUniversityIds
        }
        const response = yield call(() => {
            return API.dashboard.reportingAmbassadors(payload)
        })

        if (response.success) {
            yield put({
                type: types.AMBASSADORS_REP_SUCCESS,
                payload: response.data,
            })
            if (action.onFinish && response.data.ambassadorReport.ambassadorData.length > 0) action.onFinish(true)
        } else {
            yield put({
                type: types.AMBASSADORS_REP_FAILED,
            })
            if (action.onFinish) action.onFinish(false)
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: types.AMBASSADORS_REP_FAILED,
        })
        if (action.onFinish) action.onFinish(false)
    }
}

export function* getAmbassadorAdditionalData(action) {
    const payload = action.payload
    const { avatar, fullName } = payload

    try {
        const response = yield call(() => {
            return API.dashboard.getAmbassadorAdditionalData(payload)
        })

        if (response.success) {
            const data = {
                ...response.data,
                avatar,
                full_name: fullName,
            }
            yield put({
                type: types.GET_AMBASSADOR_ADDITIONAL_DATA_SUCCESS,
                payload: data,
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* ambassadorsExport(action) {
    const payload = action.payload

    try {
        const currentGroupUniversityIds = yield select(getCurrentGroupUniversityId)

        if (currentGroupUniversityIds) {
            payload.universityIds = currentGroupUniversityIds
        }
        const response = yield call(() => {
            return API.dashboard.ambassadorsExport(payload)
        })

        if (response.success) {
            yield put({
                type: types.AMBASSADORS_EXPORT_SUCCESS,
                payload: response.data,
            })
            action.onSuccess(response.data)
        } else {
            yield put({
                type: types.AMBASSADORS_EXPORT_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: types.AMBASSADORS_EXPORT_FAILED,
        })
    }
}

export function* setAmbassadorTimeLimit({ payload, onSuccess }) {
    try {
        const response = yield call(() => {
            return API.dashboard.setAmbassadorTimeLimit(payload)
        })

        if (response.id) {
            onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}
