import { Form, Row, Col, Input, Checkbox } from 'antd'
import { endOfDay, isBefore } from 'date-fns'
import React, { useCallback, useEffect, useMemo } from 'react'

import DatePicker from '../../../../../components/date-picker/date-picker'
import GlobalTagsSelect from '../../../../../components/global-tags-select/global-tags-select'
import TimezonesSelect from '../../../../../components/timezones-select/timezones-select'
import { roles } from '../../../../../helpers/helpers'
import { CoverImage, DurationInput, ExternalSpeakers, UsersSelect } from '../../index'

const { TextArea } = Input

const EventForm = ({
    form,
    initialValues,
    step,
    imageState,
    setImageState,
    editMode,
    isInProgress = false,
    isUpcoming = false,
    isRelatedToGroup = false,
}) => {
    useEffect(() => {
        form.resetFields()
    }, [initialValues])

    const disabledDate = useCallback((currentDate) => {
        const today = new Date()

        return currentDate && isBefore(currentDate, endOfDay(today))
    }, [])

    const showField = useMemo(() => {
        return !isInProgress || isUpcoming
    }, [isInProgress, isUpcoming])

    return (
        <Form form={form} layout="vertical" autoComplete="off" initialValues={initialValues} hideRequiredMark>
            <div
                style={{
                    display: step === 0 ? 'block' : 'none',
                }}
            >
                <Row gutter={8}>
                    <Col flex={1}>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                { whitespace: true, message: 'Add event name' },
                                { required: true, message: 'Add event name' },
                                { min: 3, message: 'Event name should contain at least 3 symbols' },
                                { max: 100, message: 'Event name should contain less than 100 symbols' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        {showField && <CoverImage form={form} imageState={imageState} setImageState={setImageState} />}
                    </Col>
                </Row>

                <Form.Item
                    name="description"
                    label="Live event description"
                    rules={[
                        { whitespace: true },
                        { required: true, message: 'Add description' },
                        { min: 50, message: 'Event description should contain at least 50 symbols' },
                        { max: 1000, message: 'Event description should not contain more than 1000 symbols' },
                    ]}
                >
                    <TextArea
                        rows={5}
                        placeholder="Describe this live event"
                        maxLength={1000}
                        showCount={{
                            formatter: ({ count, maxLength }) => `${count}/${maxLength}`,
                        }}
                    />
                </Form.Item>

                {showField && (
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Choose date' }]}>
                                <DatePicker
                                    placeholder="Choose date"
                                    disabledDate={disabledDate}
                                    allowClear={false}
                                    style={{ width: '100%' }}
                                    showToday={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <DurationInput form={form} />
                        </Col>
                    </Row>
                )}
                {showField && <TimezonesSelect />}

                <GlobalTagsSelect form={form} />

                {showField && (
                    <Form.Item name="isPromote" valuePropName="checked">
                        <Checkbox>
                            I don’t want to promote this event on the TAP Feed. I will send the link to event privately
                        </Checkbox>
                    </Form.Item>
                )}

                {showField && isRelatedToGroup && (
                    <Form.Item name="isGroupPromote" valuePropName="checked">
                        <Checkbox>Don’t show on the group account tap feed </Checkbox>
                    </Form.Item>
                )}
            </div>

            {!editMode && (
                <>
                    <div
                        style={{
                            display: step === 1 ? 'block' : 'none',
                        }}
                    >
                        <UsersSelect
                            id="internalSpeakerIds"
                            label="Internal speakers"
                            roles={[roles.admin, roles.student]}
                            form={form}
                            rules={[
                                {
                                    required: true,
                                    message: 'You should invite at least 1 speaker',
                                },
                            ]}
                            showRoles
                        />

                        <ExternalSpeakers id="externalSpeakerEmails" label="External speakers (emails)" form={form} />

                        <Form.Item
                            className="speakers-placeholder-field"
                            name="speakersPlaceholder"
                            dependencies={['internalSpeakerIds', 'externalSpeakerEmails']}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_) {
                                        const internal = getFieldValue('internalSpeakerIds')
                                        const external = getFieldValue('externalSpeakerEmails').filter(
                                            ({ email } = { email: '' }) => email.length > 1
                                        )

                                        if (internal.length === 0 && external.length === 0) {
                                            return Promise.reject(new Error('You should add at least one speaker'))
                                        }

                                        return Promise.resolve()
                                    },
                                }),
                            ]}
                        >
                            <Input style={{ display: 'none' }} />
                        </Form.Item>
                    </div>

                    <div
                        style={{
                            display: step === 2 ? 'block' : 'none',
                        }}
                    >
                        <p>
                            Chosen ambassadors will be answering prospects questions in the general chat during the live
                            event
                        </p>
                        <UsersSelect
                            id="ambassadorIds"
                            label="Add ambassadors"
                            form={form}
                            roles={[roles.student]}
                            excludeSpeakers
                        />
                    </div>
                </>
            )}
        </Form>
    )
}

export default EventForm
