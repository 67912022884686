export const USERS_INFO_REQUESTED = 'USERS_INFO_REQUESTED'
export const USERS_INFO_SUCCESS = 'USERS_INFO_SUCCESS'
export const USERS_INFO_FAILED = 'USERS_INFO_FAILED'

export const USER_TOGGLE_BAN_REQUESTED = 'USER_TOGGLE_BAN_REQUESTED'
export const USER_TOGGLE_BAN_SUCCESS = 'USER_TOGGLE_BAN_SUCCESS'
export const USER_TOGGLE_BAN_FAILED = 'USER_TOGGLE_BAN_FAILED'

export const USER_PARTNER_TOGGLE_BAN_REQUESTED = 'USER_PARTNER_TOGGLE_BAN_REQUESTED'
export const USER_PARTNER_TOGGLE_BAN_SUCCESS = 'USER_PARTNER_TOGGLE_BAN_SUCCESS'
export const USER_PARTNER_TOGGLE_BAN_FAILED = 'USER_PARTNER_TOGGLE_BAN_FAILED'

export const USER_PARTNER_TOGGLE_UNI_BAN_REQUESTED = 'USER_PARTNER_TOGGLE_UNI_BAN_REQUESTED'
export const USER_PARTNER_TOGGLE_UNI_BAN_SUCCESS = 'USER_PARTNER_TOGGLE_UNI_BAN_SUCCESS'
export const USER_PARTNER_TOGGLE_UNI_BAN_FAILED = 'USER_PARTNER_TOGGLE_UNI_BAN_FAILED'

export const OPEN_CONFIRM_BAN_POPUP = 'OPEN_CONFIRM_BAN_POPUP'
export const CLOSE_CONFIRM_BAN_POPUP = 'CLOSE_CONFIRM_BAN_POPUP'

export const USERS_POSTS_REQUESTED = 'USERS_POSTS_REQUESTED'
export const USERS_POSTS_SUCCESS = 'USERS_POSTS_SUCCESS'
export const USERS_POSTS_FAILED = 'USERS_POSTS_FAILED'

export const EDIT_USER_GLOBAL_TAG = 'EDIT_USER_GLOBAL_TAG'
export const EDIT_USER_GLOBAL_TAG_FINISH = 'EDIT_USER_GLOBAL_TAG_FINISH'

export const POPCARD_USERS_REQUESTED = 'POPCARD_USERS_REQUESTED'
export const POPCARD_USERS_FAILED = 'POPCARD_USERS_FAILED'
export const POPCARD_USERS_SUCCESS = 'POPCARD_USERS_SUCCESS'

export const USER_CHANGE_INTRODUCTION = 'USER_CHANGE_INTRODUCTION'
export const USER_CHANGE_INTRODUCTION_SUCCESS = 'USER_CHANGE_INTRODUCTION_SUCCESS'
export const USER_CHANGE_INTRODUCTION_FAILED = 'USER_CHANGE_INTRODUCTION_FAILED'

export const USER_CHANGE_DESCRIPTION = 'USER_CHANGE_DESCRIPTION'
export const USER_CHANGE_DESCRIPTION_SUCCESS = 'USER_CHANGE_DESCRIPTION_SUCCESS'
export const USER_CHANGE_DESCRIPTION_FAILED = 'USER_CHANGE_DESCRIPTION_FAILED'

export const USER_CHANGE_SUBJECT = 'USER_CHANGE_SUBJECT'
export const USER_CHANGE_SUBJECT_SUCCESS = 'USER_CHANGE_SUBJECT_SUCCESS'
export const USER_CHANGE_SUBJECT_FAILED = 'USER_CHANGE_SUBJECT_FAILED'

export const USER_CHANGE_COUNTRY = 'USER_CHANGE_COUNTRY'
export const USER_CHANGE_COUNTRY_SUCCESS = 'USER_CHANGE_COUNTRY_SUCCESS'
export const USER_CHANGE_COUNTRY_FAILED = 'USER_CHANGE_COUNTRY_FAILED'

export const DISABLE_TOGGLE = 'DISABLE_TOGGLE'
export const MAX_USERS_LIMIT_POPUP_TOGGLE = 'MAX_USERS_LIMIT_POPUP_TOGGLE'
export const GET_SA_PROFILE_TYPES = 'GET_SA_PROFILE_TYPES'
export const GET_SA_PROFILE_TYPES_SUCCESS = 'GET_SA_PROFILE_TYPES_SUCCESS'
export const GET_STAFF_TYPES = 'GET_STAFF_TYPES'
export const GET_STAFF_TYPES_SUCCESS = 'GET_STAFF_TYPES_SUCCESS'
export const GET_STUDENT_TYPES = 'GET_STUDENT_TYPES'
export const GET_STUDENT_TYPES_SUCCESS = 'GET_STUDENT_TYPES_SUCCESS'
export const GET_SUBJECTS = 'GET_SUBJECTS'
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS'
export const GET_YEAR_OF_STUDIES = 'GET_YEAR_OF_STUDIES'
export const GET_YEAR_OF_STUDIES_SUCCESS = 'GET_YEAR_OF_STUDIES_SUCCESS'
export const GET_COURSE_TYPES = 'GET_COURSE_TYPES'
export const GET_COURSE_TYPES_SUCCESS = 'GET_COURSE_TYPES_SUCCESS'
export const GET_INDUSTRIES = 'GET_INDUSTRIES'
export const GET_INDUSTRIES_SUCCESS = 'GET_INDUSTRIES_SUCCESS'

export const CHANGE_AMBASSADOR_TYPE = 'CHANGE_AMBASSADOR_TYPE'
export const CHANGE_AMBASSADOR_STUDENT_TYPE = 'CHANGE_AMBASSADOR_STUDENT_TYPE'
export const CHANGE_YEAR_OF_STUDY = 'CHANGE_YEAR_OF_STUDY'
export const CHANGE_AMBASSADOR_SUBJECT = 'CHANGE_AMBASSADOR_SUBJECT'
export const CHANGE_AMBASSADOR_COURSE_TYPE = 'CHANGE_AMBASSADOR_COURSE_TYPE'
export const CHANGE_AMBASSADOR_STAFF_TYPE = 'CHANGE_AMBASSADOR_STAFF_TYPE'
export const CHANGE_AMBASSADOR_JOB_TITLE = 'CHANGE_AMBASSADOR_JOB_TITLE'
export const CHANGE_AMBASSADOR_INDUSTRY = 'CHANGE_AMBASSADOR_INDUSTRY'
export const CHANGE_AMBASSADOR_JOB_ROLE = 'CHANGE_AMBASSADOR_JOB_ROLE'
export const CHANGE_AMBASSADOR_JOB_LOCATION = 'CHANGE_AMBASSADOR_JOB_LOCATION'
export const CHANGE_AMBASSADOR_WORKING_SINCE = 'CHANGE_AMBASSADOR_WORKING_SINCE'
export const CHANGE_AMBASSADOR_CHILDREN = 'CHANGE_AMBASSADOR_CHILDREN'

export const CHANGE_AMBASSADOR_INTERESTS = 'CHANGE_AMBASSADOR_INTERESTS'
export const CHANGE_AMBASSADOR_INTERESTS_SUCCESS = 'CHANGE_AMBASSADOR_INTERESTS_SUCCESS'
export const CHANGE_AMBASSADOR_INTERESTS_FAILED = 'CHANGE_AMBASSADOR_INTERESTS_FAILED'

export const CHANGE_AMBASSADOR_INFO = 'CHANGE_AMBASSADOR_INFO'
export const CHANGE_AMBASSADOR_INFO_SUCCESS = 'CHANGE_AMBASSADOR_INFO_SUCCESS'

export const DELETE_AMBASSADOR = 'DELETE_AMBASSADOR'
export const DELETE_AMBASSADOR_SUCCESS = 'DELETE_AMBASSADOR_SUCCESS'

export const GET_USER_TABLE_TOTALS = 'GET_USER_TABLE_TOTALS'
export const GET_USER_TABLE_TOTALS_SUCCESS = 'GET_USER_TABLE_TOTALS_SUCCESS'

export const USER_INFO_OPEN_MODAL = 'USER_INFO_OPEN_MODAL'
export const USER_INFO_CLOSE_MODAL = 'USER_INFO_CLOSE_MODAL'

export const changeUserIntroduction = (userId, introduction) => {
    return {
        type: USER_CHANGE_INTRODUCTION,
        payload: {
            user_id: userId,
            introduction,
        },
    }
}

export const changeUserDescription = (userId, description) => ({
    type: USER_CHANGE_DESCRIPTION,
    payload: {
        user_id: userId,
        description,
    },
})

export const changeUserSubject = (userId, subject) => ({
    type: USER_CHANGE_SUBJECT,
    payload: {
        user_id: userId,
        subject,
    },
})

export const changeUserCountry = ({ userId, region, countryId, countries, onSuccess }) => ({
    type: USER_CHANGE_COUNTRY,
    payload: {
        userId,
        region,
        countryId,
        countries,
    },
    meta: {
        onSuccess,
    },
})

export const getSaProfileTypes = ({ orderBy } = {}) => ({
    type: GET_SA_PROFILE_TYPES,
    payload: {
        orderBy,
    },
})

export const getStaffTypes = ({ orderBy } = {}) => ({
    type: GET_STAFF_TYPES,
    payload: {
        orderBy,
    },
})

export const getStudentTypes = ({ orderBy } = {}) => ({
    type: GET_STUDENT_TYPES,
    payload: {
        orderBy,
    },
})

export const getSubjects = ({ orderBy } = {}) => ({
    type: GET_SUBJECTS,
    payload: {
        orderBy,
    },
})

export const getYearOfStudies = ({ orderBy } = {}) => ({
    type: GET_YEAR_OF_STUDIES,
    payload: {
        orderBy,
    },
})

export const getCourseTypes = ({ orderBy } = {}) => ({
    type: GET_COURSE_TYPES,
    payload: {
        orderBy,
    },
})

export const getIndustries = ({ orderBy } = {}) => ({
    type: GET_INDUSTRIES,
    payload: {
        orderBy,
    },
})

export const changeAmbassadorType = ({ id, userId, key, name }) => ({
    type: CHANGE_AMBASSADOR_TYPE,
    payload: {
        id,
        userId,
        key,
        name,
    },
})

export const changeAmbassadorStudentType = ({ userId, data }) => ({
    type: CHANGE_AMBASSADOR_STUDENT_TYPE,
    payload: {
        userId,
        ambassadorStudentType: data,
    },
})

export const changeYearOfStudy = ({ userId, data }) => ({
    type: CHANGE_YEAR_OF_STUDY,
    payload: {
        userId,
        yearOfStudy: data,
    },
})

export const changeIndustry = ({ userId, data }) => ({
    type: CHANGE_YEAR_OF_STUDY,
    payload: {
        userId,
        industry: data,
    },
})

export const changeAmbassadorSubject = ({ userId, data }) => ({
    type: CHANGE_AMBASSADOR_SUBJECT,
    payload: {
        userId,
        subject: data,
    },
})

export const changeAmbassadorCourseType = ({ userId, data }) => ({
    type: CHANGE_AMBASSADOR_COURSE_TYPE,
    payload: {
        userId,
        courseType: data,
    },
})

export const changeAmbassadorStaffType = ({ userId, data }) => ({
    type: CHANGE_AMBASSADOR_STAFF_TYPE,
    payload: {
        userId,
        staffType: data,
    },
})

export const changeAmbassadorJobTitle = ({ userId, data }) => ({
    type: CHANGE_AMBASSADOR_JOB_TITLE,
    payload: {
        userId,
        jobTitle: data,
    },
})

export const changeAmbassadorIndustry = ({ userId, data }) => ({
    type: CHANGE_AMBASSADOR_INDUSTRY,
    payload: {
        userId,
        industry: data,
    },
})

export const changeAmbassadorJobRole = ({ userId, data }) => ({
    type: CHANGE_AMBASSADOR_JOB_ROLE,
    payload: {
        userId,
        jobRole: data,
    },
})

export const changeAmbassadorInfo = ({ userId, data }) => ({
    type: CHANGE_AMBASSADOR_INFO,
    payload: {
        userId,
        data,
    },
})

export const handleDeleteAmbassador = ({ userId, onSuccess }) => ({
    type: DELETE_AMBASSADOR,
    payload: {
        userId,
    },
    meta: {
        onSuccess,
    },
})

export const changeAmbassadorJobLocation = ({ userId, companyRegion, companyCountryId, companyCountryName }) => ({
    type: CHANGE_AMBASSADOR_JOB_LOCATION,
    payload: {
        userId,
        companyRegion,
        companyCountryId,
        companyCountryName,
    },
})

export const changeAmbassadorWorkingSince = ({ userId, workingSince }) => ({
    type: CHANGE_AMBASSADOR_WORKING_SINCE,
    payload: {
        userId,
        workingSince,
    },
})

export const changeAmbassadorChildren = ({ userId, children }) => ({
    type: CHANGE_AMBASSADOR_CHILDREN,
    payload: {
        userId,
        children,
    },
})

export const changeAmbassadorInterests = ({ userId, interests, onSuccess }) => ({
    type: CHANGE_AMBASSADOR_INTERESTS,
    payload: {
        userId,
        interests,
    },
    meta: {
        onSuccess,
    },
})

export const getUserTableTotalsAction = ({ filter }) => ({
    type: GET_USER_TABLE_TOTALS,
    payload: {
        filter,
    },
})

export const openUserInfoModal = ({ userId }) => ({
    type: USER_INFO_OPEN_MODAL,
    payload: {
        userId,
    },
})

export const closeUserInfoModal = ({ userId }) => ({
    type: USER_INFO_CLOSE_MODAL,
    payload: {
        userId,
    },
})
