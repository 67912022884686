/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { openNotificationModal } from '../../../../../../../../../../redux/actions/notification-modal/notification-modal-actions'
import { LIVE_STREAMS } from '../../../../../../../../../../redux/actions/live-streams/live-streams-action-types'

const ChatFooter = ({ activeEvent, type }) => {
    const { id: liveStreamId, name, state } = activeEvent
    const show = useMemo(() => state === 'archived', [state])
    const dispatch = useDispatch()

    const handlePublishStream = useCallback(() => {
        dispatch(
            openNotificationModal(LIVE_STREAMS.ARCHIVE_STREAM, {
                liveStreamId,
                state: 'public',
                name,
            })
        )
    }, [])

    return (
        show && (
            <div className="chat-footer-closed">
                <p className="closed-text">The stream is archived</p>
                <button type="button" className="reopen-btn" onClick={handlePublishStream}>
                    Publish
                </button>
            </div>
        )
    )
}

export default ChatFooter
