import { HttpService } from '../../system/services/HttpService';
function dialogSearch(data) {
    return HttpService.get('/dashboard/dialogs/search', data);
}
export default {
    dialogSearch: dialogSearch,
    sendMessage: function (payload) {
        return HttpService.post("/dashboard/dialogs/".concat(payload.dialog_id, "/messages/send"), payload);
    },
    sendGroupMessage: function (payload) {
        return HttpService.post("/dashboard/dialogs/".concat(payload.dialog_id, "/messages/sendGroupMessage"), payload);
    },
    getDialogSelectedAmbassadors: function (id) {
        return HttpService.get("/dashboard/dialogs/".concat(id, "/ambassadorIds"));
    },
    getDialogNewMessagesCount: function (id) {
        return HttpService.safeGet("/dashboard/dialogs/".concat(id, "/messages/newCount"));
    },
};
