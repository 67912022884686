var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GET_TIMEZONES_SUCCESS, RESET_TIMEZONES, GET_TIMEZONES } from '../../actions/settings/timezonesActions';
import { TIMEZONES } from '../../store/initialState';
var initialState = TIMEZONES;
export default function (state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type;
    var payload = action.payload;
    switch (type) {
        case GET_TIMEZONES: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case GET_TIMEZONES_SUCCESS: {
            return __assign(__assign({}, state), { items: payload.items, isLoading: false });
        }
        case RESET_TIMEZONES: {
            return __assign(__assign({}, state), { items: [] });
        }
        default: {
            return __assign({}, state);
        }
    }
}
