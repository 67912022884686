import { Avatar, Button, List, Modal, Skeleton, Typography } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AmbassadorInfoPopup from '../../../../../components/popups/ambassador-info-popup/AmbassadorInfoPopup'

import { FIND_OR_CREATE_CHAT } from '../../../../../redux/actions/chat/messagingActions'
import {
    changeAmbassadorInfo,
    changeUserDescription,
    changeUserIntroduction,
    changeUserSubject,
    closeUserInfoModal,
    openUserInfoModal,
} from '../../../../../redux/actions/content/usersActions'
import { getAccountInfo } from '../../../../../redux/selectors'

const { Text } = Typography

const userAvatar = ({ avatar, name, id }, avatarSize = '') => {
    if (avatar) {
        const avatarSrc = avatarSize === 'smallAvatar' ? avatar.sizes['70x70'] || avatar.original : avatar.original
        return <Avatar className="table-item-img" src={avatarSrc} />
    } else {
        return (
            <Avatar className={`table-item-img table-item-img-name ${avatarSize === '' && 'table-item-img-name--big'}`}>
                {name[0]}
            </Avatar>
        )
    }
}

const NameCell = ({ record }) => {
    const dispatch = useDispatch()

    const { university } = useSelector(getAccountInfo)

    const handleOpenUserPopup = useCallback(() => {
        dispatch(openUserInfoModal({ userId: record.userInfo.id }))
    }, [dispatch, record])

    const handleCloseUserPopup = useCallback(() => {
        dispatch(closeUserInfoModal({ userId: record.userInfo.id }))
    }, [dispatch, record])

    const onChangeUserIntroduction = useCallback(
        (userId, introduction) => {
            dispatch(changeUserIntroduction(userId, introduction))
        },
        [dispatch]
    )

    const onChangeUserDescription = useCallback(
        (userId, description) => {
            dispatch(changeUserDescription(userId, description))
        },
        [dispatch]
    )

    const onChangeUserSubject = useCallback(
        (userId, subject) => {
            dispatch(changeUserSubject(userId, subject))
        },
        [dispatch]
    )

    const onChangeAmbassadorInfo = useCallback(
        (payload) => {
            dispatch(changeAmbassadorInfo(payload))
        },
        [dispatch]
    )

    const afterCreated = useCallback((id) => {
        window.open('/#/chat/messaging?type=4&activeDialog=' + id, '_blank')
    }, [])

    const handleOpenChat = useCallback(
        (id) => {
            dispatch({
                type: FIND_OR_CREATE_CHAT,
                payload: {
                    user_id: record.userInfo.id,
                    afterCreated: afterCreated,
                },
            })
        },
        [dispatch, afterCreated, record]
    )

    return (
        <>
            <Skeleton loading={false} avatar active paragraph={{ rows: 0 }}>
                <List.Item.Meta
                    avatar={
                        <Avatar
                            src={record.avatar ? record.avatar.sizes['70x70'] || record.avatar.original : null}
                            size={50}
                        />
                    }
                    title={
                        <Button
                            type="link"
                            style={{ color: 'black', fontSize: '1.6rem' }}
                            onClick={handleOpenUserPopup}
                        >
                            <Text className="user-profile-name">{record.fullName}</Text>
                        </Button>
                    }
                    style={{ display: 'flex', flexDirection: 'row' }}
                />
            </Skeleton>
            <Modal
                open={record.userInfo.isModalOpen}
                centered={true}
                footer={null}
                closable={false}
                maskClosable={false}
                onCancel={handleCloseUserPopup}
                className="user-profile-modal"
                destroyOnClose
            >
                <AmbassadorInfoPopup
                    user={record.userInfo}
                    userAvatar={userAvatar}
                    handleChangeIntroduction={onChangeUserIntroduction}
                    handleChangeDescription={onChangeUserDescription}
                    handleChangeSubject={onChangeUserSubject}
                    changeAmbassadorInfo={onChangeAmbassadorInfo}
                    handleCloseUserPopup={handleCloseUserPopup}
                    handleOpenChat={handleOpenChat}
                    university={university.name}
                    editableProfile={true}
                />
            </Modal>
        </>
    )
}

const nameCellRenderer = (text, record) => <NameCell record={record} />

export default nameCellRenderer
