import React from 'react'
import { useSelector } from 'react-redux'
import { Form, Input } from 'antd'
import { getUniversityInfo } from '../../../../../redux/selectors'

const TapFeedStartConversationSection = ({
    form,
    initialValues,
    onSubmit,
    onFinishFailed,
    prevStep,
    messageSentValidator,
    showErrorMessage,
}) => {
    const universityInfo = useSelector(getUniversityInfo)
    const { url, slug } = universityInfo

    return (
        <div className="settings-item">
            <label className="settings-name">
                3. Start a conversation{' '}
                <span className="label-rest-text">
                    with the ambassador profile you created during the last step of the set up process
                </span>
            </label>
            <Form
                form={form}
                onFinish={onSubmit}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                autoComplete="off"
                initialValues={initialValues}
                hideRequiredMark
            >
                <div>
                    <Form.Item
                        name="prospectMessage"
                        className="form-item conversation-section"
                        label={null}
                        rules={[{ validator: messageSentValidator }]}
                    >
                        <Input style={{ display: 'none' }} />
                        {showErrorMessage && (
                            <div className="conversation-section-error-message">
                                <a
                                    className="conversation-section-error-message-link"
                                    href={`${url}/${slug}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {`${url}/${slug}`}
                                </a>
                            </div>
                        )}
                    </Form.Item>
                </div>
                <div className="setup-steps-buttons">
                    <button className="previous-step-button" onClick={prevStep}>
                        Previous
                    </button>
                    <button type="submit">Finish setup</button>
                </div>
            </Form>
        </div>
    )
}

export default TapFeedStartConversationSection
