import React from 'react'

import { JoinOverlay, StreamControls, JoinModal, ParticipantsView } from './components'
import { useEventStream } from './use-event-stream'

const EventStream = ({ account, activeEvent }) => {
    const {
        localMedia,
        onJoinModalClose,
        onJoinClick,
        eventVideoStreamRef,
        joined,
        onMuteVideoClick,
        onMuteAudioClick,
        onLeaveClick,
        onShareScreenClick,
        onMuteRemoteAudioClick,
    } = useEventStream({
        account,
        activeEvent,
    })

    return (
        <div className="tap-event-stream">
            <ParticipantsView eventVideoStreamRef={eventVideoStreamRef} />
            <JoinOverlay onJoinClick={onJoinClick} />
            <StreamControls
                open={joined}
                onMuteVideoClick={onMuteVideoClick}
                onMuteAudioClick={onMuteAudioClick}
                onLeaveClick={onLeaveClick}
                onShareScreenClick={onShareScreenClick}
                onMuteRemoteAudioClick={onMuteRemoteAudioClick}
            />
            <JoinModal localMedia={localMedia} onClose={onJoinModalClose} onJoinClick={onJoinClick} />
        </div>
    )
}

export default EventStream
