import { ChatType } from '../../common/constants'
import HttpService from '../../system/services/HttpService'
import { pusherChild } from '../../system/services/PusherWrapper'

export default {
    tags: (filter, search, sort, limit, offset) => {
        return HttpService.get('/dashboard/tags', {
            search: search,
            filter: filter,
            sort: sort,
            limit: limit,
            offset: offset,
        })
    },
    childTags: (filter, search, sort, limit, offset, tagId) => {
        return HttpService.get('/dashboard/tags/subtags/' + tagId, {
            search: search,
            filter: filter,
            sort: sort,
            limit: limit,
            offset: offset,
        })
    },
    posts: (filter, search, sort, postsInIds, postsNotInIds, limit, offset, withGlobalTags = false, universityIds) => {
        return HttpService.get('/dashboard/posts', {
            search: search,
            filter: filter,
            sort: sort,
            postsInIds: postsInIds,
            postsNotInIds: postsNotInIds,
            limit: limit,
            offset: offset,
            withGlobalTags: withGlobalTags,
            universityIds,
        })
    },
    universityUsers: (filter, search, sort, limit, offset, withGlobalTags = false, universityIds, orderBy, order) => {
        // only ambassadors
        return HttpService.get('/dashboard/universities/ambassadors', {
            search: search,
            filter: filter,
            sort: sort,
            limit: limit,
            offset: offset,
            withGlobalTags: withGlobalTags,
            universityIds,
            orderBy,
            order,
        })
    },

    universityAmbassadors: (search, roles = ['univercity-student'], universityIds) => {
        return HttpService.get('/dashboard/universities/users', {
            search: search,
            universityIds,
            roles,
            // usersNotInIds
        })
    },

    ambassadorsReports: (data) => HttpService.get('/dashboard/universities/ambassadors/reports', data),
    // eslint-disable-next-line camelcase
    ambassadorIntroduction: ({ user_id, introduction }) =>
        // eslint-disable-next-line camelcase
        HttpService.put(`/dashboard/users/${user_id}/introduction`, { introduction }),
    // eslint-disable-next-line camelcase
    ambassadorDescription: ({ user_id, description }) =>
        // eslint-disable-next-line camelcase
        HttpService.put(`/dashboard/users/${user_id}/description`, { description }),
    // eslint-disable-next-line camelcase
    ambassadorSubject: ({ user_id, subject }) =>
        // eslint-disable-next-line camelcase
        HttpService.put(`/dashboard/users/${user_id}/subject`, { subject }),
    changeUserCountry: ({ userId, region, countryId }) => {
        return HttpService.put(`/dashboard/users/${userId}/location`, { region, countryId })
    },
    changeUserInterests: ({ userId, interests }) =>
        // eslint-disable-next-line camelcase
        HttpService.put(`/dashboard/users/${userId}/interests`, { interests }),
    ambassadorsDiagrams: (data) => {
        return HttpService.post('/dashboard/reporting/ambassador/diagram', data)
    },
    reportingAmbassadors: (data) => HttpService.get('/dashboard/reporting/ambassador', data),
    ambassadorsExport: (data) => {
        return HttpService.post('/dashboard/reporting/ambassador/csv', data)
    },
    getAmbassadorAdditionalData: ({ userId }) => HttpService.get(`/dashboard/users/${userId}/ambassadorAdditionalData`),

    universityStatistics: (filter, universityIds) =>
        HttpService.get('/dashboard/universities/statistic', { filter, universityIds }),
    overviewSummaryStatistics: (filter, universityIds) =>
        HttpService.get('/dashboard/reporting/overview/summary', { filter, universityIds }),
    overviewChatStatistics: (filter, universityIds) =>
        HttpService.get('/dashboard/reporting/overview/chat', { filter, universityIds }),
    overviewContentStatistics: (filter, universityIds) =>
        HttpService.get('/dashboard/reporting/overview/content', { filter, universityIds }),
    overviewFaqStatistics: (filter, universityIds) =>
        HttpService.get('/dashboard/reporting/overview/faq', { filter, universityIds }),

    activityChartsData: (filter, utmParams, universityIds) =>
        HttpService.post('/dashboard/reporting/activity/charts', { filter, utmParams, universityIds }),
    activityTableData: (filter, search, limit, offset, orderBy, order, universityIds) =>
        HttpService.get('/dashboard/reporting/activity/table', {
            filter,
            search,
            limit,
            offset,
            orderBy,
            order,
            universityIds,
        }),
    activityCsvData: (filter, utmParams, universityIds) =>
        HttpService.post('/dashboard/reporting/activity/csv', { filter, utmParams, universityIds }),

    prospectsChartsData: ({
        filter,
        registeredViaGroupIds,
        registeredViaUniversityIds,
        prospectsIds,
        excludedProspectsIds,
        universityIds,
        utmParams,
    }) => {
        return HttpService.post('/dashboard/reporting/prospects/charts', {
            filter,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            prospectsIds,
            excludedProspectsIds,
            universityIds,
            utmParams,
        })
    },
    prospectsTableData: ({
        filter,
        search,
        registeredViaGroupIds,
        registeredViaUniversityIds,
        limit,
        offset,
        orderBy,
        order,
        universityIds,
        utmParams,
    }) =>
        HttpService.get('/dashboard/reporting/prospects/table', {
            filter,
            search,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            limit,
            offset,
            orderBy,
            order,
            universityIds,
            utmParams,
        }),
    prospectsCsvData: ({
        filter,
        search,
        registeredViaGroupIds,
        registeredViaUniversityIds,
        prospectsIds,
        excludedProspectsIds,
        universityIds,
        utmParams,
        liveStreamId,
    }) => {
        return HttpService.post('/dashboard/reporting/prospects/csv', {
            filter,
            search,
            registeredViaGroupIds,
            registeredViaUniversityIds,
            prospectsIds,
            excludedProspectsIds,
            universityIds,
            utmParams,
            liveStreamId,
        })
    },
    prospectOptionalData: (prospectId, universityIds) =>
        HttpService.get('/dashboard/users/' + prospectId + '/optionalData', { universityIds }),
    prospectMandatoryData: (prospectId) => HttpService.get('/dashboardGroup/users/' + prospectId + '/mandatoryData'),

    trendAnalyzerChartsData: (filter, universityIds) =>
        HttpService.get('/dashboard/analytics/trend/charts', { filter, universityIds }),
    trendAnalyzerTableData: (filter, universityIds) =>
        HttpService.get('/dashboard/analytics/trend/table', { filter, universityIds }),
    trendAnalyzerCsvData: (filter, topicIds, universityIds) =>
        HttpService.post('/dashboard/analytics/trend/csv', { filter, topicIds, universityIds }),

    contentRequests: (limit, offset, filter) => {
        return HttpService.get('/dashboard/broadcasts', {
            limit: limit,
            offset: offset,
            filter: filter,
        })
    },
    createBroadcast: (type, content, tags) => {
        return HttpService.post('/dashboard/broadcasts/create', {
            content: content,
            type: type,
            tags: tags,
        })
    },
    contentRequestResponses: (contentRequestId, limit, offset) => {
        return HttpService.get('/dashboard/broadcasts/detail/' + contentRequestId, {
            limit: limit,
            offset: offset,
        })
    },
    addDownloadPoints: (postId) => {
        return HttpService.get('/dashboard/posts/download/' + postId)
    },
    deletePost: (postId) => {
        return HttpService.get('/dashboard/posts/delete/' + postId)
    },

    sharePost: (postId, caption, rssSharing, tapSharing) => {
        // I don't think we are using it, can be removed
        return HttpService.post('/dashboard/posts/share/' + postId, {
            caption: caption,
            service: 'rss',
            rss_sharing: rssSharing,
            tap_sharing: tapSharing,
        })
    },

    sharePostToRSS: (postId, caption) => {
        return HttpService.post(`/dashboard/posts/shared/toggle/${postId}`, {
            share: true,
            sharing_caption: caption,
        })
    },

    togglePublishPost: (postId, publish) => {
        return HttpService.post(`/dashboard/posts/published/toggle/${postId}`, {
            publish,
        })
    },

    editPost: (postId, caption) => {
        return HttpService.post(`/dashboard/posts/edit/caption/${postId}`, {
            caption,
        })
    },
    userPosts: (userId, limit, offset) => {
        return HttpService.get('/dashboard/posts/user/' + userId, {
            limit: limit,
            offset: offset,
        })
    },
    uploadFile: (payload, universityId) => {
        if (universityId) {
            payload.universityId = universityId
        }
        return HttpService.post('/dashboard/posts/', payload)
    },
    videoUniversityTranscode: (payload) => {
        return HttpService.formData('/dashboard/posts/videoTranscode/', payload)
    },
    videoUpload: (payload, universityId) => {
        const data = {
            caption: payload.caption,
            file_id: payload.file_id,
            currentJobId: payload.currentJobId,
        }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.post('/dashboard/posts/videoUpload', data)
    },
    contentGroupPosts: (contentGroupId, limit, offset) => {
        return HttpService.get('/dashboard/dialogs/' + contentGroupId + '/content', {
            limit: limit,
            offset: offset,
        })
    },
    usersCount: (type, tags, filter) => {
        return HttpService.get('/dashboard/tags/users', {
            type: type,
            tags: tags,
            filter: filter,
        })
    },
    toggleUserBan: (universityId, userId, permissionId) => {
        return HttpService.post('/dashboard/universities/' + universityId + '/users/' + userId + '/ban/toggle', {
            permission_id: permissionId,
        })
    },

    toggleUserPermission: (universityId, userId, permissionId, permissionKey) => {
        return HttpService.post('/dashboard/users/' + userId + '/permission/toggle', {
            permission_key: permissionKey,
        })
    },

    toggleUserGroupPermission: (userId, universityGroupId) => {
        const data = {}
        if (universityGroupId) {
            data.university_group_id = universityGroupId
        }
        return HttpService.post('/dashboard/users/' + userId + '/shareGroup/toggle', data)
    },

    toggleUserUniPermission: (userId) => {
        return HttpService.post('/dashboard/users/' + userId + '/shareUniversity/toggle')
    },

    createContentGroup: (name, description, membersIds, globalTagsIds, avatar, eventSettings, universityId) => {
        const data = {
            name: name,
            description: description,
            membersIds: membersIds,
            avatar: avatar,
            globalTagsIds: globalTagsIds,
        }
        if (universityId) {
            data.universityId = universityId
        }
        if (eventSettings) {
            data.eventSettings = eventSettings
        }
        return HttpService.post('/dashboard/dialogs/contentGroup', data)
    },
    findOrCreate: (userId) => {
        return HttpService.post('/dashboard/dialogs/' + userId + '/findOrCreate')
    },
    dialogsInfo: (type = 2, dialogId, withGlobalTags = false, currentDialogId = null, universityIds) => {
        const data = {
            filter: {
                type,
            },
            withGlobalTags: withGlobalTags,
        }
        if (dialogId) {
            data.filter.dialog_id = dialogId
        }
        if (currentDialogId) {
            data.filter.current_dialog_id = currentDialogId // we are sending current_dialog_id coz we want to get chat even if its not active,
            // otherwise we would have to wait and check if our dialog inactive and wasn't returned and after call again api to get it information
            // dialog activates after first message has been sent to ambassador
        }
        if (universityIds) {
            data.universityIds = universityIds
        }

        return HttpService.get('/dashboard/dialogs', data)
    },
    chatsSearch: (
        // eslint-disable-next-line camelcase
        {
            filters = {},
            search = null,
            dialogId = null,
            limit = 100,
            offset = 0,
            type_chat: typeChat = ChatType.CHAT,
            withGlobalTags,
            archived,
        },
        universityIds
    ) => {
        if (filters && filters.private && filters.public) {
            delete filters.private
            delete filters.public
        } else if (filters && filters.public) {
            delete filters.public
            filters.private = false
        }
        const data = {
            limit,
            offset,
            filter: filters,
            archived,
        }
        if (search) {
            data.search = search
        }
        if (dialogId) {
            data.dialogId = dialogId
        }
        if (withGlobalTags) {
            data.withGlobalTags = withGlobalTags
        }
        if (universityIds) {
            data.universityIds = universityIds
        }
        if (archived) {
            data.typeKeys = [ChatType.GROUP_CHAT, ChatType.CHAT]
        }
        if (typeChat && !archived) {
            data.typeKeys = [typeChat]
        }
        return HttpService.get('/dashboard/dialogs/search', data)
    },
    getChatTranscript: (dialogId) => {
        return HttpService.get('/dashboard/dialogs/chatCsv', { dialogId })
    },
    getChatsTranscript: (
        // eslint-disable-next-line camelcase
        { filters = {}, search = null, dialogId = null, type_chat = ChatType.CHAT, withGlobalTags },
        universityIds
    ) => {
        const data = {
            filter: filters,
        }
        if (search) {
            data.search = search
        }
        if (dialogId) {
            data.dialogId = dialogId
        }
        if (withGlobalTags) {
            data.withGlobalTags = withGlobalTags
        }
        if (universityIds) {
            data.universityIds = universityIds
        }
        // eslint-disable-next-line camelcase
        if (type_chat) {
            // eslint-disable-next-line camelcase
            data.typeKeys = [type_chat]
        }
        return HttpService.get('/dashboard/dialogs/csv', data)
    },

    messagesInfo: (dialogId, withGlobalTags = false, beforeMessageId, afterMessageId, limit, aroundMessageId) => {
        const data = {
            order: 'desc',
            orderBy: 'id',
            withGlobalTags: withGlobalTags,
            limit,
        }
        if (afterMessageId) {
            data.afterMessageId = afterMessageId
        } else if (aroundMessageId) {
            data.aroundMessageId = aroundMessageId
        } else {
            if (beforeMessageId) {
                data.beforeMessageId = beforeMessageId
            }
        }
        return HttpService.get('/dashboard/dialogs/' + dialogId + '/messages', data)
    },
    contentMessagesInfo: ({ dialogId, withGlobalTags = false, limit, beforeMessageId }) => {
        const data = {
            order: 'desc',
            orderBy: 'id',
            withGlobalTags: withGlobalTags,
            limit,
        }
        if (beforeMessageId) {
            data.beforeMessageId = beforeMessageId
        }
        return HttpService.get(`/dashboard/dialogs/${dialogId}/messages`, data)
    },
    videoTranscode: (payload, dialogId) => {
        return HttpService.formData('/dashboard/dialogs/' + dialogId + '/messages/videoTranscode', payload)
    },
    sendMessage: (payload) => {
        return HttpService.post('/dashboard/dialogs/' + payload.dialog_id + '/messages/send', {
            ...payload,
            socketId: pusherChild.connection.socket_id,
        })
    },
    /**
     *
     * @param {import('../communities/types').MessagePayload} payload
     * @returns
     */
    sendGroupMessage: (payload) => {
        return HttpService.post('/dashboard/dialogs/' + payload.dialog_id + '/messages/sendGroupMessage', {
            ...payload,
            socketId: pusherChild.connection.socket_id,
        })
    },
    /* messagesView: (dialog_id) => {
        return HttpService.post("/dashboard/dialogs/" + dialog_id + "/messages/view");
    }, */
    editDialog: (name, users, avatar, globalTagsIds, dialogId, firstMessage, isPrivate, description) => {
        return HttpService.put('/dashboard/dialogs/update', {
            dialog_id: dialogId,
            name: name,
            users: users,
            avatar: avatar,
            globalTagsIds: globalTagsIds,
            firstMessage: firstMessage,
            is_private: isPrivate,
            description: description,
        })
    },
    closeContentGroup: (dialogId) => {
        return HttpService.put('/dashboard/dialogs/' + dialogId + '/close/toggle')
    },
    unflagDialog: (dialogId) => {
        return HttpService.put('/dashboard/dialogs/' + dialogId + '/remove/flag', {
            socketId: pusherChild.connection.socket_id,
        })
    },
    unwordDialog: (messageId) => {
        return HttpService.put('/dashboard/dialogs/' + messageId + '/hide_keywords/toggle')
    },

    setAmbassadorTimeLimit: ({ userId, time, type }) => {
        return HttpService.put('/dashboard/users/' + userId + '/timeLimit', {
            limit_minuts: time,
            limit_type: type,
        })
    },
    popcardUsers: (universityId = null, groupId = null) => {
        const data = {}
        if (groupId) {
            data.groupId = groupId
        } else {
            data.universityId = universityId
        }
        return HttpService.get('/widget/promotion/popcard', data)
    },

    getScheduleData: (filter, universityIds) =>
        HttpService.get('/dashboard/dialogs/scheduledContentGroups', { filter, universityIds }),
    getScheduleItem: (eventId) => HttpService.get('/dashboard/dialogs/scheduledContentGroups/' + eventId),
    editScheduleEvent: (data) => {
        const requestData = {
            name: data.name,
            description: data.description,
            membersIds: data.membersIds,
            globalTagsIds: data.globalTagsIds,
        }
        if (data.avatar) {
            requestData.avatar = data.avatar
        }
        if (data.eventSettings) {
            requestData.eventSettings = data.eventSettings
        }
        return HttpService.put('/dashboard/dialogs/scheduledContentGroups/' + data.eventId, requestData)
    },
    deleteScheduleEvent: (eventId) => HttpService.delete('/dashboard/dialogs/scheduledContentGroups/' + eventId),
    updateScheduleEventStatuses: (eventIds, isActive) => {
        return HttpService.put('/dashboard/dialogs/scheduledContentGroups/status', {
            dialogIds: eventIds,
            isActive: isActive,
        })
    },
    getUserTableTotals: ({ filter, universityIds = [] }) => {
        return HttpService.get('/dashboard/universities/ambassadorsTotals', {
            filter,
            universityIds,
        })
    },
}
