import { format } from 'date-fns'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as LinkIcon } from '../../../../../../../assets/svg/icons/link-v.svg'
import { ReactComponent as TimeIcon } from '../../../../../../../assets/svg/pie-chart.svg'
import { ChatType, LIVE_EVENT_STATUS } from '../../../../../../common/constants'
import { parseDateFromServer } from '../../../../../../common/utils/date-time-utils'
import { OPEN_ACTION_MODAL } from '../../../../../../redux/actions/components/componentsActions'
import { getActiveLiveEvent, getChatMessagesSlice } from '../../../../../../redux/selectors'

import ReportingPopover from '../../../../../../components/ReportingPopover'
import { TAB_KEY } from '../chat-sidebar/chat-sidebar-constants'
import EventListPopover from './components/event-list-popover'
import EventMenu from './components/event-menu'

const FROM_FORMAT = 'dd MMMM yyyy, HH:mm aa'
const TO_FORMAT = 'HH:mm aa'

const EventHeader = () => {
    const activeEvent = useSelector(getActiveLiveEvent)
    const activeTab = useSelector((state) => state.liveEvents.activeTab)
    const chatMessages = useSelector(getChatMessagesSlice)

    const dispatch = useDispatch()

    const { fromDate, toDate, status, liveEventLink } = activeEvent

    const dialogId = useMemo(() => {
        const { chat, speakersChat } = activeEvent
        return activeTab === TAB_KEY.CHAT ? chat.id : speakersChat.id
    }, [activeEvent, activeTab])
    const showEventMenu = useMemo(() => {
        const isUpcoming = status === LIVE_EVENT_STATUS.UPCOMING
        const isInProgress = status === LIVE_EVENT_STATUS.IN_PROGRESS

        return isUpcoming || isInProgress
    }, [status])

    const fromTime = useMemo(() => {
        const parsed = parseDateFromServer(fromDate)

        return format(parsed, FROM_FORMAT)
    }, [fromDate])

    const toTime = useMemo(() => {
        const parsed = parseDateFromServer(toDate)

        return format(parsed, TO_FORMAT)
    }, [toDate])

    const copyLink = useCallback(() => {
        const tempInput = document.createElement('input')
        tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px'
        tempInput.value = liveEventLink
        document.body.append(tempInput)
        tempInput.select()
        document.execCommand('copy')
        tempInput.remove()
        dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'Event link copied to clipboard',
            },
        })
    }, [liveEventLink, dispatch])

    return (
        <div className="event-head">
            <div className="chat-head-left">
                <div className="chat-name-block chat-name-block--chat">
                    <div className="chat-name-block__info">
                        <div className="event-name-block">
                            <EventListPopover />
                            {chatMessages.totalReportedMessages ? (
                                <ReportingPopover dialogId={dialogId} chatType={ChatType.LIVE_EVENT_CHAT} />
                            ) : null}
                        </div>
                        <div className="live-event-date">
                            <TimeIcon />
                            <span>{fromTime + ' - ' + toTime}</span>
                            {showEventMenu && (
                                <div className="event-link" onClick={copyLink}>
                                    <LinkIcon />
                                    <span>Event link</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="chat-head-right">{showEventMenu && <EventMenu activeEvent={activeEvent} />}</div>
        </div>
    )
}

export default EventHeader
