import MixPanelService from '../system/services/MixPanelService'
import store from '../redux/store'

export const trackAmbassadorActivated = (universityId, userId) => {
    const { account_info: accountInfo } = store.getState().account
    if (accountInfo && accountInfo.university && accountInfo.university.is_trial) {
        MixPanelService.track('Ambassador activated', {
            university: universityId,
            'Ambassador Id': userId,
        })
    }
}

export const trackTapFeed = (universityId) => {
    const { account_info: accountInfo } = store.getState().account
    if (accountInfo && accountInfo.university && accountInfo.university.is_trial) {
        MixPanelService.track('Tap feed embedded', {
            university: universityId,
        })
    }
}

export const trackAddedPrivacyPolicy = (universityId) => {
    const { account_info: accountInfo } = store.getState().account
    if (accountInfo && accountInfo.university && accountInfo.university.is_trial) {
        MixPanelService.track('Added privacy policy', {
            university: universityId,
        })
    }
}

export const trackSocialProof = (universityId, socialProof) => {
    MixPanelService.track('Social Proof Action', {
        activated: socialProof,
        university: universityId,
    })
}
