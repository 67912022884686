export const CREATE_QUESTION = 'CREATE_QUESTION'
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS'
export const CREATE_QUESTION_FAILED = 'CREATE_QUESTION_FAILED'

export const QUESTIONS_INFO = 'QUESTIONS_INFO'
export const QUESTIONS_INFO_SUCCESS = 'QUESTIONS_INFO_SUCCESS'
export const QUESTIONS_INFO_FAILED = 'QUESTIONS_INFO_FAILED'

export const QUESTIONS_ANSWERS_INFO = 'QUESTIONS_ANSWERS_INFO'
export const QUESTIONS_ANSWERS_INFO_SUCCESS = 'QUESTIONS_ANSWERS_INFO_SUCCESS'
export const QUESTIONS_ANSWERS_INFO_FAILED = 'QUESTIONS_ANSWERS_INFO_FAILED'

export const EDIT_ANSWER = 'EDIT_ANSWER'
export const EDIT_ANSWER_SUCCESS = 'EDIT_ANSWER_SUCCESS'
export const EDIT_ANSWER_FAILED = 'EDIT_ANSWER_FAILED'

export const DELETE_ANSWER = 'DELETE_ANSWER'
export const DELETE_ANSWER_SUCCESS = 'DELETE_ANSWER_SUCCESS'
export const DELETE_ANSWER_FAILED = 'DELETE_ANSWER_FAILED'

export const ANSWER_TOGGLE_PUBLISH = 'ANSWER_TOGGLE_PUBLISH'
export const ANSWER_TOGGLE_PUBLISH_FAILED = 'ANSWER_TOGGLE_PUBLISH_FAILED'
export const ANSWER_TOGGLE_PUBLISH_SUCCESS = 'ANSWER_TOGGLE_PUBLISH_SUCCESS'

export const EDIT_FAQ = 'EDIT_FAQ'
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS'
export const EDIT_FAQ_FAILED = 'EDIT_FAQ_FAILED'

export const DELETE_QUESTION = 'DELETE_QUESTION'
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAILED = 'DELETE_QUESTION_FAILED'

export const EDIT_FAQ_GLOBAL_TAG = 'EDIT_FAQ_GLOBAL_TAG'
export const EDIT_FAQ_GLOBAL_TAG_FINISH = 'EDIT_FAQ_GLOBAL_TAG_FINISH'

export const deleteQuestion = ({ id }) => ({
    type: DELETE_QUESTION,
    payload: id,
})

export const editFAQ = (faqID, options, isAnsweredQuestions, isPublished) => ({
    type: EDIT_FAQ,
    payload: {
        faqID,
        options,
        isAnsweredQuestions,
        isPublished,
    },
})

export const deleteAnswer = ({ id }) => ({
    type: DELETE_ANSWER,
    payload: id,
})

export const saveAnswer = ({ id, content, published }) => ({
    type: EDIT_ANSWER,
    payload: {
        answer_id: id,
        answer: content,
        filter: {
            published,
        },
    },
})
