var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { produce } from 'immer';
import { GET_GLOBAL_POPULAR_LIST_TAGS, GET_GLOBAL_POPULAR_LIST_TAGS_FAILED, GET_GLOBAL_POPULAR_LIST_TAGS_SUCCESS, } from '../../actions/tags/tagsActions';
var initialState = {
    items: [],
    total: 0,
    isLoading: false,
};
export default function (state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case GET_GLOBAL_POPULAR_LIST_TAGS_SUCCESS: {
            return __assign(__assign({}, state), { items: payload.items, isLoading: false });
        }
        case GET_GLOBAL_POPULAR_LIST_TAGS: {
            return produce(state, function (draftState) {
                draftState.isLoading = true;
            });
        }
        case GET_GLOBAL_POPULAR_LIST_TAGS_FAILED: {
            return produce(state, function (draftState) {
                draftState.isLoading = false;
            });
        }
        default: {
            return state;
        }
    }
}
export var selectPopularTags = function (state) { return state.popularGlobalTags.items; };
export var selectPopularTagsLoading = function (state) { return state.popularGlobalTags.isLoading; };
