import React, { PureComponent } from 'react'
import store from '../../redux/store'
import { connect } from 'react-redux'
import { NavLink, Redirect } from 'react-router-dom'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../redux/actions/content/componentsActions'
import tipIcon from '../../../assets/svg/icons/tip.svg'
import ChartBlock from './components/ChartBlock'
import InactiveAmbassadorsBlock from './components/InactiveAmbassadorsBlock'
import { RecentPostsBlock } from './components/RecentPostsBlock'
import RecentAnswersBlock from './components/RecentAnswersBlock'
import SetupStepsBlock from './components/SetupStepsBlock'
import RecentActionsBlock from './components/RecentActionsBlock'
import { GET_HOME_CHART_DATA, GET_HOME_DATA } from '../../redux/actions/home/homeActions'
import { getDateRange } from '../../helpers/time'
import Loader from '../../containers/content/components/Loader'
import dayjs from 'dayjs'
import PostsModal from './components/PostsModal'
import { checkIsInTHE } from '../../common/utils/user-account-helpers'
import { getCommonRoutePath } from '../../helpers/helpers'
import { Image } from 'antd'
import { ProtectedNavLink } from 'src/app/components/protected-nav-link'
import { LicensesEnum, PermissionEnum } from 'src/types/general'
import { Permissions } from 'src/app/permissions/permissions'

class StartPage extends PureComponent {
    constructor(props) {
        super()
        this.state = {
            currentChartOption: props.chart.currentChartOption,
            selectedButton: '7Days',
            selectedDateRange: getDateRange(7),
            contentLastFetchDateRange: {},
            postsLimit: window.innerWidth > 1750 ? 18 : 15,
            isModalOpened: false,
            clickedSlideId: null,
        }

        this.getHomeData = this.getHomeData.bind(this)
        this.onChartDropChange = this.onChartDropChange.bind(this)
        this.onDatesIntervalSelectorUpdate = this.onDatesIntervalSelectorUpdate.bind(this)
        this.handlePostClick = this.handlePostClick.bind(this)
        this.handleClosePost = this.handleClosePost.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'home',
            },
        })
        this.getHomeData()
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'home',
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.getHomeData()
        }
    }

    getHomeData() {
        const { selectedDateRange, postsLimit } = this.state
        const { account, chart } = this.props

        if (
            !account.account_info.isGroupAdmin ||
            (account.account_info.isGroupAdmin && account.currentGroupUniversityId)
        ) {
            store.dispatch({
                type: GET_HOME_CHART_DATA,
                payload: {
                    chartName: chart.currentChartOption,
                    filter: selectedDateRange,
                },
            })
            store.dispatch({
                type: GET_HOME_DATA,
                payload: {
                    dateFilter: selectedDateRange,
                    postsLimit: postsLimit,
                    faqAnswersLimit: 10,
                },
            })
        }
    }

    onChartDropChange(val) {
        store.dispatch({
            type: GET_HOME_CHART_DATA,
            payload: {
                chartName: val,
                filter: this.state.selectedDateRange,
            },
        })
    }

    onDatesIntervalSelectorUpdate(selectedButton, selectedDateRange) {
        this.setState({ selectedButton, selectedDateRange })
        store.dispatch({
            type: GET_HOME_CHART_DATA,
            payload: {
                chartName: this.props.chart.currentChartOption,
                filter: selectedDateRange,
            },
        })
    }

    handlePostClick(post) {
        this.setState({ isModalOpened: true, clickedSlideId: post.id })
    }

    handleClosePost() {
        this.setState({ isModalOpened: false, clickedSlideId: null })
    }

    viewMorePosts = () => {
        this.setState({ isModalOpened: true, clickedSlideId: null })
    }

    render() {
        const { selectedButton, selectedDateRange, postsLimit, isModalOpened, clickedSlideId } = this.state
        const { account, chart, data } = this.props
        const {
            university,
            isGroupAdmin,
            wizard_setup_passed: wizardSetupPassed,
            isIdpGroupAdmin,
        } = account.account_info
        const completeSetupSteps = (account.account_info && wizardSetupPassed) || data.wizardSetupPassed
        const today = dayjs().format('DD MMM')
        const sevenDaysAgo = dayjs().subtract(7, 'd').format('DD MMM')
        const inTHE = checkIsInTHE(university)
        const isTHEPartnerRegistration = inTHE && university.isRegisteredPartner

        const commonPath = getCommonRoutePath()
        const isPls = Permissions.can(PermissionEnum.Pls, false)
        const isIdpActivation = account.showIdpActivation

        if (isPls) {
            return <Redirect to="./pls-onboarding" />
        }
        if (isIdpActivation) {
            return <Redirect to="./idp-activation" />
        }
        return (
            <div>
                {isGroupAdmin && !account.currentGroupUniversityId ? (
                    <section className="content settings no-submenu home-start-guide">
                        <h1 className="settings-title">Group Admin dashboard</h1>
                        <div className="start-guide-intro">
                            <p>
                                The Ambassador Platform is an online tool which helps institutions to recruit more
                                students by enabling peer-to-peer conversations and user-generated content.
                            </p>
                            <p>
                                This is the Group Admin Dashboard through which you can access the reports of individual
                                Admin Dashboards in your group. You can also create one single TAP Feed showing all
                                ambassadors, content and FAQs across your group.
                            </p>
                        </div>

                        <div className="start-guide-grid start-guides">
                            <div className="column">
                                <div className="start-block">
                                    <span className="point">1</span>
                                    <div className="block-body">
                                        <h3>Customize your branding</h3>
                                        <p>Make the service your own by adding your logo and brand colors.</p>
                                        {!isIdpGroupAdmin && (
                                            <NavLink
                                                to={commonPath + '/settings/custom-styles'}
                                                className="start-button"
                                            >
                                                Customize your branding
                                            </NavLink>
                                        )}
                                    </div>
                                </div>
                                <div className="start-block">
                                    <div className="point">2</div>
                                    <div className="block-body">
                                        <h3>Create and embed your TAP Feed</h3>
                                        <p>
                                            Your TAP Feed is where The Ambassador Platform lives on your website and is
                                            made available to prospects. This TAP Feed will show all ambassadors,
                                            content and FAQs from across your group.
                                        </p>
                                        {!isIdpGroupAdmin && (
                                            <ProtectedNavLink
                                                className="start-button"
                                                to={commonPath + '/settings/tap-page'}
                                                licenseName={LicensesEnum.TapFeed}
                                            >
                                                Set it up
                                            </ProtectedNavLink>
                                        )}
                                    </div>
                                </div>
                                <div className="start-block">
                                    <div className="point">3</div>
                                    <div className="block-body">
                                        <h3>Promotion</h3>
                                        <p>
                                            Create engaging and customisable Promotions to make your TAP Feed available
                                            to your target audiences.
                                        </p>
                                        {!isIdpGroupAdmin && (
                                            <ProtectedNavLink
                                                className="start-button"
                                                to={commonPath + '/promotion/pop-card'}
                                                licenseName={LicensesEnum.Promotion}
                                            >
                                                Create Promotions
                                            </ProtectedNavLink>
                                        )}
                                    </div>
                                </div>
                                <div className="start-block">
                                    <div className="point">4</div>
                                    <div className="block-body">
                                        <h3>Reporting</h3>
                                        <p>Use the filter to access reports for each Admin Dashboard in your group.</p>
                                        {!isIdpGroupAdmin && (
                                            <NavLink to={commonPath + '/reporting/overview'} className="start-button">
                                                See Reporting
                                            </NavLink>
                                        )}
                                    </div>
                                </div>
                                <div className="callout">
                                    <strong>That&apos;s it!</strong> Once you&apos;ve completed these simple steps,
                                    you&apos;re ready to go. Don&apos;t hesitate to get in touch if you have any
                                    questions, we&apos;re always happy to help 😊
                                </div>
                            </div>

                            <div className="column resources">
                                <div className="video-wrapper">
                                    <iframe
                                        src="https://player.vimeo.com/video/319905185"
                                        width="640"
                                        height="360"
                                        frameBorder="0"
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        allowFullScreen={true}
                                    ></iframe>
                                </div>

                                <div className="resources-box">
                                    <h3>Helpful resources</h3>
                                    <ul>
                                        <li>
                                            Customising The Ambassador Platform to&nbsp;
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href="https://knowledge.theambassadorplatform.com/customizing-tap-to-fit-your-brand"
                                            >
                                                fit your brand
                                            </a>
                                        </li>
                                        <li>
                                            How&nbsp;
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href="https://knowledge.theambassadorplatform.com/how-terms-conditions-work-on-the-ambassador-platform"
                                            >
                                                terms and conditions
                                            </a>
                                            &nbsp; work on The Ambassador Platform
                                        </li>
                                        <li>
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href="https://knowledge.theambassadorplatform.com/data-collection-on-the-ambassador-platform"
                                            >
                                                Data collection
                                            </a>
                                            &nbsp; on The Ambassador Platform
                                        </li>
                                        <li>
                                            What is your&nbsp;
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href="https://knowledge.theambassadorplatform.com/what-is-your-tap-feed"
                                            >
                                                TAP Feed
                                            </a>
                                            ?
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : chart.isLoading || data.isLoading ? (
                    <section className="content no-submenu home-page">
                        <Loader />
                    </section>
                ) : (
                    <section
                        className={
                            'content no-submenu home-page' + (!completeSetupSteps ? ' with-not-completed-steps' : '')
                        }
                    >
                        <div className="home-body">
                            <h1 className="home-title">Admin dashboard</h1>

                            <div className="tip-admin-centre">
                                <Image src={tipIcon} alt="" />
                                <a
                                    rel="noopener noreferrer"
                                    href="https://www.theambassadorplatform.com/training-center"
                                    target="_blank"
                                >
                                    Training centre
                                </a>
                            </div>

                            <ChartBlock
                                currentChartOption={chart.currentChartOption}
                                selectedButton={selectedButton}
                                selectedDateRange={selectedDateRange}
                                onChartDropChange={this.onChartDropChange}
                                onDatesIntervalSelectorUpdate={this.onDatesIntervalSelectorUpdate}
                            />

                            <InactiveAmbassadorsBlock users={data.inactiveAmbassadors} />

                            <div className="recent-data-block">
                                <RecentPostsBlock
                                    recentPosts={data.recentPosts}
                                    total={data.postsTotal}
                                    postsLimit={postsLimit}
                                    today={today}
                                    sevenDaysAgo={sevenDaysAgo}
                                    handlePostClick={this.handlePostClick}
                                    viewMorePosts={this.viewMorePosts}
                                />
                                <RecentAnswersBlock
                                    recentFaqAnswers={data.recentFaqAnswers}
                                    total={data.faqsTotal}
                                    today={today}
                                    sevenDaysAgo={sevenDaysAgo}
                                />
                            </div>

                            {!isTHEPartnerRegistration ? (
                                !completeSetupSteps ? (
                                    <SetupStepsBlock wizardSetupData={data.wizardSetupData} />
                                ) : (
                                    <RecentActionsBlock account={account} activityData={data.conversationsActivity} />
                                )
                            ) : null}
                        </div>
                    </section>
                )}
                {isModalOpened && (
                    <PostsModal
                        total={data.postsTotal}
                        postsLimit={data.postsLimit}
                        recentTotal={data.postsTotal}
                        recentPosts={data.recentPosts}
                        clickedSlideId={clickedSlideId}
                        handleClosePost={this.handleClosePost}
                    />
                )}
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        chart: state.homeChart,
        data: state.homeData,
    }
})(StartPage)
