import React, { useCallback, useState } from 'react'

import { Form, Row, Col } from 'antd'
import { useDispatch } from 'react-redux'

import MobilePhoneInput from '../../../../../../../../../components/mobile-phone-input/mobile-phone-input'
import { SmsSendButton } from '../../components'

import { getAppDownloadLink } from '../../../../../../../../../redux/actions/pls-sign-up/pls-sign-up-actions'

const SmsInvitationForm = () => {
    const [isCountryNotSupported, setIsCountryNotSupported] = useState(false)
    const [formattedPhone, setFormattedPhone] = useState('')
    const [dialCode, setDialCode] = useState('')
    const [resendAllowed, setResendAllowed] = useState(true)

    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const initialValues = {
        mobileNumber: '',
    }

    const onSubmit = useCallback(
        (values) => {
            form.validateFields().then((values) => {
                console.log('submitForm values :', values)

                const data = {
                    method: 'sms',
                    phoneCountry: dialCode,
                    phoneNumber: formattedPhone,
                }
                dispatch(getAppDownloadLink(data))
                setResendAllowed(false)
            })
        },
        [form, formattedPhone, dialCode, dispatch]
    )

    const handlePhoneInput = useCallback(
        (phone, dialCode, countryCode, supportedCountries) => {
            const isSupported = supportedCountries.has(countryCode)
            const formatPhone = phone.replace(/[ ()+-]/g, '').slice(dialCode.length)

            setIsCountryNotSupported(!isSupported)
            setFormattedPhone(formatPhone)
            setDialCode(dialCode)

            form.validateFields(['mobile_number'])
        },
        [form]
    )

    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            autoComplete="off"
            hideRequiredMark
            onFinish={onSubmit}
        >
            <Form.Item
                name="mobileNumber"
                label="Mobile phone number"
                className="form-item mobile-number"
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (isCountryNotSupported) {
                                return Promise.reject(
                                    new Error('SMS notifications temporary are unavailable for your country')
                                )
                            }
                            if (formattedPhone.length < 7) {
                                return Promise.reject(new Error('Phone number must contain more than 7 digits'))
                            }
                            return Promise.resolve()
                        },
                    }),
                ]}
            >
                <Row justify="space-between">
                    <Col flex="85%">
                        <MobilePhoneInput
                            handlePhoneInput={handlePhoneInput}
                            isCountryNotSupported={isCountryNotSupported}
                        />
                    </Col>
                    <Col>
                        <SmsSendButton resendAllowed={resendAllowed} setResendAllowed={setResendAllowed} />
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    )
}

export default SmsInvitationForm
