var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Button as AntdButton } from 'antd';
import React from 'react';
import './Button.scss';
export var Button = function (props) {
    return React.createElement(AntdButton, __assign({}, props, { type: props.type || 'primary' }));
};
