import React from 'react';
import { PostItemView } from './PostItemView';
export var PostsGroupItem = function (_a) {
    var title = _a.title, posts = _a.posts, onPostClick = _a.onPostClick, onShareClick = _a.onShareClick;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "py-8 bg-light-gray" },
            React.createElement("h2", null, title)),
        React.createElement("div", { className: "grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6" }, posts.map(function (item, index) {
            return (React.createElement(PostItemView, { onClick: onPostClick, index: index, post: item, key: item.id, onShareClick: onShareClick }));
        }))));
};
