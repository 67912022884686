import React, { useCallback, useState, useMemo } from 'react'
import { Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import pencil from '../../../../../../assets/img/pencil.png'

import { changeAmbassadorType } from '../../../../../redux/actions/content/usersActions'

const { Option } = Select

const AmbassadorType = ({ profile, changeStudentStaffType, userId, saveStudentInfo }) => {
    const profileTypes = useSelector((state) => state.users.profileTypes)
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()

    const onFinish = useCallback(
        (values) => {
            const ambassadorType = profileTypes?.find((profileType) => profileType.id === +values.ambassadorId)

            dispatch(
                changeAmbassadorType({
                    id: values.ambassadorId,
                    userId,
                    key: ambassadorType?.key || '',
                    name: ambassadorType?.name || '',
                })
            )

            setEditMode(false)
            saveStudentInfo()
        },
        [profileTypes, dispatch, userId, saveStudentInfo]
    )

    const editType = useCallback(() => {
        setEditMode(true)
    }, [])

    const onCancel = useCallback(() => {
        setEditMode(false)
        changeStudentStaffType(null)
    }, [changeStudentStaffType])

    const options = useMemo(
        () =>
            profileTypes?.map((type) => {
                return (
                    <Option key={type.key} value={type.id} label={type.name}>
                        {type.name}
                    </Option>
                )
            }),
        [profileTypes]
    )

    return editMode ? (
        <>
            <Form
                initialValues={{
                    ambassadorId: profile ? profile[0].id : '',
                }}
                onFinish={onFinish}
                autoComplete="off"
                hideRequiredMark
            >
                <Form.Item
                    name="ambassadorId"
                    label={null}
                    style={{ marginBottom: 20 }}
                    rules={[{ required: true, message: 'Select country' }]}
                >
                    <Select
                        optionFilterProp="children"
                        optionLabelProp="label"
                        style={{ fontSize: 16, maxWidth: 200, minWidth: 150 }}
                    >
                        {options}
                    </Select>
                </Form.Item>
                <div className="input__buttons">
                    {profile && (
                        <button className="input-control-button" onClick={onCancel}>
                            Cancel
                        </button>
                    )}
                    <button className="input-control-button" type="submit">
                        Save
                    </button>
                </div>
            </Form>
        </>
    ) : (
        <>
            <div className="profile-info-tags-items">
                {profile.map((data) => data.name)}

                <button className="input-control-button" onClick={editType}>
                    <img className="pencil-icon" src={pencil} alt="" />
                </button>
            </div>
        </>
    )
}

export default AmbassadorType
