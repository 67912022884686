import React from 'react';
import { Card } from 'antd';
import { Icon } from 'src/app/components/base/Icon';
import { clsx } from 'src/app/helpers/classNames';
import { CardTypesEnum } from './helpers';
export var TypeCard = function (_a) {
    var isActive = _a.isActive, isActiveInstitutionCard = _a.isActiveInstitutionCard, onClick = _a.onClick, card = _a.card;
    return (React.createElement(Card, { onClick: onClick, className: clsx('pls-type-card', isActive ? 'active' : undefined, isActiveInstitutionCard ? CardTypesEnum.Institution : undefined) },
        React.createElement("div", { className: "grid gap-y-2 text-center justify-items-center" },
            React.createElement(Icon, { className: clsx('pls-checkbox-icon', isActive ? 'active' : undefined, isActiveInstitutionCard ? CardTypesEnum.Institution : undefined), icon: "pls_checkbox" }),
            React.createElement(Icon, { className: clsx('pls-card-icon', isActive ? 'active' : undefined, isActiveInstitutionCard ? CardTypesEnum.Institution : undefined), icon: card.icon }),
            React.createElement("h3", { className: clsx('pls-card-title', isActive ? 'active' : undefined, isActiveInstitutionCard ? CardTypesEnum.Institution : undefined) }, card.title),
            React.createElement("p", { className: clsx('pls-card-description', isActive ? 'active' : undefined, isActiveInstitutionCard ? CardTypesEnum.Institution : undefined) }, card.text))));
};
