import { pluckIds } from '../../common/utils/array-utils'

const mapById = (items) => {
    const result = {}

    for (const item of items) {
        result[item.id] = item
    }

    return result
}

export const createItemsShape = (items) => ({
    allIds: pluckIds(items),
    byId: mapById(items),
})
