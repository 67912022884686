import { useEffect, useRef } from 'react';
import { pusherChild } from '../system/services/PusherWrapper';
var channel = pusherChild.subscribe('dashboard');
export function usePusher(eventName, callback) {
    var callbackRef = useRef(callback);
    useEffect(function () {
        callbackRef.current = callback;
    }, [callback]);
    useEffect(function () {
        if (channel != null) {
            channel.bind(eventName, function (data) {
                callbackRef.current(data);
            });
        }
        return function () {
            channel === null || channel === void 0 ? void 0 : channel.unbind(eventName);
        };
    }, [eventName]);
}
