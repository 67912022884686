import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../redux/actions/content/componentsActions';
import { communityTypeRootScreen } from './screens';
import { Protect } from 'src/app/permissions/Protect';
import { PermissionEnum } from 'src/types/general';
var CommunytyContainer = function () {
    var dispatch = useDispatch();
    var path = useRouteMatch().path;
    useEffect(function () {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'buzz',
            },
        });
    }, [dispatch]);
    return (React.createElement(Protect, { permission: PermissionEnum.Community, defaultValue: false },
        React.createElement("div", { className: "community-container" },
            React.createElement("div", { className: "content" },
                React.createElement(Route, { path: "".concat(path, "/:param"), component: communityTypeRootScreen })))));
};
export default CommunytyContainer;
