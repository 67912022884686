import React, { useState, useEffect } from 'react'

import { Button } from 'antd'

const localStorageWrapper = window.localStorage

const SMS_REQUEST_COUNT_KEY = 'SmsRequestedCount'

const getCodeRequestCount = () => {
    return +localStorageWrapper.getItem(SMS_REQUEST_COUNT_KEY) || 0
}

const SmsSendButton = ({ onFinish, resendAllowed, setResendAllowed }) => {
    const [seconds, setSeconds] = useState(60)

    useEffect(() => {
        let interval = null
        if (!resendAllowed && seconds !== 0) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1)
                localStorageWrapper.setItem(SMS_REQUEST_COUNT_KEY, seconds - 1)
            }, 1000)
        } else {
            setResendAllowed(true)
            clearInterval(interval)
            setSeconds(60)
        }
        return () => clearInterval(interval)
    }, [resendAllowed, seconds, setResendAllowed])

    useEffect(() => {
        const count = getCodeRequestCount()
        if (count) {
            setSeconds(count)
            setResendAllowed(false)
        }
    }, [setResendAllowed])

    return (
        <>
            {resendAllowed ? (
                <Button type="link" htmlType="submit" disabled={!resendAllowed}>
                    Get link
                </Button>
            ) : (
                <div className="resend-sms-counter">Resend in {seconds} sec.</div>
            )}
        </>
    )
}

export default SmsSendButton
