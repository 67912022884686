import React, { PureComponent } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class LineChart extends PureComponent {
    prepareOptions() {
        return {
            chart: {
                type: 'spline',
                height: this.props.height,
            },
            plotOptions: {
                spline: {
                    marker: {
                        enabled: false,
                        fillColor: '#fff',
                        lineWidth: 2,
                        lineColor: null,
                    },
                },
            },
            colors: [this.props.color],
            title: {
                text: null,
            },
            xAxis: {
                type: 'datetime',
                gridLineWidth: 1,
                gridLineColor: '#ffddbb',
                gridLineDashStyle: 'longdash',
            },
            yAxis: {
                title: {
                    text: null,
                },
                labels: {
                    enabled: true,
                },
                min: 0,
                allowDecimals: false,
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                formatter: this.props.tooltipFormatter,
                backgroundColor: '#646a7a',
                borderColor: 'none',
                useHTML: true,
                shape: 'square',
                shadow: false,
            },
            series: [
                {
                    data: this.props.data,
                },
            ],
        }
    }

    render() {
        return <HighchartsReact highcharts={Highcharts} options={this.prepareOptions()} />
    }
}

export default LineChart
