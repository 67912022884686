import { NEW_MESSAGES_RECEIVED, HIDE_NEW_MESSAGES } from '../../actions/components/componentsActions'

import { NEW_MESSAGES } from '../../store/initialState'

const initialState = NEW_MESSAGES

export default function (state = initialState, action) {
    const type = action.type

    switch (type) {
        case NEW_MESSAGES_RECEIVED: {
            return {
                isNewMessagesModalOpened: true,
            }
        }

        case HIDE_NEW_MESSAGES: {
            return {
                isNewMessagesModalOpened: false,
            }
        }

        default: {
            return state
        }
    }
}
