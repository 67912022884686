import React, { PureComponent } from 'react'
import { Card, Row, Col } from 'antd'

import LineChart from '../components/LineChart'
import ChartHeader from '../components/ChartHeader'

import { prepareNumberDataWithAverage, numberTooltipFormatter, pluralize } from '../../../helpers/chart'

class Content extends PureComponent {
    prepareContentGroupsChartData() {
        return prepareNumberDataWithAverage(this.props.data.contentGroupsCreated, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    preparePostsCreatedChartData() {
        return prepareNumberDataWithAverage(this.props.data.postsCreated, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    preparePostsPublishedChartData() {
        return prepareNumberDataWithAverage(this.props.data.postsPublished, [
            [Date.parse(this.props.fromDate), 0],
            [Date.parse(this.props.toDate), 0],
        ])
    }

    conversationsTooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'group'))
    }

    postsTooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'post'))
    }

    render() {
        const contentGroupsData = this.prepareContentGroupsChartData()
        const postsCreatedData = this.preparePostsCreatedChartData()
        const postsPublishedData = this.preparePostsPublishedChartData()

        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Content Groups Created"
                                label={'Average: ' + contentGroupsData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + contentGroupsData.total}
                                totalLabelDescription="Across date range"
                            />
                            <LineChart
                                color="#d2e116"
                                data={contentGroupsData.data}
                                height={200}
                                tooltipFormatter={this.conversationsTooltipFormatter}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Posts Created"
                                label={'Average: ' + postsCreatedData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + postsCreatedData.total}
                                totalLabelDescription="Across date range"
                            />
                            <LineChart
                                color="#afd0eb"
                                data={postsCreatedData.data}
                                height={200}
                                tooltipFormatter={this.postsTooltipFormatter}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card className="reporting-block">
                            <ChartHeader
                                title="Posts Published"
                                label={'Average: ' + postsPublishedData.averageNumber}
                                labelDescription="Per day across date range"
                                totalLabel={'Total: ' + postsPublishedData.total}
                                totalLabelDescription="Across date range"
                            />
                            <LineChart
                                color="#fde971"
                                data={postsPublishedData.data}
                                height={200}
                                tooltipFormatter={this.postsTooltipFormatter}
                            />
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default Content
