import { PATTERNS } from '../../common/regex';
/**
 * Wrap links in message text
 */
export function wrapLinks(text) {
    return text.replace(PATTERNS.url, function (url) {
        return "[link]".concat(url, "[/link]");
    });
}
export function formatChatMessage(text) {
    return wrapLinks(text);
}
