import React, { useEffect, useRef } from 'react'

const ScreenVideo = ({ view }) => {
    const wrapperRef = useRef(null)

    useEffect(() => {
        if (!view) {
            wrapperRef.current.append('NO video')
        } else {
            if (wrapperRef.current.firstChild) {
                wrapperRef.current.firstChild.remove()
                wrapperRef.current.append(view)
            } else {
                wrapperRef.current.append(view)
            }
        }
    }, [view])

    return (
        <div className="participant-video" ref={wrapperRef}>
            ScreenVideo
        </div>
    )
}

export default ScreenVideo
