import React, { PureComponent } from 'react'
import dayjs from 'dayjs'
import { connect } from 'react-redux'
import questionMark from '../../../../../assets/svg/icons/question-mark.svg'
import edit from '../../../../../assets/svg/icons/pencil.svg'
import store from '../../../../redux/store'
import { openDeleteModal } from '../../../../redux/actions/content/postsActions'
import DeleteQuestionAnswerButton from '../../components/DeleteQuestionAnswerButton'
import {
    CLOSE_CREATE_QUESTION_POPUP,
    OPEN_CREATE_QUESTION_POPUP,
} from '../../../../redux/actions/faq/componentsActions'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

class NewQuestion extends PureComponent {
    handleOpenCreateQuestionPopup(options) {
        store.dispatch({
            type: OPEN_CREATE_QUESTION_POPUP,
            payload: { ...options },
        })
    }

    handleCloseCreateQuestionPopup() {
        store.dispatch({
            type: CLOSE_CREATE_QUESTION_POPUP,
        })
    }

    render() {
        const { faq, openDeleteModal } = this.props
        const createdAt = dayjs(faq.created_at).calendar(null, {
            sameDay: 'H:mm',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday at ]H:mm',
            lastWeek: 'DD MMMM',
            sameElse: 'DD MMMM',
        })

        const optionsForForm = {
            mode: 'edit',
            faqContent: faq.question,
            faqID: faq.id,
            globalTags: faq.globalTags,
            members: faq.members || [],
        }

        return (
            <div className="question">
                <img className="question__title-icon" src={questionMark} alt="Q" />
                <div className="question__body">
                    <div className="question__body__time">{createdAt}</div>
                    <p className="question__body__content__text question__body__content__text--new-question">
                        {faq.question}
                    </p>
                </div>
                <div className="question__buttons">
                    <button
                        type="submit"
                        className="question__buttons__button"
                        onClick={() => this.handleOpenCreateQuestionPopup(optionsForForm)}
                    >
                        <img className="question__buttons__button__icon" src={edit} alt="Edit" />
                        Edit
                    </button>
                    <DeleteQuestionAnswerButton openDeleteModal={() => openDeleteModal(faq.id, 'question')} />
                </div>
            </div>
        )
    }
}

export default connect(null, { openDeleteModal })(NewQuestion)
