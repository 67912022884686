import { Checkbox, Form, Input } from 'antd';
import React, { useState } from 'react';
import { concatClsx } from 'src/app/helpers/classNames';
import { clientPriorities } from '../variables';
export var ProfileSetupFields = function () {
    var _a = useState([]), checkedItems = _a[0], setCheckedItems = _a[1];
    var handleCheckboxChange = function (value) {
        setCheckedItems(value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form.Item, { name: "jobTitle", label: "Job Title/Role*", rules: [
                {
                    required: true,
                    message: 'Please enter job title',
                },
            ] },
            React.createElement(Input, { placeholder: "e.g. Head of Student Recruitment" })),
        React.createElement(Form.Item, { name: "priorities", label: React.createElement("span", { className: "text-reading-lg font-interface-bold" }, "What are your current priorities?*"), rules: [
                {
                    required: true,
                    message: 'Please select at least one priority',
                },
            ] },
            React.createElement(Checkbox.Group, { onChange: handleCheckboxChange, className: "w-full grid" },
                React.createElement("div", { className: "md:grid md:grid-cols-2 md:gap-x-2" }, clientPriorities.map(function (item, index) {
                    var checkboxClassName = concatClsx('w-full rounded mb-2', checkedItems.includes(item) ? 'bg-[#D5E0F4]' : 'bg-[#F3F4FA]');
                    return (React.createElement("div", { key: index, className: checkboxClassName },
                        React.createElement(Checkbox, { value: item, className: "p-3 w-full" }, item)));
                }))))));
};
