import {
    UPLOAD_COMPANIES_CSV_SUCCESS,
    GET_COMPANIES_DATA_SUCCESS,
    ADD_COMPANY_SUCCESS,
    DELETE_COMPANY_SUCCESS,
    EDIT_COMPANY_SUCCESS,
} from '../../../actions/settings/companies-control'

import { COMPANIES } from '../../../store/initialState'

const initialState = COMPANIES

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case UPLOAD_COMPANIES_CSV_SUCCESS: {
            const newState = {
                ...state,
                companies: payload.companies,
            }

            return newState
        }

        case GET_COMPANIES_DATA_SUCCESS: {
            const newState = {
                ...state,
                companies: payload,
            }

            return newState
        }

        case ADD_COMPANY_SUCCESS: {
            const newState = {
                ...state,
                companies: payload,
            }

            return newState
        }

        case DELETE_COMPANY_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.companies = state.companies.filter((item) => item.id !== payload.id)
            return newState
        }

        case EDIT_COMPANY_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.companies = state.companies.map((item) => {
                if (item.id === payload.id) {
                    item.name = payload.name
                }
                return item
            })
            return newState
        }
        default: {
            return state
        }
    }
}
