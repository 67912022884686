import Modal from 'antd/es/modal'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import Search from '../../../components/Search'
import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'
import {
    USERS_INFO_REQUESTED,
    USER_TOGGLE_BAN_REQUESTED,
    CLOSE_CONFIRM_BAN_POPUP,
    changeUserIntroduction,
    changeUserDescription,
    changeUserSubject,
    MAX_USERS_LIMIT_POPUP_TOGGLE,
    changeAmbassadorInfo,
} from '../../../redux/actions/content/usersActions'
import store from '../../../redux/store'

import '../../../../assets/js/custom/common/common'
import ConfirmBanPopup from './popups/ConfirmBanPopup'
import UserRow from './UserRow'

import { checkIsInTHE } from '../../../common/utils/user-account-helpers'

// eslint-disable-next-line import/order
import ReactPaginate from 'react-paginate'

class UsersTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            userBannCheckboxes: {},
            userChatCheckboxes: {},
            userFaqCheckboxes: {},
            userContentCheckboxes: {},
            checkboxesLoad: false,
            newUsersCount: 0,
        }

        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleConfirmBanSubmit = this.handleConfirmBanSubmit.bind(this)
        this.handleCopyUserLink = this.handleCopyUserLink.bind(this)
        this.handleNewUser = this.handleNewUser.bind(this)
        this.requestSearch = this.requestSearch.bind(this)
    }

    componentDidMount() {
        this.getUsersInfo(0, false)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.getUsersInfo(0, false)
        }
    }

    getUsersInfo(offset, search = true) {
        const { account, users } = this.props
        const payload = {
            filter: {
                university_id: account.account_info.university_id,
            },
            limit: 10,
            offset: offset,
            withGlobalTags: true,
        }
        if (search) {
            payload.search = {
                searchItems: users.searchItems,
            }
        }
        store.dispatch({
            type: USERS_INFO_REQUESTED,
            payload: payload,
        })
    }

    handleChangePage(select) {
        this.getUsersInfo(10 * select.selected)
    }

    handleCopyUserLink(userId) {
        if (!this.props.account.account_info.university.university_url) {
            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: "You haven't yet set up your TAP Feed widget. Please embed a TAP Feed widget in your website in order to share links to your ambassadors' profile cards.",
                },
            })
        } else {
            const copyText = document.querySelector('.university-access-code')
            copyText.value = this.props.account.account_info.university.university_url + '?user_id=' + userId
            copyText.select()
            document.execCommand('copy')

            store.dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Student link copied to clipboard',
                },
            })
        }
    }

    handleConfirmBanSubmit() {
        const userId = this.props.confirmBanModal.userId
        const userBannCheckboxes = this.state.userBannCheckboxes
        userBannCheckboxes[userId] = !userBannCheckboxes[userId]

        this.setState({
            userBannCheckboxes: userBannCheckboxes,
        })

        store.dispatch({
            type: USER_TOGGLE_BAN_REQUESTED,
            method: 'ban',
            payload: {
                university_id: this.props.account.account_info.university_id,
                user_id: userId,
                permission_id: null,
            },
        })

        store.dispatch({
            type: CLOSE_CONFIRM_BAN_POPUP,
        })
    }

    static handleCloseConfirmBanPopup() {
        store.dispatch({
            type: CLOSE_CONFIRM_BAN_POPUP,
        })
    }

    handleNewUser() {
        this.setState((state) => ({ ...state, newUsersCount: state.newUsersCount + 1 }))
    }

    requestSearch(items) {
        store.dispatch({
            type: USERS_INFO_REQUESTED,
            payload: {
                filter: {
                    university_id: this.props.account.account_info.university_id,
                },
                search: {
                    searchItems: items,
                },
                limit: 10,
                offset: 0,
                withGlobalTags: true,
            },
        })
    }

    handleCloseMaxLimitPopup = () => {
        store.dispatch({
            type: MAX_USERS_LIMIT_POPUP_TOGGLE,
        })
    }

    render() {
        const { newUsersCount } = this.state
        const { university_id: universityId, university } = this.props.account.account_info
        const {
            users,
            changeUserIntroduction,
            changeUserDescription,
            confirmBanModal,
            changeUserSubject,
            changeAmbassadorInfo,
        } = this.props

        const pageCount = users.total && Number.parseInt(users.total) > 10 ? Math.ceil(users.total / 10) : 0

        const inTHE = checkIsInTHE(university)

        const isTHEPartnerRegistration = inTHE && university.isRegisteredPartner

        if (users.items.length === 0 && users.searchItems.length === 0) {
            return (
                <div className="non-cluster">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2 -2 24 24"
                        width="50"
                        height="50"
                        preserveAspectRatio="xMinYMin"
                        className="jam jam-user-circle"
                    >
                        <path d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-14a4 4 0 0 1 4 4v2a4 4 0 1 1-8 0V8a4 4 0 0 1 4-4zm0 2a2 2 0 0 0-2 2v2a2 2 0 1 0 4 0V8a2 2 0 0 0-2-2zM5.91 16.876a8.033 8.033 0 0 1-1.58-1.232 5.57 5.57 0 0 1 2.204-1.574 1 1 0 1 1 .733 1.86c-.532.21-.993.538-1.358.946zm8.144.022a3.652 3.652 0 0 0-1.41-.964 1 1 0 1 1 .712-1.868 5.65 5.65 0 0 1 2.284 1.607 8.032 8.032 0 0 1-1.586 1.225z" />
                    </svg>
                    You have no registered ambassadors. <Link to={'/settings/invite'}>Invite</Link> your ambassadors to
                    get started
                </div>
            )
        }

        let pagination = null
        if (pageCount !== 0) {
            pagination = (
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handleChangePage}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            )
        }

        return (
            <div className="users-container flexible-page">
                <div className="top-side-container">
                    <div className="left-side-container">
                        <Search requestSearch={this.requestSearch} currentItems={users.searchItems} />
                    </div>
                    <div className="right-side-container">
                        <Link to="/settings/invite" className="el-btn btn-top-right invite-btn">
                            Invite ambassadors
                        </Link>
                    </div>
                </div>
                <h4 className="settings-title">
                    Ambassadors <span>{newUsersCount} new today</span>
                </h4>
                <div className="table-container mod-users-table">
                    <input className="university-access-code" type="text" value="" onChange={() => {}} />
                    <table className="table ambassadors-table">
                        <thead className="table-head">
                            <tr>
                                <td className="table-head-item">Ambassador</td>
                                <td className="table-head-item">Profile information</td>
                                <td className="table-head-item">Time active</td>
                                <td className="table-head-item">Last active</td>
                                <td className="table-head-item">User feedback</td>
                                <td className="table-head-item">Enabled globally</td>
                                {university.partnerGroups &&
                                    university.partnerGroups.length > 0 &&
                                    university.partnerGroups.map((group, index) => (
                                        <td key={index} className="table-head-item">
                                            {group.name}
                                        </td>
                                    ))}
                                {!isTHEPartnerRegistration && <td className="table-head-item">Tap Feed</td>}
                                <td className="table-head-item">Chat</td>
                                <td className="table-head-item">Content</td>
                                <td className="table-head-item">FAQ</td>
                                <td className="table-head-item">Link</td>
                            </tr>
                        </thead>
                        <tbody>
                            {users &&
                                users.items.map((user, index) => (
                                    <UserRow
                                        key={user.id}
                                        user={user}
                                        index={index}
                                        handleNewUser={this.handleNewUser}
                                        universityId={universityId}
                                        universityName={university.name}
                                        partnerGroups={university.partnerGroups}
                                        disableToggle={users.disableToggle}
                                        handleCopyUserLink={this.handleCopyUserLink}
                                        handleChangeIntroduction={changeUserIntroduction}
                                        handleChangeDescription={changeUserDescription}
                                        handleChangeSubject={changeUserSubject}
                                        changeAmbassadorInfo={changeAmbassadorInfo}
                                        handleViewDetailedInfo={this.userInfoDataView}
                                        editableProfile={true}
                                    />
                                ))}
                        </tbody>
                    </table>

                    {pagination}
                </div>

                <Modal
                    open={confirmBanModal.isOpened}
                    centered={true}
                    footer={null}
                    closable={true}
                    maskClosable={true}
                    onCancel={this.handleCloseConfirmBanPopup}
                >
                    <ConfirmBanPopup
                        ban_type={confirmBanModal}
                        handleConfirmBan={this.handleConfirmBanSubmit}
                        handleClosePopup={this.handleCloseConfirmBanPopup}
                    />
                </Modal>
                <Modal
                    open={users.usersMaxLimitReached}
                    centered={true}
                    width={420}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.handleCloseMaxLimitPopup}
                >
                    <div className="max-limit-popup">
                        <h3 className="modal-title">{users.usersMaxLimitReachedText}</h3>
                        <div className="btn-wrap max-limit-users-confirm-button">
                            <button type="submit" className="el-btn" onClick={this.handleCloseMaxLimitPopup}>
                                Ok
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default connect(
    (state) => {
        return {
            account: state.account,
            users: state.users,
            confirmBanModal: state.confirmBanModal,
        }
    },
    { changeUserIntroduction, changeUserDescription, changeUserSubject, changeAmbassadorInfo }
)(withRouter(UsersTable))
