import UserModel from './UserModel'

const getUsers = (items) => {
    const users = []
    for (const item in items) {
        users[item] = UserModel(items[item])
    }
    return users
}

const ChatModel = (data) => {
    const response = {
        id: null,
        type: null,
        name: '',
        reported_reason: '',
        closed: false,
        admin_closed: false,
        archived: false,
        reported: false,
        user_reported: false,
        is_mute: false,
        is_private: false,
        private_code: '',
        is_pinned: false,
        is_prospect_deleted: false,
        is_admin_involved: false,
        last_activity: new Date(),
        new_messages: 0,
        search_message: false,
        search_member: false,
        last_message: null, // Message Model
        prospect: null, // User Model
        ambassador: null, // User Model
        members: [], // User Model
        globalTags: null, // Global Tag Model
    }

    // Here we are saving data as we want to see them, we could add additional validation where we need to
    if (data) {
        if (data.id) response.id = data.id
        if (data.name) response.name = data.name
        if (data.type) response.type = data.type
        if (data.reported_reason) response.reported_reason = data.reported_reason
        if (data.admin_closed) response.admin_closed = data.admin_closed
        if (data.closed) response.closed = data.closed
        if (data.archived) response.archived = data.archived
        if (data.reported) response.reported = data.reported
        if (data.user_reported) response.user_reported = data.user_reported
        if (data.is_mute) response.is_mute = data.is_mute
        if (data.is_private) response.is_private = data.is_private
        if (data.private_code) response.private_code = data.private_code
        if (data.is_pinned) response.is_pinned = data.is_pinned
        if (data.is_prospect_deleted) response.is_prospect_deleted = data.is_prospect_deleted
        if (data.is_admin_involved) response.is_admin_involved = data.is_admin_involved
        if (data.last_activity) response.last_activity = data.last_activity
        if (data.new_messages) response.new_messages = data.new_messages
        if (data.search_message) response.search_message = data.search_message
        if (data.search_member) response.search_member = data.search_member
        if (data.last_message) response.last_message = data.last_message
        if (data.prospect) response.prospect = UserModel(data.prospect)
        if (data.ambassador) response.ambassador = UserModel(data.ambassador)
        if (data.members) response.members = getUsers(data.members)
        if (data.globalTags) response.globalTags = data.globalTags
        if (data.isBlocked) response.isBlocked = data.isBlocked
    }

    return response
}

export default ChatModel
