import React, { PureComponent } from 'react'

class RegistrationSuccessModal extends PureComponent {
    render() {
        const { handleClosePopup } = this.props

        return (
            <div className="registration-success forgot-password-success">
                <h3 className="modal-title">Password recovered</h3>
                <button type="submit" className="el-btn registration-success-button" onClick={handleClosePopup}>
                    Close
                </button>
            </div>
        )
    }
}

export default RegistrationSuccessModal
