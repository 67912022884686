import React, { PureComponent, Fragment } from 'react'
import { Card } from 'antd'

class OldSummary extends PureComponent {
    render() {
        const {
            messages_sent: messagesSent,
            answer_time: answerTime = false,
            feedback_rating: feedbackRating = {},
            prospects_created: prospectsCreated,
            faq_published: faqPublished,
            posts_published_downloaded: postsPublishedDownloaded,
            ambassador_time: ambassadorTime,
            activeConversation,
        } = this.props.data

        let answerTimeHours, answerTimeMinutes, ambassadorsTimeHours, ambassadorsTimeMinutes

        if (answerTime) {
            answerTimeHours = answerTime.days ? answerTime.days !== 0 && answerTime.days * 24 + answerTime.hours : 0

            answerTimeMinutes = answerTime.minutes
        }

        if (ambassadorTime) {
            ambassadorsTimeHours = Math.trunc(ambassadorTime / 60)
            ambassadorsTimeMinutes = ambassadorTime % 60
        }

        return (
            <Fragment>
                <div className="tile-grid">
                    <Card className="reporting-block">
                        <div className="tile">
                            <div className="tile-wrap">
                                <h3>Number of messages sent in chat</h3>
                                <div className="tile-stat">{messagesSent || 0}</div>
                            </div>
                        </div>
                    </Card>

                    <Card className="reporting-block">
                        <div className="tile">
                            <div className="tile-wrap">
                                <h3>Chat feedback rating (in numbers)</h3>
                                <div className="tile-stat feedback">
                                    {feedbackRating.good || 0} 😊
                                    <br />
                                    {feedbackRating.normal || 0} 😐
                                    <br />
                                    {feedbackRating.bad || 0} 😞
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card className="reporting-block">
                        <div className="tile">
                            <div className="tile-wrap">
                                <h3>Average time for an FAQ to be answered</h3>
                                <div className="tile-stat time">
                                    {answerTimeHours ? (
                                        <span>
                                            {answerTimeHours}
                                            <span className="tile-stat__sub-article">hr&nbsp;&nbsp;</span>
                                        </span>
                                    ) : null}
                                    {answerTimeMinutes ? (
                                        <span>
                                            {answerTimeMinutes}
                                            <span className="tile-stat__sub-article">min</span>
                                        </span>
                                    ) : (
                                        <span>0</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card className="reporting-block">
                        <div className="tile">
                            <div className="tile-wrap">
                                <h3>Total ambassador time spent on service</h3>
                                <div className="tile-stat time">
                                    {ambassadorsTimeHours ? (
                                        <span>
                                            {ambassadorsTimeHours}
                                            <span className="tile-stat__sub-article">hr&nbsp;&nbsp;</span>
                                        </span>
                                    ) : null}
                                    {ambassadorsTimeMinutes ? (
                                        <span>
                                            {ambassadorsTimeMinutes}
                                            <span className="tile-stat__sub-article">min</span>
                                        </span>
                                    ) : (
                                        <span>0</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card className="reporting-block">
                        <div className="tile">
                            <div className="tile-wrap">
                                <h3>Number of prospect accounts created</h3>
                                <div className="tile-stat">{prospectsCreated || 0}</div>
                            </div>
                        </div>
                    </Card>

                    <Card className="reporting-block">
                        <div className="tile">
                            <div className="tile-wrap">
                                <h3>Number of active conversations</h3>
                                <div className="tile-stat">{activeConversation || 0}</div>
                            </div>
                        </div>
                    </Card>

                    <Card className="reporting-block">
                        <div className="tile">
                            <div className="tile-wrap">
                                <h3>Number of FAQs published</h3>
                                <div className="tile-stat">{faqPublished || 0}</div>
                            </div>
                        </div>
                    </Card>

                    <Card className="reporting-block">
                        <div className="tile">
                            <div className="tile-wrap">
                                <h3>Number of posts published and downloaded</h3>
                                <div className="tile-stat">{postsPublishedDownloaded || 0}</div>
                            </div>
                        </div>
                    </Card>
                </div>
            </Fragment>
        )
    }
}

export default OldSummary
