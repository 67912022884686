import React, { PureComponent } from 'react'
import { Table, Button } from 'antd'

const prepareTableData = (data, searchString) => {
    if (!data) {
        return []
    }

    let filteredData = data

    if (searchString.length > 0) {
        const lowercaseSearchString = searchString.toLowerCase()

        filteredData = data.filter((item) => {
            if (!item.name) {
                return false
            }

            return item.name.toLowerCase().includes(lowercaseSearchString)
        })
    }

    return filteredData.map((item) => {
        return {
            key: item.id,
            topic: item.name,
            hits: item.count,
            percentage: item.percentage,
            percentageFormatted: item.percentage ? item.percentage.toFixed(1) : '',
            benchmark: item.benchmark,
            benchmarkFormatted: item.benchmark ? item.benchmark.toFixed(1) : '',
            createFaq: 'Create FAQ',
            createContent: 'Create Content',
            tagId: item.tagId,
        }
    })
}

const prepareColumnsData = (onCreateFaq, onCreateContent) => {
    const columnsData = [
        {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic',
            sorter: (a, b) => (a.topic.toLowerCase() > b.topic.toLowerCase() ? 1 : -1),
        },
        {
            title: 'Total Mentions',
            dataIndex: 'hits',
            key: 'hits',
            sorter: (a, b) => a.hits - b.hits,
            defaultSortOrder: 'descend',
        },
        {
            title: 'Percentage',
            dataIndex: 'percentageFormatted',
            key: 'percentage',
            sorter: (a, b) => a.percentage - b.percentage,
        },
        {
            title: 'Benchmark Percentage',
            dataIndex: 'benchmarkFormatted',
            key: 'benchmark',
            sorter: (a, b) => a.benchmark - b.benchmark,
        },
        {
            title: 'Create FAQ',
            dataIndex: 'createFaq',
            key: 'createFaq',
            render: (text, item) => (
                <Button type="link" onClick={() => onCreateFaq(item.topic, item.tagId)} className="topic-action-button">
                    {text}
                </Button>
            ),
        },
        {
            title: 'Create Content',
            dataIndex: 'createContent',
            key: 'createContent',
            render: (text, item) => (
                <Button
                    type="link"
                    onClick={() => onCreateContent(item.topic, item.tagId)}
                    className="topic-action-button"
                >
                    {text}
                </Button>
            ),
        },
    ]

    return columnsData
}

class TopicsTable extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            selectedRowKeys: [],
            allTopicIds: [],
            lastData: props.data,
            lastSearch: props.searchString,
            tableData: [],
            unselectAll: false,
        }

        this.columns = prepareColumnsData(props.onCreateFaq, props.onCreateContent)

        this.handleRowSelection = this.handleRowSelection.bind(this)
        this.handleSelectionChange = this.handleSelectionChange.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.data !== state.lastData) {
            state.lastData = props.data
            const allTopicIds = props.data.map((item) => item.id)
            state.allTopicIds = allTopicIds
            if (!state.unselectAll) {
                state.selectedRowKeys = allTopicIds
            }
            state.tableData = prepareTableData(props.data, props.searchString)
            props.onSelectionChange(allTopicIds)

            return state
        }

        if (props.searchString !== state.lastSearch) {
            state.lastSearch = props.searchString
            state.tableData = prepareTableData(props.data, props.searchString)

            return state
        }

        return null
    }

    handleRowSelection() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            unselectAll: false,
            onChange: this.handleSelectionChange,
            hideDefaultSelections: true,
            selections: [
                {
                    key: 'all-data',
                    text: 'Select All',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: this.state.allTopicIds, unselectAll: false })
                        this.props.onSelectionChange(this.state.allTopicIds)
                    },
                },
                {
                    key: 'no-data',
                    text: 'Unselect All',
                    onSelect: () => {
                        this.setState({ selectedRowKeys: [], unselectAll: true })
                        this.props.onSelectionChange([])
                    },
                },
            ],
        }

        return rowSelection
    }

    handleSelectionChange(selectedRowKeys) {
        this.setState({ selectedRowKeys })
        this.props.onSelectionChange(selectedRowKeys)
    }

    render() {
        const { tableData } = this.state
        const { onTableChange, data } = this.props

        return (
            <div
                className="users-container flexible-page table-wrapper"
                style={{
                    overflowX: 'scroll',
                    whiteSpace: 'nowrap',
                }}
            >
                <Table
                    rowSelection={this.handleRowSelection()}
                    columns={this.columns}
                    dataSource={tableData}
                    pagination={{ total: data && data.length > 0 ? data.length : 0, size: 'small' }}
                    onChange={onTableChange}
                />
            </div>
        )
    }
}

export default TopicsTable
