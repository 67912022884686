import {
    QUESTIONS_ANSWERS_INFO,
    QUESTIONS_ANSWERS_INFO_SUCCESS,
    EDIT_FAQ_GLOBAL_TAG_FINISH,
} from '../../actions/faq/faqActions'

import { FAQ_ANSWERS } from '../../store/initialState'

const initialState = FAQ_ANSWERS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case QUESTIONS_ANSWERS_INFO: {
            return {
                ...state,
                searchItems: payload.filter && payload.filter.searchItems ? payload.filter.searchItems : [],
            }
        }

        case QUESTIONS_ANSWERS_INFO_SUCCESS: {
            const newState = Object.assign({}, state)

            if (payload.filter.published) {
                newState.published.isLoading = false
                newState.published.items = payload.items
            } else {
                newState.unpublished.isLoading = false
                newState.unpublished.items = payload.items
            }

            return newState
        }

        case EDIT_FAQ_GLOBAL_TAG_FINISH: {
            const { faq_id, globalTags } = payload
            return {
                ...state,
                items: state.items.map((item) => (item.id === faq_id ? { ...item, globalTags } : item)),
            }
        }

        default: {
            return state
        }
    }
}
