import React, { PureComponent } from 'react'
import chevronDown from '../../../../../assets/img/svg/chevron-down.svg'

class PreviewOptionalQuestions extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
    }

    renderQuestions() {
        const { questions } = this.props
        if (!questions || !questions.length) {
            return null
        }

        const dataQuestions = questions.map((item) => {
            let question = null
            switch (item.key) {
                case 'postcode':
                case 'school':
                    question = (
                        <div key={item.order} className="preview-question-block">
                            <div className="preview-question-title">{item.question}</div>
                            <div className="modal-fake-input"></div>
                        </div>
                    )
                    break

                case 'already_applied':
                    question = (
                        <div key={item.order} className="preview-question-block">
                            <div className="preview-question-title">{item.question}</div>
                            <div>
                                <button className="date-button">Yes</button>
                                <button className="date-button">No</button>
                            </div>
                        </div>
                    )
                    break

                case 'birthday':
                    question = (
                        <div key={item.order} className="preview-question-block">
                            <div className="preview-question-title">{item.question}</div>
                            <button className="date-button">DD.MM.YYYY</button>
                        </div>
                    )
                    break

                case 'residence_country':
                    question = (
                        <div key={item.order} className="preview-question-block">
                            <div className="preview-question-title">{item.question}</div>
                            <div className="dropdown-custom">
                                <div className="input-wrapper">
                                    <input className="dropdown-input" placeholder="Select or type to search" />
                                    <img className="chevron" src={chevronDown} alt="" />
                                </div>
                            </div>
                        </div>
                    )
                    break

                case 'specialization':
                    question = (
                        <div key={item.order} className="preview-question-block">
                            <div className="preview-question-title">{item.question}</div>
                            <div className="dropdown-custom">
                                <div className="input-wrapper">
                                    <input className="dropdown-input" placeholder="Select or type to search" />
                                    <img className="chevron" src={chevronDown} alt="" />
                                </div>
                            </div>
                        </div>
                    )
                    break

                case 'start_study':
                    if (item.predefined_answers) {
                        const studies = item.predefined_answers.map((date, index) => {
                            return (
                                <button key={index} className="date-button">
                                    {date.name}
                                </button>
                            )
                        })
                        question = (
                            <div key={item.order} className="preview-question-block">
                                <div className="preview-question-title">{item.question}</div>
                                <div className="start-studies-block">{studies}</div>
                            </div>
                        )
                    }
                    break

                case 'custom':
                    if (item.predefined_answers) {
                        const studies = item.predefined_answers.map((answerData, index) => {
                            return (
                                <button key={index} className="boolean-button">
                                    {answerData.name}
                                </button>
                            )
                        })
                        question = (
                            <div key={item.order} className="preview-question-block">
                                <div className="preview-question-title">{item.question}</div>
                                <div>{studies}</div>
                            </div>
                        )
                    } else {
                        question = (
                            <div key={item.order} className="preview-question-block">
                                <div className="preview-question-title">{item.question}</div>
                                <div className="modal-fake-input"></div>
                            </div>
                        )
                    }
                    break
            }

            return question
        })

        return dataQuestions
    }

    render() {
        return <div className="preview-optional-questions">{this.renderQuestions()}</div>
    }
}

export default PreviewOptionalQuestions
