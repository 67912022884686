import React from 'react'
import { Editor, convertToRaw } from 'draft-js'
import { debounce } from 'smooth-scrollbar/decorators'
import 'draft-js/dist/Draft.css'

import EditorToolbar from '../../../components/EditorToolbar'

const EditableAnswer = ({ answer, ...rest }) => {
    const { id, content } = answer
    const {
        handleChange,
        handleToggleEdit,
        saveAnswer,
        published,
        onBoldClick,
        onItalicClick,
        onUnderlineClick,
        onListClick,
        onLinkClick,
    } = rest

    const handleSave = () => {
        saveAnswer({
            id,
            content: JSON.stringify(convertToRaw(content.getCurrentContent())),
            published,
        })

        handleToggleEdit(id, 'save')
    }
    return (
        <>
            <EditorToolbar
                utils={[
                    { name: 'bold', handler: () => onBoldClick(content, answer.id) },
                    { name: 'italic', handler: () => onItalicClick(content, answer.id) },
                    { name: 'underline', handler: () => onUnderlineClick(content, answer.id) },
                    { name: 'list', handler: () => onListClick(content, answer.id) },
                    { name: 'link', handler: (link) => onLinkClick(content, answer.id, link) },
                ]}
            />
            <Editor
                readOnly={false}
                editorState={content}
                onChange={(e) => handleChange(e, answer.id)}
                classname="answer__editable-content answer__editable-content--editing"
            />
            <div className="answer__buttons">
                <button
                    type="submit"
                    className="answer__buttons__button answer__buttons__button--cancel"
                    onClick={() => handleToggleEdit(id, 'close')}
                >
                    Cancel
                </button>
                <button type="button" className="answer__buttons__button" onClick={handleSave}>
                    Save
                </button>
            </div>
        </>
    )
}

export default EditableAnswer
