import {
    TREND_ANALYZER_CHARTS_SUCCESS,
    TREND_ANALYZER_TABLE_REQUESTED,
    TREND_ANALYZER_TABLE_SUCCESS,
    TREND_ANALYZER_TABLE_FAILED,
    TREND_ANALYZER_CSV_REQUESTED,
    TREND_ANALYZER_CSV_SUCCESS,
} from '../../actions/analytics/trendAnalyzerActions'

const initialState = {
    charts: {},
    table: {},
    isTableLoading: true,
    csvUrl: '',
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case TREND_ANALYZER_CHARTS_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.charts = payload
            // console.log(newState.charts);
            return newState
        }

        case TREND_ANALYZER_TABLE_REQUESTED: {
            const newState = Object.assign({}, state)
            newState.isTableLoading = true
            return newState
        }

        case TREND_ANALYZER_TABLE_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.table = payload
            newState.isTableLoading = false
            // console.log(newState.table);
            return newState
        }

        case TREND_ANALYZER_TABLE_FAILED: {
            return {
                ...state,
                isTableLoading: false,
            }
        }

        case TREND_ANALYZER_CSV_REQUESTED: {
            const newState = Object.assign({}, state)
            newState.csvUrl = ''
            return newState
        }

        case TREND_ANALYZER_CSV_SUCCESS: {
            const newState = Object.assign({}, state)
            newState.csvUrl = payload.csvFileUrl
            // console.log(newState.csvUrl);
            return newState
        }
    }

    return state
}
