/**
 * @typedef {Object} Config
 * @property {string} liveSwitch
 * @property {string} liveSwitchAppId
 * @property {string} api_host_clear
 * @property {string} api_host
 * @property {string} tap_page_url
 * @property {string} rss_url
 * @property {string} pusher_key
 * @property {string} widget_script
 * @property {string} carousel_script
 * @property {string} popcard_script
 * @property {string} button_script
 */

/**
 * @type {Config}
 */
const config = {
    liveSwitch: 'https://cloud.liveSwitch.io/',
    liveSwitchAppId: '75dce1ef-cc54-4558-8c76-5a7c6f0738e8',
}
if (!process.env.APP_ENV || process.env.APP_ENV === 'development') {
    /* DEVELOPMENT */

    config.api_host_clear = 'https://api-dev.tap.st/'
    config.api_host = config.api_host_clear + 'v1'
    config.tap_page_url = 'https://tappage-dev.tap.st/'
    config.rss_url = 'https://fs-dev.tap.st/rss/'
    config.pusher_key = 'b3ed46dc4b4ff9192bef'
    config.widget_script = 'https://cdn.theaccessplatform.com/widget_staging.js'
    config.carousel_script = 'https://cdn.theaccessplatform.com/carousel_staging.js'
    config.popcard_script = 'https://cdn.theaccessplatform.com/popcard_staging.js'
    config.button_script = 'https://cdn.theaccessplatform.com/button_staging.js'

    /* DEVELOPMENT */
} else if (process.env.APP_ENV === 'local') {
    /* LOCAL */

    config.api_host_clear = 'http://localhost:3333/'
    config.api_host = config.api_host_clear + 'v1'
    config.tap_page_url = 'https://tappage-dev.tap.st/'
    config.rss_url = 'https://fs-dev.tap.st/rss/'
    config.pusher_key = 'b3ed46dc4b4ff9192bef'
    config.widget_script = 'https://cdn.theaccessplatform.com/widget_staging.js'
    config.carousel_script = 'https://cdn.theaccessplatform.com/carousel_staging.js'
    config.popcard_script = 'https://cdn.theaccessplatform.com/popcard_staging.js'
    config.button_script = 'https://cdn.theaccessplatform.com/button_staging.js'

    /* LOCAL */
} else if (process.env.APP_ENV === 'staging') {
    /* STAGING */

    config.api_host_clear = 'https://api-staging.tap.st/'
    config.api_host = config.api_host_clear + 'v1'
    config.tap_page_url = 'https://tappage-staging.tap.st/'
    config.rss_url = 'https://fs-staging.tap.st/rss/'
    config.pusher_key = '5db5ca19614e8fbc4515'
    config.widget_script = 'https://cdn.theaccessplatform.com/widget_staging.js'
    config.carousel_script = 'https://cdn.theaccessplatform.com/carousel_staging.js'
    config.popcard_script = 'https://cdn.theaccessplatform.com/popcard_staging.js'
    config.button_script = 'https://cdn.theaccessplatform.com/button_staging.js'
    /* STAGING */
} else {
    /* PRODUCTION */

    config.api_host_clear = 'https://api.theaccessplatform.com/'
    config.api_host = config.api_host_clear + 'v1'
    config.tap_page_url = 'https://tappage.theaccessplatform.com/'
    config.rss_url = 'https://fs.theambassadorplatform.com/rss/'
    config.pusher_key = 'b467b31f50874ddbe4a1'
    config.widget_script = 'https://cdn.theaccessplatform.com/widget.js'
    config.carousel_script = 'https://cdn.theaccessplatform.com/carousel.js'
    config.popcard_script = 'https://cdn.theaccessplatform.com/popcard.js'
    config.button_script = 'https://cdn.theaccessplatform.com/button.js'
    /* PRODUCTION */
}

export default config
