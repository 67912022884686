import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import pencil from '../../../../../../assets/img/pencil.png'

import { getUsersSlice } from '../../../../../redux/selectors'

import { changeAmbassadorStaffType, getStaffTypes } from '../../../../../redux/actions/content/usersActions'

const { Option } = Select

const AmbassadorStaffType = ({ staff, userId, changeStudentStaffType, saveStudentInfo }) => {
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()

    const { staffTypes: typeOfStaff } = useSelector(getUsersSlice)

    useEffect(() => {
        dispatch(getStaffTypes())
    }, [dispatch])

    const onFinish = useCallback(
        (values) => {
            dispatch(
                changeAmbassadorStaffType({
                    userId,
                    data: typeOfStaff?.filter((item) => item.id === values.staffType),
                })
            )

            setEditMode(false)
            saveStudentInfo()
        },
        [dispatch, typeOfStaff, userId, saveStudentInfo]
    )

    const editType = useCallback(() => {
        setEditMode(true)
    }, [])

    const onCancel = useCallback(() => {
        setEditMode(false)
    }, [])

    const options = useMemo(
        () =>
            typeOfStaff?.map((staffType) => {
                return (
                    <Option key={staffType.id} value={staffType.id} label={staffType.name}>
                        {staffType.name}
                    </Option>
                )
            }),
        [typeOfStaff]
    )

    return editMode ? (
        <>
            <Form
                initialValues={{
                    staffType: staff ? staff[0].id : '',
                }}
                onFinish={onFinish}
                autoComplete="off"
                hideRequiredMark
            >
                <Form.Item
                    name="staffType"
                    label={null}
                    style={{ marginBottom: 20 }}
                    rules={[{ required: true, message: 'Select staff member type' }]}
                >
                    <Select
                        optionFilterProp="children"
                        optionLabelProp="label"
                        style={{ fontSize: 16, maxWidth: 200, minWidth: 150 }}
                        onChange={changeStudentStaffType}
                    >
                        {options}
                    </Select>
                </Form.Item>
                <div className="input__buttons">
                    {staff && (
                        <button className="input-control-button" onClick={onCancel}>
                            Cancel
                        </button>
                    )}
                    <button className="input-control-button" type="submit">
                        Save
                    </button>
                </div>
            </Form>
        </>
    ) : (
        <>
            <div className="profile-info-tags-items">
                {staff ? (
                    staff[0].name.charAt(0).toUpperCase() + staff[0].name.slice(1)
                ) : (
                    <p style={{ color: 'red' }}>Please, specify</p>
                )}
                <button className="input-control-button" onClick={editType}>
                    <img className="pencil-icon" src={pencil} alt="" />
                </button>
            </div>
        </>
    )
}

export default AmbassadorStaffType
