import React, { useMemo } from 'react'
import { Typography, Row, Space } from 'antd'

const { Text } = Typography

const CellTitle = ({ text, record, titleKey }) => {
    const { before_filter: beforeFilter, after_filter: afterFilter } = record

    const prepareTitle = useMemo(() => {
        const beforFilterTotal = beforeFilter ? beforeFilter[titleKey] : 0
        const afterFilterTotal = afterFilter ? afterFilter[titleKey] : 0

        return `(${afterFilter ? `${afterFilterTotal}/` : ''}${beforFilterTotal})`
    }, [beforeFilter, afterFilter, titleKey])

    return (
        <Row>
            <Space>
                <Text style={{ fontSize: '1.8rem' }}>{text}</Text>
                <Text style={{ color: titleKey !== 'total_ambassadors' && '#29d266', fontSize: '1.8rem' }}>
                    {prepareTitle}
                </Text>
            </Space>
        </Row>
    )
}

const cellTitleRenderer = ({ text, record, titleKey }) => <CellTitle text={text} record={record} titleKey={titleKey} />

export default cellTitleRenderer
