const NAMESPACE_PREFIX = 'SSO'

export const SSO = {
    GET_PROVIDERS: `${NAMESPACE_PREFIX}.GET_PROVIDERS`,
    GET_PROVIDERS_SUCCESS: `${NAMESPACE_PREFIX}.GET_PROVIDERS_SUCCESS`,
    GET_PROVIDERS_FAILED: `${NAMESPACE_PREFIX}.GET_PROVIDERS_FAILED`,
    SET_UNIVERSITY: `${NAMESPACE_PREFIX}.SET_UNIVERSITY`,
    RESET_UNIVERSITY: `${NAMESPACE_PREFIX}.RESET_UNIVERSITY`,
    RESET_UNIVERSITY_SUCCESS: `${NAMESPACE_PREFIX}.RESET_UNIVERSITY_SUCCESS`,
    USER_TOGGLE: `${NAMESPACE_PREFIX}.USER_TOGGLE`,
    UPLOAD_METADATA: `${NAMESPACE_PREFIX}.UPLOAD_METADATA`,
}
