import React from 'react';
import { Icon } from 'src/app/components/base/Icon';
import { CardTypesEnum } from './helpers';
export var CardInfo = function (_a) {
    var type = _a.type;
    if (!type || type === CardTypesEnum.Institution)
        return null;
    return (React.createElement("div", { className: "mt-6 mb-1 grid grid-cols-[auto_1fr] md:my-10.5 md:mt-6.5 md:px-6.5" },
        React.createElement(Icon, { className: "w-5.5 h-5.5 fill-[var(--pls-card-gray)]", icon: "pls_fluent_info" }),
        React.createElement("p", { className: "text-[var(--pls-card-gray)]" }, type === CardTypesEnum.Student ? (React.createElement(React.Fragment, null,
            "We are sorry, but this registration is ONLY for organisations. If you want to create an account to chat with the current students, please find the right university through our\u00A0",
            React.createElement("a", { href: "https://www.theambassadorplatform.com/students", rel: "noopener noreferrer", target: "_blank", className: "text-brand-orange underline underline-offset-4" }, "interactive map"),
            "\u00A0 and have your conversation.")) : (React.createElement(React.Fragment, null, "Please contact your TAP university admin or your CSM to get invited to the platform to the current account")))));
};
