/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'

import { Button } from 'antd'

import { DownloadOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import { format } from 'date-fns'

import { fetchProspectsCsvData } from '../../../../../../../../../../redux/actions/reporting/prospectsActions'
import { getUniversityInfo } from '../../../../../../../../../../redux/selectors'

const ProspectsCsvButton = ({ event }) => {
    const dispatch = useDispatch()
    const { id: liveStreamId, userIds } = event
    const prospectCsv = useSelector((state) => state.prospects.csvUrl)
    const { created_at: universityCreatedAt } = useSelector(getUniversityInfo)

    const [shouldFileBeDownloaded, setShouldFileBeDownloaded] = useState(false)
    const [lastDownloadedFile, setLastDownloadedFile] = useState('')

    const downloadCsv = useCallback(() => {
        dispatch(
            fetchProspectsCsvData({
                filter: {
                    fromDate: universityCreatedAt,
                    toDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'.000Z'"),
                },
                liveStreamId,
            })
        )
        setShouldFileBeDownloaded(true)
    }, [event])

    useEffect(() => {
        if (shouldFileBeDownloaded && lastDownloadedFile !== prospectCsv && prospectCsv.length > 0) {
            const link = document.createElement('a')
            link.download = prospectCsv
            link.href = prospectCsv
            document.body.append(link)
            link.click()
            link.remove()

            setShouldFileBeDownloaded(false)
            setLastDownloadedFile(prospectCsv)
        }
    }, [prospectCsv])

    return (
        <>
            <Button
                type="link"
                icon={<DownloadOutlined />}
                onClick={downloadCsv}
                style={{ color: '#4c4c4d' }}
                disabled={userIds.length === 0}
            >
                Download the list of participants
            </Button>
        </>
    )
}

export default ProspectsCsvButton
