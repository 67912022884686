import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import universityDefaultAvatar from '../../../../../assets/img/university_default_avatar.jpeg'

class SlideFaqCards extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const { avatar, name } = this.props

        let nameShortOne = name,
            nameShortTwo = name
        if (name && name.length > 22) {
            nameShortTwo = name.slice(0, 22) + '...'
            if (name.length > 42) {
                nameShortOne = name.slice(0, 42) + '...'
            }
        }

        return (
            <div className="preview-slide preview-large-slide slide-faq-cards">
                <svg
                    id="prefix__Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 649 429"
                    {...this.props}
                >
                    <defs>
                        <style>
                            {
                                '.prefix__cls-1{fill:#fff}.prefix__cls-3{fill:#e8e8e8}.prefix__cls-14,.prefix__cls-17,.prefix__cls-20,.prefix__cls-4{isolation:isolate}.prefix__cls-17{fill:#262e45}.prefix__cls-17,.prefix__cls-20{font-family:SourceSansPro-SemiBold,Source Sans Pro;font-weight:700}.prefix__cls-6{letter-spacing:-.01em}.prefix__cls-17,.prefix__cls-20{font-size:14px}.prefix__cls-14,.prefix__cls-20{fill:#ff5100}.prefix__cls-14{font-family:SourceSansPro-Regular,Source Sans Pro}.prefix__cls-11,.prefix__cls-12,.prefix__cls-13{fill:none}.prefix__cls-11{stroke:#e0e0e0}.prefix__cls-12,.prefix__cls-13{stroke:#bababa;stroke-linecap:round}.prefix__cls-12{stroke-width:2px}.prefix__cls-14{font-size:12px}.prefix__cls-15,.prefix__cls-16,.prefix__cls-18{letter-spacing:-.01em}.prefix__cls-19{letter-spacing:0}.prefix__cls-20{letter-spacing:-.04em}.prefix__cls-21{letter-spacing:-.01em}'
                            }
                        </style>
                    </defs>
                    <title>{'FAQ preview'}</title>
                    <path
                        id="prefix__Rectangle_1882-2"
                        data-name="Rectangle 1882-2"
                        className="prefix__cls-1"
                        d="M7.4 0h634.2c4.09 0 7.4 2.64 7.4 5.9v417.2c0 3.26-3.31 5.9-7.4 5.9H7.4c-4.09 0-7.4-2.64-7.4-5.9V5.9C0 2.64 3.31 0 7.4 0z"
                    />
                    <path
                        id="prefix__Path_64"
                        data-name="Path 64"
                        d="M291.26 58.16a45.28 45.28 0 0015-20.33c.66-1.8 3.45-1.08 2.91.81a47.46 47.46 0 01-5.61 12.47c10.72-7.68 23.83-12 36.9-13.24 17-1.64 37.78 1.91 51.19 13.19 2 1.65-.26 5.43-2.66 4.42-7.69-3.23-14.82-7-23-9.08a79.78 79.78 0 00-24.72-2.31c-13.82.92-26.46 6-38.26 12.81a23.69 23.69 0 0110.85 4.44c2.92 2.19.17 7.07-3 5-5.39-3.59-10.93-5.23-17.43-4.2-2.04.28-4.19-2.41-2.17-3.98z"
                        fill="#a6a6a6"
                    />
                    <g id="prefix__Group_3180" data-name="Group 3180">
                        <g id="prefix__Profile_image" data-name="Profile image">
                            <defs>
                                <pattern id="image" x="0%" y="0%" height="100%" width="100%" viewBox="0 0 512 512">
                                    <image
                                        x="0%"
                                        y="0%"
                                        width="512"
                                        height="512"
                                        preserveAspectRatio="xMaxYMax slice"
                                        xlinkHref={avatar || universityDefaultAvatar}
                                    />
                                </pattern>
                            </defs>
                            <circle id="sd" className="medium" cx="9.5%" cy="21%" r="7%" fill="url(#image)" />
                        </g>
                        <text
                            transform="translate(117.5 99.5)"
                            style={{
                                isolation: 'isolate',
                            }}
                            fontFamily="SourceSansPro-SemiBold,Source Sans Pro"
                            fontWeight={700}
                            fill="#262e45"
                            fontSize={21}
                            id="prefix__Name"
                        >
                            {nameShortOne}
                        </text>
                    </g>
                    <g id="prefix__Group_3181" data-name="Group 3181">
                        <g id="prefix__Group_3178" data-name="Group 3178">
                            <g id="prefix__Group_3175" data-name="Group 3175">
                                <g id="prefix__Rectangle_1909" data-name="Rectangle 1909">
                                    <rect
                                        className="prefix__cls-1"
                                        x={28.5}
                                        y={194.5}
                                        width={280}
                                        height={176.33}
                                        rx={5}
                                        ry={5}
                                    />
                                    <rect
                                        className="prefix__cls-11"
                                        x={29}
                                        y={195}
                                        width={279}
                                        height={175.33}
                                        rx={4.5}
                                        ry={4.5}
                                    />
                                </g>
                                <path
                                    id="prefix__Line_190"
                                    data-name="Line 190"
                                    className="prefix__cls-12"
                                    d="M52 218h232.5"
                                />
                                <path
                                    id="prefix__Line_191"
                                    data-name="Line 191"
                                    className="prefix__cls-12"
                                    d="M52 228h222"
                                />
                                <defs>
                                    <pattern id="image" x="0%" y="0%" height="100%" width="100%" viewBox="0 0 512 512">
                                        <image
                                            x="0%"
                                            y="0%"
                                            width="512"
                                            height="512"
                                            preserveAspectRatio="xMaxYMax slice"
                                            xlinkHref={avatar || universityDefaultAvatar}
                                        />
                                    </pattern>
                                </defs>
                                <circle id="sd" className="medium" cx={66.5} cy={268.5} r={14} fill="url(#image)" />
                                <path
                                    id="prefix__Line_193"
                                    data-name="Line 193"
                                    className="prefix__cls-13"
                                    d="M55 296h229.5"
                                />
                                <path
                                    id="prefix__Line_194"
                                    data-name="Line 194"
                                    className="prefix__cls-13"
                                    d="M55 306h229.5"
                                />
                                <path
                                    id="prefix__Line_195"
                                    data-name="Line 195"
                                    className="prefix__cls-13"
                                    d="M55 316h229.5"
                                />
                                <path
                                    id="prefix__Line_196"
                                    data-name="Line 196"
                                    className="prefix__cls-13"
                                    d="M55 326h229.5"
                                />
                                <path
                                    id="prefix__Line_197"
                                    data-name="Line 197"
                                    className="prefix__cls-13"
                                    d="M55 336h229.5"
                                />
                            </g>
                            <g id="prefix__Name-4" className="prefix__cls-4">
                                <text className="prefix__cls-17" transform="translate(88.5 273.5)">
                                    {nameShortTwo}
                                </text>
                            </g>
                        </g>
                        <g id="prefix__Name-5" className="prefix__cls-4">
                            <text className="prefix__cls-20" transform="translate(160.78 182.5)">
                                {'F'}
                                <tspan className="prefix__cls-21" x={6.61} y={0}>
                                    {'A'}
                                </tspan>
                                <tspan className="prefix__cls-19" x={14.28} y={0}>
                                    {'Q'}
                                </tspan>
                            </text>
                        </g>
                    </g>
                    <g id="prefix__Group_3182" data-name="Group 3182">
                        <g id="prefix__Name-6" className="prefix__cls-4">
                            <text className="prefix__cls-20" transform="translate(473.78 182.5)">
                                {'F'}
                                <tspan className="prefix__cls-21" x={6.61} y={0}>
                                    {'A'}
                                </tspan>
                                <tspan className="prefix__cls-19" x={14.28} y={0}>
                                    {'Q'}
                                </tspan>
                            </text>
                        </g>
                        <g id="prefix__Group_3179" data-name="Group 3179">
                            <g id="prefix__Group_3177" data-name="Group 3177">
                                <g id="prefix__Rectangle_1909-2" data-name="Rectangle 1909-2">
                                    <rect
                                        className="prefix__cls-1"
                                        x={341.5}
                                        y={194.5}
                                        width={280}
                                        height={176.33}
                                        rx={5}
                                        ry={5}
                                    />
                                    <rect
                                        className="prefix__cls-11"
                                        x={342}
                                        y={195}
                                        width={279}
                                        height={175.33}
                                        rx={4.5}
                                        ry={4.5}
                                    />
                                </g>
                                <path
                                    id="prefix__Line_190-2"
                                    data-name="Line 190-2"
                                    className="prefix__cls-12"
                                    d="M365 218h232.5"
                                />
                                <path
                                    id="prefix__Line_191-2"
                                    data-name="Line 191-2"
                                    className="prefix__cls-12"
                                    d="M365 228h222"
                                />
                                <defs>
                                    <pattern id="image" x="0%" y="0%" height="100%" width="100%" viewBox="0 0 512 512">
                                        <image
                                            x="0%"
                                            y="0%"
                                            width="512"
                                            height="512"
                                            preserveAspectRatio="xMaxYMax slice"
                                            xlinkHref={avatar || universityDefaultAvatar}
                                        />
                                    </pattern>
                                </defs>
                                <circle id="sd" className="medium" cx={379.5} cy={268.5} r={14} fill="url(#image)" />
                                <path
                                    id="prefix__Line_193-2"
                                    data-name="Line 193-2"
                                    className="prefix__cls-13"
                                    d="M368 296h229.5"
                                />
                                <path
                                    id="prefix__Line_194-2"
                                    data-name="Line 194-2"
                                    className="prefix__cls-13"
                                    d="M368 306h229.5"
                                />
                                <path
                                    id="prefix__Line_195-2"
                                    data-name="Line 195-2"
                                    className="prefix__cls-13"
                                    d="M368 316h229.5"
                                />
                                <path
                                    id="prefix__Line_196-2"
                                    data-name="Line 196-2"
                                    className="prefix__cls-13"
                                    d="M368 326h229.5"
                                />
                                <path
                                    id="prefix__Line_197-2"
                                    data-name="Line 197-2"
                                    className="prefix__cls-13"
                                    d="M368 336h229.5"
                                />
                            </g>
                            <g id="prefix__Name-7" className="prefix__cls-4">
                                <text className="prefix__cls-17" transform="translate(401.5 273.5)">
                                    {nameShortTwo}
                                </text>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

export default connect((state) => {
    return {}
})(SlideFaqCards)
