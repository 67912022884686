import { Form, Select, Row, Col } from 'antd'
import { add, differenceInMinutes, format, startOfDay, isSameDay, roundToNearestMinutes, addMinutes } from 'date-fns'
import { isNil } from 'ramda'
import React, { useCallback, useMemo } from 'react'

const { Option } = Select

const DURATION_STEP = 15

const DurationInput = ({ form, currentDateFromPicker }) => {
    const options = useMemo(() => {
        const isSameDayChosen = isSameDay(currentDateFromPicker, new Date())

        const start = isSameDayChosen
            ? roundToNearestMinutes(addMinutes(new Date(), 30), { nearestTo: 15 })
            : startOfDay(new Date())

        const options = []

        for (let i = 0; i < 96; i++) {
            const timeValue = add(start, { minutes: DURATION_STEP * i })
            const minutes = differenceInMinutes(timeValue, start)

            const item = {
                text: format(timeValue, 'hh:mm aa'),
                value: minutes,
            }

            options.push(item)
        }

        return options
    }, [currentDateFromPicker])

    const renderSelectOptions = useCallback((options, fromTime) => {
        const data = !isNil(fromTime) ? options.filter(({ value }) => value > fromTime) : options

        return data.map(({ text, value }) => (
            <Option key={value} value={value}>
                {text}
            </Option>
        ))
    }, [])

    const selectOptions = renderSelectOptions(options)

    const handleFromTimeChange = useCallback(
        (value) => {
            const toTime = form.getFieldValue('toTime')

            if (toTime < value) {
                form.setFieldsValue({
                    toTime: value + DURATION_STEP,
                })
            }
        },
        [form]
    )

    return (
        <>
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item label="From" name="fromTime" rules={[{ required: true, message: 'Choose time' }]}>
                        <Select placeholder="Choose time" onChange={handleFromTimeChange}>
                            {selectOptions}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            const fromTime = getFieldValue('fromTime')
                            const filteredOptions = renderSelectOptions(options, fromTime)

                            return (
                                <Form.Item
                                    label="To"
                                    name="toTime"
                                    rules={[{ required: true, message: 'Choose time' }]}
                                >
                                    <Select placeholder="Choose time">{filteredOptions}</Select>
                                </Form.Item>
                            )
                        }}
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default DurationInput
