var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Button } from 'src/app/components/base/Button';
import { useStepsData } from '../StepsProvider';
import AmbassadorsSelect from './AmbassadorsSelect';
import { ServiceCards } from './ServiceCards';
import { useDebouncedCallback } from 'use-debounce';
import useUsersList from '../hooks/useUsersList';
import { App } from 'antd';
import API from 'src/app/api';
import { IdpServiceTypeEnum } from 'src/types/general';
export var StepThree = function () {
    var _a = useStepsData(), setCurrentStep = _a.setCurrentStep, isConversionSelected = _a.isConversionSelected, isCommunitySelected = _a.isCommunitySelected, isCommunitySpecialOffer = _a.isCommunitySpecialOffer, isConversionSpecialOffer = _a.isConversionSpecialOffer;
    var _b = useUsersList(), state = _b.state, fetchConversionUsers = _b.fetchConversionUsers, fetchCommunityUsers = _b.fetchCommunityUsers, setCommunityUsersIds = _b.setCommunityUsersIds, setConversionUsersIds = _b.setConversionUsersIds;
    var message = App.useApp().message;
    var onNext = useCallback(function () {
        var requestData = {
            services: [],
        };
        if (isCommunitySelected) {
            var communityService = {
                key: IdpServiceTypeEnum.IdpCommunity,
                isSpecialOffer: isCommunitySpecialOffer,
                selectedAmbassadors: state.communitySelectedUsers,
            };
            requestData.services.push(communityService);
        }
        if (isConversionSelected) {
            var conversionService = {
                key: IdpServiceTypeEnum.IdpConversionChat,
                isSpecialOffer: isConversionSpecialOffer,
                selectedAmbassadors: state.conversionSelectedUsers,
            };
            requestData.services.push(conversionService);
        }
        API.idpService
            .activateSevices(requestData)
            .then(function (response) {
            if (response.success) {
                setCurrentStep(3);
            }
        })
            .catch(function (error) {
            console.log('Error activating services:', error);
        });
    }, [
        isCommunitySelected,
        isConversionSelected,
        state.communitySelectedUsers,
        state.conversionSelectedUsers,
        isCommunitySpecialOffer,
        isConversionSpecialOffer,
        setCurrentStep,
    ]);
    var onIdpConversionUserSelect = useCallback(function (_a) {
        var _b = _a.target, checked = _b.checked, value = _b.value;
        var currentValue = state.conversionSelectedUsers;
        var updatedValue = checked ? __spreadArray(__spreadArray([], currentValue, true), [value], false) : currentValue.filter(function (item) { return item !== value; });
        setConversionUsersIds(updatedValue);
    }, [setConversionUsersIds, state.conversionSelectedUsers]);
    var onIdpCommunityUserSelect = useCallback(function (_a) {
        var _b = _a.target, checked = _b.checked, value = _b.value;
        var currentValue = state.communitySelectedUsers;
        var updatedValue = checked ? __spreadArray(__spreadArray([], currentValue, true), [value], false) : currentValue.filter(function (item) { return item !== value; });
        if (updatedValue.length > 5) {
            message.open({
                type: 'info',
                content: 'You can select up to 5 ambassadors for P2P Community',
            });
        }
        else {
            setCommunityUsersIds(updatedValue);
        }
    }, [message, setCommunityUsersIds, state.communitySelectedUsers]);
    var handleConversionUserSearch = useDebouncedCallback(function (e) {
        fetchConversionUsers({ conversionSearch: e.target.value });
    }, 300);
    var handleCommunityUserSearch = useDebouncedCallback(function (e) {
        fetchCommunityUsers({ communitySearch: e.target.value });
    }, 300);
    useEffect(function () {
        fetchConversionUsers({});
        fetchCommunityUsers({});
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: "text-header-xl font-interface-bold pb-6" }, "Select ambassadors"),
        React.createElement("div", { className: "mx-23 mb-7" },
            React.createElement("p", { className: "mb-6" }, "We have pre-selected ambassadors for TAP x IDP Joint Services based on their activity on the platform. Change the list of ambassadors now or later in the dashboard."),
            React.createElement("p", { className: "mb-6" },
                React.createElement("span", { className: "font-interface-bold" }, "We will send the ambassadors the additional training automatically"),
                "\u00A0 so you do not have to worry about that. The instructions can be found on the invite ambassadors page of the dashboard.")),
        React.createElement("div", { className: "grid grid-cols-2 gap-6" },
            React.createElement(ServiceCards, null),
            React.createElement(AmbassadorsSelect, { items: state.conversionUsersList, isLoading: state.conversionLoading, isSelected: isConversionSelected, handleSearch: handleConversionUserSearch, serviceName: "P2P Conversion app", selectedIds: state.conversionSelectedUsers, handleCheckboxChange: onIdpConversionUserSelect }),
            React.createElement(AmbassadorsSelect, { items: state.communityUsersList, isLoading: state.communityLoading, isSelected: isCommunitySelected, handleSearch: handleCommunityUserSearch, serviceName: "P2P Community", selectedIds: state.communitySelectedUsers, handleCheckboxChange: onIdpCommunityUserSelect })),
        React.createElement(Button, { className: "mt-11 process-btn", onClick: onNext }, "Activate & Continue")));
};
