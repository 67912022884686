import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as EyeIcon } from '../../../../assets/svg/icons/eye-close.svg'
import { ReactComponent as AlertIcon } from '../../../../assets/svg/icons/alert-f.svg'
import { ReactComponent as SmileyIcon } from '../../../../assets/svg/icons/smiley-f.svg'
import SmileyPositive from '../../../../assets/img/smile-positive.png'
import SmileyNeutral from '../../../../assets/img/smile-neutral.png'
import SmileyNegative from '../../../../assets/img/smile-negative.png'
import SmileyPositiveGrey from '../../../../assets/img/smile-positive-grey.png'
import SmileyNeutralGrey from '../../../../assets/img/smile-neutral-grey.png'
import SmileyNegativeGrey from '../../../../assets/img/smile-negative-grey.png'
import { getCommonRoutePath } from '../../../helpers/helpers'
import { checkCanSeeGroupChats } from '../../../common/utils/user-account-helpers'
import { Image } from 'antd'
import { ProtectedNavLink } from 'src/app/components/protected-nav-link'
import { LicensesEnum } from 'src/types/general'

class RecentActionsBlock extends PureComponent {
    constructor(props) {
        super()
        this.state = {}
    }

    render() {
        const { account, activityData } = this.props

        const {
            currentGroupUniversityId,
            account_info: { university = {} },
        } = account

        const canSeeGroupChats = checkCanSeeGroupChats(university)

        const commonPath = getCommonRoutePath()
        const commonChatPath =
            commonPath +
            '/chat/messaging?' +
            (currentGroupUniversityId ? 'universityId=' + currentGroupUniversityId + '&' : '')
        const commonGroupChatPath =
            commonPath +
            '/chat/group-chats?' +
            (currentGroupUniversityId ? 'universityId=' + currentGroupUniversityId + '&' : '')

        let flaggedNumber = 0
        let flaggedIndividual = 0
        let flaggedGroup = 0
        if (activityData.flaggedDialogs) {
            flaggedIndividual = activityData.flaggedDialogs.chat || 0
            flaggedGroup = activityData.flaggedDialogs.groupChat || 0
            flaggedNumber += flaggedIndividual
            flaggedNumber += flaggedGroup
        }
        let badWordsNumber = 0
        let badWordsIndividual = 0
        let badWordsGroup = 0
        if (activityData.badwordDialogs) {
            badWordsIndividual = activityData.badwordDialogs.chat || 0
            badWordsGroup = activityData.badwordDialogs.groupChat || 0
            badWordsNumber += badWordsIndividual
            badWordsNumber += badWordsGroup
        }

        return (
            <div className="recent-actions-block">
                <div className="recent-actions-title">Conversations status</div>
                <div
                    className={
                        'flagged-chats-line action-line' + (!flaggedNumber ? ' flagged-chats-line-inactive' : '')
                    }
                >
                    <AlertIcon />
                    <span className="actions-chats-text">Report in:</span>
                    <div className="recent-actions-types">
                        <div
                            className={
                                'recent-actions-type' + (!flaggedIndividual ? ' recent-actions-type-inactive' : '')
                            }
                        >
                            <span className="recent-actions-type-text">Individual chat</span>
                            <span className="actions-chats-number">
                                {flaggedIndividual ? '+ ' + flaggedIndividual : 0}
                            </span>
                            {!!flaggedIndividual && (
                                <NavLink
                                    to={commonChatPath + 'reported=true&unreviewed=true'}
                                    target="_blank"
                                    className="review-link"
                                >
                                    Review
                                </NavLink>
                            )}
                        </div>
                        {canSeeGroupChats && (
                            <div
                                className={
                                    'recent-actions-type' + (!flaggedGroup ? ' recent-actions-type-inactive' : '')
                                }
                            >
                                <span className="recent-actions-type-text">Group chat</span>
                                <span className="actions-chats-number">{flaggedGroup ? '+ ' + flaggedGroup : 0}</span>
                                {!!flaggedGroup && (
                                    <NavLink
                                        to={commonGroupChatPath + 'reported=true&unreviewed=true'}
                                        target="_blank"
                                        className="review-link"
                                    >
                                        Review
                                    </NavLink>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div
                    className={
                        'keywords-chats-line action-line' + (!badWordsNumber ? ' keywords-chats-line-inactive' : '')
                    }
                >
                    <EyeIcon />
                    <span className="actions-chats-text">Keywords in:</span>
                    <div className="recent-actions-types">
                        <div
                            className={
                                'recent-actions-type' + (!badWordsIndividual ? ' recent-actions-type-inactive' : '')
                            }
                        >
                            <span className="recent-actions-type-text">Individual chat</span>
                            <span className="actions-chats-number">
                                {badWordsIndividual ? '+ ' + badWordsIndividual : 0}
                            </span>
                            {!!badWordsIndividual && (
                                <NavLink
                                    to={commonChatPath + 'keywords=true&unreviewed=true'}
                                    target="_blank"
                                    className="review-link"
                                >
                                    Review
                                </NavLink>
                            )}
                        </div>
                        {canSeeGroupChats && (
                            <div
                                className={
                                    'recent-actions-type' + (!badWordsGroup ? ' recent-actions-type-inactive' : '')
                                }
                            >
                                <span className="recent-actions-type-text">Group chat</span>
                                <span className="actions-chats-number">{badWordsGroup ? '+ ' + badWordsGroup : 0}</span>
                                {!!badWordsGroup && (
                                    <ProtectedNavLink
                                        className="review-link"
                                        to={commonGroupChatPath + 'keywords=true&unreviewed=true'}
                                        licenseName={LicensesEnum.GroupChats}
                                        target="_blank"
                                    >
                                        Review
                                    </ProtectedNavLink>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={
                        'feedback-chats-line' +
                        (!activityData.totalFeebacksNumber ? ' feedback-chats-line-inactive' : '')
                    }
                >
                    <div className="feedback-action-line">
                        <SmileyIcon />
                        <span className="actions-chats-text">Prospects Feedback:</span>
                    </div>
                    <div className="feedback-chats">
                        <div className="feedback-chats-types">
                            {activityData.prospectFeedbacks.bad ? (
                                <div className="feedback-type-item">
                                    <div className="smile-feedback">
                                        <Image src={SmileyNegative} alt="" className="feedback-item-smile" />
                                        <span className="actions-chats-number">
                                            {'+ ' + activityData.prospectFeedbacks.bad}
                                        </span>
                                    </div>
                                    <NavLink
                                        to={commonChatPath + 'feedback=negative&unreviewed=true'}
                                        target="_blank"
                                        className="review-link"
                                    >
                                        Review
                                    </NavLink>
                                </div>
                            ) : (
                                <div className="feedback-type-item feedback-type-item-grey">
                                    <div className="smile-feedback">
                                        <Image src={SmileyNegativeGrey} alt="" className="feedback-item-smile" />
                                        <span className="actions-chats-number">0</span>
                                    </div>
                                </div>
                            )}
                            {activityData.prospectFeedbacks.normal ? (
                                <div className="feedback-type-item">
                                    <div className="smile-feedback">
                                        <Image src={SmileyNeutral} alt="" className="feedback-item-smile" />
                                        <span className="actions-chats-number">
                                            {'+ ' + activityData.prospectFeedbacks.normal}
                                        </span>
                                    </div>
                                    <NavLink
                                        to={commonChatPath + 'feedback=neutral&unreviewed=true'}
                                        target="_blank"
                                        className="review-link"
                                    >
                                        Review
                                    </NavLink>
                                </div>
                            ) : (
                                <div className="feedback-type-item feedback-type-item-grey">
                                    <div className="smile-feedback">
                                        <Image src={SmileyNeutralGrey} alt="" className="feedback-item-smile" />
                                        <span className="actions-chats-number">0</span>
                                    </div>
                                </div>
                            )}
                            {activityData.prospectFeedbacks.good ? (
                                <div className="feedback-type-item">
                                    <div className="smile-feedback">
                                        <Image src={SmileyPositive} alt="" className="feedback-item-smile" />
                                        <span className="actions-chats-number">
                                            {'+ ' + activityData.prospectFeedbacks.good}
                                        </span>
                                    </div>
                                    <NavLink
                                        to={commonChatPath + 'feedback=positive&unreviewed=true'}
                                        target="_blank"
                                        className="review-link"
                                    >
                                        Review
                                    </NavLink>
                                </div>
                            ) : (
                                <div className="feedback-type-item feedback-type-item-grey">
                                    <div className="smile-feedback">
                                        <Image src={SmileyPositiveGrey} alt="" className="feedback-item-smile" />
                                        <span className="actions-chats-number">0</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RecentActionsBlock
