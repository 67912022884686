import React, { PureComponent } from 'react'
import store from '../../../redux/store'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

class CustomCss extends PureComponent {
    componentDidMount() {
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
    }

    render() {
        return (
            <div id="page-body" className="page-body js-index">
                <section className="content settings">
                    <h3 className="settings-title">Custom CSS</h3>
                    <div className="settings-intro">
                        <p>
                            If you want to really Customize your TAP Feed and widgets to make them even more like your
                            brand that’s fine too, <em>if you’ve got the skills...</em> 😏
                        </p>

                        <p>
                            Custom CSS is a feature for advanced users only. If you'd like to enable it, please get in
                            touch with your account manager.
                        </p>
                    </div>

                    <div className="settings-grid">
                        <div className="column"></div>
                        <div className="column resources">
                            <div className="resources-box">
                                <h3>Helpful resources</h3>
                                <ul>
                                    <li>
                                        <a
                                            href="https://knowledge.theambassadorplatform.com/customizing-tap-to-fit-your-brand"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Customizing The Ambassador Platform to fit your brand
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default CustomCss
