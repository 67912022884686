import React, { useCallback } from 'react';
import { Input, Button, Form } from 'antd';
var EditLastNameForm = function (_a) {
    var onSubmit = _a.onSubmit, onCancel = _a.onCancel;
    var form = Form.useForm()[0];
    var onFinish = useCallback(function () {
        form.validateFields().then(function (values) { return onSubmit(values, form); });
    }, [form, onSubmit]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { form: form, name: "change_last_name", layout: "vertical", onFinish: onFinish, hideRequiredMark: true },
            React.createElement(Form.Item, { name: "lastName", label: "", className: "form-input", rules: [
                    { required: true, message: 'Add last name' },
                    { min: 2, message: 'Last name should contain at least 2 symbols' },
                ] },
                React.createElement(Input, { className: "signin-input mod-hidden settings-input", placeholder: "Last name" })),
            React.createElement(Button, { htmlType: "submit", className: "settings-btn", type: "link", style: { paddingLeft: 0 }, "data-automation-id": "submit-last-name" }, "Submit"),
            React.createElement(Button, { className: "settings-btn mod-cancel js-hide-form", type: "link", onClick: onCancel, "data-automation-id": "cancel-last-name-change" }, "Cancel"))));
};
export default EditLastNameForm;
