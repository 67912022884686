import React from 'react'
import { Popover } from 'antd'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import store from '../redux/store'
import { GET_BLOCK_REASON } from '../redux/actions/chat/messagingActions'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)
dayjs.extend(calendar)

const BlockProspectPopover = (props) => {
    const { data, userId } = props.chats.blockedData

    const getBlockReason = () => {
        const currentGroupUniversityId = props.account.currentGroupUniversityId // for group admins
        const universityId = currentGroupUniversityId || props.account.account_info.university_id

        if (userId !== props.userId) {
            store.dispatch({
                type: GET_BLOCK_REASON,
                payload: {
                    universityId: universityId,
                    userId: props.userId,
                },
            })
        }
    }
    return (
        <>
            <Popover
                placement="bottomRight"
                overlayStyle={{ width: 320 }}
                trigger="hover"
                overlayClassName="options-icon-drop"
                onMouseEnter={getBlockReason}
                content={
                    <div className={`chat-blocked-content ${data && data.length > 3 ? 'overflow' : ''}`}>
                        <PopupContent {...props} />
                    </div>
                }
            >
                <div className={props.className}>
                    {!props.chatType ? <BlockIcon /> : '-'}
                    blocked
                </div>
            </Popover>
        </>
    )
}

const PopupContent = (props) => {
    const { data } = props.chats.blockedData
    if (!data) {
        return <div className="popover-content-block">No Data</div>
    }
    return data.map((data, i) => {
        return (
            <div className="popover-content-block report" key={i}>
                <p className="popover-text">
                    Blocked
                    <span className="blocker-name"> by {data.blocker_name}</span> (
                    {props.account.account_info.isGroupAdmin ? data.university_name : 'Admin'}):
                </p>
                <p className="popover-text reason">{data.blocked_reason}</p>
                <p className="popover-text created-at">
                    {dayjs(data.created_at).calendar(null, {
                        sameDay: 'H:mm',
                        nextDay: '[Tomorrow]',
                        nextWeek: '[Next] dddd',
                        lastDay: '[Yesterday at ]H:mm',
                        lastWeek: 'DD MMMM',
                        sameElse: 'DD MMMM',
                    })}
                </p>
            </div>
        )
    })
}

const BlockIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Group 3896"
        viewBox="0 0 15.25 15.25"
        className="search-term__block-icon"
    >
        <defs>
            <style>
                {`.cls-1, .cls-2 { fill:none; stroke: #dc2e2e; stroke-miterlimit: 10;
                                stroke-width: 1.3px;} .cls-2 {
                                    stroke-linecap: round;
                                }`}
            </style>
        </defs>
        <circle cx="11" cy="11" r="3.6" className="cls-1"></circle>
        <path d="M8.47 13.55L13.66 8.36" className="cls-1"></path>
        <rect width="4.22" height="5.66" x="2.79" y="0.67" className="cls-1" rx="2.11" ry="2.11"></rect>
        <path
            d="M7.81 13.32H2A1.31 1.31 0 01.69 12v-1.38A3 3 0 012.8 7.71M7 7.71a2.93 2.93 0 011.2.73"
            className="cls-2"
        ></path>
    </svg>
)

export default connect((state) => {
    return {
        chats: state.chats,
        account: state.account,
    }
})(BlockProspectPopover)
