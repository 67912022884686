import { Modal, Tag } from 'antd';
import React, { useState } from 'react';
import { Button } from 'src/app/components/base/Button';
import TagsAddPopup from 'src/app/components/popups/TagsAddPopup';
export var TagsManager = function (_a) {
    var postId = _a.postId, tags = _a.tags;
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var handleClose = function () { return setVisible(false); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-right" },
            tags != null && tags.length > 0 && (React.createElement("div", { className: "mb-4 w-full text-left" }, tags.map(function (tag) {
                return (React.createElement(Tag, { key: tag.id, className: "my-1 bg-tap-dark text-white rounded-2xl whitespace-normal break-words text-reading-lg py-1 px-2" }, tag.name));
            }))),
            React.createElement(Button, { type: "text", onClick: function () { return setVisible(true); }, className: "text-brand-orange hover:!text-brand-orange" }, "Open Tags Manager")),
        visible && (React.createElement(Modal, { open: visible, bodyStyle: { padding: 0 }, centered: true, footer: null, closable: false, maskClosable: true, onCancel: handleClose, className: "global-tags-modal", zIndex: 11100 },
            React.createElement(TagsAddPopup, { postId: postId, currentList: tags, handleCloseTagsPopup: handleClose })))));
};
