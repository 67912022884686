import { DownOutlined } from '@ant-design/icons'
import { List, Popover } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getActiveLiveEvent, getLiveEvents } from '../../../../../../../redux/selectors'
import { CLEAR_MESSAGES } from '../../../../../../../redux/actions/chat/messagingActions'

const EventListPopover = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const liveEvents = useSelector(getLiveEvents)
    const activeEvent = useSelector(getActiveLiveEvent)

    const [isOpen, setIsOpen] = useState(false)

    const { name } = activeEvent

    const handlePopoverClick = useCallback(() => {
        setIsOpen((prevState) => !prevState)
    }, [])

    const handleVisibleChange = useCallback((open) => {
        setIsOpen(open)
    }, [])

    const nameNode = useMemo(
        () => (
            <div className="current-event-name-block" onClick={handlePopoverClick}>
                <span className="current-event-name">{name}</span>
                <DownOutlined />
            </div>
        ),
        [handlePopoverClick, name]
    )

    const handleLiveEventClick = useCallback(
        (id) => () => {
            dispatch({
                type: CLEAR_MESSAGES,
            })
            history.push(`./${id}`)
            setIsOpen(false)
        },
        [dispatch, history]
    )

    const contentNode = useMemo(
        () => (
            <List
                style={{
                    maxHeight: 350,
                    overflow: 'auto',
                    padding: 10,
                }}
                dataSource={liveEvents}
                renderItem={(item) => (
                    <List.Item>
                        <div onClick={handleLiveEventClick(item.id)} style={{ padding: '0 10px' }}>
                            {item.name}
                        </div>
                    </List.Item>
                )}
            />
        ),
        [handleLiveEventClick, liveEvents]
    )

    return (
        <Popover
            open={isOpen}
            onOpenChange={handleVisibleChange}
            trigger="click"
            placement="bottomLeft"
            content={contentNode}
        >
            {nameNode}
        </Popover>
    )
}

export default EventListPopover
