import { useMemo } from 'react'

import {
    NameCell,
    DailyUsageCell,
    MedianResponseTimeCell,
    AverageFeedbackCell,
    ViewCell,
    UniversityCell,
} from '../column-components'

export const useAmbassadorReportColumnsConfig = (isGroupAdmin) => {
    return useMemo(() => {
        let cells = [
            {
                title: 'Name',
                dataIndex: 'full_name',
                sorter: true,
                render: NameCell,
            },
            {
                title: 'Profile information',
                dataIndex: 'profile_info',
                render: ViewCell,
            },
            {
                title: 'Daily Average Usage',
                dataIndex: 'daily_average_usage',
                sorter: true,
                render: DailyUsageCell,
            },
            {
                title: 'Median First Response Time',
                dataIndex: 'median_response_time',
                sorter: true,
                render: MedianResponseTimeCell,
            },
            {
                title: 'Average feedback',
                dataIndex: 'average_feedback',
                sorter: true,
                render: AverageFeedbackCell,
            },
        ]

        const institutionCell = {
            title: 'Institution',
            dataIndex: 'university',
            render: UniversityCell,
        }
        const averageMessagesCell = {
            title: 'Average number of chat messages sent',
            dataIndex: 'average_chat_messages',
            sorter: true,
        }
        const contentCreatedCell = {
            title: 'Content created',
            dataIndex: 'total_posts',
            sorter: true,
        }
        const faqAnsweredCell = {
            title: 'FAQ answered',
            dataIndex: 'total_faq_answers',
            sorter: true,
        }
        const careerPointsCell = {
            title: 'Career points',
            dataIndex: 'total_career_points',
            sorter: true,
        }
        if (isGroupAdmin) {
            cells.push(institutionCell)
        }
        cells = [...cells, averageMessagesCell, contentCreatedCell, faqAnsweredCell, careerPointsCell]
        return cells
    }, [isGroupAdmin])
}
