import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React, { PureComponent, createRef, Fragment } from 'react'

import store from '../redux/store'
import { ReactComponent as Logo } from '../../assets/img/svg/close.svg'

var timer = null

class Search extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: '',
            searchList: [],
            type: props.type ? props.type : 'posts',
            isOpen: false,
        }

        this.addItem = this.addItem.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.handleClearTerms = this.handleClearTerms.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (event) => {
        let ref = document.getElementById('searchRef')
        if (ref && !ref.contains(event.target)) {
            this.setState({
                isOpen: false,
            })
        }
    }

    handleScroll = (obj) => {}

    handleChange({ target }) {
        // this.setState(state => ({...state, searchTerm: target.value, activeItem: target.value}))

        let newItem = target.value ? target.value.trim() : ''
        if (newItem.length > 0) {
            this.setState({
                isOpen: true,
                searchTerm: target.value,
                activeItem: target.value,
            })

            /* if (timer) {
                   clearTimeout(timer);
               }
               timer = setTimeout(() => {
                   store.dispatch({
                       type: SEARCH_NAMES,
                       payload: {
                           type: this.state.type,
                           searchName: target.value,
                           limit: 10,
                           offset: 0
                       }
                   })
               }, 700)*/
        } else {
            this.setState({
                searchTerm: target.value,
                activeItem: target.value,
                isOpen: false,
            })
        }
    }

    addItem() {
        if (this.state.searchTerm.trim().length > 1) {
            let listItems = [...this.props.currentItems, this.state.searchTerm.trim()]
            this.setState({
                isOpen: false,
                searchTerm: '',
                activeItem: '',
            })
            this.props.requestSearch(listItems)
        }
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            this.addItem()
        }
        /* else if(e.key === 'ArrowDown' || e.key === 'ArrowUp'){
            let activeItem = '';
            let { searchItems, currentItems } = this.props;

            let currentListNames = [];
            for (let elem of currentItems) {
                currentListNames.push(elem.name)
            }
            
            if(searchItems && searchItems.items) {
                activeItem = searchItems.items[0] && searchItems.items[0].name ? searchItems.items[0].name : '';
                for(let index in searchItems.items) {
                    if ((searchItems.items[index].name === this.state.activeItem || this.state.activeItem === '') 
                        && !currentListNames.includes(searchItems.items[index].name)) {
                        if (e.key === 'ArrowUp') {
                            activeItem = searchItems.items[index - 1] && this.state.activeItem !== '' ?
                                searchItems.items[index - 1].name : searchItems.items[index].name;
                        } else {
                            activeItem = searchItems.items[+index + 1] && this.state.activeItem !== '' ?
                                searchItems.items[+index + 1].name : searchItems.items[index].name;
                        }
                        break;
                    }
                }
            }
            this.setState({
                activeItem: activeItem,
                searchTerm: activeItem
            });
            try {
                let activeElement = null;
                let globalItemsElem = document.getElementById('globalItems');
                if(globalItemsElem) {
                    activeElement = globalItemsElem.getElementsByClassName('active')[0]
                }
                if (activeElement) {
                    let container = activeElement.parentNode;
                    let offset = activeElement.offsetTop; // get element's y position in list
                    offset -= container.scrollTop; // subtract list's scroll position to get relative position

                    let delta_to_bottom = offset + activeElement.clientHeight - container.clientHeight; // calculate distance from active element's bottom to the list's bottom
                    if (delta_to_bottom > 0) { // if distance is greater than 0, it must be out of view
                        container.scrollTop += e.key !== 'ArrowUp' ? delta_to_bottom + 25 : delta_to_bottom // add delta to view
                    }

                    // same goes for top
                    let delta_to_top = offset + activeElement.clientHeight - container.clientHeight;
                    if (delta_to_top < 0) {
                        container.scrollTop += e.key === 'ArrowUp' ? delta_to_top - 25 : delta_to_top
                    }
                }
            } catch(err) {}
        }*/
    }

    handleClearTerms() {
        this.props.requestSearch([])
    }

    handleDeleteItem(item) {
        let itemsSearchList = []
        for (let elem of this.props.currentItems) {
            if (elem !== item) {
                itemsSearchList.push(elem)
            }
        }
        this.props.requestSearch(itemsSearchList)
    }

    render() {
        let { activeItem } = this.state
        let { currentItems } = this.props

        return (
            <Fragment>
                <div id="searchRef" className="search-tags-form">
                    <svg
                        className="search-icon jam jam-search"
                        viewBox="-2.5 -2.5 24 24"
                        width="24"
                        height="24"
                        preserveAspectRatio="xMinYMin"
                        onClick={this.addItem}
                    >
                        <path d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zm6.32-1.094l3.58 3.58a1 1 0 1 1-1.415 1.413l-3.58-3.58a8 8 0 1 1 1.414-1.414z" />
                    </svg>
                    <input
                        type="text"
                        className="search-tags-input"
                        aria-label="Search"
                        placeholder="Search"
                        aria-required="false"
                        value={activeItem}
                        onChange={this.handleChange}
                        onKeyDown={this.onKeyDown}
                    />
                </div>
                <div className="global-tags-block">
                    <div className="search-term-wrapper main-search-terms">
                        {currentItems &&
                            currentItems.map((item, index) => {
                                return (
                                    <span key={index} className="search-term">
                                        {item}
                                        <Logo
                                            onClick={() => this.handleDeleteItem(item)}
                                            className="search-term__close-icon"
                                        />
                                    </span>
                                )
                            })}
                    </div>
                    {currentItems && !!currentItems.length && (
                        <button onClick={this.handleClearTerms} className="search-footer-clear-btn">
                            Clear all
                        </button>
                    )}
                </div>
            </Fragment>
        )
    }
}

export default Search
