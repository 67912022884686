import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'src/app/components/base/Button';
import { concatClsx } from 'src/app/helpers/classNames';
import { TypeCard } from './Card';
import { CardInfo } from './CardInfo';
import { CardTypesEnum, cardsData } from './helpers';
import './styles.scss';
export var RegisteringTypeStep = function (_a) {
    var onTypeSelect = _a.onTypeSelect, layoutRef = _a.layoutRef;
    var _b = useState(null), activeKey = _b[0], setActiveKey = _b[1];
    var handleCardClick = useCallback(function (key) {
        setActiveKey(function (prevKey) { return (key === prevKey ? prevKey : key); });
    }, []);
    useEffect(function () {
        if ((layoutRef === null || layoutRef === void 0 ? void 0 : layoutRef.current) && activeKey === CardTypesEnum.Institution) {
            layoutRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'center',
            });
        }
    }, [activeKey, layoutRef]);
    var typeCards = useMemo(function () {
        return cardsData.map(function (card) { return (React.createElement("div", { key: card.key, className: "grid grid-rows-[1fr_auto]" },
            React.createElement(TypeCard, { onClick: function () { return handleCardClick(card.key); }, isActive: activeKey === card.key, card: card, isActiveInstitutionCard: activeKey === card.key && card.key === CardTypesEnum.Institution }),
            activeKey === card.key && (React.createElement("div", { className: "block md:hidden" },
                React.createElement(CardInfo, { type: activeKey }))))); });
    }, [activeKey, handleCardClick]);
    return (React.createElement("div", null,
        React.createElement("div", { className: "pls-cards-container" }, typeCards),
        React.createElement("div", { className: "hidden md:grid md:grid-rows-[15rem]" }, activeKey && activeKey === CardTypesEnum.Institution ? (React.createElement("div", { className: "mt-10.5" },
            React.createElement(Button, { className: "pls-continue-button", onClick: onTypeSelect }, "Continue"))) : (React.createElement(CardInfo, { type: activeKey }))),
        React.createElement("div", { className: concatClsx('my-8 px-4 md:hidden', activeKey !== CardTypesEnum.Institution ? 'hidden' : undefined) },
            React.createElement(Button, { className: "pls-continue-button", onClick: onTypeSelect }, "Get started"))));
};
