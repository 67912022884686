import React from 'react';
export var TapAndIdpInviteTemplate = function (_a) {
    var usniversityName = _a.usniversityName;
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "Hello ",
            React.createElement("span", { className: "bg-color-selection/40" }, "[insert first name],")),
        React.createElement("p", null,
            "Thanks so much for all the brilliant work you've been doing through TAP thus far. As part of our ongoing work to help provide prospective students with authentic insights into life here at\u00A0",
            React.createElement("span", { className: "bg-color-selection/40" }, usniversityName),
            " , we have chosen to showcase your profile on a range of platforms managed by a key partner of ours, IDP Education."),
        React.createElement("p", null,
            "IDP Education has over 50 years of experience helping students achieve their academic goals. They have a student-first approach and work with a global network of expert education counsellors spanning 35 countries. You can read more about IDP and their Peer-to-Peer (P2P) services\u00A0",
            React.createElement("a", { href: "https://knowledge.theambassadorplatform.com/ambassadors-who-are-idp-and-what-are-their-joint-services", rel: "noopener noreferrer", target: "_blank", className: "orange-link" }, "here.")),
        React.createElement("p", null, "In short, we will be providing prospective students with advice through IDP's Student Chat service or their Student Community service. Student Chat, like the name suggests, is a chat service that will enable students to chat with you (just like you do currently), whilst Student Community will display some of your fantastic content to help them picture what life at your institution is really like."),
        React.createElement("p", null, "As part of this work, there is a little additional training we need you to go through, which should take up to 30 minutes of your time. Firstly, you will need to watch the following short training videos:"),
        React.createElement("p", null,
            React.createElement("ol", { className: "ml-10" },
                React.createElement("li", null,
                    React.createElement("a", { href: "https://vimeo.com/929876983", rel: "noopener noreferrer", target: "_blank" }, "IDP Live App")),
                React.createElement("li", null,
                    React.createElement("a", { href: "https://vimeo.com/929882330", rel: "noopener noreferrer", target: "_blank" }, "WhatUni")),
                React.createElement("li", null,
                    React.createElement("a", { href: "https://vimeo.com/929882695", rel: "noopener noreferrer", target: "_blank" }, "HCI")))),
        React.createElement("p", null,
            "Working on Student Community will be very similar to how we currently work with you on producing content. The main difference is that instead of requests coming from us, they will come from IDP themselves,\u00A0",
            React.createElement("a", { href: "https://cdn.theaccessplatform.com/img/activity_content_list.png", rel: "noopener noreferrer", target: "_blank", className: "orange-link" }, "like in the screenshot.")),
        React.createElement("p", null,
            "You can find out more about their content guidelines\u00A0",
            React.createElement("a", { href: "https://knowledge.theambassadorplatform.com/idp-content-guidelines-for-ambassadors", rel: "noopener noreferrer", target: "_blank", className: "orange-link" }, "here,"),
            "\u00A0 which they have created specifically to explain the process, including time management."),
        React.createElement("p", null, "Speaking of time, we expect the work through the Student Chat service to add up to an additional hour of time per month to spend on conversations, plus an additional hour for content creation through the Student Community service."),
        React.createElement("p", null, "I hope this all makes sense and sounds exciting - through this work, not only will your guidance and content reach students on our website, but also on other global platforms powered by a large company like IDP Education."),
        React.createElement("p", null,
            "If you have any questions let me know.\u00A0",
            React.createElement("span", { className: "font-bold" },
                "Please ensure you have filled out\u00A0",
                React.createElement("a", { href: "https://share.hsforms.com/1DgokrrnLQsS01s671Tj2fw1fc7h", rel: "noopener noreferrer", target: "_blank", className: "orange-link" }, "this form"),
                "\u00A0(takes 20 seconds) to confirm you have completed the training and then I can switch you on for the correct services.")),
        React.createElement("p", null, "Thanks very much!"),
        React.createElement("p", null, "Best wishes,")));
};
