import { MenuOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import React, { useState } from 'react'

import EventMenuContent from './event-menu-content'

const EventMenu = ({ activeEvent }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleMenuClick = () => {
        setIsOpen(true)
    }

    const handleVisibleChange = (open) => {
        setIsOpen(open)
    }

    return (
        <Popover
            placement="bottomRight"
            trigger="click"
            content={<EventMenuContent setIsOpen={setIsOpen} activeEvent={activeEvent} />}
            open={isOpen}
            onOpenChange={handleVisibleChange}
        >
            <Button type="text" onClick={handleMenuClick} data-automation-id="event-menu">
                <MenuOutlined />
            </Button>
        </Popover>
    )
}

export default EventMenu
