import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import store from '../../../redux/store'
import config from '../../../system/config'

import HeaderSelector from '../../../components/HeaderSelector'
import TAPTitles from './TAPTitles'
import TAPFilters from './TAPFilters'
import DomainsSelector from './DomainsSelector'
import { TapHostedTapFeed, OrganisationUrl, OrganisationUrlForm, ThirdPartyAuth, FlagsTogler } from './components'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions'

import { getTAPTitles, changeTAPTitles, getSocialProofInfo } from '../../../redux/actions/settings/customTitlesActions'
import { getFilters, setFilters } from '../../../redux/actions/settings/filtersActions'
import { getCspDomains, setCspDomains } from '../../../redux/actions/settings/cspDomainsActions'
import { RestrictedModal } from '../../../components/modals/RestrictedModal'
import { isAllowed } from '../../../helpers/helpers'
import { trackSocialProof, trackTapFeed } from '../../../helpers/MixpanelHelper'
import { Protect } from 'src/app/permissions/Protect'
class TapPage extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            titles: {
                page_title: '',
                page_desc: '',
                display_search_bar: true,
                display_tap_logo: true,
                social_proof: false,
                show_number_likes_views: false,
                group_chat_title: 'Speak with our ambassadors and others like you',
                profile_title: 'Chat with our ambassadors',
                content_title: "Check out what we've been up to",
                faq_title: 'Explore our frequently asked questions',
                live_stream_title: 'Check out Streams',
            },
            filters: {},
        }

        this.getMainInfo = this.getMainInfo.bind(this)
    }

    componentDidMount() {
        this.getMainInfo()

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            this.getMainInfo()
        }
    }

    getMainInfo() {
        const onSuccess = (titles) => {
            if (!titles) {
                titles = {}
            }
            this.setState({
                titles: {
                    page_title: titles.page_title ? titles.page_title : '',
                    page_desc: titles.page_desc ? titles.page_desc : '',
                    display_search_bar: titles.display_search_bar,
                    display_tap_logo: titles.display_tap_logo,
                    social_proof: titles.social_proof,
                    group_chat_title: titles.group_chat_title ? titles.group_chat_title : '',
                    profile_title: titles.profile_title ? titles.profile_title : '',
                    content_title: titles.content_title ? titles.content_title : '',
                    faq_title: titles.faq_title ? titles.faq_title : '',
                    live_stream_title: titles.live_stream_title ? titles.live_stream_title : '',
                    show_number_likes_views: titles.show_number_likes_views ? titles.show_number_likes_views : '',
                },
                initialSocialProof: titles.social_proof,
            })
        }

        this.props.getTAPTitles(onSuccess)
        this.props.getFilters()
        this.props.getSocialProofInfo()

        if (
            !this.props.account.account_info.isGroupAdmin ||
            (this.props.account.account_info.isGroupAdmin && this.props.account.currentGroupUniversityId)
        ) {
            this.props.getCspDomains()
        }
    }

    handleCopyIframeCode() {
        const copyText = document.querySelectorAll('.university-access-code')[0]
        copyText.select()
        document.execCommand('copy')

        store.dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'TAP Feed embed code copied to clipboard',
            },
        })
    }

    handleChange = (event, type = 'filters') => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (type === 'filters') {
            this.setState((state) => ({
                ...state,
                filters: {
                    ...state.filters,
                    [name]: value,
                },
            }))
        } else {
            this.setState((state) => ({
                ...state,
                titles: {
                    ...state.titles,
                    [name]: value,
                },
            }))
        }
    }

    handleSubmit = async (type, options) => {
        const {
            page_title: pageTitle,
            page_desc: pageDesc,
            display_search_bar: displaySearchBar,
            group_chat_title: groupChatTitle,
            profile_title: profileTitle,
            content_title: contentTitle,
            faq_title: faqTitle,
            social_proof: socialProof,
            show_number_likes_views: showNumberLikesViews,
            display_tap_logo: displayTapLogo,
            live_stream_title: liveStreamTitle,
        } = this.state.titles

        const onSuccess = () => {
            try {
                if (socialProof !== this.state.initialSocialProof) {
                    // mixpanel call
                    trackSocialProof(this.props.account.account_info.university.id, socialProof)
                    this.setState({
                        initialSocialProof: socialProof,
                    })
                }
            } catch {}
        }
        if (type === 'titles') {
            await this.props.changeTAPTitles(
                {
                    page_title: pageTitle,
                    page_desc: pageDesc,
                    display_search_bar: displaySearchBar,
                    group_chat_title: groupChatTitle,
                    profile_title: profileTitle,
                    content_title: contentTitle,
                    faq_title: faqTitle,
                    social_proof: socialProof,
                    show_number_likes_views: showNumberLikesViews,
                    display_tap_logo: displayTapLogo,
                    live_stream_title: liveStreamTitle,
                },
                onSuccess
            )
        }

        if (type === 'filters' && options) {
            this.props.setFilters(
                options.map((option) => ({
                    ...option,
                }))
            )
        }

        const iframe = document.querySelector('#tap-iframe-widget')
        const src = iframe.src
        iframe.src = ''
        setTimeout(() => (iframe.src = src), 1000)
    }

    handleSaveDomains = (domains) => {
        const onFinish = (changed) => {
            if (changed) {
                trackTapFeed(this.props.account.account_info.university.id)

                store.dispatch({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: 'Domain(s) have been successfully updated',
                    },
                })
            }
        }
        this.props.setCspDomains(domains, onFinish)
    }

    render() {
        const { tapFilters, account, tapTitles, cspDomains } = this.props

        const websiteUrl = account?.account_info?.university?.website_url
        const organisationId = account?.account_info?.university?.id

        const dataParamName =
            account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? 'data-group-id'
                : 'data-university-id'
        const dataParamId =
            account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? account.account_info.universityGroup.id
                : account.currentGroupUniversityId
                ? account.currentGroupUniversityId
                : account.account_info.university.id
        const iframePath =
            config.tap_page_url +
            (account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? 'group/' + account.account_info.universityGroup.id
                : account.currentGroupUniversityId || account.account_info.university.id) +
            '?tap-dashboard=true'

        return (
            <Protect>
                <div>
                    <div id="page-body" className="page-body js-index">
                        <section className="content settings modal-restricted-area" id="modal-restricted-area">
                            <h3 className="settings-title">TAP Feed</h3>

                            <div className="settings-grid has-tutorial">
                                <div className="column">
                                    <div className="tutorial-intro">
                                        <p>
                                            Your TAP Feed is where prospects see your ambassador profiles and other
                                            content. We like to think of it as 'the home of your ambassadors online'.
                                            It’s just a single line of code that we recommend placing on a new page on
                                            your website.
                                        </p>
                                        <p>
                                            The Ambassador Platform populates the page automatically with your
                                            ambassador profiles and content in a fully searchable format. We recommend
                                            keeping other text and imagery on the page to a minimum, but an additional
                                            header or footer may be appropriate.
                                        </p>
                                    </div>

                                    <hr className="settings-hr"></hr>
                                    {!account.account_info.isGroupAdmin && (
                                        <div className="settings-item hosted-tap-feed">
                                            <h4 className="settings-name">TAP-hosted TAP Feed</h4>
                                            <TapHostedTapFeed />
                                            {websiteUrl ? (
                                                <OrganisationUrl
                                                    organisationUrl={websiteUrl}
                                                    organisationId={organisationId}
                                                />
                                            ) : (
                                                <OrganisationUrlForm organisationId={organisationId} />
                                            )}
                                        </div>
                                    )}

                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Embed</h4>
                                        <p>
                                            Тhis is your TAP Feed embed code{' '}
                                            <button
                                                type="button"
                                                onClick={this.handleCopyIframeCode}
                                                className="settings-btn copy-iframe-code"
                                            >
                                                Copy
                                            </button>
                                        </p>
                                        <input type="text" defaultValue="hello" className="rss-link" />
                                        <input
                                            className="university-access-code"
                                            readOnly
                                            type="text"
                                            value={
                                                "<iframe id='tap-iframe-widget' src='' style='border:none' width='100%' height='700' scrolling='no' data-type='full-widget' " +
                                                dataParamName +
                                                "='" +
                                                dataParamId +
                                                "'></iframe><script defer src='" +
                                                config.widget_script +
                                                "'></script>"
                                            }
                                        />
                                        <textarea
                                            cols="40"
                                            rows="5"
                                            readOnly
                                            // resize="none"
                                            className="settings-url"
                                            value={
                                                "<iframe id='tap-iframe-widget' style='border:none' src='' width='100%' height='700' scrolling='no' data-type='full-widget' " +
                                                dataParamName +
                                                "='" +
                                                dataParamId +
                                                "'></iframe>" +
                                                "<script defer src='" +
                                                config.widget_script +
                                                "'></script>"
                                            }
                                        />
                                        <br />
                                        {(!account.account_info.isGroupAdmin ||
                                            (account.account_info.isGroupAdmin &&
                                                account.currentGroupUniversityId)) && (
                                            <>
                                                <p>
                                                    Enter the domain(s) where your TAP Feed and TAP Promotions
                                                    (carousels) will be hosted. This is a really important step: we will
                                                    only display TAP on predefined domains to provide extra security.{' '}
                                                    <b>
                                                        <a
                                                            href="https://knowledge.theambassadorplatform.com/what-is-content-security-policy-csp"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            Find out more about Content Security Policy.
                                                        </a>
                                                    </b>
                                                </p>
                                                <DomainsSelector domains={cspDomains} onSave={this.handleSaveDomains} />
                                            </>
                                        )}
                                    </div>
                                    <ThirdPartyAuth />
                                    <FlagsTogler />
                                </div>

                                <div className="column resources">
                                    <h3 className="video-title">Video tutorial: TAP Feed set-up</h3>
                                    <div className="video-wrapper">
                                        <iframe
                                            src="https://player.vimeo.com/video/387914876"
                                            width="640"
                                            height="360"
                                            frameBorder="0"
                                            allow="autoplay; fullscreen"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                    <div className="resources-box">
                                        <h3>Helpful resources</h3>
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://knowledge.theambassadorplatform.com/what-is-your-tap-feed"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    alt="TAP Feed explainer"
                                                >
                                                    What is your TAP Feed?
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://knowledge.theambassadorplatform.com/setting-up-your-tap-feed"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Creating your TAP Feed
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://knowledge.theambassadorplatform.com/email-template-for-setting-up-your-tap-feed"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    alt="TAP Feed email template"
                                                >
                                                    Asking your IT Team to set up the TAP Feed
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="column spacer"></div>
                            </div>

                            <HeaderSelector title="TAP Feed configuration" selectors={['General', 'Drop down Filters']}>
                                <TAPTitles
                                    {...this.state.titles}
                                    holdersTitles={{ ...this.state.holdersTitles }}
                                    handleChange={this.handleChange}
                                    handleSubmit={this.handleSubmit}
                                    tapTitles={tapTitles}
                                    isGroupAdmin={account.account_info.isGroupAdmin}
                                    universityName={account.account_info.university.name}
                                    account={account}
                                />

                                <TAPFilters
                                    handleSubmit={this.handleSubmit}
                                    tapFilters={tapFilters}
                                    isFilters={tapFilters.length > 0}
                                />
                            </HeaderSelector>

                            <div className="settings-grid tap-page-widget">
                                <div className="column">
                                    <div className="settings-item tap-page-embed">
                                        <h4 className="settings-name">Widget example</h4>
                                        <p>
                                            This is an example of how your TAP Feed will look when it is embedded in
                                            your website
                                        </p>
                                        <iframe
                                            key={account.currentGroupUniversityId}
                                            name="tap-dashboard-iframe"
                                            id="tap-iframe-widget"
                                            src={iframePath}
                                            width="100%"
                                            height="700"
                                            style={{ border: 'none' }}
                                            scrolling="yes"
                                            data-type="widget"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    {!isAllowed('tap_feed') && <RestrictedModal closable={false} />}
                </div>
            </Protect>
        )
    }
}

export default connect(
    (state) => {
        return {
            account: state.account,
            tapTitles: state.tapTitles,
            tapFilters: state.tapFilters,
            cspDomains: state.cspDomains,
        }
    },
    { getTAPTitles, changeTAPTitles, getFilters, setFilters, getCspDomains, setCspDomains, getSocialProofInfo }
)(TapPage)
