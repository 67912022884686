const types = {
    UNIVERSITY_STATISTICS_REQUESTED: 'UNIVERSITY_STATISTICS_REQUESTED',
    UNIVERSITY_STATISTICS_SUCCESS: 'UNIVERSITY_STATISTICS_SUCCESS',
    UNIVERSITY_STATISTICS_FAILED: 'UNIVERSITY_STATISTICS_FAILED',
    OVERVIEW_SUMMARY_STATISTICS_REQUESTED: 'OVERVIEW_SUMMARY_STATISTICS_REQUESTED',
    OVERVIEW_SUMMARY_STATISTICS_SUCCESS: 'OVERVIEW_SUMMARY_STATISTICS_SUCCESS',
    OVERVIEW_SUMMARY_STATISTICS_FAILED: 'OVERVIEW_SUMMARY_STATISTICS_FAILED',
    OVERVIEW_CHAT_STATISTICS_REQUESTED: 'OVERVIEW_CHAT_STATISTICS_REQUESTED',
    OVERVIEW_CHAT_STATISTICS_SUCCESS: 'OVERVIEW_CHAT_STATISTICS_SUCCESS',
    OVERVIEW_CHAT_STATISTICS_FAILED: 'OVERVIEW_CHAT_STATISTICS_FAILED',
    OVERVIEW_CONTENT_STATISTICS_REQUESTED: 'OVERVIEW_CONTENT_STATISTICS_REQUESTED',
    OVERVIEW_CONTENT_STATISTICS_SUCCESS: 'OVERVIEW_CONTENT_STATISTICS_SUCCESS',
    OVERVIEW_CONTENT_STATISTICS_FAILED: 'OVERVIEW_CONTENT_STATISTICS_FAILED',
    OVERVIEW_FAQ_STATISTICS_REQUESTED: 'OVERVIEW_FAQ_STATISTICS_REQUESTED',
    OVERVIEW_FAQ_STATISTICS_SUCCESS: 'OVERVIEW_FAQ_STATISTICS_SUCCESS',
    OVERVIEW_FAQ_STATISTICS_FAILED: 'OVERVIEW_FAQ_STATISTICS_FAILED',
}

export const fetchUniversityStatistics = (filter) => ({
    type: types.UNIVERSITY_STATISTICS_REQUESTED,
    filter: { from: filter.fromDate, to: filter.toDate },
})

export const fetchOverviewSummaryStatistics = (filter) => ({
    type: types.OVERVIEW_SUMMARY_STATISTICS_REQUESTED,
    filter: filter,
})

export const fetchOverviewChatStatistics = (filter) => ({
    type: types.OVERVIEW_CHAT_STATISTICS_REQUESTED,
    filter: filter,
})

export const fetchOverviewContentStatistics = (filter) => ({
    type: types.OVERVIEW_CONTENT_STATISTICS_REQUESTED,
    filter: filter,
})

export const fetchOverviewFaqStatistics = (filter) => ({
    type: types.OVERVIEW_FAQ_STATISTICS_REQUESTED,
    filter: filter,
})

export default types
