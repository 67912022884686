import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import store from '../../redux/store'

import { UsersTable } from './components'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../redux/actions/content/componentsActions'
import { SET_CURRENT_SEARCH_LIST_TAGS } from '../../redux/actions/tags/tagsActions'

class Users extends PureComponent {
    componentDidMount() {
        store.dispatch({
            type: SET_CURRENT_SEARCH_LIST_TAGS,
            payload: {
                items: [],
            },
        })
        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'sa-list',
            },
        })
    }

    render() {
        return (
            <div>
                <div id="page-body" className="page-body js-index">
                    <section className="content no-submenu">
                        <div className="settings-container">
                            <UsersTable />
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        twoTierMenu: state.twoTierMenu,
    }
})(Users)
