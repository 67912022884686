import { AudioOutlined, AudioMutedOutlined } from '@ant-design/icons'
import { Progress, Row, Col } from 'antd'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getEventStreamSlice } from '../../../../../../../../redux/selectors/common-selectors'

const AudioLevel = ({ audioLevel }) => {
    const {
        settings: { audioMuted },
    } = useSelector(getEventStreamSlice)

    const icon = useMemo(() => (audioMuted ? <AudioMutedOutlined /> : <AudioOutlined />), [audioMuted])

    return (
        <Row gutter={8}>
            <Col flex="1">
                <Progress percent={audioLevel} strokeColor="#52c41a" showInfo={false} size="small" />
            </Col>
            <Col>{icon}</Col>
        </Row>
    )
}

export default AudioLevel
