import dayjs from 'dayjs'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(calendar)
dayjs.extend(relativeTime)

class DeletedPostMessage extends PureComponent {
    render() {
        const { account, message } = this.props

        const createdAt = dayjs(message.created_at).calendar(null, {
            sameDay: 'H:mm',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday at ]H:mm',
            lastWeek: 'DD MMMM',
            sameElse: 'DD MMMM',
        })

        return (
            <div className={message.user.id == account.account_info.id ? 'msg-item mod-my' : 'msg-item'}>
                <div className="msg-avatar-block">
                    {message.user && message.user.avatar ? (
                        <img
                            className="msg-item-img"
                            src={message.user.avatar.sizes['70x70'] || message.user.avatar.original}
                        />
                    ) : (
                        <div className="msg-avatar-text">{message.user ? message.user.name.charAt(0) : ''}</div>
                    )}
                </div>
                <div className="msgs-block mod-media">
                    <div className="msg-block-item">
                        <p className={message.user.id == account.account_info.id ? 'hidden-block' : 'msg-name'}>
                            {message.user.name}
                        </p>
                        <div className="deleted-post">
                            <p>This post has been deleted</p>
                        </div>
                        <div className="msg-controls">
                            <p className="msg-time">{createdAt}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
    }
})(DeletedPostMessage)
