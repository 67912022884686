import HttpService from '../../system/services/HttpService'

export default {
    searchEvents: ({
        status,
        filters = {},
        search = null,
        eventId = null,
        limit = 20,
        offset = 0,
        liveEventId = null,
        universityId = null,
    }) => {
        const data = {
            limit,
            offset,
            status, // upcoming, inProgress, previous
            filter: filters,
        }
        if (search) {
            data.search = search // no search for now
        }
        if (eventId) {
            data.eventId = eventId
        }
        if (liveEventId) {
            data.liveEventId = liveEventId
        }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/liveEvents', data)
    },

    createEvent: (data) => {
        return HttpService.post('/dashboard/liveEvents/create', data)
    },

    editEvent: (data) => {
        return HttpService.put('/dashboard/liveEvents/update', data)
    },

    changeStatus: (liveEventId, status) => {
        // status: cancelled, previous, inProgress
        return HttpService.put('/dashboard/liveEvents/updateStatus', { status, liveEventId })
    },

    toggleChat: (liveEventId) => {
        return HttpService.put('/dashboard/liveEvents/toggleChat', { liveEventId })
    },

    updateAmbassadors: (data) => {
        return HttpService.put('/dashboard/liveEvents/updateAmbassadors', data)
    },

    updateSpeakers: (data) => {
        return HttpService.put('/dashboard/liveEvents/updateSpeakers', data)
    },

    getProspects: (data) => {
        return HttpService.get('/dashboard/liveEvents/prospects', data)
    },

    getLiveSwitchRegistrationToken: ({ deviceId, clientId, channelClaim }) => {
        return HttpService.post('/widget/liveEvents/getLiveSwitchRegistrationToken', {
            deviceId,
            clientId,
            channelClaim,
        })
    },
}
