import {
    CHAT_MESSAGES_INFO,
    CHAT_MESSAGES_INFO_SUCCESS,
    CLEAR_MESSAGES,
    FIND_OR_CREATE_CHAT_FAILED,
    FIND_OR_CREATE_CHAT_SUCCESS,
    LOAD_AROUND_MESSAGES_SUCCESS,
    LOAD_NEW_MESSAGES_SUCCESS,
    LOAD_OLD_MESSAGES_SUCCESS,
    PIN_MESSAGE_SUCCESS,
    UNPIN_MESSAGE_SUCCESS,
    UPDATE_MESSAGE,
} from '../../../actions/chat/messagingActions'

import { ADD_TOPICS_SUCCESS } from '../../../actions/chat/topicsActions'
import { CHAT_MESSAGES } from '../../../store/initialState'

import { ChatType } from '../../../../common/constants'

const initialState = CHAT_MESSAGES

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case CHAT_MESSAGES_INFO: {
            let block = true
            try {
                const element = document.querySelector('#chat-body')
                if (element && +element.scrollHeight - Math.ceil(+element.scrollTop) < +element.clientHeight + 6) {
                    block = false
                }
            } catch {}
            if (
                (payload.type_chat === ChatType.GROUP_CHAT || payload.type_chat === ChatType.LIVE_STREAM_CHAT) &&
                !payload.afterMessageId
            ) {
                payload.limit = state.limit
            }
            if (payload.onScroll) {
                payload.beforeMessageId =
                    !state.items || (!state.items && state.items.length === 0) ? 0 : state.items[0].id
            }
            return Object.assign({}, state, {
                topics: [],
                dialog_id: payload.dialog_id,
                isBlockedScroll: !!payload.isBlockedScroll && block,
                isLoading: !payload.loaderDisabled,
                oldLoaded: false,
            })
        }

        case CHAT_MESSAGES_INFO_SUCCESS: {
            const items = payload.items && payload.items.length > 0 ? payload.items.reverse() : []
            items.forEach((message) => {
                message.hasBadWord = message.content?.text?.includes('badword') || false
            })

            return Object.assign({}, state, {
                items: items,
                total: payload.total,
                topics: payload.topics,
                isLoading: false,
                dialog_id: payload.dialog_id,
                isFirstRequest: false,
                scroll: true,
                totalReportedMessages: payload.totalReportedMessages,
                pinnedMessages: payload.pinnedMessages || [],
                isLastMessageLoaded: false,
                scrollDirection: 'top',
            })
        }

        case LOAD_NEW_MESSAGES_SUCCESS: {
            const items = state.items ? [...state.items] : []
            const lastItemId = items && items.length > 0 ? items[items.length - 1].id : 0
            const newItems = payload.items && payload.items.length > 0 ? payload.items.reverse() : []
            for (const item of newItems) {
                // on some case additional check
                if (item.id > lastItemId) {
                    items.push(item)
                }
            }
            return {
                ...state,
                items: items,
                oldLoaded: true,
                isFirstMessageLoaded: payload.items.length === 0,
                scrollDirection: 'bottom',
            }
        }

        case LOAD_OLD_MESSAGES_SUCCESS: {
            const element = document.querySelector('#chat-body')
            let previousScrollHeight = 0
            if (element) {
                previousScrollHeight = element.scrollHeight
            }
            let items = state.items ? [...state.items] : []
            // on some case we will check if messages new
            if (
                items.length > 0 &&
                payload.items &&
                payload.items.length > 0 &&
                items[0].id > payload.items[payload.items.length - 1].id
            ) {
                items = [...payload.items.reverse(), ...items]
            }
            return {
                ...state,
                items: items,
                previousScrollHeight: previousScrollHeight,
                oldLoaded: true,
                isLastMessageLoaded: payload.items.length === 0,
                scrollDirection: 'top',
            }
        }

        case LOAD_AROUND_MESSAGES_SUCCESS: {
            return {
                ...state,
                items: payload.items.reverse(),
                isFirstMessageLoaded: false,
            }
        }

        case CLEAR_MESSAGES: {
            return initialState
        }

        case FIND_OR_CREATE_CHAT_SUCCESS: {
            return {
                ...state,
                dialog_id: payload.dialog_id,
            }
        }

        case FIND_OR_CREATE_CHAT_FAILED: {
            return state
        }

        case UPDATE_MESSAGE: {
            // eslint-disable-next-line unicorn/prefer-spread
            const items = state.items?.slice()
            const messageIndex = items?.findIndex((item) => item.id === action.payload.messageId)
            if (messageIndex) {
                items[messageIndex] = {
                    ...items[messageIndex],
                    hide_keywords: !items[messageIndex].hide_keywords,
                }
            }
            return {
                ...state,
                items,
                scroll: false,
            }
        }

        case ADD_TOPICS_SUCCESS: {
            return {
                ...state,
                topics: payload.topics ? payload.topics : [...state.topics],
            }
        }
        case PIN_MESSAGE_SUCCESS: {
            return {
                ...state,
                pinnedMessages: [...state.pinnedMessages, payload.message],
                scroll: false,
            }
        }
        case UNPIN_MESSAGE_SUCCESS: {
            return {
                ...state,
                pinnedMessages: state.pinnedMessages.filter((item) => item.id !== payload.messageId),
            }
        }

        default: {
            return state
        }
    }
}
