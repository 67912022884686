import { SET_CURRENT_SEARCH_LIST_TAGS } from '../../actions/tags/tagsActions'

import { CURRENT_GLOBAL_TAGS } from '../../store/initialState'

const initialState = CURRENT_GLOBAL_TAGS

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case SET_CURRENT_SEARCH_LIST_TAGS: {
            return {
                ...state,
                items: payload.items,
            }
        }

        default: {
            return state
        }
    }
}
