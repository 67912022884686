import React, { PureComponent } from 'react'
import Logo from '../../../../assets/img/svg/logo.svg'
import { NavLink, withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { getCommonRoutePath } from '../../../helpers/helpers'

class NavigationSetupWizard extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            activeSubmenu: 'setup-privacy-policy',
        }
    }

    onStepClick = (e, step) => {
        const { account } = this.props
        if (window.localStorage.getItem('setupWizardStep')) {
            if (window.localStorage.getItem('setupWizardStep') < step) {
                e.preventDefault()
            }
        } else if (
            account.account_info &&
            !account.account_info.isGroupAdmin &&
            account.account_info.university &&
            !account.account_info.university.wizard_setup_passed
        ) {
            e.preventDefault()
        }
    }

    render() {
        const { twoTierMenu } = this.props
        const activeMenu = twoTierMenu.activeSubmenu

        const commonPath = getCommonRoutePath()

        return (
            <div>
                <nav className="nav">
                    <div className="nav-top">
                        <div className="logo nav-link nav-icon-notooltip">
                            <img className="logo-img" src={Logo} alt="The Ambassador Platform logo" />
                        </div>
                        <NavLink
                            to={commonPath + '/setup/privacy-policy'}
                            data-menu-value="setup-privacy-policy"
                            className={
                                activeMenu == 'setup-privacy-policy'
                                    ? 'nav-link-wizard nav-privacy-policy is-active'
                                    : 'nav-link-wizard nav-privacy-policy'
                            }
                            onClick={(e) => this.onStepClick(e, 1)}
                        >
                            <span className="nav-link-wizard-icon">1</span>
                        </NavLink>
                        <NavLink
                            to={commonPath + '/setup/training-centre'}
                            data-menu-value="setup-training-centre"
                            className={
                                activeMenu == 'setup-training-centre'
                                    ? 'nav-link-wizard nav-training-centre is-active'
                                    : 'nav-link-wizard nav-training-centre'
                            }
                            onClick={(e) => this.onStepClick(e, 2)}
                        >
                            <span className="nav-link-wizard-icon">2</span>
                        </NavLink>
                        <NavLink
                            to={commonPath + '/setup/branding'}
                            data-menu-value="setup-branding"
                            className={
                                activeMenu == 'setup-branding'
                                    ? 'nav-link-wizard nav-branding-customisation is-active'
                                    : 'nav-link-wizard nav-branding-customisation'
                            }
                            onClick={(e) => this.onStepClick(e, 3)}
                        >
                            <span className="nav-link-wizard-icon">3</span>
                        </NavLink>
                        <NavLink
                            to={commonPath + '/setup/tap-page'}
                            data-menu-value="setup-tap-page"
                            className={
                                activeMenu == 'setup-tap-page'
                                    ? 'nav-link-wizard nav-tap-page is-active'
                                    : 'nav-link-wizard nav-tap-page'
                            }
                            onClick={(e) => this.onStepClick(e, 4)}
                        >
                            <span className="nav-link-wizard-icon">4</span>
                        </NavLink>
                        <NavLink
                            to={commonPath + '/setup/optional-data'}
                            data-menu-value="setup-optional-data"
                            className={
                                activeMenu == 'setup-optional-data'
                                    ? 'nav-link-wizard nav-optional-data is-active'
                                    : 'nav-link-wizard nav-optional-data'
                            }
                            onClick={(e) => this.onStepClick(e, 5)}
                        >
                            <span className="nav-link-wizard-icon">5</span>
                        </NavLink>
                    </div>
                </nav>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        twoTierMenu: state.twoTierMenu,
    }
})(withRouter(NavigationSetupWizard))
