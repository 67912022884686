import React, { Component, Fragment } from 'react'
import store from './../redux/store'
import { connect } from 'react-redux'
import { CREATE_GLOBAL_TAG, GET_GLOBAL_LIST_TAGS } from '../redux/actions/tags/tagsActions'
import { ReactComponent as Logo } from '../../assets/img/svg/close.svg'

class TagsAddPopup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            globalTagsItems: [],
            inputData: '',
            activeTag: '',
            size: 10,
            showDrop: false,
        }
        this.handleDeleteTag = this.handleDeleteTag.bind(this)
        this.searchGlobalTags = this.searchGlobalTags.bind(this)
    }

    componentDidMount() {
        if (this.props.globalTagsItems) {
            this.setState({
                globalTagsItems: this.props.globalTagsItems,
            })
        }
    }

    componentWillUnmount() {}

    addTag = (tag) => {
        let globalTagsItems = [...this.state.globalTagsItems, tag]
        this.setState({
            inputData: '',
            activeTag: '',
            showDrop: false,
            globalTagsItems: globalTagsItems,
        })
        let currentListIds = []
        for (let elem of globalTagsItems) {
            currentListIds.push(elem.id)
        }
        this.props.handleGlobalTags(currentListIds, globalTagsItems)
    }

    addNewTag = (e) => {
        let newTag = this.state.inputData.trim()
        let afterSuccess = (tag) => {
            this.addTag(tag)
        }
        let tagFound = false
        let { globalTags } = this.props

        if (globalTags && globalTags.items) {
            globalTags.items.map((tag) => {
                if (tag.name === newTag) {
                    afterSuccess(tag)
                    tagFound = true
                }
            })
        }
        if (this.state.globalTagsItems) {
            this.state.globalTagsItems.map((tag) => {
                if (tag.name === newTag) {
                    afterSuccess(tag)
                    tagFound = true
                }
            })
        }
        if (!tagFound) {
            store.dispatch({
                type: CREATE_GLOBAL_TAG,
                payload: {
                    name: newTag,
                    afterSuccess: afterSuccess,
                },
            })
        }
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter' && this.state.inputData.trim().length > 1) {
            e.preventDefault()
            this.addNewTag()
            e.stopPropagation()
        } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            let activeTag = ''
            let { globalTags, currentList } = this.props

            let currentListIds = []
            if (currentList) {
                for (let elem of currentList) {
                    currentListIds.push(elem.id)
                }
            }
            if (globalTags && globalTags.items) {
                activeTag = globalTags.items[0] && globalTags.items[0].name ? globalTags.items[0].name : ''
                for (let index in globalTags.items) {
                    if (globalTags.items[index].name === this.state.activeTag || this.state.activeTag === '') {
                        if (e.key === 'ArrowUp') {
                            activeTag =
                                globalTags.items[index - 1] && this.state.activeTag !== ''
                                    ? globalTags.items[index - 1].name
                                    : globalTags.items[index].name
                        } else {
                            activeTag =
                                globalTags.items[+index + 1] && this.state.activeTag !== ''
                                    ? globalTags.items[+index + 1].name
                                    : globalTags.items[index].name
                        }
                        break
                    }
                }
            }
            this.setState({
                activeTag: activeTag,
                inputData: activeTag,
                size: e.target.value.length + 3 < 8 ? 10 : e.target.value.length + 5,
            })

            try {
                let activeElement = null
                let globalTagsElem = document.getElementById('globalTags')
                if (globalTagsElem) {
                    activeElement = globalTagsElem.getElementsByClassName('active')[0]
                }
                if (activeElement) {
                    let container = activeElement.parentNode
                    let offset = activeElement.offsetTop // get element's y position in list
                    offset -= container.scrollTop // subtract list's scroll position to get relative position

                    let delta_to_bottom = offset + activeElement.clientHeight - container.clientHeight // calculate distance from active element's bottom to the list's bottom
                    if (delta_to_bottom > 0) {
                        // if distance is greater than 0, it must be out of view
                        container.scrollTop += e.key !== 'ArrowUp' ? delta_to_bottom + 25 : delta_to_bottom // add delta to view
                    }

                    // same goes for top
                    let delta_to_top = offset + activeElement.clientHeight - container.clientHeight
                    if (delta_to_top < 0) {
                        container.scrollTop += e.key === 'ArrowUp' ? delta_to_top - 25 : delta_to_top
                    }
                }
            } catch (err) {}
        }
    }

    searchGlobalTags(e) {
        let { globalTags } = this.props
        if (e.target.value.length <= 200) {
            this.setState({
                showDrop: true,
                size: e.target.value.length + 3 < 8 ? 10 : e.target.value.length + 5,
                inputData: e.target.value,
                activeTag: e.target.value,
            })
            let currentListIds = ''
            if (this.state.globalTagsItems) {
                for (let elem of this.state.globalTagsItems) {
                    currentListIds = !currentListIds.length ? '' + elem.id : currentListIds + ',' + elem.id
                }
            }
            store.dispatch({
                type: GET_GLOBAL_LIST_TAGS,
                payload: {
                    limit: globalTags.limit,
                    offset: 0,
                    searchName: e.target.value,
                    excludeIds: currentListIds,
                },
            })
        }
    }

    handleScroll = (obj) => {
        let { globalTags } = this.props
        let element = document.getElementById('globalTagsElement')
        let currentListIds = ''
        if (this.state.globalTagsItems) {
            for (let elem of this.state.globalTagsItems) {
                currentListIds = !currentListIds.length ? '' + elem.id : currentListIds + ',' + elem.id
            }
        }
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if (globalTags.total > globalTags.offset + globalTags.limit) {
                store.dispatch({
                    type: GET_GLOBAL_LIST_TAGS,
                    payload: {
                        limit: globalTags.limit,
                        offset: globalTags.offset + globalTags.limit,
                        searchName: this.state.inputData,
                        excludeIds: currentListIds,
                    },
                })
            }
        }
    }

    handleDeleteTag(tag) {
        let globalTagsItems = []
        for (let item of this.state.globalTagsItems) {
            if (tag.id !== item.id) {
                globalTagsItems.push(item)
            }
        }
        this.setState({
            globalTagsItems: globalTagsItems,
        })
        let currentListIds = []
        for (let elem of globalTagsItems) {
            currentListIds.push(elem.id)
        }
        this.props.handleGlobalTags(currentListIds)
    }

    focusInputTag = () => {
        document.getElementById('global-tags-input').focus()
    }

    resetActive = (tag) => {
        this.setState({
            activeTag: tag.name,
            size: tag.name.length + 3 < 8 ? 10 : tag.name.length + 5,
        })
    }

    render() {
        const { globalTagsItems, showDrop, inputData, size, activeTag } = this.state
        const { globalTags } = this.props

        let currentListIds = []
        for (let elem of globalTagsItems) {
            currentListIds.push(elem.id)
        }

        return (
            <Fragment>
                <div className="global-tags-block global-create-tags-block">
                    <p className="content-group-name">Tags</p>
                    <div className="search-term-wrapper" onClick={this.focusInputTag}>
                        {globalTagsItems &&
                            globalTagsItems.map((tag) => {
                                return (
                                    <span key={tag.id} className="search-term">
                                        {tag.name}
                                        <Logo
                                            onClick={() => this.handleDeleteTag(tag)}
                                            className="search-term__close-icon"
                                        />
                                    </span>
                                )
                            })}
                        <input
                            id="global-tags-input"
                            size={size}
                            value={activeTag}
                            type="text"
                            className="global-tags-input"
                            placeholder="Add tags"
                            autoComplete="off"
                            onChange={this.searchGlobalTags}
                            onKeyDown={this.onKeyDown}
                        />
                    </div>
                </div>
                <div className="dropdown-block position-relative">
                    {showDrop && globalTags && globalTags.items && globalTags.items.length > 0 && (
                        <div
                            id="dropdown"
                            className="dropdown-tags faq-dropdown-tag-create no-top"
                            onScroll={this.handleScroll}
                        >
                            <div id="globalTagsElement" className="dropdown-tags-menu">
                                {globalTags.items.map((tag, index) => {
                                    if (!currentListIds.includes(tag.id)) {
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    activeTag === tag.name
                                                        ? 'dropdown-tags-item active'
                                                        : 'dropdown-tags-item'
                                                }
                                                onClick={() => this.addTag(tag)}
                                                onMouseEnter={(e) => this.resetActive(tag)}
                                            >
                                                {tag.name}
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        )
    }
}

export default connect((state) => {
    return {
        globalTags: state.globalTags,
    }
})(TagsAddPopup)
