import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import universityDefaultAvatar from '../../../../../assets/img/university_default_avatar.jpeg'

class SlideApp extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
    }

    wrap = (width, padding) => {
        let self = d3.select(this)
        let textLength = self.node().getComputedTextLength()
        let text = self.text()
        while (textLength > width - 2 * padding && text.length > 0) {
            text = text.slice(0, -1)
            self.text(text + '...')
            textLength = self.node().getComputedTextLength()
        }
    }

    render() {
        const { avatar, name } = this.props

        return (
            <div className="preview-slide slide-app">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={314.079}
                    height={514}
                    viewBox="0 0 314.079 514"
                    {...this.props}
                >
                    <g data-name="Group 3601">
                        <g data-name="Group 3187">
                            <g data-name="Group 3186">
                                <g data-name="Rectangle 1920" fill="#fff" stroke="#dfdfdf">
                                    <path stroke="none" d="M0 0h300v514H0z" />
                                    <path fill="none" d="M.5.5h299v513H.5z" />
                                </g>
                                <g data-name="Group 3185">
                                    <path data-name="Rectangle 1921" fill="#262e45" d="M0 0h300v40H0z" />
                                    <text
                                        transform="translate(90 10)"
                                        fill="#fff"
                                        fontSize={16}
                                        fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                    >
                                        <tspan x={3.24} y={16}>
                                            {'Career Reference'}
                                        </tspan>
                                    </text>
                                </g>
                                <g data-name="Group 594" transform="translate(13 -27)">
                                    <defs>
                                        <pattern
                                            id="image"
                                            x="0%"
                                            y="0%"
                                            height="100%"
                                            width="100%"
                                            viewBox="0 0 512 512"
                                        >
                                            <image
                                                x="0%"
                                                y="0%"
                                                width="512"
                                                height="512"
                                                preserveAspectRatio="xMaxYMax slice"
                                                xlinkHref={avatar || universityDefaultAvatar}
                                            />
                                        </pattern>
                                    </defs>
                                    <circle
                                        id="sd"
                                        className="medium"
                                        cx={40}
                                        cy={40}
                                        r={40}
                                        transform="translate(97 96)"
                                        fill="url(#image)"
                                    />
                                    <text
                                        data-name="University Name"
                                        transform="translate(39 184)"
                                        fill="#262e45"
                                        fontSize={20}
                                        fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                    >
                                        <tspan x={100} y={20} textAnchor="middle">
                                            {name}
                                        </tspan>
                                    </text>
                                    <text
                                        data-name="Verified by The Ambassador Platform"
                                        transform="translate(39 210)"
                                        fill="#262e45"
                                        fontSize={14}
                                        fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                        opacity={0.6}
                                    >
                                        <tspan x={0} y={14}>
                                            {'Verified by The Ambassador Platform'}
                                        </tspan>
                                    </text>
                                    <text
                                        data-name="Use your Career Reference on your CV and LinkedIn profile"
                                        transform="translate(21 391)"
                                        fill="#262e45"
                                        fontSize={14}
                                        fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                        opacity={0.6}
                                    >
                                        <tspan x={6.702} y={14}>
                                            {'Use your Career Reference on your CV '}
                                        </tspan>
                                        <tspan x={57.578} y={32}>
                                            {'and LinkedIn profile'}
                                        </tspan>
                                    </text>
                                    <g data-name="Group 509">
                                        <path
                                            data-name="Line 54"
                                            fill="none"
                                            stroke="rgba(38,46,69,0.3)"
                                            strokeWidth={0.5}
                                            d="M3 305.5h267.912"
                                        />
                                        <path
                                            data-name="Line 55"
                                            fill="none"
                                            stroke="rgba(38,46,69,0.3)"
                                            strokeWidth={0.5}
                                            d="M3 359.5h267.912"
                                        />
                                        <text
                                            data-name="View Career Reference"
                                            transform="translate(3 323)"
                                            fill="#262e45"
                                            fontSize={14}
                                            fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            opacity={0.9}
                                        >
                                            <tspan x={0} y={14}>
                                                {'View Career Reference'}
                                            </tspan>
                                        </text>
                                        <text
                                            data-name="How do I earn Career Points?"
                                            transform="translate(3 269)"
                                            fill="#262e45"
                                            fontSize={14}
                                            fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                            opacity={0.9}
                                        >
                                            <tspan x={0} y={14}>
                                                {'How do I earn Career Points?'}
                                            </tspan>
                                        </text>
                                        <path
                                            d="M264.279 278l-4.465-4.461a.902.902 0 111.275-1.275l5.1 5.1a.9.9 0 010 1.274l-5.1 5.1a.902.902 0 11-1.275-1.277l4.465-4.461z"
                                            fill="#262e45"
                                            opacity={0.4}
                                        />
                                        <path
                                            data-name="chevron-down"
                                            d="M264.279 332l-4.465-4.461a.902.902 0 111.275-1.275l5.1 5.1a.9.9 0 010 1.274l-5.1 5.1a.902.902 0 11-1.275-1.277l4.465-4.461z"
                                            fill="#262e45"
                                            opacity={0.4}
                                        />
                                    </g>
                                    <g data-name="Group 583" transform="translate(67.726 468.294)">
                                        <rect data-name="Rectangle 191" width={140} height={40} rx={5} fill="#ff5100" />
                                        <text
                                            data-name="Email as PDF"
                                            transform="translate(14 9.582)"
                                            fill="#fff"
                                            fontSize={14}
                                            fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                            fontWeight={600}
                                        >
                                            <tspan x={18.042} y={14}>
                                                {'Email as PDF'}
                                            </tspan>
                                        </text>
                                    </g>
                                </g>
                            </g>
                        </g>
                        <path
                            data-name="Path 64"
                            d="M211.764 95.658a45.278 45.278 0 0014.985-20.333c.663-1.799 3.448-1.078 2.906.813a47.359 47.359 0 01-5.61 12.475c10.723-7.68 23.831-11.978 36.903-13.244 16.984-1.643 37.786 1.91 51.193 13.19 1.967 1.654-.262 5.433-2.658 4.423-7.692-3.236-14.82-7.038-22.998-9.084a79.831 79.831 0 00-24.725-2.306c-13.822.922-26.456 6.001-38.263 12.81a23.628 23.628 0 0110.85 4.437c2.92 2.193.172 7.07-2.987 4.972-5.391-3.59-10.925-5.227-17.432-4.201-2.034.314-4.186-2.382-2.164-3.952z"
                            fill="rgba(34,34,34,0.4)"
                        />
                    </g>
                </svg>
            </div>
        )
    }
}

export default connect((state) => {
    return {}
})(SlideApp)
