import { PLS_SIGN_UP } from '../../actions/pls-sign-up/pls-sign-up-types'

const initialState = {
    successSignUpModal: {
        open: false,
    },
    onboardingPlsModal: {
        open: false,
    },
}

export default (state = initialState, action) => {
    const { type } = action

    switch (type) {
        case PLS_SIGN_UP.OPEN_SUCCESS_SIGN_UP_MODAL: {
            return {
                ...state,
                successSignUpModal: {
                    open: true,
                },
            }
        }

        case PLS_SIGN_UP.CLOSE_SUCCESS_SIGN_UP_MODAL: {
            return {
                ...state,
                successSignUpModal: initialState.successSignUpModal,
            }
        }

        case PLS_SIGN_UP.OPEN_ONBOARDING_PLS_MODAL: {
            return {
                ...state,
                onboardingPlsModal: {
                    open: true,
                },
            }
        }

        case PLS_SIGN_UP.CLOSE_ONBOARDING_PLS_MODAL: {
            return {
                ...state,
                onboardingPlsModal: initialState.successSignUpModal,
            }
        }
        default: {
            return state
        }
    }
}
