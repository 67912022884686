import React, { PureComponent } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class DonutChart extends PureComponent {
    prepareOptions() {
        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                height: this.props.height,
            },
            title: {
                text: this.props.centerText,
                align: 'center',
                verticalAlign: 'middle',
                style: {
                    lineHeight: 30,
                },
            },
            colors: this.props.colors,
            tooltip: {
                headerFormat: '',
                pointFormat:
                    '<table><tr><td style="text-align: center; font-size: 12px; color: #fff";>{point.name}</td></tr><tr><td style="text-align: center; font-size: 14px; color: #fff"; font-weight: bold"><b>{point.percentage:.1f} %</b></td></tr></table>',
                backgroundColor: '#646a7a',
                useHTML: true,
                shadow: false,
                borderColor: 'none',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: !this.props.dataLabelFormat,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: !!this.props.dataLabelFormat,
                        format: this.props.dataLabelFormat,
                    },
                },
            },
            series: [
                {
                    colorByPoint: true,
                    innerSize: this.props.innerRadius || '93%',
                    data: this.props.data,
                },
            ],
        }
    }

    render() {
        return <HighchartsReact highcharts={Highcharts} options={this.prepareOptions()} />
    }
}

export default DonutChart
