import React from 'react'
import cn from 'classnames'

import trashRed from '../../../../assets/svg/icons/trashRed.svg'
import trash from '../../../../assets/svg/icons/trash.svg'

const DeleteQuestionAnswerButton = ({ type = 'grey', openDeleteModal }) => {
    return (
        <button
            type="button"
            className={cn(`
        question__buttons__button 
        ${type === 'red' && 'question__buttons__button--red'}`)}
            onClick={openDeleteModal}
            data-automation-id={`delete-${type === 'grey' ? 'answer' : 'question'}`}
        >
            <img className="question__buttons__button__icon" src={type === 'red' ? trashRed : trash} alt="Delete" />
            {type === 'grey' ? 'Delete' : 'Delete question'}
        </button>
    )
}

export default DeleteQuestionAnswerButton
