import store from '../../../redux/store'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import NotificationToggles from './components/NotificationToggles'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import {
    GET_NOTIFICATIONS_LIST,
    TOGGLE_NOTIFICATION,
    DISABLE_TOGGLE,
    RECIEVE_NOTIFICATIONS,
} from '../../../redux/actions/settings/notifications-control'
import { SET_UNIVERSITY_GROUP } from '../../../redux/actions/account/accountActions'

class NotificationsControl extends PureComponent {
    componentDidMount() {
        const { account } = this.props
        const isGroupAdmin = account.account_info.isGroupAdmin

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })

        store.dispatch({
            type: GET_NOTIFICATIONS_LIST,
        })

        if (isGroupAdmin) {
            store.dispatch({
                type: SET_UNIVERSITY_GROUP,
                payload: {
                    university_id: null,
                },
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.notificationControl.items.some((i) => i.is_active) !==
            prevProps.notificationControl.items.some((i) => i.is_active)
        ) {
            store.dispatch({
                type: RECIEVE_NOTIFICATIONS,
                payload: this.props.notificationControl.items.some((i) => i.is_active),
            })
        }
    }

    handleToggleNotification = (event, id) => {
        store.dispatch({
            type: DISABLE_TOGGLE,
            payload: true,
        })

        store.dispatch({
            type: TOGGLE_NOTIFICATION,
            payload: {
                requestData: { notificationsIds: [id] },
                notificationToggle: {
                    name: event.currentTarget.name,
                    checked: event.target.checked,
                },
            },
        })
    }

    handleReceivingOfNotifications = (event) => {
        const { items } = this.props.notificationControl

        store.dispatch({
            type: DISABLE_TOGGLE,
            payload: true,
        })

        store.dispatch({
            type: RECIEVE_NOTIFICATIONS,
            payload: event.target.checked,
        })

        if (event.target.checked) {
            store.dispatch({
                type: TOGGLE_NOTIFICATION,
                payload: {
                    requestData: { all: 'on' },
                    notificationToggle: items.map((obj) => {
                        return { ...obj, is_active: true }
                    }),
                },
            })
        } else {
            store.dispatch({
                type: TOGGLE_NOTIFICATION,
                payload: {
                    requestData: { all: 'off' },
                    notificationToggle: items.map((obj) => {
                        return { ...obj, is_active: false }
                    }),
                },
            })
        }
    }

    render() {
        const { account, notificationControl } = this.props
        const { items, disableToggle, receiveNotifications } = notificationControl
        return (
            <div id="page-body" className="page-body js-index">
                <section className="content settings">
                    <div className="description-container">
                        <h3 className="settings-title">Set notification preferences</h3>

                        <div className="settings-grid has-tutorial">
                            <div className="column">
                                <div className="tutorial-intro">
                                    <p>
                                        As part of our service there are a number of notifications you will receive as
                                        an Admin. We recognise that not all notifications will be relevant for everyone,
                                        so we have some options settings for you to set your preferences.
                                    </p>
                                    <hr className="settings-hr"></hr>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div action="" className="notification-control-form">
                        <NotificationToggles
                            account={account}
                            notifications={items}
                            disableToggle={disableToggle}
                            handleToggleNotification={this.handleToggleNotification}
                            handleReceivingOfNotifications={this.handleReceivingOfNotifications}
                            receiveNotifications={receiveNotifications}
                        />
                    </div>
                </section>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        notificationControl: state.notificationsControl,
    }
})(NotificationsControl)
