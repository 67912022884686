import Modal from 'antd/es/modal'
import cn from 'classnames'
import React from 'react'
import { hotjar } from 'react-hotjar'
import { connect } from 'react-redux'

import { ChatType } from './common/constants'
import ActionPopup from './components/popups/ActionPopup.jsx'
import Router from './containers/_app/Router.jsx'
import Scripts from './containers/_layout/scripts'
import NotificationModalWrapper from './containers/notification-modal-wrapper/notification-modal-wrapper'
import { ACCOUNT_INFO_REQUESTED, SET_UNIVERSITY_GROUP } from './redux/actions/account/accountActions'
import { CHATS_INFO_SEARCH, FLAG_DIALOG } from './redux/actions/chat/messagingActions'
import { CLOSE_ACTION_MODAL, NEW_NOTIFICATION, REMOVE_NOTIFICATION } from './redux/actions/components/componentsActions'
import { CLEAR_TWO_TIER_MENU } from './redux/actions/content/componentsActions'
import store from './redux/store'
import { pusherGlobal } from './system/services/PusherWrapper'

import { withRouter } from 'react-router-dom'
import { getCommonRoutePath } from './helpers/helpers'
import { ACCOUNT } from './redux/actions/account/account-types'

import Header from '../app/containers/_layout/header'
import Navigation from '../app/containers/_layout/nav'

import '../assets/js/custom/common/common'
import '../assets/styles/custom/style.less'
import '../assets/styles/custom/style.sass'
import '../assets/styles/libs/libs.css'

class Application extends React.Component {
    constructor() {
        super()
        this.state = {
            loading: true,
        }

        try {
            if (window.localStorage.getItem('Account')) {
                const search = window.location.search

                const isSpecificUni = search.includes('universityId=') && search.split('universityId=')[1]
                if (window.location.href.lastIndexOf('/account/') === -1) {
                    store.dispatch({
                        type: ACCOUNT_INFO_REQUESTED,
                        payload: {
                            university_id: isSpecificUni ? +search.split('universityId=')[1].split('&')[0] : null,
                        },
                    })
                    if (isSpecificUni) {
                        store.dispatch({
                            type: SET_UNIVERSITY_GROUP,
                            payload: {
                                university_id: isSpecificUni,
                            },
                        })
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { account, history } = this.props
        if (account.account_info && prevProps.account.account_info !== account.account_info) {
            /* Hubspot start */
            const onHubspotLoginSuccess = (hubspotToken) => {
                if (window.HubSpotConversations) {
                    window.hsConversationsSettings = {
                        loadImmediately: false,
                        identificationEmail: account.account_info.email,
                        identificationToken: hubspotToken || '',
                    }
                    window.HubSpotConversations.clear({ resetWidget: true })
                    window.HubSpotConversations.widget.refresh()
                } else {
                    window.hsConversationsSettings = {
                        loadImmediately: false,
                        identificationEmail: account.account_info.email,
                        identificationToken: hubspotToken || '',
                    }
                    window.hsConversationsOnReady = [() => window.HubSpotConversations.widget.load()]
                    const script = document.createElement('script')
                    script.src = '//js-na1.hs-scripts.com/2395277.js'
                    document.body.append(script)
                }
            }
            store.dispatch({
                type: ACCOUNT.HUBSPOT_LOGIN,
                meta: {
                    onHubspotLoginSuccess,
                },
            })
            /* Hubspot end */

            const university = history.location.pathname.split('/')[1]
                ? history.location.pathname.split('/')[1].split('?')[0]
                : ''

            if (!Number.isInteger(+university)) {
                history.push({
                    pathname: getCommonRoutePath() + history.location.pathname,
                    search: history.location.search,
                })
            }
        }
    }

    componentDidMount() {
        const { account, history } = this.props

        window.addEventListener('load', () => {
            setTimeout(() => this.setState({ loading: false }), 500)
        })

        // FIXME: what the magic number 2_707_576 means? better to leave at least a comment
        hotjar.initialize(2_707_576, 6)
        if (account.account_info && account.account_info.university_id) {
            this.channel = pusherGlobal.subscribe('dashboard')

            this.channel.bind('register:prospect:' + account.account_info.university_id, function (data) {
                const uniqueIndex = Math.random().toString(36).slice(2, 8)

                store.dispatch({
                    type: NEW_NOTIFICATION,
                    payload: {
                        notification: {
                            text: 'A new ambassador has signed up',
                            link: 'sa-list',
                            className: 'notification-orange',
                            index: uniqueIndex,
                            key: 'new_profile_created',
                        },
                    },
                })

                setTimeout(function () {
                    store.dispatch({
                        type: REMOVE_NOTIFICATION,
                        payload: {
                            notification: uniqueIndex,
                        },
                    })
                }, 10_000)
            })

            this.channel.bind('messages:reported:' + account.account_info.university_id, function (data) {
                const uniqueIndex = Math.random().toString(36).slice(2, 8)
                store.dispatch({
                    type: NEW_NOTIFICATION,
                    payload: {
                        notification: {
                            text: 'A chat has been flagged',
                            link: 'chat/messaging?activeDialog=' + data.dialog_id,
                            className: 'notification-red',
                            index: uniqueIndex,
                            key: 'flagged_conversation',
                        },
                    },
                })

                store.dispatch({
                    type: FLAG_DIALOG,
                    payload: {
                        dialog_id: data.dialog_id,
                    },
                })

                store.dispatch({
                    type: CHATS_INFO_SEARCH,
                    payload: {
                        type_chat: ChatType.CHAT,
                    },
                })

                setTimeout(function () {
                    store.dispatch({
                        type: REMOVE_NOTIFICATION,
                        payload: {
                            notification: uniqueIndex,
                        },
                    })
                }, 10_000)
            })

            this.channel.bind('keyword:new:' + account.account_info.university_id, function (data) {
                const uniqueIndex = Math.random().toString(36).slice(2, 8)

                store.dispatch({
                    type: NEW_NOTIFICATION,
                    payload: {
                        notification: {
                            text: 'Keyword has been detected',
                            link: 'chat/messaging?activeDialog=' + data.dialog_id,
                            className: 'notification-red',
                            index: uniqueIndex,
                            key: 'keyword_detected_notification',
                        },
                    },
                })

                setTimeout(function () {
                    store.dispatch({
                        type: REMOVE_NOTIFICATION,
                        payload: {
                            notification: uniqueIndex,
                        },
                    })
                }, 10_000)
            })

            this.channel.bind('faq:answer:' + account.account_info.university_id, function (data) {
                const uniqueIndex = Math.random().toString(36).slice(2, 8)

                store.dispatch({
                    type: NEW_NOTIFICATION,
                    payload: {
                        notification: {
                            text: 'An FAQ has received a new answer',
                            link: 'faq/new-answers',
                            className: 'notification-grey',
                            index: uniqueIndex,
                            key: 'new_answer_submitted',
                        },
                    },
                })

                setTimeout(function () {
                    store.dispatch({
                        type: REMOVE_NOTIFICATION,
                        payload: {
                            notification: uniqueIndex,
                        },
                    })
                }, 10_000)
            })

            this.channel.bind('invite:new:' + account.account_info.id, function (data) {
                const uniqueIndex = Math.random().toString(36).slice(2, 8)
                store.dispatch({
                    type: NEW_NOTIFICATION,
                    payload: {
                        notification: {
                            text: 'You have been invited to chat with ' + data.prospect.name,
                            link: 'chat/messaging?activeDialog=' + data.dialog_id,
                            className: 'notification-grey',
                            index: uniqueIndex,
                        },
                    },
                })

                setTimeout(function () {
                    store.dispatch({
                        type: REMOVE_NOTIFICATION,
                        payload: {
                            notification: uniqueIndex,
                        },
                    })
                }, 10_000)
            })

            const university = history.location.pathname.split('/')[1]
                ? history.location.pathname.split('/')[1].split('?')[0]
                : ''

            if (!Number.isInteger(+university)) {
                history.push({
                    pathname: getCommonRoutePath() + history.location.pathname,
                    search: history.location.search,
                })
            }
        } else {
            window.hsConversationsSettings = {
                loadImmediately: false,
                identificationEmail: '',
                identificationToken: '',
            }
            window.hsConversationsOnReady = [() => window.HubSpotConversations.widget.load()]
            const script = document.createElement('script')
            script.src = '//js-na1.hs-scripts.com/2395277.js'
            document.body.append(script)

            const params = new URLSearchParams(history.location.search)
            const universityId = params.get('universityId')

            if (universityId && account.account_info?.isGroupAdmin) {
                store.dispatch({
                    type: SET_UNIVERSITY_GROUP,
                    payload: {
                        university_id: +universityId,
                    },
                })
            }
        }
    }

    handleClosePopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
    }

    componentWillUnmount() {
        if (this.channel) {
            this.channel.unbind()
        }
        store.dispatch({
            type: CLEAR_TWO_TIER_MENU,
        })
    }

    render() {
        const { loading } = this.state
        const { account, actionPopup, history } = this.props
        const isSetupWizard = history.location.pathname.includes('/setup/')
        const isPls = history.location.pathname.includes('/pls-onboarding')
        const isIdpActivation = account.showIdpActivation
        const isMaintenanceMode = account.isMaintenanceMode

        if (loading || account.refreshTokenIsLoading) {
            return (
                <div className="load loaded">
                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            )
        }

        return (
            <div
                className={
                    account && account.account_info && account.account_info.isGroupAdmin ? 'super-admin-dashboard' : ''
                }
            >
                {account &&
                account.account_info &&
                !isSetupWizard &&
                !isPls &&
                !isIdpActivation &&
                !isMaintenanceMode ? (
                    <div
                        id="page-wrapper"
                        className={cn('page-wrapper', {
                            'unregistered-page': !account?.account_info,
                            'group-accont': account?.account_info?.isGroupAdmin,
                        })}
                    >
                        <Navigation />
                        <Header />
                        <Router />
                    </div>
                ) : (
                    <Router />
                )}

                <Scripts />

                {account.account_info && (
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href={
                            'https://cdn.theaccessplatform.com/custom-styles/dash/' +
                            (account.account_info.isGroupAdmin && account.account_info.universityGroup
                                ? 'group_' + account.account_info.universityGroup.id
                                : account.account_info.university_id) +
                            '.css'
                        }
                    />
                )}

                <Modal
                    open={actionPopup.isOpened}
                    bodyStyle={{ padding: 0 }}
                    width="50rem"
                    zIndex={9999}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={actionPopup.hasOutsideClickClose}
                    destroyOnClose
                    onCancel={this.handleClosePopup}
                    className={actionPopup.className ? actionPopup.className : ''}
                >
                    <ActionPopup title={actionPopup.title} body={actionPopup.body} handleClose={this.handleClosePopup}>
                        {actionPopup.children}
                    </ActionPopup>
                </Modal>

                <NotificationModalWrapper />
            </div>
        )
    }
}

export default connect((state) => {
    return {
        actionPopup: state.actionPopup,
        account: state.account,
    }
})(withRouter(Application))
