import { Form, Input, Button } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import OpenIdFormModel from './models/open-id-form-model'
import { checkSsoEncryption } from '../../../../common/utils/user-account-helpers'
import { AdditionalParameters } from './additional-sso-parameters'

const formStyle = {
    display: 'block',
    width: '100%',
    marginTop: '20px',
}

const OpenIdForm = ({ providerId, data, onSave, onReset }) => {
    const [form] = Form.useForm()
    const [isCustomFieslsTouched, setIsCustomFieslsTouched] = useState(false)

    const initialValues = useMemo(() => OpenIdFormModel(data), [data])
    useEffect(() => {
        form.setFieldsValue(OpenIdFormModel(data))
    }, [providerId, form, data])

    const handleSubmit = useCallback(
        (values) => {
            const data = {
                providerName: values.providerName.trim(),
                clientId: values.clientId.trim(),
                clientSecret: values.clientSecret.trim(),
                iss: values.iss.trim(),
                /* authUrl: values.authUrl.trim(),
            tokenUrl: values.tokenUrl.trim(),
            userInfoUrl: values.userInfoUrl.trim(),
            jwksUri: values.jwksUri.trim(), */
                email: values.email.trim(),
                givenName: values.givenName.trim(),
                familyName: values.familyName.trim(),
                ssoCustomData: null,
            }

            const ssoCustomData = []
            if (values.custom_1_name && values.custom_1_reference)
                ssoCustomData.push({
                    name: values.custom_1_name.trim(),
                    reference: values.custom_1_reference,
                    unique: values.custom_1_unique,
                })
            if (values.custom_2_name && values.custom_2_reference)
                ssoCustomData.push({
                    name: values.custom_2_name.trim(),
                    reference: values.custom_2_reference,
                    unique: values.custom_2_unique,
                })
            if (values.custom_3_name && values.custom_3_reference)
                ssoCustomData.push({
                    name: values.custom_3_name.trim(),
                    reference: values.custom_3_reference,
                    unique: values.custom_3_unique,
                })
            if (ssoCustomData.length > 0) data.ssoCustomData = ssoCustomData

            onSave(data)
        },
        [onSave]
    )

    const isSubmitDisabled = () => {
        const { isFieldsTouched, getFieldsError } = form
        return isCustomFieslsTouched
            ? false
            : !isFieldsTouched() || getFieldsError().some(({ errors }) => errors.length)
    }

    return (
        <Form style={formStyle} form={form} layout="vertical" initialValues={initialValues} onFinish={handleSubmit}>
            <Form.Item
                label={'Organisation name'}
                name="providerName"
                className="sso-field-item"
                rules={[
                    {
                        required: true,
                        message: 'Field is required',
                    },
                ]}
            >
                <Input placeholder="Organisation Name" />
            </Form.Item>
            <Form.Item
                label={'Client ID'}
                name="clientId"
                className="sso-field-item"
                rules={[
                    {
                        required: true,
                        message: 'Field is required',
                    },
                ]}
            >
                <Input placeholder="Client ID" />
            </Form.Item>
            <Form.Item
                label={'Client secret'}
                name="clientSecret"
                className="sso-field-item"
                rules={[
                    {
                        required: true,
                        message: 'Field is required',
                    },
                ]}
            >
                <Input placeholder="Client secret" />
            </Form.Item>
            <Form.Item
                label="Issuer: OIDC endpoint"
                name="iss"
                className="sso-field-item"
                rules={[
                    {
                        required: true,
                        message: 'Field is required',
                    },
                ]}
            >
                <Input placeholder="Issuer url" />
            </Form.Item>
            <Form.Item
                label="This is attribute's reference to email and by default is 'email'. You can add yours here or create new in your provider's dashboard"
                name="email"
                className="sso-field-item"
            >
                <Input placeholder="email" />
            </Form.Item>
            <Form.Item
                label="This is attribute's reference to email and by default is 'given_name'. You can add yours here or create new in your provider's dashboard"
                name="givenName"
                className="sso-field-item"
            >
                <Input placeholder="given_name" />
            </Form.Item>
            <Form.Item
                label="This is attribute's reference to email and by default is 'family_name'. You can add yours here or create new in your provider's dashboard"
                name="familyName"
                className="sso-field-item"
            >
                <Input placeholder="family_name" />
            </Form.Item>
            {checkSsoEncryption && (
                <AdditionalParameters form={form} initialValues={initialValues} setTouched={setIsCustomFieslsTouched} />
            )}
            <div className="sso-field-row">
                <Form.Item>
                    <Button
                        type="primary"
                        danger
                        className="el-btn"
                        style={{ height: '40px', marginRight: '20px' }}
                        onClick={onReset}
                        disabled={!data}
                    >
                        Deactivate
                    </Button>
                </Form.Item>
                <Form.Item shouldUpdate>
                    {({ isFieldsTouched, getFieldsError }) => {
                        return (
                            <Button
                                htmlType="submit"
                                className="el-btn"
                                style={{ height: '40px' }}
                                disabled={isSubmitDisabled()}
                            >
                                Activate SSO
                            </Button>
                        )
                    }}
                </Form.Item>
            </div>
        </Form>
    )
}

export default OpenIdForm
