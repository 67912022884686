export const USERS = {
    limit: 10,
    offset: 0,
    total: 0,
    items: [],
    searchItems: [],
    partner_groups: [],
    disableToggle: false,
    usersMaxLimitReached: false,
    usersMaxLimitReachedText: '',
    subjects: [],
    industry: [],
    courseTypes: [],
    yearOfStudies: [],
    studentTypes: [],
    staffTypes: [],
    usersTotals: {
        before_filter: undefined,
        after_filter: undefined,
    },
}

export const HOME_CHART = {
    items: [],
    currentChartOption: 'prospectAccounts',
    isLoading: true,
}

export const HOME_DATA = {
    conversationsActivity: {
        badwordDialogs: 0,
        flaggedDialogs: 0,
        prospectFeedbacks: {
            bad: 0,
            good: 0,
            normal: 0,
        },
        totalFeebacksNumber: 0,
    },
    inactiveAmbassadors: [],
    recentPosts: [],
    recentFaqAnswers: [],
    postsTotal: 0,
    faqsTotal: 0,
    wizardSetupData: {
        embedTapPage: false,
        customizeBranding: false,
        inviteAmbassadors: false,
        privacyPolicy: false,
    },
    wizardSetupPassed: false,
    isLoading: true,
}

export const CONTENT_REQUEST_MODAL = {
    isOpened: false,
}

export const CONTENT_REQUEST_SENT_MODAL = {
    isOpened: false,
}

export const CHANGE_EMAIL_MODAL = {
    isOpened: false,
}

export const CHANGE_PASSWORD_MODAL = {
    isOpened: false,
}

export const TUTORIAL_MODAL = {
    isOpened: false,
}

export const SHARE_POST_MODAL = {
    isOpened: false,
    isShared: false,
    post: null,
}

export const SUCCESS_REGISTRATION_MODAL = {
    isOpened: false,
}

export const CONFIRM_DELETE_MODAL = {
    isOpened: false,
    postId: 0,
    type: '',
}

export const CONFIRM_BAN_MODAL = {
    isOpened: false,
    userId: 0,
}

export const TWO_TIER_MENU = {
    activeSubmenu: JSON.parse(window.localStorage.getItem('ActiveMenu')) || 'home',
}

export const PASSWORD_RECOVERY = {
    recoveryStep: 1,
    userId: 0,
}

export const SUCCESS_LETTER_SEND_MODAL = {
    isOpened: false,
}

export const SUCCESS_RECOVERY_MODAL = {
    isOpened: false,
}

export const UNIVERSITY_NAME_CHANGED_MODAL = {
    isOpened: false,
}

export const SHARED_POSTS_PREVIEW_MODAL = {
    isOpened: false,
}

export const SHARED_POSTS = {
    limit: 10,
    offset: 0,
    total: 0,
    items: [],
    hasMore: true,
    loading: true,
}

export const ACTION_MODAL = {
    title: null,
    body: null,
    children: null,
    isOpened: false,
    hasOutsideClickClose: true,
}

export const USER_INFO_MODAL = {
    isOpened: false,
    userInfo: null,
}

export const UNIVERSITY_WIDGETS = {
    primary_color: '#000000',
    secondary_color: '#000000',
    typeface: null,
    customCss: null,
    isLoading: true,
}

export const USERS_MESSAGING = {
    items: [],
    total: null,
    isEveryoneSelected: false,
    usersSelected: 0,
    ambassadors: [],
    isLoading: true,
}

export const CONTENT_GROUPS = {
    items: [],
    isLoading: true,
    isFirstRequest: true,
    activeContentGroup: -1,
    sсheduledContentGroup: null,
}

export const CHAT_GROUP_MODAL = {
    isOpened: false,
}

export const CHAT_COPY_MODAL = {
    isOpened: false,
    dialog_id: null,
    private_code: null,
}

export const CHAT_MESSAGES = {
    items: [],
    isFirstRequest: true,
    isFirstMessageLoaded: true,
    isLastMessageLoaded: false,
    isLoading: true,
    dialog_id: null,
    scroll: true,
    isBlockedScroll: false,
    scrollDirection: 'top',
    previousScrollHeight: null,
    oldLoaded: false,
    limit: 50,
    totalReportedMessages: 0,
    pinnedMessages: [],
}

export const DIALOG_MESSAGES = {
    items: [],
    isFirstRequest: true,
    isLoading: true,
    dialog_id: null,
    scroll: true,
    previousScrollHeight: null,
    oldLoaded: false,
    limit: 50,
    isBlockedScroll: false,
}

export const NEW_MESSAGES = {
    isNewMessagesModalOpened: false,
}

export const CHATS = {
    items: [],
    lastItems: [],
    lastReceivedCount: 0,
    lastOffset: 0,
    isLoading: true,
    isFirstRequest: true,
    activeChat: -1,
    chatsType: 1,
    search: null,
    filters: {
        unseenByAdmin: false,
        userId: null,
        userName: null,
        items: [
            { key: 'unanswered', name: 'Unanswered messages', checked: false },
            { key: 'unseen', name: 'New messages', checked: false },
            { key: 'reported', name: 'Flagged messages', checked: false },
            { key: 'keywords', name: 'With keywords', checked: false },
            { key: 'private', name: 'Private chats', checked: false },
            { key: 'public', name: 'Public chats', checked: false },
        ],
        cleanItems: {},
        dates: {
            fromDate: null,
            toDate: null,
        },
        cleanDates: {
            fromDate: null,
            toDate: null,
        },
        feedback: null,
        feedbackItems: [
            { key: 'positive', name: 'Positive', checked: false },
            { key: 'neutral', name: 'Neutral', checked: false },
            { key: 'negative', name: 'Negative', checked: false },
        ],
        conversationStatus: null,
        conversationStatusItems: [
            { key: 'active', name: 'Active', checked: false },
            { key: 'closed', name: 'Closed', checked: false },
        ],
    },
    chatsTranscriptSettings: null,
    blockedData: {
        data: null,
        userId: null,
    },
    reportedData: {
        data: null,
        dialogId: null,
    },
}

export const CURRENT_CHAT = {
    data: {},
    activeChat: -1,
    chatsType: 1,
    search: null,
    filters: null,
}

export const LIVE_EVENTS = {
    items: [],
    lastItems: [],
    lastReceivedCount: 0,
    lastOffset: 0,
    isLoading: true,
    isFirstRequest: true,
    activeEvent: null,
    activeTab: 'participants',
    onlineUsers: [],
}

export const SETTINGS_MESSAGING_MODAL = {
    isOpened: false,
}

export const PROSPECTS = {
    isLoading: true,
    items: [],
}

export const CREATE_QUESTION_MODAL = {
    isOpened: false,
    faqContent: '',
    mode: '',
    faqID: null,
    members: [],
}

export const FAQ = {
    items: [],
    searchItems: [],
    isLoading: true,
}

export const FAQ_ANSWERS = {
    published: {
        isLoading: true,
        items: [],
    },
    unpublished: {
        isLoading: true,
        items: [],
    },
    searchItems: [],
}

export const CURRENT_GLOBAL_TAGS = {
    items: [],
    total: 0,
}

export const USER_GLOBAL_TAGS = {
    items: [],
    total: 0,
    isLoading: true,
}
export const POST_GLOBAL_TAGS = {
    items: [],
    total: 0,
    isLoading: true,
}
export const FAQ_GLOBAL_TAGS = {
    items: [],
    total: 0,
    isLoading: true,
}
export const WIDGET_USERS = {
    items: [],
    widget: null,
    isLoading: true,
}

export const OPTIONAL_QUESTIONS = {
    questions: [],
    isLoading: true,
}

export const NOTIFICATIONS = {
    items: [],
}

export const ADMINS = {
    items: [],
}

export const NOTIFICATIONS_LIST = {
    items: [],
    disableToggle: false,
    receiveNotifications: false,
}

export const FAQ_REPORT = {
    items: [],
    total: 0,
    isLoading: true,
}

export const CONTENT_REPORT = {
    items: [],
    total: 0,
    all_likes: 0,
    all_views: 0,
    isLoading: true,
}

export const COMPANIES = {
    companies: [],
}

export const DIALOGS_TOPICS = {
    items: [],
    total: 0,
    limit: 50,
    offset: 0,
    isLoading: false,
}

export const TIMEZONES = {
    items: [],
    isLoading: false,
}

export const CRM = {
    apiKey: '',
}
