import { InfoCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Upload, message, Form, Popover, Row, Col } from 'antd'
import React, { useMemo } from 'react'

import { ReactComponent as Chevron } from '../../../../../../assets/img/svg/chevron-down.svg'
import { ReactComponent as TimeIcon } from '../../../../../../assets/svg/pie-chart.svg'

const getBase64 = (img, callback) => {
    try {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        // reader.addEventListener('load', () => callback(reader.result.slice(reader.result.indexOf(',') + 1)))
        reader.readAsDataURL(img)
    } catch (error) {
        console.log(error)
    }
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
        message.error('Image must smaller than 2MB')
    }
    return isJpgOrPng && isLt2M
}

const CoverImage = ({ imageState, setImageState, form }) => {
    const handleFileChange = (info) => {
        if (info.file.status === 'uploading') {
            setImageState((prevState) => ({ ...prevState, loading: true }))
            return
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) => {
                setImageState({
                    imageUrl,
                    loading: false,
                })
                form.setFieldsValue({
                    image: imageUrl,
                })
            })
        }
    }

    const uploadButton = (
        <div>
            {imageState.loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )

    const label = useMemo(
        () => (
            <>
                <Row gutter={8}>
                    <Col> Cover image</Col>
                    <Col>
                        <Popover
                            placement="bottomRight"
                            content={
                                <div className="event-cover-image-example">
                                    <div className="event-image-card-example">
                                        <div className="event-image-card">
                                            <span>TAP Feed cover image</span>
                                        </div>
                                    </div>
                                    <div className="event-example-description">
                                        <p className="event-name-example">EVENT NAME</p>
                                        <p className="event-date-example">
                                            <TimeIcon />
                                            <span>02 December, 09:00 AM - 10:00 AM</span>
                                        </p>
                                        <p className="event-example-description-text">Event description</p>
                                        <p className="event-join-example">
                                            <span>Join live event</span>
                                            <Chevron />
                                        </p>
                                    </div>
                                </div>
                            }
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    </Col>
                </Row>
            </>
        ),
        []
    )
    return (
        <>
            <Form.Item label={label}>
                <Upload
                    accept=".png, .jpg, .jpeg"
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader tap-upload-field"
                    customRequest={({ onSuccess }) => onSuccess()}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleFileChange}
                >
                    {imageState.imageUrl ? (
                        <img src={imageState.imageUrl} alt="Cover image" style={{ width: '100%' }} />
                    ) : (
                        uploadButton
                    )}
                </Upload>
                <Form.Item
                    noStyle
                    name="image"
                    rules={[
                        {
                            required: true,
                            message: 'Upload image',
                        },
                    ]}
                >
                    <></>
                </Form.Item>
            </Form.Item>
        </>
    )
}

export default CoverImage
