import { Tabs } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAccountInfoId } from '../../../../../../redux/selectors'

import { ParticipantsTab, ChatDialogTab } from './components'
import { useChat } from './use-chat'
import { TAB_KEY } from './chat-sidebar-constants'
import { setActiveLiveEventSidebarTab } from '../../../../../../redux/actions/live-event/live-events-actions'

const { TabPane } = Tabs

const ChatSidebar = ({ activeEvent }) => {
    const dispatch = useDispatch()

    const accountId = useSelector(getAccountInfoId)

    const { id, speakers } = activeEvent

    const { onTabChange, activeTab } = useChat(activeEvent)

    useEffect(() => {
        dispatch(setActiveLiveEventSidebarTab(TAB_KEY.PARTICIPANTS))
    }, [id])

    const isSpeaker = useMemo(() => {
        return speakers.some((speaker) => speaker.id === accountId)
    }, [accountId, speakers])

    const liveChatTitle = useMemo(() => {
        return <>Live chat</>
    }, [])

    const speakersChatTitle = useMemo(() => {
        return <>Speakers chat</>
    }, [])

    return (
        <div className="event-nav tap-event-nav">
            <Tabs onChange={onTabChange} activeKey={activeTab} centered>
                <TabPane tab={liveChatTitle} key={TAB_KEY.CHAT}>
                    <ChatDialogTab activeEvent={activeEvent} type={TAB_KEY.CHAT} />
                </TabPane>
                {isSpeaker && (
                    <TabPane tab={speakersChatTitle} key={TAB_KEY.SPEAKERS_CHAT}>
                        <ChatDialogTab activeEvent={activeEvent} type={TAB_KEY.SPEAKERS_CHAT} />
                    </TabPane>
                )}
                <TabPane tab="Participants" key={TAB_KEY.PARTICIPANTS}>
                    <ParticipantsTab activeEvent={activeEvent} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ChatSidebar
