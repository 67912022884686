import React, { PureComponent } from 'react'

class RegistrationSuccessModal extends PureComponent {
    render() {
        const { handleClosePopup } = this.props

        return (
            <div className="registration-success">
                <h3 className="modal-title">Email sent</h3>
                <p>
                    Please check your email inbox. If you cannot find an email from us within the next couple of
                    minutes, please check your spam/junk folders.
                </p>
                <button type="submit" className="el-btn registration-success-button" onClick={handleClosePopup}>
                    Close
                </button>
            </div>
        )
    }
}

export default RegistrationSuccessModal
