var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { App } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { getCommonRoutePath } from 'src/app/helpers/helpers';
import { useAppSelector } from 'src/app/hooks/store';
import { getAccountInfo, selectIsSascOrIdp } from 'src/app/redux/selectors';
import { LicensesEnum } from 'src/types/general';
import { licenseModal } from '../modals/LicenseModal';
export var ProtectedNavLink = function (props) {
    var modal = App.useApp().modal;
    var accountInfo = useAppSelector(getAccountInfo);
    var isSascOrIdp = useAppSelector(selectIsSascOrIdp);
    var hasPermission = !isSascOrIdp || ((accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.isSasc) && props.licenseName === LicensesEnum.Reporting);
    var to = !hasPermission ? "".concat(getCommonRoutePath(), "/home") : props.to;
    var handleClick = function (event) {
        if (!hasPermission) {
            modal.warning(licenseModal(props.licenseName));
            event.preventDefault();
        }
        if (props.onClick) {
            props.onClick(event);
        }
    };
    return (React.createElement(NavLink, __assign({}, props, { to: to, onClick: handleClick }), props.children));
};
