import { call, put, select } from 'redux-saga/effects'

import API from '../../../api'
import { ACCOUNT } from '../../actions/account/account-types'
import { SSO } from '../../actions/settings/sso/sso-action-types'
import { CLOSE_ACTION_MODAL, OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'
import { getIsGroupAdmin } from '../../selectors'
import { ACCOUNT_LOGOUT } from '../../actions/account/accountActions'

export function* getSsoProviders() {
    try {
        const response = yield call(() => {
            return API.settings.getSsoProviders()
        })

        yield response.success
            ? put({
                  type: SSO.GET_PROVIDERS_SUCCESS,
                  payload: {
                      items: response.data ? response.data.providers : [],
                      activeProvider: response.data ? response.data.activeProvider : null,
                  },
              })
            : put({
                  type: SSO.GET_PROVIDERS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: SSO.GET_PROVIDERS_FAILED,
        })
    }
}

export function* setUniversitySso(action) {
    try {
        const { payload, meta } = action
        const isGroupAdmin = yield select(getIsGroupAdmin)

        const response = yield call(() => {
            return isGroupAdmin ? API.settings.saveGroupUniversitySso(payload) : API.settings.saveUniversitySso(payload)
        })

        if (response.success) {
            yield put({
                type: SSO.GET_PROVIDERS,
            })
            yield put({
                type: ACCOUNT.UNIVERSITY_SSO_SUCCESS,
                payload: {
                    is_sso_activated: true,
                },
            })
            meta.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* activateToggleUniversitySsoOnly(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getIsGroupAdmin)

        const response = yield call(() => {
            return isGroupAdmin
                ? API.settings.activateToggleUniversityGroupSsoOnly(payload)
                : API.settings.activateToggleUniversitySsoOnly(payload)
        })

        if (response.success) {
            yield put({
                type: CLOSE_ACTION_MODAL,
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Success',
                    className: 'success-popup',
                },
            })
            yield payload.isSsoOnlyActivated
                ? put({
                      type: ACCOUNT_LOGOUT,
                  })
                : put({
                      type: ACCOUNT.UNIVERSITY_SSO_SUCCESS,
                      payload: {
                          is_sso_only_activated: payload.isSsoOnlyActivated,
                      },
                  })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* userToggleSso(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getIsGroupAdmin)

        const response = yield call(() => {
            return isGroupAdmin
                ? API.settings.userToggleUniversityGroupSso(payload)
                : API.settings.userToggleUniversitySso(payload)
        })

        if (response.success) {
            yield put({
                type: CLOSE_ACTION_MODAL,
            })

            yield put({
                type: SSO.GET_PROVIDERS,
            })
            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Success',
                    className: 'success-popup',
                },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* resetUniversitySso(action) {
    try {
        const { meta } = action
        const isGroupAdmin = yield select(getIsGroupAdmin)

        const response = yield call(() => {
            return isGroupAdmin ? API.settings.resetUniversityGroupSso() : API.settings.resetUniversitySso()
        })

        if (response.success) {
            yield put({
                type: SSO.GET_PROVIDERS,
            })
            yield put({
                type: ACCOUNT.UNIVERSITY_SSO_SUCCESS,
                payload: {
                    is_sso_activated: false,
                },
            })
            yield put({
                type: CLOSE_ACTION_MODAL,
            })
            meta.onSuccess()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* uploadMetadata(action) {
    try {
        const { payload, meta } = action

        const response = yield call(() => {
            return API.settings.uploadSsoMetadata(payload)
        })

        if (response.success) {
            meta.onSuccess(response.data)
        }
    } catch (error) {
        console.log(error)
    }
}
