import { all, call, takeEvery, put, select } from 'redux-saga/effects'

import API from '../../../api'
import { LIVE_STREAMS } from '../../actions/live-streams/live-streams-action-types'
import { fetchLiveStreams } from '../../actions/live-streams/live-streams-actions'
import { openNotificationModal } from '../../actions/notification-modal/notification-modal-actions'
import {
    getIsGroupAdmin,
    getCurrentGroupUniversityId,
    getLiveStramsOffset,
    getLiveStramsFilters,
} from '../../selectors'

function* updateLiveStreamSaga({ payload }) {
    const isGroupAdmin = yield select(getIsGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
    const offset = yield select(getLiveStramsOffset)
    const filter = yield select(getLiveStramsFilters)
    const { editType, ...requestData } = payload

    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        requestData.universityId = currentGroupUniversityId || requestData.university_id
    }
    try {
        const response = yield call(() => {
            switch (editType) {
                case 'users': {
                    return API.liveStreams.editStreamMembers(requestData)
                }
                case 'status': {
                    return API.liveStreams.editStreamStatus(requestData)
                }
                case 'speakers': {
                    return API.liveStreams.editStreamSpeakers(requestData)
                }
                case 'archive': {
                    return API.liveStreams.archiveStream(requestData)
                }
                default: {
                    return API.liveStreams.editStream(requestData)
                }
            }
        })

        if (response.success) {
            yield put({
                type: LIVE_STREAMS.UPDATE_SUCCESS,
                payload: {},
            })
            yield put(fetchLiveStreams({ offset, filter }))

            yield put(openNotificationModal(LIVE_STREAMS.UPDATE_SUCCESS, { status: requestData.status }))
        }
    } catch (error) {
        yield put({
            type: LIVE_STREAMS.UPDATE_ERROR,
        })
        console.log(error)
    }
}

function* shareLiveStreamRecordSaga({ payload }) {
    const isGroupAdmin = yield select(getIsGroupAdmin)
    const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)
    const { ...requestData } = payload

    if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
        requestData.universityId = currentGroupUniversityId
    }
    try {
        const response = yield call(() => {
            return API.liveStreams.shareStreamRecord(requestData)
        })

        if (response.success) {
            yield put({
                type: LIVE_STREAMS.SHARE_STREAM_RECORD_SUCCESS,
                payload: {
                    liveStreamId: requestData.liveStreamId,
                },
            })
            yield put(openNotificationModal(LIVE_STREAMS.SHARE_STREAM_RECORD_SUCCESS))
        }
    } catch (error) {
        console.log(error)
    }
}

function* watchLiveStreamUpdateSaga() {
    yield takeEvery(LIVE_STREAMS.UPDATE, updateLiveStreamSaga)
}

function* watchLiveStreamShareRecord() {
    yield takeEvery(LIVE_STREAMS.SHARE_STREAM_RECORD, shareLiveStreamRecordSaga)
}

function* liveStreamUpdateSaga() {
    yield all([call(watchLiveStreamUpdateSaga), call(watchLiveStreamShareRecord)])
}

export default liveStreamUpdateSaga
