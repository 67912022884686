import {
    OPEN_SUCCESS_REGISTRATION_MODAL,
    CLOSE_SUCCESS_REGISTRATION_MODAL,
} from '../../../actions/account/accountActions'

import { SUCCESS_REGISTRATION_MODAL } from '../../../store/initialState'

const initialState = SUCCESS_REGISTRATION_MODAL

export default function (state = initialState, action) {
    const type = action.type

    switch (type) {
        case OPEN_SUCCESS_REGISTRATION_MODAL: {
            let newState = {
                ...state,
                isOpened: true,
            }

            return newState
        }

        case CLOSE_SUCCESS_REGISTRATION_MODAL: {
            let newState = {
                ...state,
                isOpened: false,
            }

            return newState
        }

        default: {
            return state
        }
    }
}
