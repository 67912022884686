const EXTERNAL_SPEAKER_ROLE = 'live-event-speaker'

export default (data) => {
    const internalSpeakers = data.speakers.filter((speaker) => speaker.role_key !== EXTERNAL_SPEAKER_ROLE)
    const externalSpeakers = data.speakers.filter((speaker) => speaker.role_key === EXTERNAL_SPEAKER_ROLE)

    return {
        internalSpeakerIds: internalSpeakers.map(({ id }) => id),
        externalSpeakerEmailsExisting: externalSpeakers.map(({ id, email }) => ({ id, email, existing: true })),
        externalSpeakerEmails: [],
        meta: {
            selectedInternalSpeakers: internalSpeakers,
        },
    }
}
