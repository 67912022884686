import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { Button } from 'antd'

import Header from '../../_layout/header'
import NavigationSetupWizard from '../../_layout/navSetupWizard'

import { SetupTitle } from '../components'
import LogOutButton from '../../../components/log-out-button/log-out-button'

const BookDemoScreen = () => {
    const history = useHistory()

    const handleButtonClick = useCallback(() => {
        window.localStorage.getItem('setupWizardStep') ? history.goBack() : history.push('/setup/organization-details')
    }, [history])

    return (
        <div>
            <Header submenuUnActive={true} />
            <div id="page-wrapper" className="page-wrapper">
                <NavigationSetupWizard />
                <section className="content settings no-submenu home-start-guide setup-wizard">
                    <LogOutButton />
                    <div className="start-guide-wizard-grid book-a-call">
                        <div className="column">
                            <SetupTitle
                                blockDescription="Many thanks for setting up your free trial.  We would like to take your hand and go throught setup process together. Please, book a setup call with our team using the calendar below."
                                title="The Ambassador Platform Account Setup"
                            />
                            <iframe
                                src="https://calendly.com/madalina-6/tap-demo"
                                width="1000"
                                height="680"
                                style={{ position: 'relative', left: '-10rem' }}
                            ></iframe>
                            <Button type="link" onClick={handleButtonClick}>
                                Bring me to self-service Account setup guide
                            </Button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default BookDemoScreen
