import { TWO_TIER_MENU_CHANGE_SUBMENU, CLEAR_TWO_TIER_MENU } from '../../../actions/content/componentsActions'

import { TWO_TIER_MENU } from '../../../store/initialState'

const initialState = TWO_TIER_MENU

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case TWO_TIER_MENU_CHANGE_SUBMENU: {
            let newState = {
                ...state,
                activeSubmenu: payload.activeSubmenu,
            }

            return newState
        }

        case CLEAR_TWO_TIER_MENU: {
            return initialState
        }

        default: {
            return state
        }
    }
}
