import React, { useCallback, useState, useEffect } from 'react'
import { Col, Form, InputNumber, Row, Typography, Checkbox, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { CHANGE_PROSPECT_MESSAGING_ACTIVITY } from '../../../../redux/actions/settings/safeguardingActions'
import { PATTERNS } from '../../../../common/regex'

const { Text } = Typography

const MessagingActivity = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { prospectsMessagingActivity } = useSelector((state) => state.safeguarding)

    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setChecked(prospectsMessagingActivity?.isActivated)
        return () => {
            form.resetFields()
        }
    }, [prospectsMessagingActivity, form])

    const onFinish = useCallback(
        (values) => {
            dispatch({
                type: CHANGE_PROSPECT_MESSAGING_ACTIVITY,
                payload: {
                    isActivated: checked,
                    settings: {
                        numberOfAmbassadors: Number.parseInt(values.ambassadorsCount),
                        numberOfDays: Number.parseInt(values.daysCount),
                    },
                },
            })
        },
        [dispatch, checked]
    )

    const handleCheck = useCallback((e) => {
        setChecked(e.target.checked)
    }, [])

    return (
        <>
            <Row style={{ marginBottom: '2rem' }}>
                <Col>
                    <Text style={{ color: '#ff5100' }}>Prospect messaging activity notification</Text>
                </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
                <Col>
                    <Text>
                        Prospects are free to start conversations with one or more of your ambassadors. If you wish to
                        be notified of instances where a prospect starts a conversation with multiple ambassadors within
                        a specific timeframe, please activate notifications and set your desired parameters below. Once
                        set, email notifications activated for all Admins by default and can be adjusted in personal
                        notification preferences.
                    </Text>
                </Col>
            </Row>
            <Row style={{ marginBottom: '2rem' }}>
                <Col>
                    <Checkbox checked={checked} onChange={handleCheck}>
                        Activate email notifications about Prospect messaging activity
                    </Checkbox>
                </Col>
            </Row>

            {prospectsMessagingActivity?.isActivated || checked ? (
                <>
                    <Row style={{ marginBottom: '2rem' }}>
                        <Col>
                            <Text>
                                Set frequency of Prospect messaging activity you would like to notify Admins about:
                            </Text>
                        </Col>
                    </Row>
                    <Form
                        initialValues={{
                            ambassadorsCount: prospectsMessagingActivity.settings
                                ? prospectsMessagingActivity.settings.ambassadors
                                : 0,
                            daysCount: prospectsMessagingActivity.settings
                                ? prospectsMessagingActivity.settings.days
                                : 0,
                        }}
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                        hideRequiredMark
                        layout="inline"
                        colon={false}
                    >
                        <Form.Item
                            name="ambassadorsCount"
                            label={null}
                            style={{ marginBottom: 20 }}
                            rules={[
                                {
                                    pattern: PATTERNS.numbersOnly,
                                    message: 'Please, use numbers only.',
                                },
                                { required: checked, message: 'Please, specify number of Ambassadors' },
                            ]}
                        >
                            <Row align="middle">
                                <InputNumber
                                    defaultValue={
                                        prospectsMessagingActivity.settings
                                            ? prospectsMessagingActivity.settings.ambassadors
                                            : 0
                                    }
                                    controls={false}
                                    style={{ fontSize: 16, width: '7rem', marginRight: '1rem' }}
                                />
                                <Text>ambassadors messaged by the same prospect within</Text>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            name="daysCount"
                            label={null}
                            style={{ marginBottom: 20 }}
                            rules={[
                                {
                                    pattern: PATTERNS.numbersOnly,
                                    message: 'Please, use numbers only.',
                                },
                                { required: checked, message: 'Please, specify number of days' },
                            ]}
                        >
                            <Row align="middle">
                                <InputNumber
                                    defaultValue={
                                        prospectsMessagingActivity.settings
                                            ? prospectsMessagingActivity.settings.days
                                            : 0
                                    }
                                    controls={false}
                                    style={{ fontSize: 16, width: '7rem', marginRight: '1rem' }}
                                />
                                <Text>days</Text>
                            </Row>
                        </Form.Item>
                    </Form>
                    <Button type="primary" size="large" onClick={form.submit}>
                        Save
                    </Button>
                </>
            ) : null}
        </>
    )
}

export default MessagingActivity
