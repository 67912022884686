import React from 'react';
import Sprite from 'src/assets/img/symbol/sprite.svg';
import './buttons.scss';
export function NextArrow(_a) {
    var onClick = _a.onClick, disabled = _a.disabled, tabIndex = _a.tabIndex;
    return (React.createElement("button", { type: "button", onClick: onClick, className: "slick-next modal-button-arrow", disabled: disabled, title: "Next Post", tabIndex: tabIndex },
        React.createElement("svg", { className: "icon icon-slider-arrow" },
            React.createElement("use", { xlinkHref: Sprite + '#slider-arrow' }))));
}
export function PrevArrow(_a) {
    var onClick = _a.onClick, disabled = _a.disabled, tabIndex = _a.tabIndex;
    return (React.createElement("button", { type: "button", onClick: onClick, className: "slick-prev modal-button-arrow", disabled: disabled, title: "Previous Post", tabIndex: tabIndex },
        React.createElement("svg", { className: "icon icon-slider-arrow" },
            React.createElement("use", { xlinkHref: Sprite + '#slider-arrow' }))));
}
