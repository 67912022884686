import React, { useMemo } from 'react'
import { Typography, Row, Space } from 'antd'

const { Text } = Typography

const PartnerGroupCellTitle = ({ text, record, titleKey, groupId }) => {
    const { before_filter: beforeFilter, after_filter: afterFilter } = record

    const prepareTitle = useMemo(() => {
        const beforFilterTotal = beforeFilter ? beforeFilter[titleKey].find((item) => item.id === groupId)?.total : 0
        const afterFilterTotal = afterFilter ? afterFilter[titleKey].find((item) => item.id === groupId)?.total : 0

        return `(${afterFilter ? `${afterFilterTotal}/` : ''}${beforFilterTotal})`
    }, [beforeFilter, afterFilter, titleKey, groupId])

    return (
        <Row>
            <Space>
                <Text style={{ fontSize: '1.8rem' }}>{text}</Text>
                <Text style={{ color: '#29d266', fontSize: '1.8rem' }}>{prepareTitle}</Text>
            </Space>
        </Row>
    )
}

const partnerGroupCellTitleRenderer = ({ text, record, titleKey, groupId }) => (
    <PartnerGroupCellTitle text={text} record={record} titleKey={titleKey} groupId={groupId} />
)

export default partnerGroupCellTitleRenderer
