export default (function (chat) {
    var id = chat.id, name = chat.name, type = chat.type, typ_key = chat.typ_key, university_id = chat.university_id, reported = chat.reported, description = chat.description, welcome_message = chat.welcome_message, _a = chat.isHidden, isHidden = _a === void 0 ? false : _a;
    return {
        id: id,
        name: name,
        type: type,
        typ_key: typ_key,
        university_id: university_id,
        reported: reported,
        description: description,
        welcome_message: welcome_message,
        avatar: chat.avatar,
        isHidden: isHidden,
    };
});
