export const PROSPECTS_CHARTS_REQUESTED = 'PROSPECTS_CHARTS_REQUESTED'
export const PROSPECTS_CHARTS_SUCCESS = 'PROSPECTS_CHARTS_SUCCESS'
export const PROSPECTS_CHARTS_FAILED = 'PROSPECTS_CHARTS_FAILED'
export const PROSPECTS_TABLE_REQUESTED = 'PROSPECTS_TABLE_REQUESTED'
export const PROSPECTS_TABLE_SUCCESS = 'PROSPECTS_TABLE_SUCCESS'
export const PROSPECTS_TABLE_FAILED = 'PROSPECTS_TABLE_FAILED'
export const PROSPECTS_CSV_REQUESTED = 'PROSPECTS_CSV_REQUESTED'
export const PROSPECTS_CSV_SUCCESS = 'PROSPECTS_CSV_SUCCESS'
export const PROSPECTS_CSV_FAILED = 'PROSPECTS_CSV_FAILED'
export const PROSPECT_OPTIONAL_DATA_REQUESTED = 'PROSPECT_OPTIONAL_DATA_REQUESTED'
export const PROSPECT_OPTIONAL_DATA_SUCCESS = 'PROSPECT_OPTIONAL_DATA_SUCCESS'
export const PROSPECT_OPTIONAL_DATA_FAILED = 'PROSPECT_OPTIONAL_DATA_FAILED'
export const PROSPECT_MANDATORY_DATA_REQUESTED = 'PROSPECT_MANDATORY_DATA_REQUESTED'
export const PROSPECT_MANDATORY_DATA_SUCCESS = 'PROSPECT_MANDATORY_DATA_SUCCESS'
export const PROSPECT_MANDATORY_DATA_FAILED = 'PROSPECT_MANDATORY_DATA_FAILED'
export const PROSPECT_FEEDBACK_DATA_REQUESTED = 'PROSPECT_FEEDBACK_DATA_REQUESTED'
export const PROSPECT_FEEDBACK_DATA_SUCCESS = 'PROSPECT_FEEDBACK_DATA_SUCCESS'
export const PROSPECT_FEEDBACK_DATA_FAILED = 'PROSPECT_FEEDBACK_DATA_FAILED'

export const fetchProspectsChartsData = ({
    filter,
    registeredViaGroupIds,
    registeredViaUniversityIds,
    prospectsIds,
    excludedProspectsIds,
    utmParams,
}) => ({
    type: PROSPECTS_CHARTS_REQUESTED,
    payload: {
        filter,
        registeredViaGroupIds,
        registeredViaUniversityIds,
        prospectsIds,
        excludedProspectsIds,
        utmParams,
    },
})

export const fetchProspectsTableData = ({
    filter,
    search,
    registeredViaGroupIds,
    registeredViaUniversityIds,
    limit,
    offset,
    orderBy,
    order,
    utmParams,
}) => ({
    type: PROSPECTS_TABLE_REQUESTED,
    payload: {
        filter,
        search,
        registeredViaGroupIds,
        registeredViaUniversityIds,
        limit,
        offset,
        orderBy,
        order,
        utmParams,
    },
})

export const fetchProspectsCsvData = ({
    filter,
    search,
    registeredViaGroupIds,
    registeredViaUniversityIds,
    prospectsIds,
    excludedProspectsIds,
    utmParams,
    liveStreamId,
}) => ({
    type: PROSPECTS_CSV_REQUESTED,
    payload: {
        filter,
        search,
        registeredViaGroupIds,
        registeredViaUniversityIds,
        prospectsIds,
        excludedProspectsIds,
        utmParams,
        liveStreamId,
    },
})
