import { CHAT_TYPE_KEY } from '../common/constants'

import UserModel from './UserModel'
import ChatModel from './ChatModel'
import { roles } from '../helpers/helpers'

const ProspectModel = (data) => {
    const { nationality, name } = data
    const { country } = nationality

    const countryName = country ? `from ${country.name}` : ''

    return {
        ...data,
        name: name || '',
        role_name: `Prospect ${countryName}`,
        prospect: true,
    }
}

const mapUsers = (items) => items.map((item) => UserModel(item))
const mapProspects = (items) => items.map((item) => ProspectModel(item))

const getChats = (items) => {
    let chat = {}
    let speakersChat = {}
    for (const item in items) {
        if (items[item].type === CHAT_TYPE_KEY.LIVE_STREAM_CHAT) {
            chat = ChatModel(items[item])
        } else {
            speakersChat = ChatModel(items[item])
        }
    }
    return [chat, speakersChat]
}

export const checkIsAdminSpeaker = (account = {}, liveEvent = {}) => {
    const { account_info: { id } = {} } = account
    const { speakers = [] } = liveEvent

    return speakers.some(({ id: roleId, role_key: role }) => roleId === id && role === roles.admin)
}

export default (data = {}) => {
    const {
        id,
        name,
        description,
        image,
        archived,
        is_promote: isPromote,
        is_group_promote: isGroupPromote,
        // is_private,
        // private_code,
        slug,
        last_activity: lastActivity,
        timezone_id: timezoneId,
        timezoneName,
        // new_messages,
        from_date: fromDate,
        to_date: toDate,
        is_chat_active: isChatActive,
        is_stream_active: isStreamActive,
        status,
        // last_message,
        ambassadors = [],
        speakers = [],
        prospects = [],
        globalTags,
        totalProspects,
        dialogs,
        liveEventLink,
    } = data

    return {
        id,
        name,
        description,
        image,
        archived,
        isPromote,
        slug,
        lastActivity,
        timezoneId,
        timezoneName,
        fromDate,
        toDate,
        status,
        ambassadors: mapUsers(ambassadors),
        speakers: mapUsers(speakers),
        prospects: mapProspects(prospects),
        totalProspects,
        isStreamActive,
        chat: getChats(dialogs)[0],
        speakersChat: getChats(dialogs)[1],
        globalTags,
        isChatActive,
        liveEventLink,
        isGroupPromote,
    }
}
