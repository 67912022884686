import { call, put, select } from 'redux-saga/effects'

import API from '../../../api'
import { OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'
import { GET_API_KEY_SUCCESS, GENERATE_API_KEY_SUCCESS } from '../../actions/settings/crmActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin;
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId;

export function* getApiKey() {
    try {
        const isGroupAdmin = yield select(getGroupAdmin);
        const universityId = yield select(getCurrentGroupUniversityId);
        const response = yield call(() => {
            return API.settings.getApiKey(isGroupAdmin && !universityId ? null : universityId)
        });

        if (response.success) {
            yield put({
                type: GET_API_KEY_SUCCESS,
                payload: {
                    apiKey: response.data.apiKey || "",
                },
            });
        }
    } catch (error) {
        console.log(error);
    }
}

export function* generateApiKey(action) {
    try {
        const payload = action.payload;

        const isGroupAdmin = yield select(getGroupAdmin);
        const universityId = yield select(getCurrentGroupUniversityId);
        const response = yield call(() => {
            return API.settings.generateApiKey(isGroupAdmin && !universityId ? null : universityId)
        });

        if (response.success) {
            yield put({
                type: GENERATE_API_KEY_SUCCESS,
                payload: {
                    apiKey: response.data.apiKey || "",
                },
            });

            if (payload.successMessage) {
                yield put({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: payload.successMessage,
                    },
                })
            }
        }
    } catch (error) {
        console.log(error);
    }
}
