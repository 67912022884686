import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { ReactComponent as SearchIcon } from '../../../../../assets/svg/search-thin.svg'
import { ReactComponent as LinkIcon } from '../../../../../assets/svg/icons/link-v.svg'
import { ReactComponent as HeartIcon } from '../../../../../assets/svg/icons/heart.svg'
import { ReactComponent as ChevronIcon } from '../../../../../assets/img/svg/chevron-down.svg'
import PostExample from '../../../../../assets/img/setup/post.png'

class SlideCards extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        const { primaryColor, secondaryColor } = this.props

        return (
            <div className="preview-slide preview-large-slide slide-cards">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={611.205}
                    height={475.643}
                    viewBox="0 0 611.205 475.643"
                    {...this.props}
                >
                    <g data-name="Group 3609">
                        <g data-name="Group 3598">
                            <path
                                data-name="Path 64"
                                d="M519.604 57.81a45.278 45.278 0 005.849-24.572c-.093-1.915 2.752-2.34 2.993-.387a47.359 47.359 0 01-.29 13.675c6.87-11.259 17.256-20.337 28.794-26.61 14.992-8.148 35.528-13.007 52.277-7.862 2.457.755 1.882 5.104-.718 5.111-8.345.026-16.393-.688-24.72.624a79.831 79.831 0 00-23.66 7.538c-12.363 6.25-22.008 15.86-30.216 26.741a23.628 23.628 0 0111.722-.154c3.544.878 2.92 6.441-.807 5.744-6.366-1.198-12.1-.542-17.688 2.944-1.75 1.084-4.784-.556-3.536-2.792z"
                                fill="rgba(34,34,34,0.4)"
                            />
                            <g data-name="Group 3171">
                                <g data-name="Group 3143">
                                    <g data-name="Group 605">
                                        <g
                                            data-name="Rectangle 1521"
                                            fill="none"
                                            stroke={primaryColor}
                                            transform="translate(29 72.643)"
                                        >
                                            <rect width={426} height={31} rx={2} stroke="none" />
                                            <rect x={0.5} y={0.5} width={425} height={30} rx={1.5} />
                                        </g>
                                        <path
                                            d="M48.556 92.052a5.277 5.277 0 10-5.277-5.276 5.277 5.277 0 005.277 5.277zm5.558-.962l3.148 3.15a.879.879 0 11-1.242 1.241l-3.148-3.149a7.036 7.036 0 111.244-1.244z"
                                            fill={primaryColor}
                                        />
                                        <g
                                            data-name="Rectangle 1525"
                                            transform="translate(471 72.643)"
                                            fill={primaryColor}
                                            stroke="rgba(51,59,80,0.2)"
                                        >
                                            <rect width={128} height={31} rx={2} stroke="none" />
                                            <rect x={0.5} y={0.5} width={127} height={30} rx={1.5} fill="none" />
                                        </g>
                                        <text
                                            data-name="Search"
                                            transform="translate(495 75.643)"
                                            fill="#fff"
                                            fontSize={16}
                                            fontFamily="SourceSansPro-Regular, Source Sans Pro"
                                        >
                                            <tspan x={17.144} y={16}>
                                                {'Search'}
                                            </tspan>
                                        </text>
                                    </g>
                                </g>
                                <g data-name="Group 3170" transform="translate(-1125 -294.357)">
                                    <g data-name="Group 3166">
                                        <g data-name="Group 3152">
                                            <g data-name="Rectangle 1917" fill="#fff" stroke="#e0e0e0">
                                                <path stroke="none" d="M1154 519h180v180h-180z" />
                                                <path fill="none" d="M1154.5 519.5h179v179h-179z" />
                                            </g>
                                            <g data-name="Group 3147" fill="#e8e8e8" transform="translate(0 91)">
                                                <path data-name="Polygon 3" d="M1266 509l42 60h-84z" />
                                                <path data-name="Polygon 4" d="M1209 529l28 40h-56z" />
                                                <circle
                                                    data-name="Ellipse 33"
                                                    cx={17}
                                                    cy={17}
                                                    r={17}
                                                    transform="translate(1181 467)"
                                                />
                                            </g>
                                        </g>
                                        <g data-name="Group 3163" fill={primaryColor}>
                                            <g data-name="Group 3154">
                                                <path d="M1231.497 713.191a.538.538 0 11.761.759l-1.142 1.141a1.075 1.075 0 001.522 1.519l2.663-2.659a.537.537 0 000-.759.538.538 0 11.761-.76 1.61 1.61 0 010 2.279l-2.663 2.659a2.151 2.151 0 11-3.044-3.039l1.142-1.14zm6.455-.383a.538.538 0 11-.761-.759l1.142-1.14a1.076 1.076 0 10-1.523-1.52l-2.662 2.66a.537.537 0 000 .759.538.538 0 11-.761.76 1.61 1.61 0 010-2.279l2.663-2.66a2.151 2.151 0 113.044 3.039l-1.142 1.141z" />
                                                <text
                                                    data-name="Copy link"
                                                    transform="translate(1242 704)"
                                                    fontSize={12}
                                                    fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                                    fontWeight={600}
                                                >
                                                    <tspan x={0} y={12}>
                                                        {'Copy link'}
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g data-name="Group 3153">
                                                <path d="M1299.485 712.479l4 4 4-4a1.887 1.887 0 00-2.668-2.665l-1.33 1.331-1.334-1.331a1.887 1.887 0 10-2.668 2.665zm3.558-3.558l.445.445.445-.445a3.145 3.145 0 114.447 4.447l-4.447 4.446a.629.629 0 01-.89 0l-4.448-4.446a3.145 3.145 0 114.447-4.447z" />
                                                <text
                                                    transform="translate(1312 704)"
                                                    fontSize={12}
                                                    fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                                    fontWeight={600}
                                                >
                                                    <tspan x={0} y={12}>
                                                        {'Like'}
                                                    </tspan>
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <g data-name="Group 3165">
                                        <g data-name="Group 3151">
                                            <g data-name="Rectangle 1918" fill="#fff" stroke="#e0e0e0">
                                                <path stroke="none" d="M1349 519h180v180h-180z" />
                                                <path fill="none" d="M1349.5 519.5h179v179h-179z" />
                                            </g>
                                            <g data-name="Group 3148" fill="#e8e8e8" transform="translate(0 91)">
                                                <path data-name="Polygon 5" d="M1461 509l42 60h-84z" />
                                                <path data-name="Polygon 6" d="M1404 529l28 40h-56z" />
                                                <circle
                                                    data-name="Ellipse 34"
                                                    cx={17}
                                                    cy={17}
                                                    r={17}
                                                    transform="translate(1376 467)"
                                                />
                                            </g>
                                        </g>
                                        <g data-name="Group 3270" fill={primaryColor}>
                                            <g data-name="Group 3154">
                                                <path
                                                    data-name="link"
                                                    d="M1426.497 713.191a.538.538 0 11.761.759l-1.142 1.141a1.075 1.075 0 001.522 1.519l2.663-2.659a.537.537 0 000-.759.538.538 0 11.761-.76 1.61 1.61 0 010 2.279l-2.663 2.659a2.151 2.151 0 11-3.044-3.039l1.142-1.14zm6.455-.383a.538.538 0 11-.761-.759l1.142-1.14a1.076 1.076 0 10-1.523-1.52l-2.662 2.66a.537.537 0 000 .759.538.538 0 11-.761.76 1.61 1.61 0 010-2.279l2.663-2.66a2.151 2.151 0 113.044 3.039l-1.142 1.141z"
                                                />
                                                <text
                                                    data-name="Copy link"
                                                    transform="translate(1437 704)"
                                                    fontSize={12}
                                                    fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                                    fontWeight={600}
                                                >
                                                    <tspan x={0} y={12}>
                                                        {'Copy link'}
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g data-name="Group 3153">
                                                <path
                                                    data-name="heart"
                                                    d="M1494.485 712.479l4 4 4-4a1.887 1.887 0 00-2.668-2.665l-1.33 1.331-1.334-1.331a1.887 1.887 0 10-2.668 2.665zm3.558-3.558l.445.445.445-.445a3.145 3.145 0 114.447 4.447l-4.447 4.446a.629.629 0 01-.89 0l-4.448-4.446a3.145 3.145 0 114.447-4.447z"
                                                />
                                                <text
                                                    data-name="Like"
                                                    transform="translate(1507 704)"
                                                    fontSize={12}
                                                    fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                                    fontWeight={600}
                                                >
                                                    <tspan x={0} y={12}>
                                                        {'Like'}
                                                    </tspan>
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <g data-name="Group 3164">
                                        <g data-name="Group 3150">
                                            <g data-name="Rectangle 1919" fill="#fff" stroke="#e0e0e0">
                                                <path stroke="none" d="M1544 519h180v180h-180z" />
                                                <path fill="none" d="M1544.5 519.5h179v179h-179z" />
                                            </g>
                                            <g data-name="Group 3149" fill="#e8e8e8" transform="translate(0 91)">
                                                <path data-name="Polygon 7" d="M1656 509l42 60h-84z" />
                                                <path data-name="Polygon 8" d="M1599 529l28 40h-56z" />
                                                <circle
                                                    data-name="Ellipse 35"
                                                    cx={17}
                                                    cy={17}
                                                    r={17}
                                                    transform="translate(1571 467)"
                                                />
                                            </g>
                                        </g>
                                        <g data-name="Group 3271" fill={primaryColor}>
                                            <g data-name="Group 3154">
                                                <path
                                                    data-name="link"
                                                    d="M1621.497 713.191a.538.538 0 11.761.759l-1.142 1.141a1.075 1.075 0 001.522 1.519l2.663-2.659a.537.537 0 000-.759.538.538 0 11.761-.76 1.61 1.61 0 010 2.279l-2.663 2.659a2.151 2.151 0 11-3.044-3.039l1.142-1.14zm6.455-.383a.538.538 0 11-.761-.759l1.142-1.14a1.076 1.076 0 10-1.523-1.52l-2.662 2.66a.537.537 0 000 .759.538.538 0 11-.761.76 1.61 1.61 0 010-2.279l2.663-2.66a2.151 2.151 0 113.044 3.039l-1.142 1.141z"
                                                />
                                                <text
                                                    data-name="Copy link"
                                                    transform="translate(1632 704)"
                                                    fontSize={12}
                                                    fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                                    fontWeight={600}
                                                >
                                                    <tspan x={0} y={12}>
                                                        {'Copy link'}
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g data-name="Group 3153">
                                                <path
                                                    data-name="heart"
                                                    d="M1689.485 712.479l4 4 4-4a1.887 1.887 0 00-2.668-2.665l-1.33 1.331-1.334-1.331a1.887 1.887 0 10-2.668 2.665zm3.558-3.558l.445.445.445-.445a3.145 3.145 0 114.447 4.447l-4.447 4.446a.629.629 0 01-.89 0l-4.448-4.446a3.145 3.145 0 114.447-4.447z"
                                                />
                                                <text
                                                    data-name="Like"
                                                    transform="translate(1702 704)"
                                                    fontSize={12}
                                                    fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                                    fontWeight={600}
                                                >
                                                    <tspan x={0} y={12}>
                                                        {'Like'}
                                                    </tspan>
                                                </text>
                                            </g>
                                        </g>
                                    </g>
                                    <circle
                                        data-name="Ellipse 36"
                                        cx={12}
                                        cy={12}
                                        r={12}
                                        transform="translate(1154 468)"
                                        fill="#e8e8e8"
                                    />
                                    <circle
                                        data-name="Ellipse 37"
                                        cx={12}
                                        cy={12}
                                        r={12}
                                        transform="translate(1349 468)"
                                        fill="#e8e8e8"
                                    />
                                    <circle
                                        data-name="Ellipse 38"
                                        cx={12}
                                        cy={12}
                                        r={12}
                                        transform="translate(1544 468)"
                                        fill="#e8e8e8"
                                    />
                                    <g data-name="Group 154">
                                        <g
                                            data-name="Rectangle 1498"
                                            transform="translate(1154 498)"
                                            fill="#fff"
                                            stroke={primaryColor}
                                            strokeWidth={0.5}
                                        >
                                            <rect width={180} height={16} rx={8} stroke="none" />
                                            <rect x={0.25} y={0.25} width={179.5} height={15.5} rx={7.75} fill="none" />
                                        </g>
                                        <text
                                            data-name="Ask me a question"
                                            transform="translate(1161 499)"
                                            fill={primaryColor}
                                            fontSize={10}
                                            fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                            fontWeight={600}
                                        >
                                            <tspan x={44.175} y={10}>
                                                {'Ask me a question'}
                                            </tspan>
                                        </text>
                                    </g>
                                    <g data-name="Group 3167">
                                        <g
                                            data-name="Rectangle 1498"
                                            transform="translate(1349 498)"
                                            fill="#fff"
                                            stroke={primaryColor}
                                            strokeWidth={0.5}
                                        >
                                            <rect width={180} height={16} rx={8} stroke="none" />
                                            <rect x={0.25} y={0.25} width={179.5} height={15.5} rx={7.75} fill="none" />
                                        </g>
                                        <text
                                            data-name="Ask me a question"
                                            transform="translate(1356 499)"
                                            fill={primaryColor}
                                            fontSize={10}
                                            fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                            fontWeight={600}
                                        >
                                            <tspan x={44.175} y={10}>
                                                {'Ask me a question'}
                                            </tspan>
                                        </text>
                                    </g>
                                    <g data-name="Group 3168">
                                        <g
                                            data-name="Rectangle 1498"
                                            transform="translate(1544 498)"
                                            fill="#fff"
                                            stroke={primaryColor}
                                            strokeWidth={0.5}
                                        >
                                            <rect width={180} height={16} rx={8} stroke="none" />
                                            <rect x={0.25} y={0.25} width={179.5} height={15.5} rx={7.75} fill="none" />
                                        </g>
                                        <text
                                            data-name="Ask me a question"
                                            transform="translate(1551 499)"
                                            fill={primaryColor}
                                            fontSize={10}
                                            fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                            fontWeight={600}
                                        >
                                            <tspan x={44.175} y={10}>
                                                {'Ask me a question'}
                                            </tspan>
                                        </text>
                                    </g>
                                    <path
                                        data-name="Line 199"
                                        fill="none"
                                        stroke="#bababa"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                        d="M1186.5 480.5h58"
                                    />
                                    <path
                                        data-name="Line 200"
                                        fill="none"
                                        stroke="#bababa"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                        d="M1381.5 480.5h58"
                                    />
                                    <path
                                        data-name="Line 201"
                                        fill="none"
                                        stroke="#bababa"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                        d="M1576.5 480.5h58"
                                    />
                                    <g data-name="Group 3169" fill={primaryColor}>
                                        <text
                                            data-name="See more posts"
                                            transform="translate(1635 753)"
                                            fontSize={12}
                                            fontFamily="SourceSansPro-SemiBold, Source Sans Pro"
                                            fontWeight={600}
                                        >
                                            <tspan x={0} y={12}>
                                                {'See more posts'}
                                            </tspan>
                                        </text>
                                        <path d="M1723.395 761.197l-3.572-3.571a.721.721 0 111.018-1.021l4.084 4.082a.722.722 0 010 1.02l-4.084 4.082a.721.721 0 11-1.02-1.02l3.574-3.572z" />
                                    </g>
                                </g>
                            </g>
                            <path
                                data-name="Line 216"
                                fill="none"
                                stroke="#bababa"
                                strokeLinecap="round"
                                strokeWidth={4}
                                d="M29.5 151.143h147"
                            />
                        </g>
                        <path data-name="Rectangle 1985" fill="#fff" d="M0 15.643h10v460H0z" />
                    </g>
                </svg>
            </div>
        )
    }
}

export default connect((state) => {
    return {}
})(SlideCards)
