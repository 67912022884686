import React, { PureComponent } from 'react'
import ChatsSidebar from './components/ChatsSidebar'
import Messages from './components/Messages'
import store from '../../../redux/store'
import { connect } from 'react-redux'

import {
    CHATS_INFO_SEARCH,
    CHAT_INFO_SEARCH,
    SET_ACTIVE_CHAT,
    CHAT_MESSAGES_INFO,
    CLEAR_CHAT_MESSAGES,
} from '../../../redux/actions/chat/messagingActions'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { CLOSE_SETTINGS_MESSAGING_POPUP, HIDE_NEW_MESSAGES } from '../../../redux/actions/components/componentsActions'

import { CLOSE_CHAT_GROUP_POPUP } from '../../../redux/actions/chat/groupMessagingActions'
import { CLOSE_COPY_LINK_POPUP } from '../../../redux/actions/chat/commonActions'
import Loader from '../../content/components/Loader'

class ArchiveChats extends PureComponent {
    constructor(props) {
        super(props)

        const dialog = window.location.search.split('activeDialog=')[1]
        const activeDialog = dialog ? +dialog.split('&')[0] : null
        this.state = {
            activeDialog: activeDialog,
            activeDialogType: 1,
        }
    }

    componentDidMount() {
        const { activeDialog } = this.state

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'chat',
            },
        })
        if (activeDialog) {
            const onSuccess = (data) => {
                const selectedDialog = data.dialogs.filter((dialog) => dialog.id == activeDialog)
                if (!selectedDialog) return

                store.dispatch({
                    type: SET_ACTIVE_CHAT,
                    payload: {
                        chat_id: activeDialog,
                        chats_type: activeDialog.chatsType,
                        data: selectedDialog ? selectedDialog[0] : null,
                    },
                })

                store.dispatch({
                    type: CHAT_MESSAGES_INFO,
                    payload: {
                        dialog_id: activeDialog,
                        archived: true,
                    },
                })

                store.dispatch({
                    type: HIDE_NEW_MESSAGES,
                })
            }
            store.dispatch({
                type: CHAT_INFO_SEARCH,
                payload: {
                    search: null,
                    withGlobalTags: true,
                    archived: true,
                },
                onSuccess: onSuccess,
            })
            // window.location.hash = window.location.hash.split('?activeDialog=')[0]
        } else {
            store.dispatch({
                type: CHATS_INFO_SEARCH,
                payload: {
                    search: null,
                    withGlobalTags: true,
                    archived: true,
                },
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: CLEAR_CHAT_MESSAGES,
            })

            store.dispatch({
                type: SET_ACTIVE_CHAT,
                payload: {
                    chat_id: -1,
                },
            })

            store.dispatch({
                type: CHATS_INFO_SEARCH,
                payload: {
                    withGlobalTags: true,
                    archived: true,
                },
            })
        }
    }

    componentWillUnmount() {
        store.dispatch({
            type: CLEAR_CHAT_MESSAGES,
        })

        store.dispatch({
            type: SET_ACTIVE_CHAT,
            payload: {
                chat_id: -1,
            },
        })
    }

    handleCloseChatGroupPopup = () => {
        store.dispatch({
            type: CLOSE_CHAT_GROUP_POPUP,
        })
    }

    handleCloseChatSettingsPopup = () => {
        store.dispatch({
            type: CLOSE_SETTINGS_MESSAGING_POPUP,
        })
    }

    closeCopyLinkPopup() {
        store.dispatch({
            type: CLOSE_COPY_LINK_POPUP,
        })
    }

    render() {
        const { activeDialog, activeDialogType } = this.state
        const { account, chats } = this.props

        return (
            <div className="content mod-chat group-chats">
                <div className="chat mod-messaging">
                    {chats.isLoading ? (
                        <Loader />
                    ) : chats.items.length === 0 ? (
                        <div className="modal-title" style={{ textAlign: 'center', margin: 'auto' }}>
                            <h2 style={{ fontSize: '2.2rem' }}>There are no archived chats.</h2>
                            <h2 style={{ fontSize: '2.2rem' }}>
                                You can archive an individual or a group chat after you close or deactivate it
                            </h2>
                        </div>
                    ) : (
                        <>
                            <Messages universityID={account.account_info.university_id} />
                            <ChatsSidebar activeDialog={activeDialog} activeDialogType={activeDialogType} />
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        chats: state.chats,
    }
})(ArchiveChats)
