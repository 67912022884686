const prepareImageCode = (image) => image.slice(image.indexOf(',') + 1)

export default (
    {
        name,
        image,
        description,
        date,
        globalTagsIds,
        isPromote,
        isGroupPromote,
        userIds,
        videoId,
        videoDuration,
        status,
    },
    data = {},
    imageTouched = false
) => {
    const { id } = data

    if (!id) {
        const fromDate = date || null
        return {
            name,
            image: prepareImageCode(image),
            description,
            fromDate,
            userIds,
            globalTagsIds: globalTagsIds.map(({ id }) => +id),
            isPromote: !isPromote,
            isGroupPromote: !isGroupPromote,
            videoId,
            videoDuration,
            status,
        }
    }

    if (id) {
        const fromDate = date || null
        return {
            liveStreamId: id,
            name,
            image: imageTouched ? prepareImageCode(image) : undefined,
            description,
            fromDate,
            globalTagsIds: globalTagsIds.map(({ id }) => +id),
            isPromote: !isPromote,
            isGroupPromote: !isGroupPromote,
            editType: 'event',
        }
    }
}
