import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link } from 'react-router-dom'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'

const validate = (values) => {
    const errors = {}

    if (!values.email) {
        errors.email = 'Please enter your email address'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    return errors
}

const renderField = ({ input, label, placeholder, type, className, meta: { touched, error, warning } }) => (
    <div>
        <input {...input} className={className} placeholder={placeholder} type={type} />
        {touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

class PasswordRecoverForm extends PureComponent {
    render() {
        const { handleSubmit } = this.props

        return (
            <form action="#" method="POST" className="signin-form" onSubmit={handleSubmit}>
                <label className="signin-label">
                    <Field
                        name="email"
                        component={renderField}
                        type="text"
                        placeholder="Email"
                        className="signin-input mod-pass"
                    />
                    <svg className="icon icon-mail">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#mail'}></use>
                    </svg>
                </label>
                <button type="submit" className="signin-btn">
                    Next
                </button>
                <Link to="/account/login" className="forgot">
                    Back
                </Link>
            </form>
        )
    }
}

export default reduxForm({
    form: 'recover_form',
    validate,
})(PasswordRecoverForm)
