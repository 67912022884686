import React from 'react'

import { StreamCard, StreamVideo, StreamParticipants } from './components'

const Sidebar = ({ activeEvent }) => {
    return (
        <div className="stream-event-nav">
            {activeEvent.video && activeEvent.video.length > 0 && <StreamVideo event={activeEvent} />}
            <StreamCard event={activeEvent} />
            <StreamParticipants event={activeEvent} />
        </div>
    )
}

export default Sidebar
