import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks/store';
import { Permissions } from 'src/app/permissions/permissions';
import { getUniversityInfo } from 'src/app/redux/selectors';
import Logo from 'src/assets/svg/logo.svg';
import OnboardingLogo from 'src/assets/svg/pls-onboarding.svg';
import { PermissionEnum } from 'src/types/general';
import { prepareBookSesionLink } from '../registration/components/pls-sign-up/components/helpers';
export var PlsOnboarding = function () {
    var _a;
    var isPls = Permissions.can(PermissionEnum.Pls, false);
    var uniInfo = useAppSelector(getUniversityInfo);
    var bookLink = prepareBookSesionLink((_a = uniInfo === null || uniInfo === void 0 ? void 0 : uniInfo.country) === null || _a === void 0 ? void 0 : _a.alpha_3_code);
    if (!isPls && uniInfo) {
        return React.createElement(Redirect, { to: "/home" });
    }
    return (React.createElement("div", { className: "h-screen flex flex-col items-center md:justify-center md:bg-cover md:bg-[url('https://cdn.theaccessplatform.com/img/pls_sign_up_1_1.svg')]" },
        React.createElement("div", { className: "h-14 w-full bg-white shadow-md flex justify-center items-end mb-0.5 md:hidden" },
            React.createElement("img", { src: Logo, alt: "Logo", className: "h-13" })),
        React.createElement("div", { className: "md:w-[58rem] h-screen grid grid-rows-[auto_auto_1fr] bg-[#F8FAFC] md:bg-white p-4 md:p-6 md:rounded md:h-auto" },
            React.createElement("div", { className: " flex justify-center items-end" },
                React.createElement("img", { src: OnboardingLogo, alt: "Logo", className: "h-38" })),
            React.createElement("div", { className: "flex flex-col justify-center items-center pb-8 pt-6" },
                React.createElement("h3", { className: "font-interface-bold text-[2.8rem] text-center" }, "Your application is being processed."),
                React.createElement("p", { className: "pt-8 text-submenu-base text-[#222]" }, "Schedule a quick onboarding meeting with our specialist to speed up the process. Or, dive into our helpful guides to unlock the full potential of peer-to-peer strategies in student recruitment and engagement.")),
            React.createElement("div", { className: "flex flex-col self-center md:self-start" },
                React.createElement("a", { target: "_blank", rel: "noreferrer", href: bookLink, className: "w-full text-center text-white h-auto max-h-16 py-3 mt-4.5 rounded-[3rem] text-base bg-gradient-to-r from-[#FF702D] to-brand-orange hover:text-white font-poppins" }, "Book an onboarding session"),
                React.createElement("a", { target: "_blank", rel: "noreferrer", href: "https://www.theambassadorplatform.com/resources", className: "w-full text-center h-auto max-h-16 py-2 mt-4.5 rounded-[3rem] text-base  text-brand-orange hover:text-brand-orange underline font-poppins" }, "Read useful materials about Peer-to-Peer student marketing")))));
};
