import React from 'react'

import { ParticipantsList } from './components'

const ParticipantsTab = ({ activeEvent }) => {
    return (
        <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
            <ParticipantsList activeEvent={activeEvent} participantsType="speakers" title="Speakers" />
            <ParticipantsList activeEvent={activeEvent} participantsType="ambassadors" title="Ambassadors" />
            <ParticipantsList activeEvent={activeEvent} participantsType="prospects" title="Prospects" />
        </div>
    )
}

export default ParticipantsTab
