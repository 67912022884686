import React, { Component } from 'react'
import DatePicker from 'antd/es/date-picker'
import Button from 'antd/es/button'

import { prepareFromDate, prepareToDate, getDateRange } from '../helpers/time'

import dayjs from 'dayjs'

class DatesIntervalSelector extends Component {
    render() {
        const { hideDateRange, selectedButton, topAlign } = this.props
        const dateFormat = 'YYYY-MM-DD'
        const fromDate = this.props.fromDate
        const toDate = this.props.toDate
        const restrictBeforeDate = this.props.restrictBeforeDate
        const daysPassedFromStart = this.props.daysPassedFromStart
        return (
            <div style={{ display: 'flex', alignItems: 'center' }} className="date-interval-selector">
                {!hideDateRange && (
                    <DatePicker.RangePicker
                        style={{ marginTop: topAlign, marginRight: 8 }}
                        value={
                            selectedButton === 'None'
                                ? [dayjs(fromDate, dateFormat), dayjs(toDate, dateFormat)]
                                : [null, null]
                        }
                        format={dateFormat}
                        className="date-picker"
                        onChange={(dates) => {
                            if (dates === null) {
                                if (daysPassedFromStart) {
                                    this.props.onChange(selectedButton, getDateRange(daysPassedFromStart))
                                } else {
                                    this.props.onChange('30Days', getDateRange(30))
                                }
                            } else {
                                this.props.onChange('None', {
                                    fromDate: prepareFromDate(dates[0]),
                                    toDate: prepareToDate(dates[1]),
                                })
                            }
                        }}
                        disabledDate={(current) => {
                            if (restrictBeforeDate) {
                                return current && current < dayjs(restrictBeforeDate)
                            }
                        }}
                    />
                )}
                <Button.Group>
                    <Button
                        type={selectedButton === '7Days' ? 'primary' : 'default'}
                        onClick={() => {
                            this.props.onChange('7Days', getDateRange(7))
                        }}
                    >
                        7 Days
                    </Button>
                    {(!daysPassedFromStart || (daysPassedFromStart && daysPassedFromStart > 14)) && (
                        <Button
                            type={selectedButton === '14Days' ? 'primary' : 'default'}
                            onClick={() => {
                                this.props.onChange('14Days', getDateRange(14))
                            }}
                        >
                            14 Days
                        </Button>
                    )}

                    {(!daysPassedFromStart || (daysPassedFromStart && daysPassedFromStart > 30)) && (
                        <Button
                            type={selectedButton === '30Days' ? 'primary' : 'default'}
                            onClick={() => {
                                this.props.onChange('30Days', getDateRange(30))
                            }}
                        >
                            30 Days
                        </Button>
                    )}
                </Button.Group>
            </div>
        )
    }
}

export default DatesIntervalSelector
