import React, { useState } from 'react'

const EditUniversityNameForm = ({ university, handleSubmit }) => {
    const [name, setName] = useState(university.name || '')
    const [error, setError] = useState('')

    const onChange = (e) => {
        const value = e.target.value

        if (value.length === 0) {
            setError('Please enter your institution name')
        } else if (value.length > 150) {
            setError('150 characters maximum')
        } else if (error) {
            setError('')
        }
        setName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        handleSubmit(name)
    }

    return (
        <form className="university-form" onSubmit={onSubmit}>
            <div>
                <input
                    name="name"
                    value={name}
                    onChange={onChange}
                    className="university-input"
                    placeholder=""
                    type="text"
                />
                {error.length > 0 && <span className="error">{error}</span>}
            </div>
            <button
                type="submit"
                className={
                    'university-submit' +
                    ((!university.name && name.length === 0) || university.name === name || error
                        ? ' button-disabled'
                        : '')
                }
            >
                Change
            </button>
        </form>
    )
}

export default EditUniversityNameForm
