import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { getLiveStreamsSlice } from './common-selectors'
import LiveStreamModel from '../../models/live-streams-model'
import ScheduledLiveStreamModel from '../../models/scheduled-live-streams-model'
import { getItems } from '../utils/selector-utils'
import { MODAL_TYPE } from '../../containers/live-streams-container/variables'

export const getActiveLiveStreamId = createSelector(getLiveStreamsSlice, ({ activeEventId }) => activeEventId)

const getLiveStramsItems = createSelector(getLiveStreamsSlice, getItems)

export const getLiveStrams = createSelector(getLiveStramsItems, ({ allIds, byId }) =>
    allIds.map((id) => LiveStreamModel(byId[id]))
)
export const getLiveStramsIsLoading = createSelector(getLiveStreamsSlice, prop('loading'))
export const getLiveStramsTotal = createSelector(getLiveStreamsSlice, prop('total'))
export const getLiveStramsOffset = createSelector(getLiveStreamsSlice, prop('currentOffset'))
export const getLiveStramsFilters = createSelector(getLiveStreamsSlice, prop('currentFilters'))

export const getActiveLiveStream = createSelector(
    getActiveLiveStreamId,
    getLiveStramsItems,
    (activeEventId, { byId }) => {
        return byId[activeEventId]?.status === 'onDemand' || byId[activeEventId]?.status === 'completed'
            ? LiveStreamModel(byId[activeEventId])
            : ScheduledLiveStreamModel(byId[activeEventId])
    }
)

export const getLiveStreamsScheduledModal = createSelector(
    getLiveStreamsSlice,
    ({ scheduledEventModal }) => scheduledEventModal
)

export const getLiveStreamsTypeModal = createSelector(getLiveStreamsSlice, ({ streamTypeModal }) => streamTypeModal)
export const getLiveStreamsAmbassadorsModal = createSelector(
    getLiveStreamsSlice,
    ({ ambassadorsModal }) => ambassadorsModal
)
export const getLiveStreamsSpeakersModal = createSelector(getLiveStreamsSlice, ({ speakersModal }) => speakersModal)
export const getLivePublishControlModal = createSelector(
    getLiveStreamsSlice,
    ({ publishControlModal }) => publishControlModal
)
export const getVideoReUploadModal = createSelector(getLiveStreamsSlice, ({ videoReUploadModal }) => videoReUploadModal)
export const getLiveStreamsModal = createSelector(getLiveStreamsSlice, ({ eventModal }) => eventModal)
export const getLiveEventsUsersModal = createSelector(getLiveStreamsSlice, ({ usersModal }) => usersModal)

export const getActiveStreamsData = createSelector(getLiveStreamsSlice, ({ activeStreamData }) => activeStreamData)

export const getStreamModal = (name) => {
    switch (name) {
        case MODAL_TYPE.TYPE_MODAL:
            return getLiveStreamsTypeModal
        case MODAL_TYPE.SHEDULED_EVENT_MODAL:
            return getLiveStreamsScheduledModal
        case MODAL_TYPE.AMBASSADORS_MODAL:
            return getLiveStreamsAmbassadorsModal
        case MODAL_TYPE.SPEAKERS_MODAL:
            return getLiveStreamsSpeakersModal
        case MODAL_TYPE.PUBLISH_CONTENT_MODAL:
            return getLivePublishControlModal
        case MODAL_TYPE.VIDEO_RE_UPLOAD_MODAL:
            return getVideoReUploadModal
        case MODAL_TYPE.EVENT_MODAL:
            return getLiveStreamsModal
        case MODAL_TYPE.USERS_MODAL:
            return getLiveEventsUsersModal
        default:
            throw new Error(`Unknown selector name: ${name}`)
    }
}
