import { Link } from 'react-router-dom'
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'
import { openSsoWindow } from '../../../../helpers/SsoHelper'
import { Button, Divider } from 'antd'

const validate = (values) => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Please enter your email address'
    } else if (!/^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,16}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    if (!values.first_name) {
        errors.first_name = 'Please enter your first name'
    }

    if (!values.last_name) {
        errors.last_name = 'Please enter your last name'
    }

    if (!values.password) {
        errors.password = 'Please enter your password'
    } else if (!/^(?=.*?[\p{Ll}])(?=.*?[\p{Lu}])(?=.*\d)(?=.*?[\W_]).{8,}$/gu.test(values.password)) {
        errors.password =
            'Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol'
    }

    if (!values.confirm_password) {
        errors.confirm_password = 'Please enter your password'
    }

    if (values.confirm_password != values.password) {
        errors.confirm_password = 'This password does not match'
    }

    if (!values.invite_code) {
        errors.invite_code = 'Please enter your invite code'
    }
    return errors
}

const renderField = ({ input, label, placeholder, type, className, icon, meta: { touched, error, warning } }) => (
    <div className={'input-block-field' + (touched && (error || warning) ? ' input-block-field-error' : '')}>
        <div className="input-icon-block">
            <input {...input} className={className} placeholder={placeholder} type={type} />
            <svg className="icon icon-lock">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#' + icon}></use>
            </svg>
        </div>
        {touched &&
            ((error && <span className="error error-relative">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

const renderPasswordField = ({
    input,
    label,
    placeholder,
    type,
    className,
    icon,
    rightIcon,
    onShowPassword,
    meta: { touched, error, warning },
}) => (
    <div className={'input-block-field' + (touched && (error || warning) ? ' input-block-field-error' : '')}>
        <div className="input-icon-block">
            <input
                {...input}
                className={className}
                placeholder={placeholder}
                type={type}
                onCopy={(e) => e.preventDefault()}
            />
            <svg className="icon eye-icon" onClick={onShowPassword}>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#' + rightIcon}></use>
            </svg>
            <svg className="icon icon-lock">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#' + icon}></use>
            </svg>
        </div>
        {touched &&
            ((error && <span className="error error-relative">{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
)

class RegistrationForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            showPassword: false,
            showConfirmPassword: false,
        }

        this.showPassword = this.showPassword.bind(this)
        this.showConfirmPassword = this.showConfirmPassword.bind(this)
    }

    showPassword(e) {
        e.preventDefault()
        this.setState({
            showPassword: !this.state.showPassword,
        })
    }

    showConfirmPassword(e) {
        e.preventDefault()
        this.setState({
            showConfirmPassword: !this.state.showConfirmPassword,
        })
    }

    ssoSignIn = () => {
        const { account, invitationData } = this.props
        openSsoWindow(account.appDomain, account.appClientId, account.isEncryptionOn, invitationData)
    }

    render() {
        const { account, handleSubmit, invitationData } = this.props

        return (
            <form action="#" method="POST" className="signin-form registration-form" onSubmit={handleSubmit}>
                <label className="signin-label">
                    <Field
                        name="email"
                        component={renderField}
                        type="text"
                        placeholder="Email address"
                        className="signin-input"
                        icon="email"
                    />
                </label>
                <label className="signin-label">
                    <Field
                        name="first_name"
                        component={renderField}
                        type="text"
                        placeholder="Your first name"
                        className="signin-input"
                        icon="username"
                    />
                </label>
                <label className="signin-label">
                    <Field
                        name="last_name"
                        component={renderField}
                        type="text"
                        placeholder="Your last name"
                        className="signin-input"
                        icon="username"
                    />
                </label>
                <label className="signin-label">
                    <Field
                        name="password"
                        component={renderPasswordField}
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder="Create password"
                        className="signin-input mod-pass"
                        icon="lock"
                        rightIcon={this.state.showPassword ? 'eye-close' : 'eye'}
                        onShowPassword={this.showPassword}
                    />
                </label>
                <label className="signin-label">
                    <Field
                        name="confirm_password"
                        component={renderPasswordField}
                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm password"
                        className="signin-input mod-pass"
                        icon="lock"
                        rightIcon={this.state.showConfirmPassword ? 'eye-close' : 'eye'}
                        onShowPassword={this.showConfirmPassword}
                    />
                </label>
                {!invitationData.inviteCode ? (
                    <label className="signin-label">
                        <Field
                            name="invite_code"
                            component={renderField}
                            type="text"
                            placeholder="Invite code"
                            className="signin-input"
                            icon="points"
                        />
                    </label>
                ) : null}
                <p className="legal-notice">
                    <input type="checkbox" className="registration-checkbox"></input> I am over the age of 18 and
                    consent to my personal data being processed by my institution, according to its Privacy Policy, and
                    being shared with The Ambassador Platform according to their{' '}
                    <a className="privacy-link" target="_blank" rel="noopener noreferrer" href="https://tap.st/privacy">
                        Privacy Notice,{' '}
                    </a>
                    <a
                        className="privacy-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://legal.theambassadorplatform.com/admin-user-terms"
                    >
                        Admin User Terms{' '}
                    </a>
                    and{' '}
                    <a
                        className="privacy-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://legal.theambassadorplatform.com/customer-terms"
                    >
                        Customer Terms
                    </a>{' '}
                    so that they can facilitate the management of content generation from representatives of my
                    institution and messaging interactions with prospects.
                </p>
                <button type="submit" className="signin-btn" style={{ marginTop: 20 }}>
                    Sign Up
                </button>
                <Divider plain>Or</Divider>
                {account.appClientId && (
                    <>
                        <Button className="signin-btn sign-up-sso-btn" onClick={this.ssoSignIn}>
                            Sign Up with SSO
                        </Button>
                    </>
                )}
                <Link className="forgot" to="/account/login">
                    Already have an account?
                </Link>
            </form>
        )
    }
}

export default reduxForm({
    form: 'registration_form',
    validate,
})(RegistrationForm)
