import { createAction } from '@reduxjs/toolkit'

import { ACCOUNT } from './account-types'

export const setMaintenanceMode = () => ({
    type: ACCOUNT.SET_MAINTENANCE_MODE,
})

export const finishPlsSignUpRegistration = (payload, onPlsSignUpRegistrationSuccess) => ({
    type: ACCOUNT.FINISH_PLS_SIGN_UP_REGISTRATION,
    payload,
    meta: {
        onPlsSignUpRegistrationSuccess,
    },
})

export const registerAccount = (data) => ({
    type: ACCOUNT.REGISTER,
    payload: data,
})

export const completePlsStep = (step) => ({
    type: ACCOUNT.COMPLETE_PLS_STEP,
    payload: step,
})

export const activatePlsAmbassadorToChat = (isActive, userId) => ({
    type: ACCOUNT.ACTIVATE_PLS_AMBASSADOR_TO_CHAT,
    payload: {
        isActive,
        userId,
    },
})

export const checkIfPlsAmbassadorLoggedIn = (userId) => ({
    type: ACCOUNT.IS_PLS_AMBASSADOR_LOGGED_IN,
    payload: userId,
})

export const getPlsTapFeedLink = (data, onGetPlsTapFeedLinkSuccess) => ({
    type: ACCOUNT.GET_PLS_TAP_FEED_LINK,
    payload: data,
    meta: {
        onGetPlsTapFeedLinkSuccess,
    },
})

export const activateToggleUniversitySsoOnly = (isSsoOnlyActivated = false) => ({
    type: ACCOUNT.ACTIVATE_TOGGLE_UNIVERSITY_SSO_ONLY,
    payload: { isSsoOnlyActivated },
})

export const getUniversitySso = (email, onSuccess = () => {}) => ({
    type: ACCOUNT.GET_UNIVERSITY_SSO_DATA,
    payload: { email },
    onSuccess,
})

export const changeOrganisationWebsite = ({ websiteUrl, institutionId, onSuccess }) => ({
    type: ACCOUNT.CHANGE_ORGANISATION_WEBSITE,
    payload: {
        websiteUrl,
        institutionId,
    },
    meta: {
        onSuccess,
    },
})

export const getProfileTypes = () => ({
    type: ACCOUNT.GET_PROFILE_TYPES,
})

export const setProfileType = ({ profileTypeId, onSuccess }) => ({
    type: ACCOUNT.SET_PROFILE_TYPE,
    payload: {
        profileTypeId,
    },
    meta: {
        onSuccess,
    },
})

export const completePlsOnboarding = () => ({
    type: ACCOUNT.COMPLETE_PLS_ONBOARDING,
})

export const setToggleExperienceBasedQuestions = ({ isAllowed, allowedType }) => ({
    type: ACCOUNT.SET_EXPERIANCE_BASED_QUESTIONS_TOGGLE,
    payload: { isAllowed, allowedType },
})

export const setOTPVerificationToggle = ({ isAllowed }) => ({
    type: ACCOUNT.SET_OTP_VERIFICATION_TOGGLE,
    payload: { isAllowed },
})

export const setThirdPartyAuthToggle = ({ isEnabled, permissionKey }) => ({
    type: ACCOUNT.SET_THIRD_PARTY_AUTH_TOGGLE,
    payload: {
        isEnabled,
        permissionKey,
    },
})

export const setFlagsToggle = ({ isEnabled, permissionKey }) => ({
    type: ACCOUNT.SET_FLAGS_TOGGLE,
    payload: {
        isEnabled,
        permissionKey,
    },
})

export const setMarketingConsent = ({ isShown, isDefault }) => ({
    type: ACCOUNT.SET_MARKETING_CONSENT,
    payload: {
        isShown,
        isDefault,
    },
})

export const setMarketingConsentText = ({ text, onSuccess }) => ({
    type: ACCOUNT.SET_MARKETING_CONSENT_CUSTOM_TEXT,
    payload: {
        text,
    },
    meta: {
        onSuccess,
    },
})

export const setIdpActivation = createAction(ACCOUNT.COMPLETE_IDP_ACIVATION)
