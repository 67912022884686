import { HttpService } from 'src/app/system/services/HttpService';
export default {
    getSevicesList: function () {
        return HttpService.get('/dashboard/idp/services/list');
    },
    getAmbassadorsList: function (data) {
        return HttpService.get('/dashboard/idp/services/ambassadors', data);
    },
    activateSevices: function (data) {
        return HttpService.post('/dashboard/idp/services/activate', data);
    },
};
