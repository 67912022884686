import { App, Input } from 'antd';
import React, { useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Button } from 'src/app/components/base/Button';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { sharePostToRSS } from 'src/app/redux/actions/content/postsActions';
import { getAccountInfo } from 'src/app/redux/selectors';
var TextArea = Input.TextArea;
export var PostShareForm = function (_a) {
    var post = _a.post, onClose = _a.onClose;
    var dispatch = useAppDispatch();
    var areaRef = useRef(null);
    var notification = App.useApp().notification;
    var accountInfo = useAppSelector(getAccountInfo);
    var caption = useMemo(function () {
        var _a, _b, _c;
        var caption = post.caption ? post.caption + '\n' : '';
        if (accountInfo != null && post.post_publisher != null) {
            caption += "Created by ".concat(((_a = post.post_publisher) === null || _a === void 0 ? void 0 : _a.name) || 'Deleted User');
            if (post.post_publisher.user_tags.student && post.post_publisher.user_tags.subject) {
                caption += ", who is studying ".concat(post.post_publisher.user_tags.subject[0].name);
            }
            else {
                caption += accountInfo.university.name.startsWith('The')
                    ? ", ".concat(accountInfo.university.name, " ambassador.")
                    : ", a ".concat(accountInfo.university.name, " ambassador.");
            }
            if (((_c = (_b = post.post_publisher.permissions) === null || _b === void 0 ? void 0 : _b.chat) === null || _c === void 0 ? void 0 : _c.is_enabled) && post.post_publisher.name) {
                caption += "\nChat to ".concat(post.post_publisher.name, " at ").concat(accountInfo.university.university_url, "?user_id=").concat(post.post_publisher.id);
            }
        }
        return caption;
    }, [post, accountInfo]);
    var handleClick = function () {
        var _a, _b;
        var caption = ((_b = (_a = areaRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea) === null || _b === void 0 ? void 0 : _b.textArea.value) || '';
        dispatch(sharePostToRSS(post.id, caption, function () {
            onClose();
            notification.success({
                message: 'Post shared',
                description: 'Post has been shared',
                placement: 'topRight',
            });
        }));
    };
    return (React.createElement("div", { className: "grid grid-cols-2 gap-6" },
        React.createElement("div", null, post.type === 'image' ? (React.createElement("img", { className: "w-full max-h-[30rem] object-cover", src: post.images[0].original, alt: post.caption })) : (React.createElement(ReactPlayer, { key: post.id, className: "w-full max-h-[30rem] object-cover", url: post.videos[0].qualities['370x370'] || post.videos[0].original, "aria-label": "", width: "100%", height: "100%", playing: false, pip: true, playsinline: true, loop: true, controls: true, config: {
                file: {
                    attributes: {
                        controlsList: 'nodownload',
                        pip: 'true',
                    },
                },
            }, onContextMenu: function (event) { return event.preventDefault(); } }))),
        React.createElement("div", null,
            React.createElement("p", { className: "share-post-caption" },
                "Created by",
                ' ',
                post.post_publisher && post.post_publisher.name ? post.post_publisher.name : 'Deleted User',
                ",",
                ' ',
                post.createdAtHumanized),
            React.createElement("div", { className: "pt-2" },
                React.createElement(TextArea, { rows: 5, placeholder: "Caption", defaultValue: caption, ref: areaRef })),
            React.createElement(Button, { type: "secondary", "data-automation-id": "share", size: "large", className: "mt-4 float-right", onClick: handleClick }, "Share"))));
};
