import {
    GET_NOTIFICATIONS_LIST_SUCCESS,
    GET_NOTIFICATIONS_LIST_FAILED,
    DISABLE_TOGGLE,
    RECIEVE_NOTIFICATIONS,
} from '../../../actions/settings/notifications-control'

import { NOTIFICATIONS_LIST } from '../../../store/initialState'

const initialState = NOTIFICATIONS_LIST

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload

    switch (type) {
        case GET_NOTIFICATIONS_LIST_SUCCESS: {
            const newState = Object.assign({}, state, {
                items: payload.items,
                receiveNotifications: payload.items.some((i) => i.is_active),
            })
            return newState
        }
        case RECIEVE_NOTIFICATIONS: {
            return {
                ...state,
                receiveNotifications: payload,
            }
        }
        case DISABLE_TOGGLE: {
            return {
                ...state,
                disableToggle: payload,
            }
        }
        case GET_NOTIFICATIONS_LIST_FAILED: {
            return initialState
        }
        default: {
            return state
        }
    }
}
