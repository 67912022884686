export const EventParticipantModel = (clientId, user) => {
    return {
        id: clientId,
        user,
        media: null,
        screenMedia: null,
        view: null,
        screenView: null,
        videoMuted: false,
        audioMuted: false,
    }
}
