import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Select from 'antd/es/select'
import DatesIntervalSelector from '../../../components/DatesIntervalSelector'
import { getDateRange } from '../../../helpers/time'
import LineChart from './LineChart'
import {
    numberTooltipFormatter,
    pluralize,
    prepareSpecificNumberData,
    prepareTimeData,
    timeTooltipFormatter,
    yAxisTimeFormatter,
} from '../../../helpers/chart'
import { NavLink } from 'react-router-dom'
import { ReactComponent as ArrowRightIcon } from '../../../../assets/svg/icons/arrow-right.svg'
import { getCommonRoutePath } from '../../../helpers/helpers'

const dropChartOptions = [
    {
        key: 'prospectAccounts',
        name: 'Prospect accounts created',
    },
    {
        key: 'messagesSent',
        name: 'Number of messages',
    },
    {
        key: 'conversion',
        name: 'Conversion to sign up',
    },
    {
        key: 'ambassadorResponseTime',
        name: 'Median first response time',
    },
]

const chartOption = {
    title: 'prospect',
}

class ChartBlock extends PureComponent {
    constructor(props) {
        super()
        this.state = {
            selectedButton: '7Days',
            selectedDateRange: getDateRange(7),
            contentLastFetchDateRange: {},
        }
    }

    prepareChartData(data, fieldName, from, to) {
        return prepareSpecificNumberData(data, fieldName, [
            [Date.parse(from), 0],
            [Date.parse(to), 0],
        ])
    }

    prepareChartTimeData(data, from, to) {
        return prepareTimeData(data, [
            [Date.parse(from), 0],
            [Date.parse(to), 0],
        ])
    }

    tooltipFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, chartOption.title))
    }

    tooltipConversionFormatter() {
        return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, chartOption.title, '%'))
    }

    yAxisResponseTimeChart() {
        return yAxisTimeFormatter(this.value)
    }

    responseTimeTooltipFormatter() {
        return timeTooltipFormatter(this.point.x, this.point.y)
    }

    render() {
        const {
            data,
            currentChartOption,
            selectedButton,
            selectedDateRange,
            onDatesIntervalSelectorUpdate,
            onChartDropChange,
        } = this.props
        let chartData = {}
        try {
            switch (currentChartOption) {
                case 'prospectAccounts': {
                    chartOption.title = 'prospect'
                    chartData = this.prepareChartData(
                        data.items,
                        'count',
                        selectedDateRange.fromDate,
                        selectedDateRange.toDate
                    )

                    break
                }
                case 'messagesSent': {
                    chartOption.title = 'message'
                    chartData = this.prepareChartData(
                        data.items,
                        'count',
                        selectedDateRange.fromDate,
                        selectedDateRange.toDate
                    )

                    break
                }
                case 'conversion': {
                    chartOption.title = ''
                    chartData = this.prepareChartData(
                        data.items,
                        'conversion',
                        selectedDateRange.fromDate,
                        selectedDateRange.toDate
                    )

                    break
                }
                case 'ambassadorResponseTime': {
                    chartOption.title = ''
                    chartData = this.prepareChartTimeData(
                        data.items,
                        selectedDateRange.fromDate,
                        selectedDateRange.toDate
                    )

                    break
                }
                // No default
            }
        } catch (error) {
            console.log(error)
        }

        const commonPath = getCommonRoutePath()

        return (
            <div className="recent-activity-block">
                <div className="graph-title-block">
                    <span className="graph-title">Recent Activity</span>
                    <NavLink to={commonPath + '/reporting/overview'} target="_blank" className="reporting-link">
                        <span>Go to Reporting</span> <ArrowRightIcon />
                    </NavLink>
                </div>
                <div className="chart-header">
                    <Select
                        value={currentChartOption}
                        onChange={(value) => onChartDropChange(value)}
                        size={'large'}
                        style={{ width: 250 }}
                    >
                        {dropChartOptions.map((item) => (
                            <Select.Option key={item.key} value={item.key}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <DatesIntervalSelector
                        selectedButton={selectedButton}
                        onChange={onDatesIntervalSelectorUpdate}
                        topAlign={4}
                        hideDateRange={true}
                    />
                </div>
                <div className="main-graph">
                    {!data.items || data.items.length === 0 ? (
                        <p className="no-data-title">No available Data</p>
                    ) : (
                        <div className="chart-label-wrapper main-graph-label-wrapper">
                            <div className="chart-label-item-wrapper">
                                <div className="chart-label">
                                    {(currentChartOption !== 'ambassadorResponseTime' ? 'Total: ' : '') + data.total}
                                </div>
                                <div className="chart-label-description">Across date range</div>
                            </div>
                        </div>
                    )}
                    {currentChartOption === 'ambassadorResponseTime' ? (
                        <LineChart
                            data={chartData}
                            tooltipFormatter={this.responseTimeTooltipFormatter}
                            yAxisFormatter={this.yAxisResponseTimeChart}
                        />
                    ) : (
                        <LineChart
                            data={chartData}
                            tooltipFormatter={
                                currentChartOption === 'conversion'
                                    ? this.tooltipConversionFormatter
                                    : this.tooltipFormatter
                            }
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        data: state.homeChart,
    }
})(ChartBlock)
