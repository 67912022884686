var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b;
import API from '../../../api';
import { createSelector } from '@reduxjs/toolkit';
import { createAsyncSlice } from '../sliceCreator';
import CommunityDialogModel from '../../../models/community-dialog-model';
import { RESET_CHAT_MESSAGES_COUNTER } from '../../actions/chat/messagingActions';
var initialState = {
    communityChats: [],
    communitiesProspectMembers: [],
    chat: undefined,
    loading: false,
    listLoading: false,
    dialogLoading: false,
    error: '',
    offset: 0,
    limit: 20,
    total: null,
    totalMembers: null,
};
var communitySlice = createAsyncSlice({
    name: 'community',
    initialState: initialState,
    reducers: function (create) { return ({
        getCommunities: create.asyncThunk(function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, API.communities.getCommunities(data)];
            });
        }); }, {
            pending: function (state) {
                state.listLoading = true;
            },
            fulfilled: function (state, action) {
                var offset = action.meta.arg.offset;
                state.listLoading = false;
                state.communityChats = offset
                    ? __spreadArray(__spreadArray([], state.communityChats, true), action.payload.data.dialogs, true) : action.payload.data.dialogs;
                state.offset = offset || 0;
                state.total = action.payload.data.total;
            },
        }),
        getCommunity: create.asyncThunk(function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, API.communities.getCommunity(data)];
            });
        }); }, {
            pending: function (state) {
                state.dialogLoading = true;
            },
            fulfilled: function (state, action) {
                state.chat = CommunityDialogModel(action.payload.data.dialog);
            },
            settled: function (state) {
                state.dialogLoading = false;
            },
        }),
        updateCommunity: create.asyncThunk(function (_a, _b) {
            var data = _a.data, id = _a.id;
            var rejectWithValue = _b.rejectWithValue;
            return __awaiter(void 0, void 0, void 0, function () {
                var formData, res;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            formData = new FormData();
                            Object.keys(data).forEach(function (key) {
                                if (data[key] != null) {
                                    switch (key) {
                                        case 'tags':
                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                            if (data[key].length > 0) {
                                                formData.append('tags', JSON.stringify(data[key]));
                                            }
                                            break;
                                        default:
                                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                            formData.append(key, data[key]);
                                            break;
                                    }
                                }
                            });
                            return [4 /*yield*/, API.communities.updateCommunity(id, formData)];
                        case 1:
                            res = _c.sent();
                            if (res.success) {
                                return [2 /*return*/, API.communities.getCommunity({ id: id })];
                            }
                            return [2 /*return*/, rejectWithValue({ success: false })];
                    }
                });
            });
        }, {
            fulfilled: function (state, action) {
                if (action.payload.success) {
                    state.chat = CommunityDialogModel(action.payload.data.dialog);
                    var index = state.communityChats.findIndex(function (community) { var _a; return community.id === ((_a = state.chat) === null || _a === void 0 ? void 0 : _a.id); });
                    if (index > -1) {
                        state.communityChats[index].name = state.chat.name;
                    }
                }
            },
        }),
        updateCommunityMembers: create.asyncThunk(function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, API.communities.updateCommunityMembers(data)];
            });
        }); }, {
            pending: function (state) {
                state.loading = false;
            },
            fulfilled: function (state, action) {
                var _a = action.meta.arg, userIds = _a.user_ids, dialogId = _a.dialog_id;
                if (action.payload.success && userIds && dialogId) {
                    var community = state.communityChats.find(function (c) { return c.id === dialogId; });
                    if (community) {
                        community.members.ambassadors = userIds.length;
                    }
                }
            },
        }),
        resetCurrentCommunity: create.reducer(function (state) {
            state.chat = undefined;
        }),
        getCommunitiesProspectMembers: create.asyncThunk(function (data) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, API.communities.getCommunitiesMembers(data)];
            });
        }); }, {
            fulfilled: function (state, action) {
                state.communitiesProspectMembers = action.payload.data.members;
                state.totalMembers = action.payload.data.total;
            },
        }),
        getNewCommunityMessagesCount: create.asyncThunk(function (id) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, API.dialog.getDialogNewMessagesCount(id)];
            });
        }); }, {
            fulfilled: function (state, action) {
                var chat = state.communityChats.find(function (c) { return c.id === action.meta.arg; });
                if (chat && action.payload.success) {
                    chat.new_messages = action.payload.data.newMessagesCount;
                }
            },
        }),
        updateCommunityVisibility: create.asyncThunk(function (_a) {
            var isHidden = _a.isHidden, id = _a.id;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, API.communities.updateCommunityVisibility(id, isHidden)];
                });
            });
        }, {
            fulfilled: function (state, action) {
                var chat = state.chat;
                if (chat && chat.id === action.meta.arg.id) {
                    chat.isHidden = action.meta.arg.isHidden;
                }
            },
        }),
    }); },
    extraReducers: function (builder) {
        builder.addCase(RESET_CHAT_MESSAGES_COUNTER, function (state, action) {
            if (action.payload.dialogId) {
                var chat = state.communityChats.find(function (c) { return c.id === action.payload.dialogId; });
                if (chat) {
                    chat.new_messages = '0';
                }
            }
        });
    },
    selectors: {
        // TODO: fix selector, memoization doesn't work
        getCommunitiesList: createSelector(function (sliceState) { return sliceState; }, function (_a) {
            var communities = _a.communityChats, listLoading = _a.listLoading, offset = _a.offset, limit = _a.limit, total = _a.total;
            return {
                communities: communities,
                listLoading: listLoading,
                total: total,
                offset: offset,
                limit: limit,
            };
        }),
        getCurrentCommunity: createSelector(function (sliceState) { return sliceState; }, function (_a) {
            var community = _a.chat, dialogLoading = _a.dialogLoading;
            return {
                community: community,
                dialogLoading: dialogLoading,
            };
        }),
        selectCommunityChats: function (state) { return state.communityChats; },
        selectHasCommunities: function (state) { return state.communityChats.length > 0; },
        selectProspectMembers: function (state) { return state.communitiesProspectMembers; },
        selectHasProspectMembers: function (state) { return state.communitiesProspectMembers.length > 0; },
        selectTotalMembers: function (state) { return state.totalMembers; },
        selectIsChatHidden: function (state) { var _a; return (_a = state.chat) === null || _a === void 0 ? void 0 : _a.isHidden; },
    },
});
export var getCommunities = (_a = communitySlice.actions, _a.getCommunities), getCommunity = _a.getCommunity, updateCommunityMembers = _a.updateCommunityMembers, resetCurrentCommunity = _a.resetCurrentCommunity, updateCommunity = _a.updateCommunity, getCommunitiesProspectMembers = _a.getCommunitiesProspectMembers, getNewCommunityMessagesCount = _a.getNewCommunityMessagesCount, updateCommunityVisibility = _a.updateCommunityVisibility;
export var getCommunitiesList = (_b = communitySlice.selectors, _b.getCommunitiesList), getCurrentCommunity = _b.getCurrentCommunity, selectCommunityChats = _b.selectCommunityChats, selectHasCommunities = _b.selectHasCommunities, selectHasProspectMembers = _b.selectHasProspectMembers, selectProspectMembers = _b.selectProspectMembers, selectTotalMembers = _b.selectTotalMembers, selectIsChatHidden = _b.selectIsChatHidden;
export default communitySlice.reducer;
