var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Form } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'src/app/components/base/Button';
import { useAppDispatch } from 'src/app/hooks/store';
import { registerAccount } from 'src/app/redux/actions/account/account-actions';
import Logo from 'src/assets/svg/logo.svg';
import { LastStepFields } from '../LastStepFields';
import { ProfileSetupFields } from '../ProfileSetupFields';
import { UserInfoFields } from '../UserInfoFields';
import { RegisteringTypeStep } from '../registering-type-step';
export var SignUpMobile = function () {
    var form = Form.useForm()[0];
    var dispatch = useAppDispatch();
    var _a = useState(false), isTypeSelected = _a[0], setIsTypeSelected = _a[1];
    var layoutRef = useRef(null);
    var onFinish = useCallback(function () {
        form.validateFields().then(function (values) {
            var terms = values.terms, confirmPassword = values.confirmPassword, requestValues = __rest(values, ["terms", "confirmPassword"]);
            dispatch(registerAccount(__assign(__assign({}, requestValues), { isTrial: true })));
        });
    }, [form, dispatch]);
    var handlePhoneInput = useCallback(function (phone, dialCode) {
        form.setFieldsValue({
            phoneNumber: phone.replace(/[\s()+]/g, '').slice(dialCode.length),
            phoneCountry: dialCode,
        });
        form.validateFields(['phoneNumber']);
    }, [form]);
    var handleTypeSelect = useCallback(function () {
        setIsTypeSelected(true);
    }, []);
    useEffect(function () {
        if (layoutRef.current && isTypeSelected) {
            layoutRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [isTypeSelected]);
    return (React.createElement("div", { ref: layoutRef, className: "grid grid-rows-[auto_auto_1fr] bg-[#F8FAFC]" },
        React.createElement("div", { className: "h-14 bg-white shadow-md flex justify-center items-end" },
            React.createElement("img", { src: Logo, alt: "Logo", className: "h-13" })),
        React.createElement("div", { className: "flex flex-col justify-center items-center px-4 pt-5 pb-1.5" },
            React.createElement("h3", { className: "font-interface-bold text-[2.8rem]" }, "Create your account"),
            React.createElement("p", { className: "text-center text-[color:var(--explainer-text)] text-reading-lg" }, "Complete your profile in order to start working with your dashboard")),
        React.createElement("div", { className: " px-4 mt-3" },
            React.createElement("object", { className: "w-full", type: "image/svg+xml", data: "https://cdn.theaccessplatform.com/img/mobile_graphs_pls.svg" })),
        React.createElement("div", { className: isTypeSelected ? 'hidden' : undefined },
            React.createElement(RegisteringTypeStep, { onTypeSelect: handleTypeSelect, layoutRef: layoutRef })),
        React.createElement("div", { className: !isTypeSelected ? 'hidden' : 'mb-6 px-4  pls-sign-up-form' },
            React.createElement(Form, { name: "sign-up-form", layout: "vertical", requiredMark: false, form: form, onFinish: onFinish },
                React.createElement(UserInfoFields, { handlePhoneInput: handlePhoneInput }),
                React.createElement(ProfileSetupFields, null),
                React.createElement(LastStepFields, null),
                React.createElement(Button, { className: "pls-continue-button", htmlType: "submit" }, "Get started")))));
};
