import { createAction } from '@reduxjs/toolkit';
export var CREATE_GLOBAL_TAG = 'CREATE_GLOBAL_TAG';
export var CREATE_GLOBAL_TAG_SUCCESS = 'CREATE_GLOBAL_TAG_SUCCESS';
export var CREATE_GLOBAL_TAG_FAILED = 'CREATE_GLOBAL_TAG_FAILED';
export var GET_GLOBAL_LIST_TAGS = 'GET_GLOBAL_LIST_TAGS';
export var GET_GLOBAL_LIST_TAGS_SUCCESS = 'GET_GLOBAL_LIST_TAGS_SUCCESS';
export var GET_GLOBAL_LIST_TAGS_FAILED = 'GET_GLOBAL_LIST_TAGS_FAILED';
export var GET_GLOBAL_POPULAR_LIST_TAGS = 'GET_GLOBAL_POPULAR_LIST_TAGS';
export var GET_GLOBAL_POPULAR_LIST_TAGS_SUCCESS = 'GET_GLOBAL_POPULAR_LIST_TAGS_SUCCESS';
export var GET_GLOBAL_POPULAR_LIST_TAGS_FAILED = 'GET_GLOBAL_POPULAR_LIST_TAGS_FAILED';
export var CREATE_GLOBAL_USER_TAG = 'CREATE_GLOBAL_USER_TAG';
export var CREATE_GLOBAL_USER_TAG_SUCCESS = 'CREATE_GLOBAL_USER_TAG_SUCCESS';
export var CREATE_GLOBAL_USER_TAG_FAILED = 'CREATE_GLOBAL_USER_TAG_FAILED';
export var GET_GLOBAL_USER_TAGS = 'GET_GLOBAL_USER_TAGS';
export var GET_GLOBAL_USER_TAGS_SUCCESS = 'GET_GLOBAL_USER_TAGS_SUCCESS';
export var GET_GLOBAL_USER_TAGS_FAILED = 'GET_GLOBAL_USER_TAGS_FAILED';
export var REMOVE_GLOBAL_USER_TAG = 'REMOVE_GLOBAL_USER_TAG';
export var REMOVE_GLOBAL_USER_TAG_SUCCESS = 'REMOVE_GLOBAL_USER_TAG_SUCCESS';
export var REMOVE_GLOBAL_USER_TAG_FAILED = 'REMOVE_GLOBAL_USER_TAG_FAILED';
export var CREATE_GLOBAL_POST_TAG = 'CREATE_GLOBAL_POST_TAG';
export var CREATE_GLOBAL_POST_TAG_SUCCESS = 'CREATE_GLOBAL_POST_TAG_SUCCESS';
export var CREATE_GLOBAL_POST_TAG_FAILED = 'CREATE_GLOBAL_POST_TAG_FAILED';
export var GET_GLOBAL_POST_TAGS = 'GET_GLOBAL_POST_TAGS';
export var GET_GLOBAL_POST_TAGS_SUCCESS = 'GET_GLOBAL_POST_TAGS_SUCCESS';
export var GET_GLOBAL_POST_TAGS_FAILED = 'GET_GLOBAL_POST_TAGS_FAILED';
export var REMOVE_GLOBAL_POST_TAG = 'REMOVE_GLOBAL_POST_TAG';
export var REMOVE_GLOBAL_POST_TAG_SUCCESS = 'REMOVE_GLOBAL_POST_TAG_SUCCESS';
export var REMOVE_GLOBAL_POST_TAG_FAILED = 'REMOVE_GLOBAL_POST_TAG_FAILED';
export var CREATE_GLOBAL_FAQ_TAG = 'CREATE_GLOBAL_FAQ_TAG';
export var CREATE_GLOBAL_FAQ_TAG_SUCCESS = 'CREATE_GLOBAL_FAQ_TAG_SUCCESS';
export var CREATE_GLOBAL_FAQ_TAG_FAILED = 'CREATE_GLOBAL_FAQ_TAG_FAILED';
export var GET_GLOBAL_FAQ_TAGS = 'GET_GLOBAL_FAQ_TAGS';
export var GET_GLOBAL_FAQ_TAGS_SUCCESS = 'GET_GLOBAL_FAQ_TAGS_SUCCESS';
export var GET_GLOBAL_FAQ_TAGS_FAILED = 'GET_GLOBAL_FAQ_TAGS_FAILED';
export var REMOVE_GLOBAL_FAQ_TAG = 'REMOVE_GLOBAL_FAQ_TAG';
export var REMOVE_GLOBAL_FAQ_TAG_SUCCESS = 'REMOVE_GLOBAL_FAQ_TAG_SUCCESS';
export var REMOVE_GLOBAL_FAQ_TAG_FAILED = 'REMOVE_GLOBAL_FAQ_TAG_FAILED';
export var SET_CURRENT_SEARCH_LIST_TAGS = 'SET_CURRENT_SEARCH_LIST_TAGS';
export var getPopularTags = createAction(GET_GLOBAL_POPULAR_LIST_TAGS);
