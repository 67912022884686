import React, { useCallback, useState } from 'react'
import { Form, Input } from 'antd'
import dayjs from 'dayjs'

import { useDispatch } from 'react-redux'

import pencil from '../../../../../../assets/img/pencil.png'

import {
    changeAmbassadorJobRole,
    changeAmbassadorWorkingSince,
} from '../../../../../redux/actions/content/usersActions'
import DatePicker from '../../../../date-picker/date-picker'

const AmbassadorEmployeeJobRole = ({ jobRole, userId, saveStudentInfo, additionalData }) => {
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()

    const date = additionalData && additionalData.working_since ? new Date(additionalData.working_since) : null

    const onFinish = useCallback(
        (values) => {
            dispatch(
                changeAmbassadorJobRole({
                    userId,
                    data: [
                        {
                            name: values.jobRole,
                            key: values.jobRole,
                        },
                    ],
                })
            )

            dispatch(
                changeAmbassadorWorkingSince({
                    userId,
                    workingSince: values.workingSince,
                })
            )

            setEditMode(false)
            saveStudentInfo()
        },
        [dispatch, userId, saveStudentInfo]
    )

    const editType = useCallback(() => {
        setEditMode(true)
    }, [])

    const onCancel = useCallback(() => {
        setEditMode(false)
    }, [])

    return editMode ? (
        <>
            <Form
                initialValues={{
                    jobRole: jobRole ? jobRole[0].name.charAt(0).toUpperCase() + jobRole[0].name.slice(1) : '',
                    workingSince: date,
                }}
                onFinish={onFinish}
                autoComplete="off"
                hideRequiredMark
            >
                <Form.Item
                    name="jobRole"
                    label="Job role"
                    style={{ marginBottom: 20 }}
                    rules={[{ required: true, message: 'Add job role' }]}
                >
                    <Input maxLength={50} style={{ fontSize: 16, minWidth: 150 }} />
                </Form.Item>
                <Form.Item
                    name="workingSince"
                    label="In this role since"
                    style={{ marginBottom: 10 }}
                    rules={[{ required: true, message: 'Choose date' }]}
                >
                    <DatePicker
                        placeholder="Choose date"
                        allowClear={false}
                        style={{ width: '100%' }}
                        showToday={false}
                    />
                </Form.Item>
                <div className="input__buttons">
                    <button className="input-control-button" onClick={onCancel}>
                        Cancel
                    </button>
                    <button className="input-control-button" type="submit">
                        Save
                    </button>
                </div>
            </Form>
        </>
    ) : (
        <>
            <div className="profile-info-tags-items">
                <div>
                    {jobRole ? (
                        jobRole[0].name.charAt(0).toUpperCase() + jobRole[0].name.slice(1)
                    ) : (
                        <p style={{ color: 'red' }}>Please, specify</p>
                    )}
                    <p className="post-no-caption">
                        (since{' '}
                        {additionalData &&
                            additionalData.working_since &&
                            dayjs(additionalData.working_since).format('MMM YYYY')}
                        )
                    </p>
                </div>

                <button className="input-control-button" onClick={editType}>
                    <img className="pencil-icon" src={pencil} alt="" />
                </button>
            </div>
        </>
    )
}

export default AmbassadorEmployeeJobRole
