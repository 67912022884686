import { SHARED_POSTS_INFO_SUCCESS } from '../../../actions/settings/sharing'

import { SHARED_POSTS } from '../../../store/initialState'

const initialState = SHARED_POSTS

export default function (state = initialState, action) {
    const type = action.type
    const payload = action.payload
    switch (type) {
        case SHARED_POSTS_INFO_SUCCESS: {
            const newState = Object.assign({}, state, {
                items: payload.items,
                total: payload.total,
            })

            return newState
        }

        default: {
            return state
        }
    }
}
