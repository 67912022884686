const NAMESPACE_PREFIX = 'ACCOUNT'

export const ACCOUNT = {
    SET_MAINTENANCE_MODE: `${NAMESPACE_PREFIX}.SET_MAINTENANCE_MODE`,
    REGISTER: `${NAMESPACE_PREFIX}.REGISTER`,
    UPDATE_ACCOUNT_INFO: `${NAMESPACE_PREFIX}.UPDATE_ACCOUNT_INFO`,
    UPDATE_ACCOUNT_INFO_SUCCESS: `${NAMESPACE_PREFIX}.UPDATE_ACCOUNT_INFO_SUCCESS`,
    UPDATE_ACCOUNT_INFO_FAILED: `${NAMESPACE_PREFIX}.UPDATE_ACCOUNT_INFO_FAILED`,
    COMPLETE_PLS_STEP: `${NAMESPACE_PREFIX}.COMPLETE_PLS_STEP`,
    ACTIVATE_PLS_AMBASSADOR_TO_CHAT: `${NAMESPACE_PREFIX}.ACTIVATE_PLS_AMBASSADOR_TO_CHAT`,
    ACTIVATE_PLS_AMBASSADOR_TO_CHAT_SUCCESS: `${NAMESPACE_PREFIX}.ACTIVATE_PLS_AMBASSADOR_TO_CHAT_SUCCESS`,
    IS_PLS_AMBASSADOR_LOGGED_IN: `${NAMESPACE_PREFIX}.IS_PLS_AMBASSADOR_LOGGED_IN`,
    IS_PLS_AMBASSADOR_LOGGED_IN_SUCCESS: `${NAMESPACE_PREFIX}.IS_PLS_AMBASSADOR_LOGGED_IN_SUCCESS`,
    GET_PLS_TAP_FEED_LINK: `${NAMESPACE_PREFIX}.GET_PLS_TAP_FEED_LINK`,
    GET_PLS_TAP_FEED_LINK_SUCCESS: `${NAMESPACE_PREFIX}.GET_PLS_TAP_FEED_LINK_SUCCESS`,
    PLS_SETUP_WIZARD_FINISH: `${NAMESPACE_PREFIX}.PLS_SETUP_WIZARD_FINISH`,
    EDIT_PRIVACY_POLICY: `${NAMESPACE_PREFIX}.EDIT_PRIVACY_POLICY`,
    EDIT_PRIVACY_POLICY_SUCCESS: `${NAMESPACE_PREFIX}.EDIT_PRIVACY_POLICY_SUCCESS`,
    EDIT_PRIVACY_POLICY_FAILED: `${NAMESPACE_PREFIX}.EDIT_PRIVACY_POLICY_FAILED`,
    SSO_LOGIN: `${NAMESPACE_PREFIX}.SSO_LOGIN`,
    GET_UNIVERSITY_SSO_DATA: `${NAMESPACE_PREFIX}.GET_UNIVERSITY_SSO_DATA`,
    ACTIVATE_TOGGLE_UNIVERSITY_SSO_ONLY: `${NAMESPACE_PREFIX}.ACTIVATE_TOGGLE_UNIVERSITY_SSO_ONLY`,
    ACTIVATE_TOGGLE_UNIVERSITY_SSO_ONLY_SUCCESS: `${NAMESPACE_PREFIX}.ACTIVATE_TOGGLE_UNIVERSITY_SSO_ONLY_SUCCESS`,
    UNIVERSITY_SSO_SUCCESS: `${NAMESPACE_PREFIX}.UNIVERSITY_SSO_SUCCESS`,
    CHANGE_ORGANISATION_WEBSITE: `${NAMESPACE_PREFIX}.CHANGE_ORGANISATION_WEBSITE`,
    CHANGE_ORGANISATION_WEBSITE_SUCCESS: `${NAMESPACE_PREFIX}.CHANGE_ORGANISATION_WEBSITE_SUCCESS`,
    CHANGE_ORGANISATION_WEBSITE_FAILED: `${NAMESPACE_PREFIX}.CHANGE_ORGANISATION_WEBSITE_FAILED`,
    FINISH_PLS_SIGN_UP_REGISTRATION: `${NAMESPACE_PREFIX}.FINISH_PLS_SIGN_UP_REGISTRATION`,
    SET_PLS_SIGN_UP_STEP: `${NAMESPACE_PREFIX}.SET_PLS_SIGN_UP_STEP`,
    GET_PROFILE_TYPES: `${NAMESPACE_PREFIX}.GET_PROFILE_TYPES`,
    GET_PROFILE_TYPES_SUCCESS: `${NAMESPACE_PREFIX}.GET_PROFILE_TYPES_SUCCESS`,
    GET_PROFILE_TYPES_FAILED: `${NAMESPACE_PREFIX}.GET_PROFILE_TYPES_FAILED`,
    SET_PROFILE_TYPE: `${NAMESPACE_PREFIX}.SET_PROFILE_TYPE`,
    SET_PROFILE_TYPE_SUCCESS: `${NAMESPACE_PREFIX}.SET_PROFILE_TYPE_SUCCESS`,
    SET_PROFILE_TYPE_FAILED: `${NAMESPACE_PREFIX}.SET_PROFILE_TYPE_FAILED`,
    COMPLETE_PLS_ONBOARDING: `${NAMESPACE_PREFIX}.COMPLETE_PLS_ONBOARDING`,
    HUBSPOT_LOGIN: `${NAMESPACE_PREFIX}.HUBSPOT_LOGIN`,
    HUBSPOT_LOGIN_SUCCESS: `${NAMESPACE_PREFIX}.HUBSPOT_LOGIN_SUCCESS`,
    SET_EXPERIANCE_BASED_QUESTIONS_TOGGLE: `${NAMESPACE_PREFIX}.SET_EXPERIANCE_BASED_QUESTIONS_TOGGLE`,
    SET_EXPERIANCE_BASED_QUESTIONS_TOGGLE_SUCCESS: `${NAMESPACE_PREFIX}.SET_EXPERIANCE_BASED_QUESTIONS_TOGGLE_SUCCESS`,
    SET_OTP_VERIFICATION_TOGGLE: `${NAMESPACE_PREFIX}.SET_OTP_VERIFICATION_TOGGLE`,
    SET_OTP_VERIFICATION_TOGGLE_SUCCESS: `${NAMESPACE_PREFIX}.SET_OTP_VERIFICATION_TOGGLE_SUCCESS`,
    SET_THIRD_PARTY_AUTH_TOGGLE: `${NAMESPACE_PREFIX}.SET_THIRD_PARTY_AUTH_TOGGLE`,
    SET_MARKETING_CONSENT: `${NAMESPACE_PREFIX}.SET_MARKETING_CONSENT`,
    SET_MARKETING_CONSENT_SUCCESS: `${NAMESPACE_PREFIX}.SET_MARKETING_CONSENT_SUCCESS`,
    SET_MARKETING_CONSENT_FAILED: `${NAMESPACE_PREFIX}.SET_MARKETING_CONSENT_FAILED`,
    SET_MARKETING_CONSENT_CUSTOM_TEXT: `${NAMESPACE_PREFIX}.SET_MARKETING_CONSENT_CUSTOM_TEXT`,
    SET_MARKETING_CONSENT_CUSTOM_TEXT_SUCCESS: `${NAMESPACE_PREFIX}.SET_MARKETING_CONSENT_CUSTOM_TEXT_SUCCESS`,
    SET_MARKETING_CONSENT_CUSTOM_TEXT_FAILED: `${NAMESPACE_PREFIX}.SET_MARKETING_CONSENT_CUSTOM_TEXT_FAILED`,
    SET_FLAGS_TOGGLE: `${NAMESPACE_PREFIX}.SET_FLAGS_TOGGLE`,
    SET_PERMISSON_TOGGLE_SUCCESS: `${NAMESPACE_PREFIX}.SET_PERMISSON_TOGGLE_SUCCESS`,
    COMPLETE_IDP_ACIVATION: `${NAMESPACE_PREFIX}.COMPLETE_IDP_ACIVATION`,
}
