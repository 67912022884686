import React, { PureComponent } from 'react'
import store from '../../../redux/store'
import { connect } from 'react-redux'
import config from '../../../system/config'
import './styles.css'
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'

import { USERS_INFO_REQUESTED } from '../../../redux/actions/content/usersActions'
import Notifications from 'react-notification-system-redux'
import { isAllowed } from '../../../helpers/helpers'
import { RestrictedModal } from '../../../components/modals/RestrictedModal'
import { Protect } from 'src/app/permissions/Protect'

const PRESS_ENTER = 13
const PRESS_BACKSPACE = 8

const reloadIframe = () => {
    // we could just change key
    const iframe = document.querySelector('#tap-iframe-widget')
    const src = iframe.src
    iframe.src = ''
    setTimeout(() => (iframe.src = src), 1000)
}

class Carousel extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            tags: [],
            tagsInput: '',
            campaign: '',
            source: '',
            ambassadors: [],
            isAutoplay: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.handleToggleCarouselAutoplay = this.handleToggleCarouselAutoplay.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: USERS_INFO_REQUESTED,
            payload: {
                filter: {
                    university_id: this.props.account.account_info.university_id,
                },
                limit: this.props.users.limit,
                offset: this.props.users.offset,
            },
        })

        store.dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'promotion',
            },
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.account.currentGroupUniversityId !== this.props.account.currentGroupUniversityId) {
            store.dispatch({
                type: USERS_INFO_REQUESTED,
                payload: {
                    filter: {
                        university_id: this.props.account.account_info.university_id,
                    },
                    limit: this.props.users.limit,
                    offset: this.props.users.offset,
                },
            })
        }
    }

    handleChange({ target }) {
        const { value, name } = target
        this.setState((state) => ({
            ...state,
            [name]: name === 'tagsInput' ? value : value.replace(/\s/gm, ''),
        }))
    }

    handleKeyUp(e) {
        const key = e.keyCode

        if (key === PRESS_ENTER) {
            this.addNewTag()
        }
    }

    handleKeyDown(e) {
        const key = e.keyCode
        if (key === PRESS_BACKSPACE && !this.state.tagsInput) {
            this.editPreviousTag()
        }
    }

    addNewTag() {
        const { tags, tagsInput } = this.state
        const tag = tagsInput.trim()

        if (!tag) {
            return
        }
        if (tags.length === 10) {
            store.dispatch(
                Notifications.error({
                    title: 'Error',
                    message: "You can't add more than 10 terms.",
                    position: 'tr',
                    action: '',
                })
            )
            return
        }

        this.setState(
            {
                tags: [...tags, tag],
                tagsInput: '',
            },
            () => reloadIframe()
        )
    }

    editPreviousTag() {
        const { tags } = this.state

        const tag = tags.pop()

        this.setState({ tags, tagsInput: tag }, () => reloadIframe())
    }

    handleToggleCarouselAutoplay(e) {
        this.setState({ isAutoplay: e.target.checked })
    }

    /* Not sure why we need it here
    static userAvatar({
                          avatar,
                          name,
                          id
                      }, avatarSize = "") {
        if (avatar) {
            const avatarSrc = avatar.original;
            return <img className="table-item-img" src={avatarSrc} alt='img'/>;
        } else {
            return <div className={`table-item-img table-item-img-name ${avatarSize === "" && "table-item-img-name--big"}`}>{name[0]}</div>;
        }
    } */

    render() {
        const { tags, tagsInput, campaign, source, isAutoplay } = this.state
        const { account } = this.props

        // eslint-disable-next-line unicorn/no-array-reduce
        const urlTags = tags.reduce((acc, tag) => `${acc}&searchTerms=${encodeURIComponent(tag)}`, '')

        const joinedTags = tags.map((tag) => {
            return tag.replace(/"|'/gm, '')
        })

        const friendlyTags = tags.map((tag) => tag.replace(/"|'/gm, '')).join(', ')

        // const universityURL = this.props.account.account_info.university.university_url ? this.props.account.account_info.university.university_url : "";

        const dataParamName =
            account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? 'data-group-id'
                : 'data-university-id'
        const dataParamId =
            account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                ? account.account_info.universityGroup.id
                : account.currentGroupUniversityId
                ? account.currentGroupUniversityId
                : account.account_info.university.id

        return (
            <Protect>
                <div id="page-body" className="page-body js-index">
                    <section className="content settings modal-restricted-area" id="modal-restricted-area">
                        <h3 className="settings-title">Ambassador carousel</h3>

                        <div className="settings-grid has-tutorial">
                            <div className="column">
                                <div className="tutorial-intro">
                                    <p>
                                        You can place one or multiple carousels on your website to showcase your
                                        ambassador profiles and direct traffic to your TAP Feed. Configure your carousel
                                        using the settings below, then paste the embed code into the pages on your
                                        website where you would like the carousel to appear. There is no limit to the
                                        number of carousels that you set up, and you can configure each one differently.
                                    </p>
                                </div>
                                <hr className="settings-hr"></hr>
                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">Select search filters</h4>
                                    <p>Please choose the search filters that your Carousel should link to.</p>
                                    <p>
                                        Please add search filters that will pre-filter your Carousel. Remember that the
                                        page will be filtered by all of these search terms eg. if you search
                                        'International' and 'Business' results will be shown for content types with both
                                        of those search terms.
                                    </p>
                                    <p>
                                        Press
                                        <code>enter</code>
                                        to add a term. Press <code>backspace</code>
                                        to edit the last term.
                                    </p>
                                    <div className="tagger">
                                        <div className="tags">
                                            <ul>
                                                {tags.map((tag, i) => (
                                                    <li key={tag + i} className="tag">
                                                        {tag}
                                                    </li>
                                                ))}
                                            </ul>
                                            <input
                                                type="text"
                                                placeholder="Add a search filter.."
                                                name="tagsInput"
                                                value={tagsInput}
                                                onChange={this.handleChange}
                                                className="tag-input"
                                                onKeyUp={this.handleKeyUp}
                                                onKeyDown={this.handleKeyDown}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">
                                        Add a UTM source tag
                                        <span>(optional)</span>
                                    </h4>
                                    <p>
                                        Add a source (for example, 'website') that you would like to see in tracking
                                        data. This is usually the place where you will be using the carousel
                                    </p>
                                    <input
                                        className="promotion-input"
                                        placeholder="Add a UTM source tag..."
                                        name="source"
                                        value={source}
                                        onChange={this.handleChange}
                                        type="text"
                                    />
                                </div>

                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">
                                        Add a UTM campaign tag
                                        <span>(optional)</span>
                                    </h4>
                                    <p>
                                        Add a campaign (for example, 'Open Day') that you would like to see in tracking
                                        data. This is so that you can track individual campaigns
                                    </p>
                                    <input
                                        className="promotion-input"
                                        placeholder="Add a UTM campaign tag..."
                                        name="campaign"
                                        value={campaign}
                                        onChange={this.handleChange}
                                        type="text"
                                    />
                                </div>
                                <div className="settings-item tap-page-embed">
                                    <div className="autoplay-row">
                                        <h4 className="settings-name">Carousel Autoplay</h4>
                                        <label className="slideOne js-holder">
                                            <input
                                                type="checkbox"
                                                checked={isAutoplay}
                                                onChange={this.handleToggleCarouselAutoplay}
                                            />
                                            <span className="slideOne-icon"></span>
                                        </label>
                                    </div>
                                    <p>
                                        If set, the Carousel that includes 4 profile cards and more will be auto-played
                                    </p>
                                </div>
                            </div>

                            <div className="column resources">
                                <h3 className="video-title">Video tutorial: Creating carousels</h3>
                                <div className="video-wrapper">
                                    <iframe
                                        src="https://player.vimeo.com/video/757181495?h=27b24d64f9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22"
                                        width="640"
                                        height="373"
                                        frameBorder="0"
                                        allow="autoplay; fullscreen"
                                        allowFullScreen
                                    ></iframe>
                                </div>

                                <div className="resources-box">
                                    <h3>Helpful resources</h3>
                                    <ul>
                                        <li>
                                            <a
                                                href="https://knowledge.theambassadorplatform.com/how-to-use-the-promotion-feature"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                How to use the Promotion feature
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="column spacer"></div>
                        </div>

                        <div className="settings-grid tap-page-widget">
                            <div className="column">
                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">Summary</h4>
                                    <p>These are the settings for your carousel</p>
                                    <table className="promotion-table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Search filters
                                                    {friendlyTags ? <small>{tags.length} selected</small> : null}
                                                </td>
                                                <td>{friendlyTags || <span>None selected</span>}</td>
                                            </tr>
                                            <tr>
                                                <td>UTM source tag</td>
                                                <td>{source ? <code>{source}</code> : <span>None selected</span>}</td>
                                            </tr>
                                            <tr>
                                                <td>UTM campaign tag</td>
                                                <td>
                                                    {campaign ? <code>{campaign}</code> : <span>None selected</span>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    UTM medium tag
                                                    <small>Not editable</small>
                                                </td>
                                                <td>
                                                    <code>carousel</code>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">Embed</h4>
                                    <p>
                                        This is your carousel embed code. Copy and paste this between the{' '}
                                        <code>{'<body> </body>'}</code> tags on pages where you would like the carousel
                                        to appear
                                    </p>
                                    <textarea
                                        cols="40"
                                        rows="5"
                                        readOnly="readOnly"
                                        className="settings-url"
                                        value={`<iframe id='tap-iframe-widget' style='border:none' src='' width='100%' height='700' scrolling='no' data-type='carousel' ${dataParamName}='${dataParamId}' data-terms='${joinedTags}' data-params='?utm_medium=carousel${
                                            source ? '&utm_source=' + source : ''
                                        }${
                                            campaign ? '&utm_campaign=' + campaign : ''
                                        }${`&autoplay=${isAutoplay}`}'></iframe><script defer src='${
                                            config.carousel_script
                                        }'></script>`}
                                    />
                                </div>

                                <div className="settings-item tap-page-embed">
                                    <h4 className="settings-name">Carousel example</h4>
                                    <p>This is an example of how your carousel will look on your website</p>
                                </div>

                                <iframe
                                    key={account.currentGroupUniversityId}
                                    id="tap-iframe-widget"
                                    style={{ border: 'none', width: '100%', height: '700px' }}
                                    src={`${config.tap_page_url}${
                                        account.account_info.isGroupAdmin && !account.currentGroupUniversityId
                                            ? 'group/' + account.account_info.universityGroup.id
                                            : account.currentGroupUniversityId
                                            ? account.currentGroupUniversityId
                                            : account.account_info.university.id
                                    }/carousel?tap-dashboard=true&utm_medium=carousel${
                                        source ? '&utm_source=' + encodeURIComponent(source) : ''
                                    }${campaign ? '&utm_campaign=' + encodeURIComponent(campaign) : ''}${urlTags}`}
                                    scrolling="no"
                                ></iframe>
                            </div>
                        </div>
                    </section>
                </div>
                {!isAllowed('promotion') && <RestrictedModal closable={false} />}
            </Protect>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        users: state.users,
    }
})(Carousel)
