import HttpService from '../../system/services/HttpService'

export default {
    searchStreams: ({ limit = 20, offset = 0, universityId = null, filter = {} }) => {
        const data = {
            limit,
            offset,
            filter,
        }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/liveStreams', data)
    },

    getStream: ({ id }) => {
        return HttpService.get(`/dashboard/liveStreams/${id}`)
    },

    createStream: (data) => {
        return HttpService.post('/dashboard/liveStreams/', data)
    },
    editStream: (data) => {
        return HttpService.put('/dashboard/liveStreams/', data)
    },
    editStreamMembers: (data) => {
        return HttpService.put('/dashboard/liveStreams/members', data)
    },
    editStreamStatus: (data) => {
        return HttpService.put('/dashboard/liveStreams/status', data)
    },
    editStreamSpeakers: (data) => {
        return HttpService.put('/dashboard/liveStreams/speakers', data)
    },
    archiveStream: (data) => {
        return HttpService.put('/dashboard/liveStreams/archive', data)
    },
    getStreamMembers: ({ dialogId = null, universityId = null }) => {
        const data = {
            dialogId,
        }
        if (universityId) {
            data.universityId = universityId
        }
        return HttpService.get('/dashboard/liveStreams/members', data)
    },
    getProspects: (data) => {
        return HttpService.get('/dashboard/liveStreams/prospects', data)
    },
    shareStreamRecord: ({ liveStreamId, ...data }) => {
        return HttpService.get(`/dashboard/liveStreams/${liveStreamId}/shareRecord`, data)
    },
}
