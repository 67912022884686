import React, { useEffect, useMemo, useCallback } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions';
import { getUsersMessagingSlice } from '../../../../../redux/selectors';
import { roles } from '../../../../../helpers/helpers';
var Option = Select.Option;
var AmbassadorSelect = function (_a) {
    var onChangeAmbassadorSelect = _a.onChangeAmbassadorSelect;
    var dispatch = useDispatch();
    var ambassadors = useSelector(getUsersMessagingSlice).ambassadors;
    var options = useMemo(function () {
        return ambassadors.map(function (ambassador) {
            return (React.createElement(Option, { key: ambassador.id, value: ambassador.id, label: ambassador.name }, "".concat(ambassador.name, " ").concat(ambassador.last_name)));
        });
    }, [ambassadors]);
    var onSearch = useCallback(function (value) {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                    keyword: value,
                },
                roles: [roles.student],
            },
        });
    }, [dispatch]);
    useEffect(function () {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
                roles: [roles.student],
            },
        });
    }, [dispatch]);
    return (React.createElement(Select, { showSearch: true, size: 'large', style: { width: 'calc(80% + 2rem + 2px)' }, onChange: onChangeAmbassadorSelect, onSearch: onSearch, filterOption: false, placeholder: 'Select ambassador' }, options));
};
export default AmbassadorSelect;
